import React from 'react';
import BoxClick from './mp-box-click';
import { matchingPairTypes } from 'configs/constants';
import PropTypes from 'prop-types';
import './matching-pair.scss';
import lodashGet from 'lodash.get';

class MatchingPair extends React.Component {
  render() {
    const { question } = this.props;
    const reorder_type = lodashGet(
      question,
      'reorder_type',
      matchingPairTypes.BOX_CLICK,
    );

    if (
      reorder_type === matchingPairTypes.BOX_CLICK ||
      reorder_type === matchingPairTypes.FLUID
    ) {
      return <BoxClick {...this.props} />;
    }

    /* we hide for matching pairs since there is only one option
      if (reorder_type === matchingPairTypes.FLUID) {
        return <Fluid {...this.props} />;
      }
    */

    return null;
  }
}

MatchingPair.propTypes = {
  question: PropTypes.shape(),
};

MatchingPair.defaultProps = {
  question: {},
};

export default MatchingPair;
