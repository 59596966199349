import { required } from 'common/validators';
import { slugifier } from 'common/normalizers';
import { t1 } from 'translate';
import AcademicCategoryLayout from './AcademicCategoryLayout';
import { caphoc, targetUser } from 'components/common/elements';

const schema = (formid, values, step) => ({
  name: {
    type: 'text',
    hintText: t1('name_organization'),
    floatingLabelText: t1('name'),
    validate: [required(t1('name_cannot_be_empty'))],
    defaultValue: '',
    errorText: '',
    fullWidth: true,
  },
  code: {
    type: 'text',
    hintText: t1('code'),
    floatingLabelText: t1('code'),
    validate: [required(t1('code_cannot_be_empty'))],
    defaultValue: '',
    errorText: '',
    fullWidth: true,
    normalize: slugifier,
    disabled: step === 'edit_academic',
  },
  slug: {
    type: 'text',
    hintText: t1('slug'),
    floatingLabelText: t1('slug'),
    validate: [required(t1('slug_cannot_be_empty'))],
    defaultValue: '',
    errorText: '',
    fullWidth: true,
    normalize: slugifier,
  },
  caps: caphoc(),
  target_users: targetUser(),
});

const ui = (step) => {
  const fields = ['name', 'code', 'caps', 'target_users'];

  const config = {
    new_academic: [
      {
        id: 'default',
        fields,
      },
    ],
    edit_academic: [
      {
        id: 'default',
        fields,
      },
    ],
  };

  return config[step];
};

const layoutRow = {
  component: AcademicCategoryLayout,
  freestyle: 1,
};

const layout = {
  new: '',
};

const schemaCategory = (mode) => {
  return { schema, ui, layout: mode === 'row' ? layoutRow : layout };
};

export default schemaCategory;
