import React from 'react';
import get from 'lodash.get';
import { Redirect, withRouter } from 'react-router';
import SubLeftMenuContext from 'common/context/menu/SubMenuLeft';
import SubTopMenuContext from 'common/context/menu/SubMenuTop';
import getContent, { getUrlByTrainingPhaseIid, menuItems } from './routes';
import { compose } from 'redux';
import { fetchTrainingPhase } from 'components/bdtx/training-phase/utils';
import Loading from 'components/common/loading';
import { loadingStatuses } from 'configs/constants';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';

const TrainingPhaseMainStage = ({
  match,
  node,
  loadingStatus,
  refetchTrainingPhase,
}) => {
  const action = get(match, 'params.action');
  const trainingPhaseIid = get(match, 'params.iid');
  const name = get(node, 'name');

  const ContentComponent = getContent(action);

  const onTrainingPhaseUpdated = React.useCallback(
    () => {
      if (typeof refetchTrainingPhase === 'function') {
        refetchTrainingPhase();
      }
    },
    [refetchTrainingPhase],
  );

  if (!action) {
    return (
      <Redirect to={getUrlByTrainingPhaseIid(trainingPhaseIid, 'dashboard')} />
    );
  }

  if (loadingStatus !== loadingStatuses.FINISHED) {
    return <Loading />;
  }

  if (loadingStatus === loadingStatuses.FINISHED && !node) {
    return <SimpleNoResult text="Không tồn tại đợt đào tạo" />;
  }

  return (
    <>
      <SubLeftMenuContext schema={menuItems(node, trainingPhaseIid)} />

      <SubTopMenuContext lastBreadcrumbName={name} description={name} />

      <ContentComponent
        node={node}
        onTrainingPhaseUpdated={onTrainingPhaseUpdated}
      />
    </>
  );
};

export default compose(
  withRouter,
  fetchTrainingPhase,
)(TrainingPhaseMainStage);
