import React from 'react';
import get from 'lodash.get';
import MasterComment from 'components/master-dashboard/report-gvcc/master-comment';

const Layout = ({
  groups: {
    default: { fieldNames },
  },
  layoutOptionsProperties,
}) => {
  const course = get(layoutOptionsProperties, 'course');
  const isGVSP = get(layoutOptionsProperties, 'isGVSP');
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row">
          {fieldNames.courseIid && (
            <div className="col-md-4">{fieldNames.courseIid}</div>
          )}
          <div className={`${fieldNames.courseIid ? 'col-md-4' : 'col-md-6'}`}>
            {fieldNames.selectedQuestionIid}
          </div>
          <div className={`${fieldNames.courseIid ? 'col-md-4' : 'col-md-6'}`}>
            {fieldNames.uiid}
          </div>
        </div>
        <div className="wrap-checkbox">
          <span>{fieldNames.not_yet_marked}</span>
          <span className="m-l-10">{fieldNames.rejected}</span>
          <span className="m-l-10">{fieldNames.take_updated}</span>
        </div>
      </div>

      {!!isGVSP && (
        <div className="col-md-12">
          <div className="text-right">
            <MasterComment course={course} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Layout;
