import React, { Component } from 'react';

import PieChart from 'recharts/lib/chart/PieChart';
import Pie from 'recharts/lib/polar/Pie';
import Sector from 'recharts/lib/shape/Sector';
import Legend from 'recharts/lib/component/Legend';
import Cell from 'recharts/lib/component/Cell';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';

import defaultSurveyLabels from '../survey-labels';
import { breadCrumb, stripHTML } from 'common/utils/string';
import lodashGet from 'lodash.get';
import { t1 } from 'translate';

const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#006064',
  '#880E4F',
  '#b71c1c',
  '#3E2723',
  '#263238',
];
const COLORS_ETEP = [
  '#FF8042', // Rat khong dong y
  '#FFBB28', // Khong dong y
  '#00C49F', // Dong y
  '#0088FE', // Rat dong y
  '#D3D3D3', // Bo trong
  '#880E4F',
  '#b71c1c',
  '#3E2723',
  '#263238',
];

const formatNumberPercent = (percent) => {
  if (percent == 0 || percent == 100) {
    return percent;
  }

  return percent.toFixed(2);
};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    // algorithm to anchor the label
    <g>
      {!!payload.hoverTitle && <title>{payload.hoverTitle}</title>}

      {!!payload.subName && (
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.subName}
        </text>
      )}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >
        {payload.total}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {payload.percent}
      </text>
    </g>
  );
};

const style = {
  top: 40,
  left: 500,
  lineHeight: '24px',
};

class SurveyReportQuestionChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 3,
    };
    this.onPieEnter = this.onPieEnter.bind(this);
  }

  onPieEnter(data, index) {
    this.setState({
      activeIndex: index,
    });
  }

  getTotalAnswerOfQuestion = (question, demographic, surveyLabels) => {
    let totalAnswer = lodashGet(question, `total_answer.${demographic}`);

    if (Number.isInteger(totalAnswer)) {
      return totalAnswer;
    }

    totalAnswer = 0;

    const stats = lodashGet(question, demographic);
    if (
      !Array.isArray(stats) ||
      !stats.length ||
      !Array.isArray(surveyLabels) ||
      !surveyLabels.length
    ) {
      return totalAnswer;
    }

    surveyLabels.forEach(({ value }, index) => {
      const indexAnswer = typeof value === 'number' ? value : index;
      const total = lodashGet(stats, indexAnswer);

      if (!isNaN(total) && total > 0) {
        totalAnswer += total;
      }
    });

    return totalAnswer;
  };

  render() {
    // stats = [
    // nrOfAnswers Option 1,
    // nrOfAnswers Option 2,
    // nrOfAnswers Option 3,
    // nrOfAnswers Option 4,
    // nr of answers left unanswered,
    // ]
    const { question, demographic } = this.props;
    // const answers = question.answers2;

    const surveyLabels = Array.isArray(lodashGet(question, 'answers2'))
      ? lodashGet(question, 'answers2')
      : defaultSurveyLabels;

    const stats = question[demographic];

    const totalAnswer = this.getTotalAnswerOfQuestion(
      question,
      demographic,
      surveyLabels,
    );

    const pieData = [];

    // print out percentage alongside answer text
    surveyLabels.map((labelConfig, index) => {
      let percentage = 0;
      const text =
        stripHTML(labelConfig.text) || t1('option_%snd_of_answer', [index + 1]);
      const indexAnswer =
        typeof labelConfig.value === 'number' ? labelConfig.value : index;
      if (totalAnswer > 0) {
        percentage = (parseFloat(stats[indexAnswer]) / totalAnswer) * 100;
        if (isNaN(percentage)) {
          percentage = 0;
        }
      }

      if (stats)
        pieData.push({
          name: `${index + 1}) ${text} - ${
            stats[indexAnswer]
          } người (${formatNumberPercent(percentage)}%)`,
          shortName: `${breadCrumb(text, 15)}`,
          total: `${stats[indexAnswer]} người`,
          percent: `(${formatNumberPercent(percentage)}%)`,
          hoverTitle: `${index + 1}) ${text}`,
          subName: '',
          value: totalAnswer > 0 ? stats[indexAnswer] : 0,
        });
    });

    return (
      <ResponsiveContainer
        width={1000}
        height={surveyLabels.length * 40 > 250 ? surveyLabels.length * 40 : 250}
      >
        <PieChart>
          <Pie
            activeIndex={this.state.activeIndex}
            activeShape={renderActiveShape}
            data={pieData}
            cx={200}
            cy={100}
            innerRadius={10}
            outerRadius={50}
            fill="#8884d8"
            onMouseEnter={this.onPieEnter}
            dataKey="value"
          >
            {window.isETEP
              ? pieData.map((entry, index) => (
                  <Cell
                    fill={COLORS_ETEP[index % COLORS_ETEP.length]}
                    key={`spcp-${index}`}
                  />
                ))
              : pieData.map((entry, index) => (
                  <Cell
                    fill={COLORS[index % COLORS.length]}
                    key={`spcp-${index}`}
                  />
                ))}
          </Pie>
          <Legend
            iconSize={12}
            width={500}
            height={250}
            layout="vertical"
            verticalAlign="middle"
            wrapperStyle={style}
            className="text-muted"
          />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}

export default SurveyReportQuestionChart;
