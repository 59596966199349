import { convertBooleanValueToInt } from 'common/normalizers';
import { t1 } from 'translate';

const isDtts = (props = {}) => ({
  classWrapper: 'col-md-6',
  ...props,
  type: 'checkbox',
  label: t1('ethnic_minority_short_text'),
  normalize: convertBooleanValueToInt,
});

export default isDtts;
