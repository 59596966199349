import React from 'react';
import { useDrop } from 'react-dnd';
import ItemTag from './item-tag';

const FixedItem = ({
  className,
  accept,
  answers = [],
  onDrop,
  handleItemChildrenClick,
  disabled,
  isAnswerCorrect,
  index,
  content,
  showContentAsHeader,
}) => {
  const cssClass = 'ddm-question-fixed-item';

  const [{ canDrop, isOver }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;
  const componentClassName = `${className || ''} ${cssClass} ${
    isActive ? 'active' : ''
  }`;

  return (
    <div ref={drop} className={componentClassName}>
      <div className="main">
        {!!showContentAsHeader && (
          <div
            className={`border-bottom text-center ${cssClass}__title-header`}
          >
            {content}
          </div>
        )}
        <div className="p-5">
          {answers.length > 0 ? (
            <div className="content-item align-items-center">
              {answers.map((item, childrenIndex) => {
                return (
                  <>
                    <ItemTag
                      handleItemChildrenClick={() =>
                        handleItemChildrenClick(index, childrenIndex)
                      }
                      item={item}
                      key={item.type}
                    />
                  </>
                );
              })}
            </div>
          ) : (
            <span style={{ opacity: 0.3 }}>{content}</span>
          )}
        </div>
        {disabled && (
          <span
            className={`question-status-icon ${
              !isAnswerCorrect ? 've-close-outline' : 've-check'
            }`}
          />
        )}
      </div>
    </div>
  );
};

export default FixedItem;
