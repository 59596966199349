import lClone from 'lodash.clone';
import get from 'lodash.get';
import { useCallback } from 'react';
import { shuffleArrayByKey } from 'common/utils/Array';
import { isQuestionShufflable } from 'common/learn/Question';

const useCoreLogic = ({
  userAnswers,
  question,
  setUserAnswers,
  disabled,
  singleDropped = true,
  practice,
  setShowError,
  questionShuffleKey,
}) => {
  const {
    ddm_questions = [],
    ddm_answers = [],
    ddm_correct_answers = [],
  } = question;

  const getCorrectAnswers = (questionIndex) => {
    const correctAnswersIndex = get(
      ddm_correct_answers,
      `[${questionIndex}]`,
      [],
    );

    return correctAnswersIndex.map((answerIndex) =>
      get(ddm_answers, `[${answerIndex}].id`),
    );
  };

  const generateQuestionsData = () => {
    return ddm_questions.map((data, index) => {
      const userAnswerList = get(userAnswers, `[${index}]`, []);
      let answers = [];

      if (userAnswerList.length) {
        answers = userAnswerList.map((item) => {
          const result = get(ddm_answers, `[${item}]`);
          return {
            ...result,
            type: result.id,
          };
        });
      }

      const correctAnswers = getCorrectAnswers(index);
      const acceptAllAnswers = ddm_answers.map((item) => item.id);

      return {
        ...data,
        accepts: acceptAllAnswers,
        type: data.id,
        answers,
        correctAnswers,
      };
    });
  };

  const generateAnswersData = () => {
    return ddm_answers.map((data, index) => {
      const itemInUserAnswers =
        Array.isArray(userAnswers) &&
        userAnswers.find(
          (item) => item.includes(index) || item.includes(String(index)),
        );

      return {
        ...data,
        type: data.id,
        answered: singleDropped && itemInUserAnswers,
      };
    });
  };

  const questionsData = generateQuestionsData();
  let answersData = generateAnswersData();

  const generateApiData = useCallback(
    (data) => {
      return data.map((item) => {
        const answers = get(item, 'answers', []);
        return answers.map((item) => {
          return answersData.findIndex((i) => i.type === item.type);
        });
      });
    },
    [answersData],
  );

  const handleDrop = useCallback(
    (index, item) => {
      const question = get(questionsData, `[${index}]`);
      const answers = get(question, 'answers', []);
      const correctAnswers = get(question, 'correctAnswers', []);
      if (disabled) {
        return;
      }
      if (practice) {
        const isCorrect = correctAnswers.includes(item.type);
        setShowError((one) =>
          isCorrect
            ? -Math.abs(Math.abs(one) + 1)
            : Math.abs(Math.abs(one) + 1),
        );
        if (!isCorrect) {
          return;
        }
      }
      // TODO: check this logic
      // if (answers.length === questionsData.length) {
      //   return;
      // }

      if (answers.length && answers.find((i) => i.type === item.type)) {
        return;
      }

      const answersDataCloned = lClone(questionsData);
      answersDataCloned[index].answers = [...answers, item];

      const apiData = generateApiData(answersDataCloned);
      setUserAnswers(apiData);
    },
    [
      questionsData,
      practice,
      generateApiData,
      setUserAnswers,
      setShowError,
      disabled,
    ],
  );

  const removeAnsweredItem = useCallback(
    (index, answeredItemIndex) => {
      let answersDataCloned = lClone(questionsData);
      const answers = get(answersDataCloned, `[${index}].answers`, []);

      answers.splice(answeredItemIndex, 1);
      answersDataCloned[index].answers = answers;

      return answersDataCloned;
    },
    [questionsData],
  );

  const handleAnsweredItemClick = useCallback(
    (index, answeredItemIndex) => {
      if (disabled) {
        return;
      }

      const newData = removeAnsweredItem(index, answeredItemIndex);
      const apiData = generateApiData(newData);

      setUserAnswers(apiData);
    },
    [removeAnsweredItem, generateApiData, setUserAnswers, disabled],
  );

  const checkAnswerIsCorrect = useCallback(
    (answers, index) => {
      const userAnswersFormatted = answers.map((item) => {
        return answersData.findIndex((i) => i.type === item.type);
      });

      if (!userAnswersFormatted || !userAnswersFormatted.length) {
        return false;
      }

      const correctAnswers = get(ddm_correct_answers, `[${index}]`, []);
      if (userAnswersFormatted.length !== correctAnswers.length) {
        return false;
      }

      const userAnswerItemsCorrect = userAnswersFormatted.filter((item) =>
        correctAnswers.includes(String(item)),
      );

      return userAnswerItemsCorrect.length === correctAnswers.length;
    },
    [ddm_correct_answers, answersData],
  );

  let answerDataShuffled = answersData;

  const isShufflable = isQuestionShufflable(question);

  if (isShufflable && questionShuffleKey) {
    answerDataShuffled = shuffleArrayByKey(answersData, questionShuffleKey);
  }

  return [
    questionsData,
    answerDataShuffled,
    handleDrop,
    handleAnsweredItemClick,
    checkAnswerIsCorrect,
  ];
};

export default useCoreLogic;
