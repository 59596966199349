import React from 'react';
import { t1 } from 'translate/index';
import get from 'lodash.get';
import './stylesheet.scss';
import NodeNew from 'components/admin/node/new';
import schema from './schema';
// import apiUrls from 'api-endpoints';
import commentApiUrls from 'components/common/comment/endpoints';
import PropTypes from 'prop-types';

const validateValue = (value = '') => {
  const v = value.trim();
  return !!(v && v.length > 0);
};

const AddComment = ({
  user,
  paramsToSendComment = {},
  formid,
  searchFormId,
  onSendCommentSuccess,
  displayInAdmin,
  userToReply,
  extraActions = [],
  canCommentPeerTake,
  simplifyComment,
  showCheckboxToCommentForCourse,
  ...props
}) => {
  const { step, ...hiddenFields } = paramsToSendComment;

  const renderSubmitButton = React.useCallback(
    (formValues) => {
      const isValid = validateValue(get(formValues, 'content', ''));

      return [
        <button
          disabled={!isValid}
          className={`btn btn-secondary send-button ${
            isValid
              ? displayInAdmin
                ? 'active-button-admin'
                : 'active-button'
              : 'not-active-button'
          }`}
          type="submit"
        >
          {t1('send')}
        </button>,
        ...extraActions,
      ];
    },
    [displayInAdmin, extraActions],
  );

  return (
    <NodeNew
      closeModal={false}
      resetForm
      formid={formid || 'add-comment'}
      {...props}
      user={user}
      hiddenFields={hiddenFields}
      schema={schema}
      step={step}
      searchFormId={searchFormId}
      submitButton={renderSubmitButton}
      alternativeApi={commentApiUrls.send_comment}
      requestSuccessful={onSendCommentSuccess}
      userToReply={userToReply}
      readOnly={!canCommentPeerTake}
      showCheckboxToCommentForCourse={showCheckboxToCommentForCourse}
      simplifyComment={simplifyComment}
    />
  );
};

AddComment.propTypes = {
  canCommentPeerTake: PropTypes.bool,
};

AddComment.defaultProps = {
  canCommentPeerTake: true,
};

export default AddComment;
