import { t1 } from 'translate';

const schema = () => ({
  text: {
    type: 'text',
    floatingLabelText: t1('search_term'),
    hintText: t1('search_term'),
    fullWidth: true,
    classWrapper: 'col-md-8 m-t-5',
  },
  status: {
    type: 'multiCheckbox',
    classWrapper: 'col-md-4',
    options: [
      {
        value: 'available',
        label: t1('available'),
        primaryText: t1('available'),
      },
      {
        value: 'error',
        label: t1('error'),
        primaryText: t1('error'),
      },
      {
        value: 'completed',
        label: t1('completed'),
        primaryText: t1('completed'),
      },
    ],
    defaultValue: ['available', 'error'],
    inline: true,
    floatingLabelText: t1('status'),
    floatingLabelFixed: false,
  },
});

const ui = () => [
  {
    id: 'default',
    fields: ['text', 'status'],
  },
];

export default {
  schema,
  ui,
};
