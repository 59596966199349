import React, { useCallback, useEffect, useState } from 'react';
import ErrorMessageQuestion from '../../ErrorMessageQuestion';
import { isItemMpCorrect } from '../util';
import './box.scss';
import ClickAbleItem from './Item';

const BoxContainer = ({
  className,
  userAnswers = [],
  question,
  setUserAnswers,
  colors,
  highlightColor,
  practice,
  disabled,
}) => {
  const { l_pair = [], r_pair = [] } = question;
  const cssClass = 'matching-pair-question-type-box-click';
  const componentClassName = `${className || ''} ${cssClass}`;

  const getQuestionData = useCallback(
    () =>
      l_pair.map((data, index) => {
        return {
          ...data,
          index,
        };
      }),
    [l_pair],
  );

  const getAnswerData = useCallback(
    () => {
      const flattenUserAnswers = (Array.isArray(userAnswers)
        ? userAnswers
        : []
      ).map((item) => item[0]);

      return r_pair.map((rightItem) => {
        const foundUserAnswer = flattenUserAnswers.indexOf(rightItem.id) >= 0;
        const leftIndex = flattenUserAnswers.findIndex(
          (item) => item === rightItem.id,
        );

        return {
          ...rightItem,
          type: rightItem.id,
          questionItem: foundUserAnswer
            ? {
                ...l_pair[leftIndex],
                index: foundUserAnswer ? leftIndex : undefined,
              }
            : null,
        };
      });
    },
    [userAnswers, r_pair, l_pair],
  );

  const [questionActivatedItem, setQuestionActivatedItem] = useState({});
  const [answerActivatedItem, setAnswerActivatedItem] = useState({});
  const [answerData, setAnswerData] = useState(getAnswerData);
  const [questionData, setQuestionData] = useState(getQuestionData);
  const [showError, setShowError] = useState(0);

  useEffect(
    () => {
      setAnswerData(getAnswerData());
    },
    [userAnswers, getAnswerData],
  );

  useEffect(
    () => {
      if (!answerActivatedItem.id || !questionActivatedItem.id || disabled) {
        return;
      }

      matchingQuestion();
    },
    [answerActivatedItem, questionActivatedItem, matchingQuestion, disabled],
  );

  const clearActivatedItem = useCallback(() => {
    setQuestionActivatedItem({});
    setAnswerActivatedItem({});
  }, []);

  const generateAnswerData = useCallback(
    (answerMatched) =>
      questionData.map((question) => {
        const foundAnswer = answerMatched.find((item) => {
          const { questionItem = {} } = item;
          const { id: qId } = questionItem || {};
          return qId === question.id;
        });

        return foundAnswer ? [foundAnswer.id] : [];
      }),
    [questionData],
  );

  const itemIsActivated = useCallback(
    (item) =>
      answerData.find((rightItem) => {
        const { questionItem } = rightItem;

        return questionItem && questionItem.id === item.id;
      }),
    [answerData],
  );

  const handleMatchedQuestion = useCallback(
    (answerMatched) => {
      clearActivatedItem();

      setUserAnswers(generateAnswerData(answerMatched));
    },
    [setUserAnswers, generateAnswerData, clearActivatedItem],
  );

  const matchingQuestion = useCallback(
    () => {
      const answerMatched = answerData.map((item) => ({
        ...item,
        questionItem:
          item.id === answerActivatedItem.id
            ? questionActivatedItem
            : item.questionItem,
      }));

      if (practice) {
        const { index: itemIndex = '' } = questionActivatedItem;
        const { id: itemUserAnswer = '' } = answerActivatedItem;
        const isCorrect = isItemMpCorrect(question, itemIndex, itemUserAnswer);
        setShowError((one) =>
          isCorrect
            ? -Math.abs(Math.abs(one) + 1)
            : Math.abs(Math.abs(one) + 1),
        );
        if (!isCorrect) {
          clearActivatedItem();
          return;
        }
      }

      setAnswerData(answerMatched);

      handleMatchedQuestion(answerMatched);
    },
    [
      answerData,
      answerActivatedItem,
      questionActivatedItem,
      handleMatchedQuestion,
      practice,
      question,
      clearActivatedItem,
    ],
  );

  const unPairAnswerWithQuestion = useCallback(
    (questionId) => {
      const answerMatched = answerData.map((answer) => ({
        ...answer,
        questionItem:
          answer.questionItem && answer.questionItem.id === questionId
            ? null
            : answer.questionItem,
      }));

      clearActivatedItem();

      setAnswerData(answerMatched);

      setUserAnswers(generateAnswerData(answerMatched));
    },
    [
      answerData,
      setAnswerData,
      setUserAnswers,
      generateAnswerData,
      clearActivatedItem,
    ],
  );

  const handleQuestionClick = useCallback(
    (item) => {
      const answered = answerData.find(
        (answer) => answer.questionItem && answer.questionItem.id === item.id,
      );

      if (answered) {
        unPairAnswerWithQuestion(item.id);
      }

      setQuestionActivatedItem(answered ? {} : item);
    },
    [unPairAnswerWithQuestion, answerData],
  );

  const handleAnswerClick = useCallback(
    (item) => {
      const answered = item.questionItem && item.questionItem.id;
      if (answered) {
        unPairAnswerWithQuestion(item.questionItem.id);
      }

      setAnswerActivatedItem(answered ? {} : item);
    },
    [unPairAnswerWithQuestion],
  );

  return (
    <>
      <ErrorMessageQuestion question={question} error={showError} />

      <div className={componentClassName}>
        <div className={`${cssClass}__question`}>
          {questionData &&
            questionData.map((item) => {
              return (
                <ClickAbleItem
                  className={`${cssClass}__item ${
                    itemIsActivated(item) ? 'activated' : 'default'
                  } ${
                    item.id === questionActivatedItem.id ? 'highlight' : ''
                  } ${answerActivatedItem.id ? 'hover-highlight' : ''}`}
                  content={item.content}
                  type={item.id}
                  avatar={item.avatar}
                  isQuestion
                  key={item.id}
                  stt={item.index + 1}
                  handleBoxClick={() => {
                    if (disabled) {
                      return;
                    }
                    handleQuestionClick(item);
                  }}
                  colors={colors}
                  isActivated={!!itemIsActivated(item)}
                  isHighlight={item.id === questionActivatedItem.id}
                  highlightColor={highlightColor}
                />
              );
            })}
        </div>

        <div className={`${cssClass}__moved`}>
          {answerData &&
            answerData.map((item, index) => {
              const { questionItem, id } = item;
              const { id: questionItemId } = questionItem || {};
              const isCorrect = id === questionItemId;

              return (
                <ClickAbleItem
                  className={`${cssClass}__item ${
                    item.questionItem || answerActivatedItem.id === item.id
                      ? 'activated'
                      : ''
                  } ${questionActivatedItem.id ? 'hover-highlight' : ''}`}
                  content={item.content}
                  type={item.type}
                  avatar={item.avatar}
                  key={index}
                  stt={item.questionItem && item.questionItem.index + 1}
                  handleBoxClick={() => {
                    if (disabled) {
                      return;
                    }
                    setAnswerActivatedItem(item);
                    handleAnswerClick(item);
                  }}
                  matched={isCorrect}
                  disabled={disabled}
                  colors={colors}
                  isActivated={!!item.questionItem}
                  isHighlight={item.id === answerActivatedItem.id}
                  highlightColor={highlightColor}
                />
              );
            })}
        </div>
      </div>
    </>
  );
};

export default BoxContainer;
