import React from 'react';
import { connect } from 'react-redux';
import { getThemeConfig } from 'utils/selectors';
import { reduxForm } from 'redux-form';
import { t1 } from 'translate';
import { layouts } from 'configs/constants';
import TextField from 'schema-form/elements/redux-form-fields/AntInputField';
import lGet from 'lodash.get';
import { forgotPassword } from '../forgot-password/actions/saga-creators';
import { isEmail, required } from 'common/validators';
import userLinks from 'routes/links/user';
import { Link } from 'react-router-dom';

class ForgotPasswordContainer extends React.Component {
  forgotPasswordAction = () => {
    const { dispatch, forgotPasswordForm } = this.props;
    dispatch(forgotPassword(forgotPasswordForm.values));
  };

  render() {
    const { themeConfig, schoolConfig, isCompact = false } = this.props;

    return (
      <div
        className={
          isCompact
            ? 'row forgot-password-compact'
            : 'container forgot-password-container'
        }
      >
        <div className={isCompact ? 'col-md-12' : ''}>
          <div className="row">
            <div
              className={isCompact ? 'col-sm-12' : 'col-sm-6 col-sm-offset-3'}
            >
              <TextField
                fullWidth
                name="mail"
                floatingLabelText={t1('type_your_email')}
                className="text-field"
                validate={[required(), isEmail()]}
                placeholder={t1('type_your_email')}
              />

              <div
                className={
                  isCompact
                    ? 'text-center m-t-10 m-b-10 forgot-password-btn forgot-password-button'
                    : 'forgot-password-btn forgot-password-button'
                }
              >
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={this.forgotPasswordAction}
                  >
                    {t1('send_password')}
                  </button>
                </div>
                <div className="login-link-wrap">
                  {isCompact == false && (
                    <Link className="login-link" to={userLinks.login}>
                      {t1('login')}
                    </Link>
                  )}
                </div>
              </div>

              <div className="note">
                {isCompact
                  ? t1(
                      'your_account_has_been_blocked.you_need_to_click_the_forgot_password_button_so_the_system_will_send_the_instructions_on_how_to_reset_your_password_in_your_email.otherwise_you_can_wait_for_upto_%s_minutes',
                      [t1('number_of_brute_force_time_wait_login_setted')],
                    )
                  : t1(
                      'when_you_click_the_forgot_password_button_the_system_will_send_the_instructions_on_how_to_reset_your_password_in_your_email',
                    )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const user = state.user && state.user.info;

  return {
    user,
    forgotPasswordForm: state.form.forgotPassword,
    themeConfig: getThemeConfig(state),
    schoolConfig: lGet(state, 'domainInfo.school'),
  };
};

export default reduxForm({
  form: 'forgotPassword',
})(connect(mapStateToProps)(ForgotPasswordContainer));
