import React from 'react';
import { caphoc, chuong_trinh, targetUser } from 'components/common/elements';
import subTypes from 'configs/constants/org-sub-types';
import { isBo, isLTTUStaff } from 'components/admin/user/utils';
import { CHUONG_TRINH_01 } from 'components/bdtx/configs';
import {
  organizations,
  simpleOrganization,
} from 'components/admin/organization/schema/elements';
import { t1 } from 'translate';
import { required } from '../../../../common/validators';

const schema = (formid, values, step, xpath, props) => {
  return {
    chuong_trinh: chuong_trinh(
      {
        type: 'radio',
        validate: required(t1('chuong_trinh_can_not_empty')),
        classWrapper: 'col-md-4',
        defaultValue: CHUONG_TRINH_01,
      },
      {
        shortName: true,
      },
    ),
    target_user: targetUser({
      type: 'radio',
      validate: required(t1('target_user_can_not_empty')),
      classWrapper: 'col-md-3',
    }),
    /*    organizations: organizations({
      formid,
      multiple: false,
      label: `${t1('organizations')}`,
      defaultValue: props.orgIids,
      validate: required(t1('organization_can_not_empty')),
      classWrapper: 'col-md-5',
    }),*/
    organizations: simpleOrganization(
      {
        floatingLabelText: 'SGD',
        classWrapper: 'col-md-5',
      },
      {
        value: {
          sub_type: [subTypes.TAPHUAN_SUBTYPE_SO_GD],
        },
      },
      true,
    ),
  };
};

const ui = () => {
  let fields = ['chuong_trinh', 'target_user', 'organizations'];

  return [
    {
      id: 'id',
      fields: fields,
    },
  ];
};

export default {
  schema,
  ui,
};
