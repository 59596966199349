import React from 'react';
import get from 'lodash.get';
import { bdtxBaseRoute } from 'routes/bdtx';

const isRouteStartsWithParam = (paramPrefix, location) => {
  const pathname = get(location, 'pathname', '');

  return pathname.startsWith(paramPrefix);
};

export const isGVSPRoute = (location) =>
  isRouteStartsWithParam('/gvspcc', location || window.location);

export const isAdminRoute = (location) =>
  isRouteStartsWithParam('/admin', location || window.location);

export const isTeacherRoute = (location) =>
  isRouteStartsWithParam('/teacher', location || window.location);

export const isBDTXRoute = (location) =>
  isRouteStartsWithParam(bdtxBaseRoute, location || window.location);
