import './style.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Steps, Alert } from 'antd';
import lodashGet from 'lodash.get';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import { t1 } from 'translate';
import Icon from 'components/common/Icon';
import sagaActions from 'actions/node/saga-creators';
import fetchData from 'components/common/fetchData';
import endpoints from 'components/bdtx/training-phase/endpoints';
import BaseInformationTrainingPhase from './base-information';
import ChooseModuleTrainingPhase from './choose-module';
import ChooseMaterialTrainingPhase from './choose-material';
import ConfirmTrainingPhase from './confirmation';
import { steps } from './utils';

const { Step } = Steps;

const TrainingPhaseBySteps = ({ trainingPhase, searchFormId, dispatch }) => {
  const currentTrainingPhaseStep =
    lodashGet(trainingPhase, 'current_step') || steps.base_information;

  const [currentStep, setCurrentStep] = useState(currentTrainingPhaseStep);
  const [trainingPhaseUpdated, setTrainingPhaseUpdated] = useState(
    trainingPhase,
  );

  useEffect(
    () => {
      if (!trainingPhaseUpdated && trainingPhase) {
        setTrainingPhaseUpdated(trainingPhase);
      }

      return () => {
        if (searchFormId) {
          dispatch(submit(searchFormId));
        }
      };
    },
    [dispatch, searchFormId, trainingPhase, trainingPhaseUpdated],
  );

  const stepsInformation = useMemo(() => {
    return {
      [steps.base_information]: {
        index: 0, // thứ tự của step, Steps component của antd cũng đang trả về thứ tự từ 0->x khi active step
        component: BaseInformationTrainingPhase,
        icon: <Icon icon="list" />,
        title: t1('choose_basic_information_for_training_phase'),
        description: null,
      },
      [steps.choose_module]: {
        index: 1,
        component: ChooseModuleTrainingPhase,
        icon: <Icon icon="plagiarism" />,
        title: t1('choose_modules_for_training_phase'),
        description: null,
      },
      [steps.choose_material]: {
        index: 2,
        component: ChooseMaterialTrainingPhase,
        icon: <Icon icon="book" />,
        title: t1('choose_materials_for_training_phase'),
        description: null,
      },
      [steps.confirmation]: {
        index: 3,
        component: ConfirmTrainingPhase,
        icon: <Icon icon="crown" />,
        title: t1('confirm_to_deploy_training_phase'),
        description: null,
      },
    };
  }, []);

  const updateTrainingPhaseStatus = useCallback(
    (step) => {
      dispatch(
        sagaActions.submitFormRequest(
          '',
          {
            url: endpoints.bdtx_update_training_phase,
            extraParams: {
              id: lodashGet(trainingPhase, 'id'),
              iid: lodashGet(trainingPhase, 'iid'),
              current_step: step,
              _sand_step: 'current_step',
            },
          },
          'POST',
          false,
        ),
      );
    },
    [dispatch, trainingPhase],
  );

  const onUpdateTrainingPhase = useCallback((data) => {
    if (!data) {
      return;
    }

    setTrainingPhaseUpdated(data);
  }, []);

  const renderStep = useCallback(
    () => {
      return Object.keys(stepsInformation).map((key, index) => {
        const stepInfo = stepsInformation[key];

        return (
          <Step
            key={`training_phase_step_${lodashGet(stepInfo, 'index')}`}
            title={lodashGet(stepInfo, 'title')}
            icon={lodashGet(stepInfo, 'icon')}
            description={lodashGet(stepInfo, 'description')}
            disabled={!lodashGet(trainingPhase, 'iid')}
          />
        );
      });
    },
    [stepsInformation, trainingPhase],
  );

  const handleOnActiveStep = useCallback(
    (activeStepIndex) => {
      const stepKeys = Object.keys(stepsInformation);
      const activeStep = stepKeys[activeStepIndex];

      setCurrentStep(activeStep);

      updateTrainingPhaseStatus(activeStep);
    },
    [stepsInformation, updateTrainingPhaseStatus],
  );

  const getPreviousStepIndex = useCallback(
    () => {
      const stepKeys = Object.keys(stepsInformation);

      const currentStepIndex = lodashGet(
        stepsInformation,
        `${currentStep}.index`,
      );
      const previousStepIndex = currentStepIndex - 1;

      return stepKeys[previousStepIndex] ? previousStepIndex : undefined;
    },
    [currentStep, stepsInformation],
  );

  const getNextStepIndex = useCallback(
    () => {
      const stepKeys = Object.keys(stepsInformation);

      const currentStepIndex = lodashGet(
        stepsInformation,
        `${currentStep}.index`,
      );

      const nextStepIndex = currentStepIndex + 1;

      return stepKeys[nextStepIndex] ? nextStepIndex : undefined;
    },
    [currentStep, stepsInformation],
  );

  const renderContentByStep = useCallback(
    (currentStep) => {
      const Component = lodashGet(stepsInformation, `${currentStep}.component`);

      return (
        <div className="d-flex justify-content-center d-flex-column">
          {lodashGet(trainingPhase, 'status.deployed') ? (
            <Alert
              type="error"
              message={
                <span>
                  {t1(
                    'this_training_phase_has_been_deployed.you_can_only_edit_some_basic_information.all_actions_like_change_chuong_trinh_or_the_material_will_be_restricted',
                  )}
                </span>
              }
              className="m-b-15 text-center"
            />
          ) : null}

          <Component
            trainingPhase={trainingPhaseUpdated}
            onUpdateTrainingPhase={onUpdateTrainingPhase}
            handleOnActiveStep={handleOnActiveStep}
            previousStepIndex={getPreviousStepIndex()}
            nextStepIndex={getNextStepIndex()}
          />
        </div>
      );
    },
    [
      getNextStepIndex,
      getPreviousStepIndex,
      handleOnActiveStep,
      onUpdateTrainingPhase,
      stepsInformation,
      trainingPhase,
      trainingPhaseUpdated,
    ],
  );

  return (
    <div className="training-phase-steps">
      <Steps
        current={lodashGet(stepsInformation, `${currentStep}.index`)}
        onChange={handleOnActiveStep}
      >
        {renderStep()}
      </Steps>

      <div className="row m-t-35">
        <div className="col-md-8 col-md-offset-2">
          {renderContentByStep(currentStep)}
        </div>
      </div>
    </div>
  );
};

const fetchTrainingPhase = fetchData((props) => {
  const iid = lodashGet(props, 'match.params.iid');

  return {
    baseUrl: endpoints.bdtx_get_training_phase,
    fetchCondition: !!iid,
    params: {
      iid,
    },
    propKey: 'trainingPhase',
  };
});

export default compose(
  connect(),
  withRouter,
  // fetchTrainingPhase,
)(TrainingPhaseBySteps);
