import React from 'react';
import { Modal } from 'antd';

import { isSmallScreen } from 'common';
import withUserInfo from 'common/hoc/withUserInfo';
import lodashGet from 'lodash.get';
import { getCurrentUnixTimestamp } from 'common/utils/Date';

const getSeenTetHolidaySupportMessageKey = (userIid) =>
  `seenTetHolidaySupportMessage_${userIid}`;

const setUserSeenMessageInSession = (userIid, v) => {
  const seenTetHolidaySupportMessageKey = getSeenTetHolidaySupportMessageKey(
    userIid,
  );

  if (v) {
    window.sessionStorage.setItem(seenTetHolidaySupportMessageKey, 'true');
  } else {
    window.sessionStorage.removeItem(seenTetHolidaySupportMessageKey);
  }
};

const hasUserSeenMessageInSession = (userIid) => {
  const seenTetHolidaySupportMessageKey = getSeenTetHolidaySupportMessageKey(
    userIid,
  );

  return (
    window.sessionStorage.getItem(seenTetHolidaySupportMessageKey) === 'true'
  );
};

const TetHolidaySupport = ({ userInfo }) => {
  const userIid = lodashGet(userInfo, 'iid');

  const [seen, setSeen] = React.useState(hasUserSeenMessageInSession(userIid));

  const onUserSeen = React.useCallback(
    () => {
      setUserSeenMessageInSession(userIid, true);
      setSeen(true);
    },
    [userIid],
  );

  const onOk = React.useCallback(
    () => {
      onUserSeen();
    },
    [onUserSeen],
  );

  const options = React.useMemo(
    () => {
      const highlightClass = 'text-danger';
      const content = (
        <div>
          <p style={{ textAlign: 'justify' }}>
            Viettel trân trọng thông báo, trong thời gian nghỉ Tết từ ngày
            20/01/2023 (29 Tết) đến hết 26/01/2023 (mùng 5 Tết), Viettel duy trì
            phục vụ khách hàng qua tổng đài 18008000. Viettel xin kính chúc Quý
            khách một năm mới An khang - Thịnh vượng. Trân trọng cảm ơn Quý
            khách đã đồng hành với Viettel !
          </p>
        </div>
      );

      return {
        ...(isSmallScreen ? {} : { width: 500 }),
        icon: 'info-circle',
        title: 'Thông báo',
        okButtonProps: { className: 'btn-secondary p-t-7 p-b-7' },
        cancelButtonProps: {
          style: { display: 'none' },
        },
        okText: 'Tôi đã hiểu',
        onOk,
        content,
      };
    },
    [onOk],
  );

  React.useEffect(
    () => {
      const time = getCurrentUnixTimestamp();

      const [showFrom, showTo] = window.TET_HOLIDAY_MESSAGE
        ? [
            window.TET_HOLIDAY_MESSAGE.SHOW_FROM,
            window.TET_HOLIDAY_MESSAGE.SHOW_TO,
          ]
        : [];

      const isTimeToShow =
        showFrom && showTo && showFrom <= time && time <= showTo;

      if (isTimeToShow && !seen) {
        Modal.confirm(options);
      }
    },
    [options, seen],
  );

  return <></>;
};

const makeSureUserLoggedIn = (Comp) => ({ userInfo }) => {
  if (!lodashGet(userInfo, 'iid')) {
    return null;
  }

  return <Comp userInfo={userInfo} />;
};

export default withUserInfo(makeSureUserLoggedIn(TetHolidaySupport));
