import './stylesheet.scss';
import React, { useEffect, useState } from 'react';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import CheckBox from 'material-ui/Checkbox';
import TextField from 'schema-form/elements/ant-input';
import lodashGet from 'lodash.get';
import trim from 'lodash.trim';
import Audio from 'components/common/media-player/audio';
import {
  pushToSet,
  remove,
  removeByCondition,
  shuffleArrayByKey,
  updateMultipleElement,
} from 'common/utils/Array';
import DisplayHtml from 'components/common/html';
import ErrorMessageQuestion from '../../../ErrorMessageQuestion';
import { isQuestionShufflable } from 'common/learn/Question';

const styles = {
  block: {
    maxWidth: 250,
  },
  radioButton: {
    marginBottom: 10,
  },
  checkBox: {
    marginBottom: 10,
  },
  icon: {
    fill: '#427bc9',
  },
  error: { color: 'red' },
};

const cssClass = 'mc-text-question';

const OpenEndedAnswer = ({ title, index, onChange, value }) => {
  const handleContentChange = React.useCallback(
    (content) => {
      if (typeof onChange === 'function') {
        onChange({ content }, index);
      }
    },
    [onChange, index],
  );

  return (
    <div>
      <div>{title}</div>
      <TextField
        fullWidth
        multiLine
        onChange={handleContentChange}
        value={lodashGet(value, 'content')}
      />
    </div>
  );
};

const SelectText = ({
  meta,
  vertical,
  data,
  valueKey,
  id,
  getHighlightsClass,
  getValueOfItem,
  disabled,
  value,
  multiple,
  withAudio,
  onChange,
  options,
  hintText,
  showError,
  questionShuffleKey,
  question,
}) => {
  const [shuffleAnswers, setShuffleAnswers] = useState([]);
  const [mcAnswers, setMcAnswers] = useState([]);

  const isShufflable = isQuestionShufflable(question);

  useEffect(
    () => {
      const answersData = options || data || [];

      if (isShufflable && questionShuffleKey) {
        const answerDataShuffled = shuffleArrayByKey(
          answersData,
          questionShuffleKey,
        );

        setShuffleAnswers(answerDataShuffled);
      } else {
        setShuffleAnswers(answersData);
      }

      setMcAnswers(answersData);
    },
    [data, options, questionShuffleKey, isShufflable],
  );

  const { selectedIndexes, openEndedAnswers } = value || {};
  const directionClass = vertical ? 'direction-column' : 'direction-row';

  const handleChange = React.useCallback(
    ({
      selectedIndexes: newSelectedIndexes,
      openEndedAnswers: newOpenEndedAnswers,
    }) => {
      if (typeof onChange === 'function') {
        onChange({
          selectedIndexes: newSelectedIndexes,
          openEndedAnswers: newOpenEndedAnswers,
        });
      }
    },
    [onChange],
  );

  const handleRadioSelect = React.useCallback(
    (event, index) => {
      handleChange({ selectedIndexes: [index] });
    },
    [handleChange],
  );

  const handleCheckboxesSelect = React.useCallback(
    (indexes) => {
      handleChange({
        selectedIndexes: indexes,
        openEndedAnswers,
      });
    },
    [handleChange, openEndedAnswers],
  );

  const getCallBackToFindOpenEndedAnswerOfIndex = (answerIndex) => (ans) =>
    lodashGet(ans, 'answer_index') == answerIndex;

  const handleOpenEndedAnswerChange = React.useCallback(
    ({ content }, answerIndex) => {
      if (content) {
        handleChange({
          selectedIndexes: pushToSet(selectedIndexes, answerIndex),
          openEndedAnswers: updateMultipleElement(
            openEndedAnswers,
            getCallBackToFindOpenEndedAnswerOfIndex(answerIndex),
            (ans) => ({
              answer_index: answerIndex,
              content,
            }),
            true,
          ),
        });
      } else {
        handleChange({
          selectedIndexes: remove(selectedIndexes, answerIndex),
          openEndedAnswers: removeByCondition(
            openEndedAnswers,
            getCallBackToFindOpenEndedAnswerOfIndex(answerIndex),
          ),
        });
      }
    },
    [handleChange, selectedIndexes, openEndedAnswers],
  );

  const getRealIndexFromShuffleItem = (item, itemIndex) => {
    return mcAnswers.findIndex((opt) => opt.text == item.text);
  };
  return (
    <div className={cssClass}>
      {hintText}
      {meta && meta.touched && meta.error && (
        <div style={styles.error}>{meta.error}</div>
      )}

      <ErrorMessageQuestion
        question={question}
        error={showError}
        keepShowing={true}
      />

      {multiple && (
        <div className={`display-panel ${directionClass}`}>
          {shuffleAnswers &&
            shuffleAnswers.map((item, index) => {
              index = getRealIndexFromShuffleItem(item, index);

              const key = index;
              const title = trim(lodashGet(item, 'text'));
              const description = trim(lodashGet(item, 'description'));

              const isOpenEndedAnswer = lodashGet(item, 'is_open_ended_answer');

              const checked =
                selectedIndexes &&
                Array.isArray(selectedIndexes) &&
                selectedIndexes.includes(index);
              if (isOpenEndedAnswer) {
                return (
                  <OpenEndedAnswer
                    title={title}
                    index={index}
                    onChange={handleOpenEndedAnswerChange}
                    value={(openEndedAnswers || []).find(
                      getCallBackToFindOpenEndedAnswerOfIndex(index),
                    )}
                  />
                );
              }
              return (
                <React.Fragment>
                  <CheckBox
                    key={key}
                    checked={checked}
                    iconStyle={{
                      fill: showError > 0 ? 'red' : '#427bc9',
                    }}
                    style={styles.checkBox}
                    onCheck={(event, isChecked) => {
                      let newIndexes = [];
                      if (isChecked) {
                        newIndexes = (selectedIndexes &&
                          Array.isArray(selectedIndexes) &&
                          selectedIndexes.concat([index])) || [index];
                      } else {
                        newIndexes = selectedIndexes.filter((v) => v !== index);
                      }

                      handleCheckboxesSelect(newIndexes);
                    }}
                    disabled={disabled}
                    className={getHighlightsClass(
                      getValueOfItem(item, index, valueKey),
                    )}
                    value={index}
                    label={[
                      <DisplayHtml content={title} className={'rich-answer'} />,
                      withAudio && (
                        <Audio
                          controllable
                          playerId={`${id}-audio-${index}`}
                          style={{ zIndex: 2, position: 'relative' }}
                          src={item.audio}
                        />
                      ),
                    ]}
                  />
                  {description ? (
                    <DisplayHtml
                      content={description}
                      style={{
                        border: 'solid 1px',
                        padding: '15px 40px',
                        margin: '0 0 30px 0',
                      }}
                    />
                  ) : null}
                </React.Fragment>
              );
            })}
        </div>
      )}
      {!multiple &&
        (() => {
          const valueSelected =
            selectedIndexes &&
            Array.isArray(selectedIndexes) &&
            selectedIndexes[0];

          return (
            <RadioButtonGroup
              name={id}
              className={`display-panel ${directionClass} `}
              onChange={handleRadioSelect}
              valueSelected={valueSelected}
            >
              {shuffleAnswers &&
                shuffleAnswers.map((item, index) => {
                  index = getRealIndexFromShuffleItem(item, index);

                  return (
                    <RadioButton
                      iconStyle={{
                        fill: showError > 0 ? 'red' : '#427bc9',
                      }}
                      disabled={disabled}
                      key={index}
                      className={`${cssClass}__radio-answer ${
                        valueSelected === index
                          ? `${cssClass}__radio-answer--selected`
                          : ''
                      } ${getHighlightsClass(
                        getValueOfItem(item, index, valueKey),
                      )}`}
                      value={index}
                      label={[
                        <DisplayHtml
                          content={lodashGet(item, 'text')}
                          className={'rich-answer'}
                        />,
                        withAudio && (
                          <Audio
                            controllable
                            playerId={`${id}-audio-${index}`}
                            style={{ zIndex: 2, position: 'relative' }}
                            src={item.audio}
                          />
                        ),
                      ]}
                      style={styles.radioButton}
                    />
                  );
                })}
            </RadioButtonGroup>
          );
        })()}
    </div>
  );
};

export default SelectText;
