import { call, takeEvery } from 'redux-saga/effects';

import Requester from 'common/network/http/Request';
import apiUrls from 'api-endpoints';

function* downloadImportTemplate({ params = {} }) {
  const urlNew = apiUrls.template_request_to_import;

  const data = yield call(Requester.get, urlNew, params);

  if (data.success) {
    window.location.assign(data.objects.url);
  }
}

export default function* downloadImportTemplateSaga() {
  yield takeEvery('DOWNLOAD_IMPORT_TEMPLATE_REQUEST', downloadImportTemplate);
}
