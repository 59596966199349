import React from 'react';
import BDTXBo from 'components/temis/report/report-ncbdtx/BDTXBo';
import { REPORT_ACTION } from 'components/bdtx/report/routes';

const NCBDTX = ({ action }) => {
  const isResultForCBQL = action === REPORT_ACTION.CBQL;
  const reportTitle = `Nhu cầu bồi dưỡng của ${
    isResultForCBQL ? 'CBQL CSGDPT' : 'GIÁO VIÊN'
  }`;

  return (
    <BDTXBo
      reportForNcbdtx={true}
      autoGetDataWithoutScholastic={true}
      isResultForCBQL={isResultForCBQL}
      isPlanDevelopment={false}
      reportTitle={reportTitle}
    />
  );
};

export default NCBDTX;
