import React from 'react';
import {
  csdlnMoetURL,
  isEnbaleIdentificationCode,
} from 'components/temis/utils';
import Warning from 'components/common/Warning';
import SuccessAlert from 'components/common/SuccessAlert';
import Alert from 'antd/lib/alert';
import { leaderPositions } from 'configs/constants/user';
import lodashGet from 'lodash.get';

const WarningUpdateProfile = ({ timeClose, userInfo }) => {
  const [visible, setVisible] = React.useState(
    [
      leaderPositions.TEACHER,
      leaderPositions.TO_TRUONG,
      leaderPositions.VICE_LEADER,
      leaderPositions.LEADER,
    ].includes(lodashGet(userInfo, 'leader_position')) &&
      lodashGet(userInfo, 'identification_code') &&
      (!lodashGet(userInfo, 'identification_code_encrypted') ||
        lodashGet(userInfo, 'identification_code_encrypted') == ''),
  );

  React.useEffect(
    () => {
      if (timeClose && visible) {
        setTimeout(() => setVisible(false), timeClose);
      }
    },
    [visible, setVisible, timeClose],
  );

  if (!isEnbaleIdentificationCode() || !visible) {
    return null;
  }

  const verifyIdentificationCodeNotMatching = lodashGet(
    userInfo,
    'verify_identification_code_not_matching',
  );

  const notMatching = [];
  if (
    !Array.isArray(verifyIdentificationCodeNotMatching) ||
    !verifyIdentificationCodeNotMatching.length ||
    verifyIdentificationCodeNotMatching.includes('name')
  ) {
    notMatching.push('Họ và tên');
  }
  if (
    !Array.isArray(verifyIdentificationCodeNotMatching) ||
    !verifyIdentificationCodeNotMatching.length ||
    verifyIdentificationCodeNotMatching.includes('birthday')
  ) {
    notMatching.push('Ngày sinh');
  }
  if (
    !Array.isArray(verifyIdentificationCodeNotMatching) ||
    !verifyIdentificationCodeNotMatching.length ||
    verifyIdentificationCodeNotMatching.includes('sex')
  ) {
    notMatching.push('Giới tính');
  }
  if (
    !Array.isArray(verifyIdentificationCodeNotMatching) ||
    !verifyIdentificationCodeNotMatching.length ||
    verifyIdentificationCodeNotMatching.includes('maDD')
  ) {
    notMatching.push('Mã định danh');
  }
  if (
    !Array.isArray(verifyIdentificationCodeNotMatching) ||
    !verifyIdentificationCodeNotMatching.length ||
    verifyIdentificationCodeNotMatching.includes('school_code')
  ) {
    notMatching.push('Đơn vị');
  }

  return (
    <Alert
      message={
        <span>
          Không thể xác nhận thông tin tài khoản với{' '}
          <a href={csdlnMoetURL} target="_blank">
            {' '}
            <Warning inline>"Hệ thống Cơ Sở Dữ Liệu Ngành"</Warning>{' '}
          </a>
        </span>
      }
      description={
        <div>
          Thông tin tài khoản (
          <Warning inline>{notMatching.join(', ')}</Warning>
          ) không khớp với thông tin tài khoản trên CSDLN, bạn vui lòng kiểm tra
          và chỉnh sửa lại thông tin tài khoản.
          <br />
          (Nếu thông tin tài khoản đã chính xác, liên hệ với Admin đơn vị hoặc
          bấm vào{' '}
          <a href="/faq" target={'_blank'}>
            <SuccessAlert inline>đây để được hỗ trợ</SuccessAlert>
          </a>
          )
        </div>
      }
      type="error"
      showIcon
      closable
    />
  );
};

export default WarningUpdateProfile;
