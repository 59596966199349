import React from 'react';
import TextElement from 'schema-form/elements/ant-input';
import { required } from 'common/validators';

const text = ({
  inputType = 'text',
  hintText,
  floatingLabelText,
  floatingLabelFixed,
  defaultValue = '',
  errorText = '',
  isRequired,
  validate,
  normalize,
  onChange,
  classWrapper,
  fullWidth,
  readOnly,
  inline,
  multiLine,
  ...rest
}) => {
  return {
    type: TextElement,
    inputType,
    hintText,
    floatingLabelText,
    floatingLabelFixed,
    defaultValue,
    errorText,
    validate: !validate && isRequired ? [required()] : validate,
    normalize,
    onChange,
    classWrapper,
    fullWidth,
    readOnly,
    inline,
    multiLine,
    ...rest,
  };
};

export default text;
