import React, { useEffect, useState } from 'react';
import lodashGet from 'lodash.get';
import Image from '../../common/image';
import { getBlockItemImageStyle, getBlockItemSize } from '../../common/utils';
import { imageExists } from 'utils/WanVsInternet';
import { stripHTML } from 'common/utils/string';

const BlockImage = ({ item = {} }) => {
  const [isImgExist, setIsImgExist] = useState(false);
  const src = lodashGet(item, 'attachments[0].link');

  useEffect(
    () => {
      imageExists(src, (exists) => setIsImgExist(exists));
    },
    [src],
  );

  const style = {
    maxWidth: '100%',
    ...(getBlockItemImageStyle(item) || {}),
  };

  if (src && isImgExist)
    return <Image src={src} style={style} alt={stripHTML(item.content)} />;

  return null;
};

export default BlockImage;
