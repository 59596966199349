import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CommonAntdTable from 'components/common/antd/table';
import { Link } from 'react-router-dom';
import { t, t1, t3 } from 'translate';
import { hoursStringify } from 'utils/Util';
import Positions from './Positions';
import { sexAsText } from 'common/sex';
import { layouts } from 'configs/constants';
import { createSelector } from 'reselect';
import { getThemeConfig } from 'utils/selectors';
import { userPreviewLink } from 'components/admin/user/utils';
import ChangeStaffStatus from 'components/admin/user/user-in-school/common/UpdateStaffStatus';
import lodashGet from 'lodash.get';
import Icon from 'components/common/Icon';
import PreviewUserInDialog from 'components/admin/user/account/common/PreviewInDialog';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import { change } from 'redux-form';

const ResultsEnterprise = (props) => {
  const label = {
    iidCode: t1('code'),
    name: t1('name'),
    mail: t1('mail'),
    birthday: t1('birth_year'),
    sex: t1('sex'),
    positions: t1('positions'),
    phone: t1('phone'),
    status: t1('status'),
    role: t1('role'),
    actions: t1('actions'),
    editStaff: t1('edit_staff'),
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { dispatch, form, showInClass, searchFormId, group } = props;
  useEffect(
    () => {
      dispatch(change(form, 'targets', selectedRowKeys));
    },
    [dispatch, form, selectedRowKeys],
  );
  const getExperience = (startUnixTimestamp) => {
    if (!startUnixTimestamp) {
      return t3('n/a');
    }

    const durationInSeconds =
      Math.floor(Date.now() / 1000) - startUnixTimestamp;
    const durationInHours = Math.floor(durationInSeconds / (60 * 60));

    return (
      hoursStringify(['year', 'month'])(durationInHours) || `< 1 ${t('month')}`
    );
  };

  const { items, renderBeforeResultTable, themeConfig, action } = props;

  const itemList = props.itemList || items;
  const checkKey = props.checkKey || 'id';
  const keysSave = props.keysSave || ['id', 'iid', 'name'];
  const resultTableFieldName = props.resultTableFieldName || 'targets';

  const isLayoutVT = themeConfig && themeConfig.layout === layouts.VT;

  const mode = action === 'accounts' ? 'user' : 'student';
  const iidInCurrentPage = itemList.map(({ iid = '' }) => iid);

  const width = {
    name: '23%',
    code: '10%',
    birth: '5%',
    sex: '5%',
    jobPosition: '10%',
    org: '20%',
    phone: '10%',
    email: '20%',
    isStaff: '8%',
    action: '7%',
  };

  const columns = [
    {
      title: label.name,
      key: 'name',
      width: width.name,
      dataIndex: 'name',
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (name, item) => (
        <React.Fragment>
          <PreviewUserInDialog user={item} field="name" />
        </React.Fragment>
      ),
    },
    isLayoutVT && {
      title: label.iidCode,
      key: 'code',
      width: width.code,
      type: TYPE_OF_TABLE_DATA.TEXT,
      dataIndex: 'code',
      render: (code, item) => <PreviewUserInDialog user={item} field="code" />,
    },
    isLayoutVT && {
      title: label.birthday,
      key: 'birthday',
      width: width.birth,
      type: TYPE_OF_TABLE_DATA.DATE,
      dataIndex: 'birthday',
      render: (birthday, item) =>
        item && item.birthday && new Date(item.birthday * 1000).getFullYear(),
    },
    isLayoutVT && {
      title: label.sex,
      key: 'sex',
      width: width.sex,
      type: TYPE_OF_TABLE_DATA.TEXT,
      dataIndex: 'sex',
      render: (sex, item) => item && sexAsText(item.sex),
    },
    {
      title: 'Vị trí/chức vụ',
      key: 'positions',
      width: width.jobPosition,
      type: TYPE_OF_TABLE_DATA.TEXT,
      dataIndex: 'positions',
      render: (phone, item) =>
        item.positions && item.positions.length ? (
          <Positions item={item} textOnly={true} />
        ) : (
          '-'
        ),
    },
    {
      title: 'Đơn vị',
      key: 'organizations',
      width: width.org,
      type: TYPE_OF_TABLE_DATA.TEXT,
      dataIndex: 'organizations',
      render: (organizations, item) =>
        item.user_organizations && item.user_organizations.length
          ? lodashGet(item, '__expand.user_organizations').map((uOrg) =>
              uOrg ? `- ${uOrg.name}` : '',
            )
          : '-',
    },
  ]
    .concat(
      isLayoutVT
        ? [
            {
              title: label.phone,
              key: 'phone',
              width: width.phone,
              type: TYPE_OF_TABLE_DATA.TEXT,
              dataIndex: 'phone',
              render: (phone, item) => item && item.phone,
            },
            {
              title: label.mail,
              key: 'mail',
              width: width.email,
              type: TYPE_OF_TABLE_DATA.TEXT,
              dataIndex: 'mail',
              render: (mail, item) => (
                <PreviewUserInDialog user={item} field="mail" />
              ),
            },
          ]
        : [
            {
              title: 'Kinh nghiệm',
              key: 'experience',
              width: width.email,
              type: TYPE_OF_TABLE_DATA.TEXT,
              dataIndex: 'experience',
              render: (experience, item) => getExperience(item.startdate),
            },
          ],
    )
    .concat(
      mode === 'user' && [
        {
          title: 'Là người hướng dẫn',
          width: width.isStaff,
          key: 'mode',
          dataIndex: 'mode',
          render: (mode, item) => <ChangeStaffStatus node={item} noLabel />,
        },
        {
          title: 'Thao tác',
          key: 'action',
          width: width.action,
          type: TYPE_OF_TABLE_DATA.ACTION,
          dataIndex: 'action',
          render: (action, item) => (
            <React.Fragment>
              {props.renderActionCell ? props.renderActionCell(item) : null}
              &nbsp;
              <Link
                to={userPreviewLink(item, mode, 'view')}
                title={t1('preview')}
              >
                <Icon icon="edit" />
              </Link>
            </React.Fragment>
          ),
        },
      ],
    )
    .filter(Boolean);

  return (
    <CommonAntdTable
      dataSource={itemList}
      columns={columns}
      pagination={false}
      childrenColumnName={null}
      rowKey="iid"
      className={`learn-default`}
      key="result-table"
      name={resultTableFieldName}
      itemList={itemList}
      checkKey={checkKey}
      keysSave={keysSave}
      multiSelectable
      rowSelection={{
        selectedRowKeys,
        hideDefaultSelections: true,
        onChange: setSelectedRowKeys,
        selections: [
          {
            key: 'select_current_page',
            text: t1('select_data_in_current_page'),
            onSelect: () => {
              const currentSelectedRowKeys = Array.isArray(selectedRowKeys)
                ? selectedRowKeys
                : [];
              setSelectedRowKeys(
                currentSelectedRowKeys.concat(
                  iidInCurrentPage
                    .map((iid) => !currentSelectedRowKeys.includes(iid) && iid)
                    .filter(Boolean),
                ),
              );
            },
          },
          {
            key: 'invert_current_page',
            text: t1('invert_data_in_current_page'),
            onSelect: () => {
              setSelectedRowKeys(
                (Array.isArray(iidInCurrentPage)
                  ? iidInCurrentPage
                  : []
                ).filter((iid) => !selectedRowKeys.includes(iid)),
              );
            },
          },
          Array.isArray(selectedRowKeys) &&
            !!selectedRowKeys.length &&
            !selectedRowKeys.every((id) => iidInCurrentPage.includes(id)) && {
              key: 'remove_all',
              text: t1('remove_all_data_selected'),
              onSelect: () => {
                setSelectedRowKeys([]);
              },
            },
        ].filter(Boolean),
      }}
    />
  );
};

const mapStateToProps = createSelector(
  getThemeConfig,
  (themeConfig) => ({
    themeConfig,
  }),
);

export default connect(mapStateToProps)(ResultsEnterprise);
