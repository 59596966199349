import React from 'react';
import lodashGet from 'lodash.get';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { submit } from 'redux-form';
import { t1 } from 'translate';
import NodeNew from 'components/admin/node/new';
import endpoints from 'components/bdtx/training-phase/endpoints';
import schema from './schema';

const FORM_ID = 'additional_deployment';
const AdditionalDeploymentForm = ({
  trainingPhase,
  searchFormId,
  dispatch,
}) => {
  const submitForm = () => {
    dispatch(submit(FORM_ID));
  };
  const onSubmit = (e, formValues) => {
    e.preventDefault();
    e.stopPropagation();

    const orgs = lodashGet(formValues, 'apply_organizations') || [];
    if (!orgs.length) {
      return;
    }

    Modal.confirm({
      title: t1('do_you_want_to_deploy_for_chosen_organizations'),
      onOk: submitForm,
    });

    return;
  };

  const hiddenFields = {
    training_phase_iid: lodashGet(trainingPhase, 'iid'),
  };

  return (
    <NodeNew
      mode="new"
      schema={schema}
      hiddenFields={hiddenFields}
      formid={FORM_ID}
      alternativeApi={endpoints.bdtx_additional_deployment}
      searchFormId={searchFormId}
      caps={lodashGet(trainingPhase, 'caps') || []}
      excludeOrganizationIids={lodashGet(trainingPhase, 'apply_organizations')}
      submitButton={(formValues) => (
        <button
          onClick={(e) => onSubmit(e, formValues)}
          className="btn btn-primary m-t-15"
        >
          {t1('confirm_additional_deployment')}
        </button>
      )}
    />
  );
};

export default connect()(AdditionalDeploymentForm);
