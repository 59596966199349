import React from 'react';
import { getUrl } from 'routes/links/common';
import ReviewProgram from 'components/bdtx/review-program';
import ReviewExamStore from 'components/bdtx/review-exam-store';
import ReviewExamStoreMainStage from 'components/bdtx/review-exam-store/mainstage';
import BdtxChooseBdtxModulesForSchoolYear from 'components/bdtx/choose-modules';
import BdtxOverviewModuleMaterialsForSchoolYear from 'components/bdtx/overview-module-materials';
import BdtxMaterialSales from 'components/bdtx/material-sales';
import BdtxTrainingPhase from 'components/bdtx/training-phase';
import BdtxTrainingPhaseMainStage from 'components/bdtx/training-phase/mainstage';
import BdtxTrainingPhaseBySteps from 'components/bdtx/training-phase/steps';
import BdtxKPI from 'components/bdtx/kpi';
import BdtxModule from 'components/bdtx/module';
import BdtxReport from 'components/bdtx/report';
import MigrateEtepData from 'components/bdtx/migrate-etep-data';

export const bdtxBaseRoute = getUrl('bdtx');

export default [
  {
    componentId: 'bdtx-manage-modules',
    path: getUrl('bdtx/module:slash(|/):iid?'),
    component: BdtxModule,
    exact: false,
  },
  {
    componentId: 'bdtx-review-program',
    path: getUrl('review-program'),
    component: ReviewProgram,
    exact: true,
  },
  {
    componentId: 'bdtx-review-exam-store',
    path: getUrl('review-exam-store'),
    component: ReviewExamStore,
    exact: true,
  },
  {
    componentId: 'bdtx-review-exam-store-main-stage',
    path: getUrl('review-exam-store/:iid?/:action?'),
    component: ReviewExamStoreMainStage,
    exact: true,
  },
  {
    componentId: 'bdtx-choose-modules-for-school-year',
    path: getUrl('bdtx/choose-modules:slash(|/):targetUser(|[0-9a-zA-Z_-]*)'),
    component: BdtxChooseBdtxModulesForSchoolYear,
    exact: true,
  },
  {
    componentId: 'bdtx-overview-module-materials',
    path: getUrl(
      'bdtx/overview-module-materials:slash(|/):viewType(|[0-9a-zA-Z_-]*)',
    ),
    component: BdtxOverviewModuleMaterialsForSchoolYear,
    exact: true,
  },
  {
    componentId: 'bdtx-deal',
    path: getUrl('bdtx/deal'),
    component: BdtxMaterialSales,
    exact: false,
  },
  {
    componentId: 'bdtx-training-phase',
    path: getUrl('bdtx/training-phase'),
    component: BdtxTrainingPhase,
    exact: true,
  },
  {
    componentId: 'bdtx-training-phase-main-stage',
    path: getUrl('bdtx/training-phase/:iid?/:action?'),
    component: BdtxTrainingPhaseMainStage,
    exact: true,
  },
  {
    componentId: 'bdtx-training-phase-main-stage',
    path: getUrl('bdtx/training-phase/:iid?/steps/:step?'),
    component: BdtxTrainingPhaseBySteps,
    exact: true,
  },
  {
    componentId: 'bdtx-kpi',
    path: getUrl('bdtx/kpi/:action?'),
    component: BdtxKPI,
    exact: true,
  },
  {
    componentId: 'bdtx-report-tcnn',
    path: getUrl('bdtx/report/:reportType?/:action?'),
    component: BdtxReport,
    exact: true,
  },
  {
    componentId: 'bdtx-migrate-etep-to-bdtx',
    path: getUrl('bdtx/migrate-etep'),
    component: MigrateEtepData,
    exact: true,
  },
];
