import userIdentifiers from 'components/admin/group/schema/elements/filterset/filter/schema/userIdentifiers';

const schema = (formid, values) => {
  return {
    user_identifiers: userIdentifiers(),
  };
};

const ui = () => {
  return [
    {
      id: 'default',
      fields: ['user_identifiers'],
    },
  ];
};

export default {
  schema,
  ui,
};
