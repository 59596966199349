/**
 * Created by Peter Hoang Nguyen on 4/2/2017.
 */
import axios from 'axios';
import Store from 'store';
import actions from 'actions/node/creators';
import errorCodes from 'common/errorCodes';
import common from './Common';
import { logout } from 'actions/auth';
import {
  clearSystemMessage,
  loadSystemMessage,
  saveAppVersion,
  saveSessionEnded,
} from 'actions/app-info';
import { showSupportUserInfoNotificationHandler } from 'components/common/system/support-user-info-notification';
import checkAndHandleTwoFactorAuthenticationError from 'components/user/auth/two-factor-authentication/checkAndHandle2FAError';
import get from 'lodash.get';

import { getSupportTime } from 'utils/Util';
import CryptoJSAesDecrypt from 'common/security/Decrypt';
import lodashGet from 'lodash.get';

const PROGRESS_PROCESS = 0.8;

const appHasNewVersion = (newVersion) => {
  const storeState = Store.getState();
  const { appInfo } = storeState;
  const { version = null } = appInfo;

  return newVersion && newVersion !== version;
};

const checkAppVersion = (response) => {
  if (response && response.data && response.data.appVersion) {
    if (appHasNewVersion(response.data.appVersion)) {
      Store.dispatch(
        saveAppVersion({
          version: response.data.appVersion,
        }),
      );
    }
  }

  return response;
};

const checkSessionEnded = (response) => {
  if (
    response &&
    response.data &&
    response.data.err_code === errorCodes.ERR_INVALID_TOKEN_AND_UUID_PAIR
  ) {
    Store.dispatch(
      saveSessionEnded({
        sessionEnded: response.data.err_code,
      }),
    );
  }

  return response;
};

const getPassPhraseForDecryptingApiResponse = () => `d4a2152ac3918aa7`;

const decryptApiResponse = (response) => {
  const responseData = lodashGet(response, 'data');

  if (
    responseData &&
    lodashGet(responseData, 'encrypted_data') &&
    lodashGet(responseData, 'response_encrypted')
  ) {
    try {
      response.data = JSON.parse(
        CryptoJSAesDecrypt(
          getPassPhraseForDecryptingApiResponse(),
          lodashGet(responseData, 'encrypted_data'),
        ),
      );
    } catch (e) {
      console.log(e);
    }
  }

  return response;
};

const checkSystemMessage = (response) => {
  const hasSystemMessageFromAPI =
    response &&
    response.data &&
    response.data.success &&
    response.data.hasSystemMessage;
  const storeState = Store.getState();
  const { appInfo } = storeState;
  const { systemMessage = null } = appInfo;
  const hasSystemMessageFromStore = !!systemMessage;

  if (hasSystemMessageFromStore !== hasSystemMessageFromAPI) {
    if (hasSystemMessageFromAPI) {
      Store.dispatch(loadSystemMessage());
    } else {
      Store.dispatch(clearSystemMessage());
    }
  }

  return response;
};

const addEncryptApiResponseConfig = (config) => {
  if (config && !localStorage.ignoreEncryptApiResponse) {
    const params = lodashGet(config, 'params') || {};
    config.params = {
      ...params,
      _sand_peare: Math.random(),
    };
  }

  return config;
};

axios.interceptors.request.use(addEncryptApiResponseConfig, (error) =>
  Promise.reject(error),
);

axios.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(decryptApiResponse, (error) =>
  Promise.reject(error),
);

axios.interceptors.response.use(checkSessionEnded, (error) =>
  Promise.reject(error),
);
axios.interceptors.response.use(checkAppVersion, (error) =>
  Promise.reject(error),
);
axios.interceptors.response.use(checkSystemMessage, (error) =>
  Promise.reject(error),
);
getSupportTime() &&
  axios.interceptors.response.use(
    (response) => {
      showSupportUserInfoNotificationHandler(get(response, 'data.server_ts'));
      return response;
    },
    (error) => Promise.reject(error),
  );

class Request {
  constructor() {
    //Init cancelToken. Note: Must create cancel token for each request
    this.cancelToken = axios.CancelToken;
    this.source = this.cancelToken.source();
  }

  get(url, params, config, showProgress = true) {
    let { urlProcess, allParams } = common.getURL(url, params);
    // TODO : fake data
    if (url === '/api/note/list') {
      urlProcess = 'https://demo4431892.mockable.io/api/note/list';
      allParams = {};
    }
    if (showProgress && window.NProgress) {
      window.NProgress.start();
      window.NProgress.set(PROGRESS_PROCESS);
    }
    return axios
      .get(urlProcess, {
        params: allParams,
      })
      .then((response) => {
        const ret = response.data;
        if (!ret.success) checkAndHandleTwoFactorAuthenticationError(ret);
        if (showProgress && window.NProgress) {
          window.NProgress.done();
        }
        return ret;
      })
      .catch((error) => {
        if (showProgress && window.NProgress) {
          window.NProgress.done();
        }
      });
  }

  post(url, params, config, showProgress = true) {
    const { urlProcess, allParams } = common.getURL(url, params);
    const formPost = common.createFrom(allParams);
    if (showProgress && window.NProgress) {
      window.NProgress.start();
      window.NProgress.set(PROGRESS_PROCESS);
    }
    return axios
      .post(urlProcess, formPost, config)
      .then((response) => {
        const ret = response.data;
        if (!ret.success) checkAndHandleTwoFactorAuthenticationError(ret);
        if (window.NProgress) {
          window.NProgress.done();
        }
        return ret;
      })
      .catch((error) => {
        if (window.NProgress) {
          window.NProgress.done();
        }
      });
  }

  //Cancel request by token request
  actionCancel() {
    this.source.cancel('Operation canceled by the user.');
  }
}

export default new Request();
