import React from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import Results from './Results';
import schema from './search-schema';
import withUserInfo from 'common/hoc/withUserInfo';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';

const Layout = ({ userInfo }) => {
  const renderResultComponent = (items, props, objects, searchValues) => {
    return <Results items={items} searchValues={searchValues} />;
  };

  const renderNoResultComponent = () => {
    return <SimpleNoResult text="Chưa có dữ liệu" />;
  };

  return (
    <>
      <SearchWrapper
        formid="learning_report"
        renderResultsComponent={renderResultComponent}
        renderNoResultComponent={renderNoResultComponent}
        alternativeApi="/b-report/api/learning-report"
        schema={schema}
        userInfo={userInfo}
        alwaysShowResult
        autoSearchWhenStart={false}
      />
    </>
  );
};

export default withUserInfo(Layout);
