import { caphoc, chuong_trinh, targetUser } from 'components/common/elements';
import subTypes from 'configs/constants/org-sub-types';
import { isBo } from 'components/admin/user/utils';
import { CHUONG_TRINH_03 } from 'components/bdtx/configs';
import { t1 } from 'translate';
import { simpleOrganization } from 'components/admin/organization/schema/elements';
import { BGD_ORG_IID } from 'components/admin/organization/utils';

const schema = (formid, values, localStep, xpath, { userInfo }) => {
  let sgdIid = '';
  if (isBo(userInfo)) {
    sgdIid = BGD_ORG_IID;
  }

  return {
    code: {
      type: 'text',
      floatingLabelText: t1('module_code'),
      classWrapper: 'col-md-6',
    },
    sgd: simpleOrganization(
      {
        floatingLabelText: 'SGD',
        classWrapper: 'col-md-6',
        defaultValue: sgdIid,
      },
      {
        value: {
          includeRoot: 1,
          sub_type: [
            subTypes.TAPHUAN_SUBTYPE_SO_GD,
            subTypes.TAPHUAN_SUBTYPE_BO_GD,
          ],
        },
      },
      false,
      {
        value: '',
        label: 'Tất cả đơn vị',
      },
    ),
    chuong_trinh: chuong_trinh(
      {
        classWrapper: 'col-md-6',
      },
      {
        excludeChuongTrinhs: [CHUONG_TRINH_03],
        shortName: true,
      },
    ),
    target_user: targetUser({
      type: 'radio',
      classWrapper: 'col-md-3',
      inline: true,
    }),
    caps: caphoc({
      classWrapper: 'col-md-6',
    }),
    status: {
      type: 'multiCheckbox',
      floatingLabelText: t1('status'),
      floatingLabelFixed: true,
      options: [
        {
          value: 'approved',
          label: t1('approved'),
        },
        {
          value: 'queued',
          label: t1('queued'),
        },
      ],
      fullWidth: true,
      inline: true,
      classWrapper: 'col-md-3',
    },
  };
};

const ui = (step, values, themeConfig, xpath, formid, { userInfo = {} }) => {
  let fields = ['code', 'target_user', 'status', 'caps'];
  if (isBo(userInfo)) {
    fields = ['sgd', ...fields];
  }

  return [
    {
      id: 'id',
      fields: fields,
    },
  ];
};

export default {
  schema,
  ui,
};
