import {
  call,
  cancel,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  throttle,
} from 'redux-saga/effects';
import {
  buildExerciseStructureFromExerciseSelector,
  calculateExerciseResultSelector,
  convertQuestionTakeBetweenClientAndServerVersion,
  getAllQuestionUniqueIdsInExerciseSelector,
  getCurrentQuestionIdInExerciseInLearningScreen,
  getQuestionsWithFullInfoFromUniqueIdsInExercisesSelector,
  getSaveExerciseProgressParamsSelector,
  getSaveTakeParamsSelector,
  modes,
  questionFetchingModes,
  statuses,
  steps,
} from 'common/learn/exercise';
import { saveTakeRequest } from 'actions/learn/saga-creators';
import { getLearnItemInfoSelector, getProgressSelector } from 'common/learn';
import { getUserAnswersSelector } from 'common/learn/Question';
import { isQuestionDone } from 'common/question';
import {
  FINISH_REVIEW_EXERCISE,
  INIT_EXERCISE,
  REDO_EXERCISE,
  RESUME_EXERCISE,
  REVIEW_EXERCISE,
  START_EXERCISE,
  LOAD_PREVIOUS_TAKE,
  REDO_EXERCISE_WHEN_QUESTIONS_MISSING,
} from 'actions/learn/exercise/normal/saga-creators';
import {
  displayQuestionsCheckedResult,
  resetTickedQuestions,
  SAVE_ANSWER,
  saveAnswer,
  saveItemInfoToStore,
  saveItemQuestionInfo,
} from 'actions/learn';
import sagaActions from 'actions/saga-creators';
import Requester from 'common/network/http/Request';
import apiUrls from 'api-endpoints';
import { types as questionTypes } from 'components/admin/question/schema/question-types';
import { mapObject } from 'common/utils/object';
import { userInfoSelector } from 'common/selectors';
import setCurrentQuestionAndWaitTillFinished from './common/setCurrentQuestionAndWaitTillFinish';
import coreFlow from './coreFlow';
import lodashGet from 'lodash.get';
import nodeActions from 'actions/node/creators';
import finishExercise from './finishExercise';
import { doingStatuses } from 'common/learn/exercise';
import { isServerBusy } from 'utils/Util';
import { getNodeSelector } from 'components/admin/node/utils';

const shouldSaveProgressModes = [modes.NORMAL];
const shouldFetchProgressModes = [modes.NORMAL, modes.REVIEW];

let exerciseFlowTask = {};

function* cancelExistingExerciseFlow(itemIid) {
  if (exerciseFlowTask[itemIid]) {
    yield cancel(exerciseFlowTask[itemIid]);
  }
}
/**
 * If we redo an exercise, we wanna set the progress in the nav to zeros
 * But by default, since the tracker.php doesn't return the object for itemIid
 * if item's progress is null, we have to hack by adding "returnZerosForExercisesWithProgressEqualNull"
 * param so server can return this extra keys
 *
 * @param itemIid exercise Iid
 * @param courseIid course Iid
 * @param returnZerosForExercisesWithProgressEqualNull
 * @returns {IterableIterator<*|PutEffect<Action>|PutEffect<any>>}
 */
function* fetchProgress(
  itemIid,
  courseIid,
  returnZerosForExercisesWithProgressEqualNull,
) {
  const data = {
    tcos: itemIid,
    ciid: courseIid,
    children: 1,
    depth: 1,
  };

  if (returnZerosForExercisesWithProgressEqualNull)
    data.returnZerosForExercisesWithProgressEqualNull = 1;

  yield put(sagaActions.trackerProgressGet(data));
}

export function* saveProgressAndTake(
  itemIid,
  isFinished = false,
  updateToStoreAfterSuccess,
  displayMessageAfterSave,
  questionUniqueIdsToSave = [],
) {
  const getSaveExerciseProgressParams = yield select(
    getSaveExerciseProgressParamsSelector,
  );
  const saveProgressParams = yield call(getSaveExerciseProgressParams, itemIid);
  yield put(
    sagaActions.trackerProgressSave(
      saveProgressParams,
      updateToStoreAfterSuccess,
    ),
  );
  yield put(saveItemInfoToStore(itemIid, { lastLearnTime: Date.now() }));
  const getSaveTakeParams = yield select(getSaveTakeParamsSelector);
  const saveTakeParams = yield call(
    getSaveTakeParams,
    itemIid,
    isFinished,
    undefined,
    questionUniqueIdsToSave,
  );
  yield put(saveTakeRequest(itemIid, saveTakeParams, displayMessageAfterSave));
}

export function* saveFinalProgress(itemIid, result) {
  const courseIid = yield call(getCourseIid, itemIid);
  yield put(
    sagaActions.trackerProgressSave(
      {
        progress: [
          {
            tco_iid: itemIid,
            // p: result, // don't have to send p for 10/30 exercise because now it is calculated on server side
            cp: 100,
          },
        ],
        ciid: courseIid,
        doing_status: doingStatuses.FINISHED,
      },
      true,
    ),
  );
}

function* waitForStartAction(itemIid) {
  return yield take(
    (action) =>
      action.type === START_EXERCISE &&
      action.itemIid &&
      String(action.itemIid) === String(itemIid),
  );
}

function* waitForResumeAction(itemIid) {
  return yield take(
    (action) =>
      action.type === RESUME_EXERCISE &&
      action.itemIid &&
      String(action.itemIid) === String(itemIid),
  );
}

function* waitForRedoAction(itemIid) {
  return yield take(
    (action) =>
      action.type === REDO_EXERCISE &&
      action.itemIid &&
      String(action.itemIid) === String(itemIid),
  );
}

function* waitForReviewAction(itemIid) {
  return yield take(
    (action) =>
      action.type === REVIEW_EXERCISE &&
      action.itemIid &&
      String(action.itemIid) === String(itemIid),
  );
}

function* waitForFinishReviewAction(itemIid) {
  yield take(
    (action) =>
      action.type === FINISH_REVIEW_EXERCISE &&
      action.itemIid &&
      String(action.itemIid) === String(itemIid),
  );
}

function* cleanUp(itemIid) {
  yield put(saveItemInfoToStore(itemIid, { questions: null }));
}

function* showResult(itemIid, result, shouldShowResultDetail = true) {
  if (typeof result !== 'undefined') {
    yield put(saveItemInfoToStore(itemIid, { result }));
  }
  yield put(
    saveItemInfoToStore(itemIid, {
      step: steps.RESULT,
      shouldShowResultDetail,
    }),
  );
}

function* getCourseIid(itemIid) {
  return yield select((state) => state.learn.courseIid);
}

function* clearProgress(itemIid) {
  const courseIid = yield call(getCourseIid, itemIid);
  const userInfo = yield select(userInfoSelector);
  const userIid = userInfo && userInfo.iid;
  if (userIid && courseIid) {
    yield call(fetchProgress, itemIid, courseIid, true);
  }
}

function* redo(itemIid, redoAction) {
  const selectInfo = yield select(getLearnItemInfoSelector);
  const info = yield call(selectInfo, itemIid);

  // backup current item progress as old progress before cleaning up progress
  let highestScoreTakeHistoryProgress = null;
  if (lodashGet(info, 'keep_highest_score')) {
    highestScoreTakeHistoryProgress = yield call(
      getHighestScoreTakeHistory,
      itemIid,
    );
  }

  if (redoAction && redoAction.wrongQuestionOnly) {
    const getUserAnswers = yield select(getUserAnswersSelector);
    const userAnswers = yield call(getUserAnswers, itemIid);
    const questionUniqueIdsThatCorrect =
      userAnswers &&
      Object.keys(userAnswers).filter((key) => {
        const answer = userAnswers[key];
        return answer && answer.isCorrect;
      });
    yield put(
      displayQuestionsCheckedResult(itemIid, questionUniqueIdsThatCorrect),
    );
  } else {
    yield call(cleanUp, itemIid);
    if (shouldSaveProgressModes.includes(info.mode)) {
      yield call(clearProgress, itemIid);
    }
  }

  if (lodashGet(info, 'keep_highest_score')) {
    // set current item progress as old progress for later comparing with new result
    yield call(
      saveHighestScoreTakeProgress,
      itemIid,
      highestScoreTakeHistoryProgress,
    );
  }

  let questionUniqueId = null;
  if (redoAction) {
    ({ questionUniqueId } = redoAction);
  }
  yield call(
    setCurrentQuestionAndWaitTillFinished,
    itemIid,
    questionUniqueId,
    true,
  );
  yield put(saveItemInfoToStore(itemIid, { step: steps.MAIN }));

  yield call(cancelExistingExerciseFlow, itemIid);

  yield call(exerciseFlow, itemIid, true);
}

function* executeRedo(itemIid, redoAction, canResume) {
  const prepareResult = yield call(
    prepareExercise,
    itemIid,
    questionFetchingModes.REDO,
  );

  if (prepareResult) {
    yield call(redo, itemIid, redoAction);
  } else {
    yield call(showEndingScreen, itemIid, undefined, canResume);
  }
}

function* executeResume(itemIid, canResume) {
  const prepareResult = yield call(
    prepareExercise,
    itemIid,
    questionFetchingModes.RESUME,
  );

  if (prepareResult) {
    yield call(exerciseFlow, itemIid, false, true);
  } else {
    yield call(showEndingScreen, itemIid, undefined, canResume);
  }
}

function* review(itemIid) {
  yield call(setCurrentQuestionAndWaitTillFinished, itemIid, null, true);
  const getQuestionUniqueIds = yield select(
    getAllQuestionUniqueIdsInExerciseSelector,
  );
  const questionUniqueIds = yield call(getQuestionUniqueIds, itemIid);

  yield put(resetTickedQuestions(itemIid, questionUniqueIds));
  yield put(displayQuestionsCheckedResult(itemIid, questionUniqueIds));
  yield put(saveItemInfoToStore(itemIid, { step: steps.REVIEW }));
}

function* saveProgressAndTakeWhenAnswersChange(itemIid) {
  yield throttle(
    2, // fix a hotfix if user is doing the exercise too fast, it will not save some questions
    (action) =>
      action.type === SAVE_ANSWER && String(action.itemIid) === String(itemIid),
    function*(action) {
      const { questionIndex } = action;
      const isFinished = false;
      const updateToStoreAfterSuccess = false;
      const isOEQuestion =
        lodashGet(action, 'answer.type') == questionTypes.TYPE_OPEN_ENDED;
      const displayMessageAfterSave = isOEQuestion;

      const shouldSaveProgressAndTake = !isServerBusy() || isOEQuestion;
      if (!shouldSaveProgressAndTake) {
        return;
      }

      yield call(
        saveProgressAndTake,
        itemIid,
        isFinished,
        updateToStoreAfterSuccess,
        displayMessageAfterSave,
        [questionIndex],
      );
    },
  );
}

function* getPreviousTakes(itemIid) {
  const courseIid = yield call(getCourseIid, itemIid);
  const res = yield call(Requester.get, apiUrls.getTakeDetail, {
    course: courseIid,
    item_iid: itemIid,
    item_ntype: 'exercise',
  });

  if (!res.success) {
    throw new Error('Failed to fetch previous take');
  }

  const takes = res.result && res.result.answers;
  if (!takes) {
    return takes;
  }

  return res.result;
}

function getTakeAnswers(take) {
  return mapObject(take.answers, (exerciseTake) =>
    convertQuestionTakeBetweenClientAndServerVersion(exerciseTake, true),
  );
}

function* checkCanResume(itemIid, previousTakes) {
  const selectInfo = yield select(getLearnItemInfoSelector);
  const info = yield call(selectInfo, itemIid);
  const options = lodashGet(info, 'options');

  if (lodashGet(options, 'can_resume')) {
    return true;
  }

  // check if has api question
  // this is pixelz logic
  const getQuestionUniqueIds = yield select(
    getAllQuestionUniqueIdsInExerciseSelector,
  );
  const questionUniqueIds = yield call(getQuestionUniqueIds, itemIid);
  const getQuestionsWithFullInfoFromUniqueIdsInExercises = yield select(
    getQuestionsWithFullInfoFromUniqueIdsInExercisesSelector,
  );
  const questionsWithFullInfoFromUniqueIdsInExercises = yield call(
    getQuestionsWithFullInfoFromUniqueIdsInExercises,
    itemIid,
    questionUniqueIds,
  );
  if (
    !Array.isArray(questionsWithFullInfoFromUniqueIdsInExercises) ||
    questionsWithFullInfoFromUniqueIdsInExercises.length === 0
  ) {
    return false;
  }
  try {
    return questionsWithFullInfoFromUniqueIdsInExercises.some((question) => {
      if (question.type === questionTypes.TYPE_API) {
        const exerciseTake = previousTakes && previousTakes[question.iid];
        const answer = exerciseTake && exerciseTake.answer;
        return !isQuestionDone(question.type, answer);
      }
      return false;
    });
  } catch (ex) {
    console.log(ex);
  }
  return false;
}

function* savePreviousTakeAnswersToStore(itemIid, previousTakes) {
  if (!previousTakes) {
    return;
  }
  yield Object.keys(previousTakes).map((questionIid) =>
    put(saveAnswer(itemIid, questionIid, previousTakes[questionIid])),
  );
}

function* executeReview(itemIid, canResume) {
  const prepareResult = yield call(
    prepareExercise,
    itemIid,
    questionFetchingModes.REVIEW,
  );

  if (prepareResult) {
    yield call(review, itemIid);

    yield call(waitForFinishReviewAction, itemIid);
  }

  yield call(showEndingScreen, itemIid, undefined, canResume);
}

export function* showEndingScreen(itemIid, result, canResume) {
  const selectInfo = yield select(getLearnItemInfoSelector);
  const info = yield call(selectInfo, itemIid);

  yield call(showResult, itemIid, result);

  let raceFlow = {
    waitForRedo: call(waitForRedoAction, itemIid, info),
    waitForReview: call(waitForReviewAction, itemIid, canResume),
  };

  if (canResume) {
    raceFlow.waitForResume = call(waitForResumeAction, itemIid);
  }

  const { waitForRedo, waitForReview, waitForResume } = yield race(raceFlow);

  if (waitForRedo) {
    yield call(executeRedo, itemIid, waitForRedo, canResume);
  }

  if (waitForReview) {
    yield call(executeReview, itemIid, canResume);
  }

  if (waitForResume) {
    yield call(executeResume, itemIid, canResume);
  }
}

function* prepareExercise(
  itemIid,
  questionFetchingMode = questionFetchingModes.NEW,
  isPreviewInSyllabusEditor,
) {
  const learnItemInfoSelector = yield select(getLearnItemInfoSelector);
  const learnItemInfo = yield call(learnItemInfoSelector, itemIid);
  const { piid, course_iid: courseIid, mode } = learnItemInfo;
  const isPreview = mode === modes.PREVIEW;

  const { waitUntilSuccess } = yield call(
    fetchExerciseAndWaitUntilDone,
    itemIid,
    piid,
    courseIid,
    questionFetchingMode,
    isPreview,
    isPreviewInSyllabusEditor,
  );

  if (waitUntilSuccess) {
    yield call(buildExerciseStructure, itemIid);
  }

  return waitUntilSuccess;
}

function* fetchExerciseAndWaitUntilDone(
  itemIid,
  piid,
  courseIid,
  mode,
  isPreview = false,
  isPreviewInSyllabusEditor = false,
) {
  yield put(
    nodeActions.fetchNode({
      iid: itemIid,
      piid,
      courseIid,
      depth: -1,
      mode,
      is_preview: isPreview,
      apiUrl: apiUrls.fetch_exercise,
      learning: 1,
      loadingStatusKey: 'exercise_fetching',
      turnOffNotifications: isPreviewInSyllabusEditor,
    }),
  );

  return yield race({
    waitUntilSuccess: call(waitUntilFetchingExerciseDone, itemIid),
    waitUntilFailed: call(waitUntilFetchingExerciseFailed, itemIid),
  });
}

function* waitUntilFetchingExerciseDone(itemIid) {
  return yield take(
    (action) =>
      action.type === 'TREE_UPSERT_NODE' &&
      String(action.data.iid) === String(itemIid),
  );
}

function* waitUntilFetchingExerciseFailed(itemIid) {
  return yield take(
    (action) =>
      action.type === 'TREE_UPSERT_NODE_FAILED' &&
      String(action.data.iid) === String(itemIid),
  );
}

const getQuestionUniqueId = (question) => question && question.iid;

function* buildExerciseStructure(itemIid) {
  const selectInfo = yield select(getLearnItemInfoSelector);
  const info = yield call(selectInfo, itemIid);

  const builtStructureSelector = yield select(
    buildExerciseStructureFromExerciseSelector,
  );
  let builtStructure = yield call(
    builtStructureSelector,
    info.iid,
    getQuestionUniqueId,
    info,
  );

  const getNode = yield select(getNodeSelector);
  let node = yield call(getNode, itemIid, null, -1);

  if (node.timeRemaining) {
    builtStructure.timeRemaining = node.timeRemaining;
  }

  yield put(saveItemInfoToStore(itemIid, builtStructure));
}

function* fetchPreviousTakeAndWaitUntilDone(itemIid) {
  while (true) {
    try {
      yield put(
        saveItemInfoToStore(itemIid, { previousTakeLoadingError: false }),
      );
      return yield call(getPreviousTakes, itemIid);
    } catch (error) {
      yield put(
        saveItemInfoToStore(itemIid, { previousTakeLoadingError: true }),
      );

      // wait until user click retry button
      yield take(
        (action) =>
          action.type === LOAD_PREVIOUS_TAKE &&
          action.itemIid &&
          String(action.itemIid) === String(itemIid),
      );
    }
  }
}

export function* saveProgressAndTakeWhenAnswersChangeWithCoreFlow(itemIid) {
  yield race({
    saveProgressAndTakeWhenAnswersChange: call(
      saveProgressAndTakeWhenAnswersChange,
      itemIid,
    ),
    coreFlow: call(coreFlow, itemIid),
  });
}

function* getHighestScoreTakeHistory(itemIid) {
  const courseIid = yield call(getCourseIid, itemIid);

  const res = yield call(
    Requester.get,
    '/take/api/highest-score-take-history',
    {
      exercise_iid: itemIid,
      course_iid: courseIid,
    },
  );

  return {
    cp: 100,
    p: lodashGet(res, 'result.dbScore'),
  };
}

function* saveHighestScoreTakeProgress(
  itemIid,
  highestScoreTakeHistoryProgress,
) {
  if (highestScoreTakeHistoryProgress) {
    yield put(
      saveItemInfoToStore(itemIid, { highestScoreTakeHistoryProgress }),
    );
  }
}

function* backupHighestScoreTakeProgress(itemIid) {
  const highestScoreTakeHistoryProgress = yield call(
    getHighestScoreTakeHistory,
    itemIid,
  );

  if (highestScoreTakeHistoryProgress) {
    yield call(
      saveHighestScoreTakeProgress,
      itemIid,
      highestScoreTakeHistoryProgress,
    );
  }
}

function* exerciseFlow(
  itemIid,
  isRedo = false,
  isResume = false,
  showResultFirst = false,
) {
  yield put(saveItemInfoToStore(itemIid, { status: statuses.STARTED }));
  const selectInfo = yield select(getLearnItemInfoSelector);
  const info = yield call(selectInfo, itemIid);
  const previousTakes = yield call(fetchPreviousTakeAndWaitUntilDone, itemIid);
  const noStartScreen = info.no_start_screen;
  const inlineExercise = info.inlineExercise;
  const isPreviewInSyllabusEditor = info.isPreviewInSyllabusEditor;

  if (previousTakes && previousTakes.syllabus_has_updated_for_kgg) {
    yield put(
      saveItemInfoToStore(itemIid, {
        syllabus_has_updated_for_kgg:
          previousTakes.syllabus_has_updated_for_kgg,
      }),
    );
  }

  if (previousTakes) {
    let param = { takeId: previousTakes.takeId };

    if (info.is_on_dlow) {
      param = {
        ...param,
        dlow_preview_link: previousTakes.dlow_preview_link,
        dlow_stats: previousTakes.dlow_stats || {},
      };
    }

    yield put(saveItemInfoToStore(itemIid, param));
  }

  const previousAnswers = previousTakes ? getTakeAnswers(previousTakes) : null;
  const canResume = yield call(checkCanResume, itemIid, previousAnswers);
  const courseIid = yield call(getCourseIid, itemIid);

  if (info.mode === modes.REVIEW) {
    yield call(savePreviousTakeAnswersToStore, itemIid, previousAnswers);
    yield call(executeReview, itemIid, canResume);
    return;
  }

  if (!isRedo && lodashGet(info, 'keep_highest_score')) {
    // when redo, we have already backed up old progress
    yield call(backupHighestScoreTakeProgress, itemIid);
  }

  if (shouldFetchProgressModes.includes(info.mode)) {
    yield call(fetchProgress, itemIid, courseIid);
  }

  if (!isRedo && !isResume) {
    yield call(savePreviousTakeAnswersToStore, itemIid, previousAnswers);
    if (previousAnswers) {
      if (canResume || inlineExercise) {
        // yield put(saveItemInfoToStore(itemIid, { step: steps.NOT_CONTINUED }));
        // yield race({
        //   waitForAndExecuteResume: call(waitForAndExecuteResume, itemIid),
        //   waitForAndExecuteRedo: call(waitForAndExecuteRedo, itemIid),
        // });
        const prepareExerciseResult = yield call(
          prepareExercise,
          itemIid,
          questionFetchingModes.RESUME,
        );

        if (prepareExerciseResult) {
          // sometimes we want to show result screen first, even exercise's setting has can_resume = true
          // for example when user restores take history, we init exercise again but we want to show result screen first
          if (showResultFirst) {
            yield call(showEndingScreen, itemIid, undefined, canResume);
            return;
          }

          yield call(exerciseFlow, itemIid, false, true);
        } else {
          yield call(showEndingScreen, itemIid, undefined, canResume);
        }

        return;
      } else {
        // auto finish the old exercise if exercise setting does not allow resuming and it has not been finished
        // this case happen when for example user is doing the exercise and suddenly leave the exercise without clicking Finish button (close the browser, F5..)
        if (
          previousAnswers &&
          !canResume &&
          previousTakes.doing_status !== doingStatuses.FINISHED
        ) {
          const shouldSaveProgress = true;
          yield call(
            finishExercise,
            shouldSaveProgress,
            itemIid,
            canResume,
            inlineExercise,
          );
        } else {
          yield call(showEndingScreen, itemIid, undefined, canResume);
        }

        return;
      }
    } else {
      if (noStartScreen || inlineExercise) {
        yield call(
          prepareExercise,
          itemIid,
          questionFetchingModes.NEW,
          isPreviewInSyllabusEditor,
        );
      } else {
        yield put(saveItemInfoToStore(itemIid, { step: steps.NOT_STARTED }));
        while (true) {
          yield call(waitForStartAction, itemIid);
          const prepareExerciseResult = yield call(
            prepareExercise,
            itemIid,
            questionFetchingModes.NEW,
          );

          if (prepareExerciseResult) {
            break;
          }
        }
      }
    }
  }

  yield put(saveItemInfoToStore(itemIid, { step: steps.MAIN }));

  const questionUniqueId = getCurrentQuestionIdInExerciseInLearningScreen();
  yield put(
    saveItemQuestionInfo(itemIid, questionUniqueId, { isTouched: true }),
  );

  const shouldSaveProgress = shouldSaveProgressModes.includes(info.mode);
  if (shouldSaveProgress) {
    yield call(saveProgressAndTakeWhenAnswersChangeWithCoreFlow, itemIid);
  } else {
    yield call(coreFlow, itemIid);
  }

  yield call(
    finishExercise,
    shouldSaveProgress,
    itemIid,
    canResume,
    inlineExercise,
  );
}

export default function* exerciseFlowSaga() {
  exerciseFlowTask = {};

  yield takeEvery(INIT_EXERCISE, function*(action) {
    const { itemIid, info, showResultFirst } = action;
    yield put(saveItemInfoToStore(itemIid, info));

    yield call(cancelExistingExerciseFlow, itemIid);

    exerciseFlowTask[itemIid] = yield fork(
      exerciseFlow,
      itemIid,
      false,
      false,
      showResultFirst,
    );
  });

  // special handling for redoing exercise when question in the take is missing
  // always listen to this action and execute redo when action is dispatched
  yield takeEvery(REDO_EXERCISE_WHEN_QUESTIONS_MISSING, function*(action) {
    yield call(executeRedo, action.itemIid);
  });
}
