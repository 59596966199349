import './stylesheet.scss';

import React from 'react';
import { listVariants } from 'components/admin/video/schema/blocks/list';
import ListOrderedText from './list-ordered-text';
import ListUnOrderedText from './list-unordered-text';
import ListDefinition from './list-definition';
import ListDefinitionInTable from './list-definition-in-table';

const BlockList = ({ item }) => {
  if (item.block_list) {
    if (item.variant == listVariants.LIST_ORDERED_TEXT || !item.variant) {
      return <ListOrderedText item={item} />;
    } else if (item.variant == listVariants.LIST_UNORDERED_TEXT) {
      return <ListUnOrderedText item={item} />;
    } else if (item.variant == listVariants.LIST_DEFINITION) {
      return <ListDefinition item={item} />;
    } else if (item.variant == listVariants.LIST_DEFINITION_IN_TABLE) {
      return <ListDefinitionInTable item={item} />;
    }
  }

  return null;
};

export default BlockList;
