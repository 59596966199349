/**
 * Created by hungvo on 04/05/2017.
 */
import { nonAccentVietnamese, unaccentVietnamese } from './vn';
import { t1 } from 'translate';

// Paul Williams Smith becomes 'PS'
export const initials = (str, cap = true) => {
  if (!str) return '';

  const tmp = str.split(' ');
  const first = tmp[0][0];
  let last = '';
  if (tmp.length > 1) {
    last = tmp[tmp.length - 1][0];
  }
  let ret = `${first}${last}`;
  if (cap) ret = ret.toUpperCase();
  return ret;
};

export const randColor = () => {
  const hex = '0123456789ABCDEF'.split('');
  let color = '#';
  let i = 0;
  for (i; i < 6; i += 1) {
    color += hex[Math.floor(Math.random(Date.now()) * 16)];
  }
  return color;
};

export const breadCrumb = (str, len, showDot = true) => {
  if (!str) return '';

  if (!str || str.length <= len) {
    return str;
  }

  const ret = str.substring(0, len);

  if (!showDot) {
    return ret;
  }

  return `${ret} ...`;
};

export const wordBreadcrumb = (string, length, addTralingDots = true) => {
  if (!string) {
    return '';
  }
  const words = string.split(' ');

  if (words.length <= length) {
    return string;
  }
  let result = '';
  for (let i = 0; i < length; i += 1) {
    result += `${words[i]} `;
  }
  return addTralingDots ? `${result} ...` : result;
};

export const getMatches = (string, regex, index = 1) => {
  const matches = [];
  let match = regex.exec(string);
  while (match) {
    matches.push(match[index]);
    match = regex.exec(string);
  }
  return matches;
};

// this is used in case you cannot sure what inside subString, otherwise use String.prototype.replace(regex, newSubString) instead
export const replaceAll = (originalString, subString, newSubString) => {
  if (
    typeof originalString !== 'string' ||
    typeof subString !== 'string' ||
    typeof newSubString !== 'string'
  ) {
    throw new Error(
      `both originalString, subString, and newSubString must have type string, you give ${originalString}, ${subString}, ${newSubString}`,
    );
  }
  let result = originalString;
  while (true) {
    const before = result;
    result = result.replace(subString, newSubString);
    if (result === before) break;
  }
  return result;
};

// https://gist.github.com/mathewbyrne/1280286
export const slugify = (text, transformToCase /* 'lower' or 'upper' */) => {
  if (!text) return '';

  // ret = ret.replace(/[^a-z0-9A-Z_]/g, '-');
  // return ret;

  const ret = unaccentVietnamese(text)
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^a-z0-9A-Z_\.@]/g, '-') // all non-chars & non [._@] to -
    // .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, ''); // Trim - from start of text
  // .replace(/-+$/, '');            // Trim - from end of text

  if (transformToCase === 'lower') return ret.toLowerCase();
  else if (transformToCase === 'upper') return ret.toUpperCase();
  return ret;
};

export function arrayToString(array, key) {
  let res = '';
  if (!array) return res;

  array.forEach((value) => {
    if (typeof value === 'string') {
      res += `${value}, `;
    } else if (typeof value === 'object' && value.hasOwnProperty(key)) {
      res += `${value[key]}, `;
    }
  });
  return res.length >= 2 ? res.substring(0, res.length - 2) : res;
}

export const stringifyFromValue = (value) => {
  let string = '';
  if (!value) {
    return string;
  }
  const type = typeof value;
  if (!['array', 'object'].includes(type)) {
    return t1(value);
  }

  if (Array.isArray(value)) {
    value.forEach((val, index) => {
      if (Array.isArray(val)) {
        string += '[';
      } else if (typeof val === 'object') {
        string += '{';
      }
      string += stringifyFromValue(val);
      if (Array.isArray(val)) {
        string += ']';
      } else if (typeof val === 'object') {
        string += '}';
      }
      if (value.length > index + 1) {
        string += ', ';
      }
    });
  } else if (type === 'object') {
    Object.keys(value).forEach((key, index) => {
      if (Array.isArray(value[key])) {
        string += '[';
      } else if (typeof value[key] === 'object') {
        string += '{';
      }
      string += stringifyFromValue(value[key]);
      if (Array.isArray(value[key])) {
        string += ']';
      } else if (typeof value[key] === 'object') {
        string += '}';
      }
      if (value.length > index + 1) {
        string += ', ';
      }
    });
  }
  return string;
};

export const stripHTML = (html) => {
  if (!html) return '';

  let ret = '';
  if (document) {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    ret = tmp.textContent || tmp.innerText || '';
  } else ret = html.replace(/<(?:.|\n)*?>/gm, '');

  return ret.trim();
};

export const isHTML = (str) => {
  var a = document.createElement('div');
  a.innerHTML = str;

  for (var c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType == 1) {
      return true;
    }
  }

  return false;
};

export const isEmailString = (str) =>
  /^[_a-zA-Z0-9-]+(.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+\.(.[a-zA-Z0-9-]+)+$/.test(
    str,
  );

export const removeCharacters = (str, fromPos, length) => {
  return str.slice(0, fromPos) + str.slice(fromPos + length);
};

const defaultTextMapping = [
  {
    replaceKey: 'Phòng giáo dục và đào tạo',
    replaceTo: 'PGD',
  },
  {
    replaceKey: 'Sở giáo dục và đào tạo',
    replaceTo: 'SGD',
  },
  {
    replaceKey: 'Phòng giáo dục',
    replaceTo: 'PGD',
  },
  {
    replaceKey: 'Sở giáo dục',
    replaceTo: 'SGD',
  },
  {
    replaceKey: 'GDNN-GDTX',
    replaceTo: 'GDNN - GDTX',
  },
  {
    replaceKey: 'GDNN- GDTX',
    replaceTo: 'GDNN - GDTX',
  },
  {
    replaceKey: 'GDNN -GDTX',
    replaceTo: 'GDNN - GDTX',
  },
  {
    replaceKey: 'SGD Thành Phố',
    replaceTo: 'SGD TP.',
  },
  {
    replaceKey: 'SGD Tỉnh',
    replaceTo: 'SGD T.',
  },
  {
    replaceKey: 'PGD Thành phố',
    replaceTo: 'PGD TP.',
  },
  {
    replaceKey: 'PGD Huyện',
    replaceTo: 'PGD H.',
  },
  {
    replaceKey: 'PGD Thị Xã',
    replaceTo: 'PGD TX.',
  },
  {
    replaceKey: 'PGD Thị trấn',
    replaceTo: 'PGD TT.',
  },
  {
    replaceKey: 'GDNN - GDTX thành phố',
    replaceTo: 'GDNN - GDTX TP.',
  },
  {
    replaceKey: 'GDNN - GDTX thị xã',
    replaceTo: 'GDNN - GDTX TX.',
  },
  {
    replaceKey: 'GDNN - GDTX thị trấn',
    replaceTo: 'GDNN - GDTX TT.',
  },
  {
    replaceKey: 'GDNN - GDTX huyện',
    replaceTo: 'GDNN - GDTX H.',
  },
  {
    replaceKey: 'Trung tâm Giáo dục thường xuyên tỉnh',
    replaceTo: 'TT GDTX T.',
  },
  {
    replaceKey: 'Trung tâm Giáo dục thường xuyên huyện',
    replaceTo: 'TT GDTX H.',
  },
  {
    replaceKey: 'Trung tâm Giáo dục thường xuyên thị xã',
    replaceTo: 'TT GDTX TX.',
  },
  {
    replaceKey: 'TT Giáo dục nghề nghiệp - giáo dục thường xuyên',
    replaceTo: 'TT GDNN - GDTX',
  },
  {
    replaceKey: 'TT Giáo dục nghề nghiệp - giáo dục thường xuyên',
    replaceTo: 'TT GDNN - GDTX',
  },
  {
    replaceKey: 'Trung Tâm GDNN - GDTX thành phố',
    replaceTo: 'TT GDNN-GDTX TP.',
  },
  {
    replaceKey: 'Trung Tâm GDNN - GDTX tỉnh',
    replaceTo: 'TT GDNN-GDTX T.',
  },
  {
    replaceKey: 'Trung Tâm GDNN - GDTX huyện',
    replaceTo: 'TT GDNN-GDTX H.',
  },
  {
    replaceKey: 'Trung Tâm GDNN - GDTX thị xã',
    replaceTo: 'TT GDNN-GDTX TX.',
  },
  {
    replaceKey: 'Trung Tâm GDNN - GDTX',
    replaceTo: 'TT GDNN-GDTX',
  },
];

export const shortTextByStringMapping = (text = '', textMapping = []) => {
  if (!text) {
    return text;
  }

  let shortText = text;
  [...defaultTextMapping, ...textMapping].forEach((data) => {
    const regEx = new RegExp(data.replaceKey, 'ig');
    shortText = shortText.replace(regEx, data.replaceTo);
  });

  return shortText;
};

/**
 * Generate a hash from a string
 * @param s
 * @returns {*}
 */
export const hashCode = (s) =>
  s.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

const restrictionWordsDefault = [
  'Đã xem',
  'Đã học xong',
  'Đã hoàn thành bài',
  'Đã hoàn thành',
  'OK',
  'Okay',
  'Okie',
  'Đã hiểu',
  'Tôi đã hiểu',
  'Tôi đã đọc',
  'Đã xong',
  'Đã học',
  'Xem xong',
  'Đã xem tài liệu',
  'Đã đọc tài liệu',
  'Đã nghiên cứu tài liệu',
  'Đã xem và lưu lại',
];

export const getRestrictionWords = (content = '', restrictionWords = []) => {
  if (!content) {
    return content;
  }

  const characters = ['.', ',', '!', '?'];

  const restrictionWordsCombined = [
    ...restrictionWordsDefault,
    ...(Array.isArray(restrictionWords) ? restrictionWords : []),
  ].map((word) => nonAccentVietnamese(word).toLowerCase());

  let sentences = [];
  restrictionWordsCombined.forEach((word) => {
    sentences.push(word);
    sentences = sentences.concat(
      characters.map((character) => `${word}${character}`),
    );
  });

  return sentences.filter(
    (word) => nonAccentVietnamese(content.trim()).toLowerCase() === word,
  );
};

export const splitStringToArrayBySeparators = (
  string,
  separators = ['\\n'],
) => {
  if (!string || !separators || !separators.length) {
    return string;
  }

  const regex = new RegExp(`[${separators.join('')}]+`);
  const values = string.split(regex);

  return values.map((value) => value.trim()).filter(Boolean);
};

export const splitNewLine = (text, useRegx = true) => {
  if (!text) {
    return [];
  }

  const textSplit = useRegx ? text.split(/\n/) : text.split('\\n');

  return textSplit.map((t) => t.trim());
};

export const newlineToBr = (text) => {
  return splitNewLine(text, false).join('<br/>');
};

/**
 * đôi khi server quy định string 'false' hoặc '0' là giá trị false
 * mặc dù javascript tính string 'false' và '0' là truthy
 *
 * @param str
 * @returns {boolean}
 */
export const getValueOfBooleanString = (str) => {
  if (['false', '0'].includes(str)) {
    return false;
  }

  return Boolean(str);
};
const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
};

export const generateRandomStrContainingOnlyDigits = (length = 4) => {
  const min = Math.pow(10, length - 1);
  const max = Math.pow(10, length) - 1;
  return getRandomInt(min, max);
};
export const generateRandomStr = (length = 6, digitsOnly = false) => {
  if (digitsOnly) return generateRandomStrContainingOnlyDigits(length);
  else
    return Math.random()
      .toString(36)
      .substr(2, length);
};
