import React from 'react';
import { t1 } from 'translate';
import { steps } from 'common/learn/exercise';

const NormalExerciseNotStarted = ({
  step = '',
  onStartButtonClick = null,
  onResumeButtonClick = null,
  onRedoButtonClick = null,
  isExerciseLoading = false,
}) => (
  <div className="test-result-wrapper learn-content-border">
    <div className="quiz-content text-center">
      <div className="test-result-wrapper__take-form">
        <span className="exercise-icon ve-write" />
        <h3 className="test-result-wrapper__title">{t1('exercise')}</h3>
        {step === steps.NOT_STARTED && (
          <button
            className="btn btn-secondary"
            disabled={isExerciseLoading}
            onClick={onStartButtonClick}
          >
            {t1('take_exercise')}
          </button>
        )}
        {step === steps.NOT_CONTINUED && (
          <React.Fragment>
            <button
              className="btn-primary"
              disabled={isExerciseLoading}
              onClick={onResumeButtonClick}
            >
              {t1('continue')}
            </button>
            <button
              className="btn btn-filled"
              disabled={isExerciseLoading}
              onClick={onRedoButtonClick}
            >
              {t1('redo')}
            </button>
          </React.Fragment>
        )}
      </div>
    </div>
  </div>
);

export default NormalExerciseNotStarted;
