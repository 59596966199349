import { plannedTrainingStatuses } from 'configs/constants/user';

// same as PHP
export const SYSTEM_USER_NAMES = [
  've',
  'cs',
  'root',
  ...(window.LIST_CS_CODE || []),
];

export const isPlannedUserInactive = (user, trainingPlanIid) => {
  if (
    !trainingPlanIid ||
    !user.planned_training_data ||
    !user.planned_training_data.length
  ) {
    return false;
  }

  const inactivePlannedTraining = user.planned_training_data.find(
    (plannedTraining) =>
      plannedTraining.training_plan_iid == trainingPlanIid &&
      plannedTraining.status == plannedTrainingStatuses.INACTIVE,
  );

  return !!inactivePlannedTraining;
};

// same as PHP
export const isSystemUser = (userCode) => SYSTEM_USER_NAMES.includes(userCode);
