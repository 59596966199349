import React from 'react';
import { useDrop } from 'react-dnd';
import { Tag, Tooltip } from 'antd';

const FixedItem = ({
  className,
  accept = [],
  answers = [],
  onDrop,
  handleItemChildrenClick,
  disabled,
  isAnswerCorrect,
}) => {
  const cssClass = 'mddm-question-fixed-item';
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: accept,
    drop: !disabled && onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;
  const componentClassName = `${className || ''} ${cssClass} ${
    isActive ? 'active' : ''
  }`;

  return (
    <span ref={drop} className={componentClassName}>
      {answers.length > 0 && (
        <>
          {answers.map((item) => {
            return (
              <span
                className="cursor-pointer mddm-tag"
                onClick={() => {
                  !disabled && handleItemChildrenClick(item);
                }}
              >
                {item.content}
              </span>
            );
          })}
        </>
      )}
      {disabled && (
        <span
          className={`question-status-icon ${
            !isAnswerCorrect ? 've-close-outline' : 've-check'
          }`}
        />
      )}
    </span>
  );
};

export default FixedItem;
