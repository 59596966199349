import React from 'react';
import Loadable from 'react-loadable';
import Loading from './Loading';

const AsyncLoader_Loadable = ({ loader, ...rest }) =>
  Loadable({
    loader,
    loading: Loading,
    ...rest,
  });

export default AsyncLoader_Loadable;
