// const fields = ['name'];
import { t1 } from 'translate';
import { birthday, mail, phone } from '../../admin/user/schema/elements';
import datetimeFormat from '../../timetable_v2/configs';
import get from 'lodash.get';
import { required } from 'common/validators';
import LayoutFreestyle from './layoutFreestyle';
import { attachments } from 'schema-form/lib';

const schema = (
  formid,
  values,
  step,
  xpath,
  { organizationSubTypes },
  domainInfo,
) => {
  return {
    name: {
      type: 'text',
      floatingLabelText: 'Họ và tên',
      errorText: '',
      readOnly: true,
    },
    birthday: birthday(
      {
        floatingLabelText: 'Ngày, tháng, năm sinh',
        formatDate: datetimeFormat.DATE_FORMAT,
        gmt: '+07:00',
        readOnly: true,
      },
      get(domainInfo, 'conf'),
    ),
    mail: {
      type: 'text',
      floatingLabelText: 'Thư điện tử',
      errorText: '',
      readOnly: true,
    },
    phone: {
      type: 'text',
      floatingLabelText: 'Số điện thoại',
      errorText: '',
      readOnly: true,
    },
    reflection_send_to_organization_type: {
      floatingLabelText: t1('reflection_send_to_organization_type'),
      validate: [
        required(t1('reflection_send_to_organization_type_cannot_be_empty')),
      ],
      type: 'multiCheckbox',
      inline: true,
      options: Array.isArray(organizationSubTypes) ? organizationSubTypes : [],
    },
    reflection_name: {
      type: 'text',
      floatingLabelText: t1('reflection_name'),
      validate: [required(t1('reflection_name_cannot_be_empty'))],
    },
    reflection_description: {
      type: 'text',
      multiLine: true,
      rows: 4,
      floatingLabelText: t1('reflection_description'),
      validate: [required(t1('reflection_description_cannot_be_empty'))],
    },
    reflection_attachments: attachments({
      label: t1('reflection_attachments'),
      floatingLabelText: t1('reflection_attachments'),
    }),
  };
};

const ui = (
  step,
  values,
  themeConfig,
  xpath,
  formid,
  { organizationSubTypes },
) => [
  {
    id: 'default',
    fields: [
      'name',
      'birthday',
      'mail',
      'phone',
      Array.isArray(organizationSubTypes) &&
        !!organizationSubTypes.length &&
        'reflection_send_to_organization_type',
      'reflection_name',
      'reflection_description',
      'reflection_attachments',
    ].filter(Boolean),
  },
];

export default {
  schema,
  ui,
  layout: { component: LayoutFreestyle, freestyle: 1 },
};
