import {
  BEGIN_UPLOAD_FILE,
  FINISH_UPLOAD_FILE,
  DEFAULT_SUBMISSION_KEY,
} from 'actions/attachment';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case BEGIN_UPLOAD_FILE:
      return { ...state, [action.key || DEFAULT_SUBMISSION_KEY]: true };
    case FINISH_UPLOAD_FILE:
      return { ...state, [action.key || DEFAULT_SUBMISSION_KEY]: false };
    default:
      return state;
  }
}
