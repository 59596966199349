/* eslint-disable jsx-a11y/anchor-is-valid */
import modulePermissionSchema from './modulePermissionSchema';

const schema = (allPermissionModules) => (formid, values) =>
  allPermissionModules.reduce(
    (finalSchema, module) => ({
      ...finalSchema,
      [module.id]: {
        type: 'section',
        schema: modulePermissionSchema,
      },
    }),
    {},
  );

const ui = (allPermissionModules) => (step, values) => {
  return allPermissionModules.map((module) => ({
    id: module.id,
    title: module.name,
    fields: [module.id],
  }));
};

export default (allPermissionModules) => ({
  schema: schema(allPermissionModules),
  ui: ui(allPermissionModules),
});
