import React from 'react';
import lodashGet from 'lodash.get';
import { t1 } from 'translate';

const ContentCreator = ({ creators, className }) => {
  if (!creators || !creators.length) {
    return <span>{t1('no_content_creators')}</span>;
  }

  return (creators || []).map((c, idx) => (
    <span className={`${idx > 0 ? 'p-l-10' : ''} ${className || ''}`}>
      {lodashGet(c, 'name')}
    </span>
  ));
};

export default ContentCreator;
