import React, { Component } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { LayoutContext } from './index';

const withLayoutConfig = (WrappedComponent) => {
  class ComponentHOC extends Component {
    render() {
      return (
        <LayoutContext.Consumer>
          {(context) => {
            return <WrappedComponent {...this.props} context={context} />;
          }}
        </LayoutContext.Consumer>
      );
    }
  }

  hoistNonReactStatic(ComponentHOC, WrappedComponent);

  return ComponentHOC;
};

export default withLayoutConfig;
