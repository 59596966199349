import React from 'react';
import lodashGet from 'lodash.get';
import { t1 } from 'translate';
import apiUrls from 'api-endpoints';
import RaisedButton from 'components/common/mui/RaisedButton';
import ExportBtn from 'components/common/action-button/ExportBtn';
import Icon from 'components/common/Icon';
import CommonAntdTable from 'components/common/antd/table';
import HorizontalScrolling from 'components/common/html/horizontal-scrolling';

const AntTableWithExport = ({
  paramsExport,
  alternativeApi,
  fileName,
  reportKey,
  ...props
} = {}) => {
  const tableRef = React.useRef(React.createRef());

  let [exportTable, setExportTable] = React.useState(false);

  const getHtml = () => {
    const innerHTML = tableRef.current.getElementsByClassName('ant-table-body');
    return lodashGet(innerHTML, '0.innerHTML');
  };

  const getParams = () => {
    return {
      ...(paramsExport || {}),
      html: getHtml(),
    };
  };

  const table = <CommonAntdTable rowKey="id" {...props} />;

  return (
    <div ref={tableRef}>
      <div className="d-flex align-items-center justify-content-between m-t-20 m-b-10">
        {t1('table_guide_to_user_scroll')}

        <div className="m-b-5">
          <RaisedButton
            primary
            onClick={() => {
              setExportTable(1);
            }}
            label={'Xuất Excel'}
          />

          {!!exportTable && (
            <span className="m-l-10">
              File của bạn đã sẵn sàng.
              <ExportBtn
                fileName={fileName}
                label={t1('Download')}
                icon={<Icon icon="download" />}
                className="m-l-10 m-t-10"
                url={alternativeApi || apiUrls.export_html_table}
                getParams={getParams}
              />
            </span>
          )}
        </div>
      </div>

      {props.horizontalScrolling ? (
        <HorizontalScrolling>{table}</HorizontalScrolling>
      ) : (
        table
      )}
    </div>
  );
};

export default AntTableWithExport;
