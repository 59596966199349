import inputText from 'schema-form/lib/input/Text';
import { t1 } from 'translate';

const schema = () => {
  return {
    name: inputText({
      floatingLabelText: t1('group_name'),
    }),
  };
};

const ui = () => {
  return [
    {
      id: 'default',
      fields: ['name'],
    },
  ];
};

export default { schema, ui };
