import React from 'react';
import { ntype, scorePeriods } from 'configs/constants';
import { t1 } from 'translate';
import { isExam, isSyllabusSequential } from 'common/learn';

const getElementStatus = ({ locked, progress, node, syllabus }) => {
  const isSequential = isSyllabusSequential(syllabus);

  if (!locked && isExam(node)) {
    return null;
  }
  const finishedTitle = t1('you_have_completed_this_item');
  const percentCompletedTitle = t1('you_have_completed_%s %%', progress.cp);

  let progressToUse = progress.cp || 0;

  let notPassedExercise = false;
  if (
    isSequential &&
    node.ntype === ntype.EXERCISE &&
    !Number(node.practice) &&
    !Number(progress.pf)
  ) {
    notPassedExercise = true;
  }

  let [classStatusModifier, title] = ['check', 'finish', '%'];
  if (locked) {
    classStatusModifier = 'lock';
    title = t1('item_is_locked');
  } else if (typeof progress.cp === 'undefined') {
    classStatusModifier = 'have-not-stated';
  } else if (!progressToUse || progressToUse <= scorePeriods.BAD) {
    classStatusModifier = 'bad';
    title = percentCompletedTitle;
  } else if (progressToUse <= scorePeriods.IMPROVEMENT) {
    classStatusModifier = 'improvement';
    title = percentCompletedTitle;
  } else if (progressToUse < scorePeriods.FINISH || notPassedExercise) {
    classStatusModifier = 'progress';
    title = percentCompletedTitle;
  } else {
    classStatusModifier = 'finish';
    title = finishedTitle;
  }

  return (
    <span
      className={`learn-nav-item__icon \
                learn-nav-item__status \
                learn-nav-item__status--${classStatusModifier} \
                pull-left`}
      title={title}
    />
  );
};

export default getElementStatus;
