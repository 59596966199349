import React from 'react';
import { submit } from 'redux-form';
import DetailOnDialog from 'components/common/detail-on-dialog';
import SchemaForm from 'schema-form/Form';
import schema from './schema';
import { t1 } from 'translate';
import store from 'store';
import nodeSagaActions from 'actions/node/saga-creators';
import actions from 'actions/node/creators';

const formId = 'dialog-with-choose-all';
const dialogKey = formId;

const SubmitSelectedRows = ({
  alternativeApi = '',
  previewButton,
  dialogTitle = '',
  searchFormValues = {},
  params = {},
  submitButton,
  selectAllLabel = '',
  formIdToSubmitOnSuccess = '',
  totalRowSelected = 0,
  totalRow = 0,
}) => {
  const closeDialog = () => {
    store.dispatch(actions.handleOpenDialog({ openDialog: false }, dialogKey));
  };

  const refreshSearchForm = () => {
    if (formIdToSubmitOnSuccess) {
      store.dispatch(submit(formIdToSubmitOnSuccess));
    }
  };

  const reloadData = () => {
    closeDialog();
    refreshSearchForm();
  };

  const onSubmit = (formValues) => {
    const { select_all = 0 } = formValues;
    store.dispatch(
      nodeSagaActions.submitFormRequest(formId, {
        url: alternativeApi,
        extraParams: select_all ? searchFormValues : params,
        executeOnSuccess: reloadData,
      }),
    );
  };

  return (
    <DetailOnDialog
      renderPreview={({ showFull }) => (
        <span onClick={showFull} className="cursor-pointer">
          {previewButton}
        </span>
      )}
      renderFull={() => {
        return (
          <SchemaForm
            schema={schema}
            formid={formId}
            submitButton={() => {
              if (submitButton) {
                return submitButton;
              }
              return (
                <button className="btn btn-secondary">{t1('apply')}</button>
              );
            }}
            selectAllLabel={selectAllLabel}
            totalRowSelected={totalRowSelected}
            totalRow={totalRow}
            onSubmit={onSubmit}
          />
        );
      }}
      dialogKey={dialogKey}
      dialogOptionsProperties={{
        title: dialogTitle,
        handleClose: true,
        width: 500,
      }}
    />
  );
};

export default SubmitSelectedRows;
