import getLodash from 'lodash.get';
import { isMobileBrowser } from 'common';
import { encryptObjLHAInfo } from '../utils';
import Store from '../../../../store';
import { detect } from 'detect-browser';
import { openLinkInNewTab } from 'common/utils/browser';

const getDomainInfo = () => {
  return getLodash(Store.getState(), 'domainInfo', 'unknow');
};

export const openZoomOnWeb = (res, isZoomPlatform, isTeacher, session) => {
  const detectedBrowser = detect();
  const url = getLodash(res.result, 'url', '');
  let src = url;

  if (isMobileBrowser(true) && isZoomPlatform) {
    const response = getLodash(res, 'result.response', {});
    const {
      meetingNumber,
      userName,
      password,
      userID,
      isModerator,
      zak,
    } = response;
    const lhaInfo = {
      meetingId: meetingNumber,
      userName,
      userID,
      password,
      isModerator,
      zak,
    };
    const lhaInfoCipher = encryptObjLHAInfo(lhaInfo);
    // src = `https://taphuan-staging.csdl.edu.vn/vc?hash=${lhaInfoCipher}`;
    src = `${window.location.origin}/vc?hash=${lhaInfoCipher}`;
  }
  // temporary disable LHA in iframe to fix cannot share screen bug
  // else if (getDomainInfo().isRoot || isTeacher) {
  //   const defaultTimeLHA = 30 * 60 * 1000;
  //   const remainingTime = getLodash(
  //     res.result,
  //     'remainingTime',
  //     defaultTimeLHA,
  //   );
  //   src = `${window.location.origin}/virtual-class?url=${url}&sessionIid=${
  //     session.iid
  //   }&remainingTime=${remainingTime}`;
  // }
  // ios does not allow to open an url in an async action
  // so we cannot open url in new tab
  if (detectedBrowser.name === 'ios') {
    window.location.href = src;
  } else {
    openLinkInNewTab(src);
  }
};
