import React from 'react';
import Link from 'components/common/router/Link';
import routes from 'routes';
import { t1 } from 'translate';
import lodashGet from 'lodash.get';
import Icon from 'antd/lib/icon';
import styled from 'styled-components';
import Popover from 'antd/lib/popover';
import { Input } from 'antd';
import OrganizationNotInDbInfo from './OrganizationNotInDbInfo';

const OrgParent = styled.ul`
  list-style: none;
  padding-left: 5px;
`;

/**
 * user info is usually expanded with organization and job positions
 * We'll extract them and display here in this component
 *
 * attr can be either 'organization' or 'positions' which were expanded
 */
const OrganizationsOrPhongBanInResultTable = ({
  item,
  attr,
  showParentsInfo,
  showParentsInfoInline,
  showUpUntilOrgSubType, // for example we only wanna show PGD & SGD, not Bộ
  showNameAsInputField,
  // if withLink, show link to the org
  withLink,
  expandedAttr,
  showCode = false,
}) => {
  const iids = lodashGet(item, attr, []);
  const objects = expandedAttr || lodashGet(item, '__expand.' + attr, []);

  if (objects.length > 0) {
    return (
      <div>
        {iids.map((iid, idx) => {
          const item = objects.find((o) => o && String(o.iid) === String(iid));

          if (!item) {
            return null;
          }

          let parents = [];
          if (showParentsInfo && Array.isArray(item.parents)) {
            if (showUpUntilOrgSubType) {
              for (const value of item.parents) {
                parents.push(value);
                if (lodashGet(value, 'sub_type') == showUpUntilOrgSubType) {
                  break;
                }
              }
            } else {
              // default only show one level
              parents = item.parents.slice(0, 1);
            }
          }

          const text = (
            <div key={iid}>
              {showNameAsInputField ? (
                <Input readonly value={item.name} />
              ) : (
                item.name
              )}

              {showParentsInfo && parents && parents.length ? (
                window.isETEP && !showParentsInfoInline ? (
                  <Popover
                    content={
                      <OrgParent>
                        {parents.map((p, index) => (
                          <li className={`item-${index}`}>{p.name}</li>
                        ))}
                      </OrgParent>
                    }
                    overlayClassName="parent-info"
                  >
                    {' '}
                    <Icon type="info-circle-o" />
                  </Popover>
                ) : (
                  [
                    <br key="br" />,
                    <span key="equivalent">
                      {parents.map((p) => lodashGet(p, 'name')).join(' / ')}
                    </span>,
                  ]
                )
              ) : null}

              {showCode ? <div className="text-faded">{item.code}</div> : null}
            </div>
          );

          return (
            <div key={`org-el-${iid}`}>
              {withLink ? (
                <Link
                  to={routes.url(
                    'node_edit',
                    Object.assign({}, item, { ntype: 'organization' }),
                  )}
                  title={t1('click_to_view_%s', [item.name])}
                >
                  {' '}
                  {text}
                </Link>
              ) : (
                text
              )}
            </div>
          );
        })}
      </div>
    );
  } else if (lodashGet(item, '__expand.organization_name')) {
    return (
      <div>
        {lodashGet(item, '__expand.organization_name')}
        <OrganizationNotInDbInfo />
      </div>
    );
  }

  return null;
};

export default OrganizationsOrPhongBanInResultTable;
