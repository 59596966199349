import lodashGet from 'lodash.get';
import Warning from 'components/common/Warning';
import SuccessAlert from 'components/common/SuccessAlert';
import React from 'react';

const PassedRatioInResultTable = ({ course }) => {
  const passed = parseInt(lodashGet(course, 'stats.passed', 0));
  const total = parseInt(lodashGet(course, '__expand.number_of_students', 0));

  if (passed < total)
    return (
      <div>
        <Warning inline>{passed}</Warning>/{total}
      </div>
    );
  else
    return (
      <div>
        <SuccessAlert inline>{passed}</SuccessAlert>/{total}
      </div>
    );
};

export default PassedRatioInResultTable;
