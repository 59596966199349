export const TRAINING_MODEL = {
  COT_CAN: 'cotcan',
  DAI_TRA: 'daitra',
};

export const TRAINING_MODEL_INT_VAL = {
  COT_CAN: 1,
  DAI_TRA: 2,
};

export const TRAINING_MODEL_NAME = {
  [TRAINING_MODEL.COT_CAN]: 'Cốt cán',
  [TRAINING_MODEL.DAI_TRA]: 'Đại trà',
};

export const trainingModelOptions = () => {
  return Object.keys(TRAINING_MODEL_NAME).map((model) => {
    return {
      value: model,
      label: TRAINING_MODEL_NAME[model],
    };
  });
};

export const getTrainingModelText = (key) => TRAINING_MODEL_NAME[key];

export const ETEP_CONFIG = {
  GVDHSP_organization_iids: [
    100585,
    100590,
    100591,
    100592,
    100594,
    100595,
    100596,
    100597,
    105850,
    105851,
    144879,
    146401,
    4046164,
    16673690,
    16673701,
    16673707,
    16673740,
    16673935,
    16673936,
    16673983,
    16674024,
    16674183,
    16674204,
    16674205,
    16803806,
    20928481,
    29225227,
    47272400,
    50492245,
    50492463,
    50603229,
    50617177,
    51035683,
    51381520,
    56929969,
    62101987,
    62102797,
    62260758,
    62260809,
    63842796,
    84878584,
    84879308,
    85940148,
    91479873,
    94537355,
  ],
};
