import React from 'react';
import apiEndpoints from 'api-endpoints';
import fetchData from '../../components/common/fetchData';

/**
 * Will pass down the following props to component
 *
 *  - `serverTs`
 *
 * @param WrappedComponent
 * @returns {*}
 */
const withServerTs = (WrappedComponent) => {
  const fetchServerTs = fetchData(() => ({
    baseUrl: apiEndpoints.server_timestamp,
    propKey: 'serverTs',
  }));

  return fetchServerTs(WrappedComponent);
};

export default withServerTs;
