import React from 'react';
import groupApiUrls from 'components/admin/group/endpoints';
import { categoryRelationTypes, userGroupSubTypes } from 'configs/constants';
import GroupResults from './Results';
import ButtonNew from 'components/admin/group/new/ButtonNew';
import PageWrapper from 'common/page-wrapper';
import { t1 } from 'translate';
import BackButton from 'components/common/back-button';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import schema from 'components/front-end/meet/group/schema';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';

const MeetGroup = ({ dispatch }) => {
  const formid = 'search-my-groups';
  const type = categoryRelationTypes.USER_GROUP;

  const hiddenFields = {
    group_type: 'meeting',
    _sand_step: 'user_group',
    _sand_expand: ['next_meeting', 'first_members', 'moderators'],
    sub_type: [userGroupSubTypes.NORMAL_USER_GROUP],
  };

  const refetchUserGroup = () => {
    dispatch(submit(formid));
  };

  const renderNoResultComponent = () => {
    return <SimpleNoResult />;
  };
  const renderResultsComponent = (items) => {
    return items && items.length > 0 ? (
      <GroupResults
        refetchUserGroup={refetchUserGroup}
        items={items}
        type={type}
        mode={'meet'}
      />
    ) : (
      renderNoResultComponent()
    );
  };

  return (
    <div className="container m-t-20">
      <PageWrapper
        showIndicator={false}
        title={
          <div className={'d-flex align-items-center'}>
            <BackButton urlToBack={'/lha'} className={'m-r-10'} />
            {t1('user_group')}
          </div>
        }
        action={
          <ButtonNew
            step={'meeting_groups'}
            redirectToEditPage={false}
            requestSuccessful={refetchUserGroup}
          />
        }
      >
        <div className={'m-t-20'}>
          <SearchWrapper
            formid={formid}
            schema={schema}
            showResult
            hiddenFields={hiddenFields}
            renderResultsComponent={renderResultsComponent}
            alternativeApi={groupApiUrls.my_groups}
            autoSearchWhenStart
          />
        </div>
      </PageWrapper>
    </div>
  );
};

export default connect()(MeetGroup);
