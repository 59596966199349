import get from 'lodash.get';
import { useCallback, useMemo } from 'react';
import unionBy from 'lodash.unionby';
import isEqual from 'lodash.isequal';
import lodashPickBy from 'lodash.pickby';
import { getMapByQuestionData } from 'components/admin/question/schema/mddm/utils';
import { shuffleArrayByKey } from 'common/utils/Array';
import { isQuestionShufflable } from 'common/learn/Question';

export const useCoreLogicTableData = ({
  userAnswers = [],
  question,
  setUserAnswers,
  disabled,
  practice,
  setShowError,
  questionShuffleKey,
}) => {
  const {
    mddm_list_answer = [],
    mddm_mapping_table_data = {},
    mddm_question_table_data = {},
    mddm_single_dropped = true,
    mddm_multiple_answer = false,
  } = question;

  const { dataSource: mapping_result } = mddm_mapping_table_data;
  const { dataSource: question_result } = mddm_question_table_data;

  const getUserAnswer = useCallback(
    (row, col, index) => {
      return userAnswers.find(
        (item) => item.row == row && item.col == col && item.index == index,
      );
    },
    [userAnswers],
  );

  const getUserAnswerValue = useCallback(
    (row, col, index) => {
      const answer = getUserAnswer(row, col, index);
      return get(answer, 'value', []);
    },
    [getUserAnswer],
  );

  const generateAnswersData = useMemo(
    () => {
      const itemInUserAnswers = Array.isArray(userAnswers)
        ? userAnswers.reduce((value, current) => {
            return [...value, ...get(current, 'value', [])];
          }, [])
        : [];
      return mddm_list_answer.map((data, index) => {
        return {
          ...data,
          id: String(data.id),
          type: String(data.id),
          answered: mddm_single_dropped
            ? itemInUserAnswers.includes(String(data.id))
            : false,
        };
      });
    },
    [mddm_list_answer, mddm_single_dropped, userAnswers],
  );

  const generateQuestionsData = useMemo(
    () => {
      const acceptAllAnswers = mddm_list_answer.map((item) => String(item.id));
      const questionsData = question_result.map((data, row) => {
        return Object.values(
          lodashPickBy(data, (value, key) => key !== 'iid'),
        ).reduce((value, currentValue, col) => {
          let mapByQuestionData = getMapByQuestionData(currentValue);
          return [
            ...value,
            mapByQuestionData.map((val, index) => {
              return {
                correctAnswers: get(
                  mapping_result,
                  `[${row}][${col}][${index}]`,
                  [],
                ),
                answers: mddm_list_answer.filter((v) => {
                  return getUserAnswerValue(row, col, index).includes(
                    String(v.id),
                  );
                }),
                accepts: acceptAllAnswers,
              };
            }),
          ];
        }, []);
      });
      return questionsData;
    },
    [getUserAnswerValue, mapping_result, mddm_list_answer, question_result],
  );

  let answersData = generateAnswersData;
  const questionsData = generateQuestionsData;

  const handleDrop = useCallback(
    (item, row, col, index) => {
      if (disabled) {
        return;
      }

      if (practice) {
        const correctAnswers = get(
          mapping_result,
          `[${row}][${col}][${index}]`,
          [],
        );
        const isCorrect = correctAnswers
          .map((v) => String(v))
          .includes(String(item.id));
        setShowError((one) =>
          isCorrect
            ? -Math.abs(Math.abs(one) + 1)
            : Math.abs(Math.abs(one) + 1),
        );
        if (!isCorrect) {
          return;
        }
      }
      let userAnswerData = [];
      questionsData.map((sentenceData, rowIndex) => {
        sentenceData.map((questionData, colIndex) => {
          questionData.map((value, idx) => {
            const currentQuestionValue = getUserAnswerValue(
              rowIndex,
              colIndex,
              idx,
            );
            if (row == rowIndex && col == colIndex && idx == index) {
              userAnswerData.push({
                row: rowIndex,
                col: colIndex,
                index: idx,
                value: mddm_multiple_answer
                  ? unionBy([...currentQuestionValue, String(item.id)])
                  : [String(item.id)],
              });
            } else {
              userAnswerData.push({
                row: rowIndex,
                col: colIndex,
                index: idx,
                value: currentQuestionValue,
              });
            }
          }, []);
        }, []);
      });
      setUserAnswers(userAnswerData);
    },
    [
      disabled,
      practice,
      questionsData,
      setUserAnswers,
      mapping_result,
      setShowError,
      getUserAnswerValue,
      mddm_multiple_answer,
    ],
  );

  const handleAnsweredItemClick = useCallback(
    (item, row, col, index) => {
      if (disabled) {
        return;
      }
      let userAnswerData = [];
      userAnswers.map((question, idx) => {
        if (
          question.row == row &&
          question.col == col &&
          question.index == index
        ) {
          userAnswerData.push({
            ...question,
            value: get(question, 'value', []).filter((id) => id != item.id),
          });
        } else {
          userAnswerData.push(question);
        }
      });

      setUserAnswers(userAnswerData);
    },
    [disabled, setUserAnswers, userAnswers],
  );

  const checkAnswerIsCorrect = useCallback(
    (item = {}) => {
      const { correctAnswers = [], answers = [] } = item;
      let user_answers = Array.isArray(answers)
        ? answers.map((v) => String(v.id)).sort()
        : [];
      let correct_answers = Array.isArray(correctAnswers)
        ? correctAnswers.map((v) => String(v)).sort()
        : [];
      return userAnswers.length
        ? isEqual(user_answers, correct_answers)
        : false;
    },
    [userAnswers.length],
  );

  let answersDataShuffled = answersData;

  const isShufflable = isQuestionShufflable(question);

  if (isShufflable && questionShuffleKey) {
    answersDataShuffled = shuffleArrayByKey(answersData, questionShuffleKey);
  }

  return [
    questionsData,
    answersDataShuffled,
    handleDrop,
    handleAnsweredItemClick,
    checkAnswerIsCorrect,
  ];
};
