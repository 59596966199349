import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReviewControl from 'components/bdtx/review-program/common/review/control';
import History from 'components/bdtx/review-program/common/history';
import actions from 'actions/node/creators';
import RejectForm from 'components/bdtx/review-program/common/review/RejectForm';
import ReviewEvidenceForm from 'components/bdtx/review-program/common/review-evidence/ReviewEvidenceForm';
import { t1 } from 'translate';
import lodashGet from 'lodash.get';
import Alert from 'antd/lib/alert';
import Tooltip from 'common/tooltip';

const Review = ({
  node,
  dispatch,
  apiUrl,
  labelControl,
  onReviewSuccessful,
  shouldShowReviewControl,
  allowUpdateEvidence,
}) => {
  const [status, setStatus] = useState(node.review_status);

  const isQueued = lodashGet(node, 'status') === 'queued';

  const handleReviewSuccess = (value) => {
    setStatus(value);

    if (typeof onReviewSuccessful === 'function') {
      onReviewSuccessful();
    }
  };

  const onChange = (value) => {
    let contentDialog;
    let dialogKey;

    if (value === 'approved') {
      dialogKey = 'approve_program';
      contentDialog = (
        <ReviewEvidenceForm
          node={node}
          hiddenFields={{
            review_status: 'approved',
          }}
          step="review_status"
          apiUrl={apiUrl}
          dialogKey={dialogKey}
          onUpdateReviewEvidence={() => handleReviewSuccess(value)}
        />
      );
    } else {
      dialogKey = 'reject_program';
      contentDialog = (
        <RejectForm
          node={node}
          formid={`reject-form-${node.iid}`}
          requestSuccessful={() => handleReviewSuccess(value)}
          mode="edit"
          type="review_status"
          dialogKey={dialogKey}
          hiddenFields={{
            review_status: 'rejected',
          }}
          apiUrl={apiUrl}
        />
      );
    }

    const optionsProperties = {
      handleClose: true,
      modal: true,
      title: value === 'approved' ? t1('review_evidence') : t1('reject_reason'),
    };

    if (isQueued) {
      contentDialog = (
        <Alert
          message={t1(
            'this_program_is_queued.please_approve_this_program_first',
          )}
          type="warning"
        />
      );
    }

    dispatch(
      actions.handleOpenDialog({ contentDialog, optionsProperties }, dialogKey),
    );
  };

  return (
    <div className="white-background">
      <div className="d-flex align-items-center">
        <h3 className="m-0 m-r-5">{t1('vgd_review_information')}</h3>
        <Tooltip
          content={t1(
            'vgd_review_and_upload_evidence_as_certificate_to_allow_sp_public_program',
          )}
        />
      </div>

      {shouldShowReviewControl && (
        <div className="text-left m-t-15">
          <ReviewControl
            onChange={onChange}
            currentStatus={status}
            label={labelControl}
          />
        </div>
      )}

      <History
        node={node}
        onReviewSuccessful={onReviewSuccessful}
        allowUpdateEvidence={allowUpdateEvidence}
      />
    </div>
  );
};

export default connect()(Review);
