import React from 'react';
import { Tag } from 'antd';
import './style.scss';

const ItemTag = ({ item = {}, handleItemChildrenClick }) => {
  const { content = '' } = item;
  return (
    <Tag className="cursor-pointer" onClick={handleItemChildrenClick}>
      {content}
    </Tag>
  );
};

export default ItemTag;
