import React from 'react';
import { REPORT_ACTION } from 'components/bdtx/report/routes';
import BDTXBo from 'components/temis/report/report-ncbdtx/BDTXBo';

const NCBDTXPlanDevelop = ({ action }) => {
  const isResultForCBQL = action === REPORT_ACTION.CBQL;
  const reportTitle = `Nhu cầu bồi dưỡng theo mong muốn của ${
    isResultForCBQL ? 'CBQL CSGD' : 'GIÁO VIÊN'
  }`;

  return (
    <BDTXBo
      reportForNcbdtx={true}
      autoGetDataWithoutScholastic={true}
      isResultForCBQL={isResultForCBQL}
      isPlanDevelopment={true}
      reportTitle={reportTitle}
    />
  );
};

export default NCBDTXPlanDevelop;
