import './style.scss';

import React, { useCallback, useMemo, useState } from 'react';
import isEqual from 'lodash.isequal';
import lUniq from 'lodash.uniqwith';
import get from 'lodash.get';
import { Checkbox, Radio } from 'antd';
import { t1 } from 'translate';
import { isSmallScreen } from 'common';
import CommonAntdTable from 'components/common/antd/table';
import ErrorMessageQuestion from '../../../ErrorMessageQuestion';
import { correctLevel, isCorrectItemLikert } from '../../utils';

const TableLikert = ({
  questionData, // là question của exercise, trong đó có phần câu hỏi (questions) và answers tương ứng
  questions = [],
  answers = [],
  onTableLikertChange = () => {},
  defaultValues = [],
  shouldShowKey,
  tablePrefix,
  correctAnswers = [],
  disabled,
  multiple,
  isTableSelection = false,
  practice,
  showScroll,
}) => {
  const [showError, setShowError] = useState(0);
  const answerWidthForSmallScreen = 100;
  const answerWithForMediumAndBigScreen = 150;

  const sortAndParseToString = (array) => {
    return (array || [])
      .map((i) => String(i))
      .sort()
      .join(',');
  };

  const listUserAnswersTrueOrFalse = useMemo(
    () => {
      let correctAnswer = [];
      if (correctAnswers.length && defaultValues.length) {
        correctAnswers.forEach((item, i) => {
          const correctAnswerSorted = sortAndParseToString(item);

          const valueByIndex = get(defaultValues, `[${i}]`, []);
          const defaultValueSorted = sortAndParseToString(valueByIndex);

          correctAnswer = defaultValues.some((item) => (item || []).length)
            ? [
                ...correctAnswer,
                isEqual(correctAnswerSorted, defaultValueSorted),
              ]
            : [];
        });
      }

      return correctAnswer;
    },
    [correctAnswers, defaultValues],
  );

  const getDataChange = (questionIndex, answerIndex) => {
    const stringAnswerIndex = String(answerIndex);

    if (!defaultValues.length) {
      const newData = [];

      newData[questionIndex] = [stringAnswerIndex];

      return newData;
    }

    const defaultValuesCloned = JSON.parse(JSON.stringify(defaultValues));
    if (!multiple) {
      defaultValuesCloned[questionIndex] = [stringAnswerIndex];

      return defaultValuesCloned;
    }

    let currentValues = defaultValuesCloned[questionIndex] || [];
    const valueIndex = currentValues.indexOf(stringAnswerIndex);
    if (valueIndex >= 0) {
      currentValues.splice(valueIndex, 1);
    } else {
      currentValues = lUniq([...currentValues, stringAnswerIndex], isEqual);
    }

    defaultValuesCloned[questionIndex] = currentValues;

    return defaultValuesCloned;
  };

  const onTableChange = useCallback(
    (data) => {
      if (typeof onTableLikertChange === 'function') {
        onTableLikertChange(data);
      }
    },
    [onTableLikertChange],
  );

  const onChangeValue = (answerIndex, questionIndex) => {
    const radioValue = answerIndex;
    const newData = getDataChange(questionIndex, radioValue);

    if (disabled) {
      return;
    }

    if (!isTableSelection || !practice) {
      onTableChange(newData);

      return;
    }

    const isCorrectAnswer = isCorrectItemLikert(
      radioValue,
      questionIndex,
      correctAnswers,
      newData,
    );

    if (isCorrectAnswer) {
      onTableChange(newData);
    }

    if (isCorrectAnswer !== correctLevel.correct_not_enough) {
      setShowError((one) =>
        isCorrectAnswer
          ? -Math.abs(Math.abs(one) + 1)
          : Math.abs(Math.abs(one) + 1),
      );
    }
  };

  const isChecked = (questionIndex, answerIndex) => {
    let defaultChecked = false;
    if (defaultValues.length) {
      const values = defaultValues[questionIndex] || [];
      const itemFound = values.includes(String(answerIndex));

      if (itemFound) {
        defaultChecked = true;
      }
    }

    return defaultChecked;
  };

  const getColumns = () => {
    const columns = [
      {
        title: t1('question'),
        width: 200,
        render: (text, row, index) => {
          return (
            <div className="question">
              <span>{text}</span>
              {disabled && (
                <span
                  className={`question__icon ${
                    listUserAnswersTrueOrFalse[index]
                      ? 've-check'
                      : 've-close-outline'
                  }`}
                />
              )}
            </div>
          );
        },
      },
    ];

    if (answers.length) {
      columns.push({
        title: t1('question_answer'),
        children: answers.map((answer, answerIndex) => {
          return {
            title: answer,
            className: 'text-center',
            width: isSmallScreen
              ? answerWidthForSmallScreen
              : answerWithForMediumAndBigScreen,
            render: (text, row, questionIndex) => {
              const defaultChecked = isChecked(questionIndex, answerIndex);
              const propsParams = {
                className: tablePrefix,
                checked: defaultChecked,
                onChange: () => onChangeValue(answerIndex, questionIndex),
                disabled: disabled || shouldShowKey,
              };
              return {
                children: (
                  <div
                    className="text-center"
                    key={`${answerIndex}-${questionIndex}`}
                  >
                    {multiple ? (
                      <Checkbox {...propsParams} />
                    ) : (
                      <Radio {...propsParams} />
                    )}
                  </div>
                ),
                props: {
                  className: 'table-question-selection__radio',
                },
              };
            },
          };
        }),
      });
    }

    return columns.filter(Boolean);
  };

  if (!questions.length || !answers.length) {
    return null;
  }

  return (
    <div className="table-question-selection">
      <ErrorMessageQuestion question={questionData} error={showError} />

      <CommonAntdTable
        bordered
        pagination={false}
        columns={getColumns()}
        className="learn-default"
        dataSource={questions}
        scroll={{ x: showScroll ? true : isSmallScreen }}
      />
    </div>
  );
};

export default TableLikert;
