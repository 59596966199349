import React from 'react';
import lodashGet from 'lodash.get';
import Collapse from 'antd/lib/collapse';
import t1 from 'translate';

const renderGroup = (g) => {
  if (!lodashGet(g, 'fieldNames')) {
    return null;
  }

  return Object.values(g.fieldNames).map((f) => (
    <div className="row">
      <div className="col-md-12">{f}</div>
    </div>
  ));
};

const CustomCollapsePanel = ({ header, ...props }) => (
  <Collapse.Panel
    {...props}
    header={<span style={{ fontSize: 16 }}>{header}</span>}
    forceRender
  />
);

const LayoutFreestyle = ({ groups, submitButton }) => {
  const {
    default: defaultGroup,
    module_permissions,
    role_admin_menu_items_group,
    others,
  } = groups;

  const {
    role_admin_menu_items,
    role_admin_menu_items_bdtx,
    role_admin_menu_items_temis,
  } = role_admin_menu_items_group.fieldNames;

  return (
    <div className="container-fluid">
      {renderGroup(defaultGroup)}
      <Collapse>
        <CustomCollapsePanel key={'modules'} header={t1('modules')}>
          {renderGroup(module_permissions)}
        </CustomCollapsePanel>
        <CustomCollapsePanel
          key={'role_admin_menu_items'}
          header={t1('role_admin_menu_items')}
        >
          {role_admin_menu_items}
        </CustomCollapsePanel>
        <CustomCollapsePanel
          key={'role_admin_menu_items_bdtx'}
          header={t1('role_admin_menu_items_bdtx')}
        >
          {role_admin_menu_items_bdtx}
        </CustomCollapsePanel>
        <CustomCollapsePanel
          key={'role_admin_menu_items_temis'}
          header={t1('role_admin_menu_items_temis')}
        >
          {role_admin_menu_items_temis}
        </CustomCollapsePanel>
      </Collapse>
      {renderGroup(others)}

      <div className="row">
        <div className="col-md-12 text-center m-t-20">{submitButton}</div>
      </div>
    </div>
  );
};

export default LayoutFreestyle;
