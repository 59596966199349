const SCHOLASTIC_CONFIG_LOADING = 'SCHOLASTIC_CONFIG_LOADING';
const SCHOLASTIC_CONFIG_LOADING_SUCCESS = 'SCHOLASTIC_CONFIG_LOADING_SUCCESS';
const SCHOLASTIC_CONFIG_LOADING_ERROR = 'SCHOLASTIC_CONFIG_LOADING_ERROR';
const SCHOLASTIC_CONFIG_ALL_SCHOOL_YEARS = 'SCHOLASTIC_CONFIG_ALL_SCHOOL_YEARS';

export {
  SCHOLASTIC_CONFIG_LOADING,
  SCHOLASTIC_CONFIG_LOADING_SUCCESS,
  SCHOLASTIC_CONFIG_LOADING_ERROR,
  SCHOLASTIC_CONFIG_ALL_SCHOOL_YEARS,
};
