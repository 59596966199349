import './stylesheet.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Select, Spin } from 'antd';
import debounce from 'lodash.debounce';
import lodashGet from 'lodash.get';
import lodashEqual from 'lodash.isequal';
import makeReduxFormCompatible from 'components/common/makeReduxFormCompatible';
import Requester from 'common/network/http/Request';
import DefaultFormElementRecap from 'components/common/default-form-element-recap';
import { elementDisplayModes } from 'schema-form/constants';
import { getPopupContainerOfElement } from 'schema-form/utils';

const RecapSelect = ({ options, value, label }) => {
  options = options || [];
  value = typeof value === 'object' ? value : [value] || [];

  const recapContent = options
    .filter(
      (opt) =>
        lodashGet(opt, 'value') !== '' &&
        value.includes(lodashGet(opt, 'value')),
    )
    .map((opt) => lodashGet(opt, 'label'))
    .join(', ');

  if (!recapContent) {
    return null;
  }

  return <DefaultFormElementRecap label={label} content={recapContent} />;
};

const { Option } = Select;

const AutoCompleteElement = ({
  floatingLabelText,
  readOnly,
  errorText,
  onChange,
  value,
  requestUrl,
  defaultRequestParams = {},
  searchKeys = ['iid', 'name'],
  transformData,
  useCaseSensitive,
  debounceTime = 300,
  multiple,
  onSearched,
  placeholder,
  elementDisplayMode,
  defaultValue,
  fieldKey,
  ...remainProps
}) => {
  const [defaultParams, setDefaultParams] = useState(null);
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [firstLoaded, setFirstLoaded] = useState(false);

  const convertDataToOptions = useCallback(
    (data) => {
      if (typeof transformData === 'function') {
        return transformData(data);
      }

      return (data || []).map((item) => ({
        value: item.value || item.iid || item.id || item.code,
        label: item.name || item.label || item.text || item.primaryText,
      }));
    },
    [transformData],
  );

  const loadOption = useCallback(
    (searchValue = '', newDefaultParams) => {
      if (!requestUrl) {
        return;
      }

      const params = {};
      if (searchValue) {
        (searchKeys || []).forEach((key) => {
          params[key] = useCaseSensitive
            ? searchValue
            : searchValue.toLowerCase();
        });
      }

      setFetching(true);

      let requestParams = {
        ...(params || {}),
        ...(newDefaultParams || defaultParams || {}),
      };

      Requester.get(requestUrl, requestParams).then((response = {}) => {
        setFetching(false);

        if (response.success) {
          const results = response.result;

          if (Array.isArray(results) && results.length) {
            const dataTransformed = convertDataToOptions(results);
            setOptions(dataTransformed);
          } else {
            setOptions([]);
          }

          if (typeof onSearched === 'function') {
            onSearched(results);
          }
        }
      });
    },
    [
      convertDataToOptions,
      defaultParams,
      onSearched,
      requestUrl,
      searchKeys,
      useCaseSensitive,
    ],
  );

  useEffect(
    () => {
      let searchValue = '';
      let defaultParams = { ...(defaultRequestParams || {}) };

      if (firstLoaded || fetching) {
        return;
      }

      if (defaultValue && !value && fieldKey) {
        defaultParams[fieldKey] = defaultValue;
      }

      setFirstLoaded(true);

      loadOption(searchValue, defaultParams);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onSearch = useMemo(
    () => {
      return debounce(loadOption, debounceTime);
    },
    [debounceTime, loadOption],
  );

  const handleValueChange = useCallback(
    (value) => {
      if (typeof onChange === 'function') {
        onChange(value || '');
      }
    },
    [onChange],
  );

  useEffect(
    () => {
      if (!value && defaultValue && typeof onChange === 'function') {
        onChange(defaultValue);
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => {
      if (!lodashEqual(defaultRequestParams, defaultParams)) {
        setDefaultParams(defaultRequestParams);

        if (firstLoaded && defaultParams !== null) {
          handleValueChange('');
          loadOption('', defaultRequestParams);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      defaultRequestParams,
      defaultParams,
      setDefaultParams,
      setOptions,
      firstLoaded,
    ],
  );

  if (elementDisplayMode === elementDisplayModes.RECAP) {
    return (
      <RecapSelect options={options} value={value} label={floatingLabelText} />
    );
  }

  return (
    <div className={'autocomplete'}>
      {floatingLabelText && (
        <div className={'input-text-label'}>
          <label>{floatingLabelText}</label>
        </div>
      )}
      <Form.Item
        validateStatus={errorText ? 'error' : ''}
        help={errorText || ''}
        className="m-b-0"
      >
        <Select
          className="full-width"
          mode={multiple ? 'multiple' : 'default'}
          filterOption={false}
          onSearch={onSearch}
          onChange={handleValueChange}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          placeholder={placeholder || floatingLabelText}
          {...remainProps}
          value={typeof value == 'string' && !value ? undefined : value}
          showSearch
          disabled={readOnly}
          getPopupContainer={getPopupContainerOfElement}
        >
          {(options || []).map((opt) => {
            return (
              <Option key={opt.value} value={opt.value}>
                {opt.label || opt.primaryText}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    </div>
  );
};

export default makeReduxFormCompatible({})(AutoCompleteElement);
