import InputAutoCompleteElement from 'schema-form/elements/input-auto-complete';
import { t1 } from 'translate';

/**
 *
 * @param floatingLabelText
 * @param fullWidth
 * @param classWrapper
 * @param rest
 * @returns {*&{floatingLabelText, fullWidth, classWrapper, type: (*|((function(*=, *=): *) & hoistNonReactStatics.NonReactStatics<*, {}>))}}
 * @constructor
 */
const InputTokenAutoComplete = ({
  fieldSearch, // the key to send along
  params,
  baseUrl,
  limit,
  format,
  normalize,
  dataSourceConfig,
  floatingLabelText,
  fullWidth,
  classWrapper,
  readOnly,
  ...rest
}) => {
  return {
    type: InputAutoCompleteElement,
    fieldSearch, // the key to send along
    params,
    format,
    normalize,
    limit,
    baseUrl,
    dataSourceConfig,
    floatingLabelText,
    fullWidth,
    classWrapper,
    readOnly,
    ...rest,
  };
};

export default InputTokenAutoComplete;
