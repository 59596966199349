import React from 'react';
import NodeNew from 'components/admin/node/new';
import { withRouter } from 'react-router';
import schema from 'components/bdtx/review-program/common/review/schema-reject';

const RejectForm = ({
  node,
  step,
  apiUrl,
  dialogKey,
  formid,
  hiddenFields,
  requestSuccessful,
}) => {
  return (
    <NodeNew
      alternativeApi={apiUrl}
      schema={schema}
      mode="edit"
      node={node}
      hiddenFields={hiddenFields}
      formid={formid}
      requestSuccessful={requestSuccessful}
      dialogKey={dialogKey}
      closeModal={!!dialogKey}
      step={step || 'review_status'}
    />
  );
};

export default withRouter(RejectForm);
