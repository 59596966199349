import { t1 } from 'translate';

export const BLOCK_HEADING_FONT_WEIGHT_NORMAL = 'font-weight-normal';
export const BLOCK_HEADING_SIZE_CONTENT = 'same-size-content';
export const BLOCK_HEADING_HIDDEN = 'hidden';

export default () => ({
  type: 'multiCheckbox',
  options: [
    {
      value: BLOCK_HEADING_FONT_WEIGHT_NORMAL,
      label: t1('font_weight_normal'),
    },
    {
      value: BLOCK_HEADING_SIZE_CONTENT,
      label: t1('same_size_content'),
    },
    {
      value: BLOCK_HEADING_HIDDEN,
      label: t1('hide_heading'),
    },
  ],
  floatingLabelText: t1('heading_style'),
  floatingLabelFixed: true,
  fullWidth: true,
  inline: true,
});
