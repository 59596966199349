import React from 'react';
import schema from './schema';
import NodeNew from 'components/admin/node/new';
import courseUrls from '../../endpoints';

function SplitCourse({ course, dialogKey, searchFormId }) {
  return (
    <NodeNew
      mode="new"
      step="split-course"
      params={{
        course_iid: course.iid,
      }}
      schema={schema}
      closeModal
      dialogKey={dialogKey}
      formid={`split-course-${course.iid}`}
      alternativeApi={courseUrls.split_course}
      searchFormId={searchFormId}
    />
  );
}

export default SplitCourse;
