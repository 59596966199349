import './style.scss';

import React, { useCallback, useEffect, useState } from 'react';
import makeReduxFormCompatible from 'components/common/makeReduxFormCompatible';
import CommonAntdTable from 'components/common/antd/table';
import dataSource, {
  createFieldName,
  dataKey,
} from 'components/common/forms/questions/open-ended/digital-plan/data-source';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import get from 'lodash.get';
import {
  formatDataSource,
  KEY_MAPPING,
  parsePlanToElement,
} from 'components/common/forms/questions/open-ended/digital-plan/utils';
import { Button, Tooltip } from 'antd';
import moment from 'moment';
import { supportPlanStatuses } from 'configs/constants/course';
import Icon from 'components/common/Icon';
import ExportData from 'components/common/export-data';
import { getStringPlanCategory } from 'components/teacher-dashboard/my-support-plan/utils';
import datetimeFormat from 'components/timetable_v2/configs';
import { inRange, required } from 'common/validators';
import InputField from './element/Input';
import DatePickerField from 'components/common/forms/questions/open-ended/digital-plan/element/DatePicker';
import isEqual from 'lodash.isequal';
import { getFormSyncErrors, getFormValues, unregisterField } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isSmallScreen } from 'common';
import { leaderPositionToText } from 'configs/constants/user';
import DisplayHtml from 'components/common/html';
import { getSchoolYearByTrainingPlanCategory } from '../../../../../admin/training-plan/common';
import { t1 } from 'translate';

const DigitalSupportPlan = ({
  value,
  formValuesByFormId,
  resultPlanFormValues,
  onChange,
  disabled,
  status,
  module,
  formid,
  hideExportExcel,
  isResultPlan,
  passedTrainingPlanCategories = '',
  syncErrors,
  dispatch,
  showScroll,
  userInfo,
  exportParams,
  masterReviewingPlan,
}) => {
  const defaultSource = dataSource(
    module,
    isResultPlan,
    passedTrainingPlanCategories,
  );

  const [combinedDataSource, setCombinedDataSource] = useState();
  const [shouldRenderForm, setShouldRenderForm] = useState();
  const [formValues, setFormValues] = useState();
  const [dataSourceOfModule, setDataSourceOfModule] = useState(defaultSource);
  const [renderTime, setRenderTime] = useState(Date.now());

  useEffect(
    () => {
      if (shouldRenderForm || !formValues) {
        return;
      }

      if (Object.keys(formValues).length) {
        setShouldRenderForm(true);
        setCombinedDataSource(false);
      }
    },
    [shouldRenderForm, formValues],
  );

  useEffect(
    () => {
      if (combinedDataSource) {
        return;
      }

      const enricSource = formatDataSource(
        defaultSource,
        formValues,
        isResultPlan,
      );

      setDataSourceOfModule(enricSource);

      setCombinedDataSource(true);
    },
    [
      formValues,
      combinedDataSource,
      dataSourceOfModule,
      defaultSource,
      isResultPlan,
    ],
  );

  useEffect(
    () => {
      if (!formValues && value && !isEqual(value, formValues)) {
        setFormValues({
          ...(formValuesByFormId || {}),
          ...value,
        });
      }
    },
    [value, formValues, formValuesByFormId],
  );

  useEffect(
    () => {
      if (
        !formValues ||
        isEqual(value, formValues) ||
        typeof onChange !== 'function'
      ) {
        return;
      }

      onChange(formValues);

      setRenderTime(Date.now());
    },
    [formValues, onChange, value],
  );

  useEffect(
    () => {
      if (formValues && !isEqual(value, formValues)) {
        setCombinedDataSource(false);
      }
    },
    [formValues, value],
  );

  const onChangeData = useCallback(
    (elementName, val) => {
      setFormValues((prevState) => {
        return {
          ...prevState,
          [elementName]: val,
          module,
        };
      });
    },
    [module],
  );

  const getAllValues = useCallback(
    () => {
      return {
        ...(resultPlanFormValues || {}),
        ...(formValuesByFormId || {}),
        ...(formValues || {}),
      };
    },
    [formValues, formValuesByFormId, resultPlanFormValues],
  );

  const deleteRow = (rowId) => {
    const valueFormCloned = JSON.parse(JSON.stringify(formValues));

    delete valueFormCloned[rowId];

    const fields = Object.keys(syncErrors || {});
    if (fields.length) {
      const fieldsWithRowId = fields.filter((key) => key.startsWith(rowId));

      fieldsWithRowId.forEach((field) => {
        dispatch(unregisterField(formid, field));
      });
    }

    setFormValues(valueFormCloned);
  };

  const addRow = (parentId) => {
    let itemId = new Date().getTime();

    setFormValues({
      ...formValues,
      [itemId]: {
        parentId: String(parentId),
        id: String(itemId),
      },
    });
  };

  const validateInRange = useCallback(
    (min, max) => {
      return (value) => {
        const maxValue = typeof max === 'function' ? max(getAllValues()) : max;

        return inRange(Number(min), Number(maxValue))(Number(value));
      };
    },
    [getAllValues],
  );

  const getValidationOfField = useCallback((rowData, fieldKey, fieldIndex) => {
    return get(rowData, `${dataKey.validation}.${fieldKey}.${fieldIndex}`, {});
  }, []);

  const getDisabledOfField = useCallback((rowData, fieldKey, fieldIndex) => {
    return get(rowData, `${dataKey.disabled}.${fieldKey}.${fieldIndex}`);
  }, []);

  const getFormatDataOfField = useCallback((rowData, fieldKey, fieldIndex) => {
    return get(rowData, `${dataKey.formatData}.${fieldKey}.${fieldIndex}`);
  }, []);

  const getNextAndPreviousValueOfField = useCallback(
    (rowData, fieldKey, fieldIndex) => {
      const prevElementName = `${rowData.id}-${fieldKey}-${fieldIndex - 1}`;
      const nextElementName = `${rowData.id}-${fieldKey}-${fieldIndex + 1}`;

      const previousValue = get(formValues, prevElementName) || undefined;
      const nextValue = get(formValues, nextElementName) || undefined;

      return [previousValue, nextValue];
    },
    [formValues],
  );

  const getDefaultValueOfField = useCallback(
    (rowData, fieldKey, fieldIndex, elementName) => {
      const getValueFunc = get(
        rowData,
        `${dataKey.defaultValue}.${fieldKey}.${fieldIndex}`,
      );

      const allValues = getAllValues();

      let dataFromFormData = get(allValues, elementName);

      if (typeof getValueFunc === 'function' && !dataFromFormData) {
        dataFromFormData = getValueFunc(allValues);
      }

      return dataFromFormData;
    },
    [getAllValues],
  );

  const shouldDisableField = useCallback(
    (rowData, fieldKey, fieldIndex) => {
      const checkDisable = getDisabledOfField(rowData, fieldKey, fieldIndex);

      return typeof checkDisable === 'function'
        ? checkDisable(formValues)
        : checkDisable;
    },
    [formValues, getDisabledOfField],
  );

  const renderContentForSupportPlan = useCallback(
    (firstValue, key, component, maxValue = 0, elementType) => {
      if (firstValue) {
        return (
          <span className="d-inline-flex align-items-center">
            <span className="m-r-5">{firstValue}</span>
            &nbsp;
            <span>
              (<span key={key}>{component}</span>
              <span className="plan-tag">
                /
                {typeof maxValue === 'function'
                  ? maxValue(formValues)
                  : maxValue}
              </span>
              )
            </span>
            &nbsp;
          </span>
        );
      }

      return (
        <>
          {elementType === KEY_MAPPING.date ? <>&nbsp;</> : null}
          <span key={key}>{component}</span>
          &nbsp;
        </>
      );
    },
    [formValues],
  );

  const renderContentForResultPlan = useCallback(
    (firstValue, key, component, maxValue, elementType) => {
      if (firstValue) {
        return (
          <span className="d-inline-flex align-items-center">
            <span className="m-r-5">{firstValue}</span>
            &nbsp;
            <span key={key}>{component}</span>
          </span>
        );
      }

      return (
        <>
          {elementType === KEY_MAPPING.date ? <>&nbsp;</> : null}
          <span key={key}>{component}</span>
          &nbsp;
        </>
      );
    },
    [],
  );

  const getNumberValidateField = useCallback(
    (rowData, fieldKey, fieldIndex, disabledRow) => {
      if (disabledRow) {
        return [];
      }

      const validation = getValidationOfField(rowData, fieldKey, fieldIndex);

      const maxValue = validation.max;
      const shouldValidateRequired =
        typeof validation.required === 'undefined' ? true : validation.required;
      const validateNumberField = [];
      if (shouldValidateRequired) {
        validateNumberField.push(required());
      }

      if (maxValue) {
        validateNumberField.push(validateInRange(0, maxValue));
      }

      return [validateNumberField, maxValue];
    },
    [getValidationOfField, validateInRange],
  );

  const getTextValidateField = useCallback(
    (rowData, fieldKey, fieldIndex, disabledRow) => {
      if (disabledRow) {
        return [];
      }

      const validation = getValidationOfField(rowData, fieldKey, fieldIndex);

      const shouldValidateRequired =
        typeof validation.required === 'undefined' ? true : validation.required;
      const validateTextField = [];
      if (shouldValidateRequired) {
        validateTextField.push(required());
      }

      return validateTextField;
    },
    [getValidationOfField],
  );

  const renderField = useCallback(
    (rowData, fieldKey, elementType, fieldIndex, viewOnly) => {
      const elementName = createFieldName(rowData.id, fieldKey, fieldIndex);

      const defaultValue = getDefaultValueOfField(
        rowData,
        fieldKey,
        fieldIndex,
        elementName,
      );
      const formatData = getFormatDataOfField(rowData, fieldKey, fieldIndex);
      const [previousValue, nextValue] = getNextAndPreviousValueOfField(
        rowData,
        fieldKey,
        fieldIndex,
      );

      const disabledRow = shouldDisableField(rowData, fieldKey, fieldIndex);

      const allValues = getAllValues();
      const firstValue = formatData && formatData(defaultValue, allValues);
      const shouldShowMaxValue =
        isResultPlan &&
        fieldKey === dataKey.result &&
        firstValue &&
        defaultValue;

      const [validateNumberField, maxValue] = getNumberValidateField(
        rowData,
        fieldKey,
        fieldIndex,
        disabledRow,
      );

      const validateText = getTextValidateField(
        rowData,
        fieldKey,
        fieldIndex,
        disabledRow,
      );

      let component;
      switch (elementType) {
        case KEY_MAPPING.number:
          component = viewOnly ? (
            <span className={'plan-tag'}>{defaultValue || '.....'}</span>
          ) : (
            <InputField
              elementType="number"
              name={elementName}
              onChange={(event) => {
                onChangeData(elementName, event.target.value);
              }}
              defaultValue={defaultValue}
              min={0}
              disabled={disabledRow}
              validate={validateNumberField}
              formid={formid}
              showErrorTooltip
              style={{
                width: 53,
                height: 26,
              }}
              className="p-5"
            />
          );
          break;
        case KEY_MAPPING.area:
          component = viewOnly ? (
            <span className={'plan-tag'}>{defaultValue || '.....'}</span>
          ) : (
            <InputField
              elementType="textarea"
              name={elementName}
              onChange={(event) => {
                onChangeData(elementName, event.target.value.trim());
              }}
              defaultValue={defaultValue}
              disabled={disabledRow}
              validate={validateText}
              formid={formid}
              rows={3}
              fullWidth
            />
          );
          break;

        case KEY_MAPPING.date:
          component = viewOnly ? (
            <span className={'plan-tag'}>
              {defaultValue
                ? moment(defaultValue * 1000).format(datetimeFormat.DATE_FORMAT)
                : '.....'}
            </span>
          ) : (
            <DatePickerField
              name={elementName}
              onChange={(event, date) => {
                onChangeData(elementName, date);
              }}
              defaultValue={defaultValue || undefined}
              minDate={previousValue}
              maxDate={nextValue}
              hideLabel
              formatDate={datetimeFormat.DATE_FORMAT}
              disabled={disabledRow}
              validate={validateText}
              className="birthday-wrapper full-width"
            />
          );
          break;

        default:
          component = null;
      }

      const key = `${elementName}-${
        shouldRenderForm ? 'rendered' : 'rendering'
      }`;

      if (isResultPlan && fieldKey !== dataKey.result) {
        return renderContentForResultPlan(
          firstValue,
          key,
          component,
          maxValue,
          elementType,
        );
      }

      return renderContentForSupportPlan(
        firstValue,
        key,
        component,
        maxValue,
        elementType,
      );
    },
    [
      formid,
      getAllValues,
      getDefaultValueOfField,
      getFormatDataOfField,
      getNextAndPreviousValueOfField,
      getNumberValidateField,
      isResultPlan,
      onChangeData,
      renderContentForResultPlan,
      renderContentForSupportPlan,
      shouldDisableField,
      shouldRenderForm,
      getTextValidateField,
    ],
  );

  const renderText = useCallback(
    (elementKey) => <p style={{ width: '100%' }}>{elementKey}</p>,
    [],
  );

  const getContent = useCallback(
    (item, field, viewOnly) => {
      return parsePlanToElement(item, field, renderField, renderText, viewOnly);
    },
    [renderField, renderText],
  );

  const renderTableColumns = (viewOnly) => {
    return [
      {
        title: t1('tt'),
        width: 50,
        type: TYPE_OF_TABLE_DATA.TITLE,
        dataIndex: 'tt',
        render: (data, row) => {
          const rowSpan = get(row, 'rowSpan');
          const colSpan = get(row, 'colSpan');

          return {
            children: (
              <span className={colSpan ? 'text-bold' : ''}>{data}</span>
            ),
            props: {
              rowSpan: rowSpan || 0,
            },
          };
        },
      },
      {
        title: t1('activity'),
        width: 300,
        type: TYPE_OF_TABLE_DATA.TEXT,
        className: 'text-justify',
        render: (row) => {
          const colSpan = get(row, 'colSpan');
          const id = get(row, 'id');
          const addAble = get(row, 'addAble');
          const addAbleTooltip = get(row, 'addAbleTooltip');
          const guideForAddable = get(row, 'guideForAddable');
          const tt = get(row, 'tt');

          return {
            children: (
              <div>
                <span style={{ fontWeight: colSpan ? 'bold' : undefined }}>
                  {getContent(row, dataKey.action, viewOnly)}
                </span>

                {guideForAddable && !viewOnly ? (
                  <div className="m-t-10">
                    <i className="text-danger">
                      <DisplayHtml content={guideForAddable} />
                    </i>
                  </div>
                ) : null}

                {addAble && !viewOnly ? (
                  <Tooltip
                    title={addAbleTooltip || `Thêm bổ sung cho mục: ${tt}`}
                  >
                    <Button
                      shape="circle"
                      className={'stt_icon_add ant-btn-primary'}
                      onClick={() => {
                        addRow(id);
                      }}
                    >
                      <Icon icon={'plus'} />
                    </Button>
                  </Tooltip>
                ) : null}
              </div>
            ),
            props: {
              colSpan: isResultPlan ? Number(colSpan) + 1 : colSpan,
            },
          };
        },
      },
      {
        title: t1('targets_to_be_achieved'),
        type: TYPE_OF_TABLE_DATA.TEXT,
        dataIndex: 'result',
        className: 'text-justify',
        render: (data, row) => {
          return {
            children: getContent(row, dataKey.result, viewOnly),
            props: {
              colSpan: typeof data !== 'undefined' ? 1 : 0,
              style: {
                maxWidth: 400,
              },
            },
          };
        },
      },
      ...(isResultPlan
        ? [
            {
              title: t1('final_result'),
              type: TYPE_OF_TABLE_DATA.TEXT,
              dataIndex: dataKey.finalResult,
              className: 'text-justify',
              render: (data, row) => {
                return {
                  children: getContent(row, dataKey.finalResult, viewOnly),
                  props: {
                    colSpan: typeof data !== 'undefined' ? 1 : 0,
                  },
                };
              },
            },
          ]
        : []),
      {
        title: t1('implementation_time'),
        type: TYPE_OF_TABLE_DATA.TEXT,
        width: 170,
        dataIndex: 'dateTime',
        render: (data, row) => {
          return {
            children: getContent(row, dataKey.dateTime, viewOnly),
            props: {
              colSpan: typeof data !== 'undefined' ? 1 : 0,
            },
          };
        },
      },
      {
        title: t1('teacher_support_plan_collaborators'),
        width: 170,
        dataIndex: 'corporate',
        render: (data, row) => {
          const parentId = get(row, 'parentId');
          const id = get(row, 'id');

          return {
            children: (
              <div>
                {getContent(row, dataKey.corporate, viewOnly)}

                {parentId && !disabled && (
                  <Button
                    shape="circle"
                    className={'stt_icon_remove'}
                    onClick={() => {
                      deleteRow(id);
                    }}
                  >
                    <Icon icon={'delete'} />
                  </Button>
                )}
              </div>
            ),
            props: {
              type: TYPE_OF_TABLE_DATA.TEXT,
              colSpan: typeof data !== 'undefined' ? 1 : 0,
            },
          };
        },
      },
    ];
  };

  const renderUserInfo = () => {
    const position = get(userInfo, 'leader_position', '');
    const organizationName = get(
      userInfo,
      '__expand.user_organizations.0.name',
      '',
    );
    const email = get(userInfo, 'mail', '');
    const phone = get(userInfo, 'phone', '');

    return (
      <>
        <div>
          Họ và tên GVPT/CBQLCSGDPT cốt cán: <b>{get(userInfo, 'name', '')}</b>
        </div>
        <div>
          Chức vụ: <b>{leaderPositionToText(position)}</b>
        </div>
        <div>
          Cở sở giáo dục đang công tác: <b>{organizationName}</b>
        </div>
        <div>
          {t1('email')}: <b>{email}</b>
        </div>
        <div>
          {t1('phone_number')}: <b>{phone}</b>
        </div>
      </>
    );
  };

  const isMasterApproved =
    status === supportPlanStatuses.SUPPORT_PLAN_MASTER_APPROVED;

  const shouldShowExportExcel =
    (isMasterApproved || masterReviewingPlan) && !hideExportExcel;

  return (
    <div className="digital-plan">
      {shouldShowExportExcel && (
        <div className="d-flex justify-content-between m-t-10 m-b-15">
          <div>{masterReviewingPlan && renderUserInfo()}</div>

          <div>
            <ExportData
              apiExport="/master-teacher/export/export-support-plan"
              buttonText="Xuất excel"
              params={{
                ...(exportParams || {}),
                training_plan_category: module,
                is_result_plan: isResultPlan,
              }}
              fileName={
                isResultPlan
                  ? `Báo-cáo-kết-quả-HTĐN-năm-${getSchoolYearByTrainingPlanCategory(
                      module,
                    )}`
                  : `Kế-hoạch-hỗ-trợ-mô-đun-${getStringPlanCategory(module)}`
              }
              updateTableBody={renderTime}
              tableExport={
                <CommonAntdTable
                  dataSource={dataSourceOfModule}
                  columns={renderTableColumns(true)}
                  pagination={false}
                  className="table-without-calendar"
                  defaultExpandAllRows={true}
                />
              }
            />
          </div>
        </div>
      )}

      <CommonAntdTable
        dataSource={dataSourceOfModule}
        columns={renderTableColumns(disabled)}
        pagination={false}
        className="table-without-calendar"
        defaultExpandAllRows={true}
        scroll={{
          x: showScroll ? true : isSmallScreen,
        }}
      />
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const { formid } = props;

  return {
    formValuesByFormId: getFormValues(formid)(state),
    syncErrors: getFormSyncErrors(formid)(state),
  };
};

export default compose(
  makeReduxFormCompatible({}),
  connect(mapStateToProps),
)(DigitalSupportPlan);
