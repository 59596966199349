import React from 'react';
import AntdTag from 'antd/lib/tag';
import makeReduxFormCompatible from 'components/common/makeReduxFormCompatible';
import lodashGet from 'lodash.get';

const { CheckableTag } = AntdTag;

const tagStyle = { border: '1px solid #d9d9d9' };
const AntdHotTags = ({ options, floatingLabelText, value, onChange }) => {
  if (!Array.isArray(options) || !options.length) {
    return null;
  }

  const handleChange = (tag, checked) => {
    let nextSelectedTags = Array.isArray(value) ? value : [];
    nextSelectedTags = checked
      ? [...nextSelectedTags, tag]
      : nextSelectedTags.filter((t) => t !== tag);

    if (typeof onChange === 'function') {
      onChange(nextSelectedTags);
    }
  };

  return (
    <div>
      {!!floatingLabelText && (
        <span style={{ marginRight: 8 }}>{floatingLabelText}</span>
      )}

      {options.map((option) => {
        const optionValue = lodashGet(option, 'value');

        return (
          <CheckableTag
            style={tagStyle}
            key={lodashGet(option, 'value')}
            checked={Array.isArray(value) && value.includes(optionValue)}
            onChange={(checked) => handleChange(optionValue, checked)}
          >
            {lodashGet(option, 'primaryText') ||
              lodashGet(option, 'label') ||
              lodashGet(option, 'value')}
          </CheckableTag>
        );
      })}
    </div>
  );
};

export default makeReduxFormCompatible({})(AntdHotTags);
