import React from 'react';
import { t1 } from 'translate';
import lodashGet from 'lodash.get';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import HelpIcon from 'components/common/Icon/Help';
import DetailOnDialog from 'components/common/detail-on-dialog';
import Freeze from 'components/common/freeze/Freeze';
import Alert from 'antd/lib/alert';
import { freezeMode } from 'configs/constants';

const FreezeAlert = ({ course, showAction, insideAlert }) => {
  if (!course) return null;

  const freeze = lodashGet(course, 'freeze');
  if (!freeze) return null;

  console.log({ freeze });

  const msg = (
    <div>
      {freeze == freezeMode.FREEZE_MODE_LEARN && (
        <div>
          {t1('learning_is_frozen')}{' '}
          <DetailOnDialog
            renderPreview={({ showFull }) => (
              <span onClick={showFull} className="cursor-pointer">
                <HelpIcon />
              </span>
            )}
            renderFull={() => (
              <div>
                {t1(
                  'course_is_frozen_and_students_cannot_learn_to_update_score_or_progress',
                )}
              </div>
            )}
            dialogOptionsProperties={{
              handleClose: true,
              title: `${t1('learning_is_frozen')}`,
            }}
          />
        </div>
      )}

      {freeze == freezeMode.FREEZE_MODE_COMPLETELY && (
        <div>
          {t1('course_is_completely_frozen')}{' '}
          <DetailOnDialog
            renderPreview={({ showFull }) => (
              <span onClick={showFull} className="cursor-pointer">
                <HelpIcon />
              </span>
            )}
            renderFull={() => (
              <div>
                {t1(
                  'course_is_frozen_and_students_cannot_learn_to_update_score_or_progress_and_you_cannot_grade',
                )}
                .
                {t1(
                  'if_you_want_to_grade_ask_your_training_manager_to_open_it',
                )}
              </div>
            )}
            dialogOptionsProperties={{
              handleClose: true,
              title: `${t1('course_is_completely_frozen')}`,
            }}
          />
        </div>
      )}
      {showAction && <Freeze node={course} ntype="course" />}
    </div>
  );

  if (!insideAlert) return msg;

  return (
    <Alert
      message={msg}
      type={freeze == freezeMode.FREEZE_MODE_COMPLETELY ? 'error' : 'success'}
    />
  );
};

export default FreezeAlert;
