import { t1 } from 'translate';

export const BLOCK_IMAGE_SIZE_FULL = 'full';
export const BLOCK_IMAGE_SIZE_NORMAL = 'normal';

export default () => ({
  type: 'select',
  options: [
    {
      value: BLOCK_IMAGE_SIZE_FULL,
      label: t1('size_full_width_container'),
    },
    {
      value: BLOCK_IMAGE_SIZE_NORMAL,
      label: t1('size_normal'),
    },
  ],
  floatingLabelText: t1('image_size'),
  floatingLabelFixed: true,
  fullWidth: true,
  defaultValue: BLOCK_IMAGE_SIZE_FULL,
});
