import React from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash.get';
import { checkLearnerCanDownloadAttachments } from 'common/conf';
import Alert from 'antd/lib/alert';

import './style.scss';
import ListAttachmentsToDownloadAsTable from 'components/common/attachment/ListAttachmentsToDownloadAsTable';

class DownloadCourseAttachments extends React.Component {
  render() {
    const { node, showTitle = false } = this.props;
    const attachments = lodashGet(node, 'download_materials');

    let downloads = [];

    if (Array.isArray(attachments) && attachments.length)
      downloads = downloads.concat(attachments);

    const contentDialog =
      Array.isArray(downloads) && downloads.length ? (
        <ListAttachmentsToDownloadAsTable
          attachments={downloads}
          compact={false}
          allowDownload={!lodashGet(node, 'disable_download_materials')}
        />
      ) : null;

    let content = (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Alert type={'error'} message={'Không có tài liệu tham khảo'} />
          </div>
        </div>
      </div>
    );

    if (
      this.props.canLearnerDownloadAttachments &&
      (Array.isArray(attachments) && attachments.length)
    ) {
      content = (
        <div style={{ width: '100%' }}>
          <div>{contentDialog}</div>
        </div>
      );
    }

    return (
      <div>
        {showTitle && <h1>Tài liệu tham khảo</h1>}
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    canLearnerDownloadAttachments: checkLearnerCanDownloadAttachments(
      lodashGet(state, 'domainInfo.conf'),
    ),
  };
};

export default connect(mapStateToProps)(DownloadCourseAttachments);
