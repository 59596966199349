import React from 'react';
import Modal from 'antd/lib/modal';
import { t1 } from 'translate';
import endpoints from 'components/bdtx/training-phase/endpoints';
import get from 'lodash.get';
import store from 'store';
import sagaActions from 'actions/node/saga-creators';
import { Icon } from 'antd';

const CancelDeploy = ({
  module = {},
  organization = {},
  hiddenFields = {},
  searchFormId,
  formIdsToSubmitOnSuccess,
}) => {
  const onCancelDeploy = () => {
    const payload = {
      apiUrl: endpoints.bdtx_cancel_deploy_for_organization,
      data: {
        module_iid: get(module, 'iid'),
        organization_iid: get(organization, 'iid'),
        ...hiddenFields,
      },
      searchFormId: searchFormId,
      formIdsToSubmitOnSuccess,
    };

    store.dispatch(sagaActions.upsertNodeRequest(payload));
  };

  const showConfirmation = () => {
    Modal.confirm({
      centered: true,
      icon: (
        <Icon type="close-circle" style={{ color: 'red', fontSize: '28px' }} />
      ),
      title: 'Bạn có chắc chắn muốn hủy bỏ triển khai',
      content: (
        <div>
          Bạn đang thực hiện{' '}
          <span className="text-danger">hủy bỏ triển khai</span> của mô đun{' '}
          <b>{module.name}</b> cho đơn vị <b>{organization.name}</b>
          <div className="m-t-5 text-danger">
            Nếu bạn hủy bỏ triển khai, toàn bộ GV/CBQL sẽ bị đẩy ra ngoài lớp
            học
          </div>
        </div>
      ),
      onOk() {
        onCancelDeploy();
      },
      cancelText: t1('cancel'),
      okText: 'Tôi hiểu, hủy triển khai',
    });
  };

  return (
    <button className="btn btn-tertiary btn-small" onClick={showConfirmation}>
      Hủy triển khai
    </button>
  );
};

export default CancelDeploy;
