import {
  COURSES_FIND_REQUEST,
  COURSES_FIND_REQUEST_ERROR,
  COURSES_FIND_REQUEST_SUCCESS,
} from 'actions/course';

const initialState = {
  courseSetup: {
    isLoading: false,
    error: null,
    result: {},
  },
};

const course = (state = initialState, action) => {
  switch (action.type) {
    case COURSES_FIND_REQUEST: {
      return {
        ...state,
        courseSetup: {
          ...state.courseSetup,
          isLoading: true,
          error: null,
          result: {},
        },
      };
    }
    case COURSES_FIND_REQUEST_SUCCESS: {
      return {
        ...state,
        courseSetup: {
          ...state.courseSetup,
          isLoading: false,
          error: null,
          result: action.result,
        },
      };
    }
    case COURSES_FIND_REQUEST_ERROR: {
      return {
        ...state,
        courseSetup: {
          ...state.courseSetup,
          isLoading: false,
          error: action.error,
          result: {},
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default course;
