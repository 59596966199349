import Perm from 'common/utils/Perm';

export const initOmi = (appId) => {
  if (!window.omi_API) {
    window.omi_API = true;
    const s1 = document.createElement('script');
    const s0 = document.getElementsByTagName('script')[0];
    s1.id = 'omiWidgetScript';
    s1.src = `https://minio.infra.omicrm.com/widget/click2call.js#domain=${appId}`;
    s1.type = 'text/javascript';
    s0.parentNode.insertBefore(s1, s0);
  }
};

export const isOmiInitialized = () => !!window.omi_API;

export const showOmi = () => {
  if (window.omi_API) {
    document
      .querySelectorAll('*[id^="omi"]')
      .forEach((e) => e.removeAttribute('style'));
  }
};

export const hideOmi = () => {
  if (window.omi_API) {
    document
      .querySelectorAll('*[id^="omi"]')
      .forEach((e) => e.setAttribute('style', 'display: none !important'));
  }
};

export const shouldShowOmi = (pathname, showOmi) => {
  if (!Array.isArray(showOmi)) {
    return false;
  }
  if (Perm.isGuest()) {
    return false;
  }
  if (showOmi.includes('admin') && pathname.indexOf('/admin') === 0) {
    return true;
  }
  if (showOmi.includes('learn')) {
    // On Learn screen, will be hide Tawk
    if (pathname.indexOf('/learn') >= 0) {
      return false;
    }

    if (pathname.indexOf('/admin') === -1) {
      return true;
    }
  }
  return false;
};
