import lodashGet from 'lodash.get';
import {
  getTsFromTimeString,
  parseIsoTimeToTs,
  timeStringToSeconds,
} from 'common/utils/Date';

export const isScormFinished = (cmi) => {
  let status = lodashGet(cmi, 'completion_status');
  if (!status) {
    status = lodashGet(cmi, 'core.lesson_status');
  }

  return ['success', 'completed'].includes(status) ? 1 : 0;
};

export const getScormProgress = (cmi) => {
  if (isScormFinished(cmi)) {
    return 1;
  }

  return lodashGet(cmi, 'progress_measure') || 0;
};

export const getScormSpentTime = (cmi, serverTs) => {
  // scorm 2004: iso time
  const isoTime = lodashGet(cmi, 'total_time');
  if (isoTime) {
    return parseIsoTimeToTs(isoTime);
  }

  // scorm 1.2: HH:MM:SS
  const time = lodashGet(cmi, 'core.total_time');
  if (!time) {
    return 0;
  }

  return timeStringToSeconds(time);
};

export const getScormInteractionsMaxScore = (cmi) => {
  return lodashGet(cmi, 'score.max') || lodashGet(cmi, 'core.score.max');
};

export const getScormInteractionsScore = (cmi) => {
  return lodashGet(cmi, 'score.raw') || lodashGet(cmi, 'core.score.raw') || 0;
};

export const isScormInteractionsPassed = (cmi) => {
  const status =
    lodashGet(cmi, 'success_status') || lodashGet(cmi, 'core.lesson_status');

  return ['success', 'completed'].includes(status) ? 1 : 0;
};

const getInteractionTs = (interaction, serverTs) => {
  const timestamp = lodashGet(interaction, 'timestamp');
  if (timestamp) {
    const dt = new Date(timestamp);
    return dt.getTime() / 1000; // ts in second
  }

  // format of scorm 1.2: 0000:01:44.45
  const timeStr = lodashGet(interaction, 'time');

  return getTsFromTimeString(serverTs, timeStr);
};

const getInteractionAnswer = (interaction) => {
  return (
    lodashGet(interaction, 'learner_response') ||
    lodashGet(interaction, 'student_response')
  );
};

export const getScormInteractionsQuestions = (cmi, serverTs) => {
  const interactionsObj = lodashGet(cmi, 'interactions') || {};

  let interactionsCount = lodashGet(interactionsObj, '_count');
  if (!interactionsCount) {
    interactionsCount = Object.keys(interactionsObj).length;
  }

  if (!interactionsCount) {
    return [];
  }

  const interactions = [];
  for (let i = 0; i < interactionsCount; i++) {
    const interaction = lodashGet(cmi, `interactions.${i}`);
    if (!interaction) {
      continue;
    }

    const ts = getInteractionTs(interaction, serverTs);
    const answer = getInteractionAnswer(interaction);
    const correct = lodashGet(interaction, 'result') === 'correct' ? 1 : 0;
    const type = lodashGet(interaction, 'type') || 'unknown';
    const content = lodashGet(interaction, 'description');
    const weighted = lodashGet(interaction, 'weighting');

    interactions.push({
      type,
      content,
      answer,
      weighted,
      ts,
      pf: correct,
    });
  }

  return interactions;
};

export const isFillInQuestion = (type) => {
  return ['fill-in', 'long-fill-in'].includes(type);
};

export const getTimeFinishExercise = (cmi, serverTs) => {
  const interactionsObj = lodashGet(cmi, 'interactions') || {};

  let interactionsCount = lodashGet(interactionsObj, '_count');
  if (!interactionsCount) {
    interactionsCount = Object.keys(interactionsObj).length;
  }

  const lastInteractionIndex = interactionsCount - 1; // get by index
  const interaction = lodashGet(interactionsObj, `${lastInteractionIndex}`);

  return getInteractionTs(interaction, serverTs);
};

export const isDisableScormTrackingProgressForItem = (itemIid = undefined) => {
  if (!itemIid) {
    return false;
  }

  const items =
    lodashGet(window, 'disableScormApiTrackingProgressForItems') || [];

  if (!items.length) {
    return false;
  }

  return items.includes(Number(itemIid));
};

export const isEnableScormTrackingProgressForItem = (itemIid = undefined) => {
  if (!itemIid) {
    return false;
  }

  const items =
    lodashGet(window, 'enableScormApiTrackingProgressForItems') || [];

  if (!items.length) {
    return false;
  }

  return items.includes(Number(itemIid));
};

export const isEnableScormTrackingProgressForCourse = (
  courseIid = undefined,
) => {
  if (!courseIid) {
    return false;
  }

  const courseIids =
    lodashGet(window, 'enableScormTrackingProgressForCourses') || [];

  if (!courseIids.length) {
    return false;
  }

  return courseIids.includes(Number(courseIid));
};

export const isDisableScormTrackingProgress = (
  courseIid = undefined,
  itemIid = undefined,
) => {
  // neu config khong realtime tracking progress cho scorm player, se apply cho toan bo scorm trong lms
  // nghia la chi can vao scorm file se duoc 50%, ra item khac se duoc 100%
  const disabledTracking = lodashGet(window, 'disableScormApiTrackingProgress');

  if (disabledTracking) {
    // khi disabled scorm tracking progress nhung lai muon chi enable cho mot so course
    if (
      isEnableScormTrackingProgressForCourse(courseIid) ||
      isEnableScormTrackingProgressForItem(itemIid)
    ) {
      return false; // nghia la khong disable tracking scorm voi course hoac item nay
    }

    return true;
  }

  // enable scorm tracking nhung lai disable cho mot so learn item iid
  return isDisableScormTrackingProgressForItem(itemIid);
};
