import React from 'react';
import get from 'lodash.get';
import { Redirect, withRouter } from 'react-router';
import { renderRoutes } from 'react-router-config';
import SubLeftMenuContext from 'common/context/menu/SubMenuLeft';
import SubTopMenuContext from 'common/context/menu/SubMenuTop';
import reviewRoutes, {
  getUrlByReviewExamIid,
  MAIN_STAGE_VIEW_TYPE,
} from './routes';
import topMenuSchema from '../top-menu-schema';

const ReviewExamMainStage = ({ match }) => {
  const action = get(match, 'params.action');
  const examIid = get(match, 'params.iid');

  const menuItems = [
    {
      id: 'review-exam-dashboard',
      url: getUrlByReviewExamIid(examIid, MAIN_STAGE_VIEW_TYPE.DASHBOARD),
      title: 'Tổng quan',
    },
  ];

  if (!action) {
    return <Redirect to={getUrlByReviewExamIid(examIid, 'dashboard')} />;
  }

  const isOverview = action === MAIN_STAGE_VIEW_TYPE.DASHBOARD;

  return (
    <>
      <SubLeftMenuContext schema={menuItems} />

      <SubTopMenuContext
        schema={isOverview ? topMenuSchema(match) : () => {}}
        lastBreadcrumbName={'review-exam-store'}
        description={'review exam store'}
      />

      {renderRoutes(reviewRoutes)}
    </>
  );
};

export default withRouter(ReviewExamMainStage);
