import { requiredEvidenceMustHaveFullFiles } from 'components/temis/utils';

export const evidenceElement = (props = {}) => {
  const mustHaveFullFiles = requiredEvidenceMustHaveFullFiles();

  return {
    type: 'radio',
    inline: true,
    floatingLabelText: 'Thông tin Minh Chứng (MC)',
    defaultValue: -1,
    options: [
      { label: 'Tất cả', value: -1 },
      {
        label: 'Đầy đủ Minh chứng',
        guide: `Tất cả các tiêu chí được đánh giá từ mức Đạt trở lên có Minh chứng${
          mustHaveFullFiles
            ? ' và Minh chứng phải có tài liệu file đính kèm (Word, pdf, hình ảnh...)'
            : ''
        }.`,
        value: 1,
      },
      {
        label: 'Chưa đầy đủ Minh chứng',
        guide: mustHaveFullFiles
          ? 'Có một hoặc nhiều tiêu chí đánh giá từ mức Đạt trở lên "không có Minh chứng hoặc Minh chứng không có tài liệu file đính kèm".'
          : 'Có một hoặc nhiều tiêu chí đánh giá từ mức Đạt trở lên không có Minh chứng',
        value: 0,
      },
    ],
    ...props,
  };
};
