import React, { Component } from 'react';
import ButtonSendNotification from 'components/common/communication/ButtonSendNotification';
import { t, t1 } from 'translate';
import lodashGet from 'lodash.get';
import apiUrls from 'api-endpoints';
import { generateMessageInfo } from 'components/common/communication/utils';

class NotifyWarningButton extends Component {
  render() {
    const { userIds, totalResult, conditionToSearch } = this.props;
    const numberOfItemSelected = lodashGet(userIds, 'length');

    return (
      <ButtonSendNotification
        {...this.props}
        totalResult={totalResult}
        itemSelected={numberOfItemSelected}
        label={t1('notify_warning')}
        dialogTitle={t1('send_notify_warning')}
        formid={'notify_warning_account_batch'}
        message={generateMessageInfo(numberOfItemSelected, totalResult)}
        isNotifyWarningAccount
        type="accounts"
        hiddenFields={{
          ids: userIds,
          condition_to_search: conditionToSearch,
          totalResult: totalResult,
          itemSelected: numberOfItemSelected,
        }}
        alternativeApi={apiUrls.user_notification}
      />
    );
  }
}
export default NotifyWarningButton;
