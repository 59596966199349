import React from 'react';
import lodashGet from 'lodash.get';
import { withRouter } from 'react-router';
import Modal from 'antd/lib/modal';
import { t1 } from 'translate';
import NodeNew from 'components/admin/node/new';
import endpoints from 'components/bdtx/training-phase/endpoints';
import schema from 'components/bdtx/training-phase/mainstage/new/schema';
import {
  getUrlByTrainingPhaseIid,
  MAIN_STAGE_VIEW_TYPE,
} from 'components/bdtx/training-phase/mainstage/routes';

const FORM_ID = 'new-training-phase';

const NewTrainingPhase = ({
  node,
  readOnly,
  deployed,
  hiddenFields = {},
  searchFormId,
  mode,
  isBgd,
  formid,
  history,
  alternativeApi,
  onEditSuccessful,
  onNewSuccessful,
  submitLabel,
  closeModal = true,
  turnOffNotifications = false,
}) => {
  const api = lodashGet(node, 'id')
    ? endpoints.bdtx_update_training_phase
    : endpoints.bdtx_new_training_phase;

  const gotoTrainingPlan = () => {
    window.location.href = getUrlByTrainingPhaseIid(
      node.iid,
      MAIN_STAGE_VIEW_TYPE.ASSIGN,
    );
  };

  const requestSuccessful = (data) => {
    const result = lodashGet(data, 'result');

    if (mode === 'edit') {
      Modal.confirm({
        centered: true,
        title: 'Có sự thay đổi về đợt đào tạo',
        content:
          'Bạn vừa thay đổi đợt đào tạo, điều này sẽ ảnh hưởng đến kế hoạch đào tạo, bạn có muốn thay đổi kế hoạch đào tạo?',
        onOk() {
          gotoTrainingPlan();
        },
        cancelText: t1('cancel'),
        okText: 'Sang màn hình kế hoạch đào tạo',
      });

      if (typeof onEditSuccessful === 'function') {
        onEditSuccessful(result);
      }
    } else {
      if (typeof onNewSuccessful == 'function') {
        onNewSuccessful(result);
      }
    }
  };

  return (
    <NodeNew
      schema={schema}
      hiddenFields={hiddenFields}
      formid={formid || FORM_ID}
      alternativeApi={api}
      searchFormId={searchFormId}
      resetForm={false}
      node={node}
      readOnly={readOnly}
      mode={lodashGet(node, 'id') ? 'edit' : 'new'}
      requestSuccessful={requestSuccessful}
      submitLabel={submitLabel}
      deployed={deployed}
      closeModal={closeModal}
      isBgd={isBgd}
      turnOffNotifications={turnOffNotifications}
    />
  );
};

export default withRouter(NewTrainingPhase);
