import React from 'react';
import { connect } from 'react-redux';
import Display from 'components/learn/items/lecture/media-lecture/display';
import { setExtraInfoForCurrentItem } from 'actions/learn';
import {
  getLearningProgressBasedOnSpentTime,
  learnItemTypes,
} from 'components/learn/utils';
import './style.scss';

const DEFAULT_NUMBER_OF_INTERVAL = 10;
const NUMBER_OF_INTERVAL_TO_SAVE = 2;

class MediaLecture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spentTimeCalculatePoint: null,
      startTime: null,
      endTime: null,
      watchedIntervals: [],
      currentInterval: null,
    };

    this.hasResendProgress = false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentInterval, watchedIntervals = [] } = this.state;
    const { currentInterval: prevCurrentInterval } = prevState;

    const { watchedIntervals: watchedIntervalsProps = [] } = this.props;

    const isOverNumberOfIntervalToSave =
      watchedIntervals.length - watchedIntervalsProps.length >=
      NUMBER_OF_INTERVAL_TO_SAVE;

    const isDifferenceInterval = currentInterval !== prevCurrentInterval;

    const isFinishLearning =
      watchedIntervals.length === DEFAULT_NUMBER_OF_INTERVAL;

    if (!watchedIntervals.length && watchedIntervalsProps.length) {
      this.setState({
        watchedIntervals: watchedIntervalsProps,
      });
    }

    if (
      isDifferenceInterval &&
      (isOverNumberOfIntervalToSave || isFinishLearning)
    ) {
      this.saveLearningProgress();
    }

    // Fixed for case: PF = 1, P = 100 but CP  = 0
    if (this.shouldResendProgress()) {
      this.resendProgress();
    }
  }

  componentWillUnmount() {
    const { watchedIntervals = [] } = this.state;
    const { watchedIntervals: watchedIntervalsProps = [] } = this.props;

    if (
      watchedIntervals &&
      watchedIntervalsProps &&
      watchedIntervals.length !== watchedIntervalsProps.length
    ) {
      this.saveLearningProgress();
    }
  }

  shouldResendProgress = () => {
    const { learningCP } = this.props;

    return this.isFinish() && learningCP != 100 && !this.hasResendProgress;
  };

  resendProgress = () => {
    const {
      saveLearningProgress,
      currentTrackerProgress,
      learnItemIid,
    } = this.props;
    if (typeof saveLearningProgress === 'function') {
      saveLearningProgress([
        {
          ...currentTrackerProgress,
          tco_iid: learnItemIid,
        },
      ]);

      this.hasResendProgress = true;
    }
  };

  getSpentTimeCalculatePointState = (spentTimeCalculatePoint) => ({
    spentTimeCalculatePoint,
  });

  getTimeState = (startTime, endTime) => ({
    startTime,
    endTime,
  });

  getWatchedIntervalsState = (watchedIntervals) => ({
    watchedIntervals,
  });

  getCurrentIntervalState = (interval) => ({
    currentInterval: interval,
  });

  handleMediaPlayerStart = () => {
    this.setState(
      Object.assign({}, this.getSpentTimeCalculatePointState(new Date())),
    );
  };

  checkVideoInterval = (time) => {
    const { numberOfIntervals } = this.props;
    const { startTime, endTime } = this.state;

    const interval = Math.floor(
      ((time - startTime) / (endTime - startTime)) * numberOfIntervals,
    );
    if (interval < 0 || interval >= numberOfIntervals) {
      return NaN;
    }
    return interval;
  };

  getLearningProgressBasedOnWatchedIntervals = () => {
    const { numberOfIntervals } = this.props;
    const watchedIntervalsState = this.state.watchedIntervals;
    const watchedIntervals = watchedIntervalsState.filter(
      (interval, index, arr) => arr.indexOf(interval) === index,
    );

    return {
      learningProgress: Math.ceil(
        (watchedIntervals.length / numberOfIntervals) * 100,
      ),
      watchedIntervals,
    };
  };

  getLearningProgress = () => {
    const oldLearningProgress = this.props.learningProgress;
    const learningProgressBasedOnWatchedIntervals = this.getLearningProgressBasedOnWatchedIntervals();
    const learningProgressBasedOnSpentTime = getLearningProgressBasedOnSpentTime(
      {
        startTime: this.state.startTime,
        endTime: this.state.endTime,
        spentTime: this.props.spentTime,
        spentTimeCalculatePoint: this.state.spentTimeCalculatePoint,
      },
    );
    return {
      learningProgress: Math.max(
        oldLearningProgress,
        learningProgressBasedOnWatchedIntervals.learningProgress,
      ),
      watchedIntervals:
        learningProgressBasedOnWatchedIntervals.watchedIntervals,
      spentTime: learningProgressBasedOnSpentTime.spentTime,
    };
  };

  saveLearningProgress = () => {
    const newLearningProgress = this.getLearningProgress();
    const {
      learnItemIid,
      numberOfIntervals,
      isPreview,
      isReview,
      saveLearningProgress,
    } = this.props;
    if (isPreview || isReview || this.isFinish()) {
      return;
    }

    saveLearningProgress([
      {
        tco_iid: learnItemIid,
        p: newLearningProgress.learningProgress,
        pd: {
          n: numberOfIntervals,
          i: newLearningProgress.watchedIntervals,
        },
        time_spent: newLearningProgress.spentTime,
      },
    ]);

    this.setState(
      Object.assign({}, this.getSpentTimeCalculatePointState(new Date())),
    );
  };

  setExtraInfo = (duration) => {
    const { dispatch } = this.props;
    dispatch(setExtraInfoForCurrentItem(learnItemTypes.video, duration));
  };

  handleMediaPlayerProgress = (progress) => {
    const { playedSeconds } = progress;
    const { watchedIntervals, currentInterval } = this.state;
    if (playedSeconds) {
      const interval = this.checkVideoInterval(playedSeconds);

      if ((interval || interval === 0) && interval !== currentInterval) {
        this.setState(
          Object.assign(
            {},
            this.getCurrentIntervalState(interval),
            watchedIntervals.indexOf(interval) === -1 &&
              this.getWatchedIntervalsState(
                watchedIntervals.concat([interval]),
              ),
          ),
        );
      }
      this.setExtraInfo(playedSeconds);
    }
  };

  handleMediaPlayerTime = (startTime, endTime) => {
    this.setState(Object.assign({}, this.getTimeState(startTime, endTime)));
  };

  handleMediaPlayerEnded = () => {
    this.saveLearningProgress();
  };

  isFinish = () => {
    const { learningProgress } = this.props;

    return learningProgress == 100;
  };

  render() {
    const { type, node, playerId, autoPlay = true } = this.props;

    return (
      <div className={type === 'video' ? 'lecture-video' : 'lecture-audio'}>
        <Display
          controls
          autoPlay={autoPlay}
          className={type === 'video' ? 'learn-full-screen' : ''}
          type={type}
          node={node}
          onStart={this.handleMediaPlayerStart}
          onProgress={this.handleMediaPlayerProgress}
          onEnded={this.handleMediaPlayerEnded}
          handleMediaTime={this.handleMediaPlayerTime}
          canSkipQuestion={this.isFinish()}
          progressInterval={100}
          playerId={playerId}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { learnItemIid } = props;
  const trackerProgress = state.trackerProgress[learnItemIid];
  const spentTime = (trackerProgress && trackerProgress.time_spent) || 0;
  const learningProgress = (trackerProgress && trackerProgress.p) || 0;
  const learningPF = (trackerProgress && trackerProgress.pf) || 0;
  const learningCP = (trackerProgress && trackerProgress.cp) || 0;

  // progress details will be an object with the following fields
  // pd: {
  //   n: number of intervals,
  //   i: watched intervals,
  // }
  // if i.length === n then all intervals are watched

  const details = (trackerProgress && trackerProgress.pd) || {};
  const numberOfIntervals =
    Number.parseInt(details.n, 10) || DEFAULT_NUMBER_OF_INTERVAL;
  const watchedIntervals = (Array.isArray(details.i) ? details.i : []).map(
    (interval) => Number.parseInt(interval, 10),
  );

  return {
    spentTime,
    learningProgress,
    numberOfIntervals,
    watchedIntervals,
    learningPF,
    learningCP,
    currentTrackerProgress: trackerProgress,
  };
};

export default connect(mapStateToProps)(MediaLecture);
