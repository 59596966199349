import React from 'react';
import { getAttachment } from 'components/learn/items/lecture/common';
import { replaceStaticToStatic2 } from 'common/utils/url';

const Swf = ({ node, className }) => {
  const attachment = getAttachment(node);
  return (
    <div className={`${className || ''} lecture-swf`}>
      {node && attachment && attachment.link && (
        <embed
          src={replaceStaticToStatic2(attachment.link)}
          style={{ width: '100%', height: '100%' }}
        />
      )}
    </div>
  );
};

export default Swf;
