import React from 'react';
import PropTypes from 'prop-types';
import { t1 } from 'translate';
import schema from './schema';
import Alert from 'antd/lib/alert';
import Communication from 'components/common/communication/index';

class Form extends React.PureComponent {
  render() {
    const {
      requestSuccessful,
      searchFormId,
      formid,
      message,
      dialogKey,
      alternativeApi,
      hiddenFields,
      type,
      ...remainProps
    } = this.props;
    const { itemSelected, totalResult } = hiddenFields;

    let hiddenFieldsModified = hiddenFields;

    if (!itemSelected) {
      hiddenFieldsModified = { ...hiddenFields, send_all: 1 };
    }

    return (
      <div>
        <div>
          <Alert message={message} type="info" showIcon />
        </div>
        <br />
        <Communication
          hiddenFields={hiddenFieldsModified}
          dialogKey={dialogKey}
          formid={formid}
          schema={schema}
          submitLabels={{
            new: t1('submit'),
          }}
          type={type}
          requestSuccessful={requestSuccessful}
          alternativeApi={alternativeApi}
          {...remainProps}
        />
      </div>
    );
  }
}

Form.propTypes = {
  className: PropTypes.string,
};
Form.defaultProps = {
  className: '',
};
export default Form;
