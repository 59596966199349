export default {
  dataToAssessment: '/assessment/api/get-data-to-assessment',
  assessmentResult: '/assessment/api/get',
  assessOfPeers: '/assessment/api/get-results-assessment-of-peers-about-me',
  searchUser: '/assessment/api/search-users',
  assignToAssess: '/assessment/api/assign-peers-to-assess',
  listUserMustToApproveAssessment:
    '/assessment/api/list-user-must-to-approve-assessment',
  listOfPeersAssignedToAssess:
    '/assessment/api/list-of-peers-assigned-to-assess',
  listUserHaveDesireToBecomeMaster:
    '/temis/api/get-users-have-desire-to-become-master',
  assessmentOfPeersInPhongBan:
    '/assessment/api/assessment-of-peers-in-phongban',
  doAssessment: '/assessment/api/do-assessment',
  getRubricsOfAssessmentInsufficientEvidence:
    '/assessment/api/get-rubrics-of-assessment-insufficient-evidence',
  chooseUsersToBecomeMaster: '/temis/api/choose-users-to-become-master',
  getOverviewData: '/temis/api/temis-overview',
  masterSetStatusAccount: '/temis/api/master-set-status-account', //TODO: Need to update when API ready
  get_tieu_chi: '/bdtx/tcnn/get-tieu-chi',
  get_personal_orientation_content:
    '/ncbdtx/schema-form/get-personal-orientation-content',
  get_personal_orientation_time_doing:
    '/ncbdtx/schema-form/get-personal-orientation-time-doing',
  get_personal_orientation_performance_conditions:
    '/ncbdtx/schema-form/get-personal-orientation-performance-conditions',
  get_user: '/user/api/search',
};
