import React, { Component } from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import { connect } from 'react-redux';
import { userOrganizationAndPhongbanIidsSelector } from 'common/selectors';
import UserResults from './Results';
import schemaNew from 'components/admin/user/account/account-search/schema/schema-new';
import lodashGet from 'lodash.get';
import checkRoot from 'common/hoc/checkRoot';
import apiUrls from '../../endpoints';

class UserInSchoolSearch extends Component {
  prepareDataBeforeSearch = (values) => {
    let newValues = values;
    if (
      Array.isArray(newValues.credit_syllabuses) &&
      newValues.credit_syllabuses.length > 0
    ) {
      newValues = {
        ...newValues,
        credit_syllabuses: values.credit_syllabuses.map(
          (creditSyllabus) => creditSyllabus.iid,
        ),
      };
    }

    return newValues;
  };

  renderResultsComponent = (items, props, objects, searchValues) => {
    const {
      isRoot,
      showEditAccountButton,
      renderBottomActions,
      multiSelectable,
    } = this.props;

    return (
      <div>
        <UserResults
          {...this.props}
          {...props}
          searchValues={searchValues}
          items={items}
          showEditAccountButton={
            typeof showEditAccountButton !== 'undefined'
              ? showEditAccountButton
              : isRoot
          }
          multiSelectable={multiSelectable}
          renderBottomActions={renderBottomActions}
        />
      </div>
    );
  };

  render() {
    const {
      orgIids,
      formid,
      searchFormId,
      isSearchParent,
      alternativeApi,
      searchUserSchema,
      showSearchButton,
      includeSubOrg,
      expandFields,
      resetForm,
      onSubmit,
      autoSearchWhenStart,
      classFormFilter,
    } = this.props;

    let hiddenFields = this.props.hiddenFields || {};

    hiddenFields = {
      ...hiddenFields,
      ntype: 'user',
      _sand_step: isSearchParent ? 'parent' : 'students',
      _sand_expand: expandFields || [
        'user_organizations',
        'positions',
        'phongbans',
      ],
      orgIids,
    };

    return (
      <SearchWrapper
        formid={formid || 'user_school_search'}
        searchFormId={searchFormId || 'user_school_search'}
        hiddenFields={hiddenFields}
        renderResultsComponent={
          this.props.renderResultsComponent || this.renderResultsComponent
        }
        orgIids={orgIids}
        onResultChange={this.props.onResultChange}
        prepareDataBeforeSearch={
          typeof this.props.prepareDataBeforeSearch === 'function'
            ? this.props.prepareDataBeforeSearch
            : this.prepareDataBeforeSearch
        }
        showQueryField={false}
        showSearchButton={showSearchButton || false}
        schema={searchUserSchema ? searchUserSchema : schemaNew}
        alternativeApi={alternativeApi ? alternativeApi : apiUrls.searchUser}
        autoSearchWhenStart={
          typeof autoSearchWhenStart !== 'undefined'
            ? autoSearchWhenStart
            : true
        }
        noResultImage={'/media/images/empty/person.png'}
        includeSubOrg={includeSubOrg}
        resetForm={resetForm}
        onSubmit={onSubmit}
        method={'post'}
        classFormFilter={classFormFilter}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const departmentAndPhongbanIids =
    props.orgIids || userOrganizationAndPhongbanIidsSelector(state);

  let includeSubOrg = lodashGet(
    state,
    `valueFieldsToPopulateDefault.${props.formid ||
      'user_school_search'}.include_sub_organizations`,
  );

  const defaultSearchSubOrgFromConfig = lodashGet(
    state,
    'domainInfo.conf.default_search_sub_organizations.account',
  );

  if (typeof includeSubOrg === 'undefined') {
    includeSubOrg = defaultSearchSubOrgFromConfig;
  }

  return {
    orgIids: departmentAndPhongbanIids,
    includeSubOrg,
  };
};

export default connect(mapStateToProps)(checkRoot()(UserInSchoolSearch));
