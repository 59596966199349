import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { isSmallScreen } from 'common';

const CustomScrollbars = ({
  children,
  noScrollOnMobile,
  scrollBarRef,
  ...remainProps
}) => {
  if (noScrollOnMobile && isSmallScreen) {
    return children;
  }

  return (
    <Scrollbars
      {...remainProps}
      renderThumbVertical={(props) => (
        <div {...props} className="content-thumb-vertical" />
      )}
      renderThumbHorizontal={(props) => (
        <div {...props} className="content-thumb-horizontal" />
      )}
      ref={scrollBarRef}
    >
      {children}
    </Scrollbars>
  );
};

export default CustomScrollbars;
