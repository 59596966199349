import React, { useCallback, useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Alert, Modal } from 'antd';
import lodashGet from 'lodash.get';
import { Link } from 'react-router-dom';
import { t1 } from 'translate';
import actions from 'actions/node/creators';
import fetchData from 'components/common/fetchData';
import { getTargetUser, userGradeToText } from 'configs/constants/user';
import { parseTSToDateTimeString } from 'common/utils/Date';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import sagaActions from 'actions/node/saga-creators';
import CommonAntdTable from 'components/common/antd/table';
import Loading from 'components/common/loading';
import Icon from 'components/common/Icon';
import {
  getTrainingModelText,
  TRAINING_MODEL,
} from 'components/taphuan/configs';
import endpoints from 'components/bdtx/training-phase/endpoints';
import BackNextStep from 'components/bdtx/training-phase/steps/common/back-next-step';
import { getChuongTrinhName } from 'components/bdtx/configs';
import {
  getUrlByTrainingPhaseIid,
  MAIN_STAGE_VIEW_TYPE,
} from 'components/bdtx/training-phase/mainstage/routes';

const deployTrainingPhaseStatus = {
  deploying: 'deploying',
  deploySuccess: 'deploy_success',
  deployFailed: 'deploy_failed',
};

const deployTrainingPhaseDialogKey = 'deploy-training-phase-status';

const ConfirmTrainingPhase = ({
  trainingPhase,
  overviewTrainingPhase,
  dispatch,
  handleOnActiveStep,
  previousStepIndex,
  nextStepIndex,
}) => {
  const caps = lodashGet(trainingPhase, 'caps') || [];
  const targetUser = lodashGet(trainingPhase, 'target_user') || 'gv';
  const trainingModel =
    lodashGet(trainingPhase, 'training_model') || TRAINING_MODEL.DAI_TRA;
  const startDate = lodashGet(trainingPhase, 'start_date');
  const endDate = lodashGet(trainingPhase, 'end_date');
  const allOrgDeployed = lodashGet(
    overviewTrainingPhase,
    'all_organization_deployed',
  );

  const applyOrganizations =
    lodashGet(overviewTrainingPhase, '__expand.apply_organizations') || [];

  const getDataSource = useCallback(
    () => {
      const materialByModuleGroupedByCap =
        lodashGet(overviewTrainingPhase, 'material_by_module_grouped_by_cap') ||
        {};

      const data = [];
      caps.forEach((cap) => {
        const modules = lodashGet(materialByModuleGroupedByCap, `${cap}`) || [];
        modules.forEach((module, index) => {
          const materials = lodashGet(module, 'bought_materials') || [];
          const chosenMaterial = materials.find((material) =>
            lodashGet(material, 'chosen'),
          );

          data.push({
            ...module,
            cap: cap,
            rowSpan: index ? 0 : modules.length,
            chosen_material: chosenMaterial,
          });
        });
      });

      return data;
    },
    [caps, overviewTrainingPhase],
  );

  const shouldShowConfirmDeployButton = useCallback(
    () => {
      const dataSource = getDataSource();
      if (!dataSource || !dataSource.length) {
        return false;
      }

      const hasModuleNotDeployed = dataSource.find(
        (module) => !module.deployed,
      );

      return hasModuleNotDeployed || !allOrgDeployed;
    },
    [allOrgDeployed, getDataSource],
  );

  const validateTrainingPhase = useCallback(
    () => {
      const dataSource = getDataSource();

      if (!dataSource || !dataSource.length) {
        return {
          canDeploy: false,
          errors: [t1('this_training_phase_missing_assign_modules')],
        };
      }

      const errors = [];
      dataSource.forEach((data) => {
        const name = lodashGet(data, 'name');
        const cap = userGradeToText(lodashGet(data, 'cap'));
        const ct = lodashGet(data, 'chuong_trinh');
        const chosenMaterial = lodashGet(data, 'chosen_material');
        if (!ct && !chosenMaterial) {
          errors.push(
            t1('cap_%s_module_%s_missing_chuong_trinh_and_material', [
              cap,
              name,
            ]),
          );
        } else if (!ct) {
          errors.push(t1('cap_%s_module_%s_missing_chuong_trinh', [cap, name]));
        } else if (!chosenMaterial) {
          errors.push(t1('cap_%s_module_%s_missing_material', [cap, name]));
        }
      });

      return {
        canDeploy: !errors.length,
        errors,
      };
    },
    [getDataSource],
  );

  const getErrorMessage = useCallback((deployStatus, response) => {
    if (deployStatus === deployTrainingPhaseStatus.deployFailed) {
      return [lodashGet(response, 'message')];
    }

    const result = lodashGet(response, 'result') || [];

    let createdTps = [];
    let createdEps = [];

    result.forEach((res) => {
      createdEps = [
        ...createdEps,
        ...(lodashGet(res, 'result.createEps') || []),
      ];

      createdTps = [
        ...createdTps,
        ...(lodashGet(res, 'result.createTps') || []),
      ];
    });

    const epErrors = [];
    createdEps.forEach((createEp) => {
      if (!lodashGet(createEp, 'success')) {
        epErrors.push(lodashGet(createEp, 'message'));
      }
    });

    const tpErrors = [];
    createdTps.forEach((createTp) => {
      if (!lodashGet(createTp, 'success')) {
        tpErrors.push(lodashGet(createTp, 'message'));
      }
    });

    // khi triển khai thêm, những module deployed thì TP/EP đã được tạo sẽ trả về false
    // nên nếu false all thì mới coi là lỗi
    if (
      epErrors.length === createdEps.length ||
      tpErrors.length === createdTps.length
    ) {
      return epErrors.length ? epErrors : tpErrors;
    }

    return [];
  }, []);

  const closeDialog = useCallback(
    () => {
      dispatch(actions.closeAllDialogs());
    },
    [dispatch],
  );

  const showDeployStatus = useCallback(
    (deployStatus, response) => {
      let contentDialog = null;

      if (deployStatus === deployTrainingPhaseStatus.deploying) {
        contentDialog = (
          <div className="d-flex d-flex-column align-items-center font-size-medium">
            <Loading className="font-size-large" circularLoadingIcon />

            <span className="m-t-15">
              {t1('deploying_training_phase.please_wait_a_bit')}
              <span>...</span>
            </span>
          </div>
        );
      } else if (
        [
          deployTrainingPhaseStatus.deploySuccess,
          deployTrainingPhaseStatus.deployFailed,
        ].includes(deployStatus)
      ) {
        const errors = getErrorMessage(deployStatus, response);
        if (errors.length) {
          contentDialog = (
            <div className="d-flex d-flex-column align-items-center font-size-medium">
              <Icon
                icon="not_plagiarism"
                style={{ fontSize: '70px' }}
                className="text-danger"
              />

              <span className="m-t-15 text-center">
                {t1(
                  'deploy_training_phase_failed.please_view_information_below',
                )}
                {errors.map((error) => (
                  <div className="m-t-10 text-danger">{error}</div>
                ))}
              </span>
            </div>
          );
        } else {
          contentDialog = (
            <div className="d-flex d-flex-column align-items-center font-size-medium">
              <Icon
                icon="plagiarism"
                style={{ fontSize: '70px' }}
                className="text-primary"
              />

              <span className="m-t-15">
                {t1('deployed_training_phase')}
                <Link
                  className="link m-l-5"
                  to={getUrlByTrainingPhaseIid(
                    lodashGet(trainingPhase, 'iid'),
                    MAIN_STAGE_VIEW_TYPE.REPORT,
                  )}
                  onClick={closeDialog}
                >
                  {t1('please_click_here_to_view_detail')}
                </Link>
              </span>
            </div>
          );
        }
      }

      if (!contentDialog) {
        return null;
      }

      const optionsProperties = {
        modal: true,
        keyboard: false,
      };

      dispatch(
        actions.handleOpenDialog(
          { contentDialog, optionsProperties },
          deployTrainingPhaseDialogKey,
        ),
      );
    },
    [closeDialog, dispatch, getErrorMessage, trainingPhase],
  );

  const deployTrainingPhase = useCallback(
    () => {
      showDeployStatus(deployTrainingPhaseStatus.deploying, {});

      dispatch(
        sagaActions.submitFormRequest(
          '',
          {
            url: endpoints.bdtx_deploy,
            extraParams: {
              training_phase_iid: lodashGet(trainingPhase, 'iid'),
            },
            executeOnSuccess: (res) => {
              showDeployStatus(deployTrainingPhaseStatus.deploySuccess, res);
            },
            executeOnFailure: (res) => {
              showDeployStatus(deployTrainingPhaseStatus.deployFailed, res);
            },
          },
          'POST',
          false,
        ),
      );
    },
    [dispatch, showDeployStatus, trainingPhase],
  );

  const onNextStep = useCallback(
    () => {
      const validate = validateTrainingPhase();
      if (!validate.canDeploy) {
        Modal.error({
          title: t1(
            'this_training_phase_missing_some_required_data.please_check_information_below',
          ),
          content: (
            <>
              {validate.errors.map((error) => {
                return <div className="m-b-5 text-danger">{error}</div>;
              })}
            </>
          ),
          okText: t1('ok_i_will_check_that'),
        });

        return;
      }

      Modal.confirm({
        title: t1('do_you_want_to_deploy_this_training_phase'),
        okText: t1('yes_i_want_to_deploy'),
        cancelText: t1('no_i_will_deploy_later'),
        onOk: deployTrainingPhase,
      });
    },
    [deployTrainingPhase, validateTrainingPhase],
  );

  const getColumns = useCallback(
    () => {
      return [
        {
          title: t1('training_phase_cap'),
          render: (module) => {
            return {
              children: (
                <span>{userGradeToText(lodashGet(module, 'cap'))}</span>
              ),
              props: {
                rowSpan: lodashGet(module, 'rowSpan'),
              },
            };
          },
        },
        {
          title: t1('training_phase_module'),
          render: (module) => lodashGet(module, 'name'),
        },
        {
          title: t1('training_phase_chuong_trinh'),
          type: TYPE_OF_TABLE_DATA.TITLE,
          render: (module) => {
            const ct = lodashGet(module, 'chuong_trinh');
            if (!ct) {
              return (
                <>
                  <span className="text-danger">
                    {t1('missing_chuong_trinh')}
                  </span>
                  <div className="link m-t-5">
                    <span onClick={() => handleOnActiveStep(1)}>
                      {t1('choose_chuong_trinh')}
                    </span>
                  </div>
                </>
              );
            }

            return <span>{getChuongTrinhName(ct, true)}</span>;
          },
        },
        {
          title: t1('training_phase_material'),
          render: (module) => {
            const chosenMaterial = lodashGet(module, 'chosen_material');
            if (!chosenMaterial) {
              return (
                <>
                  <span className="text-danger">{t1('missing_material')}</span>
                  <div className="link m-t-5">
                    <span onClick={() => handleOnActiveStep(2)}>
                      {t1('choose_material')}
                    </span>
                  </div>
                </>
              );
            }

            return <span>{lodashGet(module, 'chosen_material.name')}</span>;
          },
        },
        {
          title: t1('module_deploy_status'),
          render: (module) => {
            const deployed = lodashGet(module, 'deployed');

            if (deployed) {
              return (
                <span className="text-primary">{t1('module_deployed')}</span>
              );
            }

            return (
              <span className="text-danger">{t1('module_is_not_deploy')}</span>
            );
          },
        },
      ];
    },
    [handleOnActiveStep],
  );

  if (!trainingPhase) {
    return (
      <Alert
        type="error"
        message={t1('you_have_to_create_training_phase_before_choose_module')}
      />
    );
  }

  if (!caps || !caps.length) {
    return <Alert type="error" message={t1('training_phase_missing_caps')} />;
  }

  return (
    <>
      <div className="m-b-25">
        <h3 className="m-b-15 text-center">
          {t1('training_phase_basic_information')}
        </h3>

        <div className="m-b-10">
          <span className="m-r-5">{t1('training_phase_name')}:</span>
          <b>{lodashGet(trainingPhase, 'name')}</b>
        </div>

        <div className="row m-b-10">
          <div className="col-md-6">
            <span className="m-r-5">{t1('training_phase_target_user')}:</span>
            <b>{getTargetUser(targetUser)}</b>
          </div>

          <div className="col-md-6">
            <span className="m-r-5">
              {t1('training_phase_training_model')}:
            </span>
            <b>{getTrainingModelText(trainingModel)}</b>
          </div>
        </div>

        <div className="row m-b-10">
          <div className="col-md-6">
            <span className="m-r-5">{t1('training_phase_start_date')}:</span>
            <b>{parseTSToDateTimeString(startDate)}</b>
          </div>

          <div className="col-md-6">
            <span className="m-r-5">{t1('training_phase_end_date')}:</span>
            <b>{parseTSToDateTimeString(endDate)}</b>
          </div>
        </div>

        <div>
          <span className="m-r-5">
            {t1('training_phase_apply_organizations')}:
          </span>
          {applyOrganizations.map((org, index) => {
            return (
              <b className="m-r-5">
                <b>{lodashGet(org, 'short_name')}</b>
                {index + 1 < applyOrganizations.length ? <span>/</span> : null}
              </b>
            );
          })}
        </div>

        <hr />
      </div>

      <div>
        <h3 className="m-b-15 text-center">
          {t1('training_phase_module_information')}
        </h3>

        {!allOrgDeployed ? (
          <div className="text-danger text-bold m-b-15">
            {t1('new_organizations_added.but_not_deployed')}
          </div>
        ) : null}

        <CommonAntdTable
          dataSource={getDataSource()}
          columns={getColumns()}
          pagination={false}
          className="learn-default"
        />
      </div>

      <BackNextStep
        onNextStep={onNextStep}
        onPreviousStep={handleOnActiveStep}
        previousStepIndex={previousStepIndex}
        nextStepIndex={nextStepIndex}
        hideNextButtonWhenHaveNoNextStep={!shouldShowConfirmDeployButton()}
        nextStepLabel={t1('confirm_and_deploy_training_phase')}
      />
    </>
  );
};

const fetchOverviewTrainingPhase = fetchData(({ trainingPhase }) => {
  const trainingPhaseIid = lodashGet(trainingPhase, 'iid');

  return {
    baseUrl: endpoints.bdtx_get_overview,
    params: {
      training_phase_iid: trainingPhaseIid,
    },
    fetchCondition: !!trainingPhaseIid,
    propKey: 'overviewTrainingPhase',
  };
});

export default compose(
  connect(),
  fetchOverviewTrainingPhase,
)(ConfirmTrainingPhase);
