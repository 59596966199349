import React, { useState, useEffect, useRef } from 'react';
import Helmet from 'components/common/helmet/index';
import './stylesheet.scss';
import PageTitle from 'components/common/page-title';
import VideoPlayer from 'components/common/media-player/video';
import LayoutProvider from 'layouts/container';
import { Menu, Switch, Divider, Icon } from 'antd';
import _ from 'lodash';
import { isSmallScreen } from 'common';

const objId = {
  intro: 'intro',
  objective: 'objective',
  key_result: 'key_result',
  target_user: 'target_user',
  key_action: 'key_action',
  benefit: 'benefit',
  module: 'module',
};

const arrayTab = {
  intro: {
    id: objId.intro,
    href: '#intro',
    title: 'Giới thiệu',
    icon: 'info-circle',
  },
  objective: {
    id: objId.objective,
    href: '#objective',
    title: 'Mục tiêu',
    icon: 'rise',
  },
  key_result: {
    id: objId.key_result,
    href: '#key_result',
    title: 'Đối tượng thụ hưởng',
    icon: 'user',
  },
  target_user: {
    id: objId.target_user,
    href: '#target_user',
    title: 'Kết quả chủ yếu',
    icon: 'file-done',
  },
  key_action: {
    id: objId.key_action,
    href: '#key_action',
    title: 'Hoạt động chính',
    icon: 'check',
  },
  benefit: {
    id: objId.benefit,
    href: '#benefit',
    title: 'Lợi ích của GVPT & CBQLCSGDPT',
    icon: 'like',
  },
  module: {
    id: objId.module,
    href: '#module',
    title: 'Mô đun đào tạo',
    icon: 'book',
  },
};

function AboutETEP() {
  const etepLink =
    'https://taphuan-video.csdl.edu.vn/ufiles/etep-videos/gioi-thieu-etep-13102020/playlist.m3u8';
  const cssClass = 'about-etep-page';
  const beautyHeightScroll = -60; // Để đảm bảo việc  khi scroll qua hết title mới nhảy sang title tiếp theo và
  // đảm bảo với việc click title trên menu đến vị trí mong muốn mà ko bị nhảy sang title tiếp theo.
  const arrayTitle = Object.values(objId);
  const reverseArrayTitle = arrayTitle.reverse();

  const handleScroll = () => {
    let elementActive;
    reverseArrayTitle.forEach((value) => {
      const element = document.getElementById(value);
      const tabElement = document.getElementById(`tab_${value}`);
      if (!tabElement || !element) {
        return;
      }

      if (element && element.getBoundingClientRect().top > beautyHeightScroll) {
        elementActive = value;
      }
    });

    if (elementActive) {
      arrayTitle.forEach((value) => {
        const element = document.getElementById(`tab_${value}`);
        if (element) {
          if (value !== elementActive) {
            element.classList.remove('active');
          } else {
            element.classList.add('active');
          }
        }
      });
    }
  };

  useEffect(() => {
    if (isSmallScreen) {
      document.body.removeEventListener('scroll', handleScroll);

      return;
    }

    document.body.addEventListener('scroll', handleScroll);

    return () => {
      document.body.removeEventListener('scroll', handleScroll);
    };
  });

  const renderMenu = () => {
    return Object.values(arrayTab).map((item, i) => {
      return (
        <a
          key={item.id}
          href={item.href}
          className={`item p-l-32 p-r-32 ${!i ? 'active' : ''}`}
          id={`tab_${item.id}`}
        >
          <Icon type={item.icon} /> {item.title}
        </a>
      );
    });
  };

  return (
    <div className={`d-flex ${cssClass} container`}>
      <Helmet title="Giới thiệu ETEP" />
      <div className="container-menu">
        <div className="sticky-card" style={{ top: 30 }}>
          <div className="menu">{renderMenu()}</div>
        </div>
      </div>
      <div className="p-l-30 p-r-15">
        <PageTitle
          title="chương trình phát triển các trường sư phạm để nâng cao năng lực đội ngũ giáo viên và cán bộ quản lý cơ sở giáo dục phổ thông"
          className="m-t-35 d-flex flex-wrap-wrap"
        />
        <div className="content">
          <a name="intro" id={objId.intro} />
          <p
            className="text-center d-flex justify-content-center m-t-20 m-b-30"
            key="intro"
          >
            <div className="about-etep-page__video">
              {<VideoPlayer controls url={etepLink} autoPlay="true" />}
            </div>
          </p>
          <p>
            Chương trình Phát triển các trường sư phạm để nâng cao năng lực đội
            ngũ giáo viên và cán bộ quản lý cơ sở giáo dục phổ thông (tên tiếng
            Anh: Enhancing Teacher Education Program), viết tắt là ETEP, do Ngân
            hàng Thế giới tài trợ, thực hiện từ năm 2017 đến năm 2022, theo mô
            hình tài trợ dựa trên kết quả (PforR). Bộ Giáo dục và Đào tạo là cơ
            quan chủ quản và điều phối.
          </p>
          <a name="objective" id={objId.objective} />
          <h3 key="objective">MỤC TIÊU</h3>
          <p>
            Phát triển các trường sư phạm và cơ quan quản lý giáo dục được lựa
            chọn để tăng cường năng lực bồi dưỡng giáo viên và cán bộ quản lý cơ
            sở giáo dục phổ thông (GV&CBQLCSGDPT), thông qua mô hình phát triển
            nghề nghiệp thường xuyên, liên tục theo nhu cầu thực tiễn, đáp ứng
            yêu cầu đổi mới căn bản, toàn diện giáo dục.
          </p>
          <p>
            Mục tiêu cốt lõi của ETEP là hình thành mô hình bồi dưỡng thường
            xuyên, liên tục, tại chỗ, phát triển năng lực đội ngũ GV&CBQLCSGDPT
            bằng nguồn học liệu mở và mạng lưới đồng nghiệp hỗ trợ tự bồi dưỡng,
            vừa trực tiếp, vừa qua mạng internet. Mạng lưới này được hình thành
            bởi chuyên gia của 8 trường ĐHSP/Học viện tham gia ETEP (gọi chung
            là các trường ĐHSP chủ chốt) và đội ngũ GV&CBQLCSGDPT cốt cán của 63
            tỉnh, thành phố.
          </p>
          <a name="key_result" id={objId.key_result} />
          <h3 key="key_result">ĐỐI TƯỢNG THỤ HƯỞNG</h3>
          <ul>
            <li>
              Giáo viên và cán bộ quản lý cơ sở giáo dục phổ thông, các trường
              phổ thông và trung tâm giáo dục thường xuyên.
            </li>
            <li>
              Tám đơn vị được lựa chọn tham gia ETEP: Trường ĐHSP Hà Nội, ĐHSP
              Hà Nội 2, ĐHSP-ĐH Thái Nguyên, Đại học Vinh, ĐHSP-ĐH Huế, ĐHSP-ĐH
              Đà Nẵng, ĐHSP Tp Hồ Chí Minh và Học viện Quản lý Giáo dục.
            </li>

            <li>
              Cán bộ quản lý, chỉ đạo về đào tạo, bồi dưỡng giáo viên và cán bộ
              quản lý cơ sở giáo dục phổ thông.
            </li>
          </ul>
          <p>
            Từ đó, nhiều đối tượng khác cũng được thụ hưởng gián tiếp: Học sinh
            các trường phổ thông, học viên các trung tâm giáo dục thường xuyên,
            sinh viên các trường ĐHSP, cán bộ Sở/Phòng Giáo dục & Đào tạo các
            tỉnh/huyện thị, thành phố.
          </p>
          <a name="target_user" id={objId.target_user} />
          <h3 key="target_user">KẾT QUẢ CHỦ YẾU</h3>
          <ul>
            <li>
              Năng lực đào tạo, bồi dưỡng giáo viên và cán bộ quản lý cơ sở giáo
              dục phổ thông của các trường ĐHSP được tăng cường;
            </li>
            <li>
              Đội ngũ GV&CBQLCSGDPT được bồi dưỡng thường xuyên, liên tục, ngay
              tại chỗ với hệ thống học liệu mở, trên nền tảng công nghệ thông
              tin, đảm bảo chất lượng;
            </li>
            <li>
              Các trường ĐHSP được hỗ trợ phát triển hệ thống nguồn học liệu mở
              có chất lượng dành cho giáo viên và cán bộ quản lý cơ sở giáo dục
              phổ thông;
            </li>
            <li>
              Nhu cầu, chất lượng, hiệu quả của chương trình bồi dưỡng
              GV&CBQLCSGDPT được đánh giá chính xác, kịp thời trên Hệ thống
              thông tin quản lý bồi dưỡng giáo viên (TEMIS).
            </li>
          </ul>
          <p>
            Dự kiến, khoảng 28.000 giáo viên phổ thông cốt cán, 4.000 cán bộ
            quản lý giáo dục cốt cán tham gia tập huấn, bồi dưỡng 54 mô đun liên
            tục trong 3 năm. Đội ngũ cốt cán này cùng với chuyên gia của 8
            trường ĐHSP chủ chốt sẽ hỗ trợ việc tự bồi dưỡng cho 850.000 giáo
            viên phổ thông và 70.000 cán bộ quản lý cơ sở giáo dục phổ thông.
          </p>
          <a name="key_action" id={objId.key_action} />
          <h3 key="key_action">HOẠT ĐỘNG CHÍNH CỦA ETEP</h3>
          <p>
            Tăng cường năng lực cho các trường ĐHSP chủ chốt để đào tạo và bồi
            dưỡng phát triển chuyên môn thường xuyên cho GV&CBQLCSGDPT có chất
            lượng, cụ thể:
          </p>
          <ul>
            <li>
              Tăng cường năng lực cho đội ngũ giảng viên và cơ sở vật chất cũng
              như thiết bị, năng lực quản trị, kết nối với cơ quan quản lý, cơ
              sở giáo dục phổ thông của các trường ĐHSP chủ chốt.
            </li>
            <li>
              Nâng cao chất lượng bồi dưỡng theo mô hình thường xuyên, liên tục,
              ngay tại trường cho GV&CBQLCSGDPT.
            </li>
            <li>
              Hỗ trợ phát triển hệ thống nguồn học liệu mở trên nền tảng công
              nghệ thông tin.
            </li>
            <li>
              Đánh giá nhu cầu, chất lượng và hiệu quả của chương trình bồi
              dưỡng giáo viên và cán bộ quản lý cơ sở giáo dục phổ thông.
            </li>
          </ul>
          <a name="benefit" id={objId.benefit} />
          <h3 key="benefit">LỢI ÍCH CỦA GV & CBQLCSGDPT TỪ ETEP</h3>
          <ul>
            <li>
              Tham gia các hoạt động bồi dưỡng, truy nhập vào nguồn học liệu
              trên Hệ thống quản lý học tập trực tuyến (LMS), tự học theo nhu
              cầu của cá nhân;
            </li>
            <li>
              Tham gia cộng đồng mạng xã hội học tập dành cho GV&CBQLCSGDPT,
              tương tác, chia sẻ tri thức, kinh nghiệm với các đồng nghiệp và
              các chuyên gia qua Hệ thống LMS;
            </li>
            <li>
              Được GV/CBQLCSGDPT cốt cán hỗ trợ trực tiếp, thường xuyên, ngay
              tại trường và được giảng viên các trường ĐHSP hỗ trợ qua mạng để
              phát triển năng lực nghề nghiệp;
            </li>
            <li>
              Được đánh giá kết quả học tập và năng lực dựa trên chuẩn nghề
              nghiệp qua Hệ thống TEMIS;
            </li>
            <li>
              Đáp ứng linh hoạt, chủ động ứng phó với những thay đổi của thực
              tiễn giáo dục, nhu cầu đa dạng của học sinh phổ thông và yêu cầu
              đổi mới căn bản, toàn diện giáo dục.
            </li>
          </ul>
          <a name="module" id={objId.module} />
          <h3 key="module">09 MÔ ĐUN BỒI DƯỠNG GVPT</h3>
          <ul>
            <li>
              GV_01: Hướng dẫn thực hiện Chương trình Giáo dục phổ thông 2018
              (19 môn học)
            </li>
            <li>
              GV_02: Sử dụng phương pháp dạy học và giáo dục phát triển phẩm
              chất, năng lực học sinh (19 môn học)
            </li>
            <li>
              GV_03: Đánh giá học sinh theo hướng phát triển phẩm chất, năng lực
              (19 môn học)
            </li>
            <li>
              GV_04: Xây dựng kế hoạch dạy học và giáo dục theo hướng phát triển
              phẩm chất, năng lực học sinh (19 môn học)
            </li>
            <li>
              GV_05: Tư vấn và hỗ trợ học sinh trong hoạt động giáo dục và dạy
              học
            </li>
            <li>GV_06: Xây dựng văn hóa nhà trường</li>
            <li>
              GV_07: Thực hiện và xây dựng trường học an toàn, phòng chống bạo
              lực học đường ở trường
            </li>
            <li>
              GV_08: Phối hợp giữa nhà trường, gia đình và xã hội để thực hiện
              giáo dục đạo đức, lối sống cho học sinh
            </li>
            <li>
              GV_09: Ứng dụng công nghệ thông tin, khai thác và sử dụng thiết bị
              công nghệ trong dạy học và giáo dục học sinh (19 môn học)
            </li>
          </ul>
          <h3>09 MÔ ĐUN BỒI DƯỠNG CBQLCSGDPT</h3>
          <ul>
            <li>
              QL_01: Quản trị hoạt động dạy học, giáo dục trong trường tiểu
              học/THCS/THPT
            </li>
            <li>QL_02: Quản trị nhân sự trong trường tiểu học/THCS/THPT</li>
            <li>
              QL_03: Quản trị tài chính trường học theo hướng tăng cường tự chủ
              và trách nhiệm giải trình
            </li>
            <li>
              QL_04: Quản trị cơ sở vật chất, thiết bị và công nghệ trong dạy
              học, giáo dục học sinh ở trường tiểu học/THCS/THPT
            </li>
            <li>
              QL_05: Quản trị chất lượng giáo dục trường tiểu học/THCS/THPT
            </li>
            <li>QL_06: Xây dựng văn hóa nhà trường tiểu học/THCS/THPT</li>
            <li>
              QL_07: Thực hiện và xây dựng trường học an toàn, phòng chống bạo
              lực học đường ở trường tiểu học/THCS/THPT
            </li>
            <li>
              QL_08: Phối hợp giữa nhà trường, gia đình và xã hội để thực hiện
              giáo dục đạo đức, lối sống cho học sinh tiểu học/THCS/THPT
            </li>
            <li>
              QL_09: Ứng dụng công nghệ thông tin, truyền thông trong quản trị
              trường tiểu học/THCS/THPT
            </li>
          </ul>
          <h3>THÔNG TIN LIÊN HỆ</h3>
          <strong>Website</strong>:{' '}
          <a
            href="http://etep.moet.gov.vn"
            className={`${cssClass}__about-link`}
            target="_blank"
          >
            etep.moet.gov.vn
          </a>
          <br />
          <strong>Facebook</strong>:{' '}
          <a
            href="https://facebook.com/nhagiao4.0"
            className={`${cssClass}__about-link`}
            target="_blank"
          >
            Facebook.com/nhagiao4.0
          </a>
          <br />
          <strong>Email</strong>: Nếu bạn có thắc mắc trong quá trình thao
          tác/vận hành hoặc về quy trình đào tạo hãy gửi email tới{' '}
          <a
            href="mailto:taphuan@viettel.com.vn"
            className={`${cssClass}__about-link`}
          >
            <strong>taphuan@viettel.com.vn</strong>
          </a>{' '}
          <br />
          Nếu bạn có thắc mắc mang tính quản lý và cần hỏi BQL dự án, hãy email
          tới{' '}
          <a
            href="mailto:etep@moet.gov.vn"
            className={`${cssClass}__about-link`}
          >
            <i>etep@moet.gov.vn</i>
          </a>
          <br />
          <strong>Trụ sở</strong>: Tầng 5 – Thư viện Tạ Quang Bửu, Trường Đại
          học Bách Khoa Hà Nội, số 1 – Đại Cồ Việt, Hai Bà Trưng, Hà Nội
          <br />
          <strong>Điện thoại hỗ trợ</strong>: 18008000 Nhánh 2 (Miễn phí). Thời
          gian hỗ trợ: 07:00 đến 22:00 tất cả các ngày trong tuần
          <br />
          <strong>Điện thoại BQL dự án</strong>: (+84) 2436240388; (+84)
          2432151876 (Nếu bạn có thắc mắc mang tính quản lý và cần hỏi BQL dự
          án)
          <br />
          <br />
          <p>
            BỘ GIÁO DỤC VÀ ĐÀO TẠO <br />
            BAN QUẢN LÝ CHƯƠNG TRÌNH ETEP
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutETEP;
