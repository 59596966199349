import React from 'react';
import lodashGet from 'lodash.get';
import { Alert } from 'antd';
import { t1 } from 'translate';
import fetchData from 'components/common/fetchData';
import DeploymentPreview from 'components/bdtx/training-phase/mainstage/deployment-preview';
import DeleteItem from 'components/common/action-button/DeleteBtnWithConfirmDialog';
import endpoints from 'components/bdtx/training-phase/endpoints';

const ConfirmContent = ({
  trainingPhase,
  canDeleteTrainingPhase,
  searchFormId,
  closeDialog,
}) => {
  const iid = lodashGet(trainingPhase, 'iid');
  const id = lodashGet(trainingPhase, 'id');
  const name = lodashGet(trainingPhase, 'name');

  return (
    <>
      {canDeleteTrainingPhase ? (
        <Alert
          message="Chú ý"
          description={
            <span>
              Bạn đang thực hiển xóa bỏ đợt triển khai: <b>{name}</b>. Hãy xem
              lại thật kỹ thông tin đã triển khai của đợt: {name} trước khi "Xóa
              bỏ"
            </span>
          }
          showIcon
          type="warning"
          className="m-b-15"
        />
      ) : (
        <Alert
          showIcon
          type="warning"
          message="Chú ý"
          description={t1(
            'this_training_phase_can_not_deleted_cause_by_has_some_users_registered',
          )}
          className="m-b-15"
        />
      )}

      <DeploymentPreview
        trainingPhaseIid={iid}
        noSearchForm
        showAction={false}
      />

      <div className="m-t-15 text-center">
        <button className="btn btn-secondary m-r-15" onClick={closeDialog}>
          Không hủy
        </button>

        {canDeleteTrainingPhase ? (
          <DeleteItem
            title={t1(`Xóa đợt triển khai ${name}`)}
            textConfirm={`Bạn có chắc chắn muốn xóa bỏ đợt triển khai ${name}?`}
            formid={searchFormId}
            ntype={'training-phase'}
            itemId={id}
            iconButton={false}
            alternativeApi={endpoints.bdtx_delete_training_phase}
            className="btn-tertiary"
            label="Tôi hiểu, xóa bỏ đợt triên khai"
            onRequestSuccessful={closeDialog}
          />
        ) : null}
      </div>
    </>
  );
};

const checkCanDeleteTrainingPhase = fetchData((props) => {
  const trainingPhaseIid = lodashGet(props, 'trainingPhase.iid');

  return {
    baseUrl: endpoints.bdtx_check_can_delete_training_phase,
    params: {
      training_phase_iid: trainingPhaseIid,
    },
    fetchCondition: !!trainingPhaseIid,
    propKey: 'canDeleteTrainingPhase',
  };
});

export default checkCanDeleteTrainingPhase(ConfirmContent);
