import './stylesheet.scss';
import React, { Component } from 'react';

import { Modal, Tooltip } from 'antd';
import { connect } from 'react-redux';
import filter from 'lodash.filter';
import unionBy from 'lodash.unionby';
import lodashGet from 'lodash.get';
import Progress from 'antd/lib/progress';
import Button from 'antd/lib/button';
import Result from 'antd/lib/result';
import List from 'antd/lib/list';
import { breadCrumb } from 'common/utils/string';
import { RIEInput } from 'riek';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import CloudUpload from 'material-ui/svg-icons/file/cloud-upload';
import { t1, t3 } from 'translate';
import { getStagedItems } from 'selectors/file-manager';
import { itemsStaged } from 'actions/file-manager';
import nodeActions from 'actions/node/creators';
import FlatButton from 'components/common/mui/FlatButton';
import Request from 'common/network/http/Request';
// import apiUrls from 'api-endpoints';
import fileApiUrls from 'components/common/file-manager/endpoints';
import { removeAtIndex } from 'common/utils/Array';
import FileManagerPopupButton from 'components/common/file-manager/FileManagerPopupButton';
import PrimaryButton from 'components/common/primary-button';
import DownloadLinkWrapper from 'components/common/download/DownloadLinkWrapper';
import { getDurationAsync, isVideoOrAudioFile } from 'common/utils/File';
import FileViewer from './file-viewer';

import {
  beginUploadFile as beginUploadFileAction,
  finishUploadFile as finishUploadFileAction,
} from 'actions/attachment';
import { fileCanPreview } from 'components/common/forms/questions/open-ended/attachment-viewer';

const ButtonGroup = Button.Group;

const AttachmentInfoPart = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ErrorText = styled.div`
  font-size: 13px;
  color: rgb(244, 67, 54);
`;

const style = {
  left: '-10px',
};

/**
 * Filter file accept file size
 * @param files
 * @param maxSize maxFile size pass in props shemal
 * if maxSize is undefined then not vertify size file
 * @returns {{fileAccepts: array, isOverSize: boolean}}
 */
function filterSize(files, maxSize) {
  const maxSizeByte = convertToByte(maxSize);
  let isOverSize = false;
  if (typeof maxSizeByte !== 'undefined') {
    const fileAccepts = filter(files, (el) => {
      if (el.size <= maxSizeByte) return true;
      isOverSize = true;
      return false;
    });
    return {
      fileAccepts,
      isOverSize,
    };
  }
  return {
    fileAccepts: files,
    isOverSize,
  };
}

/**
 * Conver maxSize from MB to Byte
 * @param maxFileSize
 * @returns {number}
 */
function convertToByte(maxFileSize) {
  return maxFileSize && maxFileSize * 1024 * 1024;
}

class AttachmentsInput extends Component {
  state = {
    progress: -1, // default value: no active file upload
  };

  // componentDidMount() {
  //   const { value, onChange } = this.props;
  //   if (onChange) {
  //     onChange(value || []);
  //   }
  // }

  componentWillUnmount() {
    this.handleActionCancelRequest();
  }

  handleFileUpload = (event) => {
    const acceptedFiles = event.target.files;
    const { saveFileAsValueInsteadOfUrl } = this.props;
    let { value } = this.props;
    value = value || [];
    if (saveFileAsValueInsteadOfUrl) {
      if (acceptedFiles && acceptedFiles.length) {
        this.handleChange([...acceptedFiles, ...value]);
      }
    } else {
      Request.post(fileApiUrls.upload_file, {
        files: acceptedFiles,
      }).then((data) => {
        if (
          data.success &&
          data.result &&
          data.result.attachments &&
          data.result.attachments.length
        ) {
          value = [...data.result.attachments, ...value];
          this.handleChange(value);
        }
      });
    }
  };

  uploadValidFilesToServer = (files) => {
    const {
      saveFileAsValueInsteadOfUrl,
      beginUploadFile,
      finishUploadFile,
    } = this.props;

    this.setState({ progress: 0 });

    const acceptedFiles = files;

    let value = this.props.value || [];

    if (saveFileAsValueInsteadOfUrl) {
      if (acceptedFiles && acceptedFiles.length) {
        this.handleChange([...acceptedFiles, ...value]);
      }
    } else {
      const seft = this;
      // Reset create cacel new token for request
      Request.source = Request.cancelToken.source();
      // Config event on progress request upload
      const config = {
        onUploadProgress(progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          seft.setState({ progress: percentCompleted });
        },
        cancelToken: Request.source.token, // Send token cancel to server
      };

      const url = this.props.uploadUrl || fileApiUrls.upload_file;

      beginUploadFile();
      Request.post(
        url,
        {
          files: acceptedFiles,
        },
        config,
      )
        .then((data) => {
          if (typeof data !== 'undefined') {
            if (
              data.success &&
              data.result &&
              data.result.attachments &&
              data.result.attachments.length
            ) {
              value = [...data.result.attachments, ...value];
              this.handleChange(value);
            }
          }
          // Hidden progressbar and reset progress state
          this.setState({ progress: -1 });
        })
        .finally(finishUploadFile);
    }
  };

  handleFileUploadV2 = async (files, rejectFiles) => {
    const { snackbarAction, minVideoDuration, maxVideoDuration } = this.props;

    if (rejectFiles.length) {
      // Only return if all file upload is reject
      // else notify have file upload over file size
      snackbarAction(
        `${rejectFiles.length} ${t1('file_type_or_size_not_accepted')}`,
      );
    }

    if (!files.length) {
      return;
    }

    if (!minVideoDuration && !maxVideoDuration) {
      this.uploadValidFilesToServer(files);

      return;
    }

    const validFiles = [];
    const invalidFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (!isVideoOrAudioFile(file)) {
        validFiles.push(file);

        continue;
      }

      const duration = await getDurationAsync(file);

      if (this.isValidDuration(duration, minVideoDuration, maxVideoDuration)) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file);
      }
    }

    if (validFiles.length) {
      this.uploadValidFilesToServer(validFiles);
    }

    if (invalidFiles.length) {
      snackbarAction(
        `${invalidFiles.length} ${t1(
          'file_duration_is_not_in_from_%s_to_%s_minutes',
          [minVideoDuration, maxVideoDuration],
        )}`,
      );
    }
  };

  isValidDuration = (
    videoDurationInSecond,
    minVideoDuration,
    maxVideoDuration,
  ) => {
    let minVideoDurationInSecond = (minVideoDuration || 0) * 60; // convert to second
    let maxVideoDurationInSecond = (maxVideoDuration || 0) * 60; // convert to second

    if (
      minVideoDurationInSecond &&
      videoDurationInSecond < minVideoDurationInSecond
    ) {
      return false;
    }

    if (
      maxVideoDurationInSecond &&
      videoDurationInSecond > maxVideoDurationInSecond
    ) {
      return false;
    }

    return true;
  };

  // Action cancel when half-way uploading the file
  handleActionCancelRequest = () => {
    Request.actionCancel();
    this.setState({ progress: -1 });
  };

  /*
   *   Action select file in file manager
   *   case limit == 1 then file is object file
   *   case limit != 1 then file is array files
   * */
  setValue = (file) => {
    let { value, stageItems, snackbarAction, maxFileSize } = this.props;
    /*
     * reset stagedItems
     * */
    if (typeof stageItems === 'function') {
      stageItems([], true);
    }
    value = value || [];
    let tmp2 = [];
    if (file instanceof Array) {
      const { fileAccepts, isOverSize } = filterSize(file, maxFileSize);
      if (isOverSize) {
        snackbarAction(
          `${file.length - fileAccepts.length} ${t1(
            'file_is_reject_because_file_size_over_%d_MB',
            maxFileSize,
          )}`,
        );
      }
      tmp2 = fileAccepts.map((el) => ({
        ext: el.ext,
        id: el.id,
        link: el.url,
        name: el.name,
      }));
    } else {
      if (file.size > convertToByte(maxFileSize) && maxFileSize) {
        snackbarAction(
          `${t1('file_is_reject_because_file_size_over_%d_MB', maxFileSize)} `,
        );
        return;
      }
      tmp2 = [
        {
          ext: file.ext,
          id: file.id,
          link: file.url,
          name: file.name,
        },
      ];
    }
    value = [...tmp2, ...value];
    /*
     *   filter duplicate file
     * */
    value = unionBy(value, 'id');
    this.handleChange(value);
  };

  onEditDescription = (data, index) => {
    const { value } = this.props;
    this.handleChange(
      value &&
        value.map((attachment, indexInArray) => {
          if (indexInArray === index) {
            // have to mutate attachment here because attachment may be a file (not object)
            return {
              ...attachment,
              desc: data.message,
            };
          }
          return attachment;
        }),
    );
  };

  remove = (index) => {
    const { value } = this.props;
    this.handleChange(removeAtIndex(value || [], index));
  };

  handleChange = (value) => {
    const { onChange, limit } = this.props;
    if (onChange && typeof onChange === 'function') {
      let valueToSave = value;
      if (limit && value && value.length > limit) {
        valueToSave = value.slice(0, limit);
      }
      onChange(valueToSave);
    }
  };

  renderAttachment = ({
    attachment,
    index,
    allowDownload,
    disabled,
    readOnly,
    AttachmentPreviewer,
    compactMode,
    justShowFileName,
  }) => {
    const { saveFileAsValueInsteadOfUrl, formid } = this.props;
    const attachmentDesc = attachment.desc || attachment.name || '--------';

    if (compactMode && !AttachmentPreviewer && !(readOnly || disabled)) {
      return [
        <RIEInput
          change={(data) => {
            this.onEditDescription(data, index);
          }}
          classEditing="editing"
          value={attachmentDesc}
          propName="message"
          className="rieinput-attachment"
          defaultProps={{
            style: {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%',
            },
          }}
          editProps={{
            style: {
              width: '100%',
            },
          }}
        />,
      ];
    }

    const attachmentName = `${breadCrumb(attachment.name, 30)}${
      attachment.ext ? `.${attachment.ext}` : ''
    }`;
    const attachmentTitle = `${attachment.name}${
      attachment.ext ? `.${attachment.ext}` : ''
    }`;

    return [
      <AttachmentInfoPart
        className={`${
          justShowFileName ? 'col-md-12' : 'col-md-6'
        } attachment-sub-item d-flex`}
      >
        <Tooltip title={attachmentTitle} placement="topLeft">
          <span className={'text-ellipsis'}>{attachmentName}</span>
        </Tooltip>
      </AttachmentInfoPart>,
      justShowFileName ? null : (
        <AttachmentInfoPart
          className="col-md-5 attachment-sub-item"
          style={style}
        >
          {AttachmentPreviewer ? (
            <AttachmentPreviewer attachment={attachment} formid={formid} />
          ) : null}
          {AttachmentPreviewer ? null : readOnly || disabled ? (
            <Tooltip title={attachmentDesc} placement="topLeft">
              {attachmentDesc}
            </Tooltip>
          ) : (
            <RIEInput
              change={(data) => {
                this.onEditDescription(data, index);
              }}
              classEditing="editing"
              value={attachmentDesc}
              propName="message"
              className="rieinput-attachment"
            />
          )}
        </AttachmentInfoPart>
      ),
    ];
  };

  isShowFileViewer = (attachment) => {
    const { viewerFileExtensions = undefined } = this.props;
    if (!viewerFileExtensions || !viewerFileExtensions.length) {
      return fileCanPreview(attachment);
    }

    if (viewerFileExtensions === 'all') {
      return true;
    }

    if (!Array.isArray(viewerFileExtensions) || !viewerFileExtensions.length) {
      return false;
    }

    const ext = lodashGet(attachment, 'ext', '').toLowerCase();
    const viewerFileExtensionsToLowercase = viewerFileExtensions.map((item) =>
      item.toLowerCase(),
    );
    return viewerFileExtensionsToLowercase.includes(ext);
  };

  render() {
    const {
      allowDownload,
      label,
      value,
      disabled,
      accept,
      limit,
      errorText,
      readOnly,
      AttachmentPreviewer,
      maxFileSize,
      saveFileAsValueInsteadOfUrl,
      compactMode,
      compactModeButtonNoIcon,
      compactModeButtonIconOnly,
      className,
      classWrapper,
      title,
      keepAtLeastOneFile,
      justShowFileName,
      showConfirmWhenDeleteFile,
    } = this.props;
    const disabledInput =
      disabled || readOnly || (limit && value && value.length >= limit);
    const shouldBeDisabledRemoveFile =
      disabled ||
      readOnly ||
      (keepAtLeastOneFile && value && value.length === 1);

    const multiple = limit !== 1;
    const heading = multiple
      ? t1('drag_files_here_to_upload')
      : t1('drag_file_here_to_upload');
    const chooseFileLabel = multiple
      ? t1('choose_files_from_your_computer')
      : t1('choose_file_from_your_computer');
    const progress = parseInt(this.state.progress);
    let subHeading = Array.isArray(accept)
      ? `<p>${t1('acceptable_files')}: ${accept.join(',')}</p>`
      : typeof accept === 'string'
      ? `<p>${t1('acceptable_files')}: ${accept}</p>`
      : '';
    if (typeof maxFileSize !== 'undefined') {
      subHeading = `${subHeading} <p>${t1(
        'max_file_size_is_%s',
        maxFileSize,
      )} MB</p>`;
    }
    const height = this.props.height || '100px';
    const cssClass = 'attachment-container';
    return (
      <div className={`${cssClass} ${className} ${classWrapper}`}>
        <div>
          {!!label && <span className="ant-form-item-label">{label}</span>}
          {!disabledInput && (
            <Dropzone
              accept={accept}
              style={{
                width: '100%',
                minHeight: compactMode ? 'initial' : height,
                border: '2px dashed #ddd',
                textAlign: 'center',
                transition: 'border .3s ease-in-out',
              }}
              maxSize={maxFileSize && convertToByte(maxFileSize)}
              multiple={multiple}
              onDrop={this.handleFileUploadV2}
              activeStyle={{ border: '2px solid #40A9F3' }}
              disabledStyle={{ border: '2px solid #40A9F3' }}
              rejectStyle={{ border: '2px solid red' }}
              disabled={!!(disabledInput || progress !== -1)}
              disableClick={!!(progress !== -1 || disabledInput)}
              className={`${cssClass}-dropzone ${
                compactMode ? 'p-t-10 p-b-10' : ''
              }`}
            >
              {({ isDragActive, isDragReject }) => {
                // When drag active, color text changed
                if (isDragReject) {
                  return (
                    <Result
                      status="error"
                      title={t1('file_type_not_accepted')}
                    />
                  );
                }
                if (isDragActive) {
                  return (
                    <div className={`${cssClass}-dropzone__drag`}>
                      <div
                        className={`text-muted ${cssClass}-dropzone__drag__title`}
                      >
                        {heading}
                      </div>
                      <FlatButton
                        label={chooseFileLabel}
                        labelPosition="after"
                        icon={<CloudUpload />}
                        containerElement="label"
                        disabled={disabledInput}
                        className={`text-muted ${cssClass}-dropzone__drag__button`}
                      />
                    </div>
                  );
                }
                // When requesting then show progress bar and change text from "drag_file_to_here" to "Uploading ..."
                if (progress !== -1) {
                  if (compactMode) {
                    return (
                      <div style={{ textAlign: 'center' }}>
                        <span>
                          {progress === 100
                            ? `${t1('saving')}...`
                            : `${t1('uploading')}...`}
                        </span>
                        <div className="d-flex p-l-5 p-r-5">
                          <Progress
                            style={{ width: '80%', margin: 'auto' }}
                            strokeColor="#3B7BBE"
                            status={progress === 100 ? 'null' : 'active'}
                            percent={this.state.progress}
                          />

                          {progress !== 100 && (
                            <Button
                              onClick={this.handleActionCancelRequest}
                              type="danger"
                              shape="round"
                              icon="close-circle"
                              className="p-l-10 p-r-10"
                            />
                          )}
                        </div>
                      </div>
                    );
                  }
                  return (
                    <div style={{ textAlign: 'center' }}>
                      <p>
                        {progress === 100
                          ? t1('saving_file')
                          : `${t1('uploading_file')}...`}
                      </p>
                      <Progress
                        style={{
                          width: '80%',
                          margin: 'auto',
                          marginTop: '5px',
                        }}
                        strokeColor="#3B7BBE"
                        status={progress === 100 ? 'null' : 'active'}
                        percent={this.state.progress}
                      />

                      {progress !== 100 && (
                        <Button
                          className="m-10"
                          onClick={this.handleActionCancelRequest}
                          type="danger"
                          shape="round"
                        >
                          {t1('cancel_file_upload')}
                        </Button>
                      )}
                    </div>
                  );
                }
                // Default and When upload successfully then change icon and change text

                if (compactMode) {
                  return (
                    <ButtonGroup>
                      <Button
                        disabled={disabledInput}
                        icon={compactModeButtonNoIcon ? '' : 'upload'}
                        title={chooseFileLabel}
                        className="btn btn-secondary btn-small"
                      >
                        {compactModeButtonIconOnly
                          ? ''
                          : !disabledInput
                          ? chooseFileLabel
                          : t1('upload_successful')}
                      </Button>

                      {!this.props.noFileManager && (
                        <span
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                        >
                          <FileManagerPopupButton
                            onSelect={this.setValue}
                            accept={accept}
                            getFile
                            multiple={multiple}
                            maxSize={convertToByte(maxFileSize)}
                            Component={(props) => (
                              <Button
                                disabled={disabledInput}
                                {...props}
                                icon={compactModeButtonNoIcon ? '' : 'cloud'}
                              >
                                {!disabledInput
                                  ? t1('choose_from_file_manager')
                                  : t1('upload_successful')}
                              </Button>
                            )}
                            {...this.props}
                          />
                        </span>
                      )}
                    </ButtonGroup>
                  );
                }

                return (
                  <div
                    className={`${cssClass}-dropzone__drag`}
                    style={{ textAlign: 'center' }}
                  >
                    {!!title && (
                      <div className={`${cssClass}-title`}>{title}</div>
                    )}

                    <div
                      className={`text-muted ${cssClass}-dropzone__drag__title`}
                    >
                      {!disabledInput ? heading : t1('')}
                      <br />
                      {t3('or')}
                    </div>
                    <PrimaryButton
                      buttonType="dashed"
                      disabled={disabledInput}
                      label={
                        !disabledInput
                          ? chooseFileLabel
                          : t1('upload_successful')
                      }
                    />
                    {!this.props.noFileManager && (
                      <div
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                        style={{ display: 'inline-block', marginLeft: '10px' }}
                      >
                        <FileManagerPopupButton
                          onSelect={this.setValue}
                          accept={accept}
                          getFile
                          multiple={multiple}
                          maxSize={convertToByte(maxFileSize)}
                          Component={(props) => (
                            <PrimaryButton
                              disabled={disabledInput}
                              {...props}
                              buttonType="dashed"
                              label={
                                !disabledInput
                                  ? t1('choose_from_file_manager')
                                  : t1('upload_successful')
                              }
                            />
                          )}
                          {...this.props}
                        />
                      </div>
                    )}

                    <div className={`${cssClass}-dropzone__footer`}>
                      <span
                        className="text-muted"
                        dangerouslySetInnerHTML={{ __html: subHeading }}
                      />
                    </div>
                  </div>
                );
              }}
            </Dropzone>
          )}
        </div>

        {errorText && (
          <ErrorText className="text-center m-t-10">{errorText}</ErrorText>
        )}

        {value && value.length > 0 && (
          <List
            className="m-t-10 attachment-list"
            bordered
            size={compactMode ? 'small' : 'default'}
          >
            {value.map((attachment, index) => (
              <List.Item
                key={`attachment-${index}`}
                className={`attachment-item ${
                  compactMode
                    ? 'd-flex justify-content-between attachment-item-compact'
                    : 'd-flex '
                }`}
                actions={[
                  this.isShowFileViewer(attachment) && (
                    <FileViewer attachments={[attachment]} />
                  ),
                  !saveFileAsValueInsteadOfUrl && allowDownload && (
                    <DownloadLinkWrapper
                      fileSize={attachment.size}
                      link={attachment.link || attachment.path}
                      fileName={attachment.name}
                      renderComponent={({ href, onClick }) => {
                        return (
                          <a href={href} target="_blank" onClick={onClick}>
                            <i className="mi mi-file-download" />
                          </a>
                        );
                      }}
                    />
                  ),
                  !shouldBeDisabledRemoveFile && (
                    <i
                      className="mi mi-close"
                      onClick={() => {
                        const remove = this.remove;

                        if (showConfirmWhenDeleteFile) {
                          return Modal.confirm({
                            centered: true,
                            title: t1('do_you_want_remove_this_file'),
                            onOk() {
                              remove(index);
                            },
                            cancelText: t1('cancel'),
                            okText: t1('remove_file'),
                          });
                        }

                        remove(index);
                      }}
                    />
                  ),
                ].filter(Boolean)}
              >
                <div
                  className={'d-flex '}
                  style={{ flex: 1, overflow: 'hidden' }}
                >
                  {this.renderAttachment({
                    attachment,
                    index,
                    allowDownload,
                    disabled,
                    readOnly,
                    AttachmentPreviewer,
                    compactMode,
                    justShowFileName,
                  })}
                </div>
              </List.Item>
            ))}
          </List>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, { maxFileSize }) => ({
  stagedItems: getStagedItems(state),
  maxFileSize:
    maxFileSize || lodashGet(state, 'domainInfo.conf.limit_file_size'),
});

const mapDispatchToProps = (dispatch) => ({
  stageItems: (items, reset) => dispatch(itemsStaged(items, reset)),
  snackbarAction: (msg) => dispatch(nodeActions.snackbar('error', msg)),
  beginUploadFile: () => dispatch(beginUploadFileAction()),
  finishUploadFile: () => dispatch(finishUploadFileAction()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AttachmentsInput);
