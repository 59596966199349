import React from 'react';
import { Tabs } from 'antd';
import { t1 } from 'translate';
import OverviewResultBdtx from 'components/bdtx/report/result-bdtx/overview';
import DetailResultBdtx from 'components/bdtx/report/result-bdtx/details';

const ResultNcbdtx = () => {
  return (
    <div className="container">
      <h3>Báo cáo kết quả BDTX hàng năm của Giáo Viên, CBQL CSGD</h3>

      <hr />

      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={t1('overview_result_bdtx')} key="1">
          <OverviewResultBdtx />
        </Tabs.TabPane>

        <Tabs.TabPane tab={t1('details_result_bdtx')} key="2">
          <DetailResultBdtx />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default ResultNcbdtx;
