import './style.scss';
import React from 'react';
import get from 'lodash.get';
import { t1 } from 'translate';
import TableLikert from 'components/common/forms/questions/likert/common/table-selection';
import { convertObjectWithKeyIsNumberToArray } from 'common/utils/object';

const Likert = ({
  userAnswers = [],
  question,
  setUserAnswers,
  shouldShowKey,
  disabled,
  practice,
}) => {
  const {
    likert_questions = [],
    likert_answers = [],
    likert_correct_answers = [],
    likert_multiple_answers,
  } = question;

  const cssClass = 'likert-question';

  const getContent = (data = []) => {
    return data.map((item) => get(item, 'content', ''));
  };

  const onTableLikertChange = (newData) => {
    if (typeof setUserAnswers === 'function') {
      setUserAnswers(newData);
    }
  };

  const convertUserData = () => {
    if (typeof userAnswers === 'object' && userAnswers !== null) {
      return convertObjectWithKeyIsNumberToArray(userAnswers);
    }

    return userAnswers || [];
  };

  return (
    <>
      <TableLikert
        questionData={question}
        questions={getContent(likert_questions)}
        answers={getContent(likert_answers)}
        onTableLikertChange={onTableLikertChange}
        defaultValues={convertUserData()}
        tablePrefix="table-likert-question"
        correctAnswers={likert_correct_answers}
        disabled={disabled}
        practice={practice}
        isTableSelection
        multiple={likert_multiple_answers}
      />

      {shouldShowKey && (
        <div className={`${cssClass}__table m-t-25`}>
          <div key="answer-help-text" className="m-b-10">
            {t1('correct_answer')}
          </div>
          <TableLikert
            questions={getContent(likert_questions)}
            answers={getContent(likert_answers)}
            shouldShowKey={shouldShowKey}
            defaultValues={likert_correct_answers}
            multiple={likert_multiple_answers}
            tablePrefix="table-likert-correct-answers"
          />
        </div>
      )}
    </>
  );
};

export default Likert;
