import React, { Component } from 'react';
import lodashGet from 'lodash.get';
import lodashClone from 'lodash.clone';
import isEqual from 'lodash.isequal';
import Checkbox from 'antd/lib/checkbox';
import Form from 'antd/lib/form';
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc';

import * as PropTypes from 'prop-types';
import { t1 } from 'translate';
import { elementDisplayModes } from 'schema-form/constants';
import withSnackbar from 'common/hoc/withSnackbar';
import DefaultFormElementRecap from 'components/common/default-form-element-recap';

import Help from '../Help';
import './stylesheet.scss';

const MultiCheckboxInRecapMode = ({ options, value, label }) => {
  options = options || [];
  value = value || [];

  let recapContent = options
    .filter((opt) => (value || []).includes(lodashGet(opt, 'value')))
    .map((opt) => lodashGet(opt, 'label'));

  if (!(recapContent && recapContent.length > 0)) {
    return null;
  }

  recapContent = recapContent.reduce((prev, curr) => [prev, ', ', curr]);

  return <DefaultFormElementRecap label={label} content={recapContent} />;
};

const SortableItem = SortableElement(({ value, label, disabled }) => (
  <li>
    <Checkbox key={value} value={value} disabled={disabled}>
      {' '}
      {label}
    </Checkbox>
  </li>
));

const SortableList = SortableContainer(({ options, onChange, value }) => (
  <div>
    <Checkbox.Group onChange={onChange} value={value}>
      <ul>
        {options.map((option, index) => (
          <SortableItem
            {...option}
            index={index}
            key={`${lodashGet(option, 'value')}__${index}`}
          />
        ))}
      </ul>
    </Checkbox.Group>
  </div>
));

SortableList.propTypes = { onSortEnd: PropTypes.any };
const MultiCheckboxSortable = ({
  options,
  setOptionsSorted,
  value,
  onChange,
}) => {
  const [changed, setChanged] = React.useState(false);
  React.useEffect(
    () => {
      if (changed) {
        setChanged(false);
        onChange(value);
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [options],
  );
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return;
    }

    setChanged(true);
    setOptionsSorted([...arrayMove(options, oldIndex, newIndex)]);
  };

  return (
    <SortableList
      options={options}
      onSortEnd={onSortEnd}
      onChange={onChange}
      value={value}
    />
  );
};

const MultiCheckboxInDefaultMode = ({
  name,
  options,
  value,
  disabled,
  label,
  inline,
  checkAll,
  errorText,
  required,
  onChange,
  readOnly,
  noOptionLabel,
  hiddenWhenDisable,
  renderCustomizableOptions,
  sortable,
}) => {
  options = options || [];
  value = value || [];

  const disabledSorting =
    disabled ||
    !sortable ||
    readOnly ||
    !Array.isArray(options) ||
    options.length <= 1;

  const [optionsSorted, setOptionsSorted] = React.useState([]);

  React.useEffect(
    () => {
      const optionsFormatted = Array.isArray(options)
        ? options
            .map((option) => ({
              ...option,
              label: (
                <span>
                  {option.label} <Help guide={option.guide} />
                </span>
              ),
            }))
            .filter((v) => !(hiddenWhenDisable && v.disabled))
        : [];

      if (
        !optionsFormatted.length ||
        disabledSorting ||
        !Array.isArray(value) ||
        !value.length
      ) {
        setOptionsSorted(options);
        return;
      }

      setOptionsSorted(
        value
          .map((val) =>
            optionsFormatted.find((op) => lodashGet(op, 'value') === val),
          )
          .concat(
            optionsFormatted.filter(
              (op) =>
                !Array.isArray(value) ||
                !value.includes(lodashGet(op, 'value')),
            ),
          )
          .filter(Boolean),
      );
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [options, hiddenWhenDisable],
  );

  const handleChange = (formValue) => {
    if (readOnly) {
      return;
    }

    let newValue = formValue;

    if (!disabledSorting) {
      newValue = optionsSorted
        .filter(
          (opt) =>
            opt &&
            Array.isArray(formValue) &&
            formValue.includes(lodashGet(opt, 'value')),
        )
        .map(({ value }) => value);
    }

    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  };

  const getValueCheckBoxByOptions = () => {
    if (Array.isArray(optionsSorted) && optionsSorted.length) {
      return (
        optionsSorted
          .map((option) => option && option.value)
          .filter((val) => typeof val !== 'undefined') || []
      );
    }

    return [];
  };

  const getPropsCheckAll = () => {
    const optionValues = getValueCheckBoxByOptions();
    const checked =
      Array.isArray(value) &&
      value.length &&
      isEqual(lodashClone(value).sort(), optionValues.sort());
    const indeterminate =
      Array.isArray(value) &&
      value.length &&
      value.length < optionValues.length;

    return {
      checked,
      indeterminate,
      onChange: (event) => {
        handleChange(event.target.checked ? optionValues : []);
      },
    };
  };

  const handleClickOption = (val) => {
    const optionValues = getValueCheckBoxByOptions();
    let newValue = [...value];
    if (val) newValue = val;

    handleChange(optionValues.filter((map) => newValue.includes(map)));
  };

  return (
    <div id={name}>
      <Form.Item
        validateStatus={errorText ? 'error' : ''}
        help={errorText || ''}
        style={{ width: '100%' }}
      >
        {typeof renderCustomizableOptions === 'function' ? (
          renderCustomizableOptions({
            value,
            options: optionsSorted,
            onChange: handleClickOption,
            disabled: disabled || readOnly,
            propsCheckAll: getPropsCheckAll(),
          })
        ) : (
          <>
            <div>
              {label && (
                <div className={'input-text-label'}>
                  <label>{label}</label>
                </div>
              )}
              {noOptionLabel && !optionsSorted.length && (
                <div>{noOptionLabel}</div>
              )}
              {checkAll && !!optionsSorted.length && (
                <Checkbox {...getPropsCheckAll()} label={t1('check_all')}>
                  {t1('check_all')}
                </Checkbox>
              )}
            </div>
            {disabledSorting || !optionsSorted.length ? (
              <Checkbox.Group
                options={optionsSorted}
                value={value}
                onChange={handleClickOption}
                disabled={disabled || readOnly}
                className={!inline && 'ant-checkbox-multiline'}
              />
            ) : (
              <MultiCheckboxSortable
                options={optionsSorted}
                setOptionsSorted={setOptionsSorted}
                onChange={handleClickOption}
                value={value}
              />
            )}
          </>
        )}
      </Form.Item>
    </div>
  );
};

class MultiCheckbox extends Component {
  componentWillMount() {
    const { defaultValue } = this.props;
    if (defaultValue && defaultValue.length) {
      this.handleOnChange(defaultValue);
    }
  }

  componentWillReceiveProps(nextProps) {
    const inputValue = this.props.value || [];
    const { populateValue, options } = this.props;
    const valueOptions =
      Array.isArray(options) && options.map((option) => option.value);
    const nextValueOptions =
      Array.isArray(nextProps.options) &&
      nextProps.options.map((option) => option.value);

    if (populateValue && !isEqual(valueOptions, nextValueOptions)) {
      this.handleOnChange(nextValueOptions);
    } else if (
      Array.isArray(valueOptions) &&
      valueOptions.length &&
      !isEqual(valueOptions, nextValueOptions) &&
      Array.isArray(inputValue) &&
      inputValue.length
    ) {
      this.handleOnChange([]);
    }
  }

  handleOnChange = (value) => {
    const { onChange, maximum, showSnackbar } = this.props;
    if (value && value.length > maximum) {
      showSnackbar(
        'error',
        t1('you_cannot_select_more_than_%s_items', [maximum]),
      );
      return;
    }

    if (onChange) {
      onChange(value);
    }
  };

  render() {
    const {
      elementDisplayMode,
      floatingLabelText,
      required,
      name,
      options,
      errorText,
      checkAll,
      hiddenWhenOptionEmpty,
      disabled,
      inline,
      value,
      readOnly,
      noOptionLabel,
      hiddenWhenDisable,
      renderCustomizableOptions,
      sortable,
    } = this.props;

    if (hiddenWhenOptionEmpty && (!Array.isArray(options) || !options.length)) {
      return null;
    }

    switch (elementDisplayMode) {
      case elementDisplayModes.RECAP:
        return (
          <MultiCheckboxInRecapMode
            options={options}
            value={value}
            label={floatingLabelText}
          />
        );
      default:
        return (
          <MultiCheckboxInDefaultMode
            options={options}
            value={value}
            label={floatingLabelText}
            checkAll={checkAll}
            disabled={disabled}
            errorText={errorText}
            inline={inline}
            name={name}
            required={required}
            onChange={this.handleOnChange}
            readOnly={readOnly}
            noOptionLabel={noOptionLabel}
            hiddenWhenDisable={hiddenWhenDisable}
            renderCustomizableOptions={renderCustomizableOptions}
            sortable={sortable}
          />
        );
    }
  }
}

export default withSnackbar()(MultiCheckbox);
