import React from 'react';
import Icon from 'antd/lib/icon';
import Popover from 'antd/lib/popover';
import DisplayHtml from 'components/common/html';
import { isSmallScreen } from 'common';

const Tooltip = ({ content }) => (
  <Popover
    content={
      typeof content == 'string' ? <DisplayHtml content={content} /> : content
    }
    placement={`${isSmallScreen ? 'topRight' : 'topLeft'}`}
  >
    {' '}
    <Icon type="info-circle-o" />
  </Popover>
);

export default Tooltip;
