import { t1 } from 'translate';
import { ntype as ntypes, actions } from 'configs/constants';
import get from 'lodash.get';
import { parseArrayContentToString } from '../video/schema/blocks/list';
import { isSimpleInsertMode } from '../video/schema/insert-mode';
import lodashGet from 'lodash.get';
import { getCurrentUnixTimestamp } from 'common/utils/Date';

// inside roles & staff menu , we have 2 separate tabs: 1 for roles and 1 for staff
// action : staff|roles
export const navItems = (action, editBaseUrl) => [
  {
    active: action === 'staff',
    link: `${editBaseUrl}/staff`,
    label: t1('staff'),
  },
  {
    active: action === 'roles',
    link: `${editBaseUrl}/roles`,
    label: t1('roles'),
  },
];

export const shouldShowSyllabusMainContent = (node = {}, action) => {
  return (
    (node.ntype === ntypes.SYLLABUS && action !== actions.ADD_ITEM) ||
    action === actions.SEQUENTIAL
  );
};

export const shouldShowSubContentAsTabs = (node, action) => {
  return (
    [
      'edit',
      'edit-preview',
      'skills',
      'avatar',
      'advanced-settings',
      'questions-of-video',
      'layout',
    ].includes(action) ||
    (['children', 'add-item'].includes(action) && isExerciseItem(node))
  );
};

export const shouldShowContentInsideMainStage = (action) => {
  return (
    ![
      'dashboard',
      'courses',
      'staff',
      'roles',
      'rubric',
      'programs',
      'survey',
      'approval',
      'comments',
      'sessions',
    ].includes(action) && action
  );
};

export const isExerciseItem = (item) => item.ntype === ntypes.EXERCISE;

export const isScoSpl = (item) => item.ntype === ntypes.SCO && item.is_spl;

const shouldFilerData = (step) => ['metadata', 'children'].includes(step);

export const filterDataToUpdate = (node = {}, step, mode) => {
  if (!node || !shouldFilerData(step || get(node, '_sand_step'))) {
    return node;
  }

  const fieldsToSubmit = [
    'children',
    'pid',
    'duration',
    'id',
    'iid',
    'ntype',
    'type',
    'syllabus',
    '_sand_step',
  ];

  if (mode !== 'edit') {
    fieldsToSubmit.push('metadata');
  }

  const dataToSubmit = [];
  fieldsToSubmit.forEach((field) => {
    dataToSubmit[field] = node[field];
  });

  return dataToSubmit;
};

export const removeNodeByFieldEdit = (
  nodes,
  nodeToRemove,
  parentNode,
  fieldEdit,
) => {
  const iidToRemove = get(nodeToRemove, 'iid');

  let dataEdit = get(parentNode, fieldEdit, []);

  if (!iidToRemove || !dataEdit.length) {
    return;
  }

  parentNode[fieldEdit] = dataEdit
    .map((valueInArray) => {
      const newValueInArray =
        typeof valueInArray === 'object'
          ? valueInArray
          : get(nodes, valueInArray);
      const iid = get(newValueInArray, 'iid');

      if (!iid || iid === iidToRemove) {
        return false;
      }

      return newValueInArray;
    })
    .filter(Boolean);

  return parentNode;
};

export const formatNode = (node) => {
  const type = get(node, 'type');
  if (type !== 'list') {
    return node;
  }

  const blockList = get(node, 'block_list');
  const insertMode = get(node, 'insert_mode');
  if (!isSimpleInsertMode(insertMode)) {
    return node;
  }

  return {
    ...node,
    block_list: parseArrayContentToString(blockList),
  };
};

export const isSyllabusExpired = (syllabus, serverTs = null) => {
  const endDate = lodashGet(syllabus, 'expire_date');
  const currentTs = serverTs || getCurrentUnixTimestamp();

  return endDate && endDate < currentTs;
};

export const canViewSyllabusItemWhenExpired = (syllabus, serverTs) => {
  const expired = isSyllabusExpired(syllabus, serverTs);

  return expired && lodashGet(syllabus, 'allow_view_syllabus_when_expired');
};
