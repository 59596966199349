import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import makeReduxFormCompatible from 'components/common/makeReduxFormCompatible';
import { normalizeNumberString } from 'common/normalizers';

class TextFieldEditable extends React.Component {
  cssClass = 'Editable-text-field';

  componentDidMount() {
    this.textInput.focus();
  }

  handleChange = (event) => {
    const { onChange, numberType, type } = this.props;

    let value = event.target.value;

    if (value && type === 'number') {
      value = normalizeNumberString({ isInteger: numberType === 'integer' })(
        value,
      );
    }

    onChange(value);
  };

  render() {
    const { className, label, errorText, value, onKeyDown } = this.props;

    return (
      <TextField
        className={`${className || ''} ${this.cssClass}`}
        hintText={label}
        ref={(textInput) => {
          this.textInput = textInput;
        }}
        floatingLabelText={label}
        errorText={errorText}
        value={value}
        onChange={this.handleChange}
        onKeyDown={onKeyDown}
      />
    );
  }
}

TextFieldEditable.propTypes = {
  className: PropTypes.string,
};

TextFieldEditable.defaultProps = {
  className: '',
};

export default makeReduxFormCompatible({})(TextFieldEditable);
