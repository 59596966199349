const Radio = ({
  options,
  hintText,
  floatingLabelText,
  floatingLabelFixed,
  labelStyle, // object like { color: rgb(0,0,0), width, marginRight..} for the radio label

  defaultValue,
  errorText,
  validate,
  normalize,
  onChange,
  classWrapper,
  fullWidth,
  readOnly,
  inline,
  ...rest
}) => {
  return {
    type: 'radio',
    options,
    hintText,
    floatingLabelText,
    floatingLabelFixed,
    defaultValue,
    errorText,
    validate,
    normalize,
    onChange,
    classWrapper,
    fullWidth,
    readOnly,
    inline,
    ...rest,
  };
};

export default Radio;
