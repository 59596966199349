import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { t1 } from 'translate';
import Table from 'antd/lib/table';
import lodashGet from 'lodash.get';
import DeleteItem from 'components/common/action-button/DeleteBtnWithConfirmDialog';
import { Link } from 'react-router-dom';
import { timestampToDateString } from 'common/utils/Date';
import endpoints from 'components/admin/group/endpoints';
import CopyClipboard from 'components/common/copy-clipboard';
import { editGroupMembers } from 'components/admin/group/routes';

import Tooltip from 'antd/lib/tooltip';
import Icon from 'components/common/Icon';
import withUserInfo from 'common/hoc/withUserInfo';
import DisplayGroupMembers, { DisplayUserNames } from './DisplayMembers';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import fetchData from 'components/common/fetchData';
import apiUrl from 'components/front-end/meet/endpoints';
import { compose } from 'redux';

const MeetGroupSearchResults = ({
  items,
  formid,
  mode,
  userInfo,
  refetchUserGroup,
  meetingPermissions,
}) => {
  const canCreateMeeting = lodashGet(meetingPermissions, 'create');

  const editLabel = t1('edit_user_group');
  const removeLabel = t1('remove');
  const textConfirm = t1('are_you_sure_you_want_to_do_this');

  const columns = [
    {
      title: t1('group_name'),
      key: 'name',
      dataIndex: 'name',
      type: TYPE_OF_TABLE_DATA.TEXT,
      width: 200,
      render: (name, item) => (
        <>
          {name}
          <br />
          #<CopyClipboard value={item.iid} showLabel title={'id'} />
        </>
      ),
    },
    {
      title: t1('moderators'),
      key: 'moderators',
      dataIndex: 'moderators',
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (moderators, item) => {
        return <DisplayUserNames members={moderators} maxDisplay={5} />;
      },
    },
    {
      title: t1('members'),
      key: 'members',
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (item) => {
        const total = item.current_members;
        if (total) {
          return (
            <DisplayGroupMembers
              displayOverview
              item={item}
              showGroupName={false}
              canShowDetailMembers={canCreateMeeting}
            />
          );
        }

        return null;
      },
    },

    // {
    //   title: t1('next_meeting'),
    //   key: 'next_meeting',
    //   render: (item) => {
    //     const session = lodashGet(item, '__expand.next_meeting');
    //     if (session) {
    //       return (
    //         <div>
    //           <Link to={joinMeeting(session)}>{session.name}</Link>
    //           <SessionSchedule session={session} />
    //         </div>
    //       );
    //     }
    //     return '-';
    //   },
    // },
    {
      title: t1('created_at'),
      key: 'created_at',
      dataIndex: 'ts',
      width: 100,
      type: TYPE_OF_TABLE_DATA.DATE,
      render: (timestamp) => {
        return timestampToDateString(timestamp);
      },
    },
    {
      title: t1('action'),
      width: 150,
      key: 'action',
      dataIndex: 'action',
      className: 'text-center',
      type: TYPE_OF_TABLE_DATA.ACTION,
      render: (text, item) =>
        userInfo.iid == lodashGet(item, 'u.iid') ? (
          <>
            <span className={'m-r-10'}>
              <Link to={editGroupMembers(item.iid, mode)}>
                <Tooltip placement="top" title={editLabel}>
                  <Icon icon="edit" />
                </Tooltip>
              </Link>
            </span>
            <DeleteItem
              textConfirm={textConfirm}
              formid={formid}
              itemId={item.id}
              alternativeApi={endpoints.delete_group}
              onRequestSuccessful={refetchUserGroup}
              renderComponent={({ onClick }) => {
                return (
                  <Tooltip placement="top" title={removeLabel}>
                    <Icon
                      className={'text-danger'}
                      icon="remove"
                      onClick={onClick}
                    />
                  </Tooltip>
                );
              }}
            />
          </>
        ) : null,
    },
  ];
  return (
    <div className="table-result">
      <Table
        dataSource={items}
        columns={columns}
        pagination={false}
        className="white-background"
        childrenColumnName={null}
        rowKey="id"
      />
    </div>
  );
};

MeetGroupSearchResults.propTypes = {
  formid: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
};

MeetGroupSearchResults.defaultProps = {
  formid: 'category_group_search_result',
  items: [],
};
const fetchHasPermissionCreateMeeting = fetchData((props) => ({
  baseUrl: apiUrl.meeting_permissions,
  fetchCondition: true,
  propKey: 'meetingPermissions',
  refetchCondition: () => false,
}));
export default compose(
  connect(),
  fetchHasPermissionCreateMeeting,
  withUserInfo,
)(MeetGroupSearchResults);
