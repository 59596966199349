import { teacherCourseUrl } from 'routes/root-url';

export const menuItems = {
  SCORE_DETAIL: 'score-detail',
  OVERVIEW: 'overview',
  OPEN_ENDED_MARKINGS: 'open-ended-markings',
  QA: 'qa',
};

export const teacherCourseDashboardLink = (item, menu) => {
  return teacherCourseDashboardLinkById(item.iid, menu);
};

export const teacherCourseDashboardLinkById = (iid, menu) => {
  return `${teacherCourseUrl}/${iid}/${menu}`;
};
