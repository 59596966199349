import { t1 } from 'translate';
import { addTaphuanEpV2UserOrganizationsToCourseName } from 'components/admin/course/common';
import get from 'lodash.get';
import apiUrls from 'api-endpoints';
import courseUrls from 'components/admin/course/endpoints';
import { isCourseEnded } from '../course/utils';

export const courseFilter = ({
  orgIids,
  isStudent,
  epIid,
  getCoursesApiUrl = '',
  apiParams = {},
  includeAllOption = true,
  multiple = false,
  showCourseIid = true,
  showEndedCourses = true,
  courseRubricIid = null,
  classWrapper = '',
  learningTypes = ['online', 'ilt'],
  allProjects = false,
}) => {
  const shouldAddTaphuanEpV2UserOrganizationsToCourseName = !isStudent;

  let apiUrl = getCoursesApiUrl || courseUrls.my_active_courses;
  let apiData = {
    page: 1,
    items_per_page: 200,
    learning_type: learningTypes,
    status: ['approved'],
    public: 0,
    include_sub_organizations: 1,
    requireOrganization: 1,
    is_active: 1,
    all_projects: allProjects ? 1 : 0,
    _sand_expand: ['enrolment_plans'],
    ...(apiParams || {}),
  };

  let key = `course_list_${isStudent ? 'student' : 'teacher'}_${epIid}`;
  const sessionStatuses = get(apiParams, 'session_status', []);
  if (sessionStatuses.length) {
    key = `${key}_${sessionStatuses.join('_')}`;
  }

  if (!isStudent) {
    apiUrl = getCoursesApiUrl || apiUrls.course_search;
    apiData = {
      ...apiData,
      organizations: orgIids,
      learning_type: ['ilt'],
    };
  }

  if (epIid) {
    apiUrl = apiUrls.course_search;
    apiData = {
      status: ['approved'],
      learning_type: learningTypes,
      items_per_page: -1,
      enrolment_plans: [epIid],
    };
  }

  return {
    type: 'select',
    label: t1('select_course'),
    floatingLabelText: t1('select_course'),
    options: 'async',
    multiple,
    classWrapper,
    paramsasync: {
      key,
      __url__: apiUrl,
      transformData: (courses) => {
        if (!showEndedCourses) {
          courses = courses.filter((course) => !isCourseEnded(course));
        }

        if (courseRubricIid) {
          courses = courses.filter(
            (course) => course.rubric_iid == courseRubricIid,
          );
        }

        const options = (courses || []).map((course) => {
          let name = get(course, 'name');
          let iid = get(course, 'iid');

          if (shouldAddTaphuanEpV2UserOrganizationsToCourseName) {
            name = addTaphuanEpV2UserOrganizationsToCourseName(course);
          }

          return {
            value: get(course, 'iid'),
            label: showCourseIid ? `${name} (#${iid})` : name,
          };
        });

        return [
          ...(includeAllOption
            ? [
                {
                  value: '',
                  label: t1('all_courses'),
                  primaryText: t1('all_courses'),
                },
              ]
            : []),
          ...options,
        ];
      },
      value: apiData,
    },
    showSearch: true,
    fullWidth: true,
  };
};
