import React from 'react';
import { t1 } from 'translate';
import DetailOnDialog from 'components/common/detail-on-dialog';

const WaitForMarking = () => {
  return (
    <DetailOnDialog
      renderPreview={({ showFull }) => (
        <span onClick={showFull} className="cursor-pointer text-bold">
          {t1('wait_for_marking')}
        </span>
      )}
      renderFull={() => {
        return (
          <div>
            {t1(
              'this_exercise_cannot_be_automatically_graded_by_computer_and_requires_marking_therefore_you_cannot_see_your_score_until_your_instructor_has_graded_your_work',
            )}
          </div>
        );
      }}
      dialogKey="wait_for_marking"
      dialogOptionsProperties={{
        modal: true,
        title: t1('wait_for_marking'),
        handleClose: true,
      }}
    />
  );
};

export default WaitForMarking;
