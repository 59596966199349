import { caphoc, targetUser, trainingModel } from 'components/common/elements';
import { input } from 'components/common/forms/schema-fields';
import DateTimePicker from 'schema-form/elements/date-time-picker';
import { UiLibs } from 'configs/constants';

const schema = () => {
  return {
    name: input('Tên đợt', false, {
      classWrapper: 'col-md-4',
    }),
    start_date: {
      type: DateTimePicker,
      uiLib: UiLibs.ANT,
      floatingLabelText: 'Thời gian bắt đầu',
      fullWidth: true,
      classWrapper: 'col-md-6',
    },
    end_date: {
      type: DateTimePicker,
      uiLib: UiLibs.ANT,
      floatingLabelText: 'Thời gian kết thúc',
      fullWidth: true,
      classWrapper: 'col-md-6',
    },
    target_user: targetUser({
      type: 'radio',
      classWrapper: 'col-md-3',
      defaultValue: '',
    }),
    caps: caphoc({
      classWrapper: 'col-md-6',
    }),
    training_model: trainingModel({
      classWrapper: 'col-md-3',
      defaultValue: '',
    }),
  };
};

const ui = () => {
  return [
    {
      id: 'default',
      fields: [
        'start_date',
        'end_date',
        'target_user',
        'training_model',
        'caps',
        // 'name',
      ],
    },
  ];
};

export default { schema, ui };
