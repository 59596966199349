import React from 'react';
import { t1 } from 'translate';

const SelectedRowMessage = ({
  selectMessage = t1('choose'),
  totalRowSelected = 0,
  totalRow = 0,
  selectAll,
  className = '',
  rowFoundMessage = t1('members_found'),
  rowSelectedMessage = t1('members_selected'),
}) => {
  return (
    <h3 className={className}>
      <span className="m-r-5">{selectMessage}</span>
      <span className="text-bold text-primary">
        {selectAll ? totalRow : totalRowSelected}
      </span>
      <span className="m-l-5">
        {selectAll ? rowFoundMessage : rowSelectedMessage}
      </span>
    </h3>
  );
};

export default SelectedRowMessage;
