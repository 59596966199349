import React, { Component } from 'react';
import './stylesheet.scss';

class CloseButton extends Component {
  render() {
    const { onClick } = this.props;
    return <span onClick={onClick} className="ve-close" />;
  }
}

export default CloseButton;
