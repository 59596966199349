import React from 'react';
import { t1 } from 'translate';
import QuestionGroups from 'components/learn/items/exercise/NormalExercise/common/NormalExerciseControlQuestionGroups';
import './NormalExerciseControl.scss';
import Portal, { portals } from 'components/common/portal';
import Modal from 'antd/lib/modal';
import screenfull from 'screenfull';
import QuestionSavingStatus from '../question-saving-status';
import { Tooltip } from 'antd';
import { isQuestionMissing } from 'components/learn/utils';
import TakeHistories from 'components/learn/items/exercise/NormalExercise/take-histories';
import lodashGet from 'lodash.get';
import { scrollToLearnContainer } from 'components/learn/common/Util';
import { isSmallScreen } from 'common';
import { shouldShowTakeHistory } from '../common/utils';

const NormalExerciseControl = ({
  currentQuestionUniqueId = null,
  exercise = null,
  failToSaveTakeQuestions = [],
  isBackButtonDisabled = false,
  isFinishButtonDisabled = false,
  isNextButtonDisabled = false,
  isQuestionClickable = null,
  onBackButtonClick = null,
  onCheckAnswerButtonClick = null,
  onFinishButtonOnClick = null,
  onNextButtonClick = null,
  onQuestionClick = null,
  showBackButton = true,
  showCheckButton = true,
  showControlQuestions = true,
  showFinishButton = true,
  showNextButton = true,
  labelFinish,
  finishButtonStyle,
  textForFinishButtonDisabled = '',
  onFinishReviewButtonOnClick,
  isFinishReviewButtonDisabled,
  finishReviewButtonStyle,
  labelFinishReview,
  showFinishReviewButton = false,
  showQuestionSavingStatus = false,
  inlineExercise = false,
  userInfo,
  course,
  syllabus,
}) => {
  const canShowControlQuestions =
    showControlQuestions &&
    exercise &&
    exercise.children &&
    Array.isArray(exercise.children) &&
    exercise.children.length > 1 &&
    !isQuestionMissing(exercise);

  let questionNav;
  if (canShowControlQuestions) {
    questionNav = (
      <div className="normal-exercise-control__groups">
        <QuestionGroups
          questions={exercise.children}
          failToSaveTakeQuestions={failToSaveTakeQuestions}
          currentQuestionUniqueId={currentQuestionUniqueId}
          isQuestionClickable={isQuestionClickable}
          onQuestionClick={onQuestionClick}
        />
      </div>
    );
  }

  const { ntype } = exercise;

  const isSurvey = ntype === 'survey';
  const guideText = (
    <div className="font-size-small text-danger m-b-10 m-t-10">
      {isSurvey
        ? t1('you_have_to_click_finish_button_to_finish_the_survey')
        : t1('you_have_to_click_finish_button_to_finish_the_exercise')}
    </div>
  );

  const finishButton = (
    <>
      <button
        className="btn-secondary"
        onClick={() => {
          if (screenfull && screenfull.enabled) {
            screenfull.exit();
          }
          if (!isSurvey) {
            Modal.confirm({
              centered: true,
              title: 'Xác nhận kết thúc',
              content: 'Bạn có chắc chắn muốn kết thúc làm bài tập không?',
              onOk() {
                onFinishButtonOnClick();
              },
            });
          } else {
            onFinishButtonOnClick();
          }
        }}
        disabled={isFinishButtonDisabled}
        style={finishButtonStyle}
      >
        {labelFinish || t1('finish')}
      </button>
    </>
  );

  const checkButton = showCheckButton ? (
    <Tooltip title={t1('when_you_clicked_you_can_not_change_your_answer')}>
      <button
        className="btn btn-secondary m-l-3 m-r-3 p-5 p-l-7 p-r-7"
        onClick={onCheckAnswerButtonClick}
      >
        {t1('check')}
      </button>
    </Tooltip>
  ) : null;

  const historiesButton = (
    <div className={'d-flex align-item-center justify-content-center '}>
      <TakeHistories
        userIid={lodashGet(userInfo, 'iid')}
        exerciseIid={lodashGet(exercise, 'iid')}
        course={course}
        syllabus={syllabus}
        inlineExercise={inlineExercise}
      />
    </div>
  );

  if (isSurvey) {
    return (
      <div className="text-center m-t-5">
        {finishButton}
        {guideText}
      </div>
    );
  }

  if (inlineExercise) {
    return (
      <div className="text-center m-t-5">
        <div className="d-flex align-items-center justify-content-center">
          {finishButton}
          {shouldShowTakeHistory(exercise) && (
            <div className="m-l-10">{historiesButton}</div>
          )}
        </div>

        {guideText}
      </div>
    );
  }

  return (
    <div className="learn-control normal-exercise-control center">
      {canShowControlQuestions && (
        <div className="normal-exercise-control__questions`">
          <Portal
            id={portals.QUESTION_NAVIGATIONS}
            insideCard
            title={t1('exercise_questions')}
          >
            {questionNav}
          </Portal>
        </div>
      )}

      <div
        className={`normal-exercise-control__action ${
          showBackButton || showNextButton || showCheckButton
            ? 'normal-exercise-control__mobile'
            : ''
        } ${ntype === 'survey' ? 'text-center' : 'center'}`}
      >
        <button
          className={`btn btn-secondary m-l-3 m-r-3 p-5 p-l-7 p-r-7 ${
            isBackButtonDisabled || !showBackButton ? 'visibility-hidden' : ''
          }`}
          onClick={onBackButtonClick}
          disabled={isBackButtonDisabled || !showBackButton}
        >
          {t1('previous_question')}
        </button>
        <div className="center" style={{ flex: 1 }}>
          {ntype !== 'survey' && checkButton}
        </div>
        <button
          className={`btn btn-secondary m-l-3 m-r-3 p-5 p-l-7 p-r-7 ${
            isNextButtonDisabled || !showNextButton ? 'visibility-hidden' : ''
          }`}
          onClick={onNextButtonClick}
          disabled={isNextButtonDisabled || !showNextButton}
        >
          {t1('next_question')}
        </button>

        {showFinishButton ? (
          <Portal id={portals.EXERCISE_FINISH_BUTTON}>
            <div className="text-center">
              {finishButton}
              {guideText}
            </div>
          </Portal>
        ) : null}

        {showQuestionSavingStatus && (
          <Portal id={portals.EXERCISE_QUESTION_SAVING_STATUS}>
            <QuestionSavingStatus itemIid={exercise.iid} />
          </Portal>
        )}

        {!!showFinishReviewButton && (
          <Portal id={portals.EXERCISE_FINISH_REVIEW_BUTTON}>
            <div className="text-center">
              <button
                className="btn-secondary m-t-20 m-l-5 m-r-5 m-b-15"
                onClick={() => {
                  onFinishReviewButtonOnClick();
                  if (isSmallScreen) {
                    scrollToLearnContainer(window.innerWidth);
                  }
                }}
                disabled={isFinishReviewButtonDisabled}
                style={finishReviewButtonStyle}
              >
                {labelFinishReview || t1('finish_review')}
              </button>
            </div>
          </Portal>
        )}

        {shouldShowTakeHistory(exercise) && (
          <Portal id={portals.EXERCISE_TAKE_HISTORIES}>
            {historiesButton}
          </Portal>
        )}
      </div>
      <div className="clearfix" />
    </div>
  );
};

export default NormalExerciseControl;
