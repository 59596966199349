import React from 'react';
import store from 'store/index';
import get from 'lodash.get';
import { t1 } from 'translate/index';

import { equivalentPositions } from 'components/admin/equivalent-job-position/schema/elements';
import { evnEquivalentPositions } from 'components/admin/top-equivalent-position/schema/elements';
import { positions } from 'components/admin/job-position/schema/elements';
import { UiLibs } from 'configs/constants';
import skill from 'components/admin/group/schema/elements/filterset/filter/skill/skill';
import { textOp } from 'components/admin/group/schema/elements/filterset/filter/query/text';
import sex from 'components/admin/group/schema/elements/filterset/filter/sex/sex';
import age from 'components/admin/group/schema/elements/filterset/filter/age/age';
import experience from 'components/admin/group/schema/elements/filterset/filter/experience/experience';
import code from 'components/admin/group/schema/elements/filterset/filter/schema/code';
import codes from 'components/admin/group/schema/elements/filterset/filter/schema/codes';
import name from 'components/admin/group/schema/elements/filterset/filter/schema/name';
import lname from 'components/admin/group/schema/elements/filterset/filter/schema/lname';
import external from 'components/admin/group/schema/elements/filterset/filter/schema/external';
import creditSyllabuses from 'components/admin/group/schema/elements/filterset/filter/schema/creditSyllabuses';
import iid from 'components/admin/group/schema/elements/filterset/filter/schema/iid';
import mail from 'components/admin/group/schema/elements/filterset/filter/schema/mail';
import statuses from 'components/admin/group/schema/elements/filterset/filter/schema/statuses';
import subject from 'components/admin/group/schema/elements/filterset/filter/schema/subject';
import contractBox from 'components/admin/group/schema/elements/filterset/filter/schema/contractBox';
import totalCreditBox from 'components/admin/group/schema/elements/filterset/filter/schema/totalCreditBox';
import averageScoreBox from 'components/admin/group/schema/elements/filterset/filter/schema/averageScoreBox';
import { gradeElement, groupElement } from 'common/utils/form';
import DatePicker from 'schema-form/elements/date-picker';
import { commonFormLayouts, elementDisplayModes } from 'schema-form/constants';
import { addPropsToEverySchemaElements } from 'common/utils/schema-form';
import MinimalSearchRecapFreeStyleLayout from 'schema-form/layouts/common-freestyle-layouts/MinimalSearchRecap';
import {
  includeSubOrganizations,
  organizations,
} from 'components/admin/organization/schema/elements';
import {
  approveStatuses,
  leaderPositions,
  leaderPositionToText,
  userGradeOptions,
  workingOptions,
} from 'configs/constants/user';
import { academicCategories } from 'components/admin/academic-category/schema/elements';
import cotCanDataByCategory from 'components/admin/group/schema/elements/filterset/filter/schema/cotCanDataByCategory';
import trainingGrades from 'components/admin/group/schema/elements/filterset/filter/schema/trainingGrades';
import mails from 'components/admin/group/schema/elements/filterset/filter/schema/mails';
import dia_ban_khokhan from 'components/admin/group/schema/elements/filterset/filter/schema/dia_ban_khokhan';
import dtts from 'components/admin/group/schema/elements/filterset/filter/schema/dtts';
import { remove } from 'common/utils/Array';
import { taphuanTargetUserMulti } from 'components/admin/group/schema/elements/filterset/filter/schema/taphuanTargetUser';
import needToAddToPreviousTpCategory from 'components/admin/group/schema/elements/filterset/filter/schema/needToAddToPreviousTpCategory';
import shouldSkipQuota from 'components/admin/group/schema/elements/filterset/filter/schema/shouldSkipQuota';
import iidMultiple from 'components/admin/group/schema/elements/filterset/filter/schema/iidMultiple';
import Perm from 'common/utils/Perm';
import taphuanSubTypes from 'configs/constants/org-sub-types';

const getAllowedEnableFiltersKey = (isStaff) => {
  return isStaff
    ? 'domainInfo.conf.allowed_staff_filters'
    : 'domainInfo.conf.allowed_target_group_filters';
};

const getApproveStatusOptions = (availableApproveStatusOptions = []) => {
  return [
    {
      value: approveStatuses.NOT_YET_APPROVED,
      label: t1('student_approve_status_not_yet_approved'),
    },
    {
      value: approveStatuses.APPROVED_BY_SGD,
      label: t1('student_approve_status_approved_by_sgd'),
    },
    {
      value: approveStatuses.APPROVED_BY_GVSP,
      label: t1('student_approve_status_approved_by_gvsp'),
    },
  ].filter((option) => availableApproveStatusOptions.includes(option.value));
};

const getElementsSchema = (forRecap = false, props) => (
  formid,
  values,
  localStep,
  xpath,
  domainInfo,
) => {
  const userOrganizations = get(
    values,
    xpath ? `${xpath}.user_organizations` : 'user_organizations',
  );

  const tpCategory = get(values, xpath ? `${xpath}.tpCategory` : 'tpCategory');
  const trainingPlanIid = get(
    values,
    xpath ? `${xpath}.training_plan_iid` : 'training_plan_iid',
  );

  const showNeedToAddToPreviousTpCategory =
    props.showNeedToAddToPreviousTpCategory;

  let elementsSchema = {
    positions: positions(formid, {}, userOrganizations, {
      notRequiredOrganization: 1,
    }),
    equivalent_positions: equivalentPositions(formid, userOrganizations),
    evn_equivalent_positions: evnEquivalentPositions(formid, userOrganizations),
    user_organizations: organizations({
      formid,
      label: `${t1('content_organizations')} (*)`,
      defaultValue: props.orgIids,
      fullWidth: true,
      rootIids: values.organizationRootIids,
      includeRoot: values.includeRootOrganizations,
      getOnlyOrganizationWhereUserHasPermission:
        values.getOnlyOrganizationWhereUserHasPermission,
      defaultOrganizations:
        Array.isArray(props.orgIids) && props.orgIids.length > 0
          ? props.orgIids
          : undefined,
      includeSubOrganizations: 0,
      includeSubOrganizationsLabel: t1('include_users_in_sub_organizations'),
      trainingPlanCategory: tpCategory,
      trainingPlanIid,
      subTypesDiscarded: showNeedToAddToPreviousTpCategory
        ? [taphuanSubTypes.TAPHUAN_SUBTYPE_LTTU]
        : undefined,
    }),
    include_sub_organizations: includeSubOrganizations(domainInfo.conf),
    skill,
    // text: text(),
    textOp: textOp(),
    sex: sex({ classWrapper: forRecap ? null : 'col-md-6' }),
    age: age({ classWrapper: forRecap ? null : 'col-md-6' }),
    experience: experience({ classWrapper: forRecap ? null : 'col-md-6' }),
    code: code(),
    codes: codes(),
    nin_codes: codes(true),
    mails: mails(),
    nin_mails: mails(true),
    should_skip_quota: shouldSkipQuota(),
    dtts: dtts(),
    dia_ban_khokhan: dia_ban_khokhan(),
    lname: lname(),
    external: external({ classWrapper: forRecap ? null : 'col-md-6' }),
    name: name(),
    iid: iidMultiple(),
    mail: mail(),
    contract_box: contractBox,
    statuses: statuses(
      values.license_sale_package || values.license_keys
        ? {
            defaultValues: [],
            floatingLabelText: 'Trạng thái trên Hệ thống',
          }
        : {
            floatingLabelText: 'Trạng thái trên Hệ thống',
          },
    ),
    account_from_system: {
      name: 'status',
      type: 'multiCheckbox',
      inline: true,
      classWrapper: forRecap ? null : 'col-md-6',
      floatingLabelText: t1('the_account_from_the_system'),
      options: [
        {
          name: 'system',
          value: 'system',
          label: t1('from_in_system'),
        },
        {
          name: 'hrms',
          value: 'hrms',
          label: t1('from_hrms_system'),
        },
      ],
      defaultValue: [],
    },
    credit_syllabuses: creditSyllabuses(formid),
    total_credit_box: totalCreditBox,
    average_score_box: averageScoreBox,
    subject,
    school__grade: gradeElement(domainInfo, true, t1('grade')), // for search
    school__group: groupElement(values, 'school__grade'),
    number_of_warnings: {
      type: 'number',
      min: 0,
      step: 1,
      floatingLabelText: t1('number_of_warnings'),
      classWrapper: 'col-md-6 m-t-10',
      fullWidth: true,
    },
    time_to_start: {
      type: DatePicker,
      uiLib: UiLibs.ANT,
      classWrapper: 'col-md-6',
      floatingLabelText: t1('date_user_join_learning'),
      fullWidth: true,
      getEndDate: true,
      maxDate: new Date(),
      autoOk: true,
    },
    grade: {
      type: 'multiCheckbox',
      floatingLabelText: t1('user_grade'),
      classWrapper: forRecap ? null : 'col-md-6',
      options: userGradeOptions(),
      inline: true,
    },
    license_in_active: {
      type: 'multiCheckbox',
      classWrapper: forRecap ? null : 'col-md-6',
      floatingLabelText: t1('user_license_status'),
      options: [
        {
          value: 'active',
          label: 'Đang hoạt động',
        },
        {
          value: 'inactive',
          label: 'Hết hạn',
        },
        {
          value: 'no_license',
          label: 'Chưa có giấy phép',
        },
      ],
      inline: true,
    },
    training_grades: trainingGrades(formid, values.grade),
    academic_categories: academicCategories(formid, {}),
    cot_can_data_by_category: cotCanDataByCategory({
      forRecap,
    }),
    target_user: taphuanTargetUserMulti(formid),
    working_status: {
      type: 'multiCheckbox',
      floatingLabelText: t1('working_status'),
      options: workingOptions(),
      inline: true,
    },
    temis_account_status: {
      type: 'multiCheckbox',
      inline: true,
      floatingLabelText: 'Trạng thái tài khoản trên TEMIS',
      options: [
        { value: 'activated', label: 'Đang hoạt động' },
        { value: 'inactivate', label: 'Không hoạt động' },
      ],
    },

    leader_position: {
      type: 'select',
      floatingLabelText: 'Chức vụ',
      fullWidth: true,
      options: Perm.hasPerm('root')
        ? [
            { value: '', label: t1('all') },
            ...Object.values(leaderPositions)
              .map(
                (v) =>
                  leaderPositionToText(v) && {
                    value: v,
                    label: leaderPositionToText(v),
                  },
              )
              .filter(Boolean),
          ].filter(Boolean)
        : [
            { value: '', label: t1('all') },
            {
              value: leaderPositions.LEADER,
              label: leaderPositionToText(leaderPositions.LEADER),
            },
            {
              value: leaderPositions.VICE_LEADER,
              label: leaderPositionToText(leaderPositions.VICE_LEADER),
            },
            {
              value: leaderPositions.TO_TRUONG,
              label: leaderPositionToText(leaderPositions.TO_TRUONG),
            },
            {
              value: leaderPositions.TEACHER,
              label: leaderPositionToText(leaderPositions.TEACHER),
            },
          ],
    },
  };

  if (props.showApproveStatusFilter) {
    const options = getApproveStatusOptions(
      props.availableApproveStatusOptions || [],
    );

    if (options.length) {
      elementsSchema.approve_status = {
        type: 'multiCheckbox',
        floatingLabelText: t1('student_approve_status'),
        options,
        inline: true,
      };
    }
  }

  if (showNeedToAddToPreviousTpCategory) {
    elementsSchema.need_to_add_to_previous_tp_category = needToAddToPreviousTpCategory();
  }

  if (forRecap) {
    return addPropsToEverySchemaElements(elementsSchema, {
      elementDisplayMode: elementDisplayModes.RECAP,
    });
  }
  return elementsSchema;
};

const availableElements = [
  'approve_status',
  'need_to_add_to_previous_tp_category',

  'positions',
  'equivalent_positions',
  'evn_equivalent_positions',
  'user_organizations',
  'include_sub_organizations',
  'skill',
  'textOp',
  'sex',
  'age',
  'experience',
  'code',
  'codes',
  'nin_codes',
  'mails',
  'nin_mails',
  'should_skip_quota',
  'dtts',
  'dia_ban_khokhan',
  'lname',
  'external',
  'name',
  'iid',
  'mail',
  'contract_box',
  'statuses',
  'account_from_system',
  'credit_syllabuses',
  'total_credit_box',
  'average_score_box',
  'subject',
  'school__grade',
  'school__group',
  'number_of_warnings',
  'time_to_start',
  'grade',
  'license_in_active',
  'training_grades',
  'academic_categories',
  'cot_can_data_by_category',
  'target_user',
  'working_status',
  'temis_account_status',
  'leader_position',
];

const ui = (
  step,
  values,
  themeConfig,
  xpath,
  formid,
  props,
  mode,
  domainInfo,
) => {
  const skipFields = get(props, 'skipFields', []);

  const allowedEnableFiltersKey = getAllowedEnableFiltersKey(
    values && values.is_staff,
  );

  const requiredLicense = get(domainInfo, 'conf.required_license');

  //Hack display all field
  let allowedEnabledFilters = (
    get(store.getState(), allowedEnableFiltersKey) || []
  ).filter((f) => f !== 'text');
  allowedEnabledFilters.splice(
    allowedEnabledFilters.indexOf('user_organizations') + 1,
    0,
    'include_sub_organizations',
  );
  let fields = ['textOp', ...allowedEnabledFilters];
  // let fields;

  if (formid === 'account_search' && fields.includes('temis_account_status')) {
    fields.push('leader_position');
  }
  // let fields;

  if (formid === 'account_search' && window.isETEP) {
    fields.push('account_from_system');
  }

  if (props.showApproveStatusFilter) {
    const options = getApproveStatusOptions(
      props.availableApproveStatusOptions || [],
    );

    if (options.length) {
      fields.push('approve_status');
    }
  }

  if (props.showNeedToAddToPreviousTpCategory) {
    fields.push('need_to_add_to_previous_tp_category');
  }

  if (props.hideCotCanUserField) {
    fields = remove(fields, 'cot_can_data_by_category');
  }

  fields.push('should_skip_quota');
  fields.push('working_status');

  if (requiredLicense || Perm.hasPerm('root')) {
    fields.push('license_in_active');
  }

  if (Array.isArray(fields)) {
    fields = fields.filter((f) => availableElements.includes(f));
  }

  return [
    // TODO: should we switch if it's ums or enterprise here
    // or get the list from configurations, of which fields are searchable by each school
    {
      id: 'filters', // you still have to have this id even for freestyle
      fields: fields.filter((field) => !skipFields.includes(field)),
    },
  ];
};

const getSchema = (forRecap = false, props = {}) => ({
  schema: getElementsSchema(forRecap, props),
  ui: (
    step,
    values,
    themeConfig,
    xpath,
    formid,
    ownProps,
    mode,
    domainInfo,
  ) => {
    // we want to get props that are passed to the SearchWrapper component too
    const mergeProps = { ...ownProps, ...props };

    return ui(
      step,
      values,
      themeConfig,
      xpath,
      formid,
      mergeProps,
      mode,
      domainInfo,
    );
  },
  layout: forRecap
    ? {
        freestyle: 1,
        component: MinimalSearchRecapFreeStyleLayout,
      }
    : commonFormLayouts.DEFAULT,
});

export const searchFormSchema = (props = {}) => getSchema(false, props);
export const searchRecapFormSchema = (props = {}) => getSchema(true, props);

export default getSchema();
