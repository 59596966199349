import InputFile from 'schema-form/elements/input-file';
import InputToken from 'schema-form/elements/input-token';
import { t1 } from 'translate';
import themeSchema from './themeSchema';

const schema = (formid, values, step, xpath) => {
  return {
    domains: {
      type: InputToken,
      hintText: `${t1('ex')}: example.com`,
      floatingLabelText: t1('your_own_domain'),
      fullWidth: true,
    },
    logo: {
      type: InputFile,
      fileType: 'image',
      preview: 1,
      maxSize: '15px',
      hintText: t1('organization_logo'),
      newHintLabelText: t1('edit_organization_logo_manually'),
      fullWidth: true,
      classWrapper: 'col-md-6',
    },
    login_background_image_url: {
      type: InputFile,
      fileType: 'image',
      preview: 1,
      maxSize: '15px',
      hintText: t1('login_background_image_url'),
      newHintLabelText: t1('edit_login_background_image_url_manually'),
      fullWidth: true,
      classWrapper: 'col-md-6',
    },
    banner_image_url: {
      type: InputFile,
      fileType: 'image',
      preview: 1,
      maxSize: '15px',
      hintText: t1('banner_image_url'),
      newHintLabelText: t1('edit_banner_image_url_manually'),
      fullWidth: true,
      classWrapper: 'col-md-6',
    },
    copyright: {
      type: 'text',
      hintText: t1('copyright'),
      floatingLabelText: t1('copyright'),
      defaultValue: '',
      fullWidth: true,
      classWrapper: 'col-md-6',
    },
    course_copyright_text: {
      type: 'text',
      hintText: t1('course_copyright_text'),
      floatingLabelText: t1('course_copyright_text'),
      defaultValue: '',
      fullWidth: true,
      classWrapper: 'col-md-6',
    },
    hotline: {
      type: 'text',
      hintText: t1('organization_hotline'),
      floatingLabelText: t1('organization_hotline'),
      defaultValue: '',
      fullWidth: true,
      classWrapper: 'col-md-6',
    },
    branch_hotline: {
      type: 'text',
      hintText: t1('organization_branch_hotline'),
      floatingLabelText: t1('organization_branch_hotline'),
      defaultValue: '',
      fullWidth: true,
      classWrapper: 'col-md-6',
    },
    system_name: {
      type: 'text',
      hintText: t1('organization_system_name'),
      floatingLabelText: t1('organization_system_name'),
      defaultValue: '',
      fullWidth: true,
      classWrapper: 'col-md-6',
    },
    theme: {
      type: 'section',
      schema: themeSchema,
    },
  };
};

const ui = () => {
  return [
    {
      id: 'default',
      wrapperClass: 'border m-15',
      fields: [
        'domains',
        'system_name',
        'hotline',
        'branch_hotline',
        'copyright',
        'course_copyright_text',
        'logo',
        'login_background_image_url',
        'banner_image_url',
        'theme',
      ],
    },
  ];
};
export default {
  schema,
  ui,
};
