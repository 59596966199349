import React from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import Results from './Results';
import schema from './search-schema';
import withUserInfo from 'common/hoc/withUserInfo';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import endpoints from '../endpoints';

const Layout = ({ userInfo, submitButton, ...propsSearchForm }) => {
  const searchFormId = 'module_search';
  const renderResultComponent = (items, props) => {
    return (
      <Results
        items={items}
        {...props}
        userInfo={userInfo}
        searchFormId={searchFormId}
      />
    );
  };

  const renderNoResultComponent = () => {
    return <SimpleNoResult text="Chưa có mô đun" />;
  };

  return (
    <SearchWrapper
      {...propsSearchForm}
      ntype="module"
      formid={searchFormId}
      renderResultsComponent={renderResultComponent}
      renderNoResultComponent={renderNoResultComponent}
      alternativeApi={endpoints.search_module}
      schema={schema}
      userInfo={userInfo}
      submitButton={submitButton}
      autoSearchWhenStart
    />
  );
};

export default withUserInfo(Layout);
