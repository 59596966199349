import { t1 } from 'translate';
import { attachment } from 'components/common/forms/schema-fields';
import { ImageAttachmentRender } from 'schema-form/elements/attachments';
import { required } from 'common/validators';
import { splitStringToArrayBySeparators } from 'common/utils/string';
import { isSimpleInsertMode } from '../insert-mode';
import RTE from 'schema-form/elements/richtext';

export const listVariants = {
  LIST_ORDERED_TEXT: 'list_ordered_text',
  LIST_UNORDERED_TEXT: 'list_unordered_text',
  LIST_DEFINITION: 'list_definition',
  LIST_DEFINITION_IN_TABLE: 'list_definition_in_table',
  LIST_ORDERED_IMAGE_TEXT: 'list_ordered_image_text',
};

export const listOptions = (insertMode) => [
  {
    value: listVariants.LIST_ORDERED_TEXT,
    label: t1('list_ordered_text'),
  },
  {
    value: listVariants.LIST_UNORDERED_TEXT,
    label: t1('list_unordered_text'),
  },
  ...(!isSimpleInsertMode(insertMode)
    ? [
        {
          value: listVariants.LIST_DEFINITION,
          label: t1('list_definition'),
        },
        {
          value: listVariants.LIST_DEFINITION_IN_TABLE,
          label: t1('list_definition_in_table'),
        },

        {
          value: listVariants.LIST_ORDERED_IMAGE_TEXT,
          label: t1('list_ordered_image_text'),
        },
      ]
    : []),
];

export const parseStringToArrayContent = (value) => {
  const listValues = splitStringToArrayBySeparators(value);

  return listValues.map((value) => ({
    name: value,
  }));
};

export const parseArrayContentToString = (values) => {
  if (!Array.isArray(values) || !values.length) {
    return values;
  }

  return values
    .map((obj) => obj.name)
    .filter(Boolean)
    .join('\n');
};

export const listElementSchema = (type, insertMode) => {
  if (isSimpleInsertMode(insertMode)) {
    return {
      type: 'text',
      hintText: t1('add_list_items'),
      floatingLabelText: t1('add_list_items'),
      multiLine: true,
      rows: 4,
      validate: [required()],
    };
  }

  return {
    type: 'array',
    floatingLabelText: t1('add_list_items'),
    schema: {
      schema: () => ({
        name: {
          type: 'text',
          fullWidth: true,
          floatingLabelText: t1('name_of_list_item'),
        },
        content: {
          type: RTE,
          fullWidth: true,
          floatingLabelText: t1('explanation_of_list_item'),
        },
        avatar: attachment(false, 'image', ImageAttachmentRender),
      }),
      ui: () => [
        {
          id: 'defaultsss',
          fields: ['name', 'content'],
          title: t1('list_of_bullet_points'),
        },
      ],
    },
  };
};
