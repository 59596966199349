import React, { useRef } from 'react';

import { createDndContext, DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { isSmallScreen } from 'common';

const DNDContext = createDndContext(
  isSmallScreen ? TouchBackend : HTML5Backend,
);

const useDNDProviderElement = (props) => {
  const manager = useRef(DNDContext);

  if (!props.children) return null;

  return (
    <DndProvider manager={manager.current.dragDropManager}>
      {props.children}
    </DndProvider>
  );
};

const DragAndDrop = (props) => {
  const DNDElement = useDNDProviderElement(props);

  return <React.Fragment>{DNDElement}</React.Fragment>;
};

export default DragAndDrop;
