export const examResult = (
  contestCode,
  courseIid,
  examIid,
  examOrder,
  userIid,
) =>
  `/exam/result/${contestCode}/${courseIid}/${examIid}/${examOrder}${
    userIid ? '/' + userIid : ''
  }`;

export const contestLink = (contestCode, contestantIid) =>
  `/exam/take/${contestCode}/${contestantIid}`;

export const contestLinkPublic = (contestCode, examSubjectIid) =>
  `/exam/take/${contestCode}/public/${examSubjectIid}`;

export const contestPracticeLink = (contestCode, contestantIid) =>
  `/exam/practice/${contestCode}/${contestantIid}`;

export const examTemplatePracticeLinkForSyllabus = (syllabusIid, exerciseIid) =>
  `/bank-review/${syllabusIid}/${exerciseIid}`;
