import XText from './input/Text';
import XAttachments from './Attachments';
import XCascade from './Cascade';
import XCheckbox from './Checkbox';
import XInputToken from './InputToken';
import XNumber from './input/Number';
import XRadio from './Radio';
import XRte from './Rte';
import XSection from './Section';
import XSelect from './Select';
import XToggle from './Toggle';
import XMultiCheckbox from './MultiCheckbox';
import XDatePicker from './DatePicker';
import XDuration from './Duration';
import XInputTokenAutoComplete from './InputTokenAutoComplete';
import XInlineEditable from './InlineEditable';
import XInputAutoComplete from './InputAutoComplete';

export const text = XText;
export const inlineEditable = XInlineEditable;
export const attachments = XAttachments;
export const cascade = XCascade;
export const checkbox = XCheckbox;
export const inputToken = XInputToken;
export const number = XNumber;
export const radio = XRadio;
export const rte = XRte;
export const section = XSection;
export const select = XSelect;
export const datePicker = XDatePicker;
export const toggle = XToggle;
export const multiCheckbox = XMultiCheckbox;
export const duration = XDuration;
export const inputTokenAutoComplete = XInputTokenAutoComplete;
export const inputAutoComplete = XInputAutoComplete;
