import React, { useCallback, useState } from 'react';
import AntIcon from 'antd/lib/icon';
import './stylesheet.scss';

const CopyClipboard = ({ value, label, showLabel = true }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = useCallback(
    () => {
      if (copied) {
        return;
      }

      const textField = document.createElement('textarea');
      textField.innerText = value;
      document.body.appendChild(textField);
      textField.select();

      document.execCommand('copy');
      textField.remove();

      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 2000);
    },
    [value, copied],
  );

  return (
    <div className="copy-clipboard">
      <>
        <div
          className={`copy-clipboard__copy m-t-5 ${
            copied ? '' : 'cursor-pointer'
          }`}
          onClick={copyToClipboard}
        >
          {showLabel && (
            <span className="copy-clipboard__value">{label || value}</span>
          )}
          <AntIcon
            type={`${copied ? 'check' : 'copy'}`}
            className={`copy-clipboard__copy-icon ${copied ? 'copied' : ''}`}
            title={`${copied ? `Copied ${value}` : `Copy ${value}`}`}
          />
        </div>
      </>
    </div>
  );
};

export default CopyClipboard;
