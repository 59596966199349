import React from 'react';
import { t1 } from 'translate';
import trim from 'lodash.trim';
import { isOEQuestion } from 'common/learn/Question';
import Alert from 'antd/lib/alert';
import DisplayHtml from '../../html';

class QuestionFeedback extends React.Component {
  renderFeedbackForOE = () => {
    const { question } = this.props;

    return (
      <Alert
        type="info"
        message={t1('feedback_result_suggestion')}
        description={<DisplayHtml content={question.feedback_true} />}
        showIcon
      />
    );
  };

  renderFeedback = () => {
    const { isCorrect, question } = this.props;

    return (
      <div className="question-feedback m-t-15">
        <div className="m-b-5 question-feedback__title">{t1('feedback')}:</div>
        {isCorrect === true ? (
          <div className="d-flex align-items-center">
            <span className="ve ve-check text-primary m-r-10" />
            <DisplayHtml content={question.feedback_true} />
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <span className="ve ve-close-outline text-danger font-size-small m-r-10" />
            <DisplayHtml content={question.feedback_false} />
          </div>
        )}
      </div>
    );
  };

  render() {
    const { question } = this.props;
    const showFeedback =
      trim(question.feedback_true) !== '' ||
      trim(question.feedback_false) !== '';

    if (!showFeedback) {
      return null;
    }

    if (isOEQuestion(question)) {
      return this.renderFeedbackForOE();
    }

    return this.renderFeedback();
  }
}

export default QuestionFeedback;
