import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t1 } from 'translate';
import { reduxForm } from 'redux-form';
import { loginSuccess } from 'actions/auth';
import {
  activeLoginTab,
  activeRegisterTab,
  closeLoginDialog,
} from 'actions/auth/auth-dialog';
import nodeActions from 'actions/node/creators';
import Request from 'common/network/http/Request';
import { layouts } from 'configs/constants';
import { getThemeConfig } from 'utils/selectors';
import { getRootUrl } from 'routes/links/common';
import sagaActions from 'actions/saga-creators';

import '../stylesheet.scss';
import EtecRegister from './etec/index';
import MobileRegister from './mobile/index';
import { getBaseAPIUrl } from 'common/utils/url';

/**
 * Created by Peter Hoang Nguyen
 * Email: vntopmas@gmail.com
 * Tel: 0966298666
 * created date 30/03/2017
 * */

class Register extends React.Component {
  checkboxLabelStyle = { color: '#9d9d9d' };
  checkboxIconStyle = { fill: '#9d9d9d' };
  checkboxStyle = { display: 'none', width: 'auto' };
  divStyle = { display: 'none' };

  constructor(props) {
    super(props);
    this.state = {};
    this.doRegister = this.doRegister.bind(this);
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(activeRegisterTab());
  }

  doRegister() {
    const { registerForm, dispatch, themeConfig } = this.props;
    // let data = Object.assign({},registerForm.values, {submit : 1});
    // Fetch.post("/user/register", data);
    // console.log(registerForm);

    if (
      registerForm.values &&
      registerForm.values.name &&
      registerForm.values.email &&
      registerForm.values.pass
    ) {
      const baseUrl = getBaseAPIUrl();

      const registerUrl = `${baseUrl}/user/register?submit=1&&name=${
        registerForm.values.name
      }&pass=${registerForm.values.pass}&mail=${registerForm.values.email}`;

      Request.get(registerUrl)
        .then((response) => {
          if (response.success) {
            dispatch(
              sagaActions.getInformationByDomain(window.location.hostname),
            );
            dispatch(closeLoginDialog());
            dispatch(loginSuccess(response.result));
          } else {
            dispatch(nodeActions.snackbar(true, response.message));
          }
        })
        .catch((response) => {
          dispatch(nodeActions.snackbar(true, response.message));
        });
    } else {
      dispatch(
        nodeActions.snackbar(true, t1("the_register_fields_can't_be_empty")),
      );
    }

    // Request.post("/user/register", registerForm.values);
    // Request.post("/user/login", loginForm.values)
  }

  render() {
    const { dispatch, themeConfig, rootUrl } = this.props;
    if (rootUrl === 'mobile') {
      return <MobileRegister doRegister={this.doRegister} />;
    }

    return (
      <EtecRegister
        doRegister={this.doRegister}
        goToLogin={() => {
          dispatch(activeLoginTab());
        }}
        themeConfig={themeConfig}
      />
    );
  }
}

const populateStateToProps = (state, props) => ({
  registerForm: state.form.register,
  themeConfig: getThemeConfig(state),
  rootUrl: getRootUrl(props),
});

Register.childContextTypes = {
  muiTheme: PropTypes.shape().isRequired,
};

const RegisterForm = reduxForm({
  form: 'register', // a unique identifier for this form
})(Register);

export default connect(populateStateToProps)(RegisterForm);
