import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import CommonAntdTable from 'components/common/antd/table';
import { userSchoolLevelToShortText } from 'configs/constants/user';

const formatDataSourceToRenderTable = (rows) => {
  if (!Array.isArray(rows) || !rows.length) {
    return [];
  }

  return rows.reduce((result, { data_report, children, ...row }) => {
    if (Array.isArray(data_report) && data_report.length) {
      const newChildren = [];

      data_report.forEach(({ cap, ...data } = {}) => {
        if (cap === 'all') {
          row.data_report = data;
        } else {
          newChildren.push(
            Object.assign(
              {},
              {
                iid: `${row.iid}_${cap}`,
                id: `${row.id}_${cap}`,
                name: userSchoolLevelToShortText(cap),
                data_report: data,
              },
            ),
          );
        }
      });

      if (newChildren.length) {
        row.children = newChildren;
      }
    }

    result.push(row);

    return result.concat(formatDataSourceToRenderTable(children));
  }, []);
};

const Results = ({ items, searchValues }) => {
  const targetUser =
    searchValues && searchValues.target_user === 'gv' ? 'GVPT' : 'CBQL';

  const columns = [
    {
      title: 'ĐƠn vị/Cấp',
      key: 'name',
      dataIndex: 'name',
      render: (name) => <span>{name}</span>,
    },
    {
      title: `Tổng số ${targetUser}`,
      render: (item) => {
        return get(item, 'data_report.total_members');
      },
    },
    {
      title: `Tổng số ${targetUser} đã tham gia`,
      render: (item) => {
        return get(item, 'data_report.total_members_learned');
      },
    },
    {
      title: `Tổng số ${targetUser} đã hoàn thành`,
      render: (item) => {
        return get(item, 'data_report.total_members_passed');
      },
    },
    {
      title: `Tổng số ${targetUser} hài lòng về chương trình`,
      render: (item) => {
        return get(item, 'data_report.total_members_happy');
      },
    },
  ];

  return (
    <CommonAntdTable
      dataSource={formatDataSourceToRenderTable(items)}
      columns={columns}
      rowKey="id"
      pagination={false}
      className="learn-default"
    />
  );
};

export default connect()(Results);
