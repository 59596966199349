import React from 'react';
import lodashGet from 'lodash.get';
import TakeDetail from 'components/common/open-ended-take/marking';
import fetchData from 'components/common/fetchData';
import apiUrls from 'api-endpoints';
import Loading from 'components/common/loading';

const Index = ({
  userIid,
  node,
  question,
  defaultCommentIdToFocus,
  peerMarking = false,
  courseIid = null,
  questionIid,
  takeId,
  searchFormId,
  viewOnly = false,
  split,
  loading,
  onSubmitSuccess,
}) => {
  if (loading === 'loading') {
    return <Loading circularLoadingIcon />;
  }

  if (!question) {
    return null;
  }

  return (
    <TakeDetail
      peerMarking={peerMarking}
      courseIid={courseIid}
      userIid={userIid}
      nodeIid={lodashGet(node, 'iid')}
      item={question}
      questionIid={questionIid}
      takeId={takeId}
      defaultCommentIdToFocus={defaultCommentIdToFocus}
      searchFormId={searchFormId}
      viewOnly={viewOnly}
      split={split}
      onSubmitSuccess={onSubmitSuccess}
    />
  );
};

const fetchQuestion = fetchData(({ questionIid }) => ({
  baseUrl: apiUrls.fetch_node,
  params: {
    ntype: 'question',
    iid: questionIid,
  },
  loadingStatusPropKey: 'loading',
  propKey: 'question',
}));

export default fetchQuestion(Index);
