import PropTypes from 'prop-types';
import React from 'react';
import { breadCrumb, stripHTML } from 'common/utils/string';
import Icon from 'antd/lib/icon';
import { t1 } from 'translate';

function ShowMoreLess({
  content,
  length,
  showIcon = true,
  showLessOnly = false,
}) {
  const [showFull, setShowFull] = React.useState(false);
  content = stripHTML(content);
  return (
    <>
      {showFull ? content : breadCrumb(content, length, false)}
      {content.length > length && (
        <>
          {' '}
          <a
            onClick={() => {
              setShowFull(!showFull);
            }}
            style={{
              borderBottom: '1px dashed',
              color: '#000',
            }}
          >
            {!showFull ? (
              ' ...'
            ) : showLessOnly ? (
              ''
            ) : showIcon ? (
              <Icon type="up" />
            ) : (
              t1('show_less')
            )}
          </a>
        </>
      )}
    </>
  );
}

export default ShowMoreLess;

ShowMoreLess.propTypes = {
  content: PropTypes.any.isRequired,
  length: PropTypes.number.isRequired,
};
