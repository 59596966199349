import React, { Component } from 'react';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import get from 'lodash.get';
import 'schema-form/layouts/flex.scss';

import FiltersFormSingleUser from './FiltersFormSingleUser';
import FiltersFormTargetGroup from './FiltersFormTargetGroup';
import getAvailableFilters, {
  editGroupFiltersFormId,
  filtersForSingleUser,
  filtersForTargetGroup,
} from '../utils';
import { isSearchingWithinGroup } from './utils';

class SearchFormLayoutFreestyle extends Component {
  render() {
    const {
      groups,
      message,
      formid,
      availableFilters,
      values,
      submitButton,
    } = this.props;

    let hasFiltersForSingleUser;
    let hasFiltersForTargetGroup;

    if (
      availableFilters &&
      availableFilters.length &&
      Array.isArray(availableFilters)
    ) {
      hasFiltersForSingleUser = availableFilters.some(
        (r) => filtersForSingleUser.indexOf(r) >= 0,
      );
      hasFiltersForTargetGroup = availableFilters.some(
        (r) => filtersForTargetGroup.indexOf(r) >= 0,
      );
    }

    // is search is used when we wanna filter live
    // !isSearching is when you wanna add filters for a group only.
    const isSearching = formid !== editGroupFiltersFormId;

    const TheFiltersFormSingleUser = FiltersFormSingleUser;

    const col2 = isSearching
      ? isSearchingWithinGroup(formid)
        ? 'col-md-3'
        : 'col-md-6'
      : 'col-md-12';

    const fieldNamesDefault = get(groups, 'default.fieldNames');
    const fieldsDefault = fieldNamesDefault && Object.keys(fieldNamesDefault);

    return (
      <div className="container-fluid elementGroup">
        <div className="row">
          <div className={isSearching ? 'col-md-3' : ''}>
            {groups.others.fieldNames.availableFilters}
          </div>

          <div className={col2}>
            {hasFiltersForSingleUser && (
              <TheFiltersFormSingleUser {...this.props} />
            )}
            {hasFiltersForTargetGroup && (
              <FiltersFormTargetGroup {...this.props} />
            )}
            {Array.isArray(fieldsDefault) &&
              fieldsDefault.length > 0 &&
              fieldsDefault.map((field) => {
                return fieldNamesDefault[field];
              })}
          </div>
        </div>

        {isSearching && (
          <div className="row">
            <div className="col-md-2 submit-btn-col">
              <div>
                {message && <h3>{message}</h3>}
                {submitButton}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { formid, xpath } = props;

  const values = getFormValues(formid)(state);
  const availableFilters = getAvailableFilters(values, xpath);

  return {
    values,
    availableFilters,
  };
};

export default connect(mapStateToProps)(SearchFormLayoutFreestyle);
