import './stylesheet.scss';
import React, { useMemo } from 'react';
import Helmet from 'components/common/helmet';
import withLayoutConfig from 'layouts/container/withLayoutConfig';
import BackButton from 'components/common/back-button';
import { isSmallScreen } from 'common/index.js';

const PageWrapper = ({
  children,
  title = '',
  subtitle,
  context,
  helmet,
  noSpacing,
  action,
  showIndicator = true,
  urlToBack,
}) => {
  const getHelmet = () => {
    return helmet || typeof title === 'object' ? helmet : title;
  };

  return (
    <div className={`page-wrapper ${noSpacing ? 'p-0 m-0' : ''}`}>
      <Helmet title={getHelmet()} />

      <div className="d-flex align-items-center justify-content-between m-b-15">
        <div className="d-flex d-flex-column justify-content-start page-wrapper__left">
          {!!urlToBack && (
            <div className={`${isSmallScreen ? 'm-r-5' : 'm-r-10'}`}>
              <BackButton
                urlToBack={urlToBack}
                size={isSmallScreen ? 'tiny' : 'small'}
              />
            </div>
          )}
          {!!title && (
            <>
              <h3
                className={`page-wrapper__title ${subtitle ? 'm-b-5' : 'm-0'}`}
              >
                {title}
              </h3>

              {subtitle && <i className="page-wrapper__subtitle">{subtitle}</i>}

              {showIndicator ? (
                <hr className="page-wrapper__indicator" />
              ) : null}
            </>
          )}
        </div>

        {!!action && <>{action}</>}
      </div>

      {children}
    </div>
  );
};

export default withLayoutConfig(PageWrapper);
