import withTemisConfig from 'common/hoc/withTemisConfig';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import { isAdminTruongOrHieuTruongOrPho } from 'components/admin/user/utils';
import CommonAntdTable from 'components/common/antd/table';
import { TCNN_TYPE } from 'components/temis/constant';
import { userGradeToText } from 'configs/constants/user';
import get from 'lodash.get';
import React from 'react';
import { divisionNumber, getNumberFormat } from '../../utils';
import './stylesheets.scss';
import { TABLE_OF_CONTENT } from 'components/learn/nav/navigator';
import { t1 } from 'translate';

const isTCNNHT = (tcnn) => tcnn === TCNN_TYPE.HT;

const getDataRenderByColumn = (
  total,
  detail,
  titleColumn,
  reportType,
  isHeader,
  skipFormatNumber,
) => {
  let text = get(detail, titleColumn) || 0;

  if (reportType === 'percentage' && total > 0 && text) {
    text = parseFloat(((text / total) * 100).toFixed(2));
  }

  return {
    children: total > 0 ? getNumberFormat(text, skipFormatNumber) : 0,
    props: {
      className: 'text-right',
      width: 80,
      colSpan: isHeader ? 0 : 1,
    },
  };
};

const getDataRenderForTotalColumn = (
  totalAssessed,
  totalGeneralNeeds,
  reportType,
  isHeader,
  skipFormatNumber,
) => {
  let text = totalGeneralNeeds;

  if (reportType === 'percentage' && text > 0 && totalGeneralNeeds) {
    text = divisionNumber(totalGeneralNeeds, totalAssessed, skipFormatNumber);
  }

  return {
    children: totalAssessed > 0 ? getNumberFormat(text, skipFormatNumber) : 0,
    props: {
      className: 'text-right',
      width: 80,
      colSpan: isHeader ? 0 : 1,
    },
  };
};

const getTitleFirstColumn = (userRoot, isPlanDevelopment) => {
  let title = '';
  if (isPlanDevelopment) {
    title = isAdminTruongOrHieuTruongOrPho(userRoot)
      ? 'Nội dung'
      : 'Cấp học/nội dung';
  } else {
    title = isAdminTruongOrHieuTruongOrPho(userRoot)
      ? 'Tên tiêu chí'
      : 'Cấp học/tên tiêu chí';
  }
  return title;
};

const getColumns = (
  userRoot,
  tcnn,
  isPlanDevelopment,
  skipFormatNumber,
  reportForNcbdtx,
) =>
  [
    {
      title: getTitleFirstColumn(userRoot, isPlanDevelopment),
      type: TYPE_OF_TABLE_DATA.TEXT,
      width: 360,
      render: (item) => {
        const { title, school_level, isHeader, name, content } = item;
        const colSpan = isHeader ? (reportForNcbdtx ? 5 : 12) : 1;
        return {
          children: (
            <span
              style={{
                fontWeight: !(name || content) ? 'bold' : '',
              }}
            >
              {title || name || userGradeToText(school_level, true) || content}
            </span>
          ),
          props: {
            colSpan,
            minWidth: reportForNcbdtx ? 'auto' : 380,
          },
        };
      },
    },
    ...(reportForNcbdtx
      ? [
          {
            title: 'Mô đun tương ứng',
            type: TYPE_OF_TABLE_DATA.TITLE,
            render: (item) => {
              const { iid, isHeader } = item;

              const modules = get(item, 'training_modules');

              return {
                children:
                  !Array.isArray(modules) || !modules.length ? null : (
                    <ul>
                      {modules.map((module) => (
                        <li>{get(module, 'name')}</li>
                      ))}
                    </ul>
                  ),
                props: {
                  className: 'text-left',
                  colSpan: isHeader ? 0 : 1,
                },
              };
            },
          },
        ]
      : []),
    {
      title: 'Số lượng và tỷ lệ %',
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: [
        {
          title: isPlanDevelopment
            ? isTCNNHT(tcnn)
              ? 'Tổng số CBQL đã tự đánh giá'
              : 'Tổng số GV đã tự đánh giá'
            : isTCNNHT(tcnn)
            ? 'Tổng số CBQL được đánh giá và xếp loại'
            : 'Tổng số GV được đánh giá và xếp loại',
          width: 160,
          type: TYPE_OF_TABLE_DATA.TITLE,
          render: ({ total_assessed, isHeader }) => {
            return {
              children: getNumberFormat(total_assessed, skipFormatNumber),
              props: {
                className: 'text-right',
                colSpan: isHeader ? 0 : 1,
              },
            };
          },
        },
        {
          title: 'Nhu cầu chung',
          type: TYPE_OF_TABLE_DATA.TITLE,
          children: [
            {
              title: `SL`,
              type: TYPE_OF_TABLE_DATA.TITLE,
              render: ({ total_assessed, total_general_needs, isHeader }) =>
                getDataRenderForTotalColumn(
                  total_assessed,
                  total_general_needs,
                  '',
                  isHeader,
                  skipFormatNumber,
                ),
            },
            {
              title: `%`,
              type: TYPE_OF_TABLE_DATA.TITLE,
              render: ({ total_assessed, total_general_needs, isHeader }) =>
                getDataRenderForTotalColumn(
                  total_assessed,
                  total_general_needs,
                  'percentage',
                  isHeader,
                  skipFormatNumber,
                ),
            },
          ],
        },
        ...(reportForNcbdtx
          ? []
          : [
              {
                title: 'Nữ',
                type: TYPE_OF_TABLE_DATA.TITLE,
                children: [
                  {
                    title: `SL`,
                    type: TYPE_OF_TABLE_DATA.TITLE,
                    render: ({ total_general_needs, detail, isHeader }) =>
                      getDataRenderByColumn(
                        total_general_needs,
                        detail,
                        'female',
                        '',
                        isHeader,
                        skipFormatNumber,
                      ),
                  },
                  {
                    title: `%`,
                    type: TYPE_OF_TABLE_DATA.TITLE,
                    render: ({ total_general_needs, detail, isHeader }) =>
                      getDataRenderByColumn(
                        total_general_needs,
                        detail,
                        'female',
                        'percentage',
                        isHeader,
                        skipFormatNumber,
                      ),
                  },
                ],
              },
              {
                title: t1('ethnic_minority_short_text'),
                type: TYPE_OF_TABLE_DATA.TITLE,
                children: [
                  {
                    title: `SL`,
                    type: TYPE_OF_TABLE_DATA.TITLE,
                    render: ({ total_general_needs, detail, isHeader }) =>
                      getDataRenderByColumn(
                        total_general_needs,
                        detail,
                        'dtts',
                        '',
                        isHeader,
                        skipFormatNumber,
                      ),
                  },
                  {
                    title: `%`,
                    type: TYPE_OF_TABLE_DATA.TITLE,
                    render: ({ total_general_needs, detail, isHeader }) =>
                      getDataRenderByColumn(
                        total_general_needs,
                        detail,
                        'dtts',
                        'percentage',
                        isHeader,
                        skipFormatNumber,
                      ),
                  },
                ],
              },
              {
                title: 'Nữ DTTS',
                type: TYPE_OF_TABLE_DATA.TITLE,
                children: [
                  {
                    title: `SL`,
                    type: TYPE_OF_TABLE_DATA.TITLE,
                    render: ({ total_general_needs, detail, isHeader }) =>
                      getDataRenderByColumn(
                        total_general_needs,
                        detail,
                        'female_dtts',
                        '',
                        isHeader,
                        skipFormatNumber,
                      ),
                  },
                  {
                    title: `%`,
                    type: TYPE_OF_TABLE_DATA.TITLE,
                    render: ({ total_general_needs, detail, isHeader }) =>
                      getDataRenderByColumn(
                        total_general_needs,
                        detail,
                        'female_dtts',
                        'percentage',
                        isHeader,
                        skipFormatNumber,
                      ),
                  },
                ],
              },
              {
                title: 'Vùng khó khăn',
                type: TYPE_OF_TABLE_DATA.TITLE,
                children: [
                  {
                    title: `SL`,
                    type: TYPE_OF_TABLE_DATA.TITLE,
                    render: ({ total_general_needs, detail, isHeader }) =>
                      getDataRenderByColumn(
                        total_general_needs,
                        detail,
                        'poor_area',
                        '',
                        isHeader,
                        skipFormatNumber,
                      ),
                  },
                  {
                    title: `%`,
                    type: TYPE_OF_TABLE_DATA.TITLE,
                    render: ({ total_general_needs, detail, isHeader }) =>
                      getDataRenderByColumn(
                        total_general_needs,
                        detail,
                        'poor_area',
                        'percentage',
                        isHeader,
                        skipFormatNumber,
                      ),
                  },
                ],
              },
            ]),
      ],
    },
  ].filter(Boolean);

const formatDataToRenderWithSchoolLevel = (data = []) => {
  if (
    Array.isArray(data) &&
    data.length == 1 &&
    data[0].school_level &&
    data[0].children
  ) {
    return data[0].children;
  }
  return data;
};

const getPropsToRenderTable = (
  dataTable,
  userRoot,
  tcnn,
  isPlanDevelopment,
  isResultForCBQL,
) => {
  if (!Array.isArray(dataTable) || !dataTable.length) {
    return { dataSource: [], expandedRowKeys: [] };
  }

  let dataSource = [];
  const expandedRowKeys = [];

  let indexRowKey = 0;

  dataSource = formatDataToRenderWithSchoolLevel(
    dataTable
      .map((data, idx) => {
        const id = `${data.school_level}_${++indexRowKey}`;
        expandedRowKeys.push(id);
        return {
          ...data,
          isHeader: true,
          ...(isPlanDevelopment
            ? {
                children: formatDataToRenderWithSchoolLevel(
                  (data.children || []).map((childrenData) => {
                    childrenData.id = `${childrenData.iid}_${++indexRowKey}`;
                    expandedRowKeys.push(childrenData.id);
                    return {
                      ...childrenData,
                      isHeader: true,
                      children: (childrenData.children || []).map(
                        ({ name, ...item }) => {
                          return {
                            ...item,
                            content: name,
                          };
                        },
                      ),
                    };
                  }),
                ),
              }
            : {}),
          id: id,
        };
      })
      .filter(Boolean),
  );

  return {
    dataSource,
    expandedRowKeys,
  };
};
const TableResult = ({
  dataResult,
  userRoot,
  tcnn,
  isPlanDevelopment,
  isResultForCBQL,
  defaultExpandAllRows = false,
  isReportOfTruong,
  skipFormatNumber,
  reportForNcbdtx,
}) => {
  const { dataSource, expandedRowKeys } = getPropsToRenderTable(
    dataResult,
    userRoot,
    tcnn,
    isPlanDevelopment,
    isResultForCBQL,
  );
  return (
    <CommonAntdTable
      bordered
      rowKey="id"
      pagination={false}
      defaultExpandAllRows={defaultExpandAllRows}
      className={`white-background learn-default temis-report-ncbdtx ${
        isPlanDevelopment ? 'plan-develop' : ''
      } ${isReportOfTruong ? 'plan-develop-custom' : ''}`}
      columns={getColumns(
        userRoot,
        tcnn,
        isPlanDevelopment,
        skipFormatNumber,
        reportForNcbdtx,
      )}
      dataSource={Array.isArray(dataSource) ? dataSource : []}
      scroll={{ x: true }}
      defaultExpandedRowKeys={expandedRowKeys}
    />
  );
};

export default withTemisConfig(TableResult);
