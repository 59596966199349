import React from 'react';
import get from 'lodash.get';
import RTE from 'schema-form/elements/richtext';

import { t, t1 } from 'translate';
import { required } from 'common/validators';
import { convertBooleanValueToInt, slugifier } from 'common/normalizers';
import { generateSlug } from 'common/utils/form';
import {
  allRubricSubTypes,
  rubricSubTypes,
  rubricTypes,
} from 'components/admin/rubric/utils';
import {
  creditSyllabusHasTopEquivalentPositionCode,
  creditSyllabusLevels,
} from 'common/conf';
import { equivalentPositions } from 'components/admin/equivalent-job-position/schema/elements/index';
import { evnEquivalentPositions } from 'components/admin/top-equivalent-position/schema/elements';
import { academicCategories } from 'components/admin/academic-category/schema/elements';
import AspectsPercentElement from '../form/AspectsPercentElement';
// import Penalty from '../form/Penalty';
import Expiry from '../form/Expiry';
import { generateLevelOptions } from '../utils';
import { effortSourcesMultiCheckboxOptions as effortSources } from '../form/configs';
import { commonFormLayouts } from 'schema-form/constants';
import InputToken from 'schema-form/elements/input-token';
import { surveyTypes } from '../configs';
import { applicableScopes } from 'components/admin/rubric/schema/elements';
import { surveyStatuses } from 'configs/constants/survey';
import sApiUrls from '../../survey/endpoints';
import { taphuanCategoryElement } from '../../training-plan/schema/element/taphuan-category';
import { academicAndSkillByCap } from 'components/admin/academic-category/schema/elements/academic-and-skill-by-cap';
import { targetUser } from 'components/common/elements';
import { isBDTX } from 'utils/Util';
import store from 'store';
import { change } from 'redux-form';
import { compareMethod } from 'configs/constants/comparer';

const rubricSubTypeSupportPassScoreCompareMethod = (subType) => {
  return [allRubricSubTypes.RUBRIC_FORMULA_EXERCISE_AVERAGE].includes(subType);
};

const conditionsOfParticipation = [
  {
    value: 'default',
    label: t1('default'),
    primaryKey: t1('default'),
  },
];

const formulaParamsLabel = (values) => {
  if (get(values, 'survey_type') == surveyTypes.TCNN)
    return 'Đợt đánh giá TEMIS';
  if (
    get(values, 'sub_type') == allRubricSubTypes.RUBRIC_FORMULA_EXERCISE_AVERAGE
  ) {
    return t1(
      'enter_multiple_exercise_nth_order_in_the_syllabus_separated_by_comma',
    );
  }
  // if (get(values, 'survey_type') == allRubricSubTypes.RUBRIC_FORMULA_A) {
  //   return t1('enter_exercise_nth_order_in_the_syllabus');
  // }

  return t1('param_for_rubric_formula_(optional)');
};

const getFormulaOptions = (applicableScope, props) => {
  if (applicableScope == 'course') {
    // configured in rubric_sub_types_enable
    const rubricSubTypeOptions = rubricSubTypes(
      get(props, 'rubricSubTypeEnables', []),
      get(props, 'parentNode'),
    );

    return rubricSubTypeOptions;
  } else if (
    applicableScope == 'enrolment_plan'
    // applicableScope == 'training_plan'
  ) {
    // TODO: put all those into constants
    return [
      {
        value: allRubricSubTypes.RUBRIC_FORMULA_WEIGHTED_AVERAGE,
        label: t1('weighted_average'),
        guide: t1('user_must_pass_all_child_rubrics'),
      },
      {
        value: allRubricSubTypes.RUBRIC_PASS_ALL_COURSES_ASSIGNED,
        label: t1('pass_all_courses_assigned'),
        guide: t1('user_must_pass_all_courses_assigned_to_learn'),
      },
      {
        value: allRubricSubTypes.RUBRIC_PASS_COMMON_SUBJECT,
        label: t1('pass_common_subject'),
        guide: 'Môn tổng thể',
      },
      {
        value: allRubricSubTypes.RUBRIC_PASS_AT_LEAST_ONE_SPECIALIZED_SUBJECT,
        label: t1('pass_at_least_one_specialized_subject'),
        guide: 'Môn chuyên ngành',
      },
      {
        value: allRubricSubTypes.RUBRIC_PASS_AT_LEAST_X_SUBJECTS,
        label: t1('pass_at_least_x_subjects'),
        guide: 'Phải đạt ít nhất X môn, hãy điền X ở trong phần pass_score',
      },
      // {
      //   value:
      //     allRubricSubTypes.RUBRIC_PASS_COMMON_AND_AT_LEAST_ONE_OTHER_SUBJECT,
      //   label: t1('pass_common_and_at_least_one_other_subject'),
      //   guide: 'Môn tổng thể + 1 môn chuyên ngành',
      // },
      {
        value: allRubricSubTypes.RUBRIC_FORMULA_TAKE_SURVEY,
        label: t1('take_survey'),
        guide: t1('user_must_take_some_survey_specified_below'),
      },
      {
        value:
          allRubricSubTypes.RUBRIC_FORMULA_FINISHED_SELECTION_MODULES_OF_BDTX,
        label: t1('selection_modules_of_bdtx'),
        guide: t1('user_must_selection_modules_of_bdtx'),
      },
      {
        value:
          allRubricSubTypes.RUBRIC_FORMULA_PROVIDE_FULL_PERSONAL_INFORMATION,
        label: t1('provide_full_personal_information'),
        guide: t1('user_only_pass_if_they_provide_full_personal_information'),
      },
      // {
      //   value: allRubricSubTypes.RUBRIC_PASS_ALL_ENROLMENT_PLANS_IN_TP,
      //   label: t1('pass_all_enrolment_plans_in_tp'),
      //   guide: t1('pass_all_enrolment_plans_in_tp'),
      // },

      // {
      //   value: 'manual_input',
      //   label: t1('manual_input'),
      // },
      {
        value: allRubricSubTypes.RUBRIC_PASS_PREVIOUS_MODULE,
        label: t1('pass_previous_module'),
        guide: t1('user_must_pass_previous_module'),
      },
      {
        value: allRubricSubTypes.RUBRIC_PASS_CONTEST,
        label: t1('pass_contest'),
        guide: t1('using_user_score_in_contest'),
      },
      {
        value: allRubricSubTypes.RUBRIC_PASS_COURSE_RUBRIC,
        label: t1('pass_course_rubric'),
        guide: t1('user_must_pass_some_specific_course_rubric_items'),
      },
    ];
  } else if (applicableScope == 'training_plan') {
    return [
      {
        value: allRubricSubTypes.RUBRIC_FORMULA_WEIGHTED_AVERAGE,
        label: t1('weighted_average'),
        guide: t1('user_must_pass_all_child_rubrics'),
      },
      {
        value: allRubricSubTypes.RUBRIC_FORMULA_TAKE_SURVEY,
        label: t1('take_survey'),
        guide: t1('user_must_take_some_survey_specified_below'),
      },
      {
        value:
          allRubricSubTypes.RUBRIC_FORMULA_PROVIDE_FULL_PERSONAL_INFORMATION,
        label: t1('provide_full_personal_information'),
        guide: t1('user_only_pass_if_they_provide_full_personal_information'),
      },
      {
        value: allRubricSubTypes.RUBRIC_PASS_ALL_ENROLMENT_PLANS_IN_TP,
        label: t1('pass_all_enrolment_plans_in_tp'),
        guide: t1('pass_all_enrolment_plans_in_tp'),
      },
      {
        value: allRubricSubTypes.RUBRIC_PASS_ENROLMENT_PLAN_RUBRIC,
        label: t1('pass_enrolment_plan_rubric'),
        guide: t1('pass_enrolment_plan_rubric'),
      },
    ];
  }
};

const schema = (formid, values, step, xpath, props, domainInfo) => {
  const identifier = values.type === 'rubric' ? 'rubric' : 'skill';
  const rubricTypesOptions = rubricTypes(
    get(props, 'rubricTypeEnables', ['normal_rubric']),
  );
  const targetUsers = get(values, 'target_users', []);

  // console.log({ rubricSubTypeOptions });
  return {
    name: {
      type: 'text',
      hintText: t1('name'),
      floatingLabelText: t1('name'),
      validate: [required(t1('cannot_be_empty'))],
      onChange: (event, value) => {
        if (
          /* step === 'new' || step === 'new_skill' || */ step ===
          'new_new_learning_item_rubric'
        ) {
          generateSlug(formid, value, 'code', values.code_prefix || '');
        }
      },
    },
    short_name: {
      type: 'text',
      floatingLabelText: t1('short_name'),
    },
    code: {
      type: 'text',
      hintText: t1('unique_code_for_%s', [t(identifier)]),
      floatingLabelText: t1('code'),
      validate: [required(t1('cannot_be_empty'))],
      prefix: values.code_prefix || '',
      normalize: slugifier,
    },
    description: {
      type: RTE,
      // selectorId: 'description_rte',
      hintText: t1('description_of_how_rubric_is_calculated_and_how_to_pass'),
      floatingLabelText: t1(
        'description_of_how_rubric_is_calculated_and_how_to_pass',
      ),
      multiLine: true,
      guide: t1('you_should_describe_as_detailed_as_possible'),
    },
    from: {
      type: 'select',
      floatingLabelText: t1('from_skill'),
      options: 'async',
      validate: [required(t1('cannot_be_empty'))],
    },
    to: {
      type: 'select',
      floatingLabelText: t1('to_skill'),
      options: 'async',
      validate: [required(t1('cannot_be_empty'))],
    },
    estimated_effort: {
      type: 'number',
      floatingLabelText: t1('amount_of_effort'),
    },
    estimated_effort_type: {
      type: 'select',
      floatingLabelText: t1('estimated_effort_type'),
      options:
        'async' /* TODO: Cơ chế validate để field A phụ thuộc vào field B. VD: Nếu B có gtri thì A là required */,
    },
    status: {
      type: 'select',
      floatingLabelText: t1('estimated_effort_type'),
      options: 'async',
    },
    tags: {
      type: InputToken,
      floatingLabelText: t1('tags'),
      fullWidth: true,
    },
    scale: {
      type: 'select',
      floatingLabelText: t1('scale'),
      options: Array.isArray(values.skillScaleOptions)
        ? values.skillScaleOptions
        : [],
    },
    detailed_description: {
      type: RTE,
      floatingLabelText: t1('details'),
    },
    rubric_type: {
      type: 'select',
      floatingLabelText: t1('type'),
      floatingLabelFixed: true,
      classWrapper:
        !Array.isArray(rubricTypesOptions) || rubricTypesOptions.length === 1
          ? 'display-none'
          : 'null',
      options: rubricTypesOptions,
      fullWidth: true,
      defaultValue:
        rubricTypesOptions.length > 0 ? rubricTypesOptions[0].value : null,
    },
    applicable_scope: {
      type: 'radio',
      floatingLabelText: t1('type'),
      options: applicableScopes,
    },
    is_root_rubric: {
      type: 'checkbox',
      label: t1('is_root_rubric'),
      normalize: convertBooleanValueToInt,
    },
    sub_type: {
      type: 'radio',
      floatingLabelText: `${t1(
        'rubric_formula_(how_should_score_be_calculated',
      )} (sub_type) ?`,
      floatingLabelFixed: true,
      options: getFormulaOptions(values.applicable_scope, props),
      fullWidth: true,
      onChange: (event, value) => {
        if (!rubricSubTypeSupportPassScoreCompareMethod(value)) {
          store.dispatch(change(formid, 'pass_score_compare_method', null));
        }
      },
    },
    // formula_help: {
    //   type: 'help',
    //   component: <FormulateHelp type={values.sub_type} />,
    //   // classWrapper: 'col-md-6',
    // },
    survey_type: {
      type: 'select',
      floatingLabelText: t1('choose_survey'),
      options: [
        {
          value: surveyTypes.TRAINING_PLAN,
          label: t1('surveys_of_training_plan'),
        },
        {
          value: surveyTypes.ENROLMENT_PLAN,
          label: t1('surveys_of_enrolment_plan'),
        },
        {
          value: surveyTypes.GLOBAL_SURVEY,
          label: t1('global_survey'),
        },
        {
          value: surveyTypes.COURSE_SURVEY,
          label: t1('course_survey'),
        },
        {
          value: surveyTypes.SPECIFIC_SURVEY_IID,
          label: t1('choose_specific_surveys'),
        },
        ...(window.isETEP
          ? [
              {
                value: surveyTypes.BDTX,
                label: 'BDTX',
              },
              {
                value: surveyTypes.TCNN,
                label: 'TCNN',
              },
            ]
          : []),
      ],
    },
    survey_iids: {
      type: 'select',
      floatingLabelText: t1('choose_specific_surveys'),
      options: 'async',
      multiple: true,
      paramsasync: {
        value: {
          items_per_page: -1,
          status: [surveyStatuses.APPROVED],
        },
        __url__: sApiUrls.survey_search,
        transformData: (result) =>
          Array.isArray(result)
            ? result.map((map) => ({
                value: map.iid,
                primaryText: map.name,
              }))
            : [],
      },
      fullWidth: true,
    },
    only_use_exercises_marked_as_included_in_final_score: {
      type: 'checkbox',
      label: t1('only_use_exercises_marked_as_included_in_final_score'),
      defaultValue: 0,
      normalize: convertBooleanValueToInt,
      classWrapper: 'm-t-10',
    },
    course_rubric_iids: {
      type: 'text',
      validate: [required()],
      floatingLabelText: t1('enter_course_rubric_iids_separated_by_comma'),
    },
    course_rubric_pass_all: {
      type: 'checkbox',
      fullWidth: true,
      label: t1('user_need_to_pass_these_rubric_iids_in_all_courses'),
      defaultValue: 0,
      normalize: convertBooleanValueToInt,
    },
    contest_iid: {
      floatingLabelText: t1('enter_contest_iid'),
      type: 'text',
      validate: [required()],
    },
    formula_params: {
      type: 'text',
      floatingLabelText: formulaParamsLabel(values),
      guide: t1('only_input_if_you_really_know_what_you_are_doing'),
      // defaultValue.
    },
    conditions_of_participation: {
      type: 'select',
      floatingLabelText: t1('conditions_of_participation'),
      floatingLabelFixed: true,
      fullWidth: true,
      options: conditionsOfParticipation,
      defaultValue: conditionsOfParticipation[0].value,
    },
    // pass_point: {
    //   type: 'select',
    //   floatingLabelText: t1('pass_point'),
    //   floatingLabelFixed: true,
    //   fullWidth: true,
    //   options: conditionsOfParticipation,
    //   defaultValue: conditionsOfParticipation[0].value,
    // },
    pass_score: {
      type: 'number',
      step: 1,
      min: 0,
      max: 100,
      floatingLabelText: t1('rubric_passing_score'),
      floatingLabelFixed: true,
      fullWidth: true,
      hintText: t1('normally_out_of_100'),
      guide: t1('score_is_out_of_100_for_normal_rubric'),
    },
    exercise_index: {
      type: 'number',
      step: 1,
      min: 1,
      max: 10000,
      floatingLabelText: t1('exercise_index'),
      floatingLabelFixed: true,
      fullWidth: true,
      hintText: t1('one_based_exercise_index_hint'),
      guide: t1('one_based_exercise_index_guide'),
    },
    pass_score_compare_method: {
      type: 'select',
      floatingLabelText: t1('pass_score_compare_method'),
      fullWidth: true,
      options: [
        {
          value: compareMethod.COMPARE_METHOD_GT,
          label: `${t('greater_than')} (>)`,
        },
        {
          value: compareMethod.COMPARE_METHOD_GTE,
          label: `${t('greater_than_or_equal_to')} (>=)`,
        },
      ],
      validate: (value, values) => {
        if (rubricSubTypeSupportPassScoreCompareMethod(values.sub_type)) {
          return required()(value);
        }
      },
      disabled: !rubricSubTypeSupportPassScoreCompareMethod(values.sub_type),
    },
    must_pass_for_parent_to_pass: {
      type: 'checkbox',
      fullWidth: true,
      label: t1('must_pass_for_parent_to_pass'),
      // defaultValue: 1,
      normalize: convertBooleanValueToInt, // parseInt(value),
    },
    is_happy_survey_rubric: {
      type: 'checkbox',
      fullWidth: true,
      label: t1('is_happy_survey_rubric'),
      // defaultValue: 1,
      normalize: convertBooleanValueToInt, // parseInt(value),
    },
    allow_passing_by_teacher: {
      type: 'checkbox',
      fullWidth: true,
      label: t1('allow_passing_by_teacher'),
      // defaultValue: 1,
      normalize: convertBooleanValueToInt, // parseInt(value),
      guide: t1('when_teacher_can_manually_make_a_student_pass_this_criteria'),
    },
    teacher_marking_pass_fail: {
      type: 'checkbox',
      fullWidth: true,
      label: t1('teacher_marking_pass_fail'),
      normalize: convertBooleanValueToInt,
    },
    manual_input_rubric_default_pass: {
      type: 'checkbox',
      fullWidth: true,
      label: t1('manual_input_rubric_default_pass'),
      normalize: convertBooleanValueToInt,
    },
    only_show_marking_passed: {
      type: 'checkbox',
      fullWidth: true,
      label: t1('only_show_marking_passed'),
      // defaultValue: 1,
      normalize: convertBooleanValueToInt,
    },
    sub_tasks: {
      type: 'array',
      label: 'sub tasks in simple text',
      schema: {
        schema: () => ({
          name: {
            type: 'text',
            label: 'sub task',
          },
        }),
        ui: () => [
          {
            id: 'fields',
            fields: ['name'],
          },
        ],
      },
    },

    /* ************passing definition*********************** */
    // pd_test: {
    //   type: 'checkbox',
    //   label: t1('must_pass_output_test_(if_exists)'),
    //   defaultValue: 1,
    //   normalize: convertBooleanValueToInt, // parseInt(value),
    // },
    // pd_learn: {
    //   type: 'checkbox',
    //   label: t1('must_pass_all_required_learning_items'),
    //   defaultValue: 1,
    //   normalize: convertBooleanValueToInt, // parseInt(value),
    // },
    // applied
    // pd_applied_duration: {
    //   type: 'number',
    //   hintText: t1('minimum_period_that_skill_must_maintain'),
    //   floatingLabelText: t1('duration'),
    //   defaultValue: 1,
    // },
    // pd_applied_right: {
    //   type: 'number',
    //   hintText: t1('minimum_number_of_times_skill_must_be_applied_correctly'),
    //   floatingLabelText: t1('correct'),
    //   defaultValue: 1,
    //   // normalize: convertBooleanValueToInt, // parseInt(value),
    // },
    // pd_apply_wrong: {
    //   type: 'number',
    //   hintText: t1('maximum_number_of_times_skill_can_be_applied_wrong'),
    //   floatingLabelText: t1('wrong'),
    //   defaultValue: 1,
    //   // normalize: convertBooleanValueToInt, // parseInt(value),
    // },
    // pd_apply_wrong_penalty: {
    //   type: 'number',
    //   hintText: t1('%_of_score_decreased_for_each_type_skill_is_applied_wrong'),
    //   floatingLabelText: t1('penalty'),
    //   defaultValue: 10,
    //   // normalize: convertBooleanValueToInt, // parseInt(value),
    // },
    // // applied
    // pd_practiced_duration: {
    //   type: 'number',
    //   hintText: t1('minimum_period_that_skill_must_maintain'),
    //   floatingLabelText: t1('duration'),
    //   defaultValue: 1,
    // },
    // pd_practiced_right: {
    //   type: 'number',
    //   hintText: t1('minimum_number_of_times_skill_must_be_practiced_correctly'),
    //   floatingLabelText: t1('correct'),
    //   defaultValue: 1,
    //   // normalize: convertBooleanValueToInt, // parseInt(value),
    // },
    // pd_practice_wrong: {
    //   type: 'number',
    //   hintText: t1('maximum_number_of_times_skill_can_be_applied_wrong'),
    //   floatingLabelText: t1('wrong'),
    //   defaultValue: 1,
    //   // normalize: convertBooleanValueToInt, // parseInt(value),
    // },
    // pd_practice_wrong_penalty: {
    //   type: 'number',
    //   hintText: t1('%_of_score_decreased_for_each_type_skill_is_practiced_wrong'),
    //   floatingLabelText: t1('penalty'),
    //   defaultValue: 10,
    //   // normalize: convertBooleanValueToInt, // parseInt(value),
    // },
    /* ************end passing definition*************** */
    aspects_percent: {
      type: AspectsPercentElement,
      hintText: t1(
        'the_following_skill_aspects_combination_is_considered_as_passed',
      ),
    },
    effort_sources: {
      type: 'multiCheckbox',
      options: effortSources,
      hintText: t1('effort_sources'),
    },
    // penalty_practice: {
    //   type: 'Penalty',
    //   hintText: t1('when_skill_is_already_passed_and_user_has_errors_when_practicing'),
    // },
    penalty_practice: {
      type: 'number',
      hintText: t1('max_number_of_mistakes'),
      floatingLabelText: t1('max_number_of_mistakes'),
    },
    // penalty_apply: {
    //   type: Penalty,
    //   hintText: t1('when_skill_is_already_passed_and_user_has_errors_when_applying'),
    // },
    expiry: {
      type: Expiry,
    },
    // passdef: {
    //   label: t1('passdef'),
    //   type: 'select',
    //   floatingLabelText: t1('passdef'),
    //   options: 'async',
    //   fullWidth: true,
    // },
    level: {
      type: 'select',
      options: generateLevelOptions(creditSyllabusLevels(domainInfo)),
      floatingLabelText: t1('skill_level'),
      floatingLabelFixed: true,
      fullWidth: true,
    },
    job_position_codes: {
      type: InputToken,
      hintText: t1('job_position_codes'),
      floatingLabelText: t1('job_position_codes'),
      defaultValue: [],
    },
    academic_categories: academicCategories(formid, {
      label: `${t1('academic_categories')}`,
      // validate: [required(t1('academic_categories_cannot_be_empty'))],
    }),
    equivalent_positions: equivalentPositions(formid, {}),
    top_equivalent_positions: evnEquivalentPositions(formid, {}),
    evidence_from_system_with_learn_module: taphuanCategoryElement({
      multiple: true,
      validate: [],
      floatingLabelText: t1('training_plan_category_mapping'),
    }),
    target_users: targetUser({
      floatingLabelText: 'Đối tượng',
      defaultValue: [],
    }),
    tieu_chi_by_cap: academicAndSkillByCap({
      targetUsers,
      floatingLabelText: 'Tiêu chí theo cấp và đối tượng',
      hideAcademicField: true,
    }),
    ep_rubric_pass_previous_module: {
      type: 'section',
      schema: {
        schema: {
          previous_tp_category: taphuanCategoryElement({
            modifyOptions: (options) => {
              return [
                {
                  value: '',
                  label: t1('auto'),
                },
              ].concat(options);
            },
            floatingLabelText: t1('previous_module'),
          }),
          smart_cot_can: {
            type: 'section',
            schema: {
              schema: {
                manually_added_users: {
                  type: 'section',
                  schema: {
                    schema: {
                      dependency_tp_category: taphuanCategoryElement({
                        modifyOptions: (options) => {
                          return [
                            {
                              value: '',
                              label: t1('previous_module'),
                            },
                          ].concat(options);
                        },
                        cotcan: true,
                        floatingLabelText: t1(
                          'for_smart_cot_can,_module_that_manually_added_tp_users_depend_on',
                        ),
                      }),
                    },
                    ui: () => [
                      { id: 'default', fields: ['dependency_tp_category'] },
                    ],
                  },
                },
              },
              ui: () => [{ id: 'default', fields: ['manually_added_users'] }],
            },
          },
        },
        ui: () => [
          {
            id: 'default',
            fields: ['previous_tp_category', 'smart_cot_can'],
          },
        ],
      },
    },
    tp_rubric_pass_ep_rubric: {
      type: 'section',
      schema: {
        schema: {
          rubric_iids_to_check: {
            type: InputToken,
            floatingLabelText: t1('possible_enrolment_plan_rubric_iids'),
          },
        },
        ui: () => [{ id: 'default', fields: ['rubric_iids_to_check'] }],
      },
    },
    is_on_dlow: {
      type: 'checkbox',
      fullWidth: true,
      label: t1('rubric_for_dlow'),
      normalize: convertBooleanValueToInt,
    },
    dlow_min_page: {
      type: 'number',
      step: 1,
      min: 0,
      max: 100,
      floatingLabelText: t1('dlow_min_page'),
      fullWidth: true,
    },
    dlow_min_question: {
      type: 'number',
      step: 1,
      min: 0,
      max: 100,
      floatingLabelText: t1('dlow_min_question'),
      fullWidth: true,
    },
    dlow_min_question_from_bank: {
      type: 'number',
      step: 1,
      min: 0,
      max: 100,
      floatingLabelText: t1('dlow_min_question_from_bank'),
      fullWidth: true,
    },
    dlow_min_question_level: {
      type: 'number',
      step: 1,
      min: 0,
      max: 100,
      floatingLabelText: t1('dlow_min_question_level'),
      fullWidth: true,
    },
    dlow_min_question_type: {
      type: 'number',
      step: 1,
      min: 0,
      max: 100,
      floatingLabelText: t1('dlow_min_question_type'),
      fullWidth: true,
    },
  };
};

const getRubricFields = (props, values) => {
  return [
    'name',
    'short_name',
    'code',
    'description',
    get(props, 'options.hasSelectRubricType') ? 'rubric_type' : null,
    'is_root_rubric',
    'applicable_scope',
    'sub_type',
    // 'formula_help',
    get(values, 'sub_type') === allRubricSubTypes.RUBRIC_FORMULA_TAKE_SURVEY
      ? 'survey_type'
      : null,
    get(values, 'survey_type') === surveyTypes.SPECIFIC_SURVEY_IID
      ? 'survey_iids'
      : null,
    get(values, 'sub_type') === allRubricSubTypes.RUBRIC_FORMULA_TAKE_SURVEY &&
      'is_happy_survey_rubric',
    get(values, 'sub_type') ===
    allRubricSubTypes.RUBRIC_FORMULA_EXERCISE_AVERAGE
      ? 'only_use_exercises_marked_as_included_in_final_score'
      : null,
    get(values, 'sub_type') === allRubricSubTypes.RUBRIC_PASS_CONTEST
      ? 'contest_iid'
      : null,
    get(values, 'sub_type') === allRubricSubTypes.RUBRIC_PASS_PREVIOUS_MODULE
      ? 'ep_rubric_pass_previous_module'
      : null,
    get(values, 'sub_type') ===
    allRubricSubTypes.RUBRIC_PASS_ENROLMENT_PLAN_RUBRIC
      ? 'tp_rubric_pass_ep_rubric'
      : null,
    'formula_params',
    get(values, 'sub_type') === allRubricSubTypes.RUBRIC_SUBMIT_EXERCISE
      ? 'exercise_index'
      : null,
    'must_pass_for_parent_to_pass',

    get(values, 'sub_type') === allRubricSubTypes.RUBRIC_SUBMIT_EXERCISE
      ? 'is_on_dlow'
      : null,
    ...(get(values, 'is_on_dlow')
      ? [
          'dlow_min_page',
          'dlow_min_question',
          'dlow_min_question_from_bank',
          'dlow_min_question_level',
          'dlow_min_question_type',
        ]
      : []),

    'pass_score',
    'pass_score_compare_method',
    'allow_passing_by_teacher',
    get(values, 'allow_passing_by_teacher')
      ? 'teacher_marking_pass_fail'
      : null,
    get(values, 'sub_type') === allRubricSubTypes.RUBRIC_FORMULA_MANUAL_INPUT
      ? 'manual_input_rubric_default_pass'
      : null,
    'only_show_marking_passed',
    'sub_tasks',
    get(values, 'sub_type') === allRubricSubTypes.RUBRIC_PASS_COURSE_RUBRIC
      ? 'course_rubric_iids'
      : null,
    get(values, 'sub_type') === allRubricSubTypes.RUBRIC_PASS_COURSE_RUBRIC
      ? 'course_rubric_pass_all'
      : null,
  ].filter(Boolean);
};

const ui = (
  step,
  values,
  themeConfig,
  xpath,
  formid,
  props,
  mode,
  domainInfo,
) => {
  const config = {
    new_rubric: [
      {
        id: 'new',
        fields: getRubricFields(props, values),
      },
    ],
    new_new_rubric: [
      {
        id: 'new',
        fields: getRubricFields(props, values),
      },
    ],
    edit_pass_score: [
      {
        id: 'default',
        fields: ['pass_score'],
      },
    ],
    edit_pass_score_and_description: [
      {
        id: 'default',
        fields: ['description', 'pass_score'],
      },
    ],
    edit_rubric: [
      {
        id: 'left',
        fields: getRubricFields(props, values),
      },
    ],
    edit_edit_rubric: [
      {
        id: 'left',
        fields: getRubricFields(props, values),
      },
    ],
    new_skill: [
      {
        id: 'new',
        fields: [
          'name',
          'short_name',
          'code',
          ...(creditSyllabusLevels(domainInfo) ? ['level'] : []),
          ...(creditSyllabusHasTopEquivalentPositionCode(domainInfo)
            ? ['top_equivalent_positions']
            : []),
          'academic_categories',
          'description',
        ],
      },
    ],
    edit_skill: [
      {
        id: 'row',
        fields: [
          'name',
          'short_name',
          'code',
          ...(creditSyllabusLevels(domainInfo) ? ['level'] : []),
          'tags',
          ...(creditSyllabusHasTopEquivalentPositionCode(domainInfo)
            ? ['top_equivalent_positions']
            : []),
          'academic_categories',
          'description',
          'detailed_description',
          ...(window.isETEP ? ['evidence_from_system_with_learn_module'] : []),
          // 'passdef',
          ...(isBDTX() ? ['target_users', 'tieu_chi_by_cap'] : []),
        ],
      },
    ],
    new_skill_relation: [
      {
        id: 'new_skill_relation',
        fields: [
          'name',
          'from',
          'to',
          'estimated_effort',
          'estimated_effort_type',
          'description',
        ],
      },
    ],
    edit_skill_relation: [
      {
        id: 'edit_skill_relation',
        fields: [
          'name',
          'estimated_effort',
          'estimated_effort_type',
          'description',
          'status',
        ],
      },
    ],
    edit_scale: [
      {
        id: 'edit_scale',
        fields: ['scale'],
      },
    ],
    edit_scale_part: [
      {
        id: 'edit_scale_part',
        fields: ['description', 'detailed_description'],
      },
    ],
    edit_new_scale_part: [
      {
        id: 'edit_new_scale_part',
        fields: ['description', 'detailed_description'],
      },
    ],
    edit_penalty_practice: [
      {
        id: 'row2',
        // title: t1('penalty'),
        subTitle: t1(
          'user_practice_progress_will_be_set_to_0_once_has_this_number_of_failed_times',
        ),
        fields: ['penalty_practice'],
      },
    ],
    edit_expiry: [
      {
        id: 'rowx',
        title: t1('skill_validity_over_time'),
        fields: ['expiry'],
      },
    ],
    edit_passing_definition: [
      {
        id: 'row',
        title: t1('passed_when'),
        subTitle: t1(
          'if_any_aspect_greater_than_0_it_means_that_aspects_must_be_passed',
        ),
        fields: [
          'aspects_percent',
          // 'pd_test',
          // 'pd_learn',
        ],
      },
      {
        id: 'row2',
        title: t1('effort_sources'),
        subTitle: t1(
          'any_of_the_following_effort_sources_can_be_considered_as_passed_for_skill',
        ),
        fields: [
          'effort_sources',
          // 'pd_test',
          // 'pd_learn',
        ],
      },
      // {
      //   id: 'row2',
      //   title: t1('penalty_for_practicing_errors'),
      //   fields: [
      //     'penalty_practice',
      //   ],
      // },
      // {
      //   id: 'row3',
      //   title: t1('penalty_for_applying_errors'),
      //   fields: [
      //     'penalty_apply',
      //   ],
      // },
      // {
      //   id: 'col_left_1',
      //   title: t1('practiced_correct'),
      //   fields: [
      //     'pd_practiced_right',
      //     'pd_practiced_duration',
      //   ],
      // },
      // {
      //   id: 'col_left_1',
      //   title: t1('penalty_for_wrong_practice_once_passed'),
      //   fields: [
      //     'pd_practice_wrong',
      //     'pd_practice_wrong_penalty',
      //   ],
      // },
      // {
      //   id: 'col_left_2',
      //   title: t1('applied_correct'),
      //   fields: [
      //     'pd_applied_right',
      //     'pd_applied_duration',
      //   ],
      // },
      // {
      //   id: 'col_right_2',
      //   title: t1('penalty_for_wrong_application_once_passed'),
      //   fields: [
      //     'pd_apply_wrong',
      //     'pd_apply_wrong_penalty',
      //   ],
      // },
    ],
    new_new_learning_item_rubric: [
      {
        id: 'new_new_learning_item_rubric',
        fields: ['scale', 'name', 'code', 'description', 'tags'],
      },
    ],
  };
  const r = config[step];
  if (!r) {
    return config[`${step}_${(values && values.type) || 'skill'}`];
  }
  return r;
};

const layout = {
  // edit_aspects_percent: 'skill_aspects_percent',
  edit_skill: commonFormLayouts.TWO_COLS_AND_ROW,
  edit: commonFormLayouts.TWO_COLS_AND_ROW,
};

const finalProcessBeforeSubmit = (fullData, node, schema, mode, step) => {
  if (mode === 'edit' && step === 'new_scale_part') {
    const returnedData = Object.assign({}, fullData);
    const DataToPopulateInScaleParts = {};
    ['description', 'detailed_description'].forEach((fieldName) => {
      DataToPopulateInScaleParts[fieldName] = fullData[fieldName];
      delete returnedData[fieldName];
    });
    returnedData.extra_data_to_populate_in_scale_parts = {
      [returnedData.scale_part_index]: DataToPopulateInScaleParts,
    };
    return returnedData;
  }
  return fullData;
};

export default {
  schema,
  ui,
  layout,
  finalProcessBeforeSubmit,
};
