import React, { Component } from 'react';
import { connect } from 'react-redux';
import { t1 } from 'translate/index';
import Icon from 'components/common/Icon/index';
// import FormCreateSession from './Create';
import apiUrls from '../endpoints/index';
import Request from 'common/network/http/Request';
import { canJoinToLearn, isSessionScheduled, joinStatuses } from '../utils';
import { ERR_SESSION_NOT_STUDENT_OR_TEACHER_OF_COURSE } from './constants';
import withAutomaticScaleVirtualClass from 'components/admin/session/common/withAutomaticScaleVirtualClass';
import { createSelector } from 'reselect';
import getLodash from 'lodash.get';
import './stylesheet.scss';
import withGlobalDialogs from 'components/common/modal/withGlobalDialogs';
import { isMobileBrowser } from 'common';
import { openZoomOnWeb } from '../join-meeting-options/join-zoom';
import actionsNode from 'actions/node/creators';
import actions from 'actions/node/creators';
import JoinMeetingOptionsPopup from '../join-meeting-options/JoinMeetingOptionsPopup';

class MeetingUrl extends Component {
  createBBBLink = (sessionIid) => {
    const isZoomPlatform = getLodash(this.props.session, 'platform') == 'zoom';
    const params = {
      session_iid: sessionIid,
      is_course_admin: 1,
      is_app: isZoomPlatform && isMobileBrowser(true) ? 1 : 0, // open Zoom to mobile app if play url link in mobile web
    };

    const { session, isTeacher, dispatch } = this.props;

    Request.post(apiUrls.join_class_by_session, params).then((res) => {
      if (res.success) {
        const joinZoomOptionDialogKey = 'join_zoom_options';

        if (
          window.CAN_USER_OPEN_ZOOM_APP &&
          getLodash(res, 'result.response.urlDirect')
        ) {
          dispatch(
            actionsNode.handleOpenDialog(
              {
                contentDialog: (
                  <JoinMeetingOptionsPopup
                    joinMeetingResponse={res}
                    isTeacher={isTeacher}
                    session={session}
                    onMeetingJoined={() => {
                      dispatch(
                        actions.handleOpenDialog(
                          { openDialog: false },
                          joinZoomOptionDialogKey,
                        ),
                      );
                    }}
                  />
                ),
                optionsProperties: {
                  handleClose: true,
                  title: t1('join_zoom_options'),
                  modal: true,
                },
              },
              joinZoomOptionDialogKey,
            ),
          );
        } else {
          openZoomOnWeb(res, isZoomPlatform, isTeacher, session);
        }
      } else {
        if (res.err_code === ERR_SESSION_NOT_STUDENT_OR_TEACHER_OF_COURSE) {
          alert(t1('you_cant_join_virtual_class_cause_of_you_are_not_teacher'));
        } else {
          alert(t1(res.message || 'get_virtual_class_failed'));
        }
      }
    });
  };

  render() {
    const {
      session,
      beforeTimeCanJoinClass,
      statusText,
      serverTimestamp,
      automaticScaleVirtualClassEnabled,
      requiredLicense,
      canJoinVirtualClass,
    } = this.props;

    if (!isSessionScheduled(session, automaticScaleVirtualClassEnabled)) {
      return <span>{t1('not_scheduled')}</span>;
    }

    const status = canJoinToLearn(
      session,
      beforeTimeCanJoinClass,
      serverTimestamp,
      automaticScaleVirtualClassEnabled,
    );

    return (
      <div
        className={
          requiredLicense && !canJoinVirtualClass
            ? 'cannot-join-virtual_class'
            : ''
        }
      >
        {status === joinStatuses.JOIN_STATUS_OK && (
          <div>
            <div
              className={`status-item ${
                requiredLicense && !canJoinVirtualClass ? '' : 'cursor-pointer'
              }`}
              onClick={() => {
                if (requiredLicense && !canJoinVirtualClass) {
                  return;
                }
                this.createBBBLink(session.iid);
              }}
            >
              <div
                className={'d-flex justify-content-center align-items-center '}
              >
                <div
                  className="join-icon ve-enter m-r-10"
                  title={t1('get_new_link_to_join')}
                />
                <div>{statusText}</div>
              </div>
            </div>
            {!!session.stream_link && (
              <a
                className={'text-secondary'}
                target={'_blank'}
                href={session.stream_link}
              >
                <Icon icon={'link'} /> {t1('session_stream_link')}
              </a>
            )}
          </div>
        )}

        {status === joinStatuses.JOIN_STATUS_TOO_EARLY && (
          <div className="text-muted status-item">
            {t1('too_early_to_join')}.{' '}
            <span
              title={t1(
                'class_opens_%d_minutes_before_starting',
                session.pre_class_time || beforeTimeCanJoinClass,
              )}
            >
              <Icon icon="help" />
            </span>
          </div>
        )}

        {status === joinStatuses.JOIN_STATUS_TOO_LATE && (
          <div className="status-item">
            <span>{t1('time_over')}</span>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => getLodash(state, 'domainInfo.conf.before_time_can_join_class'),
  (state, { session }) =>
    !getLodash(session, 'skipRequiredLicense') &&
    getLodash(state, 'domainInfo.conf.required_license'),
  (state, { canJoinVirtualClass }) => {
    const requiredLicense = getLodash(
      state,
      'domainInfo.conf.required_license',
    );

    if (!requiredLicense || canJoinVirtualClass) {
      return true;
    }

    const functionActiveOfLicense = getLodash(
      state,
      'dataApiResults.license.license.package_detail.function_active',
    );
    if (
      !Array.isArray(functionActiveOfLicense) ||
      !functionActiveOfLicense.length
    ) {
      return false;
    }

    const licenseUserSystem = functionActiveOfLicense.find(
      ({ id } = {}) => id === 'license_user_system',
    );

    return [1, '1'].includes(
      getLodash(licenseUserSystem, 'action.license_admin_virtual_class'),
    );
  },
  (beforeTimeCanJoinClass, requiredLicense, canJoinVirtualClass) => ({
    beforeTimeCanJoinClass,
    requiredLicense,
    canJoinVirtualClass,
  }),
);

export default connect(mapStateToProps)(
  withGlobalDialogs()(withAutomaticScaleVirtualClass(MeetingUrl)),
);
