import React from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash.get';

class SearchFormLayout extends React.PureComponent {
  render() {
    const { groups, submitButton } = this.props;

    const {
      cot_can_data_by_category,
      codes,
      mails,
      names,
      phones,
      text,
      license_in_active,
    } = lodashGet(groups, 'id.fieldNames', {});

    return (
      <div className="container-fluid elementGroup">
        <div className="row">
          {!!cot_can_data_by_category && (
            <div className="col-md-12"> {cot_can_data_by_category}</div>
          )}
          {!!codes && <div className="col-md-6">{codes}</div>}
          {!!mails && <div className="col-md-6">{mails}</div>}
          {!!names && <div className="col-md-6">{names}</div>}
          {!!phones && <div className="col-md-6">{phones}</div>}
        </div>
        <div className="row">
          <div className="col-md-12">{text}</div>
          {!!license_in_active && (
            <div className="col-md-12">{license_in_active}</div>
          )}
        </div>
        <div className="row">
          <div className="col-md-12 m-t-20 text-center">{submitButton}</div>
        </div>
      </div>
    );
  }
}

export default connect()(SearchFormLayout);
