import React, { useCallback, useEffect, useState } from 'react';
import {
  enableTheme,
  getThemeFromLocalStorage,
  saveThemeToLocalStorage,
  themes,
} from './utils';
import get from 'lodash.get';
import { layouts } from 'configs/constants';
import { connect } from 'react-redux';
import { layoutSelector } from 'utils/selector';
import { compose } from 'redux';
import withUserInfo from 'common/hoc/withUserInfo';

const defaultBodyClass = document.body.className;

export const ThemeContext = React.createContext({
  theme: themes.light,
  toggleTheme: () => {},
});

const ThemeProvider = ({ children, themeConfig, userInfo }) => {
  const [theme, setTheme] = useState(
    getThemeFromLocalStorage() || themes.light,
  );

  const toggleTheme = useCallback((themeValue) => {
    setTheme(themeValue);

    saveThemeToLocalStorage(themeValue);
  }, []);

  const renderClassnameByColorPalette = useCallback(
    () => {
      let layout = get(themeConfig, 'layout');
      layout = [layouts.VT].includes(layout) ? layout : 'default';

      return `theme-${layout}-${theme}`;
    },
    [themeConfig, theme],
  );

  useEffect(
    () => {
      let themeConfig = getThemeFromLocalStorage(userInfo) || themes.light;

      if (!enableTheme()) {
        themeConfig = themes.light;
      }

      setTheme(themeConfig);
    },
    [userInfo],
  );

  useEffect(
    () => {
      const themeClass = renderClassnameByColorPalette();

      document.body.className = `${defaultBodyClass} ${themeClass}`;
    },
    [renderClassnameByColorPalette, theme],
  );

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default compose(
  connect(layoutSelector),
  withUserInfo,
)(ThemeProvider);
