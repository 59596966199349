export default {
  login: '/user/login',
  logout: '/user/logout',
  register: '/user/register',
  forgot_password: '/user/forgot-password',
  reset_password_forgotten: '/user/reset-password-forgotten',
  update: '/user/update',
  update_profile_info: '/profile/update-info',
  update_profile_avatar: '/profile/update-avatar',
  update_password: '/profile/change-password',
  update_settings: '/profile/user-settings',
  login_histories: '/profile/login-histories',
  learn_histories: '/profile/learn-histories',
  profile: '/profile',
  finding_courses_to_learn: '/profile/finding-courses-to-learn',
  complete_before_using: '/profile/complete-before-using',
  edit_profile: '/profile/edit/:id',
  inProgressCourses: '/dashboard/in-progress-courses',
  myEnrollmentPlans: '/dashboard/my-enrolment-plans',
  adminProfile: (userIid) => `/admin/user/${userIid}/view`,
};
