import React, { useCallback } from 'react';
import lodashGet from 'lodash.get';
import { connect } from 'react-redux';
import { t1 } from 'translate';
import CommonAntdTable from 'components/common/antd/table';
import sagaActions from 'actions/node/saga-creators';
import endpoints from 'components/bdtx/training-phase/endpoints';

const AssignMaterialsByCap = ({
  trainingPhase,
  cap,
  targetUser,
  moduleMaterials,
  refetchListMaterial,
  dispatch,
}) => {
  const onSuccess = useCallback(
    () => {
      if (typeof refetchListMaterial === 'function') {
        refetchListMaterial();
      }
    },
    [refetchListMaterial],
  );

  const onChangeMaterial = useCallback(
    (moduleIid, programIid) => {
      dispatch(
        sagaActions.submitFormRequest(
          '',
          {
            url: endpoints.bdtx_step_save_module_material,
            extraParams: {
              cap: cap,
              training_phase_iid: lodashGet(trainingPhase, 'iid'),
              module_iid: moduleIid,
              program_iid: programIid,
            },
            executeOnSuccess: onSuccess,
          },
          'POST',
          false,
        ),
      );
    },
    [cap, dispatch, onSuccess, trainingPhase],
  );

  const renderMaterials = useCallback(
    (module, materials) => {
      if (!materials || !materials.length) {
        return (
          <span className="text-danger">{t1('module_have_no_material')}</span>
        );
      }
      const deployed = lodashGet(module, 'deployed');

      return materials.map((material) => {
        const radioName = `${lodashGet(module, 'iid')}-${cap}-${targetUser}`;

        const radioId = `${radioName}-${lodashGet(material, 'iid')}`;

        const sellerName =
          lodashGet(material, '__expand.organizations.0.name') || '';

        return (
          <div className="d-flex align-items-center m-5">
            <input
              type="radio"
              id={radioId}
              name={radioName}
              defaultChecked={lodashGet(material, 'chosen')}
              disabled={deployed}
              onChange={() =>
                onChangeMaterial(
                  lodashGet(module, 'iid'),
                  lodashGet(material, 'iid'),
                )
              }
            />

            <label for={radioId} className="m-l-5">
              {lodashGet(material, 'name')}{' '}
              {sellerName ? `(${sellerName})` : ''}
            </label>
          </div>
        );
      });
    },
    [cap, onChangeMaterial, targetUser],
  );

  const columns = useCallback(
    () => {
      return [
        {
          title: t1('module_name'),
          width: '35%',
          render: (module) => {
            const name = lodashGet(module, 'name');
            const materials = lodashGet(module, 'bought_materials') || [];
            const hasChooseMaterial = materials.find(
              (material) => material.chosen,
            );

            return (
              <>
                <span>{name}</span>
                {!hasChooseMaterial && (
                  <div className="text-danger font-size-small">
                    {t1('missing_material')}
                  </div>
                )}
              </>
            );
          },
        },
        {
          title: t1('module_materials'),
          render: (module) => {
            const materials = lodashGet(module, 'bought_materials') || [];

            return renderMaterials(module, materials);
          },
        },
      ];
    },
    [renderMaterials],
  );

  if (!moduleMaterials || !moduleMaterials.length) {
    return <span>{t1('no_assign_modules')}</span>;
  }

  return (
    <div>
      <CommonAntdTable
        rowKey="iid"
        dataSource={moduleMaterials}
        columns={columns()}
        pagination={false}
        className="learn-default"
      />
    </div>
  );
};

export default connect()(AssignMaterialsByCap);
