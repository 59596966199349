import React from 'react';
import Layout from './Layout';
import ModuleLabel from 'components/bdtx/choose-modules/new/ModuleLabel';

const schema = (modules, readOnly, hiddenFields) => () => {
  const formatOptions = (modules || []).map((module) => {
    const assigned = module.assigned;
    const fromEtep = module.from_etep;

    return {
      value: module.iid,
      label: <ModuleLabel module={module} hiddenFields={hiddenFields} />,
      disabled: assigned || fromEtep,
    };
  });

  return {
    modules: {
      type: 'multiCheckbox',
      options: formatOptions,
      disabled: readOnly,
    },
  };
};

const ui = () => {
  return [
    {
      id: 'default',
      fields: ['modules'],
    },
  ];
};

export default (modules, readOnly, hiddenFields) => ({
  schema: schema(modules, readOnly, hiddenFields),
  ui,
  layout: {
    component: Layout,
    freestyle: 1,
    optionsProperties: {
      modules,
    },
  },
});
