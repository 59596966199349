const Select = ({
  options, // see schema-form/lib/attribs.js
  populateValue,
  paramsasync, // see schema-form/lib/attribs.js

  hintText,
  floatingLabelText,
  floatingLabelFixed,
  defaultValue,
  errorText,
  validate,
  normalize,
  onChange,
  classWrapper,
  fullWidth,
  readOnly,
  inline,
  multiple,
  ...rest
}) => {
  return {
    type: 'select',
    options,
    populateValue,
    paramsasync,

    hintText,
    floatingLabelText,
    floatingLabelFixed,
    defaultValue,
    errorText,
    validate,
    normalize,
    onChange,
    classWrapper,
    fullWidth,
    readOnly,
    inline,
    multiple,
    ...rest,
  };
};

export default Select;
