import React from 'react';
import { t1 } from 'translate';
import Requester from 'common/network/http/Request';
import { openLinkInNewTab } from 'common/utils/browser';
import apiUrls from 'api-endpoints';
import lodashGet from 'lodash.get';
import { Modal } from 'antd';
import { detect } from 'detect-browser';

const LiveStreamLink = ({}) => {
  const fetchNewLink = () => {
    const url = apiUrls.get_information_by_domain;
    const postData = {
      _sand_hostname: window.location.hostname,
    };

    Requester.post(url, postData).then((response) => {
      const liveStreamLinkConf = lodashGet(
        response,
        'result.conf.virtual_class_live_stream_link_for_non_login_user',
      );

      const liveStreamLink = lodashGet(liveStreamLinkConf, 'link');

      const noLivestreamMessageTitle = lodashGet(
        liveStreamLinkConf,
        'no_livestream_message_title',
      );
      const noLivestreamMessageContent = lodashGet(
        liveStreamLinkConf,
        'no_livestream_message_content',
      );

      if (liveStreamLink && liveStreamLink.length) {
        const detectedBrowser = detect();
        if (detectedBrowser.name === 'ios') {
          window.location.href = liveStreamLink;
        } else {
          openLinkInNewTab(liveStreamLink);
        }
      } else {
        Modal.info({
          title: noLivestreamMessageTitle,
          content: noLivestreamMessageContent,
          onOk() {
            window.location.reload();
          },
        });
      }
    });
  };

  return (
    <a className={'text-secondary'} onClick={fetchNewLink}>
      {t1('session_live_stream_for_non_login_user')}
    </a>
  );
};

export default LiveStreamLink;
