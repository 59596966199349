import React from 'react';
import get from 'lodash.get';
import { withRouter } from 'react-router';
import Search from './search';

const DeploymentPreview = ({
  match,
  hiddenFields,
  noSearchForm,
  showAction,
  searchFormId,
  trainingPhaseIid,
  isCCModel,
}) => {
  const newTrainingPhaseIid = trainingPhaseIid || get(match, 'params.iid');

  return (
    <Search
      trainingPhaseIid={newTrainingPhaseIid}
      hiddenFields={hiddenFields}
      noSearchForm={noSearchForm}
      showAction={showAction}
      searchFormId={searchFormId}
      isCCModel={isCCModel}
    />
  );
};

export default withRouter(DeploymentPreview);
