import React, { useCallback } from 'react';
import { t1 } from 'translate';
import lodashGet from 'lodash.get';
import isEqual from 'lodash.isequal';
import unique from 'lodash.uniqwith';
import Alert from 'antd/lib/alert';
import BlockItem from './block';
import { isScormSco } from 'components/admin/scorm/scorm';

import ScoSplColumned from './SplColumned';
import connect from 'react-redux/es/connect/connect';
import { ntype } from 'configs/constants';
import SuccessAlert from 'components/common/SuccessAlert';
import { isItemPassed, isSequentialSCO } from 'common/learn';
import { isItemDone } from 'common/learn';
import { isIidEqual } from 'components/learn/utils';
import { useProgressSavingForChildrenOfSCOSPL } from './block/saveProgress';

const ScoSpl = ({
  nodes,
  scoIid,
  courseIid,
  course,
  trackerProgress,
  dispatch,
  isNested = false,
  ...remainProps
}) => {
  const sco = nodes[scoIid];

  const children = lodashGet(sco, 'children', []);
  const isSequential = isSequentialSCO(nodes[scoIid]);

  const firstNotDoneItemIndex = children.findIndex(
    (childIid) => !isItemDone(trackerProgress, childIid),
  );

  const [visibleItems, setVisibleItems] = React.useState([]);
  const [currentDoingItemIid, setCurrentDoingItemIid] = React.useState(
    lodashGet(children, '0'),
  );

  React.useEffect(
    () => {
      let visibleItems;

      if (isSequential) {
        visibleItems = (children || []).filter((iid) =>
          isItemDone(trackerProgress, iid),
        );
      } else {
        visibleItems = children;
      }

      if (currentDoingItemIid) {
        visibleItems = [...visibleItems, currentDoingItemIid];
      }

      visibleItems = visibleItems.map((item) => Number(item));

      visibleItems = unique(visibleItems, isEqual);

      if (visibleItems.length) {
        setVisibleItems(visibleItems);
      }
    },
    [isSequential, currentDoingItemIid, children, trackerProgress],
  );

  useProgressSavingForChildrenOfSCOSPL(
    scoIid,
    courseIid,
    nodes,
    trackerProgress,
    dispatch,
    isNested,
  );

  const showNextItem = () => {
    const notDoneItems = children.filter(
      (itemIid) => !isItemDone(trackerProgress, itemIid),
    );

    if (notDoneItems.length) {
      const [nextItemIid] = notDoneItems.slice(0, 1);
      setCurrentDoingItemIid(nextItemIid);
    }
  };

  const shouldShowNextButton = useCallback(
    () => {
      if (currentDoingItemIid && currentDoingItemIid !== scoIid) {
        const currentDoingItem = nodes[currentDoingItemIid];

        if (isSequentialSCO(currentDoingItem)) {
          return isItemDone(trackerProgress, currentDoingItemIid);
        }
      }

      const lastChildItemIid = lodashGet(children, `${children.length - 1}`);
      const isLastItem = isIidEqual(currentDoingItemIid, lastChildItemIid);

      return isSequential && firstNotDoneItemIndex >= 0 && !isLastItem;
    },
    [
      scoIid,
      currentDoingItemIid,
      isSequential,
      firstNotDoneItemIndex,
      nodes,
      trackerProgress,
      children,
    ],
  );

  const shouldEnableNextButton = () => {
    const [lastVisibleItemIid] = visibleItems.slice(-1);

    return !!(
      lastVisibleItemIid &&
      (isItemDone(trackerProgress, lastVisibleItemIid) ||
        isItemPassed(trackerProgress, lastVisibleItemIid))
    );
  };

  const shouldShowItem = (itemIid) => {
    return visibleItems.includes(Number(itemIid));
  };

  const shouldShowDoneItemMessage = isSequential && firstNotDoneItemIndex < 0;

  if (!children.length) {
    return <Alert type="error" message={t1('chapter_has_no_children')} />;
  }

  // if scp.layout_columns
  if (sco.spl_columns) {
    return (
      <ScoSplColumned
        nodes={nodes}
        scoIid={scoIid}
        course={course}
        {...remainProps}
      />
    );
  }

  return (
    <div className="p-b-15 p-t-10">
      {sco.children.map((childIid, index) => {
        const child = nodes[childIid];

        if (!shouldShowItem(childIid)) {
          return null;
        }

        if (child.ntype === ntype.SCO && !isScormSco(child)) {
          return (
            <ScoSpl
              {...remainProps}
              nodes={nodes}
              scoIid={childIid}
              courseIid={courseIid}
              course={course}
              trackerProgress={trackerProgress}
              dispatch={dispatch}
              isNested
            />
          );
        }

        return (
          <>
            <BlockItem
              key={`spl-${scoIid}-${childIid}`}
              iid={childIid}
              piid={scoIid}
              nodes={nodes}
              course={course}
              shouldSelfSavingProgress={isSequential}
              {...remainProps}
            />
          </>
        );
      })}

      {shouldShowNextButton() && (
        <div className={'m-t-20 m-b-40 text-center'}>
          <button
            onClick={() => showNextItem()}
            className={'btn btn-primary'}
            disabled={!shouldEnableNextButton()}
          >
            Chuyển sang mục kế tiếp
          </button>

          {!shouldEnableNextButton() && (
            <div className={'text-center m-t-5'}>
              (Bạn cần học xong nội dung bên trên để có thể tiếp tục)
            </div>
          )}
        </div>
      )}

      {shouldShowDoneItemMessage && !isNested && (
        <div className={'m-t-20 m-b-40 text-center'}>
          <SuccessAlert inline>Bạn đã hoàn thành nội dung này</SuccessAlert>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  trackerProgress: state.trackerProgress,
});

export default connect(mapStateToProps)(ScoSpl);
