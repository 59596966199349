export default {
  syncDebugSearchUser: '/taphuan/sync-debug/search-users',
  syncDebugSearchSchools: '/taphuan/sync-debug/search-schools',
  searchUser: '/user/api/search',
  loginHistory: '/user/api/get-login-history',
  preview_import_users_secondary_code:
    '/taphuan/user/preview-import-users-secondary-code',
  confirm_import_users_secondary_code:
    '/taphuan/user/confirm-import-users-secondary-code',
  export_errors_when_import_users_secondary_code:
    '/taphuan/user/export-errors-when-import-users-secondary-code',
  get_detail_license_histories: '/license/api/get-detail-histories',
  get_user_detail: '/user/detail',
  get_user_basic_info: '/user/api/get-basic-info',
  get_user_learning_and_assessment:
    '/user/api/get-user-learning-and-assessment',
  add_cotcan: '/user/api/add-cotcan-by-condition',
  remove_cotcan: '/user/api/remove-cotcan',
  delete_user: '/user/index/delete',
};
