import React from 'react';
import get from 'lodash.get';
import CommonAntdTable from 'components/common/antd/table';
import Status from 'components/common/Status';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import { Link } from 'react-router-dom';
import Icon from 'components/common/Icon';

const TableResult = ({ items = [] }) => {
  const renderTableColumns = () => {
    return [
      {
        title: 'Tên đề thi',
        // dataIndex: 'name',
        render: (item) => (
          <span>
            <Link
              to={`/admin/review-exam-store/${item.iid}`}
              className={'m-r-5'}
            >
              {item.name}
            </Link>
          </span>
        ),
      },
      {
        title: 'Mẫ đề thi',
        dataIndex: 'code',
        render: (code) => <span>{code}</span>,
      },
      {
        title: 'Đơn vị tạo',
        dataIndex: 'organizations',
        render: (organizations) => {
          const orgName = get(organizations, '0.name');

          return <span>{orgName}</span>;
        },
      },
      {
        title: 'Tổng câu hỏi trong ngân hàng',
        type: TYPE_OF_TABLE_DATA.TITLE,
        width: 120,
        dataIndex: 'bank_questions_count',
        render: (bank_questions_count) => <span>{bank_questions_count}</span>,
      },
      {
        title: 'Trạng thái',
        type: TYPE_OF_TABLE_DATA.TITLE,
        dataIndex: 'review_status',
        render: (review_status) => {
          if (typeof review_status === 'undefined') {
            return <span>Đang chờ duyệt</span>;
          }

          return <Status status={review_status} />;
        },
      },
      {
        title: 'Thao tác',
        type: TYPE_OF_TABLE_DATA.TITLE,
        render: (item) => {
          return (
            <>
              <Link
                to={`/admin/exam-template/${item.iid}/dashboard`}
                className={'m-r-5'}
              >
                <Icon icon="edit" antIcon />
              </Link>
              <Link to={`/admin/review-exam-store/${item.iid}`}>
                <Icon icon="eye" antIcon />
              </Link>
            </>
          );
        },
      },
    ];
  };

  return (
    <CommonAntdTable
      dataSource={items}
      columns={renderTableColumns()}
      pagination={false}
      className="learn-default"
    />
  );
};

export default TableResult;
