import React from 'react';
import AntdTreeSelect from 'antd/lib/tree-select';
import hoistNonReactStatic from 'hoist-non-react-statics';
import Icon from 'antd/lib/icon';
import { ACADEMIC_CATEGORY } from 'configs/constants';
import { unaccentVietnamese } from 'common/utils/string/vn';
import { getPopupContainerOfElement } from 'common/utils/schema-form';
import { Input } from 'antd';
import lodashGet from 'lodash.get';
import debounce from 'lodash.debounce';
const { TreeNode } = AntdTreeSelect;

const TreeSelect = ({
  treeData = [],
  multiple,
  placeholder,
  value,
  onChange,
  valueAsArrayEvenWhenNotMultiple,
  treeCheckable,
  showCheckedStrategy,
  style,
  checkParentEqualCheckAllChildren,
  onDropdownVisibleChange,
  loading,
  className,
  onBlur,
  allowClear,
  elementType,
  readOnly,
  showSearch = true,
  dropdownStyle = { maxHeight: 300, overflow: 'auto' },
  loadAsyncData,
  treeDefaultExpandedKeys,
  extendNode,
}) => {
  if (checkParentEqualCheckAllChildren) {
    treeCheckable = true;
    showCheckedStrategy = TreeSelect.SHOW_PARENT;
  }
  const showWhenNotFounded = lodashGet(extendNode, 'showWhenNotFounded');

  const node = lodashGet(extendNode, 'node');

  const ExtendNote = () => {
    if (typeof node === 'function') {
      return node(text);
    } else {
      return undefined;
    }
  };

  const [showExtendNode, setShow] = React.useState(!showWhenNotFounded);
  const [text, setText] = React.useState('');

  const treeSelectValue = React.useMemo(
    () => {
      if (!multiple && valueAsArrayEvenWhenNotMultiple) {
        return value && value[0];
      }
      return value;
    },
    [valueAsArrayEvenWhenNotMultiple, multiple, value],
  );

  const handleTreeSelectChange = React.useCallback(
    (newTreeSelectValue, label, extra) => {
      if (typeof newTreeSelectValue === 'undefined') {
        newTreeSelectValue = null;
        // because redux form onChange will ignore undefined value
        // so if we want to clear the value in redux form
        // we need to onChange(null)
      }

      if (!multiple && valueAsArrayEvenWhenNotMultiple) {
        onChange(
          newTreeSelectValue !== null ? [newTreeSelectValue] : null,
          label,
          extra,
        );
        return;
      }
      onChange(newTreeSelectValue, label, extra);
    },
    [valueAsArrayEvenWhenNotMultiple, multiple, onChange],
  );

  const handleDropdownVisibleChange = React.useCallback(
    (visible) => {
      if (typeof onDropdownVisibleChange === 'function') {
        onDropdownVisibleChange(visible);
      }
    },
    [onDropdownVisibleChange],
  );

  const onSearch = React.useCallback(
    debounce((text) => {
      if (!showWhenNotFounded) {
        return;
      }
      if (
        treeData.filter(
          (v) =>
            typeof v.title === 'string' &&
            unaccentVietnamese(String(v.title))
              .toLowerCase()
              .indexOf(unaccentVietnamese(text).toLowerCase()) >= 0,
        ).length === 0 &&
        !!text
      ) {
        setShow(true);
      } else {
        setShow(false);
      }
    }, 500),
    [treeData],
  );

  // we fake the tree data to render a loading icon
  if (loading) {
    treeData = [
      {
        value: Math.random(),
        title: <Icon type="loading" style={{ fontSize: 20 }} />,
        disabled: true,
      },
    ];
  }

  if (readOnly) {
    style = {
      ...(style || {}),
      pointerEvents: 'none',
    };
  }

  const isAsyncLoadData = loadAsyncData && typeof loadAsyncData === 'function';

  return (
    <AntdTreeSelect
      showSearch={showSearch}
      treeData={[
        ...(node && showExtendNode
          ? [
              {
                selectable: false,
                title: ExtendNote(),
              },
            ]
          : []),
        ...treeData,
      ]}
      multiple={multiple}
      placeholder={placeholder}
      value={treeSelectValue}
      onChange={handleTreeSelectChange}
      treeCheckable={treeCheckable}
      showCheckedStrategy={showCheckedStrategy}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      style={style}
      className={className}
      onBlur={!(elementType === ACADEMIC_CATEGORY) ? onBlur : false}
      allowClear={allowClear}
      dropdownStyle={dropdownStyle}
      filterTreeNode={(input, child) => {
        if (typeof child.props.title !== 'string') {
          return showExtendNode;
        }
        return (
          unaccentVietnamese(String(child.props.title))
            .toLowerCase()
            .indexOf(unaccentVietnamese(input).toLowerCase()) >= 0
        );
      }}
      onSearch={(text) => {
        setText(text);
        onSearch(text);
      }}
      treeDataSimpleMode={isAsyncLoadData}
      loadData={isAsyncLoadData && loadAsyncData}
      treeDefaultExpandedKeys={treeDefaultExpandedKeys}
      getPopupContainer={getPopupContainerOfElement}
    />
  );
};

hoistNonReactStatic(TreeSelect, AntdTreeSelect);

export default TreeSelect;
