import React from 'react';
import { compose } from 'redux';
import lodashGet from 'lodash.get';
import { connect } from 'react-redux';
import fetchData from 'components/common/fetchData';
import CommonAntdTable from 'components/common/antd/table';
import Icon from 'components/common/Icon';
import { t1 } from 'translate';
import { timestampToDateTimeString } from 'common/utils/Date';
import RestoreButton from './RestoreButton';
import { getQuestionAnswersWrongScore } from '../common/utils';
import { initExercise as initExerciseAction } from 'actions/learn/exercise/normal/saga-creators';
import { isCourseEnded } from 'components/admin/course/utils';
import { isFreeze } from 'utils/Util';
import { isSyllabusExpired } from 'components/admin/syllabus/utils';
import withServerTs from 'common/hoc/withServerTs';

const TakeHistoryResult = ({
  course,
  syllabus,
  exerciseIid,
  takeHistoryResult,
  restoreSuccessful,
  currentTakeScore,
  learnInfo,
  nodes,
  initExercise,
  serverTs,
}) => {
  const currentTake = lodashGet(takeHistoryResult, 'currentTake');

  const columns = [
    {
      title: t1('date_time'),
      key: 'ts',
      width: 150,
      className: 'text-center',
      render: (row) => timestampToDateTimeString(row.ts),
    },
    {
      title: t1('score'),
      key: 'dbScore',
      className: 'text-center',
      width: 120,
      render: (row) => lodashGet(row, 'dbScore', ''),
    },
    {
      title: t1('is_current_take'),
      width: 150,
      key: 'isCurrent',
      className: 'text-center',
      render: (row) =>
        lodashGet(row, 'isCurrent') ? (
          <Icon icon={'check'} className={'text-primary'} />
        ) : null,
    },
    {
      title: t1('action'),
      width: 150,
      key: 'action',
      className: 'text-center',
      render: (row) => {
        if (lodashGet(row, 'isCurrent')) {
          return null;
        }

        const takeInHistoryScore = parseFloat(lodashGet(row, 'dbScore', 0));
        currentTakeScore = parseFloat(currentTakeScore);

        const keepHighestScore = lodashGet(learnInfo, 'keep_highest_score');

        if (
          keepHighestScore &&
          (takeInHistoryScore < currentTakeScore ||
            (isNaN(takeInHistoryScore) && currentTakeScore))
        ) {
          return (
            <span>
              {t1('this_take_history_score_is_less_than_current_score')}
            </span>
          );
        }

        /*
         * method getQuestionAnswersWrongScore need the first param with this format:
         * {
         *    questionId: {
         *        answer: {
         *            answer: {}
         *        }
         *    }
         * }
         * so we need to format the answers in the history take
         */

        const formattedQuestions = {};
        Object.keys(lodashGet(row, 'answers')).forEach((questionId) => {
          formattedQuestions[questionId] = {
            answer: lodashGet(row, `answers.${questionId}`),
          };
        });

        const wrongQuestionAnswersScore = getQuestionAnswersWrongScore(
          formattedQuestions,
          nodes,
        );

        if (wrongQuestionAnswersScore && wrongQuestionAnswersScore.length) {
          return (
            <span className={'text-danger'}>
              {t1('exercise_has_changed_after_this_was_taken')}
            </span>
          );
        }

        if (lodashGet(row, 'isTakeQuestionsMissing')) {
          return (
            <span className={'text-danger'}>
              {t1('exercise_has_changed_after_this_was_taken')}
            </span>
          );
        }

        if (isSyllabusExpired(syllabus, serverTs)) {
          return (
            <span>
              {t1('cannot_restore_take_because_syllabus_has_already_ended')}
            </span>
          );
        }

        if (isCourseEnded(course, serverTs)) {
          return (
            <span>
              {t1('cannot_restore_take_because_course_has_already_ended')}
            </span>
          );
        }

        if (isFreeze(course)) {
          return (
            <span>
              {t1('cannot_restore_take_because_course_has_already_freezed')}
            </span>
          );
        }

        const takeId = lodashGet(currentTake, 'id');

        return (
          <RestoreButton
            restoreSuccessful={() => {
              initExercise(exerciseIid, learnInfo);

              if (typeof restoreSuccessful === 'function') {
                restoreSuccessful();
              }
            }}
            takeId={takeId}
            historyId={row.id}
          />
        );
      },
    },
  ];

  let histories = lodashGet(takeHistoryResult, 'histories', []);

  if (currentTake) {
    histories = [
      {
        ...currentTake,
        isCurrent: true,
      },
      ...histories,
    ];
  }

  return (
    <CommonAntdTable
      bordered
      columns={columns}
      dataSource={histories || []}
      className="learn-default"
      pagination={false}
    />
  );
};

const fetchTakeHistories = fetchData(({ userIid, exerciseIid, course }) => {
  return {
    baseUrl: 'take/api/histories',
    params: {
      user_iid: userIid,
      exercise_iid: exerciseIid,
      course_iid: lodashGet(course, 'iid'),
    },
    loadingStatusPropKey: 'loading',
    propKey: 'takeHistoryResult',
  };
});

const mapStateToProps = (state, props) => {
  const itemIid = lodashGet(props, 'exerciseIid');

  return {
    learnInfo: lodashGet(state, `learn.info.${itemIid}`),
    currentTakeScore: lodashGet(state, `trackerProgress.${itemIid}.p`),
    nodes: lodashGet(state, 'tree'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initExercise(itemIid, learnInfo) {
      dispatch(
        initExerciseAction(itemIid, learnInfo, !learnInfo.inlineExercise),
      );
    },
  };
};

export default compose(
  fetchTakeHistories,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withServerTs,
)(TakeHistoryResult);
