import React from 'react';
import Heading from '../../common/Heading';
import Content from '../../common/Content';
import { stripHTML } from 'common/utils/string';

const ListDefinition = ({ item }) => {
  if (item.block_list && Array.isArray(item.block_list)) {
    return (
      <div className="block-list-container">
        <Heading item={item} />
        <Content item={item} />
        <div className="content unordered-list">
          <ul>
            {item.block_list.map((text, i) => (
              <li key={`list-${item.iid}-${i}`}>
                <span
                  className="text-primary block-list-container__left"
                  style={{ display: 'inline' }}
                >{`${text.name}: `}</span>
                {!!text.content && stripHTML(text.content)}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  return null;
};

export default ListDefinition;
