import React, { useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Collapse, Alert } from 'antd';
import lodashGet from 'lodash.get';
import { t1 } from 'translate';
import fetchData from 'components/common/fetchData';
import ChooseModuleOnDialog from 'components/bdtx/training-phase/steps/common/choose-module-on-dialog';
import { getSchoolYearOfCurrentUser } from 'components/common/scholastic-config/utils';
import endpoints from 'components/bdtx/training-phase/endpoints';
import AssignModulesByCap from 'components/bdtx/training-phase/steps/choose-module/assign-modules-by-cap';
import sagaActions from 'actions/node/saga-creators';
import BackNextStep from 'components/bdtx/training-phase/steps/common/back-next-step';

const { Panel } = Collapse;

const ChooseModuleTrainingPhase = ({
  trainingPhase,
  refetchAssignModulesByCap,
  assignModulesByCap,
  dispatch,
  handleOnActiveStep,
  previousStepIndex,
  nextStepIndex,
}) => {
  const caps = lodashGet(trainingPhase, 'caps') || [];
  const targetUser = lodashGet(trainingPhase, 'target_user') || 'gv';
  const schoolYear =
    lodashGet(trainingPhase, 'school_year') || getSchoolYearOfCurrentUser();

  const deployed = lodashGet(trainingPhase, 'status.deployed');

  const onSelectModules = useCallback(
    (cap, moduleIids) => {
      dispatch(
        sagaActions.submitFormRequest(
          '',
          {
            url: endpoints.bdtx_step_save_assign_module,
            extraParams: {
              cap: cap,
              training_phase_iid: lodashGet(trainingPhase, 'iid'),
              module_iids: moduleIids,
            },
            executeOnSuccess: () => refetchAssignModulesByCap(),
          },
          'POST',
          false,
        ),
      );
    },
    [dispatch, refetchAssignModulesByCap, trainingPhase],
  );

  const renderPanelHeader = useCallback(
    (cap) => {
      const modules = lodashGet(assignModulesByCap, `${cap}`) || [];

      return (
        <div className="d-flex align-items-center justify-content-between">
          <div className="text-bold">{t1(`cap_${cap}`)}</div>
          <ChooseModuleOnDialog
            cap={cap}
            targetUser={targetUser}
            schoolYear={schoolYear}
            onSelectModules={onSelectModules}
            selectedModules={modules}
          />
        </div>
      );
    },
    [assignModulesByCap, onSelectModules, schoolYear, targetUser],
  );

  const renderAssignModulesByCap = useCallback(
    (cap) => {
      const modules = lodashGet(assignModulesByCap, `${cap}`) || [];

      return (
        <AssignModulesByCap
          cap={cap}
          targetUser={targetUser}
          assignModules={modules}
          trainingPhase={trainingPhase}
          refetchAssignModulesByCap={refetchAssignModulesByCap}
        />
      );
    },
    [assignModulesByCap, refetchAssignModulesByCap, targetUser, trainingPhase],
  );

  const renderPanel = useCallback(
    (cap) => {
      return (
        <Panel key={cap} header={renderPanelHeader(cap)}>
          {renderAssignModulesByCap(cap)}
        </Panel>
      );
    },
    [renderAssignModulesByCap, renderPanelHeader],
  );

  if (!trainingPhase) {
    return (
      <Alert
        type="error"
        message={t1('you_have_to_create_training_phase_before_choose_module')}
      />
    );
  }

  if (!caps || !caps.length) {
    return <Alert type="error" message={t1('training_phase_missing_caps')} />;
  }

  return (
    <>
      <Collapse defaultActiveKey={caps} className="full-width">
        {caps.map((cap) => {
          return renderPanel(cap);
        })}
      </Collapse>

      <BackNextStep
        onNextStep={handleOnActiveStep}
        onPreviousStep={handleOnActiveStep}
        previousStepIndex={previousStepIndex}
        nextStepIndex={nextStepIndex}
      />
    </>
  );
};

const fetchAssignModule = fetchData(({ trainingPhase }) => {
  const trainingPhaseIid = lodashGet(trainingPhase, 'iid');
  const caps = lodashGet(trainingPhase, 'caps') || [];
  const targetUser = lodashGet(trainingPhase, 'target_user') || 'gv';

  return {
    baseUrl: endpoints.bdtx_step_get_list_modules_grouped_by_cap,
    params: {
      training_phase_iid: trainingPhaseIid,
      target_user: targetUser,
      caps: caps,
      flat_by_cap: 1,
    },
    fetchCondition: !!trainingPhaseIid,
    propKey: 'assignModulesByCap',
    fetchFunctionPropKey: 'refetchAssignModulesByCap',
  };
});

export default compose(
  connect(),
  fetchAssignModule,
)(ChooseModuleTrainingPhase);
