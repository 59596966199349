import React from 'react';
import InvalidUsers from './invalid-users';
import { t1 } from 'translate';
import lodashGet from 'lodash.get';
import PrimaryButton from 'components/common/primary-button';
import ApiRequestBtnWithConfirmDialog from 'components/common/action-button/ApiRequestBtnWithConfirmDialog';
import userApiEndpoints from 'components/admin/user/endpoints';
import { deleteUserErrCodes } from 'components/admin/user/account/delete-users/common';
import Perm from 'common/utils/Perm';

export default ({
  openDialog,
  closeDialog,
  dialogKey,
  onCloseDialog,
  selectedAssessmentPhasesOptions,
}) => {
  return React.useCallback(
    (post) => {
      const { erroredUsers, errorsByUserIid, nDeleted, nErrored } = post || {};

      const isRoot = Perm.hasPerm('root');

      if (lodashGet(erroredUsers, 'length')) {
        openDialog(dialogKey, {
          contentDialog: (
            <div>
              <InvalidUsers
                erroredUsers={erroredUsers}
                errorsByUserIid={errorsByUserIid}
                nDeleted={nDeleted}
                nErrored={nErrored}
              />
              <div className="text-center">
                <PrimaryButton
                  className="m-r-10"
                  label={t1('close')}
                  onClick={() => closeDialog(dialogKey)}
                />
                {isRoot ? (
                  <ApiRequestBtnWithConfirmDialog
                    renderComponent={({ onClick }) => {
                      return (
                        <PrimaryButton
                          label={t1('force_delete_these_users')}
                          onClick={onClick}
                          className="btn-tertiary"
                        />
                      );
                    }}
                    url={userApiEndpoints.delete_user}
                    params={{
                      ids: (erroredUsers || []).map((u) => lodashGet(u, 'id')),
                      ignore_error_codes: [
                        deleteUserErrCodes.ERROR_ALREADY_DONE_ACTIVITIES_IN_THE_SYSTEM,
                      ],
                      assessment_phases: selectedAssessmentPhasesOptions,
                    }}
                    textConfirm={t1(
                      'are_you_sure_you_want_to_force_delete_these_users',
                    )}
                    onRequestSuccessful={() => closeDialog(dialogKey)}
                  />
                ) : null}
              </div>
            </div>
          ),
          optionsProperties: {
            title: t1('some_users_cannot_be_deleted'),
            modal: true,
            width: '90%',
            callbacks: {
              onCloseDialog,
            },
          },
        });
      }
    },
    [
      openDialog,
      dialogKey,
      onCloseDialog,
      closeDialog,
      selectedAssessmentPhasesOptions,
    ],
  );
};
