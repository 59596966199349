/* eslint-disable jsx-a11y/anchor-is-valid */
import { t1 } from 'translate';
import { constants } from 'configs/constants';
import { academicCategories } from 'components/admin/academic-category/schema/elements';
import {
  includeSubOrganizations,
  organizations,
} from 'components/admin/organization/schema/elements';
import { required, validationWithCondition } from 'common/validators';
import { hasAcademicCategories, hasOrganization } from 'common/conf';
import SearchFormDetailFreestyleEnterprise from './SearchFormLayoutFreeStyleEnterprise';
import lodashGet from 'lodash.get';
import { addPropsToEverySchemaElements } from 'common/utils/schema-form';
import { commonFormLayouts, elementDisplayModes } from 'schema-form/constants';
import MinimalSearchRecapFreeStyleLayout from 'schema-form/layouts/common-freestyle-layouts/MinimalSearchRecap';
import {
  markingStatuses,
  markingStatusesToText,
} from 'configs/constants/course';
import { courseLearningTypesMulti } from 'components/admin/group/schema/elements/filterset/filter/schema/courseLearningTypes';

const schema = (forRecap = false, forAdvanceSearch = false, props) => (
  formid,
  values,
  step,
  xpath,
  domainInfo,
) => {
  let statusOptions = constants.StatusOptions();
  let defaultStatuses = lodashGet(props, 'defaultStatuses') || [
    'queued',
    'approved',
  ];

  if (Array.isArray(values.exclude_statuses)) {
    statusOptions = statusOptions.filter(
      (opt) => !values.exclude_statuses.includes(lodashGet(opt, 'value')),
    );
    defaultStatuses = defaultStatuses.filter(
      (opt) => !values.exclude_statuses.includes(lodashGet(opt, 'value')),
    );
  }

  let element = {
    status: {
      type: 'multiCheckbox',
      options: statusOptions,
      inline: true,
      defaultValue: defaultStatuses,
      floatingLabelText: t1('status'),
      floatingLabelFixed: false,
      classWrapper: forRecap ? false : 'col-md-6',
    },
    marking_status: {
      type: 'multiCheckbox',
      floatingLabelText: t1('marking_status'),
      options: Object.values(markingStatuses).map((status) => ({
        value: status,
        label: markingStatusesToText(status),
      })),
      inline: true,
      classWrapper: forRecap ? false : 'col-md-6',
    },
    learning_type: courseLearningTypesMulti(),
    organizations: organizations({
      formid,
      label: `${t1('organizations')} (*)`,
      defaultValue: props.orgIids,
      fullWidth: true,
      validate: [
        validationWithCondition(required(), values.requireOrganization),
      ],
    }),
    include_sub_organizations: includeSubOrganizations(domainInfo.conf),
    include_items_from_ancestor_organizations: {
      type: 'checkbox',
      label: t1('include_courses_from_ancestor_organizations'),
      defaultValue: 1,
    },
    academic_categories: academicCategories(formid, {
      label: `${t1('academic_categories')}`,
    }),
    public: {
      type: 'checkbox',
      floatingLabelText: t1('is_public_course'),
      label: t1('is_public_course'),
      inline: true,
      defaultValue: 0,
    },
  };

  if (forAdvanceSearch) {
    if (forRecap) {
      return addPropsToEverySchemaElements(element, {
        elementDisplayMode: elementDisplayModes.RECAP,
      });
    }
  } else {
    element = {
      name: {
        type: 'text',
        floatingLabelText: t1('name'),
        floatingLabelFixed: false,
        errorText: '',
        fullWidth: true,
      },
      ...element,
    };
  }

  return element;
};

const ui = (forAdvanceSearch = false) => (
  step,
  values,
  themeConfig,
  xpath,
  formid,
  props,
  mode,
  domainInfo,
) => {
  const isRoot = lodashGet(domainInfo, 'isRoot');
  let fields = [];
  if (!forAdvanceSearch) {
    fields.push('name');
  }

  if (window.isETEP && isRoot) {
    fields.push('public');
  }

  if (hasOrganization(domainInfo))
    fields.push(
      'organizations',
      'include_sub_organizations',
      'include_items_from_ancestor_organizations',
    );

  if (!(values && values.type && values.type === 'syllabus_exam')) {
    if (
      hasAcademicCategories(domainInfo) &&
      !lodashGet(window, 'hideOnFormSearch.academic_categories')
    )
      fields.push('academic_categories');
  }

  const supportedLearningMethods = lodashGet(
    domainInfo,
    'school.supported_learning_methods',
  );

  if (
    supportedLearningMethods &&
    supportedLearningMethods.length > 1 &&
    !lodashGet(window, 'hideOnFormSearch.learning_type')
  ) {
    fields.push('learning_type');
  }

  fields.push('status');

  if (window.isETEP) {
    fields.push('marking_status');
  }

  return [
    {
      id: 'id', // you still have to have this id even for freestyle
      fields,
    },
  ];
};

const layout = () => {
  return {
    component: SearchFormDetailFreestyleEnterprise,
    freestyle: 1,
  };
};

const getSchema = (forRecap = false, forAdvance = false, props = {}) => ({
  schema: schema(forRecap, forAdvance, props),
  ui: ui(forAdvance),
  layout: forAdvance
    ? forRecap
      ? {
          freestyle: 1,
          component: MinimalSearchRecapFreeStyleLayout,
        }
      : commonFormLayouts.DEFAULT
    : layout,
});

export const searchFormSchema = (props = {}) => getSchema(false, true, props);
export const searchRecapFormSchema = (props = {}) =>
  getSchema(true, true, props);

export default getSchema();
