import { put, call, select, takeEvery } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';
import Requester from 'common/network/http/Request';
import actions from 'actions/node/creators';
import { downloadFileFromUrl } from 'common/utils/File';

function* exportData(action) {
  let { url, params, formid, onRequestSuccessful, fileName = '' } = action;
  if (!params && formid) {
    params = yield select(getFormValues(formid));
  }

  try {
    const data = yield call(Requester.post, url, params);

    if (data.success && data.objects && data.objects.url) {
      if (onRequestSuccessful && typeof onRequestSuccessful == 'function') {
        onRequestSuccessful(data);
      }

      downloadFileFromUrl({
        downloadUrl: data.objects.url,
        fileName,
      });
    } else {
      yield put(actions.snackbar('error', data.message));
      console.log('Export failed');
    }
  } catch (e) {
    console.log(e);
  }
}

export default function* exportDataSaga() {
  yield takeEvery('EXPORT_DATA_REQUEST', exportData);
}
