import './stylesheet.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isSmallScreen } from 'common';
import { t1 } from 'translate';
import lodashGet from 'lodash.get';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import { Table } from 'antd';
import QuickConfig from 'components/common/antd/table/config-column';
import { v4 } from 'uuid';

const getClassNameByType = (item) => {
  let className = '';
  switch (item.type) {
    case TYPE_OF_TABLE_DATA.TEXT:
      return (className = 'text-left');
    case TYPE_OF_TABLE_DATA.NUMBER:
      return (className = 'text-right');
    case TYPE_OF_TABLE_DATA.DATE:
    case TYPE_OF_TABLE_DATA.ACTION:
    case TYPE_OF_TABLE_DATA.TITLE:
      return (className = 'text-center');
    default:
      return (className = 'text-left');
  }
};

const standardColumns = (columns = []) => {
  const columnsStandard = [];
  if (!columns || !columns.length) {
    return [];
  }

  columns.forEach((column) => {
    const hasChildren = column.children && column.children.length;

    const defaultProps = {
      id: v4(),
      visible: true,
    };

    columnsStandard.push({
      ...defaultProps,
      ...column,
      children: hasChildren && standardColumns(column.children),
      className: `${
        column.className ? column.className : ''
      } ${getClassNameByType(column)}`,
    });
  });

  return columnsStandard;
};

const filterVisibleColumns = (columns = [], visibleColumnIds) => {
  const columnsVisible = [];
  if (!columns || !columns.length) {
    return [];
  }

  if (!visibleColumnIds || !visibleColumnIds.length) {
    return columns;
  }

  columns.forEach((column) => {
    const hasChildren = column.children && column.children.length;
    const hasRender = !!column.render;
    const hasChildrenVisible =
      hasChildren &&
      filterVisibleColumns(column.children, visibleColumnIds).length;

    if (
      visibleColumnIds.includes(column.id) &&
      (hasChildrenVisible || hasRender)
    ) {
      columnsVisible.push({
        ...column,
        children:
          hasChildrenVisible &&
          filterVisibleColumns(column.children, visibleColumnIds),
      });
    }
  });

  return columnsVisible;
};

const CommonAntdTable = ({
  disabled,
  className,
  columns,
  showColumnConfig,
  defaultVisibleColumnIds,
  onChangeVisibleColumns,
  ...props
}) => {
  const [defaultColumns, setDefaultColumns] = useState([]);
  const [tableColumns, setTableColumns] = useState();

  const { stt, searchValues, sttProps, locale } = props;

  const sttCol = useMemo(
    () => {
      const page = lodashGet(searchValues, 'page') || 0;
      const itemsPerPage = lodashGet(searchValues, 'items_per_page') || 10;

      return {
        title: '#',
        key: 'stt',
        width: 20,
        className: 'text-center stt',
        ...(sttProps || {}),
        render: (text, item, idx) => (
          <div>{(page - 1) * itemsPerPage + idx + 1}</div>
        ),
      };
    },
    [searchValues, sttProps],
  );

  useEffect(
    () => {
      let newColumns = standardColumns(columns);

      if (stt && columns && searchValues) {
        newColumns = [sttCol, ...newColumns];
      }

      if (newColumns.length !== defaultColumns.length) {
        setDefaultColumns(newColumns);
      }

      if (defaultVisibleColumnIds && defaultVisibleColumnIds.length) {
        const visibleColumns = filterVisibleColumns(
          newColumns,
          defaultVisibleColumnIds,
        );
        setTableColumns(visibleColumns);
      } else {
        setTableColumns(newColumns);
      }
    },
    [
      columns,
      defaultVisibleColumnIds,
      defaultColumns,
      searchValues,
      stt,
      sttCol,
    ],
  );

  const changeVisibleColumns = useCallback(
    (columns, columnIds) => {
      setTableColumns(columns);

      if (typeof onChangeVisibleColumns === 'function') {
        onChangeVisibleColumns(columns, columnIds);
      }
    },
    [onChangeVisibleColumns],
  );

  const cssClass = 'common-antd-table learn-default';

  return (
    <>
      {showColumnConfig ? (
        <div className="m-b-25 text-left">
          <QuickConfig
            columns={defaultColumns}
            changeVisibleColumns={changeVisibleColumns}
          />
        </div>
      ) : null}

      <Table
        className={`${className || ''} ${cssClass} ${
          disabled ? `${cssClass}--disabled` : ''
        }`}
        scroll={{
          x: isSmallScreen,
        }}
        columns={tableColumns}
        locale={locale || { emptyText: t1('no_data') }}
        pagination={false}
        {...props}
      />
    </>
  );
};

export default CommonAntdTable;
