import React from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import withUserInfo from 'common/hoc/withUserInfo';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import endpoints from 'components/bdtx/module/endpoints';
import Results from 'components/bdtx/training-phase/steps/common/choose-module-on-dialog/search-module/Results';
import schema from 'components/bdtx/training-phase/steps/common/choose-module-on-dialog/search-module/schema';

const FORM_ID = 'module_search';

const SearchModule = ({
  userInfo,
  hiddenFields,
  onSelectModules,
  targetUser,
  cap,
  closeDialog,
}) => {
  const renderResultComponent = (items) => {
    return (
      <Results
        items={items}
        userInfo={userInfo}
        onSelectModules={onSelectModules}
        targetUser={targetUser}
        cap={cap}
        closeDialog={closeDialog}
        searchFormId={FORM_ID}
      />
    );
  };

  const renderNoResultComponent = () => {
    return <SimpleNoResult text="Chưa có mô đun" />;
  };

  return (
    <>
      <SearchWrapper
        ntype="module"
        formid={FORM_ID}
        renderResultsComponent={renderResultComponent}
        renderNoResultComponent={renderNoResultComponent}
        alternativeApi={endpoints.search_module}
        schema={schema}
        userInfo={userInfo}
        hiddenFields={hiddenFields}
        autoSearchWhenStart
      />
    </>
  );
};

export default withUserInfo(SearchModule);
