import React from 'react';
import { t1 } from 'translate';
import './style.scss';

const PassOrFail = ({
  status = false,
  showIcon = true,
  showLabel = true,
  size,
}) => {
  const wrapperClassName = `d-inline-flex align-items-center pass-or-fail ${
    size ? size : ''
  }`;
  return status ? (
    <span className={`${wrapperClassName} text-primary passed`}>
      {showIcon && <span className={`ve-check ${showLabel ? 'm-r-5' : ''}`} />}
      {showLabel && <span>{t1('passed')}</span>}
    </span>
  ) : (
    <span className={`${wrapperClassName} failed`}>
      {showIcon && (
        <span className={`ve-close-outline ${showLabel ? 'm-r-5' : ''}`} />
      )}
      {showLabel && <span>{t1('fail')}</span>}
    </span>
  );
};

export default PassOrFail;
