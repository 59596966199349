import React, { useState } from 'react';
import Radio from 'antd/lib/radio';
import { Alert } from 'antd';
import { getProjectOfCurrentUser, onSaveProject } from './utils';
import {
  APPLICABLE_PROJECT,
  applicableProjectOptions,
} from 'configs/constants/project';
import WarningModuleNN from 'components/common/switch-project/WarningModuleNN';

const ChooseProject = ({ closeDialog }) => {
  const projectConfig = getProjectOfCurrentUser();
  const [defaultProject, setDefaultProject] = useState(
    projectConfig || APPLICABLE_PROJECT.ETEP,
  );

  const projectOptions = applicableProjectOptions();
  const onChangeProject = (value) => {
    setDefaultProject(value);
  };

  const saveProject = () => {
    onSaveProject(defaultProject, closeDialog);
  };

  const shouldDisabledButtonSave =
    !defaultProject || defaultProject === projectConfig;

  return (
    <>
      <Alert
        message="Thầy/Cô vui lòng chọn chương trình đào tạo. Sau khi chọn, toàn bộ dữ liệu sẽ theo chương trình đã chọn. Sau khi chọn, thầy/cô có thể thay đổi chương trình bằng cách nhấn vào thanh thông báo"
        showIcon
        type="info"
      />

      <WarningModuleNN />

      <div className="text-center m-t-30">
        <Radio.Group
          options={projectOptions}
          defaultValue={defaultProject}
          onChange={(event) => onChangeProject(event.target.value)}
        />

        <div className="m-t-30">
          <button
            className="btn btn-primary"
            disabled={shouldDisabledButtonSave}
            onClick={saveProject}
          >
            Lưu lại
          </button>
        </div>
      </div>
    </>
  );
};

export default ChooseProject;
