import { t1 } from 'translate';

export const markingStatuses = {
  MARKING_STATUS_DOING: 'teacher_doing',
  MARKING_STATUS_TEACHER_FINISHED: 'teacher_finished',
  MARKING_STATUS_MASTER_APPROVED: 'master_approved',
  MARKING_STATUS_MASTER_REJECTED: 'master_rejected',
};

export const supportPlanStatuses = {
  SUPPORT_PLAN_MASTER_APPROVED: 'master_approved',
  SUPPORT_PLAN_MASTER_REJECTED: 'master_rejected',
};

export const markingStatusesToText = (status) => {
  return (
    {
      [markingStatuses.MARKING_STATUS_DOING]: t1('core_teacher_is_supporting'),
      [markingStatuses.MARKING_STATUS_TEACHER_FINISHED]: t1(
        'core_teacher_done_supporting',
      ),
      [markingStatuses.MARKING_STATUS_MASTER_APPROVED]: t1(
        'core_teacher_educator_core_principal_advisor_educator_approved',
      ),
      [markingStatuses.MARKING_STATUS_MASTER_REJECTED]: t1(
        'core_teacher_educator_core_principal_advisor_educator_rejected',
      ),
    }[status] || 'Đang hỗ trợ'
  );
};
