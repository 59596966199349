import React from 'react';
import DataFetchHook from 'common/custom-hook/fetch-data-hook';
import { t1 } from 'translate';

/**
 *
 * @param {*} dataInput
 * onRequestSuccess : action run afterfetch successful with 2 param in result and sent params
 * params: params putting in
 * url: url putting in
 */

const RequestButton = (dataInput) => {
  const [
    requestData,
    updateEndpointRequestData,
    updateParamHookRequestData,
  ] = DataFetchHook(
    dataInput.url,
    '',
    t1('request_button_successful'),
    t1('request_button_have_problems'),
    t1('request_button_failed'),
    dataInput.onRequestSuccess,
  );

  const onSubmitHandle = (data) => {
    updateParamHookRequestData(dataInput.params);
  };

  return (
    <button
      className={
        'btn ant-btn ant-btn-primary btn-secondary ' + dataInput.className
      }
      onClick={onSubmitHandle}
      style={dataInput.style}
    >
      {dataInput.label}
    </button>
  );
};

export default RequestButton;
