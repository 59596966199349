import React from 'react';
import Perm from 'common/utils/Perm';
import get from 'lodash.get';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import endpoints from 'components/bdtx/migrate-etep-data/endpoints';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import schema from './schema';
import TableResult from './TableResult';
import withUserInfo from 'common/hoc/withUserInfo';

const FORM_ID = 'migration-form';

const MigrateForm = ({ userInfo }) => {
  const renderResultsComponent = (items) => {
    return <TableResult items={items} searchFormId={FORM_ID} />;
  };

  const renderNoResultComponent = () => {
    return <SimpleNoResult text="Không có thông tin kết chuyển dữ liệu" />;
  };

  const hiddenFields = {};
  if (!Perm.hasPerm('root')) {
    hiddenFields['organization_iid'] = get(userInfo, 'user_organizations.0');
  }

  return (
    <SearchWrapper
      formid={FORM_ID}
      alternativeApi={endpoints.list_migrate}
      schema={schema}
      autoSearchWhenStart
      autoSearchWhenValuesChange
      showSearchButton={false}
      renderResultsComponent={renderResultsComponent}
      renderNoResultComponent={renderNoResultComponent}
      hiddenFields={hiddenFields}
    />
  );
};

export default withUserInfo(MigrateForm);
