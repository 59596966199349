import React from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import schema from './schema';
import lodashGet from 'lodash.get';
import get from 'lodash.get';
import Warning from 'components/common/Warning';

import PlannedUserManager from './PlannedUserManager';

const renderResultComponent = () => {
  return <div>result</div>;
};

const renderNoResultComponent = (props, searchValues) => {
  const trainingPlanIid = lodashGet(searchValues, 'training_plan');

  let contentDisplay;
  if (trainingPlanIid) {
    contentDisplay = <PlannedUserManager trainingPlanIid={trainingPlanIid} />;
  } else {
    contentDisplay = <Warning>Chọn đợt tập huấn trước</Warning>;
  }

  return <div className="whitebox">{contentDisplay}</div>;
};

const CotCanPlannedUsers = ({ node }) => {
  const formid = 'bdtx-cot-can-planned-users';
  const trainingPhaseIid = get(node, 'iid');

  return (
    <div className="container-training-plan-quota">
      <SearchWrapper
        formid={formid}
        schema={schema(trainingPhaseIid)}
        renderResultsComponent={renderResultComponent}
        renderNoResultComponent={renderNoResultComponent}
        alternativeApi={'/site/api/dev-null'}
        destroyOnUnmount
        autoSearchWhenValuesChange
        showSearchButton={false}
      />
    </div>
  );
};

export default CotCanPlannedUsers;
