import React from 'react';
import get from 'lodash.get';
import { t1 } from 'translate';
import DetailOnDialog from 'components/common/detail-on-dialog';
import AttachmentViewer from 'components/common/forms/questions/open-ended/attachment-viewer';

const ViewComment = ({ comment }) => {
  const content = get(comment, 'content');
  const attachments = get(comment, 'attachments');

  const hasContent = !!content;
  const hasAttachments = attachments && attachments.length;

  if (!hasContent && !hasAttachments) {
    return null;
  }

  const renderPreview = ({ showFull }) => {
    return (
      <>
        <span>{t1('marker_comment')}.</span>
        <span className="link m-l-5" onClick={showFull}>
          {t1('click_this_to_view_comment')}
        </span>
      </>
    );
  };

  const renderFull = () => {
    return (
      <>
        {hasContent && (
          <div className="m-b-25">
            <h3>{t1('content')}</h3>
            <div>{content}</div>
          </div>
        )}

        {hasAttachments && (
          <div>
            <h3>{t1('marking_feedback_attachments')}</h3>
            <AttachmentViewer attachments={attachments} />
          </div>
        )}
      </>
    );
  };

  return (
    <DetailOnDialog
      renderPreview={renderPreview}
      renderFull={renderFull}
      dialogOptionsProperties={{
        title: t1('marker_comment'),
        modal: true,
        width: '60%',
      }}
    />
  );
};

export default ViewComment;
