import { userGrades } from 'configs/constants/user';

export const formatDataByGrades = (dataSource) => {
  if (!dataSource || !dataSource.length) {
    return;
  }

  const grades = Object.keys(userGrades).map((key) => userGrades[key]);

  const data = {};

  grades.forEach((grade) => {
    const dataByGrade = dataSource.find((item) => item.cap === grade);
    if (dataByGrade && !data[grade]) {
      data[grade] = dataByGrade.modules;
    }
  });

  return data;
};

export const VIEW_TYPE = {
  PURCHASED: 'purchased',
  CHOOSE_MATERIAL: 'choose-material',
  PUBLIC: 'public-material',
};
