import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getSchool } from 'utils/selectors';
import lodashGet from 'lodash.get';
import ReactHelmet from 'react-helmet';
import { stripHTML } from 'common/utils/string';

function Helmet({ seo, title, image, description, withSiteName = true, logo }) {
  const siteName = lodashGet(seo, 'site_name');
  const websiteTitle = lodashGet(seo, 'website_title');
  const siteDescription = description
    ? description
    : lodashGet(seo, 'website_description');

  return (
    <ReactHelmet>
      <title>
        {title
          ? withSiteName
            ? `${title} - ${siteName}`
            : title
          : websiteTitle}
      </title>
      {siteDescription && (
        <meta name="description" content={stripHTML(siteDescription)} />
      )}
      <meta name="image" content={image || logo} />
    </ReactHelmet>
  );
}

const mapStateToProps = (state) => ({
  seo: lodashGet(getSchool(state), 'seo'),
  logo: lodashGet(getSchool(state), 'theme.logo'),
});

export default connect(mapStateToProps)(Helmet);

Helmet.propTypes = {
  description: PropTypes.string,
  image: PropTypes.any,
  logo: PropTypes.any,
  seo: PropTypes.object,
  title: PropTypes.any,
  withSiteName: PropTypes.bool,
};

Helmet.defaultProps = {
  withSiteName: true,
};
