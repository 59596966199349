import text from './Text';

/**
 * @param hintText
 * @param floatingLabelText
 * @param floatingLabelFixed
 * @param defaultValue
 * @param errorText
 * @param validate
 * @param normalize
 * @param onChange
 * @param classWrapper
 * @param fullWidth
 * @param readOnly
 * @param inline
 * @param rest
 * @returns {{type, inputType: string, hintText: *, floatingLabelText: *, floatingLabelFixed: *, defaultValue: *, errorText: *, validate: *, normalize: *, onChange: *, classWrapper: *, fullWidth: *, readOnly: *, inline: *}}
 * @constructor
 */
const Number = ({
  hintText,
  floatingLabelText,
  floatingLabelFixed,
  defaultValue,
  errorText,
  validate,
  normalize,
  onChange,
  classWrapper,
  fullWidth,
  readOnly,
  inline,
  isRequired,
  ...rest
}) => {
  return {
    type: 'number',
    hintText,
    floatingLabelText,
    floatingLabelFixed,
    defaultValue,
    errorText,
    validate,
    normalize,
    onChange,
    classWrapper,
    fullWidth,
    readOnly,
    inline,
    isRequired,
    ...rest,
  };

  return;
  text({
    inputType: 'number',
    hintText,
    floatingLabelText,
    floatingLabelFixed,
    defaultValue,
    errorText,
    validate,
    normalize,
    onChange,
    classWrapper,
    fullWidth,
    readOnly,
    inline,
    isRequired,
    ...rest,
  });
};

export default Number;
