import { init as initApm } from '@elastic/apm-rum';
import { isEnableTemis } from 'components/temis/utils';

export default () => {
  if (
    process.env.NODE_ENV === 'development' ||
    (window && window.APP_SKIP_TRACER)
  ) {
    return;
  }

  initApm({
    // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    serviceName: isEnableTemis() ? 'temis' : 'taphuan',

    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl: 'https://taphuan-monitoring.csdl.edu.vn:8443',

    // Set service version (required for source map feature)
    serviceVersion: '',
  });
};
