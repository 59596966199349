import { t1 } from 'translate';

const lname = (props = {}) => ({
  type: 'text',
  floatingLabelText: t1('user_login_name'),
  hintText: t1('user_login_name'),
  fullWidth: true,
  ...props,
});

export default lname;
