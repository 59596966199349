import get from 'lodash.get';

export const enterMissingData = () => `/enter-missing-data`;

export const doesUserMissingTrainingGradesInRegistration = (userInfo) => {
  return get(userInfo, 'missing_training_grades_in_registration');
};

export const doesUserMissingIdentificationCard = (userInfo) => {
  return get(userInfo, 'missing_identification_card');
};

export const doesUserMissingData = (userInfo) => {
  return (
    doesUserMissingTrainingGradesInRegistration(userInfo) ||
    doesUserMissingIdentificationCard(userInfo)
  );
};
