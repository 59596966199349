import { fork } from 'redux-saga/effects';

import forgotPassword from './forgotPassword';
import resetPasswordForgotten from './resetPasswordForgotten';

const forgotPasswordSagas = [
  fork(forgotPassword),
  fork(resetPasswordForgotten),
];

export default forgotPasswordSagas;
