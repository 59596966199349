import React from 'react';
import PropTypes from 'prop-types';

const ZoomIn = ({
  zoomInClass,
  scale,
  defaultScale,
  maxScale,
  handleZoomIn,
}) => {
  const checkScale = Math.max(maxScale, defaultScale);
  const isDisabled = scale.toFixed(2) === checkScale.toFixed(2);

  return (
    <button
      type="button"
      className={`button-zoom m-l-2 m-r-2 ${zoomInClass || ''}`}
      disabled={isDisabled}
      onClick={handleZoomIn}
    >
      <span className="icon">
        <i className="material-icons">zoom_in</i>
      </span>
    </button>
  );
};

ZoomIn.propTypes = {
  zoomInClass: PropTypes.string,
  scale: PropTypes.number.isRequired,
  defaultScale: PropTypes.number.isRequired,
  maxScale: PropTypes.number.isRequired,
  handleZoomIn: PropTypes.func.isRequired,
};

export default ZoomIn;
