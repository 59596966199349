import React from 'react';
import './style.scss';
import Heading from '../../common/Heading';
import FlippingCard from './flippingCard';

const FlashcardBlock = ({ item }) => {
  const card = [
    {
      font: {
        title: 'Yourself',
        content:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      },
      back: {
        title: 'The next course lessons ',
        content: [
          'Lorem Ipsum is simply dummy text of the printing  1',
          'Lorem Ipsum is simply' + ' dummy text of the printing  2',
          'Lorem Ipsum is simply dummy text of the printing  3',
        ],
      },
    },
    {
      font: {
        title: 'Others',
        content:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      },
      back: {
        title: 'a few of the key differences',
        content: [
          'Lorem Ipsum is simply dummy text of the printing  1',
          'Lorem Ipsum is simply' + ' dummy text of the printing  2',
          'Lorem Ipsum is simply dummy text of the printing  3',
        ],
      },
    },
    {
      font: {
        title: 'A Competitive Advantage',
        content:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      },
      back: {
        title: 'back',
        content: [
          'Lorem Ipsum is simply dummy text of the printing  1',
          'Lorem Ipsum is simply' + ' dummy text of the printing  2',
          'Lorem Ipsum is simply dummy text of the printing  3',
        ],
      },
    },
    {
      font: {
        title: 'A Competitive Advantage',
        content:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      },
      back: {
        title: 'back',
        content: [
          'Lorem Ipsum is simply dummy text of the printing  1',
          'Lorem Ipsum is simply' + ' dummy text of the printing  2',
          'Lorem Ipsum is simply dummy text of the printing  3',
        ],
      },
    },
    {
      font: {
        title: 'A Competitive Advantage',
        content:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      },
      back: {
        title: 'back',
        content: [
          'Lorem Ipsum is simply dummy text of the printing  1',
          'Lorem Ipsum is simply' + ' dummy text of the printing  2',
          'Lorem Ipsum is simply dummy text of the printing  3',
        ],
      },
    },
    {
      font: {
        title: 'A Competitive Advantage',
        content:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      },
      back: {
        title: 'back',
        content: [
          'Lorem Ipsum is simply dummy text of the printing  1',
          'Lorem Ipsum is simply' + ' dummy text of the printing  2',
          'Lorem Ipsum is simply dummy text of the printing  3',
        ],
      },
    },
    {
      font: {
        title: 'A Competitive Advantage adasdadas',
        content:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      },
      back: {
        title: 'back',
        content: [
          'Lorem Ipsum is simply dummy text of the printing  1',
          'Lorem Ipsum is simply' + ' dummy text of the printing  2',
          'Lorem Ipsum is simply dummy text of the printing  3',
        ],
      },
    },
    {
      font: {
        title: 'font1',
        content:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      },
      back: {
        title: 'back',
        content: [
          'Lorem Ipsum is simply dummy text of the printing  1',
          'Lorem Ipsum is simply' + ' dummy text of the printing  2',
          'Lorem Ipsum is simply dummy text of the printing  3',
        ],
      },
    },
    {
      font: {
        title: 'font1',
        content:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      },
      back: {
        title: 'back',
        content: [
          'Lorem Ipsum is simply dummy text of the printing  1',
          'Lorem Ipsum is simply' + ' dummy text of the printing  2',
          'Lorem Ipsum is simply dummy text of the printing  3',
        ],
      },
    },
  ];
  return (
    <div className="flipping-card-container">
      <Heading item={item} />
      <div className="container" id="flipping-card">
        <FlippingCard card={card} />
      </div>
    </div>
  );
};

export default FlashcardBlock;
