import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import lodashGet from 'lodash.get';
import { timestampToDateTimeString } from 'common/utils/Date';
import CourseSearchResultActions from 'components/admin/course/common/CourseSearchResultActions';
import { populateRowSpanInfoToRenderListOfItemAsTable } from 'common/utils/Array';
import { t1 } from 'translate';
import sagaActions from 'actions/node/saga-creators';
import { fetchEnrolmentPlanData } from 'components/admin/enrolment-plan/common';
import { showInTable } from 'common/utils/antd';
import commonSagaActions from 'actions/saga-creators';
import apiUrls from '../endpoints';
import { submit } from 'redux-form';
import Icon from 'components/common/Icon';
import RaisedButton from 'components/common/mui/RaisedButton';
import StaffList from 'components/common/staff-list';
import routes from 'routes';
import { Link } from 'react-router-dom';
import item from 'components/front-end/dashboard/courses/in-progress/item';
import PassedRatioInResultTable from 'components/admin/course/common/PassedRatioInResultTable';
import MarkingRatioInResultTable from '../common/MarkingRatioInResultTable';
import Menu from 'antd/lib/menu';
import DetailOnDialog from 'components/common/detail-on-dialog';
import SplitCourse from './split-course';
import Dialog from '../../user-abac-role/staff/search/remove/dialog';
import actions from 'actions/node/creators';
import aApiUrls from '../../abac/abac-role/endpoints';
import styled from 'styled-components';
import Popover from 'antd/lib/popover';
import ApiRequestBtnWithConfirmDialog from 'components/common/action-button/ApiRequestBtnWithConfirmDialog';
import Warning from 'components/common/Warning';
import CommonAntdTable from 'components/common/antd/table';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import { markingStatusesToText } from 'configs/constants/course';
import { createSelector } from 'reselect';
import ExportBtnWithConfirmDialog from '../../../common/action-button/ExportBtnWithConfirmDialog';
import ImportCourseButton from '../../enrolment-plan/mainstage/courses/import/ImportButton';
import taphuanDaiTraLearningApiUrls from 'components/admin/taphuan-dai-tra-learning/endpoints';
import ShowListMore from 'components/common/show-list-more';

const ApproveToggleMenu = styled(Menu.Item)`
  > div {
    justify-content: flex-end;
  }
`;

export const columnKeys = {
  COURSE: 'course',
  ENROLMENT_PLAN: 'enrolment_plan',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  PASSED_STUDENTS: 'passed_students',
  MARKING_NOT_YET_MARKED: 'marking_not_yet_marked',
  MARKING_STATUS: 'marking_status.status',
  STAFF: 'staff',
  ORGANIZATIONS: 'organizations',
  STATUS: 'status',
  VIEW: 'view',
  ACTIONS: 'actions',
  CREATED_DATE: 'created_date',
  CREATED_USER: 'created_user',
};

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coursesName: {},
      selectedRowKeys: [],
    };
  }

  componentDidMount() {
    const { items } = this.props;
    let coursesName = {};

    if (items) {
      populateRowSpanInfoToRenderListOfItemAsTable(items, [
        'credit_syllabus',
      ]).map((item) => {
        coursesName = {
          ...coursesName,
          [item.iid]: item.name,
        };
      });
      this.setState({
        coursesName,
      });
    }
  }

  actionToggleReadOnlyLabelSet = { on: 'approved', off: 'queued' };
  actionToggleDataSet = { on: 1, off: 0 };

  deepCloneSuccessFul = () => {
    const { dispatch, formid } = this.props;
    dispatch(submit(formid));
  };

  handleExport = () => {
    const { selectedRowKeys } = this.state;
    const { dispatch } = this.props;
    dispatch(
      commonSagaActions.exportDataRequest({
        url: apiUrls.export_score_of_courses_selected,
        params: { ids: selectedRowKeys },
      }),
    );
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDeleteStaffClick = (userInfo, course) => {
    const { dispatch, formid } = this.props;
    const contentDialog = (
      <Dialog
        onRemoveButtonClick={() =>
          dispatch(
            sagaActions.submitFormRequest('', {
              extraParams: {
                userIid: userInfo.iid,
                applied_target_iid: course.iid,
                _sand_step: 'resource_staff',
                ntype: course.ntype,
              },
              url: aApiUrls.user_abac_roles_delete,
              formidToSubmitOnSuccess: formid,
              closeModal: true,
            }),
          )
        }
        onCancelButtonClick={() => {
          dispatch(actions.handleOpenDialog({ openDialog: false }));
        }}
        item={userInfo}
        node={course}
      />
    );
    const optionsProperties = {
      handleClose: true,
    };

    dispatch(actions.handleOpenDialog({ contentDialog, optionsProperties }));
  };

  render() {
    const {
      items,
      node,
      trainingPlan,
      searchValues,
      searchFormId,
      columnsNotToShow,
      blackListActions,
      disableChangeCourseName,
      isViewingEp,
      showDeleteStaff,
      showStaffAsTag,
      showSplitAction,
      showSplitBySchoolGroupAction,
      formid,
      showDelete,
      didSplitCourse,
      onlyGetListLicensedUsers,
      showExportCourseButton = false,
      showImportCourseButton = false,
    } = this.props;

    let { showExportCourseStudents } = this.props;

    // tạm thời ẩn nút này đi do logic đang bị sai
    showExportCourseStudents = false;

    const { coursesName, selectedRowKeys } = this.state;
    const idInCurrentPage = Array.isArray(items)
      ? items.map(({ id }) => id)
      : [];

    const width = {
      iid: '10%',
      credit_syllabus: '10%',
      course: '23%',
      organizations: '12%',
      learning_type: '10%',
      start_date: '8%',
      end_date: '8%',
      created_date: '8%',
      created_user: '7%',
      students: '9%',
      status: '10%',
      action: '5%',
      approved: '5%',
      delete: '5%',
    };

    const columns = [
      {
        title: t1('iid'),
        render: (item) => lodashGet(item, 'iid'),
      },
      {
        title: t1('course'),
        key: columnKeys.COURSE,
        width: width.course,
        type: TYPE_OF_TABLE_DATA.TEXT,
        render: (item) => (
          <div>
            <span>
              <Link
                to={routes.url('node_edit', {
                  ...item,
                  step: 'dashboard',
                })}
                {...(isViewingEp ? { target: '_blank' } : {})}
              >
                {lodashGet(coursesName, item.iid) || (item && item.name)}
              </Link>
            </span>
            <div className="text-muted">{item.code}</div>
          </div>
        ),
      },
      ...(!isViewingEp && lodashGet(item, 'enrolment_plan_name')
        ? [
            {
              title: t1('enrolment_plan'),
              key: columnKeys.ENROLMENT_PLAN,
              dataIndex: 'enrolment_plan_name',
              type: TYPE_OF_TABLE_DATA.TEXT,
            },
          ]
        : []),
      {
        title: t1('start_date'),
        key: columnKeys.START_DATE,
        type: TYPE_OF_TABLE_DATA.DATE,
        dataIndex: 'start_date',
        width: width.start_date,
        render: (startDate) =>
          timestampToDateTimeString(startDate, { type: 'long_date' }),
      },
      {
        title: t1('end_date'),
        key: columnKeys.END_DATE,
        dataIndex: 'end_date',
        width: width.end_date,
        type: TYPE_OF_TABLE_DATA.DATE,
        render: (endDate) =>
          timestampToDateTimeString(endDate, { type: 'long_date' }),
      },
      {
        title: t1('created_user'),
        type: TYPE_OF_TABLE_DATA.TEXT,
        width: width.created_user,
        render: (item) => lodashGet(item, 'u.name', ''),
      },
      {
        title: t1('created_date'),
        type: TYPE_OF_TABLE_DATA.DATE,
        width: width.created_date,
        render: (item) => {
          const createdDate = lodashGet(item, 'ts');
          return timestampToDateTimeString(createdDate, { type: 'long_date' });
        },
      },
      !onlyGetListLicensedUsers && {
        title: t1('passed_over_total_number_of_course_students'),
        key: columnKeys.PASSED_STUDENTS,
        width: width.students,
        type: TYPE_OF_TABLE_DATA.NUMBER,
        render: (item) => {
          return <PassedRatioInResultTable course={item} />;
        },
      },
      !onlyGetListLicensedUsers && {
        title: t1('number_of_marked_over_number_of_submitted'),
        key: columnKeys.MARKING_NOT_YET_MARKED,
        width: width.students,
        type: TYPE_OF_TABLE_DATA.NUMBER,
        render: (item) => {
          return <MarkingRatioInResultTable course={item} />;
        },
      },
      {
        title: t1('marking_status'),
        key: columnKeys.MARKING_STATUS,
        width: width.students,
        type: TYPE_OF_TABLE_DATA.TEXT,
        render: (item) => {
          const markingStatus = lodashGet(
            item,
            'marking_status.status',
            'teacher_doing',
          );
          return markingStatusesToText(markingStatus);
        },
      },
      {
        title: t1('staff'),
        key: columnKeys.STAFF,
        type: TYPE_OF_TABLE_DATA.TEXT,
        render: (item) => {
          if (didSplitCourse && !lodashGet(item, 'school_group_id')) {
            return (
              <Warning>
                <b>
                  Lớp này chỉ dành cho học viên thuộc trường chưa được phân cụm
                </b>
              </Warning>
            );
          }

          let staffs = lodashGet(item, 'staff');
          if (!Array.isArray(staffs) || !staffs.length) {
            staffs = lodashGet(item, '__expand.staff_with_roles', []).map(
              ({ user }) => user,
            );
          }

          return (
            <StaffList
              staff={staffs}
              showStaffAsTag={showStaffAsTag}
              showDeleteStaff={
                typeof showDeleteStaff !== 'undefined'
                  ? showDeleteStaff
                  : isViewingEp
              }
              onDeleteStaffClick={(userInfo) =>
                this.onDeleteStaffClick(userInfo, item)
              }
            />
          );
        },
      },
      {
        title: t1('organizations'),
        key: columnKeys.ORGANIZATIONS,
        dataIndex: 'organizations_name',
        width: width.organizations,
        type: TYPE_OF_TABLE_DATA.TEXT,
        render: (orgName) => {
          if (!Array.isArray(orgName) || !orgName.length) {
            return '';
          }

          return <ShowListMore list={orgName} />;
        },
      },
      {
        title: t1('status'),
        key: columnKeys.STATUS,
        dataIndex: 'status',
        width: width.status,
        type: TYPE_OF_TABLE_DATA.TEXT,
        render: (status) => t1(status),
      },
      {
        title: t1('view'),
        key: columnKeys.VIEW,
        width: width.action,
        type: TYPE_OF_TABLE_DATA.ACTION,
        // fixed: 'right',
        render: (item) => {
          if (!isViewingEp) {
            return (
              <Link
                to={routes.url('node_edit', {
                  ...item,
                  step: 'dashboard',
                })}
              >
                <Icon icon={'eye'} title={t1('edit')} antIcon />
              </Link>
            );
          }

          return (
            <CourseSearchResultActions
              deleteSuccessful={() => fetchEnrolmentPlanData(node)}
              blackListActions={blackListActions}
              course={item}
              searchFormId={searchFormId}
              isViewingEp={isViewingEp}
              showAction
              className="text-center"
              managerOnly
            />
          );
        },
      },
      {
        title: t1('actions'),
        key: columnKeys.ACTIONS,
        render: (item) => {
          const splitCourseDialogKey = `split-course-${item.iid}`;
          return {
            props: {
              className: 'text-center',
            },
            children: (
              <Popover
                content={
                  <Menu>
                    {showSplitBySchoolGroupAction &&
                    !lodashGet(item, 'school_group_id') ? (
                      <Menu.Item key="split_by_school_group">
                        <ApiRequestBtnWithConfirmDialog
                          renderComponent={({ onClick }) => {
                            return (
                              <div onClick={onClick}>
                                <Icon type="apartment" antIcon />{' '}
                                {'Tách lớp theo cụm'}
                              </div>
                            );
                          }}
                          url={
                            taphuanDaiTraLearningApiUrls.split_course_by_school_group
                          }
                          params={{
                            course_iid: lodashGet(item, 'iid'),
                          }}
                          formidToSubmitOnSuccess={searchFormId}
                          textConfirm={'Bạn có muốn tách lớp theo cụm?'}
                        />
                      </Menu.Item>
                    ) : null}
                    {showSplitAction && (
                      <Menu.Item key="split">
                        <DetailOnDialog
                          dialogKey={splitCourseDialogKey}
                          renderPreview={({ showFull }) => (
                            <div onClick={showFull}>
                              <Icon type="share-alt" antIcon />{' '}
                              {t1('split_course')}
                            </div>
                          )}
                          renderFull={() => (
                            <SplitCourse
                              course={item}
                              dialogKey={splitCourseDialogKey}
                              searchFormId={searchFormId}
                            />
                          )}
                          dialogOptionsProperties={{
                            title: `${t1('split_course')}: ${item.name}`,
                            handleClose: true,
                          }}
                        />
                      </Menu.Item>
                    )}
                    <ApproveToggleMenu key="approved">
                      <CourseSearchResultActions
                        deleteSuccessful={() => fetchEnrolmentPlanData(node)}
                        blackListActions={blackListActions}
                        course={item}
                        searchFormId={searchFormId}
                        isViewingEp={isViewingEp}
                        showAction
                        className="text-center"
                        approvedOnly
                      />
                    </ApproveToggleMenu>
                    <Menu.Divider />
                    {showDelete ? (
                      <Menu.Item key="delete">
                        <Icon type="delete" antIcon />
                        <CourseSearchResultActions
                          deleteSuccessful={() => fetchEnrolmentPlanData(node)}
                          blackListActions={blackListActions}
                          course={item}
                          searchFormId={searchFormId}
                          isViewingEp={isViewingEp}
                          showDelete
                          textOnly
                        />
                      </Menu.Item>
                    ) : null}
                  </Menu>
                }
                getPopupContainer={() => this.table}
              >
                <Icon type="more" antIcon style={{ fontSize: 22 }} />
              </Popover>
            ),
          };
        },
      },
    ].filter(Boolean);

    const renderExportCourseButton = () => {
      if (!showExportCourseButton) {
        return null;
      }

      // only display this button for ep courses & tp courses
      if (lodashGet(node, 'ntype') !== 'enrolment_plan' && !trainingPlan) {
        return null;
      }

      let fileName = 'DS lớp học';
      let name =
        lodashGet(node, 'ntype') === 'enrolment_plan'
          ? lodashGet(node, 'name')
          : lodashGet(trainingPlan, 'name');

      if (name) {
        fileName = `DS lớp học - ${name}`;
      }

      return (
        <ExportBtnWithConfirmDialog
          className="m-r-10"
          newButton
          primary
          params={searchValues}
          icon={null}
          label={'Xuất Excel'}
          url={'/course/export'}
          fileName={fileName}
        />
      );
    };

    const renderImportCourseButton = () => {
      if (!showImportCourseButton) {
        return null;
      }

      if (lodashGet(node, 'ntype') !== 'enrolment_plan') {
        return null;
      }

      return <ImportCourseButton enrolmentPlanIid={lodashGet(node, 'iid')} />;
    };

    return (
      <div
        className="table-result"
        style={{ width: '100%' }}
        ref={(table) => (this.table = table)}
      >
        <CommonAntdTable
          columns={showInTable(columns, columnsNotToShow)}
          dataSource={populateRowSpanInfoToRenderListOfItemAsTable(items, [
            'credit_syllabus',
          ])}
          className="white-background table-border-round learn-default"
          bordered
          rowKey="id"
          pagination={false}
          childrenColumnName={null}
          rowSelection={
            showExportCourseStudents
              ? {
                  selectedRowKeys,
                  hideDefaultSelections: true,
                  onChange: this.onSelectChange,
                  selections: [
                    {
                      key: 'select_current_page',
                      text: t1('select_data_in_current_page'),
                      onSelect: () => {
                        this.setState((state) => {
                          let currentSelectedRowKeys = lodashGet(
                            state,
                            'selectedRowKeys',
                            [],
                          );
                          currentSelectedRowKeys = currentSelectedRowKeys.concat(
                            idInCurrentPage
                              .map(
                                (id) =>
                                  !currentSelectedRowKeys.includes(id) && id,
                              )
                              .filter(Boolean),
                          );

                          return { selectedRowKeys: currentSelectedRowKeys };
                        });
                      },
                    },
                    {
                      key: 'invert_current_page',
                      text: t1('invert_data_in_current_page'),
                      onSelect: () => {
                        this.setState((state) => {
                          let currentSelectedRowKeys = idInCurrentPage.filter(
                            (id) =>
                              !lodashGet(state, 'selectedRowKeys', []).includes(
                                id,
                              ),
                          );
                          return { selectedRowKeys: currentSelectedRowKeys };
                        });
                      },
                    },
                    Array.isArray(selectedRowKeys) &&
                      !!selectedRowKeys.length &&
                      !selectedRowKeys.every((id) =>
                        idInCurrentPage.includes(id),
                      ) && {
                        key: 'remove_all',
                        text: t1('remove_all_data_selected'),
                        onSelect: () => {
                          this.setState(() => ({ selectedRowKeys: [] }));
                        },
                      },
                  ].filter(Boolean),
                }
              : null
          }
        />
        {showExportCourseStudents ? (
          <RaisedButton
            primary
            disabled={
              !Array.isArray(selectedRowKeys) || !selectedRowKeys.length
            }
            onClick={this.handleExport}
            icon={<Icon icon="export" style={{ color: 'white' }} />}
            label={t1('export_score_of_%s_selected_courses', [
              Array.isArray(selectedRowKeys) ? selectedRowKeys.length : 0,
            ])}
            className="m-t-10"
          />
        ) : null}

        {renderExportCourseButton()}
        {renderImportCourseButton()}
      </div>
    );
  }
}

Results.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  searchFormId: PropTypes.string,
};

Results.defaultProps = {
  items: [],
  searchFormId: '',
  showExportCourseStudents: true,
  showDelete: true,
};

const mapStateToProps = createSelector(
  (state) => lodashGet(state, 'domainInfo.conf.only_get_list_licensed_users'),
  (onlyGetListLicensedUsers) => ({
    onlyGetListLicensedUsers,
  }),
);

export default connect(mapStateToProps)(Results);
