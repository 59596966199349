import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import ViewRenderer from './ViewRenderer';

class SchemaForm extends React.PureComponent {
  render() {
    const { handleSubmit, onFormBlur, ...rest } = this.props;
    return (
      <form
        onSubmit={(e) => {
          e.stopPropagation();
          handleSubmit(e);
        }}
        onBlur={() => {
          if (onFormBlur && typeof onFormBlur === 'function') {
            onFormBlur(this.props.formValues);
          }
        }}
      >
        <ViewRenderer {...rest} dispatch={this.props.dispatch} />
      </form>
    );
  }
}

SchemaForm.propTypes = {
  handleSubmit: PropTypes.func,
};

SchemaForm = reduxForm({
  destroyOnUnmount: false,
})(SchemaForm);

export default SchemaForm;
