import React from 'react';
import PropTypes from 'prop-types';
import NoResult from './NoResult';
import lodashGet from 'lodash.get';

class Results extends React.Component {
  cssClass = '';

  shouldComponentUpdate(nextProps) {
    const props = nextProps || this.props;
    const { resultId, total, resultsRerenderTrigger } = this.props;
    if (props.ignoreOptimize) {
      return true;
    }

    if (
      resultId !== nextProps.resultId ||
      (resultId === nextProps.resultId && total !== nextProps.total)
    ) {
      return true;
    }

    if (Array.isArray(resultsRerenderTrigger)) {
      for (let index = 0; index < resultsRerenderTrigger.length; index += 1) {
        if (
          lodashGet(resultsRerenderTrigger, index) !==
          lodashGet(nextProps.resultsRerenderTrigger, index)
        ) {
          return true;
        }
      }
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    const { onResultChange, resultId, items } = this.props;
    if (prevProps.resultId !== resultId) {
      if (typeof onResultChange === 'function') {
        onResultChange(items);
      }
    }
  }

  isGettingTotal = (total, items) => {
    if (total || total === 0) {
      return false;
    }

    return Array.isArray(items) && items.length;
  };

  render() {
    const { className } = this.props;
    const componentClassName = `${className || ''} ${this.cssClass}`;
    const {
      items,
      total,
      showResult,
      showNoResult = true,
      renderResultsComponent,
      objects,
      renderNoResultComponent,
      searchValues,
      noResultText,
      noResultImage,
      resultId,
      formValues,
      fromParamsToSortData,
      onSortDataChange,
      noResultTextMultiLine,
      fullSearchResult,
    } = this.props;

    return (
      <div className={componentClassName}>
        {(() => {
          if (
            showResult ||
            (items && total) ||
            this.isGettingTotal(total, items)
          ) {
            /* Để đảm bảo rằng luôn luôn có total */
            const totalPossible = this.isGettingTotal(total, items)
              ? items.length
              : total;

            return (
              renderResultsComponent &&
              renderResultsComponent(
                items,
                {
                  ...this.props,
                  total: totalPossible,
                },
                objects ? { ...objects, total: totalPossible } : {},
                searchValues,
                resultId,
                typeof fromParamsToSortData === 'function'
                  ? fromParamsToSortData(formValues)
                  : {},
                onSortDataChange,
                totalPossible,
                fullSearchResult,
              )
            );
          }

          if (renderNoResultComponent) {
            return renderNoResultComponent(
              { ...this.props },
              searchValues,
              objects,
              resultId,
              fullSearchResult,
            );
          }

          return (
            showNoResult && (
              <NoResult
                text={noResultText}
                image={noResultImage}
                noResultTextMultiLine={noResultTextMultiLine}
              />
            )
          );
        })()}
      </div>
    );
  }
}

Results.propTypes = {
  className: PropTypes.string,
};

Results.defaultProps = {
  className: '',
};

export default Results;
