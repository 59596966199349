/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useEffect } from 'react';
import Requester from 'common/network/http/Request';
import { message } from 'antd';
import { t1 } from 'translate';
import lGet from 'lodash.get';
const DataFetchHook = (
  initUrl,
  initParams,
  succesMes,
  warningMes,
  errorMes,
  callBack,
  callBackFailed,
  showProgress = true,
) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState({ count: 0, total: 0 });
  const [url, setUrl] = useState(initUrl);
  const [params, setParams] = useState(initParams);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [serverTs, setServerTs] = useState(Math.floor(Date.now() / 1000));

  useEffect(
    () => {
      const fetchData = async (url, params) => {
        setIsError(false);
        setIsLoading(true);
        try {
          const result = await Requester.post(url, params, null, showProgress);
          if (result.success) {
            if (result.result != undefined) {
              setData(result.result);
            } else {
              setData([]);
            }
            if (result.objects != undefined) {
              setPage(result.objects);
            }
            if (lGet(result, 'server_ts')) {
              setServerTs(lGet(result, 'server_ts'));
            }
            /*message.success(
              succesMes ? succesMes : t1('fetch_data_successful'),
              1,
            );*/
            if (typeof callBack === 'function') {
              callBack(result.result, params);
            }
          } else {
            setData([]);
            message.warning(
              warningMes ? warningMes : t1('fetch_data_failed'),
              1,
            );
            setIsError(true);
            if (typeof callBackFailed === 'function') {
              callBackFailed(result.result, params);
            }
          }
        } catch (error) {
          console.log(error);
          setIsError(true);
          message.error(errorMes ? errorMes : t1('fetch_data_error'), 1);
        }
        setIsLoading(false);
      };
      if (url != '' && params != '') {
        fetchData(url, params);
      }
    },
    [url, params],
  );

  return [
    { data, isLoading, isError, page, serverTs, setData },
    setUrl,
    setParams,
  ];
};

export default DataFetchHook;
