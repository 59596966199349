export const peerTakeFor = {
  COURSE_DASHBOARD: 'course',
  TEACHER_DASHBOARD: 'teacher-dashboard',
  ADMIN: 'admin',
};

export const peerTakeType = {
  OVERVIEW: 'overview',
  WITH_FILTER: 'filter',
};
