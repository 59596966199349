import React, { useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Collapse, Alert } from 'antd';
import lodashGet from 'lodash.get';
import { t1 } from 'translate';
import fetchData from 'components/common/fetchData';
import endpoints from 'components/bdtx/training-phase/endpoints';
import AssignMaterialsByCap from 'components/bdtx/training-phase/steps/choose-material/assign-materials-by-cap';
import BackNextStep from 'components/bdtx/training-phase/steps/common/back-next-step';

const { Panel } = Collapse;

const ChooseMaterialTrainingPhase = ({
  trainingPhase,
  moduleMaterialsByCap,
  refetchListMaterial,
  dispatch,
  handleOnActiveStep,
  previousStepIndex,
  nextStepIndex,
}) => {
  const caps = lodashGet(trainingPhase, 'caps') || [];
  const targetUser = lodashGet(trainingPhase, 'target_user') || 'gv';
  const deployed = lodashGet(trainingPhase, 'status.deployed');

  const renderPanelHeader = useCallback((cap) => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div className="text-bold">{t1(`cap_${cap}`)}</div>
      </div>
    );
  }, []);

  const renderAssignModulesByCap = useCallback(
    (cap) => {
      const modules = lodashGet(moduleMaterialsByCap, `${cap}`) || [];

      return (
        <AssignMaterialsByCap
          cap={cap}
          targetUser={targetUser}
          moduleMaterials={modules}
          trainingPhase={trainingPhase}
          refetchListMaterial={refetchListMaterial}
        />
      );
    },
    [moduleMaterialsByCap, refetchListMaterial, targetUser, trainingPhase],
  );

  const renderPanel = useCallback(
    (cap) => {
      return (
        <Panel key={cap} header={renderPanelHeader(cap)}>
          {renderAssignModulesByCap(cap)}
        </Panel>
      );
    },
    [renderAssignModulesByCap, renderPanelHeader],
  );

  if (!trainingPhase) {
    return (
      <Alert
        type="error"
        message={t1('you_have_to_create_training_phase_before_choose_module')}
      />
    );
  }

  if (!caps || !caps.length) {
    return <Alert type="error" message={t1('training_phase_missing_caps')} />;
  }

  return (
    <>
      <Collapse defaultActiveKey={caps} className="full-width">
        {caps.map((cap) => {
          return renderPanel(cap);
        })}
      </Collapse>

      <BackNextStep
        onNextStep={handleOnActiveStep}
        onPreviousStep={handleOnActiveStep}
        previousStepIndex={previousStepIndex}
        nextStepIndex={nextStepIndex}
      />
    </>
  );
};

const fetchListMaterial = fetchData(({ trainingPhase }) => {
  const trainingPhaseIid = lodashGet(trainingPhase, 'iid');

  return {
    baseUrl: endpoints.bdtx_step_get_list_material,
    params: {
      training_phase_iid: trainingPhaseIid,
    },
    fetchCondition: !!trainingPhaseIid,
    propKey: 'moduleMaterialsByCap',
    fetchFunctionPropKey: 'refetchListMaterial',
  };
});

export default compose(
  connect(),
  fetchListMaterial,
)(ChooseMaterialTrainingPhase);
