import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import AttachmentViewer from 'components/common/forms/questions/open-ended/attachment-viewer';
import './style.scss';
import get from 'lodash.get';

const FileViewer = ({ attachments }) => {
  const ext = get(attachments, '[0].ext');
  const isPdf = ext === 'pdf' || ext === 'PDF';
  return (
    <DetailOnDialog
      renderPreview={({ showFull }) => (
        <span onClick={showFull} className="ve-eye cursor-pointer" />
      )}
      renderFull={() => (
        <AttachmentViewer attachments={attachments} showHeader={false} />
      )}
      dialogKey="view-evidence"
      dialogOptionsProperties={{
        title: 'Nội dung file tải lên',
        width: '100%',
        handleClose: true,
        className: `view-evidence-dialog ${isPdf ? 'custom-for-pdf' : ''}`,
      }}
    />
  );
};

export default FileViewer;
