import './style.scss';

import React from 'react';
import Split from 'react-split';
import { SPLIT_SCREEN_VALUE } from '../constant';

const SplitScreen = ({ split, leftComponent, rightComponent }) => {
  return (
    <div className="split-screen m-b-20">
      <div
        className={`${split !== SPLIT_SCREEN_VALUE.ONLY_LEFT ? 'd-none' : ''}`}
      >
        {leftComponent}
      </div>
      <Split
        class={`${split !== SPLIT_SCREEN_VALUE.SPLIT ? 'd-none' : 'd-flex'}`}
        sizes={[50, 50]}
        gutterSize={20}
      >
        {leftComponent}
        {rightComponent}
      </Split>
      <div
        className={`${split !== SPLIT_SCREEN_VALUE.ONLY_RIGHT ? 'd-none' : ''}`}
      >
        {rightComponent}
      </div>
    </div>
  );
};

export default SplitScreen;
