import React from 'react';
import get from 'lodash.get';
import { t1 } from 'translate';
import './stylesheet.scss';
import { displayElement } from 'schema-form/FormLayout';

const LayoutFreeStyle = (props) => {
  const { groups } = props;
  const fieldNames = get(props, 'groups.default.fieldNames');
  if (!fieldNames) {
    return null;
  }

  const { submitButton } = props;

  return (
    <div className="layout-new-session">
      {fieldNames.name}
      {fieldNames.scheduled}

      {fieldNames.group_iid ? (
        <div className={'m-t-5'}>
          <h3>{t1('invite_members')}</h3>
          {displayElement(groups, 'default', 'group_iid')}
          {displayElement(groups, 'default', 'members')}
          {displayElement(groups, 'default', 'group_name')}
          {displayElement(groups, 'default', 'add_myself_to_group')}
          {displayElement(groups, 'default', 'enable_public')}
          {displayElement(groups, 'default', 'download_materials')}
        </div>
      ) : null}
      <div className="text-center">{submitButton}</div>
    </div>
  );
};

export default LayoutFreeStyle;
