import React from 'react';
import { t1 } from 'translate';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { Card } from 'antd';
import SubmittedPerTotal from './submitted-per-total';
import { getQuestionStatusInfoOfItem } from './utils';
import './style.scss';

const ExerciseStatusOfQuestions = ({
  inCard,
  autoMarking,
  teacherMarking,
  exercise,
  trackingLine,
}) => {
  if (!(trackingLine || []).length) {
    return null;
  }

  // TODO: don't show teacher Makring if exercise.no_marking
  const content = (
    <div className="user-answer-info">
      {autoMarking && !!autoMarking.total && (
        <div>
          <SubmittedPerTotal
            submitted={autoMarking.submitted}
            total={autoMarking.total}
            mode="auto"
          />
        </div>
      )}
      {teacherMarking && !!teacherMarking.total && (
        <div className={autoMarking && !!autoMarking.total ? 'm-t-15' : ''}>
          <SubmittedPerTotal
            submitted={teacherMarking.submitted}
            total={teacherMarking.total}
            mode={'teacher_marking'}
            noMarking={exercise.no_marking}
          />
          {!exercise.no_marking && !!teacherMarking.marked && (
            <div>Đã chấm: {teacherMarking.marked}</div>
          )}
        </div>
      )}
    </div>
  );

  if (inCard) {
    return <Card>{content}</Card>;
  }

  return content;
};

const mapStateToProps = (state) => {
  const learn = get(state, 'learn');
  const questionsFromTree = get(state, 'tree', {});

  return getQuestionStatusInfoOfItem(learn, questionsFromTree);
};

export default connect(mapStateToProps)(ExerciseStatusOfQuestions);
