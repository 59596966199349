import React from 'react';
import { Timeline } from 'antd';

const BlockTimeline = ({ item }) => {
  return (
    <Timeline>
      {item.block_timeline.map((tl, i) => (
        <Timeline.Item>{tl.name} (tl.date)</Timeline.Item>
      ))}
    </Timeline>
  );
};

export default BlockTimeline;
