import lodashGet from 'lodash.get';
import {
  isTaphuanSmartEpV2SupplementaryTrainingPlan,
  shouldUseTaphuanSmartCotCanLearningFlow,
} from 'components/admin/training-plan/common';
import get from 'lodash.get';
import Perm from 'common/utils/Perm';
import { TRAINING_MODEL } from 'components/taphuan/configs';

export const addTaphuanEpV2UserOrganizationsToCourseName = (expandedCourse) => {
  let name = lodashGet(expandedCourse, 'name');

  if (window.isETEP) {
    const taphuan_smart_enrolment_plan_v2_user_organizations = lodashGet(
      expandedCourse,
      '__expand.enrolment_plans.0.taphuan_smart_enrolment_plan_v2_user_organizations.0.name',
    );
    if (taphuan_smart_enrolment_plan_v2_user_organizations) {
      name = `${name} - ${taphuan_smart_enrolment_plan_v2_user_organizations}`;
    }
  }

  return name;
};

export const getCourseNameWithOrgName = (course) => {
  if (!course) {
    return '';
  }

  let courseName = lodashGet(course, 'name');

  const orgNames = lodashGet(course, 'organizations_name', []);
  if (orgNames.length) {
    courseName = courseName + ' - ' + orgNames[orgNames.length - 1];
  }

  return courseName;
};

export const canAddMembersToCourse = (course, trainingPlanOfCourse) => {
  return (
    (Perm.hasPerm('root') && canAddMembersManually(course)) ||
    !(
      shouldUseTaphuanSmartCotCanLearningFlow(trainingPlanOfCourse) ||
      isTaphuanSmartEpV2SupplementaryTrainingPlan(trainingPlanOfCourse) ||
      get(course, 'is_taphuan_smart_enrolment_plan_v2_course')
    )
  );
};

export const canAddTeachersToCourse = (course, trainingPlanOfCourse) => {
  const trainingPlanCategory = lodashGet(trainingPlanOfCourse, 'category');

  return true;
};

const canAddMembersManually = (course) => {
  const courseIid = lodashGet(course, 'iid');
  const coursesConf = lodashGet(window, 'courseCanAddMemberManually', []);

  return coursesConf.includes(Number(courseIid));
};

export const isBdtxCourse = (course) => {
  return (lodashGet(course, 'projects') || []).includes('bdtx');
};

export const isBdtxCotCanCourse = (course) => {
  return (
    isBdtxCourse(course) &&
    lodashGet(course, 'training_model') === TRAINING_MODEL.COT_CAN
  );
};

export const isUserCourseStaff = (user, course) => {
  return Boolean(
    (lodashGet(course, 'staff') || []).find(
      (st) => lodashGet(st, 'iid') == lodashGet(user, 'iid'),
    ),
  );
};
