import React, { Component } from 'react';
import throttle from 'lodash.throttle';
import PropTypes from 'prop-types';

import './stylesheet.scss';

class ScrollAnimation extends Component {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();

    this.listener = throttle(this.handleScroll, 500);
    this.visibility = {
      onScreen: false,
      inViewport: false,
    };
  }

  componentDidMount() {
    const { scrollableParentSelector, animatePreScroll } = this.props;

    this.scrollableParent = scrollableParentSelector
      ? document.querySelector(scrollableParentSelector)
      : window;

    if (!this.scrollableParent || !this.node) {
      return;
    }

    if (this.scrollableParent && this.scrollableParent.addEventListener) {
      this.scrollableParent.addEventListener('scroll', this.listener);
    }

    if (animatePreScroll) {
      setTimeout(this.handleScroll);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.delayedAnimationTimeout);
    clearTimeout(this.callbackTimeout);

    if (this.scrollableParent && this.scrollableParent.removeEventListener) {
      this.scrollableParent.removeEventListener('scroll', this.listener);
    }
  }

  getDefaultState = () => {
    const { duration, initiallyVisible } = this.props;
    return {
      classes: 'animated',
      style: {
        animationDuration: `${duration}s`,
        opacity: initiallyVisible ? 1 : 0,
      },
    };
  };

  getElementTop = (element) => {
    let node = element;
    let yPos = 0;

    while (
      node &&
      node.offsetTop !== undefined &&
      node.clientTop !== undefined
    ) {
      yPos += node.offsetTop + node.clientTop;
      node = node.offsetParent;
    }

    return yPos;
  };

  getScrollPos = () => {
    if (
      this.scrollableParent &&
      this.scrollableParent.pageYOffset !== undefined
    ) {
      return this.scrollableParent.pageYOffset;
    }

    return this.scrollableParent ? this.scrollableParent.scrollTop : 0;
  };

  getScrollableParentHeight = () => {
    if (
      this.scrollableParent &&
      this.scrollableParent.innerHeight !== undefined
    ) {
      return this.scrollableParent.innerHeight;
    }

    return this.scrollableParent ? this.scrollableParent.clientHeight : 0;
  };

  getViewportTop = () => this.getScrollPos() + this.props.offset;

  getViewportBottom = () =>
    this.getScrollPos() + this.getScrollableParentHeight() - this.props.offset;

  isInViewport = (yPos) =>
    yPos >= this.getViewportTop() && yPos <= this.getViewportBottom();

  isAboveViewport = (yPos) => yPos < this.getViewportTop();

  isBelowViewport = (yPos) => yPos > this.getViewportBottom();

  inViewport = (elementTop, elementBottom) =>
    this.isInViewport(elementTop) ||
    this.isInViewport(elementBottom) ||
    (this.isAboveViewport(elementTop) && this.isBelowViewport(elementBottom));

  onScreen = (elementTop, elementBottom) =>
    !this.isAboveScreen(elementBottom) && !this.isBelowScreen(elementTop);

  isAboveScreen = (yPos) => yPos < this.getScrollPos();

  isBelowScreen = (yPos) =>
    yPos > this.getScrollPos() + this.getScrollableParentHeight();

  isNodeVisible = () => this.state.style.opacity === 1;

  getNodeVisibility = () => {
    const elementTop =
      this.getElementTop(this.node) - this.getElementTop(this.scrollableParent);

    const elementBottom = this.node
      ? elementTop + this.node.clientHeight
      : elementTop;

    return {
      inViewport: this.inViewport(elementTop, elementBottom),
      onScreen: this.onScreen(elementTop, elementBottom),
    };
  };

  visibilityHasChanged = (previousVis, currentVis) =>
    previousVis.inViewport !== currentVis.inViewport ||
    previousVis.onScreen !== currentVis.onScreen;

  animate = (animation, callback) => {
    const { duration, delay } = this.props;

    this.delayedAnimationTimeout = setTimeout(() => {
      this.animating = true;
      this.setState({
        classes: `animated ${animation}`,
        style: {
          animationDuration: `${duration}s`,
        },
      });
      this.callbackTimeout = setTimeout(callback, duration * 1000);
    }, delay);
  };

  animateIn = (callback) => {
    const { duration, animateIn, animateOnce } = this.props;

    this.animate(animateIn, () => {
      if (!animateOnce) {
        this.setState({
          style: {
            animationDuration: `${duration}s`,
            opacity: 1,
          },
        });

        this.animating = false;
      }

      if (typeof callback === 'function') {
        const nodeVisibility = this.getNodeVisibility();

        callback(nodeVisibility);
      }
    });
  };

  animateOut = (callback) => {
    const { duration, afterAnimatedIn, animateIn, animateOut } = this.props;

    this.animate(animateOut, () => {
      this.setState({
        classes: 'animated',
        style: {
          animationDuration: `${duration}s`,
          opacity: 0,
        },
      });

      const nodeVisibility = this.getNodeVisibility();
      if (nodeVisibility.inViewport && animateIn) {
        this.animateIn(afterAnimatedIn);
      } else {
        this.animating = false;
      }

      if (typeof callback === 'function') {
        callback(nodeVisibility);
      }
    });
  };

  handleScroll = () => {
    const {
      afterAnimatedIn,
      afterAnimatedOut,
      animateIn,
      animateOut,
      resetWhenOutSide,
    } = this.props;

    if (this.animating) {
      return;
    }

    const nodeVisibility = this.getNodeVisibility();
    if (this.visibilityHasChanged(this.visibility, nodeVisibility)) {
      clearTimeout(this.delayedAnimationTimeout);

      if (!nodeVisibility.onScreen && resetWhenOutSide) {
        this.setState(this.getDefaultState());
      } else if (nodeVisibility.inViewport && animateIn) {
        this.animateIn(afterAnimatedIn);
      } else if (
        animateOut &&
        nodeVisibility.onScreen &&
        this.visibility.inViewport &&
        this.isNodeVisible()
      ) {
        this.animateOut(afterAnimatedOut);
      }

      this.visibility = nodeVisibility;
    }
  };

  render() {
    const { className, style: propStyle = {}, children } = this.props;
    const { classes, style } = this.state;

    const elClass = className ? `${className} ${classes}` : classes;

    return (
      <div
        ref={(node) => {
          this.node = node;
        }}
        className={elClass}
        style={{
          ...style,
          ...propStyle,
        }}
      >
        {children}
      </div>
    );
  }
}

ScrollAnimation.defaultProps = {
  offset: 0,
  duration: 1,
  delay: 0,
  initiallyVisible: false,
  animateOnce: false,
  resetWhenOutSide: false,
  animatePreScroll: true,
};

ScrollAnimation.propTypes = {
  animateIn: PropTypes.string,
  animateOut: PropTypes.string,
  offset: PropTypes.number,
  duration: PropTypes.number,
  delay: PropTypes.number,
  initiallyVisible: PropTypes.bool,
  animateOnce: PropTypes.bool,
  style: PropTypes.object,
  scrollableParentSelector: PropTypes.string,
  className: PropTypes.string,
  animatePreScroll: PropTypes.bool,
  resetWhenOutSide: PropTypes.bool,
};

export default ScrollAnimation;
