import React from 'react';
import schema from './schema';
import TableResult from './TableResult';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import endpoints from 'components/bdtx/review-exam-store/endpoints';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import get from 'lodash.get';
import { BGD_ORG_IID, HVQL_IID } from 'components/admin/organization/utils';
import { ETEP_CONFIG } from 'components/taphuan/configs';

const SEARCH_FORM_ID = 'search-exam-store';

const SearchExamStore = () => {
  const renderResultComponent = (items) => {
    return <TableResult items={items} />;
  };

  const renderNoResultComponent = () => {
    return <SimpleNoResult text="Không có đề thi" />;
  };

  return (
    <>
      <SearchWrapper
        schema={schema}
        formid={SEARCH_FORM_ID}
        alternativeApi={endpoints.bdtx_get_exam_store}
        renderResultsComponent={renderResultComponent}
        renderNoResultComponent={renderNoResultComponent}
        showSearchButton={false}
        autoSearchWhenValuesChange
        defaultValues={{
          organizations: get(
            ETEP_CONFIG,
            'GVDHSP_organization_iids',
            [],
          ).concat([BGD_ORG_IID, HVQL_IID]),
        }}
        hiddenFields={{ status: 'approved' }}
      />
    </>
  );
};

export default SearchExamStore;
