import React from 'react';
import AnswerTextCommon from '../../mc-common/answer-text';
import { correctFullAnswerQuestions } from '../../utility';
import { isMMCCorrect } from '../util';

const AnswerText = ({
  handleChooseAnswer,
  meta,
  vertical,
  getHighlightsClass,
  getValueOfItem,
  multiple,
  withAudio,
  options,
  hintText,
  question,
  highlights,
  disabled,
  userAnswers,
  practice,
  questionShuffleKey,
}) => {
  const [showError, setShowError] = React.useState(0);

  const handleChange = React.useCallback(
    ({ selectedIndexes = [] }) => {
      handleChooseAnswer(selectedIndexes);
    },
    [handleChooseAnswer],
  );

  React.useEffect(
    () => {
      if (
        !disabled &&
        practice &&
        Array.isArray(userAnswers) &&
        userAnswers.length
      ) {
        setShowError((count) => getStatusAnswer(count, userAnswers));
      } else {
        setShowError(undefined);
      }
    },
    [disabled, getStatusAnswer, practice, userAnswers],
  );

  const getStatusAnswer = React.useCallback(
    (count = 0, userAns) => {
      const { answers = [] } = question;
      const isCorrect = isMMCCorrect(question, userAns || []);
      const isCorrectFull = correctFullAnswerQuestions(
        (userAns || []).map((v) => parseInt(v)).sort(),
        answers.sort(),
      );
      if (answers.length > userAns.length) {
        return undefined;
      }

      if (!isCorrect) {
        return Math.abs(Math.abs(count) + 1);
      } else {
        if (isCorrectFull) {
          return -Math.abs(Math.abs(count) + 1);
        } else {
          return Math.abs(Math.abs(count) + 1);
        }
      }
    },
    [question],
  );

  const value = React.useMemo(
    () => ({
      selectedIndexes: (userAnswers || []).map((answer) => Number(answer)),
    }),
    [userAnswers],
  );

  return (
    <AnswerTextCommon
      showError={showError}
      onChange={handleChange}
      meta={meta}
      vertical={vertical}
      getHighlightsClass={getHighlightsClass}
      getValueOfItem={getValueOfItem}
      multiple={multiple}
      withAudio={withAudio}
      options={options}
      hintText={hintText}
      question={question}
      highlights={highlights}
      disabled={disabled}
      value={value}
      questionShuffleKey={questionShuffleKey}
    />
  );
};

export default AnswerText;
