import { t1 } from 'translate';
import { freezeMode } from 'configs/constants';

const schema = () => {
  return {
    freeze: {
      type: 'radio',
      options: [
        {
          value: freezeMode.FREEZE_MODE_NORMAL,
          label: `${t1('students_can_learn_and_instructor_can_grade')}`,
        },
        {
          value: freezeMode.FREEZE_MODE_LEARN,
          label: t1('students_cannot_learn_and_instructor_can_still_grade'),
        },
        {
          value: freezeMode.FREEZE_MODE_COMPLETELY,
          label: `${t1('students_cannot_learn_and_instructor_cannot_grade')}`,
        },
      ],
    },
  };
};

const ui = () => [
  {
    id: 'default',
    fields: ['freeze'],
  },
];

const layout = {};

export default { schema, ui, layout };
