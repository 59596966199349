import React from 'react';
import { caphoc, targetUser } from 'components/common/elements';
import { t1 } from 'translate';

const schema = () => {
  return {
    q: {
      type: 'text',
      floatingLabelText: t1('name_or_code_of_module'),
      classWrapper: 'col-md-12',
    },
    target_user: targetUser({
      type: 'radio',
      classWrapper: 'col-md-4',
    }),
    caps: caphoc({
      classWrapper: 'col-md-8',
    }),
  };
};

const ui = () => {
  return [
    {
      id: 'id',
      fields: ['target_user', 'caps', 'q'],
    },
  ];
};

export default {
  schema,
  ui,
};
