import React from 'react';
import fetchData from 'components/common/fetchData';

const QRCode = ({ QRUrl }) => {
  if (!QRUrl) {
    return null;
  }

  return <img src={QRUrl} />;
};

export default fetchData(() => ({
  baseUrl: '/site/two-factor-authentication/qr-code',
  propKey: 'QRUrl',
  fetchCondition: true,
  refetchCondition: () => false,
}))(QRCode);
