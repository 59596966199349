import { call, put, race, select, take } from 'redux-saga/effects';
import {
  SAVE_TAKE_REQUEST_FAIL,
  SAVE_TAKE_REQUEST_SUCCESS,
  saveItemInfoToStore,
} from 'actions/learn';
import {
  saveFinalProgress,
  saveProgressAndTake,
  showEndingScreen,
  saveProgressAndTakeWhenAnswersChangeWithCoreFlow,
} from './exerciseFlow';
import { calculateExerciseResultSelector } from 'common/learn/exercise';
import {
  SAVE_FINAL_PROGRESS_FAILED,
  SAVE_FINAL_PROGRESS_SUCCESS,
} from 'actions/creators';
import {
  finishExerciseFailed,
  finishExerciseRequest,
  finishExerciseSuccess,
} from 'actions/learn/exercise/normal/saga-creators';
import coreFlow from './coreFlow';
import nodeActions from 'actions/node/creators';
import { t1 } from 'translate';
import { isServerBusy } from 'utils/Util';
import { getLearnItemInfoSelector } from 'common/learn';
import {
  generateEndingScreenFromCurrentPathname,
  reloadPageAfterFinishExerciseOfCourse,
} from 'components/learn/viewer/utils';

function* finishExercise(
  shouldSaveProgress,
  itemIid,
  canResume,
  inlineExercise,
) {
  yield put(finishExerciseRequest(itemIid));

  if (shouldSaveProgress) {
    const isFinished = true;
    const updateToStoreAfterSuccess = true;
    const displayMessageAfterSave = false;
    // when server is busy, we need to get all question from exercise, so have to set questionUniqueIdsToSave to null
    const questionUniqueIdsToSave = isServerBusy() ? null : [];

    yield call(
      saveProgressAndTake,
      itemIid,
      isFinished,
      updateToStoreAfterSuccess,
      displayMessageAfterSave,
      questionUniqueIdsToSave,
    );

    const { saveFinalTakeSuccess, saveFinalTakeFailed } = yield call(
      waitForSavingFinalTake,
      itemIid,
    );

    if (saveFinalTakeSuccess) {
      const getInfo = yield select(getLearnItemInfoSelector);
      const info = yield call(getInfo, itemIid);

      const { course_iid } = info || {};
      const course = {
        iid: course_iid,
      };

      // SGD VPC, khi thi trong exercise: Course set pacing, sau khi finish exercise sẽ reload lại page để clean data.
      if (reloadPageAfterFinishExerciseOfCourse(course)) {
        const redirectPath = generateEndingScreenFromCurrentPathname();
        window.location.assign(redirectPath);
      }
    }

    if (saveFinalTakeFailed) {
      return yield call(
        finishExerciseFailedFlow,
        itemIid,
        shouldSaveProgress,
        canResume,
        inlineExercise,
      );
    }
  }

  yield put(saveItemInfoToStore(itemIid, { done: true }));
  const result = yield call(getResult, itemIid);

  if (shouldSaveProgress) {
    yield call(saveFinalProgress, itemIid, result);

    const { saveFinalProgressSuccess } = yield call(
      waitForSavingFinalProgress,
      itemIid,
    );

    if (!saveFinalProgressSuccess) {
      return yield call(
        finishExerciseFailedFlow,
        itemIid,
        shouldSaveProgress,
        canResume,
      );
    }
  }

  yield call(
    finishExerciseSuccessFlow,
    itemIid,
    shouldSaveProgress,
    canResume,
    result,
    inlineExercise,
  );
}

function* finishExerciseCommonFlow(
  itemIid,
  shouldSaveProgress,
  canResume,
  inlineExercise,
) {
  if (shouldSaveProgress) {
    yield call(saveProgressAndTakeWhenAnswersChangeWithCoreFlow, itemIid);
  } else {
    yield call(coreFlow, itemIid);
  }

  yield call(
    finishExercise,
    shouldSaveProgress,
    itemIid,
    canResume,
    inlineExercise,
  );
}

function* finishExerciseSuccessFlow(
  itemIid,
  shouldSaveProgress,
  canResume,
  result,
  inlineExercise,
) {
  yield put(finishExerciseSuccess(itemIid));

  if (!inlineExercise) {
    return yield call(showEndingScreen, itemIid, result, canResume);
  }

  yield put(nodeActions.snackbar('success', t1('save_successfully')));

  yield call(
    finishExerciseCommonFlow,
    itemIid,
    shouldSaveProgress,
    canResume,
    inlineExercise,
  );
}

function* finishExerciseFailedFlow(
  itemIid,
  shouldSaveProgress,
  canResume,
  inlineExercise,
) {
  yield put(finishExerciseFailed(itemIid));

  yield call(
    finishExerciseCommonFlow,
    itemIid,
    shouldSaveProgress,
    canResume,
    inlineExercise,
  );
}

function* waitForSavingFinalTake(itemIid) {
  return yield race({
    saveFinalTakeSuccess: call(waitForSavingTakeSuccess, itemIid),
    saveFinalTakeFailed: call(waitForSavingTakeFailed, itemIid),
  });
}

function* waitForSavingTakeSuccess(itemIid) {
  return yield take((action) => {
    return (
      action.type === SAVE_TAKE_REQUEST_SUCCESS &&
      action.itemIid &&
      String(action.itemIid) === String(itemIid)
    );
  });
}

function* waitForSavingTakeFailed(itemIid) {
  return yield take((action) => {
    return (
      action.type === SAVE_TAKE_REQUEST_FAIL &&
      action.itemIid &&
      String(action.itemIid) === String(itemIid)
    );
  });
}

function* waitForSavingFinalProgress(itemIid) {
  return yield race({
    saveFinalProgressSuccess: call(waitForSavingFinalProgressSuccess, itemIid),
    saveFinalProgressFailed: call(waitForSavingFinalProgressFailed, itemIid),
  });
}

function* waitForSavingFinalProgressSuccess(itemIid) {
  return yield take(
    (action) =>
      action.type === SAVE_FINAL_PROGRESS_SUCCESS &&
      action.itemIid &&
      String(action.itemIid) === String(itemIid),
  );
}

function* waitForSavingFinalProgressFailed(itemIid) {
  return yield take(
    (action) =>
      action.type === SAVE_FINAL_PROGRESS_FAILED &&
      action.itemIid &&
      String(action.itemIid) === String(itemIid),
  );
}

function* getResult(itemIid) {
  const calculateExerciseResult = yield select(calculateExerciseResultSelector);
  return calculateExerciseResult(itemIid);
}

export default finishExercise;
