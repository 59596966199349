import { call, put, takeEvery } from 'redux-saga/effects';
import Requester from 'common/network/http/Request';
import nodeActions from 'actions/node/creators';
import { loginSuccess, logout } from 'actions/auth';

import { LOGIN_AUTH_WITH_TOKEN } from 'actions/auth/saga-creators';

function* loginWithToken(action) {
  const { authUrl, data, handler = {} } = action;
  const response = yield call(Requester.post, authUrl, data);

  if (response.success) {
    const dataResponse = response.result || {};
    yield put(loginSuccess(dataResponse));

    if (typeof handler.onRequestSuccessful === 'function') {
      handler.onRequestSuccessful();
    }
  } else {
    yield put(logout());

    yield put(nodeActions.snackbar('error', response.message));

    if (typeof handler.onRequestFailure === 'function') {
      handler.onRequestFailure();
    }
  }
}

export default function* loginWithTokenSaga() {
  yield takeEvery(LOGIN_AUTH_WITH_TOKEN, loginWithToken);
}
