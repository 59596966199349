import React from 'react';
import ReCAPTCHA from './ReCAPTCHA';
import makeReduxFormCompatible from 'components/common/makeReduxFormCompatible';
import withConf from 'common/hoc/withConf';
import { captchaTypes as allCaptchaTypes } from 'configs/constants/captcha';

const Captcha = ({
  errorText = '',
  onChange,
  floatingLabelText = '',
  captchaType,
}) => {
  return (
    <div className="lmscaptcha">
      {floatingLabelText && (
        <div className="lmscaptcha__label m-b-5">{floatingLabelText}</div>
      )}
      {captchaType === allCaptchaTypes.RECAPTCHA ? (
        <ReCAPTCHA onChange={onChange} />
      ) : null}
      {errorText && <div className="form-validate-error">{errorText}</div>}
    </div>
  );
};

export default makeReduxFormCompatible({})(
  withConf('captcha_type', 'captchaType')(Captcha),
);
