import './stylesheet.scss';

import React from 'react';
import { connect } from 'react-redux';
import { getNodeSelector } from 'components/admin/node/utils';
import { initExercise as initExerciseAction } from 'actions/learn/exercise/normal/saga-creators';
import NormalExercise from 'components/learn/items/exercise/NormalExercise';
import {
  buildExerciseStructureFromExerciseSelector,
  modes,
  types,
} from 'common/learn/exercise';
import get from 'lodash.get';
import { getQuestionStatusInfoOfItem } from 'components/learn/common/exercise-status-of-questions/utils';
import { Modal } from 'antd';
import { t1 } from 'translate';
import { isSmallScreen } from 'common';
import TotalPoint from 'components/learn/common/total-point/TotalPoint';

class InlineNormalExercise extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFullscreen: false,
    };
  }

  componentWillMount() {
    const { onComponentWillMount } = this.props;
    if (typeof onComponentWillMount === 'function') {
      onComponentWillMount();
    }
  }

  shouldDisabledFinishButton = () => {
    const { questionStatusInfo } = this.props;

    const autoMarking = get(questionStatusInfo, 'autoMarking');
    const teacherMarking = get(questionStatusInfo, 'teacherMarking');

    return (
      autoMarking.total !== autoMarking.submitted ||
      teacherMarking.total !== teacherMarking.submitted
    );
  };

  render() {
    const {
      exerciseIid,
      courseIid,
      title,
      exerciseInfo,
      exerciseScore,
      course,
      syllabus,
    } = this.props;
    const { isFullscreen } = this.state;
    const isFinishButtonDisabled = this.shouldDisabledFinishButton();
    const noMarking = get(exerciseInfo, 'no_marking');
    const practice = get(exerciseInfo, 'practice');

    const shouldShowScore =
      ![undefined, null, ''].includes(exerciseScore) && !noMarking && !practice;

    const contentExercise = (
      <NormalExercise
        learnItemIid={exerciseIid}
        courseIid={courseIid}
        isFinishButtonDisabled={isFinishButtonDisabled}
        course={course}
        syllabus={syllabus}
        inlineExercise
        noCustomScroll
      />
    );

    return (
      <>
        <div className="d-flex">
          <div style={{ flex: 1 }}>
            <h3>{title}</h3>
          </div>

          <div className="d-flex align-items-center m-b-10">
            {shouldShowScore && (
              <div>
                <span className="m-r-5">{t1('score')}:</span>
                <TotalPoint point={exerciseScore} />
              </div>
            )}

            <span
              title={t1('open_full_screen')}
              className={`ui-cursor-pointer m-l-15 ${
                isSmallScreen ? 'p-r-15' : ''
              } ve-expand`}
              onClick={() => this.setState({ isFullscreen: true })}
            />
          </div>
        </div>

        {contentExercise}

        <Modal
          visible={isFullscreen}
          onCancel={() =>
            this.setState({
              isFullscreen: false,
            })
          }
          footer={null}
          title={title}
          wrapClassName="inline-exercise-modal"
        >
          {contentExercise}
        </Modal>
      </>
    );
  }
}

const getQuestionUniqueId = (question) => question && question.iid;

const mapStateToProps = (state, props) => {
  const { exerciseIid, courseIid, isPreviewInSyllabusEditor } = props;

  const progress = get(state, `trackerProgress.${exerciseIid}`);
  const learn = get(state, 'learn');
  const tree = get(state, 'tree', {});

  const questionStatusInfo = getQuestionStatusInfoOfItem(
    learn,
    tree,
    exerciseIid,
  );

  const exercise = getNodeSelector(state)(exerciseIid) || {};

  let exerciseInfo = {
    type: types.EXERCISE,
    id: exercise.id,
    iid: exercise.iid,
    name: exercise.name,
    description: exercise.description,
    exam_type: exercise.ntype,
    duration: exercise.duration,
    timeRemaining: exercise.timeRemaining,
    mode: modes.NORMAL,
    course_iid: courseIid,
    inlineExercise: true,
    no_start_screen: exercise.no_start_screen,
    options: exercise.options,
    passing_scheme: exercise.passing_scheme,
    max_number_of_questions_per_try: exercise.max_number_of_questions_per_try,
    no_marking: exercise.no_marking,
    practice: exercise.practice,
    isPreviewInSyllabusEditor,
    keep_highest_score: exercise.keep_highest_score,
  };

  const builtStructure = buildExerciseStructureFromExerciseSelector(state)(
    exercise.iid,
    getQuestionUniqueId,
    exerciseInfo,
  );

  if (builtStructure) {
    exerciseInfo = {
      ...exerciseInfo,
      ...builtStructure,
    };
  }

  return {
    exerciseIid,
    exerciseInfo,
    exerciseScore: get(progress, 'p'),
    questionStatusInfo,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  initExercise: (itemIid, info) => {
    dispatch(initExerciseAction(itemIid, info));
  },
});

const mergeProps = (stateProps, dispatchProps, props) => {
  const { exerciseIid, exerciseInfo } = stateProps;
  const { initExercise } = dispatchProps;

  return {
    ...props,
    ...stateProps,
    ...dispatchProps,
    onComponentWillMount() {
      initExercise(exerciseIid, exerciseInfo);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(InlineNormalExercise);
