import React from 'react';
import get from 'lodash.get';
import { compose } from 'redux';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import { t1 } from 'translate';
import Widget from 'components/common/Widget';
import WidgetTypeOfQuestions from 'components/admin/question-bank/edit/dashboard/TypeOfQuestions';
import WidgetDifficultyOfQuestions from 'components/admin/question-bank/edit/dashboard/DifficultyOfQuestions';
import { fetchExamTemplate } from 'components/bdtx/review-exam-store/utils';
import Review from 'components/bdtx/review-program/common/review';
import { TreeTemplate } from 'components/contest/practice/ExamTemplateLayout';
import endpoints from 'components/bdtx/review-exam-store/endpoints';

import './style.scss';
import { isBo, isVGDStaff } from 'components/admin/user/utils';
import withUserInfo from 'common/hoc/withUserInfo';

const ReviewExamDashboard = ({ node, userInfo, refetchExamTemplate }) => {
  const onReviewSuccessful = () => {
    if (typeof refetchExamTemplate === 'function') {
      refetchExamTemplate();
    }
  };

  const questionBanks = get(node, 'question_banks');
  const shouldShowReviewControl = isBo(userInfo) || isVGDStaff(userInfo);

  if (!node) {
    return <SimpleNoResult text="Chưa lấy được thông tin" />;
  }

  return (
    <div className="row">
      <div className="col-md-9">
        <Widget title={t1('exam_template_here')}>
          {TreeTemplate(
            Object.assign({}, node, {
              exercise_template: node.exercise_templates,
            }),
          )}
        </Widget>

        <Widget title={t1('the_difficulty_of_questions_by_category')}>
          <WidgetDifficultyOfQuestions
            groupBy="category"
            questionBanks={questionBanks}
          />
        </Widget>

        <Widget title={t1('question_number_by_type_group_by_category')}>
          <WidgetTypeOfQuestions questionBanks={questionBanks} />
        </Widget>
      </div>

      <div className="col-md-3">
        <Review
          node={node}
          apiUrl={endpoints.bdtx_update_exam_template}
          shouldShowReviewControl={shouldShowReviewControl}
          onReviewSuccessful={onReviewSuccessful}
        />
      </div>
    </div>
  );
};

export default compose(
  fetchExamTemplate,
  withUserInfo,
)(ReviewExamDashboard);
