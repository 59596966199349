import { CryptoJSAesEncryptNormalPassphrase } from 'common/security/Encrypt';
import fetchData from 'components/common/fetchData';
import QRCode from 'qrcode.react';
import React from 'react';
import { t1 } from 'translate';
import ScheduledDateTime from '../common/ScheduledDateTime';
import Teachers from '../common/Teachers';
import AttendanceRegisteringToVirtualClassTable from './AttendanceRegisteringToVirtualDialogTable';
import './styles.scss';
import get from 'lodash.get';
import DisplayHtml from 'components/common/html';
import { isMeeting } from 'components/admin/session/utils';
import lodashGet from 'lodash.get';

const AttendanceRegisteringToVirtualDialog = (props) => {
  const {
    sessionFullResponse = {},
    handleRefetch,
    automaticScaleVirtualClassEnabled,
    viewMode,
    foreHideSystemAttendance,
  } = props;
  const { result = {}, server_ts } = sessionFullResponse;
  const session = result;
  const courses = session.courses || [];
  const getTotalStudentsAttendance = (session) => {
    const totalStudentAttendances = get(session, 'attendance_total', 0);
    const totalStudents = get(session, 'total_students', 0);
    return `${totalStudentAttendances} / ${totalStudents}`;
  };

  return (
    <div className="row">
      {/*<div className="col-md-3">*/}
      {/*  <h3>{t1('qr_code_to_scan')}</h3>*/}
      {/*  <QRCode*/}
      {/*    value={JSON.stringify({*/}
      {/*      courseIid: session.course_iid,*/}
      {/*      sessionIid: session.iid,*/}
      {/*      sessionName: session.name,*/}
      {/*      token: CryptoJSAesEncryptNormalPassphrase(*/}
      {/*        `${session.course_iid}_${session.iid}`,*/}
      {/*        JSON.stringify({*/}
      {/*          courseIid: session.course_iid,*/}
      {/*          sessionIid: session.iid,*/}
      {/*        }),*/}
      {/*      ),*/}
      {/*    })}*/}
      {/*  />*/}
      {/*</div>*/}
      <div className="col-md-8">
        <h3>{t1('session_info')}</h3>
        {courses.length > 0 && (
          <div className="row">
            <div className="col-md-4">{t1('course_name')}:</div>
            <div className="col-md-8 text-bold">
              <DisplayHtml
                content={courses
                  .map((course) => `${course.name} (#${course.iid})`)
                  .join('<br/>')}
              />{' '}
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-4">{t1('session_name')}:</div>
          <div className="col-md-8 text-bold">{session.name}</div>
        </div>
        <div className="row">
          <div className="col-md-4">{t1('session_schedule')}:</div>
          <div className="col-md-8 text-bold">
            <div className="color-by-status">
              <ScheduledDateTime session={session} inline={true} />
            </div>
          </div>
        </div>

        {(courses.length === 1 || isMeeting(lodashGet(session, 'type'))) && (
          <div className="row">
            <div className="col-md-4">{t1('number_of_student_attended')}:</div>
            <div className="col-md-8 text-bold">
              <div className="color-by-status">
                {getTotalStudentsAttendance(session)}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="col-md-4">
        <div className="row">
          <div className="col-md-4">{t1('session_instructors')}:</div>
          <div className="col-md-8">
            <div className="color-by-status text-bold">
              <Teachers session={session} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 m-t-10">
        <AttendanceRegisteringToVirtualClassTable
          viewMode={viewMode}
          foreHideSystemAttendance={foreHideSystemAttendance}
          automaticScaleVirtualClassEnabled={automaticScaleVirtualClassEnabled}
          server_ts={server_ts}
          session={session}
          callbackAttendanceChange={() => {
            handleRefetch();
          }}
        />
      </div>
    </div>
  );
};

const fetchSessionDetail = (props) => ({
  baseUrl: '/session/detail',
  fetchCondition: true,
  params: {
    iid: props.sessionIid,
    '_sand_expand[]': 'scheduled',
  },
  propKey: 'sessionFullResponse',
  getFullResponse: true,
  method: 'post',
  refetchCondition: () => false,
});
export default fetchData(fetchSessionDetail)(
  AttendanceRegisteringToVirtualDialog,
);
