import React from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { compose } from 'redux';
import { t1 } from 'translate';
import ProgramSearch, {
  searchFormId,
} from 'components/admin/program/search/Layout';
import schema from './schema';
import Results from './Results';
import { ntype } from 'configs/constants';
import StatisticsCard from 'components/common/statistics/Card';
import { statisticsItems } from './utils';
import fetchData from 'components/common/fetchData';
import programApiUrls from '../endpoints';

const SearchReviewProgram = ({ statistics, dispatch }) => {
  const [itemActive, setItemActive] = React.useState('all');
  const [paramFilter, setParamFilter] = React.useState({});

  const renderResults = (items) => {
    return <Results items={items} />;
  };

  const handleClickOnCard = (id, parmas) => {
    setItemActive(id);
    setParamFilter(parmas);

    dispatch(submit(searchFormId));
  };

  return (
    <div>
      {statistics ? (
        <div className="m-b-20">
          <StatisticsCard
            items={statisticsItems(statistics)}
            onClick={handleClickOnCard}
            itemActive={itemActive}
          />
        </div>
      ) : null}

      <ProgramSearch
        menuTopTitle={t1('review_program')}
        schema={schema}
        renderResultsComponent={renderResults}
        hiddenFields={{
          type: ntype.PROGRAM,
          program_type: [ntype.PROGRAM],
          ...paramFilter,
        }}
        hideTopMenuButton
      />
    </div>
  );
};

const fetchProgram = fetchData(() => ({
  baseUrl: programApiUrls.get_review_program_statistic,
  fetchCondition: true,
  params: {},
  propKey: 'statistics',
  refetchCondition: () => false,
}));

export default compose(
  fetchProgram,
  connect(),
)(SearchReviewProgram);
