import React from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import endpoints from 'components/bdtx/report/endpoints';
import schema from './schema';
import Results from './Results';

const OverviewNcbdtxByModule = () => {
  const renderNoResultComponent = () => {
    return <SimpleNoResult text="Chưa có dữ liệu" />;
  };

  const renderResultComponent = (items, { resultId }) => {
    if (!Array.isArray(items) || !items.length) {
      return renderNoResultComponent();
    }

    return <Results modules={items} resultId={resultId} />;
  };

  return (
    <SearchWrapper
      formid="ncbdtx_by_module"
      schema={schema}
      renderResultsComponent={renderResultComponent}
      renderNoResultComponent={renderNoResultComponent}
      alternativeApi={endpoints.overview_ncbdtx_by_module}
      autoSearchWhenStart={true}
      hidePagination
    />
  );
};

export default OverviewNcbdtxByModule;
