import { t1 } from 'translate';
import {
  getDataOfCurrentUser,
  saveValueToLocalStorage,
} from 'common/utils/local-storage';

export const themes = {
  dark: 'dark',
  light: 'light',
};

export const themeOptions = () => {
  const values = Object.values(themes);

  return values.map((value) => ({
    value,
    label: t1(`theme-${value}`),
  }));
};

const themeConfigKey = 'theme';

export const saveThemeToLocalStorage = (value) => {
  saveValueToLocalStorage(value, themeConfigKey);
};

export const getThemeFromLocalStorage = (user) => {
  return getDataOfCurrentUser(themeConfigKey, user);
};

export const enableTheme = () => {
  return !!window.THEME_ENABLE;
};
