import React from 'react';
import Results, {
  columnIds,
} from 'components/admin/user/account/common/AntdSearchResult';
import { t1 } from 'translate';
import lodashGet from 'lodash.get';
import { deleteUserErrCodes } from 'components/admin/user/account/delete-users/common';

const DisplayInvalidUsers = ({ erroredUsers, errorsByUserIid }) => {
  if (!erroredUsers || erroredUsers.length === 0) {
    return null;
  }

  const getErrorsFromUserIid = (userIid) => {
    return lodashGet(errorsByUserIid, [userIid]);
  };

  return (
    <Results
      items={erroredUsers}
      searchFormId={undefined}
      previewUserInDialog
      selectable={false}
      columnsToShow={[
        columnIds.CODE,
        columnIds.NAME,
        columnIds.MAIL,
        columnIds.BIRTHDAY,
        columnIds.SEX,
        columnIds.ETHNICITY,
        columnIds.PHONE,
        columnIds.ORGANIZATION,
        columnIds.PARENT_ORGANIZATION,
      ]}
      addExtraColumns={(columns) => {
        return columns
          .filter((col) =>
            [columnIds.CODE, columnIds.NAME, columnIds.MAIL].includes(col.id),
          )
          .concat([
            {
              title: t1('errors'),
              key: 'errors',
              render: (user) => {
                const userIid = lodashGet(user, 'iid');
                const errors = getErrorsFromUserIid(userIid);

                return (
                  <ul>
                    {(errors || [])
                      .map((err) => {
                        const errCode = lodashGet(err, 'errCode');

                        switch (errCode) {
                          case deleteUserErrCodes.ERROR_PERMISSION_DENIED:
                            return t1('permission_denied');
                          case deleteUserErrCodes.ERROR_ALREADY_DONE_ACTIVITIES_IN_THE_SYSTEM:
                            return t1(
                              'user_already_done_activities_in_the_system',
                            );
                        }
                      })
                      .map((errText) => (
                        <li>{errText}</li>
                      ))}
                  </ul>
                );
              },
            },
          ])
          .concat(
            columns.filter((col) =>
              [
                columnIds.BIRTHDAY,
                columnIds.SEX,
                columnIds.ETHNICITY,
                columnIds.PHONE,
                columnIds.ORGANIZATION,
                columnIds.PARENT_ORGANIZATION,
              ].includes(col.id),
            ),
          );
      }}
    />
  );
};

export default DisplayInvalidUsers;
