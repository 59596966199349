import React, { useEffect } from 'react';
import { H5P } from 'h5p-standalone';
import lodashGet from 'lodash.get';
import { t1 } from 'translate';
import { getPromiseSequentialRunner } from 'common/utils';

/**
 * if there are more than 1 H5Ps on the screen
 * they must be initiated sequentially
 * @see https://github.com/tunapanda/h5p-standalone#multiple-h5p-players-on-the-same-page
 */
const execute = getPromiseSequentialRunner().execute;

const H5PPlayer = ({ h5pJsonPath, item }) => {
  const initH5pRef = React.useRef(false);

  const h5pContainerId = `h5p-container-${lodashGet(item, 'iid')}`;

  useEffect(
    () => {
      if (initH5pRef.current || !h5pJsonPath) {
        return;
      }

      const el = document.getElementById(h5pContainerId);
      const options = {
        h5pJsonPath: h5pJsonPath,
        frameJs: '/h5p/frame.bundle.js',
        frameCss: '/h5p/styles/h5p.css',
      };

      execute(() => {
        return new H5P(el, options);
      });

      initH5pRef.current = true;
    },
    [h5pContainerId, h5pJsonPath],
  );

  if (!h5pJsonPath) {
    return (
      <div className="h5b-container text-center m-t-50">
        <h3 className="text-danger">
          {t1('h5p_file_is_processing.please_retry_after_some_minutes')}
        </h3>
      </div>
    );
  }

  return (
    <div className="h5b-container">
      <div id={h5pContainerId} />
    </div>
  );
};

export default H5PPlayer;
