import {
  LAYOUT_HIDE_HEADER,
  LAYOUT_SHOW_HEADER,
  SET_APP_LAYOUTS,
} from 'layouts/actions';

const layoutState = {
  headerShown: true,
};

const CommonState = (state = layoutState, action) => {
  switch (action.type) {
    case SET_APP_LAYOUTS:
      return {
        ...state,
        ...action.layoutConfigs,
      };
    case LAYOUT_SHOW_HEADER:
      return {
        ...state,
        headerShown: true,
      };
    case LAYOUT_HIDE_HEADER:
      return {
        ...state,
        headerShown: false,
      };
    default:
      return state;
  }
};
export default CommonState;
