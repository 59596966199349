import React from 'react';
import ListTrainingPhase from './search';
import SubTopMenuContext from 'common/context/menu/SubMenuTop';
import topMenuSchema from 'components/bdtx/training-phase/top-menu-schema';

const TrainingPhase = () => (
  <>
    <SubTopMenuContext
      schema={topMenuSchema()}
      lastBreadcrumbName="Đợt đào tạo"
      description="Đợt đào tạo"
      hideBackAction
    />

    <ListTrainingPhase />
  </>
);

export default TrainingPhase;
