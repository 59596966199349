import './stylesheet.scss';

import React, { useEffect, useState } from 'react';
import lodashGet from 'lodash.get';
import { connect } from 'react-redux';
import Store from 'store';
import { saveLearningProgress } from 'common/learn';
import { getNodeSelector } from 'components/admin/node/utils';
import MediaLecture from 'components/learn/items/lecture/media-lecture';
import Heading from 'components/learn/items/sco-spl/common/Heading';
import WatchOrReadWrapper from 'components/learn/watch-or-read';
import { isWatchMode, ITEM_TYPE } from 'components/learn/watch-or-read/utils';
import sagaActions from 'actions/node/saga-creators';

const BlockVideo = ({
  lecture,
  course,
  dispatch,
  isPreview,
  isReview,
  showInScoSpl,
  hiddenHeading = false,
}) => {
  const [hasSendLog, setHasSendLog] = useState(false);

  // TODO: remove
  // just log to check video is not hls
  useEffect(
    () => {
      const videoIid = lodashGet(lecture, 'iid');
      const courseIid = lodashGet(course, 'iid');
      const syllabusIid = lodashGet(course, 'syllabus');
      const videoLink = lodashGet(lecture, 'attachments.0.link') || '';

      const data = (videoLink || '').split('.');
      const videoExt = data && data.length ? data[data.length - 1] : '';

      const shouldSendLog =
        !hasSendLog && videoExt && videoExt !== 'm3u8' && videoExt !== 'ts';

      if (shouldSendLog) {
        Store.dispatch(
          sagaActions.submitFormRequest('', {
            extraParams: {
              text: `courseIid_${courseIid}_syllabusIid_${syllabusIid}_videoIid_${videoIid}_videoLink_${videoLink}`,
              file_name: 'video_not_hls.txt',
            },
            url: '/site/api/log-to-file',
          }),
        );

        setHasSendLog(true);
      }
    },
    [lecture, hasSendLog, course],
  );

  if (!lecture) {
    return null;
  }

  const getVideoClassName = () => {
    if (hiddenHeading) {
      return 'video-wrapper';
    }

    return 'video-wrapper video-wrapper-ratio';
  };

  const watchMode = isWatchMode(lecture);

  return (
    <WatchOrReadWrapper
      item={lecture}
      course={course}
      showInScoSpl={showInScoSpl}
      itemType={ITEM_TYPE.VIDEO}
    >
      {!hiddenHeading && watchMode && (
        <div className={'video-heading block-item-heading'}>
          <i className="ve-play" />
          <Heading item={lecture} />
        </div>
      )}

      <div className={getVideoClassName()}>
        <div className="video">
          <div className="video-body">
            <MediaLecture
              className={`learn-content-border`}
              type="video"
              learnItemIid={lecture.iid}
              node={lecture}
              saveLearningProgress={(progress) => {
                saveLearningProgress(
                  progress,
                  lodashGet(course, 'iid'),
                  dispatch,
                  isPreview,
                  isReview,
                );
              }}
              autoPlay={false}
              playerId={lecture.iid}
            />
          </div>
        </div>
      </div>
    </WatchOrReadWrapper>
  );
};

const mapStateToProps = (state, props) => {
  const lecture = props.item;
  if (!lecture) {
    return;
  }

  const iid = lodashGet(lecture, 'iid');
  const pid = lodashGet(lecture, 'pid');

  return {
    lecture: getNodeSelector(state)(iid, pid) || {},
  };
};

export default connect(mapStateToProps)(BlockVideo);
