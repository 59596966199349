import NCBDTX from 'components/bdtx/report/ncbdtx';
import NCBDTXPlanDevelop from 'components/bdtx/report/ncbdtx-plan-develop';
import LEARNING from 'components/bdtx/report/learning';
import NcbdtxByModule from './ncbdtx-by-module';
import ResultBDTX from './result-bdtx';

export const REPORT_TYPE = {
  LEARNING: 'learning',
  NCBDTX: 'ncbdtx',
  NCBDTX_BY_MODULE: 'ncbdtx-by-module',
  RESULT_BDTX: 'result-bdtx',
  NCBDTX_PLAN_DEVELOP: 'ncbdtx-plan-develop',
};

export const REPORT_ACTION = {
  GV: 'gv',
  CBQL: 'cbql',
};

export const getBdtxReportUrl = (reportType, action) => {
  return `/admin/bdtx/report/${reportType}${action ? `/${action}` : ''}`;
};

export const getItems = () => {
  return [
    {
      path: getBdtxReportUrl(REPORT_TYPE.NCBDTX, REPORT_ACTION.GV),
      title: 'Nhu cầu bồi dưỡng của GIÁO VIÊN',
      description:
        'Nhu cầu bồi dưỡng của GIÁO VIÊN (Ghi tên 5 tiêu chí; số lượng và tỷ lệ % ở mức xếp loại thấp nhất của từng tiêu chí theo tổng chung, phân tách theo các nhóm Nữ, DTTS, Nữ DTTS và công tác vùng khó khăn)',
      componentId: 'ncbdtx-gv',
    },
    {
      path: getBdtxReportUrl(REPORT_TYPE.NCBDTX, REPORT_ACTION.CBQL),
      title: 'Nhu cầu bồi dưỡng của CBQL CSGD',
      description:
        'Nhu cầu bồi dưỡng của CBQL CSGD (Ghi tên 5 tiêu chí; số lượng và tỷ lệ % ở mức xếp loại thấp nhất của từng tiêu chí theo tổng chung, phân tách theo các nhóm Nữ, DTTS, Nữ DTTS và công tác vùng khó khăn)',
      componentId: 'ncbdtx-cbql',
    },
    {
      path: getBdtxReportUrl(REPORT_TYPE.NCBDTX_PLAN_DEVELOP, REPORT_ACTION.GV),
      title: 'Nhu cầu bồi dưỡng theo mong muốn của GIÁO VIÊN',
      description:
        'Nhu cầu bồi dưỡng theo mong muốn của GIÁO VIÊN (Tập hợp từ câu hỏi 2 trong phiếu Tự đánh giá xếp loại giáo viên)',
      componentId: 'ncbdtx-plan-develop-gv',
    },
    {
      path: getBdtxReportUrl(
        REPORT_TYPE.NCBDTX_PLAN_DEVELOP,
        REPORT_ACTION.CBQL,
      ),
      title: 'Nhu cầu bồi dưỡng theo mong muốn của CBQL CSGD',
      description:
        'Nhu cầu bồi dưỡng theo mong muốn của CBQL CSGD (Tập hợp từ câu hỏi 2 trong phiếu Tự đánh giá xếp loại CBQL CSGD)',
      componentId: 'ncbdtx-plan-develop-cbql',
    },
    {
      path: getBdtxReportUrl(REPORT_TYPE.NCBDTX_BY_MODULE),
      title:
        'Tổng hợp Nhu cầu BDTX theo module đào tạo của Giáo Viên, CBQL CSGD',
      description:
        'Tổng hợp Nhu cầu BDTX theo module đào tạo của Giáo Viên, CBQL CSGD (Tổng hợp theo khảo sát mong muốn của Giáo Viên, CBQL CSGD)',
      componentId: REPORT_TYPE.NCBDTX_BY_MODULE,
    },
    {
      path: getBdtxReportUrl(REPORT_TYPE.RESULT_BDTX),
      title: 'Báo cáo kết quả BDTX hàng năm của Giáo Viên, CBQL CSGD',
      description: 'Báo cáo kết quả BDTX hàng năm của Giáo Viên, CBQL CSGD',
      componentId: REPORT_TYPE.RESULT_BDTX,
    },
    {
      path: getBdtxReportUrl(REPORT_TYPE.LEARNING),
      title: 'Tổng hợp kết quả học tập theo Chương Trình 01,02,03',
      description:
        'Báo cáo tổng hợp kết quả học tập BDTX theo chương trình (01,02,03) của GVPT/CBQL theo đơn vị',
      componentId: 'learning',
    },
  ];
};

export default (reportType) => {
  switch (reportType) {
    case REPORT_TYPE.NCBDTX:
      return NCBDTX;

    case REPORT_TYPE.NCBDTX_BY_MODULE:
      return NcbdtxByModule;

    case REPORT_TYPE.NCBDTX_PLAN_DEVELOP:
      return NCBDTXPlanDevelop;

    case REPORT_TYPE.LEARNING:
      return LEARNING;

    case REPORT_TYPE.RESULT_BDTX:
      return ResultBDTX;
  }
};
