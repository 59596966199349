import React from 'react';
import SubTopMenuContext from 'common/context/menu/SubMenuTop';
import withUserInfo from 'common/hoc/withUserInfo';
import topMenuSchema from './top-menu-schema';
import Search from './search';

const Deals = ({ userInfo }) => {
  return (
    <>
      <SubTopMenuContext
        lastBreadcrumbName="Bản quyền học liệu"
        schema={topMenuSchema(userInfo)}
      />

      <h1>Danh sách các học liệu đã được mua</h1>
      <Search />
    </>
  );
};

export default withUserInfo(Deals);
