import React from 'react';
import { t1 } from 'translate';
import JoinMeetingGuide from './JoinMeetingGuide';
import Modal from 'antd/lib/modal';
import lodashGet from 'lodash.get';
import DisplayHtml from 'components/common/html';
import { newlineToBr } from 'common/utils/string';
import { openZoomOnWeb } from './join-zoom';
import Button from 'components/common/button/Button';
import ButtonSecondary from 'components/common/button/ButtonSecondary';
import Icon from 'components/common/Icon';

const JoinMeetingOptionsPopup = ({
  joinMeetingResponse,
  isTeacher,
  session,
  onMeetingJoined,
}) => {
  const handleOnMeetingJoined = () => {
    if (typeof onMeetingJoined === 'function') {
      onMeetingJoined();
    }
  };

  const confirmOpenZoomApp = () => {
    Modal.confirm({
      centered: true,
      title: t1('open_zoom_app_confirm_title'),
      content: (
        <div>
          {t1('open_zoom_app_confirm_content')}
          <div className={'text-center m-t-10'}>
            <a
              href={'https://zoom.us/download'}
              target={'_blank'}
              className={'text-secondary'}
            >
              {t1('click_here_to_download_zoom_app')}
            </a>
          </div>
        </div>
      ),
      onOk() {
        window.open(
          lodashGet(joinMeetingResponse, 'result.response.urlDirect'),
          '_blank',
        );

        handleOnMeetingJoined();
      },
      cancelText: t1('close'),
      okText: t1('i_see_open_zoom_app'),
    });
  };

  const confirmOpenZoomWeb = () => {
    Modal.confirm({
      centered: true,
      title: t1('open_zoom_web_confirm_title'),
      content: (
        <div>
          <DisplayHtml
            content={newlineToBr(t1('open_zoom_web_confirm_content'))}
          />
        </div>
      ),
      onOk() {
        openZoomOnWeb(joinMeetingResponse, true, isTeacher, session);

        handleOnMeetingJoined();
      },
      cancelText: t1('close'),
      okText: t1('i_see_open_zoom_web'),
    });
  };

  return (
    <div>
      <div className={'d-flex align-items-center justify-content-center'}>
        <ButtonSecondary onClick={confirmOpenZoomApp}>
          {t1('open_zoom_app')}
        </ButtonSecondary>
        <Button className={'m-l-20'} onClick={confirmOpenZoomWeb}>
          {t1('open_meeting_on_web')}
        </Button>
      </div>

      {!!session.stream_link && (
        <div className={'m-t-20 text-danger'}>
          {t1('session_stream_link_notice')}{' '}
          <a
            href={session.stream_link}
            target={'_blank'}
            className={'text-secondary'}
          >
            <Icon icon={'link'} /> {session.stream_link}
          </a>
        </div>
      )}

      <hr />

      <JoinMeetingGuide isTeacher={isTeacher} />
    </div>
  );
};

export default JoinMeetingOptionsPopup;
