import React from 'react';
import lodashGet from 'lodash.get';
import { t1 } from 'translate';
import { formatNumber } from 'common/utils/Number';
import CommonAntdTable from 'components/common/antd/table';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import ResultBdtxViewDetailUsers from './view-detail';

const Results = ({ items, organizations, caps, targetUsers }) => {
  const columns = [
    {
      title: t1('organization_name'),
      render: (organization) => {
        return lodashGet(organization, 'name');
      },
    },
    {
      title: t1('credit_must_to_learn_in_year'),
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: [
        {
          title: t1('credit_gv_must_to_learn_in_year'),
          type: TYPE_OF_TABLE_DATA.TITLE,
          width: 150,
          render: (organization) => {
            return lodashGet(organization, 'credit_gv.total_credit');
          },
        },
        {
          title: t1('credit_cbql_must_to_learn_in_year'),
          type: TYPE_OF_TABLE_DATA.TITLE,
          width: 150,
          render: (organization) => {
            return lodashGet(organization, 'credit_cbql.total_credit');
          },
        },
      ],
    },
    {
      title: t1('total_users_in_org'),
      type: TYPE_OF_TABLE_DATA.TITLE,
      width: 150,
      render: (organization) => {
        const total = lodashGet(organization, 'learning_stats.total_gv');
        return total ? formatNumber(total) : total;
      },
    },
    {
      title: t1('total_users_passed'),
      type: TYPE_OF_TABLE_DATA.TITLE,
      width: 100,
      render: (organization) => {
        const totalPassed = lodashGet(
          organization,
          'learning_stats.total_gv_passed',
        );
        if (!totalPassed) {
          return '--';
        }

        return (
          <ResultBdtxViewDetailUsers
            organizations={[lodashGet(organization, 'iid')]}
            caps={caps}
            targetUsers={targetUsers}
            viewLearnType="passed"
            previewClassName="text-primary"
            label={totalPassed ? formatNumber(totalPassed) : totalPassed}
          />
        );
      },
    },
    {
      title: t1('percentage_users_passed'),
      type: TYPE_OF_TABLE_DATA.TITLE,
      width: 100,
      render: (organization) => {
        const percentagePassed = lodashGet(
          organization,
          'learning_stats.percentage_gv_passed',
        );
        if (!percentagePassed) {
          return `0 %`;
        }

        return <span className="text-primary">{`${percentagePassed} %`}</span>;
      },
    },
    {
      title: t1('total_users_failed'),
      type: TYPE_OF_TABLE_DATA.TITLE,
      width: 100,
      render: (organization) => {
        const totalFailed = lodashGet(
          organization,
          'learning_stats.total_gv_failed',
        );
        if (!totalFailed) {
          return '--';
        }

        return (
          <ResultBdtxViewDetailUsers
            organizations={[lodashGet(organization, 'iid')]}
            caps={caps}
            targetUsers={targetUsers}
            viewLearnType="failed"
            previewClassName="text-danger"
            label={totalFailed ? formatNumber(totalFailed) : totalFailed}
          />
        );
      },
    },
    {
      title: t1('percentage_users_failed'),
      type: TYPE_OF_TABLE_DATA.TITLE,
      width: 100,
      render: (organization) => {
        const percentageFailed = lodashGet(
          organization,
          'learning_stats.percentage_gv_failed',
        );
        if (!percentageFailed) {
          return `0 %`;
        }

        return <span className="text-danger">{`${percentageFailed} %`}</span>;
      },
    },
  ];

  return (
    <CommonAntdTable
      columns={columns}
      dataSource={items}
      pagination={false}
      rowKey="iid"
      childrenKey="children"
    />
  );
};

export default Results;
