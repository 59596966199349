import { t1 } from 'translate';

const mails = (notIn = false, props = {}) => ({
  type: 'text',
  multiLine: true,
  hintText: `abc@example.com, xyz@example.com, ... ${t1(
    'separated_by_comma_or_new_line',
  )}`,
  floatingLabelText: notIn ? t1('user_emails_not_in') : t1('user_emails'),
  fullWidth: true,
  ...props,
});

export default mails;
