import React from 'react';
import NodeNew from 'components/admin/node/new';
import schema from './schema';
import endpoints from 'components/bdtx/training-phase/endpoints';
import { fetchChosenModules } from 'components/bdtx/training-phase/utils';
import get from 'lodash.get';
import DetailOnDialog from 'components/common/detail-on-dialog';
import DeploymentPreview from 'components/bdtx/training-phase/mainstage/deployment-preview';
import { userGradeToText } from 'configs/constants/user';

const FORM_ID = 'assign_module_to_oranization';

const AssignModuleToOrganization = ({
  chosenModules,
  organizations,
  formid,
  searchFormId,
  hiddenFields = {},
  modules,
  searchValues,
  cap,
  trainingPhaseIid,
  readOnly,
  isCCModel,
}) => {
  const convertOrganizationsToNode = () => {
    return (organizations || []).map((data) => ({
      ...data,
      organization: get(data, 'organization.iid'),
    }));
  };

  const getOrganizationsDeployed = () => {
    return organizations.filter((org) => {
      const cts = get(org, 'chuong_trinhs', []);

      return cts.some((ct) => {
        const modulesDeployed = get(ct, 'modules_deployed', []);
        return modulesDeployed.length;
      });
    });
  };

  const newHiddenFields = {
    ...searchValues,
    ...(hiddenFields || {}),
  };

  const previewDeploymentHiddenFields = {
    ...newHiddenFields,
    cap,
  };

  const organizationsDeployed = getOrganizationsDeployed();
  const hasOrganizationDeployed = !!organizationsDeployed.length;

  const renderSubmitButton = () => {
    return (
      <button className={'btn btn-primary btn-small m-t-10'} type="submit">
        Lưu và triển khai
      </button>
    );
  };

  return (
    <>
      <NodeNew
        schema={schema}
        hiddenFields={newHiddenFields}
        formid={formid || FORM_ID}
        alternativeApi={endpoints.bdtx_update_assign_module}
        searchFormId={searchFormId}
        node={{
          assign_modules: convertOrganizationsToNode(),
        }}
        chosenModules={chosenModules}
        organizations={organizations}
        modules={modules}
        trainingPhaseIid={trainingPhaseIid}
        previewDeploymentHiddenFields={previewDeploymentHiddenFields}
        submitButton={renderSubmitButton()}
        readOnly={readOnly}
        isCCModel={isCCModel}
        mode="edit"
      />

      {hasOrganizationDeployed ? (
        <DetailOnDialog
          renderPreview={({ showFull }) => {
            return (
              <div className="m-t-10 text-center">
                <b className="text-secondary m-r-5">
                  Đã triển khai cho {organizationsDeployed.length} đơn vị
                </b>
                <i className="link" onClick={showFull}>
                  (Xem chi tiết)
                </i>
              </div>
            );
          }}
          renderFull={() => {
            return (
              <DeploymentPreview
                hiddenFields={previewDeploymentHiddenFields}
                trainingPhaseIid={trainingPhaseIid}
                searchFormId={searchFormId}
                showAction={!readOnly}
                isCCModel={isCCModel}
                noSearchForm
              />
            );
          }}
          dialogOptionsProperties={{
            title: (
              <span>
                Chi tiết triển khai cho cấp: <b>{userGradeToText(cap)}</b>
              </span>
            ),
            handleClose: true,
            modal: true,
            width: '90%',
          }}
        />
      ) : null}
    </>
  );
};

export default fetchChosenModules(AssignModuleToOrganization);
