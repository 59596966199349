import React from 'react';
import TwoSideToggle from 'schema-form/elements/toggle/TwoSideToggle';
import {
  APPLICABLE_PROJECT,
  getApplicableProjectText,
} from 'configs/constants/project';
import {
  getProjectOfCurrentUser,
  onSaveProject,
} from 'components/common/switch-project/utils';
import { isBDTXRoute } from 'utils/router';
import { withRouter } from 'react-router';

const ChooseProjectWithToggleMode = ({ history }) => {
  const projectConfig = getProjectOfCurrentUser();

  const handleAfterSaveProject = () => {
    if (!isBDTXRoute()) {
      return;
    }

    history.push('/admin');
  };

  const onChangeProject = (e, checked) => {
    const project = checked ? APPLICABLE_PROJECT.BDTX : APPLICABLE_PROJECT.ETEP;

    onSaveProject(project, handleAfterSaveProject);
  };
  return (
    <TwoSideToggle
      toggled={projectConfig === APPLICABLE_PROJECT.BDTX}
      offLabel={getApplicableProjectText(APPLICABLE_PROJECT.BDTX)}
      onToggle={onChangeProject}
    />
  );
};

export default withRouter(ChooseProjectWithToggleMode);
