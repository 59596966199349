import React from 'react';
import get from 'lodash.get';
import { t1 } from 'translate';
import Portal, { portals } from 'components/common/portal';
import ResultMarked from '../marking-result';
import Icon from 'components/common/Icon';
import DetailOnDialog from 'components/common/detail-on-dialog/index';
import RaisedButton from 'components/common/mui/RaisedButton';
import UploadAnswers from '../UploadAnswers';
import ViewAnswer from './viewAnswer';
import TotalPoint from 'components/learn/common/total-point/TotalPoint';
import WaitForMarking from 'components/common/wait-for-marking';
import { isSmallScreen } from 'common';
import screenfull from 'screenfull';
import './stylesheet.scss';
import { isQuestionFormValid, isQuestionMarked } from 'common/learn/Question';
import withSnackbar from 'common/hoc/withSnackbar';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import ShowDatetimeFromTimestamp from 'components/common/show-datetime-from-timestamp';

const SubmissionAreaTrinh = ({
  value,
  onChange,
  itemToEdit,
  readOnly,
  question,
  courseIid,
  takeId,
  resultMarked,
  formid,
  handleRowOnChange,
  disabled,
  showSnackbar,
  dispatch,
  course = {},
  userAnswers,
  minVideoDuration,
  maxVideoDuration,
}) => {
  const cssClass = 'submission-area';
  const dialogOptionsProperties = {
    width: isSmallScreen ? '100%' : '80%',
    className: `document-submission-form-modal ${
      isSmallScreen ? 'modal-full-height' : ''
    }`,
    modal: true,
    handleClose: false,
  };

  const { input_submit, id } = itemToEdit || {};

  if (!Array.isArray(input_submit) || !input_submit.length) {
    return null;
  }

  const currentValue = (Array.isArray(value) &&
    value.find((val) => id === val.id)) || { id };

  const exitFullScreen = () => {
    if (screenfull && screenfull.enabled) {
      screenfull.exit();
    }
  };

  return (
    <div className={cssClass}>
      {!readOnly && itemToEdit.description && (
        <Portal id={portals.questionHeader(question.id)}>
          {itemToEdit.description}
        </Portal>
      )}

      {!readOnly && (
        <DetailOnDialog
          renderPreview={({ showFull }) => (
            <div className={`${cssClass}__upload-btn`}>
              <RaisedButton
                primary
                className="btn btn-secondary"
                onClick={() => {
                  exitFullScreen();
                  showFull();
                }}
                label={t1('upload')}
                icon={<Icon icon="plus" />}
                disabled={disabled}
                title={disabled ? t1('cannot_upload_in_review_mode') : ''}
              />
            </div>
          )}
          renderFull={({ closeDialog }) => (
            <UploadAnswers
              fields={input_submit}
              currentValue={currentValue}
              minVideoDuration={minVideoDuration}
              maxVideoDuration={maxVideoDuration}
              onChange={(newValue) => {
                if (
                  isQuestionFormValid(
                    userAnswers,
                    newValue,
                    question.score,
                    false,
                    true,
                  )
                ) {
                  handleRowOnChange(onChange, value, newValue);
                  if (closeDialog) {
                    closeDialog();
                  }

                  setTimeout(() => {
                    dispatch(submit(formid));
                  }, 10);
                } else {
                  showSnackbar('error', t1('answer_cannot_be_empty'));
                }
              }}
              onCloseDialog={closeDialog}
            />
          )}
          dialogOptionsProperties={dialogOptionsProperties}
        />
      )}

      <ViewAnswer answer={currentValue} showHeader={false} />

      {!readOnly && (
        <ResultMarked
          question={question}
          courseIid={courseIid}
          takeId={takeId}
          resultMarked={resultMarked}
          rubricIidsToShowMarking={
            get(itemToEdit, 'rubric_iid_marking') || null
          }
        />
      )}
    </div>
  );
};

export default withSnackbar()(connect()(SubmissionAreaTrinh));
