import React from 'react';
import Flippy, { FrontSide, BackSide } from 'react-flippy';

class FlippingCard extends React.Component {
  render() {
    const { card: cards } = this.props;
    return cards.map((card) => (
      <Flippy
        flipOnHover={false} // default false
        flipOnClick={true} // default false
        flipDirection="horizontal" // horizontal or vertical
        ref={(r) => (this.flippy = r)} // to use toggle method like this.flippy.toggle()
        // if you pass isFlipped prop component will be controlled component.
        // and other props, which will go to div
      >
        <FrontSide className="frontStyle">
          <p className="title">{card.font.title}</p>
        </FrontSide>
        <BackSide className="backStyle">
          <div className="content">
            <ul>
              {card.back.content.map((contentLi) => (
                <li>{contentLi}</li>
              ))}
            </ul>
          </div>
        </BackSide>
      </Flippy>
    ));
  }
}

export default FlippingCard;
