export const initTawk = (appId) => {
  if (!window.Tawk_API) {
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();
    const s1 = document.createElement('script');
    const s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = `https://embed.tawk.to/${appId}/default`;
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  }
};

export const isTawkInitialized = () => !!window.Tawk_API;

export const isTawkHidden = () =>
  window.Tawk_API &&
  typeof window.Tawk_API.isChatHidden === 'function' &&
  window.Tawk_API.isChatHidden();

export const showTawk = () => {
  if (window.Tawk_API && isTawkHidden()) {
    window.Tawk_API.toggleVisibility();
  }
};

export const hideTawk = () => {
  if (window.Tawk_API && !isTawkHidden()) {
    window.Tawk_API.toggleVisibility();
  }
};

/**
 * show_tawkto: array ['admin', 'learn', 'contest']
 */
export const shouldShowTawk = (pathname, showTawkto) => {
  if (!Array.isArray(showTawkto)) {
    return false;
  }

  if (showTawkto.includes('admin') && pathname.indexOf('/admin') === 0) {
    return true;
  }

  if (showTawkto.includes('learn')) {
    // On Learn screen, will be hide Tawk
    if (pathname.indexOf('/learn') >= 0) {
      return false;
    }

    if (pathname.indexOf('/admin') === -1) {
      return true;
    }
  }

  return false;
};
