import React from 'react';
import Radio from 'antd/lib/radio';
import { t1 } from 'translate';

const options = () => [
  {
    primaryText: t1('vgd_approved'),
    label: t1('vgd_approved'),
    value: 'approved',
  },
  {
    primaryText: t1('vgd_reject'),
    label: t1('vgd_reject'),
    value: 'rejected',
  },
];

const ReviewControl = ({ label, onChange, currentStatus }) => {
  return (
    <div className={'d-flex'}>
      <div className="d-flex align-items-center">
        {!!label && <span className="m-r-5">{label}</span>}

        <Radio.Group
          onChange={(e) => {
            if (typeof onChange === 'function') {
              onChange(e.target.value);
            }
          }}
          value={currentStatus}
          optionType="button"
          viewType="button"
          buttonStyle="solid"
        >
          {options().map((option) => {
            return (
              <Radio.Button value={option.value}>{option.label}</Radio.Button>
            );
          })}
        </Radio.Group>
      </div>
    </div>
  );
};

export default ReviewControl;
