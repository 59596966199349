import React from 'react';
import ImageBackGround from 'components/common/views/image-background';

import useDragWithScrolling from '../../ddm/scrollable';

const matchedColor = '#92c36a';
const notMatchedColor = '#f05252 ';

const MovableItem = ({
  className,
  avatar,
  content,
  type,
  isBeingDragged,
  handleBoxClick,
  isDropped,
  disabled,
}) => {
  const cssClass = 'reorder-question-movable-item';

  const componentClassName = `${className || ''} ${cssClass} ${
    isBeingDragged ? `${cssClass}--dragging` : ''
  } ${disabled ? 'pointer-event-none' : ''}`;

  const [{ opacity }, drag] = useDragWithScrolling({
    item: { content, type, avatar },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });

  return (
    <div
      ref={drag}
      className={componentClassName}
      onClick={() => handleBoxClick()}
    >
      <div className="main" style={{ opacity: isDropped ? 0.3 : 1 }}>
        <div className="content">
          {avatar && (
            <div className={`avatar`}>
              <ImageBackGround height="100" src={avatar} />
            </div>
          )}
          {content && (
            <div className="content-text">
              <div title={content}>{content}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MovableItem;
