import React from 'react';
import get from 'lodash.get';

import './stylesheet.scss';

const LayoutFreeStyle = (props) => {
  const fieldNames = get(props, 'groups.default.fieldNames');
  if (!fieldNames) {
    return null;
  }
  const { submitButton } = props;

  return (
    <div>
      <div className="virtual-classes-platform">{fieldNames.platform}</div>
      <div className="virtual-classes-name">{fieldNames.name}</div>

      {!!fieldNames.course_iids ? fieldNames.course_iids : null}
      {!!fieldNames.course_iids_or_codes
        ? fieldNames.course_iids_or_codes
        : null}
      {fieldNames.scheduled}
      {fieldNames.location}
      {fieldNames.room_types}
      {fieldNames.learn_duration}
      {fieldNames.break_time}
      {fieldNames.download_materials}

      <div className={'row'}>
        <div className={'col-md-6'}>
          {fieldNames.enable_recording}

          <div className="virtual-classes-public">
            {fieldNames.enable_public}
            {!!fieldNames.public_code && fieldNames.public_code}
          </div>
        </div>

        <div className={'col-md-6'}>
          {fieldNames.is_testing ? fieldNames.is_testing : null}
          {!!fieldNames.interpretation_enabled
            ? fieldNames.interpretation_enabled
            : null}
        </div>
      </div>

      {(fieldNames.max_user_count || fieldNames.max_devices_per_session) && (
        <div className={'row'}>
          {fieldNames.max_user_count}
          {fieldNames.max_devices_per_session}
        </div>
      )}

      {!!fieldNames.stream_link && (
        <div className={'row'}>{fieldNames.stream_link}</div>
      )}

      <div className="text-center">{submitButton}</div>
    </div>
  );
};

export default LayoutFreeStyle;
