import React, { Component } from 'react';
import t from 'translate';
import { t1 } from 'translate';
import { connect } from 'react-redux';
import {
  getFailedToSaveQuestionAnswerMessage,
  getQuestionAnswerSavingMessage,
} from 'common/question';
import { retrySavingAnswers } from 'actions/learn';
import CircularProgress from 'material-ui/CircularProgress';
import './stylesheet.scss';
import { isSmallScreen } from 'common';

class QuestionSavingStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      secondsToRetry: null,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const itemInfo = this.getItemInfoFromProps(this.props);
    const previousInfo = this.getItemInfoFromProps(prevProps);
    const retryDelay = itemInfo.retryDelay || 0;

    const retryCount = itemInfo.retryCount;
    const prevRetryCount = previousInfo.retryCount;

    if (retryDelay && retryCount !== prevRetryCount) {
      this.startCountDownToRetry(retryDelay / 1000);
    }
  }

  getItemInfoFromProps = (props) => {
    const { itemIid, info } = props;

    return info[itemIid];
  };

  startCountDownToRetry = (seconds) => {
    this.setState({
      secondsToRetry: seconds,
    });

    if (seconds) {
      setTimeout(() => {
        this.startCountDownToRetry(seconds - 1);
      }, 1000);
    }
  };

  render() {
    const { itemIid, info, retryNow, theme = 'light' } = this.props;
    const { secondsToRetry } = this.state;

    if (!itemIid || !info[itemIid]) {
      return null;
    }

    const cssClass = 'question-saving-status';
    const itemInfo = info[itemIid];
    const failedToSaveQuestionsExisted =
      itemInfo.failToSaveTakeQuestions &&
      itemInfo.failToSaveTakeQuestions.length;
    const saving = !!(itemInfo.pendingQuestions || []).length;
    const retrying = !!itemInfo.retrying;

    let savingMessage = null;
    if (saving) {
      savingMessage = getQuestionAnswerSavingMessage(
        itemInfo.trackingLine,
        itemInfo.pendingQuestions,
      );
    }

    let failedToSaveMessage = null;
    if (failedToSaveQuestionsExisted) {
      failedToSaveMessage = getFailedToSaveQuestionAnswerMessage(
        itemInfo.trackingLine,
        itemInfo.affectedQuestions,
      );
    }

    const message = savingMessage || failedToSaveMessage;

    if (!message) {
      return null;
    }

    const retryNowElement = (
      <span
        className="m-t-15 text-secondary cursor-pointer text-small"
        onClick={retryNow}
      >
        {t('retry_now')}
      </span>
    );

    return (
      <div className={`${cssClass} ${cssClass}--${theme}`}>
        <div className={`${cssClass}__message`}>
          {saving && (
            <span
              className={`${cssClass}__progress m-r-5 ${
                isSmallScreen ? 'd-flex justify-content-center' : ''
              }`}
            >
              <CircularProgress size={18} thickness={2} />
            </span>
          )}

          <span
            className={`m-r-5 text-small ${
              !savingMessage ? '' : 'text-success'
            }`}
          >
            {message}
          </span>

          {failedToSaveMessage && !saving && !retrying && retryNowElement}
        </div>

        {failedToSaveMessage && retrying && !saving && !!secondsToRetry && (
          <div className="m-t-5">
            <span className="text-left text-small m-r-5">
              {t1('retrying_in_%s_seconds', secondsToRetry)}
            </span>
            {retryNowElement}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  info: state.learn && state.learn.info,
});

const mapDispatchToProps = (dispatch, props) => {
  const { itemIid } = props;

  return {
    retryNow: () => {
      const isImmediate = true;
      dispatch(retrySavingAnswers(itemIid, isImmediate));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuestionSavingStatus);
