import React from 'react';
import DisplayHtml from 'components/common/html';
import SimpleSubmitForm from 'components/common/forms/SimpleSubmitForm';
import courseApiUrls from 'components/admin/course/endpoints';
import schema from './schema';
import fetchData from 'components/common/fetchData';
import get from 'lodash.get';
import { t1 } from 'translate';

const CommentForm = ({
  justShowStatus,
  courseIid,
  markingStatus,
  formidToSubmitOnSuccess,
  closeDialog,
  readOnly,
  executeOnSuccess,
}) => {
  const comment = get(
    markingStatus,
    'comment',
    t1(
      'core_teacher_educators_core_principal_advisor_educators_have_not_commented_yet',
    ),
  );

  if (justShowStatus) {
    return <DisplayHtml content={comment} />;
  }

  return (
    <SimpleSubmitForm
      schema={schema}
      node={markingStatus}
      alternativeApi={courseApiUrls.master_approve_marking}
      params={{
        course_iid: courseIid,
      }}
      formid={`master_comment_course_${courseIid}`}
      formidToSubmitOnSuccess={formidToSubmitOnSuccess}
      executeOnSuccess={() => {
        if (typeof executeOnSuccess === 'function') {
          executeOnSuccess();
        }

        closeDialog();
      }}
      readOnly={readOnly}
    />
  );
};

const fetchMarkingStatus = fetchData((props) => {
  return {
    baseUrl: courseApiUrls.get_marking_status,
    params: {
      course_iid: get(props, 'courseIid'),
    },
    propKey: 'markingStatus',
  };
});

export default fetchMarkingStatus(CommentForm);
