import React from 'react';
import { getHeadingColorSettings } from './utils';
import lodashGet from 'lodash.get';
import videoTypes from 'components/admin/video/schema/videoTypes';
import { BLOCK_HEADING_HIDDEN } from 'components/admin/video/schema/block-settings/heading-style';
import { showMessageForQuestionInVideo } from 'components/learn/utils';
import MessageForQuestionInVideo from 'components/learn/common/message-for-question-in-video';

const Heading = ({ item }) => {
  const style = {};

  const headingStyles = lodashGet(item, 'block_settings.heading_style', []);

  if (
    headingStyles.includes(BLOCK_HEADING_HIDDEN) ||
    lodashGet(item, 'block_settings.hide_heading') // backward compatible
  ) {
    return null;
  }

  if (getHeadingColorSettings(item)) {
    style.color = getHeadingColorSettings(item);
  }

  return (
    <h3
      className={`block-item-heading ${headingStyles.join(' ')}`}
      style={style}
    >
      {item.name}{' '}
      {item.type == videoTypes.TYPE_VIDEO && item.duration
        ? `(${item.duration})`
        : ' '}
      {showMessageForQuestionInVideo(item) && (
        <span className="m-l-10">
          <MessageForQuestionInVideo />
        </span>
      )}
    </h3>
  );
};

export default Heading;
