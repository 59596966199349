import React, { Component } from 'react';
import { connect } from 'react-redux';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { getGlobalConfigs } from 'utils/selectors';
import { createSelector } from 'reselect';

const mapStateToProps = (state, props) => {
  return { nodes: state.tree };
};

const withTreeNodes = (WrappedComponent, mapGlobalConfigsToProps) => {
  class SchoolConfigsHOC extends Component {
    render() {
      const { nodes, ...others } = this.props;

      let newProps =
        typeof mapGlobalConfigsToProps === 'function'
          ? mapGlobalConfigsToProps(nodes)
          : nodes;

      return <WrappedComponent {...others} {...newProps} />;
    }
  }

  hoistNonReactStatic(SchoolConfigsHOC, WrappedComponent);

  return connect(mapStateToProps)(SchoolConfigsHOC);
};

export default withTreeNodes;
