import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import ProgramResults from './Results';
import SubTopMenuContext from 'common/context/menu/SubMenuTop';
import ButtonNew from '../../path/new/ButtonNew';
import schemaNew from './schema-advance';
import { t1 } from 'translate';

export const searchFormId = 'program_search';

class Layout extends PureComponent {
  constructor(props) {
    super(props);
    this.renderResultComponent = this.renderResultComponent.bind(this);
  }

  renderResultComponent(items, props) {
    const type = this.props.type || 'path';

    return (
      <ProgramResults
        items={items}
        {...this.props}
        ntype="path"
        formid={searchFormId}
      />
    );
  }

  getTopMenuButtons = () => {
    return [<ButtonNew label={t1('new_program')} type="program" />];
  };

  render() {
    const {
      hiddenFields,
      menuTopTitle,
      schema,
      hideTopMenuButton,
    } = this.props;
    let newHiddenFields = hiddenFields ? { ...hiddenFields } : {};

    const type = this.props.type || 'path';
    newHiddenFields.type = newHiddenFields.type || type;

    return (
      <div>
        <SubTopMenuContext
          buttons={hideTopMenuButton ? false : this.getTopMenuButtons()}
          lastBreadcrumbName={menuTopTitle ? menuTopTitle : t1('program')}
        />
        <SearchWrapper
          {...this.props}
          formid={searchFormId}
          hiddenFields={newHiddenFields}
          renderResultsComponent={
            this.props.renderResultsComponent || this.renderResultComponent
          }
          onResultChange={this.props.onResultChange}
          schema={schema || schemaNew}
          showSearchButton={false}
        />
      </div>
    );
  }
}

export default connect()(Layout);

Layout.propTypes = {
  hiddenFields: PropTypes.object,
  menuTopTitle: PropTypes.string,
  schema: PropTypes.object,
  renderResultsComponent: PropTypes.func,
};
