import React, { useCallback, useMemo, useState } from 'react';
import { withRouter } from 'react-router';

const DEFAULT_KEY = 'default';

export const LayoutContext = React.createContext({
  [DEFAULT_KEY]: {
    fullWidth: false,
    noPadding: false,
    containerClass: '',
    setLayout: () => {},
  },
});

const LayoutProvider = ({
  children,
  fullWidth,
  noPadding,
  containerClass,
  location,
}) => {
  const [layoutValue, setLayoutValue] = useState({
    [DEFAULT_KEY]: {
      fullWidth: fullWidth,
      noPadding: noPadding,
      containerClass: containerClass,
    },
  });

  const customClassName = useMemo(
    () => {
      const { pathname } = location;

      const { fullWidth, noPadding, containerClass } =
        layoutValue[pathname] || layoutValue[DEFAULT_KEY];

      let className = fullWidth ? 'container-fluid' : 'container';
      if (containerClass) {
        className = `${className} ${containerClass}`;
      }

      if (noPadding) {
        className = `${className} p-0`;
      }

      return className;
    },
    [layoutValue, location],
  );

  const setLayout = useCallback(
    (value) => {
      const { pathname } = location;

      const layoutValueByKey = {
        ...(layoutValue[pathname] || {}),
        ...(value || {}),
      };

      setLayoutValue({
        ...layoutValue,
        [pathname]: layoutValueByKey,
      });
    },
    [layoutValue, location],
  );

  const currentLayoutValue = useMemo(
    () => {
      const { pathname } = location;

      return layoutValue[pathname || DEFAULT_KEY];
    },
    [layoutValue, location],
  );

  return (
    <LayoutContext.Provider
      value={{
        ...currentLayoutValue,
        setLayout,
      }}
    >
      <div className={customClassName}>{children}</div>
    </LayoutContext.Provider>
  );
};

export default withRouter(LayoutProvider);
