import lodashGet from 'lodash.get';

export const addPropsToEverySchemaElements = (schema, newProps) => {
  if (!schema) {
    return schema;
  }

  const result = {};
  Object.keys(schema).forEach((fieldName) => {
    if (!schema[fieldName] || typeof schema[fieldName] !== 'object') {
      result[fieldName] = schema[fieldName];
    } else {
      result[fieldName] = Object.assign({}, schema[fieldName], newProps);
    }
  });
  return result;
};

export const addRquiredTick = (string, cond) =>
  `${string}${cond ? ' (*)' : ''}`;

export const getPopupContainerOfElement = (node) => {
  if (node) {
    return node.parentNode;
  }

  return document.body;
};

export const getFormElementValue = (
  values,
  xpath,
  field,
  defaultValue = null,
) => {
  let ret;
  if (xpath) {
    ret = lodashGet(values, `${xpath}.${field}`, null);
  } else {
    ret = lodashGet(values, field, null);
  }

  if (
    field == 'contest_settings.admission_type' ||
    field == 'admission_type' ||
    field == 'exam_round_settings.should_show_score' ||
    field == 'should_show_score'
  ) {
    // console.log({[field]: ret, xpath, values});
  }

  if (ret === null) return defaultValue;

  return ret;
  // let tmp = xpath ? lodashGet(values, xpath, {}) : values;
  //
  // if (field == 'exam_round_settings.should_show_score') {
  //   console.log({tmmmmmmmmmmmmmmmmmmmp: tmp, xpath, values});
  // }
  //
  // if (tmp[field]) {
  //   return tmp[field];
  // }
  //
  // return null;
};

/**
 * Check if any touched field of the form has error
 *
 * @param syncErrors
 * @param submitErrors
 * @param formMeta
 * @returns {boolean}
 */
export const doesFormHaveAnyTouchedFieldError = (
  syncErrors,
  submitErrors,
  formMeta,
) => {
  const doesAnyTouchedFieldHaveSyncError =
    syncErrors &&
    Object.keys(syncErrors).some((keyThatHasSyncError) =>
      lodashGet(formMeta, `${keyThatHasSyncError}.touched`),
    );

  const doesAnyFieldHaveSubmitError =
    submitErrors &&
    Object.keys(submitErrors).some((keyThatHasSubmitError) =>
      lodashGet(formMeta, keyThatHasSubmitError),
    );

  return doesAnyTouchedFieldHaveSyncError || doesAnyFieldHaveSubmitError;
};

/**
 * Check if any field of the form has error (not only touched fields)
 *
 * @param syncErrors
 * @param formMeta
 * @returns {*|boolean}
 */
export const doesFormHaveAnyFieldError = (syncErrors) => {
  return syncErrors && syncErrors && Object.keys(syncErrors).length;
};
