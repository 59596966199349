import { t1 } from 'translate';
import SelectedRowMessage from '../selected-row-message';

const schema = (formId, values, step, xpath, props) => {
  const { selectAllLabel = '', totalRowSelected = 0, totalRow = 0 } = props;
  const { select_all = false } = values;
  return {
    message: {
      type: 'cascade',
      component: SelectedRowMessage,
      className: 'text-center',
      totalRow: totalRow,
      totalRowSelected: totalRowSelected,
      selectAll: select_all,
    },
    select_all: {
      type: 'checkbox',
      label: selectAllLabel || t1('choose_all_member'),
      classWrapper: 'text-center',
    },
  };
};

const ui = () => [
  {
    id: 'default',
    fields: ['message', 'select_all'],
  },
];

export default {
  schema,
  ui,
};
