import React from 'react';
import get from 'lodash.get';
import { t1 } from 'translate';
import Checkbox from 'antd/lib/checkbox';
import fetchData from 'components/common/fetchData';
import Html from 'components/common/html';
import makeReduxFormCompatible from 'components/common/makeReduxFormCompatible';
import Icon from 'antd/lib/icon';
import Tooltip from 'antd/lib/tooltip';
import endPoints from 'components/temis/assessment/endpoints';
import CommonAntdTable from 'components/common/antd/table';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import { compose } from 'redux';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';

const getDataToRenderFromDataServer = (
  rubrics,
  rubricIidsToShowMarking,
  depth = 0,
) => {
  let dataSource = [];
  let rubricToCalculateScore = [];

  if (!Array.isArray(rubrics) || !rubrics.length) {
    return { dataSource, rubricToCalculateScore };
  }

  rubrics.forEach(({ children, ...rubric }) => {
    const rubricIidStr = String(rubric.iid);

    const isHeader = Array.isArray(children) && children.length > 0;

    rubric.isHeader = isHeader;
    rubric.depth = depth;

    if (!isHeader) {
      rubricToCalculateScore.push(rubric);
    }

    const reserve =
      !Array.isArray(rubricIidsToShowMarking) ||
      !rubricIidsToShowMarking.length ||
      rubricIidsToShowMarking.includes(rubricIidStr);

    const recursiveData = getDataToRenderFromDataServer(
      children,
      reserve ? null : rubricIidsToShowMarking,
      depth + 1,
    );

    if (reserve || recursiveData.dataSource.length) {
      dataSource.push(rubric);
    }

    rubricToCalculateScore = rubricToCalculateScore.concat(
      recursiveData.rubricToCalculateScore,
    );
    dataSource = dataSource.concat(recursiveData.dataSource);
  });

  return { dataSource, rubricToCalculateScore };
};

const MarkingByRubric = ({
  loadingStatus,
  rubrics,
  score_scale,
  value,
  onChange,
  setScore,
  assessment_name,
  readOnly,
  rubricIidsToShowMarking,
}) => {
  if (loadingStatus !== 'finished') {
    return `${t1('loading')} ...`;
  }

  const parts = get(score_scale, 'parts', []);
  if (!(rubrics || []).length) {
    return <SimpleNoResult text={t1('no_rubric_to_marking')} />;
  }

  if (!parts.length) {
    return <SimpleNoResult text={t1('rubric_have_no_parts_to_marking')} />;
  }

  const { dataSource, rubricToCalculateScore } = getDataToRenderFromDataServer(
    rubrics,
    rubricIidsToShowMarking,
  );

  const maxScoreScale = Math.max.apply(Math, parts.map(({ id }) => id));

  const shouldCalculateScoreByWeighted = () => {
    return rubricToCalculateScore.every((rubric) => !!rubric.weighted);
  };

  const updateFormValue = (changedValue) => {
    if (!value || !value.length) {
      return [changedValue];
    }

    let formValues = [];

    const currentValue = value.find(({ iid }) => iid == changedValue.iid);
    if (!currentValue) {
      formValues = [...value, changedValue];
    } else {
      formValues = value.map((val) => {
        const isSame = val.iid == changedValue.iid;
        return {
          ...val,
          score: isSame ? changedValue.score : val.score,
        };
      });
    }

    return formValues;
  };

  const getFinalMarkingData = (markingData) => {
    return markingData.filter(({ iid }) => {
      return rubricToCalculateScore.find((rubric) => rubric.iid == iid);
    });
  };

  const calculateScoreByWeighted = (markingData) => {
    let totalScoreByScoreScale = 0;

    const filterMarkingData = getFinalMarkingData(markingData);

    filterMarkingData.forEach(({ iid, score }) => {
      const scoreInt = Number(score) || 0;

      const rubric =
        rubricToCalculateScore.find((rubric) => rubric.iid == iid) || {};

      const weightedInt = Number(rubric.weighted) || 0;

      totalScoreByScoreScale += scoreInt * weightedInt;
    });

    return (totalScoreByScoreScale / maxScoreScale).toFixed(0);
  };

  const calculateScoreByAverage = (markingData) => {
    let totalScoreByScoreScale = 0;

    const filterMarkingData = getFinalMarkingData(markingData);

    filterMarkingData.forEach((rubricScore) => {
      totalScoreByScoreScale += Number(rubricScore.score) || 0;
    });

    const maxScore = rubricToCalculateScore.length * maxScoreScale;

    return maxScore > 0
      ? ((100 * totalScoreByScoreScale) / maxScore).toFixed(0)
      : 0;
  };

  const handleRowOnChange = (changedValue) => {
    if (readOnly) {
      return;
    }

    const newValues = updateFormValue(changedValue);
    if (typeof onChange === 'function') {
      onChange(newValues);
    }

    let score;
    if (shouldCalculateScoreByWeighted()) {
      score = calculateScoreByWeighted(newValues);
    } else {
      score = calculateScoreByAverage(newValues);
    }

    if (typeof setScore === 'function') {
      setScore(score);
    }
  };

  const getColumns = () => {
    return [
      {
        title: assessment_name,
        render: ({ isHeader, name, depth }) => {
          const extraColumn = shouldCalculateScoreByWeighted() ? 2 : 1;
          return {
            children: <p className={`m-l-${15 * depth}`}>{name}</p>,
            props: {
              colSpan: isHeader ? parts.length + extraColumn : 1,
            },
          };
        },
      },
      {
        title: score_scale.name,
        type: TYPE_OF_TABLE_DATA.TITLE,
        children: parts.map(({ id, name }) => ({
          title: name,
          type: TYPE_OF_TABLE_DATA.TITLE,
          width: 70,
          render: ({ isHeader, iid, scaled_children }) => {
            const scaledCell =
              Array.isArray(scaled_children) &&
              scaled_children.find(({ scale_id }) => scale_id == id);

            const description =
              get(scaledCell, 'detailed_description') ||
              get(scaledCell, 'description');

            const currentValue =
              (Array.isArray(value) &&
                value.find((val) => get(val, 'iid') == iid)) ||
              {};

            return {
              children: [
                <Checkbox
                  checked={get(currentValue, 'score') == id}
                  onChange={(event) => {
                    const checked = get(event, 'target.checked');
                    handleRowOnChange({
                      iid: iid,
                      score: checked ? id : null,
                    });
                  }}
                  disabled={readOnly}
                />,
                description && (
                  <span style={{ position: 'absolute', top: 0, right: 0 }}>
                    <Tooltip
                      title={<Html content={description} />}
                      placement="topRight"
                    >
                      <Icon
                        type="question-circle"
                        style={{ color: 'rgba(0,0,0,.45)' }}
                      />
                    </Tooltip>
                  </span>
                ),
              ],
              props: {
                colSpan: isHeader ? 0 : 1,
                className: 'text-center position-relative',
              },
            };
          },
        })),
      },
      shouldCalculateScoreByWeighted() && {
        title: t1('max_score'),
        type: TYPE_OF_TABLE_DATA.TITLE,
        width: 100,
        render: ({ isHeader, weighted }) => {
          return {
            children: <b>{weighted}</b>,
            props: {
              colSpan: isHeader ? 0 : 1,
            },
          };
        },
      },
    ].filter(Boolean);
  };

  return (
    <CommonAntdTable
      className="white-background learn-default"
      dataSource={dataSource}
      columns={getColumns()}
      pagination={false}
      bordered
    />
  );
};

const fetchRubric = fetchData((props) => ({
  baseUrl: endPoints.dataToAssessment,
  params: {
    rubric_iid: props.rubricIid,
  },
  propKey: 'rubric',
  formatDataResult: ({ rubrics, score_scale, assessment_name } = {}) => {
    return {
      assessment_name,
      rubrics,
      score_scale,
    };
  },
  fetchCondition: true,
  refetchCondition: () => false,
}));

export default compose(
  fetchRubric,
  makeReduxFormCompatible({}),
)(MarkingByRubric);
