import React from 'react';
import get from 'lodash.get';
import lodashGet from 'lodash.get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getThemeConfig } from 'utils/selectors';
import { change, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { layouts } from 'configs/constants';
import {
  activeLoginTab,
  activeRegisterTab,
  closeLoginDialog,
} from 'actions/auth/auth-dialog';
import { getRootUrl } from 'routes/links/common';
import EtecLogin from './etec/index';
import EtepLogin from './etep';

import MobileSignIn from './mobile/index';
import userLinks from 'routes/links/user';

import '../stylesheet.scss';
import LoginAction from './LoginAction';

const LOGIN_FORM_ID = 'login';

class Login extends LoginAction {
  textFieldHintStyle = { padding: '6px 12px' };
  textFieldUnderlineStyle = { display: 'none' };
  checkboxLabelStyle = { color: '#9d9d9d' };
  checkboxIconStyle = { fill: '#9d9d9d' };

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(activeLoginTab());
  }

  componentDidUpdate(prevProps, prevState) {
    const { loginCaptchaId: prevLoginCaptchaId } = prevProps;
    const { loginCaptchaId, dispatch } = this.props;

    if (prevLoginCaptchaId !== loginCaptchaId) {
      dispatch(change(LOGIN_FORM_ID, 'captcha', null));
    }

    return super.componentDidUpdate(prevProps, prevState);
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.doLogin();
    }
  };

  changePassword = () => {
    const { dispatch, history } = this.props;

    dispatch(closeLoginDialog());
    history.push(userLinks.forgot_password);
  };

  doLogin() {
    const {
      loginForm,
      themeConfig,
      dispatch,
      history,
      loginCaptchaEnabled,
    } = this.props;
    if (
      loginForm &&
      loginForm.values &&
      !Object.keys(loginForm.syncErrors || {}).length &&
      // this is kinda hacky because somehow this form not validate properly if captcha field is re-rendered
      (!loginCaptchaEnabled ||
        (loginCaptchaEnabled && loginForm.values.captcha))
    ) {
      this.doLoginAction(loginForm.values, themeConfig, dispatch, history);
    }
  }

  renderContent = (props) => {
    const {
      themeConfig,
      rootUrl,
      dispatch,
      enableRegisters,
      history,
      allowLoginByOperatingOrganization,
      isInsideDialog,
      loginCaptchaEnabled,
      loginCaptchaId,
    } = props;
    let content = <div />;
    if (rootUrl === 'mobile') {
      content = (
        <MobileSignIn
          doLogin={() => {
            this.doLogin();
          }}
          changePassword={this.changePassword}
          handleKeyPress={this.handleKeyPress}
        />
      );
    } else if (themeConfig.layout === layouts.VT) {
      content = (
        <EtepLogin
          enableRegisters={enableRegisters}
          allowLoginByOperatingOrganization={allowLoginByOperatingOrganization}
          doLogin={() => {
            this.doLogin();
          }}
          changePassword={this.changePassword}
          handleKeyPress={this.handleKeyPress}
          goToRegister={() => {
            history.push(userLinks.register);
          }}
          themeConfig={themeConfig}
          loginCaptchaEnabled={loginCaptchaEnabled}
          loginCaptchaId={loginCaptchaId}
          formId={LOGIN_FORM_ID}
        />
      );
    } else {
      content = (
        <div className={`${!isInsideDialog ? 'row' : ''}`}>
          <div
            className={`${!isInsideDialog ? 'col-md-offset-3 col-md-6' : ''}`}
          >
            <EtecLogin
              enableRegisters={enableRegisters}
              allowLoginByOperatingOrganization={
                allowLoginByOperatingOrganization
              }
              doLogin={() => {
                this.doLogin();
              }}
              changePassword={this.changePassword}
              handleKeyPress={this.handleKeyPress}
              goToRegister={() => {
                if (themeConfig.layout === layouts.VT) {
                  history.push(userLinks.register);
                } else {
                  dispatch(activeRegisterTab());
                }
              }}
              themeConfig={themeConfig}
            />
          </div>
        </div>
      );
    }
    return content;
  };

  render() {
    return this.renderContent(this.props);
  }
}

Login.childContextTypes = {
  muiTheme: PropTypes.shape().isRequired,
};
const populateStateToProps = (state, props) => {
  const userInfo = state.user.info;
  return {
    rootUrl: getRootUrl(props),
    loginForm: state.form.login,
    userInfo,
    allowLoginByOperatingOrganization: get(
      state,
      'domainInfo.conf.allow_login_by_the_operating_organization',
    ),
    enableRegisters: get(state, 'domainInfo.conf.enable_registers'),
    themeConfig: getThemeConfig(state),
    domainInfo: state.domainInfo,
    loginCaptchaEnabled: lodashGet(state, 'login.loginCaptcha.enabled'),
    loginCaptchaId: lodashGet(state, 'login.loginCaptcha.captchaId'),
  };
};

export default connect(populateStateToProps)(
  reduxForm({
    form: LOGIN_FORM_ID, // a unique identifier for this form
  })(withRouter(Login)),
);
