import './stylesheet.scss';

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import get from 'lodash.get';
import lodashGet from 'lodash.get';
import { connect } from 'react-redux';
import Paper from 'material-ui/Paper';
import { t1 } from 'translate';
import sagaNodeActions from 'actions/node/saga-creators';
import NewForm from 'components/admin/node/new';
import Attachments from 'components/admin/course/mainstage/marking/Attachments';
import { types as questionTypes } from 'components/admin/question/schema/question-types';
import { getNodeSelector } from 'components/admin/node/utils';
import { mapObject } from 'common/utils/object';
import apiUrls from 'api-endpoints';
import DisplayHtml from 'components/common/html';
import Rubric from 'components/admin/skill/skill/rubric';
import ViewOpenEndedAnswer from './view-answer';
import { getKeyStateForRubricProgress, getKeyStateTakeDetail } from './utils';
import schema from 'components/common/open-ended-take/marking/schema';
import Widget from 'components/common/Widget';
import { timestampToDateString } from 'common/utils/Date';
import OpenEndedQuestionComments from 'components/common/comment/open-ended-answer-comment';
import { submit } from 'redux-form';
import { getSearchTakesFormId } from 'components/common/forms/questions/open-ended/peer-takes/Search';
import { isSmallScreen } from 'common';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import withUserInfo from 'common/hoc/withUserInfo';
import { isLTTUStaff } from 'components/admin/user/utils';
import CommentTake from './comment-take';
import { isGVSPRoute } from 'utils/router';
import MarkingGuide from './guide/Guide';
import ShowDatetimeFromTimestamp from 'components/common/show-datetime-from-timestamp';
import SplitScreen from './split-screen';
import MarkingGuideDetail from './guide/MarkingGuideDetail';
import { compose } from 'redux';
import endpoints from './endpoints';
import {
  canFeedbackOnMarkedQuestion,
  isSubmissionToolQuestion,
} from 'common/learn/Question';
import {
  isOESupportPlanLayoutType,
  teacherCanCommentPeerTake,
} from 'components/common/forms/questions/open-ended/util';
import { isBDTX } from 'utils/Util';
import { last } from 'common/utils/Array';

class Marking extends Component {
  style = { paddingTop: 200, textAlign: 'center' };

  cssClass = `report-dashboard-marking ${
    isSmallScreen ? 'mui-paper-no-padding mui-paper-no-box-shadow' : ''
  }`;

  constructor(props) {
    super(props);
    this.state = {
      suggestedScore: null,
      rubricMarkingValues: null,
      rubricIidsToShowMarking: null,
    };
  }

  loadData = () => {
    const { userIid, assignmentGroupForMarkingIid } = this.props;
    this.getData(userIid);
    if (
      userIid &&
      assignmentGroupForMarkingIid &&
      userIid !== assignmentGroupForMarkingIid
    ) {
      this.getData(assignmentGroupForMarkingIid);
    }
  };

  componentWillMount() {
    this.loadData();
  }

  getData = (userIid) => {
    this.getTakeDetail(userIid);
    this.getUserProgressForRubric(userIid);
  };

  getTakeDetail = (userIid) => {
    const {
      nodeIid,
      dispatch,
      item,
      peerMarking,
      assignmentGroupForMarkingIid,
      takeId,
      questionIid,
    } = this.props;
    if (!nodeIid || !item || !item.iid || !userIid) {
      return;
    }

    let params = {
      takeId,
      question_id: questionIid,
      userIid,
      course: nodeIid,
      item_iid: item.iid,
      item_ntype: item.ntype,
      peer_marking: peerMarking ? 1 : 0,
    };

    if (assignmentGroupForMarkingIid) {
      params = {
        ...params,
        assignment_group: assignmentGroupForMarkingIid,
      };
    }
    const url = apiUrls.getTakeDetail;
    const keyState = getKeyStateTakeDetail(userIid, nodeIid, item);
    dispatch(
      sagaNodeActions.getDataRequest(
        {
          url,
          keyState,
          post: true,
        },
        params,
      ),
    );
  };

  getUserProgressForRubric = (userIid) => {
    const { nodeIid, dispatch, item, rubric } = this.props;
    if (!nodeIid || !userIid || !item || !item.iid || !rubric || !rubric.iid) {
      return;
    }

    dispatch(
      sagaNodeActions.getDataRequest(
        {
          url: apiUrls.tracker_progress_get(false),
          keyState: getKeyStateForRubricProgress(userIid, nodeIid, item),
        },
        {
          ciid: nodeIid,
          tcos: rubric.iid,
          children: 1,
          depth: 3,
          userIid,
        },
      ),
    );
  };

  onUpdateSuccess = (value) => {
    this.getData();
    const {
      userIid,
      dispatch,
      rubric,
      courseIid,
      onSubmitSuccess,
    } = this.props;
    const { rubricMarkingValues } = this.state;
    if (rubric && rubricMarkingValues) {
      dispatch(
        sagaNodeActions.submitFormRequest('', {
          extraParams: {
            iids: Object.keys(rubricMarkingValues),
            scale: rubric.scale,
          },
          url: apiUrls.set_scale_for_skills,
          executeOnSuccess: () => {
            dispatch(
              sagaNodeActions.submitFormRequest('', {
                extraParams: {
                  user_iid: userIid,
                  progresses: rubricMarkingValues,
                },
                url: apiUrls.set_rubric_progress,
              }),
            );
          },
        }),
      );
    }

    // Need to have 2 dispatch here for 2 cases:
    // - admin screen, there will be search form for each course (with form id is created by courseId)
    // - teacher dashboard screen, there is only 1 search form (with form id is opened-question-takes)
    dispatch(submit(getSearchTakesFormId(courseIid)));
    dispatch(submit(getSearchTakesFormId()));
    if (onSubmitSuccess) {
      onSubmitSuccess(value);
    }
  };

  setRubricToShowMarking = (rubricIidsToShowMarking) => {
    this.setState({ rubricIidsToShowMarking });
  };

  render() {
    const {
      peerMarking,
      takeDetail = {},
      groupTakeDetail,
      formid,
      searchFormId,
      item,
      courseIid,
      assignmentGroupForMarkingIid,
      rubric,
      rubricProgress,
      defaultCommentIdToFocus,
      userInfo,
      location,
      viewOnly,
      split,
    } = this.props;

    const isMarkingOpenEndedQuestion =
      get(item, 'ntype') === 'question' &&
      get(item, 'type') === questionTypes.TYPE_OPEN_ENDED;

    const rubricMarking = get(item, 'rubric_marking.0');

    const hiddenFields = {};
    if (item.ntype === 'question') {
      hiddenFields.question = item.iid;
    }

    if (
      groupTakeDetail &&
      groupTakeDetail.takeId &&
      groupTakeDetail.score &&
      groupTakeDetail.takeId !== takeDetail.takeId
    ) {
      hiddenFields.group_score = groupTakeDetail.score;
    }

    const { suggestedScore } = this.state;
    if (typeof suggestedScore === 'number') {
      hiddenFields.suggestedScore = suggestedScore;
    }

    const isCourseOfMaster = isGVSPRoute(location);
    const isLTTU = isLTTUStaff(userInfo);
    const question = get(takeDetail, 'question', {});

    const isSupportPlanLayoutType = isOESupportPlanLayoutType(question);
    const supportPlanApproved = isSupportPlanLayoutType
      ? get(takeDetail, 'under_organization_management_status')
      : false;

    const masterComment = get(takeDetail, 'master_comment');
    const gvspccCanComment =
      isLTTU &&
      isCourseOfMaster &&
      (canFeedbackOnMarkedQuestion(question) ||
        isSubmissionToolQuestion(question));
    const score = get(takeDetail, 'score');
    const hasScore = ![null, undefined].includes(score);

    const commentHiddenFields = {
      course: courseIid,
      take_id: get(takeDetail, 'takeId'),
      question: get(takeDetail, 'iid'),
      id: get(takeDetail, 'takeId'),
    };

    const timeStampSubmit = get(takeDetail, 'ts');

    const showTeacherComment =
      isMarkingOpenEndedQuestion &&
      !viewOnly &&
      teacherCanCommentPeerTake(question) &&
      !peerMarking;

    const comments = get(takeDetail, 'comments', []);
    const lastComment = last(comments);

    const leftComponent =
      isMarkingOpenEndedQuestion && !assignmentGroupForMarkingIid ? (
        <div
          className={`${isSmallScreen ? '' : 'sticky-card'} ${
            split ? '' : 'col-md-6'
          }`}
        >
          <Widget title={t1('user_answer')}>
            {timeStampSubmit && (
              <ShowDatetimeFromTimestamp
                timestamp={timeStampSubmit}
                label={t1('time_open_ended_submit')}
              />
            )}
            <div className="m-t-15">
              <ViewOpenEndedAnswer
                key="view-open-ended-answer"
                question={item}
                takeDetail={takeDetail}
                setRubricToShowMarking={this.setRubricToShowMarking}
              />
            </div>
          </Widget>
        </div>
      ) : null;

    const rightComponent = (
      <Widget
        title={t1('mark_score')}
        className={`rubric-mark-score-wrapper ${split ? '' : 'col-md-6'}`}
        noMarginButton={split && !isSmallScreen}
      >
        {masterComment && isCourseOfMaster && (
          <div className="text-danger">Bài chấm có nhận xét, góp ý từ GVSP</div>
        )}
        <div
          className={isLTTU && isCourseOfMaster ? 'content-not-allowed' : ''}
        >
          <MarkingGuide question={item} />

          <NewForm
            rubricMarking={rubricMarking}
            rubricIidsToShowMarking={this.state.rubricIidsToShowMarking}
            schema={schema}
            hiddenFields={{
              course: courseIid,
              take_id: get(takeDetail, 'takeId'),
              peer_marking: peerMarking ? 1 : 0,
            }}
            isCourse={1}
            mode="edit"
            step="set_question_score"
            ntype="take"
            question={item}
            node={{
              score_by_rubric: {
                rubric_iid: get(rubricMarking, 'iid'),
              },
              ...takeDetail,
              id: get(takeDetail, 'takeId'),
              question: get(takeDetail, 'iid'),
              content: get(lastComment, 'content'),
              attachments: get(lastComment, 'attachments'),
            }}
            params={hiddenFields}
            formid={formid}
            alternativeApi={endpoints.take_update}
            requestSuccessful={this.onUpdateSuccess}
            updateTree={!peerMarking}
            closeModal={false}
            readOnly={
              (isLTTU && isCourseOfMaster) || viewOnly || supportPlanApproved
            }
          />

          {!isCourseOfMaster && !isBDTX() && <MarkingGuideDetail />}
        </div>

        {gvspccCanComment ? (
          hasScore ? (
            <CommentTake
              hiddenFields={commentHiddenFields}
              searchFormId={searchFormId}
              reloadData={this.loadData}
              masterComment={masterComment}
            />
          ) : (
            <div className="text-center">
              <span className="text-danger">
                {t1('wait_for_gvcc_marked_to_comment')}
              </span>
            </div>
          )
        ) : null}
      </Widget>
    );

    return (
      <div className={`${this.cssClass}`}>
        <div>
          <DisplayHtml content={lodashGet(item, 'content')} />
        </div>

        {!takeDetail || !takeDetail.takeId ? (
          <SimpleNoResult text={t1('the_user_has_not_taken_the_test_yet')} />
        ) : (
          <>
            {rubric && (
              <div className="row">
                <Widget
                  title={t1('rubric')}
                  subtitle={t1('click_to_show/hide')}
                  className="col-md-12"
                >
                  <Rubric
                    defaultMarkingValues={
                      rubricProgress &&
                      mapObject(rubricProgress, (value) => value && value.p)
                    }
                    onMarkingOverallValueChange={(value) => {
                      this.setState({ suggestedScore: value });
                    }}
                    onMarkingValuesChange={(values) => {
                      this.setState({ rubricMarkingValues: values });
                    }}
                    className={`${this.cssClass}__rubric`}
                    mode="marking"
                    node={rubric}
                  />
                </Widget>
              </div>
            )}

            {split && !isSmallScreen ? (
              <SplitScreen
                leftComponent={leftComponent}
                rightComponent={rightComponent}
                split={split}
              />
            ) : (
              <div className="row">
                <div
                  id="scroll-inside-me"
                  style={{ display: 'inline-block', width: '100%' }}
                >
                  {leftComponent}
                  {rightComponent}
                </div>
              </div>
            )}

            <div className="row">
              {Array.isArray(takeDetail.comments) &&
                !!takeDetail.comments.length &&
                !viewOnly && (
                  <Widget title={t1('histories')} className="col-md-12">
                    {takeDetail.comments.map((comment, index) => (
                      <Paper
                        className={`${this.cssClass}__take-comment p-15`}
                        key={`${takeDetail.takeId}-comments-${index}`}
                      >
                        <div className={`${this.cssClass}__take-comment-score`}>
                          {t1('score')}: <b>{comment.score || ''}</b>
                        </div>

                        <div
                          className={`${this.cssClass}__take-comment-account`}
                        >
                          {t1('by')}:{' '}
                          <b className="m-r-10">
                            {comment.u && comment.u.name}
                          </b>
                          {timestampToDateString(comment.ts, {
                            showTime: true,
                          })}
                        </div>

                        <div
                          className={`${this.cssClass}__take-comment-content`}
                        >
                          {comment.content && (
                            <div>
                              <DisplayHtml content={comment.content} />
                            </div>
                          )}
                          {comment.attachments &&
                            comment.attachments.length && (
                              <Attachments attachments={comment.attachments} />
                            )}
                        </div>
                      </Paper>
                    ))}
                  </Widget>
                )}
            </div>

            {showTeacherComment ? (
              <div className="row">
                <Widget
                  className={`
                    overflow-hidden mui-paper-no-padding 
                    ${rubricMarking ? 'col-md-12 p-15' : ''}
                  `}
                >
                  <OpenEndedQuestionComments
                    title={t1('comments')}
                    questionIid={lodashGet(item, 'iid')}
                    takeId={lodashGet(takeDetail, 'takeId')}
                    defaultShowDetailComments
                    defaultCommentIdToFocus={defaultCommentIdToFocus}
                  />
                </Widget>
              </div>
            ) : null}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { userIid, nodeIid, item, assignmentGroupForMarkingIid } = props;
  let rubric = item && item.rubric;
  if (rubric) {
    rubric = getNodeSelector(state)(rubric.iid, undefined, -1) || rubric;
  }
  const keyState = getKeyStateTakeDetail(userIid, nodeIid, item);
  const rubricProgressKeyState = getKeyStateForRubricProgress(
    userIid,
    nodeIid,
    item,
  );

  let groupTakeDetail;
  let groupRubricProgress;
  if (assignmentGroupForMarkingIid) {
    const keyStateForAssignmentTake = getKeyStateTakeDetail(
      assignmentGroupForMarkingIid,
      nodeIid,
      item,
    );
    const keyStateForAssignmentRubricProgress = getKeyStateForRubricProgress(
      assignmentGroupForMarkingIid,
      nodeIid,
      item,
    );
    groupTakeDetail = state.dataApiResults[keyStateForAssignmentTake];
    groupRubricProgress =
      state.dataApiResults[keyStateForAssignmentRubricProgress];
  }

  return {
    formid: keyState,
    takeDetail: state.dataApiResults[keyState],
    groupTakeDetail,
    rubricProgress: state.dataApiResults[rubricProgressKeyState],
    groupRubricProgress,
    rubric,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  withUserInfo,
)(Marking);
