import { Tooltip } from 'antd';
import React from 'react';
import { t1 } from 'translate';
import { validateLicense } from '../../../license/utils';

const UserStatus = (props) => {
  const { userData } = props;
  const { status, licenses } = userData;
  const isHasLicense = () => {
    if (!licenses || licenses.length === 0) {
      return false;
    }
    let hasLicenses = false;
    licenses.forEach((value) => {
      if (validateLicense(value)) {
        hasLicenses = true;
      }
    });
    return hasLicenses;
  };

  const metadata =
    status === 'activated' && isHasLicense()
      ? { color: 'green', title: t1('active_with_license') }
      : status === 'activated' && !isHasLicense()
      ? { color: 'orange', title: t1('active_without_license') }
      : { color: 'red', title: t1('de_active') };

  return (
    <Tooltip placement="topLeft" title={metadata.title} arrow>
      <div className="dot m-b-10" style={{ background: metadata.color }} />
    </Tooltip>
  );
};

export default UserStatus;
