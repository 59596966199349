import './stylesheet.scss';

import { Tag } from 'antd';
import Avatar from 'antd/lib/avatar';
import { isPlannedUserInactive } from 'common/user';
import {
  timestampToDateString,
  timestampToDateTimeString,
} from 'common/utils/Date';
import Perm from 'common/utils/Perm';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import OrganizationsParent from 'components/admin/group/common/OrganizationParent';
import OrganizationsOrPhongBan from 'components/admin/group/common/OrganizationsOrPhongBanInResultTable';
import Positions from 'components/admin/group/edit/member/search-results/Positions';
import {
  canUseSecondaryCodeFeature,
  getUserBirthday,
  getUserEthnicity,
  getUserGender,
  isSupport,
  userPreviewLink,
} from 'components/admin/user/utils';
import CommonAntdTable from 'components/common/antd/table';
import SuccessAlert from 'components/common/SuccessAlert';
import Warning from 'components/common/Warning';
import subTypes from 'configs/constants/org-sub-types';
import { approveStatuses } from 'configs/constants/user';
import lodashGet from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { t1 } from 'translate';
import PreviewUserInDialog from './PreviewInDialog';
import UserStatus from './UserStatus';
import CopyClipboard from 'components/common/copy-clipboard';
import { userInfoSelector } from 'common/selectors';
import checkRoot from 'common/hoc/checkRoot';
import { isEnableTemis } from 'components/temis/utils';
import DetailOnDialog from 'components/common/detail-on-dialog';
import DisplayHtml from 'components/common/html';
import DeleteAccountsButton from '../account-search/DeleteAccountsButton';

const deleteUserLabel = t1('delete');
const confirmDeleteUserLabel = t1('are_you_sure_you_want_to_do_this');

export const getLabel = () => ({
  approve_status: t1('approve_status'),
  iid: t1('iid'),
  iidCode: t1('account_code'),
  secondary_code: t1('secondary_code'),
  name: t1('name'),
  mail: t1('mail'),
  phone: t1('phone'),
  birthday: t1('birthday'),
  sex: t1('sex'),
  ethnicity: t1('ethnicity'),
  teaching_exp_years: t1('teaching_exp_years'),
  managing_exp_years: t1('managing_exp_years'),
  congtac_thuoc_diaban_khokhan: t1('congtac_thuoc_diaban_khokhan'),
  status: t1('status'),
  actions: t1('actions'),
  organization: t1('organization'),
  parent_organization: 'Trực thuộc đơn vị',
  created_date: t1('created_date'),
  active_license: t1('active_license'),
  md4_without_md12_cot_can: 'không tính đến MD2,3 trong điều kiện học MD4',
});

export const columnIds = {
  IID: 'IID',
  CODE: 'CODE',
  SECONDARY_CODE: 'SECONDARY_CODE',
  NAME: 'NAME',
  BIRTHDAY: 'BIRTHDAY',
  SEX: 'SEX',
  ETHNICITY: 'ETHNICITY',
  CONGTAC_THUOC_DIABAN_KHOKHAN: 'CONGTAC_THUOC_DIABAN_KHOKHAN',
  MAIL: 'MAIL',
  PHONE: 'PHONE',
  TEACHING_EXP_YEARS: 'TEACHING_EXP_YEARS',
  MANAGING_EXP_YEARS: 'MANAGING_EXP_YEARS',
  ORGANIZATION: 'ORGANIZATION',
  PARENT_ORGANIZATION: 'PARENT_ORGANIZATION',
  CREATED_DATE: 'CREATED_DATE',
  ACTIVE_LICENSE: 'ACTIVE_LICENSE',
  IS_STAFF: 'IS_STAFF',
  STATUS: 'STATUS',
  APPROVE_STATUS: 'APPROVE_STATUS',
  ACTIONS: 'ACTIONS',
  MD4_WITHOUT_MD12_COT_CAN: 'MD4_WITHOUT_MD12_COT_CAN',
};

const AntdSearchResult = ({
  triggerResetSelection,
  resetSelectionWhenItemIdsChange,
  items,
  renderAfter,
  searchFormId,
  formid,
  domain,
  showDeleteAction,
  deleteAction,
  columnsToShow,
  addExtraColumns,
  selectable = true,
  trainingPlanIid,
  previewUserInDialog = true,
  showFullDetailButton = true,
  renderAfterName,
  required_license,
  onSelectedUsersChange,
  showStatus,
  canUseSecondaryCodeFeature,
  isRoot,
  currentUser,
  searchValues,
  total,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

  const triggerResetSelectionWhenItemIdsChange = resetSelectionWhenItemIdsChange
    ? (items || []).map((item) => lodashGet(item, 'id')).join()
    : '';

  React.useEffect(
    () => {
      setSelectedRowKeys([]);
    },
    [triggerResetSelection, triggerResetSelectionWhenItemIdsChange],
  );

  React.useEffect(
    () => {
      if (typeof onSelectedUsersChange === 'function') {
        onSelectedUsersChange(selectedRowKeys);
      }
    },
    [selectedRowKeys, onSelectedUsersChange],
  );

  const getRowClassName = (user) => {
    let className = isPlannedUserInactive(user, trainingPlanIid)
      ? 'deleted-row'
      : '';

    if (!className) {
      className = user.need_to_add_to_planned_users_of_previous_tp_category
        ? 'highlight-danger-row'
        : '';
    }

    return className;
  };

  const label = getLabel();

  const approveStatusColumn = {
    id: columnIds.APPROVE_STATUS,
    title: label.approve_status,
    width: '10%',
    type: TYPE_OF_TABLE_DATA.TEXT,
    render: (user) => {
      const plannedTrainingData =
        lodashGet(user, 'planned_training_data') || [];
      const plannedTraining = plannedTrainingData.find(
        (p) => p.training_plan_iid == trainingPlanIid,
      );

      if (isPlannedUserInactive(user, trainingPlanIid)) {
        return <Tag color="grey">Đã xóa</Tag>;
      }

      if (
        plannedTraining &&
        plannedTraining.approve_status == approveStatuses.APPROVED_BY_SGD
      ) {
        return <Tag color="green">SGD đã duyệt</Tag>;
      }

      if (
        plannedTraining &&
        plannedTraining.approve_status == approveStatuses.APPROVED_BY_GVSP
      ) {
        return <Tag color="green">GVSP đã duyệt</Tag>;
      }

      return <Tag color="orange">Chưa duyệt bởi SGD</Tag>;
    },
  };

  let columns = [
    ...(columnsToShow && columnsToShow.includes(columnIds.APPROVE_STATUS)
      ? [approveStatusColumn]
      : []), // dont want this column is displayed by default
    columnsToShow && columnsToShow.includes(columnIds.IID)
      ? {
          id: columnIds.IID,
          title: label.iid,
          width: 100,
          type: TYPE_OF_TABLE_DATA.TEXT,
          render: (item, row) => (
            <div>
              {previewUserInDialog ? (
                item.iid
              ) : (
                <Link to={userPreviewLink(item, 'user', 'view')}>
                  {item.iid}
                </Link>
              )}
            </div>
          ),
        }
      : null,
    {
      id: columnIds.CODE,
      title: label.iidCode,
      width: '10%',
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (item, row) => (
        <div>
          {showStatus && <UserStatus userData={row} />}
          <div className="d-flex align-items-center">
            {previewUserInDialog ? (
              item.code
            ) : (
              <Link to={userPreviewLink(item, 'user', 'view')}>
                {item.code}
              </Link>
            )}

            {showStatus && (
              <CopyClipboard value={item.code} showLabel={false} />
            )}
          </div>
        </div>
      ),
    },
    ...(canUseSecondaryCodeFeature
      ? [
          {
            id: columnIds.SECONDARY_CODE,
            title: label.secondary_code,
            width: '10%',
            type: TYPE_OF_TABLE_DATA.TEXT,
            render: (item, row) => (
              <div>
                {item.secondary_code}
                {item.secondary_code ? (
                  <CopyClipboard
                    value={item.secondary_code}
                    showLabel={false}
                  />
                ) : null}
              </div>
            ),
          },
        ]
      : []),
    {
      id: columnIds.NAME,
      title: label.name,
      width: 250,
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (user) => {
        if (previewUserInDialog) {
          return (
            <>
              <PreviewUserInDialog
                user={user}
                hiddenItem={['code']}
                openDetailInNewTab
                showFullDetailButton={showFullDetailButton}
              >
                <Avatar user={user} size={30} />{' '}
                <strong>{lodashGet(user, 'name')}</strong>
              </PreviewUserInDialog>
              &nbsp;
              {typeof renderAfterName === 'function'
                ? renderAfterName(user)
                : null}
            </>
          );
        }

        return (
          <>
            <Link to={userPreviewLink(user, 'user', 'view')}>{user.name}</Link>
            &nbsp;
            {typeof renderAfterName === 'function'
              ? renderAfterName(user)
              : null}
          </>
        );
      },
    },
    {
      id: columnIds.BIRTHDAY,
      title: label.birthday,
      width: 120,
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (item) => getUserBirthday(lodashGet(item, 'birthday')),
    },
    {
      id: columnIds.SEX,
      title: label.sex,
      width: 80,
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (item) => getUserGender(lodashGet(item, 'sex')),
    },
    {
      id: columnIds.ETHNICITY,
      title: label.ethnicity,
      width: 80,
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (item) => getUserEthnicity(item),
    },
    {
      id: columnIds.MAIL,
      title: label.mail,
      width: 250,
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (item) =>
        previewUserInDialog ? (
          item.mail
        ) : (
          <Link to={userPreviewLink(item, 'user', 'view')}>{item.mail}</Link>
        ),
    },
    {
      id: columnIds.PHONE,
      title: label.phone,
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (item) =>
        previewUserInDialog ? (
          item.phone
        ) : (
          <Link to={userPreviewLink(item, 'user', 'view')}>{item.phone}</Link>
        ),
    },
    {
      id: columnIds.CONGTAC_THUOC_DIABAN_KHOKHAN,
      title: label.congtac_thuoc_diaban_khokhan,
      width: 80,
      className: 'text-center',
      render: (item) =>
        lodashGet(item, 'congtac_thuoc_diaban_khokhan') ? 'Có' : 'Không',
    },
    {
      id: columnIds.TEACHING_EXP_YEARS,
      title: label.teaching_exp_years,
      width: 80,
      className: 'text-center',
      render: (item) => item.teaching_exp_years,
    },
    {
      id: columnIds.MANAGING_EXP_YEARS,
      title: label.managing_exp_years,
      width: 80,
      className: 'text-center',
      render: (item) => item.managing_exp_years,
    },
    {
      id: columnIds.ORGANIZATION,
      title: label.organization,
      type: TYPE_OF_TABLE_DATA.TEXT,
      width: 250,
      render: (item) =>
        [
          item.positions && !!item.positions.length && (
            <Positions item={item} textOnly={true} />
          ),
          item.user_organizations && !!item.user_organizations.length && (
            <OrganizationsOrPhongBan
              item={item}
              attr={'user_organizations'}
              showParentsInfo
              showCode
            />
          ),
          item.phongbans && !!item.phongbans.length && (
            <OrganizationsOrPhongBan
              item={item}
              attr={'phongbans'}
              showParentsInfo={false}
            />
          ),
        ].filter(Boolean),
    },
    ...(window.isETEP
      ? [
          {
            id: columnIds.PARENT_ORGANIZATION,
            title: label.parent_organization,
            type: TYPE_OF_TABLE_DATA.TEXT,
            width: 250,
            render: (item) => (
              <OrganizationsParent
                item={item}
                attr={'user_organizations'}
                parentSubTypesToShow={[
                  subTypes.TAPHUAN_SUBTYPE_PHONG_GD,
                  subTypes.TAPHUAN_SUBTYPE_SO_GD,
                ]}
              />
            ),
          },
        ]
      : []),
    {
      id: columnIds.CREATED_DATE,
      title: label.created_date,
      type: TYPE_OF_TABLE_DATA.DATE,
      render: (item) => timestampToDateString(item.ts, { type: 'full_date' }),
    },

    !!required_license &&
      !isEnableTemis() && {
        id: columnIds.ACTIVE_LICENSE,
        title: label.active_license,
        children: [
          {
            title: t1('license_effective_date'),
            render: (text, item) => {
              const licenses = lodashGet(item, 'licenses', []);
              const index = licenses.length - 1;
              const key = lodashGet(item, `licenses.${index}.key`);
              const startDate = lodashGet(item, `licenses.${index}.start_date`);
              const endDate = lodashGet(item, `licenses.${index}.end_date`);

              if (!key) {
                return null;
              }

              return `${timestampToDateString(startDate, {
                splitter: '/',
              })} -> ${timestampToDateString(endDate, { splitter: '/' })}`;
            },
          },
          {
            title: t1('date_of_active_account'),
            render: (text, item) => {
              const ts = lodashGet(item, 'date_of_active_account_by_license');

              if (!ts) {
                return null;
              }

              return timestampToDateTimeString(ts, {
                type: 'long_date',
                unixEpoch: true,
                splitter: '/',
              });
            },
          },
        ],
      },
    ...(searchFormId === 'account_search' && !isEnableTemis()
      ? [
          {
            id: columnIds.IS_STAFF,
            title: t1('is_staff'),
            type: TYPE_OF_TABLE_DATA.TEXT,
            render: (item) =>
              Perm.hasPerm('staff', domain, item) ? (
                <SuccessAlert>{t1('yes')}</SuccessAlert>
              ) : (
                t1('no')
              ),
          },
          !required_license && {
            id: columnIds.STATUS,
            title: label.status,
            type: TYPE_OF_TABLE_DATA.TEXT,
            render: (item) =>
              item.status && item.status === 'activated' ? (
                <SuccessAlert>{t1(item.status)}</SuccessAlert>
              ) : (
                <Warning>{t1('unactivated')}</Warning>
              ),
          },
          ...(isRoot || isSupport(currentUser)
            ? [
                {
                  id: columnIds.MD4_WITHOUT_MD12_COT_CAN,
                  title: label.md4_without_md12_cot_can,
                  type: TYPE_OF_TABLE_DATA.TEXT,
                  render: (item) => {
                    return lodashGet(
                      item,
                      '__can_jump_from_md_1_cot_can_to_md_4_cot_can',
                    )
                      ? 1
                      : '';
                  },
                },
              ]
            : []),
        ]
      : [
          isEnableTemis() && {
            title: 'Trạng thái tài khoản',
            key: 'status',
            width: 110,
            type: TYPE_OF_TABLE_DATA.TITLE,
            render: (item) => {
              const { temis_inactive_account, temis_inactive_reason } = item;
              const showReason =
                temis_inactive_account && temis_inactive_reason;

              return (
                <div className="account-status">
                  {!temis_inactive_account && (
                    <>
                      <div className="text-primary">Đang hoạt động</div>
                    </>
                  )}

                  {!!temis_inactive_account && (
                    <>
                      <div className="text-danger m-b-5">Không hoạt động</div>
                      {showReason && (
                        <DetailOnDialog
                          renderPreview={({ showFull }) => (
                            <i className="cursor-pointer" onClick={showFull}>
                              (nhấn vào để xem lý do)
                            </i>
                          )}
                          renderFull={() => (
                            <DisplayHtml content={temis_inactive_reason} />
                          )}
                          dialogOptionsProperties={{
                            title: 'Lý do dừng hoạt động',
                            width: '500px',
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              );
            },
          },
        ]),
    ...(showDeleteAction && searchFormId === 'account_search'
      ? [
          {
            id: columnIds.ACTIONS,
            title: label.actions,
            type: TYPE_OF_TABLE_DATA.ACTION,
            render: (item) => {
              return (
                <div className="d-flex justify-content-center">
                  <DeleteAccountsButton
                    searchFormId={searchFormId}
                    itemId={lodashGet(item, 'id')}
                    selectedRowKeys={[lodashGet(item, 'id')]}
                    onSelectRowKeysChange={onSelectChange}
                    iconButton
                  />
                </div>
              );
            },
          },
        ]
      : []),
  ].filter((col) => {
    return (
      col &&
      (!Array.isArray(columnsToShow) ||
        columnsToShow.includes(lodashGet(col, 'id')))
    );
  });

  if (typeof addExtraColumns === 'function') {
    columns = addExtraColumns(columns);
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const idInCurrentPage = Array.isArray(items) ? items.map(({ id }) => id) : [];

  return (
    <div className="table-result" style={{ width: '100%' }}>
      <CommonAntdTable
        bordered
        rowKey="id"
        size="middle"
        pagination={false}
        childrenColumnName={null}
        style={{ background: 'white' }}
        columns={columns}
        className="learn-default"
        dataSource={Array.isArray(items) ? items : []}
        scroll={{ x: true }}
        rowClassName={getRowClassName}
        rowSelection={
          selectable
            ? {
                selectedRowKeys,
                hideDefaultSelections: true,
                onChange: onSelectChange,
                selections: [
                  {
                    key: 'select_current_page',
                    text: t1('select_data_in_current_page'),
                    onSelect: () => {
                      setSelectedRowKeys((currentSelectedRowKeys) => {
                        return (currentSelectedRowKeys || []).concat(
                          idInCurrentPage
                            .map(
                              (id) =>
                                !currentSelectedRowKeys.includes(id) && id,
                            )
                            .filter(Boolean),
                        );
                      });
                    },
                  },
                  {
                    key: 'invert_current_page',
                    text: t1('invert_data_in_current_page'),
                    onSelect: () => {
                      setSelectedRowKeys((currentSelectedRowKeys) => {
                        return idInCurrentPage.filter(
                          (id) => !(currentSelectedRowKeys || []).includes(id),
                        );
                      });
                    },
                  },
                  Array.isArray(selectedRowKeys) &&
                    !!selectedRowKeys.length &&
                    !selectedRowKeys.every((id) =>
                      idInCurrentPage.includes(id),
                    ) && {
                      key: 'remove_all',
                      text: t1('remove_all_data_selected'),
                      onSelect: () => {
                        setSelectedRowKeys([]);
                      },
                    },
                ].filter(Boolean),
              }
            : null
        }
      />
      {typeof renderAfter === 'function'
        ? renderAfter({ selectedRowKeys, total, searchValues, onSelectChange })
        : null}
    </div>
  );
};

AntdSearchResult.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
  searchFormId: PropTypes.string,
};

AntdSearchResult.defaultProps = {
  items: [],
  searchFormId: '',
};

function mapStateToProps(state, props) {
  const domainInfo = state.domainInfo;
  const currentUser = userInfoSelector(state);

  return {
    domain: domainInfo && domainInfo.domain,
    required_license: lodashGet(state, 'domainInfo.conf.required_license'),
    canUseSecondaryCodeFeature: canUseSecondaryCodeFeature(currentUser),
    currentUser,
  };
}

export default connect(mapStateToProps)(checkRoot()(AntdSearchResult));
