import React from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { Avatar, List } from 'antd';
import { withRouter } from 'react-router';
import get from 'lodash.get';
import ok from 'common/images/ok.png';
import withUserInfo from 'common/hoc/withUserInfo';
import getContent, { getItems } from './routes';
import SubTopMenuContext from 'common/context/menu/SubMenuTop';

import './stylesheet.scss';

const ReportBdtxDashboard = ({ userInfo, match }) => {
  const reportType = get(match, 'params.reportType');
  const action = get(match, 'params.action');

  const items = getItems();

  if (!reportType) {
    return (
      <div>
        <h1>Báo cáo BDTX</h1>

        <List
          itemLayout="horizontal"
          dataSource={items}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={ok} />}
                title={
                  <Link to={item.path}>
                    <span style={{ color: 'black' }}>{item.title}</span>
                  </Link>
                }
                description={item.description || item.title}
              />
            </List.Item>
          )}
        />
      </div>
    );
  }

  const Component = getContent(reportType);

  return (
    <>
      <SubTopMenuContext lastBreadcrumbName={'Report'} description={'report'} />

      <div className="bdtx-report">
        <Component action={action} />
      </div>
    </>
  );
};

export default compose(
  withUserInfo,
  withRouter,
)(ReportBdtxDashboard);
