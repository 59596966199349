import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import { Icon } from 'antd';

const SubmittedPerTotal = ({
  submitted,
  total,
  mode = 'auto',
  noMarking = false,
}) => {
  const label =
    mode == 'auto' ? 'Số câu máy chấm/tổng ' : 'Số câu tự luận/tổng ';

  const help = (
    <DetailOnDialog
      renderPreview={({ showFull }) => (
        <span
          onClick={showFull}
          title={'Click để xem giải thích chi tiết'}
          className="cursor-pointer m-l-5"
        >
          <Icon type="question-circle" />
        </span>
      )}
      renderFull={() => {
        if (mode == 'auto') {
          return (
            <div>
              Câu hỏi máy chấm là những câu hỏi máy tính có thể chấm tự động dựa
              vào đáp án có sẵn như câu hỏi trắc nghiệm, ghép cặp....
            </div>
          );
        } else {
          return (
            <div>
              Câu hỏi tự luận là những câu hỏi hệ thống không thể chấm tự động
              mà cần có sự đánh giá của người hướng dẫn.
            </div>
          );
        }
      }}
      dialogOptionsProperties={{
        handleClose: true,
        title: 'Giải thích chi tiết',
      }}
    />
  );

  return (
    <div>
      <span>
        {label}: {submitted}/{total} {help}
      </span>
    </div>
  );
};

export default SubmittedPerTotal;
