import React from 'react';
import lodashGet from 'lodash.get';
import './TextStructureAnswerOverview.scss';
import { getQuestionAnswerTexts } from '../util';
import { stripHTML } from 'common/utils/string';

class TextStructureAnswerOverview extends React.Component {
  render() {
    const { question, take } = this.props;
    const userInputs = lodashGet(question, 'answer.content').map((i) => {
      return lodashGet(i, 'content');
    });

    const questionAnswerTexts = getQuestionAnswerTexts(question);

    if (Array.isArray(questionAnswerTexts) && questionAnswerTexts.length) {
      return (
        <div>
          <ul className="user-answer">
            {questionAnswerTexts.map((text, idx) => {
              return (
                <li key={`${idx}-structure`}>
                  <span className="text-primary">{stripHTML(text)}</span>{' '}
                  <span>{stripHTML(userInputs[idx])}</span>
                </li>
              );
            })}
          </ul>
        </div>
      );
    }

    return null;
  }
}

export default TextStructureAnswerOverview;
