import React from 'react';
import Modal from 'antd/lib/modal';
import { Alert } from 'antd';
import get from 'lodash.get';
import store from 'store';
import sagaActions from 'actions/node/saga-creators';
import actions from 'actions/node/creators';
import endpoints from 'components/bdtx/choose-modules/endpoints';
import CommonAntdTable from 'components/common/antd/table';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';

const DIALOG_KEY = 'dialog-confirm-cancel-chosen-module';

const CancelChosenModule = ({
  module,
  chuongTrinh,
  organization,
  cap,
  targetUser,
  searchFormId,
}) => {
  const renderTableColumnsForContentDialog = () => {
    return [
      {
        title: 'Tên chương trình',
        key: 'name',
        dataIndex: 'name',
        render: (name) => <span>{name}</span>,
      },
      {
        title: 'Đơn vị',
        render: (item) => {
          const organizationName = get(item, '__expand.organizations.0.name');

          return <span>{organizationName}</span>;
        },
      },
      {
        title: 'Tổng số lớp',
        type: TYPE_OF_TABLE_DATA.TITLE,
        key: 'number_of_courses',
        dataIndex: 'number_of_courses',
        render: (courses) => {
          return <span>{courses || 0}</span>;
        },
      },
      {
        title: 'Tổng số học viên',
        type: TYPE_OF_TABLE_DATA.TITLE,
        key: 'number_of_members',
        dataIndex: 'number_of_members',
        render: (members) => {
          return <span>{members || 0}</span>;
        },
      },
      {
        title: 'Danh sách học viên (đạt / tổng)',
        type: TYPE_OF_TABLE_DATA.TITLE,
        render: (item) => {
          const passed = get(item, 'stats.passed', 0);
          const members = get(item, 'number_of_members', 0);

          return (
            <>
              <span className="text-primary">{passed}</span>/{members}
            </>
          );
        },
      },
    ];
  };

  const closeDialog = () => {
    store.dispatch(actions.handleOpenDialog({ openDialog: false }, DIALOG_KEY));
  };

  const renderContentDialog = (information) => {
    const organizationName = get(organization, 'name');

    return (
      <>
        <Alert
          message={`Nếu bỏ chọn mô đun: ${get(
            module,
            'name',
          )} cho đơn vị: ${organizationName}, thì toàn bộ các dữ liệu của đơn vị này cũng sẽ bị xóa. Ví dụ: Các kế hoạch triển khai, các lớp học, điểm của học viên ... `}
          type="warning"
        />

        {information && information.length ? (
          <CommonAntdTable
            dataSource={information}
            columns={renderTableColumnsForContentDialog()}
            pagination={false}
            className="learn-default m-t-15"
          />
        ) : null}

        <div className="m-t-15 text-center">
          <div className="m-b-15">Bạn có thực sự muốn bỏ chọn mô đun này?</div>
          <button className="btn btn-secondary m-l-15" onClick={closeDialog}>
            Không bỏ
          </button>
          <button
            className="btn btn-tertiary m-l-15"
            onClick={cancelChosenModule}
          >
            Bỏ chọn
          </button>
        </div>
      </>
    );
  };

  const showConfirmation = (information) => {
    const optionsProperties = {
      handleClose: true,
      title: 'Bỏ chọn mô đun cho đơn vị',
      modal: true,
    };

    store.dispatch(
      actions.handleOpenDialog(
        {
          contentDialog: renderContentDialog(information),
          optionsProperties,
        },
        DIALOG_KEY,
      ),
    );
  };

  const showGetInfoError = () => {
    Modal.error({
      centered: true,
      content:
        'Quá trình bỏ chọn mô đun cho đơn vị này gặp lỗi, vui lòng thử lại!',
    });
  };

  const cancelChosenModule = () => {
    const payload = {
      apiUrl: endpoints.bdtx_cancel_chosen_module_for_organization,
      data: {
        module_iid: get(module, 'iid'),
        chuong_trinh: chuongTrinh,
        cap: cap,
        target_user: targetUser,
        organization_iid: get(organization, 'iid'),
      },
      requestSuccessful: closeDialog,
      searchFormId: searchFormId,
    };

    store.dispatch(sagaActions.upsertNodeRequest(payload));
  };

  const getDeploymentInfo = () => {
    store.dispatch(
      sagaActions.getDataRequest(
        {
          url: endpoints.bdtx_get_deployment_status_to_unchoosing,
          executeOnSuccess: (deploymentInfo) => {
            showConfirmation(deploymentInfo);
          },
          executeOnFailure: showGetInfoError,
        },
        {
          module_iid: get(module, 'iid'),
          chuong_trinh: chuongTrinh,
          cap: cap,
          target_user: targetUser,
          organization_iid: get(organization, 'iid'),
        },
      ),
    );
  };

  return (
    <button
      className="btn-outline btn-small btn-tertiary"
      onClick={getDeploymentInfo}
    >
      Bỏ chọn
    </button>
  );
};

export default CancelChosenModule;
