import React from 'react';
import DisplayHtml from 'components/common/html/index';
import lodashGet from 'lodash.get';
import { types as questionTypes } from 'components/admin/question/schema/question-types';
import { t1 } from 'translate';

const Module03Tuluan = () => {
  return (
    <div>
      <strong>Thang đánh giá cho mỗi câu hỏi với mức 10 điểm như sau:</strong>
      <table>
        <thead>
          <th>Mức 5 (90-100 điểm)</th>
          <th>Mức 4 (7-8 điểm)</th>
          <th>Mức 3 (5-6 điểm)</th>
          <th>Mức 2 (3-4 điểm)</th>
          <th>Mức 1 (0-2 điểm)</th>
        </thead>
        <tbody>
          <tr>
            <td>
              Trả lời được câu hỏi với đủ ý, ngắn gọn. <br />
              Cấu trúc câu trả lời logic. <br />
              Giải thích với các minh chứng cụ thể.
            </td>
            <td>
              Trả lời được câu hỏi với đủ ý. <br />
              Giải thích vấn đề còn mang tính lí thuyết.
            </td>
            <td>
              Trả lời câu hỏi, có thể thiếu 1 vài ý. Diễn đạt câu trả lời dài,
              rối.
            </td>
            <td>Trả lời câu hỏi với vài ý có liên quan đến vấn đề được hỏi.</td>
            <td>
              Chưa trả lời câu hỏi hoặc nêu được sơ lược 1 vài ý có liên quan
              hoặc không liên quan vấn đề được hỏi.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const Module03CongCuPart1 = () => {
  return (
    <div>
      <strong>
        Kế hoạch đánh giá cho chủ đề/bài học (theo yêu cầu cần đạt, 30 điểm)
      </strong>
      <i>Gợi ý sử dụng checklist</i>
      <table>
        <thead>
          <th>Tiêu chí</th>
          <th>Có</th>
          <th>Không</th>
        </thead>
        <tbody>
          <tr>
            <td>Xác định các NL cần đánh giá trong chủ đề</td>
            <td>5 điểm</td>
            <td />
          </tr>
          <tr>
            <td>
              Xác định các yêu cầu cần đạt cần đánh giá tương ứng với mỗi năng
              lực.
            </td>
            <td>10 điểm</td>
            <td />
          </tr>
          <tr>
            <td>Xác định phương pháp đánh giá phù hợp.</td>
            <td>5 điểm</td>
            <td />
          </tr>
          <tr>
            <td>Xác định công cụ đánh giá hợp lý.</td>
            <td>5 điểm</td>
            <td />
          </tr>
          <tr>
            <td>Xác định thời điểm đánh giá phù hợp.</td>
            <td>5 điểm</td>
            <td />
          </tr>

          <tr>
            <td>Tổng điểm</td>
            <td>5 điểm</td>
            <td />
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const Module03CongCuPart2 = () => {
  return (
    <div>
      <strong>Bộ công cụ đánh giá theo kế hoạch trên (40 điểm) </strong>
      <div>
        Tùy theo chủ đề mà có thể có số lượng công cụ phù hợp. Tuy nhiên, mỗi
        chủ đề nên xây dựng 3 - 5 loại công cụ khác nhau (câu hỏi, bài tập, bảng
        kiểm, thang đo, rubrics,…).
      </div>
      <i>Gợi ý tiêu chí đánh giá nội dung này như sau:</i>

      <table>
        <thead>
          <th />
        </thead>
      </table>
    </div>
  );
};

const Module03CongCu = () => {
  return (
    <div>
      <Module03CongCuPart1 />
      <Module03CongCuPart2 />
    </div>
  );
};

const MarkingGuide = ({ question, forStudent = false }) => {
  if (
    question &&
    lodashGet(question, 'type') == questionTypes.TYPE_OPEN_ENDED
  ) {
    if (forStudent && !lodashGet(question, 'display_marking_guide_for_student'))
      return null;

    const tpl = lodashGet(question, 'marking_guide_template');
    if (tpl == 'module03-tuluan') {
      return <Module03Tuluan />;
    }
    if (tpl == 'module03-congcu') {
      return <Module03CongCu />;
    }

    let guide = lodashGet(question, 'marking_guide');
    if (!guide || guide === '<p><br></p>') {
      return null;
    }

    return (
      <div className="m-t-15 marking-guide">
        <h3>{t1('marking_guide')}</h3>

        <DisplayHtml content={guide} />
      </div>
    );
  }

  return null;
};

export default MarkingGuide;
