import {
  SHOW_SUPPORT_USER_INFO_NOTIFICATION,
  HIDE_SUPPORT_USER_INFO_NOTIFICATION,
  RESET_SUPPORT_USER_INFO_NOTIFICATION_OPEN_STATE,
} from 'actions/client-settings';

import { getSupportTime } from 'utils/Util';

const SUPPORT_TIME = getSupportTime() || {};

const initialState = {
  supportUserInfoNotification: {
    opening: false,
    turnOff: false,
    checked: false,
    nextTimeToChecking: undefined,
  },
};

const clientSettingsReducer = (state = initialState, action) => {
  let newState;
  const { supportUserInfoNotification } = state;
  switch (action.type) {
    case SHOW_SUPPORT_USER_INFO_NOTIFICATION: {
      newState = {
        ...state,
        supportUserInfoNotification: {
          ...supportUserInfoNotification,
          opening: true,
          checked: true,
        },
      };
      break;
    }
    case HIDE_SUPPORT_USER_INFO_NOTIFICATION: {
      const { turnOff = false, skipped = false } = action;
      const now = new Date();
      const hours = now.getHours();
      const date = now.getDate();
      const nextTime = new Date(
        now.setDate(
          // if current time in the period from 00:00 to 7 am
          // then set time to show to 10 pm of current day.
          hours < SUPPORT_TIME.from ? date : date + 1,
        ),
      );
      nextTime.setHours(SUPPORT_TIME.to, 0, 0, 0);

      // if turnOff >> do not show notification again.
      // if skipped >> should show notification on next day.
      // else should show notification when refresh.
      newState = {
        ...state,
        supportUserInfoNotification: {
          ...supportUserInfoNotification,
          opening: false,
          checked: !skipped,
          turnOff,
          nextTimeToChecking: !turnOff && skipped ? nextTime : undefined,
        },
      };
      break;
    }
    case RESET_SUPPORT_USER_INFO_NOTIFICATION_OPEN_STATE: {
      newState = {
        ...state,
        supportUserInfoNotification: {
          ...supportUserInfoNotification,
          opening: false,
          checked: false,
        },
      };
      break;
    }
    default: {
      return state;
    }
  }
  return newState;
};

export default clientSettingsReducer;
