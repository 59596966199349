import { t1 } from 'translate';
import { courseLearningTypes } from 'configs/constants';

const courseLearningTypesOptions = () => {
  return [
    {
      value: courseLearningTypes.ILT,
      label: t1('ilt'),
    },
    {
      value: courseLearningTypes.ONLINE,
      label: t1('online'),
    },
  ];
};

export const courseLearningTypesMulti = ({
  defaultValue = [courseLearningTypes.ONLINE, courseLearningTypes.ILT],
  label = t1('learning_type'),
  fullWidth = true,
} = {}) => {
  return {
    type: 'multiCheckbox',
    floatingLabelText: label,
    floatingLabelFixed: true,
    options: courseLearningTypesOptions(),
    defaultValue,
    fullWidth,
  };
};

export const courseLearningTypesOne = ({
  defaultValue = courseLearningTypes.ILT,
  label = t1('learning_type'),
  fullWidth = true,
  inline = true,
  wrapperClass,
} = {}) => {
  return {
    type: 'radio',
    floatingLabelText: label,
    floatingLabelFixed: true,
    options: courseLearningTypesOptions(),
    defaultValue,
    fullWidth,
    inline,
    wrapperClass,
  };
};
