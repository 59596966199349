import React from 'react';
import { t1 } from 'translate';

const ShowMore = ({ list, itemShowLess = 2 }) => {
  const [showFull, setShowFull] = React.useState(false);

  const items = showFull ? list : list.slice(0, itemShowLess).concat(['...']);

  return (
    <div>
      {items.join(', ')}

      <div className="show-more">
        (
        <a onClick={() => setShowFull(!showFull)} href="#">
          {t1(showFull ? 'show_less' : 'show_more')}
        </a>
        )
      </div>
    </div>
  );
};

const ShowListMore = ({ list, itemShowLess = 2 }) => {
  if (!Array.isArray(list) || !list.length) {
    return null;
  }

  if (list.length <= itemShowLess) {
    return list.join(', ');
  }

  return <ShowMore list={list} itemShowLess={itemShowLess} />;
};

export default ShowListMore;
