import React from 'react';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import TableResult from './TableResult';
import { ntype } from 'configs/constants';
import apiUrls from 'api-endpoints';

const FORM_ID = 'list-pubic-program';

const PublicProgram = () => {
  const renderResultsComponent = (items) => {
    return <TableResult items={items} />;
  };

  const renderNoResultComponent = () => {
    return <SimpleNoResult text="Không có chương trình công khai" />;
  };

  return (
    <SearchWrapper
      formid={FORM_ID}
      schema={{}}
      alternativeApi={apiUrls.program_search}
      autoSearchWhenStart
      autoSearchWhenValuesChange
      showSearchButton={false}
      renderResultsComponent={renderResultsComponent}
      renderNoResultComponent={renderNoResultComponent}
      hiddenFields={{
        type: ntype.PROGRAM,
        program_type: [ntype.PROGRAM],
        is_public: 1,
      }}
    />
  );
};

export default PublicProgram;
