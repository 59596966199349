import React from 'react';
import { flattenAllChildrenInSCOSPL } from 'components/learn/nav/nav-generator';
import sagaActions from 'actions/saga-creators';
import videoTypes from 'components/admin/video/schema/videoTypes';
import { ntype } from 'configs/constants';
import { allItemsAreDone, isItemDone } from 'common/learn';
import { isScoSpl } from 'components/admin/syllabus/utils';

const isItemHandleSavingProgressByItself = (item) => {
  return (
    item.ntype === ntype.EXERCISE ||
    item.type === videoTypes.TYPE_VIDEO ||
    item.type === videoTypes.TYPE_AUDIO ||
    item.type === videoTypes.TYPE_PDF ||
    item.type === videoTypes.TYPE_LINK
  );
};

const saveProgressForBlock = (
  nodes,
  itemIid,
  courseIid,
  trackerProgress,
  p,
  cp,
  dispatch,
) => {
  const item = nodes[itemIid];

  if (!item || isItemHandleSavingProgressByItself(item)) {
    return;
  }

  if (isItemDone(trackerProgress, itemIid)) {
    return;
  }

  dispatch(
    sagaActions.trackerProgressSave({
      progress: [{ tco_iid: itemIid, p, cp }],
      ciid: courseIid,
    }),
  );
};

export const useProgressSavingForChildrenOfSCOSPL = (
  scoSPLIid,
  courseIid,
  nodes,
  trackerProgress,
  dispatch,
  nestedScoSPL,
) => {
  const maxCallTimes = 1;
  const [callTimes, setCallTimes] = React.useState(0);

  React.useEffect(
    () => {
      if (!courseIid) {
        return;
      }

      const spl = nodes[scoSPLIid];

      const shouldIncludeScoNoneSPL =
        isScoSpl(spl) || (!isScoSpl(spl) && nestedScoSPL);

      if (
        !spl ||
        !shouldIncludeScoNoneSPL ||
        isItemDone(trackerProgress, scoSPLIid) ||
        callTimes >= maxCallTimes
      ) {
        return;
      }

      const allTCOsWithoutSequential = flattenAllChildrenInSCOSPL(
        spl,
        nodes,
      ).filter(Boolean);

      const allTCOsWithoutSavingIfSelf = allTCOsWithoutSequential.filter(
        (tco) => {
          return !isItemHandleSavingProgressByItself(tco);
        },
      );

      let tcosNotDone = allTCOsWithoutSavingIfSelf.filter((tco) => {
        return !isItemDone(trackerProgress, tco.iid);
      });

      const includeSequential = true;
      const allTCOs = flattenAllChildrenInSCOSPL(spl, nodes, includeSequential);
      // When all children are done, we need to save progress for one children to re-calculate progress of parent.
      if (allTCOs.length && allItemsAreDone(trackerProgress, allTCOs)) {
        tcosNotDone = [allTCOs[0]];
      }

      if (!tcosNotDone.length) {
        return;
      }

      const p = 100;
      const cp = 100;

      const progresses = tcosNotDone.map((tco) => ({
        tco_iid: tco.iid,
        p,
        cp,
      }));

      dispatch(
        sagaActions.trackerProgressSave({
          progress: progresses,
          ciid: courseIid,
        }),
      );

      setCallTimes(callTimes + 1);
    },
    [
      scoSPLIid,
      courseIid,
      nodes,
      trackerProgress,
      dispatch,
      callTimes,
      nestedScoSPL,
    ],
  );
};

export const useProgressSaving = (
  itemIid,
  courseIid,
  nodes,
  trackerProgress,
  dispatch,
  shouldSelfSavingProgress = true,
) => {
  React.useEffect(
    () => {
      if (!courseIid || !shouldSelfSavingProgress) {
        return;
      }

      const p = 100;
      const cp = 100;

      saveProgressForBlock(
        nodes,
        itemIid,
        courseIid,
        trackerProgress,
        p,
        cp,
        dispatch,
      );
    },
    [
      itemIid,
      courseIid,
      nodes,
      trackerProgress,
      dispatch,
      shouldSelfSavingProgress,
    ],
  );
};
