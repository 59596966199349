/**
 * Created by vohung on 22/05/2017.
 */
import { call, put, takeEvery } from 'redux-saga/effects';
import Requester from 'common/network/http/Request';
import apiUrls from 'api-endpoints';
import actions from 'actions/creators';
import Perm from 'common/utils/Perm';
import { doingStatuses } from 'common/learn/exercise';

function* trackerProgressSave(data) {
  try {
    if (Perm.isGuest()) {
      return;
    }
    const { params, updateToStoreAfterSuccess, executeOnSuccess } = data;

    const url = apiUrls.tracker_progress_save();

    yield call(
      executeTrackerProgressAction,
      url,
      params,
      updateToStoreAfterSuccess,
      executeOnSuccess,
    );
  } catch (e) {
    console.log(e);
  }
}

function* executeTrackerProgressAction(
  url,
  params,
  updateToStoreAfterSuccess,
  executeOnSuccess,
) {
  const post = yield call(Requester.post, url, params, null, false);

  const { doing_status, progress = [] } = params;
  const { tco_iid } = progress[0] || {};

  if (post && post.success && post.result) {
    if (typeof executeOnSuccess === 'function') {
      executeOnSuccess(post.result);
    }

    if (updateToStoreAfterSuccess) {
      yield put(actions.saveProgressMulti(post.result));
    }

    if (doing_status === doingStatuses.FINISHED && !!tco_iid) {
      yield put(actions.saveFinalProgressSuccess(tco_iid));
    }
  } else if (!post || !post.success) {
    if (doing_status === doingStatuses.FINISHED && !!tco_iid) {
      yield put(actions.saveFinalProgressFailed(tco_iid));
    }

    console.log('Tracker failed');
  }
}

function* trackerProgressGet(data) {
  try {
    if (Perm.isGuest()) {
      return;
    }
    const { params, updateToStoreAfterSuccess, executeOnSuccess } = data;
    const url = apiUrls.tracker_progress_get();
    yield call(
      executeTrackerProgressAction,
      url,
      params,
      updateToStoreAfterSuccess,
      executeOnSuccess,
    );
  } catch (e) {
    console.log(e);
  }
}

export default function* trackerProgressSaga() {
  yield takeEvery('TRACKER_PROGRESS_SAVE', trackerProgressSave);
  yield takeEvery('TRACKER_PROGRESS_GET', trackerProgressGet);
}
