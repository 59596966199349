import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { lightBaseTheme, MuiThemeProvider } from 'material-ui/styles';
import { PersistGate } from 'redux-persist/integration/react';
import { renderRoutes } from 'react-router-config';
import store, { history, persistor } from './store';
import appRouters from './routes/register';
import { MediaGroupContextProvider } from 'components/common/enhanced-react-player/media-group';
import { EnabledFeaturesContextProvider } from 'feature-flag/contexts/enabled-features';
import initTracer from './tracer';
import initDebuggers from './initDebuggers';

const lightMuiTheme = getMuiTheme(lightBaseTheme);

window.rootAppContainer = document.getElementById('root');

initTracer();
initDebuggers({ store });

const loading = <div>persistor loading...</div>;

/**
 * the requirement is that there is at most one active media player on screen
 * so we need MediaGroupContextProvider,
 * if the requirement change, you can remove it
 */
render(
  <EnabledFeaturesContextProvider>
    <Provider store={store}>
      <PersistGate loading={loading} persistor={persistor}>
        <MuiThemeProvider muiTheme={lightMuiTheme}>
          <MediaGroupContextProvider>
            <ConnectedRouter history={history}>
              {renderRoutes(appRouters)}
            </ConnectedRouter>
          </MediaGroupContextProvider>
        </MuiThemeProvider>
      </PersistGate>
    </Provider>
  </EnabledFeaturesContextProvider>,
  window.rootAppContainer,
);
