import { enrolmentPlanStatuses } from 'configs/constants/enrolmentPlan';
import lodashGet from 'lodash.get';
import lodashIntersection from 'lodash.intersection';
import nodeActions from 'actions/node/creators';
import Store from 'store';
import allUserRts from 'configs/constants/group-members-relationship-types';
import { checkIfAllCreditSyllabusesAreOnlineOnlyGivenConf } from 'common/conf';
import {
  epCanAddMemberManually,
  isTaphuanSmartEpV2SupplementaryTrainingPlan,
  shouldUseTaphuanSmartCotCanLearningFlow,
} from 'components/admin/training-plan/common';
import Perm from 'common/utils/Perm';

// currently, this logic is scattered in both client and server, in the future,
// we should improve it by using a better workflow mechanism
export const getOrderOfStatusesInEnrolmentPlanWorkflow = () => [
  enrolmentPlanStatuses.CREATED,
  enrolmentPlanStatuses.APPROVED,
  enrolmentPlanStatuses.READY_TO_EXECUTE,
  enrolmentPlanStatuses.EXECUTED,
  enrolmentPlanStatuses.STOPPED,
];

export const getPossibleNextStatusOfAnEnrolmentPlan = (enrolmentPlan) => {
  const orderOfStatusesInEnrolmentPlanWorkflow = getOrderOfStatusesInEnrolmentPlanWorkflow();
  return orderOfStatusesInEnrolmentPlanWorkflow[
    orderOfStatusesInEnrolmentPlanWorkflow.indexOf(
      lodashGet(enrolmentPlan, 'status'),
    ) + 1
  ];
};

export const getStatusesThatEnrolmentPlanHasAlreadyPassed = (enrolmentPlan) => {
  const orderOfStatusesInEnrolmentPlanWorkflow = getOrderOfStatusesInEnrolmentPlanWorkflow();
  return orderOfStatusesInEnrolmentPlanWorkflow.slice(
    0,
    orderOfStatusesInEnrolmentPlanWorkflow.indexOf(
      lodashGet(enrolmentPlan, 'status'),
    ) + 1,
  );
};

export const isEnrolmentPlanInStatusThatCanBeApproved = (enrolmentPlan) =>
  getPossibleNextStatusOfAnEnrolmentPlan(enrolmentPlan) ===
  enrolmentPlanStatuses.APPROVED;

export const isEnrolmentPlanInStatusThatCanBeUnApproved = (enrolmentPlan) =>
  lodashGet(enrolmentPlan, 'status') === enrolmentPlanStatuses.APPROVED;

export const isEnrolmentPlanApproved = (enrolmentPlan) =>
  getStatusesThatEnrolmentPlanHasAlreadyPassed(enrolmentPlan).includes(
    enrolmentPlanStatuses.APPROVED,
  );

export const canEnrolmentPlanInfoBeEdited = (enrolmentPlan) =>
  isEnrolmentPlanApproved(enrolmentPlan);

export const canEnrolmentPlanInfoBeExtended = (enrolmentPlan) => false;
// isEnrolmentPlanApproved(enrolmentPlan);

export const canEnrolmentPlanMembersBeApprovedAndRejected = (
  enrolmentPlan,
  memberRt,
) => [allUserRts.USER_RT_PENDING].includes(memberRt);

export const canEnrolmentPlanMembersBeApproved = (enrolmentPlan, memberRt) =>
  canEnrolmentPlanMembersBeApprovedAndRejected(enrolmentPlan, memberRt) ||
  [allUserRts.USER_RT_REJECTED].includes(memberRt);

export const canEnrolmentPlanMembersBeRejected = (enrolmentPlan, memberRt) =>
  canEnrolmentPlanMembersBeApprovedAndRejected(enrolmentPlan, memberRt) ||
  [allUserRts.USER_RT_CURRENT].includes(memberRt);

export const isEnrolmentPlanMembersApproved = (enrolmentPlan, memberRt) =>
  [allUserRts.USER_RT_CURRENT].includes(memberRt);

export const isEnrolmentPlanMembersRejected = (enrolmentPlan, memberRt) =>
  [allUserRts.USER_RT_REJECTED].includes(memberRt);

export const isEnrolmentPlanInStatusThatCanTurnIntoReadyToExecute = (
  enrolmentPlan,
) =>
  getPossibleNextStatusOfAnEnrolmentPlan(enrolmentPlan) ===
  enrolmentPlanStatuses.READY_TO_EXECUTE;

export const isEnrolmentPlanExecuted = (enrolmentPlan) =>
  lodashGet(enrolmentPlan, 'status') === enrolmentPlanStatuses.EXECUTED;

export const isEnrolmentPlanStopped = (enrolmentPlan) =>
  lodashGet(enrolmentPlan, 'status') === enrolmentPlanStatuses.STOPPED;

export const isEnrolmentPlanSharedFromAncestorOrganizations = (enrolmentPlan) =>
  lodashGet(enrolmentPlan, 'is_shared_from_ancestor_organizations');

// whether EP is created in the same org as user's org
export const isEnrolmentOrganizationMyOrganization = (user, enrolmentPlan) => {
  const intersected = lodashIntersection(
    lodashGet(user, 'user_organizations'),
    lodashGet(enrolmentPlan, 'organizations'),
  );
  return intersected.length > 0;
};

export const canUserEditChildrenOfEnrolmentPlanProgram = (
  enrolmentPlanProgram,
) => true;

export const canUserOnlyAddOnlineOnlyCreditToEnrolmentPlanProgram = (
  enrolmentPlanProgram,
) =>
  Boolean(lodashGet(enrolmentPlanProgram, 'can_only_add_online_only_credit'));

export const checkIfNeedToInviteAllEnrolmentPlanMembersToAllCreditSyllabuses = (
  enrolmentPlan,
) =>
  lodashGet(
    enrolmentPlan,
    'settings.need_to_invite_all_members_to_all_credit_syllabuses',
  );

export const fetchEnrolmentPlanData = (enrolmentPlan) => {
  Store.dispatch(
    nodeActions.fetchNode({
      iid: enrolmentPlan && enrolmentPlan.iid,
      ntype: 'enrolment_plan',
    }),
  );

  Store.dispatch(
    nodeActions.fetchNode({
      iid: enrolmentPlan && enrolmentPlan.group_iid,
      ntype: 'category',
    }),
  );

  Store.dispatch(
    nodeActions.fetchNode({
      iid: enrolmentPlan && enrolmentPlan.program_iid,
      ntype: 'path',
    }),
  );
};

export const getSearchFormId = () => 'enrolment_plan_search';

export const isEnrolmentPlanHasTrainingPlan = (enrolmentPlan) =>
  enrolmentPlan && enrolmentPlan.training_plan_iid;

export const doesEnrolmentPlanMembersNeedApprovalGivenConf = (conf) => {
  if (window.isETEP) {
    return false;
  }

  return !checkIfAllCreditSyllabusesAreOnlineOnlyGivenConf(conf);
};

export const hasCertificate = (ep = {}) =>
  ep &&
  (ep.certificate_template_ids || ep.participate_certificate_template_ids);

export const hasParticipatingCertificate = (ep) =>
  ep &&
  ep.participate_certificate_template_ids &&
  ep.participate_certificate_template_ids.length;

export const canAddEnrolmentPlanMembers = (
  enrolmentPlan,
  trainingPlanOfEnrolmentPlan,
) => {
  return (
    (Perm.hasPerm('root') && epCanAddMemberManually(enrolmentPlan)) ||
    (!shouldUseTaphuanSmartCotCanLearningFlow(trainingPlanOfEnrolmentPlan) &&
      !isTaphuanSmartEpV2SupplementaryTrainingPlan(
        trainingPlanOfEnrolmentPlan,
      ) &&
      !lodashGet(enrolmentPlan, 'is_taphuan_smart_enrolment_plan_v2'))
  );
};
export const canAddTeachersToEnrolmentPlan = (
  enrolmentPlan,
  trainingPlanOfEnrolmentPlan,
) => {
  return true;
};

export const enrolmentPlanUseCotCanAsTeacher = (enrolmentPlan) => {
  return lodashGet(enrolmentPlan, 'use_cot_can_as_teacher');
};

export const enrolmentPlanUseSchoolGroupLogic = (enrolmentPlan) => {
  return lodashGet(enrolmentPlan, 'use_school_group_logic');
};

export const getSpecialUsersThatCanViewEnrolmentPlanMembersFromConfig = (
  specialUsersThatCanViewEnrolmentPlanMembersConf,
  enrolmentPlanIid,
) => {
  const epConf = (specialUsersThatCanViewEnrolmentPlanMembersConf || []).find(
    (c) => lodashGet(c, 'enrolment_plan_iid') == enrolmentPlanIid,
  );

  return lodashGet(epConf, 'viewer_iids');
};
