import React from 'react';
import AntdTable from 'antd/lib/table';
import get from 'lodash.get';
import RaisedButton from 'components/common/mui/RaisedButton';
import { t1 } from 'translate';
import Icon from 'components/common/Icon';
import ConfirmDialogImport from 'components/common/action-button/ApiRequestBtnWithConfirmDialog';

const isRowDisabled = (record) => {
  return (
    (Array.isArray(record && record.errors) && !!record.errors.length) ||
    get(record, 'status') === 'completed'
  );
};

const ImportResult = ({
  items,
  importId,
  searchFormId,
  columns,
  importApiUrl,
  otherImportParams,
  onImportSuccessful,
  searchImportedItemsFormId,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = React.useState(null);

  const handleImportSuccessful = () => {
    setSelectedRowKeys(null);

    if (typeof onImportSuccessful === 'function') {
      onImportSuccessful();
    }
  };

  return [
    <AntdTable
      columns={columns}
      dataSource={Array.isArray(items) ? items : []}
      bordered
      pagination={false}
      childrenColumnName={null}
      className={'learn-default'}
      rowKey={'id'}
      rowSelection={{
        selectedRowKeys,
        hideDefaultSelections: true,
        onChange: setSelectedRowKeys,
        getCheckboxProps: (record) => ({
          disabled: isRowDisabled(record),
        }),
      }}
      scroll={{ x: true }}
    />,

    <ConfirmDialogImport
      formIdsToSubmitOnSuccess={[
        searchFormId,
        searchImportedItemsFormId,
      ].filter(Boolean)}
      renderComponent={({ onClick }) => (
        <RaisedButton
          label={t1('import_%s_items_selected', [
            Array.isArray(selectedRowKeys) ? selectedRowKeys.length : 0,
          ])}
          className="m-r-10 m-t-10"
          secondary
          onClick={onClick}
          disabled={!Array.isArray(selectedRowKeys) || !selectedRowKeys.length}
          icon={<Icon icon={'import'} className="m-5" />}
        />
      )}
      title={t1(
        t1('import_%s_items_selected', [
          Array.isArray(selectedRowKeys) ? selectedRowKeys.length : 0,
        ]),
      )}
      url={importApiUrl}
      params={{
        ids: selectedRowKeys,
        importId,
        ...(otherImportParams || {}),
      }}
      textConfirm="Bạn có chắc chắn thực hiện điều này?"
      onRequestSuccessful={handleImportSuccessful}
    />,

    <ConfirmDialogImport
      formIdsToSubmitOnSuccess={[
        searchFormId,
        searchImportedItemsFormId,
      ].filter(Boolean)}
      renderComponent={({ onClick }) => (
        <RaisedButton
          label={t1('import_all_items_availabe')}
          className="m-r-10 m-t-10"
          secondary
          onClick={onClick}
          icon={<Icon icon={'import'} className="m-5" />}
        />
      )}
      title={t1('import_all_items_availabe')}
      url={importApiUrl}
      params={{
        importId,
        ...(otherImportParams || {}),
      }}
      textConfirm="Bạn có chắc chắn thực hiện điều này?"
      onRequestSuccessful={handleImportSuccessful}
    />,
  ];
};

export default ImportResult;
