import { t1 } from 'translate';

export const pageLayouts = {
  SCO_LAYOUT_LIST: 'list',
  SCO_LAYOUT_IMAGE_MAP: 'image-map',
};

export const pageLayoutSchema = {
  type: 'select',
  options: [
    {
      value: '',
      label: '-',
    },
    {
      value: pageLayouts.SCO_LAYOUT_LIST,
      label: t1('layout_list'),
    },
    {
      value: pageLayouts.SCO_LAYOUT_IMAGE_MAP,
      label: 'infographic',
    },
  ],
};
