import React from 'react';
import './style.scss';

const QuestionHint = ({ hint = '', handleClose }) => {
  return (
    <div className="question-hints">
      <span className="ve-close-outline" onClick={() => handleClose()} />
      {hint && <div>{hint}</div>}
    </div>
  );
};

export default QuestionHint;
