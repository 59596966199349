import React from 'react';
import get from 'lodash.get';
import fetchData from 'components/common/fetchData';
import Loading from 'components/common/loading';
import { Redirect } from 'react-router-dom';
import { loginOAuthSuccessRequest } from 'actions/auth/saga-creators';

class LoginWithViettelSSO extends React.Component {
  componentDidMount() {
    if (get(this.props, 'conf.sso_viettel.server_get_access_token')) {
      const { dispatch } = this.props;
      dispatch(
        loginOAuthSuccessRequest('viettel', null, {
          auth_code: decodeURI(get(this.props, 'authCode') || ''),
        }),
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      get(nextProps, 'conf.sso_viettel.server_get_access_token') &&
      !get(this.props, 'conf.sso_viettel.server_get_access_token')
    ) {
      const { dispatch } = this.props;
      dispatch(
        loginOAuthSuccessRequest('viettel', null, {
          auth_code: decodeURI(get(this.props, 'authCode') || ''),
        }),
      );
    }

    const accessToken = get(nextProps, 'token.access_token');

    if (accessToken && !get(this.props, 'token.access_token')) {
      const { dispatch } = this.props;
      dispatch(loginOAuthSuccessRequest('viettel', accessToken));
    }
  }

  render() {
    if (get(this.props, 'tokenLoadingStatus') !== 'finished') {
      return <Loading key={1} />;
    }

    if (!get(this.props, 'token.access_token')) {
      return <Redirect to="/" />;
    }

    return <Loading />;
  }
}

export default fetchData((props) => {
  const viettelClientId = get(props, 'conf.sso_viettel.client_id');
  const serverGetAccessToken = get(
    props,
    'conf.sso_viettel.server_get_access_token',
  );

  return {
    baseUrl: 'https://sso-edu.viettel.vn/api/Core/OAuth/AuthCode/accessToken',
    fetchCondition:
      !!get(props, 'authCode') && !!viettelClientId && !serverGetAccessToken,
    refetchCondition: () => false,
    params: (() => {
      const ssoViettel = get(props, 'conf.sso_viettel');
      return {
        ...ssoViettel,
        grant_type: 'authorization_code',
        code: decodeURI(get(props, 'authCode') || ''),
      };
    })(),
    loadingStatusPropKey: 'tokenLoadingStatus',
    method: 'post',
    getFullResponse: true,
    propKey: 'token',
  };
})(LoginWithViettelSSO);
