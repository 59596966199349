import React from 'react';
import { t1 } from 'translate';
import ViewForm from './Form';
import RaisedButton from 'components/common/mui/RaisedButton';
import DisplayHtml from 'components/common/html';
import lodashGet from 'lodash.get';
import { breadCrumb, stripHTML } from 'common/utils/string';
import DetailOnDialog from 'components/common/detail-on-dialog';
import ExportBtnWithConfirmDialog from 'components/common/action-button/ExportBtnWithConfirmDialog';

const OpenEndedQuestionAnswersByQuestionId = ({
  surveyIid,
  question,
  paramsToFilter = {},
  trainingPlan,
}) => {
  const questionAnswers = [
    <DetailOnDialog
      renderPreview={({ showFull }) => (
        <RaisedButton label={t1('view_answers')} onClick={showFull} />
      )}
      renderFull={() => (
        <div>
          <DisplayHtml content={lodashGet(question, 'content')} />
          <hr />
          <ViewForm
            questionIid={lodashGet(question, 'iid')}
            surveyIid={surveyIid}
            trainingPlan={trainingPlan}
            paramsToFilter={paramsToFilter}
          />
        </div>
      )}
      dialogOptionsProperties={{
        handleClose: true,
        width: '90%',
        title: t1('view_answers'),
        modal: true,
      }}
    />,
    <ExportBtnWithConfirmDialog
      primary
      newButton
      className="m-l-30"
      label={t1('export')}
      fileName={breadCrumb(stripHTML(lodashGet(question, 'content')), 50)}
      url="/survey/api/export-open-ended-answers"
      params={{
        survey_iid: surveyIid,
        question_iid: lodashGet(question, 'iid'),
        // item_iid: trainingPlan && trainingPlan.iid,
        ...paramsToFilter,
      }}
      fetchingCacheOnly
    />,
  ];

  return <div>{questionAnswers}</div>;
};

export default OpenEndedQuestionAnswersByQuestionId;
