import React, { Component } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import store from 'store';
import nodeActions from 'actions/node/creators';
import { t1 } from 'translate';
import notification from 'antd/lib/notification';

const withShowWarningOfPracticeMode = (WrappedComponent) => {
  class ShowWarningOfPracticeModeHOC extends Component {
    showWarning = () => {
      store.dispatch(nodeActions.snackbar('error', t1('incorrect_answer')));
    };

    hideWarning = () => {
      notification.destroy();
    };

    showSuccess = () => {
      notification.destroy();
      store.dispatch(nodeActions.snackbar('success', t1('correct_answer')));
    };

    render() {
      return (
        <WrappedComponent
          showWarningOfPracticeMode={this.showWarning}
          showSuccessOfPracticeMode={this.showSuccess}
          hideWarningOfPracticeMode={this.hideWarning}
          {...this.props}
        />
      );
    }
  }

  hoistNonReactStatic(ShowWarningOfPracticeModeHOC, WrappedComponent);

  return ShowWarningOfPracticeModeHOC;
};

export default withShowWarningOfPracticeMode;
