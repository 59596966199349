import React from 'react';
import lodashGet from 'lodash.get';
import { t1 } from 'translate';
import { Tag } from 'antd';
import DetailOnDialog from 'components/common/detail-on-dialog';
import SearchMembers from 'components/admin/group/edit/member/search';
import Icon from 'components/common/Icon';

const DisplayGroupMembers = ({
  item,
  showGroupName = true,
  displayOverview = true,
  extraMembers,
  canShowDetailMembers,
}) => {
  if (!item) {
    return Array.isArray(extraMembers) && extraMembers.length ? (
      <DisplayUserNames
        members={extraMembers}
        total={extraMembers.length}
        prefix={''}
      />
    ) : null;
  }
  const total =
    lodashGet(item, 'current_members', 0) ||
    lodashGet(extraMembers, 'length', 0);
  const firstMembers = lodashGet(item, '__expand.first_members');

  return (
    <DetailOnDialog
      renderPreview={({ showFull }) => {
        return (
          <div
            className={'cursor-pointer'}
            title={t1('click_to_view_member_details')}
            onClick={canShowDetailMembers ? showFull : () => {}}
          >
            {showGroupName ? (
              <div>
                {lodashGet(item, 'name')}{' '}
                <Tag className={'cursor-pointer'}>
                  <Icon icon={'user'} /> {total}
                </Tag>
              </div>
            ) : null}

            {displayOverview ? (
              <DisplayUserNames members={firstMembers} total={total} />
            ) : null}
            {Array.isArray(extraMembers) && extraMembers.length ? (
              <DisplayUserNames
                members={extraMembers}
                total={extraMembers.length}
                prefix={t1('additional_members')}
              />
            ) : null}
          </div>
        );
      }}
      renderFull={() => {
        return <SearchMembers group={item} action={'members'} />;
      }}
      dialogOptionsProperties={{
        title: t1('group_members'),
        modal: true,
      }}
      fullScreen
      dialogKey={'group_members_list'}
    />
  );
};

export default DisplayGroupMembers;

export const DisplayUserNames = ({
  members,
  total,
  prefix,
  maxDisplay = 10,
}) => {
  const names = Array.isArray(members)
    ? members.map((member, idx) => {
        if (idx <= maxDisplay) {
          return member.name;
        }
        return null;
      })
    : [];

  return (
    <div>
      {prefix ? `${prefix} : ` : ''}
      <span className={'text-muted'}>
        <span>{names.filter(Boolean).join(', ')}</span>
        {Array.isArray(members) && members.length > maxDisplay ? (
          <span>...</span>
        ) : null}
      </span>
    </div>
  );
};
