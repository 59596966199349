import { t1 } from 'translate';
import { convertBooleanValueToInt } from 'common/normalizers';

const needToAddToPreviousTpCategory = () => ({
  type: 'checkbox',
  classWrapper: 'col-md-6 m-t-15',
  label: t1('need_to_add_to_previous_tp_category'),
  normalize: convertBooleanValueToInt,
});

export default needToAddToPreviousTpCategory;
