import React from 'react';
import get from 'lodash.get';
import isEqual from 'lodash.isequal';
import FixedItem from '../FixedItem';
import MovableItem from '../MovableItem';
import DragAndDrop from '../../DnDProvider';
import CorrectAnswer from '../correct-answer';
import useCoreLogic from '../core-logic';
import ErrorMessageQuestion from '../../ErrorMessageQuestion';

const DDM = React.memo((props) => {
  const {
    className,
    question,
    shouldShowKey,
    disabled,
    userAnswers,
    setUserAnswers,
    singleDropped = true,
    practice,
    questionShuffleKey,
  } = props;

  const cssClass = 'ddm-question-type-box';
  const componentClassName = `${className || ''} ${cssClass}`;
  const [showError, setShowError] = React.useState(0);

  let [
    questionsData,
    answersData,
    handleDrop,
    handleAnsweredItemClick,
    checkAnswerIsCorrect,
  ] = useCoreLogic({
    userAnswers,
    question,
    setUserAnswers,
    disabled,
    singleDropped,
    practice,
    setShowError,
    questionShuffleKey,
  });
  return (
    <>
      <ErrorMessageQuestion question={question} error={showError} />

      <DragAndDrop>
        <div className={`${componentClassName} row`}>
          <div className={`col-md-6 col-xs-6 ${cssClass}__box-fixed`}>
            {questionsData.map((item, index) => {
              const answers = get(item, 'answers', []);
              const isAnswerCorrect = checkAnswerIsCorrect(answers, index);

              return (
                <div className={`${cssClass}__fixed m-b-5`} key={index}>
                  <FixedItem
                    className={`${cssClass}__item`}
                    accept={item.accepts}
                    answers={answers}
                    onDrop={(item) => {
                      handleDrop(index, item);
                    }}
                    key={index}
                    handleItemChildrenClick={handleAnsweredItemClick}
                    disabled={disabled}
                    isAnswerCorrect={isAnswerCorrect}
                    index={index}
                    content={item.content}
                    showContentAsHeader
                  />
                </div>
              );
            })}
          </div>
          <div className={`${cssClass}__moved col-md-6 col-xs-6`}>
            {answersData
              .map(
                (item, index) =>
                  !item.answered && (
                    <MovableItem
                      className={`${cssClass}__item`}
                      content={item.content}
                      type={item.type}
                      key={index}
                      answered={item.answered}
                    />
                  ),
              )
              .filter(Boolean)}
          </div>
        </div>
      </DragAndDrop>

      {shouldShowKey && <CorrectAnswer question={question} />}
    </>
  );
});

class DDMQuestionCheckPropsToUpdate extends React.Component {
  shouldComponentUpdate(prevProps, nextState, nextContext) {
    const {
      className,
      question,
      shouldShowKey,
      disabled,
      userAnswers,
      setUserAnswers,
      singleDropped = true,
      practice,
      showWarning,
      showSuccess,
      questionShuffleKey,
    } = this.props;

    const isPropsNoChange =
      isEqual(className, prevProps.className) &&
      isEqual(question, prevProps.question) &&
      isEqual(shouldShowKey, prevProps.shouldShowKey) &&
      isEqual(disabled, prevProps.disabled) &&
      isEqual(userAnswers, prevProps.userAnswers) &&
      isEqual(setUserAnswers, prevProps.setUserAnswers) &&
      isEqual(singleDropped, prevProps.singleDropped) &&
      isEqual(practice, prevProps.practice) &&
      isEqual(showWarning, prevProps.showWarning) &&
      isEqual(showSuccess, prevProps.showSuccess) &&
      isEqual(questionShuffleKey, prevProps.questionShuffleKey);

    return !isPropsNoChange;
  }

  render() {
    const {
      className,
      question,
      shouldShowKey,
      disabled,
      userAnswers,
      setUserAnswers,
      singleDropped = true,
      practice,
      questionShuffleKey,
    } = this.props;

    return (
      <DDM
        className={className}
        question={question}
        shouldShowKey={shouldShowKey}
        disabled={disabled}
        userAnswers={userAnswers}
        setUserAnswers={setUserAnswers}
        singleDropped={singleDropped}
        practice={practice}
        questionShuffleKey={questionShuffleKey}
      />
    );
  }
}

export default DDMQuestionCheckPropsToUpdate;
