import React from 'react';
import OverviewModuleMaterials from './search';
import MaterialPurchased from './materials-purchased';
import PublicProgram from './public-program';
import { CHUONG_TRINHS } from 'components/bdtx/configs';
import SubLeftMenuContext from 'common/context/menu/SubMenuLeft';
import SubTopMenuContext from 'common/context/menu/SubMenuTop';
import get from 'lodash.get';
import { VIEW_TYPE } from './utils';
import { Redirect, withRouter } from 'react-router';

const OverviewModule = ({ match }) => {
  const viewType = get(match, 'params.viewType');

  const menuItems = [
    {
      id: `overview-material-${VIEW_TYPE.CHOOSE_MATERIAL}`,
      url: `/admin/bdtx/overview-module-materials/${VIEW_TYPE.CHOOSE_MATERIAL}`,
      title: 'Chọn học liệu cho mô đun',
    },
    {
      id: `overview-material-${VIEW_TYPE.PURCHASED}`,
      url: `/admin/bdtx/overview-module-materials/${VIEW_TYPE.PURCHASED}`,
      title: 'Học liệu đã mua',
    },
    {
      id: `overview-material-${VIEW_TYPE.PUBLIC}`,
      url: `/admin/bdtx/overview-module-materials/${VIEW_TYPE.PUBLIC}`,
      title: 'Học liệu công khai',
    },
  ];

  if (!viewType) {
    return (
      <Redirect
        to={`/admin/bdtx/overview-module-materials/${
          VIEW_TYPE.CHOOSE_MATERIAL
        }`}
      />
    );
  }

  let content;
  switch (viewType) {
    case VIEW_TYPE.CHOOSE_MATERIAL:
      content = <OverviewModuleMaterials chuongTrinh={CHUONG_TRINHS} />;
      break;
    case VIEW_TYPE.PURCHASED:
      content = <MaterialPurchased />;
      break;
    case VIEW_TYPE.PUBLIC:
      content = <PublicProgram />;
      break;
  }

  return (
    <>
      <SubLeftMenuContext schema={menuItems} />

      <SubTopMenuContext
        lastBreadcrumbName="Tổng quan học liệu"
        hideBackAction
      />

      {content}
    </>
  );
};

export default withRouter(OverviewModule);
