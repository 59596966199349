/**
 * URL could be /learn[learnType]/$courseIid-$syllabusIid/$scoIid-$exerciseIid/item.html
 * where learnType could be one of the following values
 *
 * @type {{LEARN: string, PREVIEW_LEARN: string, OVERVIEW: string, SESSION_OFFLINE: string, IFRAME: string}}
 */
export const learningItemTypeViewers = {
  LEARN: 'learn', // Chỉ định cho màn hình học các item
  PREVIEW_LEARN: 'p-learn', // Mode này giúp giáo viên có thể preview được khóa học
  // REVIEW_LEARN: 'r-learn', // Sau khi học sinh hoàn thành khóa học được review lại các item đã học
  OVERVIEW: 'overview', // Chỉ định cho màn hình overview khóa học
  // PREVIEW_OVERVIEW: 'p-overview',
  // REVIEW_OVERVIEW: 'r-overview',
  // SESSION_OFFLINE: 'session-offline', // Dùng để hỗ trợ học sinh xem trước các item trong một buổi học offline
  IFRAME: 'iframe',
};
