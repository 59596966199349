import get from 'lodash.get';
import {
  input,
  radioElement,
  rte,
} from 'components/common/forms/schema-fields';
import { t1 } from 'translate';

export const linkTypes = {
  TYPE_EMBED: 'embed',
  TYPE_OPEN_LINK: 'open_link',
};

export const linkTypeOptions = [
  {
    value: linkTypes.TYPE_EMBED,
    label: t1('embed'),
  },
  {
    value: linkTypes.TYPE_OPEN_LINK,
    label: t1('open_link'),
  },
];

export const linkElementSchema = (videoType, variant) => {
  return {
    type: 'section',
    schema: {
      schema: {
        link: input(t1('link'), true),
        link_type: radioElement(t1('link_type'), linkTypeOptions, {
          defaultValue: linkTypes.TYPE_EMBED,
        }),
        description: rte(t1('description')),
      },
      ui: (step, values) => {
        const linkType = get(values, 'block_link.link_type');

        const fields = ['link', 'link_type'];
        if (linkType === linkTypes.TYPE_OPEN_LINK) {
          fields.push('description');
        }

        return [
          {
            id: 'link',
            fields: fields,
          },
        ];
      },
    },
  };
};
