import React from 'react';
import { Alert } from 'antd';

const WarningModuleNN = () => {
  return (
    <Alert
      message={
        <>
          Chú ý: Để học <b>Chương trình Ngoại Ngữ</b>, quý thầy/cô vui lòng chọn
          chương trình đào tạo là <b>BDTX</b>
        </>
      }
      showIcon
      type="warning"
      className="m-t-10"
    />
  );
};

export default WarningModuleNN;
