import './NormalExerciseResult.scss';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { t1 } from 'translate';
import withSchoolConfig from 'common/hoc/withSchoolConfigs';
import NormalExerciseResultWithSkillAnalysis from './result-with-skill-analysis';
import lodashGet from 'lodash.get';
import TotalPoint from 'components/learn/common/total-point/TotalPoint';
import {
  isMarkingPassFail,
  isNotOEExercise,
  shouldHideScore,
  waitForMarking,
} from 'common/learn/Question';
import ExerciseStatusOfQuestions from 'components/learn/common/exercise-status-of-questions';
import {
  shouldShowExerciseQuestionsStatus,
  shouldShowTakeHistory,
} from '../common/utils';
import PassOrFail from 'components/common/pass-or-fail';
import withGlobalDialogs from 'components/common/modal/withGlobalDialogs';
import RestoreHighestScoreTake from '../restore-highest-score-take/RestoreHighestScoreTake';
import TakeHistories from '../take-histories';
import withUserInfo from 'common/hoc/withUserInfo';
import { isCourseEnded } from 'components/admin/course/utils';
import { isFreeze } from 'utils/Util';
import { isSyllabusExpired } from 'components/admin/syllabus/utils';
import withServerTs from 'common/hoc/withServerTs';
import RedoExercise from 'components/learn/items/exercise/NormalExercise/result/redo-exercise';
import { portals } from 'components/common/portal';

const cssClass = 'normal-exercise-result';

const NormalExerciseResult = ({
  exercise = null,
  onNextButtonClick = null,
  onRedoButtonClick = null,
  onReviewButtonClick = null,
  options = null,
  positionOfCurrentItem = null,
  detailActionProps = null,
  result = null,
  learnInfo,
  onResumeButtonClick = null,
  shouldShowDetail = false,
  hasAction,
  course,
  isExerciseLoading = false,
  openDialog,
  closeDialog,
  userInfo,
  syllabus,
  serverTs,
}) => {
  const courseIid = lodashGet(course, 'iid');
  const canReview = lodashGet(options, 'can_review');
  const canResume = lodashGet(options, 'can_resume');
  const numberOfTimesUserCanDoExercise = lodashGet(options, 'can_do');

  // const canRedo =
  //   !numberOfTimesUserCanDoExercise || numberOfTimesUserCanDoExercise > 1;

  const shouldDisplaySkillAnalysisWhenDisplayExerciseResult = lodashGet(
    options,
    'show_result_by_skills',
  );

  const keepHighestScore = lodashGet(learnInfo, 'keep_highest_score');
  const highestScore = parseFloat(
    lodashGet(learnInfo, 'highestScoreTakeHistoryProgress.p'),
  );
  const currentScore = parseFloat(result);
  const takeId = lodashGet(learnInfo, 'takeId');
  const exerciseIid = lodashGet(learnInfo, 'iid');

  const [isDialogShown, setIsDialogShown] = useState(false);

  // use this effect to reset flag isDialogShown
  useEffect(
    () => {
      setIsDialogShown(false);
    },
    [currentScore, highestScore],
  );

  useEffect(
    () => {
      if (
        isCourseEnded(course, serverTs) ||
        isFreeze(course, serverTs) ||
        isSyllabusExpired(syllabus, serverTs)
      ) {
        return;
      }

      if (keepHighestScore && currentScore < highestScore && !isDialogShown) {
        const dialogKey = `restore-highest-take-${exerciseIid}-${courseIid}`;

        openDialog(dialogKey, {
          contentDialog: (
            <RestoreHighestScoreTake
              currentScore={currentScore}
              highestScore={highestScore}
              takeId={takeId}
              restoreSuccessful={() => {
                closeDialog(dialogKey);
              }}
              itemIid={exerciseIid}
              learnInfo={learnInfo}
            />
          ),
          optionsProperties: {
            title: t1('restore_highest_score_take_dialog_title'),
            handleClose: false,
            modal: true,
          },
        });

        setIsDialogShown(true);
      }
    },
    [
      course,
      keepHighestScore,
      currentScore,
      highestScore,
      takeId,
      exerciseIid,
      courseIid,
      learnInfo,
      openDialog,
      closeDialog,
      isDialogShown,
      setIsDialogShown,
      serverTs,
      syllabus,
    ],
  );
  const hideScore = shouldHideScore(exercise);
  return (
    <div className="test-result-wrapper learn-content-border">
      <div
        className={`quiz-content text-center ${cssClass} ${
          shouldDisplaySkillAnalysisWhenDisplayExerciseResult
            ? 'result-with-skills'
            : ''
        }`}
      >
        <span className={`${cssClass}__icon ve-done`} />

        <h3>{t1('exercises')}</h3>

        {!hideScore ? (
          <div className={`${cssClass}__point-section`}>
            <span className={`${cssClass}__point-label m-r-5`}>
              {t1('point')}:
            </span>
            {!isNotOEExercise(exercise) && waitForMarking(result) ? (
              <span className={`${cssClass}__point-label`}>
                {t1('wait_for_marking')}
              </span>
            ) : isMarkingPassFail(exercise) ? (
              <PassOrFail
                size="large"
                status={result && parseInt(result) > 0}
              />
            ) : (
              <TotalPoint point={result} size="big" />
            )}
          </div>
        ) : null}

        {/* TODO: Enable and fully testing for this option.
          shouldShowDetail ? (
            <Detail
              actionProps={detailActionProps}
              exercise={exercise}
              className={`${cssClass}__detail`}
            />
          ) : null
        */}
        {hasAction ? (
          <div className={`${cssClass}__buttons`}>
            {canReview && typeof onReviewButtonClick === 'function' ? (
              <button
                className={`btn btn-secondary ${cssClass}__button`}
                disabled={isExerciseLoading}
                onClick={onReviewButtonClick}
              >
                {t1('review')}
              </button>
            ) : null}
            {/* canRedo && typeof onRedoButtonClick === 'function' ? (
              <button
                className={`btn btn-filled ${cssClass}__button`}
                disabled={isExerciseLoading}
                onClick={onRedoButtonClick}
              >
                {t1('redo')}
              </button>
            ) : null */}

            <RedoExercise
              className={`btn btn-filled ${cssClass}__button`}
              numberOfTimesUserCanDoExercise={numberOfTimesUserCanDoExercise}
              isExerciseLoading={isExerciseLoading}
              onRedoButtonClick={onRedoButtonClick}
              userIid={lodashGet(userInfo, 'iid')}
              exerciseIid={lodashGet(learnInfo, 'iid')}
              courseIid={lodashGet(course, 'iid')}
            />

            {canResume && typeof onResumeButtonClick === 'function' ? (
              <button
                className={`btn btn-primary ${cssClass}__button`}
                disabled={isExerciseLoading}
                onClick={onResumeButtonClick}
              >
                {t1('continue')}
              </button>
            ) : null}
          </div>
        ) : null}

        <div id={portals.WARNING_NUMBER_OF_TIMES_DID_EXERCISE} />

        {shouldDisplaySkillAnalysisWhenDisplayExerciseResult
          ? [
              <hr />,
              <NormalExerciseResultWithSkillAnalysis
                exercise={exercise}
                onNextButtonClick={onNextButtonClick}
                onRedoButtonClick={onRedoButtonClick}
                onReviewButtonClick={onReviewButtonClick}
                options={options}
                positionOfCurrentItem={positionOfCurrentItem}
                detailActionProps={detailActionProps}
                result={result}
                onResumeButtonClick={onResumeButtonClick}
                shouldShowDetail={shouldShowDetail}
                hasAction={hasAction}
                courseIid={courseIid}
              />,
            ]
          : null}

        {shouldShowExerciseQuestionsStatus(exercise) && (
          <div className="m-t-30">
            <ExerciseStatusOfQuestions exercise={exercise} inCard />
          </div>
        )}

        {shouldShowTakeHistory(learnInfo) && (
          <div className={'m-t-30'}>
            <TakeHistories
              userIid={lodashGet(userInfo, 'iid')}
              exerciseIid={lodashGet(learnInfo, 'iid')}
              course={course}
              syllabus={syllabus}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const iid = lodashGet(props, 'learnInfo.iid');
  const exercise = Object.assign(
    {},
    { ...state.tree[iid], children: state.tree[iid].metadata },
  );

  return {
    positionOfCurrentItem: state.learn.positionOfCurrentItem,
    exercise,
  };
};

export default compose(
  withGlobalDialogs(),
  withSchoolConfig,
  withUserInfo,
  withServerTs,
  connect(mapStateToProps),
)(NormalExerciseResult);
