import React from 'react';

const FixedItem = ({
  className,
  avatar,
  content,
  matched,
  disabled,
  handleBoxClick,
  isActivated,
  isHighlight,
  stt,
  colors,
  highlightColor,
}) => {
  const cssClass = 'matching-pair-question-clickable-item';
  const componentClassName = `${className || ''} ${cssClass}`;

  return (
    <div className="d-flex align-items-center">
      <div className={componentClassName} onClick={() => handleBoxClick()}>
        {!!stt && (
          <span
            style={{
              backgroundColor: isActivated ? `${colors[stt - 1]}` : 'white',
              borderColor: isHighlight ? highlightColor : 'initial',
            }}
            className={`${cssClass}__number m-r-10`}
          >
            {stt}
          </span>
        )}
        <div
          className="main"
          style={{
            border: isActivated
              ? `1px solid ${colors[stt - 1]}`
              : isHighlight
              ? `1px solid ${highlightColor}`
              : 'initial',
          }}
        >
          <div className={`${avatar ? 'content--avatar' : ''} content`}>
            {avatar && (
              <div className="avatar">
                <img src={avatar} />
              </div>
            )}
            {content && (
              <div className="content-text">
                <div
                  title={content}
                  className={`${
                    !avatar ? 'content-text-ellipsis--line-clamp' : ''
                  }`}
                >
                  {content}
                </div>
              </div>
            )}

            {disabled && (
              <span
                className={`question-status-icon ${
                  !matched ? 've-close-outline' : 've-check'
                }`}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FixedItem;
