import React from 'react';
import Link from 'components/common/router/Link';
import { createLinkForItem } from 'components/learn/nav/nav-generator';
import { breadCrumb } from 'common/utils/string';
import { getNavigationSCOAncestor } from 'common/learn';

const BackToNavigationSCOButton = ({ course, nodes, itemIid }) => {
  const navigationSCOAncestor = getNavigationSCOAncestor(nodes, itemIid);

  if (!navigationSCOAncestor) {
    return null;
  }

  return (
    <div className={'m-t-20 m-b-40 text-center'}>
      <Link
        to={createLinkForItem(course, nodes, navigationSCOAncestor)}
        className={'btn btn-primary'}
      >
        Quay lại ({breadCrumb(navigationSCOAncestor.name, 25)})
      </Link>
    </div>
  );
};

export default BackToNavigationSCOButton;
