import React from 'react';
import get from 'lodash.get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import CommonAntdTable from 'components/common/antd/table';
import { CHUONG_TRINHS, getChuongTrinhName } from 'components/bdtx/configs';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import { fetchChosenModules } from 'components/bdtx/training-phase/utils';
import actions from 'actions/node/creators';
import DetailStatusContent from 'components/bdtx/kpi/detail-by-organization/search/DetailStatusContent';
import TooltipWithIcon from 'common/tooltip';
import Tooltip from 'antd/lib/tooltip';

const STAT_KEY = {
  learned: 'learned',
  registered: 'registered',
  license: 'license',
};

const TableResult = ({ items, chosenModules, dispatch }) => {
  const handleOnClick = (item) => {
    const contentDialog = <DetailStatusContent data={item} />;
    const optionsProperties = {
      handleClose: true,
      title: 'Thông tin',
      modal: true,
    };

    dispatch(actions.handleOpenDialog({ contentDialog, optionsProperties }));
  };

  const getModuleObject = (item, chuongTrinh, moduleIid) => {
    const chuongTrinhs = get(item, 'chuong_trinhs', []);
    const dataByChuongTrinh = chuongTrinhs.find(
      (ct) => ct.chuong_trinh == chuongTrinh,
    );

    if (!dataByChuongTrinh) {
      return;
    }

    const modules = get(dataByChuongTrinh, 'modules', []);

    return modules.find((md) => md.iid == moduleIid);
  };

  const renderStatusDialog = (item, chuongTrinh, moduleIid, type) => {
    const module = getModuleObject(item, chuongTrinh, moduleIid);

    const stats = get(module, 'stats');
    const deployed = get(module, 'deployed', 0);

    const total = get(stats, 'total', 0);
    const registered = get(stats, 'registered', 0);
    const learned = get(stats, 'learned', 0);

    if (!deployed) {
      return <span className="text-danger">---</span>;
    }

    let content;
    if (type === STAT_KEY.registered) {
      content = (
        <>
          <span>
            <span className="text-secondary">{registered}</span>/{total}
          </span>
          <TooltipWithIcon
            content={`Tổng ${registered} GV/CBQL đã đăng ký / Tổng ${total} GV/CBQL`}
          />
        </>
      );
    } else if (type === STAT_KEY.learned) {
      content = (
        <>
          <span>
            <span className="text-primary">{learned}</span>/{registered}
          </span>
          <TooltipWithIcon
            content={`Tổng ${learned} GV/CBQL đã học / Tổng ${registered} GV/CBQL đã đăng ký`}
          />
        </>
      );
    } else if (type === STAT_KEY.license) {
    }

    return (
      <div className="d-flex d-flex-column align-items-center justify-content-center">
        {content}
      </div>
    );
  };

  const renderModuleColumn = (modules, chuongTrinh) => {
    return modules.map((module) => {
      return {
        title: (
          <div>
            <Tooltip title={get(module, 'name')}>
              {get(module, 'code')} ({get(module, 'credit')}t)
            </Tooltip>
          </div>
        ),
        type: TYPE_OF_TABLE_DATA.TITLE,
        width: 150,
        children: [
          {
            title: (
              <>
                <span>Đã đăng ký</span>
                <TooltipWithIcon
                  content={`Tổng GV/CBQL đã đăng ký / Tổng GV/CBQL`}
                />
              </>
            ),
            type: TYPE_OF_TABLE_DATA.TITLE,
            render: (row) => {
              return renderStatusDialog(
                row,
                chuongTrinh,
                module.iid,
                STAT_KEY.registered,
              );
            },
          },
          {
            title: (
              <>
                <span>Đã đi học</span>
                <TooltipWithIcon
                  content={`Tổng GV/CBQL đã học / Tổng GV/CBQL đã đăng ký`}
                />
              </>
            ),
            type: TYPE_OF_TABLE_DATA.TITLE,
            render: (row) => {
              return renderStatusDialog(
                row,
                chuongTrinh,
                module.iid,
                STAT_KEY.learned,
              );
            },
          },
        ],
      };
    });
  };

  const renderTableColumns = () => {
    return [
      {
        title: 'Đơn vị',
        render: ({ organization }) => <span>{get(organization, 'name')}</span>,
      },
      {
        title: 'Licenses',
        type: TYPE_OF_TABLE_DATA.TITLE,
        render: ({ organization }) => {
          const licensesUsed = get(organization, 'licenses_used', 0);
          const totalLicenses = get(organization, 'total_licenses', 0);

          return (
            <>
              <span className="text-primary">{licensesUsed}</span>/
              <span>{totalLicenses}</span>
            </>
          );
        },
      },
      ...CHUONG_TRINHS.map((ct) => {
        const title = getChuongTrinhName(ct);

        const chuongTrinhModules = get(chosenModules, `${ct}.modules`, []);

        return {
          title: title,
          type: TYPE_OF_TABLE_DATA.TITLE,
          children: [...renderModuleColumn(chuongTrinhModules, ct)],
        };
      }),
    ];
  };

  return (
    <CommonAntdTable
      dataSource={items}
      columns={renderTableColumns()}
      pagination={false}
      className="learn-default"
    />
  );
};

export default compose(
  fetchChosenModules,
  connect(),
)(TableResult);
