import React from 'react';
import { t1 } from 'translate';
import NodeNew from 'components/admin/node/new';
import userGroupSchema from 'components/admin/group/schema/form';
import actions from 'actions/node/creators';
import { connect } from 'react-redux';
import endpoints from '../../endpoints';
import FilterSets from '../filterset';
import lodashGet from 'lodash.get';

/**
 * @param className PropTypes.string,
 * @param group PropTypes.shape()
 * @returns {*}
 * @constructor
 */
const GroupInfoEdit = ({ className, group, dispatch, searchFormId }) => {
  if (!group) {
    return null;
  }

  return (
    <div className={'whitebox'}>
      <NodeNew
        ntype={'group'}
        mode={'edit'}
        step={'user_group_info'}
        alternativeApi={endpoints.update_group}
        schema={userGroupSchema(
          group && group.type ? { type: group.type } : {},
        )}
        node={group}
        formid={'edit_group'}
        requestSuccessful={() => {
          dispatch(
            actions.fetchNode(
              { iid: group.iid, ntype: 'group' },
              endpoints.get_group_info,
            ),
          );
        }}
        searchFormId={searchFormId}
      />

      {!!group.smart && (
        <div>
          <hr />
          <h3>{t1('edit_group_filters')}</h3>
          <FilterSets group={group} />
        </div>
      )}
    </div>
  );
};

export default connect()(GroupInfoEdit);
