import { t1 } from 'translate';

const phones = (notIn = false, props = {}) => ({
  type: 'text',
  multiLine: true,
  hintText: `0979234321, 0979234320, ... ${t1(
    'separated_by_comma_or_new_line',
  )}`,
  floatingLabelText: notIn ? t1('user_phones_not_in') : t1('user_phones'),
  fullWidth: true,
  ...props,
});

export default phones;
