import { t1 } from 'translate/index';

const code = (props = {}) => ({
  type: 'text',
  floatingLabelText: t1('user_code'),
  hintText: t1('user_code'),
  validate: props.validate,
});

export default code;
