import React from 'react';
import { t1 } from 'translate';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import fetchData from 'components/common/fetchData';
import Select from 'schema-form/elements/redux-form-fields/SelectDefault';
import TextField from 'schema-form/elements/redux-form-fields/AntInputField';
import Checkbox from 'schema-form/elements/redux-form-fields/MuiCheckbox';
import SocialAuth from '../../social-auth/SocialAuth';
import withSchoolConfigs from 'common/hoc/withSchoolConfigs';
import '../../etec.scss';
import { getThemeConfig } from 'utils/selectors';
import { Link } from 'react-router-dom';
import get from 'lodash.get';
import lodashGet from 'lodash.get';
import backgroundImageUrl from 'common/images/edu-bg.jpg';
import temisBackgroundImageUrl from 'common/images/temis-login-bg.jpg';
import { getSupportEmail } from 'configs/constants';
import {
  getTemisCopyright,
  isEnableTemis,
  temisSystemText,
} from 'components/temis/utils';
import LiveStreamLink from 'components/admin/session/common/LiveStreamLink';
import Field from 'redux-form/lib/Field';
import Captcha from 'schema-form/elements/captcha';
import { formValueSelector } from 'redux-form';

const loginGuide = '/media/pdf/login-guide.pdf?v=2';

class EtepLogin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLdapHostsOptions: true,
      showPass: false,
      usernameFocus: false,
      passwordFocus: false,
    };
  }

  divStyle = { margin: 0, padding: 0 };
  checkboxLabelStyle = { color: '#242c42' };
  checkboxIconStyle = {
    fill: '#242c42',
    width: '15px',
    height: '15px',
    top: '2px',
    marginRight: '5px',
  };

  toggleLdapServersOptions = () => {
    this.setState({
      showLdapHostsOptions: !this.state.showLdapHostsOptions,
    });
  };

  handleClickButton = () => {
    this.setState({
      showPass: !this.state.showPass,
    });
  };

  getLdapServersOptions = (ldapServers) => {
    const ldapServersOptions = [
      { value: '', name: t1('choose_domains'), label: t1('choose_domains') },
    ];
    ldapServers &&
      ldapServers.forEach((ldapServer) => {
        ldapServersOptions.push({
          value: ldapServer.host,
          name: ldapServer.name,
          label: ldapServer.name,
        });
      });

    return ldapServersOptions;
  };

  setFocus = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  getCopyright = () => {
    const { domainSchool } = this.props;

    const copyright = get(domainSchool, 'copyright');
    if (copyright) {
      return copyright;
    }

    const defaultCopyright = `© ${new Date().getFullYear()} - ${get(
      domainSchool,
      'name',
      '',
    )}`;

    const poweredBy = get(domainSchool, 'powered_by', 'Powered by Vieted');

    return `${defaultCopyright} | ${poweredBy}`;
  };

  render() {
    const {
      doLogin,
      changePassword,
      handleKeyPress,
      goToRegister,
      enableRegisters,
      ldapServers,
      ldapEnabled,
      operatingOrganizations,
      themeConfig,
      domainSchool,
      domainConf,
      loginCaptchaEnabled,
      loginCaptchaId,
      captchaValue,
    } = this.props;

    const { usernameFocus, passwordFocus } = this.state;

    let defaultValue = '';
    const ldapServersOptions = this.getLdapServersOptions(ldapServers);

    ldapServersOptions.forEach((option) => {
      if (option.value !== '') {
        defaultValue = option.value;
      }
    });

    const logoLink = get(themeConfig, 'logo', '');
    const hotline = get(domainSchool, 'hotline', '');
    const branch_hotline = get(domainSchool, 'branch_hotline');

    const loginBackgroundImageUrl = get(
      domainSchool,
      'login_background_image_url',
    );
    const systemName = get(domainSchool, 'system_name');

    const isTemis = isEnableTemis();

    return (
      <div
        className="vt-login-wrapper"
        style={{
          backgroundImage: `url(${loginBackgroundImageUrl ||
            (isTemis ? temisBackgroundImageUrl : backgroundImageUrl)})`,
        }}
      >
        <div className="vt-login-header">
          <div className="vt-login-header__wrapper">
            <div className="container">
              <div className="row vt-login-header__content">
                <div className="col-md-3 vt-login-header__logo">
                  <Link to="/" className="logo-link">
                    <img src={logoLink} alt="logo" />
                  </Link>
                </div>
                <div className="col-md-9">
                  <div className="wrap-support-login">
                    <div className="support-login">
                      <div>
                        <span className="support-title">Tư vấn và hỗ trợ</span>
                        <a href={`tel:${hotline}`} className="support-phone">
                          {hotline}
                        </a>
                      </div>

                      <div className="support-phone-key">
                        {typeof branch_hotline !== 'undefined' ? (
                          branch_hotline
                        ) : (
                          <>
                            Nhánh <span className="key">2</span> (miễn phí)
                          </>
                        )}
                      </div>

                      <div>
                        <a
                          className="support-email"
                          href={`mailto:${getSupportEmail()}`}
                          target="_self"
                        >
                          {`Email: ${getSupportEmail()}`}
                        </a>
                      </div>

                      <Link className="question-answer" to="/faq">
                        Các câu hỏi thường gặp
                      </Link>

                      {lodashGet(
                        domainConf,
                        'virtual_class_live_stream_link_for_non_login_user.show_link',
                      ) && (
                        <span className="m-l-5">
                          <LiveStreamLink />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="vt-login-content">
          <div className="vt-login-form">
            <div className="vt-login-form__wrapper">
              <div className="vt-login-form__title">
                <div className="vt-login-form__title-main">{t1('login')}</div>
                <div className="vt-login-form__system">
                  {systemName ||
                    (isTemis ? temisSystemText : t1('system_name'))}
                </div>
              </div>

              {ldapEnabled === 'yes' && [
                <div className="vt-login-form__item ldap-enabled">
                  <Checkbox
                    checked={this.state.showLdapHostsOptions}
                    labelStyle={this.checkboxLabelStyle}
                    iconStyle={this.checkboxIconStyle}
                    name="ldap_enabled"
                    label={t1('login_via_active_directory_account')}
                    onClick={() => this.toggleLdapServersOptions}
                  />
                </div>,
                this.state.showLdapHostsOptions &&
                  ldapServersOptions.length > 2 && (
                    <div className="vt-login-form__item">
                      <Select
                        defaultValue={defaultValue}
                        name="ldap_host"
                        className="input-default"
                        options={ldapServersOptions}
                      />
                    </div>
                  ),
              ]}

              {ldapEnabled === 'yes' && [
                <div className="vt-login-form__item ldap-enabled">
                  <Checkbox
                    checked={this.state.showLdapHostsOptions}
                    labelStyle={this.checkboxLabelStyle}
                    iconStyle={this.checkboxIconStyle}
                    name="ldap_enabled"
                    label={t1('login_via_active_directory_account')}
                    onClick={() => this.toggleLdapServersOptions}
                  />
                </div>,
                this.state.showLdapHostsOptions &&
                  ldapServersOptions.length > 2 && (
                    <div className="vt-login-form__item">
                      <Select
                        defaultValue={defaultValue}
                        name="ldap_host"
                        className="input-default"
                        options={ldapServersOptions}
                      />
                    </div>
                  ),
              ]}
              {ldapEnabled !== 'yes' &&
                Array.isArray(operatingOrganizations) &&
                operatingOrganizations.length && (
                  <div className="vt-login-form__item">
                    <Select
                      label={t1('operating_organization')}
                      name="operating_organization"
                      className="input-default"
                      options={[
                        {
                          value: '',
                          name: t1('choose_operating_organization'),
                          label: t1('choose_operating_organization'),
                        },
                      ].concat(
                        operatingOrganizations.map(({ name, iid, code }) => ({
                          value: iid,
                          name,
                          label: name,
                        })),
                      )}
                    />
                  </div>
                )}
              <div className="vt-login-form__item">
                <TextField
                  fullWidth
                  name="lname"
                  floatingLabelText={t1('account')}
                  onKeyPress={handleKeyPress}
                  onFocus={() => {
                    this.setFocus('usernameFocus', true);
                  }}
                  onBlur={() => {
                    this.setFocus('usernameFocus', false);
                  }}
                  className={usernameFocus ? 'focus' : ''}
                  placeholder={t1('account')}
                />
              </div>
              <div className="vt-login-form__item">
                <TextField
                  type={this.state.showPass ? 'text' : 'password'}
                  fullWidth
                  name="pass"
                  floatingLabelText={t1('pass')}
                  onKeyPress={handleKeyPress}
                  icon={
                    <span
                      className={this.state.showPass ? 've-eye' : 've-eye-off'}
                      onClick={this.handleClickButton}
                    />
                  }
                  onFocus={() => {
                    this.setFocus('passwordFocus', true);
                  }}
                  onBlur={() => {
                    this.setFocus('passwordFocus', false);
                  }}
                  className={passwordFocus ? 'focus' : ''}
                  placeholder={t1('pass')}
                />
              </div>
              {loginCaptchaEnabled && loginCaptchaId ? (
                <div key={loginCaptchaId} className="vt-login-form__item">
                  {!captchaValue ? (
                    <div style={{ color: 'red' }} className="m-b-5">
                      {t1(
                        'there_are_abnormal_activities_with_your_account._please_verify_you_are_a_human',
                      )}
                    </div>
                  ) : null}
                  <Field component={Captcha} name={'captcha'} />
                </div>
              ) : null}
              <div className="vt-login-form__guide-and-forgot-password">
                {!isTemis && (
                  <a href={loginGuide} target="_blank" className="guide">
                    {t1('guide')}
                  </a>
                )}
                <a onClick={changePassword} className="forgot-password">
                  {t1('forgot_password')}?
                </a>
              </div>
              <div className="vt-login-form__item">
                <button
                  type="button"
                  className="vt-login-form__login-button btn btn-primary"
                  onClick={() => doLogin()}
                >
                  {t1('login')}
                </button>
              </div>

              <div className="vt-login-form__sso">
                <SocialAuth />
              </div>

              {enableRegisters && enableRegisters.includes('normal_register') && (
                <div className="vt-login-form__register">
                  <a onClick={goToRegister} className="register">
                    {`${t1('you_do_not_have_an_account_yet')}?`}&nbsp;
                    <span onClick={goToRegister}>{t1('register')}</span>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="vt-login-footer">
          <div className="container">
            {isTemis ? getTemisCopyright() : this.getCopyright()}
          </div>
        </div>
      </div>
    );
  }
}

EtepLogin.propTypes = {
  doLogin: PropTypes.func,
  changePassword: PropTypes.func,
  handleKeyPress: PropTypes.func,
  goToRegister: PropTypes.func,
  enableRegisters: PropTypes.arrayOf(PropTypes.string),
};

EtepLogin.defaultProps = {
  doLogin: null,
  changePassword: null,
  handleKeyPress: null,
  goToRegister: null,
  enableRegisters: null,
};

const mapStateToProps = createSelector(
  (state) =>
    state.domainInfo &&
    state.domainInfo.conf &&
    state.domainInfo.conf.ldap_enabled,
  (state) =>
    state.domainInfo &&
    state.domainInfo.conf &&
    state.domainInfo.conf.ldap_servers,
  (state) => lodashGet(state, 'domainInfo.conf'),

  (state) => state.domainInfo && state.domainInfo.school,
  getThemeConfig,
  (state, props) => {
    const formId = props.formId;
    return formValueSelector(formId)(state, 'captcha');
  },
  (
    ldapEnabled,
    ldapServers,
    domainConf,
    domainSchool,
    themeConfig,
    captchaValue,
  ) => ({
    ldapEnabled,
    ldapServers,
    domainConf,
    domainSchool,
    themeConfig,
    captchaValue,
  }),
);

export default connect(mapStateToProps)(
  fetchData((props) => ({
    baseUrl: '/organization/api/get-operating-organization-list-to-login',
    propKey: 'operatingOrganizations',
    fetchCondition:
      props.ldapEnabled !== 'yes' && !!props.allowLoginByOperatingOrganization,
    refetchCondition: () => false,
    // Never refetch, I did not add this logic here, I just refactor based on the previous coder logic
    // he/she did not pass refetchCondition here, therefore, it will never refetch
    // I just refactor make it clearer
  }))(withSchoolConfigs(EtepLogin)),
);
