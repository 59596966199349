import React from 'react';
import DisplayInvalidUsers from './DisplayInvalidUsers';
import { t1 } from 'translate';
import Alert from 'antd/lib/alert';

const InvalidUsers = ({
  erroredUsers,
  errorsByUserIid,
  nDeleted,
  nErrored,
}) => {
  return (
    <div>
      <Alert
        type={'info'}
        message={t1('only_%s_users_deleted,_%s_users_cannot_be_deleted', [
          nDeleted,
          nErrored,
        ])}
      />
      <DisplayInvalidUsers
        erroredUsers={erroredUsers}
        errorsByUserIid={errorsByUserIid}
      />
    </div>
  );
};

export default InvalidUsers;
