import React from 'react';
import { t, t1 } from 'translate';
import { required } from 'common/validators';
import RTE from 'schema-form/elements/richtext';
import Badge from 'antd/lib/badge';

export const communicationTitle = (props = {}) => ({
  type: 'text',
  floatingLabelText: `${t1('message_subject')} (*)`,
  fullWidth: true,
  label: t1('message_subject'),
  hintText: t1('message_subject'),
  validate: [required(t1('title_cannot_be_empty'))],
  className: 'notify-message-subject',
  ...props,
});

export const communicationContent = (props = {}) => ({
  type: RTE,
  floatingLabelText: `${t1('message_content')} (*)`,
  fullWidth: true,
  label: 'message_content',
  hintText: 'please_type_content',
  validate: [required(t1('content_cannot_be_empty'))],
  className: 'notify-message-content',
  ...props,
});

export const communicationDeliveryMethods = (props = {}) => {
  const { communicationOptions = [], specificMethods = [] } = props;
  const options = specificMethods.length
    ? specificMethods.filter((item) => communicationOptions.includes(item))
    : communicationOptions;

  const convertOptions = options.map((item) => ({
    label: t1(item),
    value: item,
    primaryText: t1(item),
  }));

  const defaultCommunication = options || []; //options[0] ? [options[0]] : [];

  return {
    type: 'multiCheckbox',
    fullWidth: true,
    floatingLabelText: t1('select_sending_methods'),
    className: 'notify-sending-methods',
    validate: [required(t1('sending_methods_cannot_be_empty'))],
    options: convertOptions,
    defaultValue: defaultCommunication,
    disabled: options.length === 1,
    ...props,
  };
};

export const communicationNote = (props = {}) => {
  const { message } = props;

  return {
    type: 'cascade',
    component: (
      <h4 className="notify-note">
        {message
          ? message
          : t1('message_will_send_via_your_send_method_did_you_choose')}
      </h4>
    ),
    ...props,
  };
};

export const showNumber = (number) => {
  return (
    <Badge
      count={number}
      style={{ backgroundColor: '#52c41a', fontWeight: 'bold' }}
      overflowCount={number + 1}
    />
  );
};

export const generateMessageInfo = (
  numberOfItemSelected,
  totalResult,
  type = 'accounts',
) => {
  let message = '';
  const firstString = t1('do_you_want_to_send_notify_warning_these');
  if (totalResult && numberOfItemSelected) {
    message = (
      <>
        {firstString} {showNumber(totalResult)} {t(`result_${type}_or`)}{' '}
        {showNumber(numberOfItemSelected)} {t(`selected_${type}`)}?
      </>
    );
  } else if (!numberOfItemSelected && totalResult) {
    message = (
      <>
        {firstString} {showNumber(totalResult)} {t(`result_${type}`)}?
      </>
    );
  } else if (numberOfItemSelected) {
    message = (
      <>
        {firstString} {showNumber(numberOfItemSelected)}
        {t(`selected_${type}`)}?
      </>
    );
  }

  return message;
};
