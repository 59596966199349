/**
 * Created by hungvo on 19/04/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t1 } from 'translate/index';
import actions from 'actions/node/creators';
import Modal from 'antd/lib/modal';
import GenerateButton from './GenerateButton';

class ActionBtnWithConfirmDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onRequestSuccessful = () => {
    const { dispatch, closeDialogAfterActionSuccessFull } = this.props;

    if (closeDialogAfterActionSuccessFull) {
      dispatch(actions.handleOpenDialog({ openDialog: false }));
    }
  };

  handleRequestSuccessful = (...params) => {
    if (typeof this.props.onRequestSuccessful === 'function') {
      this.props.onRequestSuccessful(...params);
    } else {
      this.onRequestSuccessful();
    }
    this.handleClose();
  };

  handleAction = () => {
    const { actionHandler } = this.props;
    return actionHandler(this.handleRequestSuccessful);
  };

  handleClick = () => {
    if (this.props.noConfirm) {
      return this.handleAction();
    } else {
      return this.onFindAndArrangeButtonClick();
    }
  };

  onFindAndArrangeButtonClick = () => {
    const onOk = this.handleAction;
    const onCancel = this.handleClose;
    const textConfirm =
      this.props.textConfirm || t1('are_you_sure_you_want_to_do_this?');

    const { customModalConfirm, contentConfirm } = this.props;

    if (typeof customModalConfirm === 'function') {
      return customModalConfirm({
        onOk,
        onCancel,
      });
    }

    Modal.confirm({
      centered: true,
      title: textConfirm,
      content: contentConfirm || null,
      onOk() {
        onOk();
      },
      onCancel() {
        onCancel();
      },
      cancelText: t1('cancel'),
      okText: t1('yes_go_ahead'),
    });
  };

  render() {
    return (
      <React.Fragment>
        <GenerateButton {...this.props} onClick={this.handleClick} />
      </React.Fragment>
    );
  }
}

ActionBtnWithConfirmDialog.propTypes = {
  actionHandler: PropTypes.func,
  actionProps: PropTypes.shape(),
  buttonLabelStyle: PropTypes.shape(),
  buttonStyle: PropTypes.shape(),
  className: PropTypes.string,
  closeDialogAfterActionSuccessFull: PropTypes.bool,
  contentDialog: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconButton: PropTypes.bool,
  label: PropTypes.string,
  noConfirm: PropTypes.bool,
  onRequestSuccessful: PropTypes.func,
  primary: PropTypes.bool,
  raisedButton: PropTypes.bool,
  renderComponent: PropTypes.func,
  secondary: PropTypes.bool,
  style: PropTypes.bool,
  textConfirm: PropTypes.string,
  title: PropTypes.string,
};

export default connect()(ActionBtnWithConfirmDialog);
