import React from 'react';
import { t1 } from 'translate';
//import AntProgress from 'antd/lib/progress';
//import styled from 'styled-components';
import lodashGet from 'lodash.get';
//import Progress from 'antd/lib/progress';
import ProgressBar from '../../../common/progress-bar';

const schema = (formid, values, step, xpath, props) => {
  const { node } = props;
  const totalExtendedTime = lodashGet(node, 'totalExtendedTime');
  const endTime = lodashGet(node, 'endTime');
  let maxAddTime = 60 - totalExtendedTime; //60 minutes is the max time can add to a session
  const diffMinutesFromEndTimeToMidNight = 1439 - endTime; //1439 is 23:59
  maxAddTime =
    maxAddTime < diffMinutesFromEndTimeToMidNight
      ? maxAddTime
      : diffMinutesFromEndTimeToMidNight;
  return {
    minutes: {
      type: 'number',
      floatingLabelText: t1('input_time_to_add'),
      min: 0,
      max: maxAddTime,
      fullWidth: true,
    },
  };
};

const ui = () => [
  {
    id: 'default',
    fields: ['minutes'],
  },
];

const Layout = ({ groups, submitButton, ...props }) => {
  const { node, formValues } = props;
  const totalExtendedTime = lodashGet(node, 'totalExtendedTime');
  const endTime = lodashGet(node, 'endTime');
  let remainTime = 60 - totalExtendedTime; //60 minutes is the max time can add to a session
  const diffMinutesFromEndTimeToMidNight = 1439 - endTime; //1439 is 23:59
  let shoudShowWarning = false;
  if (remainTime > diffMinutesFromEndTimeToMidNight) {
    remainTime = diffMinutesFromEndTimeToMidNight;
    shoudShowWarning = true;
  }
  const minutes = parseInt(lodashGet(formValues, 'minutes', 0));
  const fullTimeNotification = (
    <p className="text-danger">{t1('time_allowed_to_add_is_full')}</p>
  );
  const percent = Math.trunc(
    ((totalExtendedTime + minutes) / (totalExtendedTime + remainTime)) * 100,
  );

  return (
    <div>
      <div>
        {t1(
          'note_session_time_is_added_and_maximum_is_60_minutes_for_a_session_but_must_end_before_12pm',
        )}
      </div>
      <div>{groups.default.fieldNames.minutes}</div>
      <p>
        {t1('total_time_allowed_to_add')}: <strong> 60 </strong>
      </p>
      <p>
        {t1('you_added')}: <strong> {node.totalExtendedTime} </strong>
      </p>
      <p>
        {t1('remaining_time')}: <strong> {remainTime} </strong>
        {shoudShowWarning && (
          <span>( {t1('cause_session_must_finish_before_12pm')} )</span>
        )}
      </p>
      <div className="text-center">
        {(remainTime && submitButton) || fullTimeNotification}
      </div>
      <div className="progress-container">
        <div className="content">
          <ProgressBar
            percent={percent}
            className="course-progress"
            showInfo={false}
          />
        </div>
      </div>
    </div>
  );
};

export default {
  schema,
  ui,
  layout: {
    component: Layout,
    freestyle: 1,
  },
};
