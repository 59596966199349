import React from 'react';
import { t1 } from 'translate';
import get from 'lodash.get';

export const allRubricSubTypes = {
  RUBRIC_FORMULA_WEIGHTED_AVERAGE: 'weighted_average',
  RUBRIC_FORMULA_BEST_CHILD: 'best_child',
  RUBRIC_FORMULA_ATTENDANCE_ONLINE: 'attendance_online',
  RUBRIC_FORMULA_ATTENDANCE_OFFLINE: 'attendance_offline',
  RUBRIC_FORMULA_EXERCISE_COMPUTER_GRADED: 'exercise_computer_graded_only',
  RUBRIC_FORMULA_EXERCISE_AVERAGE: 'exercise_average',
  RUBRIC_FORMULA_SYLLABUS_SCORE: 'syllabus_score',
  RUBRIC_FORMULA_LAST_EXERCISE: 'last_exercise',

  RUBRIC_FORMULA_LAST_EXERCISE_WITH_ONLY_ONE_OPEN_ENDED_QUESTION:
    'open_ended_score',
  RUBRIC_FORMULA_EXERCISE_WITH_SIMPLE_OPEN_ENDED_QUESTIONS:
    'exercise_with_simple_open_ended_questions',
  RUBRIC_FORMULA_TAKE_SURVEY: 'take_survey',
  RUBRIC_FORMULA_FINISHED_SELECTION_MODULES_OF_BDTX: 'selection_modules',
  RUBRIC_FORMULA_PROVIDE_FULL_PERSONAL_INFORMATION:
    'provide_full_personal_information',
  RUBRIC_FORMULA_MANUAL_INPUT: 'manual_input',

  RUBRIC_PASS_COMMON_AND_AT_LEAST_ONE_OTHER_SUBJECT:
    'pass_common_and_at_least_one_other_subject',
  RUBRIC_PASS_COMMON_SUBJECT: 'pass_common_subject',
  RUBRIC_PASS_AT_LEAST_ONE_SPECIALIZED_SUBJECT:
    'pass_at_least_one_specialized_subject',
  RUBRIC_PASS_AT_LEAST_X_SUBJECTS: 'pass_at_least_x_subjects',
  RUBRIC_PASS_ALL_ENROLMENT_PLANS_IN_TP: 'pass_all_enrolment_plans_in_tp',
  RUBRIC_PASS_ENROLMENT_PLAN_RUBRIC: 'pass_enrolment_plan_rubric',

  RUBRIC_PASS_ALL_COURSES_ASSIGNED: 'pass_all_courses_assigned',

  RUBRIC_PASS_PREVIOUS_MODULE: 'pass_previous_module',

  RUBRIC_PASS_SUBJECT_IN_PREVIOUS_MODULE: 'pass_subject_in_previous_module',
  RUBRIC_PASS_CONTEST: 'pass_contest',

  RUBRIC_PASS_COURSE_RUBRIC: 'pass_course_rubric',
  RUBRIC_SUBMIT_EXERCISE: 'submit_exercise',
};

export const FormulaHelp = (type) => {
  switch (type) {
    case allRubricSubTypes.RUBRIC_FORMULA_WEIGHTED_AVERAGE:
      return t1('rubric_score_will_be_based_on_the_score_of_the_children');
      break;

    case allRubricSubTypes.RUBRIC_FORMULA_BEST_CHILD:
      return t1('rubric_score_will_be_the_best_score_of_all_of_its_children');
      break;

    case allRubricSubTypes.RUBRIC_FORMULA_ATTENDANCE_OFFLINE:
      return (
        <div>
          {t1(
            'score_will_be_automatically_calculated_from_offline_sessions_attendance',
          )}
        </div>
      );

      break;
    case allRubricSubTypes.RUBRIC_FORMULA_ATTENDANCE_ONLINE:
      return t1(
        'score_will_be_automatically_calculated_from_completion_progress_of_syllabus_online_items',
      );
      break;
    case allRubricSubTypes.RUBRIC_FORMULA_LAST_EXERCISE_WITH_ONLY_ONE_OPEN_ENDED_QUESTION:
      return t1(
        'score_will_be_the_score_of_last_exercise_in_syllabus_that_only_have_one_question_and_it_is_open_ended',
      );
      break;
    case allRubricSubTypes.RUBRIC_FORMULA_EXERCISE_WITH_SIMPLE_OPEN_ENDED_QUESTIONS:
      return t1(
        'score_will_be_the_average_score_of_all_exercises_in_syllabus_that_have_one_or_more_simple_open_ended_questions',
      );
      break;
    case allRubricSubTypes.RUBRIC_FORMULA_EXERCISE_COMPUTER_GRADED:
      return t1('score_will_be_the_score_of_all_computer_graded_exercises');
      break;
    case allRubricSubTypes.RUBRIC_FORMULA_EXERCISE_AVERAGE:
      return t1('score_will_be_the_average_of_all_exercises');
      break;
    case allRubricSubTypes.RUBRIC_FORMULA_LAST_EXERCISE:
      return t1('score_will_be_the_score_of_the_last_exercise_in_the_syllabus');
      break;
    case allRubricSubTypes.RUBRIC_FORMULA_TAKE_SURVEY:
      return t1('user_only_pass_if_he_has_taken_survey');
      break;
    case allRubricSubTypes.RUBRIC_FORMULA_FINISHED_SELECTION_MODULES_OF_BDTX:
      return t1('user_only_pass_if_he_finished_selection_modules_of_bdtx');
      break;
    case allRubricSubTypes.RUBRIC_FORMULA_PROVIDE_FULL_PERSONAL_INFORMATION:
      return t1('user_only_pass_if_they_provide_full_personal_information');
      break;
    case allRubricSubTypes.RUBRIC_FORMULA_MANUAL_INPUT:
      return t1('score_will_be_enter_manually_be_teacher');
      break;
    case allRubricSubTypes.RUBRIC_FORMULA_SYLLABUS_SCORE:
      return t1('score_will_simply_be_the_progress_of_syllabus');
      break;
    case allRubricSubTypes.RUBRIC_PASS_COMMON_AND_AT_LEAST_ONE_OTHER_SUBJECT:
      return t1('pass_common_and_at_least_one_other_subject');
      break;
    case allRubricSubTypes.RUBRIC_PASS_COMMON_SUBJECT:
      return t1('pass_common_subject');
      break;
    case allRubricSubTypes.RUBRIC_PASS_AT_LEAST_ONE_SPECIALIZED_SUBJECT:
      return t1('pass_at_least_one_specialized_subject');
      break;
    case allRubricSubTypes.RUBRIC_PASS_AT_LEAST_X_SUBJECTS:
      return t1('pass_at_least_x_subjects');
      break;
    case allRubricSubTypes.RUBRIC_PASS_ALL_ENROLMENT_PLANS_IN_TP:
      return t1('pass_all_enrolment_plans_in_training_plan');
      break;
    case allRubricSubTypes.RUBRIC_PASS_ENROLMENT_PLAN_RUBRIC:
      return t1('pass_enrolment_plan_rubric');
      break;
    case allRubricSubTypes.RUBRIC_PASS_ALL_COURSES_ASSIGNED:
      return t1('pass_all_courses_assigned');
      break;
    case allRubricSubTypes.RUBRIC_PASS_PREVIOUS_MODULE:
      return t1('user_must_pass_previous_module');
      break;
    case allRubricSubTypes.RUBRIC_PASS_SUBJECT_IN_PREVIOUS_MODULE:
      return t1('user_must_pass_the_same_subject_in_the_previous_module');
      break;
    case allRubricSubTypes.RUBRIC_PASS_CONTEST:
      return t1('pass_contest');
      break;
    case allRubricSubTypes.RUBRIC_SUBMIT_EXERCISE:
      return t1('submit_exercise');
    default:
      return type;
  }
};

export const rubricTypes = (enables) => {
  const options = [
    {
      value: 'normal_rubric',
      label: t1('normal'),
      primaryText: t1('normal'),
    },
    {
      value: 'pmd_rubric',
      label: t1('pmd'),
      primaryText: t1('pmd'),
    },
  ];

  if (!Array.isArray(enables)) {
    return options;
  }
  return options.filter((item) => enables.includes(item.value));
};

export const rubricSubTypeToText = (subType) => {
  switch (subType) {
    case allRubricSubTypes.RUBRIC_FORMULA_LAST_EXERCISE_WITH_ONLY_ONE_OPEN_ENDED_QUESTION:
      return t1('last_exercise_with_only_one_open_ended_question');
    default:
      return t1(subType);
  }
};
/**
 * sub_type is where the scores come from
 * @param enables array<string>[attenance, academic_score, test]
 * @returns {*[]}
 */
export const rubricSubTypes = (enables, { sub_type, metadata } = {}) => {
  if (sub_type === 'attendance') {
    const currentSubTypes = Array.isArray(metadata)
      ? metadata.map((chil) => chil.sub_type)
      : [];
    return ['attendance_online', 'attendance_offline']
      .filter((x) => !currentSubTypes.includes(x))
      .map((x) => {
        return {
          value: x,
          label: rubricSubTypeToText(x),
          primaryText: rubricSubTypeToText(x),
        };
      });
  }

  return enables.map((x) => {
    return {
      value: x,
      label: rubricSubTypeToText(x),
      primaryText: rubricSubTypeToText(x),
      guide: FormulaHelp(x),
    };
  });
};

/**
 * Điều kiện để ẩn button Add Rubric là:
 * 1: Node phải là rubric
 * 2: node có children là pmd_rubric
 * @param node
 * @returns {boolean}
 */
export const checkIfRubricHasChildren = (node, depth) => {
  // const { maxNumberOfExamResits } = this.props;
  if (get(node, 'type') !== 'rubric') return true;

  const subType = get(node, 'sub_type');

  if (subType === 'attendance') {
    const children = get(node, 'children');
    if (!Array.isArray(children) || !children.length || children.length < 2) {
      return true;
    }
    return false;
  } else if (
    [
      allRubricSubTypes.RUBRIC_FORMULA_WEIGHTED_AVERAGE,
      allRubricSubTypes.RUBRIC_FORMULA_BEST_CHILD,
    ].includes(subType)
  )
    return true;
  else return false;

  if (get(node, 'rubric_type') === 'pmd_rubric') return false;

  // if (
  //   get(node, 'sub_type') === 'academic_score' &&
  //   get(node, 'children.length') >= maxNumberOfExamResits + 1
  // )
  //   return false;

  return true;
};
