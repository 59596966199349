import Loadable from 'react-loadable';
import Loading from '../components/common/loading';
import { authUrl } from './root-url';

const Auth = Loadable({
  loader: () => import('components/auth'),
  loading: Loading,
});

export default () => [
  {
    path: `${authUrl}/:token/:uiid`,
    component: Auth,
    exact: true,
  },
];
