import React from 'react';
import store from 'store/index';
import get from 'lodash.get';
import { t1 } from 'translate/index';

import { equivalentPositions } from 'components/admin/equivalent-job-position/schema/elements/index';
import { evnEquivalentPositions } from 'components/admin/top-equivalent-position/schema/elements';
import { positions } from 'components/admin/job-position/schema/elements';
import { UiLibs } from 'configs/constants';
import skill from 'components/admin/group/schema/elements/filterset/filter/skill/skill';
import { textOp } from 'components/admin/group/schema/elements/filterset/filter/query/text';
import sex from 'components/admin/group/schema/elements/filterset/filter/sex/sex';
import age from 'components/admin/group/schema/elements/filterset/filter/age/age';
import experience from 'components/admin/group/schema/elements/filterset/filter/experience/experience';
import code from 'components/admin/group/schema/elements/filterset/filter/schema/code';
import codes from 'components/admin/group/schema/elements/filterset/filter/schema/codes';
import mails from 'components/admin/group/schema/elements/filterset/filter/schema/mails';
import name from 'components/admin/group/schema/elements/filterset/filter/schema/name';
import lname from 'components/admin/group/schema/elements/filterset/filter/schema/lname';
import external from 'components/admin/group/schema/elements/filterset/filter/schema/external';
import creditSyllabuses from 'components/admin/group/schema/elements/filterset/filter/schema/creditSyllabuses';
import iid from 'components/admin/group/schema/elements/filterset/filter/schema/iid';
import mail from 'components/admin/group/schema/elements/filterset/filter/schema/mail';
import statuses from 'components/admin/group/schema/elements/filterset/filter/schema/statuses';
import subject from 'components/admin/group/schema/elements/filterset/filter/schema/subject';
import contractBox from 'components/admin/group/schema/elements/filterset/filter/schema/contractBox';
import totalCreditBox from 'components/admin/group/schema/elements/filterset/filter/schema/totalCreditBox';
import averageScoreBox from 'components/admin/group/schema/elements/filterset/filter/schema/averageScoreBox';
import getAvailableFilters from 'components/admin/group/schema/elements/filterset/utils';
import { gradeElement, groupElement } from 'common/utils/form';
import DatePicker from 'schema-form/elements/date-picker';
import { commonFormLayouts, elementDisplayModes } from 'schema-form/constants';
import { addPropsToEverySchemaElements } from 'common/utils/schema-form';
import MinimalSearchRecapFreeStyleLayout from 'schema-form/layouts/common-freestyle-layouts/MinimalSearchRecap';
import { required, validationWithCondition } from 'common/validators';
import {
  includeSubOrganizations,
  organizations,
} from 'components/admin/organization/schema/elements';
import { userGradeOptions } from 'configs/constants/user';
import { academicCategories } from 'components/admin/academic-category/schema/elements';
import trainingGrades from 'components/admin/group/schema/elements/filterset/filter/schema/trainingGrades';
import dia_ban_khokhan from '../../../../schema/elements/filterset/filter/schema/dia_ban_khokhan';
import dtts from '../../../../schema/elements/filterset/filter/schema/dtts';
import { taphuanTargetUserMulti } from 'components/admin/group/schema/elements/filterset/filter/schema/taphuanTargetUser';
import cotCanDataByCategory from 'components/admin/group/schema/elements/filterset/filter/schema/cotCanDataByCategory';

const getAllowedEnableFiltersKey = (isStaff) => {
  let allowedEnableFiltersKey = isStaff
    ? 'domainInfo.conf.allowed_staff_filters'
    : 'domainInfo.conf.allowed_target_group_filters';

  return allowedEnableFiltersKey;
};

const getElementsSchema = (forRecap = false, props) => (
  formid,
  values,
  localStep,
  xpath,
  domainInfo,
) => {
  const userOrganizations = get(
    values,
    xpath ? `${xpath}.user_organizations` : 'user_organizations',
  );

  let elementsSchema = {
    positions: positions(formid, {}, userOrganizations),
    equivalent_positions: equivalentPositions(formid, userOrganizations),
    evn_equivalent_positions: evnEquivalentPositions(formid, userOrganizations),
    user_organizations: organizations({
      formid,
      label: `${t1('content_organizations')}`,
      defaultValue: props.orgIids,
      fullWidth: true,
      validate: [
        validationWithCondition(required(), values.requireOrganization),
      ],
      rootIids: values.organizationRootIids,
      includeRoot: values.includeRootOrganizations,
      getOnlyOrganizationWhereUserHasPermission:
        values.getOnlyOrganizationWhereUserHasPermission,
      defaultOrganizations:
        Array.isArray(props.orgIids) && props.orgIids.length > 0
          ? props.orgIids
          : undefined,
      includeSubOrganizations: 0,
      includeSubOrganizationsLabel: t1('include_users_in_sub_organizations'),
    }),
    include_sub_organizations: includeSubOrganizations(domainInfo.conf),
    skill,
    // text: text(),
    textOp: textOp(),
    sex: sex(),
    age: age(),
    experience: experience(),
    code: code(),
    codes: codes(),
    nin_codes: codes(true),
    mails: mails(),
    nin_mails: mails(true),
    dtts: dtts(),
    dia_ban_khokhan: dia_ban_khokhan(),
    lname: lname(),
    external: external(),
    name: name(),
    iid: iid(),
    mail: mail(),
    contract_box: contractBox,
    statuses: statuses(),
    credit_syllabuses: creditSyllabuses(formid),
    total_credit_box: totalCreditBox,
    average_score_box: averageScoreBox,
    subject,
    school__grade: gradeElement(domainInfo, true, t1('grade')), // for search
    school__group: groupElement(values, 'school__grade'),
    number_of_warnings: {
      type: 'number',
      min: 0,
      step: 1,
      floatingLabelText: t1('number_of_warnings'),
      classWrapper: 'col-md-6 m-t-10',
      fullWidth: true,
    },
    time_to_start: {
      type: DatePicker,
      uiLib: UiLibs.ANT,
      classWrapper: 'col-md-6',
      floatingLabelText: t1('date_user_join_learning'),
      fullWidth: true,
      getEndDate: true,
      maxDate: new Date(),
      autoOk: true,
    },
    grade: {
      type: 'multiCheckbox',
      floatingLabelText: t1('user_grade'),
      options: userGradeOptions(),
      inline: true,
    },
    training_grades: trainingGrades(formid, values.grade),
    academic_categories: academicCategories(formid, {}),
    cot_can_data_by_category: cotCanDataByCategory({
      forRecap,
    }),
    target_user: taphuanTargetUserMulti(formid),
  };
  if (forRecap) {
    return addPropsToEverySchemaElements(elementsSchema, {
      elementDisplayMode: elementDisplayModes.RECAP,
    });
  }
  return elementsSchema;
};

const ui = (step, values, themeConfig, xpath, formid) => {
  const availFilters = getAvailableFilters(values, xpath);
  const allowedEnableFiltersKey = getAllowedEnableFiltersKey(
    values && values.is_staff,
  );
  //Hack display all field
  let allowedEnabledFilters = (
    get(store.getState(), allowedEnableFiltersKey) || []
  ).filter((f) => f !== 'text');
  allowedEnabledFilters.splice(
    allowedEnabledFilters.indexOf('user_organizations') + 1,
    0,
    'include_sub_organizations',
  );
  let fields = availFilters ? ['textOp', ...allowedEnabledFilters] : ['textOp'];
  // let fields;

  return [
    // TODO: should we switch if it's ums or enterprise here
    // or get the list from configurations, of which fields are searchable by each school
    {
      id: 'filters', // you still have to have this id even for freestyle
      fields,
    },
  ];
};

const getSchema = (forRecap = false, props = {}) => ({
  schema: getElementsSchema(forRecap, props),
  ui,
  layout: forRecap
    ? {
        freestyle: 1,
        component: MinimalSearchRecapFreeStyleLayout,
      }
    : commonFormLayouts.DEFAULT,
});

export const searchFormSchema = (props = {}) => getSchema(false, props);
export const searchRecapFormSchema = (props = {}) => getSchema(true, props);

export default getSchema();
