import { t1 } from 'translate';

const userIdentifiers = () => ({
  type: 'text',
  floatingLabelText: t1(
    'exact_user_codes,_emails,_or_iids_separated_by_spaces_or_commas',
  ),
  multiLine: true,
  rows: 5,
});

export default userIdentifiers;
