import React from 'react';
import PrimaryButton from 'components/common/primary-button';
import Icon from 'components/common/Icon';
import DetailOnDialog from 'components/common/detail-on-dialog';
import NewDeal from './NewDeal';

const NewDealBtn = () => {
  return (
    <DetailOnDialog
      renderPreview={({ showFull }) => (
        <PrimaryButton
          name="submit"
          type="submit"
          icon={<Icon icon="plus" />}
          label={'Thêm hợp đồng'}
          onClick={showFull}
        />
      )}
      renderFull={({ closeDialog }) => (
        <NewDeal searchFormId="material-search" step={'batch'} />
      )}
      dialogOptionsProperties={{
        title: 'Thêm hợp đồng',
        handleClose: true,
        width: '80%',
      }}
    />
  );
};

export default NewDealBtn;
