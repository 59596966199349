import React from 'react';
import { t1 } from 'translate';
import { required } from 'common/validators';
import { isOfflineExam } from 'common/learn';
import lodashGet from 'lodash.get';
import { convertBooleanValueToInt } from 'common/normalizers';
import {
  filterLocationTypesBaseOnSupportedLearningMethods,
  generateRandomStr,
} from 'utils/Util';
import {
  getLocationTypes,
  locationTypes,
  roomTypes,
} from '../common/constants';
import scheduledSchema from './schedule-schema';

import LayoutFreeStyle from './layout_free_style';
import { get } from 'common/utils/object';
import moment from 'moment';
import apiUrls from '../endpoints';
import { checkbox } from 'components/common/forms/schema-fields';
import Attachments from 'schema-form/elements/attachments';
import { courseFilter } from '../../common/schemaFormField';
import Perm from 'common/utils/Perm';
import {
  getEpIidFromParentNode,
  getTpIidFromParentNode,
  isMeeting,
} from '../utils';
import { isSupport } from 'components/admin/user/utils';

const getLocationTypesByTypeOfCourse = (course, domainInfo) => {
  if (isOfflineExam(course)) {
    return getLocationTypes().filter(
      (opt) => opt.value === locationTypes().ILT_PHYSICAL,
    );
  }

  const supportedLearningMethods = lodashGet(
    domainInfo,
    'school.supported_learning_methods',
  );

  return filterLocationTypesBaseOnSupportedLearningMethods(
    supportedLearningMethods,
    getLocationTypes(),
  );
};

const numberClassHourDefault = 1;
const durationOfClassHourDefault = 60;

const sessionBreakTimeDefault = 0;

const schema = (formid, values, step, xpath, props, domainInfo) => {
  const { node, mode, sessionStatus } = props;
  const numberClassHour =
    lodashGet(
      domainInfo,
      'conf.create_session_default_values.number_class_hour_default',
    ) || numberClassHourDefault;

  const durationOfClassHour =
    lodashGet(
      domainInfo,
      'conf.create_session_default_values.duration_of_class_hour_default',
    ) || durationOfClassHourDefault;

  const sessionBreakTime =
    lodashGet(
      domainInfo,
      'conf.create_session_default_values.session_break_time',
    ) || sessionBreakTimeDefault;

  const defaultCode = generateRandomStr(6);
  const parentNode = lodashGet(props, 'parentNode');

  return {
    course_iids_or_codes: {
      type: 'text',
      floatingLabelText: t1('course_iids_or_codes'),
      multiLine: true,
      validate: [required(t1('course_iids_or_codes_can_not_be_empty'))],
    },
    course_iids: courseFilter({
      orgIids: lodashGet(props, 'orgIids'),
      isStudent: lodashGet(props, 'isStudent'),
      epIid: getEpIidFromParentNode(parentNode),
      includeAllOption: false,
      multiple: true,
      showEndedCourses: false,
    }),
    name: {
      type: 'text',
      floatingLabelText: t1('virtual_class_name'),
      placeholder: t1('enter_virtual_class_name'),
      validate: [required(t1('virtual_class_name_is_not_empty!'))],
    },
    platform: {
      type: 'select',
      floatingLabelText: t1('virtual_class_available_platforms'),
      options: 'async',
      validate: [required()],
      populateValue: true,
      paramsasync: {
        __url__: apiUrls.available_platforms,
      },
    },
    session_ids: {
      type: 'array',
    },
    rename_session: {
      type: 'checkbox',
      label: t1('rename_session_cloned'),
      defaultValue: 1,
      normalize: convertBooleanValueToInt,
    },
    location: {
      type: 'radio',
      floatingLabelText: t1('location'),
      floatingLabelFixed: true,
      options: getLocationTypesByTypeOfCourse(node, domainInfo),
      fullWidth: true,
      defaultValue: locationTypes().ILT_BBB,
    },
    room_types: {
      type: 'select',
      multiple: true,
      floatingLabelText: t1('required_room_type'),
      floatingLabelFixed: true,
      options: roomTypes(),
      fullWidth: true,
      defaultValue: ['theory'],
    },
    learn_duration: {
      type: 'number',
      defaultValue: numberClassHour * durationOfClassHour,
      floatingLabelText: t1('duration(_minutes)'),
      fullWidth: true,
      validate: [required(t1('invoice_type_cannot_be_empty'))],
    },
    break_time: {
      type: 'number',
      fullWidth: true,
      defaultValue: sessionBreakTime,
      floatingLabelText: t1('break_time'),
    },
    scheduled: {
      type: 'section',
      schema: scheduledSchema({
        mode,
        node,
        courseIids: lodashGet(values, 'course_iids'),
        courseIidsOrCodes: lodashGet(values, 'course_iids_or_codes'),
        tpIid: getTpIidFromParentNode(lodashGet(props, 'parentNode')),
        sessionStatus,
      }),
    },
    download_materials: {
      type: Attachments,
      label: t1('download_materials'),
      allowDownload: true,
      multiple: true,
    },
    enable_recording: {
      type: 'checkbox',
      label: t1('enable_recording'),
      normalize: convertBooleanValueToInt,
      defaultValue: 1,
    },
    enable_public: {
      type: 'checkbox',
      label: t1('enable_public'),
      normalize: convertBooleanValueToInt,
    },
    public_code: {
      type: 'text',
      floatingLabelText: t1('public_code'),
      placeholder: t1('enter_public_code'),
      validate: [required(t1('public_code_cannot_be_empty'))],
      defaultValue: defaultCode,
    },
    is_testing: checkbox(t1('is_testing_session'), {
      readOnly:
        get(props, 'isCourseTest') || lodashGet(node, 'status') === 'studied',
      defaultValue: get(props, 'isCourseTest') ? 1 : 0,
      fullWidth: 1,
      onChange: get(props, 'isTestingChanged'),
    }),
    max_user_count: {
      type: 'text',
      floatingLabelText: t1('max_user_count'),
      classWrapper: 'col-md-2',
    },
    max_devices_per_session: {
      type: 'text',
      floatingLabelText: t1('max_devices_per_session'),
      classWrapper: 'col-md-2',
    },
    interpretation_enabled: checkbox(t1('interpretation_enabled'), {
      readOnly: lodashGet(node, 'status') === 'studied',
      defaultValue: 0,
      fullWidth: 1,
    }),
    stream_link: {
      type: 'text',
      floatingLabelText: t1('stream_link'),
      classWrapper: 'col-md-12',
    },
  };
};

const ui = (step, values, themeConfig, xpath, formid, props) => {
  const sessionIds = lodashGet(values, 'session_ids');
  const enablePublic = lodashGet(values, 'enable_public');
  const isRoot = Perm.hasPerm('root');
  const parentNode = lodashGet(props, 'parentNode');
  const tpIid = getTpIidFromParentNode(parentNode);
  const epIid = getEpIidFromParentNode(parentNode);
  const type = lodashGet(props, 'type');
  if (Array.isArray(sessionIds) && sessionIds.length) {
    return [
      {
        id: 'default',
        title: t1("clones_%s_session('s)_by_session('s)_selected", [
          sessionIds.length,
        ]),
        fields: ['session_ids', 'rename_session'],
      },
    ];
  }

  return [
    {
      id: 'default',
      fields: [
        'name',
        tpIid ? 'course_iids_or_codes' : 'course_iids', // hiển thị textbox nhập course_iids hoặc course_codes thay vì select box chọn course_iids nếu form nằm trong TP
        'scheduled',
        'download_materials',
        'enable_recording',
        'enable_public',
        'platform',
        (isSupport() || isRoot) && 'is_testing',
        enablePublic && 'public_code',
        (isSupport() || isRoot) && 'max_user_count',
        (isSupport() || isRoot) && 'max_devices_per_session',
        'interpretation_enabled',
        'stream_link',
      ].filter(Boolean),
    },
  ];
};

const layout = (step, values, xpath, props) => {
  const sessionIds = lodashGet(values, 'session_ids');
  const { node } = props;

  if (Array.isArray(sessionIds) && sessionIds.length) {
    return null;
  }

  return {
    component: LayoutFreeStyle,
    freestyle: 1,
    optionsProperties: {
      node,
    },
  };
};

const finalProcessBeforeSubmit = (fullData) => {
  let sessionDateAsString = '';
  const sessionDateUnix = get(fullData, 'scheduled.date_time');

  if (sessionDateUnix) {
    sessionDateAsString = moment(sessionDateUnix * 1000).format('YYYY-MM-DD');
  }

  const scheduled = {
    ...fullData.scheduled,
    date_time: sessionDateAsString,
  };

  // remove learn_duration field from scheduled and move it to outside of scheduled obj
  delete scheduled.learn_duration;

  return {
    ...fullData,
    scheduled,
    learn_duration: lodashGet(fullData, 'scheduled.learn_duration'),
  };
};

export default {
  schema,
  ui,
  layout,
  finalProcessBeforeSubmit,
};
