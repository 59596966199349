import React from 'react';
import get from 'lodash.get';
import CommonAntdTable from 'components/common/antd/table';
import { getChuongTrinhName } from 'components/bdtx/configs';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import { getTargetUser, userGradeToText } from 'configs/constants/user';
import CancelChosenModule from 'components/bdtx/choose-modules/cancel-chosen-module/CancelChosenModule';

const TableResult = ({
  chuongTrinh,
  modules,
  searchFormId,
  schoolYear,
  showAction = true,
}) => {
  const formatModules = () => {
    let modulesFormatted = [];

    modules.forEach((module) => {
      const chosenInfo = get(module, 'chosen_info', []);

      modulesFormatted = [
        ...modulesFormatted,
        ...chosenInfo.map((info, index) => ({
          module,
          ...info,
          rowSpan: !index ? chosenInfo.length : 0,
        })),
      ];
    });

    return modulesFormatted;
  };

  const renderTableColumns = () => [
    {
      title: 'Mô đun',
      render: (item) => {
        const module = get(item, 'module', {});

        return {
          children: <span>{get(module, 'name')}</span>,
          props: {
            rowSpan: get(item, 'rowSpan', 0),
          },
        };
      },
    },
    {
      title: 'Thông tin đơn vị đã chọn mô đun',
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: [
        {
          title: 'Đơn vị',
          render: (item) => {
            const organization = get(item, 'organization');

            return <span>{get(organization, 'name')}</span>;
          },
        },
        {
          title: 'Đối tượng',
          type: TYPE_OF_TABLE_DATA.TITLE,
          render: (item) => {
            const targetUser = get(item, 'target_user');

            return <span>{getTargetUser(targetUser)}</span>;
          },
        },
        {
          title: 'Cấp học',
          type: TYPE_OF_TABLE_DATA.TITLE,
          render: (item) => {
            const cap = get(item, 'cap');

            return <span>{userGradeToText(cap)}</span>;
          },
        },
        ...(showAction
          ? [
              {
                title: 'Thao tác',
                type: TYPE_OF_TABLE_DATA.TITLE,
                render: (item) => {
                  const module = get(item, 'module');
                  const cap = get(item, 'cap');
                  const targetUser = get(item, 'target_user');
                  const organization = get(item, 'organization');

                  return (
                    <CancelChosenModule
                      module={module}
                      cap={cap}
                      targetUser={targetUser}
                      chuongTrinh={chuongTrinh}
                      organization={organization}
                      searchFormId={searchFormId}
                      schoolYear={schoolYear}
                    />
                  );
                },
              },
            ]
          : []),
      ],
    },
  ];

  return (
    <>
      <h3 className="text-secondary">{getChuongTrinhName(chuongTrinh)}</h3>

      {!modules || !modules.length ? (
        <SimpleNoResult
          text={`${getChuongTrinhName(
            chuongTrinh,
          )} chưa có mô đun được chọn cho các đơn vị con`}
        />
      ) : (
        <CommonAntdTable
          dataSource={formatModules()}
          columns={renderTableColumns()}
          pagination={false}
          className="learn-default assign-form"
        />
      )}
    </>
  );
};

export default TableResult;
