import React from 'react';
import { Element } from 'schema-form/elements';
import DatePicker from 'schema-form/elements/date-picker';

const DatePickerField = ({ onChange, ...remain }) => {
  return (
    <Element
      schema={{
        ...remain,
        type: DatePicker,
        onChange,
      }}
    />
  );
};

export default DatePickerField;
