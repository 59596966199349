import CryptoJS from 'crypto-js';

const CryptoJSAesDecrypt = (passphrase, plain_text) => {
  var AesKey = passphrase;
  var message = plain_text;
  var ECBOptions = {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  };
  var key = CryptoJS.enc.Utf8.parse(AesKey);
  var bytes = CryptoJS.AES.decrypt(message, key, ECBOptions);
  var originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

export const CryptoJSAesDecryptNormalPassphrase = (passphrase, plain_text) =>
  CryptoJSAesDecrypt(CryptoJS.MD5(passphrase), plain_text);

export default CryptoJSAesDecrypt;
