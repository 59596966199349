const SAVE_APP_VERSION = 'SAVE_APP_VERSION';

const SAVE_SESSION_ENDED = 'SAVE_SESSION_ENDED';

const SYSTEM_MESSAGE_LOADING = 'SYSTEM_MESSAGE_LOADING';
const SYSTEM_MESSAGE_LOADING_SUCCESS = 'SYSTEM_MESSAGE_LOADING_SUCCESS';
const SYSTEM_MESSAGE_LOADING_ERROR = 'SYSTEM_MESSAGE_LOADING_ERROR';
const SYSTEM_MESSAGE_CLEAR = 'SYSTEM_MESSAGE_CLEAR';

const CHANGELOG_LOADING = 'CHANGELOG_LOADING';
const CHANGELOG_LOADING_SUCCESS = 'CHANGELOG_LOADING_SUCCESS';
const CHANGELOG_LOADING_ERROR = 'CHANGELOG_LOADING_ERROR';

export {
  SAVE_APP_VERSION,
  SAVE_SESSION_ENDED,
  SYSTEM_MESSAGE_LOADING,
  SYSTEM_MESSAGE_LOADING_SUCCESS,
  SYSTEM_MESSAGE_LOADING_ERROR,
  SYSTEM_MESSAGE_CLEAR,
  CHANGELOG_LOADING,
  CHANGELOG_LOADING_SUCCESS,
  CHANGELOG_LOADING_ERROR,
};
