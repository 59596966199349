import React from 'react';
import { t1 } from 'translate';
import RTE from 'schema-form/elements/richtext';
import { requiredRTE, restrictionWords } from 'common/validators';

const schema = () => ({
  comment: {
    type: RTE,
    fullWidth: true,
    validate: [
      requiredRTE(t1('comment_cannot_be_empty')),
      restrictionWords(t1('comment_has_restriction_words')),
    ],
  },
});

const ui = () => [
  {
    id: 'default',
    fields: ['comment'],
  },
];

export default {
  schema,
  ui,
  layout: {
    component: ({ groups, readOnly }) => {
      return (
        <div>
          <div>{groups.default.fieldNames.comment}</div>
          <div className="text-center m-t-15">
            <button disabled={readOnly} className="btn btn-secondary">
              Gửi góp ý
            </button>
          </div>
        </div>
      );
    },
    freestyle: 1,
  },
};
