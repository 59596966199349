import React from 'react';
import CommonAntdTable from 'components/common/antd/table';
import { t1 } from 'translate';
import { Link } from 'react-router-dom';
import Tag from 'antd/lib/tag';
import { getTargetUser, userGradeToShortText } from 'configs/constants/user';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import Status from 'components/common/Status';

const TableResult = ({ items }) => {
  const renderTableColumns = () => {
    return [
      {
        title: t1('program_name'),
        key: 'name',
        render: (row) => (
          <>
            <Link to={`/admin/program/${row.iid}/children`} className={'m-r-5'}>
              {row.name}
            </Link>
          </>
        ),
      },
      {
        title: t1('organization'),
        key: 'organizations_name',
        dataIndex: 'organizations_name',
        render: (organizations = []) => organizations.join(', '),
      },
      {
        title: 'Cấp học',
        width: 110,
        key: 'caps',
        render: (item) => {
          return (
            <div>
              {Array.isArray(item.caps) &&
                item.caps.map((cap, i) => (
                  <Tag className={'m-5'} key={`cap-${i}`}>
                    {userGradeToShortText(cap)}
                  </Tag>
                ))}
            </div>
          );
        },
      },
      {
        title: t1('target_users'),
        key: 'target_users',
        width: 80,
        render: (item) => (
          <div>
            {Array.isArray(item.target_users) &&
              item.target_users.map((target, i) => (
                <Tag className={'m-5'} key={`target-${i}`}>
                  {getTargetUser(target)}
                </Tag>
              ))}
          </div>
        ),
      },
      {
        title: t1('number_of_syllabuses'),
        key: 'number_of_syllabuses',
        dataIndex: 'children',
        width: 100,
        type: TYPE_OF_TABLE_DATA.TITLE,
        render: (children = []) => children.length,
      },
      {
        title: t1('status'),
        key: 'status',
        type: TYPE_OF_TABLE_DATA.TITLE,
        width: 150,
        render: (row) => {
          const status = row.status;
          if (typeof status === 'undefined') {
            return <span>Đang chờ duyệt</span>;
          }

          return <Status status={status} />;
        },
      },
    ];
  };

  return (
    <CommonAntdTable
      dataSource={items}
      columns={renderTableColumns()}
      pagination={false}
      childrenColumnName={null}
      className="learn-default"
    />
  );
};

export default TableResult;
