import React, { useEffect } from 'react';
import SchemaForm from 'schema-form/Form';
import schema from './schema';
import { connect } from 'react-redux';
import { t1 } from 'translate';
import { portals } from 'components/common/portal';
import get from 'lodash.get';
import { steps } from 'common/learn/exercise';
import Icon from 'antd/lib/icon';
import NewButton from 'components/common/primary-button';
import withSnackbar from 'common/hoc/withSnackbar';
import {
  isOEDigitalSupportPlanLayoutType,
  isOESupportPlanLayoutType,
} from './util';
import { supportPlanStatuses } from 'configs/constants/course';
import { submit, change } from 'redux-form';
import fetchData from 'components/common/fetchData';
import { loadingStatuses } from 'configs/constants';

const SubmitButton = (props) => {
  const { formid, dispatch } = props;

  const handleSubmitButtonClick = () => {
    dispatch(submit(formid));
  };

  return (
    <div className="ui-form-control m-t-15">
      <NewButton
        primary
        onClick={handleSubmitButtonClick}
        className={'submit-open-ended-question'}
        label={t1('submit_open_ended_answer')}
        icon={<Icon type="check-circle" />}
      />
    </div>
  );
};

const allowFetchUserAnswer = ({ question, userAnswers }) => {
  return (
    isOEDigitalSupportPlanLayoutType(question) && !get(userAnswers, 'content')
  );
};

const Form = (props) => {
  const [hideSubmitButton, setHideSubmitButton] = React.useState(false);
  const [populatedDefaultValue, setDefaultValue] = React.useState(false);

  const {
    question = {},
    loadingStatus,
    userAnswers,
    supportPlan,
    formid,
    dispatch,
  } = props;

  useEffect(
    () => {
      const content = get(supportPlan, 'content');
      if (
        allowFetchUserAnswer({ question, userAnswers }) &&
        loadingStatus === loadingStatuses.FINISHED &&
        content &&
        !populatedDefaultValue
      ) {
        setDefaultValue(true);
        dispatch(change(formid, 'content', content));
      }
    },
    [
      populatedDefaultValue,
      setDefaultValue,
      question,
      loadingStatus,
      userAnswers,
      supportPlan,
      formid,
      dispatch,
    ],
  );

  const isDigitalSupportPlanLayoutType = isOEDigitalSupportPlanLayoutType(
    question,
  );

  const disabledStep = get(props, 'exerciseStep') === steps.REVIEW;

  const hideSubmitBtnWhenSupportPlanApproved =
    isOESupportPlanLayoutType(question) &&
    get(question, 'under_organization_management_status') ===
      supportPlanStatuses.SUPPORT_PLAN_MASTER_APPROVED;

  const disabled = disabledStep || hideSubmitBtnWhenSupportPlanApproved;

  return (
    <>
      <SchemaForm
        {...props}
        isDigitalSupportPlanLayoutType={isDigitalSupportPlanLayoutType}
        disabled={disabled}
        hideSubmitButton={
          props.hideSubmitButton || hideSubmitButton || disabled
        }
        setHideSubmitButton={setHideSubmitButton}
        schema={schema}
        ntype={'take'}
        mode={'new'}
        resetForm={false}
        submitButton={(formValues) => {
          return <SubmitButton {...props} formValues={formValues} />;
        }}
      />
      <div id={portals.MARKING_ASSESSMENT_RESULT} />
    </>
  );
};

export default fetchData((props) => {
  const { questionIid, courseIid } = props;
  const fetchCondition = allowFetchUserAnswer(props);
  return {
    baseUrl: '/take/api/get-user-answer-from-old-support-plan',
    params: {
      questionIid,
      courseIid,
    },
    propKey: 'supportPlan',
    fetchCondition,
    refetchCondition: () => false,
  };
})(withSnackbar()(connect()(Form)));
