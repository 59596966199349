import React from 'react';
import get from 'lodash.get';
import CommonAntdTable from 'components/common/antd/table';
import { t1 } from 'translate';
import ItemTag from '../item-tag';

const CorrectAnswer = ({ question }) => {
  const {
    ddm_questions = [],
    ddm_answers = [],
    ddm_correct_answers = [],
  } = question;

  const getTableData = () => {
    return ddm_questions.map((question, index) => {
      const currentCorrectAnswers = get(ddm_correct_answers, `[${index}]`, []);
      const answerFull = currentCorrectAnswers.map((answer) => {
        const index = parseInt(answer);
        return get(ddm_answers, `[${index}]`, []);
      });
      return {
        ...question,
        correctAnswers: answerFull,
      };
    });
  };

  const columns = [
    {
      title: t1('question'),
      key: 'question',
      dataIndex: 'question',
      render: (question, item) => <span>{get(item, 'content')}</span>,
      width: 200,
    },
    {
      title: t1('answer'),
      key: 'correct',
      dataIndex: 'correct',
      render: (correct, item) => {
        const correctAnswers = get(item, 'correctAnswers', []);
        return (
          !!correctAnswers.length &&
          correctAnswers.map((answer, index) => {
            return <ItemTag key={index} item={answer} />;
          })
        );
      },
    },
  ];

  return (
    <div className="m-t-30">
      <h3>{t1('correct_answer')}</h3>
      <CommonAntdTable
        dataSource={getTableData()}
        columns={columns}
        pagination={false}
        rowKey="id"
        className={'learn-default'}
        scroll={{
          x: true,
        }}
      />
    </div>
  );
};
export default CorrectAnswer;
