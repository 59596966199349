import React from 'react';
import shareHostImg from './imgs/sharehost.png';
import browserAcceptImg from './imgs/browser_accept.png';
import ImageOnDialog from 'components/common/image-on-dialog';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const JoinMeetingGuide = ({ isTeacher }) => {
  const renderGuideForStudent = () => {
    return (
      <Collapse collapsible="header">
        <Panel header="HƯỚNG DẪN MỞ BUỔI HỌC TRÊN ỨNG DỤNG ZOOM">
          <ul>
            <li>
              B1: Tải ứng dụng zoom{' '}
              <a
                href={'https://zoom.us/download'}
                target={'_blank'}
                className={'text-secondary'}
              >
                tại đây
              </a>{' '}
              sau đó cài vào máy tính
            </li>
            <li>
              B2: Click vào nút{' '}
              <strong>"Mở buổi học trên ứng dụng zoom"</strong> bên trên và chọn
              đồng ý mở ứng dụng khi được trình duyệt hỏi
              <div className={'text-center'}>
                <ImageOnDialog src={browserAcceptImg} />
              </div>
              <div className={'text-center m-t-5'}>
                (Lưu ý, hình ảnh thực tế có thể hơi khác tùy thuộc trình duyệt
                bạn sử dụng)
              </div>
            </li>
          </ul>
        </Panel>
      </Collapse>
    );
  };

  const renderGuideForTeacher = () => {
    return (
      <Collapse collapsible="header">
        <Panel header="HƯỚNG DẪN MỞ BUỔI HỌC TRÊN ỨNG DỤNG ZOOM (DÀNH CHO GIẢNG VIÊN)">
          <ul>
            <li>
              B1: Tải ứng dụng zoom{' '}
              <a
                href={'https://zoom.us/download'}
                target={'_blank'}
                className={'text-secondary'}
              >
                tại đây
              </a>{' '}
              sau đó cài vào máy tính
            </li>
            <li>
              B2: Click vào nút{' '}
              <strong>"Mở buổi học trên ứng dụng zoom"</strong> và chọn đồng ý
              mở ứng dụng khi được trình duyệt hỏi => bạn sẽ được vào buổi học
              bằng ứng dụng zoom
              <div className={'text-center'}>
                <ImageOnDialog src={browserAcceptImg} />
              </div>
              <div className={'text-center m-t-5'}>
                (Lưu ý, hình ảnh thực tế có thể hơi khác tùy thuộc trình duyệt
                bạn sử dụng)
              </div>
            </li>
            <li>B3: Phân quyền giảng viên (quyền host) cho ứng dụng zoom</li>
            <ul>
              <li>
                B3.1: Click vào nút "Mở buổi học trên Web" để vào buổi học qua
                web
              </li>
              <li>
                B3.2: Bấm vào "Người tham gia" trên web zoom và chia sẻ quyền
                host cho giảng viên trên ứng dụng zoom, khi này ứng dụng zoom
                của bạn sẽ có quyền host
                <div className={'text-center'}>
                  <ImageOnDialog src={shareHostImg} />
                </div>
              </li>
            </ul>
            <li>
              B4: Rời khỏi buổi học trên Web và tiếp tục buổi học trên ứng dụng
              zoom
            </li>
          </ul>
        </Panel>
      </Collapse>
    );
  };

  return renderGuideForStudent(); // now teacher & student can use the same guide
  // return isTeacher ? renderGuideForTeacher() : renderGuideForStudent();
};

export default JoinMeetingGuide;
