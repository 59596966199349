import React from 'react';
import SelectImage from 'schema-form/elements/select-image';
import './stylesheet.scss';
import withShowWarningOfPracticeMode from '../../withShowWarningOfPracticeMode';
import { isMMCCorrect } from '../../mc/util';
import { correctFullAnswerQuestions } from '../../utility';

class AnswerAvatar extends React.Component {
  handleWarningOfPracticeMode = (isCorrect, isCorrectFull) => {
    const {
      showWarningOfPracticeMode,
      showSuccessOfPracticeMode,
      hideWarningOfPracticeMode,
    } = this.props;

    if (!isCorrect) {
      showWarningOfPracticeMode();
    } else {
      if (isCorrectFull) {
        showSuccessOfPracticeMode();
      } else {
        hideWarningOfPracticeMode();
      }
    }
  };

  handleChooseAnswer = (indexes = []) => {
    const { handleChooseAnswer, question, practice } = this.props;
    const { answers = [] } = question;
    const isCorrect = isMMCCorrect(question, indexes);
    const isCorrectFull = correctFullAnswerQuestions(
      indexes.sort(),
      answers.sort(),
    );

    if (practice) {
      this.handleWarningOfPracticeMode(isCorrect, isCorrectFull);

      if (isCorrect || (Array.isArray(indexes) && !indexes.length)) {
        handleChooseAnswer(indexes);
      }
    } else {
      handleChooseAnswer(indexes);
    }
  };

  render() {
    let { question, highlights, disabled, userAnswers } = this.props;
    question = question || {};
    const { mc_answers } = question;
    // const mode = this.props.mode || 'single';

    return (
      <div>
        <SelectImage
          {...this.props}
          saveAsArrayWhenMultipleIsFalse
          notBlurNonselectedImage
          disabled={disabled}
          highlights={highlights}
          value={userAnswers}
          onChange={this.handleChooseAnswer}
          valueKey="@index"
          itemsPerLine={2}
          height="150px"
          data={mc_answers}
          title="text"
        />
      </div>
    );
  }
}

export default withShowWarningOfPracticeMode(AnswerAvatar);
