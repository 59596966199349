import React from 'react';
import Search from '../search';
import lodashGet from 'lodash.get';

const UnApprovedPlannedUsers = ({ trainingPlan }) => {
  return (
    <Search
      searchFormId={`training_plan_unapproved_planned_users_${
        trainingPlan.iid
      }`}
      showUnapproved
      trainingPlanIid={lodashGet(trainingPlan, 'iid')}
    />
  );
};

export default UnApprovedPlannedUsers;
