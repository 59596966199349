import lodashGet from 'lodash.get';
import get from 'lodash.get';
import { t1 } from 'translate';
import { categoryTypes } from 'configs/constants'; // todo move it inside organization
import Organizations from 'components/admin/organization/schema/elements/organizations';
import orgApiUrls from 'components/admin/organization/endpoints';
import { unaccentVietnamese } from 'common/utils/string/vn';
import { required } from 'common/validators';
import store from 'store';
import { isSupport } from 'components/admin/user/utils';
import { isEnableTemis } from 'components/temis/utils';
import subTypes from 'configs/constants/org-sub-types';
import { BGD_ORG_IID } from 'components/admin/organization/utils';

export const isOrganizationWithOrgTypes = (node, orgTypes) => {
  if (
    node.type === categoryTypes.CATEGORY_ORGANIZATION &&
    orgTypes &&
    Array.isArray(orgTypes) &&
    orgTypes.map((t) => String(t)).includes(String(node.sub_type))
  ) {
    return true;
  }

  return false;
};

export const phongbanInOrganization = ({
  orgTypes,
  ancestor_iids,
  defaultValue,
  readOnly = false,
  hiddenWhenOptionEmpty = false,
  floatingLabelText,
  firstOption,
  lastOption = {},
  ...props
}) => {
  const isTemis = isEnableTemis();

  return {
    type: 'select',
    floatingLabelText: floatingLabelText || 'Tổ chuyên môn',
    options: 'async',
    allowClear: true,
    showSearch: true,
    hiddenWhenOptionEmpty: hiddenWhenOptionEmpty,
    optionFilterProp: 'children',
    populateValue: true,
    readOnly: readOnly,
    defaultValue: defaultValue ? parseInt(defaultValue) : null,
    filterOption: (input, option) =>
      unaccentVietnamese(option.props.children)
        .toLowerCase()
        .indexOf(unaccentVietnamese(input).toLowerCase()) >= 0,
    paramsasync: {
      __url__: isTemis
        ? '/tcm/api/search'
        : orgApiUrls.get_organizations_for_select_options,
      value: isTemis
        ? {
            organization_iid: ancestor_iids,
            items_per_page: -1,
          }
        : {
            p_iids: ancestor_iids,
            sub_type: Array.isArray(orgTypes)
              ? orgTypes
                  .filter(({ is_phongban }) => is_phongban)
                  .map(({ type }) => type)
              : [], //Tổ bộ môn
          },
      key: `phongban_id_by_${
        Array.isArray(ancestor_iids) ? ancestor_iids.join('-') : ''
      }`,
      transformData: (data) => {
        const convertData = (data || []).map((d) => ({
          value: d.value || d.iid,
          label: d.name,
        }));

        const option =
          firstOption && typeof firstOption === 'object'
            ? firstOption
            : { label: 'Tất cả bộ môn', value: '' };
        return [option, ...convertData].concat(
          typeof lastOption === 'object' ? [lastOption] : [],
        );
      },
    },
    disabledWhenOptionEmpty: false,
    ...props,
  };
};

export const organizations = ({
  formid,
  rootIids,
  userOrgIidsAsRootIids,
  includeRoot,
  hasSearchDialog,
  label,
  hintText,
  defaultValue,
  populateDefaultValue = false,
  name,
  validate,
  readOnly,
  multiple,
  normalize,
  format,
  provinceId,
  districtId,
  onChange,
  fullWidth,
  classWrapper,
  subTypes,
  excludeOrganizationIids,
  subTypesDiscarded,
  organizationInactiveStatus,
  disabledBySubType,
  disabledOptions,
  modeRender,
  subTypesToDisplay,
  depthOfOrganizationTreeToShow,
  orgTypesReached,
  renderKey,
  // (BDTX) các EP, TP có thể có cây đơn vị khác nhau do cây đơn vị phụ thuộc vào module, năm học
  enrolmentPlanIid,
  trainingPlanIid,
  moduleIid,
  schoolYear,
  trainingPlanCategory,
  userTypes, // gv or cbql
  defaultValidate = [],
  isBdtx,
}) => {
  const isRoot = store.getState().domainInfo.isRoot;
  const isCS = isSupport(store.getState().user.info);
  const userOrganizations = lodashGet(
    store.getState(),
    'user.info.user_organizations',
    [],
  );

  const validation =
    isRoot || isCS
      ? defaultValidate
      : Array.isArray(validate)
      ? validate
      : [required()];

  return organizationsWithPhongBan({
    formid,
    shouldGetAllSubTypes: 0,
    rootIids,
    userOrgIidsAsRootIids,
    includeRoot,
    hasSearchDialog,
    label,
    hintText,
    defaultValue:
      defaultValue || (populateDefaultValue ? userOrganizations : null) || [],
    name,
    validate: validation,
    readOnly,
    multiple,
    normalize,
    format,
    provinceId,
    districtId,
    onChange,
    fullWidth,
    classWrapper,
    subTypes,
    subTypesDiscarded,
    organizationInactiveStatus,
    excludeOrganizationIids,
    disabledBySubType,
    disabledOptions,
    modeRender,
    subTypesToDisplay,
    depthOfOrganizationTreeToShow,
    orgTypesReached,
    renderKey,
    enrolmentPlanIid,
    trainingPlanIid,
    moduleIid,
    schoolYear,
    trainingPlanCategory,
    userTypes,
    isBdtx,
  });
};
// OLD LOGIC WITH TREE SELECT
// const defaultMultiSelectable = true;
// const defaultMultiSelectableLimit = Infinity;
//
// const multiSelectable = lodashGet(
//   configs,
//   'treeProps.multiSelectable',
//   defaultMultiSelectable,
// );
// const multiSelectableLimit = lodashGet(
//   configs,
//   'treeProps.multiSelectableLimit',
//   defaultMultiSelectableLimit,
// );
//
// let defaultLabel = '';
// if (!multiSelectable) {
//   defaultLabel = t1('organization');
// } else if (multiSelectableLimit !== Infinity) {
//   defaultLabel = t1(
//     `organizations_(select_up_to_${multiSelectableLimit}_choices)`,
//   );
// } else {
//   defaultLabel = t1('organizations');
// }
//
// return {
//   type: 'treeSelect',
//   nameElement: 'organizations',
//   componentElementEditor: OrganizationSelection,
//   optionsProperties: {
//     style: {
//       maxHeight: '135px',
//       overflowY: 'auto',
//     },
//   },
//   fullWidth: true,
//   fullWidthInput: true,
//   floatingLabelText: defaultLabel,
//   hintText: defaultLabel,
//   treeProps: {
//     multiSelectable: defaultMultiSelectable,
//     checkParentEqualCheckAllChildren: false,
//   },
//   params: {
//     view: 'tree',
//     depth: -1,
//     pIids: rootIids,
//   },
//   baseUrl: apiUrls.get_user_organization_tree,
//   noFetchDataResultText: t1('there_are_no_organization_to_select.'),
//   keyState: userOrganizationsSchemaFormKeyState(formid),
//   mapResultToTreeData: {
//     key: 'iid',
//     title: 'name',
//     value: 'iid',
//   },
//   mapTreeDataToText: 'title',
//   ...(configs || {}),
// };

export const organizationsWithPhongBan = ({
  formid,
  shouldGetAllSubTypes,
  rootIids,
  userOrgIidsAsRootIids = 1,
  includeRoot = 1,
  hasSearchDialog = true,
  label,
  hintText,
  defaultValue,
  name,
  validate,
  readOnly,
  multiple = true,
  normalize,
  format,
  provinceId,
  districtId,
  onChange,
  fullWidth = true,
  classWrapper = '',
  subTypes,
  subTypesDiscarded,
  organizationInactiveStatus,
  excludeOrganizationIids,
  disabledBySubType,
  disabledOptions,
  modeRender,
  subTypesToDisplay,
  depthOfOrganizationTreeToShow,
  orgTypesReached,
  renderKey,
  // (BDTX) các EP, TP có thể có cây đơn vị khác nhau do cây đơn vị phụ thuộc vào module, năm học
  enrolmentPlanIid,
  trainingPlanIid,
  moduleIid,
  schoolYear,
  trainingPlanCategory,
  userTypes,
  isBdtx,
}) => {
  if (!label) {
    if (multiple) {
      label = t1('organizations');
    } else {
      label = t1('organization');
    }
  }

  return {
    type: Organizations,
    includeRoot,
    shouldGetAllSubTypes,
    rootIids,
    userOrgIidsAsRootIids,
    provinceId,
    districtId,
    label,
    hintText: hintText || label,
    defaultValue,
    name,
    validate,
    normalize,
    format,
    onChange,
    multiple,
    readOnly,
    fullWidth,
    classWrapper,
    subTypes,
    subTypesDiscarded,
    organizationInactiveStatus,
    excludeOrganizationIids,
    disabledBySubType,
    disabledOptions,
    modeRender,
    subTypesToDisplay,
    depthOfOrganizationTreeToShow,
    orgTypesReached,
    renderKey,
    enrolmentPlanIid,
    trainingPlanIid,
    moduleIid,
    schoolYear,
    trainingPlanCategory,
    userTypes,
    isBdtx,
  };
};

export const includeSubOrganizations = (
  conf,
  { label = t1('include_sub_organizations'), defaultValue, ...props } = {},
) => {
  if (typeof defaultValue === 'undefined') {
    defaultValue = lodashGet(conf, 'default_search_sub_organizations');
  }

  if (typeof defaultValue === 'undefined') {
    defaultValue = 0;
  }

  return {
    ...props,
    type: 'checkbox',
    defaultValue,
    label,
  };
};

export const organizationSubTypes = ({
  label = t1('organization_types'),
  parentOrganizationIids = null,
  includeSubOrganizations = false,
  inline = false,
} = {}) => {
  return {
    type: 'multiCheckbox',
    options: 'async',
    floatingLabelText: label,
    fullWidth: true,
    inline,
    paramsasync: {
      __url__:
        orgApiUrls.get_organization_children_sub_types_for_select_options,
      key: `organization_sub_types_${parentOrganizationIids}_${includeSubOrganizations}`,
      value: parentOrganizationIids
        ? {
            parent_organization_iids: parentOrganizationIids,
            get_deeper_level: includeSubOrganizations,
          }
        : {},
    },
  };
};

// normal orgnization element, no grouping by subtypes
export const simpleOrganization = (
  elementProps = {},
  paramsasyncProps = {},
  isRequired,
  allOrganizationOption,
  apiUrl,
) => {
  const { value = {}, key, transformData } = paramsasyncProps;

  const defaultTransformData = (organizations) => {
    if (!organizations || !organizations.length) {
      return [];
    }

    const orgFormatted = organizations.map((org) => ({
      value: org.iid,
      label: org.name,
    }));

    if (allOrganizationOption && typeof allOrganizationOption === 'object') {
      return [allOrganizationOption].concat(orgFormatted);
    }

    return orgFormatted;
  };

  return {
    type: 'select',
    floatingLabelText: `Đơn vị ${isRequired ? '(*)' : ''}`,
    options: 'async',
    paramsasync: {
      __url__: apiUrl || '/organization/api/search',
      value: {
        type: 'organization',
        pIids: [BGD_ORG_IID],
        include_sub_organizations: 1,
        view: 'grid',
        items_per_page: -1,
        depth: -1,
        ...value,
      },
      transformData:
        typeof transformData === 'function'
          ? transformData
          : defaultTransformData,
      key: `simple_organization__${key}`,
    },
    validate: isRequired ? [required()] : [],
    showSearch: true,
    filterOption: (input, option) => {
      const children = get(option, 'props.children', '');
      return (
        unaccentVietnamese(children)
          .toLowerCase()
          .indexOf(unaccentVietnamese(input).toLowerCase()) >= 0
      );
    },
    ...elementProps,
  };
};

export const contentOrganizations = (
  elementProps = {},
  paramsasyncProps = {}, // null
  isRequired,
  allOrganizationOption,
  subTypesFilter,
) => {
  const subTypesToFilter =
    subTypesFilter && subTypesFilter.length
      ? subTypesFilter
      : [subTypes.TAPHUAN_SUBTYPE_BO_GD, subTypes.TAPHUAN_SUBTYPE_LTTU];

  const paramsAsyncProps = {
    value: {
      sub_type: subTypesToFilter,
    },
    key: `filter-org-by-sub-types-${subTypesToFilter.join('-')}`,
  };

  return simpleOrganization(
    elementProps,
    paramsAsyncProps,
    isRequired,
    allOrganizationOption,
    '/organization/api/get-content-organizations',
  );
};

// list of all org types that can make content
export const contentOrgTypes = (includeOthers = true) => {
  return [
    {
      value: subTypes.TAPHUAN_SUBTYPE_BO_GD,
      label: t1('sub_type_bgd'),
    },
    {
      value: subTypes.TAPHUAN_SUBTYPE_LTTU,
      label: t1('sub_type_dhsp'),
    },
    {
      value: subTypes.TAPHUAN_SUBTYPE_SO_GD,
      label: t1('sub_type_sgd_dt'),
    },
    includeOthers
      ? {
          value: -1,
          label: t1('other_organization'),
        }
      : null,
  ].filter(Boolean);
};
