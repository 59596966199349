import { createSelectorWithExtraParams } from 'utils/selector';
import { getNode } from 'components/admin/node/utils';
import { ntype, sequentialValue } from 'configs/constants';
import { templateTypes as templateTypesSco } from 'components/admin/sco/schema/tpl-types';
import { createSelector } from 'reselect';
import { isIidEqual } from 'components/learn/utils';
import get from 'lodash.get';
import sagaActions from 'actions/saga-creators';

export const statuses = {
  INIT: 'INIT',
  DOING: 'DOING',
  STARTED: 'STARTED',
  FINISHED: 'FINISHED',
  RETAKE: 'RETAKE',
};

export const errorStatuses = {
  DO_TAKE_EXAM_PER_TWO_DEVICE: 'DO_TAKE_EXAM_PER_TWO_DEVICE',
};

export const learnInfoSelector = (state) => state.learn && state.learn.info;

export const getItemLearnInfo = (info, itemIid) => info && info[itemIid];

export const getLearnItemInfoSelector = createSelectorWithExtraParams(
  learnInfoSelector,
  1,
  (info) => (itemIid) => getItemLearnInfo(info, itemIid),
);

export const getProgressSelector = createSelectorWithExtraParams(
  (state) => state.trackerProgress,
  1,
  (progress) => (itemIid) => progress[itemIid],
);

export const getLearnCourseIidSelector = createSelector(
  (state) => get(state, 'learn.courseIid'),
  (courseIid) => courseIid,
);

export const getLearnItemQuestionInfoSelector = createSelectorWithExtraParams(
  getLearnItemInfoSelector,
  2,
  (getLearnItemInfo) => (itemIid, questionUniqueId) => {
    const info = getLearnItemInfo(itemIid);
    return info && info.questions && info.questions[questionUniqueId];
  },
);

export const isStandardSco = (node) =>
  node && node.ntype === 'sco' && node.tpl_type === 'standard';

export const isExam = (node) =>
  node &&
  ((node.ntype === 'sco' && node.tpl_type === 'exam') ||
    (node.ntype === 'exercise' && node.type === 'exam') ||
    (node.ntype === 'syllabus' && node.is_exam));

export const isExamSco = (sco) => {
  if (sco && sco.tpl_type === 'exam') {
    return true;
  }

  return false;
};

export const isExamTemplate = (node) =>
  ['question-bank', 'exam-template'].includes(node && node.ntype);

export const isExamShift = (node) =>
  node && node.ntype === 'course' && node.exam_type === 'EXAM_SHIFT';
export const isSkill = (node) => node && node.ntype === 'skill';

export const isOfflineExam = (node) =>
  node && node.ntype === 'course' && node.exam_type === 'OFFLINE_EXAM';

export const isGroupAssignment = (node) =>
  node &&
  node.ntype === ntype.SCO &&
  node.tpl_type === templateTypesSco.TYPE_GROUP_ASSIGNMENT;

export const isAcademicScoreRubric = (node) =>
  node &&
  node.ntype === ntype.SKILL &&
  node.type === 'rubric' &&
  node.sub_type === 'academic_score';

export const isAttendanceScoreRubric = (node) =>
  node &&
  node.ntype === ntype.SKILL &&
  node.type === 'rubric' &&
  node.sub_type === 'attendance';

const isNodeBelongToThisSco = (nodes, scoIid, nodeIid) => {
  const scoInfo = nodes[scoIid] || {};
  if (!scoInfo || !scoInfo.children || !scoInfo.children.length) {
    return false;
  }
  for (let i = 0; i < scoInfo.children.length; i++) {
    const childrenNode = nodes[scoInfo.children[i]] || {};
    if (String(childrenNode.iid) === String(nodeIid)) {
      return true;
    } else {
      if (childrenNode.ntype === ntype.SCO) {
        const isNodeBelongToThisChildren = isNodeBelongToThisSco(
          nodes,
          childrenNode.iid,
          nodeIid,
        );
        if (isNodeBelongToThisChildren) {
          return true;
        }
      }
    }
  }
  return false;
};

const vipUsers = [
  've',
  'author@viettel.com.vn',
  'lephuongnga54@gmail.com',
  'xtnha@moet.gov.vn',
  'buithuhoaithkd@gmail.com',
  'trankieu1940@gmail.com',
  'ndmanh.gdth@moet.gov.vn',
  'tothuythaithinh@gmail.com',
  'thuyedcc@gmail.com',
  'nthhanh@moet.gov.vn',
  'phamhongthanhkm@gmail.com',
  'thannt@hnue.edu.vn',
  'tthgiang@daihocthudo.edu.vn',
  'huongthanh.tran@gmail.com',
  'buiphuongnga112@gmail.com',
  'nnyen@moet.gov.vn',
  'dieuhathuy@yahoo.com',
  'dangvanduc@hnue.edu.vn',
  'daothihong1955@yahoo.com.vn',
  'pttp.dtd@yahoo.com',
  'trinhthanhhai@tnus.edu.vn',
  'tnkhoa@moet.gov.vn',
  'gvtuan@bacgiang.edu.vn',
  'khanhnt@hnue.edu.vn',
  'ntqsuu@moet.gov.vn',
  'phuonghalan2112@gmail.com',
  'buianhtuan.nd@gmail.com',
  'nktu@moet.gov.vn',
  'mediahoangsa@gmail.com',
  'nguyenthitomai@spnttw.edu.vn',
  'nvquyet@moet.gov.vn',
  'buithibichngoc762018@gmail.com',
  'bien.anh2603@gmail.com',
  'lqhuy@moet.gov.vn',
  'manhtuannimus@gmail.com',
  'thinhvanh@yahoo.com',
  'thtoan@moet.gov.vn',
  'buithanhman030976@gmail.com',
  'truongminhchinh@dhsphue.edu.vn',
];

export const isPreviousNeighborDone = (itemIid, nodes, trackerProgress) => {
  const pId = get(nodes[itemIid], 'pid');
  const parent = nodes[pId];

  if (!parent) {
    return true;
  }

  const allChildrenOfParent = get(parent, 'children') || [];
  const itemIndex = allChildrenOfParent.findIndex(
    (childIid) => childIid == itemIid,
  );
  const previousIid = get(allChildrenOfParent, `[${itemIndex - 1}]`);

  if (!previousIid) {
    return true;
  }

  return (
    isItemDone(trackerProgress, previousIid) ||
    isItemPassed(trackerProgress, previousIid)
  );
};

/**
 * result will be {
 *   isAccessible: true, false or null (no info)
 *   notLearntIids: array of iid that user need to learn to access this item => only meaningful if isAccessible === false
 *   navIdToRedirectTo: navId of the most recent item that user can learn
 */
export const getLearnItemAccessibilityInfo = (
  nodes,
  syllabusIid,
  isPreview,
  trackerProgress,
  iid,
  userInfo,
) => {
  const syllabus = getNode(syllabusIid, null, nodes);
  const sequentialLearningType = get(syllabus, 'sequential_learning_type');
  const dependOnWeight = get(syllabus, 'depend_on_weight');

  const trackingLine = get(syllabus, 'tracking_line');

  const userCode = get(userInfo, 'code');

  if (isPreview || vipUsers.includes(userCode)) {
    return {
      isAccessible: true,
    };
  }

  if (!syllabus || !Array.isArray(trackingLine) || !iid) {
    return {
      isAccessible: null,
    };
  }

  const getItemIidAccessibilityInfo = (iid) => {
    const nodeToCheckAccessibility = getNode(iid, null, nodes);
    const pIid = get(nodeToCheckAccessibility, 'pid');

    if (pIid && pIid != iid) {
      const parentAccessibilityInfo = getItemIidAccessibilityInfo(pIid);
      if (get(parentAccessibilityInfo, 'isAccessible') === false) {
        return parentAccessibilityInfo;
      }
    }

    const positionInTrackingLine = trackingLine.findIndex(
      (trackingLineIid) => trackingLineIid == iid,
    );

    if (positionInTrackingLine === -1) {
      return {
        isAccessible: null,
      };
    }

    const sequential =
      nodeToCheckAccessibility && nodeToCheckAccessibility.sequential;

    let isAccessible = true;
    let notLearntIids = [];
    let navIdToRedirectTo = null;
    let shouldFindItemToRedirectTo = false;
    let haveCandidateForItemToRedirectTo = false;
    let nodeFromThisWillAffectAccessibility = false;
    let currentScoIid = null;

    // start from 1 because the first element in trackingLine is syllabus
    for (let i = 1; i < positionInTrackingLine; i += 1) {
      const nodeIid = trackingLine[i];
      const node = getNode(nodeIid, null, nodes);
      if (
        node &&
        (!sequential ||
          !sequential.length ||
          (sequential &&
            String(node.iid) !== String(pIid) &&
            (sequential.includes(String(node.iid)) ||
              sequential.includes(String(node.pid)))))
      ) {
        const nodeNavIndex = 1;
        const nodeNavId =
          node.ntype === ntype.SCO
            ? `${syllabusIid}-${nodeIid}-${nodeNavIndex}`
            : `${currentScoIid}-${nodeIid}-${nodeNavIndex}`;

        if (node.ntype === ntype.SCO) {
          currentScoIid = nodeIid;
          if (isNodeBelongToThisSco(nodes, nodeIid, iid)) {
            nodeFromThisWillAffectAccessibility = true;
          } else if (
            [sequentialValue.SCO, sequentialValue.ITEM].includes(
              sequentialLearningType,
            )
          ) {
            // if SCO has no weight, do not have to care about its children (next nodes in trackingLine)
            nodeFromThisWillAffectAccessibility = dependOnWeight
              ? Boolean(node.weighed_score)
              : true;
          } else {
            nodeFromThisWillAffectAccessibility = false;
          }
        }

        let canBeItemToRedirectTo = false;
        let canAffectAccessibility = false;
        let shouldFinishChecking = false;
        if (sequential && sequential.length) {
          canAffectAccessibility = String(node.iid) !== String(pIid);
          canBeItemToRedirectTo = node.ntype !== ntype.SCO || isExam(node);
        } else {
          switch (sequentialLearningType) {
            case sequentialValue.SCO: {
              canAffectAccessibility =
                nodeFromThisWillAffectAccessibility &&
                node.ntype === ntype.SCO &&
                !isNodeBelongToThisSco(nodes, nodeIid, iid);
              canBeItemToRedirectTo = node.ntype !== ntype.SCO || isExam(node);
              break;
            }
            case sequentialValue.ITEM: {
              canAffectAccessibility =
                nodeFromThisWillAffectAccessibility &&
                !isNodeBelongToThisSco(nodes, nodeIid, iid);
              canBeItemToRedirectTo = node.ntype !== ntype.SCO || isExam(node);
              break;
            }
            default: {
              shouldFinishChecking = true;
              break;
            }
          }
        }

        if (shouldFinishChecking) {
          break;
        }

        if (
          (sequential && sequential.length) ||
          !dependOnWeight ||
          (dependOnWeight &&
            (node.weighted || (node.ntype === ntype.SCO && node.weighed_score)))
        ) {
          const progress = trackerProgress[nodeIid];

          const passed =
            progress &&
            (progress.pf ||
              ((node.ntype !== ntype.EXERCISE ||
                node.practice ||
                node.no_marking) &&
                Number(progress.cp) === 100)); // sometimes PDF/Text item has cp = 100 but pf = 0

          if (!progress || !passed) {
            if (canAffectAccessibility) {
              isAccessible = false;
              notLearntIids = notLearntIids.concat([
                {
                  iid: nodeIid,
                  pid: isIidEqual(nodeIid, currentScoIid)
                    ? syllabusIid
                    : currentScoIid,
                },
              ]);
              if (!navIdToRedirectTo) {
                shouldFindItemToRedirectTo = true;
              }
            }
            if (shouldFindItemToRedirectTo && canBeItemToRedirectTo) {
              navIdToRedirectTo = nodeNavId;
              shouldFindItemToRedirectTo = false;
            }
          }
        }

        if (
          shouldFindItemToRedirectTo &&
          !haveCandidateForItemToRedirectTo &&
          canBeItemToRedirectTo
        ) {
          // act as a candidate in case there are no node with weighted > 0 and progress < threshold
          navIdToRedirectTo = nodeNavId;
          haveCandidateForItemToRedirectTo = true;
        }
      }
    }

    const shouldCheckWithPreviousNeighbor =
      sequentialLearningType === sequentialValue.ITEM;
    const isPrevDone = isPreviousNeighborDone(iid, nodes, trackerProgress);
    const canAccess = shouldCheckWithPreviousNeighbor
      ? isAccessible || isPrevDone
      : isAccessible;
    return {
      isAccessible: canAccess,
      notLearntIids: Array.isArray(sequential)
        ? sequential.map((iid) => ({
            iid,
            pid: get(nodes[iid], 'pid'),
          }))
        : notLearntIids,
      navIdToRedirectTo,
    };
  };

  return getItemIidAccessibilityInfo(iid);
};

export const getLearningItemUserIidSelector = createSelectorWithExtraParams(
  getLearnItemInfoSelector,
  1,
  (getLearnItemInfo) => (itemIid) => {
    const learnItemInfo = getLearnItemInfo(itemIid);
    return learnItemInfo && learnItemInfo.userIid;
  },
);

export const getLearningItemModeSelector = createSelectorWithExtraParams(
  getLearnItemInfoSelector,
  1,
  (getLearnItemInfo) => (itemIid) => {
    const learnItemInfo = getLearnItemInfo(itemIid);
    return learnItemInfo && learnItemInfo.mode;
  },
);

export const isClassGroup = (node) =>
  node && node.ntype === 'path' && node.type === 'classgroup';

export const isBranchedSCO = (node) => {
  return !!(node && node.ntype === ntype.SCO && !!Number(node.branched));
};

export const isNavigationSCO = (node) => {
  return !!(
    node &&
    node.ntype === ntype.SCO &&
    !!Number(node.is_navigation_sco)
  );
};

export const isSplSCO = (node) => {
  return !!(node && node.ntype === ntype.SCO && !!Number(node.is_spl));
};

const isSCOExpandByDefault = (node) => {
  return node && node.ntype === ntype.SCO && !!Number(node.expand_by_default);
};

export const shouldNodeBeExpanded = (node) => {
  return isSCOExpandByDefault(node);
};

export const shouldSkipNavigation = (node) => {
  return (
    node && node.ntype === ntype.SCO && !!Number(node.should_skip_navigation)
  );
};

export const isSyllabusSequential = (syllabus) => {
  return (
    syllabus &&
    syllabus.ntype === ntype.SYLLABUS &&
    [sequentialValue.SCO, sequentialValue.ITEM].includes(
      syllabus.sequential_learning_type,
    )
  );
};

export const isSequentialSCO = (node) => {
  return node && node.ntype === ntype.SCO && !!Number(node.is_sequential_spl);
};

export const isSCO = (node) => {
  return node && node.ntype === ntype.SCO;
};

export const isExercise = (node) => node && node.ntype === ntype.EXERCISE;

export const isItemDone = (trackerProgress, itemIid) => {
  return Number(get(trackerProgress, `${itemIid}.cp`)) === 100;
};

export const allItemsAreDone = (trackerProgress, items = []) => {
  return items.every((item) => isItemDone(trackerProgress, get(item, 'iid')));
};

export const isItemPassed = (trackerProgress, itemIid) => {
  return !!Number(get(trackerProgress, `${itemIid}.pf`));
};

export const isDescendantOfNavigationSCO = (
  nodes,
  nodeIid,
  navigationSCOIidToCompare, // check whether or not the navigation sco of a node is this sco
) => {
  const navigationSCOAncestor = getNavigationSCOAncestor(nodes, nodeIid);

  if (navigationSCOAncestor) {
    if (navigationSCOIidToCompare) {
      return isIidEqual(navigationSCOIidToCompare, navigationSCOAncestor.iid);
    }

    return true;
  }

  return false;
};

export const getNavigationSCOAncestor = (nodes, nodeIid) => {
  const node = nodes[nodeIid];

  if (!node || !node.iid) {
    // sometimes node is an empty object
    return null;
  }

  const parentNode = nodes[node.pid];

  if (isNavigationSCO(parentNode)) {
    return parentNode;
  }

  if (parentNode && parentNode.iid && parentNode.iid != nodeIid) {
    return getNavigationSCOAncestor(nodes, parentNode.iid);
  }

  return null;
};

export const saveLearningProgress = (
  progress,
  courseIid,
  dispatch,
  isPreview = false,
  isReview = false,
) => {
  if (!courseIid) {
    return;
  }

  if (isPreview || isReview) {
    return;
  }

  const data = {
    progress,
    ciid: courseIid,
  };

  dispatch(sagaActions.trackerProgressSave(data));
};
