import React, { Component } from 'react';
import fetchData from 'components/common/fetchData';
import getLodash from 'lodash.get';

const withAutomaticScaleVirtualClass = (WrappedComponent) => {
  class TemisConfigsHOC extends Component {
    render() {
      const {
        handleRefetch,
        automaticScaleVirtualClassEnabled,
        ...props
      } = this.props;
      return (
        <WrappedComponent
          {...props}
          automaticScaleVirtualClassEnabled={
            automaticScaleVirtualClassEnabled || 0
          }
          handleRefetchAsseementPhase={handleRefetch}
        />
      );
    }
  }

  return fetchData((props) => {
    const courseIid =
      getLodash(props, 'courseIid') ||
      getLodash(props, 'course_iid') ||
      getLodash(props, 'course.iid') ||
      getLodash(props, 'session.course_iid') ||
      getLodash(props, 'node.course_iid') ||
      getLodash(props, 'node.iid');

    const automaticScaleVirtualClassEnabled =
      props.automaticScaleVirtualClassEnabled;

    return {
      baseUrl: '/course/api/check-automatic-scale-lha-enabled',
      fetchCondition:
        !!courseIid && typeof automaticScaleVirtualClassEnabled === 'undefined',
      params: {
        course_iid: courseIid,
      },
      formatDataResult: (automaticScaleVirtualClassEnabled) => {
        return {
          automaticScaleVirtualClassEnabled:
            automaticScaleVirtualClassEnabled ||
            props.automaticScaleVirtualClassEnabled,
        };
      },
      refetchCondition: (prevProps) => {
        const courseIidOld =
          getLodash(prevProps, 'courseIid') ||
          getLodash(prevProps, 'course_iid') ||
          getLodash(prevProps, 'course.iid') ||
          getLodash(prevProps, 'session.course_iid') ||
          getLodash(prevProps, 'node.course_iid') ||
          getLodash(prevProps, 'node.iid');
        return courseIid && courseIidOld !== courseIid;
      },
    };
  })(TemisConfigsHOC);
};

export default withAutomaticScaleVirtualClass;
