import React from 'react';
import actions from 'actions/node/creators';
import { showVideoWindow } from 'actions/learn';
import { Button } from 'antd';
import { t1 } from 'translate';
import lodashGet from 'lodash.get';
import { connect } from 'react-redux';
import {
  isInfoGraphicMode,
  isReadMode,
  isWatchMode,
  VIEW_MODE,
} from 'components/learn/watch-or-read/utils';

const Action = ({
  lecture,
  listActiveVideoWindow,
  dispatch,
  playAsPicture,
  itemType,
}) => {
  const readMode = isReadMode(lecture);
  const infoGraphicMode = isInfoGraphicMode(lecture);
  const watchMode = isWatchMode(lecture);

  // TODO: style those buttons based on lecture.watch_or_learn mode
  const setWatchOrReadMode = (mode) => {
    const listVideoWithoutLecture = listActiveVideoWindow.filter(
      (lectureIid) => lectureIid !== lecture.iid,
    );

    const addLastVideo = [...listVideoWithoutLecture, lecture.iid];

    dispatch(
      actions.treeUpsertNode({
        iid: lecture.iid,
        ntype: lecture.ntype,
        watch_or_learn: mode,
        hide_video_picture: false,
      }),
    );

    dispatch(
      showVideoWindow(
        mode === VIEW_MODE.READ || mode === VIEW_MODE.INFO_GRAPHIC
          ? addLastVideo
          : listVideoWithoutLecture,
      ),
    );
  };

  const setHideVideoPicture = () => {
    dispatch(
      actions.treeUpsertNode({
        iid: lecture.iid,
        ntype: lecture.ntype,
        hide_video_picture: true,
      }),
    );
  };

  if (playAsPicture) {
    return (
      <Button
        onClick={setHideVideoPicture}
        className="close-icon-window"
        shape="circle"
        icon="close-circle"
      />
    );
  }

  return (
    <span>
      <button
        className={`btn ${
          watchMode ? 'btn-primary' : 'btn-secondary btn-outline'
        } p-5 m-r-5`}
        onClick={() => {
          !watchMode && setWatchOrReadMode(VIEW_MODE.WATCH);
        }}
      >
        {t1(`learn_view_${itemType || 'video'}`)}
      </button>
      {lecture.sco_iid && (
        <>
          <button
            className={`btn ${
              readMode ? 'btn-primary' : 'btn-secondary btn-outline'
            } p-5  m-r-5`}
            onClick={() => {
              !readMode && setWatchOrReadMode(VIEW_MODE.READ);
            }}
          >
            {t1('learn_read')}
          </button>
        </>
      )}
      {lecture.infographic_sco_iid && (
        <>
          <button
            className={`btn ${
              infoGraphicMode ? 'btn-primary' : 'btn-secondary btn-outline'
            } p-5 m-r-5`}
            onClick={() => {
              !infoGraphicMode && setWatchOrReadMode(VIEW_MODE.INFO_GRAPHIC);
            }}
          >
            {t1('info_graphic')}
          </button>
        </>
      )}
    </span>
  );
};

const mapStateToProps = (state) => ({
  listActiveVideoWindow: lodashGet(state, 'learn.listActiveVideoWindow'),
});

export default connect(mapStateToProps)(Action);
