export default {
  bdtx_get_chosen_module: '/bdtx/assign-module/api/get-chosen-modules',
  bdtx_get_assign_module: '/bdtx/assign-module/api/list',
  bdtx_update_assign_module:
    '/bdtx/training-phase/deploy/update-assign-modules',
  bdtx_deployment: 'bdtx/api/auto-create-eps-for-tp',
  bdtx_get_training_plan_by_school_year:
    'bdtx/api/get-training-plan-by-school-year',
  bdtx_get_organizations_to_deploy: 'bdtx/api/get-organizations-to-deploy',
  bdtx_get_list_training_phase: 'bdtx/training-phase/api/search',
  bdtx_get_training_phase: 'bdtx/training-phase/api/get',
  bdtx_update_training_phase: 'bdtx/training-phase/api/update',
  bdtx_new_training_phase: 'bdtx/training-phase/api/new',
  bdtx_delete_training_phase: 'bdtx/training-phase/api/delete',
  bdtx_check_can_delete_training_phase:
    '/bdtx/training-phase/api/check-can-delete-training-phase',
  bdtx_preview_tps: 'bdtx/training-phase/deploy/get-deployment-status',
  bdtx_get_caps_of_training_phase: 'bdtx/training-phase/schema-form/get-caps',
  bdtx_cancel_deploy_for_organization:
    'bdtx/training-phase/deploy/cancel-deploy-for-organization',
  bdtx_get_overview: '/bdtx/training-phase/api/overview',

  bdtx_deploy: '/bdtx/training-phase/deploy/deploy',
  bdtx_additional_deployment:
    '/bdtx/training-phase/deploy/additional-deployment',

  // with step mode
  bdtx_step_get_list_modules_grouped_by_cap:
    '/bdtx/assign-module/step/get-list-modules-grouped-by-cap',
  bdtx_step_save_assign_module: '/bdtx/assign-module/step/save',
  bdtx_step_remove_assign_module: '/bdtx/assign-module/step/remove',
  bdtx_step_get_list_material: '/bdtx/assign-module/step/get-list-material',
  bdtx_step_save_module_material:
    '/bdtx/assign-module/step/save-material-for-module',
};
