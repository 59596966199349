import './box.scss';
import React, { useCallback } from 'react';
import lClone from 'lodash.clone';
import get from 'lodash.get';
import { t1 } from 'translate';
import { isSmallScreen } from 'common';
import ImageBackGround from 'components/common/views/image-background';
import DragAndDrop from '../../DnDProvider';
import ErrorMessageQuestion from '../../ErrorMessageQuestion';
import { isItemRoCorrect } from '../util';
import FixedItem from './FixedItem';
import MovableItem from './MovableItem';

const Reorder = ({
  className,
  userAnswers = [],
  question,
  setUserAnswers,
  shouldShowKey,
  disabled,
  practice,
}) => {
  const [showError, setShowError] = React.useState(0);
  const cssClass = 'reorder-question-type-box';
  const { reorders = [], answers, answer_as_hint } = question;
  const typeOfReorders = reorders.map((item) => item.id);

  const getLeftData = () => {
    return reorders.map((data, index) => {
      const lastDroppedItemId = get(userAnswers, `[${index}][0]`);
      let lastDroppedItem = reorders.find(
        (item) => item.id === lastDroppedItemId,
      );

      if (lastDroppedItem) {
        lastDroppedItem.type = lastDroppedItem.id;
      }

      return {
        ...data,
        accepts: typeOfReorders,
        type: data.id,
        lastDroppedItem,
      };
    });
  };

  const getRightData = () => {
    return reorders.map((data) => {
      const itemInUserAnswers =
        Array.isArray(userAnswers) &&
        userAnswers.find((item) => data.id === get(item, [0]));
      return {
        ...data,
        type: data.id,
        isDropped: shouldShowKey || !!itemInUserAnswers,
      };
    });
  };

  const generateApiData = (data) => {
    return data.map((item) => {
      const lastDroppedItemId = get(item, `lastDroppedItem.type`);
      return lastDroppedItemId ? [lastDroppedItemId] : [];
    });
  };

  const leftData = getLeftData();
  const rightData = getRightData();

  const handleDrop = useCallback(
    (index, item) => {
      const lastDroppedItem = get(leftData, `[${index}].lastDroppedItem`);
      if (lastDroppedItem || disabled) {
        return;
      }

      if (practice) {
        const { type = '' } = item;
        const isCorrect = isItemRoCorrect(question, index, type);
        setShowError((one) =>
          isCorrect
            ? -Math.abs(Math.abs(one) + 1)
            : Math.abs(Math.abs(one) + 1),
        );
        if (!isCorrect) {
          return;
        }
      }

      const newLeftData = lClone(leftData);
      newLeftData[index].lastDroppedItem = item;
      const apiData = generateApiData(newLeftData);
      setUserAnswers(apiData);
    },
    [leftData, disabled, practice, setUserAnswers, question],
  );

  const componentClassName = `${className || ''} ${cssClass}`;

  const resetItem = (type) => {
    const newLeftData = lClone(leftData);
    newLeftData.forEach((element, i) => {
      if (element.lastDroppedItem && element.lastDroppedItem.type === type) {
        newLeftData[i].lastDroppedItem = null;
      }
    });

    const apiData = generateApiData(newLeftData);
    setUserAnswers(apiData);
  };

  const handleFixReorderClick = (item, index) => {
    if (!item || disabled) {
      return;
    }

    const { type } = item;
    resetItem(type, index);
  };

  const handleBoxClick = (type, isDropped, index) => {
    if (!isDropped) {
      return;
    }
    resetItem(type, index);
  };

  return (
    <>
      <ErrorMessageQuestion question={question} error={showError} />

      <DragAndDrop>
        <div className={componentClassName}>
          <div className={`${cssClass}__fixed`}>
            {leftData &&
              leftData.map((item, index) => {
                const reorderIdWhenLastDroppedItem = get(
                  item,
                  'lastDroppedItem.type',
                );
                const reorderIdFromAnswers = get(answers, `[${index}][0]`);
                const isMatched =
                  answers &&
                  reorderIdWhenLastDroppedItem &&
                  reorderIdFromAnswers &&
                  reorderIdWhenLastDroppedItem === reorderIdFromAnswers;

                return (
                  <FixedItem
                    className={`${cssClass}__item`}
                    accept={item.accepts}
                    lastDroppedItem={item.lastDroppedItem}
                    onDrop={(item) => handleDrop(index, item)}
                    key={index}
                    stt={index + 1}
                    handleFixReorderClick={() =>
                      handleFixReorderClick(item.lastDroppedItem, index)
                    }
                    disabled={disabled}
                    matched={isMatched}
                  />
                );
              })}
          </div>
          <div className={`${cssClass}__moved`}>
            {rightData &&
              rightData.map((item, index) => (
                <MovableItem
                  className={`${cssClass}__item`}
                  content={item.content}
                  type={item.type}
                  avatar={item.avatar}
                  isDropped={item.isDropped}
                  key={index}
                  handleBoxClick={() =>
                    handleBoxClick(item.type, item.isDropped, index)
                  }
                  disabled={disabled}
                />
              ))}
          </div>
        </div>
      </DragAndDrop>

      {!!shouldShowKey && (
        <div>
          <h5>{t1('answer')}</h5>
          <table className={`${cssClass}__key-table`}>
            <tbody>
              {answer_as_hint &&
                Array(
                  Math.min(
                    answer_as_hint.length || 0,
                    answer_as_hint.length || 0,
                  ),
                )
                  .fill(0)
                  .map((row, index) => {
                    const displayTableCell = (item, oriented = 'LEFT') => {
                      if (!item) {
                        return null;
                      }

                      let content;
                      if (oriented === 'RIGHT') {
                        content = [
                          item.avatar && (
                            <div
                              className={`${cssClass}__key-tb-cell-content-avatar`}
                            >
                              <ImageBackGround height="100" src={item.avatar} />
                            </div>
                          ),
                          item.content && (
                            <div
                              className={`${cssClass}__key-tb-cell-content-main`}
                            >
                              <div
                                title={item.content}
                                className="content-text-ellipsis"
                              >
                                {item.content}
                              </div>
                            </div>
                          ),
                        ];
                      } else {
                        content = [
                          <div
                            className={`${cssClass}__key-tb-cell-stt d-flex justify-content-center`}
                          >
                            {index + 1}
                          </div>,
                        ];
                      }

                      return (
                        <div
                          className={`${cssClass}__key-tb-cell-content ${
                            oriented === 'LEFT' ? 'justify-content-center' : ''
                          }`}
                        >
                          {content}
                        </div>
                      );
                    };

                    return (
                      <tr>
                        <td style={{ width: isSmallScreen ? 30 : 50 }}>
                          {displayTableCell(answer_as_hint[index], 'LEFT')}
                        </td>
                        <td>
                          {displayTableCell(answer_as_hint[index], 'RIGHT')}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default Reorder;
