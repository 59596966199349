import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { getAttachment } from 'components/learn/items/lecture/common';
import { replaceStaticToStatic2 } from 'common/utils/url';

const Image = ({
  node,
  className,
  isReview,
  isPreview,
  learningProgress,
  saveLearningProgress,
}) => {
  const attachment = React.useMemo(() => getAttachment(node), [node]);

  React.useEffect(
    () => {
      if (
        !node ||
        isPreview ||
        isReview ||
        String(learningProgress) === '100'
      ) {
        return;
      }
      saveLearningProgress &&
        saveLearningProgress([
          {
            tco_iid: get(node, 'iid'),
            p: 100,
            cp: 100,
            time_spent: 0,
          },
        ]);
    },
    [node, isReview, isPreview, learningProgress, saveLearningProgress],
  );

  return (
    <div className={`${className || ''} lecture-image`}>
      {node && attachment && attachment.link && (
        <img src={replaceStaticToStatic2(attachment.link)} alt={node.name} />
      )}
    </div>
  );
};

Image.propTypes = {
  isPreview: PropTypes.bool,
  isReview: PropTypes.bool,
  className: PropTypes.string,
  node: PropTypes.object.isRequired,
  saveLearningProgress: PropTypes.func.isRequired,
};

const mapStateToProps = createSelector(
  (state, props) => get(state, ['trackerProgress', props.learnItemIid]),
  (trackerProgress) => ({
    learningProgress: (trackerProgress || {}).p || 0,
  }),
);

export default connect(mapStateToProps)(Image);
