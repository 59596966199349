import React from 'react';
import withTreeNodes from 'common/hoc/withTreeNodes';
import BlockItem from '../sco-spl/block';

class ItemInBlock extends React.Component {
  render() {
    return <BlockItem {...this.props} notInScoSpl />;
  }
}

export default withTreeNodes(ItemInBlock);
