import DatePicker from 'schema-form/elements/date-picker';
import { t1, t } from 'translate';
import Duration from 'schema-form/elements/duration/smaller-than-one-day';
import { durationDisplayFormats } from 'schema-form/elements/duration/smaller-than-one-day/common/constants';
import React from 'react';
import { courseTeachers } from 'components/admin/course/schema/courseTeachers';
import { joinStatuses } from 'components/admin/session/utils';
import lodashGet from 'lodash.get';
import store from 'store';
import { change } from 'redux-form';

const defaultStartDate = Math.round(new Date().getTime() / 1000);
const numberClassHourDefault = 1;
const durationOfClassHourDefault = 60;
const startTime = 540;
const resultDuration = (startTime, endTime) => endTime - startTime;
const onChangeScheduleTime = (formid, startTime, endTime) =>
  store.dispatch(
    change(
      formid,
      'scheduled.learn_duration',
      resultDuration(startTime, endTime),
    ),
  );

// nếu tìm các course bằng courseIidsOrCodes thì phải truyền cả tpIid
const schema = (
  mode,
  node,
  courseIids,
  courseIidsOrCodes,
  tpIid,
  sessionStatus,
) => (formid, values, step, xpath, props, domainInfo) => {
  const numberClassHour =
    lodashGet(
      domainInfo,
      'conf.create_session_default_values.number_class_hour_default',
    ) || numberClassHourDefault;

  const durationOfClassHour =
    lodashGet(
      domainInfo,
      'conf.create_session_default_values.duration_of_class_hour_default',
    ) || durationOfClassHourDefault;

  const learnDuration = numberClassHour * durationOfClassHour;

  const scheduled = lodashGet(values, 'scheduled');
  const studied = lodashGet(node, 'status') === 'studied';

  const shouldDisabledTimeField = [
    joinStatuses.JOIN_STATUS_OK,
    joinStatuses.JOIN_STATUS_TOO_LATE,
  ].includes(sessionStatus);

  return {
    date_time: {
      type: DatePicker,
      getStartDate: true,
      hintText: t1('date'),
      floatingLabelText: t1('date'),
      fullWidth: true,
      defaultValue: mode === 'new' ? defaultStartDate : null,
      disabled: studied || shouldDisabledTimeField,
    },
    start_time: {
      type: Duration,
      valueIsNumberOfMinutes: true,
      label: `${t1('start_time')} (${t('hh:mm')})`, //Giờ học: Từ (giờ: phút)
      formatTime: durationDisplayFormats.TIME_FORMAT, //default format,
      defaultValue: mode === 'new' ? startTime : '',
      fullWidth: true,
      disable: studied || shouldDisabledTimeField,
      onChange: (event, value) =>
        scheduled && onChangeScheduleTime(formid, value, scheduled.end_time),
    },
    end_time: {
      type: Duration,
      valueIsNumberOfMinutes: true,
      label: `${t1('end_time')} (${t('hh:mm')})`, //Giờ học: Từ (giờ: phút)
      formatTime: durationDisplayFormats.TIME_FORMAT, //default format
      fullWidth: true,
      defaultValue: mode === 'new' ? startTime + learnDuration : '',
      disable: shouldDisabledTimeField,
      onChange: (event, value) =>
        scheduled && onChangeScheduleTime(formid, scheduled.start_time, value),
    },
    teacher_iids: courseTeachers({
      label: t1('instructor_in_charge'),
      guide: t1('instructors_must_be_added_to_course_as_staff_first'),
      courseIids,
      courseIidsOrCodes,
      tpIid,
    }),
    learn_duration: {
      type: 'number',
      defaultValue: learnDuration,
      floatingLabelText: `${t1('session_duration')} (${t('minute')})`,
      fullWidth: true,
      readOnly: true,
    },
  };
};

const ui = () => {
  const fields = [
    'date_time',
    'start_time',
    'end_time',
    'teacher_iids',
    'learn_duration',
  ];

  return [
    {
      id: 'scheduled-session',
      title: t1('session_scheduling'),
      fields,
    },
  ];
};

const LayoutFreeStyle = (props) => {
  const fieldNames = lodashGet(props, 'groups.scheduled-session.fieldNames');
  if (!fieldNames) {
    return null;
  }

  return (
    <div className="layout-new-session">
      <div className="row layout-new-session__content">
        <div className="col-md-3 date-time">{fieldNames.date_time}</div>

        <div className="col-md-6">
          <div className="row d-flex">
            <div className="col-xs-6 md-full-width">
              {fieldNames.start_time}
            </div>
            <span className="ve-arrrow-right icon-arrow" />
            <div className="col-xs-6 md-full-width">{fieldNames.end_time}</div>
          </div>
        </div>
        <div className="col-md-3">{fieldNames.learn_duration}</div>
      </div>

      <div>{fieldNames.teacher_iids}</div>
    </div>
  );
};

const layout = (step, values, xpath, props) => {
  return {
    component: LayoutFreeStyle,
    freestyle: 1,
    optionsProperties: {
      props,
    },
  };
};

export default ({
  mode = 'new',
  node,
  courseIids,
  courseIidsOrCodes,
  tpIid,
  sessionStatus,
}) => ({
  schema: schema(
    mode,
    node,
    courseIids,
    courseIidsOrCodes,
    tpIid,
    sessionStatus,
  ),
  ui: () => ui(),
  layout,
});
