import React from 'react';
import Multiple from './multiple-questions';
import Two from './two-questions';
import { ddmTypes } from 'configs/constants';
import withShowWarningOfPracticeMode from '../withShowWarningOfPracticeMode';

import './stylesheet.scss';

const DDM = React.memo((props = {}) => {
  const {
    question,
    className,
    shouldShowKey,
    disabled,
    userAnswers,
    setUserAnswers,
    singleDropped = true,
    practice,
    questionShuffleKey,
  } = props;
  const { ddm_type } = question || {};

  switch (ddm_type) {
    case ddmTypes.MULTIPLE_QUESTION:
      return (
        <Multiple
          className={className}
          question={question}
          shouldShowKey={shouldShowKey}
          userAnswers={userAnswers}
          setUserAnswers={setUserAnswers}
          singleDropped={singleDropped}
          practice={practice}
          disabled={disabled}
          questionShuffleKey={questionShuffleKey}
        />
      );

    case ddmTypes.TWO_QUESTION:
      return (
        <Two
          className={className}
          question={question}
          shouldShowKey={shouldShowKey}
          userAnswers={userAnswers}
          setUserAnswers={setUserAnswers}
          singleDropped={singleDropped}
          practice={practice}
          disabled={disabled}
          questionShuffleKey={questionShuffleKey}
        />
      );

    default:
      return (
        <Multiple
          className={className}
          question={question}
          shouldShowKey={shouldShowKey}
          userAnswers={userAnswers}
          setUserAnswers={setUserAnswers}
          singleDropped={singleDropped}
          practice={practice}
          disabled={disabled}
          questionShuffleKey={questionShuffleKey}
        />
      );
  }
});

export default DDM;
