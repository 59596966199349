import React, { Component } from 'react';
import { t1 } from 'translate';
import ReportOfQuestionEndedMarkingByRubric from 'components/admin/report/node/ReportOfQuestionOpenEndedMarkingByRubric';
import Portal, { portals } from 'components/common/portal';

class PeerTakes extends Component {
  render() {
    const {
      courseIid,
      question,
      rubricIidsToShowMarking,
      peerMarking,
      showHeader = false,
      percentagePortalId = '',
    } = this.props;

    const node = {
      iid: courseIid,
      ntype: 'course',
    };

    // TODO: add other reports, such as aggregate of all users taking the syllabus
    return (
      <>
        {showHeader && (
          <Portal id={portals.questionHeader(question.id)}>
            {t1('open_ended_question_report_title')}
          </Portal>
        )}
        <ReportOfQuestionEndedMarkingByRubric
          peerMarking={peerMarking}
          question={question}
          nodeEditer={node}
          rubricIidsToShowMarking={rubricIidsToShowMarking}
          percentagePortalId={percentagePortalId}
        />
      </>
    );
  }
}

export default PeerTakes;
