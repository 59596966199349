import React, { useCallback } from 'react';
import isEqual from 'lodash.isequal';

import FixedItem from '../FixedItem';
import MovableItem from '../MovableItem';
import get from 'lodash.get';
import DragAndDrop from '../../DnDProvider';
import CorrectAnswer from '../correct-answer';
import useCoreLogic from '../core-logic';
import ErrorMessageQuestion from '../../ErrorMessageQuestion';

const DDM = React.memo((props) => {
  const { className, question, shouldShowKey, disabled } = props;

  const cssClass = 'ddm-question-type-box';
  const componentClassName = `${className || ''} ${cssClass}`;
  const [show, setShowError] = React.useState(0);
  const [
    questionsData,
    answersData,
    handleDrop,
    handleAnsweredItemClick,
    checkAnswerIsCorrect,
  ] = useCoreLogic({ ...props, setShowError });

  const [firstQuestion, secondQuestion] = questionsData;

  const renderQuestionItem = useCallback(
    (questionItem, questionIndex) => {
      if (!questionItem) {
        return null;
      }
      const answers = get(questionItem, 'answers', []);
      const isAnswerCorrect = checkAnswerIsCorrect(answers, questionIndex);
      return (
        <FixedItem
          className={`${cssClass}__item ${cssClass}__item--full-height`}
          accept={get(questionItem, 'accepts', [])}
          answers={answers}
          onDrop={(item) => handleDrop(questionIndex, item)}
          key={questionIndex}
          handleItemChildrenClick={handleAnsweredItemClick}
          disabled={disabled}
          isAnswerCorrect={isAnswerCorrect}
          index={questionIndex}
          content={get(questionItem, 'content')}
          showContentAsHeader
        />
      );
    },
    [checkAnswerIsCorrect, handleAnsweredItemClick, disabled, handleDrop],
  );

  return (
    <>
      <ErrorMessageQuestion question={question} error={show} />

      <DragAndDrop>
        <div className={`${componentClassName} row`}>
          <div className={`${cssClass}__fixed col-md-4 col-xs-4`}>
            {renderQuestionItem(firstQuestion, 0)}
          </div>

          <div
            className={`${cssClass}__moved ${cssClass}__moved--two-question col-md-4 col-xs-4`}
          >
            {answersData
              .map(
                (item, index) =>
                  !item.answered && (
                    <MovableItem
                      className={`${cssClass}__item`}
                      content={item.content}
                      type={item.type}
                      key={index}
                      answered={item.answered}
                    />
                  ),
              )
              .filter(Boolean)}
          </div>

          <div
            className={`${cssClass}__fixed ${cssClass}__fixed--full-height col-md-4 col-xs-4`}
          >
            {renderQuestionItem(secondQuestion, 1)}
          </div>
        </div>
      </DragAndDrop>

      {shouldShowKey && <CorrectAnswer question={question} />}
    </>
  );
});

class DDMQuestionCheckPropsToUpdate extends React.Component {
  shouldComponentUpdate(prevProps, nextState, nextContext) {
    const {
      className,
      question,
      shouldShowKey,
      disabled,
      userAnswers,
      setUserAnswers,
      singleDropped = true,
      practice,
      showWarning,
      showSuccess,
      questionShuffleKey,
    } = this.props;

    const isPropsNoChange =
      isEqual(className, prevProps.className) &&
      isEqual(question, prevProps.question) &&
      isEqual(shouldShowKey, prevProps.shouldShowKey) &&
      isEqual(disabled, prevProps.disabled) &&
      isEqual(userAnswers, prevProps.userAnswers) &&
      isEqual(setUserAnswers, prevProps.setUserAnswers) &&
      isEqual(singleDropped, prevProps.singleDropped) &&
      isEqual(practice, prevProps.practice) &&
      isEqual(showWarning, prevProps.showWarning) &&
      isEqual(showSuccess, prevProps.showSuccess) &&
      isEqual(questionShuffleKey, prevProps.questionShuffleKey);

    return !isPropsNoChange;
  }

  render() {
    const {
      className,
      question,
      shouldShowKey,
      userAnswers,
      setUserAnswers,
      singleDropped = true,
      practice,
      showWarning,
      showSuccess,
      disabled,
      questionShuffleKey,
    } = this.props;

    return (
      <DDM
        className={className}
        question={question}
        shouldShowKey={shouldShowKey}
        userAnswers={userAnswers}
        setUserAnswers={setUserAnswers}
        singleDropped={singleDropped}
        practice={practice}
        showWarning={showWarning}
        showSuccess={showSuccess}
        disabled={disabled}
        questionShuffleKey={questionShuffleKey}
      />
    );
  }
}

export default DDMQuestionCheckPropsToUpdate;
