import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommonAntdTable from 'components/common/antd/table';
import { t, t1, t3 } from 'translate/index';
import { hoursStringify } from 'utils/Util';
import Positions from './Positions';
import { sexAsText } from 'common/sex';
import { layouts } from 'configs/constants';
import { createSelector } from 'reselect';
import { getThemeConfig } from 'utils/selectors';
import ChangeStaffStatus from 'components/admin/user/user-in-school/common/UpdateStaffStatus';
import lodashGet from 'lodash.get';
import PreviewUserInDialog from 'components/admin/user/account/common/PreviewInDialog';
import './stylesheet.scss';
import Avatar from 'antd/lib/avatar';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import {
  timestampToDateString,
  timestampToDateTimeString,
} from 'common/utils/Date';
import Perm from 'common/utils/Perm';

class ResultsEnterprise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: null,
    };
  }

  getExperience = (startUnixTimestamp) => {
    if (!startUnixTimestamp) {
      return t3('n/a');
    }

    const durationInSeconds =
      Math.floor(Date.now() / 1000) - startUnixTimestamp;
    const durationInHours = Math.floor(durationInSeconds / (60 * 60));

    return (
      hoursStringify(['year', 'month'])(durationInHours) || `< 1 ${t('month')}`
    );
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const {
      items,
      renderBeforeResultTable,
      themeConfig,
      action,
      required_license,
      notShowCheckbox,
      searchValues,
      multiSelectable,
      rowKeySelection,
      renderBottomActions,
      columnKeysToDisplay,
    } = this.props;

    const itemList = this.props.itemList || items;
    const checkKey = this.props.checkKey || 'id';
    const keysSave = this.props.keysSave || ['id', 'iid', 'name'];
    const resultTableFieldName = this.props.resultTableFieldName || 'targets';

    const isLayoutVT = themeConfig && themeConfig.layout === layouts.VT;

    const mode = action === 'accounts' ? 'user' : 'student';

    const selectedRowKeys = this.state.selectedRowKeys;

    const label = {
      iidCode: t1('code'),
      name: t1('name'),
      mail: t1('mail'),
      birthday: t1('birth_year'),
      sex: t1('sex'),
      positions: t1('positions'),
      phone: t1('phone'),
      status: t1('status'),
      role: t1('role'),
      actions: t1('actions'),
      editStaff: t1('edit_staff'),
    };

    const columns = [
      {
        title: label.iidCode,
        key: 'code',
        type: TYPE_OF_TABLE_DATA.TEXT,
        dataIndex: 'code',
        render: (code, item) => (
          <PreviewUserInDialog user={item} field="code" />
        ),
      },
      {
        title: label.name,
        key: 'name',
        type: TYPE_OF_TABLE_DATA.TEXT,
        dataIndex: 'name',
        render: (name, item) => (
          <React.Fragment>
            {item.avatar && <Avatar src={item.avatar} />}&nbsp;
            <PreviewUserInDialog user={item} field="name" />
          </React.Fragment>
        ),
      },
      {
        title: label.mail,
        key: 'mail',
        type: TYPE_OF_TABLE_DATA.TEXT,
        dataIndex: 'mail',
        render: (mail, item) => (
          <PreviewUserInDialog
            user={item}
            field="mail"
            fallbackToUsername={false}
          />
        ),
      },
      isLayoutVT && {
        title: label.birthday,
        key: 'birthday',
        dataIndex: 'birthday',
        type: TYPE_OF_TABLE_DATA.DATE,
        render: (birthday, item) =>
          item && item.birthday && new Date(item.birthday * 1000).getFullYear(),
      },
      isLayoutVT && {
        title: label.sex,
        key: 'sex',
        type: TYPE_OF_TABLE_DATA.TEXT,
        dataIndex: 'sex',
        render: (sex, item) => item && sexAsText(item.sex),
      },
      {
        title: 'Vị trí/chức vụ',
        key: 'positions',
        type: TYPE_OF_TABLE_DATA.TEXT,
        dataIndex: 'positions',
        render: (phone, item) =>
          item.positions && item.positions.length ? (
            <Positions item={item} textOnly={true} />
          ) : (
            '-'
          ),
      },
      {
        title: 'Đơn vị',
        key: 'organizations',
        type: TYPE_OF_TABLE_DATA.TEXT,
        dataIndex: 'organizations',
        render: (organizations, item) =>
          item.user_organizations && item.user_organizations.length
            ? lodashGet(item, '__expand.user_organizations').map((uOrg) =>
                uOrg ? (
                  <>
                    <div>{uOrg.name}</div>
                    <div className="text-faded">{uOrg.code}</div>
                  </>
                ) : (
                  ''
                ),
              )
            : '-',
      },
      isLayoutVT
        ? {
            title: label.phone,
            key: 'phone',
            type: TYPE_OF_TABLE_DATA.TEXT,
            dataIndex: 'phone',
            render: (phone, item) => item && item.phone,
          }
        : {
            title: 'Phòng ban',
            key: 'phongbans',
            dataIndex: 'phongbans',
            type: TYPE_OF_TABLE_DATA.TEXT,
            render: (phongbans, item) =>
              item.phongbans && item.phongbans.length
                ? lodashGet(item, '__expand.phongbans').map((phongban) =>
                    phongban ? `- ${phongban.name}` : '',
                  )
                : '-',
          },
      !isLayoutVT &&
        false && {
          title: 'Kinh nghiệm',
          key: 'experience',
          type: TYPE_OF_TABLE_DATA.TEXT,
          dataIndex: 'experience',
          render: (experience, item) => this.getExperience(item.startdate),
        },
      mode == 'user' && {
        title: 'Là người hướng dẫn',
        key: 'mode',
        dataIndex: 'mode',
        render: (mode, item) => <ChangeStaffStatus node={item} noLabel />,
      },
      (!!required_license || Perm.hasPerm('root')) && {
        id: 'active_license',
        title: t1('active_license'),
        children: [
          {
            title: t1('license_effective_date'),
            render: (text, item) => {
              const licenseKeys = lodashGet(searchValues, 'license_keys');
              let license = lodashGet(item, 'licenses.0');
              if (licenseKeys) {
                license = lodashGet(item, 'licenses', []).find(({ key }) =>
                  licenseKeys.includes(key),
                );
              }
              const key = lodashGet(license, 'key');
              const startDate = lodashGet(license, 'start_date');
              const endDate = lodashGet(license, 'end_date');

              if (!key) {
                return null;
              }

              return `${timestampToDateString(startDate, {
                splitter: '/',
              })} -> ${timestampToDateString(endDate, { splitter: '/' })}`;
            },
          },
          {
            title: t1('date_of_active_account'),
            render: (text, item) => {
              const ts = lodashGet(item, 'date_of_active_account_by_license');

              if (!ts) {
                return null;
              }

              return timestampToDateTimeString(ts, {
                type: 'long_date',
                unixEpoch: true,
                splitter: '/',
              });
            },
          },
        ],
      },
      {
        title: 'Thao tác',
        key: 'action',
        dataIndex: 'action',
        type: TYPE_OF_TABLE_DATA.ACTION,
        render: (action, item) =>
          this.props.renderActionCell
            ? this.props.renderActionCell(item, { searchValues })
            : null,
      },
    ].filter(({ key }) => {
      if (!key) {
        return false;
      }

      if (!Array.isArray(columnKeysToDisplay) || !columnKeysToDisplay.length) {
        return true;
      }

      return columnKeysToDisplay.includes(key);
    });

    return (
      <div>
        <CommonAntdTable
          scroll={{
            x: true,
          }}
          dataSource={itemList}
          columns={columns}
          pagination={false}
          childrenColumnName={null}
          rowKey={rowKeySelection || 'id'}
          className={`learn-default`}
          key="result-table"
          name={resultTableFieldName}
          checkKey={checkKey}
          keysSave={keysSave}
          multiSelectable
          rowSelection={
            multiSelectable
              ? {
                  selectedRowKeys,
                  hideDefaultSelections: true,
                  onChange: this.onSelectChange,
                }
              : null
          }
        />
        {typeof renderBottomActions === 'function' &&
          renderBottomActions(
            searchValues,
            selectedRowKeys,
            this.onSelectChange,
          )}
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  getThemeConfig,
  (state, props) =>
    lodashGet(state, 'domainInfo.conf.required_license') &&
    !props.hideLicenseInfo,
  (themeConfig, required_license) => ({
    themeConfig,
    required_license,
  }),
);

export default connect(mapStateToProps)(ResultsEnterprise);
