import React from 'react';
import Alert from 'antd/lib/alert';
import PropTypes from 'prop-types';
import { ntype } from 'configs/constants';
import QuestionWrapper from './QuestionWrapper';
import { findDOMNode } from 'react-dom';
import { max } from 'common/utils/Array';
import { t1 } from 'translate';
import './NormalExerciseDisplay.scss';
import lodashGet from 'lodash.get';
import { connect } from 'react-redux';

class NormalExerciseDisplay extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {
      currentQuestionUniqueId,
      shouldDisplayCurrentQuestionAtTop,
    } = this.props;
    if (currentQuestionUniqueId && shouldDisplayCurrentQuestionAtTop) {
      this.scrollQuestionToTop(currentQuestionUniqueId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      currentQuestionUniqueId,
      shouldDisplayCurrentQuestionAtTop,
    } = this.props;
    if (
      currentQuestionUniqueId !== prevProps.currentQuestionUniqueId &&
      shouldDisplayCurrentQuestionAtTop
    ) {
      this.scrollQuestionToTop(currentQuestionUniqueId);
    }
  }

  scrollQuestionToTop = (questionUniqueId) => {
    const questionNode = this.getQuestionNode(questionUniqueId);
    if (!questionNode) return;

    const scrollBarNode = this.getScrollBarNode();
    if (!scrollBarNode) return;

    const scrollBarContentNode = this.getScrollBarContentNode();

    const scrollBarNodeTop = scrollBarNode.getBoundingClientRect().top;
    if (scrollBarNodeTop < 0) {
      questionNode.scrollIntoView();
    } else {
      const questionNodeTop = questionNode.getBoundingClientRect().top;
      const scrollBarContentNodeTop = scrollBarContentNode.getBoundingClientRect()
        .top;
      scrollBarNode.scrollTop = questionNodeTop - scrollBarContentNodeTop;
    }
  };

  getQuestionNodeRef = (questionUniqueId) => `question-${questionUniqueId}`;

  getQuestionNode = (questionUniqueId) =>
    findDOMNode(this[this.getQuestionNodeRef(questionUniqueId)]);

  getScrollBarNode = () => {
    const tmp = findDOMNode(this.scrollBar);
    if (!tmp) return null;
    return tmp.getElementsByTagName('div')[0];
  };

  getScrollBarContentNode = () => findDOMNode(this.scrollBarContent);

  getVisibilityOfQuestion = (questionUniqueId) => {
    const scrollBarNode = this.getScrollBarNode();
    if (!scrollBarNode) return 0;

    const questionNode = this.getQuestionNode(questionUniqueId);
    if (!questionNode) return 0;

    const questionNodeHeight = questionNode.getBoundingClientRect().height;
    const scrollBarNodeHeight = scrollBarNode.getBoundingClientRect().height;
    const bottom = Math.max(
      questionNode.getBoundingClientRect().bottom,
      scrollBarNode.getBoundingClientRect().bottom,
    );
    const top = Math.min(
      questionNode.getBoundingClientRect().top,
      scrollBarNode.getBoundingClientRect().top,
    );

    return questionNodeHeight + scrollBarNodeHeight - (bottom - top);
  };

  getMostVisibleQuestion = () => {
    const defaultResult = null;

    const scrollBarNode = this.getScrollBarNode();
    if (!scrollBarNode) return defaultResult;

    const { questions } = this.props;
    if (!Array.isArray(questions) || questions.length === 0)
      return defaultResult;

    const visibilityOfQuestions = questions.map((question) =>
      this.getVisibilityOfQuestion(question.uniqueId),
    );

    const maxVisibility = max(visibilityOfQuestions);

    return questions[maxVisibility.index];
  };

  isMostVisibleQuestion = (questionUniqueId) => {
    const mostVisibleQuestion = this.getMostVisibleQuestion();
    return (
      mostVisibleQuestion && mostVisibleQuestion.uniqueId === questionUniqueId
    );
  };

  render() {
    const {
      className,
      courseIid,
      handleUserAnswer,
      isQuestionContentAudioControllable,
      onQuestionBookMarkAreaClick,
      onQuestionContentAudioEnded,
      onQuestionContentAudioPlay,
      onQuestionDone,
      onQuestionMouseEnter,
      options,
      questionMode,
      renderAfter,
      renderBefore,
      shouldShowQuestionHelpText,
      shouldShowQuestionNumber,
      shouldShowQuestionHeader,
      shouldShowQuestionLearningSuggestionWhenShowAnswer,
      style,
      renderNoQuestion,
      displayTemplate,
      isShowSaveQuestionOnly,
      questionGroupTimeLeftInSeconds,
      isSurvey,
      exerciseStep,
      onFinishHandleChangingQuestion,
      course,
      practice,
      noMarking,
      inlineExercise,
      saveTakeSuccess,
      learnItemIid,
      questionShuffleKey,
    } = this.props;
    let { questions } = this.props;

    const classContainer = `${className || ''} normal-exercise-display
       ${questionMode !== 'exam' ? ' learn-content-border' : ''} `;

    if (isShowSaveQuestionOnly) {
      questions = questions.filter((question) => question.isTicked);
    }

    const rendererItem = (
      <ul
        className="normal-exercise-display__items"
        ref={(el) => {
          this.scrollBarContent = el;
        }}
      >
        {typeof renderBefore === 'function' && renderBefore()}
        {Array.isArray(questions) && questions.length > 0 ? (
          questions.map(
            (question) =>
              (question.ntype === ntype.END_INTRO_STICKY && <div />) ||
              (question.ntype === ntype.QUESTION && (
                <QuestionWrapper
                  key={question.uniqueId}
                  question={question}
                  onQuestionMouseEnter={onQuestionMouseEnter}
                  getQuestionRef={(el) => {
                    this[this.getQuestionNodeRef(question.uniqueId)] = el;
                  }}
                  courseIid={courseIid}
                  handleUserAnswer={handleUserAnswer}
                  onQuestionDone={onQuestionDone}
                  isQuestionContentAudioControllable={
                    isQuestionContentAudioControllable
                  }
                  onQuestionContentAudioPlay={onQuestionContentAudioPlay}
                  onQuestionContentAudioEnded={onQuestionContentAudioEnded}
                  onQuestionBookMarkAreaClick={onQuestionBookMarkAreaClick}
                  shouldShowQuestionHelpText={shouldShowQuestionHelpText}
                  shouldShowQuestionNumber={shouldShowQuestionNumber}
                  shouldShowQuestionHeader={shouldShowQuestionHeader}
                  options={options}
                  questionMode={questionMode}
                  displayTemplate={displayTemplate}
                  shouldShowQuestionLearningSuggestionWhenShowAnswer={
                    shouldShowQuestionLearningSuggestionWhenShowAnswer
                  }
                  isSurvey={isSurvey}
                  exerciseStep={exerciseStep}
                  onFinishHandleChangingQuestion={
                    onFinishHandleChangingQuestion
                  }
                  course={course}
                  practice={practice}
                  noMarking={noMarking}
                  inlineExercise={inlineExercise}
                  saveTakeSuccess={saveTakeSuccess}
                  learnItemIid={learnItemIid}
                  questionShuffleKey={questionShuffleKey}
                />
              )),
          )
        ) : renderNoQuestion ? (
          renderNoQuestion()
        ) : (
          <div className="m-20">
            <Alert
              showIcon
              type="error"
              message={t1(
                isShowSaveQuestionOnly
                  ? 'select_question_to_view'
                  : 'this_section_has_no_question',
              )}
            />
          </div>
        )}
        {typeof renderAfter === 'function' && renderAfter()}
      </ul>
    );

    return (
      <div className={classContainer} style={style}>
        <div
          ref={(el) => {
            this.scrollBar = el;
          }}
        >
          {rendererItem}
        </div>
      </div>
    );
  }
}

NormalExerciseDisplay.propTypes = {
  className: PropTypes.string,
  courseIid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentQuestionUniqueId: PropTypes.string,
  handleUserAnswer: PropTypes.func,
  isQuestionContentAudioControllable: PropTypes.func,
  onQuestionBookMarkAreaClick: PropTypes.func,
  onQuestionContentAudioEnded: PropTypes.func,
  onQuestionContentAudioPlay: PropTypes.func,
  onQuestionDone: PropTypes.func,
  onQuestionMouseEnter: PropTypes.func,
  options: PropTypes.shape(),
  questionMode: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.shape()),
  renderAfter: PropTypes.func,
  renderBefore: PropTypes.func,
  shouldDisplayCurrentQuestionAtTop: PropTypes.bool,
  shouldShowQuestionHelpText: PropTypes.bool,
  shouldShowQuestionNumber: PropTypes.bool,
  shouldShowQuestionHeader: PropTypes.bool,
  shouldShowQuestionLearningSuggestionWhenShowAnswer: PropTypes.bool,
  style: PropTypes.shape(),
};

NormalExerciseDisplay.defaultProps = {
  className: '',
  courseIid: null,
  questionShuffleKey: null,
  currentQuestionUniqueId: null,
  handleUserAnswer: null,
  isQuestionContentAudioControllable: () => true,
  onQuestionBookMarkAreaClick: null,
  onQuestionContentAudioEnded: null,
  onQuestionContentAudioPlay: null,
  onQuestionDone: null,
  onQuestionMouseEnter: null,
  options: {},
  questionMode: 'normal',
  questions: [],
  renderAfter: null,
  renderBefore: null,
  shouldDisplayCurrentQuestionAtTop: true,
  shouldShowQuestionHelpText: true,
  shouldShowQuestionNumber: true,
  shouldShowQuestionHeader: true,
  shouldShowQuestionLearningSuggestionWhenShowAnswer: false,
  style: null,
};

const mapStateToProps = (state) => {
  const isShowSaveQuestionOnly = lodashGet(
    state,
    'learn.isShowSaveQuestionOnly',
    false,
  );

  return {
    isShowSaveQuestionOnly,
  };
};

export default connect(mapStateToProps)(NormalExerciseDisplay);
