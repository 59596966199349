import React from 'react';
import ScoImageMap from '../image-map';
import ScoLayoutList from './ScoLayoutList';
import lodashGet from 'lodash.get';

const ScoLayoutImageMap = ({
  navId,
  learnItem,
  courseIid,
  course,
  syllabus,
  isPreview,
  navRootNodeIid,
  learnMode,
  nodes,
}) => {
  const regions = lodashGet(learnItem, 'image_map_regions.regions');
  const shouldNotShowListUnderInfographic = lodashGet(
    learnItem,
    'should_not_show_list_under_infographic',
    false,
  );
  const continueToLearnMessage = shouldNotShowListUnderInfographic
    ? 'Để học tiếp, bạn hãy click vào các vùng trong ảnh.'
    : 'Để học tiếp, bạn hãy click vào các vùng trong ảnh hoặc click vào danh sách dưới đây.';

  if (!regions) return <div>No regions</div>;

  const listItems = regions.map((region) => {
    const linkedIid = lodashGet(region, 'actions.onClick');
    return nodes[linkedIid];
  });

  return (
    <div className={'p-20'}>
      <h3>{lodashGet(learnItem, 'name')}</h3>

      <ScoImageMap
        key={`${navId}-${learnItem.iid}-sco`}
        id={`${navId}-${learnItem.iid}-sco`}
        courseIid={courseIid}
        scoIid={learnItem.iid}
        node={learnItem}
        course={course}
        syllabus={syllabus}
        isPreview={isPreview}
        navRootNodeIid={navRootNodeIid}
        learnMode={learnMode}
        nodes={nodes}
      />
      <div className="text-primary m-b-20 m-t-20">{continueToLearnMessage}</div>

      {!shouldNotShowListUnderInfographic && (
        <ScoLayoutList
          nodes={nodes}
          course={course}
          learnItem={learnItem}
          listItems={listItems}
          syllabus={syllabus}
          imageMap
          navRootNodeIid={navRootNodeIid}
        />
      )}
    </div>
  );
};

export default ScoLayoutImageMap;
