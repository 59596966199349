import React from 'react';
import PropTypes from 'prop-types';
import Word from './word';
import Complex from './complex';
import Box from './box';
import { reorderTypes } from 'configs/constants';
import { isSmallScreen } from 'common';
import './stylesheets.scss';

class Reorder extends React.Component {
  render() {
    const { question } = this.props;
    const { reorder_type } = question || {};

    if (reorder_type === reorderTypes.WORD) {
      return <Word {...this.props} />;
    }

    if (reorder_type === reorderTypes.COMPLEX) {
      return <Complex {...this.props} />;
    }

    if (reorder_type === reorderTypes.BOX) {
      return isSmallScreen ? (
        <Complex {...this.props} />
      ) : (
        <Box {...this.props} />
      );
    }

    return null;
  }
}

Reorder.propTypes = {
  question: PropTypes.shape(),
};

Reorder.defaultProps = {
  question: {},
};

export default Reorder;
