import React from 'react';
import lodashGet from 'lodash.get';
import { t1 } from 'translate';
import { parseTSToDateTimeString } from 'common/utils/Date';
import CommonAntdTable from 'components/common/antd/table';
import { userGradeToShortText } from 'configs/constants/user';
import { isBo, isLTTUStaff } from 'components/admin/user/utils';
import withUserInfo from 'common/hoc/withUserInfo';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import get from 'lodash.get';
import DetailOnDialog from 'components/common/detail-on-dialog';
import Icon from 'components/common/Icon';
import DeleteItem from 'components/common/action-button/DeleteBtnWithConfirmDialog';
import NewDeal from '../NewDeal';
import endpoints from '../endpoints';

const Deals = ({ items, userInfo, searchFormId }) => {
  const columns = [
    {
      title: 'Tên/Mã hợp đồng',
      key: 'name',
      render: (item) => {
        return (
          <div>
            {item.name} <br />
            <span className="text-muted">{item.code}</span>{' '}
          </div>
        );
      },
    },
    {
      title: 'Học liệu ',
      key: 'material',
      render: (item) => {
        const materials = lodashGet(item, '__expand.materials');

        return (
          <div>
            {materials && Array.isArray(materials)
              ? materials.map((material, index) => {
                  const caps = lodashGet(material, 'caps');
                  return (
                    <div
                      key={`${material.iid}-material`}
                      className={`${
                        index + 1 < materials.length ? 'border-bottom' : ''
                      } p-t-5 p-b-5`}
                    >
                      <strong>{material.name}</strong> - (Cấp:&nbsp;
                      {caps
                        ? caps.map((v) => userGradeToShortText(v)).join(',')
                        : ''}
                      )
                    </div>
                  );
                })
              : null}
          </div>
        );
      },
    },
    ...(isBo(userInfo) || isLTTUStaff(userInfo)
      ? [
          {
            title: 'Đơn vị mua học liệu',
            key: 'organizations',
            render: (item) => {
              const org = lodashGet(item, '__expand.buyer_organization');
              return <div key={`${org.iid}-organizations`}>{org.name}</div>;
            },
          },
        ]
      : []),
    ...(!isLTTUStaff(userInfo)
      ? [
          {
            title: 'Đơn vị làm học liệu',
            key: 'seller',
            render: (item) => {
              return (
                <div>
                  {lodashGet(item, '__expand.seller_organization.name')}
                </div>
              );
            },
          },
        ]
      : []),
    {
      title: 'Thời gian bắt đầu',
      key: 'start_time',
      render: (item) => parseTSToDateTimeString(item.start_date),
    },
    {
      title: 'Thời gian kết thúc',
      key: 'end_time',
      render: (item) => parseTSToDateTimeString(item.end_date),
    },
    ...(isBo(userInfo) || isLTTUStaff(userInfo)
      ? [
          {
            title: 'Thao tác',
            type: TYPE_OF_TABLE_DATA.TITLE,
            render: (item) => {
              const name = get(item, 'name');

              return (
                <div className="d-flex align-items-center justify-content-center">
                  <DetailOnDialog
                    renderPreview={({ showFull }) => (
                      <span
                        title={`Cập nhập cho hợp đồng ${name}`}
                        onClick={showFull}
                        className="d-flex m-r-10 p-0 cursor-pointer"
                      >
                        <Icon icon="edit" />
                      </span>
                    )}
                    renderFull={() => (
                      <NewDeal
                        mode="edit"
                        node={item}
                        searchFormId={searchFormId}
                        formid="edit-deal"
                        alternativeApi={endpoints.update_deal}
                      />
                    )}
                    dialogOptionsProperties={{
                      modal: true,
                      handleClose: true,
                      title: `Cập nhập cho hợp đồng ${name}`,
                    }}
                  />
                  <DeleteItem
                    title={t1(`Xóa hợp đồng ${name}`)}
                    textConfirm={`Bạn có chắc chắn muốn xóa bỏ hợp đồng ${name}?`}
                    formid={searchFormId}
                    ntype={'deal'}
                    itemId={item.id}
                    iconButton
                    alternativeApi={endpoints.delete_deal}
                    className="m-l-20"
                  />
                </div>
              );
            },
          },
        ]
      : []),
  ];

  return (
    <CommonAntdTable
      dataSource={items}
      columns={columns}
      rowKey="id"
      childrenColumnName={null}
      pagination={false}
      className={'learn-default'}
    />
  );
};

export default withUserInfo(Deals);
