import React from 'react';
import ImageMapper from 'react-image-mapper';
import lodashGet from 'lodash.get';
import lodashEqual from 'lodash.isequal';
import { createLinkForItem } from 'components/learn/nav/nav-generator';
import { withRouter } from 'react-router';
import MouseTooltip from 'react-sticky-mouse-tooltip';
import { isBigScreen, isSmallScreen } from 'common';
import './stylesheet.scss';

const convertRegionShape = (shape) => {
  return 'poly';
};

const imageWidth = isSmallScreen ? 300 : isBigScreen ? 800 : 600;

const convertRegionPointsToCoords = (region, imageWidth, pixelSize = {}) => {
  const originalWidth = lodashGet(pixelSize, 'w');
  const originalHeight = lodashGet(pixelSize, 'h');
  const imageHeight = (imageWidth * originalHeight) / originalWidth;

  const type = lodashGet(region, 'region.type');

  // list of [xRelative, yRelative]
  let points = [];
  if (type == 'polygon') {
    points = lodashGet(region, 'region.points');
  } else if (type == 'box') {
    const { x, y, w, h } = lodashGet(region, 'region');
    points.push([x, y]);
    points.push([x, y + h]);
    points.push([x + w, y + h]);
    points.push([x + w, y]);
  }

  let coords = [];
  if (Array.isArray(points)) {
    points.map((xy) => {
      const xOffset = imageWidth * xy[0];
      const yOffset = imageHeight * xy[1];
      coords.push(parseInt(xOffset));
      coords.push(parseInt(yOffset));
    });
  }

  return coords;
};

class InteractiveImageMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoveredArea: null,
      isMouseTooltipVisible: false,
      tooltip: 'Click để xem',
      linkedItem: null,
    };
  }

  setMouseTooltip = (isMouseTooltipVisible) => {
    this.setState({ isMouseTooltipVisible });
  };

  getActiveRegion(area) {
    const { node, isEditingMap } = this.props;

    if (isEditingMap) return null;

    const coords = area.coords;
    const regions = lodashGet(node, 'image_map_regions.regions');

    return regions.find((region) => {
      const pixelSize = lodashGet(node, 'image_map_regions.pixelSize');

      const regionCoords = convertRegionPointsToCoords(
        region,
        imageWidth,
        pixelSize,
      );

      return lodashEqual(regionCoords, coords);
    });
  }

  onMouseEnter(area) {
    const { nodes } = this.props;
    const activeRegion = this.getActiveRegion(area);
    const itemIid = lodashGet(activeRegion, 'actions.onClick');

    const linkedItem = itemIid ? nodes[itemIid] : null;

    if (activeRegion) {
      this.setMouseTooltip(true);
      this.setState({ linkedItem });
    }
  }

  onMouseLeave(area) {
    this.setMouseTooltip(false);
    this.setState({ linkedItem: null });
  }

  onClickArea(area) {
    const {
      node,
      course,
      nodes,
      navRootNodeIid,
      learnMode,
      isPreview,
      history,
    } = this.props;

    const activeRegion = this.getActiveRegion(area);

    if (!activeRegion) return;

    const itemIid = lodashGet(activeRegion, 'actions.onClick');
    if (itemIid && activeRegion) {
      const item = nodes[itemIid];
      const url = createLinkForItem(course, nodes, item, {
        navRootNodeIid,
        pathIid: null, // pathIid,
        learnMode,
        isPreview,
      });
      if (url) history.push(url);
    }
  }

  render() {
    const { node, isEditingMap } = this.props;
    if (!node) return <div>load</div>;

    const regions = lodashGet(node, 'image_map_regions.regions');
    if (!regions) return <div>No regions</div>;

    const pixelSize = lodashGet(node, 'image_map_regions.pixelSize');

    const MAP = {
      name: 'my-map',
      areas: regions
        .map((region) => {
          const coords = convertRegionPointsToCoords(
            region,
            imageWidth,
            pixelSize,
          );
          if (!coords) return null;

          const area = {
            name: region.name || '',
            shape: convertRegionShape(lodashGet(region, 'region.type')),
            coords,
            // fillColor: 'green',
            // strokeColor: 'red',
          };
          if (isEditingMap) area.preFillColor = 'red';
          else area.strokeColor = 'green';

          return area;
        })
        .filter(Boolean),
    };

    return (
      <div className="text-center image-map-content">
        <MouseTooltip
          visible={this.state.isMouseTooltipVisible}
          offsetX={15}
          offsetY={10}
          style={{ zIndex: 10000 }}
        />

        <ImageMapper
          src={node.avatar}
          map={MAP}
          width={imageWidth}
          onClick={(area) => this.onClickArea(area)}
          onMouseEnter={(area) => this.onMouseEnter(area)}
          onMouseLeave={(area) => this.onMouseLeave(area)}
        />
        {this.state.hoveredArea && (
          <span
            className="tooltip"
            style={{ ...this.getTipPosition(this.state.hoveredArea) }}
          >
            {this.state.hoveredArea && this.state.hoveredArea.name}
          </span>
        )}
      </div>
    );
  }
}

export default withRouter(InteractiveImageMap);
