import React from 'react';
import { t1 } from 'translate';
import { required } from 'common/validators';
import lodashGet from 'lodash.get';
import { convertBooleanValueToInt } from 'common/normalizers';
import scheduledSchema from 'components/front-end/meet/new/scheduled-schema';
import LayoutFreeStyle from 'components/front-end/meet/new/schema/layout_free_style';
import { get } from 'common/utils/object';
import moment from 'moment';
import groupElement from 'components/admin/session/schema/elements/group-element';
import Attachments from 'schema-form/elements/attachments';

const numberClassHourDefault = 1;
const durationOfClassHourDefault = 60;

const schema = (formid, values, step, xpath, props, domainInfo) => {
  const { node, mode, sessionStatus, groups, type } = props;
  const numberClassHour =
    lodashGet(
      domainInfo,
      'conf.create_session_default_values.number_class_hour_default',
    ) || numberClassHourDefault;
  const durationOfClassHour =
    lodashGet(
      domainInfo,
      'conf.create_session_default_values.duration_of_class_hour_default',
    ) || durationOfClassHourDefault;

  return {
    name: {
      type: 'text',
      floatingLabelText:
        values.type == 'meeting'
          ? t1('meeting_name')
          : t1('virtual_class_name'),
      validate: [required(t1('name_cannot_be_empty'))],
    },
    learn_duration: {
      type: 'number',
      defaultValue: numberClassHour * durationOfClassHour,
      floatingLabelText: t1('duration(_minutes)'),
      fullWidth: true,
      validate: [required(t1('invoice_type_cannot_be_empty'))],
    },
    scheduled: {
      type: 'section',
      schema: scheduledSchema({
        type: type,
        mode,
        node,
        sessionStatus,
      }),
    },
    enable_recording: {
      type: 'checkbox',
      defaultValue: 1,
      label: t1('enable_recording'),
      normalize: convertBooleanValueToInt,
      classWrapper: 'display-none',
    },
    // meeting
    group_iid: groupElement(groups, sessionStatus),
    members: {
      type: 'text',
      fullWidth: true,
      floatingLabelText: `${t1('user_codes')} (${t1(
        'multiple_user_codes_separated_by_commas',
      )})`,
      label: t1('user_codes'),
      hintText: t1('user_codes'),
      multiLine: true,
      rows: 4,
      // validate: [required()],
    },
    group_name: {
      type: 'text',
      floatingLabelText: t1('create_a_group_for_those_members'),
    },
    add_myself_to_group: {
      type: 'checkbox',
      defaultValue: 1,
      label: t1('add_myself_to_this_group'),
      normalize: convertBooleanValueToInt,
    },
    enable_public: {
      type: 'checkbox',
      defaultValue: 1,
      floatingLabelText: t1('enable_public'),
      label: t1('enable_public'),
      normalize: convertBooleanValueToInt,
    },
    download_materials: {
      type: Attachments,
      label: t1('download_materials'),
      allowDownload: true,
      multiple: true,
    },
  };
};

const ui = (
  step,
  values,
  themeConfig,
  xpath,
  formid,
  props = {},
  formStep,
  domainInfo,
) => {
  return [
    {
      id: 'default',
      fields: [
        'name',
        'group_iid',
        'scheduled',
        'members',
        ...(values.members && step === 'new_create_meeting'
          ? ['group_name']
          : []),
        ...(values.members && values.group_name ? ['add_myself_to_group'] : []),
        'enable_recording',
        'enable_public',
        'download_materials',
      ],
    },
  ];
};

const layout = (step, values, xpath, props) => {
  const sessionIds = lodashGet(values, 'session_ids');
  const { node, syllabusObject } = props;

  if (Array.isArray(sessionIds) && sessionIds.length) {
    return null;
  }

  return {
    component: LayoutFreeStyle,
    freestyle: 1,
    optionsProperties: {
      node,
      syllabus: syllabusObject,
    },
  };
};

const finalProcessBeforeSubmit = (fullData) => {
  let sessionDateAsString = '';
  const sessionDateUnix = get(fullData, 'scheduled.date_time');

  if (sessionDateUnix) {
    sessionDateAsString = moment(sessionDateUnix * 1000).format('YYYY-MM-DD');
  }

  const scheduled = {
    ...fullData.scheduled,
    date_time: sessionDateAsString,
  };

  // remove learn_duration field from scheduled and move it to outside of scheduled obj
  delete scheduled.learn_duration;

  return {
    ...fullData,
    scheduled,
    learn_duration:
      lodashGet(fullData, 'scheduled.end_time', 0) -
      lodashGet(fullData, 'scheduled.start_time', 0),
  };
};

export default {
  schema: schema,
  ui,
  layout,
  finalProcessBeforeSubmit,
};
