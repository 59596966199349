import get from 'lodash.get';
import { getValueOfBooleanString } from 'common/utils/string';
import { isLTTUStaff } from 'components/admin/user/utils';
import getUser from 'common/auth';
import taphuanSubTypes from 'configs/constants/org-sub-types';
import { userSchoolLevels } from 'configs/constants/user';

export const getOptionsOrgTypes = (allOrgTypes, hasProvinceDistrict) => {
  const user = getUser();
  const userOrganizationSubType = get(user, 'info.organizations.0.sub_type');

  if (!userOrganizationSubType && allOrgTypes) {
    /* Các đơn vị ngoài Sở*/
    const subTypeOfOutsideSGD = [
      taphuanSubTypes.TAPHUAN_SUBTYPE_BO_GD,
      taphuanSubTypes.ORG_TYPE_RGEP,
      taphuanSubTypes.ORG_TYPE_ETEP,
      taphuanSubTypes.ORG_TYPE_RGEP_AND_ETEP,
      taphuanSubTypes.ORG_TYPE_VU_GIAO_DUC,
      taphuanSubTypes.TAPHUAN_SUBTYPE_LTTU,
    ];

    return allOrgTypes.filter(({ value }) => {
      if (value === taphuanSubTypes.TAPHUAN_SUBTYPE_SO_GD) {
        return true;
      }

      const outsideSGD = subTypeOfOutsideSGD.includes(value);
      return hasProvinceDistrict ? !outsideSGD : outsideSGD;
    });
  }

  let subTypes = [];

  const truongPtSubtypes = [
    taphuanSubTypes.TAPHUAN_SUBTYPE_TIEUHOC,
    taphuanSubTypes.TAPHUAN_SUBTYPE_THCS,
    taphuanSubTypes.TAPHUAN_SUBTYPE_GIAODUC_THUONGXUYEN,
    taphuanSubTypes.TAPHUAN_SUBTYPE_LIENCAP,
    taphuanSubTypes.TAPHUAN_SUBTYPE_TUTHUC_LIENCAP,
    taphuanSubTypes.TAPHUAN_SUBTYPE_MAM_NON,
  ];

  switch (userOrganizationSubType) {
    case taphuanSubTypes.TAPHUAN_SUBTYPE_SO_GD: {
      subTypes = !hasProvinceDistrict
        ? [
            taphuanSubTypes.TAPHUAN_SUBTYPE_SO_GD,
            taphuanSubTypes.TAPHUAN_SUBTYPE_PHONG_GD,
          ]
        : [
            taphuanSubTypes.TAPHUAN_SUBTYPE_SO_GD,
            taphuanSubTypes.TAPHUAN_SUBTYPE_PHONG_GD,
            taphuanSubTypes.TAPHUAN_SUBTYPE_THPT,
          ].concat(truongPtSubtypes);
      break;
    }
    case taphuanSubTypes.TAPHUAN_SUBTYPE_PHONG_GD: {
      subTypes = !hasProvinceDistrict
        ? [taphuanSubTypes.TAPHUAN_SUBTYPE_PHONG_GD]
        : [
            taphuanSubTypes.TAPHUAN_SUBTYPE_PHONG_GD,
            taphuanSubTypes.TAPHUAN_SUBTYPE_TIEUHOC,
            taphuanSubTypes.TAPHUAN_SUBTYPE_THCS,
            taphuanSubTypes.TAPHUAN_SUBTYPE_LIENCAP,
            taphuanSubTypes.TAPHUAN_SUBTYPE_TUTHUC_LIENCAP,
            taphuanSubTypes.TAPHUAN_SUBTYPE_MAM_NON,
            taphuanSubTypes.TAPHUAN_SUBTYPE_GIAODUC_THUONGXUYEN,
          ];
      break;
    }
    case taphuanSubTypes.TAPHUAN_SUBTYPE_BO_GD:
    case taphuanSubTypes.ORG_TYPE_RGEP:
    case taphuanSubTypes.ORG_TYPE_ETEP:
    case taphuanSubTypes.ORG_TYPE_RGEP_AND_ETEP:
    case taphuanSubTypes.ORG_TYPE_VU_GIAO_DUC:
    case taphuanSubTypes.TAPHUAN_SUBTYPE_LTTU: {
      if (hasProvinceDistrict) {
        subTypes = [
          taphuanSubTypes.TAPHUAN_SUBTYPE_SO_GD,
          taphuanSubTypes.TAPHUAN_SUBTYPE_PHONG_GD,
          taphuanSubTypes.TAPHUAN_SUBTYPE_THPT,
        ].concat(truongPtSubtypes);
        break;
      }
      subTypes = [
        taphuanSubTypes.ORG_TYPE_RGEP,
        taphuanSubTypes.ORG_TYPE_ETEP,
        taphuanSubTypes.ORG_TYPE_RGEP_AND_ETEP,
        taphuanSubTypes.ORG_TYPE_VU_GIAO_DUC,
        taphuanSubTypes.TAPHUAN_SUBTYPE_LTTU,
        taphuanSubTypes.TAPHUAN_SUBTYPE_SO_GD,
        taphuanSubTypes.TAPHUAN_SUBTYPE_PHONG_GD,
      ].concat([userOrganizationSubType]);
      break;
    }
    default: {
      subTypes = subTypes = [taphuanSubTypes.TAPHUAN_SUBTYPE_THPT].concat(
        truongPtSubtypes,
      );
    }
  }

  return allOrgTypes.filter(({ value }) => subTypes.includes(value));
};

export const doesUserNeedToFillProfileNow = (conf, userInfo) => {
  const isGuest = !get(userInfo, 'id');

  let bool =
    get(conf, 'user_must_enter_temis_profile_info') &&
    !isLTTUStaff(userInfo) &&
    !isGuest &&
    !get(userInfo, 'can_skip_profile') &&
    (!getValueOfBooleanString(get(userInfo, 'have_enter_temis_profile_info')) ||
      getValueOfBooleanString(get(userInfo, 'force_re_enter_profile_info')));

  const isFinishProfileForm = JSON.parse(
    localStorage.getItem('isFinishProfileForm'),
  );

  return bool && !isFinishProfileForm;
};

export const getDefaultValueOfGrade = (formValues, node) => {
  const subType = get(formValues, 'org_sub_type');
  switch (subType) {
    case taphuanSubTypes.TAPHUAN_SUBTYPE_MAM_NON: {
      return userSchoolLevels.NURSERY;
    }
    case taphuanSubTypes.TAPHUAN_SUBTYPE_TIEUHOC: {
      return userSchoolLevels.PRIMARY;
    }
    case taphuanSubTypes.TAPHUAN_SUBTYPE_THCS: {
      return userSchoolLevels.SECONDARY;
    }
    case taphuanSubTypes.TAPHUAN_SUBTYPE_THPT: {
      return userSchoolLevels.HIGH;
    }
    default: {
      return node && node.grade;
    }
  }
};
