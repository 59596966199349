import React from 'react';
import DisplayHtml from 'components/common/html';

const Content = ({ item = {} }) => {
  const { content } = item;
  if (!content) {
    return null;
  }

  return (
    <div className="content">
      <DisplayHtml content={content} />
    </div>
  );
};

export default Content;
