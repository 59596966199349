import React, { Component } from 'react';
import NodeNew from 'components/admin/node/new';
import schema from './schema';
import { Alert } from 'antd';
import { t1 } from 'translate';

class ChooseFreezeMode extends Component {
  render() {
    const { node, ntype } = this.props;

    return (
      <div>
        {ntype == 'course' && (
          <Alert
            message={t1(
              'you_can_freeze_a_course_if_you_dont_want_students_or_instructors_to_learn_or_grade_scores',
            )}
            type="info"
          />
        )}
        <NodeNew
          ntype={ntype}
          schema={schema}
          mode="edit"
          step="freeze"
          formid={`update-type-freeze-${ntype}`}
          // alternativeApi={apiUrls.node_update}
          node={node}
          dialogKey={`update-node-${ntype}`}
        />
      </div>
    );
  }
}

export default ChooseFreezeMode;
