import './styles.scss';

import React from 'react';
import CommonAntdTable from 'components/common/antd/table';
import { t1 } from 'translate';
import { timestampToDateTimeString } from 'common/utils/Date';
import Tag from 'antd/lib/tag';
import { Link } from 'react-router-dom';
import Icon from 'components/common/Icon';
import get from 'lodash.get';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import { getTargetUser, userGradeToShortText } from 'configs/constants/user';

const Results = ({ items }) => {
  const columns = [
    {
      title: t1('program_name'),
      key: 'name',
      render: (row) => (
        <>
          <Link to={`/admin/review-program/${row.iid}`} className={'m-r-5'}>
            {row.name}
          </Link>
        </>
      ),
    },
    {
      title: 'Mã mô-đun',
      key: 'module',
      dataIndex: 'module',
      render: (module = []) => module && module.code,
    },
    {
      title: t1('organization'),
      key: 'organizations_name',
      dataIndex: 'organizations_name',
      render: (organizations = []) => organizations.join(', '),
    },
    {
      title: 'Cấp học',
      width: 110,
      key: 'caps',
      render: (item) => {
        return (
          <div>
            {Array.isArray(item.caps) &&
              item.caps.map((cap, i) => (
                <Tag className={'m-5'} key={`cap-${i}`}>
                  {userGradeToShortText(cap)}
                </Tag>
              ))}
          </div>
        );
      },
    },
    {
      title: t1('target_users'),
      key: 'target_users',
      width: 80,
      render: (item) => (
        <div>
          {Array.isArray(item.target_users) &&
            item.target_users.map((target, i) => (
              <Tag className={'m-5'} key={`target-${i}`}>
                {getTargetUser(target)}
              </Tag>
            ))}
        </div>
      ),
    },
    {
      title: t1('number_of_syllabuses'),
      key: 'number_of_syllabuses',
      dataIndex: 'children',
      width: 100,
      type: TYPE_OF_TABLE_DATA.TITLE,
      render: (children = []) => children.length,
    },
    {
      title: t1('status'),
      key: 'status',
      type: TYPE_OF_TABLE_DATA.TITLE,
      width: 150,
      render: (row) => {
        let status = row.status || 'dhsp_editing_program';

        if (status === 'approved') {
          status = 'dhsp_approved_program';
        }

        return <span className={`status ${status}`}>{t1(status)}</span>;
      },
    },
    {
      title: t1('approved_status'),
      key: 'approved_status',
      type: TYPE_OF_TABLE_DATA.TITLE,
      render: (row = {}) => {
        const status = row.review_status;
        if (!status) {
          return <span>{t1(`vgd_not_review`)}</span>;
        }

        const lastHistory = get(row, 'review_histories', []).pop();

        return (
          <div className="text-center">
            <span className={`status ${status}`}>{t1(`vgd_${status}`)}</span>
            {lastHistory ? (
              <div className="m-t-5 text-muted">
                <span>{get(lastHistory, 'u.name')}</span>
                <br />
                <span>{timestampToDateTimeString(get(lastHistory, 'ts'))}</span>
              </div>
            ) : null}
          </div>
        );
      },
    },
    {
      title: t1('action'),
      key: 'detail',
      type: TYPE_OF_TABLE_DATA.ACTION,
      width: 80,
      render: (row) => (
        <>
          <Link to={`/admin/program/${row.iid}/children`} className={'m-r-5'}>
            <Icon icon="edit" antIcon />
          </Link>
          <Link to={`/admin/review-program/${row.iid}`}>
            <Icon icon="eye" antIcon />
          </Link>
        </>
      ),
    },
  ];

  return (
    <CommonAntdTable
      className="review-program-container learn-default"
      rowKey="iid"
      columns={columns}
      dataSource={items}
      pagination={false}
      childrenColumnName={null}
      bordered
    />
  );
};

export default Results;
