import { LayoutRegistered } from 'layouts/LayoutHelper';
import Loadable from 'components/common/async-loader/Loadable';
import {
  adminRootUrl,
  guideUrl,
  learnRootUrl,
  systemRootUrl,
} from './root-url';

// frontend
import learnRoutes from './student/learn';
import blogRoutes from './student/blog';
import accountRoutes from './student/account';
import Loading from '../components/common/loading';
import { enterMissingData } from 'components/temis/common/routes';

const Admin = Loadable({
  loader: () =>
    import(/* webpackChunkName: "admin" */ 'routes/route-groups/admin'),
});

const System = Loadable({
  loader: () =>
    import(/* webpackChunkName: "system" */ 'routes/route-groups/system'),
});

const NoMatch = Loadable({
  loader: () =>
    import(/* webpackChunkName: "profile" */ 'components/common/404/index'),
});

const FAQ = Loadable({
  loader: () => import('components/front-end/faq'),
  loading: Loading,
});

const PrivacyPolicy = Loadable({
  loader: () => import('components/front-end/privacy-policy'),
  loading: Loading,
});

const HomePageLayout = Loadable({
  loader: () => import('components/front-end/homepage'),
  loading: Loading,
});

const Guide = Loadable({
  loader: () => import('components/front-end/guide'),
  loading: Loading,
});

const Temis = Loadable({
  loader: () => import('components/temis'),
  loading: Loading,
});

const EnterMissingData = Loadable({
  loader: () => import('components/temis/enter-missing-data'),
  loading: Loading,
});

const temisRoutes = [
  ...accountRoutes(),
  {
    componentId: 'TemisFrontend',
    path: '/temis',
    component: Temis,
  },
  {
    path: enterMissingData(),
    component: EnterMissingData,
    exact: true,
  },
  {
    componentId: 'Homepage',
    path: '/',
    component: HomePageLayout,
    exact: true,
  },
  {
    componentId: 'FAQ',
    path: '/faq',
    component: FAQ,
  },
  ...blogRoutes(),
  {
    componentId: 'privacy_policy',
    path: '/privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: adminRootUrl,
    component: Admin,
    layout: LayoutRegistered.admin,
  },
  {
    path: systemRootUrl,
    component: System,
    layout: LayoutRegistered.admin,
  },
  {
    componentId: 'Guide',
    path: guideUrl,
    component: Guide,
    exact: true,
  },
  ...learnRoutes(learnRootUrl),
  {
    component: NoMatch,
  },
];

export default temisRoutes;
