import lodashGet from 'lodash.get';
import { getFileFullName } from '../../../common/utils/File';
import DownloadLinkWrapper from '../download/DownloadLinkWrapper';
import React from 'react';

const DownloadAttachmentWrapper = ({ attachment, renderComponent }) => {
  const link = lodashGet(attachment, 'link');
  const fileName = getFileFullName(attachment);

  if (!link) {
    return null;
  }

  return (
    <DownloadLinkWrapper
      link={link}
      fileName={fileName}
      fileSize={lodashGet(attachment, 'size')}
      renderComponent={renderComponent}
    />
  );
};

export default DownloadAttachmentWrapper;
