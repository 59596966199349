import AttachmentsElement from 'schema-form/elements/attachments';

const Attachments = ({
  uploadUrl,
  allowDownload, // Boolean
  limit, // maximum number of files, if multiple=true
  multiple, // Boolean
  format, // function to format the values
  accept, // ['.zip', '.pdf'] will only accept zip & pdf files
  keepAtLeastOneFile, // Boolean.
  AttachmentPreviewer, // Component
  noFileManager, // don't show the file manager (which will display the file manager in a dialog)
  maxFileSize,
  viewerFileExtensions, // extension có thể show preview
  justShowFileName, // when we preview the file name, we don't show id...
  compactMode, // the dashed box will be more compact
  onFinishUploadFile, // (value) => {}
  hideFilesList = false, // Boolean: defaulted to false. Set to true when you are uploading only one file and you want to handle the display else where
  isAudioAttachments = false,
  isImageAttachments = false,
  uploadButtonComponent = null,
  fileListComponent = null,
  showProgress = true,
  displayInline = false,
  // common properties
  label,
  floatingLabelText,
  defaultValue,
  errorText,
  validate,
  normalize,
  onChange,
  classWrapper,
  fullWidth,
  readOnly,
  ...rest
}) => {
  return {
    type: AttachmentsElement,
    uploadUrl,
    label,
    floatingLabelText,
    allowDownload,
    limit,
    multiple,
    format,
    accept,
    keepAtLeastOneFile,
    AttachmentPreviewer,
    noFileManager,
    maxFileSize,
    viewerFileExtensions,
    justShowFileName,
    compactMode,
    onFinishUploadFile,
    hideFilesList,
    isAudioAttachments,
    isImageAttachments,
    uploadButtonComponent,
    fileListComponent,
    showProgress,
    displayInline,

    defaultValue,
    errorText,
    validate,
    normalize,
    onChange,
    classWrapper,
    fullWidth,
    readOnly,
    ...rest,
  };
};

export default Attachments;
