import React, { Component } from 'react';
import schema from 'components/admin/course/mainstage/session/form-filter/schema-form';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import ResultsDialogUni from './ResultsDialogUni';
import lodashGet from 'lodash.get';

class AttendanceRegisteringToVirtualDialogTable extends Component {
  callbackAttendanceChange = () => {
    // this.props.handleRefetch();
    this.props.callbackAttendanceChange &&
      this.props.callbackAttendanceChange();
  };

  renderResultComponent = (items) => {
    const {
      session,
      server_ts,
      automaticScaleVirtualClassEnabled,
      viewMode,
      foreHideSystemAttendance,
    } = this.props;
    return (
      <ResultsDialogUni
        viewMode={viewMode}
        foreHideSystemAttendance={foreHideSystemAttendance}
        server_ts={server_ts}
        users={items}
        session={session}
        callbackAttendanceChange={this.callbackAttendanceChange}
        automaticScaleVirtualClassEnabled={automaticScaleVirtualClassEnabled}
      />
    );
  };

  render() {
    const { session } = this.props;
    const sessionIid = lodashGet(session, 'iid');
    return (
      <div>
        <SearchWrapper
          ignoreOptimize={true}
          key={`session_${sessionIid}`}
          formid={'session_search_user'}
          showResult={true}
          schema={schema(this.props)}
          hiddenFields={{
            session_iid: sessionIid,
          }}
          renderResultsComponent={this.renderResultComponent}
          showQueryField={false}
          autoSearchWhenStart
          alternativeApi={'/session/api/search-users'}
        />
      </div>
    );
  }
}

export default AttendanceRegisteringToVirtualDialogTable;
