import CryptoJS from 'crypto-js';

const CryptoJSAesEncrypt = (passphrase, plain_text) => {
  var AesKey = passphrase; // The key used for encryption is the same as PHP
  var message = plain_text; // encrypted character channeling
  var ECBOptions = {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  };
  var key = CryptoJS.enc.Utf8.parse(AesKey);
  var bytes = CryptoJS.AES.encrypt(message, key, ECBOptions);
  var encryptedText = bytes.toString();
  return encryptedText;
};

export const CryptoJSAesEncryptNormalPassphrase = (passphrase, plain_text) =>
  CryptoJSAesEncrypt(CryptoJS.MD5(passphrase), plain_text);

export default CryptoJSAesEncrypt;
