const Section = ({
  schema, // another form schema object { schema, ui }
  hintText,
  floatingLabelText,
  floatingLabelFixed,
  defaultValue,
  errorText,
  validate,
  normalize,
  onChange,
  classWrapper,
  fullWidth,
  readOnly,
  inline, // TODO: is this applicable ???
  ...rest
}) => {
  return {
    type: 'section',
    schema,
    hintText,
    floatingLabelText,
    defaultValue,
    errorText,
    validate,
    normalize,
    onChange,
    classWrapper,
    fullWidth,
    readOnly,
    inline,
    ...rest,
  };
};

export default Section;
