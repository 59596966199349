import React, { useState } from 'react';
import { confirmRestoreFromHistory, restoreFromHistory } from './util';
import Icon from 'components/common/Icon';
import { t1 } from 'translate';
import ButtonSecondary from 'components/common/button/ButtonSecondary';
import connect from 'react-redux/lib/connect/connect';

const RestoreButton = ({
  dispatch,
  takeId,
  historyId,
  searchFormId,
  restoreSuccessful,
  requestFailedCallback,
  showIcon = true,
  label = '',
  confirmBeforeDoing = true,
}) => {
  const initialLabel = label || t1('take_restore');
  const [buttonLabel, setButtonLabel] = useState(initialLabel);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const onRestoreSuccessful = () => {
    setButtonLabel(initialLabel);
    setButtonDisabled(false);

    if (typeof restoreSuccessful === 'function') {
      restoreSuccessful();
    }
  };

  const onRestoreFailed = () => {
    setButtonLabel(initialLabel);
    setButtonDisabled(false);

    if (typeof requestFailedCallback === 'function') {
      requestFailedCallback();
    }
  };

  const handleClick = () => {
    if (confirmBeforeDoing) {
      confirmRestoreFromHistory(
        dispatch,
        takeId,
        historyId,
        searchFormId,
        onRestoreSuccessful,
        onRestoreFailed,
      );
    } else {
      setButtonLabel(t1('doing...'));
      setButtonDisabled(true);

      restoreFromHistory(
        dispatch,
        takeId,
        historyId,
        searchFormId,
        onRestoreSuccessful,
        onRestoreFailed,
      );
    }
  };

  return (
    <ButtonSecondary onClick={handleClick} disabled={buttonDisabled}>
      {showIcon && <Icon icon={'reload'} />} {buttonLabel}
    </ButtonSecondary>
  );
};

export default connect()(RestoreButton);
