import React from 'react';
import {
  isAdminTruongOrHieuTruongOrPho,
  isTeacher,
  isHVQLStaff,
  isMainLeaderPositionToTruong,
  isBo,
  isAmTinh,
  isSo,
  isAdminTruong,
  isBoIVA,
  isSoOrPhong,
  isVuGD,
  isLTTUStaff,
} from 'components/admin/user/utils';
import { REPORT_GROUP_ROUTER } from '../constant';
import { Avatar, List } from 'antd';
import ok from '../../../common/images/ok.png';
import { Link } from 'react-router-dom';
import DisplayHtml from 'components/common/html';
import { formatNumber } from 'common/utils/Number';
import { isIVAUser } from 'components/temis/utils';
import Store from 'store';
import { change, submit } from 'redux-form';
import { getStartTimeOfDay } from 'components/timetable/common/Datetime';
import ReportProgressSyncerButton from './syncer';
import { t1 } from 'translate';

export const reportMNTitle = {
  mn: 'Danh sách báo cáo Mầm Non',
  mn1: 'Kết quả đánh giá và xếp loại GV Mầm non theo chuẩn nghề nghiệp',
  mn2: 'Kết quả đánh giá và xếp loại CBQL CSGD Mầm non theo chuẩn hiệu trưởng',
  mn3:
    'Nhu cầu bồi dưỡng của GV Mầm non (Ghi tên 5 tiêu chí; số lượng và tỷ lệ % ở mức xếp loại thấp nhất của từng tiêu chí theo tổng chung, phân tách theo các nhóm Nữ, DTTS, Nữ DTTS và công tác vùng khó khăn)',
  mn4:
    'Nhu cầu bồi dưỡng của CBQLCSGD Mầm non (Ghi tên 5 tiêu chí; số lượng và tỷ lệ % ở mức xếp loại thấp nhất của từng tiêu chí theo tổng chung, phân tách theo các nhóm Nữ, DTTS, Nữ DTTS và công tác vùng khó khăn)',
  mn5:
    'Nhu cầu bồi dưỡng theo mong muốn của GV Mầm non (Tập hợp từ câu hỏi 2 trong phiếu Tự đánh giá xếp loại giáo viên)',
  mn6:
    'Nhu cầu bồi dưỡng theo mong muốn của CBQL CSGD Mầm non (Tập hợp từ câu hỏi 2 trong phiếu Tự đánh giá xếp loại CBQL CSGD Mầm non',
  mn7:
    'Báo cáo tổng hợp theo tiêu chí kết quả đánh giá và xếp loại của GV Mầm non theo chuẩn nghề nghiệp',
  mn8:
    'Báo cáo tổng hợp theo tiêu chí kết quả đánh giá và xếp loại của CBQL CSGD Mầm non theo chuẩn nghề nghiệp',
};

export const reportMN = (user) => {
  if (isTeacher(user) || isMainLeaderPositionToTruong(user) || isVuGD(user)) {
    return [];
  }

  const isHVQL = isHVQLStaff(user);

  let allReports = [
    !isHVQL && {
      title: reportMNTitle.mn1,
      path: REPORT_GROUP_ROUTER.cbql_gvmn,
    },
    {
      title: reportMNTitle.mn2,
      path: REPORT_GROUP_ROUTER.cbql_cbmn,
    },
    !isHVQL && {
      title: reportMNTitle.mn3,
      path: REPORT_GROUP_ROUTER.ncbdtx_gvmn,
    },
    {
      title: reportMNTitle.mn4,
      path: REPORT_GROUP_ROUTER.ncbdtx_cbmn,
    },
    !isHVQL && {
      title: reportMNTitle.mn5,
      path: REPORT_GROUP_ROUTER.ncbdtx_plan_develop_gvmn,
    },
    {
      title: reportMNTitle.mn6,
      path: REPORT_GROUP_ROUTER.ncbdtx_plan_develop_cbmn,
    },
    !isHVQL && {
      title: reportMNTitle.mn7,
      path: REPORT_GROUP_ROUTER.cbql_gvmn_by_rubric,
    },
    {
      title: reportMNTitle.mn8,
      path: REPORT_GROUP_ROUTER.cbql_cbmn_by_rubric,
    },
  ].filter(Boolean);

  if (isAdminTruongOrHieuTruongOrPho(user)) {
    allReports = allReports.concat([
      {
        title: 'Báo cáo khảo sát',
        path: REPORT_GROUP_ROUTER.survey,
      },
      {
        title: t1('comment_and_reflect_report'),
        path: REPORT_GROUP_ROUTER.reflect,
      },
    ]);
  }

  return allReports;
};

export const reportATitle = {
  a:
    'PHẦN A: KẾT QUẢ ĐÁNH GIÁ VÀ XẾP LOẠI GIÁO VIÊN VÀ CÁN BỘ QUẢN LÝ CƠ SỞ GIÁO DỤC THEO CHUẨN NGHỀ NGHIỆP GIÁO VIÊN VÀ CHUẨN HIỆU TRƯỞNG CƠ SỞ GIÁO DỤC',
  a1: 'A.1. Kết quả đánh giá và xếp loại giáo viên theo chuẩn nghề nghiệp',
  a2:
    'A.2. Kết quả đánh giá và xếp loại cán bộ quản lí cơ sở giáo dục theo chuẩn hiệu trưởng',
};

export const reportA = (user) => {
  if (isTeacher(user) || isMainLeaderPositionToTruong(user) || isVuGD(user)) {
    return [];
  }

  const forGV = isHVQLStaff(user)
    ? []
    : [
        {
          id: 'a1',
          title: reportATitle.a1,
          path: REPORT_GROUP_ROUTER.cbql_gvpt,
        },
      ];

  return [
    ...forGV,
    {
      id: 'a2',
      title: reportATitle.a2,
      path: REPORT_GROUP_ROUTER.cbql_cbpt,
    },
  ];
};

export const getReportATitle = (
  isResultForMN,
  isResultByRubric,
  isResultForCBQL,
) => {
  let reportTitle;
  if (isResultByRubric) {
    if (isResultForMN) {
      reportTitle = isResultForCBQL ? reportMNTitle.mn8 : reportMNTitle.mn7;
    } else {
      reportTitle = isResultForCBQL
        ? reportBCThongKeTitle.cnnCBQL
        : reportBCThongKeTitle.cnnGVPT;
    }
  } else if (isResultForMN) {
    reportTitle = isResultForCBQL ? reportMNTitle.mn2 : reportMNTitle.mn1;
  } else {
    reportTitle = isResultForCBQL ? reportATitle.a2 : reportATitle.a1;
  }

  return reportTitle;
};

export const reportBTitle = {
  b:
    'PHẦN B: KẾT QUẢ ĐÁNH GIÁ VỀ CÁC CHƯƠNG TRÌNH BỒI DƯỠNG THƯỜNG XUYÊN (Dựa trên khảo sát trực tuyến GV và CBQLCSGD hoàn thành các mô đun bồi dưỡng vào cuối năm)',
  b1:
    'B.1. Kết quả trả lời phiếu khảo sát trực tuyến của GIÁO VIÊN về chương trình bồi dưỡng thường xuyên',
  b2:
    'B.2. Kết quả trả lời phiếu khảo sát trực tuyến của CBQLCSGD về chương trình bồi dưỡng thường xuyên',
};

export const reportB = (user) => {
  if (isTeacher(user) || isMainLeaderPositionToTruong(user) || isVuGD(user)) {
    return [];
  }

  const forGV = isHVQLStaff(user)
    ? []
    : [
        {
          id: 'b1',
          title: reportBTitle.b1,
          path: REPORT_GROUP_ROUTER.ctbdtx_gvpt,
        },
      ];

  return [
    ...forGV,
    {
      id: 'b2',
      title: reportBTitle.b2,
      path: REPORT_GROUP_ROUTER.ctbdtx_cbql,
    },
  ];
};

export const getReportBTitle = (isResultForCBQL) => {
  return isResultForCBQL ? reportBTitle.b2 : reportBTitle.b1;
};

export const reportSynthesisTitle = {
  synthesis: 'Tổng kết đánh giá chung',
};

export const getReportSynthesisTitle = () => {
  return reportSynthesisTitle.synthesis;
};

export const reportGenerateTitle = {
  tk: 'BÁO CÁO TỔNG HỢP A-B-C',
  generate: 'Báo cáo tổng hợp A,B,C',
  list_user: 'Danh sách GIÁO VIÊN VÀ CBQLCSGD theo từng sở Giáo Dục',
};

export const getReportGenerateTitle = () => {
  return reportGenerateTitle.generate;
};

export const reportCTitle = {
  c:
    'PHẦN C: BÁO CÁO KẾT QUẢ KHẢO SÁT NHU CẦU BỒI DƯỠNG THƯỜNG XUYÊN CỦA GIÁO VIÊN VÀ CBQLCSGD THEO CÁC TIÊU CHÍ TRONG CHUẨN NGHỀ NGHIỆP GIÁO VIÊN VÀ CHUẨN HIỆU TRƯỞNG CƠ SỞ GIÁO DỤC',
  c1: 'C.1. Nhu cầu bồi dưỡng dựa trên đánh giá theo chuẩn',
  c11:
    'C.1.1. Nhu cầu bồi dưỡng của GIÁO VIÊN (Ghi tên 5 tiêu chí; số lượng và tỷ lệ % ở mức xếp loại thấp nhất của từng tiêu chí theo tổng chung, phân tách theo các nhóm Nữ, DTTS, Nữ DTTS và công tác vùng khó khăn)',
  c12:
    'C.1.2. Nhu cầu bồi dưỡng của CBQLCSGD (Ghi tên 5 tiêu chí; số lượng và tỷ lệ % ở mức xếp loại thấp nhất của từng tiêu chí theo tổng chung, phân tách theo các nhóm Nữ, DTTS, Nữ DTTS và công tác vùng khó khăn)',
  c2:
    'C.2. Nhu cầu bồi dưỡng theo mong muốn của GIÁO VIÊN (Tập hợp từ câu hỏi 2 trong phiếu Tự đánh giá xếp loại giáo viên)',
  c3:
    'C.3. Nhu cầu bồi dưỡng theo mong muốn của CBQL CSGD (Tập hợp từ câu hỏi 2 trong phiếu Tự đánh giá xếp loại CBQL CSGD)',
  cx: 'Tổng hợp Nhu cầu BDTX theo module đào tạo của Giáo Viên, CBQL CSGD',
  cr: 'Báo cáo kết quả BDTX hàng năm của Giáo Viên, CBQL CSGD',
};

export const reportC = (user) => {
  if (isTeacher(user) || isMainLeaderPositionToTruong(user) || isVuGD(user)) {
    return [];
  }

  const isHVQL = isHVQLStaff(user);
  return [
    {
      id: 'c1',
      title: reportCTitle.c1,
      description: [
        !isHVQL && {
          title: reportCTitle.c11,
          path: REPORT_GROUP_ROUTER.ncbdtx_gvpt,
        },
        {
          title: reportCTitle.c12,
          path: REPORT_GROUP_ROUTER.ncbdtx_cbql,
        },
      ].filter(Boolean),
    },
    !isHVQL && {
      id: 'c2',
      title: reportCTitle.c2,
      path: REPORT_GROUP_ROUTER.ncbdtx_plan_develop_gvpt,
    },
    {
      id: 'c3',
      title: reportCTitle.c3,
      path: REPORT_GROUP_ROUTER.ncbdtx_plan_develop_cbpt,
    },
    {
      id: 'cx',
      title: reportCTitle.cx,
      path: REPORT_GROUP_ROUTER.ncbdtx_by_module,
    },
    {
      id: 'cr',
      title: reportCTitle.cr,
      path: REPORT_GROUP_ROUTER.result_bdtx,
    },
  ].filter(Boolean);
};

export const getReportCTitle = (
  isResultForMN,
  isThongKeReport,
  isResultForCBQL,
  isPlanDevelopment,
) => {
  let reportTitle;
  if (isThongKeReport) {
    reportTitle = reportBCThongKeTitle.cnnCBQLOrg;
  } else if (isPlanDevelopment) {
    if (isResultForMN) {
      reportTitle = isResultForCBQL ? reportMNTitle.mn6 : reportMNTitle.mn5;
    } else {
      reportTitle = isResultForCBQL ? reportCTitle.c3 : reportCTitle.c2;
    }
  } else {
    if (isResultForMN) {
      reportTitle = isResultForCBQL ? reportMNTitle.mn4 : reportMNTitle.mn3;
    } else {
      reportTitle = isResultForCBQL ? reportCTitle.c12 : reportCTitle.c11;
    }
  }

  return reportTitle;
};

export const reportBCThongKeTitle = {
  tk: 'DANH SÁCH BÁO CÁO THỐNG KÊ',
  cnnGVPT:
    'Báo cáo tổng hợp theo tiêu chí kết quả đánh giá và xếp loại của GV CSGD theo chuẩn nghề nghiệp',
  cnnCBQL:
    'Báo cáo tổng hợp theo tiêu chí kết quả đánh giá và xếp loại của CBQL CSGD theo chuẩn nghề nghiệp',
  cnnGVPTOrg:
    'Báo cáo tổng hợp đánh giá và xếp loại chuẩn nghề nghiệp GV theo đơn vị',
  cnnCBQLOrg:
    'Báo cáo tổng hợp đánh giá và xếp loại chuẩn nghề nghiệp CBQL theo đơn vị',
  synthesis: 'Báo cáo tổng hợp ',
};

export const reportSynthesisThongKeTitle = {
  tk: 'BÁO CÁO TỔNG QUAN',
  synthesis: 'Báo cáo tổng hợp ',
  detail_synthesis: 'Báo cáo tổng hợp chi tiết đánh giá chung',
};

export const reportBCThongKe = (user) => {
  if (isTeacher(user) || isMainLeaderPositionToTruong(user)) {
    return [];
  }

  const surveyReport = {
    id: 'survey',
    title: 'Báo cáo khảo sát',
    path: REPORT_GROUP_ROUTER.survey,
  };

  const reflectReport = {
    id: 'reflect',
    title: t1('comment_and_reflect_report'),
    path: REPORT_GROUP_ROUTER.reflect,
  };

  if (isVuGD(user)) {
    return [surveyReport, reflectReport];
  }

  return [
    !isHVQLStaff(user) && {
      id: 'gvpt_by_rubric',
      title: reportBCThongKeTitle.cnnGVPT,
      path: REPORT_GROUP_ROUTER.cbql_gvpt_by_rubric,
    },
    !isAdminTruongOrHieuTruongOrPho(user) && {
      id: 'cbpt_by_rubric',
      title: reportBCThongKeTitle.cnnCBQL,
      path: REPORT_GROUP_ROUTER.cbql_cbpt_by_rubric,
    },
    !isIVAUser(user) && surveyReport,
    !isIVAUser(user) && !isLTTUStaff(user) && reflectReport,
  ].filter(Boolean);
};

export const reportSynthesis = (user) => {
  let reports = [];

  if (isVuGD(user)) {
    return reports;
  }

  if (isBo(user) || isLTTUStaff(user) || isSoOrPhong(user)) {
    reports.push({
      id: 'synthesis',
      title: reportSynthesisThongKeTitle.synthesis,
      path: REPORT_GROUP_ROUTER.synthesis,
    });
  }

  if (1 || isBo(user) || isLTTUStaff(user) || isAmTinh(user) || isSo(user)) {
    reports.push({
      id: 'generate',
      title: reportGenerateTitle.generate,
      path: REPORT_GROUP_ROUTER.generate,
    });
  }

  if (
    (isBo(user) || isLTTUStaff(user)) &&
    (!isIVAUser(user) || isBoIVA(user))
  ) {
    reports.push({
      id: 'list_user',
      title: reportGenerateTitle.list_user,
      path: REPORT_GROUP_ROUTER.list_user,
    });
  }

  if (isAdminTruong(user)) {
    reports.push({
      id: 'detail_synthesis',
      title: reportSynthesisThongKeTitle.detail_synthesis,
      path: REPORT_GROUP_ROUTER.detail_synthesis,
    });
  }

  return reports;
};

export const convertStringToElement = (item, className = '') => {
  const title = item.title || item;

  if (!title) {
    return null;
  }

  const titleConvert = title.replace(
    /(\(.*\))/g,
    `<span class="${className || 'sub-title-report'}">$1</span>`,
  );

  return <DisplayHtml content={titleConvert} />;
};

export const renderReportList = (items) => {
  const renderDescription = (description) => {
    if (Array.isArray(description)) {
      return renderReportList(description);
    }

    return description;
  };

  const renderTitle = (item) => {
    if (!item) {
      return;
    }

    const title = (
      <span style={{ color: 'black' }}>{convertStringToElement(item)}</span>
    );

    const path = item.link || item.path;
    return path ? <Link to={path}>{title}</Link> : title;
  };

  return (
    <List
      itemLayout="horizontal"
      dataSource={items}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar src={ok} />}
            title={renderTitle(item)}
            description={renderDescription(item.description)}
          />
        </List.Item>
      )}
    />
  );
};

export const checkPermissionByPathname = (pathname, search, listItem = []) => {
  if (!listItem || !listItem.length) {
    return;
  }

  const matchedItem = listItem.find((item) => {
    if (Array.isArray(item.description)) {
      return checkPermissionByPathname(pathname, search, item.description);
    }

    return (
      pathname === item.path ||
      `${pathname}${search}` === item.path ||
      pathname === REPORT_GROUP_ROUTER.detail
    );
  });

  return !!matchedItem;
};

export const getNumberFormat = (data = 0, skipFormatNumber) => {
  return skipFormatNumber ? data : formatNumber(data) || 0;
};

export const divisionNumber = (dividend, total, skipFormatNumber) => {
  return getNumberFormat(
    total > 0
      ? parseFloat(((Number(dividend) / Number(total)) * 100).toFixed(2))
      : 0,
    skipFormatNumber,
  );
};

export const getSubTypesToDisplayFilterData = (
  organizations,
  subtypes = {},
  subTypeOptions = [],
  parentSubType = null,
) => {
  if (!Array.isArray(organizations) || !organizations.length) {
    return {
      subTypeData: subtypes,
      subTypeOptions: subTypeOptions,
    };
  }

  let newSubtypes = subtypes;
  let newSubTypeOptions = Array.isArray(subTypeOptions) ? subTypeOptions : [];

  organizations.forEach(({ sub_type, children }) => {
    let tmp = newSubtypes[sub_type] || [];
    if (parentSubType && !tmp.includes(parentSubType)) {
      tmp.push(parentSubType);
      if (newSubtypes[parentSubType]) {
        tmp = tmp.concat(newSubtypes[parentSubType]);
      }
      newSubtypes[sub_type] = tmp;
    }

    if (!newSubTypeOptions.includes(sub_type)) {
      newSubTypeOptions.push(sub_type);
    }

    tmp = getSubTypesToDisplayFilterData(
      children,
      newSubtypes,
      newSubTypeOptions,
      sub_type,
    );

    newSubtypes = tmp.subTypeData;
    newSubTypeOptions = tmp.subTypeOptions;
  });

  return {
    subTypeData: newSubtypes,
    subTypeOptions: newSubTypeOptions,
  };
};

export const ReloadDataSyncerButtonComponent = ({
  formid,
  resultId,
  ts,
  ts_started,
}) => {
  const [state, setState] = React.useState({});

  const handleSyncData = () => {
    Store.dispatch(
      change(formid, 'option_fetching', 'refetch-cache-in-backgroundable'),
    );
    setTimeout(() => {
      Store.dispatch(submit(formid));
    }, 0);
  };

  React.useEffect(
    () => {
      if (!ts_started) {
        if (ts && ts * 1000 < getStartTimeOfDay(new Date())) {
          handleSyncData();
        }

        return;
      }

      Store.dispatch(change(formid, 'option_fetching', null));

      const timeReload = 30 * 1000;
      if (state.formid === formid && state.resultId + timeReload > resultId) {
        return;
      }

      setState({ formid, resultId });

      setTimeout(() => Store.dispatch(submit(formid)), timeReload);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formid, resultId, state, setState, ts, ts_started],
  );

  if (!ts && !ts_started) {
    return null;
  }

  return (
    <div className="d-flex justify-content-end">
      <ReportProgressSyncerButton
        handleAction={handleSyncData}
        disabled={!!ts_started}
        syncingReport={!!ts_started}
        lastTimeSyncing={ts_started}
        timeFinishedSyncing={ts}
      />
    </div>
  );
};
