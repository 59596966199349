/* eslint-disable react/prop-types,no-undef,jsx-a11y/anchor-is-valid */
import React from 'react';

class ModulePermissionSchemaLayout extends React.PureComponent {
  h3Style = {
    margin: 0,
    background: 'white',
    color: 'red',
    textAlign: 'center',
  };

  divStyle = { textAlign: 'center' };

  render() {
    const { groups, readOnly } = this.props;

    if (readOnly) {
      //submitButton = null;
    }

    return (
      <div>
        {groups.default.fieldNames ? (
          <>
            {groups.default.fieldNames.accessible}
            {groups.default.fieldNames.allowed_actions}
            {groups.default.fieldNames.allowed_actions_on_user_own_resources}
          </>
        ) : null}
      </div>
    );
  }
}

export default ModulePermissionSchemaLayout;
