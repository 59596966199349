import React from 'react';
import Heading from '../../common/Heading';
import Content from '../../common/Content';
import DisplayHtml from 'components/common/html';

const ListOrderedText = ({ item }) => {
  if (item.block_list && Array.isArray(item.block_list)) {
    return (
      <div className="block-list-container">
        <Heading item={item} />
        <Content item={item} />
        <div className="content ordered-list">
          <ol>
            {item.block_list.map((text, i) => (
              <li key={`list-${item.iid}-${i}`}>
                <span>{text.name}</span>
                {!!text.content && <DisplayHtml content={text.content} />}
              </li>
            ))}
          </ol>
        </div>
      </div>
    );
  }

  return null;
};

export default ListOrderedText;
