import { t1 } from 'translate';
import { convertListOfValuesIntoOptionsForFormElement } from 'common/utils/form';

export const plannedUsersAddMethods = {
  SELECT: 'select',
  SELECT_FROM_COT_CAN: 'select_from_cot_can', // select members from previous TP, e.g Cotcan module 2 members can be selected from module 1
  IMPORT: 'import',
};

export const specialTpTypes = {
  SPECIAL_TP_TYPE_TAPHUAN_SMART_COT_CAN: 'taphuan_smart_cot_can',
};

export const specialTpTypeToText = (type) => {
  switch (type) {
    case specialTpTypes.SPECIAL_TP_TYPE_TAPHUAN_SMART_COT_CAN:
      return t1('taphuan_smart_cot_can_training_plan');
  }
};

export const specialTpTypeOptions = ({ includeNone = true } = {}) => {
  let opts = convertListOfValuesIntoOptionsForFormElement(
    Object.values(specialTpTypes),
    specialTpTypeToText,
  );

  if (includeNone) {
    opts = [
      {
        value: '',
        label: t1('normal_training_plan'),
      },
      ...opts,
    ];
  }

  return opts;
};
