const schema = (materials) => {
  return {
    program_iid: {
      type: 'radio',
      options: materials || [],
    },
  };
};

const ui = () => {
  return [
    {
      id: 'default',
      fields: ['program_iid'],
    },
  ];
};

export default (materials) => ({
  schema: () => schema(materials),
  ui,
});
