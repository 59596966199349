import { getUrl } from 'routes/links/common';
import { t1, t2, t3 } from 'translate';
import { adminMenuItems } from 'configs/constants/menu';
import { isAmTinh, isSupport } from 'components/admin/user/utils';
import Perm from 'common/utils/Perm';
import { useCloudContest } from 'utils/Util';
import { generateAdminCloudContestUrl } from 'common/utils/url';
import lodashGet from 'lodash.get';

/**
 * IMPORTANT Don't change the ids of the submenu
 * Also whenever you add a new menu item here, remember to configure it in School_Model_School::getInstance()->allAdminMenuItemsComplexSettings
 * as well
 *
 * @type {*[]}
 */

const getOrganizationsAndDepartmentsMenu = (loggedInUser = null) => {
  let url = getUrl('organization');
  const orgIid = lodashGet(loggedInUser, 'organizations[0].iid');

  if (window.isETEP && !!orgIid) {
    url = getUrl('node_edit', lodashGet(loggedInUser, 'organizations[0]'));
  }

  return {
    id: adminMenuItems.ORGANIZATIONS,
    url,
    title: t1('organizations_and_departments'),
    icon: {
      position: 'left',
      type: 'cluster',
    },
  };
};

const isOrgEnabledFeaturesByOrganization = (orgIid, schoolConfig) => {
  const enabledOrgIids = lodashGet(
    schoolConfig,
    'org_iids_for_enabled_features_by_organization',
    [],
  ).map((orgIidString) => parseInt(orgIidString));

  return enabledOrgIids.includes(orgIid);
};

const enabledFeaturesByOrganization = (loggedInUser, schoolConfig) => {
  if (
    isSupport(loggedInUser) ||
    Perm.hasPerm('root') ||
    (isAmTinh(loggedInUser) &&
      isOrgEnabledFeaturesByOrganization(
        lodashGet(loggedInUser, 'user_organizations.0'),
        schoolConfig,
      ))
  ) {
    const url = getUrl('enabled-features-by-organization');

    return {
      id: adminMenuItems.ENABLED_FEATURES_BY_ORGANIZATION,
      url,
      title: t1('enabled_features_by_organization'),
      icon: {
        position: 'left',
        type: 'appstore',
      },
    };
  }

  return null;
};

const adminMenu = (loggedInUser, schoolConfig) => {
  return [
    {
      id: adminMenuItems.HOME,
      url: '/admin',
      title: t2('admin_home'),
      icon: {
        position: 'left',
        type: 'home',
      },
    },
    {
      id: 'bdtx',
      title: t1('organizating_bdtx'),
      icon: {
        position: 'left',
        type: 'read',
      },
      subMenu: [
        {
          id: adminMenuItems.BDTX_MODULE,
          url: getUrl('bdtx/module'),
          title: t1('module_management'),
          icon: {
            position: 'left',
            type: 'read',
          },
        },
        {
          id: adminMenuItems.REVIEW_PROGRAM,
          url: getUrl('review-program'),
          title: t1('program_approval'),
          icon: {
            position: 'left',
            type: 'book',
          },
        },
        {
          id: adminMenuItems.REVIEW_EXAM_STORE,
          url: getUrl('review-exam-store'),
          title: t1('exam_approval'),
          icon: {
            position: 'left',
            type: 'book',
          },
        },
        {
          id: adminMenuItems.BDTX_CHOOSE_MODULES_FOR_YEAR,
          url: getUrl('bdtx/choose-modules'),
          title: t1('modules_of_school_year'),
          icon: {
            position: 'left',
            type: 'book',
          },
        },
        {
          id: adminMenuItems.BDTX_BUY_MATERIALS,
          url: getUrl('bdtx/deal'),
          title: t1('learning_material_deals'),
          icon: {
            position: 'left',
            type: 'book',
          },
        },
        {
          id: adminMenuItems.BDTX_OVERVIEW_MODULE_MATERIALS,
          url: getUrl('bdtx/overview-module-materials'),
          title: t1('learning_material_overview'),
          icon: {
            position: 'left',
            type: 'book',
          },
        },
        {
          id: adminMenuItems.BDTX_ASSIGN_MODULES,
          url: getUrl('bdtx/training-phase'),
          title: t1('training_phases'),
          icon: {
            position: 'left',
            type: 'book',
          },
        },
        {
          id: adminMenuItems.BDTX_KPI,
          url: getUrl('bdtx/kpi'),
          title: t1('kpi_progress'),
          icon: {
            position: 'left',
            type: 'book',
          },
        },
        {
          id: adminMenuItems.BDTX_MIGRATE_ETEP_TO_BDTX,
          url: getUrl('bdtx/migrate-etep'),
          title: t1('data_migration'),
          icon: {
            position: 'left',
            type: 'book',
          },
        },
      ],
    },
    {
      id: 'academic_materials',
      title: t3('academic_materials'),
      icon: {
        position: 'left',
        type: 'read',
      },
      subMenu: [
        {
          id: adminMenuItems.CREDIT,
          url: getUrl('credit'),
          title: t1('subjects'),
          icon: {
            position: 'left',
            type: 'read',
          },
        },
        {
          id: adminMenuItems.SYLLABUS,
          url: getUrl('syllabus'),
          title: t1('syllabus'),
          icon: {
            position: 'left',
            type: 'read',
          },
        },
        {
          id: adminMenuItems.PROGRAM,
          url: getUrl('program'),
          title: t1('programs'),
          icon: {
            position: 'left',
            type: 'book',
          },
        },
      ],
    },
    {
      id: 'training',
      title: t3('training'),
      icon: {
        position: 'left',
        type: 'highlight',
      },
      subMenu: [
        {
          id: adminMenuItems.TRAINING_PLAN,
          url: getUrl('training-plan'),
          title: t1('training_plans'),
          icon: {
            position: 'left',
            type: 'schedule',
          },
        },
        {
          id: adminMenuItems.TRAINING_PLAN_QUOTA,
          url: getUrl('tp-quota'),
          title: t1('training_plan_quota'),
          icon: {
            position: 'left',
            type: 'schedule',
          },
        },
        {
          id: adminMenuItems.TRAINING_PLAN_QUOTA_MULTIPLE_MODULES,
          url: getUrl('tp-quota-multiple-modules'),
          title: t1('training_plan_quota_multiple_modules'),
          icon: {
            position: 'left',
            type: 'schedule',
          },
        },
        {
          id: adminMenuItems.LICENSE_LEARNING,
          url: getUrl('license/report'),
          title: t1('licenses'),
          icon: {
            position: 'left',
            type: 'schedule',
          },
        },
        {
          id: adminMenuItems.ENROLMENT_PLAN,
          url: getUrl('enrolment-plan'),
          title: t1('enrolment_plans'),
          icon: {
            position: 'left',
            type: 'schedule',
          },
        },
        {
          id: adminMenuItems.SCHOOL_GROUP,
          url: getUrl(adminMenuItems.SCHOOL_GROUP),
          title: t1('school_cluster_distribution'),
          icon: {
            position: 'left',
            type: 'schedule',
          },
        },

        {
          id: adminMenuItems.BANK,
          url: getUrl('bank', { type: 'question' }),
          title: t1('bank'),
          icon: {
            position: 'left',
            type: 'bank',
          },
        },
        {
          id: adminMenuItems.SEMESTER,
          url: getUrl('semester'),
          title: t1('semesters_&_school_years'),
          icon: {
            position: 'left',
            type: 'calendar',
          },
        },

        {
          id: adminMenuItems.TIMETABLE,
          url: getUrl('timetable'),
          title: t1('timetable'),
          icon: {
            position: 'left',
            type: 'table',
          },
        },
        {
          id: adminMenuItems.SCHEDULE_OVERVIEW,
          url: getUrl('schedule-overview'),
          title: t1('schedule_overview'),
          icon: {
            position: 'left',
            type: 'table',
          },
        },
        {
          id: adminMenuItems.MY_SYLLABUS,
          url: getUrl('my-syllabus'),
          icon: {
            position: 'left',
            type: 'fund',
          },
          title: t1('my_syllabus_menu'),
        },
        {
          id: adminMenuItems.COURSE,
          url: getUrl('course'),
          icon: {
            position: 'left',
            type: 'fund',
          },
          title: t1('courses_menu'),
        },
        {
          id: adminMenuItems.OTHER_CERTIFICATES,
          url: getUrl('other-certificates'),
          icon: {
            position: 'left',
            type: 'fund',
          },
          title: t1('other_certificates_menu'),
        },
        {
          id: adminMenuItems.TEACHER_DASHBOARD,
          url: getUrl('teacher'),
          icon: {
            position: 'left',
            type: 'reconciliation',
          },
          title: t1('teacher_dashboard'),
        },
        {
          id: adminMenuItems.MASTER_TEACHER,
          url: getUrl('master-teacher'),
          icon: {
            position: 'left',
            type: 'reconciliation',
          },
          title: t1('master_teacher'),
        },
        {
          id: adminMenuItems.TRAINING_HOMEWORK,
          url: getUrl('training-homework'),
          title: t1('training_homework'),
          icon: {
            position: 'left',
            type: 'file-text',
          },
        },
        {
          id: adminMenuItems.STUDENT_FEEDBACK,
          url: getUrl('feedback/student'),
          title: t1('student_feedback'),
          icon: {
            position: 'left',
            type: 'exclamation-circle',
          },
        },
        {
          id: adminMenuItems.TEACHER_FEEDBACK,
          url: getUrl('feedback/teacher'),
          title: t1('teacher_feedback'),
          icon: {
            position: 'left',
            type: 'exclamation-circle',
          },
        },
        {
          id: adminMenuItems.TEACHER_QA,
          url: getUrl('feedback/qa'),
          title: t1('students_questions'),
          icon: {
            position: 'left',
            type: 'exclamation-circle',
          },
        },
        {
          id: adminMenuItems.ORGANIZATION_LEARNING,
          url: getUrl('organization-learning'),
          title: t1(adminMenuItems.ORGANIZATION_LEARNING),
          icon: {
            position: 'left',
            type: 'exclamation-circle',
          },
        },
        {
          id: adminMenuItems.TIME_SHEET,
          url: getUrl('time-sheet/specialized-work'),
          title: t1('time_sheet'),
          icon: {
            position: 'left',
            type: 'clock-circle',
          },
        },
        {
          id: adminMenuItems.REPORT,
          url: window.isETEP
            ? '/admin/report/etep/dashboard'
            : getUrl('report/dashboard'),
          title: t1('reports'),
          icon: {
            position: 'left',
            type: 'bar-chart',
          },
        },
      ],
    },
    {
      id: 'temis',
      title: t3('temis'),
      icon: {
        position: 'left',
        type: 'file-text',
      },
      subMenu: [
        {
          id: adminMenuItems.TEMIS,
          url: getUrl('temis'),
          title: 'Báo cáo TEMIS',
          icon: {
            position: 'left',
            type: 'bar-chart',
          },
        },
        {
          id: adminMenuItems.ASSESSMENT_EVIDENCE_TEMPLATE,
          url: getUrl('assessment-evidence-template'),
          title: t1('assessment_evidence_template'),
          icon: {
            position: 'left',
            type: 'file-text',
          },
        },
      ],
    },
    {
      id: 'planning',
      title: t3('academic_planning'),
      icon: {
        position: 'left',
        type: 'deployment-unit',
      },
      subMenu: [
        // {
        //   id: adminMenuItems.ICO,
        //   url: getUrl('ico'),
        //   title: t1('incoming_classes'),
        //   icon: {
        //     position: 'left',
        //     type: 'contacts',
        //   },
        // },
        // {
        //   id: adminMenuItems.GOAL,
        //   url: getUrl('goal'),
        //   title: t1('goals'),
        //   icon: {
        //     position: 'left',
        //     type: 'trophy',
        //   },
        // },
        // {
        //   id: adminMenuItems.PLAN,
        //   url: getUrl('plan'),
        //   title: t1('teaching_plans'),
        //   icon: {
        //     position: 'left',
        //     type: 'schedule',
        //   },
        // },
        {
          id: adminMenuItems.CLASSGROUP,
          url: getUrl('classgroup'),
          title: t1('class_groups'),
          icon: {
            position: 'left',
            type: 'appstore',
          },
        },
      ],
    },
    {
      id: 'department_of_education_and_student_affairs',
      title: t3('department_of_education_and_student_affairs'),
      icon: {
        position: 'left',
        type: 'gateway',
      },
      subMenu: [
        {
          id: adminMenuItems.PROCESSING_REQUESTS,
          url: '/admin/request',
          title: t1('processing_requests'),
          icon: {
            position: 'left',
            type: 'ordered-list',
          },
        },
        {
          id: adminMenuItems.REQUEST_TYPE_MANAGE,
          url: '/admin/req-type',
          title: t1('request_type_manage'),
          icon: {
            position: 'left',
            type: 'ordered-list',
          },
        },
      ],
    },
    // {
    //   id: adminMenuItems.FINANCIAL,
    //   url: getUrl('financial'),
    //   title: t3('financial_management'),
    //   icon: {
    //     position: 'left',
    //     type: 'dollar',
    //   },
    // },
    {
      id: 'exam',
      title: t3('testing'),
      icon: {
        position: 'left',
        type: 'safety',
      },
      subMenu: [
        {
          id: adminMenuItems.CONTEST,
          // eslint-disable-next-line react-hooks/rules-of-hooks
          url: useCloudContest()
            ? generateAdminCloudContestUrl(loggedInUser)
            : getUrl('contest'),
          // eslint-disable-next-line react-hooks/rules-of-hooks
          externalLink: useCloudContest(),
          // eslint-disable-next-line react-hooks/rules-of-hooks
          target: useCloudContest() ? '_blank' : '',
          title: t1('contests'),
          icon: {
            position: 'left',
            type: 'project',
          },
        },
        // eslint-disable-next-line react-hooks/rules-of-hooks
        ...(useCloudContest()
          ? []
          : [
              {
                id: adminMenuItems.EXAM_TEMPLATE,
                url: getUrl('exam-template'),
                title: t1('exam_template'),
                icon: {
                  position: 'left',
                  type: 'file-text',
                },
              },
              {
                id: adminMenuItems.QUESTION_BANK,
                url: getUrl('question-bank'),
                title: t1('question_bank'),
                icon: {
                  position: 'left',
                  type: 'bank',
                },
              },
            ]),
      ],
    },
    {
      id: 'school',
      title: t3('manager'),
      icon: {
        position: 'left',
        type: 'cluster',
      },
      subMenu: [
        {
          id: adminMenuItems.ACCOUNT,
          url: getUrl('school/accounts'),
          title: t1('accounts'),
          icon: {
            position: 'left',
            type: 'user',
          },
        },
        {
          id: adminMenuItems.USER_MANAGE,
          url: getUrl('school/users'),
          title: t1('users'),
          icon: {
            position: 'left',
            type: 'team',
          },
        },
        {
          id: adminMenuItems.COTCAN_MANAGE,
          url: getUrl('school/cotcan'),
          title: t1('cotcan'),
          icon: {
            position: 'left',
            type: 'team',
          },
        },
        {
          id: adminMenuItems.TEACHER,
          url: getUrl('school/teachers'),
          title: t1('trainers'),
          icon: {
            position: 'left',
            type: 'user',
          },
        },
        {
          id: adminMenuItems.PARENT,
          url: getUrl('school/parents'),
          title: t1('parents'),
          icon: {
            position: 'left',
            type: 'user',
          },
        },
        {
          id: adminMenuItems.GROUP_MANAGE,
          url: getUrl('group'),
          title: t1('groups'),
          icon: {
            position: 'left',
            type: 'usergroup-add',
          },
        },
        {
          id: adminMenuItems.SKILL,
          url: getUrl('skill'),
          title: t1('skills'),
          icon: {
            position: 'left',
            type: 'bars',
          },
        },
        getOrganizationsAndDepartmentsMenu(loggedInUser),
        enabledFeaturesByOrganization(loggedInUser, schoolConfig),
        // {
        //   id: adminMenuItems.USER_MAJORS,
        //   url: getUrl('user-major'),
        //   title: t1('user_majors'),
        //   icon: {
        //     position: 'left',
        //     type: 'team',
        //   },
        // },

        {
          id: adminMenuItems.USER_GROUP_CATEGORY,
          url: getUrl('user-group-category'),
          title: t1('user_group_categories'),
          icon: {
            position: 'left',
            type: 'tags',
          },
        },
        {
          id: adminMenuItems.ASSET,
          url: getUrl('asset/items'),
          title: t1('assets'),
          icon: {
            position: 'left',
            type: 'database',
          },
        },
        {
          id: adminMenuItems.BUDGETARY,
          url: getUrl('budgetary-allocations'),
          title: t1('budgetary_allocations'),
          icon: {
            position: 'left',
            type: 'dashboard',
          },
        },
      ].filter(Boolean),
    },
    // {
    //   id: 'graduation',
    //   title: t3('graduation'),
    //   icon: {
    //     position: 'left',
    //     type: 'file-done',
    //   },
    //   subMenu: [
    //     {
    //       id: adminMenuItems.ADMISSION,
    //       url: getUrl('admission'),
    //       title: t1('admission_group'),
    //       icon: {
    //         position: 'left',
    //         type: 'safety-certificate',
    //       },
    //     },
    //     {
    //       id: adminMenuItems.STUDENT_RECOGNITION,
    //       url: getUrl('student-recognition'),
    //       title: t1('student_recognition'),
    //       icon: {
    //         position: 'left',
    //         type: 'safety-certificate',
    //       },
    //     },
    //     {
    //       id: adminMenuItems.FINISHING_SENIOR,
    //       url: getUrl('finishing-senior'),
    //       title: t1('finishing_senior'),
    //       icon: {
    //         position: 'left',
    //         type: 'safety-certificate',
    //       },
    //     },
    //     {
    //       id: adminMenuItems.GRADUATING_SENIOR,
    //       url: getUrl('graduating-senior'),
    //       title: t1('graduating_senior'),
    //       icon: {
    //         position: 'left',
    //         type: 'star',
    //       },
    //     },
    //     {
    //       id: adminMenuItems.EXPULSION_GROUP,
    //       url: getUrl('expulsion-group'),
    //       title: t1('expulsion_group'),
    //       icon: {
    //         position: 'left',
    //         type: 'safety-certificate',
    //       },
    //     },
    //   ],
    // },
    {
      id: 'blogs',
      title: t3('blogs_&_events'),
      icon: {
        position: 'left',
        type: 'global',
      },
      subMenu: [
        {
          id: adminMenuItems.EVENT,
          url: getUrl('event'),
          title: t1('event'),
          icon: {
            position: 'left',
            type: 'schedule',
          },
        },
      ],
    },
    {
      id: 'other',
      title: t3('other'),
      icon: {
        position: 'left',
        type: 'gold',
      },
      subMenu: [
        {
          id: adminMenuItems.SUPPORT,
          url: getUrl('support'),
          title: t1('support'),
          icon: {
            position: 'left',
            type: 'customer-service',
          },
        },
        {
          id: adminMenuItems.MENU_ITEM_IT_SYNCDAT_FOR_CSDLN,
          url: getUrl('syncdata-user-organization-for-csdln'),
          title: t1('update_teachers_info_to_csdln'),
          icon: {
            position: 'left',
            type: 'exclamation-circle',
          },
        },

        {
          id: adminMenuItems.SURVEY,
          url: getUrl('survey'),
          title: t1('survey_management'),
          icon: {
            position: 'left',
            type: 'cluster',
          },
        },
        {
          id: adminMenuItems.MONITOR_USER_ACTION,
          url: getUrl('monitor/view-user-actions'),
          title: t1('monitor_user_action'),
          icon: {
            position: 'left',
            type: 'schedule',
          },
        },
        {
          id: adminMenuItems.PAGE,
          url: getUrl('page'),
          title: t1('page'),
          icon: {
            position: 'left',
            type: 'file',
          },
        },
      ],
    },
  ].filter(Boolean);
};

export default adminMenu;
