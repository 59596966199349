export const APPLICABLE_PROJECT = {
  ETEP: 'etep',
  BDTX: 'bdtx',
};

export const applicableProjectOptions = () => [
  {
    value: APPLICABLE_PROJECT.ETEP,
    label: 'ETEP',
  },
  {
    value: APPLICABLE_PROJECT.BDTX,
    label: 'BDTX',
  },
];

export const getApplicableProjectText = (value) => {
  const mapping = {
    [APPLICABLE_PROJECT.ETEP]: 'ETEP',
    [APPLICABLE_PROJECT.BDTX]: 'BDTX',
  };

  return mapping[value];
};
