import React from 'react';
import { t1 } from 'translate';
import CommonAntdTable from 'components/common/antd/table';

const AnswerTable = ({ dataSource = [] }) => {
  const columns = [
    {
      title: t1('question'),
      render: (row) => {
        const { content = '' } = row;
        return content;
      },
    },
    {
      title: t1('answer'),
      render: (row) => {
        const { answer = '' } = row;
        return answer;
      },
    },
  ];
  return (
    <div>
      <h3>{t1('answer')}</h3>
      <CommonAntdTable
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowKey="iid"
        className="learn-default"
      />
    </div>
  );
};

export default AnswerTable;
