import React from 'react';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import endpoints from 'components/bdtx/report/endpoints';
import Results from './Results';
import schema from './schema';

const DetailNcbdtxByModule = () => {
  const renderNoResultComponent = () => {
    return <SimpleNoResult text="Chưa có dữ liệu" />;
  };

  const renderResultComponent = (
    items,
    props,
    objects,
    searchValues,
    resultId,
  ) => {
    if (!Array.isArray(items) || !items.length) {
      return renderNoResultComponent();
    }

    return (
      <Results
        usersChosenNcbdtx={items}
        searchValues={searchValues}
        resultId={resultId}
      />
    );
  };

  return (
    <SearchWrapper
      formid="detail_ncbdtx_by_module"
      schema={schema}
      renderResultsComponent={renderResultComponent}
      renderNoResultComponent={renderNoResultComponent}
      alternativeApi={endpoints.details_ncbdtx_by_module}
      autoSearchWhenStart={true}
    />
  );
};

export default DetailNcbdtxByModule;
