import React from 'react';
import lodashGet from 'lodash.get';
import Dropdown from 'antd/lib/dropdown';
import Menu from 'antd/lib/menu';
import Tooltip from 'antd/lib/tooltip';
import Alert from 'antd/lib/alert';
import { t1 } from 'translate';
import Icon from 'components/common/Icon';

const ChooseScormPlayerLink = ({ scormPlayerLinks, setScormPlayerLink }) => {
  const [visible, setVisible] = React.useState(true);
  const [versionIndex, setVersionIndex] = React.useState('0');

  React.useEffect(
    () => {
      setScormPlayerLink(lodashGet(scormPlayerLinks, versionIndex));
    },
    [scormPlayerLinks, setScormPlayerLink, versionIndex],
  );

  if (
    !Array.isArray(scormPlayerLinks) ||
    scormPlayerLinks.length <= 1 ||
    !visible
  ) {
    return null;
  }

  return (
    <Alert
      style={{
        position: 'absolute',
      }}
      message={
        <Tooltip title={t1('guide_choose_scorm_player')} defaultVisible>
          <Dropdown.Button
            type="ghost"
            className="m-r-10"
            overlay={
              <Menu
                onClick={(event) => setVersionIndex(lodashGet(event, 'key'))}
              >
                {scormPlayerLinks.map((scormPlayerLink, index) => {
                  return (
                    <Menu.Item key={index} disabled={index == versionIndex}>
                      <Icon icon="player" />
                      {t1(`scorm_player_version_${index + 1}`)}
                    </Menu.Item>
                  );
                })}
              </Menu>
            }
          >
            {t1(`scorm_player_version_${parseInt(versionIndex) + 1}`)}
          </Dropdown.Button>
        </Tooltip>
      }
      type="warning"
      closable
      afterClose={() => setVisible(false)}
    />
  );
};

export default ChooseScormPlayerLink;
