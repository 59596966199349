import React from 'react';
import lodashGet from 'lodash.get';
import { shortenPgdSgdName } from 'components/admin/group/common/UserPGDOrSGD';

const getSubType = (org) => {
  const subType = lodashGet(org, 'sub_type');
  if (!subType) {
    return subType;
  }
  return Number.parseInt(subType, 10);
};

/**
 * user info is usually expanded with organization and job positions
 * We'll extract them and display here in this component
 *
 * attr can be either 'organization' or 'positions' which were expanded
 */
const OrganizationsParent = ({
  item,
  attr,
  expandedAttr,
  wrapOrgInBlock,
  parentSubTypesToShow,
}) => {
  const iids = lodashGet(item, attr, []);
  const objects = expandedAttr || lodashGet(item, '__expand.' + attr, []);

  if (parentSubTypesToShow) {
    parentSubTypesToShow = parentSubTypesToShow.map((subType) =>
      Number.parseInt(subType, 10),
    );
  }

  return (
    !!objects.length && (
      <div>
        {iids.map((iid, idx) => {
          const item = objects.find((o) => o && String(o.iid) === String(iid));
          if (!item) {
            return null;
          }

          let { parents = [] } = item;

          if (parentSubTypesToShow) {
            parents = parents
              .filter((p) => parentSubTypesToShow.includes(getSubType(p)))
              .sort((a, b) => {
                return (
                  parentSubTypesToShow.indexOf(getSubType(a)) -
                  parentSubTypesToShow.indexOf(getSubType(b))
                );
              });
          }

          if (!parents.length) {
            return null;
          }

          return (
            <div key={`org-el-${iid}`}>
              <div key="equivalent">
                {wrapOrgInBlock
                  ? parents.map((p) => (
                      <div>{shortenPgdSgdName(lodashGet(p, 'name'))}</div>
                    ))
                  : parents
                      .map((p) => shortenPgdSgdName(lodashGet(p, 'name')))
                      .join(' / ')}
              </div>
            </div>
          );
        })}
      </div>
    )
  );
};

export default OrganizationsParent;
