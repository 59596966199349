import React from 'react';
import { t1 } from 'translate';
import { Modal } from 'antd';
import Icon from 'antd/lib/icon';
import Tooltip from 'antd/lib/tooltip';
import { isSmallScreen } from 'common';
import './style.scss';

const MessageForQuestionInVideo = () => {
  const onClick = () => {
    const config = {
      centered: true,
      title: t1('question_in_video'),
      content: t1('the_teacher_watch_and_answer_the_questions_in_video'),
    };
    Modal.info(config);
  };

  return (
    <span
      className="message-for-question-in-video cursor-pointer d-inline-flex align-items-center"
      onClick={onClick}
    >
      {!isSmallScreen && (
        <span className="m-r-5">{t1('question_in_video')}</span>
      )}
      <Tooltip title={t1('question_in_video')}>
        <Icon type="question-circle" />
      </Tooltip>
    </span>
  );
};

export default MessageForQuestionInVideo;
