import lodashGet from 'lodash.get';
import Tabs from 'antd/lib/tabs';
import PlannedUsers from 'components/bdtx/training-phase/mainstage/training-model-cot-can/planned-user/approved-planned-users';
import UnApprovedPlannedUsers from 'components/bdtx/training-phase/mainstage/training-model-cot-can/planned-user/unapproved-planned-users';
import { t1 } from 'translate';
import QuickAddPlannedUsers from 'components/bdtx/training-phase/mainstage/training-model-cot-can/planned-user/quick-add-planned-users';
import fetchData from 'components/common/fetchData';
import apiUrls from 'api-endpoints';
import { editingSyllabusMode } from 'configs/constants';
import { compose } from 'redux';
import withUserOrganizations from 'common/hoc/withUserOrganizations';
import React from 'react';

const TrainingPlanQuotaManage = ({ trainingPlanIid, trainingPlan }) => {
  return (
    <>
      <Tabs defaultActiveKey={'planned_users'} destroyInactiveTabPane>
        {/* TODO: implement this tab
          <Tabs.TabPane tab={'Tổng quan số lượng GVCC'} key="overview">
            <Overview trainingPlanIid={trainingPlanIid} />
          </Tabs.TabPane>
*/}

        <Tabs.TabPane tab={'Danh sách chính thức'} key="planned_users">
          <PlannedUsers trainingPlanIid={trainingPlanIid} />
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={'Danh sách chờ duyệt'}
          key="unapproved_planned_users"
        >
          <UnApprovedPlannedUsers trainingPlan={trainingPlan} />
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={t1('quick_add_planned_users')}
          key="quick_add_planned_users"
        >
          <QuickAddPlannedUsers trainingPlan={trainingPlan} />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

const fetchTrainingPlan = fetchData((props) => {
  const trainingPlanIid = lodashGet(props, 'trainingPlanIid');

  return {
    baseUrl: apiUrls.fetch_node,
    params: {
      ntype: 'training_plan',
      depth: 1,
      iid: trainingPlanIid,
      editing_syllabus: editingSyllabusMode.GET_TO_REVIEW,
    },
    propKey: 'trainingPlan',
    shouldRenderLoading: true,
  };
});

export default compose(
  withUserOrganizations,
  fetchTrainingPlan,
)(TrainingPlanQuotaManage);
