import { t1 } from 'translate/index';

const iidMultiple = ({ label = t1('user_iids') } = {}) => ({
  type: 'text',
  multiLine: true,
  hintText: `001, 002, ... ${t1('separated_by_comma_or_new_line')}`,
  floatingLabelText: label,
});

export default iidMultiple;
