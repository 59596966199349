import sagaActions from 'actions/node/saga-creators';
import Radio from 'antd/lib/radio';
import AntdTable from 'antd/lib/table';
import { timestampToDateString } from 'common/utils/Date';
import Warning from 'components/common/Warning';
import { attenStatus } from 'configs/constants';
import get from 'lodash.get';
import lodashIncludes from 'lodash/includes';
import React, { Component } from 'react';
import { t1 } from 'translate';
import './styles.scss';
import { connect } from 'react-redux';
import { canJoinToLearn, joinStatuses } from '../utils';
import { secondsToTimeString } from 'common/utils/Date';
import RegisterAttendaceTypes from './RegisterAttendaceTypes';
class ResultsDialogUni extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      users = [],
      session = {},
      server_ts,
      automaticScaleVirtualClassEnabled,
      viewMode,
      foreHideSystemAttendance = false,
    } = this.props;

    const CenterText = (props) => {
      const { children } = props;
      return <div className="d-flex justify-content-center">{children}</div>;
    };

    if (!Array.isArray(users) || !users.length) {
      return (
        <div>
          <Warning>{t1('there_are_no_students_enrolled_the_course')}</Warning>
        </div>
      );
    }

    const disableChangeAttendance = () => {
      if (viewMode) {
        return true;
      }
      const status = canJoinToLearn(
        session,
        session.pre_class_time,
        server_ts,
        automaticScaleVirtualClassEnabled ||
          session.automaticScaleVirtualClassEnabled,
      );

      return status !== joinStatuses.JOIN_STATUS_OK;
    };

    const collectData = (collection) => {
      const {
        course,
        user,
        attendanceStatus,
        type = RegisterAttendaceTypes.TYPE_DATE_FOR_USER,
      } = collection;
      const data = {
        status: attendanceStatus,
        user_iid: user && user.iid,
      };

      return {
        type,
        course_iid: get(course, 'course_iid'),
        ntype: 'session',
        attendance: data,
        iid: get(course, 'iid'),
        id: get(course, 'id'),
      };
    };

    const submitAttendance = (data) => {
      const { dispatch, callbackAttendanceChange } = this.props;
      dispatch(
        sagaActions.updateNodeRequest({
          step: 'attendance',
          data: data,
          requestSuccessful: () => {
            callbackAttendanceChange && callbackAttendanceChange();
          },
          requestFailedCallback: () => {
            this.forceUpdate();
          },
        }),
      );
    };

    const isShowAutoSystemAttendance =
      window.show_joined_history_virtual_class === 1 &&
      !foreHideSystemAttendance;

    const columnAttendanceStatus = (data) => {
      const { absent = [], allowed_leaves = [], attendance = [] } = data;
      const collectDataAndSubmitAttendance = (
        attendanceStatus,
        user,
        data,
        type,
      ) => {
        const dataSubmit = collectData({
          attendanceStatus,
          user,
          course: data,
          type,
        });
        submitAttendance(dataSubmit);
      };

      const onChangeAttendance = (e, user, attendanceStatus, type) => {
        if (!e.nativeEvent.target.checked) {
          return;
        }
        collectDataAndSubmitAttendance(attendanceStatus, user, data, type);
      };

      const getDefaultCheckedAttendance = (userIid) => {
        return lodashIncludes(attendance, userIid);
      };

      const getDefaultCheckedAbsentHasPermission = (userIid) => {
        return lodashIncludes(allowed_leaves, userIid);
      };

      const getDefaultCheckedAbsentNoPermission = (userIid) => {
        return lodashIncludes(absent, userIid);
      };

      const HeaderAttendance = (props) => {
        const { title, status } = props;
        return (
          <div className="d-flex-column align-items-center justify-content-center">
            <p>{title}</p>
            <Radio
              disabled={disableChangeAttendance()}
              onChange={(e) =>
                onChangeAttendance(
                  e,
                  null,
                  status,
                  RegisterAttendaceTypes.TYPE_SESSION_FOR_ALL_USER,
                )
              }
            />
          </div>
        );
      };

      let columns = [
        {
          title: () => (
            <HeaderAttendance
              status={attenStatus.ATTENDANCE_ATTENDED_STATUS}
              title={t1('be_attendance')}
            />
          ),
          align: 'center',
          key: 'attendance',
          render: (text, row) => {
            return (
              <Radio
                disabled={disableChangeAttendance()}
                onChange={(e) =>
                  onChangeAttendance(
                    e,
                    row,
                    attenStatus.ATTENDANCE_ATTENDED_STATUS,
                  )
                }
                checked={getDefaultCheckedAttendance(row.iid)}
              />
            );
          },
        },
        {
          title: t1('absent'),
          children: [
            {
              title: () => (
                <HeaderAttendance
                  status={attenStatus.ATTENDANCE_ALLOWED_LEAVE_STATUS}
                  title={t1('absent_has_permission')}
                />
              ),
              key: 'absent_has_permission',
              align: 'center',
              render: (text, row) => {
                return (
                  <Radio
                    disabled={disableChangeAttendance()}
                    onChange={(e) =>
                      onChangeAttendance(
                        e,
                        row,
                        attenStatus.ATTENDANCE_ALLOWED_LEAVE_STATUS,
                      )
                    }
                    checked={getDefaultCheckedAbsentHasPermission(row.iid)}
                  />
                );
              },
            },
            {
              title: () => (
                <HeaderAttendance
                  status={attenStatus.ATTENDANCE_ABSENTED_STATUS}
                  title={t1('absent_no_permission')}
                />
              ),
              key: 'absent_no_permission',
              align: 'center',
              render: (text, row) => {
                return (
                  <Radio
                    disabled={disableChangeAttendance()}
                    onChange={(e) =>
                      onChangeAttendance(
                        e,
                        row,
                        attenStatus.ATTENDANCE_ABSENTED_STATUS,
                      )
                    }
                    checked={getDefaultCheckedAbsentNoPermission(row.iid)}
                  />
                );
              },
            },
          ],
        },
      ];
      const columnsViewMode = [
        {
          title: t1('attendance_teacher_signing'),
          children: columns,
        },
      ];

      return viewMode ? columnsViewMode : columns;
    };

    const getTimeJoined = (time) => {
      if (!time) return '';
      return timestampToDateString(time, { showTime: true });
    };

    const columnAttendanceAutoSystem = isShowAutoSystemAttendance
      ? [
          {
            title: t1('attendance_auto_system_info'),
            children: [
              {
                title: t1('join_time'),
                key: 'join_time',
                align: 'center',
                render: (text, row) => {
                  return getTimeJoined(get(row, 'join_time', 0));
                },
              },
              {
                title: t1('left_time'),
                key: 'left_time',
                align: 'center',
                render: (text, row) => {
                  return getTimeJoined(get(row, 'left_time', 0));
                },
              },
              {
                title: t1('duration_hour_minute_second'),
                key: 'duration',
                align: 'center',
                render: (text, row) => {
                  const { duration = 0 } = row;
                  return duration > 0 ? secondsToTimeString(duration) : '';
                },
              },
              {
                title: t1('number_of_leave'),
                key: 'number_of_leave',
                align: 'center',
                render: (text, row) => {
                  const { number_of_leave = 0 } = row;
                  return number_of_leave;
                },
              },
            ],
          },
          {
            title: t1('attendance_result'),
            key: 'attendance_result',
            align: 'center',
            render: (text, row) => {
              const { attendance_result = '' } = row;
              return attendance_result;
            },
          },
        ]
      : [];

    let columns = [
      {
        title: t1('surname_name'),
        key: 'iid',
        align: 'center',
        width: 150,
        render: (text, row) => {
          return row.name || '';
        },
      },
      {
        title: t1('code_phone_number'),
        key: 'phone',
        width: 200,
        align: 'center',
        render: (text, row) => {
          const { code, phone } = row;
          return `${code || ''}${!!row && !!phone ? ' /' : ''}${phone || ''}`;
        },
      },
      ...columnAttendanceStatus(session),
      ...columnAttendanceAutoSystem,
    ];

    return (
      <div style={{ background: 'white' }}>
        <AntdTable
          columns={columns}
          dataSource={users}
          pagination={false}
          bordered
          size="middle"
          scroll={{ x: 1000 }}
        />
      </div>
    );
  }
}

export default connect()(ResultsDialogUni);
