import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import DownloadAttachments from 'components/learn/viewer/download-attachments';

const DownloadDocumentButton = ({ learnItem }) => {
  return (
    <>
      {learnItem &&
        learnItem.download_materials &&
        learnItem.download_materials.length && (
          <DetailOnDialog
            renderPreview={({ showFull }) => (
              <button
                onClick={showFull}
                className="btn btn-primary btn-outline p-5"
              >
                {' '}
                <span className="ve-download" /> Tải tài liệu
              </button>
            )}
            renderFull={() => (
              <DownloadAttachments node={learnItem} showTitle />
            )}
          />
        )}
    </>
  );
};

export default DownloadDocumentButton;
