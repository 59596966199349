import {
  TARGET_USER,
  userGrades,
  userGradeOptions,
} from 'configs/constants/user';
import { CHUONG_TRINHS } from 'components/bdtx/configs';

export const formatChooseModuleData = (dataSource) => {
  const dataForGVPT = dataSource.filter(
    (item) => item.type === TARGET_USER.GVPT,
  );
  const dataForCBQL = dataSource.filter(
    (item) => item.type === TARGET_USER.CBQL,
  );
  const grades = Object.keys(userGrades).map((key) => userGrades[key]);

  let gvptData = {};
  let cbqlData = {};

  grades.forEach((grade) => {
    const modulesGVPTByGrade = dataForGVPT.find((item) => item.cap === grade);
    if (modulesGVPTByGrade && !gvptData[grade]) {
      gvptData[grade] = modulesGVPTByGrade.modules;
    }

    const modulesCBQLByGrade = dataForCBQL.find((item) => item.cap === grade);
    if (modulesCBQLByGrade && !cbqlData[grade]) {
      cbqlData[grade] = modulesCBQLByGrade.modules;
    }
  });

  return [gvptData, cbqlData];
};

export const generateTableDataSource = (modulesData = []) => {
  return CHUONG_TRINHS.map((chuongTrinh) => {
    const modulesDataByChuongTrinh = modulesData.filter(
      (moduleData) => moduleData.chuong_trinh == chuongTrinh,
    );
    const dataByCap = {};

    userGradeOptions().forEach((userGrade) => {
      const modulesDataByUserGrade = modulesDataByChuongTrinh.find(
        (data) => data.cap === userGrade.value,
      );

      dataByCap[userGrade.value] = modulesDataByUserGrade
        ? modulesDataByUserGrade.modules
        : [];
    });

    return {
      chuongTrinh,
      ...dataByCap,
    };
  });
};
