export const CHUONG_TRINH_01 = 1;
export const CHUONG_TRINH_02 = 2;
export const CHUONG_TRINH_03 = 3;

export const CHUONG_TRINHS = [
  CHUONG_TRINH_01,
  CHUONG_TRINH_02,
  CHUONG_TRINH_03,
];

export const CHUONG_TRINH_NAME = {
  [CHUONG_TRINH_01]: 'Chương trình 01',
  [CHUONG_TRINH_02]: 'Chương trình 02',
  [CHUONG_TRINH_03]: 'Chương trình 03',
};

export const getChuongTrinhName = (chuongTrinh, shortName) => {
  const ctName = CHUONG_TRINH_NAME[chuongTrinh];
  if (!ctName) {
    return 'Không xác định';
  }

  if (shortName) {
    return ctName.replace('Chương trình', 'CT');
  }

  return ctName;
};

export const chuongTrinhOptions = ({
  excludeChuongTrinhs = [],
  shortName = false,
}) => {
  return CHUONG_TRINHS.map((chuongTrinh) => {
    if (excludeChuongTrinhs.includes(chuongTrinh)) {
      return;
    }

    return {
      value: chuongTrinh,
      label: getChuongTrinhName(chuongTrinh, shortName),
    };
  }).filter(Boolean);
};
