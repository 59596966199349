import React from 'react';
import DateTimePicker from 'schema-form/elements/date-time-picker';
import { UiLibs } from 'configs/constants';
import subTypes from 'configs/constants/org-sub-types';
import {
  organizations,
  simpleOrganization,
} from 'components/admin/organization/schema/elements';
import { isBo } from 'components/admin/user/utils';

const schema = (formid, values, step, xpath, props) => {
  return {
    name: {
      type: 'text',
      floatingLabelText: 'Tìm theo tên',
      classWrapper: 'col-md-6',
    },
    code: {
      type: 'text',
      floatingLabelText: 'Tìm theo mã',
      classWrapper: 'col-md-6',
    },
    seller_organization_iid: simpleOrganization(
      {
        floatingLabelText: 'Đơn vị đã bán',
        classWrapper: 'col-md-6',
      },
      {
        value: {
          sub_type: [subTypes.TAPHUAN_SUBTYPE_LTTU],
        },
      },
      false,
      {
        value: '',
        label: 'Tất cả đơn vị',
      },
    ),
    start_date: {
      type: DateTimePicker,
      uiLib: UiLibs.ANT,
      floatingLabelText: 'Thời gian bắt đầu',
      fullWidth: true,
      classWrapper: 'col-md-6',
    },
    end_date: {
      type: DateTimePicker,
      uiLib: UiLibs.ANT,
      floatingLabelText: 'Thời gian kết thúc',
      fullWidth: true,
      classWrapper: 'col-md-6',
    },
    buyer_organization_iid: organizations({
      formid,
      multiple: false,
      label: 'Đơn vị mua học liệu',
      defaultValue: props.orgIids,
      subTypes: [
        subTypes.TAPHUAN_SUBTYPE_BO_GD,
        subTypes.TAPHUAN_SUBTYPE_SO_GD,
        subTypes.TAPHUAN_SUBTYPE_PHONG_GD,
        subTypes.TAPHUAN_SUBTYPE_LTTU,
      ],
      classWrapper: isBo(props.userInfo) ? 'col-md-6' : 'display-none',
    }),
  };
};

const ui = () => {
  return [
    {
      id: 'id',
      fields: [
        'name',
        'code',
        'buyer_organization_iid',
        'seller_organization_iid',
        'start_date',
        'end_date',
      ],
    },
  ];
};

export default {
  schema,
  ui,
};
