export const epLink = (item) => `/admin/enrolment-plan/${item.ep_iid}/courses`;
export const editUrl = (itemIid) => `/admin/group/${itemIid}/dashboard`;
export const editGroupDashboard = (itemIid, mode = 'admin') =>
  `/${mode}/group/${itemIid}/dashboard`;

export const editGroupInfo = (itemIid, mode = 'admin') =>
  `/${mode}/group/${itemIid}/info`;
export const editGroupMembers = (itemIid, mode = 'admin') =>
  `/${mode}/group/${itemIid}/members`;
export const editGroupTimekeeping = (itemIid, mode = 'admin') =>
  `/${mode}/group/${itemIid}/timekeeping`;
export const editGroupMembersNew = (itemIid, mode = 'admin') =>
  `/${mode}/group/${itemIid}/members/new`;
export const editGroupMembersImport = (itemIid, mode = 'admin') =>
  `/${mode}/group/${itemIid}/members/import`;
export const editGroupStaff = (itemIid, mode = 'admin') =>
  `/${mode}/group/${itemIid}/staff`;
export const editGroupRoles = (itemIid, mode = 'admin') =>
  `/${mode}/group/${itemIid}/roles`;
export const editGroupRolesNew = (itemIid, mode = 'admin') =>
  `/${mode}/group/${itemIid}/roles/new`;

export const editGroupLearningItems = (itemIid, mode = 'admin') =>
  `/${mode}/group/${itemIid}/learning-items`;
export const editGroupLearningItemsNew = (itemIid, mode = 'admin') =>
  `/${mode}/group/${itemIid}/learning-items/new`;
