import { t1 } from 'translate';
import { required } from 'common/validators';
import { unaccentVietnamese } from 'common/utils/string/vn';

export default ({
  formid,
  category = '',
  targetUser = null,
  trainingModel = null,
  moduleIid = null,
  schoolYear = null,
  classWrapper,
  multiple = false,
  isRequired = true,
  populateValue = true,
  notIncludeSupplmentaryTrainingPlan = false,
  organizations = '',
  havePlannedUsers,
  customValueOfApi,
  apiUrl = '/training-plan/schema-form/search-based-on-category',
  type = '',
  includeAllOption = false,
}) => {
  let value = { items_per_page: -1 };

  if (organizations) {
    value.organizations = organizations;
  }
  if (category) {
    value.category = category;
  } else if (trainingModel && moduleIid && schoolYear) {
    value.training_model = trainingModel;
    value.module_iid = moduleIid;
    value.school_year = schoolYear;
  }

  if (targetUser) {
    value.targetUser = targetUser;
  }

  if (typeof havePlannedUsers !== 'undefined') {
    value.have_planned_users = havePlannedUsers ? 1 : 0;
  }

  if (customValueOfApi) {
    value = {
      ...value,
      ...customValueOfApi,
    };
  }

  // maybe at the server, the logic can change
  value.formid = formid;
  value.not_include_supplmentary_training_plan = notIncludeSupplmentaryTrainingPlan
    ? 1
    : 0;

  let key = `form-element-tp-iids-${formid}-${category}-${trainingModel}-${moduleIid}-${schoolYear}-${
    Array.isArray(organizations) ? organizations.join('-') : organizations
  }`;

  if (customValueOfApi) {
    key = `${key}-${JSON.stringify(customValueOfApi)}`;
  }

  return {
    type: type ? type : 'select',
    floatingLabelText: t1('training_plan'),
    options: 'async',
    populateValue,
    classWrapper,
    showSearch: true,
    optionFilterProp: 'children',
    filterOption: (input, option) =>
      unaccentVietnamese(option.props.children)
        .toLowerCase()
        .indexOf(unaccentVietnamese(input).toLowerCase()) >= 0,
    // hiddenWhenOptionEmpty: true,
    paramsasync: {
      __url__: apiUrl,
      value,
      key,
      transformData: (tps) => {
        if (!Array.isArray(tps) || !tps.length) {
          return [];
        }

        const options = tps.map((row) => {
          let label = row.name;
          // if (row.code) {
          //   label = `${label} - ${row.code}`;
          // }

          return {
            value: row.iid,
            label,
            primaryText: label,
          };
        });

        if (includeAllOption) {
          return [
            {
              value: '',
              label: t1('all'),
              primaryText: t1('all'),
            },
            ...options,
          ];
        }

        return options;
      },
    },
    fullWidth: true,
    multiple,
    validate: isRequired ? [required()] : null,
  };
};
