import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import ButtonSecondary from 'components/common/button/ButtonSecondary';
import { t1 } from 'translate';
import ImportCourse from './index';

const ImportCourseButton = ({ enrolmentPlanIid }) => {
  return (
    <DetailOnDialog
      renderPreview={({ showFull }) => (
        <ButtonSecondary onClick={showFull} className={'m-l-10'}>
          {t1('import_courses')}
        </ButtonSecondary>
      )}
      renderFull={() => {
        return <ImportCourse enrolmentPlanIid={enrolmentPlanIid} />;
      }}
      dialogOptionsProperties={{
        handleClose: true,
        title: t1('import_courses'),
        modal: true,
        width: '80%',
      }}
      dialogKey="import_course_dialog"
    />
  );
};

export default ImportCourseButton;
