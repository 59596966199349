import { t1 } from 'translate';
import { required } from 'common/validators';
import { isETEP } from 'utils/Util';

// const categories = () => {
//   const ret = [];
//   for (let i = 1; i < 10; i++) {
//     ret.push({
//       value: i,
//       primaryText: `Module ${i}`,
//     })
//   }
//   return ret;
// }
//
//
// export default categories;
//
export const taphuanCategoryElement = ({
  forSearch = false,
  classWrapper = null,
  defaultValue,
  saveValueAsString = false,
  multiple = false,
  floatingLabelText = null,
  validate,
  params = {},
  populateValue = true,
  cotcan = false,
  daitra = false,
  paramsasync = {},
  modifyOptions,
  specialTpType = null,
  readOnly = false,
  includeOtherModule = false,
} = {}) => {
  let paramKey = '';
  Object.keys(params || {}).forEach((key) => {
    paramKey = `${paramKey}-${key}-${params[key]}`;
  });

  return {
    type: 'select',
    readOnly,
    classWrapper,
    floatingLabelText: floatingLabelText || t1('training_plan_category'),
    floatingLabelFixed: true,
    validate:
      typeof validate !== 'undefined'
        ? validate
        : isETEP()
        ? [required(t1('category_cannot_be_empty'))]
        : null,
    multiple,
    ...(defaultValue !== undefined ? { defaultValue } : {}),
    fullWidth: true,
    populateValue: populateValue,
    options: 'async',
    paramsasync: {
      __url__: '/training-plan/schema-form/get-categories',
      key: `tp_categories_${cotcan}_${daitra}_${forSearch}_${specialTpType}-${paramKey}`,
      value: {
        ...params,
        cotcan: cotcan ? 1 : 0,
        daitra: daitra ? 1 : 0,
        forSearch: forSearch ? 1 : 0,
        special_tp_type: specialTpType,
        includeOtherModule: includeOtherModule ? 1 : 0,
      },
      transformData: (data) => {
        let dataTransformed = data;

        if (saveValueAsString) {
          dataTransformed = (dataTransformed || [])
            .filter(Boolean)
            .map((d) => ({
              ...d,
              value: String(d.value),
            }));
        }

        if (typeof modifyOptions === 'function') {
          return modifyOptions(dataTransformed);
        }

        return dataTransformed;
      },
      ...paramsasync,
    },
  };
};
