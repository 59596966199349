import React from 'react';
import { getAttachment } from 'components/learn/items/lecture/common';
import MicroSoftEmbedLink from 'components/common/MicroSoftEmbedLink';
import get from 'lodash.get';
import Heading from 'components/learn/items/sco-spl/common/Heading';

const MicrosoftOfficeViewer = ({
  node,
  className,
  lectureType,
  isReview,
  isPreview,
  learningProgress,
  saveLearningProgress,
  hiddenHeading = false,
}) => {
  const attachment = getAttachment(node);
  let link = attachment && attachment.link;

  React.useEffect(
    () => {
      if (
        !node ||
        isPreview ||
        isReview ||
        String(learningProgress) === '100'
      ) {
        return;
      }
      saveLearningProgress &&
        saveLearningProgress([
          {
            tco_iid: get(node, 'iid'),
            p: 100,
            cp: 100,
            time_spent: 0,
          },
        ]);
    },
    [node, isReview, isPreview, learningProgress, saveLearningProgress],
  );

  const getVideoClassName = () => {
    if (hiddenHeading) {
      return 'video-wrapper';
    }

    return 'video-wrapper video-wrapper-ratio';
  };

  return (
    <div className={`${className || ''} lecture-${lectureType}`}>
      {!hiddenHeading && (
        <div className={'video-heading block-item-heading'}>
          <i className="ve-play" />
          <Heading item={node} />
        </div>
      )}

      <div className={getVideoClassName()}>
        <div className="video">
          <div className="video-body">
            <MicroSoftEmbedLink link={link} height="100%" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MicrosoftOfficeViewer;
