export const formatNumber = (num) => {
  if (!num) {
    return num;
  }

  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const isNumberOrNumericString = (value) => {
  if (typeof value == 'number') return true;

  return /^-?\d+$/.test(value);
};

export const convertNumberToRomanNumber = (number) => {
  switch (number) {
    case 1: {
      return 'I';
    }
    case 2: {
      return 'II';
    }
    case 3: {
      return 'III';
    }
    case 4: {
      return 'IV';
    }
    case 5: {
      return 'V';
    }
    case 6: {
      return 'VI';
    }
    case 7: {
      return 'VII';
    }
    case 8: {
      return 'VIII';
    }
    case 9: {
      return 'IX';
    }
    case 10: {
      return 'X';
    }
    default: {
      return `X${convertNumberToRomanNumber(number - 10)}`;
    }
  }
};

export const convertNumberToLetter = (number) => {
  return String.fromCharCode(96 + number);
};

export const calculateThePercentageIncrease = (
  newNumber = 0,
  originalNumber = 0,
) => {
  if (!newNumber) {
    return originalNumber ? (originalNumber ? -100 : 0) : 0;
  } else if (!originalNumber) {
    return newNumber ? 100 : 0;
  }

  return `${newNumber >= originalNumber ? '' : '-'} ${Math.abs(
    (newNumber / originalNumber - 1) * 100,
  ).toFixed(2)}`;
};

export const getPercentToDisplay = (number, total, fractionDigits = 2) => {
  if (!total) {
    return '';
  }

  if (!number) {
    return 0;
  }

  const percent = (number * 100) / total;

  if (percent === 100 || percent == 0) {
    return percent;
  }

  return percent.toFixed(fractionDigits);
};
