import React from 'react';

import { connect } from 'react-redux';
import AntSubLeftMenu from 'components/common/views/ant-menu';

/**
 * New sub menu top when editing any node
 * @param mode admin| frontend
 * @returns {*}
 * @constructor
 */
const NodeEditMenu = ({ node, mode, schema }) => {
  const klass =
    mode == 'admin'
      ? `ui-admin-left-menu-panel sticky-card z-index-1000 m-b-15`
      : 'm-b-15';
  const klass2 = mode == 'admin' ? 'sub-left-menu' : '';
  return (
    <div className={klass}>
      <div className={klass2}>
        <AntSubLeftMenu mode={'horizontal'} schema={schema} />
      </div>
    </div>
  );
};

export default NodeEditMenu;
