import './complex.scss';
import React from 'react';
import get from 'lodash.get';
import Paper from 'material-ui/Paper';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc';
import { t1 } from 'translate';
import actions from 'actions/node/creators';
import { getTargetHasScrollBar } from 'components/common/forms/questions/ddm/scrollable';
import ErrorMessageQuestion from '../../ErrorMessageQuestion';
import { isItemRoCorrect } from '../util';

const cssClass = 'reorder-question-type-complex';

const AnswerItem = ({ className, reorder, initialReorders }) => (
  <Paper className={`${cssClass}__answer-item ${className || ''}`}>
    <div className={`${cssClass}__answer-item-numbering`}>
      {initialReorders &&
        initialReorders.indexOf(
          initialReorders.find((item) => item.id === reorder.id),
        ) + 1}
    </div>
    {reorder.avatar && (
      <div className={`${cssClass}__answer-item-avatar`}>
        <img src={reorder.avatar} id="button_value" alt={reorder.content} />
      </div>
    )}

    <div className={`${cssClass}__answer-item-content`}>
      <div title={reorder.content} className="content-text-ellipsis">
        {reorder.content}
      </div>
    </div>
    <div className={`${cssClass}__answer-item-icon ve-move`} />
  </Paper>
);

const SortableItem = SortableElement(AnswerItem);

const AnswerList = ({
  sortable,
  userAnswers,
  question,
  getHighlightsClass,
}) => (
  <div className={`${cssClass}__answer`}>
    {userAnswers &&
      userAnswers.map((answer, index) => {
        const id = answer[0];
        const { reorders } = question || {};

        const reorder = reorders && reorders.find((item) => item.id === id);

        const ItemComponent = sortable ? SortableItem : AnswerItem;

        return (
          reorder && (
            <ItemComponent
              className={getHighlightsClass(id)}
              reorder={reorder}
              key={`item-${index}`}
              index={index}
              initialReorders={reorders}
            />
          )
        );
      })}
  </div>
);

const SortableList = SortableContainer((props) =>
  AnswerList({ ...props, sortable: true }),
);

class Reorder extends React.Component {
  constructor(props) {
    super(props);
    this.sortableListRef = undefined;
    this.state = {
      showError: 0,
    };
  }
  handleSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return;
    }
    const {
      disabled,
      userAnswers,
      setUserAnswers,
      resetHighlights,
      question,
      practice,
    } = this.props;
    if (disabled) return;

    const newUserAnswers = arrayMove(userAnswers, oldIndex, newIndex);

    if (practice) {
      const itemUserAnswer = get(newUserAnswers, `[${newIndex}][0]`);
      const isCorrect = isItemRoCorrect(question, newIndex, itemUserAnswer);
      const count = Math.abs(Math.abs(this.state.showError) + 1);
      this.setState({
        showError: isCorrect ? -count : count,
      });
      if (!isCorrect) {
        return;
      }
    }

    resetHighlights();

    if (typeof setUserAnswers === 'function') {
      setUserAnswers(newUserAnswers);
    }
  };

  shouldCancelStart = (e) => {
    let targetEle = e;

    if (!targetEle.id) {
      targetEle = e.target;
    }
    if (targetEle.id === 'button_value') {
      const { dispatch } = this.props;
      const contentDialog = (
        <div>
          <img
            style={{ width: '100%' }}
            src={targetEle.src}
            alt={targetEle.content}
          />
        </div>
      );
      const optionsProperties = {
        handleClose: true,
        title: targetEle.alt,
        modal: true,
      };
      const dialogKey = `item-${targetEle.id}`;
      dispatch(
        actions.handleOpenDialog(
          { contentDialog, optionsProperties },
          dialogKey,
        ),
      );
    }
  };

  render() {
    const { className, question, shouldShowKey, disabled } = this.props;
    const { answer_as_hint } = question;

    const componentClassName = `${className || ''} ${cssClass}`;

    return (
      <div
        className={componentClassName}
        ref={(ref) => (this.sortableListRef = ref)}
      >
        <ErrorMessageQuestion
          question={question}
          error={this.state.showError}
        />

        {disabled ? (
          <AnswerList {...this.props} />
        ) : (
          <SortableList
            {...this.props}
            helperClass="sortable-element"
            onSortEnd={this.handleSortEnd}
            shouldCancelStart={this.shouldCancelStart}
            helperContainer={() => {
              if (this.sortableListRef) {
                return getTargetHasScrollBar(this.sortableListRef);
              }
              return document.body;
            }}
          />
        )}
        <div className={`${cssClass}__section`}>
          {shouldShowKey && [
            <div key="answer-help-text" className={`${cssClass}__help-text`}>
              {t1('correct_answer')}
            </div>,
            ...((answer_as_hint &&
              answer_as_hint.map((answer) => (
                <AnswerItem
                  key={answer.id}
                  reorder={answer}
                  initialReorders={question.reorders}
                />
              ))) ||
              []),
          ]}
        </div>
      </div>
    );
  }
}

Reorder.propTypes = {
  disabled: PropTypes.bool,
  getHighlightsClass: PropTypes.func,
  question: PropTypes.shape(),
  resetHighlights: PropTypes.func,
  setUserAnswers: PropTypes.func,
  shouldShowKey: PropTypes.bool,
  userAnswers: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

Reorder.defaultProps = {
  disabled: false,
  getHighlightsClass: () => {},
  question: {},
  resetHighlights: () => {},
  setUserAnswers: () => {},
  shouldShowKey: false,
  userAnswers: null,
};

export default connect()(Reorder);
