import React from 'react';
import get from 'lodash.get';
import apiUrls from 'api-endpoints';
import { t1 } from 'translate';
import CommonAntdTable from 'components/common/antd/table';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import ExportData from 'components/common/export-data';
import ChuongTrinhTag from 'components/bdtx/common/ChuongTrinhTag';

const getDemographics = () => [
  {
    title: t1('total'),
    key: 'all_users',
  },
  {
    title: t1('female'),
    key: 'female',
  },
  {
    title: t1('ethnic_minority_short_text'),
    key: 'dtts',
  },
  {
    title: t1('female_ethnic_minority_short_text'),
    key: 'female_dtts',
  },
  {
    title: t1('vung_kho_khan'),
    key: 'dia_ban_khokhan',
  },
];

const Results = ({ modules, resultId }) => {
  const columns = [
    // {
    //   title: 'Chương trình',
    //   type: TYPE_OF_TABLE_DATA.TITLE,
    //   render: (row) => (
    //     <ChuongTrinhTag chuongTrinh={get(row, 'chuong_trinh')} shortName />
    //   ),
    // },
    {
      title: 'Mô đun đào tạo',
      key: 'name',
      dataIndex: 'name',
      render: (name) => <span>{name}</span>,
    },
    {
      title: 'Nhu cầu BDTX (Theo số lượng mong muốn của Giáo Viên, CBQL CSGD)',
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: getDemographics().map(({ title, key }) => {
        return {
          title,
          className: 'text-center',
          render: (row) => {
            return {
              children: get(row, `data_report.${key}`, 0),
              props: {
                style: { width: 100 },
                className: 'text-right',
              },
            };
          },
        };
      }),
    },
  ];

  return (
    <ExportData
      buttonClass="m-b-20"
      classNameWrapperTable="d-block"
      apiExport={apiUrls.export_html_table}
      buttonText="Xuất excel"
      fileName="Nhu-cau-bdtx"
      updateTableBody={resultId}
      params={{ auto_resize: 1 }}
      tableExport={
        <CommonAntdTable
          dataSource={modules}
          columns={columns}
          rowKey="id"
          pagination={false}
          className="learn-default"
        />
      }
    />
  );
};

export default Results;
