import React from 'react';
import lodashGet from 'lodash.get';
import { t1 } from 'translate';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import endpoints from 'components/temis/endpoints';
import { ReloadDataSyncerButtonComponent } from 'components/temis/report/utils';
import ExportBtnWithConfirmDialog from 'components/common/action-button/ExportBtnWithConfirmDialog';
import schema from './schema';
import Results from './Results';

const OverviewResultBdtx = () => {
  const FORM_ID = 'get-overview-result-bdtx';

  const renderResultComponent = (
    items,
    props,
    objects,
    searchValues,
    resultId,
  ) => {
    let comp;
    if (!items || !items.length) {
      comp = <SimpleNoResult />;
    } else {
      comp = (
        <Results
          items={items}
          organizations={lodashGet(searchValues, 'organizations')}
          caps={lodashGet(searchValues, 'caps')}
          targetUsers={lodashGet(searchValues, 'target_users')}
        />
      );
    }

    return (
      <>
        <div className="d-flex align-items-center justify-content-between">
          <ExportBtnWithConfirmDialog
            url={endpoints.export_result_bdtx}
            params={searchValues}
            formid={FORM_ID}
            label={t1('export_result_bdtx')}
            disabled={!items || !items.length}
            fetchingCacheOnly
          />

          <ReloadDataSyncerButtonComponent
            ts={lodashGet(objects, 'last_time_finished_syncing')}
            ts_started={lodashGet(objects, 'ts_started')}
            formid={FORM_ID}
            resultId={resultId}
          />
        </div>

        {comp}
      </>
    );
  };

  return (
    <SearchWrapper
      alternativeApi={endpoints.result_bdtx}
      schema={schema}
      formid={FORM_ID}
      renderResultsComponent={renderResultComponent}
      showResult={true}
      autoSearchWhenValuesChange={true}
      autoSearchWhenStart={true}
      showSearchButton={true}
      method="POST"
    />
  );
};

export default OverviewResultBdtx;
