import React from 'react';
import SubTopMenuContext from 'common/context/menu/SubMenuTop';
import MigrateData from './migrate-to-bdtx';

const MigrateEtepData = () => {
  return (
    <>
      <SubTopMenuContext lastBreadcrumbName="Kết chuyển dữ liệu" />

      <MigrateData />
    </>
  );
};

export default MigrateEtepData;
