import React from 'react';
import get from 'lodash.get';
import DetailOnDialog from 'components/common/detail-on-dialog';
import Icon from 'components/common/Icon';
import ConfirmContent from './ConfirmContent';

const ConfirmDelete = ({ trainingPhase, searchFormId }) => {
  const name = get(trainingPhase, 'name');

  return (
    <DetailOnDialog
      renderPreview={({ showFull }) => {
        return (
          <Icon icon={'delete'} className="text-danger" onClick={showFull} />
        );
      }}
      renderFull={({ closeDialog }) => {
        return (
          <ConfirmContent
            trainingPhase={trainingPhase}
            searchFormId={searchFormId}
            closeDialog={closeDialog}
          />
        );
      }}
      dialogOptionsProperties={{
        title: (
          <span>
            Thông tin triển khai của đợt: <b>{name}</b>
          </span>
        ),
        handleClose: true,
        modal: true,
        width: '90%',
      }}
    />
  );
};

export default ConfirmDelete;
