import Dashboard from './dashboard';

export const MAIN_STAGE_VIEW_TYPE = {
  DASHBOARD: 'dashboard',
  REVIEW: 'review',
};

export const reviewExamRootUrl = '/admin/review-exam-store';

export const generateReviewExamUrl = (action) => {
  if (action) {
    return `${reviewExamRootUrl}/:iid/${action}`;
  }

  return reviewExamRootUrl;
};

export const getUrlByReviewExamIid = (examIid, action) => {
  return `${reviewExamRootUrl}/${examIid}/${action ||
    MAIN_STAGE_VIEW_TYPE.DASHBOARD}`;
};

export const reviewExamUrl = {
  rootUrl: reviewExamRootUrl,
  dashboard: generateReviewExamUrl(MAIN_STAGE_VIEW_TYPE.DASHBOARD),
  review: generateReviewExamUrl(MAIN_STAGE_VIEW_TYPE.REVIEW),
};

export default [
  {
    componentId: 'review-exam-dashboard',
    path: reviewExamUrl.dashboard,
    component: Dashboard,
    exact: true,
  },
];
