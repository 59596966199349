import React, { Component } from 'react';
import { t1 } from 'translate';

class StatusDisplay extends Component {
  render() {
    const { status, text, bold } = this.props;

    const style =
      status === 'approved' || status === 'active'
        ? {
            color: 'green',
          }
        : status === 'deleted' || status === 'rejected'
        ? { color: 'red' }
        : {};

    if (bold) style.fontWeight = 'bold';

    return <span style={style}>{text || t1(status)}</span>;
  }
}

export default StatusDisplay;
