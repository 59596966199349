import React from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash.get';
import Perm from 'common/utils/Perm';
import fetchData from 'components/common/fetchData';
import Loading from 'components/common/loading';
import apiUrls from 'api-endpoints';
import { createSelector } from 'reselect';
import Result from 'antd/lib/result';
import Button from 'antd/lib/button';
import { Link } from 'react-router-dom';
import { t1 } from 'translate';
import Modal from 'antd/lib/modal';
import { logout } from 'actions/auth';
import Deadline from '../components/front-end/common/Deadline';
import { getCurrentUnixTimestamp } from '../common/utils/Date';
import { routerUrlParams } from '../common/utils/url';
import { DefinedUrlParams } from '../routes/links/common';
import { isEnableTemis } from '../components/temis/utils';

class Layouts extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      show_warning: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { data = {}, requireLicenseKey } = nextProps;
    if (
      (!this.props.data || this.props.data.err_code !== 'session_timeout') &&
      data &&
      data.err_code === 'session_timeout'
    ) {
      this.showSessionEndedPopup();
    } else if (
      data &&
      data.err_code &&
      data.err_code !== 'exceeded_ccu_limit' &&
      data.type_of_action_when_license_inactive === 'warning'
    ) {
      this.showAlertBoxLicenseInactive(data);
    } else if (
      data &&
      !data.err_code &&
      data.license &&
      requireLicenseKey &&
      !this.state.show_warning
    ) {
      this.showAlertBoxTimeOfLicenseInactive(data);
    }
  }

  componentWillUnmount() {
    if (this.state.modal) {
      this.state.modal.destroy();
    }
  }

  showAlertBoxLicenseInactive = ({ err_code }) => {
    const { title, subTitle } = this.getContentToShowResultByErrorCode(
      err_code,
    );

    let modal = null;

    if (!this.state.show_warning) {
      modal = Modal.error({
        title,
        content: subTitle,
        maskClosable: false,
      });
    }

    if (modal) {
      this.setState(() => {
        return {
          modal,
          show_warning: true,
        };
      });
    }
  };

  showSessionEndedPopup = () => {
    Modal.warning({
      title: 'Phiên làm việc hết hạn',
      content: (
        <div>
          Phiên làm việc đã hết hạn, vui lòng đăng nhập lại để tiếp tục!
        </div>
      ),
      onOk: this.goBackToLogin,
    });
  };

  goBackToLogin = () => {
    this.props.dispatch(logout());
  };

  showAlertBoxTimeOfLicenseInactive = ({
    license,
    day_of_started_countdown_warning_license_inactive = 0,
    day_of_period_allows_operating_when_the_license_inactive = 0,
  }) => {
    let endDate = lodashGet(license, 'end_date');
    const now = getCurrentUnixTimestamp();
    let modal = null;

    if (
      endDate &&
      endDate - now > 0 &&
      endDate - now <
        day_of_started_countdown_warning_license_inactive * 24 * 60 * 60 &&
      !this.state.show_warning
    ) {
      modal = Modal.warning({
        title: t1('time_of_license_is_about_to_expire'),
        content: (
          <span>
            {t1('countdown_time_of_license_is_about_to_expire')}:{' '}
            <Deadline countDown notShowIcon endDate={endDate} okText="Đóng" />.{' '}
            {t1('time_of_license_is_about_to_expire_content')}
          </span>
        ),
        maskClosable: true,
      });
    } else if (
      day_of_period_allows_operating_when_the_license_inactive > 0 &&
      endDate &&
      endDate - now < 0 &&
      day_of_period_allows_operating_when_the_license_inactive * 24 * 60 * 60 +
        endDate -
        now >
        0 &&
      !this.state.show_warning
    ) {
      modal = Modal.error({
        title: t1('day_of_period_allows_operating_when_the_license_inactive'),
        content: (
          <span>
            {t1(
              'countdown_day_of_period_allows_operating_when_the_license_inactive',
            )}
            :{' '}
            <Deadline
              countDown
              notShowIcon
              endDate={
                endDate +
                day_of_period_allows_operating_when_the_license_inactive *
                  24 *
                  60 *
                  60
              }
              okText="Đóng"
            />
            .{' '}
            {t1(
              'day_of_period_allows_operating_when_the_license_inactive_content',
            )}
          </span>
        ),
        maskClosable: true,
      });
    }

    if (modal) {
      this.setState(() => {
        return {
          modal,
          show_warning: true,
        };
      });
    }
  };

  getContentToShowResultByErrorCode = (err_code, handleRefetch) => {
    let status = 'warning';
    let title = '';
    let subTitle =
      'Quý thầy/cô cần liên hệ với Quản trị viên của đơn vị (Trường/Phòng/Sở) để được giải đáp/hỗ trợ';
    let extra = [
      <Button type="primary">
        <Link to="/faq">{`Đi vào ${t1('faq')}`}</Link>
      </Button>,
    ];

    switch (err_code) {
      case 'have_no_license': {
        title =
          'Tài khoản Quý thầy/cô chưa được đăng ký sử dụng hoặc hết thời hạn hợp đồng.';
        status = 'error';
        break;
      }
      case 'license_inactive': {
        title =
          'Hạn sử dụng tài khoản của Quý Thầy/cô đã hết thời hạn hợp đồng.';
        status = 'error';
        break;
      }

      //err_code: exceeded_ccu_limit
      default: {
        title = 'Truy cập của Quý Thầy/cô đang bị giới hạn!';
        subTitle =
          'Số lượng tài khoản của gói dịch vụ đang sử dụng vượt quá số lượng cho phép của hệ thống. Quý Thầy/cô có thể thử lại hoặc liên hệ Quản trị viên của đơn vị (Trường/Phòng/Sở) để được giải đáp/hỗ trợ';
        extra = [
          <Button onClick={() => handleRefetch()}>Thử lại</Button>,
          <Button type="primary">
            <Link to="/faq">{`Đi vào ${t1('faq')}`}</Link>
          </Button>,
        ];
        break;
      }
    }

    return { status, title, subTitle, extra };
  };

  render() {
    const {
      children,
      handleRefetch,
      loadingStatus,
      requireLicenseKey,
      data,
      pageRequireLicensedKey,
    } = this.props;

    if (isEnableTemis()) {
      return children;
    }

    if (
      requireLicenseKey &&
      pageRequireLicensedKey &&
      loadingStatus !== 'finished'
    ) {
      return (
        <div className="text-center m-t-50">
          <Loading circularLoadingIcon />
          <div className="m-t-15">Đang tải ...</div>
        </div>
      );
    }

    const { err_code, type_of_action_when_license_inactive } = data || {};

    if (
      !err_code ||
      err_code === 'session_timeout' ||
      !pageRequireLicensedKey ||
      type_of_action_when_license_inactive === 'warning'
    ) {
      return children;
    }

    const {
      status,
      title,
      subTitle,
      extra,
    } = this.getContentToShowResultByErrorCode(err_code, handleRefetch);

    return (
      <Result
        className="m-t-50"
        status={status}
        title={title}
        subTitle={subTitle}
        extra={extra}
      />
    );
  }
}

const mapStateToProps = createSelector(
  (state) => lodashGet(state, 'domainInfo.conf.required_license', []),
  (state) => lodashGet(state, 'router.location.pathname'),
  (state) => {
    const courseIid = lodashGet(state, 'learn.courseIid');
    const pathname = lodashGet(state, 'router.location.pathname');

    return pathname.includes(courseIid) ? courseIid : null;
  },
  () => Perm.isGuest(),
  (pagesRequiredLicense, pathname, courseIid, isGuest) => {
    const learningPage =
      courseIid && pathname.includes('learn') && !pathname.includes('p-learn');

    const requireLicenseKey =
      Array.isArray(pagesRequiredLicense) &&
      (pagesRequiredLicense.includes('all_page') ||
        (pagesRequiredLicense.includes('leaning_page') && learningPage));

    const pageRequireLicensedKey =
      requireLicenseKey &&
      !pathname.includes('/faq') &&
      !pathname.includes('/profile') &&
      !pathname.includes('/user/login') &&
      ((learningPage && pagesRequiredLicense.includes('leaning_page')) ||
        (!learningPage && pagesRequiredLicense.includes('all_page')));

    return {
      courseIid,
      learningPage,
      requireLicenseKey,
      pageRequireLicensedKey,
    };
  },
);

export default connect(mapStateToProps)(
  fetchData(({ requireLicenseKey, courseIid, learningPage }) => {
    return {
      baseUrl: apiUrls.validate_license,
      propKey: 'data',
      keyState: 'license',
      fetchCondition: requireLicenseKey && !isEnableTemis(),
      params: {
        courseIid,
        learningPage: learningPage ? 1 : 0,
      },
      refetchCondition: (prevProps) => {
        if (isEnableTemis()) {
          return false;
        }

        if (!prevProps.requireLicenseKey && requireLicenseKey) {
          return true;
        }

        if (learningPage && courseIid && courseIid !== prevProps.courseIid) {
          return true;
        }

        return false;
      },
    };
  })(Layouts),
);
