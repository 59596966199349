import { t1 } from 'translate';
import get from 'lodash.get';
import Layout from './Layout';
import Attachments from 'schema-form/elements/attachments';
import { MakeReduxFormAnswerByStructure } from './AnswerByStructure';
import RTE from 'schema-form/elements/richtext';
import { normalizeYoutubeUrl } from 'common/normalizers';
import {
  defaultAcceptType,
  openEndedAcceptType,
} from 'components/admin/question/schema/question-types';
import { getValidateForRTE, validateVideoDuration } from './util';
import DigitalSupportPlan from './digital-plan';

const mappingAcceptInputWithField = {
  [openEndedAcceptType.TEXT]: 'content',
  [openEndedAcceptType.FILE]: 'attachments',
  [openEndedAcceptType.YOUTUBE]: 'youtube',
};

const schema = (formid, values, step, xpath, props) => {
  const structureAnswer = get(props, 'structureAnswer');
  const isAdvancedLayoutType = get(props, 'isAdvancedLayoutType');
  const isDigitalSupportPlanLayoutType = get(
    props,
    'isDigitalSupportPlanLayoutType',
  );
  const course = get(props, 'course');
  const userAnswers = get(props, 'userAnswers');
  const isExamMode = get(props, 'isExamMode');
  const acceptFiles = get(props, 'acceptFiles');
  const training_plan_category = get(props, 'course.training_plan_category');
  const wordmin = values.wordmin;
  const wordmax = values.wordmax;
  const min_video_duration = values.min_video_duration;
  const max_video_duration = values.max_video_duration;

  const label = isExamMode
    ? t1('type_your_answer_here')
    : t1('enter_the_answer_here_or_upload_your_answer_file');

  let contentType = RTE;
  if (isAdvancedLayoutType) {
    contentType = MakeReduxFormAnswerByStructure;
  } else if (isDigitalSupportPlanLayoutType) {
    contentType = DigitalSupportPlan;
  }
  if (isExamMode) {
    contentType = 'text';
  }

  return {
    content: {
      name: 'content',
      structureAnswer: structureAnswer,
      type: contentType,
      multiLine: true,
      floatingLabelText: label,
      hintText: null,
      rows: 2,
      takeId: props.takeId,
      question: props.question,
      questionIid: props.questionIid,
      courseIid: props.courseIid,
      resultMarked: props.resultMarked,
      setHideSubmitButton: props.setHideSubmitButton,
      disabled: props.disabled,
      course,
      userAnswers,
      validate: [getValidateForRTE(wordmin, wordmax)],
      onChange: props.onChange,
      inlineExercise: props.inlineExercise,
      exerciseStep: get(props, 'exerciseStep'),
      module: training_plan_category,
      showScroll: isDigitalSupportPlanLayoutType,
      minVideoDuration: min_video_duration,
      maxVideoDuration: max_video_duration,
    },
    attachments: {
      type: Attachments,
      name: 'file_answer',
      allowDownload: true,
      rootFolder: 'public',
      folder: 'file-answer',
      readOnly: props.disabled,
      noFileManager: true,
      height: '50px',
      primary: true,
      accept: acceptFiles,
      showConfirmWhenDeleteFile: true,
      minVideoDuration: min_video_duration,
      maxVideoDuration: max_video_duration,
    },
    youtube: {
      type: 'text',
      name: 'youtube',
      floatingLabelText: t1('youtube'),
      fullWidth: true,
      normalize: normalizeYoutubeUrl,
    },
  };
};

const ui = (step, values, themeConfig, xpath, formid, props) => {
  const isAdvancedLayoutType = get(props, 'isAdvancedLayoutType');
  const isDigitalSupportPlanLayoutType = get(
    props,
    'isDigitalSupportPlanLayoutType',
  );

  const acceptInputs = get(props, 'question.accept_inputs', defaultAcceptType);

  let fields = [];
  if (isAdvancedLayoutType || isDigitalSupportPlanLayoutType) {
    fields.push('content');
  } else {
    acceptInputs.forEach((acceptInput) =>
      fields.push(mappingAcceptInputWithField[acceptInput]),
    );
  }

  const config = {
    new_question: [
      {
        id: 'default',
        fields,
      },
    ],
  };

  return config[step];
};

export default {
  schema,
  ui,
  layout: (step, values, xpath, props) => {
    return {
      freestyle: 1,
      component: Layout,
      optionsProperties: {
        isAdvancedLayoutType: props.isAdvancedLayoutType,
        exerciseStep: props.exerciseStep,
        inlineExercise: props.inlineExercise,
      },
    };
  },
};
