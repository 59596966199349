import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import { t1 } from 'translate';
import Button from 'components/common/button/Button';
import Icon from 'components/common/Icon';
import TakeHistoryResult from './List';

const TakeHistories = ({
  userIid,
  exerciseIid,
  course,
  syllabus,
  inlineExercise,
}) => {
  return (
    <DetailOnDialog
      renderPreview={({ showFull }) => (
        <Button onClick={showFull}>
          <Icon icon={'list'} /> {t1('view_take_histories')}
        </Button>
      )}
      renderFull={({ closeDialog }) => (
        <TakeHistoryResult
          userIid={userIid}
          exerciseIid={exerciseIid}
          inlineExercise={inlineExercise}
          course={course}
          syllabus={syllabus}
          restoreSuccessful={() => {
            closeDialog();
          }}
        />
      )}
      dialogOptionsProperties={{
        handleClose: true,
        title: t1('take_histories'),
        className: 'score-modal',
      }}
    />
  );
};

export default TakeHistories;
