import React, { Fragment } from 'react';
import { findDOMNode } from 'react-dom';
import { t1 } from 'translate/index';
import Avatar from 'components/common/avatar/index';
import {
  timestampToDateTimeString,
  displayDurationSinceEpochTime,
} from 'common/utils/Date';
import FlatButton from 'components/common/mui/FlatButton';
import './stylesheet.scss';
import lodashGet from 'lodash.get';
import Comment from 'antd/lib/comment';
import Tooltip from 'antd/lib/tooltip';
import DetailOnDialog from '../../detail-on-dialog';
import Card from 'antd/lib/card';
import TeacherReplyStatus from '../teacher-reply-status';
import withUserInfo from 'common/hoc/withUserInfo';
import { COMMENT_REPLY_TIME_WARNING } from 'configs/constants';
import {
  getMyReply,
  getOneMasterAssigned,
  getCommentAssignToMe,
  getMasterReply,
} from '../helper';
import { isTeacherRoute, isGVSPRoute, isAdminRoute } from 'utils/router';
import { isFreeze } from 'utils/Util';
import { getCourseMasterMenuItem } from 'routes/master/utils';
import { teacherCourseDashboardLink } from 'components/admin/teacher-dashboard/routes';
import routes from 'routes';
import { teacherMenuItems } from 'routes/teacher/util';
import { Link } from 'react-router-dom';

const styles = {
  pointerCursor: {
    cursor: 'pointer',
  },
};

const CommentStyled = Comment;

const ProfileCard = ({ user, type }) => {
  const size = 300;
  const renderFull = ({}) => (
    <Card
      style={{ width: size }}
      cover={
        <Avatar className="avatar" user={user} shape="square" size={size - 2} />
      }
    >
      <Card.Meta
        title={user && user.name}
        description={lodashGet(user, 'mail')}
      />
    </Card>
  );

  const renderPreview = ({ showFull }) => {
    return (
      <span onClick={showFull}>
        {type === 'avatar' ? (
          <Avatar className="avatar" user={user} />
        ) : (
          <>
            <span className="ant-comment-content-author-name__name">
              {user && user.name}
            </span>
            <span className="ant-comment-content-author-name__organization-name">
              {user && lodashGet(user, 'mail')}
            </span>
          </>
        )}
      </span>
    );
  };

  return (
    <DetailOnDialog
      renderPreview={renderPreview}
      renderFull={renderFull}
      dialogKey="profile_card"
      dialogOptionsProperties={{
        handleClose: true,
        modal: true,
        width: size + 48,
      }}
    />
  );
};

const AntComment = ({
  item,
  onResolveComment,
  displayInAdmin,
  handleShowReplyComment,
  canResolveComment,
  children,
  searchFormCommentsId,
  elementReplyComment,
  isMaster,
  isGVCC,
  isTeacherMode,
  userInfo,
  viewOnly,
}) => {
  const [showReplies, setShowReplies] = React.useState(false);

  const { comment, teachers_answer, replies, course = {} } = item;
  const { name: courseName } = course;
  const { content } = comment;
  const isResolved = comment.is_resolved;
  const onlyViewComment = viewOnly || isFreeze(course);
  const user = item.user || item.u;
  const countReplies = Array.isArray(replies) && replies.length;

  const isTeachersAnswer =
    Array.isArray(teachers_answer) && teachers_answer.length;

  const getCourseDetailLinkByRouter = (course, routerId, step) => {
    if (lodashGet(userInfo, 'code') === 'bgd-iva') {
      return null;
    }

    if (isAdminRoute()) {
      return routes.url('node_edit', {
        ...course,
        ntype: 'course',
        step,
      });
    }

    if (isGVSPRoute()) {
      return getCourseMasterMenuItem(course, routerId);
    }

    if (isTeacherRoute()) {
      return teacherCourseDashboardLink(course, routerId);
    }

    return null;
  };

  const link = getCourseDetailLinkByRouter(
    course,
    teacherMenuItems.DASHBOARD,
    'dashboard',
  );

  return (
    <CommentStyled
      actions={[
        <span
          className={`reply-comment ${onlyViewComment ? 'disabled' : ''}`}
          onClick={onlyViewComment ? () => {} : handleShowReplyComment}
        >
          <span className="ve-comment" />
          {t1('reply')}
        </span>,
        countReplies > 0 && (
          <span
            className="count-replies"
            onClick={() => setShowReplies(!showReplies)}
          >
            {countReplies} {t1('comment')}
          </span>
        ),
        ...(canResolveComment
          ? [
              !isResolved ? (
                <FlatButton
                  label={t1('resolve')}
                  className="resolve"
                  style={styles.pointerCursor}
                  onClick={() => {
                    onResolveComment(item);
                  }}
                />
              ) : (
                <div
                  className={`resolve-button ${
                    displayInAdmin ? 'resolved-admin' : 'resolved'
                  }`}
                >
                  {t1('resolved')}
                </div>
              ),
            ]
          : []),
        <div className="comment-detail-right-action">
          {isTeacherMode && (
            <TeacherReplyStatus
              replies={replies}
              teachers_answer={teachers_answer}
              comment={item}
              viewOnly={onlyViewComment}
              isMaster={isMaster}
              isGVCC={isGVCC}
              searchFormCommentsId={searchFormCommentsId}
              userInfo={userInfo}
            />
          )}
          <span
            onClick={() => setShowReplies(!showReplies)}
            className={`toggle-reply ${
              showReplies ? 've-chevron-up' : 've-chevron-down'
            } ${countReplies > 0 ? 'visible' : 'invisible'}`}
          />
        </div>,
      ]}
      author={ProfileCard({ user })}
      avatar={ProfileCard({ user, type: 'avatar' })}
      content={
        <Fragment>
          {!!courseName && (
            <div className="comment-detail-course">
              <span>{t1('label_course')}: &#32;</span>
              {!!link ? (
                <Link to={link}>
                  <span>{courseName}</span>
                </Link>
              ) : (
                <span>{courseName}</span>
              )}
            </div>
          )}
          <p title={isTeachersAnswer ? t1('ask_teacher_to_comment') : null}>
            {content}
          </p>
        </Fragment>
      }
      datetime={
        <Tooltip title={timestampToDateTimeString(item.ts)}>
          {t1('%s_ago', [displayDurationSinceEpochTime(item.ts)])}
        </Tooltip>
      }
    >
      {showReplies && children}
      {elementReplyComment}
    </CommentStyled>
  );
};

const ShowDetail = ({
  elementReplyComment,
  item,
  onResolveComment,
  displayInAdmin,
  handleShowReplyComment,
  canResolveComment,
  searchFormCommentsId,
  isMaster,
  isGVCC,
  isTeacherMode,
  userInfo,
  viewOnly,
}) => {
  const { replies } = item;
  return (
    <AntComment
      elementReplyComment={elementReplyComment}
      item={item}
      onResolveComment={onResolveComment}
      displayInAdmin={displayInAdmin}
      handleShowReplyComment={handleShowReplyComment}
      canResolveComment={canResolveComment}
      searchFormCommentsId={searchFormCommentsId}
      isMaster={isMaster}
      isGVCC={isGVCC}
      isTeacherMode={isTeacherMode}
      userInfo={userInfo}
      viewOnly={viewOnly}
    >
      {Array.isArray(replies) &&
        replies.length > 0 &&
        replies.map((reply) => (
          <ShowDetail
            key={lodashGet(reply, 'id')}
            onResolveComment={onResolveComment}
            displayInAdmin={displayInAdmin}
            handleShowReplyComment={handleShowReplyComment}
            item={reply}
            canResolveComment={canResolveComment}
            userInfo={userInfo}
            viewOnly={viewOnly}
          />
        ))}
    </AntComment>
  );
};

class AntCommentDetail extends React.PureComponent {
  componentDidUpdate(prevProps, prevState) {
    const { item, replyCommentItem } = this.props;
    if (item.id === replyCommentItem) {
      const tesNode = findDOMNode(this.myRef);
      if (tesNode) {
        const h = (Array.isArray(item.replies) && item.replies.length) || 1;
        window.scrollTo(0, tesNode.offsetTop + h * 70);
      }
    }
  }

  shouldCommentBeWarned = () => {
    const { item, userInfo, isTeacherMode } = this.props;
    const { teachers_answer, replies } = item;

    if (!isTeacherMode) {
      return;
    }

    const myReply = getMyReply(replies, userInfo);
    const masterAssigned = getOneMasterAssigned(teachers_answer, userInfo);
    const assignToMe = getCommentAssignToMe(teachers_answer, userInfo);
    const masterReplied = getMasterReply(replies, masterAssigned);

    let myElapsed = 0;
    let masterElapsed = 0;

    if (!myReply && !masterAssigned) {
      myElapsed = new Date().getTime() / 1000 - lodashGet(assignToMe, 'ts');
    } else {
      if (myReply) {
        myElapsed = lodashGet(myReply, 'ts') - lodashGet(assignToMe, 'ts');
      }

      if (masterAssigned) {
        myElapsed =
          lodashGet(masterAssigned, 'ts') - lodashGet(assignToMe, 'ts');

        if (!masterReplied) {
          masterElapsed =
            new Date().getTime() / 1000 - lodashGet(masterAssigned, 'ts');
        } else {
          masterElapsed =
            lodashGet(masterReplied, 'ts') - lodashGet(masterAssigned, 'ts');
        }
      }
    }

    return (
      myElapsed > COMMENT_REPLY_TIME_WARNING ||
      masterElapsed > COMMENT_REPLY_TIME_WARNING
    );
  };

  shouldCommentBeHighlight = () => {
    const { item, userInfo, isTeacherMode } = this.props;
    const { teachers_answer, replies } = item;

    if (!isTeacherMode) {
      return;
    }
    const myReply = getMyReply(replies, userInfo);
    const masterAssigned = getOneMasterAssigned(teachers_answer, userInfo);
    const masterReplied = getMasterReply(replies, masterAssigned);

    return !myReply && !masterReplied;
  };

  render() {
    const {
      item,
      displayInAdmin,
      elementReplyComment,
      handleShowReplyComment,
      onResolveComment,
      canResolveComment,
      withRef,
      searchFormCommentsId,
      isMaster,
      isGVCC,
      isTeacherMode = false,
      userInfo,
      viewOnly,
    } = this.props;

    const shouldCommentBeWarned = this.shouldCommentBeWarned();
    const shouldCommentBeHighlight = this.shouldCommentBeHighlight();

    const commentDetailAdditionalClass = isTeacherMode
      ? shouldCommentBeWarned
        ? 'warning'
        : shouldCommentBeHighlight
        ? 'highlight'
        : ''
      : '';

    return (
      <div
        ref={(el) => {
          if (typeof withRef === 'function') {
            withRef(el);
          }
          this.myRef = el;
        }}
        className={`comment-detail ${commentDetailAdditionalClass}`}
      >
        <ShowDetail
          item={item}
          displayInAdmin={displayInAdmin}
          elementReplyComment={elementReplyComment}
          handleShowReplyComment={handleShowReplyComment}
          onResolveComment={onResolveComment}
          canResolveComment={canResolveComment}
          searchFormCommentsId={searchFormCommentsId}
          isMaster={isMaster}
          isGVCC={isGVCC}
          isTeacherMode={isTeacherMode}
          userInfo={userInfo}
          viewOnly={viewOnly}
        />
      </div>
    );
  }
}

export default withUserInfo(AntCommentDetail);
