export default {
  get_bdtx_report: '/temis/bdtx/get-bdtx-report',
  get_temis_profile: '/temis/profile/get',
  get_organization_learning_report:
    '/temis/report/get-organization-learning-report',
  export_organization_learning_report:
    '/temis/report/export-organization-learning-report',

  get_organization_teacher_report:
    '/temis/report/get-organization-teacher-report',
  export_organization_teacher_report:
    '/temis/report/export-organization-teacher-report',

  so_giao_duc_va_dao_tao_can_bo_cot_can_report:
    '/temis/report/so-giao-duc-va-dao-tao-can-bo-cot-can-report',
  assessment_evidence_template_search: 'assessment-evidence-template/search',
  delete_assessment_evidence_template: 'assessment-evidence-template/delete',
  delete_assessment_evidence: 'assessment-evidence/delete',
  assessment_evidence_from_template_search:
    'assessment-evidence/api/search-assessment-evidence-from-template',
  templateless_assessment_evidence_search:
    'assessment-evidence/api/search-templateless-assessment-evidence',
  sync_evidence_from_system:
    'assessment-evidence/api/sync-evidence-from-system',
  get_evidences_to_do_assessment:
    'assessment-evidence/schema-form/get-evidences-to-do-assessment',
  get_assessment_options: 'assessment/schema-form/get-assessment-options',
  get_temis_tieu_chuan_options:
    'assessment/schema-form/get-temis-tieu-chuan-options',
  get_temis_tieu_chi_options:
    'assessment/schema-form/get-temis-tieu-chi-options',
  get_assessment_score_options:
    'assessment/schema-form/get-assessment-score-options',
  get_evidence_code_to_create:
    'assessment-evidence/schema-form/get-evidence-code-to-create',
  organization_can_bo_cot_can_report:
    '/temis/report/organization-can-bo-cot-can-report',
  new_taphuan_training_registration: '/taphuan-training-registration/index/new',
  taphuan_registration_get_training_plan_categories_for_user:
    '/taphuan-training-registration/index/get-training-plan-categories-for-user',
  taphuan_registration_get_training_credit_syllabuses_for_select_options:
    '/taphuan-training-registration/index/get-training-credit-syllabuses-for-select-options',
  taphuan_registration_get_training_academic_categories_for_select_options:
    '/taphuan-training-registration/index/get-training-academic-categories-for-select-options',
  get_user_taphuan_training_registration:
    '/taphuan-training-registration/index/get-user-effective-registrations',
  delete_taphuan_training_registration:
    '/taphuan-training-registration/index/delete',
  get_message_for_registered_displayed_academic_categories:
    '/taphuan-training-registration/index/get-message-for-registered-displayed-academic-categories',

  taphuan_registration_get_training_plan_categories_for_tham_khao_select_options:
    '/taphuan-training-registration/index/get-training-plan-categories-for-tham-khao-select-options',
  taphuan_registration_get_tham_khao_credit_syllabuses_for_select_options:
    '/taphuan-training-registration/index/get-tham-khao-credit-syllabuses-for-select-options',
  taphuan_registration_tham_khao:
    '/taphuan-training-registration/index/tham-khao',
  taphuan_registration_get_tham_khao_registrations:
    '/taphuan-training-registration/index/get-tham-khao-registrations',
  update_training_grades_to_taphuan_training_registrations:
    '/taphuan-training-registration/index/update-training-grades-to-taphuan-training-registrations',
  get_data_to_update_missing_training_grades_in_registration:
    '/taphuan-training-registration/index/get-data-to-update-missing-training-grades-in-registration',
  get_data_to_update_missing_identification_card:
    '/user/data/get-data-to-update-missing-identification-card',

  retry_training_registration_processing:
    '/taphuan-training-registration/index/retry-processing',
  taphuan_training_registration_history:
    '/taphuan-training-registration/index/history',
  get_leader_of_organization: 'temis/api/get-leader-of-organizations',
  send_message_school_in_province: 'temis/api/get-leader-of-organizations',
  get_team_leader_assess_offline: '/faq/search', //TODO: update later
  export_assessment_result_of_peers_in_phongban:
    '/temis/export/export-assessment-result-of-peers-in-phongban',
  get_all_scholastic: '/assessment/phase/get-all',
  assessment_phase_search: '/assessment/phase/search',
  assessment_phase_get_detail: '/assessment/phase/get-detail',
  new_assessment_phase: 'assessment/phase/new',
  edit_assessment_phase: 'assessment/phase/update',
  get_conditions_completing_module_bdtx:
    '/temis/api/get-conditions-completing-module-bdtx',
  edit_conditions_completing_module_bdtx:
    '/temis/api/update-conditions-completing-module-bdtx',
  save_bm3_pl2: '/temis/api/save-bm3pl2-by-cv',
  get_bm3_pl2: '/temis/api/get-bm3pl2-by-cv',
  get_report_by_tcnn: '/assessment/api/get-data-tcnn-report',
  get_survey_list_to_report: '/temis/api/get-survey-list-to-report',
  summary_report_assessment_by_tcnn:
    '/assessment/api/summary-report-assessment-by-tcnn',
  summary_report_assessment_with_custom_filter:
    '/assessment/api/summary-report-assessment-with-custom-filter',
  summary_report_assessment_by_organization:
    '/assessment/api/summary-report-assessment-by-organization',
  summary_report_bdtx_survey: '/temis/bdtx/summary-report-bdtx-survey',
  summary_report_bdtx_survey_with_custom_filter:
    '/temis/bdtx/summary-report-bdtx-survey-custom-filter',
  result_bdtx: '/temis/bdtx/get-result-bdtx-report',
  export_result_bdtx: '/temis/bdtx/export-result-bdtx-report',
  detail_result_bdtx: '/temis/bdtx/get-detail-result-bdtx-report',
  export_detail_result_bdtx: '/temis/bdtx/export-detail-result-bdtx-report',
  assessment_of_users_in_organization:
    '/temis/report/assessment-of-users-in-organization',
  export_assessment_of_users_in_organization:
    '/temis/export/assessment-of-users-in-organization',
  summary_synthesis_report: '/temis/report/summary-synthesis-report',
  export_summary_report_bdtx_survey:
    '/temis/export/export-summary-report-bdtx-survey',
  summary_report_ncbdtx_with_custom_filter_by_tcnn:
    '/assessment/api/summary-report-ncbdtx-with-custom-filter-by-tcnn',
  summary_report_ncbdtx_by_tcnn:
    '/assessment/api/summary-report-ncbdtx-by-tcnn',
  export_summary_report_ncbdtx_by_tcnn:
    '/temis/export/export-summary-report-ncbdtx-by-tcnn',
  summary_report_criteria_with_the_lowest_ranking_level:
    '/assessment/api/summary-report-criteria-with-the-lowest-ranking-level',
  summary_report_criteria_with_the_lowest_ranking_level_with_custom_filter:
    '/assessment/api/summary-report-criteria-with-the-lowest-ranking-level-with-custom-filter',
  export_summary_report_criteria_with_the_lowest_ranking_level:
    '/temis/export/export-summary-report-criteria-with-the-lowest-ranking-level',
  export_overall_result_assessment:
    '/temis/export/export-overall-result-assessment-report-in-organization',
  export_summary_report_assessment_by_tcnn:
    '/temis/export/export-summary-report-assessment-by-tcnn',
  export_synthesis_report: '/temis/export/export-synthesis-report',
  get_bm3pl2_standards: '/temis/api/get-bm3pl2-standards',
  get_accounts: '/user/api/search',
  update_account: '/user/update',
  sync_report_progress: '/temis/report/sync-report-progress',
  remove_user_in_phongban: '/temis/api/remove-users-in-phongban',
  add_user_in_phongban: '/temis/api/add-users-into-phongban',
  assign_leader_of_phongban: '/temis/api/assign-leader-of-phongban',
  organization_search: '/organization/api/search?type=organization',
  organization_update: '/organization/api/update',
  temis_get_missions: '/temis/api/get-missions',
  update_assessment_step: '/temis/api/update-assessment-step',
  update_lock_assessment: '/temis/api/lock-assessment',
  get_lock_assessment: 'temis/lock-assessment/search',
  tcm_search: '/tcm/api/search',
  download_file_mc: '/temis/export/export-evidences-of-users-in-organization',
  send_assessment_notification:
    '/temis/api/send-notification-with-data-assessment',
  import_identification_code: '/temis/user/import-identification-code',
  import_identification_code_search:
    '/temis/user/import-identification-code-search',
  confirm_import_identification_code_search:
    '/temis/user/confirm-import-identification-code',
  get_data_lock_report_status: '/temis/api/get-data-lock-report-status',

  kq_hoan_thanh_danh_gia_report: '/temis/report/kq-hoan-thanh-danh-gia-report',
  thong_ke_nhu_cau_bdtx_report_by_module:
    '/temis/report/thong-ke-nhu-cau-bdtx-report-by-module',
  thong_ke_nhu_cau_bdtx_report_by_time:
    '/temis/report/thong-ke-nhu-cau-bdtx-report-by-time',
  search_comment_and_reflect: '/reflect/api/search',
  export_comment_and_reflect: '/reflect/api/export',
  temis_course_report: '/temis/report/course-report',
  export_temis_course_report: '/temis/report/export-course-report',
};
