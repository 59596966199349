export const getCurrentDirCode = (state) =>
  state && state.fileManager && state.fileManager.currentDirCode;

export const getViewMode = (state) =>
  state && state.fileManager && state.fileManager.viewMode;

export const shouldShowFileInfo = (state) =>
  state && state.fileManager && state.fileManager.showFileInfo;

export const getStagedItems = (state) =>
  state && state.fileManager && state.fileManager.stagedItems;
