import RTE from 'schema-form/elements/richtext';

const Rte = ({
  multiLine,
  selectorId,
  hintText,
  floatingLabelText,
  floatingLabelFixed,
  defaultValue,
  errorText,
  validate,
  normalize,
  onChange,
  classWrapper,
  fullWidth,
  readOnly,
  inline,
  ...rest
}) => {
  return {
    type: RTE,
    multiLine,
    selectorId,
    hintText,
    floatingLabelText,
    floatingLabelFixed,
    defaultValue,
    errorText,
    validate,
    normalize,
    onChange,
    classWrapper,
    fullWidth,
    readOnly,
    inline,
    ...rest,
  };
};

export default Rte;
