import './stylesheet.scss';
import React, { useEffect, useMemo } from 'react';
import { compose } from 'redux';
import Helmet from 'components/common/helmet';
import sessionUrls from 'components/admin/session/endpoints';
import { t1 } from 'translate';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import fetchData from 'components/common/fetchData';
import { createSelector } from 'reselect';
import lodashGet from 'lodash.get';
import { connect } from 'react-redux';
import ScheduledDateTime from 'components/admin/session/common/ScheduledDateTime';
import Status from 'components/admin/session/common/Status';
import { secondsToTimeString, timestampToDateString } from 'common/utils/Date';
import { Descriptions } from 'antd';
import withUserInfo from 'common/hoc/withUserInfo';
import CopyClipboard from 'components/common/copy-clipboard';
import { joinMeeting } from '../routes';
import { getFullUrl } from 'common/utils/url';
import Alert from 'antd/lib/alert';
import DisplayGroupMembers from '../group/DisplayMembers';
// import CourseMembersDialog from 'components/front-end/course/MembersDialog';
import EditButton from 'components/admin/session/edit/EditButton';
import Perm from 'common/utils/Perm';
// import SessionRepeatSchedule from 'components/admin/session/common/SessionRepeatSchedule';
import {
  canJoinToLearn,
  getCountdownTime,
  joinStatuses,
} from 'components/admin/session/utils';
import { withRouter } from 'react-router-dom';
import withConf from 'common/hoc/withConf';
import groupApiUrls from 'components/admin/group/endpoints';
import SessionDownloadMaterial from 'components/learn/viewer/sessions/SessionDownloadMaterial';
import Warning from 'components/common/Warning';

const MeetJoin = ({
  iid,
  session,
  beforeCanJoinToLearn,
  course = {},
  teachers = [],
  userInfo,
  serverTs,
  type,
  groups,
  refetchSessionDetail,
  canShowDetailMembers,
}) => {
  useEffect(
    () => {
      const sessionStatus = canJoinToLearn(
        session,
        beforeCanJoinToLearn,
        serverTs,
      );
      const countdownTime = getCountdownTime(session, beforeCanJoinToLearn);
      const timeToReload = Math.max(countdownTime * 1000 - Date.now(), 0);

      if (
        !timeToReload ||
        sessionStatus !== joinStatuses.JOIN_STATUS_TOO_EARLY
      ) {
        return;
      }

      const countdownTimer = setTimeout(() => {
        refetchSessionDetail();
      }, timeToReload);

      return () => {
        if (countdownTimer) {
          clearTimeout(countdownTimer);
        }
      };
    },
    [session, beforeCanJoinToLearn, refetchSessionDetail, serverTs],
  );

  const duration = useMemo(
    () => {
      const learnDuration = lodashGet(session, 'learn_duration');
      const duration = lodashGet(session, 'duration');
      const breakTime = lodashGet(session, 'break_time');

      return (
        <>
          {(learnDuration > 0 || learnDuration > 0) &&
            `${secondsToTimeString(learnDuration || duration || 0)}`}
          {breakTime > 0 && [
            learnDuration > 0 || learnDuration > 0 ? <br /> : null,
            `${t1('break_time')}: ${secondsToTimeString(breakTime)}`,
          ]}
        </>
      );
    },
    [session],
  );

  const canJoinVirtualClass =
    Array.isArray(teachers) &&
    !!teachers.find(({ iid }) => iid === userInfo.iid);

  const repeaterSession = lodashGet(session, '__expand.repeater_session');

  const joinRepeaterSessionUrl = repeaterSession
    ? getFullUrl(joinMeeting({ iid: lodashGet(repeaterSession, 'iid') }))
    : null;

  const joinUrl = repeaterSession
    ? joinRepeaterSessionUrl
    : getFullUrl(joinMeeting({ iid: lodashGet(session, 'iid') }));

  const showAlertPasscode = window.location.search == '?passcode=1';

  let description;
  let descriptionText;
  if (session) {
    const passCodeText = session.public_code
      ? `${t1('pass_code')}: ${session.public_code}`
      : '';
    const timeText = timestampToDateString(
      lodashGet(session, 'scheduled.start_time_ts'),
      { showTime: true },
    );

    description = `Link: ${joinUrl}<br />${passCodeText}`;
    descriptionText = `${t1('link')}: ${joinUrl}\n${passCodeText}\n${t1(
      'start_time',
    )}: ${timeText}`;
  }

  const joinButton = (
    <div className="row m-b-20">
      <div className="col-md-12">
        <div className="text-center m-t-25">
          <Status
            session={session}
            canJoinVirtualClass={canJoinVirtualClass}
            beforeCanJoinToLearn={beforeCanJoinToLearn}
            showPassCodeForm
            serverTimestamp={serverTs}
            showCountDownTitle
          />
        </div>
      </div>
    </div>
  );
  const group = lodashGet(session, '__expand.group');
  const memberLabel = t1('session_members');

  function renderMemberInfo() {
    return (
      <DisplayGroupMembers
        item={group}
        extraMembers={lodashGet(session, '__expand.members')}
        canShowDetailMembers={canShowDetailMembers}
      />
    );
  }

  const sessionStatus = canJoinToLearn(session, beforeCanJoinToLearn, serverTs);
  const canEditSession = [sessionStatus].includes([
    joinStatuses.JOIN_STATUS_TOO_EARLY,
    joinStatuses.JOIN_STATUS_NOT_YET_SCHEDULED,
  ]);
  const isSessionCreator = lodashGet(session, 'u.id') == userInfo.id;
  const attachments = lodashGet(session, 'download_materials', []);
  return (
    <div className="public-session m-b-30 m-t-30">
      <Helmet title={t1('join_meeting')} />
      {session ? (
        <div className={'container container-fluid'}>
          <div className={'row'}>
            <div className="col-md-6 col-md-offset-3">
              <div className="text-center m-t-25">
                <h1>
                  {session.name}{' '}
                  {isSessionCreator && canEditSession && (
                    <>
                      <EditButton
                        session={session}
                        hasPermUpdate
                        onEditSuccessful={refetchSessionDetail}
                        type={type}
                        groups={groups}
                        sessionStatus={canJoinToLearn(
                          session,
                          beforeCanJoinToLearn,
                          serverTs,
                        )}
                      />
                    </>
                  )}
                </h1>
                {/*{repeaterSession ? (*/}
                {/*  <>*/}
                {/*    <div className={'text-muted'}>*/}
                {/*      {repeaterSession.type == 'meeting'*/}
                {/*        ? 'Đây là buổi họp định kỳ'*/}
                {/*        : 'Đây là buổi học định kỳ'}*/}
                {/*      : <SessionRepeatSchedule session={repeaterSession} />{' '}*/}
                {/*      {isSessionCreator && (*/}
                {/*        <EditButton*/}
                {/*          session={repeaterSession}*/}
                {/*          hasPermUpdate*/}
                {/*          title={'Sửa lịch định kỳ'}*/}
                {/*          onEditSuccess={handleRefetch}*/}
                {/*        />*/}
                {/*      )}*/}
                {/*    </div>*/}
                {/*  </>*/}
                {/*) : null}*/}

                {lodashGet(session, 'current_user_can_join') ? (
                  joinButton
                ) : (
                  <Warning>{t1('current_session_is_not_public')}</Warning>
                )}
              </div>

              {!!showAlertPasscode && (
                <div>
                  <Alert
                    type={'error'}
                    showIcon
                    description={
                      <div>{t1('this_meeting_requires_a_pass_code')}</div>
                    }
                  />
                </div>
              )}

              <div className="table-list m-t-10">
                <Descriptions bordered size="middle" column={1}>
                  {/*
                  <Descriptions.Item label={`${t1('session_id')}`}>
                    {iid}
                  </Descriptions.Item>
                  */}
                  <Descriptions.Item label={`${t1('session_link')}`}>
                    {joinUrl}{' '}
                    <CopyClipboard value={joinUrl} showLabel={false} />
                  </Descriptions.Item>
                  )}
                  <Descriptions.Item label={`${t1('session_schedule')}`}>
                    {<ScheduledDateTime session={session} />}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={`${t1('session_duration')} (hh:mm)`}
                  >
                    {duration}
                  </Descriptions.Item>
                  <Descriptions.Item label={memberLabel}>
                    {Perm.isGuest()
                      ? t1('members_info_are_not_displayed_for_guest')
                      : renderMemberInfo()}
                  </Descriptions.Item>
                  <Descriptions.Item label={`${t1('session_creator')}`}>
                    {lodashGet(session, 'u.name')}
                  </Descriptions.Item>
                  <Descriptions.Item label={`${t1('download_materials')}`}>
                    {attachments && attachments.length > 0 ? (
                      <SessionDownloadMaterial attachments={attachments} />
                    ) : (
                      <>{t1('no_material')}</>
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SimpleNoResult
          text={t1('public_session_does_not_exist')}
          icon={'ve-lophocao'}
        />
      )}
    </div>
  );
};

const mapStateToProps = createSelector(
  (state) => lodashGet(state, 'domainInfo.conf.before_time_can_join_class'),
  (beforeCanJoinToLearn) => ({
    beforeCanJoinToLearn,
  }),
);

const fetchSession = fetchData((props) => {
  const { iid } = props;
  return {
    baseUrl: sessionUrls.get_session_api,
    params: {
      iid: iid,
      _sand_expand: [
        'creator',
        'course',
        //'teacher'
      ],
    },
    shouldRenderLoading: true,
    fetchFunctionPropKey: 'refetchSessionDetail',
    formatDataResult: (session) => {
      const info = lodashGet(session, '__expand', {});
      const { course = {}, teachers = [] } = info;

      return {
        session,
        course,
        teachers,
      };
    },
    refetchCondition: (prevProps) => {
      if (prevProps.iid != props.iid) {
        return true;
      }

      if (
        lodashGet(prevProps, 'userInfo.iid') != lodashGet(props, 'userInfo.iid')
      ) {
        return true;
      }

      return false;
    },
    serverTs: true,
  };
});
const fetchMyGroups = fetchData((props) => ({
  baseUrl: groupApiUrls.my_recent_groups,
  fetchCondition: true,
  propKey: 'groups',
  refetchCondition: () => false,
}));

export default compose(
  withConf('before_time_can_join_class', 'beforeCanJoinToLearn'),
  connect(mapStateToProps),
  withRouter,
  fetchSession,
  withUserInfo,
  fetchMyGroups,
)(MeetJoin);
