import { change } from 'redux-form';
import { questionTemplateTypes } from 'components/admin/question/schema/question-template-types';
import { displayTypes, answerTypes, OEType } from './schema/question-types';
import lodashGet from 'lodash.get';
import { isHTML } from 'common/utils/string';

export const checkConditionToShowQuestionFieldBaseOnTemplateType = (
  questionField,
  templateType,
  displayOrAnswer = 'display',
) => {
  switch (templateType) {
    case questionTemplateTypes.MC_ANSWER_TEXT: {
      if (displayOrAnswer === 'display') {
        return (
          [
            displayTypes.AUDIO,
            displayTypes.AVATAR,
            displayTypes.CONTENT,
          ].indexOf(questionField) !== -1
        );
      } else {
        return [answerTypes.TEXT].indexOf(questionField) !== -1;
      }
    }
    case questionTemplateTypes.MC_ANSWER_AVATAR: {
      if (displayOrAnswer === 'display') {
        return (
          [displayTypes.AUDIO, displayTypes.CONTENT].indexOf(questionField) !==
          -1
        );
      } else {
        return [answerTypes.AVATAR].indexOf(questionField) !== -1;
      }
    }
    case questionTemplateTypes.MC_ANSWER_AVATAR_TEXT: {
      if (displayOrAnswer === 'display') {
        return (
          [displayTypes.AUDIO, displayTypes.CONTENT].indexOf(questionField) !==
          -1
        );
      } else {
        return (
          [answerTypes.AVATAR, answerTypes.TEXT].indexOf(questionField) !== -1
        );
      }
    }
    case questionTemplateTypes.MC_ANSWERS_TEXT_AUDIO: {
      if (displayOrAnswer === 'display') {
        return (
          [displayTypes.AVATAR, displayTypes.CONTENT].indexOf(questionField) !==
          -1
        );
      } else {
        return (
          [answerTypes.AUDIO, answerTypes.TEXT].indexOf(questionField) !== -1
        );
      }
    }
    case questionTemplateTypes.MMC_ANSWER_TEXT: {
      if (displayOrAnswer === 'display') {
        return (
          [
            displayTypes.AUDIO,
            displayTypes.AVATAR,
            displayTypes.CONTENT,
          ].indexOf(questionField) !== -1
        );
      } else {
        return [answerTypes.TEXT].indexOf(questionField) !== -1;
      }
    }
    case questionTemplateTypes.MMC_ANSWER_AVATAR: {
      if (displayOrAnswer === 'display') {
        return (
          [displayTypes.AUDIO, displayTypes.CONTENT].indexOf(questionField) !==
          -1
        );
      } else {
        return [answerTypes.AVATAR].indexOf(questionField) !== -1;
      }
    }
    case questionTemplateTypes.MMC_ANSWER_AVATAR_TEXT: {
      if (displayOrAnswer === 'display') {
        return (
          [displayTypes.AUDIO, displayTypes.CONTENT].indexOf(questionField) !==
          -1
        );
      } else {
        return (
          [answerTypes.AVATAR, answerTypes.TEXT].indexOf(questionField) !== -1
        );
      }
    }
    case questionTemplateTypes.MMC_ANSWERS_TEXT_AUDIO: {
      if (displayOrAnswer === 'display') {
        return (
          [displayTypes.AVATAR, displayTypes.CONTENT].indexOf(questionField) !==
          -1
        );
      } else {
        return (
          [answerTypes.AUDIO, answerTypes.TEXT].indexOf(questionField) !== -1
        );
      }
    }
  }

  return true;
};

export const scoreFormulaValue = {
  BY_PART_QUESTION: 'by_part_question',
  BY_ENTIRE_QUESTION: 'by_entire_question',
};

export const question_types_mddm = {
  INLINE: 'inline',
  TABLE: 'table',
};

export const options_show_table_mddm = {
  hideFirstColumn: 'hidden_first_row',
  hideHeader: 'hidden_header',
};

export const onChangeSubTypeOfOEQuestion = (formId, dispatch, value) => {
  if (value === OEType.support_plan) {
    dispatch(change(formId, 'marking_pass_or_fail', 1));
  }
};

export const getValueUsingRteEditContentOfAnswerByAnswer2 = (question = {}) => {
  const answers = lodashGet(question, 'answers2');

  if (!Array.isArray(answers) || !answers.length) {
    return null;
  }

  let usedRte = 0;

  answers.forEach(({ text }) => {
    if (isHTML(text)) {
      usedRte = 1;
    }
  });

  return usedRte;
};
