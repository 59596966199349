import React from 'react';

const ButtonPrimary = ({ children, className, ...props }) => {
  const c = className ? `${className} btn btn-primary` : 'btn btn-primary';

  return (
    <button className={c} {...props}>
      {children}
    </button>
  );
};

export default ButtonPrimary;
