import React, { Component } from 'react';
import FlatButton from 'components/common/mui/NewButton';
import Icon from 'components/common/Icon';
import { withRouter } from 'react-router';

class PreviewExam extends Component {
  render() {
    return (
      <a href={'/template-review/' + this.props.template} target="_blank">
        <FlatButton
          name="submit"
          type="submit"
          icon={<Icon icon="plus" />}
          label={'Xem trước đề thi'}
        />
      </a>
    );
  }
}

export default withRouter(PreviewExam);
