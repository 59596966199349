import { call, put, takeEvery } from 'redux-saga/effects';
import formSubmitFunction from 'sagas/node/common/formSubmitFunction';
import { submit } from 'redux-form';
import sagaActions from 'actions/node/saga-creators';

function* formSubmitRequest(action) {
  const { formid, options, method, showSnackbar } = action;
  yield call(formSubmitFunction, formid, options, method, showSnackbar);
}

function* dispatchSubmitMultipleForm(action) {
  const { formIds } = action;

  if (!Array.isArray(formIds) || !formIds.length) {
  } else {
    let formid = null;
    const formids = [];
    formIds.forEach((id) => {
      if (!formid) {
        formid = id;
      } else {
        formids.push(id);
      }
    });

    yield put(submit(formid));
    yield put(sagaActions.submitMultipleForm(formids));
  }
}

export function* submitMultipleForm() {
  yield takeEvery('SUBMIT_MULTIPLE_FORM', dispatchSubmitMultipleForm);
}

export default function* formSubmitSaga() {
  yield takeEvery('FORM_SUBMIT_REQUEST', formSubmitRequest);
}
