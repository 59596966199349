import { t1 } from 'translate';

const TYPE_OF_TABLE_DATA = {
  TEXT: 'text',
  NUMBER: 'number',
  DATE: 'date',
  ACTION: 'action',
  TITLE: 'title',
};

export const getTypeOfTableOptions = () => {
  return Object.values(TYPE_OF_TABLE_DATA).map((value) => ({
    label: t1(value),
    value,
  }));
};

export default TYPE_OF_TABLE_DATA;
