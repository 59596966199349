import React from 'react';
import get from 'lodash.get';
import moment from 'moment';
import Store from 'store';
import {
  ASSESSMENT_STATUS,
  MANAGER_ASSESSMENT_GROUP_ROUTER,
  SUMMARY_ASSESSMENT_RESULT_ROUTER_GROUP_ROUTER,
} from './constant';
import {
  isAdminTruong,
  isAmTinh,
  isBo,
  isHieuTruong,
  isHieuTruongOrPho,
  isLTTUStaff,
  isPhong,
  isSo,
  isSoOrPhong,
  isTeacher,
  isVuGD,
} from 'components/admin/user/utils';
import { nonAccentVietnamese } from 'common/utils/string/vn';
import {
  breadCrumb,
  getValueOfBooleanString,
  shortTextByStringMapping,
  slugify,
} from 'common/utils/string';
import { getAssessmentPhaseNameOfCurrentUser } from 'components/common/scholastic-config/utils';
import { CONFIG_3B_STEP } from 'components/temis/sub-organization/config-3b/utils';
import Perm from 'common/utils/Perm';
import lodashGet from 'lodash.get';

export const getResultFromTheAssessment = (assessment) => {
  const scoreScale = getScoreScaleOfAssesment(assessment);
  let tasks = get(assessment, 'task');
  tasks = Array.isArray(tasks) ? tasks : [];

  if (!tasks.length || !Array.isArray(scoreScale) || !scoreScale.length) {
    return null;
  }

  const final = String(get(assessment, 'result.final'));
  return scoreScale.find(({ id }) => String(id) == final);
};

export const generateClassByStatusAssessment = (status) => {
  const standardsStatus = nonAccentVietnamese(status);

  return standardsStatus.replace(/\s/g, '');
};

export const getScoreScaleOfAssesment = (assessment) => {
  let parts = get(assessment, 'score_scale');

  if (Array.isArray(parts) && parts.length) {
    return parts;
  }

  return [
    { id: 0, name: 'Chưa đạt' },
    { id: 1, name: 'Đạt' },
    { id: 2, name: 'Khá' },
    { id: 3, name: 'Tốt' },
  ];
};

export const generateStatusResultAssessment = (statusEnum) => {
  if (statusEnum === undefined) {
    return;
  }
  switch (statusEnum) {
    case 0:
      return 'chưa đạt';
    case 1:
      return 'đạt';
    case 2:
      return 'khá';
    case 3:
      return 'tốt';
    default:
      return '';
  }
};

export const isApprovedAssessment = (status) => {
  return status === ASSESSMENT_STATUS.APPROVED;
};

export const getMenuTab = (userInfo, type) => {
  switch (type) {
    case 'manager-assessment':
      return isPhong(userInfo) || isSo(userInfo)
        ? [
            {
              link: MANAGER_ASSESSMENT_GROUP_ROUTER.school_in_organization,
              label: 'Các trường trong huyện/tỉnh',
            },
            {
              link: MANAGER_ASSESSMENT_GROUP_ROUTER.principal_assessment,
              label: 'Đánh giá hiệu trưởng',
            },
            // {
            //   link: MANAGER_ASSESSMENT_GROUP_ROUTER.choose_manager,
            //   label: 'Lựa chọn CBQLCC',
            // },
            // {
            //   link: MANAGER_ASSESSMENT_GROUP_ROUTER.approve_teacher,
            //   label: 'Duyệt GVCC',
            // },
          ]
        : [];
    case 'assessment-result':
      return [
        (isHieuTruongOrPho(userInfo) || isAdminTruong(userInfo)) && {
          link: SUMMARY_ASSESSMENT_RESULT_ROUTER_GROUP_ROUTER.school,
          label: 'Toàn trường',
        },
        // isHieuTruong(userInfo) && {
        //   link: SUMMARY_ASSESSMENT_RESULT_ROUTER_GROUP_ROUTER.choose_teacher,
        //   label: 'Lựa chọn GVCC',
        // },
      ].filter(Boolean);

    default:
      return [];
  }
};

const TC2Iid = '172548';
export const getAllTCInTC2 = (rubrics) => {
  if (!rubrics || !rubrics.length) {
    return;
  }

  const rubricTC2 = rubrics.find((rubric) => rubric.iid === TC2Iid);

  if (!rubricTC2) {
    return;
  }

  return rubricTC2.children;
};

const answerIsGood = 3;
const answerIsRather = 2;
const answerIsQualified = 1;

export const isGoodAssessment = (tc) => Number(tc.answer) === answerIsGood;
export const isRatherFinalAssessment = (final) =>
  Number(final) >= answerIsRather;
export const isFromQualifiedAssessment = (tc) =>
  Number(tc.answer) >= answerIsQualified;

const mainDay = 15;
const mainMonth = 7;
export const getDefaultStartDate = () => {
  const currentDate = moment().format('X');
  const mainDate = moment()
    .set('date', mainDay)
    .set('month', mainMonth)
    .format('X');

  let year = moment().get('year');

  if (currentDate < mainDate) {
    year = year - 1;
  }

  return moment()
    .set('date', mainDay)
    .set('month', mainMonth)
    .set('year', year)
    .format('X');
};

export const getDefaultEndDateBasedOnStartDate = (startDate) => {
  return moment(startDate * 1000)
    .add(1, 'year')
    .subtract(1, 'days')
    .format('X');
};

export const temisSystemText = 'Hệ thống quản lý thông tin GV và CBQLCSGD';

export const isEnableTemis = () => {
  return !!get(window, 'TEMIS_CONFIG.ENABLED');
};

export const isEnbaleIdentificationCode = () => {
  return !!get(window, 'enbaleIdentificationCode') || isIVAUser();
};

export const csdlnMoetURL = 'https://truong.csdl.moet.gov.vn';

export const getTemisCopyright = () => {
  return `© Copyright ${new Date().getFullYear()} - ${temisSystemText} - Bộ GDĐT-WB | Đơn vị đồng hành: Viettel | Hotline 18008000 Nhánh 2 (Miễn phí)`;
};

export const doesUserNeedToFillTemisNow = (conf, userInfo) => {
  const isGuest = !get(userInfo, 'id');
  const isUserMustEnterProfile = get(
    conf,
    'user_must_enter_temis_profile_info',
  );

  if (
    0 &&
    !isGuest &&
    !!isUserMustEnterProfile &&
    isTeacher(userInfo) &&
    !get(userInfo, 'phongban_id')
  ) {
    return true;
  }

  let bool =
    !!isUserMustEnterProfile &&
    !isLTTUStaff(userInfo) &&
    !isGuest &&
    !get(userInfo, 'can_skip_profile') &&
    (!getValueOfBooleanString(get(userInfo, 'have_enter_temis_profile_info')) ||
      getValueOfBooleanString(get(userInfo, 'force_re_enter_profile_info')));

  const isFinishTemisForm = JSON.parse(
    localStorage.getItem('isFinishTemisForm'),
  );

  return bool && !isFinishTemisForm;
};

export const generateReportTitle = (title = '', maxLength = 180) => {
  if (!title) {
    return '';
  }

  const titleToSlug = slugify(title);
  if (titleToSlug.length <= maxLength) {
    return titleToSlug;
  }

  return breadCrumb(titleToSlug, maxLength);
};

export const generateReportName = (fileName = '', orgShortName) => {
  let name = '';

  if (orgShortName) {
    name += `${shortTextByStringMapping(orgShortName)}_`;
  }

  if (fileName) {
    name += `${moment().format('DDMMYYYY')}_${fileName}`;
  }

  const assessmentName = getAssessmentPhaseNameOfCurrentUser();
  if (assessmentName) {
    name = `[${assessmentName}]_${name}`;
  }

  return name;
};

export const requiredEvidenceMustHaveFullFiles = () => {
  const currentSchoolYear = get(Store.getState(), 'scholasticConfig.config.id');
  return (
    currentSchoolYear !== get(window, 'TEMIS_CONFIG.SCHOLASTIC_20192020_ID')
  );
};

export const shouldShowViewReportMode = (user) =>
  isBo(user) || isSoOrPhong(user) || isLTTUStaff(user) || isAmTinh(user);

export const isOldBusiness = () => {
  const currentSchoolYear = get(Store.getState(), 'scholasticConfig.config.id');

  const oldBusinessesConfig = get(window, 'TEMIS_CONFIG.OLD_BUSINESSES', []);

  return oldBusinessesConfig.includes(currentSchoolYear);
};

const hasStep = (step) => ![null, undefined].includes(step);

export const isRequired3B = (step) => {
  let assessmentStep = step;
  if (!hasStep(step)) {
    assessmentStep = get(
      Store.getState(),
      'scholasticConfig.config.assessment_step',
    );
  }

  return (
    [null, undefined].includes(assessmentStep) ||
    assessmentStep == CONFIG_3B_STEP.FULL_ASSESSMENT
  );
};

export const isRequired1B = (step) => {
  let assessmentStep = step;
  if (!hasStep(step)) {
    assessmentStep = get(
      Store.getState(),
      'scholasticConfig.config.assessment_step',
    );
  }

  return isRequired3B(step) || assessmentStep == CONFIG_3B_STEP.SELF_ASSESSMENT;
};

export const MESSAGE_KEY = {
  notAssessment: 'not_assessment',
  oneStep: 'one_step_assessment',
  threeStep: 'three_step_assessment',
  lockAssessment: 'lock_assessment',
  lockedDataReport: 'lock_data_report',
  outDeadline: 'out_deadline',
};

export const isIVAUser = (userInfo) => {
  const userFromStore = get(Store.getState(), 'user.info');

  const user = userInfo || userFromStore;

  const userCode = get(user, 'code');

  return (window.LIST_IVA_CODE || []).includes(userCode);
};

const getAnswersByGradeOfConfig = (grades, answersByGradeOfQuestion = {}) => {
  let result = [];

  for (const grade in answersByGradeOfQuestion) {
    const values = answersByGradeOfQuestion[grade];

    if (!Array.isArray(grades) || !grades.length || grades.includes(grade)) {
      result = result.concat(values);
    }
  }

  return result;
};

export const filterAnswersOfQuestionByGradeOfConfig = (
  question = {},
  grades,
) => {
  const { id, answers2 } = question || {};

  const answersByGradeOfQuestion = get(
    window,
    `TEMIS_CONFIG.ANSWER_OF_SURVEY_QUESTION_BY_GRADE.${id}`,
  );

  if (
    !answersByGradeOfQuestion ||
    typeof answersByGradeOfQuestion != 'object' ||
    !Array.isArray(answers2) ||
    !answers2.length
  ) {
    return answers2;
  }

  const answersByConfig = getAnswersByGradeOfConfig(
    grades,
    answersByGradeOfQuestion,
  );

  return answers2.filter(({ value }) => answersByConfig.includes(value));
};

export const formatQuestionsToDisplay = (questions, grades) => {
  if (!Array.isArray(questions) || !questions.length) {
    return questions;
  }

  return questions.map((question) => {
    return Object.assign({}, question, {
      answers2: filterAnswersOfQuestionByGradeOfConfig(question, grades),
    });
  });
};

export const showSurveyManagement = (user) => {
  return (
    isVuGD(user) ||
    isAmTinh(user) ||
    isBo(user) ||
    isSoOrPhong(user) ||
    isAdminTruong(user) ||
    isHieuTruong(user) ||
    Perm.hasPerm('root')
  );
};

export const showContestManagement = (user) => {
  return isVuGD(user) || isBo(user) || Perm.hasPerm('root');
};

export const showPageManagement = (user) => {
  return isBo(user) || isVuGD(user) || Perm.hasPerm('root');
};

export const showMaterialsProgram = (user) => {
  return (
    isBo(user) || isVuGD(user) || isLTTUStaff(user) || Perm.hasPerm('root')
  );
};

export const showAdminMenu = (user) => {
  return (
    showSurveyManagement(user) ||
    showContestManagement(user) ||
    showPageManagement(user) ||
    showMaterialsProgram(user)
  );
};

const inactiveAccountReasons = {
  ACCOUNT_INACTIVE: 'account_inactive',
  ORGANIZATION_INACTIVE: 'organization_inactive',
};
export const checkInactiveAccountStatus = (userInfo, pathname) => {
  if (!pathname) {
    return false;
  }

  const temisInactiveAccountReasons = lodashGet(
    userInfo,
    'temis_inactive_account_reasons',
  );

  if (
    !Array.isArray(temisInactiveAccountReasons) ||
    !temisInactiveAccountReasons.length
  ) {
    return false;
  }

  return (
    temisInactiveAccountReasons.includes(
      inactiveAccountReasons.ACCOUNT_INACTIVE,
    ) ||
    temisInactiveAccountReasons.includes(
      inactiveAccountReasons.ORGANIZATION_INACTIVE,
    )
  );
};
