export const urls = {
  get_other_training_plans_options:
    '/taphuan-dai-tra-learning/index/get-other-training-plans-options',
  add_training_plan_planned_users:
    '/taphuan-dai-tra-learning/member/add-planned-users',
  remove_training_plan_planned_users:
    '/taphuan-dai-tra-learning/member/remove-planned-users',

  // clone planned users
  get_invalid_organizations_if_clone_planned_users:
    '/taphuan-dai-tra-learning/member/get-invalid-organizations-if-clone-planned-users',
  clone_planned_users_for_training_plan:
    '/taphuan-dai-tra-learning/member/clone-planned-users-for-training-plan',
  clone_planned_users_get_source_training_plan_options:
    '/taphuan-dai-tra-learning/member/clone-planned-users-get-source-training-plan-options',
  check_clone_planned_users_for_training_plan_status:
    '/taphuan-dai-tra-learning/member/check-clone-planned-users-for-training-plan-status',

  get_academic_categories_options:
    '/taphuan-dai-tra-learning/schema-form/get-academic-categories-options',

  // clone cụm trường
  clone_school_groups_for_training_plan:
    '/taphuan-dai-tra-learning/school-group/clone-school-groups-for-training-plan',
  get_clone_school_groups_for_training_plan_status:
    '/taphuan-dai-tra-learning/school-group/get-clone-school-groups-for-training-plan-status',
  clone_school_groups_get_source_training_plan_options:
    '/taphuan-dai-tra-learning/school-group/clone-school-groups-get-source-training-plan-options',
  clone_school_groups_get_target_organizations_options:
    '/taphuan-dai-tra-learning/school-group/clone-school-groups-get-target-organizations-options',
  clone_school_groups_get_target_enrolment_plans_options:
    '/taphuan-dai-tra-learning/school-group/clone-school-groups-get-target-enrolment-plans-options',

  // quản lý cụm trường
  search_enrolment_plan_to_edit_school_group:
    '/taphuan-dai-tra-learning/school-group/search-enrolment-plan-to-edit-school-group',
  get_enrolment_plan_school_group_overview:
    '/taphuan-dai-tra-learning/school-group/get-enrolment-plan-school-group-overview',
  get_enrolment_plan_academic_category_school_group:
    '/taphuan-dai-tra-learning/school-group/get-enrolment-plan-academic-category-school-group',
  get_school_options_for_school_group:
    '/taphuan-dai-tra-learning/school-group/get-school-options-for-school-group',
  upsert_school_group: '/taphuan-dai-tra-learning/school-group/upsert',
  split_course_by_school_group:
    '/taphuan-dai-tra-learning/school-group/split-course-by-school-group',

  remove_school_groups_for_training_plan:
    '/taphuan-dai-tra-learning/school-group/remove-school-groups-for-training-plan',

  get_split_course_by_school_group_info:
    '/taphuan-dai-tra-learning/school-group/get-split-course-by-school-group-info',

  // báo cáo phân cụm
  gvcc_school_group_report:
    '/taphuan-dai-tra-learning/school-group/gvcc-school-group-report',
  export_gvcc_school_group_report:
    '/taphuan-dai-tra-learning/school-group/export-gvcc-school-group-report',
  school_without_school_group_report:
    '/taphuan-dai-tra-learning/school-group/school-without-school-group-report',
  export_school_without_school_group_report:
    '/taphuan-dai-tra-learning/school-group/export-school-without-school-group-report',
  academic_categories_without_gvcc_report:
    '/taphuan-dai-tra-learning/school-group/academic-categories-without-gvcc-report',
  export_academic_categories_without_gvcc_report:
    '/taphuan-dai-tra-learning/school-group/export-academic-categories-without-gvcc-report',

  // màn hình phân tp_quota cho nhiều module
  get_children_organizations_quota_for_multiple_modules:
    '/taphuan-dai-tra-learning/tp-quota/get-children-organizations-quota-for-multiple-modules',
};

export default urls;
