/**
 * Created by Peter Hoang Nguyen on 4/13/2017.
 */
import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { loginOAuthSuccessRequest } from 'actions/auth/saga-creators';
import { layouts } from 'configs/constants';
import { getThemeConfig } from 'utils/selectors';
import { t1 } from 'translate';

class SocialAuth extends React.Component {
  style = { paddingLeft: 0 };

  constructor(props) {
    super(props);
    this.state = {};
  }

  responseGoogle = (response) => {
    const { dispatch } = this.props;
    dispatch(loginOAuthSuccessRequest('google', response.tokenId));
  };

  responseFacebook = (response) => {
    const { dispatch } = this.props;
    dispatch(loginOAuthSuccessRequest('facebook', response.accessToken));
  };

  render() {
    const {
      googleClientId,
      facebookAppId,
      viettelClientId,
      themeConfig,
      enableRegisters,
    } = this.props;
    if (!enableRegisters) return '';
    let numberOfLoginOptions = 0;
    if (enableRegisters.includes('register_facebook') && facebookAppId)
      numberOfLoginOptions += 1;
    if (enableRegisters.includes('register_google') && googleClientId)
      numberOfLoginOptions += 1;
    if (enableRegisters.includes('viettel') && viettelClientId)
      numberOfLoginOptions += 1;
    if (numberOfLoginOptions === 0) return '';

    const wrapperProperties = {
      className: `col-xs-${12 / numberOfLoginOptions}`,
      style:
        numberOfLoginOptions > 1 ? { padding: '0 15px 0 0' } : { padding: '0' },
    };

    return (
      <div className="center-block tools row" style={this.style}>
        {facebookAppId &&
          Array.isArray(enableRegisters) &&
          enableRegisters.includes('register_facebook') && (
            <div {...wrapperProperties}>
              <FacebookLogin
                appId={facebookAppId}
                fields="name,email,picture"
                textButton=" Facebook"
                callback={this.responseFacebook}
                cssClass="my-facebook-button-class"
                icon="fa-facebook"
              />
            </div>
          )}
        {googleClientId &&
          Array.isArray(enableRegisters) &&
          enableRegisters.includes('register_google') && (
            <div {...wrapperProperties}>
              <GoogleLogin
                clientId={googleClientId}
                buttonText={
                  <div>
                    <i className="fa fa-google-plus" aria-hidden="true" />{' '}
                    Google
                  </div>
                }
                className="another-login-icon googleplus"
                onSuccess={this.responseGoogle}
              />
            </div>
          )}
        {viettelClientId &&
          Array.isArray(enableRegisters) &&
          enableRegisters.includes('viettel') && (
            <div {...wrapperProperties}>
              <a
                href={`https://sso-edu.viettel.vn/?page=Account.App.login&userId=${viettelClientId}`}
                className="login-btn-with-viettel-sso"
              >
                <div>
                  <i className="fa fa-plus" aria-hidden="true" />{' '}
                  {t1('login_with_viettel_sso')}
                </div>
              </a>
            </div>
          )}
      </div>
    );
  }
}

SocialAuth.childContextTypes = {
  muiTheme: PropTypes.shape().isRequired,
};

SocialAuth.propTypes = {
  facebookAppId: PropTypes.string,
  googleClientId: PropTypes.string,
};

SocialAuth.defaultProps = {
  facebookAppId: '',
  googleClientId: '',
};

const mapStateToProps = (state, props) => {
  const conf = state.domainInfo.conf;
  const { themeConfig } = props;
  const appName = (themeConfig && themeConfig.layout) || 'pixelz';

  const facebookAppIdConfig = conf && conf['sso:facebook:app_id'];
  const facebookConfigByAppName =
    conf && conf['sso:facebook:config_by_app_name'];
  let facebookAppId =
    (facebookConfigByAppName &&
      facebookConfigByAppName[appName] &&
      facebookConfigByAppName[appName].app_id) ||
    facebookAppIdConfig;
  if (facebookAppId === 'none') {
    facebookAppId = null;
  }

  let googleClientId = conf && conf['sso:google:client_id'];
  if (googleClientId === 'none') {
    googleClientId = null;
  }

  const viettelClientId = get(conf, 'sso_viettel.client_id');

  return {
    googleClientId,
    facebookAppId,
    viettelClientId,
    enableRegisters: conf && conf.enable_registers,
    themeConfig: getThemeConfig(state),
  };
};

export default connect(mapStateToProps)(SocialAuth);
