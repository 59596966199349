import React from 'react';
import './stylesheet.scss';
import MDDMTableQuestion from 'components/common/forms/questions/mddm/MDDMTableQuestion';

const MDDM = (props) => {
  const [showError, setShowError] = React.useState(0);
  return (
    <MDDMTableQuestion
      {...props}
      setShowError={setShowError}
      showError={showError}
    />
  );
};
export default MDDM;
