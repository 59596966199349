export const MAX_CREDIT_OF_MODULE = 40;

export const isEnoughCredit = (credit, numberOfChuongTrinh = 1) =>
  credit >= MAX_CREDIT_OF_MODULE * numberOfChuongTrinh;

export const getTotalCredit = (modules) => {
  if (!modules || !modules.length) {
    return 0;
  }

  return (modules || []).filter(Boolean).reduce((total, currentObj) => {
    return total + currentObj.credit;
  }, 0);
};
