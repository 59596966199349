import React from 'react';
import { DefinedUrlParams } from 'routes/links/common';
import { connect } from 'react-redux';
import Join from './MeetJoin';
import fetchData from 'components/common/fetchData';
import apiUrl from 'components/front-end/meet/endpoints';
import { compose } from 'redux';
import lodashGet from 'lodash.get';

const MeetJoinOneMeeting = ({ iid, meetingPermissions }) => {
  const canCreateMeeting = lodashGet(meetingPermissions, 'create');
  return (
    <Join iid={iid} type={'meeting'} canShowDetailMembers={canCreateMeeting} />
  );
};

const mapStateToProps = (state, props) => {
  const { match } = props;
  const iid = match && match.params && match.params[DefinedUrlParams.IID];

  return {
    iid,
  };
};
const fetchHasPermissionCreateMeeting = fetchData((props) => ({
  baseUrl: apiUrl.meeting_permissions,
  fetchCondition: true,
  propKey: 'meetingPermissions',
  refetchCondition: () => false,
}));
export default compose(
  connect(mapStateToProps),
  fetchHasPermissionCreateMeeting,
)(MeetJoinOneMeeting);
