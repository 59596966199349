import React from 'react';
import { connect } from 'react-redux';
import hoistNonReactStatic from 'hoist-non-react-statics';
import lodashGet from 'lodash.get';

const checkStaff = () => (WrappedComponent) => {
  const Display = ({ isStaff, ...others }) => {
    return <WrappedComponent isStaff={isStaff} {...others} />;
  };

  const mapStateToProps = (state) => ({
    isStaff: lodashGet(state, 'domainInfo.isStaff'),
  });

  const CheckRootHOC = connect(mapStateToProps)(Display);

  hoistNonReactStatic(CheckRootHOC, WrappedComponent);

  return CheckRootHOC;
};

export default checkStaff;
