import React, { Component } from 'react';

import { Portal } from 'react-portal';
import Card from 'antd/lib/card';

class CommonPortal extends Component {
  render() {
    const { id, insideCard, title, className } = this.props;

    let content;
    if (insideCard)
      content = (
        <Card title={title} className={className}>
          {this.props.children}
        </Card>
      );
    else content = this.props.children;

    return (
      <Portal node={document && document.getElementById(id)}>{content}</Portal>
    );
  }
}

export default CommonPortal;

export const portals = {
  MARKING_ASSESSMENT_RESULT: 'assessment_of_teacher_and_peer_assessment',
  BREADCRUMB_THE_ITEM_IS_LEARNING: 'portals_breadcrumb-the-item-is-learning',
  QUESTION_NAVIGATIONS: 'portals_exercise-question-navigation',
  EXERCISE_POINT: 'portals_exercise-point',
  EXERCISE_TIMER: 'portals_exercise-timer',
  EXERCISE_FINISH_BUTTON: 'portals_exercise-finish-button',
  EXERCISE_QUESTION_SAVING_STATUS: 'portals_exercise-question-saving-status',
  LEARN_NAV: 'portals_learn_nav',
  VIDEO_TRANSCRIPT: 'portals_video_transcript',
  VIDEO_ATTACHED_SCO: 'portals_video_attached_sco',
  SP1_QUESTION_NAV_INSIDE_COURSE_LEARN_NAV:
    'sp1-question-nav-inside-course-nav',
  SP1_QUESTION_NAVIGATIONS_RIGHT_BAR: (id) =>
    `sp1-question-nav-right-side${id ? `-${id}` : ''}`,
  questionHeader: (id) => `question-header-${id}`,
  questionAction: (id) => `question-action-${id}`,
  questionScore: (id) => `question-score-${id}`,
  questionTimeSubmit: (id) => `question-time-submit-${id}`,
  questionPeerAssessmentPanel: (id) => `question-peer-assessment-panel-${id}`,
  EXERCISE_FINISH_REVIEW_BUTTON: 'portals_exercise-finish-review-button',
  tp_quota_for_org_overall_stats: (tpIid, orgIid) =>
    `tp_quota_for_org_overall_stats_${tpIid}_${orgIid}`,
  TEACHER_QUESTION_ANSWER: 'portals_teacher_question_answer',
  TEACHER_QUESTION_ANSWER_PAGINATION:
    'portals_teacher_question_answer_pagination',
  TEACHER_QUESTION_ANSWER_LOADING: 'portals_teacher_question_answer_loading',
  QUESTION_IN_VIDEO_TIME: 'portals_question-in-video-time',
  EXERCISE_STATUS_OF_QUESTIONS: 'portals-exercise-status-of-questions',
  EXERCISE_TAKE_HISTORIES: 'portals_exercise_take_histories',
  WARNING_NUMBER_OF_TIMES_DID_EXERCISE:
    'portal_warning_number_of_times_did_exercise',
};
