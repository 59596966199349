import { t1 } from 'translate';

export const getGuideStrings = (isGVSPRoute, isGVSPCC) => {
  return {
    notMarkedYet: {
      id: 'not_marked_yet',
      shortText: t1('not_marked_yet'),
      longText: t1(
        isGVSPRoute
          ? 'not_marked_yet_detail_only_gvcc_marked'
          : 'not_marked_yet_detail',
      ),
    },
    notSubmittedYet: {
      id: 'not_submitted_yet',
      shortText: t1('not_submitted_yet'),
      longText: t1('not_submitted_yet_detail'),
    },
    ...(isGVSPCC && !isGVSPRoute
      ? {}
      : {
          hasCommented: {
            id: 'gvsp_has_commented',
            shortText: t1('gvsp_has_commented'),
            longText: t1('gvsp_has_commented_detail'),
          },
        }),
    takeUpdated: {
      id: 'take_updated',
      shortText: t1('take_updated'),
      longText: t1(
        isGVSPRoute ? 'student_take_updated_detail' : 'take_updated_detail',
      ),
    },
    takeMarking: {
      id: 'take_marking',
      shortText: t1('take_marking'),
      longText: t1('take_marking_detail'),
    },
  };
};
