import React from 'react';
import { convertBooleanValueToInt } from 'common/normalizers';
import CheckboxElement from 'schema-form/elements/ant-checkbox';

/**
 * Always convert value to Int in our framework
 * @param label
 * @param fullWidth
 * @param classWrapper
 * @param rest
 * @returns {*&{fullWidth, classWrapper, normalize: ((function(*=): (number))|*), label, type: string}}
 * @constructor
 */
const checkbox = ({ label, fullWidth, classWrapper, readOnly, ...rest }) => {
  return {
    label,
    fullWidth,
    classWrapper,
    type: CheckboxElement,
    normalize: convertBooleanValueToInt,
    readOnly,
    ...rest,
  };
};

export default checkbox;
