import React from 'react';
import { t1 } from 'translate';
import NodeNew from 'components/admin/node/new';
import AskToParent from '../ask-to-parent';

const AssignToMaster = ({
  comment,
  searchFormCommentsId,
  viewOnly = false,
}) => {
  const formId = `assign_to_master${comment && comment.id}`;
  return (
    <NodeNew
      formid={formId}
      mode="edit"
      ntype="feedback"
      step="assign_to_master"
      node={comment}
      schema={{ schema: () => {}, ui: () => {} }}
      searchFormId={searchFormCommentsId}
      readOnly={viewOnly}
      submitButton={() => (
        <span className="comment-detail-assign-to-master">
          <AskToParent
            formId={formId}
            messageTitle={t1('are_you_sure_send_question_to_gvsp_title')}
            messageContent={t1('are_you_sure_send_question_to_gvsp_message')}
          >
            {t1('teacher_ask_master_reply')}
          </AskToParent>
        </span>
      )}
    />
  );
};

export default AssignToMaster;
