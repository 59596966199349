import React from 'react';
import AntDesignDateTimePicker, {
  modes as antDateTimePickerModes,
} from 'schema-form/elements/ant-date-time-picker';

const DatePicker = (props) => {
  const {
    defaultValue,
    minDate,
    maxDate,
    onChange,
    onBlur,
    getStartDate,
    getEndDate,
    errorText,
    fullWidth,
    floatingLabelText,
    value,
    className,
    style,
    disabled,
    allowClear,
    placeholder,
    size,
    formatDate,
    ...reduxFormElementProps
  } = props;

  return (
    <AntDesignDateTimePicker
      {...reduxFormElementProps}
      defaultValue={defaultValue}
      minDate={minDate}
      maxDate={maxDate}
      onChange={onChange}
      onBlur={onBlur}
      getStartDate={getStartDate}
      getEndDate={getEndDate}
      errorText={errorText}
      fullWidth={fullWidth}
      floatingLabelText={floatingLabelText}
      value={value}
      className={className}
      style={style}
      disabled={disabled}
      allowClear={allowClear}
      placeholder={placeholder}
      size={size}
      formatDate={formatDate}
      mode={antDateTimePickerModes.DATE_PICKER}
    />
  );
};

export default DatePicker;
