import React, { Component } from 'react';
import { t1 } from 'translate/index';
import getLodash from 'lodash.get';

class SessionTeachers extends Component {
  render() {
    const { session } = this.props;

    let teachers = getLodash(session, 'scheduled.teachers_info', {});
    if (teachers) {
      teachers = Object.values(teachers);
    }

    return teachers && teachers.length ? (
      teachers.map((teacher) => (
        <div className="session-teacher">{teacher.name}</div>
      ))
    ) : (
      <span>{t1('no_instructors')}</span>
    );
  }
}

export default SessionTeachers;
