// select() for saga
// http://stackoverflow.com/questions/37772877/how-to-get-something-from-the-state-store-inside-a-redux-saga-function
// https://github.com/redux-saga/redux-saga/tree/master/docs/api#selectselector-args
const getEditingItem = (state) => {
  const iid =
    state.editing.editingItemIid ||
    (state.editing.item && state.editing.item.iid);
  return state.tree[iid];
};

export const enrichEditingItem = (state) => {
  const node = getEditingItem(state) || {};

  const metadata = node['metadata'] || [];
  const children = node['children'] || [];

  if (!metadata.length && children.length) {
    node['metadata'] = children.map((child) => {
      if (typeof child === 'object' && child !== null) {
        return child;
      }

      return state.tree[child];
    });
  }

  return node;
};

export default getEditingItem;
