import React from 'react';
import { useDrop } from 'react-dnd';
import ImageBackGround from 'components/common/views/image-background';
import { isSmallScreen } from 'common';

const FixedItem = ({
  className,
  stt,
  accept,
  lastDroppedItem,
  onDrop,
  handleFixReorderClick,
  disabled,
  matched,
}) => {
  const cssClass = 'reorder-question-fixed-item';
  const componentClassName = `${className || ''} ${cssClass}`;

  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  let questionStatusIcon = [
    <span
      className={`question-status-icon ${
        !matched ? 've-close-outline' : 've-check'
      }`}
    />,
  ];

  return (
    <div
      ref={drop}
      className={componentClassName}
      onClick={() => handleFixReorderClick()}
    >
      {!lastDroppedItem ? (
        <>
          <span className={`${cssClass}__number`}>{stt}</span>
          {!!disabled && questionStatusIcon}
        </>
      ) : (
        <div className="main cursor-pointer">
          <div className="content">
            {lastDroppedItem.avatar && (
              <div className={`avatar`}>
                <ImageBackGround
                  height={
                    isSmallScreen && !lastDroppedItem.content ? '80' : '100'
                  }
                  src={lastDroppedItem.avatar}
                />
              </div>
            )}
            {lastDroppedItem.content && (
              <div className="content-text">
                <div title={lastDroppedItem.content}>
                  {lastDroppedItem.content}
                </div>
              </div>
            )}
            {!!disabled && questionStatusIcon}
          </div>
        </div>
      )}
    </div>
  );
};

export default FixedItem;
