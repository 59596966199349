import { t1 } from 'translate';
import lodashGet from 'lodash.get';
import { joinStatuses } from 'components/admin/session/utils';

export default (groups, sessionStatus) => {
  const shouldDisabledTimeField = [
    joinStatuses.JOIN_STATUS_OK,
    joinStatuses.JOIN_STATUS_TOO_LATE,
  ].includes(sessionStatus);

  const options = Array.isArray(groups)
    ? groups.map((group) => {
        return {
          label: `${group.name} (${t1('%d_members', [
            lodashGet(group, 'current_members', 0),
          ])})`,
          value: group.iid,
        };
      })
    : [];

  options.unshift({
    label: `---${t1('select_a_group')}---`,
    value: '',
  });

  return {
    type: 'select',
    options,
    // label: t1('choose_a_group'),
    disabled: shouldDisabledTimeField,
    floatingLabelText: t1('or_choose_one_of_your_group'),
  };
};
