import React from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import Results from 'components/bdtx/material-sales/search/Results';
import schema from './search-schema';
import endpoints from '../endpoints';
import withUserInfo from 'common/hoc/withUserInfo';

const FORM_ID = 'overview-module-purchased-material';

const MaterialPurchased = ({ userInfo, isPublic }) => {
  const renderResultComponent = (items, props) => {
    return <Results items={items} {...props} />;
  };

  return (
    <SearchWrapper
      ntype="module"
      formid={FORM_ID}
      renderResultsComponent={renderResultComponent}
      alternativeApi={endpoints.bdtx_get_deal}
      schema={schema}
      hiddenFields={{
        _sand_step: 'buyer',
        is_public: isPublic ? 1 : 0,
      }}
      userInfo={userInfo}
      autoSearchWhenStart
    />
  );
};

export default withUserInfo(MaterialPurchased);
