import { t1 } from 'translate';

export const statisticsItems = ({
  total_program = 0,
  total_program_queued = 0,
  total_program_approved = 0,
  total_review_program_approved = 0,
  total_review_program_rejected = 0,
} = {}) => [
  {
    id: 'all',
    title: t1('total_materials'),
    icon: 'read',
    value: total_program,
    colorByStatus: 'success',
    paramFilter: {
      status: null,
      review_status: null,
    },
  },
  {
    id: 'queued',
    title: t1('dhsp_editing_program'),
    icon: 'bell',
    value: total_program_queued,
    colorByStatus: 'queued',
    paramFilter: {
      status: ['queued'],
    },
  },
  {
    id: 'approved',
    title: t1('dhsp_approved_program'),
    icon: 'check-square',
    value: total_program_approved,
    paramFilter: {
      status: ['approved'],
    },
  },
  {
    id: 'review_rejected',
    title: t1('review_rejected'),
    icon: 'close-square',
    value: total_review_program_rejected,
    colorByStatus: 'rejected',
    paramFilter: {
      review_status: ['rejected'],
    },
  },
  {
    id: 'review_approved',
    title: t1('review_approved'),
    icon: 'check-square',
    value: total_review_program_approved,
    colorByStatus: 'approved',
    paramFilter: {
      review_status: ['approved'],
    },
  },
];

export const statisticsSyllabusItems = (syllabuses = []) => {
  const approved = syllabuses.filter(
    (syllabus) => syllabus.status === 'approved',
  ).length;
  const queued = syllabuses.filter((syllabus) => syllabus.status === 'queued')
    .length;

  return [
    {
      title: t1('total_materials'),
      icon: 'read',
      value: approved + queued,
      colorByStatus: 'success',
    },
    {
      title: t1('dhsp_editing_program'),
      icon: 'bell',
      value: queued,
      colorByStatus: 'queued',
    },
    {
      title: t1('dhsp_approved_program'),
      icon: 'check-square',
      value: approved,
      colorByStatus: 'approved',
    },
  ];
};
