import InputFile from 'schema-form/elements/input-file';
import { t1 } from 'translate';

const schema = () => {
  return {
    logo: {
      classWrapper: 'col-md-6',
      type: InputFile,
      fileType: 'image',
      maxSize: '15px',
      preview: 1,
      hintText: t1('organization_logo'),
      floatingLabelText: t1('organization_logo'),
      defaultValue: '',
      fullWidth: true,
    },
    mobile_logo: {
      classWrapper: 'col-md-6',
      type: InputFile,
      fileType: 'image',
      maxSize: '15px',
      preview: 1,
      hintText: t1('organization_mobile_logo'),
      floatingLabelText: t1('organization_mobile_logo'),
      defaultValue: '',
      fullWidth: true,
    },
    black_logo: {
      classWrapper: 'col-md-6',
      type: InputFile,
      fileType: 'image',
      maxSize: '15px',
      preview: 1,
      hintText: t1('organization_black_logo'),
      floatingLabelText: t1('organization_black_logo'),
      defaultValue: '',
      fullWidth: true,
    },
    footer_logo: {
      classWrapper: 'col-md-6',
      type: InputFile,
      fileType: 'image',
      maxSize: '15px',
      preview: 1,
      hintText: t1('organization_footer_logo'),
      floatingLabelText: t1('organization_footer_logo'),
      defaultValue: '',
      fullWidth: true,
    },
    favicon: {
      classWrapper: 'col-md-6',
      type: InputFile,
      fileType: 'image',
      preview: 1,
      maxSize: '15px',
      hintText: t1('organization_favicon'),
      floatingLabelText: t1('organization_favicon'),
      defaultValue: '',
      fullWidth: true,
    },
  };
};

const ui = () => {
  return [
    {
      id: 'default',
      wrapperClass: 'border m-15',
      fields: ['logo', 'mobile_logo', 'black_logo', 'footer_logo', 'favicon'],
    },
  ];
};
export default {
  schema,
  ui,
};
