import React from 'react';
import NodeNew from 'components/admin/node/new';
import defaultSchema from './schema';
import get from 'lodash.get';
import { connect } from 'react-redux';
import { t1 } from 'translate';

const Communication = ({
  hiddenFields,
  schema = defaultSchema,
  communicationOptions = [],
  ...remainProps
}) => {
  return (
    <NodeNew
      {...remainProps}
      hiddenFields={hiddenFields}
      mode="new"
      closeModal
      schema={schema}
      communicationOptions={communicationOptions}
      submitLabel={t1('send')}
    />
  );
};

const mapStateToProps = (state) => ({
  communicationOptions: get(
    state,
    'domainInfo.conf.delivery_methods_enable',
    [],
  ),
});

export default connect(mapStateToProps)(Communication);
