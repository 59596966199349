import axios from 'axios';
import React, { useEffect, useState } from 'react';
import lodashGet from 'lodash.get';
import { getUrlOrigin } from 'common/utils/url';
import { getScormManifestUrl } from 'components/admin/scorm/scorm';

async function getScormPlayerBaseLinks(scormOrigin) {
  const scormPlayerPathInServer = 'xscorm/index.html';

  const scormPlayerHostedInOriginLink = `${scormOrigin}/${scormPlayerPathInServer}`;

  let checkValid = false;
  try {
    await axios.get(scormPlayerHostedInOriginLink).then((response) => {
      const data = lodashGet(response, 'data');
      if (data.indexOf('SCORM Player')) {
        checkValid = true;
      }
    });
  } catch (e) {}

  if (checkValid) {
    return [scormPlayerHostedInOriginLink];
  }

  return [
    '/scorm/player2.html' /* support older versions */,
    `/${scormPlayerPathInServer}`,
  ];
}

const useScormPlayerLinks = (
  scormDirectoryUrl,
  scormEntryFile,
  eventSetDataKey = '',
  scormId = '',
) => {
  const [
    scormPlayerBaseLinkByScormOrigin,
    setScormPlayerBaseLinkByScormOrigin,
  ] = useState({});

  const scormOrigin = getUrlOrigin(scormDirectoryUrl);

  useEffect(
    () => {
      if (scormOrigin) {
        getScormPlayerBaseLinks(scormOrigin).then((links) => {
          setScormPlayerBaseLinkByScormOrigin((res) => ({
            ...res,
            [scormOrigin]: links,
          }));
        });
      }
    },
    [scormOrigin],
  );

  if (!scormOrigin) {
    return null;
  }

  const scormPlayerBaseLinks = lodashGet(
    scormPlayerBaseLinkByScormOrigin,
    scormOrigin,
  );

  if (!Array.isArray(scormPlayerBaseLinks) || !scormPlayerBaseLinks.length) {
    return null;
  }

  return scormPlayerBaseLinks.map((scormPlayerBaseLink) => {
    const scormPlayerHostedInLocal = scormPlayerBaseLink.indexOf('/') === 0;

    return `${scormPlayerBaseLink}?manifest=${getScormManifestUrl(
      scormDirectoryUrl,
      scormEntryFile,
      scormPlayerHostedInLocal,
    )}&eventSetDataKey=${eventSetDataKey}&scormId=${scormId}`;
  });
};

export default useScormPlayerLinks;
