import './stylesheet.scss';
import React, { useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import { render } from 'react-dom';
import moment from 'moment';
import { inputId, parseInlineQuestionRawText } from 'common/learn/Question';
import DisplayHtml from 'components/common/html';
import ErrorMessageQuestion from '../ErrorMessageQuestion';
import InlineInputTemplate from './InlineInputTemplate';
import { isInlineCorrect } from './util';

const Inline = (props) => {
  const {
    question,
    disabled,
    onCheckAnswer,
    practice,
    userAnswers,
    resetHighlights,
    setUserAnswers,
  } = props;

  const [showError, setError] = useState(0);
  const time = useRef(moment().valueOf());

  const parsed =
    question &&
    question.content &&
    parseInlineQuestionRawText(question.content, question, time.current);

  const {
    content,
    correctAnswers = [],
    correctAnswersAsArray,
    rawQuestion,
  } = parsed;

  const populateInlineContent = () => {
    if (correctAnswers) {
      correctAnswers.forEach((e, i) => {
        // inject element to DOM
        const ele = document.getElementById(inputId(i, question, time.current));
        ele &&
          render(
            <InlineInputTemplate
              {...props}
              disabled={disabled}
              onChange={handleChange}
              onCheckAnswer={onCheckAnswer}
              e={e}
              i={i}
              question={question}
            />,
            ele,
          );
      });
    }
  };

  const checkAnswer = debounce((value, index) => {
    if (practice) {
      const isCorrect = isInlineCorrect(question, value, index);
      setError((one) =>
        isCorrect ? -Math.abs(Math.abs(one) + 1) : Math.abs(Math.abs(one) + 1),
      );
    }
  }, 300);

  const handleChange = (value, index) => {
    const newUserAnswer = Object.assign([], userAnswers, { [index]: value });
    checkAnswer(value, index);
    setUserAnswers(newUserAnswer);
    resetHighlights();
  };

  const getMinHeighForSelectBoxAnswer = () => {
    let maxOptionsNumber = 1;
    if (Array.isArray(correctAnswersAsArray)) {
      correctAnswersAsArray.map((data, key) => {
        if (data.length == 1) {
          if (correctAnswers[key].answer.length > maxOptionsNumber) {
            maxOptionsNumber = correctAnswers[key].answer.length;
          }
        }
      });
    }
    return maxOptionsNumber * 25 + 'px';
  };

  useEffect(() => {
    populateInlineContent();
  });

  if (question.isVocabQuestion) {
    return (
      <div
        className="inline-question"
        style={{ minHeight: getMinHeighForSelectBoxAnswer(parsed) }}
      >
        {question.inlineText && (
          <div className="inline-text">{question.inlineText}</div>
        )}
        <div className="text-center vocab-input">
          <DisplayHtml content={rawQuestion} />
        </div>
      </div>
    );
  }

  return (
    <div
      className="inline-question"
      style={{ minHeight: getMinHeighForSelectBoxAnswer(parsed) }}
    >
      <ErrorMessageQuestion question={question} error={showError} />

      <DisplayHtml content={rawQuestion} />
    </div>
  );
};

export default Inline;
