import React from 'react';
import { t1 } from 'translate';
import { timestampToDateString } from 'common/utils/Date';

const lastTimeFinishedSyncing = (ts) => {
  if (!ts) {
    return null;
  }

  return (
    <span className="text-primary">{`${t1(
      'last_time_finished_syncing',
    )}: ${timestampToDateString(ts, {
      showTime: true,
    })}`}</span>
  );
};

export default lastTimeFinishedSyncing;
