/* eslint-disable no-undef */
import React, { useState } from 'react';
import get from 'lodash.get';
import { t1 } from 'translate';
import DownloadTemplate from '../import/DownloadTemplate';
//import { Redirect } from 'react-router-dom';
//import InputFile from 'schema-form/elements/input-file';
import fetchData from 'components/common/fetchData';
import UploadFileHook from './element/UploadFileHook';
import Select from 'antd/lib/select';
import Steps from 'antd/lib/steps';
import PreviewForm from './Preview';
import SearchUsers from 'components/admin/user/user-in-school/search';
import Radio from 'antd/lib/radio';
//import Table from 'antd/lib/table';
import Results from 'components/admin/user/account/common/AntdSearchResultIid';
import RequestButton from './element/RequestButton';
import message from 'antd/lib/message';
import endpoints from './endpoints';

const { Step } = Steps;
const { Option } = Select;

const fetchExamShift = (props) => ({
  baseUrl: endpoints.fetch_exam_shift,
  fetchCondition: (() => {
    return get(props, 'node.ntype', 'syllabus') === 'contest' ? true : false;
  })(),
  params: (() => {
    return {
      contest_iid: get(props, 'node.iid', 0),
      can_get_passed_exam_shift: 1,
      should_have_empty_value: 1,
      formid: 'user_search',
    };
  })(),
  propKey: 'exam_shift',
  refetchCondition: () => false,
});

const fetchExamSubject = fetchData((props) => {
  const apiData = {
    baseUrl: endpoints.fetch_exam_subject,
    fetchCondition: (() => {
      return get(props, 'node.ntype', 'syllabus') === 'contest' ? true : false;
    })(),
    params: (() => {
      return {
        contest_iid: get(props, 'node.iid', 0),
        should_have_empty_value: 1,
        formid: 'user_search',
      };
    })(),
    propKey: 'exam_subject',
    refetchCondition: () => false,
  };

  return {
    ...apiData,
  };
});

const SelectBox = (props) => {
  return (
    <Select
      className={props.className ? props.className : ''}
      showSearch={props.showSearch ? props.showSearch : true}
      style={props.style ? props.style : { width: 200 }}
      placeholder={
        props.placeholder
          ? props.placeholder
          : t1('select_box_default_placeholder')
      }
      optionFilterProp="children"
      onChange={props.onChange ? props.onChange : () => {}}
      onFocus={props.onFocus ? props.onFocus : () => {}}
      onBlur={props.onBlur ? props.onBlur : () => {}}
      onSearch={props.onSearch ? props.onSearch : () => {}}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      defaultValue={props.defaultValue ? props.defaultValue : ''}
    >
      {get(props, 'options', []).map((option) => {
        return <Option value={option.value}>{option.primaryText}</Option>;
      })}
    </Select>
  );
};

const RadioGroup = (props) => {
  const switchTab = (e) => {
    props.setHook(e.target.value);
  };

  return (
    <Radio.Group
      value={get(props, 'defaultValue', '')}
      className="m-b-15 p-b-10"
      style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}
      onChange={switchTab}
    >
      {get(props, 'options', []).map((option) => {
        return <Radio.Button value={option.value}>{option.label}</Radio.Button>;
      })}
    </Radio.Group>
  );
};

const StepProgress = (props) => {
  return (
    <Steps size="small" current={get(props, 'current', 0)}>
      {get(props, 'steps', []).map((step) => {
        return (
          <Step
            style={{ cursor: 'pointer' }}
            onClick={props.onClick}
            title={step.title}
          />
        );
      })}
    </Steps>
  );
};

const renderResultComponent = (
  items,
  props,
  objects,
  searchValues,
  examSubject,
  examShift,
  contestIid,
) => {
  return (
    <Results
      items={items}
      {...props}
      searchFormId="account_search"
      renderAfter={({ selectedRowKeys }) => {
        return (
          <RequestButton
            url={endpoints.create_contestant}
            params={{
              contest_iid: contestIid,
              user_iids: selectedRowKeys,
              exam_subject: examSubject,
              exam_shift: examShift,
            }}
            label={t1('add_%s_user_to_contest', [selectedRowKeys.length])}
            onRequestSuccess={(result) => {
              message.success(
                t1('add_%s_user_to_contest_successful', [
                  selectedRowKeys.length,
                ]),
              );
            }}
            style={{ float: 'right' }}
            className="m-t-5"
          />
        );
      }}
    />
  );
};

const StepOne = (props) => {
  const IncreaseStep = (e) => {
    if (props.examShiftHook.data != '' && props.examSubjectHook.data != '') {
      props.stepHook(props.current + 1);
    }
  };
  return (
    <div className="col-md-12 p-20">
      <div className="col-md-offset-3 col-md-6">
        <SelectBox
          style={{ width: '100%' }}
          placeholder={t1('select_exam_shift')}
          onChange={(result) => {
            props.examShiftHook.hook(result);
          }}
          defaultValue={props.examShiftHook.data}
          options={props.examShiftList}
        />
        <SelectBox
          className="m-t-15"
          style={{ width: '100%' }}
          placeholder={t1('select_exam_subject')}
          onChange={(result) => {
            props.examSubjectHook.hook(result);
          }}
          defaultValue={props.examSubjectHook.data}
          options={props.examSubjectList}
        />
        <button
          className={
            props.examShiftHook.data != '' && props.examSubjectHook.data != ''
              ? 'btn ant-btn ant-btn-primary btn-primary m-t-25'
              : 'btn ant-btn ant-btn-primary btn-secondary m-t-25 disabled'
          }
          style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
          onClick={IncreaseStep}
        >
          {t1('next')}
        </button>
      </div>
    </div>
  );
};

const StepTwo = (props) => {
  const [mode, setMode] = useState('import');
  const [importId, setImportId] = useState('');
  const [userIidList, setUserIidList] = useState([]);
  const modeList = [
    { value: 'import', label: t1('import_contestant_from_file') },
    { value: 'invite', label: t1('select_contestant_from_search') },
  ];
  const IncreaseStep = (e) => {
    props.stepHook(props.current + 1);
  };
  const DecreaseStep = (e) => {
    props.stepHook(props.current - 1);
  };
  return (
    <div className="col-md-12 p-30">
      <div className="text-center">
        <RadioGroup options={modeList} defaultValue={mode} setHook={setMode} />
      </div>
      {mode === 'import' ? (
        importId != '' ? (
          <PreviewForm importId={importId} node={props.node} />
        ) : (
          <>
            <div className="text-center m-t-15">
              <UploadFileHook
                onUploadSuccessful={(result) => {
                  setImportId(get(result, 'import_id', ''));
                }}
                exam_shift={get(props, 'examShiftHook.data', 0)}
                exam_subject={get(props, 'examSubjectHook.data', 0)}
                contest_iid={get(props, 'node.iid', 0)}
                contest_id={get(props, 'node.id', 0)}
              />
              <DownloadTemplate
                template={get(props, 'node.iid') && 'import-users-in-node'}
                icon="cloud-download"
              />
            </div>
          </>
        )
      ) : (
        <></>
      )}
      {mode === 'invite' ? (
        <SearchUsers
          onResultChange={(newItems) => {}}
          renderResultsComponent={(items, propsmini, objects, searchValues) =>
            renderResultComponent(
              items,
              propsmini,
              objects,
              searchValues,
              get(props, 'examSubjectHook.data', 0),
              get(props, 'examShiftHook.data', 0),
              get(props, 'node.iid', 0),
            )
          }
        />
      ) : (
        <></>
      )}
      <div className="col-md-offset-3 col-md-6">
        <div className="text-center" style={{ display: 'none' }}>
          <button
            className="btn ant-btn ant-btn-primary btn-secondary m-t-25 m-r-15"
            onClick={DecreaseStep}
          >
            {t1('back')}
          </button>
          <button
            className="btn ant-btn ant-btn-primary btn-secondary m-t-25"
            onClick={IncreaseStep}
          >
            {t1('next')}
          </button>
        </div>
      </div>
    </div>
  );
};

const MainContent = (props) => {
  switch (props.current) {
    case 0:
      return (
        <StepOne
          current={props.current}
          stepHook={props.stepHook}
          examShiftList={props.examShiftList}
          examSubjectList={props.examSubjectList}
          examShiftHook={props.examShiftHook}
          examSubjectHook={props.examSubjectHook}
        />
      );
    case 1:
      return (
        <StepTwo
          current={props.current}
          stepHook={props.stepHook}
          examShiftList={props.examShiftList}
          examSubjectList={props.examSubjectList}
          examShiftHook={props.examShiftHook}
          examSubjectHook={props.examSubjectHook}
          {...props}
        />
      );
    case 2:
      return <></>;
  }
};

const ImportForm = (props) => {
  const [examShift, setExamShift] = useState('');
  const [examSubject, setExamSubject] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const stepsList = [
    { title: t1('shift_and_round') },
    { title: t1('select_contestant') },
  ];
  const hiddenFields = get(props, 'node.iid')
    ? {
        import_for_item: {
          id: get(props, 'node.id'),
          iid: get(props, 'node.iid'),
          ntype: get(props, 'node.ntype'),
        },
      }
    : {};

  const resetForm = (e) => {
    setExamShift('');
    setExamSubject('');
    setCurrentStep(0);
  };

  return (
    <div
      className="col-md-12 p-20"
      style={{ backgroundColor: '#fff', borderRadius: '5px' }}
    >
      <div className="row">
        <div className="col-md-12">
          <StepProgress
            current={currentStep}
            steps={stepsList}
            onClick={resetForm}
          />
        </div>
        <div className="col-md-12 m-t-15">
          <MainContent
            current={currentStep}
            steps={stepsList}
            stepHook={setCurrentStep}
            examShiftList={get(props, 'exam_shift', [])}
            examSubjectList={get(props, 'exam_subject', [])}
            examShiftHook={{ data: examShift, hook: setExamShift }}
            examSubjectHook={{ data: examSubject, hook: setExamSubject }}
            {...props}
          />
        </div>
      </div>
    </div>
  );
};

export default fetchData(fetchExamShift)(fetchExamSubject(ImportForm));
