import React from 'react';
import lodashGet from 'lodash.get';

import { t1 } from 'translate';
import withUserInfo from 'common/hoc/withUserInfo';
import { getSchoolYearOfCurrentUser } from 'components/common/scholastic-config/utils';
import { isBo } from 'components/admin/user/utils';
import NewTrainingPhase from 'components/bdtx/training-phase/mainstage/new';
import { steps } from 'components/bdtx/training-phase/steps/utils';

const BaseInformationTrainingPhase = ({
  trainingPhase,
  onUpdateTrainingPhase,
  handleOnActiveStep,
  nextStepIndex,
  userInfo,
}) => {
  const onUpdateData = (data) => {
    if (typeof onUpdateTrainingPhase === 'function') {
      onUpdateTrainingPhase(data);
    }

    if (nextStepIndex && typeof handleOnActiveStep === 'function') {
      handleOnActiveStep(nextStepIndex);
    }
  };

  const hiddenFields = {
    school_year: getSchoolYearOfCurrentUser(),
    current_step: steps.base_information,
  };

  return (
    <>
      <NewTrainingPhase
        node={trainingPhase}
        hiddenFields={hiddenFields}
        onNewSuccessful={onUpdateData}
        onEditSuccessful={onUpdateData}
        submitLabel={t1('next_step')}
        closeModal={false}
        deployed={lodashGet(trainingPhase, 'status.deployed')}
        isBgd={isBo(userInfo)}
        turnOffNotifications
      />
    </>
  );
};

export default withUserInfo(BaseInformationTrainingPhase);
