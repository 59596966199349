import React, { Component } from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import NewButton from 'components/common/primary-button';
import Form from './Form';

class ButtonSendNotification extends Component {
  renderPreview = ({ showFull }) => {
    const { className, primary, label, hiddenFields } = this.props;

    const { itemSelected, totalResult } = hiddenFields;

    return (
      <NewButton
        disabled={!itemSelected && !totalResult}
        onClick={showFull}
        primary={primary}
        className={className}
        label={label}
        {...this.props}
      />
    );
  };
  getDialogKey = () =>
    this.props.formid || 'enrolment_plan_members_batch_action';
  renderFull = () => {
    const { itemSelected, searchFormId, requestSuccessful } = this.props;
    return (
      <Form
        {...this.props}
        dialogKey={this.getDialogKey()}
        itemSelected={itemSelected}
        searchFormId={searchFormId}
        requestSuccessful={requestSuccessful}
      />
    );
  };
  dialogOptionsProperties = () => ({
    title: this.props.dialogTitle,
  });
  render() {
    const { renderPreview } = this.props;
    return (
      <DetailOnDialog
        {...this.props}
        dialogOptionsProperties={this.dialogOptionsProperties()}
        dialogKey={this.getDialogKey()}
        renderPreview={renderPreview || this.renderPreview}
        renderFull={this.renderFull}
      />
    );
  }
}
export default ButtonSendNotification;
