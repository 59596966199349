//Using for Import user to contest
import React from 'react';
import { connect } from 'react-redux';
import { t1 } from 'translate';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './stylesheet.scss';

import Positions from 'components/admin/group/edit/member/search-results/Positions';
import OrganizationsOrPhongBan from 'components/admin/group/common/OrganizationsOrPhongBanInResultTable';
import OrganizationsParent from 'components/admin/group/common/OrganizationParent';
import actions from 'actions/node/creators';
import DeleteItem from 'components/common/action-button/DeleteBtnWithConfirmDialog';
import { userPreviewLink } from 'components/admin/user/utils';
import { timestampToDateString } from 'common/utils/Date';
import Perm from 'common/utils/Perm';
import Avatar from 'antd/lib/avatar';
import SuccessAlert from 'components/common/SuccessAlert';
import Warning from 'components/common/Warning';
import lodashGet from 'lodash.get';
import subTypes from 'configs/constants/org-sub-types';
import CommonAntdTable from 'components/common/antd/table';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';

const deleteUserLabel = t1('delete');
const confirmDeleteUserLabel = t1('are_you_sure_you_want_to_do_this');

const label = {
  iidCode: t1('code'),
  name: t1('name'),
  mail: t1('mail'),
  status: t1('status'),
  actions: t1('actions'),
  organization: t1('organization'),
  parent_organization: 'Trực thuộc đơn vị',
  created_date: t1('created_date'),
};

export const columnIds = {
  CODE: 'CODE',
  NAME: 'NAME',
  MAIL: 'MAIL',
  ORGANIZATION: 'ORGANIZATION',
  PARENT_ORGANIZATION: 'PARENT_ORGANIZATION',
  CREATED_DATE: 'CREATED_DATE',
  IS_STAFF: 'IS_STAFF',
  STATUS: 'STATUS',
  ACTIONS: 'ACTIONS',
};

const getUserSubType = (userInfo = {}) =>
  lodashGet(userInfo, 'user_organizations_info[0].sub_type');

const AntdSearchResult = ({
  triggerResetSelection,
  resetSelectionWhenItemIdsChange,
  items,
  renderAfter,
  searchFormId,
  formid,
  domain,
  showDeleteAction,
  columnsToShow,
  addExtraColumns,
  selectable = true,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

  const triggerResetSelectionWhenItemIdsChange = resetSelectionWhenItemIdsChange
    ? (items || []).map((item) => lodashGet(item, 'id')).join()
    : '';

  React.useEffect(
    () => {
      setSelectedRowKeys([]);
    },
    [triggerResetSelection, triggerResetSelectionWhenItemIdsChange],
  );

  let columns = [
    {
      id: columnIds.CODE,
      title: label.iidCode,
      width: '10%',
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (item) => (
        <Link to={userPreviewLink(item, 'user', 'view')}>{item.code}</Link>
      ),
    },
    {
      id: columnIds.NAME,
      title: label.name,
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (item) => (
        <>
          {item.avatar && <Avatar src={item.avatar} />}&nbsp;
          <Link to={userPreviewLink(item, 'user', 'view')}>{item.name}</Link>
        </>
      ),
    },
    {
      id: columnIds.MAIL,
      title: label.mail,
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (item) => (
        <Link to={userPreviewLink(item, 'user', 'view')}>{item.mail}</Link>
      ),
    },
    {
      id: columnIds.ORGANIZATION,
      title: label.organization,
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (item) =>
        [
          item.positions && !!item.positions.length && (
            <Positions item={item} textOnly={true} />
          ),
          item.user_organizations && !!item.user_organizations.length && (
            <OrganizationsOrPhongBan
              item={item}
              attr={'user_organizations'}
              showParentsInfo
            />
          ),
          item.phongbans && !!item.phongbans.length && (
            <OrganizationsOrPhongBan
              item={item}
              attr={'phongbans'}
              showParentsInfo={false}
            />
          ),
        ].filter(Boolean),
    },
    ...(window.isETEP
      ? [
          {
            id: columnIds.PARENT_ORGANIZATION,
            title: label.parent_organization,
            type: TYPE_OF_TABLE_DATA.TEXT,
            render: (item) => (
              <OrganizationsParent
                item={item}
                attr={'user_organizations'}
                parentSubTypesToShow={[
                  subTypes.TAPHUAN_SUBTYPE_PHONG_GD,
                  subTypes.TAPHUAN_SUBTYPE_SO_GD,
                ]}
              />
            ),
          },
        ]
      : []),
    {
      id: columnIds.CREATED_DATE,
      title: label.created_date,
      type: TYPE_OF_TABLE_DATA.DATE,
      render: (item) => timestampToDateString(item.ts, { type: 'full_date' }),
    },
    ...(searchFormId === 'account_search'
      ? [
          {
            id: columnIds.IS_STAFF,
            title: t1('is_staff'),
            type: TYPE_OF_TABLE_DATA.TEXT,
            render: (item) =>
              Perm.hasPerm('staff', domain, item) ? (
                <SuccessAlert>{t1('yes')}</SuccessAlert>
              ) : (
                t1('no')
              ),
          },
          {
            id: columnIds.STATUS,
            title: label.status,
            type: TYPE_OF_TABLE_DATA.TEXT,
            render: (item) =>
              item.status && item.status === 'activated' ? (
                <SuccessAlert>{t1(item.status)}</SuccessAlert>
              ) : (
                <Warning>{t1('unactivated')}</Warning>
              ),
          },
          ...(showDeleteAction
            ? [
                {
                  id: columnIds.ACTIONS,
                  title: label.actions,
                  type: TYPE_OF_TABLE_DATA.ACTION,
                  render: (item) => [
                    <div className="d-flex justify-content-center">
                      <DeleteItem
                        title={deleteUserLabel}
                        textConfirm={confirmDeleteUserLabel}
                        formid={formid}
                        ntype={'user'}
                        itemId={item.id}
                        iconButton
                      />
                    </div>,
                  ],
                },
              ]
            : []),
        ]
      : []),
  ].filter((col) => {
    return (
      !Array.isArray(columnsToShow) ||
      columnsToShow.includes(lodashGet(col, 'id'))
    );
  });

  if (typeof addExtraColumns === 'function') {
    columns = addExtraColumns(columns);
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const idInCurrentPage = Array.isArray(items)
    ? items.map(({ iid }) => iid)
    : [];

  return (
    <div className="table-result" style={{ width: '100%' }}>
      <CommonAntdTable
        bordered
        rowKey="iid"
        size="middle"
        pagination={false}
        childrenColumnName={null}
        style={{ background: 'white' }}
        columns={columns}
        className="learn-default"
        dataSource={Array.isArray(items) ? items : []}
        rowSelection={
          selectable
            ? {
                selectedRowKeys,
                hideDefaultSelections: true,
                onChange: onSelectChange,
                selections: [
                  {
                    key: 'select_current_page',
                    text: t1('select_data_in_current_page'),
                    onSelect: () => {
                      setSelectedRowKeys((currentSelectedRowKeys) => {
                        return (currentSelectedRowKeys || []).concat(
                          idInCurrentPage
                            .map(
                              (iid) =>
                                !currentSelectedRowKeys.includes(iid) && iid,
                            )
                            .filter(Boolean),
                        );
                      });
                    },
                  },
                  {
                    key: 'invert_current_page',
                    text: t1('invert_data_in_current_page'),
                    onSelect: () => {
                      setSelectedRowKeys((currentSelectedRowKeys) => {
                        return idInCurrentPage.filter(
                          (iid) =>
                            !(currentSelectedRowKeys || []).includes(iid),
                        );
                      });
                    },
                  },
                  Array.isArray(selectedRowKeys) &&
                    !!selectedRowKeys.length &&
                    !selectedRowKeys.every((iid) =>
                      idInCurrentPage.includes(iid),
                    ) && {
                      key: 'remove_all',
                      text: t1('remove_all_data_selected'),
                      onSelect: () => {
                        setSelectedRowKeys([]);
                      },
                    },
                ].filter(Boolean),
              }
            : null
        }
      />
      {typeof renderAfter === 'function'
        ? renderAfter({ selectedRowKeys })
        : null}
    </div>
  );
};

AntdSearchResult.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
  searchFormId: PropTypes.string,
};

AntdSearchResult.defaultProps = {
  items: [],
  searchFormId: '',
};

function mapStateToProps(state, props) {
  const domainInfo = state.domainInfo;
  return {
    domain: domainInfo && domainInfo.domain,
  };
}

export default connect(mapStateToProps)(AntdSearchResult);
