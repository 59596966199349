import React from 'react';

const Button = ({ children, className, ...props }) => {
  const c = className ? `${className} btn` : 'btn';

  return (
    <button className={c} {...props}>
      {children}
    </button>
  );
};

export default Button;
