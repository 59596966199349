import React, { Component } from 'react';
import NodeNew from 'components/admin/node/new';
import sessionSchema from '../schema/form';
import meetSchema from 'components/front-end/meet/new/schema';
import apiUrls from '../endpoints';
import Loading from 'components/common/loading/index';
import { get } from 'common/utils/object';
import { convertTSToTSVNTime } from 'common/utils/Date';
import {
  getCourseIidFromParentNode,
  getEpIidFromParentNode,
  getTpIidFromParentNode,
  isMeeting,
  showTestingSessionWarning,
} from '../utils';

class SessionEdit extends Component {
  render() {
    const {
      id,
      userIid,
      viewOnly,
      course,
      parentNode,
      sessionStatus,
      type,
      groups,
      onRequestSuccessful,
    } = this.props;
    let { node } = this.props;
    const searchFormId = this.props.searchFormId || `edit_session_${id}`;

    // Convert date_time field to VN time to show on date picker
    const scheduledDateTime = get(node, 'scheduled.date_time');
    if (typeof scheduledDateTime === 'number') {
      node = {
        ...node,
        scheduled: {
          ...node.scheduled,
          date_time: scheduledDateTime
            ? convertTSToTSVNTime(scheduledDateTime)
            : null,
          learn_duration: node.learn_duration,
        },
      };
    }

    if (!node && !id) {
      return <Loading circularLoadingIcon />;
    }

    const courseIid = getCourseIidFromParentNode(parentNode);
    const epIid = getEpIidFromParentNode(parentNode);
    const tpIid = getTpIidFromParentNode(parentNode);

    // nếu đang đứng ở trong 1 course nào đó và tạo LHA thì sẽ là tạo cho course đó
    // nếu không thì trên UI sẽ có element chọn course
    const hiddenFields = {};
    if (courseIid) {
      hiddenFields.course_iids = [courseIid];
    }

    if (epIid) {
      hiddenFields.enrolment_plan_iid = epIid;
    }

    if (tpIid) {
      hiddenFields.training_plan_iid = tpIid;
    }

    return (
      <div>
        <NodeNew
          ntype="session"
          schema={isMeeting(type) ? meetSchema : sessionSchema}
          step={''}
          mode={'edit'}
          formid="session-manage"
          closeModal
          alternativeApi={apiUrls.session_update}
          searchFormId={searchFormId}
          node={node}
          hiddenFields={hiddenFields}
          userIid={userIid}
          hideSubmitButton={viewOnly}
          dialogKey="edit_session"
          isCourseTest={get(course, 'is_testing')}
          isTestingChanged={(event, isTesting) =>
            showTestingSessionWarning(event, isTesting)
          }
          parentNode={parentNode}
          sessionStatus={sessionStatus}
          type={type}
          groups={groups}
          requestSuccessful={onRequestSuccessful}
        />
      </div>
    );
  }
}

export default SessionEdit;
