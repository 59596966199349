import Modal from 'antd/lib/modal';
import { t1 } from 'translate';
import sagaActions from 'actions/node/saga-creators';
import endpoints from 'components/etep/endpoints';

export const confirmRestoreFromHistory = (
  dispatch,
  takeId,
  takeHistoryId,
  searchFormId,
  requestSuccessful,
  requestFailedCallback,
) => {
  Modal.confirm({
    centered: true,
    title: t1('are_you_sure_to_restore_this_take?'),
    cancelText: t1('cancel'),
    okText: 'OK',
    onOk() {
      restoreFromHistory(
        dispatch,
        takeId,
        takeHistoryId,
        searchFormId,
        requestSuccessful,
        requestFailedCallback,
      );
    },
  });
};

export const restoreFromHistory = (
  dispatch,
  takeId,
  takeHistoryId,
  searchFormId,
  requestSuccessful,
  requestFailedCallback,
) => {
  dispatch(
    sagaActions.updateNodeRequest({
      alternativeApi: endpoints.restore_take,
      ntype: 'take',
      data: {
        take_id: takeId,
        history_id: takeHistoryId,
      },
      searchFormId,
      mode: 'new',
      requestSuccessful,
      requestFailedCallback,
    }),
  );
};
