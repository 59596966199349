import React from 'react';
import { t1 } from 'translate';
import DetailOnDialog from 'components/common/detail-on-dialog';
import {
  getItemAttachment,
  isPicture,
} from 'components/common/forms/questions/open-ended/attachment-viewer';

const PreviewOneAttachmentButton = ({
  file,
  viewOptions,
  showImageAttachmentContentWithControlsMode = false,
  classNameForButton,
  dialogKey,
}) => {
  const itemView = getItemAttachment(file, viewOptions);
  const { contentItem } = itemView;

  const dialogClassName = () => {
    let ret = 'attachment-preview-dialog';
    if (isPicture(file.ext) && showImageAttachmentContentWithControlsMode) {
      ret = `${ret} image-with-controls-dialog`;
    }

    return ret;
  };

  if (!contentItem) {
    return null;
  }

  return (
    <DetailOnDialog
      renderPreview={({ showFull }) => (
        <button
          className={`btn btn-primary btn-small btn-outline ${classNameForButton}`}
          onClick={showFull}
        >
          {t1('preview')}
        </button>
      )}
      dialogKey={dialogKey}
      renderFull={() => <>{contentItem}</>}
      fullScreen={false}
      dialogOptionsProperties={{
        className: dialogClassName(),
      }}
    />
  );
};

export default PreviewOneAttachmentButton;
