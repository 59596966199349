import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/common/Icon';

const styleProps = {
  color: 'green',
};

const SuccessAlert = (props) => {
  const { inline, className, style, children, withIcon, ...rest } = props;
  if (inline) {
    return (
      <span
        className={`warning-item ${className}`}
        style={Object.assign({}, styleProps, style)}
        {...rest}
      >
        {withIcon ? <Icon icon="ok" /> : ''} {children}
      </span>
    );
  } else
    return (
      <div
        className={`warning-item ${className}`}
        style={Object.assign({}, styleProps, style)}
        {...rest}
      >
        {withIcon ? <Icon icon="ok" /> : ''} {children}
      </div>
    );
};

// // withIcon =
SuccessAlert.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  inline: PropTypes.bool,
  style: PropTypes.string,
  withIcon: PropTypes.bool,
};

SuccessAlert.defaultProps = {
  className: '',
};

export default SuccessAlert;
