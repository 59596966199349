import { t1 } from 'translate';

const videoTypes = {
  TYPE_TEXT: 'text',
  TYPE_VIDEO: 'video',
  TYPE_PDF: 'pdf',
  TYPE_PPT: 'ppt',
  TYPE_WORD: 'word',
  TYPE_EXCEL: 'excel',
  TYPE_IMAGE: 'image',
  TYPE_AUDIO: 'audio',
  TYPE_SWF: 'swf',
  TYPE_LIST: 'list',
  TYPE_QUOTE: 'quote',
  TYPE_CHART: 'chart',
  TYPE_FLASHCARD: 'flashcard',
  TYPE_GALLERY: 'gallery',
  TYPE_TIMELINE: 'timeline',
  TYPE_TABS: 'tabs',
  TYPE_ACCORDION: 'accordion',
  // TYPE_PARAGRAPH: 'paragraph',
  TYPE_STATEMENT: 'statement',
  TYPE_LINK: 'link',
  TYPE_H5P: 'h5p',
};

export const templateOptions = () => [
  {
    value: videoTypes.TYPE_VIDEO,
    label: t1('video_lecture'),
    avatar: '/images/lecture-types/video.png',
  },
  {
    value: videoTypes.TYPE_TEXT,
    label: t1('text_lecture'),
    avatar: '/images/lecture-types/text.png',
  },
  {
    value: videoTypes.TYPE_PDF,
    label: t1('pdf_lecture'),
    avatar: '/images/lecture-types/pdf.png',
  },
  {
    value: videoTypes.TYPE_PPT,
    label: t1('ppt_lecture'),
    avatar: '/images/lecture-types/ppt.png',
  },
  {
    value: videoTypes.TYPE_WORD,
    label: t1('word_lecture'),
    avatar: '/images/lecture-types/pdf.png',
  },
  {
    value: videoTypes.TYPE_EXCEL,
    label: t1('excel_lecture'),
    avatar: '/images/lecture-types/pdf.png',
  },
  {
    value: videoTypes.TYPE_IMAGE,
    label: t1('image_lecture'),
    avatar: '/images/lecture-types/image.png',
  },
  {
    value: videoTypes.TYPE_AUDIO,
    label: t1('audio_lecture'),
    avatar: '/images/lecture-types/video.png',
  },
  // {
  //   value: videoTypes.TYPE_PARAGRAPH,
  //   label: t1('styled_paragraph'),
  //   // avatar: '/images/lecture-types/video.png',
  // },
  {
    value: videoTypes.TYPE_STATEMENT,
    label: t1('statement_block'),
    // avatar: '/images/lecture-types/video.png',
  },
  {
    value: videoTypes.TYPE_LIST,
    label: t1('list'),
    // avatar: '/images/lecture-types/video.png',
  },
  {
    value: videoTypes.TYPE_QUOTE,
    label: t1('quote'),
    // avatar: '/images/lecture-types/video.png',
  },
  {
    value: videoTypes.TYPE_CHART,
    label: t1('chart_block'),
    // avatar: '/images/lecture-types/video.png',
  },
  {
    value: videoTypes.TYPE_GALLERY,
    label: t1('gallery_block'),
    // avatar: '/images/lecture-types/video.png',
  },
  {
    value: videoTypes.TYPE_TIMELINE,
    label: t1('timeline_block'),
    // avatar: '/images/lecture-types/video.png',
  },
  {
    value: videoTypes.TYPE_FLASHCARD,
    label: t1('flashcard_block'),
    // avatar: '/images/lecture-types/video.png',
  },

  {
    value: videoTypes.TYPE_TABS,
    label: t1('tabs_block'),
    // avatar: '/images/lecture-types/video.png',
  },
  {
    value: videoTypes.TYPE_ACCORDION,
    label: t1('accordion_block'),
    // avatar: '/images/lecture-types/video.png',
  },

  {
    value: videoTypes.TYPE_SWF,
    label: t1('swf_lecture'),
    avatar: '/images/lecture-types/swf.png',
  },
  {
    value: videoTypes.TYPE_LINK,
    label: t1('link'),
    // avatar: '/images/lecture-types/video.png',
  },
  {
    value: videoTypes.TYPE_H5P,
    label: t1('h5p_lecture'),
    avatar: '/images/lecture-types/pdf.png',
  },
];

export default videoTypes;
