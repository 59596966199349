import { getNode } from 'components/admin/node/utils';
import { isIidEqual } from 'components/learn/utils';
import lodashGet from 'lodash.get';

export const TABLE_OF_CONTENT = 'table-of-content';
export const STUDY_GUIDE = 'study-guide';
// export const TABLE_OF_CONTENT = 'table-of-content';
export const LAST_SCREEN = 'last-screen';
export const COURSE_SURVEYS = 'survey';
export const DOWNLOAD_MATERIALS = 'materials';

// todo remove this
export const isFirstItemInNavBar = (
  navId,
  navRootNodeIid,
  positionOfCurrentItem,
) => {
  return positionOfCurrentItem === 'first';
};

// todo remove this
export const isLastItemInNavBar = (
  navId,
  navRootNodeIid,
  positionOfCurrentItem,
) => {
  return positionOfCurrentItem === 'last';
};

export const getNewNavIdFromItemIid = (
  trackingLine,
  nodes,
  itemIid,
  parentIid,
  navRootNodeIid,
) => {
  const item = getNode(itemIid, null, nodes);

  if (!item || !Array.isArray(trackingLine)) {
    return '';
  }

  if (parentIid) {
    const parent = getNode(parentIid, null, nodes);
    if (!parent) {
      return '';
    }

    // nav root node is the first item in the navbar
    if (String(itemIid) === String(navRootNodeIid)) {
      return `${parentIid}-${itemIid}-1`;
    }

    const parentIndex = trackingLine.findIndex((iid) =>
      isIidEqual(iid, parentIid),
    );
    if (parentIndex === -1) {
      return '';
    }
    const itemIndex = trackingLine.findIndex((iid) => isIidEqual(iid, itemIid));
    if (itemIndex === -1) {
      return '';
    }
    return `${parentIid}-${itemIid}-${
      navRootNodeIid ? itemIndex + 1 : itemIndex
    }`;
  }

  if (item.pid) {
    const index = trackingLine.findIndex((iid) => isIidEqual(iid, itemIid));
    if (index !== -1) {
      return `${item.pid}-${itemIid}-${navRootNodeIid ? index + 1 : index}`;
    }
  }

  return '';
};

export const getNodeInNavTree = (navTree, nodeIid) => {
  if (!navTree) {
    return null;
  }

  if (isIidEqual(navTree.iid, nodeIid)) {
    return navTree;
  }

  if (navTree.children) {
    for (let child of navTree.children) {
      const found = getNodeInNavTree(child, nodeIid);

      if (found) {
        return found;
      }
    }
  }

  return null;
};

// find previous/next nav item which should not be skipped
export const getTargetNavItem = (currentNavItem, isNext) => {
  let targetNavItem = lodashGet(currentNavItem, 'prevNode');

  if (isNext) {
    targetNavItem = lodashGet(currentNavItem, 'nextNode');
  }

  // if (targetNavItem && targetNavItem.shouldSkipNavigation) {
  //   return getTargetNavItem(targetNavItem, isNext);
  // }

  return targetNavItem;
};
