import React from 'react';
import ImageGallery from 'react-image-gallery';
import Heading from '../../common/Heading';
import get from 'lodash.get';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import './stylesheet.scss';

const BlockGallery = ({ item }) => {
  if (item.block_gallery && Array.isArray(item.block_gallery)) {
    const gallery = item.block_gallery.map((galleryImage) => ({
      original: get(galleryImage, 'avatar[0].link'),
      thumbnail: get(galleryImage, 'avatar[0].link'),
      description: galleryImage.name,
    }));

    return (
      <div>
        <Heading item={item} />
        <div className="content gallery-container">
          <ImageGallery items={gallery} />
        </div>
      </div>
    );
  }

  return null;
};

export default BlockGallery;
