import React, { Component } from 'react';
import Avatar from 'components/common/avatar';
import { Link } from 'react-router-dom';
import { sortStaff } from 'common/utils/index';
import Tag from 'antd/lib/tag';
import PreviewUserInDialog from 'components/admin/user/account/common/PreviewInDialog';

// import VarDump from 'components/common/VarDump';

// hiddenStaffNames is array of staff names, it will be join'ed by ', '
// to display like "staff A, staff B,...."
const Plus = (hiddenStaffNames, count, linkTo) =>
  linkTo ? (
    <Link
      to={linkTo}
      title={
        hiddenStaffNames &&
        hiddenStaffNames.length &&
        hiddenStaffNames.join(', ')
      }
    >{`+${count}`}</Link>
  ) : (
    <span
      title={
        hiddenStaffNames &&
        hiddenStaffNames.length &&
        hiddenStaffNames.join(', ')
      }
    >{` +${count}`}</span>
  );

class StaffList extends Component {
  avatarStyle = { marginRight: '5px' };

  render() {
    const {
      staff,
      linkTo,
      showDeleteStaff,
      renderDeleteButton,
      onDeleteStaffClick,
      isPreviewUserDialog,
      showNameInsteadOfAvatar,
      inline = false,
      hiddenItem = [],
      showItem = [],
    } = this.props;
    const showStaffAsTag = this.props.showStaffAsTag || showDeleteStaff;
    const displayCount = this.props.displayCount || 3;

    if (!staff || staff.length === 0) return <span>N/A</span>;

    let newStaff = [];
    if (!Array.isArray(staff)) {
      newStaff = sortStaff(
        Object.keys(staff).map((key) => [Number(key), staff[key]]),
      );
    } else {
      newStaff = sortStaff(staff);
    }

    const hiddenStaffCount =
      staff.length - displayCount > 2 ? staff.length - displayCount : 0;

    // array of staff names
    const hiddenStaffNames = [];

    return (
      <div style={{ display: inline ? 'inline' : 'block' }}>
        {newStaff.map((oneStaff, index) => {
          if (hiddenStaffCount === 0 || index + 1 <= displayCount) {
            if (showStaffAsTag) {
              const onClose =
                onDeleteStaffClick && typeof onDeleteStaffClick === 'function'
                  ? { onClose: () => onDeleteStaffClick(oneStaff) }
                  : {};

              return (
                <div style={{ display: inline ? 'inline' : 'block' }}>
                  <Tag
                    closable={showDeleteStaff}
                    visible
                    className="m-b-8"
                    {...onClose}
                  >
                    {oneStaff.name}
                  </Tag>
                </div>
              );
            }

            const content = showNameInsteadOfAvatar ? (
              <div style={{ display: inline ? 'inline' : 'block' }}>
                {oneStaff.name}
                {index < newStaff.length - 1 ? ', ' : ''}
              </div>
            ) : (
              <Avatar
                key={(oneStaff && oneStaff.iid) || index}
                user={oneStaff}
                style={this.avatarStyle}
              />
            );

            return isPreviewUserDialog ? (
              <PreviewUserInDialog
                user={oneStaff}
                showFullDetailButton={false}
                hiddenItem={hiddenItem}
                showItem={showItem}
              >
                {content}
              </PreviewUserInDialog>
            ) : (
              content
            );
          }
          if (oneStaff && oneStaff.name) {
            hiddenStaffNames.push(oneStaff.name);
          }
        })}
        {hiddenStaffCount !== 0 &&
          Plus(hiddenStaffNames, hiddenStaffCount, linkTo)}
      </div>
    );
  }
}

/*
StaffList.propTypes = {
  staff: PropTypes.arrayOf(PropTypes.objectOfShape({iid, name, avatar})),
  displayCount: PropTypes.integer, // maximumNumber of
}

*/
export default StaffList;
