import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'actions/node/creators';
import Icon from 'components/common/Icon';
import FlatButton from 'components/common/mui/NewButton';
// import NewModuleForm from '../mainstage/new';
import NewModuleForm from 'components/bdtx/training-phase/steps';

class ButtonNew extends Component {
  handleOnClick = () => {
    const { dispatch } = this.props;
    const contentDialog = (
      <NewModuleForm
        mode="new"
        title={'Tạo đợt đào tạo'}
        searchFormId="search-training-phase"
      />
    );
    const optionsProperties = {
      handleClose: true,
      modal: true,
      keyboard: false,
      width: '100%',
      className: 'modal-full-height',
    };
    dispatch(
      actions.handleOpenDialog(
        { contentDialog, optionsProperties },
        'new-training-phase-by-steps-mode',
      ),
    );
  };

  render() {
    return (
      <FlatButton
        name="submit"
        type="submit"
        icon={<Icon icon="plus" />}
        label={'Tạo đợt đào tạo'}
        onClick={() => this.handleOnClick()}
      />
    );
  }
}

export default connect()(ButtonNew);
