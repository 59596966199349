import { call, put, takeEvery } from 'redux-saga/effects';
import nodeActions from 'actions/node/creators';
import Requester from 'common/network/http/Request';
import apiUrls from 'api-endpoints';
import { RESET_PASSWORD_FORGOTTEN } from 'components/user/profile/reset-password-forgotten/actions/saga-creators.js';

function* resetPasswordForgotten(action) {
  const { params } = action;
  const data = yield call(
    Requester.post,
    apiUrls.reset_password_forgotten,
    params,
  );

  yield put(
    nodeActions.snackbar(data && data.success ? true : 'error', data.message),
  );
}

export default function* resetPasswordForgottenSaga() {
  yield takeEvery(RESET_PASSWORD_FORGOTTEN, resetPasswordForgotten);
}
