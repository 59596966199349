import React from 'react';
import useDragWithScrolling from 'components/common/forms/questions/ddm/scrollable';
import { Tooltip } from 'antd';

const MovableItem = ({ className, item, isBeingDragged }) => {
  const cssClass = 'mddm-question-movable-item';

  const componentClassName = `${className || ''} ${cssClass} ${
    isBeingDragged ? `${cssClass}--dragging` : ''
  }`;
  const { content, answered, type } = item;
  const [property, drag] = useDragWithScrolling({
    item: item,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });
  return (
    <Tooltip title={content}>
      <div
        ref={drag}
        className={componentClassName}
        style={{ opacity: answered ? 0.3 : 1 }}
      >
        <div className={`${cssClass}__span`}>{content}</div>
      </div>
    </Tooltip>
  );
};
export default MovableItem;
