import React, { useCallback } from 'react';
import { t1 } from 'translate';

const BackNextStep = ({
  previousStepIndex,
  nextStepIndex,
  onNextStep,
  onPreviousStep,
  nextStepLabel,
  previousStepLabel,
  hidePreviousButtonWhenHaveNoPreviousStep = true,
  hideNextButtonWhenHaveNoNextStep = true,
}) => {
  const handlePreviousStep = useCallback(
    () => {
      if (typeof onPreviousStep === 'function') {
        onPreviousStep(previousStepIndex);
      }
    },
    [onPreviousStep, previousStepIndex],
  );

  const handleNextStep = useCallback(
    () => {
      if (typeof onNextStep === 'function') {
        onNextStep(nextStepIndex);
      }
    },
    [nextStepIndex, onNextStep],
  );

  return (
    <div className="m-t-25 d-flex align-items-center justify-content-center">
      {hidePreviousButtonWhenHaveNoPreviousStep &&
      previousStepIndex === undefined ? null : (
        <button
          className="btn btn-secondary btn-outline m-10"
          onClick={handlePreviousStep}
        >
          {previousStepLabel || t1('back_step')}
        </button>
      )}

      {hideNextButtonWhenHaveNoNextStep &&
      nextStepIndex === undefined ? null : (
        <button className="btn btn-primary m-10" onClick={handleNextStep}>
          {nextStepLabel || t1('next_step')}
        </button>
      )}
    </div>
  );
};

export default BackNextStep;
