import React, { Component } from 'react';
import CommonGroupEdit from 'components/admin/group/edit';
import { t1 } from 'translate';

class GroupEditContainer extends Component {
  render() {
    return (
      <CommonGroupEdit
        mode={'meet'}
        title={t1('edit_group')}
        type={'meeting'}
        urlToBack={'/meet/group'}
        {...this.props}
      />
    );
  }
}

export default GroupEditContainer;
