import DatePicker from 'schema-form/elements/date-picker';
import { t, t1 } from 'translate';
import Duration from 'schema-form/elements/duration/smaller-than-one-day';
import { durationDisplayFormats } from 'schema-form/elements/duration/smaller-than-one-day/common/constants';
import lodashGet from 'lodash.get';
import React from 'react';
import moment from 'moment';
import { joinStatuses } from 'components/admin/session/utils';
import store from 'store';
import { change } from 'redux-form';

const defaultStartDate = Math.round(new Date().getTime() / 1000);
const numberClassHourDefault = 1;
const durationOfClassHourDefault = 60;
const startTime = 540;
const resultDuration = (startTime, endTime) => endTime - startTime;
const onChangeScheduleTime = (formid, startTime, endTime) =>
  store.dispatch(
    change(
      formid,
      'scheduled.learn_duration',
      resultDuration(startTime, endTime),
    ),
  );
const schema = (teachers, mode, node, sessionStatus) => (
  formid,
  values,
  step,
  xpath,
  props,
  domainInfo,
) => {
  const numberClassHour =
    lodashGet(
      domainInfo,
      'conf.create_session_default_values.number_class_hour_default',
    ) || numberClassHourDefault;

  const durationOfClassHour =
    lodashGet(
      domainInfo,
      'conf.create_session_default_values.duration_of_class_hour_default',
    ) || durationOfClassHourDefault;

  const learnDuration = numberClassHour * durationOfClassHour;
  const startTime = Math.floor(
    (moment().unix() -
      moment()
        .startOf('day')
        .unix()) /
      60,
  );
  const scheduled = lodashGet(values, 'scheduled');
  const shouldDisabledTimeField = [
    joinStatuses.JOIN_STATUS_OK,
    joinStatuses.JOIN_STATUS_TOO_LATE,
  ].includes(sessionStatus);
  return {
    date_time: {
      type: DatePicker,
      getStartDate: true,
      hintText: t1('date'),
      floatingLabelText: t1('date'),
      fullWidth: true,
      defaultValue: mode === 'new' ? defaultStartDate : null,
      disabled: shouldDisabledTimeField,
    },
    start_time: {
      type: Duration,
      valueIsNumberOfMinutes: true,
      label: `${t1('start_time')} (${t('hh:mm')})`, //Giờ học: Từ (giờ: phút)
      formatTime: durationDisplayFormats.TIME_FORMAT, //default format,
      defaultValue: mode === 'new' ? startTime : '',
      fullWidth: true,
      disable: shouldDisabledTimeField,
      onChange: (event, value) =>
        scheduled && onChangeScheduleTime(formid, value, scheduled.end_time),
    },
    end_time: {
      type: Duration,
      valueIsNumberOfMinutes: true,
      label: `${t1('end_time')} (${t('hh:mm')})`, //Giờ học: Từ (giờ: phút)
      formatTime: durationDisplayFormats.TIME_FORMAT, //default format
      fullWidth: true,
      defaultValue: mode === 'new' ? startTime + learnDuration : '',
      disable: shouldDisabledTimeField,
      onChange: (event, value) =>
        scheduled && onChangeScheduleTime(formid, scheduled.start_time, value),
    },
  };
};

const ui = (values, type) => {
  const fields = ['date_time', 'start_time', 'end_time'].filter(Boolean);

  return [
    {
      id: 'scheduled-session',
      title: t1('session_scheduling'),
      fields,
    },
  ];
};

const LayoutFreeStyle = (props) => {
  const fieldNames = lodashGet(props, 'groups.scheduled-session.fieldNames');
  if (!fieldNames) {
    return null;
  }
  const timestamp = Date.now() / 1000 + 15 * 60;
  const dateTime = lodashGet(props, 'node.scheduled.date_time') || 0;
  const timeStart = lodashGet(props, 'node.scheduled.start_time') || 0;
  const canEditSession = dateTime + timeStart * 60 > timestamp;

  return (
    <div className="layout-new-session">
      <div className="row layout-new-session__content">
        <div className={`col-md-4 date-time`}>{fieldNames.date_time}</div>

        <div className={'col-md-8'}>
          <div className="row d-flex">
            <span className="ve-arrow-right icon-arrow" />
            <div className=" d-flex col-xs-6 justify-content-center align-items-center">
              {fieldNames.start_time}
            </div>
            <div className="d-flex col-xs-6 justify-content-center align-items-center">
              {fieldNames.end_time}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const layout = (step, values, xpath, props) => {
  return {
    component: LayoutFreeStyle,
    freestyle: 1,
    optionsProperties: {
      props,
      step,
    },
  };
};

export default ({
  type,
  teachers,
  mode = 'new',
  // automaticScaleVirtualClassEnabled,
  node,
  sessionStatus,
}) => ({
  schema: schema(teachers, mode, node, sessionStatus),
  ui: (step, values, themeConfig, xpath, formid, props = {}) =>
    ui(values, type),
  layout,
});
