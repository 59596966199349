import { detect } from 'detect-browser';

function isChromeBrowser() {
  const detectedBrowser = detect();

  return (
    (detectedBrowser.name === 'chrome' || detectedBrowser.name === 'crios') &&
    !isCocCocBrowser()
  );
}

function isCocCocBrowser() {
  return window.navigator.userAgent.indexOf('coc_coc') >= 0;
}

const openLinkInNewTab = (link) => {
  const newlink = document.createElement('a');
  newlink.setAttribute('href', link);
  newlink.setAttribute('target', '_blank');
  newlink.click();
  newlink.remove();
};

export { isChromeBrowser, openLinkInNewTab };
