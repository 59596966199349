import React from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import bdtxCotCanTpApiUrls from 'components/bdtx/training-phase/mainstage/training-model-cot-can/endpoints';
import { t1 } from 'translate';
import lodashGet from 'lodash.get';
import { getSearchFormId } from './common';
import schema from 'components/admin/user/account/account-search/schema/schema-new';
import UserResults, {
  columnIds,
} from 'components/admin/user/account/common/AntdSearchResult';
import ApiRequestBtnWithConfirmDialog from 'components/common/action-button/ApiRequestBtnWithConfirmDialog';
import ExportBtnWithConfirmDialog from 'components/common/action-button/ExportBtnWithConfirmDialog';
import Button from 'components/common/primary-button';
import Warning from 'components/common/Warning';
import Icon from 'components/common/Icon';
import { ButtonType } from 'components/common/primary-button/button-type-constants';
import { approveStatuses, userGradeToShortText } from 'configs/constants/user';
import fetchData from 'components/common/fetchData';
import apiUrls from 'api-endpoints';
import withUserInfo from 'common/hoc/withUserInfo';
import {
  isHVQLStaff,
  isLTTUStaff,
  isSGDStaff,
} from 'components/admin/user/utils';
import Perm from 'common/utils/Perm';
import SubmitSelectedRows from 'components/common/submit-selected-rows';
import { getFilterDuplicateCallback } from 'common/utils/Array';
import { editingSyllabusMode } from 'configs/constants';

const SearchPlannedUsers = ({
  trainingPlanIid,
  plannedTrainingGrade,
  trainingPlan,
  academicCategoryIid,
  noAcademicCategoryInfo, // search planned users who academic categories info is invalid
  organizationIid,
  includeSubOrganizations,
  hideSearchForm,
  refetchPlannedUserOverview,
  searchFormId,
  additionalSearchValues,
  userInfo,
  showUnapproved,
  showApproved,
  showOther, // chỉ hiển thị các user trực thuộc đơn vị (ko tính các đơn vị con)
  subOrganizations = [],
}) => {
  if (!trainingPlan) {
    return null;
  }

  if (!searchFormId) {
    searchFormId = getSearchFormId(trainingPlanIid);
  }

  const isSGD = isSGDStaff(userInfo);
  const isLTTU = isLTTUStaff(userInfo);
  const isHVQL = isHVQLStaff(userInfo);
  const isRoot = Perm.hasPerm('root');

  const renderResults = (items, props, objects, searchValues, resultId) => {
    const columnsToShow = [
      columnIds.CODE,
      columnIds.NAME,
      columnIds.MAIL,
      columnIds.ORGANIZATION,
      columnIds.CREATED_DATE,
    ];

    // people who does not have final approve permission need to see approve status column
    if (showUnapproved && (isSGD || isHVQL || isLTTU || isRoot)) {
      columnsToShow.unshift(columnIds.APPROVE_STATUS);
    }

    return (
      <>
        <UserResults
          trainingPlanIid={trainingPlanIid}
          items={items}
          triggerResetSelection={resultId}
          columnsToShow={columnsToShow}
          addExtraColumns={(columns) => {
            return (columns || []).concat([
              {
                key: 'grade',
                title: t1('user_grade'),
                render: (row) => {
                  return userGradeToShortText(
                    lodashGet(row, 'current_planned_training_data.grade'),
                  );
                },
              },
              {
                key: 'academic_category',
                title: t1('academic_category'),
                render: (row) => {
                  return (
                    lodashGet(
                      row,
                      'current_planned_training_data.academic_categories',
                    ) || []
                  )
                    .map((cate) => lodashGet(cate, 'name'))
                    .filter(getFilterDuplicateCallback())
                    .join(', ');
                },
              },
              {
                key: 'other_academic_categories',
                title: t1('other_academic_categories'),
                render: (row) => {
                  const grade = lodashGet(
                    row,
                    'current_planned_training_data.grade',
                  );

                  return (
                    lodashGet(
                      row,
                      'current_planned_training_data.planned_training_data_by_grade',
                    ) || []
                  ).map((byGrade) => {
                    const otherGrade = lodashGet(byGrade, 'grade');
                    if (otherGrade === grade) {
                      return null;
                    }

                    return (
                      <div key={otherGrade}>
                        {userGradeToShortText(otherGrade)}:{' '}
                        {lodashGet(byGrade, 'academic_categories')
                          .map((cate) => lodashGet(cate, 'name'))
                          .filter(getFilterDuplicateCallback())
                          .join(', ')}
                      </div>
                    );
                  });
                },
              },
            ]);
          }}
          renderAfter={({ selectedRowKeys: userIds }) => {
            const showApproveButton =
              showUnapproved && (isSGD || isLTTU || isHVQL || isRoot); // in additional planned users screen, display approve button for SGD only

            const showRemoveButton =
              isLTTU || isHVQL || isRoot || (isSGD && !showApproved);

            const numberOfSelectedUsers = lodashGet(userIds, 'length') || 0;
            const approveLabel = isSGD
              ? `Duyệt ${numberOfSelectedUsers} học viên`
              : `Duyệt ${numberOfSelectedUsers} học viên và xếp lớp`;

            return (
              <>
                {/* approve button*/}
                {showApproveButton && (
                  <SubmitSelectedRows
                    params={{
                      user_ids: userIds,
                      training_plan_iid: trainingPlanIid,
                    }}
                    previewButton={
                      <Button
                        disabled={numberOfSelectedUsers === 0}
                        label={approveLabel}
                        icon={<Icon icon="check" />}
                        buttonType={ButtonType.primary}
                        className="m-r-10 m-t-10 btn-primary"
                      />
                    }
                    alternativeApi={
                      bdtxCotCanTpApiUrls.approve_bdtx_cot_can_training_plan_planned_users
                    }
                    formIdToSubmitOnSuccess={searchFormId}
                    dialogTitle={t1('approve_training_plan')}
                    submitButton={
                      <button className="btn btn-primary">
                        {t1('approve')}
                      </button>
                    }
                    searchFormValues={searchValues}
                    totalRowSelected={userIds.length}
                    totalRow={lodashGet(props, 'total', 0)}
                  />
                )}

                {/* remove button*/}
                {showRemoveButton ? (
                  <ApiRequestBtnWithConfirmDialog
                    formidToSubmitOnSuccess={searchFormId}
                    onRequestSuccessful={() => {
                      if (typeof refetchPlannedUserOverview === 'function') {
                        refetchPlannedUserOverview();
                      }
                    }}
                    renderComponent={({ onClick }) => {
                      return (
                        <Button
                          disabled={numberOfSelectedUsers === 0}
                          label={`Xóa ${numberOfSelectedUsers} học viên`}
                          onClick={onClick}
                          icon={<Icon icon="delete" />}
                          buttonType={ButtonType.danger}
                          className="m-r-10 m-t-10 btn-primary"
                        />
                      );
                    }}
                    url={
                      bdtxCotCanTpApiUrls.remove_bdtx_cot_can_training_plan_planned_users
                    }
                    textConfirm={
                      <Warning>{`Xóa ${numberOfSelectedUsers} học viên`}</Warning>
                    }
                    params={{
                      user_ids: userIds,
                      training_plan_iid: trainingPlanIid,
                    }}
                  />
                ) : null}

                <ExportBtnWithConfirmDialog
                  renderComponent={({ onClick }) => {
                    return (
                      <Button
                        label={'Xuất danh sách học viên'}
                        onClick={onClick}
                        icon={<Icon icon="export" />}
                        className="m-r-10 m-t-10 btn-primary"
                      />
                    );
                  }}
                  url={
                    bdtxCotCanTpApiUrls.export_bdtx_cot_can_training_plan_planned_users
                  }
                  params={searchValues}
                />
              </>
            );
          }}
        />
      </>
    );
  };

  let hiddenFields = {
    ...(academicCategoryIid
      ? {
          academic_category_iids: [academicCategoryIid],
        }
      : {}),
    ...(organizationIid
      ? {
          user_organizations: [organizationIid],
        }
      : {}),
    training_plan_iid: trainingPlanIid,
    ...(includeSubOrganizations
      ? {
          include_sub_organizations: 1,
        }
      : {}),
    _sand_expand: ['user_organizations'],
  };

  if (plannedTrainingGrade) {
    hiddenFields.planned_training_grade = plannedTrainingGrade;
  }

  if (noAcademicCategoryInfo) {
    hiddenFields.no_academic_category_info = noAcademicCategoryInfo;
  }

  // this component is displayed in Approved planned users page
  if (showApproved) {
    hiddenFields.approve_status = [approveStatuses.APPROVED_BY_GVSP];
  }

  const setFilterStatusBasedOnRole = () => {
    if (isLTTU || isHVQL) {
      hiddenFields.approve_status = [
        approveStatuses.NOT_YET_APPROVED,
        approveStatuses.APPROVED_BY_SGD,
      ];
    } else if (isRoot) {
      // SGD will be handled in prepareDataBeforeSearch because it has approve_status filter
      hiddenFields.approve_status = [
        approveStatuses.NOT_YET_APPROVED,
        approveStatuses.APPROVED_BY_SGD,
      ];
    }
  };

  // this component is displayed in Unapproved planned users page
  if (showUnapproved) {
    setFilterStatusBasedOnRole();
  }

  hiddenFields.nin_organizations = [];
  if (showOther) {
    hiddenFields.nin_organizations = subOrganizations
      .map((subOrganization) => subOrganization.iid)
      .filter((subOrganizationIid) => subOrganizationIid);
    hiddenFields.nin_organizations__include_sub_organizations = 1;
  }

  if (additionalSearchValues) {
    hiddenFields = {
      ...hiddenFields,
      ...additionalSearchValues,
    };
  }

  const prepareDataBeforeSearch = (values) => {
    // For PGD|SGD, there is approve status filter, so we need this to make sure that it only display NOT_YET_APPROVED and APPROVED_BY_SGD if no filter checked
    if (showUnapproved) {
      if (isSGD) {
        const selectedApproveStatusOptions =
          lodashGet(values, 'approve_status') || [];

        if (selectedApproveStatusOptions.length === 0) {
          values.approve_status = [
            approveStatuses.NOT_YET_APPROVED,
            approveStatuses.APPROVED_BY_SGD,
          ];
        }
      }
    }

    return values;
  };

  const approveStatusOptions = isSGD
    ? [approveStatuses.NOT_YET_APPROVED, approveStatuses.APPROVED_BY_SGD]
    : [];

  return (
    <div className="m-b-10 p-b-20">
      <SearchWrapper
        formid={searchFormId}
        renderResultsComponent={renderResults}
        alternativeApi={
          bdtxCotCanTpApiUrls.search_bdtx_cot_can_training_plan_planned_users
        }
        noResultText={t1('no_users')}
        hiddenFields={hiddenFields}
        schema={hideSearchForm ? undefined : schema}
        showSearchButton={!hideSearchForm}
        hideCotCanUserField
        showApproveStatusFilter={showUnapproved}
        availableApproveStatusOptions={approveStatusOptions}
        method={'post'}
        prepareDataBeforeSearch={prepareDataBeforeSearch}
      />
    </div>
  );
};

export default fetchData((props) => {
  const { trainingPlanIid } = props;
  return {
    baseUrl: apiUrls.fetch_node,
    params: {
      iid: trainingPlanIid,
      ntype: 'training_plan',
      editing_syllabus: editingSyllabusMode.GET_TO_REVIEW,
    },
    propKey: 'trainingPlan',
  };
})(withUserInfo(SearchPlannedUsers));
