import React from 'react';
import { downloadFileFromUrl } from 'common/utils/File';

const DownloadLinkWrapper = ({
  link,
  fileSize /*in bytes*/,
  fileName,
  renderComponent,
}) => {
  const handleClick = React.useCallback(
    (event) => {
      if (fileSize > 50000000) {
        // if file is too big
        // open link in new tab to avoid having to download it to blob
        window.open(link, '_blank').focus();
      } else {
        downloadFileFromUrl({
          downloadUrl: link,
          fileName: fileName,
        });
      }
      event.preventDefault();
      event.stopPropagation();
    },
    [link, fileName, fileSize],
  );

  return renderComponent({ href: link, onClick: handleClick });
};

export default DownloadLinkWrapper;
