import React from 'react';
import { t1 } from 'translate';
import get from 'lodash.get';
import store from 'store';
import { change } from 'redux-form';
import { required } from 'common/validators';
import Attachments from 'schema-form/elements/attachments';
import Toggle from 'schema-form/elements/toggle';
import MarkingByRubric from 'components/common/open-ended-take/marking/by-rubric';
import { normalizeRoundNumber } from 'common/normalizers';

const onUseGroupScoreChange = (event, value, formid, values) => {
  if (event) {
    if (value) {
      store.dispatch(change(formid, 'score', values.group_score));
    } else {
      store.dispatch(change(formid, 'score', null));
    }
  }
};

const setScore = (score, formid) => {
  store.dispatch(change(formid, 'suggest_score', score));
};

const elementMarkingPassFail = (values, step, props, formid) => {
  const passScore = 100;

  const readOnly = get(props, 'readOnly');

  let label = t1('enter_pass_fail');

  if (values && typeof values.suggest_score !== 'undefined') {
    const score = Number(values.suggest_score);

    const passOrFail = score >= passScore ? t1('passed') : t1('fail');
    label = `${label} - ${t1(
      'suggest_pass_fail_from_marked_rubric',
    )}: ${passOrFail}`;
  }

  return {
    type: Toggle,
    classWrapper: 'm-t-15 m-b-15',
    checkedChildren: t1('marking_passed'),
    unCheckedChildren: t1('marking_failed'),
    label: '',
    floatingLabelText: label,
    format: (value) => (value ? 1 : 0),
    normalize: (value) => (value ? passScore : 0),
    defaultValue: 0,
    disabled: readOnly,
  };
};

const elementMarkingScore = (values, step, props, formid) => {
  const readOnly = get(props, 'readOnly');

  let label =
    typeof values.group_score !== 'undefined'
      ? t1('or_enter_different_score')
      : t1('enter_marking_score');

  if (values && typeof values.suggest_score !== 'undefined') {
    label = `${label} - ${t1('suggest_score_from_marked_rubric')}: ${
      values.suggest_score
    }`;
  }

  const labelWithMessage = (
    <span>
      {label}{' '}
      <span className="text-danger bold">{t1('score_per_one_hundred')}</span>
    </span>
  );

  return {
    type: 'number',
    step: 1,
    min: 0,
    max: 100,
    defaultValue: 0,
    floatingLabelText: labelWithMessage,
    fullWidth: true,
    floatingLabelFixed: false,
    readOnly: values && values.use_group_score,
    normalize: normalizeRoundNumber,
    validate:
      ['edit_set_question_score'].includes(step) && !readOnly
        ? [required(t1('score_is_invalid'))]
        : null,
  };
};

const scoreElement = (values, step, props, formid) => {
  const markingPassOrFail = get(props, 'question.marking_pass_or_fail');

  if (markingPassOrFail) {
    return elementMarkingPassFail(values, step, props, formid);
  }

  return elementMarkingScore(values, step, props, formid);
};

const schema = (formid, values, step, xpath, props) => {
  const questionOrCommentStep = [
    'edit_set_question_score',
    'new_comment',
  ].includes(step);

  return {
    use_group_score: {
      type: Toggle,
      label: t1('use_group_score'),
      labelPosition: 'right',
      onChange: (event, value) => {
        onUseGroupScoreChange(event, value, formid, values);
      },
    },
    score_by_rubric: {
      type: 'section',
      schema: {
        schema: () => ({
          detail: {
            type: MarkingByRubric,
            rubricIid: get(props, 'rubricMarking.iid'),
            rubricIidsToShowMarking: get(props, 'rubricIidsToShowMarking'),
            setScore: (score) => setScore(score, formid),
          },
        }),
        ui: () => [
          {
            fields: ['detail'],
          },
        ],
      },
    },
    score: scoreElement(values, step, props, formid),
    content: {
      name: 'content',
      type: 'text',
      multiLine: true,
      floatingLabelText: questionOrCommentStep
        ? t1('marker_comment')
        : t1('enter_the_answer_here_or_upload_your_answer_file'),
      hintText: questionOrCommentStep ? t1('enter_the_comment') : null,
      rows: 2,
      floatingLabelFixed: false,
      fullWidth: true,
      disabled: !(questionOrCommentStep || typeof values.score === 'undefined'),
    },
    attachments: {
      type: Attachments,
      name: 'file_answer',
      allowDownload: true,
      rootFolder: 'public',
      folder: 'file-answer',
      disabled: !(questionOrCommentStep || typeof values.score === 'undefined'),
      label: t1('upload_marking_feedback_attachments'),
      noFileManager: true,
      height: '50px',
      primary: true,
      compactMode: true,
    },
  };
};

const ui = (step, values, themeConfig, xpath, formid, props) => {
  switch (step) {
    case 'new_question': {
      return [
        {
          id: 'default',
          fields: ['content', 'attachments'],
        },
      ];
    }
    case 'new_comment': {
      return [
        {
          id: 'default',
          fields: ['content', 'attachments'],
        },
      ];
    }
    case 'edit_set_question_score': {
      let fields = [];

      if (typeof values.group_score !== 'undefined') {
        fields.push('use_group_score');
      }

      if (get(props, 'rubricMarking.iid')) {
        fields.push('score_by_rubric');
      }

      fields = fields.concat(['score', 'content', 'attachments']);

      return [
        {
          id: 'edit_score',
          fields,
        },
      ];
    }
    default: {
      return [];
    }
  }
};

export default { schema, ui };
