import React from 'react';
import PropTypes from 'prop-types';
import { t1 } from 'translate';

import Tabs from 'antd/lib/tabs';
import lodashGet from 'lodash.get';

class TabsComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.initialSelectedIndex || 0,
    };
  }

  componentDidMount() {
    const activeTab =
      this.props.value || lodashGet(this.props, 'tabs.0.value') || 0;
    this.setActiveTab(activeTab);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.initialSelectedIndex !== nextProps.initialSelectedIndex) {
      this.setActiveTab(nextProps.initialSelectedIndex);
    }
  }

  setActiveTab = (index) => {
    this.setState({
      activeTab: index,
    });
    const { onActive } = this.props;
    if (typeof onActive === 'function') {
      onActive(index);
    }
  };

  renderResultComponent = (items) => this.setState({ items });

  render() {
    const { activeTab } = this.state;
    const { tabs, ...props } = this.props;

    if (!Array.isArray(tabs) || !tabs.length) {
      return null;
    }

    if (tabs.length === 1) {
      const content = lodashGet(tabs, '0.content');
      return typeof content === 'function' ? content() : content;
    }

    const activeKey = String(activeTab || 0);

    return (
      <Tabs
        value={activeKey}
        activeKey={activeKey}
        {...props}
        onChange={this.setActiveTab}
      >
        {tabs.map((tab, index) => {
          const { content, value, label, ...propsTab } = tab;

          const key = String(value || index);

          return (
            <Tabs.TabPane
              key={key}
              tab={label || t1('tab_%s', [value || index + 1])}
            >
              {activeKey != key
                ? null
                : typeof content === 'function'
                ? content()
                : content}
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    );
  }
}

TabsComponent.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.any),
};

TabsComponent.defaultProps = {
  tabs: null,
};

export default TabsComponent;
