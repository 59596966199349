import React, { Component } from 'react';
import { t1 } from 'translate';
import DetailOnDialog from 'components/common/detail-on-dialog';
import Icon from 'components/common/Icon';
import AddSessionTimeForm from './AddSessionTimeForm';
import get from 'lodash.get';
import { connect } from 'react-redux';

class AddSessionTimeButton extends Component {
  render() {
    const { session, formid } = this.props;
    const sessionIid = get(session, 'iid');

    return (
      <DetailOnDialog
        renderPreview={({ showFull }) => (
          <div onClick={showFull} className="link">
            <Icon icon="clock" title={t1('add_time')} />
            <span className="m-l-3">{t1('add_time')}</span>
          </div>
        )}
        renderFull={({ closeDialog }) => (
          <AddSessionTimeForm
            sessionIid={sessionIid}
            searchFormId={formid}
            closeDialog={closeDialog}
          />
        )}
        dialogOptionsProperties={{
          title: t1('add_time_for_session'),
          width: '40%',
          handleClose: true,
          modal: true,
        }}
      />
    );
  }
}

export default connect()(AddSessionTimeButton);
