import React from 'react';
import Icon from 'components/common/Icon';
import { t } from 'translate';
import PropTypes from 'prop-types';

const Index = ({
  title,
  item,
  label,
  totalComment,
  handleExpandComment,
  showDetailComments,
  allowAskTeacher,
}) => (
  <div className="action-bar" onClick={handleExpandComment}>
    <div className="action-bar__right">
      <div className="number-comment-wrapper--desktop">
        <span className="number-comment">
          {totalComment || 0}{' '}
          {allowAskTeacher ? t('questions') : t('discussion_comments')}
        </span>
      </div>
    </div>
  </div>
);

Index.propTypes = {
  item: PropTypes.shape(),
  title: PropTypes.string,
  label: PropTypes.string,
  totalComment: PropTypes.number,
  handleExpandComment: PropTypes.shape(),
  showDetailComments: PropTypes.bool,
};

Index.defaultProps = {
  item: null,
  title: '',
  label: '',
  totalComment: 0,
  handleExpandComment: () => {},
  showDetailComments: false,
};

export default Index;
