const urls = {
  add_course_staff: '/course/staff/add-staff',
  search_staff_to_add_to_course: '/course/staff/search-staff-to-add-to-course',

  get_learning_types: '/course/api/get-learning-types',
  export_score_of_courses_selected: '/course/data/export-score-of-courses',
  search_online_score: '/course/progress/search-online-score',
  score_by_rubric: '/course/report/get-course-progress-by-rubrik',
  sync_course_progress_by_rubric:
    '/course/progress/sync-course-progress-by-rubrik',

  search_members: '/course/member/search',
  export_members: '/course/member/export',
  create_course: '/course/new',

  my_active_courses: '/student/api/get-student-active-courses',
  // TODO: why those 2 apis
  get_current_courses: '/student/api/get-current-courses',

  // enrolment
  move_students_to_other_course: '/course/enrol/move-students-to-other-course',
  get_course_list_to_move_students_to:
    '/course/enrol/get-other-course-list-to-move-to',
  can_move_students_to_other_course:
    '/course/enrol/can-move-students-to-other-course',

  // get_courses_to_register: '/course/api/get-courses-allowed-to-register',
  register_the_course: '/course/enrol/register',
  withdraw_the_course: '/course/enrol/withdraw',

  // student
  download_certificate: '/course/certificate/create-certificate',
  reset_item_progress: '/course/progress/reset-item-progress',
  split_course: '/course/index/split-course',

  // score
  set_marking_finished: '/course/score/set-marking-finished',
  set_master_verified_score: '/course/score/set-master-verified-score',

  get_open_ended_take: '/course/marking/get-open-ended-takes',
  course_marking_student_list: '/course/marking/get-student-list',
  course_marking_open_ended:
    '/course/marking/get-open-ended-questions-list-in-course',
  teacher_finish_marking: '/course/score/teacher-finish-marking',
  teacher_undo_finish_marking: '/course/score/teacher-undo-finish-marking',
  master_approve_marking: '/course/score/master-approve-marking',
  master_undo_approve_marking: '/course/score/master-undo-approve-marking',
  master_reject_marking: '/course/score/master-reject-marking',
  master_undo_reject_marking: '/course/score/master-undo-reject-marking',
  get_master_rejection: '/course/score/get-master-rejection',
  teacher_resolve_master_rejection:
    '/course/score/teacher-resolve-master-rejection',
  get_marking_status: '/course/get-marking-status',

  // survey
  get_course_surveys: '/course/api/get-surveys',

  export_score: '/course/report/export-score',
  save_progress_manual_rubric:
    '/progress/api/save-progress-course-for-manual-rubric',

  export_members_for_bql: '/course/report/export-members-for-bql',
  cache_courses_over_all_statistics:
    '/course/api/cache-courses-overall-statistics',
  search_users_to_add_to_course: '/course/enrol/search-users-to-add-to-course',

  get_course_overall_progress_report:
    '/course/report/get-course-overall-progress',
  get_marking_stats_report: '/course/report/get-marking-stats-report',
  get_detailed_rubric_pass_fail_stats:
    '/course/report/get-detailed-rubric-pass-fail-stats-report',
  course_search: '/course/search',
};

export default urls;
