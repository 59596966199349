import React from 'react';
import PropTypes from 'prop-types';
import Select from 'antd/lib/select';
import Form from 'antd/lib/form';
import isEqual from 'lodash.isequal';
import './stylesheet.scss';
import makeReduxFormCompatible from 'components/common/makeReduxFormCompatible';
import DefaultFormElementRecap from 'components/common/default-form-element-recap';
import lodashGet from 'lodash.get';
import { elementDisplayModes } from 'schema-form/constants';
import Help from '../Help';
import { unaccentVietnamese } from 'common/utils/string/vn';
import { getPopupContainerOfElement } from 'common/utils/schema-form';
import { t1 } from 'translate';

const { Option } = Select;

const getTextFromOption = (opt) =>
  (opt && (opt.primaryText || opt.label)) || '';

const RecapSelect = ({ options, value, label }) => {
  options = options || [];
  value = typeof value === 'object' ? value : [value] || [];
  const recapContent = options
    .filter(
      (opt) =>
        lodashGet(opt, 'value') !== '' &&
        value.includes(lodashGet(opt, 'value')),
    )
    .map((opt) => getTextFromOption(opt))
    .join(', ');

  if (!recapContent) {
    return null;
  }

  return <DefaultFormElementRecap label={label} content={recapContent} />;
};

const getHelpText = ({ errorText, optionsLoading }) => {
  if (errorText) {
    return errorText;
  }

  if (optionsLoading) {
    return t1('options_are_being_fetch_please_wait');
  }

  return '';
};

const DefaultSelect = ({ options, values, props, handleOnChange }) => {
  options = options || [];

  const {
    className,
    readOnly,
    renderedLabel,
    hiddenWhenOptionEmpty,
    transformValueOption,
    waringWhenOptionsIsEmpty,
    multiple,
    floatingLabelText,
    onBlur,
    errorText,
    placeholder,
    guide,
    showSearch,
    disabledWhenOptionEmpty = true,
    // from schema-form
    schemaFormAsyncOptionsLoading,
  } = props;

  const optionsLoading = schemaFormAsyncOptionsLoading;

  if (multiple) {
    values = values || [];
  }

  let { ...custom } = props;

  if (showSearch) {
    custom = {
      filterOption: (input, option = {}) => {
        const { props = {} } = option;

        return (
          unaccentVietnamese(props.children)
            .toLowerCase()
            .indexOf(unaccentVietnamese(input).toLowerCase()) >= 0
        );
      },
      ...custom,
    };
  }
  const selection = options.find((item) => item.value === values);

  const selectionText = getTextFromOption(selection);
  let extraProps = {};
  let disabled = props.disabled;

  if (!options || !options.length) {
    if (waringWhenOptionsIsEmpty) {
      return waringWhenOptionsIsEmpty;
    }
    if (hiddenWhenOptionEmpty) {
      return null;
    }
    disabled = disabledWhenOptionEmpty;
    extraProps = {
      ...extraProps,
      disabled,
    };
  }
  if (selectionText) {
    extraProps = {
      ...extraProps,
      hintText: '',
      disabled,
    };
  }
  const opts = {};
  if (renderedLabel)
    opts.selectionRenderer = (values, menuItems) => (
      <span>{renderedLabel}</span>
    );

  const label = guide ? (
    <span>
      {floatingLabelText} <Help guide={guide} />
    </span>
  ) : (
    floatingLabelText
  );
  return (
    <div
      className={`${className || ''} ${custom.fullWidth ? 'full-width' : ''}`}
    >
      <Form.Item
        validateStatus={errorText ? 'error' : ''}
        help={getHelpText({ errorText, optionsLoading })}
        className="ant-form-select-option"
        label={label}
        colon={false}
      >
        {// mode 'single' have different way to display readOnly field
        !multiple && readOnly ? (
          <Select
            className={className || ''}
            {...custom}
            {...extraProps}
            onBlur={null}
            onChange={null}
            value={selectionText}
            showArrow={false}
            notFoundContent={null}
            getPopupContainer={getPopupContainerOfElement}
            disabled
            style={Object.assign(
              { color: 'grey' },
              lodashGet(custom, 'style'),
              lodashGet(extraProps, 'style'),
            )}
            loading={optionsLoading}
          />
        ) : (
          <Select
            className="full-width"
            mode={multiple ? 'multiple' : 'default'}
            placeholder={placeholder}
            onBlur={() => onBlur(values)}
            {...custom}
            {...extraProps}
            {...opts}
            onChange={(value) => handleOnChange(value)}
            value={values}
            showArrow
            getPopupContainer={getPopupContainerOfElement}
            {...(readOnly // if readOnly, disable component, otherwise, depend on other props
              ? {
                  disabled: true,
                }
              : {})}
            loading={optionsLoading}
          >
            {options &&
              options.length &&
              options.map((opt) => {
                const { children, id, primaryText, ...rest } = opt;
                const value =
                  typeof transformValueOption === 'function'
                    ? transformValueOption(rest.value)
                    : rest.value;
                if (custom.multiple) {
                  const checked =
                    values && Array.isArray(values) && values.includes(value);
                  return (
                    <Option
                      {...rest}
                      key={id || value}
                      value={value}
                      checked={checked}
                      title={getTextFromOption(opt)}
                    >
                      {getTextFromOption(opt)}
                    </Option>
                  );
                }
                return (
                  <Option
                    {...rest}
                    key={id || value}
                    value={value}
                    title={getTextFromOption(opt)}
                  >
                    {getTextFromOption(opt)}
                  </Option>
                );
              })}
          </Select>
        )}
      </Form.Item>
    </div>
  );
};

class AntdSelectField extends React.Component {
  hasValue = (value) => {
    return ![undefined, null, ''].includes(value);
  };

  handlePopulateValue = (options) => {
    const { populateValue, multiple, transformValueOption } = this.props;
    const value = this.getValue();

    const shouldPopulateValue =
      populateValue &&
      Array(options) &&
      options.length &&
      !this.hasValue(value);
    if (!shouldPopulateValue) {
      return;
    }

    const validOptions = options.filter(Boolean);
    const firstOption = validOptions.length >= 1 ? validOptions[0] : {};
    const firstValue = firstOption.value;

    if (!this.hasValue(firstValue)) {
      return;
    }

    const transformValue =
      typeof transformValueOption == 'function'
        ? transformValueOption(firstValue)
        : firstValue;
    const newValue = multiple ? [transformValue] : transformValue;

    if (!isEqual(newValue, value)) {
      this.handleOnChange(newValue);
    }
  };

  handleValueInOptions = (options) => {
    const value = this.getValue();

    if (!Array(options) || !options.length || !this.hasValue(value)) {
      return;
    }

    const validOptions = options.filter(Boolean);

    let shouldClearValue = false;
    if (Array.isArray(value)) {
      value.forEach((val) => {
        if (!this.hasValueInOptions(val, validOptions)) {
          shouldClearValue = true;
        }
      });
    } else {
      shouldClearValue = !this.hasValueInOptions(value, validOptions);
    }

    if (shouldClearValue) {
      this.handleOnChange(null);
    }
  };

  hasValueInOptions = (value, options) => {
    const { transformValueOption } = this.props;

    return options.find((item) => {
      const itemValue =
        typeof transformValueOption === 'function'
          ? transformValueOption(item.value)
          : item.value;

      return isEqual(value, itemValue);
    });
  };

  componentDidMount() {
    const { options } = this.props;

    this.handlePopulateValue(options);
    this.handleValueInOptions(options);
  }

  componentWillReceiveProps(nextProps) {
    const { options } = this.props;
    const nextOptions = nextProps && nextProps.options;

    if (isEqual(options, nextOptions)) {
      return;
    }

    this.handlePopulateValue(nextOptions);
    this.handleValueInOptions(nextOptions);
  }

  // TODO: Fix with case element is type Array. When remove element 0, all are element below will be clear value
  getValue = (props) => {
    const { value, transformValueOption } = props || this.props;

    if (
      !value ||
      !transformValueOption ||
      typeof transformValueOption !== 'function'
    ) {
      return value;
    }

    if (Array.isArray(value)) {
      return value.map((value) => transformValueOption(value));
    }
    return transformValueOption(value);
  };

  handleOnChange = (value) => {
    const { onChange, transformValueOption, options } = this.props;
    const curentValue = this.getValue();
    let newValue = value;

    if (
      typeof transformValueOption == 'function' &&
      value &&
      Array.isArray(options)
    ) {
      if (Array.isArray(newValue)) {
        newValue = options
          .map((option) => {
            if (
              value.includes(
                transformValueOption(option.value, value, curentValue),
              )
            ) {
              return option.value;
            }
            return false;
          })
          .filter(Boolean);

        if (!newValue.length) {
          newValue = null;
        }
      } else {
        const option = options.find((op) => {
          return isEqual(
            value,
            transformValueOption(op.value, value, curentValue),
          );
        });
        newValue = option ? option.value : null;
      }
    } else if (typeof newValue === 'undefined') {
      newValue = null;
    }

    if (onChange) {
      onChange(typeof newValue !== 'undefined' ? newValue : null);
    }
  };

  render() {
    const { options, floatingLabelText, elementDisplayMode } = this.props;
    const values = this.getValue();

    if (elementDisplayMode === elementDisplayModes.RECAP) {
      return (
        <RecapSelect
          options={options}
          value={values}
          label={floatingLabelText}
        />
      );
    } else {
      return (
        <DefaultSelect
          options={options}
          values={values}
          props={this.props}
          handleOnChange={this.handleOnChange}
        />
      );
    }
  }
}

export default makeReduxFormCompatible({})(AntdSelectField);
