import React from 'react';
import { getProjectOfCurrentUser } from './utils';
import DetailOnDialog from 'components/common/detail-on-dialog';
import ChooseProject from './ChooseProject';
import ChooseProjectWithToggleMode from './ChooseProjectWithToggleMode';

const SwitchProject = ({ modal = true, showConfigOneTime = true }) => {
  const projectConfig = getProjectOfCurrentUser();

  if (!modal) {
    return <ChooseProjectWithToggleMode />;
  }

  if (projectConfig && showConfigOneTime) {
    return null;
  }

  return (
    <DetailOnDialog
      renderFull={({ closeDialog }) => (
        <ChooseProject closeDialog={closeDialog} />
      )}
      dialogOptionsProperties={{
        title: 'Chọn chương trình',
        modal: true,
        handleClose: false,
        width: '450px',
      }}
      dialogKey="switch-project"
      defaultRenderFull={true}
    />
  );
};

export default SwitchProject;
