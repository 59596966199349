import React from 'react';
import Search from 'schema-form/elements/advance-search';
import { searchFormSchema, searchRecapFormSchema } from './advance';
import { t1 } from 'translate';
import codes from 'components/admin/group/schema/elements/filterset/filter/schema/codes';
import mails from 'components/admin/group/schema/elements/filterset/filter/schema/mails';
import names from 'components/admin/group/schema/elements/filterset/filter/schema/names';
import phones from 'components/admin/group/schema/elements/filterset/filter/schema/phones';
import cotCanDataByCategory from 'components/admin/group/schema/elements/filterset/filter/schema/cotCanDataByCategory';
import SearchFormLayout from './SearchFormLayout';
import get from 'lodash.get';
import { isEnableTemis } from 'components/temis/utils';
import lodashGet from 'lodash.get';

const defaultFields = (value, skipFields = false) => {
  let fields = ['codes', 'mails', 'names', 'phones', 'text'];

  if (get(value, 'cot_can_only')) {
    if (typeof lodashGet(value, 'is_cot_can') === 'undefined') {
      fields = fields.concat(['cot_can_data_by_category']);
    }

    if (skipFields) {
      fields = fields.concat([
        'license_in_active',
        'should_skip_quota',
        'cot_can_data_by_category',
      ]);
    }
  }

  return fields;
};

const schema = (formid, values, localStep, xpath, props) => {
  const { defaultValues } = props;
  return {
    cot_can_data_by_category: cotCanDataByCategory({
      fields: [
        !isEnableTemis() && 'training_plan_category',
        'passed_cot_can',
      ].filter(Boolean),
      classWrapper: 'col-md-6',
      taphuanCategoryElementProps: {
        modifyOptions: (options) => options,
        multiple: true,
        populateValue: false,
      },
    }),
    codes: codes(false, {
      rows: 2,
    }),
    mails: mails(false, {
      rows: 2,
    }),
    names: names(false, {
      rows: 2,
    }),
    phones: phones(false, {
      rows: 2,
    }),
    text: {
      type: Search,
      schema: searchFormSchema({
        ...props,
        skipFields: defaultFields(values, true),
      }),
      recapSchema: searchRecapFormSchema({
        ...props,
        skipFields: defaultFields(values, true),
      }),
      labelText: t1('user_name,_email,_id_or_code...'),
      defaultValues,
    },
    licensing_status: {
      type: 'radio',
      floatingLabelText: t1('license_licensing_status'),
      options: [
        {
          value: 'licensed',
          label: 'Đã được cấp',
        },
        {
          value: 'unlicensed',
          label: 'Chưa được cấp',
        },
      ],
      defaultValue: 'licensed',
      inline: true,
    },
    license_in_active: {
      type: 'multiCheckbox',
      floatingLabelText: t1('user_license_status'),
      options: [
        {
          value: 'active',
          label: 'Đang hoạt động',
        },
        {
          value: 'inactive',
          label: 'Hết hạn',
        },
        {
          value: 'no_license',
          label: 'Chưa có giấy phép',
        },
      ],
      inline: true,
    },
  };
};

const ui = (step, values) => {
  const fields = defaultFields(values);

  return [
    {
      id: 'id',
      fields,
    },
  ];
};

export default {
  schema,
  ui,
  layout: { component: SearchFormLayout, freestyle: 1 },
  isAdvanceSearch: true,
};
