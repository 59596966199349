import { organizations } from 'components/admin/organization/schema/elements';
import subTypes from 'configs/constants/org-sub-types';

const schema = (formid, values, localStep, xpath, props) => {
  return {
    organization: organizations({
      formid,
      multiple: false,
      defaultValue: props.orgIids,
      label: 'Đơn vị',
      subTypes: [
        subTypes.TAPHUAN_SUBTYPE_BO_GD,
        subTypes.TAPHUAN_SUBTYPE_SO_GD,
        subTypes.TAPHUAN_SUBTYPE_PHONG_GD,
        subTypes.TAPHUAN_SUBTYPE_LTTU,
      ],
      classWrapper: 'col-md-6',
    }),
  };
};

const ui = () => {
  return [
    {
      id: 'default',
      fields: ['organization'],
    },
  ];
};

export default { schema, ui };
