import get from 'lodash.get';
import getUser from 'common/auth';
import Store from 'store';
import { isBDTX, isProjectConfigBDTX } from 'utils/Util';
import { isEnableTemis } from 'components/temis/utils';
import { shouldShowSwitchProject } from '../switch-project/utils';

const assessmentPhaseConfigKey = 'TEMIS_ASSESSMENT_PHASE_CONFIGURATION_KEY';

export const saveAssessmentPhaseToLocalStorage = (assessmentPhase, userIid) => {
  let iid = userIid;
  if (!iid) {
    const userInfo = getUser();
    iid = get(userInfo, 'info.iid', '');
  }
  const currentData = getAssessmentPhaseOfAllUserFromLocalStorage() || {};
  const newData = {
    ...currentData,
    [iid]: assessmentPhase,
  };

  localStorage.setItem(assessmentPhaseConfigKey, JSON.stringify(newData));
};

export const getAssessmentPhaseOfAllUserFromLocalStorage = () => {
  const dataString = localStorage.getItem(assessmentPhaseConfigKey);
  if (dataString) {
    return JSON.parse(dataString);
  }

  return null;
};

export const getAssessmentPhaseOfCurrentUser = (userInfo = undefined) => {
  const user = userInfo || get(getUser(), 'info');
  const userId = get(user, 'iid', '');

  const dataFromLocalStorage =
    getAssessmentPhaseOfAllUserFromLocalStorage() || {};

  return get(dataFromLocalStorage, userId);
};

export const clearAssessmentPhaseOfCurrentUser = () => {
  saveAssessmentPhaseToLocalStorage('');
};

export const onSaveAssessmentPhase = (assessmentPhase, callback) => {
  saveAssessmentPhaseToLocalStorage(assessmentPhase);

  if (typeof callback === 'function') {
    callback();
  }
};

export const isChangeAssessmentPhase = (assessmentPhase) => {
  const currentData = getAssessmentPhaseOfCurrentUser();
  return currentData !== assessmentPhase;
};

export const getAssessmentPhaseNameOfCurrentUser = () => {
  const schoolYears = get(Store.getState(), 'scholasticConfig.schoolYears', []);
  const selectedSchoolYearId = getAssessmentPhaseOfCurrentUser();
  const selectedSchoolYear = schoolYears.find(
    (schoolYear) => schoolYear.id == selectedSchoolYearId,
  );

  return get(selectedSchoolYear, 'name', '');
};

// migrate from school year config (old)
const schoolYearConfigKey = 'SCHOOL_YEAR_CONFIGURATION_KEY';

export const savSchoolYearToLocalStorage = (schoolYear) => {
  const userInfo = getUser();
  const userId = get(userInfo, 'info.iid', '');
  const currentSchoolYear = getSchoolYearOfAllUserFromLocalStorage() || {};
  const newSchoolYear = {
    ...currentSchoolYear,
    [userId]: schoolYear,
  };

  localStorage.setItem(schoolYearConfigKey, JSON.stringify(newSchoolYear));
};

export const getSchoolYearOfAllUserFromLocalStorage = () => {
  const schoolYearString = localStorage.getItem(schoolYearConfigKey);
  if (schoolYearString) {
    return JSON.parse(schoolYearString);
  }

  return null;
};

export const getSchoolYearOfCurrentUser = () => {
  const userInfo = getUser();
  const userId = get(userInfo, 'info.iid', '');
  const schoolYearFromLocalStorage =
    getSchoolYearOfAllUserFromLocalStorage() || {};

  return get(schoolYearFromLocalStorage, userId) || window.DEFAULT_SCHOOL_YEAR;
};

export const shouldShowSchoolYearConfig = () => isBDTX();

export const onSaveSchoolYear = (schoolYear, callback) => {
  savSchoolYearToLocalStorage(schoolYear);

  if (typeof callback === 'function') {
    callback();
  }

  window.location.reload();
};
