import React, { useState } from 'react';

/**
 * @return {Function} that you can call whenever you need to trigger component update
 */
const useForceUpdate = () => {
  const [value, setValue] = useState(0); // integer state

  return () => setValue((value) => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here
  // is better than directly setting `value + 1`
};

export default useForceUpdate;
