import { call } from 'redux-saga/effects';
import { delay } from 'redux-saga';

export default function* callApiMultipleTimes(
  callApiMultipleTimesSettings,
  req,
  url,
  originalSubmitParams,
) {
  const { configForEachSubmit } = callApiMultipleTimesSettings;

  let requestResult = null;

  for (let submitIndex = 0; true; submitIndex += 1) {
    if (typeof configForEachSubmit !== 'function') {
      break;
    }

    const { submitParams, shouldSubmit, timeToWait } = configForEachSubmit({
      submitIndex,
      originalSubmitParams,
      prevRequestResult: requestResult,
    });

    if (!shouldSubmit) {
      break;
    }

    if (timeToWait) {
      yield call(delay, timeToWait);
    }

    requestResult = yield call(req, url, submitParams);
  }

  return requestResult;
}
