import './stylesheet.scss';

import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { t1 } from 'translate';
import lodashGet from 'lodash.get';
import { timestampToDateString } from 'common/utils/Date';
import DetailOnDialog from 'components/common/detail-on-dialog/index';
import MarkOpenEndedQuestion from 'components/admin/course/mainstage/score/marking-open-ended-question';
import OrganizationsOrPhongBan from 'components/admin/group/common/OrganizationsOrPhongBanInResultTable';
import InlineEditable from 'components/common/forms/editable/inline';
import { connect } from 'react-redux';
import sagaActions from 'actions/node/saga-creators';
import ShowMoreLess from 'components/common/show-more-less';
import isEqual from 'lodash.isequal';
import TotalPoint from 'components/learn/common/total-point/TotalPoint';
import { isSmallScreen } from 'common';
import DetailButton from 'components/common/detail-button/DetailButton';
import CommonAntdTable from 'components/common/antd/table';
import Loading from 'components/common/loading/index';
import { normalizeTo2DecimalPlaces } from 'common/normalizers';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import {
  alertMarkingDialog,
  getQuestionAnswerTexts,
  isOEAdvancedLayoutType,
  isStructureAnswerType,
} from '../util';
import RejectedMessage from './components/rejected-message';
import withUserInfo from 'common/hoc/withUserInfo';
import ScoreNotice from './components/score-notice';
import { getGuideStrings } from './utils';
import TextStructureAnswerOverview from './TextStructureAnswerOverview';
import PassOrFail from 'components/common/pass-or-fail';
import { waitForMarking } from 'common/learn/Question';
import ListAttachmentsToDownloadAsTable from 'components/common/attachment/ListAttachmentsToDownloadAsTable';

const dialogOptionsProperties = {
  width: '95%',
  handleClose: true,
  className: 'open-ended-peer-takes-modal modal-full-height',
};

const updateScoreUrl = '/take/update';

class TakeResults extends Component {
  constructor(props) {
    super(props);

    this.guide = getGuideStrings();
  }

  state = {
    scoreByAnswer: {},
    isAnswerUpdatedMap: {}, // is_answer_updated answer data mapped by take id and answer id
  };

  componentDidMount() {
    this.updateScoreArray(this.props);
  }

  componentDidUpdate(preProps) {
    const { items } = this.props;
    if (!isEqual(preProps.items, items)) {
      this.updateScoreArray(this.props);
    }
  }

  updateScoreArray = (props) => {
    const { items } = props;

    let scoreByAnswer = {};
    let isAnswerUpdatedMap = {};
    if (items) {
      items.map((item, index) => {
        const answers = lodashGet(item, 'take.answers');
        const question = lodashGet(item, 'question');
        const qId = question.id;

        scoreByAnswer = {
          ...scoreByAnswer,
          [item.id]: lodashGet(answers, `${qId}.score`),
        };

        isAnswerUpdatedMap[item.id] = lodashGet(
          answers,
          `${qId}.is_answer_updated`,
        );
      });
    }

    this.setState({
      scoreByAnswer,
      isAnswerUpdatedMap,
    });
  };

  renderMarkingDialog = (take, questionIid, formid, viewOnly) => {
    if (!take || !questionIid) {
      return <Loading circularLoadingIcon />;
    }

    return (
      <div>
        <div style={{ width: '100%' }}>
          <MarkOpenEndedQuestion
            userIid={lodashGet(take, 'u.iid')}
            node={{ iid: lodashGet(take, 'course') }}
            questionIid={questionIid}
            peerMarking={this.props.peerMarking}
            courseIid={lodashGet(take, 'course')}
            takeId={lodashGet(take, 'id')}
            searchFormId={formid}
            viewOnly={viewOnly}
          />
        </div>
      </div>
    );
  };

  updateScoreInState = (itemId, qId, score) => {
    this.setState({
      scoreByAnswer: {
        ...this.state.scoreByAnswer,
        [itemId]: score,
      },
      isAnswerUpdatedMap: {
        ...this.state.isAnswerUpdatedMap,
        [itemId]: false,
      },
    });
  };

  updateScore = (field, itemId, score, take, question, index) => {
    const { dispatch, peerMarking } = this.props;

    const qId = lodashGet(question, 'iid');

    dispatch(
      sagaActions.submitFormRequest('', {
        url: updateScoreUrl,
        extraParams: {
          _sand_step: 'set_question_score',
          score,
          peer_marking: peerMarking ? 1 : 0,
          course: lodashGet(take, 'course'),
          type: lodashGet(question, 'type'),
          iid: qId,
          question: qId,
          id: take.id, //uid
          take_id: take.id,
        },
        executeOnSuccess: this.updateScoreInState(itemId, qId, score, index),
      }),
    );
  };

  hasScore = (question) => {
    const score = lodashGet(question, 'score');
    return typeof score === 'number';
  };

  renderAnswer = ({ question, take }, refreshFormSubmit) => {
    if (isStructureAnswerType(question)) {
      const questionAnswerTexts = getQuestionAnswerTexts(question);

      if (Array.isArray(questionAnswerTexts) && questionAnswerTexts.length) {
        return <TextStructureAnswerOverview question={question} take={take} />;
      }

      return this.renderComplexAnswer(question, take, refreshFormSubmit);
    }

    if (isOEAdvancedLayoutType(question)) {
      return this.renderComplexAnswer(question, take, refreshFormSubmit);
    }

    return this.renderSimpleAnswer(question);
  };

  renderSimpleAnswer = (question) => {
    const answer = lodashGet(question, 'answer');
    const answerContent = lodashGet(answer, `content`);

    let attachments = lodashGet(answer, 'attachments') || [];

    let attachmentComponent = null;

    // sometimes content contains attachments.
    if (typeof answerContent === 'object') {
      attachments = lodashGet(answerContent, '[0].attachments', []);
    }

    if (attachments && attachments.length) {
      attachmentComponent = (
        <ListAttachmentsToDownloadAsTable
          compact
          showHeader={false}
          attachments={attachments}
        />
      );
    }

    let textComponent;
    if (typeof answerContent === 'string') {
      textComponent = <ShowMoreLess content={answerContent} length={150} />;
    }

    return (
      <div>
        <div>{textComponent}</div>
        {attachmentComponent ? (
          <div className="m-t-10">{attachmentComponent}</div>
        ) : null}
      </div>
    );
  };

  renderComplexAnswer = (question, take, refreshFormSubmit) => {
    const { formid, viewOnly } = this.props;
    const showWarning = window.isPreventMarking && take.isDaiTraCourse;

    const qId = lodashGet(question, 'iid');

    return (
      <DetailOnDialog
        renderPreview={({ showFull }) => <DetailButton onClick={showFull} />}
        renderFull={() =>
          showWarning
            ? alertMarkingDialog()
            : this.renderMarkingDialog(take, qId, formid, viewOnly)
        }
        dialogOptionsProperties={{
          ...dialogOptionsProperties,
          title: t1('marking_for_"%s"', lodashGet(take, 'u.name')),
          width: showWarning ? '45%' : '100%',
          callbacks: {
            onCloseDialog: () => {
              if (typeof refreshFormSubmit === 'function') {
                refreshFormSubmit();
              }
            },
          },
        }}
      />
    );
  };

  render() {
    const {
      items,
      formid,
      viewOnly,
      refreshFormSubmit,
      noMarking,
      peerMarking,
      canPeerMarkingQuestion,
    } = this.props;
    const { scoreByAnswer } = this.state;
    const searchValues = lodashGet(this.props, 'searchValues');

    const canPeerMarking = peerMarking ? canPeerMarkingQuestion : true;

    let columns = [
      {
        title: t1('name'),
        width: 200,
        key: 'name',
        type: TYPE_OF_TABLE_DATA.TEXT,
        render: (text, { take }) => (
          <div>
            {lodashGet(take, 'u.name')}
            <div className="text-muted email">{lodashGet(take, 'u.mail')}</div>
            {!peerMarking && (
              <>
                <div className="text-muted">{lodashGet(take, 'u.phone')}</div>
                <div className="text-muted">
                  {lodashGet(take, 'u.__expand.user_organizations') ? (
                    <OrganizationsOrPhongBan
                      item={lodashGet(take, 'u')}
                      attr={'user_organizations'}
                      showParentsInfo
                    />
                  ) : lodashGet(take, 'u.user_organizations_info', []) ? (
                    lodashGet(take, 'u.user_organizations_info', []).map(
                      (org) => org.name,
                    )
                  ) : null}
                </div>
              </>
            )}
          </div>
        ),
      },
      ...(!peerMarking
        ? [
            {
              title: t1('question_content'),
              key: 'question_content',
              width: 250,
              type: TYPE_OF_TABLE_DATA.TEXT,
              render: (text, { question }) => {
                return (
                  <div>
                    <ShowMoreLess
                      content={lodashGet(question, `content`)}
                      length={100}
                    />
                  </div>
                );
              },
            },
          ]
        : []),
      {
        title: t1('answer'),
        key: 'answer',
        width: 250,
        type: TYPE_OF_TABLE_DATA.TEXT,
        render: (questionTakeRow) => {
          if (lodashGet(questionTakeRow, 'question.is_answer_empty')) {
            return null;
          }

          return this.renderAnswer(questionTakeRow, refreshFormSubmit);
        },
      },
      {
        title: t1('submitted_date'),
        key: 'submitted_date',
        type: TYPE_OF_TABLE_DATA.DATE,
        width: 130,
        render: (text, { question }) => {
          return (
            <div>
              {timestampToDateString(lodashGet(question, 'ts'), {
                showDate: false,
                showTime: true,
              })}
              <br />
              {timestampToDateString(lodashGet(question, 'ts'), {
                showDate: true,
                showTime: false,
              })}
            </div>
          );
        },
      },
      ...(!noMarking && !peerMarking
        ? [
            {
              title: (
                <span>
                  {t1('score')} <span className="text-danger">(/100)</span>
                </span>
              ),
              className: 'text-center',
              key: 'score',
              type: TYPE_OF_TABLE_DATA.NUMBER,
              width: 120,
              render: (text, { id, question, take }, index) => {
                const isDaiTraCourse = take.isDaiTraCourse;
                const hasScore = this.hasScore(question);
                const score = lodashGet(question, 'score');
                const isAdvancedLayoutType = isOEAdvancedLayoutType(question);
                const isAnswerUpdated = !!this.state.isAnswerUpdatedMap[id];

                const isMarkingPassOrFail = !!lodashGet(
                  question,
                  'marking_pass_or_fail',
                );
                const statusMarkingPassOrFail = !!score;

                if (question.is_answer_empty) {
                  return <span>{t1('user_answer_is_empty')}</span>;
                }

                if (isMarkingPassOrFail) {
                  return waitForMarking(score) ? (
                    <span>{t1('wait_for_marking')}</span>
                  ) : (
                    <PassOrFail status={statusMarkingPassOrFail} />
                  );
                }

                // for question with rubric, we want teacher to mark in the detailed dialog
                let scoreElement = null;
                if (isAdvancedLayoutType)
                  scoreElement = (
                    <Fragment>
                      {hasScore && <TotalPoint size="small" point={score} />}
                    </Fragment>
                  );
                else
                  scoreElement = (
                    <>
                      <InlineEditable
                        value={lodashGet(scoreByAnswer, id) || score}
                        propName="score"
                        handleOnChange={({ score }) =>
                          this.updateScore(
                            'score',
                            id,
                            normalizeTo2DecimalPlaces(score),
                            take,
                            question,
                            index,
                          )
                        }
                        type="number"
                        validate={(newValue) =>
                          newValue && newValue > 0 && newValue <= 100
                        }
                        editProps={{
                          disabled: viewOnly,
                          style: {
                            width: 50,
                          },
                        }}
                        defaultProps={{
                          disabled: viewOnly,
                          style: {
                            width: 50,
                          },
                        }}
                        className="cursor-pointer text-secondary"
                        format={(value) => {
                          const valueFormatted = normalizeTo2DecimalPlaces(
                            value,
                          );
                          if (!valueFormatted || valueFormatted === 'NaN') {
                            return value;
                          }

                          return valueFormatted;
                        }}
                      />
                      {hasScore && <span>/100</span>}
                    </>
                  );

                return (
                  <Fragment>
                    {window.isPreventMarking && isDaiTraCourse ? (
                      !isAdvancedLayoutType ? (
                        <label>{score}</label>
                      ) : hasScore ? (
                        <TotalPoint size="small" point={score} />
                      ) : null
                    ) : (
                      scoreElement
                    )}

                    {hasScore && isAnswerUpdated && !peerMarking && (
                      <ScoreNotice title={this.guide.takeUpdated.longText} />
                    )}
                  </Fragment>
                );
              },
            },
          ]
        : []),
      ...(canPeerMarking
        ? [
            {
              title: t1('mark_detail'),
              key: 'detail',
              width: 100,
              type: TYPE_OF_TABLE_DATA.ACTION,
              render: (text, { take, question }) => {
                const showWarning =
                  window.isPreventMarking && take.isDaiTraCourse;
                const qId = lodashGet(question, 'iid'); //questionIid || Object.keys(answers || {})[0];
                const rejectedComment = lodashGet(
                  question,
                  'rejected_comment',
                  '',
                );

                if (question.is_answer_empty) {
                  return null;
                }

                return (
                  <div>
                    <DetailOnDialog
                      renderPreview={({ showFull }) => (
                        <span
                          className="btn-take ve-write cursor-pointer"
                          onClick={showFull}
                        />
                      )}
                      renderFull={() =>
                        showWarning
                          ? alertMarkingDialog()
                          : this.renderMarkingDialog(
                              take,
                              qId,
                              formid,
                              viewOnly,
                            )
                      }
                      dialogOptionsProperties={{
                        ...dialogOptionsProperties,
                        title: t1(
                          'marking_for_"%s"',
                          lodashGet(take, 'u.name'),
                        ),
                        width: showWarning ? '45%' : '100%',
                        callbacks: {
                          onCloseDialog: () => {
                            if (typeof refreshFormSubmit === 'function') {
                              refreshFormSubmit();
                            }
                          },
                        },
                      }}
                    />

                    {!!rejectedComment && (
                      <RejectedMessage comment={rejectedComment} />
                    )}
                  </div>
                );
              },
            },
          ]
        : []),
    ];

    if (isSmallScreen) {
      columns = [
        {
          title: t1('name'),
          key: 'name',
          width: 170,
          type: TYPE_OF_TABLE_DATA.TEXT,
          render: (text, { take }) => (
            <div>
              {lodashGet(take, 'u.name')}{' '}
              <div className="text-muted email">
                {lodashGet(take, 'u.mail')}
              </div>
              <div className="text-muted">{lodashGet(take, 'u.phone')}</div>
              <div className="text-muted">
                {lodashGet(take, 'u.__expand.user_organizations') ? (
                  <OrganizationsOrPhongBan
                    item={lodashGet(take, 'u')}
                    attr={'user_organizations'}
                    showParentsInfo
                  />
                ) : lodashGet(take, 'u.user_organizations_info', []) ? (
                  lodashGet(take, 'u.user_organizations_info', []).map(
                    (org) => org.name,
                  )
                ) : null}
              </div>
            </div>
          ),
        },
        ...(canPeerMarking
          ? [
              {
                title: t1('mark_detail'),
                key: 'detail',
                type: TYPE_OF_TABLE_DATA.ACTION,
                render: (text, { take, question }) => {
                  const isDaiTraCourse = take.isDaiTraCourse;
                  const qId = lodashGet(question, 'iid'); //questionIid || Object.keys(answers || {})[0];

                  return (
                    <div>
                      <DetailOnDialog
                        renderPreview={({ showFull }) => (
                          <span
                            onClick={showFull}
                            className={`cursor-pointer ve-write mark-icon`}
                          />
                        )}
                        renderFull={() =>
                          window.isPreventMarking && isDaiTraCourse
                            ? alertMarkingDialog()
                            : this.renderMarkingDialog(
                                take,
                                qId,
                                formid,
                                viewOnly,
                              )
                        }
                        dialogOptionsProperties={{
                          ...dialogOptionsProperties,
                          title: t1(
                            'marking_for_"%s"',
                            lodashGet(take, 'u.name'),
                          ),
                          ...(isSmallScreen ? { width: '100%' } : {}),
                          className: isSmallScreen ? 'modal-full-height' : '',
                          callbacks: {
                            onCloseDialog: () => {
                              if (typeof refreshFormSubmit === 'function') {
                                refreshFormSubmit();
                              }
                            },
                          },
                        }}
                      />
                    </div>
                  );
                },
              },
            ]
          : []),
      ];
    }

    return (
      <CommonAntdTable
        className={`learn-default scroll-x-table table-result-peer-take`}
        columns={columns}
        dataSource={items}
        searchValues={searchValues}
        stt={1}
        sttProps={{ title: 'STT', width: 50 }}
        bordered
        pagination={false}
        rowKey={(row, index) => `${row.id}`}
        ref={(el) => (this.table = el)}
        id="mark-table"
      />
    );
  }
}

export default withRouter(withUserInfo(connect()(TakeResults)));
