import { inRange, required } from 'common/validators';
import { t1 } from 'translate';
import { normalizeNumberString } from 'common/normalizers';

const schema = (formid, values, step, xpath, props, domainInfo) => ({
  number_of_split: {
    type: 'text',
    floatingLabelText: t1('number_of_split'),
    validate: [required(), inRange(2)],
    fullWidth: true,
    defaultValue: 2,
    normalize: normalizeNumberString({ isInteger: true }),
  },
});

const ui = () => [
  {
    id: 'numberOfSplit',
    fields: ['number_of_split'],
  },
];

export default { schema, ui };
