import './stylesheet.scss';

import React from 'react';
import {
  getFormSyncErrors,
  getFormValues,
  initialize,
  isDirty,
  submit,
} from 'redux-form';
import isEqual from 'lodash.isequal';
import lodashGet from 'lodash.get';
import Loading from 'components/common/loading';
import Perm from 'common/utils/Perm';
import { openLoginDialog } from 'actions/auth/auth-dialog';
import { connect } from 'react-redux';
import { t1 } from 'translate';
import OpenEndedSchemaForm from './Form';
import {
  getLearningItemModeSelector,
  getLearningItemUserIidSelector,
  getLearnItemInfoSelector,
} from 'common/learn';
import InputText from 'antd/lib/input';
import OpenEndedQuestionComments from 'components/common/comment/open-ended-answer-comment';
import { last } from 'common/utils/Array';
import DetailMarkingByRubric from 'components/common/open-ended-take/marking/by-rubric';
import Portal, { portals } from 'components/common/portal';
import TotalPoint from 'components/learn/common/total-point/TotalPoint';
import withSnackbar from 'common/hoc/withSnackbar';
import {
  isUserAnswerAttachmentsDifferent,
  isFormValuesDifferenceUserAnswers,
  isQuestionFormValid,
  isQuestionMarked,
  waitForMarking,
} from 'common/learn/Question';
import WaitForMarking from 'components/common/wait-for-marking';
import MarkingGuide from 'components/common/open-ended-take/marking/guide/Guide';
import ShowDatetimeFromTimestamp from 'components/common/show-datetime-from-timestamp';
import { isSmallScreen } from 'common';
import {
  canPeerMarking,
  defaultActivityMenuItem,
  generateOEFormId,
  getArchivedAnswerAttachmentsRestorationInfo,
  getRightMenuItemByPeerActivity as getMenuItems,
  isOEAdvancedLayoutType,
  isOEAdvancedTableLayout,
  manualValidateWordMinMax,
  showMarkingInformation,
} from './util';
import PeerTakes from './peer-takes';
import QuestionReports from './reports';
import { openEndedPeerActivity } from 'components/admin/question/schema/question-types';
import PassOrFail from 'components/common/pass-or-fail';
import { stripHTML } from 'common/utils/string';
import { getAcceptFileExtensionFromAcceptFileType } from 'common/utils/File';
import OEMenu from './OEMenu';
import ViewComment from './view-comment';
import CollaboratingItemComment from 'components/common/comment/collaborating-item-comment';
import Warning from 'components/common/Warning';
import { timestampToDateTimeString } from 'common/utils/Date';

const getOEFormId = (props) => {
  const courseIid = lodashGet(props, 'courseIid');
  const questionIid = lodashGet(props, 'question.iid');

  return generateOEFormId(courseIid, questionIid);
};

class OpenEnded extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
      activatedMenuItem: defaultActivityMenuItem,
    };
  }

  componentDidMount() {
    if (Perm.isGuest()) {
      const dispatch = this.props.dispatch;
      dispatch(openLoginDialog());
    }
  }

  componentDidUpdate(prevProps) {
    const userAnswers = lodashGet(this.props, 'userAnswers');
    const prevUserAnswers = lodashGet(prevProps, 'userAnswers');

    const { initialized } = this.state;

    if (userAnswers && !isEqual(prevUserAnswers, userAnswers) && !initialized) {
      const { userAnswers, dispatch, question } = this.props;

      const prePopulateContent = lodashGet(question, 'pre_populate_content');

      const newFormValues = {
        attachments: [],
        ...(userAnswers || {}),
        score: question.score,
      };

      const { content } = newFormValues;

      // Prevent exception with case switch OE advanced to normal
      if (!isOEAdvancedLayoutType(question) && Array.isArray(content)) {
        newFormValues.content = '';
      }

      if (
        !content &&
        prePopulateContent &&
        stripHTML(prePopulateContent).length
      ) {
        newFormValues.content = prePopulateContent;
      }

      dispatch(initialize(getOEFormId(this.props), newFormValues));

      this.setState({
        initialized: true,
      });
    }

    const { info = {}, question } = this.props;
    const { info: previousInfo = {} } = prevProps;

    if (
      info.changingQuestionIid == question.iid &&
      info.changingQuestionIid != previousInfo.changingQuestionIid
    ) {
      this.handleBeforeChangingQuestion();
    }
  }

  handleBeforeChangingQuestion = () => {
    const {
      dispatch,
      question,
      showSnackbar,
      formValues,
      isSurvey,
      dirty,
      userAnswers,
    } = this.props;

    if (!formValues || !userAnswers) {
      return;
    }

    const formValid = isQuestionFormValid(
      userAnswers,
      formValues,
      question.score,
      isSurvey,
    );

    if (formValid) {
      if (dirty) {
        dispatch(submit(getOEFormId(this.props)));
      }
    } else {
      showSnackbar('error', t1('answer_cannot_be_empty'));
    }

    const { onFinishHandleChangingQuestion } = this.props;
    if (typeof onFinishHandleChangingQuestion === 'function') {
      // we wait 1 tick so that it can submit the form before unmounting this component to load the next question
      setTimeout(() => {
        onFinishHandleChangingQuestion(question, formValid);
      });
    }
  };

  saveAnswer = (formValues) => {
    if (!formValues) {
      return;
    }

    const { content, attachments, youtube } = formValues;
    const {
      question,
      courseIid,
      isSurvey,
      showSnackbar,
      userAnswers,
    } = this.props;
    if (
      !isQuestionFormValid(userAnswers, formValues, question.score, isSurvey)
    ) {
      showSnackbar('error', t1('answer_cannot_be_empty'));
      return;
    }

    const newAnswer = {
      content,
      attachments,
      youtube,
      question: {
        iid: question.iid,
        type: question.type,
        weighted: question.weighted,
      },
      course: courseIid,
    };

    const { setUserAnswers } = this.props;

    if (setUserAnswers) {
      setUserAnswers(newAnswer);
    }
  };

  handleTypingAnswer = (event) => {
    const values = lodashGet(event, 'target.value');
    const { input, setUserAnswers } = this.props;

    if (input && typeof input.onChange === 'function') {
      input.onChange(values);
    }

    setUserAnswers({
      content: values,
    });
  };

  isAttachmentsChange = () => {
    const { userAnswers, formValues } = this.props;
    const content = lodashGet(userAnswers, 'content');
    const contentForms = lodashGet(formValues, 'content');

    let attachments = lodashGet(userAnswers, 'attachments', []);
    let attachmentsForms = lodashGet(formValues, 'attachments', []);

    if (Array.isArray(content) && Array.isArray(contentForms)) {
      attachments = content.reduce((val, cur, index) => {
        return cur.attachments ? val.concat(cur.attachments) : val;
      }, []);

      attachmentsForms = contentForms.reduce((val, cur, index) => {
        return cur.attachments ? val.concat(cur.attachments) : val;
      }, []);
    }
    return attachments.length !== attachmentsForms.length;
  };

  handleFormBlur = () => {
    const {
      formValues,
      syncErrors,
      question,
      showSnackbar,
      userAnswers,
    } = this.props;
    const isChangedValue = isFormValuesDifferenceUserAnswers(
      userAnswers,
      formValues,
      question,
    );

    if (!formValues || !isChangedValue) {
      return;
    }

    let errorText = '';

    const structureAnswer = lodashGet(question, 'structure_answer');
    if (structureAnswer && structureAnswer.length) {
      errorText = manualValidateWordMinMax({
        formValues,
        params: {
          wordmin: question.wordmin,
          wordmax: question.wordmax,
        },
      });
    }

    if (errorText) {
      showSnackbar('error', errorText);
      return;
    }

    const formErrors = Object.keys(syncErrors || {}).length;
    if (!formErrors) {
      this.saveAnswer(formValues);
    }
  };

  handleFormChangeAttachments = () => {
    const { formValues, userAnswers } = this.props;
    if (isUserAnswerAttachmentsDifferent(userAnswers, formValues)) {
      this.saveAnswer(formValues);
    }
  };

  getRightMenuItemByPeerActivity = (peerActivities = []) => {
    const { question, userAnswers } = this.props;

    return getMenuItems(peerActivities, question, userAnswers);
  };

  renderMarkingInformation = () => {
    const { question } = this.props;
    const timestampSubmit = lodashGet(question, 'ts');
    const score = lodashGet(question, 'score');
    const markerComment = last(lodashGet(question, 'comments'));

    const isMarkingPassOrFail = !!lodashGet(question, 'marking_pass_or_fail');
    const statusMarkingPassOrFail = !!score;

    return (
      <div className="open-ended-question-form__score">
        <Portal id={portals.questionScore(question.id)}>
          <span style={{ fontWeight: 'normal' }}>
            {t1('your_current_score')}
          </span>
          :{' '}
          {isQuestionMarked(question) ? (
            <>
              {isMarkingPassOrFail ? (
                waitForMarking(score) ? (
                  <span>{t1('wait_for_marking')}</span>
                ) : (
                  <PassOrFail status={statusMarkingPassOrFail} />
                )
              ) : (
                <TotalPoint point={score} />
              )}
            </>
          ) : (
            <WaitForMarking />
          )}
        </Portal>

        {timestampSubmit && !isSmallScreen && (
          <Portal id={portals.questionTimeSubmit(question.id)}>
            <ShowDatetimeFromTimestamp
              timestamp={timestampSubmit}
              label={t1('time_open_ended_submit')}
            />
          </Portal>
        )}

        <ViewComment comment={markerComment} />
      </div>
    );
  };

  renderOEForm = () => {
    const {
      question,
      userAnswers,
      courseIid,
      isExamMode,
      readOnly,
      exerciseStep,
      info,
      course,
      inlineExercise,
      disabled,
    } = this.props;

    const takeId = lodashGet(info, 'takeId');
    const questionIid = lodashGet(question, 'iid');
    const acceptFiles = lodashGet(question, 'accept_files');
    const acceptFileExtensions = getAcceptFileExtensionFromAcceptFileType(
      acceptFiles,
    );
    const structureAnswer = lodashGet(question, 'structure_answer');
    const resultMarked = {
      score: lodashGet(question, 'score'),
      score_by_rubric: lodashGet(question, 'score_by_rubric'),
    };

    const isAdvancedLayoutType = isOEAdvancedLayoutType(question);

    return (
      <OpenEndedSchemaForm
        formid={getOEFormId(this.props)}
        step="question"
        isExamMode={isExamMode}
        takeId={takeId}
        onSubmit={this.saveAnswer}
        structureAnswer={structureAnswer}
        courseIid={courseIid}
        question={question}
        questionIid={questionIid}
        resultMarked={resultMarked}
        params={{
          accept_files: acceptFileExtensions,
          min_video_duration: question.min_video_duration,
          max_video_duration: question.max_video_duration,
          wordmin: question.wordmin,
          wordmax: question.wordmax,
        }}
        hideSubmitButton={readOnly || isAdvancedLayoutType || inlineExercise}
        readOnly={readOnly || disabled}
        isAdvancedLayoutType={isAdvancedLayoutType}
        exerciseStep={exerciseStep}
        onFormBlur={this.handleFormBlur}
        onChange={this.handleFormChangeAttachments}
        course={course}
        userAnswers={userAnswers}
        acceptFiles={acceptFileExtensions}
        inlineExercise={inlineExercise}
      />
    );
  };

  renderForm = () => {
    const { userAnswers, isExamMode, isSurvey, info, noMarking } = this.props;
    const shouldShowMarkingInfo = showMarkingInformation(
      info,
      userAnswers,
      isExamMode,
      noMarking,
    );

    const oeForm = this.renderOEForm();

    if (isSurvey) {
      return (
        <InputText.TextArea
          {...this.props}
          defaultValue={lodashGet(userAnswers, 'content')}
          title={t1('text')}
          fullWidth
          autosize={{ minRows: 3, maxRows: 5 }}
          onBlur={this.handleTypingAnswer}
        />
      );
    }

    return (
      <>
        <div className="m-b-15">
          {!!shouldShowMarkingInfo && this.renderMarkingInformation()}
        </div>

        {oeForm}
      </>
    );
  };

  onActiveMenu = (item) =>
    this.setState({
      activatedMenuItem: item,
    });

  renderRightMenu = () => {
    const {
      readOnly,
      question,
      isSurvey,
      inlineExercise,
      exerciseStep,
      userAnswers,
    } = this.props;

    return (
      <OEMenu
        exerciseStep={exerciseStep}
        inlineExercise={inlineExercise}
        isSurvey={isSurvey}
        onActiveMenu={this.onActiveMenu}
        question={question}
        readOnly={readOnly}
        userAnswers={userAnswers}
        defaultActivatedMenuItem={defaultActivityMenuItem}
        noPortal
      />
    );
  };

  renderFormByActivatedMenu = () => {
    const { activatedMenuItem } = this.state;
    const {
      courseIid,
      question,
      inlineExercise,
      noMarking = false,
      userAnswers,
      info,
      isExamMode,
      course,
    } = this.props;
    const questionIid = lodashGet(question, 'iid');
    const takeLimit = lodashGet(question, 'view_peer_take_limit');
    const syllabusIid = lodashGet(course, 'syllabus');
    const takeId = lodashGet(info, 'takeId');

    const canPeerMarkingQuestion = canPeerMarking(
      info,
      isExamMode,
      noMarking,
      question,
    );

    let content = this.renderForm();

    switch (activatedMenuItem && activatedMenuItem.id) {
      case openEndedPeerActivity.VIEW_DESIGN_OF_OTHER: {
        content = (
          <PeerTakes
            courseIid={courseIid}
            questionIid={questionIid}
            question={question}
            canPeerMarkingQuestion={canPeerMarkingQuestion}
            peerMarking
            noSelectCourse
            takeLimit={takeLimit}
            noMarking={noMarking}
            formId={`peer-takes-of-${questionIid}`}
            noTitle
          />
        );

        break;
      }
      case openEndedPeerActivity.GENERAL_ANALYSIS: {
        content = (
          <QuestionReports
            courseIid={courseIid}
            question={question}
            showHeader
            percentagePortalId={portals.questionAction(question.id)}
          />
        );

        break;
      }
      case openEndedPeerActivity.DETAIL_MARKING: {
        content = (
          <DetailMarkingByRubric
            value={lodashGet(userAnswers, 'score_by_rubric.detail')}
            rubricIid={lodashGet(userAnswers, 'score_by_rubric.rubric_iid')}
            readOnly
          />
        );

        break;
      }
      case openEndedPeerActivity.CAN_COMMENT: {
        content = (
          <CollaboratingItemComment
            syllabusIid={syllabusIid}
            courseIid={courseIid}
            collaboratingItemId={questionIid}
            collaboratingItem={question}
            isLearn={true}
            askTeacherToAnswer={false}
          />
        );

        break;
      }
      case openEndedPeerActivity.TEACHER_CAN_COMMENT: {
        content = (
          <OpenEndedQuestionComments
            title={t1('comments')}
            takeId={takeId}
            questionIid={questionIid}
            defaultShowDetailComments
          />
        );

        break;
      }
    }

    return <div className={inlineExercise ? 'p-t-25' : ''}>{content}</div>;
  };

  render() {
    const { question, userAnswers, isExamMode } = this.props;
    const markingGuide = <MarkingGuide question={question} forStudent />;
    const isAdvancedLayoutType = isOEAdvancedLayoutType(question);
    const isAdvancedTableLayout = isOEAdvancedTableLayout(question);
    const isAnswerAttachmentsArchived = lodashGet(
      question,
      'is_answer_attachments_archived',
    );
    const expectedTimeToAccessRestoredArchiveFile = lodashGet(
      question,
      'expected_time_to_access_restored_archive_file',
    );

    const {
      archiveRestorationFromTs,
      archiveRestorationToTs,
      isArchiveRestorationInitiated,
      isArchiveRestored,
    } = getArchivedAnswerAttachmentsRestorationInfo(
      expectedTimeToAccessRestoredArchiveFile,
    );

    if (isAnswerAttachmentsArchived && !isArchiveRestored) {
      if (isArchiveRestorationInitiated) {
        return (
          <Warning>
            {t1(
              'your_archived_answer_is_being_restored,_it_will_available_from_%s_to_%s',
              [
                timestampToDateTimeString(archiveRestorationFromTs),
                timestampToDateTimeString(archiveRestorationToTs),
              ],
            )}
          </Warning>
        );
      }

      return (
        <Warning>
          {t1(
            'your_answer_has_been_archived,_please_contact_support_if_you_need_to_get_or_edit_it',
          )}
        </Warning>
      );
    }

    if (isAdvancedLayoutType && !isAdvancedTableLayout) {
      return (
        <>
          {markingGuide}
          {this.renderForm()}
        </>
      );
    }

    return (
      <div className="open-ended-question-form">
        {markingGuide}

        {userAnswers || isExamMode ? (
          <>
            {this.renderRightMenu()}
            {this.renderFormByActivatedMenu()}
          </>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const learnItemIid = props.learnItemIid || state.learn.itemIid;
  const isExamMode = props.mode === 'exam';
  const question = props.question || {};

  const usedFor = lodashGet(props, 'question.used_for');
  // TODO Hot fix check hien thi cau hoi cho survey
  const isSurvey =
    Array.isArray(usedFor) && usedFor.length === 1 && usedFor[0] === 'survey';

  const userAnswers = props.userAnswers;
  const userIid = getLearningItemUserIidSelector(state)(learnItemIid);
  const mode = getLearningItemModeSelector(state)(learnItemIid);
  const info = getLearnItemInfoSelector(state)(learnItemIid);
  const formId = getOEFormId(props);

  return {
    userAnswers,
    isExamMode,
    isSurvey,
    userIid,
    info,
    question,
    readOnly: mode === 'preview_test',
    formValues: getFormValues(formId)(state),
    dirty: isDirty(formId)(state),
    syncErrors: getFormSyncErrors(formId)(state),
  };
};

export default withSnackbar()(connect(mapStateToProps)(OpenEnded));
