import lodashGet from 'lodash.get';
import Warning from 'components/common/Warning';
import SuccessAlert from 'components/common/SuccessAlert';
import React from 'react';

const MarkingRatioInResultTable = ({ course }) => {
  const marked = lodashGet(course, 'stats.marking.marked', 0);
  const submissions = lodashGet(course, 'stats.marking.submissions', 0);

  if (marked < submissions)
    return (
      <div>
        <Warning inline>{marked}</Warning>/{submissions}
      </div>
    );
  else
    return (
      <div>
        <SuccessAlert inline>{marked}</SuccessAlert>/{submissions}
      </div>
    );
};

export default MarkingRatioInResultTable;
