import AutoComplete from 'schema-form/elements/autocomplete';

const InputAutoComplete = ({
  floatingLabelText,
  readOnly,
  onChange,
  requestUrl,
  defaultRequestParams = {},
  searchKeys = ['iid', 'name'],
  transformData,
  useCaseSensitive,
  debounceTime = 300,
  multiple,
  onSearched,
  placeholder,
  classWrapper,
  allowClear,
  ...remainProps
}) => {
  return {
    type: AutoComplete,
    floatingLabelText,
    readOnly,
    onChange,
    requestUrl,
    defaultRequestParams,
    searchKeys,
    transformData,
    useCaseSensitive,
    debounceTime,
    multiple,
    onSearched,
    placeholder,
    classWrapper,
    allowClear,
    ...remainProps,
  };
};

export default InputAutoComplete;
