import React from 'react';
import get from 'lodash.get';
import withUserInfo from 'common/hoc/withUserInfo';
import SearchChooseModule from './search';
import SubTopMenuContext from 'common/context/menu/SubMenuTop';
import SubLeftMenuContext from 'common/context/menu/SubMenuLeft';
import { Redirect, withRouter } from 'react-router';
import { getTargetUser, targetUserOptions } from 'configs/constants/user';

const ChooseModule = ({ userInfo, match }) => {
  const targetUser = get(match, 'params.targetUser');

  const menuItems = targetUserOptions().map((target) => {
    return {
      id: `target-user-${target.value}`,
      url: `/admin/bdtx/choose-modules/${target.value}`,
      title: `Chọn mô đun cho đối tượng ${getTargetUser(target.value)}`,
    };
  });

  if (!targetUser) {
    return <Redirect to={`/admin/bdtx/choose-modules/gv`} />;
  }

  return (
    <>
      <SubLeftMenuContext schema={menuItems} />

      <SubTopMenuContext
        lastBreadcrumbName="Chọn mô đun cho năm học"
        hideBackAction
      />

      <SearchChooseModule targetUser={targetUser} userInfo={userInfo} />
    </>
  );
};

export default withUserInfo(withRouter(ChooseModule));
