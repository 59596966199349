import React, { Component } from 'react';
import { t1 } from 'translate';
import SearchTakes from './Search';
import Portal, { portals } from 'components/common/portal';
import withUserInfo from 'common/hoc/withUserInfo';
import fetchData from 'components/common/fetchData';
import lGet from 'lodash.get';
import apiUrls from 'api-endpoints';

class PeerTakes extends Component {
  render() {
    const {
      courseIid,
      questionIid,
      question,
      peerMarking,
      canPeerMarkingQuestion,
      noSelectCourse,
      userInfo,
      course,
      takeLimit,
      noMarking,
      formId,
    } = this.props;

    return (
      <div>
        {!this.props.noTitle && (
          <Portal id={portals.questionHeader(question.id)}>
            <span className={'m-l-10'}>
              {' '}
              {t1('takes_by_all_students_in_course')}
            </span>
          </Portal>
        )}
        <SearchTakes
          courseIid={courseIid}
          questionIid={questionIid}
          peerMarking={peerMarking}
          canPeerMarkingQuestion={canPeerMarkingQuestion}
          hideSearchButton
          isNotYetMarkedShown={false}
          isTakeUpdatedShown={false}
          noSelectCourse={noSelectCourse}
          userInfo={userInfo}
          node={course}
          takeLimit={takeLimit}
          noMarking={noMarking}
          formId={formId}
        />
      </div>
    );
  }
}

const fetchCourseDetail = fetchData((props) => {
  const courseIid = lGet(props, 'courseIid', '');
  return {
    baseUrl: apiUrls.get_course_api,
    params: {
      ciid: courseIid,
    },
    propKey: 'course',
  };
});

export default withUserInfo(fetchCourseDetail(PeerTakes));
