import React, { useCallback, useEffect, useMemo } from 'react';
import get from 'lodash.get';
import { t1 } from 'translate';
import AntInput from 'antd/lib/input';
import CommonAntdTable from 'components/common/antd/table';
import usePrevious from 'components/common/hook/usePrevious';
import './style.scss';
import { isSmallScreen } from 'common';

const { TextArea } = AntInput;

const TableEditor = ({
  nameOfRows = [],
  nameOfColumns = [],
  columnWidths = [],
  defaultValue = {},
  onChange,
  readOnly,
  readOnlyHeader = false,
  renderItem,
  alwaysShowScroll,
  rows,
  className,
  hideHeader = false,
  hideFirstColumn = false,
}) => {
  const onDataChange = useCallback(
    (newData) => {
      if (typeof onChange === 'function') {
        onChange(newData);
      }
    },
    [onChange],
  );

  const previousNameOfRows = usePrevious(nameOfRows);
  const previousNameOfColumns = usePrevious(nameOfColumns);

  const getDataSourceWhenChangeRow = useCallback(
    (previousRows, currentRows, currentColumns, currentDataSource) => {
      const newDataSource = [];

      currentRows.forEach((item, index) => {
        const rowIndexFound = previousRows.findIndex((r) => r === item);
        if (rowIndexFound >= 0) {
          const rowFound = get(currentDataSource, `[${rowIndexFound}]`, {});
          newDataSource.push({
            ...rowFound,
            iid: index,
          });
        } else {
          let rowObject = { iid: index };
          currentColumns.forEach((column, i) => {
            rowObject[i] = '';
          });
          newDataSource.push(rowObject);
        }
      });
      return newDataSource;
    },
    [],
  );

  const getDataSourceWhenChangeColumn = useCallback(
    (previousColumns, currentColumns, currentDataSource) => {
      const newDataSource = currentDataSource.map((item) => {
        const newItem = {
          iid: item.iid,
        };
        currentColumns.forEach((c, index) => {
          const indexFound = previousColumns.findIndex((col) => col === c);
          newItem[index] = get(item, indexFound, '');
        });
        return newItem;
      });
      return newDataSource;
    },
    [],
  );

  useEffect(
    () => {
      const { dataSource = [] } = defaultValue;
      if (
        !Array.isArray(dataSource) ||
        !Array.isArray(previousNameOfRows) ||
        !Array.isArray(previousNameOfColumns)
      ) {
        return;
      }
      if (
        previousNameOfRows.length === nameOfRows.length &&
        previousNameOfColumns.length === nameOfColumns.length
      ) {
        return;
      }
      let newValue = defaultValue;
      if (dataSource.length !== nameOfRows.length) {
        newValue = {
          ...newValue,
          dataSource: getDataSourceWhenChangeRow(
            previousNameOfRows,
            nameOfRows,
            nameOfColumns,
            get(newValue, 'dataSource', []),
          ),
        };
      }
      const firstDataSourceObject = get(dataSource, `[0]`, {});
      const firstObjectLength = Object.keys(firstDataSourceObject).length - 1; // Exclude iid
      if (nameOfColumns.length !== firstObjectLength) {
        newValue = newValue = {
          ...newValue,
          dataSource: getDataSourceWhenChangeColumn(
            previousNameOfColumns,
            nameOfColumns,
            get(newValue, 'dataSource', []),
          ),
        };
      }
      onDataChange(newValue);
    },
    [
      defaultValue,
      previousNameOfRows,
      previousNameOfColumns,
      nameOfColumns,
      nameOfRows,
      onDataChange,
      getDataSourceWhenChangeRow,
      getDataSourceWhenChangeColumn,
    ],
  );

  const getDefaultDataSource = (rows = [], columns = []) => {
    return rows.map((r, index) => {
      let rowObject = {
        iid: index,
      };
      columns.forEach((c, i) => {
        rowObject[i] = '';
      });
      return rowObject;
    });
  };

  const onCellChange = useCallback(
    (newCellValue, rowIndex, columnKey) => {
      const { dataSource = [] } = defaultValue;

      let newDataSource = [];

      if (dataSource.length) {
        newDataSource = dataSource.map((item) => {
          if (item.iid === rowIndex) {
            return {
              ...item,
              [columnKey]: newCellValue,
            };
          }
          return item;
        });
      } else {
        newDataSource = nameOfRows.map((r, index) => {
          let rowObject = {
            iid: index,
          };
          nameOfColumns.forEach((c, i) => {
            rowObject[i] =
              index === rowIndex && i === columnKey ? newCellValue : '';
          });
          return rowObject;
        });
      }

      const newData = {
        ...defaultValue,
        dataSource: newDataSource,
      };

      onDataChange(newData);
    },
    [defaultValue, onDataChange, nameOfColumns, nameOfRows],
  );

  const onFirstColumnTextChange = useCallback(
    (newFirstColumnText) => {
      const newData = {
        ...defaultValue,
        firstColumnText: newFirstColumnText,
      };
      onDataChange(newData);
    },
    [defaultValue, onDataChange],
  );

  const defaultDataSource = useMemo(
    () => {
      const { dataSource = [] } = defaultValue;
      if (Array.isArray(dataSource) && dataSource.length) {
        return dataSource;
      }
      return getDefaultDataSource(nameOfRows, nameOfColumns);
    },
    [defaultValue, nameOfRows, nameOfColumns],
  );

  const renderTable = useCallback(
    () => {
      const { firstColumnText = '' } = defaultValue;
      if (
        !Array.isArray(nameOfColumns) ||
        !nameOfColumns.length ||
        !Array.isArray(nameOfRows) ||
        !nameOfRows.length
      ) {
        return <div>{t1('no_data')}</div>;
      }
      const widthFirstColumn = get(columnWidths, '[0]');
      const firstColumn = [
        {
          title:
            readOnly || readOnlyHeader ? (
              <span>{firstColumnText}</span>
            ) : (
              <TextArea
                value={firstColumnText}
                onChange={(event) =>
                  onFirstColumnTextChange(event.target.value)
                }
                minRows={4}
              />
            ),
          width: widthFirstColumn ? `${widthFirstColumn}px` : 'auto',
          render: (row, item, index) => {
            return get(nameOfRows, `[${index}]`, '');
          },
        },
      ];

      const tableColumns = nameOfColumns.map((nameOfColumn, index) => {
        const widthStyle = get(
          columnWidths,
          `[${hideFirstColumn ? index : index + 1}]`,
        );
        return {
          title: hideHeader ? undefined : nameOfColumn,
          width: widthStyle ? `${widthStyle}px` : 'auto',
          render: (row, item, idx) => {
            const { iid = '' } = row;
            const currentValue = get(row, `[${index}]`, '');
            if (readOnly) {
              return <span>{currentValue}</span>;
            }
            if (typeof renderItem === 'function') {
              return (
                <>
                  {renderItem(currentValue, idx, index, (value) =>
                    onCellChange(value, iid, index),
                  )}
                </>
              );
            }

            return (
              <TextArea
                value={currentValue}
                onChange={(event) =>
                  onCellChange(event.target.value, iid, index)
                }
                minRows={4}
                rows={rows}
                key={`input_${iid}_${index}`}
              />
            );
          },
        };
      });

      const allColumns = hideFirstColumn
        ? tableColumns
        : [...firstColumn, ...tableColumns];

      return (
        <CommonAntdTable
          dataSource={defaultDataSource}
          columns={allColumns}
          pagination={false}
          rowKey="iid"
          scroll={{
            x: alwaysShowScroll ? true : isSmallScreen,
          }}
          className={`learn-default ${
            hideHeader ? 'table-editor__hidden-header' : ''
          } ${className}`}
        />
      );
    },
    [
      defaultValue,
      nameOfColumns,
      nameOfRows,
      columnWidths,
      readOnly,
      readOnlyHeader,
      hideFirstColumn,
      defaultDataSource,
      alwaysShowScroll,
      hideHeader,
      className,
      onFirstColumnTextChange,
      renderItem,
      rows,
      onCellChange,
    ],
  );

  return <div className="table-editor">{renderTable()}</div>;
};

export default TableEditor;
