import React, { Component } from 'react';
import Warning from 'components/common/Warning';
import { getLearnItemInfoSelector } from 'common/learn';
import { connect } from 'react-redux';
import lodashGet from 'lodash.get';
import t1 from 'translate';
import QuestionSavingStatus from '../question-saving-status';

class ConfirmToLeave extends Component {
  render() {
    const {
      onSubmit,
      onGoBack,
      onCancel,
      savingAnswers,
      message,
      submitLabel = t1('retry_saving_take'),
      pendingQuestions,
      showPendingToSaveQuestions = false,
      learnItemIid = null,
    } = this.props;

    const saving = pendingQuestions.length || savingAnswers;

    return (
      <div className="text-center">
        <Warning>
          <span className="font-size-large">{message}</span>
        </Warning>

        {showPendingToSaveQuestions && learnItemIid && (
          <QuestionSavingStatus theme="dark" itemIid={learnItemIid} />
        )}

        {!showPendingToSaveQuestions && (
          <div className="text-center m-t-25">
            <button
              className="btn btn-secondary"
              disabled={saving}
              onClick={() => onSubmit()}
            >
              {submitLabel}
            </button>
            {!!onCancel && (
              <button
                className="m-l-10 btn btn-tertiary"
                disabled={saving}
                onClick={() => onCancel()}
              >
                {t1('discard_current_take')}
              </button>
            )}
            <button
              className="m-l-10 btn btn-primary"
              disabled={saving}
              onClick={() => onGoBack()}
            >
              {t1('back_to_exercise_to_continue')}
            </button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { learnItemIid } = props;
  const info = getLearnItemInfoSelector(state)(learnItemIid);

  return {
    pendingQuestions: lodashGet(info, 'pendingQuestions') || [],
    savingAnswers: info && !!info.saving_answers,
  };
};

export default connect(
  mapStateToProps,
  null,
)(ConfirmToLeave);
