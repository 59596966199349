import get from 'lodash.get';

export const getMyReply = (replies = [], userInfo) =>
  replies.find(
    ({ user }) => String(get(user, 'iid')) === String(get(userInfo, 'iid')),
  );

export const getGVCCReply = (replies = [], gvccAssigned) => {
  return replies.find(
    ({ user }) =>
      String(get(user, 'iid')) === String(get(gvccAssigned, 'assign_to')),
  );
};

export const getMasterReply = (replies = [], masterAssigned) =>
  masterAssigned &&
  replies.find(
    ({ user }) =>
      String(get(user, 'iid')) === String(get(masterAssigned, 'assign_to')),
  );

const getMastersAndGVCCsAssigned = (teachersAnswer) => {
  teachersAnswer = teachersAnswer || [];

  const assignToIids = teachersAnswer.map((a) => get(a, 'assign_to'));

  let assignToTeacher = [];
  let assignToMaster = [];

  teachersAnswer.forEach((assignInfo) => {
    if (
      // vì đầu tiên comment sẽ đc assign cho gvcc sau đó gvcc sẽ assign lại cho gvsp
      assignToIids.findIndex((iid) => get(assignInfo, 'assignor') == iid) !==
        -1 &&
      // vì is_automatic_assigned chỉ dùng để tạo assign feedback cho gv của course (không phải cho gvsp)
      !get(assignInfo, 'is_automatic_assigned')
    ) {
      assignToMaster = assignToMaster.concat([assignInfo]);
    } else {
      assignToTeacher = assignToTeacher.concat([assignInfo]);
    }
  });

  return {
    assignToTeacher,
    assignToMaster,
  };
};

export const getAllMastersAssigned = (teachersAnswer = []) => {
  const { assignToMaster } = getMastersAndGVCCsAssigned(teachersAnswer);
  return assignToMaster;
};

export const getOneMasterAssigned = (teachersAnswer = []) => {
  const { assignToMaster } = getMastersAndGVCCsAssigned(teachersAnswer);
  return get(assignToMaster, '[0]');
};

export const getAllGVCCsAssigned = (teachersAnswer) => {
  const { assignToTeacher } = getMastersAndGVCCsAssigned(teachersAnswer);
  return assignToTeacher;
};

export const getOneGVCCAssigned = (teachersAnswer) => {
  const { assignToTeacher } = getMastersAndGVCCsAssigned(teachersAnswer);
  return get(assignToTeacher, '[0]');
};

export const getCommentAssignToMe = (teachersAnswer, userInfo) =>
  teachersAnswer.find(
    ({ assign_to }) => String(assign_to) === String(get(userInfo, 'iid')),
  );

export const getOneMasterAssignedPrioritizedWhoReplied = (
  teachersAnswer,
  replies,
) => {
  const allMastersAssigned = getAllMastersAssigned(teachersAnswer) || [];

  let masterAssigned = allMastersAssigned[0];
  let masterReplied;

  for (let assignInfo of allMastersAssigned) {
    const mayBeMasterReplied = getMasterReply(replies, assignInfo);
    if (mayBeMasterReplied) {
      masterReplied = mayBeMasterReplied;
      masterAssigned = assignInfo;
      break;
    }
  }

  return {
    masterAssigned,
    masterReplied,
  };
};

export const getOneGVCCAssignedPrioritizedWhoReplied = (
  teachersAnswer,
  replies,
) => {
  const allGVCCsAssigned = getAllGVCCsAssigned(teachersAnswer) || [];

  let gvccAssigned = allGVCCsAssigned[0];
  let gvccReplied;

  for (let assignInfo of allGVCCsAssigned) {
    const mayBeGVCCReplied = getGVCCReply(replies, assignInfo);
    if (mayBeGVCCReplied) {
      gvccReplied = mayBeGVCCReplied;
      gvccAssigned = assignInfo;
      break;
    }
  }

  return {
    gvccAssigned,
    gvccReplied,
  };
};
