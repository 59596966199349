import React from 'react';
import { t1 } from 'translate';
import Warning from 'components/common/Warning';
import lodashGet from 'lodash.get';
import VideoPlayer from 'components/common/media-player/video';

const OpenEndedAnswerLayoutFreestyle = ({
  groups: {
    default: { fieldNames },
  },
  submitButton,
  formValues,
  ...props
}) => {
  const isAdvancedLayoutType = lodashGet(
    props,
    'layoutOptionsProperties.isAdvancedLayoutType',
  );
  const exerciseStep = lodashGet(props, 'layoutOptionsProperties.exerciseStep');
  const inlineExercise = lodashGet(
    props,
    'layoutOptionsProperties.inlineExercise',
  );
  const youtubeLink = lodashGet(formValues, 'youtube');

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="m-b-10 m-t-10">{fieldNames.content}</div>
      </div>

      {fieldNames.attachments && (
        <div className="row">
          <div className="m-b-10">{fieldNames.attachments}</div>
        </div>
      )}

      {fieldNames.youtube && (
        <div className="row">
          <div className="m-b-10">{fieldNames.youtube}</div>
          {!!youtubeLink && (
            <div className="m-b-10">
              <VideoPlayer
                controls
                youTubeId={formValues.youtube}
                autoPlay="true"
                width="100%"
              />
            </div>
          )}
        </div>
      )}

      {!submitButton ? null : (
        <>
          {!isAdvancedLayoutType && exerciseStep === 'main' && (
            <div className="row">
              <div className="col-md-12 text-center">
                <Warning>
                  {t1(
                    'before_moving_on_to_another_question_you_must_submit_your_answer_for_this_question_by_clicking_the_button_below',
                  )}
                </Warning>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-md-12 text-center">{submitButton}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default OpenEndedAnswerLayoutFreestyle;
