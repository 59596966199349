import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { t1 } from 'translate';
import Attachments from 'schema-form/elements/attachments/core/Component';
import TextInput from 'schema-form/elements/text/TextField';
import QuillRickText from 'schema-form/elements/richtext/QuillRickText';
import { normalizeYoutubeUrl } from 'common/normalizers';
import RaisedButton from 'components/common/mui/RaisedButton';
import { Card } from 'antd';
import './stylesheet.scss';
import { DEFAULT_SUBMISSION_KEY } from 'actions/attachment';

const UploadAnswers = ({
  fields = [],
  currentValue = '',
  onChange = () => {},
  onCloseDialog = () => {},
  fileUploading,

  minVideoDuration,
  maxVideoDuration,
}) => {
  let [value, setValue] = React.useState(currentValue);

  return (
    <div className="upload-answers">
      {fields.includes('youtube') && (
        <Card
          type="inner"
          hoverable
          title={t1('input_youtube_url')}
          style={{ marginBottom: 30 }}
        >
          <TextInput
            placeholder={'youtube'}
            value={get(value, 'youtube')}
            onChange={(e) => {
              const url = e.target.value;
              value.youtube = normalizeYoutubeUrl(url);
              setValue({ ...value });
            }}
            fullWidth
          />
        </Card>
      )}

      {fields.includes('attachment') && (
        <Card
          type="inner"
          hoverable
          title={t1('upload_your_documents')}
          style={{ marginBottom: 30 }}
        >
          <Attachments
            noFileManager
            allowDownload
            value={get(value, 'attachments') || []}
            onChange={(attachments) => {
              setValue({ ...value, attachments: attachments });
            }}
            minVideoDuration={minVideoDuration}
            maxVideoDuration={maxVideoDuration}
          />
        </Card>
      )}

      {fields.includes('text') && (
        <Card
          type="inner"
          hoverable
          title={t1('edit_your_document')}
          style={{ marginBottom: 30 }}
        >
          <QuillRickText
            rows={8}
            value={get(value, 'content')}
            onChange={(text) => {
              value.content = text;
              setValue({ ...value });
            }}
          />
        </Card>
      )}

      <div className="text-center m-t-20 m-b-20">
        <button
          className="m-l-10 m-r-10 btn-tertiary"
          onClick={() => {
            onCloseDialog();
          }}
          disabled={fileUploading}
        >
          {t1('cancel')}
        </button>

        <RaisedButton
          className="m-l-10 m-r-10 btn btn-primary"
          onClick={() => {
            onChange(value);
          }}
          label={t1('submit')}
          disabled={fileUploading}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  fileUploading: get(state, `attachments.${DEFAULT_SUBMISSION_KEY}`),
});

export default connect(mapStateToProps)(UploadAnswers);
