import { t1 } from 'translate';
import { caphoc, targetUser } from 'components/common/elements';
import { academicCategories } from 'components/admin/academic-category/schema/elements';

const schema = (formid) => ({
  caps: caphoc({ classWrapper: 'col-md-12' }),
  target_users: targetUser({ classWrapper: 'col-md-6', defaultValue: [] }),
  min_teaching_exp_years: {
    classWrapper: 'col-md-6',
    floatingLabelText: t1('min_number_of_years_of_teaching_experience'),
    type: 'number',
    min: 0,
    max: 40,
  },
  academic_categories: academicCategories(formid, {
    multiple: true,
    allowClear: true,
    dropdownStyle: {
      maxHeight: '30vh',
    },
    label: t1('academic_categories_of_user'),
    classWrapper: 'col-md-12',
  }),
  certificates: {
    type: 'select',
    multiple: true,
    floatingLabelText: t1('certificates'),
    floatingLabelFixed: true,
    fullWidth: true,
    showSearch: true,
    optionFilterProp: 'children',
    options: 'async',
    paramsasync: {
      __url__: '/certificate-template/api/search',
      params: {
        status: ['approved'],
        items_per_page: -1,
      },
    },
  },
  external_certificate_types: {
    type: 'select',
    multiple: true,
    options: 'async',
    floatingLabelText: t1('external_certificate_types'),
    floatingLabelFixed: true,
    fullWidth: true,
    showSearch: true,
    optionFilterProp: 'children',
    paramsasync: {
      __url__: '/student/api/get-certificate-type-options',
      transformData: (data) => {
        if (!Array.isArray(data) || !data.length) {
          return [];
        }
        return data.map((val) => ({
          value: val,
          label: t1(`certificate_type_${val}`),
          primaryText: t1(`certificate_type_${val}`),
        }));
      },
    },
  },
});

const ui = () => [
  {
    id: 'default',
    fields: [
      'caps',
      'target_users',
      'min_teaching_exp_years',
      'academic_categories',
      'certificates',
      'external_certificate_types',
    ],
  },
];

export default { schema, ui };
