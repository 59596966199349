import React from 'react';
import SimpleImport from 'components/admin/import-simple';
import get from 'lodash.get';

const ImportCourse = ({ enrolmentPlanIid }) => {
  const columns = [
    {
      title: 'STT',
      className: 'text-center',
      render: (text, row, index) => {
        return index + 1;
      },
    },
    {
      title: 'Môn học',
      render: (row) => {
        return get(row, 'syllabus_name');
      },
    },
    {
      title: 'Tên lớp',
      render: (row) => {
        return get(row, 'name');
      },
    },
    {
      title: 'Mã lớp',
      render: (row) => {
        return get(row, 'code');
      },
    },
    {
      title: 'Mã giảng viên',
      render: (row) => {
        return get(row, 'teachers');
      },
    },
    {
      title: 'Ngày bắt đầu',
      render: (row) => {
        return get(row, 'start_date');
      },
    },
    {
      title: 'Ngày kết thúc',
      render: (row) => {
        return get(row, 'end_date');
      },
    },
    {
      title: 'Lỗi',
      width: 300,
      render: (row) => {
        const completed = get(row, 'status') === 'completed';
        const errors = get(row, 'errors');

        if (completed) {
          return '';
        }

        if (!Array.isArray(errors) || !errors.length) {
          return '';
        }

        return errors.map((err) => (
          <span>
            <p style={{ backgroundColor: '#faad14' }}>{err}</p>
          </span>
        ));
      },
    },
  ];

  return (
    <SimpleImport
      type={'course'}
      importTempApiUrl={'/course/import/import-courses'}
      searchImportApiUrl={'/course/import/search'}
      importApiUrl={'/course/import/process'}
      downloadTemplateParams={{
        type: 'course',
        template: 'import_course',
      }}
      otherImportParams={{ ep_iid: enrolmentPlanIid }}
      searchImportColumns={columns}
    />
  );
};

export default ImportCourse;
