import { t, t1 } from 'translate';
import Badge from 'antd/lib/badge';
import React from 'react';
import {
  communicationDeliveryMethods,
  communicationTitle,
  communicationContent,
  communicationNote,
} from './utils';

const schema = (formid, values, localStep, xpath, props) => {
  const { itemSelected = 0, totalResult = 0 } = values;

  const totalMemberToSend = values.send_all ? totalResult : itemSelected;
  const { communicationOptions = [], type = 'members' } = props;

  return {
    view_description: {
      type: 'cascade',
      component: (
        <h3 className="notify-send-for">
          {t1('send_notify_for')}{' '}
          <Badge
            count={totalMemberToSend}
            style={{ backgroundColor: '#52c41a', fontWeight: 'bold' }}
            overflowCount={totalMemberToSend + 1}
          />{' '}
          {t(values.send_all ? 'users_found' : 'members_selected')}
        </h3>
      ),
    },
    send_all: {
      type: 'checkbox',
      label: t1(`tick_if_send_all_${type}`),
      fullWidth: true,
      valueSet: values && values.targets && values.targets.node_iid,
    },
    delivery_methods: communicationDeliveryMethods({ communicationOptions }),
    subject: communicationTitle(),
    content: communicationContent(),
    note: communicationNote(),
  };
};

const ui = () => {
  return [
    {
      id: 'default',
      fields: [
        'send_all',
        'view_description',
        'delivery_methods',
        'subject',
        'content',
        'note',
      ],
    },
  ];
};

export default { schema, ui };
