import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { Element } from 'schema-form/elements';
import Form from 'antd/lib/form';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';

const { TextArea } = Input;

const ErrorAsTooltip = ({ error }) => {
  if (!error) {
    return;
  }

  return (
    <div className="ant-tooltip ant-tooltip-placement-topLeft">
      <div className="ant-tooltip-content">
        <div className="ant-tooltip-arrow" />
        <div className="ant-tooltip-inner" role="tooltip">
          {error}
        </div>
      </div>
    </div>
  );
};

const Field = ({
  input,
  meta,
  defaultValue,
  populateData,
  elementType,
  fullWidth,
  onInitValue,
  showErrorTooltip,
  ...custom
}) => {
  const [populatedData, setPopulatedData] = useState(false);
  useEffect(
    () => {
      if (typeof populateData !== 'function' || populatedData) {
        return;
      }

      const { name, value } = input || {};

      if (
        typeof defaultValue !== 'undefined' &&
        ['', undefined, null].includes(value)
      ) {
        populateData(name, defaultValue);
        setPopulatedData(true);

        if (typeof onInitValue === 'function') {
          onInitValue(name, defaultValue);
        }
      }
    },
    [
      defaultValue,
      populateData,
      input,
      onInitValue,
      populatedData,
      setPopulatedData,
    ],
  );

  const errorText = meta && meta.touched && meta.error;

  const { onChange, value, ...remainInput } = input || {};

  const onChangeDebounce = debounce((event) => {
    if (typeof onChange === 'function') {
      onChange(event);
    }
  }, 500);

  const componentClass = errorText
    ? 'text-input-wrapper--error'
    : 'text-input-wrapper';

  let Component = null;
  switch (elementType) {
    case 'textarea':
      Component = TextArea;
      break;

    case 'number':
      Component = Input;
      break;
  }

  if (!Component) {
    return <span>Can't render element with type: {elementType}</span>;
  }

  return (
    <span
      className={`${componentClass} d-inline-block position-relative ${
        fullWidth ? 'full-width' : ''
      }`}
    >
      <Form.Item
        validateStatus={errorText ? 'error' : ''}
        help={!showErrorTooltip && errorText ? errorText : ''}
        colon={false}
      >
        {showErrorTooltip && !!errorText && (
          <ErrorAsTooltip error={errorText} />
        )}

        <Component
          {...custom}
          {...remainInput}
          type={elementType}
          defaultValue={defaultValue}
          onChange={(event) => {
            event.persist();
            onChangeDebounce(event);
          }}
        />
      </Form.Item>
    </span>
  );
};

const InputField = ({ dispatch, formid, ...remain }) => {
  const populateData = (field, value) => {
    dispatch(change(formid, field, value));
  };

  return (
    <Element
      schema={{
        ...remain,
        type: Field,
        populateData,
      }}
    />
  );
};

export default connect()(InputField);
