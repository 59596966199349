import React, { Component } from 'react';
import getLodash from 'lodash.get';
import { getTimeName } from '../../../timetable_v2/utils/DailyUnixTimestamp';
import { timestampToDateString } from 'common/utils/Date';
import AddSessionTimeButton from '../extend-session-time';

class ScheduledDateTime extends Component {
  renderStartAndEndTime = (startTime, endTime) => {
    if (startTime && endTime) {
      return (
        <>
          {startTime} → {endTime}
        </>
      );
    }

    return (
      <>
        {startTime}
        {endTime}
      </>
    );
  };

  render() {
    const { session, showAddTimeButton, formid } = this.props;
    const d = getLodash(session, 'scheduled.date_time');
    const startTime = getTimeName(getLodash(session, 'scheduled.start_time'));
    const endTime = getTimeName(getLodash(session, 'scheduled.end_time'));
    return (
      <div>
        <div>
          {!!d && timestampToDateString(d, { convertTimezoneToVN: true })}
        </div>
        <div>{this.renderStartAndEndTime(startTime, endTime)}</div>
        {showAddTimeButton && (
          <div>
            <AddSessionTimeButton session={session} formid={formid} />
          </div>
        )}
      </div>
    );
  }
}

export default ScheduledDateTime;
