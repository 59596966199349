import React from 'react';
import AntdCascader from 'antd/lib/cascader';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { unaccentVietnamese } from 'common/utils/string/vn';
import Icon from 'antd/lib/icon';

const getValueToDisplayByCurrentValueAndOptions = (
  currentValue = [],
  options = [],
  pIid = null,
) => {
  let newValue = currentValue;

  if (!Array.isArray(currentValue)) {
    return [];
  } else if (!Array.isArray(options) || !options.length) {
    return currentValue;
  }

  options.forEach(({ iid, children } = {}) => {
    newValue = getValueToDisplayByCurrentValueAndOptions(
      newValue,
      children,
      iid,
    );
    if (newValue.includes(iid)) {
      if (pIid) {
        newValue.unshift(pIid);
      }
      return;
    }
  });

  return newValue;
};

const Cascader = ({
  treeData,
  placeholder,
  value,
  style = {},
  onChange,
  onDropdownVisibleChange,
  loading,
  className,
  allowClear,
  readOnly,
  showSearch = true,
}) => {
  const cascaderValue = React.useMemo(
    () => {
      if (!Array.isArray(value) || !value.length) {
        return null;
      }

      return getValueToDisplayByCurrentValueAndOptions(value, treeData);
    },
    [treeData, value],
  );

  const handleCascaderChange = React.useCallback(
    (value, selectedOptions) => {
      if (
        typeof value === 'undefined' ||
        !Array.isArray(value) ||
        !value.length
      ) {
        value = null;
      }

      const newValue = value.find((iid) => {
        const selectedOption = selectedOptions.find(
          (option) => option && option.iid === iid,
        );
        return !(
          Array.isArray(selectedOption.children) &&
          selectedOption.children.length
        );
      });

      onChange([newValue]);
    },
    [onChange],
  );

  const handlePopupVisibleChange = React.useCallback(
    (visible) => {
      if (typeof onDropdownVisibleChange === 'function') {
        onDropdownVisibleChange(visible);
      }
    },
    [onDropdownVisibleChange],
  );

  // we fake the tree data to render a loading icon
  if (loading) {
    treeData = [
      {
        value: Math.random(),
        title: <Icon type="loading" style={{ fontSize: 20 }} />,
        disabled: true,
      },
    ];
  }

  return (
    <AntdCascader
      options={treeData}
      value={cascaderValue}
      showSearch={{
        filter: (inputValue, path) => {
          return path.some((option) => {
            return (
              unaccentVietnamese(option.name)
                .toLowerCase()
                .indexOf(unaccentVietnamese(inputValue).toLowerCase()) > -1
            );
          });
        },
      }}
      allowClear={allowClear}
      placeholder={placeholder}
      onChange={handleCascaderChange}
      onPopupVisibleChange={handlePopupVisibleChange}
      style={readOnly ? { ...style, pointerEvents: 'none' } : style}
      className={className}
      fieldNames={{ label: 'name', value: 'iid', children: 'children' }}
      displayRender={(label, selectedOptions) => {
        if (Array.isArray(label)) {
          return label.reverse().join(' / ');
        }
        return null;
      }}
    />
  );
};

hoistNonReactStatic(Cascader, AntdCascader);

export default Cascader;
