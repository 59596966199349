import React from 'react';
import get from 'lodash.get';
import { t1 } from 'translate';
import Menu from 'antd/lib/menu';
import { connect } from 'react-redux';
import PeerTakes from './peer-takes';
import QuestionReports from './reports';
import { portals } from 'components/common/portal';
import Icon from 'components/common/Icon';
import ContentItemToEdit from './submission-area/Trinh';
import tableAnswerByStructure from './submission-area/Table';
import makeReduxFormCompatible from 'components/common/makeReduxFormCompatible';
import { isOEAdvancedTableLayout } from './util';
import OEMenu from './OEMenu';
import { openEndedPeerActivity } from 'components/admin/question/schema/question-types';
import DetailMarkingByRubric from 'components/common/open-ended-take/marking/by-rubric';
import OpenEndedQuestionComments from 'components/common/comment/open-ended-answer-comment';
import CollaboratingItemComment from 'components/common/comment/collaborating-item-comment';

const handleRowOnChange = (onChange, value, valueChanged = {}) => {
  if (typeof onChange !== 'function' || !get(valueChanged, 'id')) {
    return;
  }

  const newValue = Array.isArray(value)
    ? value.filter(({ id }) => id !== get(valueChanged, 'id'))
    : [];
  newValue.push({ ...valueChanged });

  onChange(newValue);
};

const AnswerByStructure = ({
  dataSource,
  defaultItemToEdit,
  value,
  readOnly,
  onChange,
  takeId,
  courseIid,
  questionIid,
  question,
  resultMarked,
  setHideSubmitButton,
  setRubricToShowMarking,
  formid,
  disabled,
  forMarking,
  course,
  userAnswers,
  inlineExercise,
  exerciseStep,
  viewAnswerInMarkingMode,
  minVideoDuration,
  maxVideoDuration,
}) => {
  const [itemToEdit, setItemToEdit] = React.useState(null);

  const syllabusIid = get(course, 'syllabus');

  if (!Array.isArray(dataSource) || !dataSource.length) {
    return <div>{t1('structure_for_submit_answer_not_found')}</div>;
  }

  if (isOEAdvancedTableLayout(question)) {
    // Dạng table để rải phẳng tất cả các câu trả lời.
    return tableAnswerByStructure({
      dataSource,
      value,
      readOnly,
      onChange,
      handleRowOnChange,
    });
  }

  if (!itemToEdit) {
    setItemToEdit(defaultItemToEdit);

    const rubricIidMarking = get(defaultItemToEdit, 'rubric_iid_marking');

    if (typeof setRubricToShowMarking === 'function') {
      setRubricToShowMarking(rubricIidMarking);
    }
  }

  const menu = (
    <OEMenu
      userAnswers={userAnswers}
      viewAnswerInMarkingMode={viewAnswerInMarkingMode}
      question={question}
      onActiveMenu={setItemToEdit}
      inlineExercise={inlineExercise}
      exerciseStep={exerciseStep}
      defaultActivatedMenuItem={get(dataSource, '[0]')}
      dataSource={dataSource}
      setRubricToShowMarking={setRubricToShowMarking}
      setHideSubmitButton={setHideSubmitButton}
    />
  );

  const takeLimit = get(question, 'view_peer_take_limit');

  let content;

  switch (itemToEdit && itemToEdit.id) {
    case openEndedPeerActivity.VIEW_DESIGN_OF_OTHER: {
      content = (
        <PeerTakes
          courseIid={courseIid}
          questionIid={questionIid}
          question={question}
          peerMarking
          noSelectCourse
          takeLimit={takeLimit}
        />
      );
      break;
    }
    case openEndedPeerActivity.GENERAL_ANALYSIS: {
      content = (
        <QuestionReports
          courseIid={courseIid}
          question={question}
          showHeader
          percentagePortalId={portals.questionAction(question.id)}
        />
      );
      break;
    }
    case openEndedPeerActivity.DETAIL_MARKING: {
      content = (
        <DetailMarkingByRubric
          value={get(userAnswers, 'score_by_rubric.detail')}
          rubricIid={get(userAnswers, 'score_by_rubric.rubric_iid')}
          readOnly
        />
      );

      break;
    }
    case openEndedPeerActivity.CAN_COMMENT: {
      content = (
        <CollaboratingItemComment
          syllabusIid={syllabusIid}
          courseIid={courseIid}
          collaboratingItemId={questionIid}
          collaboratingItem={question}
          isLearn={true}
          askTeacherToAnswer={false}
        />
      );

      break;
    }
    case openEndedPeerActivity.TEACHER_CAN_COMMENT: {
      content = (
        <OpenEndedQuestionComments
          title={t1('comments')}
          takeId={takeId}
          questionIid={questionIid}
          defaultShowDetailComments
        />
      );
      break;
    }
    default: {
      content = (
        <ContentItemToEdit
          value={value}
          onChange={onChange}
          itemToEdit={itemToEdit}
          readOnly={readOnly}
          question={question}
          courseIid={courseIid}
          takeId={takeId}
          resultMarked={resultMarked}
          formid={formid}
          handleRowOnChange={handleRowOnChange}
          disabled={disabled}
          forMarking={forMarking}
          course={course}
          userAnswers={userAnswers}
          minVideoDuration={minVideoDuration}
          maxVideoDuration={maxVideoDuration}
        />
      );
    }
  }

  return (
    <>
      <div className="m-b-10">{menu}</div>
      {content}
    </>
  );
};

const getPropsToRenderMenu = (structureAnswer, path = '') => {
  const dataSource = [];
  let defaultItemToEdit = null;
  let defaultOpenKeys = [];

  if (!Array.isArray(structureAnswer) || !structureAnswer.length) {
    return { dataSource, defaultItemToEdit, defaultOpenKeys };
  }

  structureAnswer.forEach(({ children, ...row }, index) => {
    let isParent = false;
    const id = `${path}[${index}]`;
    row.id = id;

    if (Array.isArray(children) && children.length) {
      isParent = true;
      const tmp = getPropsToRenderMenu(children, `${path}[${index}].children`);

      defaultItemToEdit = defaultItemToEdit || tmp.defaultItemToEdit;
      defaultOpenKeys = defaultOpenKeys.concat(tmp.defaultOpenKeys);

      if (Array.isArray(tmp.dataSource) && tmp.dataSource.length) {
        row.children = tmp.dataSource;
      }
    } else {
      defaultItemToEdit = defaultItemToEdit || row;
    }

    row.isParent = isParent;

    defaultOpenKeys.push(id);

    dataSource.push(row);
  });

  return { dataSource, defaultItemToEdit, defaultOpenKeys };
};

const mapStateToProps = (state, props) => {
  const {
    dataSource,
    defaultItemToEdit,
    defaultOpenKeys,
  } = getPropsToRenderMenu(props.structureAnswer);
  return {
    dataSource,
    defaultItemToEdit,
    defaultOpenKeys,
  };
};

const AnswerByStructureExport = connect(mapStateToProps)(AnswerByStructure);

export const MakeReduxFormAnswerByStructure = makeReduxFormCompatible({})(
  AnswerByStructureExport,
);

export default AnswerByStructureExport;
