import React, { Component } from 'react';
import BDTXReportSearch from './component/Form';
import withUserInfo from 'common/hoc/withUserInfo';
import { TCNN_TYPE } from 'components/temis/constant';
import { convertStringToElement } from '../utils';
import { generateReportTitle } from 'components/temis/utils';

class BDTXBo extends Component {
  render() {
    const {
      userInfo = {},
      isResultForCBQL,
      isPlanDevelopment,
      reportTitle,
      isResultForMN,
      reportForNcbdtx,
      autoGetDataWithoutScholastic,
    } = this.props;
    const { organizations = [] } = userInfo;
    const bo = organizations[0];
    const tcnn = isResultForCBQL ? TCNN_TYPE.HT : TCNN_TYPE.GV;
    const formId = `temis-report-survey-needs-${tcnn}-${
      isPlanDevelopment ? 'khbd' : 'ncbd'
    }`;

    const excelExportFileName = generateReportTitle(reportTitle);

    return (
      <div className="report-result">
        <div className={'container'}>
          <div className="report-result__organization m-b-10">
            {`${!!bo && bo.name}`}
          </div>
          <div className="report-result__school-export-excel">
            <div className="report-result__tnnn-type">
              {convertStringToElement(reportTitle)}
            </div>
          </div>
          <hr />
        </div>
        <div className="report-result__container">
          <BDTXReportSearch
            formId={formId}
            tcnn={tcnn}
            exportProps={{
              fileName: excelExportFileName,
            }}
            userInfo={userInfo}
            isResultForMN={isResultForMN}
            isResultForCBQL={isResultForCBQL}
            isPlanDevelopment={isPlanDevelopment}
            autoSearchWhenValuesChange
            reportForNcbdtx={reportForNcbdtx}
            autoGetDataWithoutScholastic={autoGetDataWithoutScholastic}
          />
        </div>
      </div>
    );
  }
}

export default withUserInfo(BDTXBo);
