import { t1 } from 'translate';
import apiEndPoint from 'api-endpoints';
import Layout from './Layout';
import { addTaphuanEpV2UserOrganizationsToCourseName } from 'components/admin/course/common';

const schema = (formid, values, step, xpath, props) => {
  const { orgIids = [] } = props;
  const { courseIid } = values;

  return {
    not_yet_marked: {
      type: 'checkbox',
      label: t1('filter_not_yet_marked_takes'),
    },
    rejected: {
      type: 'checkbox',
      //label: t1('filter_rejected_marked_takes'),
      label: 'Lọc những bài đã bị giảng viên sư phạm chưa góp ý',
    },
    take_updated: {
      type: 'checkbox',
      label: t1('filter_updated_takes'),
    },
    courseIid: {
      type: 'select',
      label: t1('select_course'),
      floatingLabelText: t1('select_course'),
      options: 'async',
      paramsasync: {
        __url__: apiEndPoint.course_search,
        value: {
          page: 1,
          items_per_page: 100,
          learning_type: ['ilt', 'online'],
          status: ['approved', 'queued'],
          public: 0,
          include_sub_organizations: 1,
          requireOrganization: 1,
          organizations: orgIids,
          is_active: 1,
          _sand_expand: ['enrolment_plans'],
        },
        transformData: (courses) => {
          if (!Array.isArray(courses) || !courses.length) {
            return [];
          }

          return courses
            .map((row) => {
              const { is_thamkhao } = row;

              const name = addTaphuanEpV2UserOrganizationsToCourseName(row);

              return (
                !is_thamkhao && {
                  value: row.iid.toString(),
                  label: name,
                  primaryText: name,
                }
              );
            })
            .filter(Boolean);
        },
      },
    },
    selectedQuestionIid: {
      type: 'select',
      label: t1('choose_open_ended_question'),
      floatingLabelText: t1('choose_open_ended_question'),
      options: 'async',
      paramsasync: {
        __url__: `/course/marking/get-open-ended-questions-list-in-course`,
        value: {
          courseIid: courseIid,
        },
        key: `selected-question-by-course-${courseIid}`,
      },
    },
    uiid: {
      type: 'select',
      label: t1('choose_a_student'),
      floatingLabelText: t1('choose_a_student'),
      showSearch: true,
      options: 'async',
      paramsasync: {
        __url__: `/course/marking/get-student-list`,
        value: {
          courseIid: courseIid,
        },
        key: `selected-student-by-course-${courseIid}`,
      },
    },
  };
};

const ui = (step, values, themeConfig, xpath, formid, props) => {
  const {
    isNotYetMarkedShown = true,
    isTakeUpdatedShown = true,
    noSelectCourse,
  } = props;

  let fields = [
    ...(isNotYetMarkedShown ? ['not_yet_marked'] : []),
    ...(isTakeUpdatedShown ? ['take_updated'] : []),
  ];

  if (!values.questionIid) {
    noSelectCourse
      ? (fields = [...fields, 'selectedQuestionIid', 'uiid'])
      : (fields = [...fields, 'courseIid', 'selectedQuestionIid', 'uiid']);
  }

  return [
    {
      id: 'default',
      fields,
    },
  ];
};

export default (course, isGVSP) => ({
  schema,
  ui,
  layout: {
    component: Layout,
    freestyle: 1,
    optionsProperties: {
      course,
      isGVSP,
    },
  },
});
