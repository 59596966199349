import Links from 'routes/links/index';
import courseApiUrls from 'components/admin/course/endpoints';
import sagaActions from 'actions/saga-creators';
import lodashGet from 'lodash.get';

export const getOverviewCourseLink = (item, viewUserIid, rootPathIid) => {
  if (viewUserIid) {
    return Links.adminOverviewCourseOfUser(viewUserIid, item);
  }

  return Links.overviewCourseByPath(rootPathIid, item);
};

export const courseShouldDisplayOverview = (course) => {
  return !!Number(lodashGet(course, 'display_overview'));
};

export const getDefaultCourseLink = (
  course,
  navId = null,
  rootPathIid = null,
) => {
  if (courseShouldDisplayOverview(course)) {
    return getOverviewCourseLink(course, null, rootPathIid);
  }

  return Links.LearnCourseByPath(course, navId, { pathId: rootPathIid });
};

export const downloadCertificate = (courseIid, userIid, dispatch) => {
  const url = courseApiUrls.download_certificate;

  const params = {
    course_iid: courseIid,
    user_iid: userIid,
  };
  dispatch(sagaActions.downloadCertificateRequest(url, params));
};
