import { t1 } from 'translate';

const schema = () => {
  return {
    reject_reason: {
      type: 'text',
      floatingLabelText: t1('reject_reason'),
      multiLine: true,
      placeholder: t1('reject_reason'),
    },
  };
};

const ui = () => {
  return [
    {
      id: 'evaluation_results',
      fields: ['reject_reason'],
    },
  ];
};

export default { schema, ui };
