/**
 * Created by Peter Hoang Nguyen on 3/17/2017.
 */
export const SET_APP_LAYOUTS = 'SET_APP_LAYOUTS';
export const WINDOW_RESIZE = 'WINDOW_RESIZE';
export const LAYOUT_SHOW_HEADER = 'LAYOUT_SHOW_HEADER';
export const LAYOUT_HIDE_HEADER = 'LAYOUT_HIDE_HEADER';

export function setLayout(layoutId, params) {
  const config = { layoutId, params };
  return { type: SET_APP_LAYOUTS, layoutConfigs: config };
}

export function windowResize(screenSize, bodyScreenSize) {
  return { type: WINDOW_RESIZE, screenSize, bodyScreenSize };
}

export function showHeader() {
  return { type: LAYOUT_SHOW_HEADER };
}

export function hideHeader() {
  return { type: LAYOUT_HIDE_HEADER };
}
