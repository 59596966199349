import { t1 } from 'translate';

const codes = (notIn = false, props = {}) => ({
  type: 'text',
  multiLine: true,
  hintText: `user01, user02, ... ${t1('separated_by_comma_or_new_line')}`,
  floatingLabelText: notIn ? t1('user_codes_not_in') : t1('user_codes'),
  fullWidth: true,
  ...props,
});

export default codes;
