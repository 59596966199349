import { isOEQuestion, isSubmissionTool } from 'common/learn/Question';
import { getQuestionAnswersInfo } from 'common/question';
import get from 'lodash.get';

export const shouldShowExerciseQuestionsStatus = (exercise) =>
  !isSubmissionTool(exercise);
export const shouldShowTakeHistory = (exercise) => {
  return !get(exercise, 'no_marking') && !get(exercise, 'practice');
};
export const getQuestionAnswersWrongScore = (questions, nodes) => {
  const questionAsArray = [];

  questions &&
    Object.keys(questions).forEach((questionId) => {
      questionAsArray.push({
        ...get(nodes, questionId), //get info question
        ...questions[questionId], // get info answer question
      });
    });

  let wrongQuestionAnswersScore = [];

  // // trường hợp fomular thay đổi thì point thay đổi
  // questionAsArray.map((question) => {
  //   const { percent = 0 } = get(question, 'answer', {});
  //   const { score_formula } = question;

  //   if (
  //     (percent * 10) % 10 !== 0 &&
  //     (!score_formula || score_formula === scoreFormulaValue.BY_ENTIRE_QUESTION)
  //   ) {
  //     listAnswer.push({
  //       ...question.answer,
  //       percent: 0,
  //       score: 0,
  //       answer: [],
  //       isCorrect: false,
  //     });
  //   }
  // });

  //Tính lại điểm và  tìm ra các câu có kết quả thay đổi
  questionAsArray.map((question) => {
    const getQuestionAnswer = getQuestionAnswersInfo(
      question,
      question.answer.answer,
    );
    const { take } = getQuestionAnswer;
    const score = get(question, 'answer.score') || 0;
    const new_score = get(take, 'score') || 0;
    if (new_score != score && !isOEQuestion(question)) {
      wrongQuestionAnswersScore.push(take);
    }
  });

  return wrongQuestionAnswersScore;
};
