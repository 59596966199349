import React from 'react';
import Search from './search/Layout';
import SubTopMenuContext from 'common/context/menu/SubMenuTop';
import topMenuSchema from './menu/MainstageTopMenu';

const ModuleManager = () => {
  return (
    <>
      <SubTopMenuContext
        schema={topMenuSchema()}
        lastBreadcrumbName="Quản lý mô đun"
      />
      <Search />
    </>
  );
};

export default () => <ModuleManager />;
