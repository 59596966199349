import React from 'react';
import lodashGet from 'lodash.get';
import Tag from 'antd/lib/tag';
import { t1 } from 'translate';
import DeleteItem from 'components/common/action-button/DeleteBtnWithConfirmDialog';
import DetailOnDialog from 'components/common/detail-on-dialog';
import { getSchoolYearOfCurrentUser } from 'components/common/scholastic-config/utils';
import CommonAntdTable from 'components/common/antd/table';
import { CHUONG_TRINH_01 } from 'components/bdtx/configs';
import Icon from 'components/common/Icon';
import { commonStatus } from 'configs/constants';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import { getTargetUser, userGradeToShortText } from 'configs/constants/user';
import ActionToggle from 'components/common/toggle/ActionToggle';
import Warning from 'components/common/Warning';
import { isBo, isSo } from 'components/admin/user/utils';
import { isBGD } from 'components/admin/organization/utils';
import ShowMoreLess from 'components/bdtx/module/search/show-more-less';
import endpoints from 'components/bdtx/module/endpoints';
import ncbdtxEndpoints from 'components/bdtx/choose-module-for-ncbdtx/endpoints';
import HtmlContent from 'components/common/html';
import UpdateNode from 'components/bdtx/module/new/Form';

const Results = ({ items, formid, userInfo, searchFormId }) => {
  const formatDataSource = () => {
    let dataSource = [];

    items.forEach((item) => {
      const academicCategoriesByCap = lodashGet(
        item,
        '__expand.academic_categories_by_cap',
        [],
      );

      if (!academicCategoriesByCap.length) {
        dataSource.push({
          ...item,
          rowSpan: 1,
        });
      }

      dataSource = dataSource.concat(
        academicCategoriesByCap.map((academic, index) => ({
          ...item,
          academic_category_by_cap: academic,
          rowSpan: !index ? academicCategoriesByCap.length : 0,
        })),
      );
    });

    return dataSource;
  };

  const columns = [
    {
      title: 'Mã mô đun',
      key: 'code',
      dataIndex: 'code',
      render: (code, item) => {
        return {
          children: <span>{code}</span>,
          props: {
            rowSpan: item.rowSpan,
          },
        };
      },
    },
    {
      title: 'Tên mô đun',
      key: 'name',
      dataIndex: 'name',
      render: (name, item) => {
        return {
          children: (
            <HtmlContent content={name} showLessMore lessWordCount={10} />
          ),
          props: {
            rowSpan: item.rowSpan,
          },
        };
      },
    },
    // {
    //   title: 'Chương trình',
    //   key: 'chuong_trinh',
    //   dataIndex: 'chuong_trinh',
    //   type: TYPE_OF_TABLE_DATA.TITLE,
    //   render: (chuongTrinh, item) => {
    //     return {
    //       children: <ChuongTrinhTag chuongTrinh={chuongTrinh} shortName />,
    //       props: {
    //         rowSpan: item.rowSpan,
    //       },
    //     };
    //   },
    //   width: 80,
    // },
    {
      title: 'Năm học',
      key: 'school_year',
      width: 110,
      type: TYPE_OF_TABLE_DATA.TITLE,
      render: (item) => {
        return {
          children: (
            <>
              {Array.isArray(item.school_years) &&
                item.school_years.map((year, i) => (
                  <Tag className={'m-5'} key={`target-${i}`}>
                    {year}
                  </Tag>
                ))}
            </>
          ),
          props: {
            rowSpan: item.rowSpan,
          },
        };
      },
    },
    {
      title: 'Đối tượng',
      key: 'target_user',
      width: 80,
      render: (item) => {
        return {
          children: (
            <>
              {Array.isArray(item.target_users) &&
                item.target_users.map((target, i) => (
                  <Tag className={'m-5'} key={`target-${i}`}>
                    {getTargetUser(target)}
                  </Tag>
                ))}
            </>
          ),
          props: {
            rowSpan: item.rowSpan,
          },
        };
      },
    },
    {
      title: 'Cấp',
      key: 'caps',
      width: 100,
      render: (item) => {
        const academicCategoryByCap = lodashGet(
          item,
          'academic_category_by_cap',
          [],
        );

        if (!academicCategoryByCap.cap) {
          return null;
        }

        return {
          children: (
            <Tag className={'m-5'}>
              {userGradeToShortText(academicCategoryByCap.cap)}
            </Tag>
          ),
          props: {
            rowSpan: 1,
          },
        };
      },
    },
    {
      title: 'Môn học',
      key: 'academic_categories_by_cap',
      width: 200,
      render: (item) => {
        const academicCategoryByCap = lodashGet(
          item,
          'academic_category_by_cap',
          [],
        );

        return {
          children: (
            <ShowMoreLess items={academicCategoryByCap.academic_categories} />
          ),
          props: {
            rowSpan: 1,
          },
        };
      },
    },
    {
      title: t1('organizations'),
      key: 'organizations',
      render: (item) => {
        const organizations = lodashGet(item, '__expand.organizations');
        return {
          children: (
            <div>
              {organizations
                ? organizations.map((org) => (
                    <div key={`org-${org.iid}`}>{org.name}</div>
                  ))
                : null}
            </div>
          ),
          props: {
            rowSpan: item.rowSpan,
          },
        };
      },
    },
    {
      title: 'Số tiết',
      key: 'credit',
      dataIndex: 'credit',
      type: TYPE_OF_TABLE_DATA.TITLE,
      render: (credit, item) => {
        return {
          children: credit,
          props: {
            rowSpan: item.rowSpan,
          },
        };
      },
      width: 60,
    },
    {
      title: t1('module_status'),
      type: TYPE_OF_TABLE_DATA.TITLE,
      width: 80,
      render: (record) => {
        const status = lodashGet(record, 'status');
        const canDelete = lodashGet(record, 'can_delete');
        const deployed = lodashGet(record, 'deployed');
        const organizationIid = lodashGet(record, 'organizations.0');
        const isModuleOfBgd = isBGD(organizationIid);
        const loggedUserIsBgd = isBo(userInfo);

        let children;

        if (deployed) {
          children = (
            <span className="text-primary">{t1('module_deployed')}</span>
          );
        } else if (
          status === commonStatus.DELETED ||
          !canDelete ||
          (isModuleOfBgd && !loggedUserIsBgd)
        ) {
          children = <Warning inline>{t1(status)}</Warning>;
        } else {
          children = (
            <ActionToggle
              hideLabel
              node={record}
              searchFormId={formid}
              params={{ _sand_step: 'status' }}
              baseURL={endpoints.update_module}
              dataSet={{ on: commonStatus.APPROVED, off: commonStatus.QUEUED }}
              value={status || commonStatus.QUEUED}
              name="status"
            />
          );
        }

        return {
          children: <div className="text-center">{children}</div>,
          props: {
            rowSpan: record.rowSpan,
          },
        };
      },
    },
    (isBo(userInfo) || isSo(userInfo)) && {
      title: t1('enable_for_ncbdtx'),
      type: TYPE_OF_TABLE_DATA.TITLE,
      width: 80,
      render: (record) => {
        const chuongTrinh = lodashGet(record, 'chuong_trinh');
        const status = lodashGet(record, 'status');
        const enableNCBDTX = lodashGet(record, 'enable_for_ncbdtx');
        const selfChosen = lodashGet(record, 'self_chosen_ncbdtx');
        const organizationIid = lodashGet(record, 'organizations.0');

        let apiUrl = ncbdtxEndpoints.bdtx_save_ncbdtx;
        if (enableNCBDTX) {
          apiUrl = ncbdtxEndpoints.bdtx_remove_ncbdtx;
        }

        let children;
        if ([commonStatus.QUEUED || commonStatus.DELETED].includes(status)) {
          children = <Warning inline>{t1(`module_${status}`)}</Warning>;
        } else {
          children = (
            <>
              <ActionToggle
                hideLabel
                params={{
                  module_iid: lodashGet(record, 'iid'),
                  chuong_trinh: chuongTrinh,
                  school_year: getSchoolYearOfCurrentUser(),
                  _sand_step: 'enable_for_ncbdtx',
                }}
                searchFormId={searchFormId}
                baseURL={apiUrl}
                dataSet={{ on: 1, off: 0 }}
                value={enableNCBDTX || 0}
                name="enable_for_ncbdtx"
                disabled={enableNCBDTX && !selfChosen}
              />

              {enableNCBDTX && !selfChosen ? (
                <span className="m-t-5 font-size-small">
                  {t1('parent_has_been_chosen_ncbdtx')}
                </span>
              ) : null}
            </>
          );
        }

        return {
          children: <div className="text-center">{children}</div>,
          props: {
            rowSpan: record.rowSpan,
          },
        };
      },
    },
    {
      title: t1('action'),
      key: 'action',
      width: 80,
      type: TYPE_OF_TABLE_DATA.TITLE,
      render: (item) => {
        const canDelete = lodashGet(item, 'can_delete');

        return {
          children: (
            <div className="d-flex align-items-center justify-content-center">
              <DetailOnDialog
                renderPreview={({ showFull }) => (
                  <span
                    title={t1('edit_module_%s', [item.name])}
                    onClick={showFull}
                    className="m-r-10 p-0 cursor-pointer d-flex"
                  >
                    <Icon icon={`${canDelete ? 'edit' : 'preview'}`} />
                  </span>
                )}
                renderFull={() => (
                  <UpdateNode
                    mode="edit"
                    title={t1('edit_module')}
                    node={item}
                    step=""
                    searchFormId={formid}
                    formid="edit_module"
                    readOnly={!canDelete}
                  />
                )}
                dialogOptionsProperties={{
                  modal: true,
                  handleClose: true,
                  title: t1('edit_module'),
                }}
              />

              {canDelete && (
                <DeleteItem
                  title={t1('delete')}
                  textConfirm={t1('are_you_sure_you_want_to_do_this')}
                  formid={formid}
                  ntype={'module'}
                  itemId={item.id}
                  iconButton
                  alternativeApi={endpoints.delete_module}
                />
              )}
            </div>
          ),
          props: {
            rowSpan: item.rowSpan,
          },
        };
      },
    },
  ].filter(Boolean);

  return (
    <CommonAntdTable
      dataSource={formatDataSource()}
      columns={columns}
      pagination={false}
      childrenColumnName={null}
      className="learn-default"
      scroll={{ x: true }}
    />
  );
};

export default Results;
