import React from 'react';
import { t1 } from 'translate';
import { questionTypesOptions } from 'components/admin/question/schema/question-types';

export const guideTypes = {
  plain_html: '1',
  pdf_link: '2',
  youtube_link: '3',
};

const guideTypeOptions = [
  {
    value: '1',
    primaryText: t1('guide_type_plain_html'),
  },
  {
    value: '2',
    primaryText: t1('guide_type_pdf_link'),
  },
  {
    value: '3',
    primaryText: t1('guide_type_youtube_link'),
  },
];

const guideTypeSelect = {
  type: 'select',
  floatingLabelText: t1('guide_type_options'),
  options: guideTypeOptions,
  fullWidth: true,
  allowClear: false,
};

const questionTypeOptionsList = () => {
  let output = [];
  questionTypesOptions.map((item) => {
    output.push({
      value: item.value.toString(),
      primaryText: item.label,
    });
  });
  return output;
};

const questionTypeSelect = {
  type: 'select',
  floatingLabelText: t1('question_type_options'),
  options: questionTypeOptionsList(),
  fullWidth: true,
  allowClear: false,
};

const schema = (formid) => {
  return {
    question_type: questionTypeSelect,
    guide_type: guideTypeSelect,
    guide_content: {
      type: 'text',
      hintText: t1('question_guide_content_goes_here'),
      floatingLabelText: t1('question_guide_content'),
      defaultValue: '',
      errorText: '',
      rows: 6,
      fullWidth: true,
      multiLine: true,
    },
  };
};

const ui = (step, values, themeConfig, xpath) => {
  return [
    {
      id: 'default',
      title: t1('question_guideline_settings'),
      fields: ['question_type', 'guide_type', 'guide_content'],
    },
  ];
};

export default {
  schema,
  ui,
};
