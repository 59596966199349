import { DefinedUrlParams, getCatalogueUrl } from 'routes/links/common';
import Loadable from 'react-loadable';
import Loading from 'components/common/loading';

const PreviewPaper = Loadable({
  loader: () => import('components/contest/preview-paper'),
  loading: Loading,
});

// TODO: fix this
const ExamTesting = Loadable({
  loader: () => import('components/contest/pre-exam'),
  loading: Loading,
});
const ExamResult = Loadable({
  loader: () => import('components/contest/exam-result'),
  loading: Loading,
});
const PreviewTake = Loadable({
  loader: () => import('components/contest/preview-take'),
  loading: Loading,
});

const ExamPracticeTesting = Loadable({
  loader: () => import('components/contest/practice'),
  loading: Loading,
});

export default () => [
  {
    componentId: 'ExamInfo',
    path: '/exam/info',
    component: ExamTesting,
    exact: true,
  },
  {
    componentId: 'ExamInfoDetail',
    path: `/exam/take/:${DefinedUrlParams.CONTEST_CODE}/:${
      DefinedUrlParams.CONTESTANT_IID
    }?`,
    component: ExamTesting,
    exact: true,
  },
  {
    componentId: 'PublicExamInfoDetail',
    path: `/exam/take/:${DefinedUrlParams.CONTEST_CODE}/public/:${
      DefinedUrlParams.EXAM_SUBJECT_IID
    }`,
    component: ExamTesting,
    exact: true,
  },
  {
    componentId: 'ExamPracticeInfoDetailWithAction',
    path: `/exam/practice/:${DefinedUrlParams.CONTEST_CODE}/:${
      DefinedUrlParams.CONTESTANT_IID
    }/:${'mode'}`,
    component: ExamPracticeTesting,
    exact: true,
  },
  {
    componentId: 'ExamPracticeInfoDetail',
    path: `/exam/practice/:${DefinedUrlParams.CONTEST_CODE}/:${
      DefinedUrlParams.CONTESTANT_IID
    }?`,
    component: ExamPracticeTesting,
    exact: true,
  },
  {
    componentId: 'ExamTemplatePracticeInfoDetailWithAction',
    path: `/bank-review/:${DefinedUrlParams.SYLLABUS_IID}/:${
      DefinedUrlParams.EXERCISE_IID
    }/:${'mode'}`,
    component: ExamPracticeTesting,
    exact: true,
  },
  {
    componentId: 'ExamTemplatePracticeInfoDetail',
    path: `/bank-review/:${DefinedUrlParams.SYLLABUS_IID}/:${
      DefinedUrlParams.EXERCISE_IID
    }`,
    component: ExamPracticeTesting,
    exact: true,
  },
  {
    componentId: 'ExamTemplatePracticeInfoDetailWithAction',
    path: `/template-review/:${DefinedUrlParams.TEMPLATE_IID}/:${'mode'}`,
    component: ExamPracticeTesting,
    exact: true,
  },
  {
    componentId: 'ExamTemplatePracticeInfoDetail',
    path: `/template-review/:${DefinedUrlParams.TEMPLATE_IID}`,
    component: ExamPracticeTesting,
    exact: true,
  },
  {
    componentId: 'ExamResult',
    path: `/exam/result/:${DefinedUrlParams.CONTEST_CODE}/:${
      DefinedUrlParams.COURSE_IID
    }/:${DefinedUrlParams.EXAM_IID}/:${DefinedUrlParams.EXAM_ORDER}/:${
      DefinedUrlParams.USER_IID
    }?`,
    component: ExamResult,
    exact: true,
  },
  {
    componentId: 'PreviewPaper',
    path: `/exam/preview-paper/:${DefinedUrlParams.SYLLABUS_IID}(\\d+)/:${
      DefinedUrlParams.PAPER_ID
    }`,
    component: PreviewPaper,
    exact: true,
  },
  {
    componentId: 'PreviewTake',
    path: `/exam/preview-take/:${DefinedUrlParams.TAKE_ID}`,
    component: PreviewTake,
    exact: true,
  },
];
