export const adminRootUrl = '/admin';
export const systemRootUrl = '/system';
export const learnRootUrl = '/learn';
export const mobileRootUrl = '/mobile';
export const dashboardUrl = '/dashboard';
export const profileUrl = '/profile';
export const scheduleRoomUrl = '/schedule-room';
export const catalogue = 'catalogue';
export const teacherUrl = '/teacher';
export const teacherCourseUrl = '/teacher/course';
export const masterCourseUrl = '/gvspcc/course';
export const missionUrl = '/mission';
export const guideUrl = '/guide';
export const masterUrl = '/gvspcc';
export const cbqlUrl = '/cbql';
export const supportPlanApprovalUrl = '/support-plan-approval';
export const authUrl = '/auth';
export const lha = '/lha';
export const meet = '/meet/group';
