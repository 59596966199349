import React from 'react';
import { t1 } from 'translate';
import './stylesheet.scss';

const SimpleNoResult = ({ text, icon, imagePath, type = 'info' }) => {
  const message = text || t1("we_couldn't_find_any_result");
  const useImage = !icon && imagePath;
  const noResultIcon = icon || 've-done';

  return (
    <div className={`simple-no-result ${type}`}>
      {useImage ? (
        <img src={imagePath} alt="" className="simple-no-result__icon-image" />
      ) : (
        <span className={`simple-no-result__icon ${noResultIcon}`} />
      )}

      <span className="simple-no-result__text">{message}</span>
    </div>
  );
};

export default SimpleNoResult;
