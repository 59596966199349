import React from 'react';
import Tooltip from 'antd/lib/tooltip';

const OrganizationNotInDbInfo = () => {
  return <Tooltip
    title="Đơn vị chưa có trên cơ sở dữ liệu ngành"
  >
    <span>{' '}?</span>
  </Tooltip>
}

export default OrganizationNotInDbInfo;