import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SubTopMenuContext from 'common/context/menu/SubMenuTop';
import TopRightMenu from './menu/TopRightMenu';
import { menuItems, menuItemsMeeting } from './sub-left-menu-configs';
import groupRoutes from './route-registration';
import { renderRoutes } from 'react-router-config';
import NodeEditMenu from 'layouts/layout/menu-v3/NodeEditMenu';
import Loading from 'components/common/loading';
import actions from 'actions/node/creators';
import { getNodeMapStateToProps } from 'components/admin/node/utils';
import endpoints from '../endpoints';
import withConf from 'common/hoc/withConf';
import { isMeeting } from 'components/admin/session/utils';
import PageWrapper from 'common/page-wrapper';
import BackButton from 'components/common/back-button';

/**
 *
 * @param iid
 * @param node gotten from state via getNodeMapStateToProps
 * @param action
 * @param subAction
 * @param mode admin|meet
 * @param dispatch
 * @param type
 * @param timeframes
 * @param title
 * @returns {*}
 * @constructor
 */
const GroupEditContainer = ({
  iid,
  node,
  action,
  subAction,
  mode,
  dispatch,
  type,
  timeframes,
  title,
  urlToBack,
}) => {
  const [theRoutes, setTheRoutes] = useState([]);

  useEffect(
    () => {
      setTheRoutes(groupRoutes(mode, node, action, subAction, type));
    },
    [mode, node, action, subAction, type],
  );

  if (!node) {
    dispatch(
      actions.fetchNode({ iid, ntype: 'group' }, endpoints.get_group_info),
    );
    return <Loading />;
  }

  const menuSchema = isMeeting(type)
    ? menuItemsMeeting(node, mode, timeframes)
    : menuItems(node);
  return (
    <div className={'container'}>
      <PageWrapper
        showIndicator={false}
        title={
          <div className={'d-flex align-items-center'}>
            <BackButton urlToBack={urlToBack} className={'m-r-10'} />
            {title}
          </div>
        }
      >
        <SubTopMenuContext
          node={Object.assign({}, node, {
            action,
            subAction,
          })}
          lastBreadcrumbName={node.name}
          action={action}
          button={
            <TopRightMenu action={action} group={node} subAction={subAction} />
          }
          isSmallSize
        />

        <NodeEditMenu node={node} schema={menuSchema} mode={mode} />

        {renderRoutes(theRoutes)}
      </PageWrapper>
    </div>
  );
};

export default withConf('k12_timeframe_of_timekeeping', 'timeframes', {
  params: {
    key: 'k12_timeframe_of_timekeeping',
    load_default: 0,
  },
})(connect(getNodeMapStateToProps)(GroupEditContainer));
