import React from 'react';
import { t1 } from 'translate';
import DetailOnDialog from 'components/common/detail-on-dialog';
import GenerateButton from '../GenerateButton';
import DownloadFileFromCache from './downloadFileFromCache';

const ExportBtnWithDataFromCache = ({
  url,
  params,
  fileName,
  dialogKey,
  onDownloadFile,
  onClick,
  executeRealtime = false,
  ...props
}) => {
  return (
    <DetailOnDialog
      confirmModal
      renderPreview={({ showFull }) => {
        return (
          <GenerateButton
            {...props}
            title={props.title || t1('export')}
            icon={props.icon || 'export'}
            onClick={() => {
              showFull();

              if (typeof onClick === 'function') {
                onClick();
              }
            }}
          />
        );
      }}
      renderFull={({ closeDialog }) => {
        return (
          <DownloadFileFromCache
            closeDialog={closeDialog}
            onDownloadFile={onDownloadFile}
            url={url}
            params={params}
            fileName={fileName}
            executeRealtime={executeRealtime}
          />
        );
      }}
      dialogKey={dialogKey || 'export_btn_with_data_from_cache'}
      dialogOptionsProperties={{
        handleClose: false,
        width: 'auto',
        style: { maxWidth: 450 },
      }}
    />
  );
};

export default ExportBtnWithDataFromCache;
