import './style.scss';

import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import NodeNew from 'components/admin/node/new';
import apiUrls from 'api-endpoints';
import schema from './schema';
import { t1 } from 'translate';

const COMMENT_TAKE_FORM_ID = 'comment-take-form';

const CommentTake = (props) => {
  const { reloadData = () => {}, masterComment = '' } = props;
  return (
    <DetailOnDialog
      renderPreview={({ showFull }) => (
        <div className="comment-take-popup text-center m-t-30">
          <div className="text-center">{t1('master_teacher_comment_note')}</div>
          <button className="btn btn-primary" onClick={showFull}>
            {t1('comment_take')}
          </button>
        </div>
      )}
      renderFull={({ closeDialog }) => (
        <NodeNew
          schema={schema}
          closeModal
          alternativeApi={apiUrls.master_take_marking_approval}
          formid={COMMENT_TAKE_FORM_ID}
          requestSuccessful={() => {
            if (typeof reloadData === 'function') {
              reloadData();
            }
            closeDialog();
          }}
          node={{
            comment: masterComment,
          }}
          {...props}
        />
      )}
      dialogKey={COMMENT_TAKE_FORM_ID}
      dialogOptionsProperties={{
        title: t1('comment_take'),
        handleClose: true,
      }}
    />
  );
};

export default CommentTake;
