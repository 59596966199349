import React from 'react';
import get from 'lodash.get';
import { connect } from 'react-redux';
import Store from 'store';
import { createSelector } from 'reselect';
import { Modal, Checkbox } from 'antd';

import { isSmallScreen } from 'common';
import { getSupportTime } from 'utils/Util';

import {
  hideSupportUserInfoNotification,
  resetShowSupportUserInfoNotificationOpenState,
  showSupportUserInfoNotification,
} from 'actions/client-settings';

const SUPPORT_TIME = getSupportTime() || {};

export const showSupportUserInfoNotificationHandler = (serverTimestamp) => {
  if (!serverTimestamp) {
    return;
  }

  const serverTime = new Date(serverTimestamp * 1000);
  const { clientSettings } = Store.getState();
  const supportUserInfoNotification = get(
    clientSettings,
    'supportUserInfoNotification',
    {},
  );

  const { opening, turnOff, checked } = supportUserInfoNotification;
  const { nextTimeToChecking } = supportUserInfoNotification;

  const currentHours = serverTime.getHours();
  const isTheTimeThatUserCanBeSupport =
    currentHours >= SUPPORT_TIME.from && currentHours < SUPPORT_TIME.to;

  if (opening || turnOff || isTheTimeThatUserCanBeSupport) {
    return;
  }

  if (!checked) {
    const now = new Date();

    if (nextTimeToChecking && now < new Date(nextTimeToChecking)) {
      return;
    }

    Store.dispatch(showSupportUserInfoNotification());
  }
};

const SupportUserInfoNotification = React.memo((props) => {
  const { opening, dispatch } = props;
  const [agree, setUserAgree] = React.useState(false);
  const [visible, toggleVisible] = React.useState(false);
  const [modal, setModalHandler] = React.useState(null);
  const [options, setOptions] = React.useState({});

  React.useEffect(
    () => {
      dispatch &&
        window.addEventListener('beforeunload', () => {
          dispatch(resetShowSupportUserInfoNotificationOpenState());
        });
    },
    [dispatch],
  );

  const onOk = React.useCallback(
    () => {
      const turnOff = agree; // ok + agree = turnOff >> do not show notification again.
      const skipped = !agree; // ok + !agree = skipped >> should show notification on next day.
      dispatch(hideSupportUserInfoNotification(turnOff, skipped));
      toggleVisible(false);
    },
    [dispatch, agree],
  );

  const onCancel = React.useCallback(
    () => {
      // should show notification on refresh.
      dispatch(hideSupportUserInfoNotification());
      toggleVisible(false);
    },
    [dispatch],
  );

  React.useEffect(
    () => {
      const highlightClass = 'text-danger';
      const time = (
        <span className={highlightClass}>
          {SUPPORT_TIME.from}:00 đến {SUPPORT_TIME.to}:00
        </span>
      );
      const email = (
        <a className="link" href="mailto:taphuan@viettel.com.vn">
          taphuan@viettel.com.vn
        </a>
      );
      const content = (
        <div>
          <p style={{ textAlign: 'justify' }}>
            Tổng đài hỗ trợ khách hàng của Viettel hoạt động từ {time} hàng
            ngày. Mọi nhu cầu hỗ trợ sau {SUPPORT_TIME.to}h hàng ngày, quý thầy
            cô vui lòng gửi email tới địa chỉ {email} hoặc liên hệ Tổng đài vào
            hôm sau để được ghi nhận và hỗ trợ.
          </p>
          <Checkbox
            onChange={(event) => {
              setUserAgree(event.target.checked);
            }}
          >
            Tôi đã hiểu, đừng nhắc lại tôi nữa
          </Checkbox>
        </div>
      );

      setOptions({
        ...(isSmallScreen ? {} : { width: 500 }),
        icon: 'info-circle',
        title: 'Hỗ trợ khách hàng',
        okButtonProps: { className: 'btn-secondary p-t-7 p-b-7' },
        okText: 'Tôi đã hiểu',
        cancelText: 'Huỷ',
        content,
        onOk,
        onCancel,
      });
    },
    [onOk, onCancel],
  );

  React.useEffect(
    () => {
      if (opening && !visible) {
        const modal = Modal.confirm(options);
        setModalHandler(modal);
        toggleVisible(true);
      }
      modal && modal.update(options);
    },
    [opening, visible, options, modal],
  );

  return <></>;
});

const mapStateToProps = createSelector(
  (state) => get(state.clientSettings, 'supportUserInfoNotification'),
  (supportUserInfoNotification) => {
    const opening = get(supportUserInfoNotification, 'opening', false);
    return { opening };
  },
);

const Component = connect(mapStateToProps)(SupportUserInfoNotification);
Component.displayName = 'SupportUserInfoNotification';

export default Component;
