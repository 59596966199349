import React from 'react';
import { withRouter } from 'react-router';
import Icon from 'antd/lib/icon';
import './style.scss';

const BackButton = ({
  className,
  urlToBack,
  history = [],
  size = 'small',
  label,
}) => {
  const handleClickBack = () => {
    if (urlToBack) {
      history.push(urlToBack);
      return;
    }
    history.goBack();
  };

  return (
    <span
      className={`${className} back-button ${size} d-flex align-items-center`}
    >
      <Icon onClick={handleClickBack} type="arrow-left" />
      {!!label && <span className="m-l-5">{label}</span>}
    </span>
  );
};

export default withRouter(BackButton);
