import React from 'react';
import ScoStandard from 'components/learn/items/sco-standard';
import { pageLayouts } from 'components/admin/sco/schema/page-layout';
import ScoLayoutImageMap from './ScoLayoutImageMap';
import ScoLayoutList from './ScoLayoutList';
import CustomScrollbars from 'components/common/custom-scrollbars';
import get from 'lodash.get';
import Store from 'store';
import sagaActions from 'actions/saga-creators';

class ScoItSelf extends React.Component {
  componentDidMount() {
    const { learnItem, courseIid } = this.props;
    const children = get(learnItem, 'children', []);
    const itemIid = get(learnItem, 'iid');

    if (children.length) {
      return;
    }

    Store.dispatch(
      sagaActions.trackerProgressSave({
        progress: [{ tco_iid: itemIid, p: 100, cp: 100 }],
        ciid: courseIid,
      }),
    );
  }

  render() {
    const {
      learnItem,
      navId,
      courseIid,
      nodes,
      course,
      syllabus,
      isPreview,
      navRootNodeIid,
      learnMode,
    } = this.props;

    let content;
    if (learnItem.page_layout === pageLayouts.SCO_LAYOUT_IMAGE_MAP) {
      content = (
        <CustomScrollbars noScrollOnMobile>
          <ScoLayoutImageMap
            navId={navId}
            learnItem={learnItem}
            courseIid={courseIid}
            course={course}
            syllabus={syllabus}
            isPreview={isPreview}
            navRootNodeIid={navRootNodeIid}
            learnMode={learnMode}
            nodes={nodes}
          />
        </CustomScrollbars>
      );
    } else if (learnItem.page_layout === pageLayouts.SCO_LAYOUT_LIST) {
      content = (
        <CustomScrollbars noScrollOnMobile>
          <ScoLayoutList
            nodes={nodes}
            course={course}
            learnItem={learnItem}
            listItems={learnItem.children}
            syllabus={syllabus}
            navRootNodeIid={navRootNodeIid}
          />
        </CustomScrollbars>
      );
    } else {
      content = (
        <ScoStandard
          key={`${navId}-${learnItem.iid}-sco-exam`}
          id={`${navId}-${learnItem.iid}-sco-exam`}
          courseIid={courseIid}
        />
      );
    }

    return content;
  }
}

export default ScoItSelf;
