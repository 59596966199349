import React from 'react';
import { connect } from 'react-redux';
import screenfull from 'screenfull';
import { findDOMNode } from 'react-dom';
import { t1 } from 'translate';
import Icon from 'components/common/Icon';
import Pagination from './pagination';
import './stylesheet.scss';
import { getAttachment } from 'components/learn/items/lecture/common';
import PDF from 'components/common/pdf';
import lodashGet from 'lodash.get';
import styled from 'styled-components';
import { isSmallScreen } from 'common';
import ZoomIn from './zoomIn';
import ZoomOut from './zoomOut';
import { getLearningProgressBasedOnSpentTime } from 'components/learn/utils';
import { setExtraInfoForCurrentItem } from 'actions/learn';
import { learnItemTypes } from '../../../utils';
import { replaceStaticToStatic2 } from 'common/utils/url';

const PDFContainer = styled.div`
  min-height: calc(100% - 35px) !important;
`;

const getDefaultRotateDegFromNode = (node) =>
  lodashGet(node, 'pdf_default_rotate_deg');

const maxNumberOfRoundingTheProgress = 2;
const minNumberOfRoundingTheProgress = 1;

class Pdf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rotate: 0,
      fullScreenPdfOnly: false,
      scale: this.props.scale,
      defaultScale: this.props.scale,
      startTime: null,
      endTime: null,
    };
  }

  componentDidMount() {
    this.setDefaultRotate();
    this.handlePDFStart();
    this.setExtraInfo(1);
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, pages } = this.state;
    const { isNotLearn } = this.props;
    if (page && page !== prevState.page && pages && !isNotLearn) {
      this.saveLearningProgress();
      this.setExtraInfo(page);
    }
    this.setDefaultRotate(prevProps);
  }

  componentWillUnmount() {
    this.handlePDFEnded();
  }

  setExtraInfo = (page) => {
    const { dispatch } = this.props;
    dispatch(setExtraInfoForCurrentItem(learnItemTypes.pdf, page));
  };

  setDefaultRotate = (prevProps = {}) => {
    const { node } = this.props;
    const { node: prevNode } = prevProps;
    const defaultRotateDeg = getDefaultRotateDegFromNode(node);
    const prevDefaultRotateDeg = getDefaultRotateDegFromNode(prevNode);

    if (defaultRotateDeg && defaultRotateDeg !== prevDefaultRotateDeg) {
      this.setState({
        rotate: defaultRotateDeg,
      });
    }
  };

  getSpentTimeCalculatePointState = (spentTimeCalculatePoint) => ({
    spentTimeCalculatePoint,
  });

  handlePDFStart = () => {
    this.setState(
      Object.assign({}, this.getSpentTimeCalculatePointState(new Date())),
    );
  };

  roundingTheProgress = () => {
    const { page, pages } = this.state;

    const diff = pages - page;
    if (
      diff >= minNumberOfRoundingTheProgress &&
      diff <= maxNumberOfRoundingTheProgress
    ) {
      return 1;
    }

    return page / pages;
  };

  getLearningProgress = () => {
    const progress = this.roundingTheProgress();

    const oldLearningProgress = this.props.learningProgress;
    const newProgress = Math.ceil(progress * 100);

    const learningProgressBasedOnSpentTime = getLearningProgressBasedOnSpentTime(
      {
        startTime: this.state.startTime,
        endTime: this.state.endTime,
        spentTime: this.props.spentTime,
        spentTimeCalculatePoint: this.state.spentTimeCalculatePoint,
      },
    );

    return {
      learningProgress: Math.max(oldLearningProgress, newProgress),
      spentTime: learningProgressBasedOnSpentTime.spentTime,
    };
  };

  isFinish = () => {
    const { learningProgress } = this.props;

    return learningProgress == 100;
  };

  saveLearningProgress = () => {
    const newLearningProgress = this.getLearningProgress();

    const {
      learnItemIid,
      isPreview,
      isReview,
      saveLearningProgress,
    } = this.props;
    if (isPreview || isReview || this.isFinish()) {
      return;
    }

    if (typeof saveLearningProgress === 'function') {
      saveLearningProgress([
        {
          tco_iid: learnItemIid,
          p: newLearningProgress.learningProgress,
          time_spent: newLearningProgress.spentTime,
        },
      ]);
    }

    this.setState(
      Object.assign({}, this.getSpentTimeCalculatePointState(new Date())),
    );
  };

  handlePDFEnded = () => {
    this.saveLearningProgress();
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ page: 1, pages: numPages });
  };

  handlePreviousPageButtonClick = (page) => {
    if (page - 1 < 1) return;
    if (this.pdfViewer) {
      this.pdfViewer.goToPage(page - 1);
    }
  };

  handleNextPageButtonClick = (page) => {
    const { pages } = this.state;
    if (page + 1 > pages) return;
    if (this.pdfViewer) {
      this.pdfViewer.goToPage(page + 1);
    }
  };

  handlePaginationChange = (page) => {
    const { pages } = this.state;
    if (page > pages || page < 1) return;
    if (this.pdfViewer) {
      this.pdfViewer.goToPage(page);
      this.setState({ page });
    }
  };

  handleGoToPage = (page) => {
    this.setState({ page });
  };

  fullscreen = () => {
    if (screenfull && screenfull.enabled) {
      screenfull.request(findDOMNode(this));
      screenfull.on('change', () => {
        this.setState({
          fullScreenPdfOnly:
            !this.state.fullScreenPdfOnly && screenfull.isFullscreen,
        });
      });
    }
  };

  exitFullscreen = () => {
    if (screenfull && screenfull.enabled) {
      screenfull.exit();
      screenfull.on('change', () => {
        this.setState({
          fullScreenPdfOnly:
            !this.state.fullScreenPdfOnly && screenfull.isFullscreen,
        });
      });
    }
  };

  rotate = (degree) => {
    this.setState((state) => ({
      ...state,
      rotate: (state.rotate || 0) + degree,
    }));
  };

  rotateLeft = () => this.rotate(-90);
  rotateRight = () => this.rotate(90);

  handleZoomIn = () => {
    const { maxScale, scaleStep } = this.props;
    const { defaultScale, scale } = this.state;
    const checkScale = Math.max(maxScale, defaultScale);

    if (scale < checkScale) {
      this.setState({
        scale: scale + scaleStep,
      });
    }
  };

  handleZoomOut = () => {
    const { minScale, scaleStep } = this.props;
    const { defaultScale, scale } = this.state;
    const checkScale = Math.min(minScale, defaultScale);

    if (scale > checkScale) {
      this.setState({
        scale: scale - scaleStep,
      });
    }
  };

  render() {
    const {
      node,
      className,
      link,
      isNotLearn,
      notShowFullscreenButton,
      maxScale,
      minScale,
    } = this.props;
    const attachment = getAttachment(node);

    let attachmentLink = '';
    if (attachment && attachment.link) {
      attachmentLink = attachment.link;
    }
    if (isNotLearn && link) {
      attachmentLink = link;
    }

    const {
      fullScreenPdfOnly,
      page,
      pages,
      rotate,
      scale,
      defaultScale,
    } = this.state;

    if (!attachmentLink) {
      return (
        <div className="learn-lecture-wrapper__no-content text-danger">
          {t1('learn_pdf_item_no_content')}
        </div>
      );
    }

    return (
      <PDFContainer
        className={`lecture-pdf\
          ${fullScreenPdfOnly ? 'lecture-pdf--fullscreen' : ''}\
          ${className || ''}\
          learn-content-border`}
      >
        {notShowFullscreenButton ? null : (
          <button
            type="button"
            className="lecture-pdf__fullscreen-button"
            onClick={fullScreenPdfOnly ? this.exitFullscreen : this.fullscreen}
          >
            {fullScreenPdfOnly ? (
              <Icon icon="fullscreen-exit" title={t1('exit_fullscreen')} />
            ) : (
              <Icon icon="fullscreen" title={t1('view_fullscreen')} />
            )}
          </button>
        )}
        {attachmentLink ? (
          <div
            style={{
              height: `${isSmallScreen ? 'calc(100% - 45px)' : '100%'}`,
            }}
            className="lecture-pdf__content"
          >
            <PDF
              file={replaceStaticToStatic2(attachmentLink)}
              onDocumentLoadSuccess={this.onDocumentLoadSuccess}
              onGoToPage={this.handleGoToPage}
              pdfViewerRef={(pdfViewer) => {
                this.pdfViewer = pdfViewer;
              }}
              rotate={rotate}
              scale={scale}
            />
          </div>
        ) : null}

        <div
          className={`lecture-pdf__navigation ${
            fullScreenPdfOnly ? 'lecture-pdf__navigation--fullscreen' : ''
          }`}
        >
          {pages && (
            <>
              <Pagination
                onPageChange={this.handlePaginationChange}
                page={page}
                pages={pages}
              />

              <div className="lecture-pdf__rotation-zoom">
                <div className="lecture-pdf__zoom">
                  <ZoomOut
                    scale={scale}
                    minScale={minScale}
                    defaultScale={defaultScale}
                    handleZoomOut={this.handleZoomOut}
                  />

                  <ZoomIn
                    scale={scale}
                    maxScale={maxScale}
                    defaultScale={defaultScale}
                    handleZoomIn={this.handleZoomIn}
                  />
                </div>
                <div className="lecture-pdf__rotation">
                  <span
                    className="ui-cursor-pointer m-l-5 m-r-15 ve-rotate-left"
                    title={t1('rotate_lecture_left')}
                    onClick={this.rotateLeft}
                  />
                  <span
                    className="ui-cursor-pointer ve-rotate-right m-r-15"
                    title={t1('rotate_lecture_right')}
                    onClick={this.rotateRight}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </PDFContainer>
    );
  }
}

Pdf.defaultProps = {
  defaultScale: 1,
  scale: 1,
  scaleStep: 0.2,
  maxScale: 1.6,
  minScale: 0.4,
};

const mapStateToProps = (state, props) => {
  const { learnItemIid } = props;
  const trackerProgress = state.trackerProgress[learnItemIid];
  const learningProgress = (trackerProgress && trackerProgress.p) || 0;

  return {
    learningProgress,
    isPreview: state.learn.isPreview,
    isReview: state.learn.isReview,
  };
};

export default connect(mapStateToProps)(Pdf);
