import React from 'react';
import PropTypes from 'prop-types';

const ZoomOut = ({
  zoomOutClass,
  scale,
  defaultScale,
  minScale,
  handleZoomOut,
}) => {
  const checkScale = Math.min(minScale, defaultScale);
  const isDisabled = scale.toFixed(2) === checkScale.toFixed(2);

  return (
    <button
      type="button"
      className={`button-zoom m-l-2 m-r-2 ${zoomOutClass}`}
      disabled={isDisabled}
      onClick={handleZoomOut}
    >
      <span className="icon">
        <i className="material-icons">zoom_out</i>
      </span>
    </button>
  );
};

ZoomOut.propTypes = {
  zoomOutClass: PropTypes.string,
  scale: PropTypes.number.isRequired,
  defaultScale: PropTypes.number.isRequired,
  minScale: PropTypes.number.isRequired,
  handleZoomOut: PropTypes.func.isRequired,
};

export default ZoomOut;
