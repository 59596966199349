import PropTypes from 'prop-types';
import React from 'react';
import Progress from 'antd/lib/progress';
import './style.scss';

function ProgressBar({ percent = 0, ...props }) {
  return (
    <Progress
      percent={percent}
      successPercent={false}
      className="progress-bar"
      {...props}
    />
  );
}

export default ProgressBar;

ProgressBar.propTypes = {
  percent: PropTypes.number.isRequired,
};
