import React from 'react';
import get from 'lodash.get';
import { Redirect, withRouter } from 'react-router';
import SubLeftMenuContext from 'common/context/menu/SubMenuLeft';
import SubTopMenuContext from 'common/context/menu/SubMenuTop';
import { renderRoutes } from 'react-router-config';
import kpiRoutes, { kpiUrl } from 'components/bdtx/kpi/routes';

const KPI = ({ match }) => {
  const action = get(match, 'params.action');

  const menuItems = [
    {
      id: 'overview-deployment',
      url: kpiUrl.overview_deployment,
      title: 'Tổng quan triển khai',
    },
    {
      id: 'detail-deployment',
      url: kpiUrl.detail_deployment,
      title: 'Thông tin chi tiết theo đơn vị',
    },
  ];

  if (!action) {
    return <Redirect to={kpiUrl.overview_deployment} />;
  }

  return (
    <>
      <SubLeftMenuContext schema={menuItems} />

      <SubTopMenuContext
        lastBreadcrumbName="Tiến độ triển khai"
        hideBackAction
      />

      {renderRoutes(kpiRoutes)}
    </>
  );
};

export default withRouter(KPI);
