import React from 'react';
import { useForm } from 'react-hook-form';
import DataFetchHook from 'common/custom-hook/fetch-data-hook';
import { t1 } from 'translate';
import UrlEndPoint from '../endpoints';

/**
 *
 * @param {*} dataInput
 * onUploadSuccessful : action run afterfetch successful with 2 param in result and sent params
 * exam_shift: exam_shift putting in
 * exam_subject: exam_subject putting in
 */

const UploadFileHook = (dataInput) => {
  const { handleSubmit, setValue, register, errors } = useForm();
  const [
    uploadFileData,
    updateEndpointUploadFileData,
    updateParamHookUploadFileData,
  ] = DataFetchHook(
    UrlEndPoint.import_contestant_list,
    '',
    t1('upload-practice-score_successful'),
    t1('upload-practice-score_have_problems'),
    t1('upload-practice-score_failed'),
    dataInput.onUploadSuccessful,
  );

  const onSubmitHandle = (data) => {
    updateParamHookUploadFileData({
      files: data.contestant_list,
      contest_iid: dataInput.contest_iid,
      contest_id: dataInput.contest_id,
      exam_shift_iid: dataInput.exam_shift,
      exam_subject_iid: dataInput.exam_subject,
      ntype: 'contest',
      timestamp: new Date().getTime(),
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandle)}
      style={{ display: 'inline-block' }}
    >
      <input
        id="contestant_list"
        type="file"
        name="contestant_list"
        placeholder={t1('import_contestant_list')}
        ref={register({
          required: false,
        })}
        onChange={handleSubmit(onSubmitHandle)}
        style={{ display: 'none' }}
      />
      <label
        className="ant-btn btn m-b-10 m-l-10 ant-btn-primary btn-primary"
        for="contestant_list"
      >
        {t1('import_contestant_list')}
      </label>
    </form>
  );
};

export default UploadFileHook;
