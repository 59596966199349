import { call, put, select } from 'redux-saga/effects';
import {
  getFormValues,
  reset,
  startSubmit,
  stopSubmit,
  submit,
} from 'redux-form';
import Requester from 'common/network/http/Request';
import apiUrls from 'api-endpoints';
import actions from 'actions/node/creators';
import { t1 } from 'translate';
import sagaActions from 'actions/node/saga-creators';
import callApiMultipleTimes from './callApiMultipleTimes';

export default function* formSubmit(
  formid,
  options,
  method,
  showSnackbar = true,
) {
  try {
    const {
      executeOnSuccess,
      executeOnFailure,
      closeModal,
      modalKey,
      extraParams,
      formidToSubmitOnSuccess,
      formIdsToSubmitOnSuccess,
      successMessage,
      failureMessage,
      params,
      getFullResponse,
      clearFormOnSubmitSuccess,
      callApiMultipleTimesSettings,
    } = options;
    let { url } = options;

    url = url || apiUrls[formid];

    const formValues = formid ? yield select(getFormValues(formid)) : {};
    let submittedParams = Object.assign({}, formValues, params, extraParams);

    const req =
      typeof method === 'string'
        ? Requester[method.toLowerCase()]
        : Requester.post;

    if (formid) {
      yield put(startSubmit(formid));
    }

    let post;

    if (callApiMultipleTimesSettings) {
      post = yield call(
        callApiMultipleTimes,
        callApiMultipleTimesSettings,
        req,
        url,
        submittedParams,
      );
    } else {
      post = yield call(req, url, submittedParams);
    }

    const success = post && (post.success || getFullResponse);

    if (success) {
      // success
      if (formidToSubmitOnSuccess) {
        yield put(submit(formidToSubmitOnSuccess));
      }
      if (
        Array.isArray(formIdsToSubmitOnSuccess) &&
        formIdsToSubmitOnSuccess.length
      ) {
        yield put(sagaActions.submitMultipleForm(formIdsToSubmitOnSuccess));
      }
      if (closeModal) {
        yield put(actions.handleOpenDialog({ openDialog: false }, modalKey));
      }
      if (executeOnSuccess) {
        yield call(executeOnSuccess, post);
      }
      if (clearFormOnSubmitSuccess && formid) {
        yield put(reset(formid));
      }

      let message = '';
      if (successMessage) {
        message = successMessage;
      } else if (post.message) {
        message = post.message;
      } else {
        message = t1('request_successfully');
      }

      if (showSnackbar) yield put(actions.snackbar(true, message));
    } else {
      // failed
      if (executeOnFailure) {
        yield call(executeOnFailure, post);
      }

      let message = '';
      if (failureMessage) {
        message = failureMessage;
      } else if (post && post.message) {
        message = post.message;
      } else {
        message = t1('request_failed');
      }

      if (showSnackbar) yield put(actions.snackbar('error', message));
    }

    if (formid) {
      yield put(stopSubmit(formid));
    }
    return post;
  } catch (ex) {
    console.log(ex);
    return ex;
  }
}
