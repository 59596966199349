import React, { Component } from 'react';
import { Input, Select } from 'antd';
import get from 'lodash.get';
import shuffle from 'lodash.shuffle';

const { Option } = Select;

class InlineInputTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shuffleItems: [],
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const { e } = this.props;

    const listOptionAnswers = get(e, 'answer', []);
    this.setState({
      shuffleItems: shuffle(listOptionAnswers),
    });
  }

  handleKeyPress = (event) => {
    const { onCheckAnswer, question } = this.props;
    if (event.key === 'Enter') {
      if (onCheckAnswer && question.isVocabQuestion) {
        onCheckAnswer();
      }
    }
  };

  render() {
    const {
      e,
      i,
      onChange,
      getHighlightsClass,
      disabled,
      userAnswers,
      shouldShowKey,
    } = this.props;

    const { shuffleItems } = this.state;

    const userAnswer = (userAnswers && userAnswers[i]) || '';
    const isOpen = !userAnswer && shouldShowKey ? { defaultOpen: true } : {};

    return (
      <span style={{ display: 'inline-flex', position: 'relative' }}>
        <div>
          {e.type === 'input' && (
            <Input
              disabled={disabled}
              name={`text_${i}`}
              className={getHighlightsClass(i)}
              onChange={(event) => {
                onChange(event.target.value, i);
              }}
              onKeyPress={this.handleKeyPress}
              value={userAnswer}
              style={{ minWidth: 120, maxWidth: 320 }}
              autocomplete="off"
            />
          )}
          {e.type === 'select' && (
            <>
              <Select
                disabled={disabled}
                key={shouldShowKey}
                name={`select_${i}`}
                style={{ minWidth: 120, maxWidth: 320 }}
                className={getHighlightsClass(i)}
                onChange={(event) => {
                  !shouldShowKey && onChange(event, i);
                }}
                value={userAnswer}
                getPopupContainer={() => this.myRef.current}
                {...isOpen}
              >
                <Option key={-1} value={undefined}>
                  ---
                </Option>
                {shuffleItems.map((option, index) => (
                  <Option
                    key={index}
                    value={option.text}
                    style={
                      option.isAnswer &&
                      shouldShowKey && { background: 'green' }
                    }
                  >
                    {option.text}
                  </Option>
                ))}
              </Select>
            </>
          )}
          <div ref={this.myRef} style={{ position: 'absolute', bottom: 0 }} />
        </div>
      </span>
    );
  }
}

export default InlineInputTemplate;
