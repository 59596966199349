import React from 'react';
import { timestampToDateTimeString } from 'common/utils/Date';

const ShowDatetimeFromTimestamp = ({ timestamp, label, className = '' }) => {
  if (!timestamp) {
    return null;
  }

  const datetimeString = timestampToDateTimeString(timestamp);
  const datetimeStringWithLabel = label
    ? `${label}: ${datetimeString}`
    : datetimeString;

  return <div className={className}>{datetimeStringWithLabel}</div>;
};

export default ShowDatetimeFromTimestamp;
