import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash.get';
import { List } from 'antd';
import { createLinkForItem } from '../../nav/nav-generator';
import Link from '../../../common/router/Link';
import ProgressStatusIcon from 'components/learn/common/item-progress/ProgressStatusIcon';
import StatusInCircle from 'components/learn/common/item-progress/StatusInCircle';

import './stylesheet.scss';
import lClone from 'lodash.clone';

const computeLevel = (currentItem, listItems) => {
  if (currentItem.level || currentItem.level === 0) {
    return currentItem.level;
  }

  if (!currentItem.pid) {
    return 0;
  }

  let level = 0;
  listItems.forEach((item) => {
    if (String(currentItem.pid) === String(item.iid)) {
      if (!item.level) {
        item.level = computeLevel(item, listItems);
      }
      level = item.level + 1;
    }
  });

  return level;
};

const ScoLayoutList = ({
  trackerProgress,
  learnItem,
  course,
  syllabus,
  nodes,
  listItems = [],
  imageMap = false,
  navRootNodeIid,
}) => {
  const renderItem = useCallback(
    (item) => {
      if (!item) return null;

      return (
        <List.Item
          className={`${item.level > 0 ? `children-level-${item.level}` : ''} `}
        >
          <Link to={createLinkForItem(course, nodes, item, { navRootNodeIid })}>
            <span className="progress-icon m-r-10">
              {ProgressStatusIcon({
                progress: lodashGet(trackerProgress, item.iid, {}),
                node: item,
                syllabus: syllabus,
              })}
            </span>{' '}
            {item.name}
          </Link>
        </List.Item>
      );
    },
    [course, syllabus, nodes, trackerProgress, navRootNodeIid],
  );

  if (!listItems || !Array.isArray(listItems)) return null;

  const dataListItems = lClone(listItems.filter(Boolean));

  dataListItems.forEach(
    (item) => (item.level = computeLevel(item, dataListItems)),
  );

  return (
    <div className={imageMap ? '' : 'sco-layout-list'}>
      <List
        className="sco-layout-list__list-nav"
        header={
          <span
            className={`${
              imageMap ? '' : 'justify-content-center'
            } d-flex align-items-center`}
          >
            <h3 className="sco-layout-list__title">{learnItem.name} </h3>
            <StatusInCircle
              learnItem={learnItem}
              trackerProgress={trackerProgress}
            />
          </span>
        }
        bordered
        dataSource={dataListItems || []}
        renderItem={renderItem}
      />
    </div>
  );
};

const map = (state, props) => {
  return {
    trackerProgress: state.trackerProgress,
  };
};

export default connect(map)(ScoLayoutList);
