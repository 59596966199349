export const COURSES_FIND_REQUEST = 'COURSES_FIND_REQUEST';
export const COURSES_FIND_REQUEST_SUCCESS = 'COURSES_FIND_REQUEST_SUCCESS';
export const COURSES_FIND_REQUEST_ERROR = 'COURSES_FIND_REQUEST_ERROR';

export const findCourse = (userIid) => {
  return {
    type: COURSES_FIND_REQUEST,
    userIid,
  };
};

export const findCoursesSuccess = (result) => {
  return {
    type: COURSES_FIND_REQUEST_SUCCESS,
    result,
  };
};

export const findCoursesError = (error) => {
  return {
    type: COURSES_FIND_REQUEST_ERROR,
    error,
  };
};
