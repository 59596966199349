import React from 'react';
import lodashGet from 'lodash.get';
import { t1 } from 'translate';

const ErrorMessageQuestion = ({
  question,
  error = undefined,
  keepShowing = false,
}) => {
  const [isErrorAnswer, setShow] = React.useState(error);
  const ref = React.useRef();
  const focusRender = React.useRef(false);

  React.useEffect(
    () => {
      setShow(error);
      focusRender.current = !focusRender.current;
      if (!keepShowing) {
        if (ref.current) {
          clearTimeout(ref.current);
        }
        ref.current = setTimeout(() => {
          setShow(undefined);
        }, 3000);
      }
    },
    [error, keepShowing],
  );

  if (isErrorAnswer < 0) {
    return (
      <div key={focusRender.current} className="m-l-5 m-b-20 text-primary">
        <span className="ve ve-check text-primary m-r-10" />
        {lodashGet(question, 'feedback_true') || t1('correct_answer')}
      </div>
    );
  }
  if (isErrorAnswer > 0 || isErrorAnswer) {
    return (
      <div
        key={focusRender.current}
        className="m-l-5 m-b-20 text-danger question-error"
      >
        <span className="ve ve-close-outline font-size-small m-r-10" />{' '}
        {lodashGet(question, 'feedback_false') || t1('wrong_answer')}
      </div>
    );
  }
  return null;
};

export default ErrorMessageQuestion;
