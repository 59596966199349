import Attachments from 'schema-form/elements/attachments';

import { t1 } from 'translate';
import lodashGet from 'lodash.get';

const schema = () => {
  return {
    content: {
      type: 'text',
      multiline: true,
      rows: 4,
      floatingLabelText: t1('review_evidence_content'),
      fullWidth: true,
    },
    attachments: {
      type: Attachments,
      allowDownload: true,
      label: t1('review_evidence_attachments'),
    },
  };
};

const ui = () => {
  return [
    {
      id: 'default',
      fields: ['content', 'attachments'],
    },
  ];
};

const finalProcessBeforeSubmit = (fullData, node, schema, mode, step) => {
  const reviewEvidence = {};
  if (lodashGet(fullData, 'content')) {
    reviewEvidence['content'] = lodashGet(fullData, 'content');
  }

  if (lodashGet(fullData, 'attachments')) {
    reviewEvidence['attachments'] = lodashGet(fullData, 'attachments');
  }

  return {
    review_evidence: reviewEvidence,
    ...(fullData || {}),
  };
};

export default {
  schema,
  ui,
  finalProcessBeforeSubmit,
};
