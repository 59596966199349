import React from 'react';
import LocaleProvider from 'antd/lib/locale-provider';
import moment from 'moment';
import 'moment/locale/vi';
import 'moment/locale/en-au';
import 'moment/locale/ja';
import vi_VN from 'antd/lib/locale-provider/vi_VN';
import en_US from 'antd/lib/locale-provider/en_US';
import ja_JP from 'antd/lib/locale-provider/ja_JP';
import withSchoolConfigs from 'common/hoc/withSchoolConfigs';

const LocaleProviderWrapper = ({ children, userLanguage }) => {
  const [locale, setLocale] = React.useState(vi_VN);

  React.useEffect(
    () => {
      let locale;
      switch (userLanguage) {
        case 'en':
          moment.locale('en');
          locale = en_US;
          break;
        case 'jp':
        case 'ja':
          moment.locale('ja');
          locale = ja_JP;
          break;
        default:
          moment.locale('vi');
          locale = vi_VN;
      }

      setLocale(locale);
    },
    // eslint-disable-next-line react/prop-types
    [setLocale, userLanguage],
  );

  return <LocaleProvider locale={locale}>{children}</LocaleProvider>;
};

export default withSchoolConfigs(LocaleProviderWrapper);
