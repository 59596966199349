import React from 'react';
import get from 'lodash.get';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import endpoints from 'components/bdtx/choose-modules/endpoints';
import ChooseModule from 'components/bdtx/choose-modules/new';
import {
  getTargetUser,
  userGradeOptions,
  userGradeToText,
} from 'configs/constants/user';
import { generateTableDataSource } from 'components/bdtx/choose-modules/utils';
import Alert from 'antd/lib/alert';
import CommonAntdTable from 'components/common/antd/table';
import ChuongTrinhTag from 'components/bdtx/common/ChuongTrinhTag';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import { getTotalCredit, isEnoughCredit } from 'components/bdtx/utils';
import { CHUONG_TRINH_01, CHUONG_TRINH_03 } from 'components/bdtx/configs';
import { isBo } from 'components/admin/user/utils';
import Tooltip from 'common/tooltip';
import { Link } from 'react-router-dom';

const SEARCH_FORM_ID = 'search_choose_module';

const SearchChooseModule = ({ targetUser, readOnly, userInfo }) => {
  const generateFormKey = (cap, chuongTrinh) => {
    return `choose-module-${chuongTrinh}-${cap} -${targetUser}`;
  };

  const isEnoughCreditOfCap = (items = [], cap) => {
    const dataByCap = items.filter((item) => item.cap === cap);

    let modules = [];
    dataByCap.forEach((data) => {
      modules = [...modules, ...data.modules];
    });

    const dataByCapOfCT3 = dataByCap.find(
      (data) => data.chuong_trinh == CHUONG_TRINH_03,
    );
    const modulesOfCT3 = get(dataByCapOfCT3, 'modules', []);

    const totalCredit = getTotalCreditFromModules(modules);
    const totalCreditOfCT3 = getTotalCreditFromModules(modulesOfCT3);

    return isEnoughCredit(totalCredit, 3) && isEnoughCredit(totalCreditOfCT3);
  };

  const getTotalCreditFromModules = (modules = []) => {
    const moduleHasChosen = modules.filter((module) => module.chosen);

    return getTotalCredit(moduleHasChosen);
  };

  const renderChooseForm = (modules, chuongTrinh, cap) => {
    const hasModules = modules && modules.length;
    // const isReadOnlyForm =
    //   (isBo(userInfo) && chuongTrinh != CHUONG_TRINH_01) ||
    //   (!isBo(userInfo) && chuongTrinh == CHUONG_TRINH_01);
    // TODO: Remove this
    const isReadOnlyForm = isBo(userInfo) && chuongTrinh != CHUONG_TRINH_01;

    if (!hasModules) {
      return <div className="p-l-15 m-b-15 text-danger">Chưa có mô đun</div>;
    }

    return (
      <ChooseModule
        formid={generateFormKey(cap, chuongTrinh)}
        modules={modules}
        chuongTrinh={chuongTrinh}
        hiddenFields={{
          chuong_trinh: chuongTrinh,
          cap: cap,
          target_user: targetUser,
        }}
        searchFormId={SEARCH_FORM_ID}
        readOnly={isReadOnlyForm}
      />
    );
  };

  const renderTableColumns = (items) => {
    return [
      {
        title: 'Chương trình',
        dataIndex: 'chuongTrinh',
        key: 'chuongTrinh',
        render: (chuongTrinh) => {
          return <ChuongTrinhTag chuongTrinh={chuongTrinh} shortName />;
        },
      },
      {
        title: 'Cấp học',
        type: TYPE_OF_TABLE_DATA.TITLE,
        children: userGradeOptions().map((userGrade) => {
          const isEnough = isEnoughCreditOfCap(items, userGrade.value);

          return {
            title: (
              <>
                <span>{userGradeToText(userGrade.value)}</span>
                <div
                  className={`text-bold font-size-small ${
                    isEnough ? 'text-primary' : 'text-danger'
                  }`}
                >
                  {`${isEnough ? 'Đã' : 'Chưa'} đủ 120 tiết`}
                  <Tooltip content="Tổng số tiết của chương trình 01, chương trình 02, chương trình 03 tối thiểu có đủ 120 tiết. Trong đó, chương trình 03 phải có tối thiểu 40 tiết" />
                </div>
              </>
            ),
            dataIndex: userGrade.value,
            key: userGrade.value,
            type: TYPE_OF_TABLE_DATA.TITLE,
            render: (modules = [], item) => {
              return {
                children: (
                  <>
                    {renderChooseForm(
                      modules,
                      item.chuongTrinh,
                      userGrade.value,
                    )}
                  </>
                ),
                props: {
                  className: 'text-left position-relative',
                },
              };
            },
          };
        }),
      },
    ];
  };

  const renderResultComponent = (items) => {
    return (
      <>
        <div className="m-b-25">
          <Alert
            message={'Chọn mô đun đào tạo'}
            description={
              <ul>
                <li>
                  Tổng số tiết của chương trình 01, chương trình 02, chương
                  trình 03 tối thiểu có đủ 120 tiết. Trong đó, chương trình 03
                  phải có tối thiểu 40 tiết
                </li>
                <li>
                  Mô đun có trạng thái <b>Đã lên kế hoạch</b> là mô đun đã được
                  lên kế hoạch và triển khai trong năm học. Bạn có thể nhấn vào
                  đó để hiển thị chi tiết
                </li>
                <li>
                  Mô đun có trạng thái <b>Được kết chuyển từ ETEP</b> là mô đun
                  được kết chuyển từ ETEP. Bạn có thể nhấn vào đó để hiển thị
                  chi tiết. Để xem tình trạng kết chuyển, vui lòng{' '}
                  <Link to={'/admin/bdtx/migrate-etep'} className="link">
                    nhấn vào đây
                  </Link>
                </li>
              </ul>
            }
            type="info"
            showIcon
          />
        </div>

        <CommonAntdTable
          dataSource={generateTableDataSource(items)}
          columns={renderTableColumns(items)}
          pagination={false}
          className="learn-default assign-form"
        />
      </>
    );
  };

  const renderNoResultComponent = () => {
    return (
      <SimpleNoResult
        text={`Đối tượng ${getTargetUser(targetUser)} không có mô đun`}
      />
    );
  };

  return (
    <SearchWrapper
      schema={{}}
      formid={SEARCH_FORM_ID}
      alternativeApi={endpoints.bdtx_get_choose_module}
      renderResultsComponent={renderResultComponent}
      renderNoResultComponent={renderNoResultComponent}
      hiddenFields={{
        target_user: targetUser,
      }}
      autoSearchWhenValuesChange
      autoSearchWhenStart
      hidePagination
      showSearchButton={false}
      userInfo={userInfo}
    />
  );
};

export default SearchChooseModule;
