import { Modal } from 'antd';
import { call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { t1 } from 'translate';

const isPromise = (any) => ({}.toString.call(any) === '[object Promise]');
const isFunction = (func) => ({}.toString.call(func) === '[object Function]');
const isGeneratorFunction = (func) =>
  ({}.toString.call(func) === '[object GeneratorFunction]');

const responseErrorCompareFunction = (response) =>
  !response || !response.success || response.err;

const DELAY_TIME = 1000;
const NUMBER_OF_RETRY = 3;

export const returnFunctionTypes = {
  async: 'async',
  generator: 'generator',
};

const withRetry = (request, options) => {
  const {
    numberOfRetry = NUMBER_OF_RETRY,
    delayTime = DELAY_TIME,
    type = returnFunctionTypes.generator,
    responseErrorCondition = responseErrorCompareFunction,
    showDialog = true,
    message = t1('an_error_occurred_while_making_the_request'),
    executeOnRetryFailure = () => {},
  } = options || {};

  const numberOfRetryConfig = numberOfRetry <= 0 ? 1 : numberOfRetry;

  function* retry(...params) {
    for (let i = 0; i < numberOfRetryConfig; i++) {
      const isContinueRetry = i < numberOfRetryConfig - 1;
      try {
        let response = null;

        if (isFunction(request)) {
          response = yield request(...params);
        }

        if (isGeneratorFunction(request)) {
          response = yield call(request, ...params);
        }
        if (
          isFunction(responseErrorCondition) &&
          responseErrorCondition(response)
        ) {
          if (isContinueRetry) {
            yield delay(delayTime);
          } else if (showDialog) {
            Modal.error({
              title: t1('connection_errors'),
              content: message,
            });
            isFunction(executeOnRetryFailure) &&
              executeOnRetryFailure(response);
          }
        } else {
          return yield response;
        }
      } catch (error) {
        if (isContinueRetry) {
          yield delay(delayTime);
        } else {
          isFunction(executeOnRetryFailure) && executeOnRetryFailure(error);
          return error;
        }
      }
    }
  }

  if (type === returnFunctionTypes.async) {
    return async (...params) => {
      const iterator = retry(...params);
      let iteratorResult = iterator.next();
      let result = undefined;

      while (!iteratorResult.done) {
        if (isPromise(iteratorResult.value)) {
          result = await iteratorResult.value;
        } else {
          result = iteratorResult.value;
        }
        iteratorResult = iterator.next(result || iteratorResult.value);
      }

      return iteratorResult.value || result;
    };
  }

  return retry;
};

export default withRetry;
