import BdtxDetailDeployment from './detail-by-organization';
import BdtxOverviewDeployment from './overview-by-organization';

export const kpiRootUrl = '/admin/bdtx/kpi';

export const kpiUrl = {
  root: kpiRootUrl,
  overview_deployment: `${kpiRootUrl}/overview`,
  detail_deployment: `${kpiRootUrl}/detail`,
};

export default [
  {
    componentId: 'overview-deployment',
    path: kpiUrl.overview_deployment,
    component: BdtxOverviewDeployment,
    exact: true,
  },
  {
    componentId: 'kpi-detail-by-organization',
    path: kpiUrl.detail_deployment,
    component: BdtxDetailDeployment,
    exact: true,
  },
];
