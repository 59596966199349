import React, { Component } from 'react';
import { connect } from 'react-redux';
import { t1 } from 'translate';
import { Link } from 'react-router-dom';
import DisplayHtml from 'components/common/html';
import {
  questionTypeInText,
  types as questionTypes,
} from 'components/admin/question/schema/question-types';
import SurveyReportQuestionChart from './Chart';
import { USER_GROUP_ALL } from 'configs/constants/user-demographics';
import OpenEndedQuestionAnswersByQuestionId from '../open-ended-question-answers-by-question-id';

const styles = {
  title: {
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
};

class SurveyReportQuestion extends Component {
  renderQuestionContent = (question, stt) => {
    const qType = questionTypeInText(question.type);

    return (
      <div>
        {question.type != questionTypes.TYPE_INTRODUCTION && (
          <div style={styles.title}>
            <span>
              {t1(`question_%s`, stt)}: ({qType})
            </span>
          </div>
        )}
        <DisplayHtml content={question.content} />
      </div>
    );
  };

  render() {
    const {
      question,
      stt,
      surveyIid,
      trainingPlan,
      paramsToFilter,
      userDemographic,
    } = this.props;

    const questionContent = this.renderQuestionContent(question, stt);
    let questionAnswers = <div />;

    switch (question.type) {
      case questionTypes.TYPE_MC:
      case questionTypes.TYPE_MMC:
      case questionTypes.TYPE_MC_OPEN_ENDED:
      case questionTypes.TYPE_NUMBER:
      case questionTypes.TYPE_TRUE_FALSE:
        questionAnswers = (
          <SurveyReportQuestionChart
            question={question}
            demographic={USER_GROUP_ALL}
          />
        );
        break;
      case questionTypes.TYPE_OPEN_ENDED:
        questionAnswers = (
          <OpenEndedQuestionAnswersByQuestionId
            question={question}
            surveyIid={surveyIid}
            trainingPlan={trainingPlan}
            paramsToFilter={paramsToFilter}
            userDemographic={userDemographic}
          />
        );

        break;
      default:
        break;
    }

    return (
      <div>
        <div>{questionContent}</div>
        <div>{questionAnswers}</div>
        <hr />
      </div>
    );
  }
}

export default connect()(SurveyReportQuestion);
