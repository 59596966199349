import lodashGet from 'lodash.get';
import {
  caphoc,
  chuong_trinh,
  listSchoolYear,
  targetUser,
} from 'components/common/elements';
import { organizations } from 'components/admin/organization/schema/elements';
import { t1 } from 'translate';
import { required } from 'common/validators';
import { userGrades } from 'configs/constants/user';
import { CHUONG_TRINH_03 } from 'components/bdtx/configs';
import subTypes from 'configs/constants/org-sub-types';
import { isEnableTemis } from 'components/temis/utils';

const schema = (formid, values, step, xpath, props) => {
  return {
    q: {
      type: 'text',
      floatingLabelText: t1('input_name_or_email_of_user'),
      classWrapper: 'col-md-6',
    },
    chuong_trinh: chuong_trinh(
      {
        classWrapper: 'col-md-5',
      },
      {
        excludeChuongTrinhs: [CHUONG_TRINH_03],
      },
    ),
    school_year: listSchoolYear({
      floatingLabelText: 'Năm học',
      multiple: false,
      validate: [required('school_year_cannot_be_empty')],
      classWrapper: 'col-md-4',
    }),
    organizations: organizations({
      formid,
      multiple: false,
      label: t1('organizations'),
      classWrapper: 'col-md-6',
      populateDefaultValue: true,
      isBdtx: !isEnableTemis(),
      subTypesDiscarded: isEnableTemis() ? [subTypes.TAPHUAN_SUBTYPE_LTTU] : [],
    }),
    target_user: targetUser({
      type: 'radio',
      classWrapper: 'col-md-3',
      defaultValue: 'gv',
    }),
    cap: caphoc({
      classWrapper: 'col-md-6',
      type: 'radio',
      defaultValue: userGrades.PRIMARY,
    }),
  };
};

const ui = () => {
  let fields = [
    // 'chuong_trinh',
    // 'school_year',
    'organizations',
    'q',
    'cap',
    'target_user',
  ];

  return [
    {
      id: 'id',
      fields: fields,
    },
  ];
};

export default {
  schema,
  ui,
};
