import subTypes from 'configs/constants/org-sub-types';
import { simpleOrganization } from 'components/admin/organization/schema/elements';

const schema = () => {
  return {
    organizations: simpleOrganization(
      {
        floatingLabelText: 'Đơn vị làm đề thi',
        classWrapper: 'col-md-12',
      },
      {
        value: {
          sub_type: [subTypes.TAPHUAN_SUBTYPE_LTTU],
        },
      },
      false,
      {
        value: '',
        label: 'Tất cả các đơn vị',
      },
    ),
  };
};

const ui = () => {
  return [
    {
      id: 'default',
      fields: ['organizations'],
    },
  ];
};

export default {
  schema,
  ui,
};
