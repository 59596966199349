/**
 * this file contains util functions to work with question in exercise flow
 * it will have some attributes that normal question do not have such as 'isTicked', 'isTouched', 'disabled'
 *
 * DO NOT USE THIS FUNCTION WITH NORMAL QUESTION
 */
import lodashGet from 'lodash.get';
import { types } from 'components/admin/question/schema/question-types';
import { isQuestionDone } from '../../question';
import { types as questionTypes } from 'components/admin/question/schema/question-types';

export const isQuestionDisabled = (question) => {
  return lodashGet(question, 'disabled');
};

export const getQuestionDuration = (question) => {
  return lodashGet(question, 'duration');
};

export const getQuestionTimeLeft = (question) => {
  return lodashGet(question, 'questionTimeLeftInSeconds');
};

export const getQuestionCSSClassModifier = (question, progresses = {}) => {
  const { answer, shouldDisplayCheckedResult, isTouched, type, isCorrect } =
    question || {};
  const isQuestionAnswered = isQuestionDone(type, answer);

  if (
    question.iid &&
    progresses[question.iid] &&
    progresses[question.iid].tts
  ) {
    return 'ordered';
  }

  if (isQuestionAnswered && type !== questionTypes.TYPE_OPEN_ENDED) {
    if (
      shouldDisplayCheckedResult ||
      (question.type === types.TYPE_API && typeof isCorrect !== 'undefined')
    ) {
      return isCorrect ? 'correct' : 'incorrect';
    }
  }

  if (isQuestionAnswered) {
    return 'done';
  }
  return isTouched ? 'touched' : '';
};
