import React from 'react';
import { canJoinToLearn, joinStatuses } from '../utils';
import MeetingUrl from './MeetingUrl';
import { t1 } from 'translate';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import getLodash from 'lodash.get';
import { isEtepCotCanTpCategory } from 'components/admin/training-plan/common';
import Warning from 'components/common/Warning';

const Status = ({
  session,
  beforeCanJoinToLearn,
  serverTimestamp,
  automaticScaleVirtualClassEnabled,
  requiredLicense,
  canJoinVirtualClass,
  isTeacher,
}) => {
  let cssClass;
  let statusText;

  const status = canJoinToLearn(
    session,
    beforeCanJoinToLearn,
    serverTimestamp,
    automaticScaleVirtualClassEnabled,
  );

  switch (status) {
    case joinStatuses.JOIN_STATUS_OK:
      statusText = t1('join_session'); //'Đang học';
      break;
    case joinStatuses.JOIN_STATUS_TOO_LATE:
      cssClass = 'session-ended ve-daketthuc';
      statusText = t1('finished'); //'Đã kết thúc';
      break;
    case joinStatuses.JOIN_STATUS_TOO_EARLY:
      cssClass = 'session-too-early ve-chuadengio';
      statusText = t1('upcoming');
      // statusText = 'Sắp diễn ra';
      break;
    case joinStatuses.JOIN_STATUS_NOT_YET_SCHEDULED:
      statusText = t1('not_yet_scheduled'); // Chưa xếp lớp';
  }

  return [
    <div
      className={`color-by-status ${
        !!requiredLicense && !canJoinVirtualClass
          ? 'cannot-join-virtual_class'
          : ''
      }`}
    >
      {status === joinStatuses.JOIN_STATUS_OK ? (
        <MeetingUrl
          session={session}
          statusText={statusText}
          serverTimestamp={serverTimestamp}
          automaticScaleVirtualClassEnabled={automaticScaleVirtualClassEnabled}
          canJoinVirtualClass={canJoinVirtualClass}
          isTeacher={isTeacher}
        />
      ) : (
        <div className="status-item">
          {!!cssClass && <div className={`join-icon ${cssClass}`} />}
          <div>{statusText}</div>
        </div>
      )}
    </div>,
    !!requiredLicense && !canJoinVirtualClass && (
      <div
        className="text-center m-t-15"
        title="Gói dịch vụ giấy phép hoạt động không hỗ trợ tính năng này"
      >
        <Warning inline>Không được phép tham gia vào buổi học</Warning>
      </div>
    ),
  ].filter(Boolean);
};

const mapStateToProps = createSelector(
  (state) => getLodash(state, 'domainInfo.conf.before_time_can_join_class'),
  (state) => getLodash(state, 'domainInfo.school.supported_learning_methods'),
  (state, { session }) =>
    !getLodash(session, 'skipRequiredLicense') &&
    getLodash(state, 'domainInfo.conf.required_license'),
  (state, { canJoinVirtualClass, session }) => {
    const requiredLicense = getLodash(
      state,
      'domainInfo.conf.required_license',
    );

    const tpCategory = getLodash(session, 'course.training_plan_category');

    if (
      !requiredLicense ||
      !!canJoinVirtualClass ||
      isEtepCotCanTpCategory(tpCategory)
    ) {
      return true;
    }

    const courses = getLodash(session, 'courses');
    if (Array.isArray(courses) && courses.length) {
      const isCotCan = courses.find(({ training_plan_category }) =>
        isEtepCotCanTpCategory(training_plan_category),
      );

      if (isCotCan) {
        return true;
      }
    }

    const functionActiveOfLicense = getLodash(
      state,
      'dataApiResults.license.license.package_detail.function_active',
    );
    if (
      !Array.isArray(functionActiveOfLicense) ||
      !functionActiveOfLicense.length
    ) {
      return false;
    }

    const licenseUserSystem = functionActiveOfLicense.find(
      ({ id } = {}) => id === 'license_user_system',
    );
    return [1, '1'].includes(
      getLodash(licenseUserSystem, 'action.license_admin_virtual_class'),
    );
  },
  (
    beforeCanJoinToLearn,
    supportedLearningMethods,
    requiredLicense,
    canJoinVirtualClass,
  ) => ({
    beforeCanJoinToLearn,
    supportedLearningMethods,
    requiredLicense,
    canJoinVirtualClass,
  }),
);

export default connect(mapStateToProps)(Status);
