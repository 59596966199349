import React, { Component } from 'react';
import schema from '../extend-session-time/schema';
import SimpleSubmitForm from 'components/common/forms/SimpleSubmitForm';
import { t1 } from 'translate';
import sessionApiUrls from 'components/admin/session/endpoints';
import fetchData from 'components/common/fetchData';
import get from 'lodash.get';

class AddSessionTimeForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { sessionDetail, closeDialog } = this.props;
    const endTime = get(sessionDetail, 'scheduled.end_time');
    const timeExtends = get(sessionDetail, 'time_extends');
    const totalExtendedTime = timeExtends
      ? timeExtends.reduce((total, item) => total + item.minutes, 0)
      : 0;
    const node = {
      totalExtendedTime: totalExtendedTime,
      endTime: endTime,
    };
    const searchFormId =
      this.props.searchFormId || `edit_session_${get(sessionDetail, 'iid')}`;
    return (
      <SimpleSubmitForm
        resetForm
        schema={schema}
        node={node}
        mode={'edit'}
        formidToSubmitOnSuccess={searchFormId}
        hiddenFields={{
          sessionIid: get(sessionDetail, 'iid'),
        }}
        alternativeApi={sessionApiUrls.update_session_extend_time}
        executeOnSuccess={() => closeDialog()}
        submitLabels={{
          edit: t1('submit'),
        }}
      />
    );
  }
}

const fetchExtendTimeInfor = fetchData((props) => {
  return {
    baseUrl: sessionApiUrls.get_session_with_time_extends,
    params: {
      iid: get(props, 'sessionIid'),
    },
    propKey: 'sessionDetail',
  };
});

export default fetchExtendTimeInfor(AddSessionTimeForm);
