export default {
  search_bdtx_cot_can_training_plan_planned_users:
    '/bdtx/cot-can/search-planned-users',
  export_bdtx_cot_can_training_plan_planned_users:
    '/bdtx/cot-can/export-planned-users',
  remove_bdtx_cot_can_training_plan_planned_users:
    '/bdtx/cot-can/remove-planned-users',
  approve_bdtx_cot_can_training_plan_planned_users:
    '/bdtx/cot-can/approve-planned-users',

  get_academic_categories_by_grade_to_add_planned_users:
    '/bdtx/cot-can/get-academic-categories-by-grade-to-add-planned-users',
  search_users_to_quick_add_planned_users:
    '/bdtx/cot-can/search-users-to-quick-add-planned-users',
  quick_add_planned_users: '/bdtx/cot-can/quick-add-planned-users',
};
