import './stylesheet.scss';

import PropTypes from 'prop-types';
import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import { Tooltip } from 'antd';

const ImageOnDialog = ({
  src,
  alt = '',
  size,
  style = {},
  className = '',
  dialogKey,
  dialogFullScreen = true,
  hoverToZoomOut = false,
}) => {
  if (!src) {
    return <span>no image</span>;
  }

  const previewImg = (showFull, showOnTooltip) => (
    <div className="text-center">
      <div
        className={`component-image__preview cursor-pointer ${
          showOnTooltip ? 'show-on-tooltip' : ''
        }`}
        onClick={showFull}
        style={style}
      >
        <img width="100%" src={src} alt={alt} />
      </div>
    </div>
  );

  const renderPreview = ({ showFull }) => {
    if (hoverToZoomOut) {
      return (
        <Tooltip
          title={previewImg(showFull, true)}
          overlayClassName="max-width-auto"
        >
          {previewImg(showFull)}
        </Tooltip>
      );
    }

    return previewImg(showFull);
  };
  const renderFull = ({ closeDialog }) => {
    return (
      <div
        className={`component-image__container ${
          dialogFullScreen ? 'full-height' : ''
        }`}
        onClick={() => {
          closeDialog();
        }}
      >
        <img src={src} alt={alt} className="full-image" />
      </div>
    );
  };

  return (
    <DetailOnDialog
      renderPreview={renderPreview}
      renderFull={renderFull}
      dialogOptionsProperties={{
        modal: true,
        width: dialogFullScreen ? '100%' : undefined,
        handleClose: true,
        className: `component-image ${
          dialogFullScreen ? 'modal-full-height' : ''
        }`,
      }}
      className={className}
      dialogKey={dialogKey || src}
    />
  );
};

export default ImageOnDialog;

ImageOnDialog.propTypes = {
  alt: PropTypes.string,
  size: PropTypes.any,
  src: PropTypes.any.isRequired,
  style: PropTypes.object,
};

ImageOnDialog.defaultProps = {
  alt: '',
  style: {},
};
