import React from 'react';
import SurveyReportQuestion from './Question';
import { types } from 'components/admin/question/schema/question-types';
import { USER_GROUP_ALL } from 'configs/constants/user-demographics';
import lodashGet from 'lodash.get';

const ReportAsChart = ({
  dataByQuestion,
  surveyIid,
  trainingPlan,
  paramsToFilter,
  survey,
}) => {
  if (!Array.isArray(dataByQuestion) || !dataByQuestion.length) {
    return null;
  }

  let stt = 0;
  const children =
    lodashGet(survey, 'children') || lodashGet(survey, 'metadata');

  return dataByQuestion.map((item, index) => {
    let question = item;
    if (Array.isArray(children) && children.length) {
      question = Object.assign(
        question,
        children.find(({ id }) => question.id == id || question.iid == id) ||
          {},
      );
    }

    if (question.type != types.TYPE_INTRODUCTION) {
      stt = stt + 1;
    }

    return (
      <SurveyReportQuestion
        stt={stt}
        question={question}
        surveyIid={surveyIid}
        key={`srq-${index}_${question.id}`}
        trainingPlan={trainingPlan}
        paramsToFilter={paramsToFilter}
        userDemographic={USER_GROUP_ALL}
      />
    );
  });
};

export default ReportAsChart;
