export const getCurrentPercent = ({ trackerProgress, learnItem }) => {
  if (trackerProgress && learnItem) {
    const currentItemProgress = trackerProgress[learnItem.iid];
    if (currentItemProgress) {
      if (learnItem.ntype === 'exercise') {
        // do not display progress for exercise
        return undefined;
      }

      if (currentItemProgress.cp) {
        return currentItemProgress.cp;
      }

      if (currentItemProgress.p && currentItemProgress.time_spent) {
        return currentItemProgress.p;
      }

      return undefined;
    }
  }
  return undefined;
};
