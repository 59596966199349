import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import commonSagaActions from 'actions/saga-creators';
import get from 'lodash.get';
import DownloadFileFromCache from 'components/common/action-button/export-btn-with-data-from-cache/downloadFileFromCache';
import DetailOnDialog from 'components/common/detail-on-dialog';
import lodashGet from 'lodash.get';
import { shortTextByStringMapping } from 'common/utils/string';

const ExportData = ({
  buttonClass = '',
  buttonText = 'Xuất dữ liệu',
  disabled = false,
  exportResult,
  tableExport,
  apiExport,
  params = {},
  updateTableBody = true,
  fetchingCacheOnly = false,
  fileName = '',
  fullFileName,
  addTimeGetFile = false,
  executeRealtime = false,
  classNameWrapperTable = 'display-none',
  organizationsFullInfo = {},
  resultId = null,
}) => {
  const [fileNameExport, setFileNameExport] = useState(fileName);
  const [tableBodyHtml, setTableBodyHtml] = React.useState(null);
  const tableRef = React.useRef();

  useEffect(
    () => {
      setTimeout(() => {
        if (tableRef && tableRef.current && (updateTableBody || resultId)) {
          const innerHTML = tableRef.current.getElementsByClassName(
            'ant-table-body',
          );
          setTableBodyHtml(get(innerHTML, '0.innerHTML'));
        }
      }, 10);
    },
    [tableRef, setTableBodyHtml, updateTableBody, resultId],
  );

  useEffect(
    () => {
      const organization =
        organizationsFullInfo[lodashGet(params, 'organizations.0')] ||
        organizationsFullInfo[lodashGet(params, 'user_organizations.0')] ||
        organizationsFullInfo[lodashGet(params, 'organizations')] ||
        organizationsFullInfo[lodashGet(params, 'user_organizations')];

      if (lodashGet(organization, 'name') && fileName) {
        setFileNameExport(
          `[${shortTextByStringMapping(
            lodashGet(organization, 'name'),
          )}]_${fileName}`,
        );
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [params],
  );

  return (
    <>
      {fetchingCacheOnly ? (
        <DetailOnDialog
          confirmModal
          renderPreview={({ showFull }) => {
            return (
              <button
                className={`btn btn-secondary btn-outline ${buttonClass}`}
                onClick={showFull}
                disabled={disabled}
                type="button"
              >
                {buttonText}
              </button>
            );
          }}
          renderFull={({ closeDialog }) => {
            return (
              <DownloadFileFromCache
                closeDialog={closeDialog}
                url={apiExport}
                executeRealtime={executeRealtime}
                params={Object.assign({}, { html: tableBodyHtml }, params)}
                fileName={fileNameExport}
                fullFileName={fullFileName}
                addTimeGetFile={addTimeGetFile}
              />
            );
          }}
          dialogKey={'export_btn_with_data_from_cache'}
          dialogOptionsProperties={{
            handleClose: false,
            width: 'auto',
            style: { maxWidth: 450 },
          }}
        />
      ) : (
        <button
          className={`btn btn-secondary btn-outline ${buttonClass}`}
          onClick={() => exportResult(tableBodyHtml, fileNameExport)}
          disabled={disabled}
          type="button"
        >
          {buttonText}
        </button>
      )}
      {!!tableExport && (
        <div className={classNameWrapperTable} ref={tableRef}>
          {tableExport}
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (
  dispatch,
  { apiExport, fileName = '', params, formId },
) => {
  return {
    exportResult: (html, fileNameExport) => {
      dispatch(
        commonSagaActions.exportDataRequest({
          url: apiExport,
          params: Object.assign({}, { html }, params),
          formid: formId,
          fileName: fileNameExport,
        }),
      );
    },
  };
};

const mapStateToProps = (state) => {
  return {
    organizationsFullInfo: lodashGet(
      state,
      'formSchemaConfigs.organization_schema_config',
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExportData);
