import React from 'react';
import { t1 } from 'translate';
import { questionTypeInText } from 'components/admin/question/schema/question-types';

const texts = () => ({
  action_list: {
    random_test: t1('random_test'),
    history: t1('practice_history'),
    category_test: t1('category_test'),
    category_template_test: t1('category_template_test'),
  },
  congrat_text: t1('congratulation_you_just_finished_your_practice_exam'),
  review_take_text: t1('view_your_practice_take'),
  score_label: t1('your_score'),
  practice_status_text: t1('practice_exam_status'),
  passed_text: t1('passed'),
  failed_text: t1('failed'),
  not_show_on_this_mode_text: t1('not_show_on_this_mode_text'),
  time_spend_text: t1('time_spent_on_test'),
  time_spend_format: 'HH:mm:ss',
  detail_text: t1('result_in_detail'),
  practice_again_btn_text: t1('back_to_practice_again'),
  end_exam_practice: t1('end_exam_practice'),
  view_take_history: t1('view_take_history'),
  no_value_tags_category: t1('no_value_tags_category'),
  no_value_tags: 'no_value_tags',
  all_questions_text: t1('all_questions'),
  start_practice_exam: t1('start_practice_exam'),
  question_result_review_text: (totalTime, correctTime, incorrectTime, last) =>
    t1(
      'you_have_been_practice_this_question_%s_times_correct_%s_incorrect_%s_last_result_%s',
      [totalTime, correctTime, incorrectTime, last],
    ),
  exam_template_full_title: (name, nrQuestion, total) => (
    <div>
      <span style={{ whiteSpace: 'normal' }}>
        {t1('exam_template_%s', [name])}
      </span>
      <br />
      <small>
        {t1('total_question_%s_,_total_score_%s', [nrQuestion, total])}
      </small>
    </div>
  ),
  exam_practice_group_full_text: (name, total) =>
    t1('part_%s_(total_score_%s)', [name, total]),
  exam_template_group_schema_name: (key) => t1('group_number_%s', [key]),
  text_group_categories: (categories) => {
    var string = '';
    categories.map((cat, key) => {
      if (key == 0) {
        string += cat;
      } else {
        string += ', ' + cat;
      }
    });
    return t1('categories:_%s', [string]);
  },
  text_group_difficulty: (difficulty) =>
    t1('question_difficulty:_%s', [t1(difficulty)]),
  text_group_nr_of_questions: (nrQuestion) =>
    t1('nr_of_questions:_%s', [nrQuestion]),
  text_group_question_type: (types) => {
    var string = '';
    types.map((qType, key) => {
      if (key == 0) {
        string += questionTypeInText(qType);
      } else {
        string += ', ' + questionTypeInText(qType);
      }
    });
    return t1('question_belong_to_type_%s', [string]);
  },
  text_group_score: (score) => t1('exam_group_score:_%s', [score]),
  exam_practice_by_category_text: (categoryName) => categoryName,
  exercise_practice_by_category_text: (categoryName) => categoryName,
  exam_practice_by_category_total_question_text: (numberOfTotalQuestion) =>
    t1('total_%s_questions_available_in_this_category', [
      numberOfTotalQuestion,
    ]),
  exam_practice_by_category_total_question_tooltip: (
    totalQuestion,
    listQuestionSuitableCount,
    numberOfIncorrect,
  ) =>
    t1('total_%s_questions_in_this_category_practiced_%s_incorrect_%s', [
      totalQuestion,
      listQuestionSuitableCount,
      numberOfIncorrect,
    ]),
  previous_button_text: t1('previous_question_text'),
  next_button_text: t1('next_question_text'),
  practicing_screen_title: (templateName, contestSubjectName) =>
    t1('practicing_template_%s_for_contest_subject_%s', [
      templateName,
      contestSubjectName,
    ]),
  show_answer_button_text: t1('show_answer'),
});

export default texts;
