import React from 'react';
import schema from './schema';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import TableResult from './TableResult';
import endpoints from 'components/bdtx/kpi/endpoints';
import { KPI_REPORT_TYPE_LEARNING_COMPLETION } from './schema';
import get from 'lodash.get';
import { Alert } from 'antd';

const SEARCH_FORM_ID = 'search-overview-by-organization';

const Search = () => {
  const renderGuide = (reportType) => {
    let guide;
    if (reportType == KPI_REPORT_TYPE_LEARNING_COMPLETION) {
      guide = (
        <Alert
          message="Tổng quan tiến độ học của học viên"
          description={
            <ul>
              <li>
                Tổng quan tiến độ học của học viên thể hiện tình trạng học tập
                của học viên trong đơn vị
              </li>
              <li>
                Ở bảng dữ liệu phía dưới, thể hiện Số học viên đã học hoàn thành
                trên tổng số học viên trong đơn vị
                <div>
                  Ví dụ: Cột CT 01 + 02, dữ liệu 123 / 456 nghĩa là: có 123 học
                  viên đã hoàn thành trên tổng 456 học viên trong đơn vị của
                  chương trình 01 + 02
                </div>
              </li>
            </ul>
          }
          type="info"
          showIcon
        />
      );
    } else {
      guide = (
        <Alert
          message="Tổng quan mức độ hoàn thành của việc lên kế hoạch"
          description={
            <ul>
              <li>
                Tổng quan mức độ hoàn thành của việc lên kế hoạch thể hiện tình
                trạng đã tổ chức kế hoạch đào tạo trong năm cho đơn vị
              </li>
              <li>
                Ở bảng dữ liệu phía dưới, thể hiện Số học viên đã được lên kế
                hoạch trong năm (tổng số học viên của các đơn vị được lên kế
                hoạch)
                <div>
                  Ví dụ: Cột CT 01 + 02, dữ liệu 123 / 456 nghĩa là: có 123 học
                  viên đã được lên kế hoạch đào tạotrên tổng 456 học viên trong
                  đơn vị của chương trình 01 + 02
                </div>
              </li>
            </ul>
          }
          type="info"
          showIcon
        />
      );
    }

    return (
      <>
        <h3>
          {reportType == KPI_REPORT_TYPE_LEARNING_COMPLETION
            ? 'Tỷ lệ học viên đã hoàn thành học tập'
            : 'Mức độ hoàn thành của việc lên kế hoạch'}
        </h3>

        {guide}
      </>
    );
  };

  const renderResultComponent = (items, props, objects, searchValues) => {
    const reportType = get(searchValues, 'report_type');

    return (
      <>
        <div className="m-b-15">{renderGuide(reportType)}</div>

        <TableResult items={items} />
      </>
    );
  };

  const renderNoResultComponent = () => {
    return <SimpleNoResult text="Chưa có thông tin" />;
  };

  return (
    <SearchWrapper
      schema={schema}
      formid={SEARCH_FORM_ID}
      alternativeApi={endpoints.bdtx_kpi_overview_deployment}
      renderResultsComponent={renderResultComponent}
      renderNoResultComponent={renderNoResultComponent}
      showSearchButton={false}
      autoSearchWhenStart
      autoSearchWhenValuesChange
      hidePagination
      enableDebounceTime
    />
  );
};

export default Search;
