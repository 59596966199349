import { editGroupPath } from 'components/admin/group/edit/route-registration';
import MeetGroupEditContainer from './group/EditContainer';
import Join from './join';
import { meet } from 'routes/root-url';
import MeetGroup from 'components/front-end/meet/group';

/**
 * normal routes that go to the meet layout
 * @returns {*[]}
 */
export const meetRoutesConfig = () => {
  return [
    {
      path: meet,
      component: MeetGroup,
      exact: true,
    },
    {
      path: editGroupPath('meet'),
      component: MeetGroupEditContainer,
      exact: true,
    },
    {
      componentId: 'join-one-meeting',
      path: '/meet/j/:iid',
      component: Join,
      exact: true,
    },
  ];
};
