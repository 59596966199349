import React from 'react';
import fetchData from 'components/common/fetchData';
import lodashGet from 'lodash.get';
import { t1 } from 'translate';
import Alert from 'antd/lib/alert';
import Portal, { portals } from 'components/common/portal';

const RedoExercise = ({
  numberOfTimesUserCanDoExercise,
  takeHistoryResult,
  onRedoButtonClick,
  isExerciseLoading = false,
  className = '',
}) => {
  // chỉ cho phép làm một lần
  if ([0, 1].includes(numberOfTimesUserCanDoExercise)) {
    return null;
  }

  const redoButton = (disabled) => (
    <button
      className={className}
      disabled={disabled || isExerciseLoading}
      onClick={onRedoButtonClick}
    >
      {t1('redo')}
    </button>
  );

  // nếu không setup, luôn được làm
  if (typeof numberOfTimesUserCanDoExercise === 'undefined') {
    return redoButton();
  }

  const currentTake = lodashGet(takeHistoryResult, 'currentTake');
  let histories = lodashGet(takeHistoryResult, 'histories', []);

  if (currentTake) {
    histories = [
      {
        ...currentTake,
        isCurrent: true,
      },
      ...histories,
    ];
  }

  const totalTimeUserDid = histories.length;
  const isMax = totalTimeUserDid >= numberOfTimesUserCanDoExercise;

  return (
    <>
      {redoButton(isMax)}

      <Portal id={portals.WARNING_NUMBER_OF_TIMES_DID_EXERCISE}>
        <div className="m-t-15">
          <Alert
            type="error"
            message={
              <>
                <span className="m-r-5">
                  {t1('number_of_times_you_have_did_exercise')}
                </span>
                <b>
                  {totalTimeUserDid}/{numberOfTimesUserCanDoExercise}
                </b>
              </>
            }
          />
        </div>
      </Portal>
    </>
  );
};

const fetchTakeHistories = fetchData(({ userIid, exerciseIid, courseIid }) => {
  return {
    baseUrl: 'take/api/histories',
    params: {
      user_iid: userIid,
      exercise_iid: exerciseIid,
      course_iid: courseIid,
    },
    propKey: 'takeHistoryResult',
  };
});

export default fetchTakeHistories(RedoExercise);
