import React from 'react';
import { t1 } from 'translate';
import DetailOnDialog from 'components/common/detail-on-dialog';
import DetailResultBdtx from 'components/bdtx/report/result-bdtx/details';

const ResultBdtxViewDetailUsers = ({
  organizations,
  caps,
  targetUsers,
  viewLearnType, // passed || failed
  label,
  previewClassName,
}) => {
  return (
    <DetailOnDialog
      renderPreview={({ showFull }) => {
        return (
          <span
            className={`${previewClassName || ''} cursor-pointer`}
            onClick={showFull}
          >
            {label || t1('view_details')}
          </span>
        );
      }}
      renderFull={({ closeDialog }) => {
        return (
          <DetailResultBdtx
            hiddenFields={{
              organizations: organizations,
              learn_types: [viewLearnType],
            }}
            defaultValues={{
              caps: caps,
              target_users: targetUsers,
            }}
          />
        );
      }}
      dialogOptionsProperties={{
        title: t1('view_result_bdtx_details_users'),
        modal: true,
      }}
    />
  );
};

export default ResultBdtxViewDetailUsers;
