import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import HtmlContent from 'components/common/html';
import get from 'lodash.get';
import sagaActions from 'actions/saga-creators';

import './style.scss';

const ScoStandard = ({ sco, courseIid, dispatch, isPreview, isReview }) => {
  useEffect(
    () => {
      const learnChildren = get(sco, 'children', []);
      const learnItemIid = get(sco, 'iid');

      if (isPreview || isReview || learnChildren.length) {
        return;
      }

      const progress = [
        {
          tco_iid: learnItemIid,
          p: 100,
        },
      ];

      const data = {
        progress,
        ciid: courseIid,
      };
      dispatch(sagaActions.trackerProgressSave(data));
    },
    [sco, isPreview, isReview, courseIid, dispatch],
  );

  const contentSco = get(sco, 'content', '');
  const nameSco = get(sco, 'name', '');
  const content = contentSco.replace(/<[^>]*>?/gm, '') && contentSco; //strip html

  return (
    <div className={`${content ? 'sco-standard--top' : ''} sco-standard`}>
      <div
        className={`sco-standard__title ${
          content ? 'sco-standard__title--left' : ''
        }`}
      >
        <HtmlContent content={nameSco} />
      </div>

      {content && (
        <div>
          <HtmlContent content={content} />
        </div>
      )}
    </div>
  );
};

ScoStandard.propTypes = {};
ScoStandard.defaultProps = {};

const mapStateToProps = (state, props) => {
  const learnItemIid = props.learnItemIid || state.learn.itemIid;
  const nodes = state.tree;
  const sco = nodes[learnItemIid];

  return {
    learnItemIid,
    sco,
    info: state.learn.info,
    isPreview: state.learn.isPreview,
    isReview: state.learn.isReview,
  };
};

export default connect(mapStateToProps)(ScoStandard);
