import courseUrls from 'components/admin/course/endpoints';
import apiUrls from 'api-endpoints';

export const getTeacherCourseAPIObject = (params = {}) => ({
  baseUrl: apiUrls.course_search,
  params: {
    page: 1,
    items_per_page: 100,
    learning_type: ['online', 'ilt'],
    status: ['approved'],
    public: 0,
    include_sub_organizations: 1,
    requireOrganization: 1,
    is_active: 1,
    _sand_expand: ['enrolment_plans'],
    ...params,
  },
});

export const getStudentCourseAPIObject = (params = {}) => ({
  baseUrl: courseUrls.my_active_courses,
  params: {
    ...params,
  },
});
