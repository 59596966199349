import React from 'react';
import BlockItem from './block';

import { isSmallScreen } from 'common';

const ColumnedItem = (props) => {
  const { idx, nodes, scoIid, ...remainProps } = props;

  const sco = nodes[scoIid];
  return (
    <BlockItem
      key={`spl-${scoIid}-${sco.children[idx]}`}
      iid={sco.children[idx]}
      piid={scoIid}
      nodes={nodes}
      {...remainProps}
    />
  );
};

const ScoSpl = (props) => {
  const { nodes, scoIid, ...remainProps } = props;

  const sco = nodes[scoIid];

  return (
    <div className="p-b-15 p-t-10">
      <div className="container-fluid">
        {sco.spl_columns == 2 && (
          <div className="row">
            <div className="col-md-6">
              <ColumnedItem idx={0} {...props} />
            </div>
            <div className="col-md-6">
              <ColumnedItem idx={1} {...props} />
            </div>
          </div>
        )}
        {sco.spl_columns == 3 && (
          <div className="row">
            <div className="col-md-4">
              <ColumnedItem idx={0} {...props} />
            </div>
            <div className="col-md-4">
              <ColumnedItem idx={1} {...props} />
            </div>
            <div className="col-md-4">
              <ColumnedItem idx={2} {...props} />
            </div>
          </div>
        )}
        {sco.spl_columns == 4 && (
          <div className="row">
            <div className="col-md-3">
              <ColumnedItem idx={0} {...props} />
            </div>
            <div className="col-md-3">
              <ColumnedItem idx={1} {...props} />
            </div>
            <div className="col-md-3">
              <ColumnedItem idx={2} {...props} />
            </div>
            <div className="col-md-3">
              <ColumnedItem idx={3} {...props} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScoSpl;
