import trim from 'lodash.trim';
import { getLearnItemInfoSelector } from 'common/learn';
import { createSelectorWithExtraParams } from 'utils/selector';
import { arraysConsistOfTheSameValues, sum } from 'common/utils/Array';
import { types as questionTypes } from 'components/admin/question/schema/question-types';
import lodashGet from 'lodash.get';
import { stripHTML } from '../utils/string';
import {
  isOEAdvancedLayoutType,
  isOEDigitalSupportPlanLayoutType,
} from 'components/common/forms/questions/open-ended/util';
import isEqual from 'lodash.isequal';

export const audioStatuses = {
  PLAYED: 'PLAYED',
  FINISHED: 'FINISHED',
};

export const inputId = (i, node, time) =>
  node && node.iid
    ? `${node.iid}-input-${i}-${time || 0}`
    : `input-${i}-${time || 0}`;

const getOptions = (e) => {
  let f = e;
  f = f.replace('[', '').replace(']', '');

  let options;
  if (f.indexOf('|') !== -1) {
    options = f.split('|');
  } else {
    options = f.split(',');
  }

  const beforeSorted = options.map((val, i) => ({
    text: trim(val),
    isAnswer: i === 0,
  }));

  // shuffle the options
  const getValue = (text) => {
    if (typeof text !== 'string') {
      return 0;
    }
    let total = 0;
    for (let i = 0; i < text.length; i += 1) {
      total += text.charCodeAt(i) * (i % 2 ? 1 : -1);
    }
    return total;
  };

  const compareFunction = (op, anotherOp) =>
    op && anotherOp && getValue(op.text) > getValue(anotherOp.text);

  return beforeSorted.sort(compareFunction);
};

export const parseInlineQuestionRawText = (content, node, time) => {
  // console.log(content);
  // Combine 2 regexes
  // [a,b,c,d] or __input__
  const regex = /(\[[^\[]*\]|__[^_]*__)/g;
  const matches = content.match(regex);
  if (!matches) {
    return [content];
  }

  let rawQuestion = '';
  const questionAfterSplitByRegex = content.split(regex);
  const correctAnswers = [];
  const correctAnswersAsArray = [];
  let f;

  let i = -1;
  questionAfterSplitByRegex.forEach((e) => {
    if (e && e.match(regex)) {
      if (e.charAt(0) === '[') {
        // [a,b,c,d]
        const options = getOptions(e);

        correctAnswers.push({
          type: 'select',
          answer: options,
        });

        correctAnswersAsArray.push(
          options
            .filter((answer) => answer.isAnswer)
            .map((filtered) => filtered.text),
        );
      } else {
        // __input__
        f = trim(e)
          .replace('__', '')
          .replace('__', '');

        if (f.indexOf('/') !== -1) {
          f = f.split('/');
        } else {
          f = [f];
        }

        correctAnswers.push({
          type: 'input',
          answer: f,
        });

        correctAnswersAsArray.push(f);
      }
      i += 1;
      rawQuestion += `<span id='${inputId(i, node, time)}'></span>`;
    } else {
      rawQuestion += e;
    }
  });

  return {
    content,
    correctAnswers,
    correctAnswersAsArray,
    rawQuestion,
  };
};

export const isIntro = (question) =>
  !!(
    question &&
    question.ntype === 'question' &&
    question.type === questionTypes.TYPE_INTRODUCTION
  );

export const isIntroSticky = (question) =>
  !!(isIntro(question) && question.intro_sticky);

export const groupQuestions = (questions) => {
  if (!questions) return [];
  return questions.reduce(
    ({ groups, lastGroupId }, question) => {
      if (lastGroupId === null) {
        return {
          groups: [[question]],
          lastGroupId: question.group,
        };
      }
      if (lastGroupId === question.group) {
        const previousGroups = groups.slice(0, groups.length - 1);
        const lastGroup = groups[groups.length - 1];
        return {
          groups: previousGroups.concat([lastGroup.concat([question])]),
          lastGroupId,
        };
      }
      return {
        groups: groups.concat([[question]]),
        lastGroupId: question.group,
      };
    },
    { groups: [], lastGroupId: null },
  ).groups;
};

export const getUserAnswersByQuestionUniqueIdSelector = createSelectorWithExtraParams(
  (state) => state.learn.info,
  2,
  (info) => (itemIid, questionUniqueId) =>
    info &&
    info[itemIid] &&
    info[itemIid].questions &&
    info[itemIid].questions[questionUniqueId] &&
    info[itemIid].questions[questionUniqueId].answer,
);

export const getAudiosInfoByQuestionUniqueIdSelector = createSelectorWithExtraParams(
  (state) => state.learn.info,
  2,
  (info) => (itemIid, questionUniqueId) =>
    info &&
    info[itemIid] &&
    info[itemIid].questions &&
    info[itemIid].questions[questionUniqueId] &&
    info[itemIid].questions[questionUniqueId].audiosInfo,
);

export const getShouldDisplayCheckedResultSelector = createSelectorWithExtraParams(
  (state) => state.learn.info,
  1,
  (info) => (itemIid) =>
    info &&
    info[itemIid] &&
    info[itemIid].questions &&
    Object.keys(info[itemIid].questions).reduce((result, key) => {
      const value = info[itemIid].questions[key];
      if (value && value.shouldDisplayCheckedResult) {
        return result.concat([key]);
      }
      return result;
    }, []),
);

export const getUserAnswersSelector = createSelectorWithExtraParams(
  (state) => state.learn.itemIid,
  getLearnItemInfoSelector,
  1,
  (learnItemIid, getLearnItemInfo) => (itemIid, questionUniqueIds) => {
    const localItemIid = itemIid || learnItemIid;
    const learnItemInfo = getLearnItemInfo(localItemIid);
    return (
      (learnItemInfo &&
        learnItemInfo.questions &&
        Object.keys(learnItemInfo.questions).reduce((result, key) => {
          if (
            Array.isArray(questionUniqueIds) &&
            !questionUniqueIds.includes(key)
          ) {
            return result;
          }
          const value = learnItemInfo.questions[key];
          return { ...result, [key]: value && value.answer };
        }, {})) ||
      {}
    );
  },
);

export const getQuestionPositionInExercise = (
  exercise,
  questionUniqueId,
  getQuestionUniqueId = (q) => q && q.uniqueId,
) => {
  const defaultResult = {
    groupIndex: -1,
    questionIndex: -1,
  };

  if (!exercise || !questionUniqueId) return defaultResult;

  let questionIndex = -1;

  const questionGroups =
    Array.isArray(exercise.children) && groupQuestions(exercise.children);

  if (!Array.isArray(questionGroups)) return defaultResult;

  const groupIndex = questionGroups.findIndex((group) => {
    questionIndex =
      Array.isArray(group) &&
      group.findIndex(
        (questionInGroup) =>
          questionInGroup &&
          getQuestionUniqueId(questionInGroup) === questionUniqueId,
      );
    return questionIndex !== -1;
  });

  return {
    groupIndex,
    questionIndex,
  };
};

export const getQuestionPositionInExercises = (
  exercises,
  questionUniqueId,
  getQuestionUniqueId,
) => {
  const defaultResult = {
    exerciseIndex: -1,
    groupIndex: -1,
    questionIndex: -1,
  };

  if (!questionUniqueId || !Array.isArray(exercises)) return defaultResult;

  let groupIndex = -1;
  let questionIndex = -1;

  const exerciseIndex = exercises.findIndex((exercise) => {
    const positionInExercise = getQuestionPositionInExercise(
      exercise,
      questionUniqueId,
      getQuestionUniqueId,
    );
    groupIndex = positionInExercise.groupIndex;
    questionIndex = positionInExercise.questionIndex;
    return groupIndex !== -1 && questionIndex !== -1;
  });

  return {
    exerciseIndex,
    groupIndex,
    questionIndex,
  };
};

export const getQuestionFromPositionInExercise = (
  exercise,
  groupIndex,
  questionIndex,
) => {
  if (!exercise || !Array.isArray(exercise.children)) {
    return {};
  }
  const questions = exercise.children;
  const questionGroups = groupQuestions(questions);
  const questionsToDisplay = questionGroups[groupIndex] || [];
  const questionToDisplay = questionsToDisplay[questionIndex];
  return {
    questions: questionsToDisplay,
    question: questionToDisplay,
  };
};

export const getQuestionFromPositionInExercises = (
  exercises,
  exerciseIndex,
  groupIndex,
  questionIndex,
) => {
  if (!Array.isArray(exercises)) {
    return {};
  }
  const exercise = exercises[exerciseIndex];
  return getQuestionFromPositionInExercise(exercise, groupIndex, questionIndex);
};

const getResultForAverageScoreScheme = (questions, answers) => {
  let scoreAndWeightOfQuestions = (questions || []).map((q) => {
    const qId = lodashGet(q, 'id');
    const ans = lodashGet(answers, qId);

    let score = lodashGet(ans, 'score') || 0;
    const weighted = lodashGet(q, 'weighted');

    if (lodashGet(q, 'type') != questionTypes.TYPE_OPEN_ENDED) {
      score = score * 100;
    }

    return {
      score,
      weighted,
    };
  });

  if (scoreAndWeightOfQuestions.every((elem) => !lodashGet(elem, 'weighted'))) {
    scoreAndWeightOfQuestions = scoreAndWeightOfQuestions.map((elem) => ({
      ...elem,
      weighted: 1,
    }));
  }

  const result =
    sum(scoreAndWeightOfQuestions.map((elem) => elem.score * elem.weighted)) /
    sum(scoreAndWeightOfQuestions.map((elem) => elem.weighted));

  return !isNaN(result) ? Math.round(result) : 0;
};

export const calculateResultForQuestions = (questions, answers) =>
  getResultForAverageScoreScheme(questions, answers);

export const getQuestionAudios = (question) => {
  if (!question) {
    return [];
  }
  if (Array.isArray(question.audio)) {
    return question.audio.map((audio) => audio.path);
  }
  if (question.audio) {
    return [question.audio];
  }
  return [];
};

export const isQuestionHaveContent = (
  question,
  typesOfContentToNotIncluded,
) => {
  const haveAudios = getQuestionAudios(question).length > 0;
  const haveVideo = question.vid;
  const haveAvatar = question.avatar;
  const haveQuestionBody =
    question.type !== questionTypes.TYPE_INLINE && question.content;

  const criteria = {
    audio: haveAudios,
    video: haveVideo,
    avatar: haveAvatar,
    questionBody: haveQuestionBody,
  };

  if (Array.isArray(typesOfContentToNotIncluded)) {
    typesOfContentToNotIncluded.forEach((criterion) => {
      delete criteria[criterion];
    });
  }

  return Object.values(criteria).some((value) => !!value);
};

export const doesQuestionAverageScoreMeaningful = (question) => {
  return (
    question && String(question.type) === String(questionTypes.TYPE_NUMBER)
  );
};

export const isNotOEExercise = (exercise) => {
  const children = lodashGet(exercise, 'children', []);
  // with empty exercise, children will be return false instead of array.
  const listQuestionNotTypeOE = (children || [])
    .map((item) => !isOEQuestion(lodashGet(item, 'type')) && item)
    .filter(Boolean);

  return Array.isArray(listQuestionNotTypeOE) && listQuestionNotTypeOE.length;
};

export const isOEQuestion = (question) => {
  let questionType = question;
  if (typeof question === 'object') {
    questionType = lodashGet(question, 'type');
  }

  return [questionTypes.TYPE_OPEN_ENDED].includes(questionType);
};

export const isEmptyAnswerFormValue = (formValues) => {
  let content = stripHTML(lodashGet(formValues, 'content'));

  return (
    !formValues ||
    (!formValues.youtube && !content && !(formValues.attachments || []).length)
  );
};

export const isQuestionFormValid = (
  userAnswers = {},
  formValues,
  score,
  isSurvey = false,
  multipleContentItems = false,
) => {
  if ([null, undefined, ''].includes(score) || isSurvey) {
    return true;
  }

  if (!multipleContentItems) {
    return !isEmptyAnswerFormValue(formValues);
  }

  const { content = [] } = userAnswers;
  const contentIsArray = Array.isArray(content) && content.length;

  const formatData = !contentIsArray
    ? [formValues]
    : content.map((answer) => ({
        ...answer,
        ...(answer.id === formValues.id ? formValues : {}),
      }));

  const isError = formatData.every((data) => isEmptyAnswerFormValue(data));
  return !isError;
};

export const isQuestionMarked = (question) => {
  const score = lodashGet(question, 'score');

  return (
    typeof score === 'number' ||
    (typeof score === 'string' &&
      score.trim() === score &&
      score.length > 0 &&
      !isNaN(score))
  );
};

export const isSubmissionTool = (exercise) => {
  const firstQuestion = lodashGet(exercise, 'children[0]');
  return isSubmissionToolQuestion(firstQuestion);
};

export const isSubmissionToolQuestion = (question = {}) => {
  return isOEAdvancedLayoutType(question);
};

export const canFeedbackOnMarkedQuestion = (question) => {
  return lodashGet(question, 'can_feedback_on_marked_question');
};

export const isMarkingPassFail = (exercise) => {
  return !!lodashGet(exercise, 'children[0].marking_pass_or_fail');
};

export const waitForMarking = (score) => {
  return [null, undefined].includes(score);
};

export const displayAsMarkingPassFailMode = (exercise) => {
  const isNoMarking = Boolean(lodashGet(exercise, 'no_marking'));
  const isPractice = Boolean(lodashGet(exercise, 'practice'));
  const includeScore = !isNoMarking && !isPractice;

  const allAreOEQuestions = !isNotOEExercise(exercise);
  const markingPassFail = isMarkingPassFail(exercise);

  return allAreOEQuestions && includeScore && markingPassFail;
};

export const shouldHideScore = (exercise) => {
  const isNoMarking = Boolean(lodashGet(exercise, 'no_marking'));
  const isPractice = Boolean(lodashGet(exercise, 'practice'));

  return isNoMarking || isPractice;
};

export const isFormValuesDifferenceUserAnswers = (
  userAnswers,
  formValues,
  oeQuestion,
) => {
  let content = lodashGet(userAnswers, 'content', '');
  let contentForms = lodashGet(formValues, 'content', '');

  const isObjectContent =
    typeof content === 'object' && typeof contentForms === 'object';
  const isArrayContent = Array.isArray(content) && Array.isArray(contentForms);
  const isHtmlContent = !isArrayContent && !isObjectContent;

  if (isHtmlContent) {
    content = stripHTML(content);
    contentForms = stripHTML(contentForms);
  }

  if (isObjectContent) {
    content = JSON.stringify(content);
    contentForms = JSON.stringify(contentForms);
  }

  const attachments = lodashGet(userAnswers, 'attachments', []);
  const attachmentsForm = lodashGet(formValues, 'attachments', []);

  const youtube = lodashGet(userAnswers, 'youtube');
  const youtubeForm = lodashGet(formValues, 'youtube');

  if (isOEDigitalSupportPlanLayoutType(oeQuestion)) {
    return !isEqual(content, contentForms);
  }

  return (
    !isEqual(content, contentForms) ||
    !isEqual(attachments, attachmentsForm) ||
    !isEqual(youtube, youtubeForm)
  );
};

export const hasUserAnswer = (userAnswers) => {
  let content = lodashGet(userAnswers, 'content', '');
  const attachments = lodashGet(userAnswers, 'attachments', []);
  const youtube = lodashGet(userAnswers, 'youtube');

  return content || (attachments && attachments.length) || youtube;
};

export const isUserAnswerAttachmentsDifferent = (userAnswers, formValues) => {
  const content = lodashGet(userAnswers, 'content');
  const formContent = lodashGet(formValues, 'content');

  let attachments = lodashGet(userAnswers, 'attachments', []);
  let formAttachments = lodashGet(formValues, 'attachments', []);

  if (Array.isArray(content) && Array.isArray(formContent)) {
    attachments = content.reduce((val, cur, index) => {
      return cur.attachments ? val.concat(cur.attachments) : val;
    }, []);

    formAttachments = formContent.reduce((val, cur, index) => {
      return cur.attachments ? val.concat(cur.attachments) : val;
    }, []);
  }

  const attachmentIds = attachments.map((att) => lodashGet(att, 'id'));
  const formAttachmentIds = formAttachments.map((att) => lodashGet(att, 'id'));

  return !arraysConsistOfTheSameValues(attachmentIds, formAttachmentIds);
};

export const isQuestionShufflable = (question) => {
  return lodashGet(question, 'shufflable');
};
