/**
 * Created by hungvo on 19/04/2017.
 */
import React from 'react';
import { connect } from 'react-redux';
import { t1 } from 'translate/index';
import commonSagaActions from 'actions/saga-creators';
import Button from 'components/common/primary-button';

class ExportBtnWithConfirmDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      executing: false,
    };
  }

  handleOnRequestSuccessful = (onRequestSuccessful) => {
    this.setState({ executing: false });

    if (typeof onRequestSuccessful === 'function') {
      onRequestSuccessful();
    }
  };

  handleAction = (onRequestSuccessful) => {
    this.setState({ executing: true });

    const { dispatch, url, params, formid, fileName, getParams } = this.props;

    let dynamicParams = {};
    if (typeof getParams === 'function') {
      dynamicParams = getParams();
    }

    dispatch(
      commonSagaActions.exportDataRequest({
        url,
        params: {
          ...(params || {}),
          ...(dynamicParams || {}),
        },
        formid,
        onRequestSuccessful: () => {
          this.handleOnRequestSuccessful(onRequestSuccessful);
        },
        fileName,
      }),
    );
  };

  render() {
    return (
      <Button
        {...this.props}
        title={this.props.title || t1('export')}
        icon={this.props.icon || 'export'}
        disabled={this.state.executing || this.props.isDisabled}
        onClick={() => {
          this.handleAction(this.props.onRequestSuccessful);
        }}
      />
    );
  }
}

export default connect()(ExportBtnWithConfirmDialog);
