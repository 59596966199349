import React from 'react';
import get from 'lodash.get';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import schema from './schema';
import endpoints from 'components/bdtx/choose-modules/endpoints';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import TableResult from './TableResult';
import withUserInfo from 'common/hoc/withUserInfo';

const SEARCH_FORM_ID = 'search-overview-chosen-module';

const OverviewChosenModule = ({
  userInfo,
  hiddenFields = {},
  showSearchForm,
  showAction,
}) => {
  const renderResultComponent = (items, props, objects, searchValues) => {
    const schoolYear = get(searchValues, 'school_year');

    return (
      <div className="white-background">
        {items.map((item, index) => {
          const chuongTrinh = get(item, 'chuong_trinh');
          const modules = get(item, 'modules', []);

          return (
            <>
              <TableResult
                chuongTrinh={chuongTrinh}
                modules={modules}
                schoolYear={schoolYear}
                searchFormId={SEARCH_FORM_ID}
                showAction={showAction}
              />
              {index + 1 < items.length ? <hr /> : null}
            </>
          );
        })}
      </div>
    );
  };

  const renderNoResultComponent = () => {
    return <SimpleNoResult text="Không có dữ liệu" />;
  };

  return (
    <SearchWrapper
      schema={showSearchForm ? schema : {}}
      formid={SEARCH_FORM_ID}
      alternativeApi={endpoints.bdtx_get_overview_chosen_module}
      renderResultsComponent={renderResultComponent}
      renderNoResultComponent={renderNoResultComponent}
      hiddenFields={hiddenFields}
      autoSearchWhenStart
      showSearchButton={false}
      autoSearchWhenValuesChange
      userInfo={userInfo}
    />
  );
};

export default withUserInfo(OverviewChosenModule);
