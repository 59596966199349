//Move this to action later
//Action constant define
export const INIT_EXAM_PRACTICE = 'INIT_EXAM_PRACTICE';
export const START_EXAM_PRACTICE = 'START_EXAM_PRACTICE';
export const FINISH_EXAM_PRACTICE = 'FINISH_EXAM_PRACTICE';
export const LOAD_QUESTION_CONTENT_EXAM_PRACTICE =
  'LOAD_QUESTION_CONTENT_EXAM_PRACTICE';
export const SAVE_ANSWER_EXAM_PRACTICE = 'SAVE_ANSWER_EXAM_PRACTICE';
export const CHANGE_QUESTION_EXAM_PRACTICE = 'CHANGE_QUESTION_EXAM_PRACTICE';
export const UPDATE_EXAM_PRACTICE_TICK = 'UPDATE_EXAM_PRACTICE_TICK';
export const SAVE_EXAM_PRACTICE_TO_LOCAL_STORAGE =
  'SAVE_EXAM_PRACTICE_TO_LOCAL_STORAGE';
export const LOAD_EXAM_PRACTICE_FROM_LOCAL_STORAGE =
  'LOAD_EXAM_PRACTICE_FROM_LOCAL_STORAGE';
export const PREVIEW_EXAM_PRACTICE = 'PREVIEW_EXAM_PRACTICE';
export const GET_TIME_PRACTICE_IN_LOCAL_STORAGE =
  'GET_TIME_PRACTICE_IN_LOCAL_STORAGE';
export const SHOW_ANSWER_AND_LOCK_QUESTION = 'SHOW_ANSWER_AND_LOCK_QUESTION';
export const REMOVE_TAKE_EXAM_PRACTICE = 'REMOVE_TAKE_EXAM_PRACTICE';
export const LOAD_EXAM_PRACTICE_TAKES_FROM_LOCAL_STORAGE =
  'LOAD_EXAM_PRACTICE_TAKES_FROM_LOCAL_STORAGE';
export const REVIEW_TAKE_QUESTION = 'REVIEW_TAKE_QUESTION';
export const REVIEW_ALL_TAKE = 'REVIEW_ALL_TAKE';
export const STOP_REVIEW_TAKE = 'STOP_REVIEW_TAKE';
export const VIEW_HISTORY = 'VIEW_HISTORY';
//Create Actions List for redux (only for storing practice test -> not gonna have any interact with server others than download missing question content)

//Save paper Item into Store practiceExam.paper and store examRule to practiceExam.rules
//User can save this paper to localstorage for continue practice
export const initExamPractice = (paper, examRule) => ({
  type: INIT_EXAM_PRACTICE,
  paper,
  examRule,
});

//Creating take at practiceExam.take -> create id, iid, answerLog, timestart, timepassed
//this action also create exam_practice_tick = timepassed in tick (second)
//if user saved take into local storage for later load -> user can open it to continue
export const startExamPractice = (takeObject) => ({
  type: START_EXAM_PRACTICE,
  takeObject,
});

//Added status finished to take -> proceed to mark the take -> giving result
export const finishExamPractice = (
  contestantIid,
  contestCode,
  isPublic,
  syllabusIid,
) => ({
  type: FINISH_EXAM_PRACTICE,
  contestantIid,
  contestCode,
  isPublic,
  syllabusIid,
});

//Download Question Content and update it to paper -> rerender paper => this kind of action giving a token for security protection
//this token randomly generated
export const loadQuestionContentExamPractice = (questionId, token) => ({
  type: LOAD_QUESTION_CONTENT_EXAM_PRACTICE,
  questionId,
  token,
});

//saved answer of question to practiceExam.take[0].answer
//this also create a log in practiceExam.take[0].answer_log
//this action also update time tick in the take
export const saveAnswerExamPractice = (answerObject) => ({
  type: SAVE_ANSWER_EXAM_PRACTICE,
  answerObject,
});

//action change question
export const changeQuestionExamPractice = (questionId) => ({
  type: CHANGE_QUESTION_EXAM_PRACTICE,
  questionId,
});

//this action will update tick to take, this action will likely be run after a period of time 10sec or more in order to keep up take with current time
//will trigger 1 time when time reached the end in exam rules
export const updateExamPracticeTick = (tickAdded) => ({
  type: UPDATE_EXAM_PRACTICE_TICK,
  tickAdded,
});

//this action will save paper and take to local storage with identification of the practice time
export const saveEXamPracticeToLocalStorage = (
  contestCode,
  contestantIid,
  timePractice,
  subjectIid,
) => ({
  type: SAVE_EXAM_PRACTICE_TO_LOCAL_STORAGE,
  contestCode,
  contestantIid,
  timePractice, //time that user practice this contest
  subjectIid,
});

//this action will load paper and take from local storage to redux store with identification of the practice time
export const loadExamPracticeOngoingFromLocalStorage = (
  contestCode,
  contestantIid,
  timePractice,
  subjectIid,
) => ({
  type: LOAD_EXAM_PRACTICE_FROM_LOCAL_STORAGE,
  contestCode,
  contestantIid,
  timePractice,
  subjectIid,
});

//this action will lock answer of the take -> proceed to show feedback and let user know the correct answer
export const showAnswerAndLockQuestion = (questionId) => ({
  type: SHOW_ANSWER_AND_LOCK_QUESTION,
  questionId,
});

//this action will load paper and take from local storage to redux store with identification of the practice time
export const getTimePracticeInLocalStorage = (
  contestCode,
  contestantIid,
  subjectIid,
) => ({
  type: GET_TIME_PRACTICE_IN_LOCAL_STORAGE,
  contestCode,
  contestantIid,
  subjectIid,
});

//this action will remove take from state
export const removeTakeExamPractice = () => ({
  type: REMOVE_TAKE_EXAM_PRACTICE,
});

export const loadExamPracticeTakesFromLocalStorage = (
  contestantIid,
  contestCode,
  isPublic,
  syllabusIid,
) => ({
  type: LOAD_EXAM_PRACTICE_TAKES_FROM_LOCAL_STORAGE,
  contestantIid,
  contestCode,
  isPublic,
  syllabusIid,
});

export const reviewTakeQuestion = (questionId) => ({
  type: REVIEW_TAKE_QUESTION,
  questionId,
});

export const reviewTake = () => ({
  type: REVIEW_ALL_TAKE,
});

export const stopReviewTake = () => ({
  type: STOP_REVIEW_TAKE,
});

export const viewHistory = (historyKey) => ({
  type: VIEW_HISTORY,
  historyKey,
});
