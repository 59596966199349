import React, { Fragment } from 'react';
import lodashGet from 'lodash.get';
import { t1 } from 'translate';
import { getDurationSinceElapsed } from 'common/utils/Date';
import AssignToMaster from '../assign-to-master';
import {
  getOneGVCCAssignedPrioritizedWhoReplied,
  getOneMasterAssignedPrioritizedWhoReplied,
} from '../helper';

const renderSpacing = (
  <span className="render-spacing" style={{ 'margin-right': '5px' }}>
    &#59;
  </span>
);

const getTimeElapsed = (from, to) => {
  return lodashGet(from, 'ts') - lodashGet(to, 'ts');
};

const TeacherReplyStatus = ({
  replies = [],
  teachers_answer = [],
  comment,
  isMaster,
  isGVCC,
  searchFormCommentsId,
  userInfo,
  viewOnly,
}) => {
  const {
    masterAssigned,
    masterReplied,
  } = getOneMasterAssignedPrioritizedWhoReplied(teachers_answer, replies);

  const { gvccAssigned, gvccReplied } = getOneGVCCAssignedPrioritizedWhoReplied(
    teachers_answer,
    replies,
  );

  const myElapsed = getTimeElapsed(gvccReplied, gvccAssigned);
  const masterElapsed = getTimeElapsed(masterReplied, masterAssigned);

  let teacherItem = gvccReplied ? (
    <span>
      {t1('teacher_replied_%s', [getDurationSinceElapsed(myElapsed * 1000)])}
    </span>
  ) : (
    <span>{t1('teacher_not_reply')}</span>
  );

  const masterItem = masterAssigned ? (
    masterReplied ? (
      <span>
        {t1('master_replied_%s', [
          getDurationSinceElapsed(masterElapsed * 1000),
        ])}
      </span>
    ) : (
      <span>{t1('waiting_master_reply')}</span>
    )
  ) : !isMaster && isGVCC ? (
    <AssignToMaster
      isMaster={isMaster}
      comment={comment}
      searchFormCommentsId={searchFormCommentsId}
      viewOnly={viewOnly}
    />
  ) : null;

  if (!gvccReplied && masterAssigned) {
    return masterItem;
  }

  return (
    <Fragment>
      {masterItem}

      {masterItem ? renderSpacing : null}

      {teacherItem}
    </Fragment>
  );
};

export default TeacherReplyStatus;
