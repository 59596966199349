import { teacherUrl, adminRootUrl } from 'routes/root-url';
import { peerTakeType } from 'components/common/forms/questions/open-ended/constants';
import { stringify } from 'query-string';

export const teacherMenuItems = {
  COURSE: 'course',
  DASHBOARD: 'dashboard',
  MARKING_OPEN_ENDED_QUESTIONS: 'score/marking-open-ended-questions',
  MARKING_OPEN_ENDED_QUESTIONS_FILTER:
    'score/marking-open-ended-questions/filter',
  QA: 'qa',
  BY_RUBRIC: 'score/by-rubric',
  SESSION_MANAGER: 'session-manager',
  FEEDBACK: 'feedback',
  REPORT: 'report',
  LIST_GVCC: 'list-gvcc',
  LIST_GVSP: 'list-gvsp',
  SUPPORT_PLAN: 'ke-hoach-ho-tro',
  RESULT_PLAN: 'ket-qua-ke-hoach-ho-tro',
  APPROVE_SUPPORT_PLAN: 'duyet-ke-hoach-ho-tro',
  APPROVE_RESULT_PLAN: 'duyet-ket-qua-ho-tro',
  OUTCOME_SUPPORT_PLAN: 'ketqua-khht',
  OUTCOME_RESULT_PLAN: 'ketqua-baocao-khht',
  TIMELINE_SUPPORT_PLAN: 'timeline-khht',
  QUIZ: 'quiz',
  STUDENTS: 'students',
  SUPPORT_EVIDENCE: 'support-evidence',
};

export const getTeacherMenuById = (id) => {
  return `${teacherUrl}/${id}`;
};

export const getSubmitSupportPlanUrl = ({ module } = {}) => {
  const queryStr = stringify({
    module,
  });

  return `${getTeacherMenuById(teacherMenuItems.SUPPORT_PLAN)}${
    queryStr ? `?${queryStr}` : ''
  }`;
};

export const getSubmitSupportPlanResultUrl = ({ school_year } = {}) => {
  const queryStr = stringify({
    school_year,
  });

  return `${getTeacherMenuById(teacherMenuItems.RESULT_PLAN)}${
    queryStr ? `?${queryStr}` : ''
  }`;
};

export const getTeacherCoursesUrl = ({ module } = {}) => {
  const queryStr = stringify({
    module,
  });

  return `${getTeacherMenuById(teacherMenuItems.COURSE)}${
    queryStr ? `?${queryStr}` : ''
  }`;
};

export const getCourseDetailLink = (id, menu) => {
  return `${teacherUrl}/${teacherMenuItems.COURSE}/${id}/${menu}`;
};

export const getAdminPeerTakeRootRouter = (id) => {
  return `${adminRootUrl}/course/${id}/${
    teacherMenuItems.MARKING_OPEN_ENDED_QUESTIONS
  }`;
};

export const getAdminPeerTakeFilterRouter = (id) => {
  return `${getAdminPeerTakeRootRouter(id)}/${peerTakeType.WITH_FILTER}`;
};

export const getTeacherPeerTakeRootRouter = () => {
  return `${teacherUrl}/${teacherMenuItems.MARKING_OPEN_ENDED_QUESTIONS}`;
};

export const getTeacherPeerTakeFilterRouter = () => {
  return `${getTeacherPeerTakeRootRouter()}/${peerTakeType.WITH_FILTER}`;
};

export const getCourseDashboardPeerTakeRootRouter = (id) => {
  return `${getCourseDetailLink(id, 'score/marking-open-ended-questions')}`;
};

export const getCourseDashboardPeerTakeFilterRouter = (id) => {
  const root = getCourseDashboardPeerTakeRootRouter(id);

  return `${root}/${peerTakeType.WITH_FILTER}`;
};
