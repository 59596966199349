import {
  saveTakeRequest,
  SAVE_MULTIPLE_TAKE_REQUEST,
} from 'actions/learn/saga-creators';
import commonSagaActions from 'actions/saga-creators';
import { put, takeEvery } from 'redux-saga/effects';

function* saveMultipleTakesToServer(action) {
  // itemIid: exam iid or exercise iid

  const {
    exerciseInfo,
    listQuestionAnswer,
    options = {},
    displayMessageAfterSave,
  } = action;

  if (!listQuestionAnswer.length) return;

  const answers = listQuestionAnswer.reduce((acc, cur, index) => {
    return { ...acc, [cur.iid]: { ...cur, is_correct: cur.isCorrect } };
  }, {});
  const params = {
    answers,
    course_iid: exerciseInfo.course_iid,
    exam_finished: 1,
    exam: {
      description: exerciseInfo.description,
      duration: exerciseInfo.duration,
      exam_type: exerciseInfo.exam_type,
      id: exerciseInfo.id,
      iid: exerciseInfo.iid,
      options: exerciseInfo.options,
      type: exerciseInfo.type,
    },
  };

  const saveProgressParams = {
    ciid: exerciseInfo.course_iid,
    doing_status: 'finished',
    progress: [
      ...listQuestionAnswer.map((v) => {
        return {
          tco_iid: v.iid,
          p: v.percent * 100,
        };
      }),
      {
        tco_iid: exerciseInfo.iid,
        time_spent: 0,
        cp: 100,
      },
    ],
  };

  yield put(
    saveTakeRequest(exerciseInfo.iid, params, displayMessageAfterSave, options),
  );
  yield put(commonSagaActions.trackerProgressSave(saveProgressParams, true));
}

export default function* saveTakeAll() {
  yield takeEvery(SAVE_MULTIPLE_TAKE_REQUEST, saveMultipleTakesToServer);
}
