import React from 'react';
import { getUrl } from 'routes/links/common';
import PreviewExam from './PreviewExam';
import lGet from 'lodash.get';

export default (props) => {
  return [
    {
      component: <PreviewExam template={lGet(props, 'params.iid', 0)} />,
      href: getUrl('/review-exam-store'),
      type: 'modal',
      floatRight: true,
      icon: 'plus',
    },
  ];
};
