import React from 'react';
import DownloadMobileApp from 'components/common/download-mobile-app';
import { t1 } from 'translate';
import Warning from 'components/common/Warning';

const OnlyOnMobileQuestion = () => {
  return (
    <DownloadMobileApp
      title={
        <Warning>
          {t1(
            'this_question_is_not_supported_in_the_website,_you_need_to_download_the_mobile_app',
          )}
        </Warning>
      }
    />
  );
};

export default OnlyOnMobileQuestion;
