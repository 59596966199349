import React from 'react';
import get from 'lodash.get';
import { isEnoughCredit, getTotalCredit } from 'components/bdtx/utils';

const Layout = (props) => {
  const { layoutOptionsProperties, formValues, groups } = props;

  const modules = get(layoutOptionsProperties, 'modules', []);
  const modulesFormValue = get(formValues, 'modules', []);
  const fieldNames = get(groups, 'default.fieldNames');

  const modulesChosen = modules.filter((module) =>
    modulesFormValue.includes(module.iid),
  );

  const totalCredit = getTotalCredit(modulesChosen);

  return (
    <>
      <div className="total-credit font-size-small">
        <span
          className={`text-bold ${
            isEnoughCredit(totalCredit) ? 'text-primary' : 'text-danger'
          }`}
        >
          Tổng: {totalCredit} (tiết)
        </span>
      </div>
      <div>{fieldNames.modules}</div>
    </>
  );
};

export default Layout;
