import { put, takeEvery } from 'redux-saga/effects';
import {
  clearSystemMessage,
  loadSystemMessageError,
  loadSystemMessageSuccess,
} from 'actions/app-info';
import Requester from 'common/network/http/Request';
import apiUrls from 'api-endpoints/index';
import { SYSTEM_MESSAGE_LOADING } from 'actions/app-info/types';

function* loadSystemMessage() {
  try {
    const systemMessageResponse = yield Requester.get(apiUrls.systemMessage);

    if (systemMessageResponse && systemMessageResponse.success) {
      if (systemMessageResponse.result && systemMessageResponse.result) {
        yield put(loadSystemMessageSuccess(systemMessageResponse.result));
      } else {
        yield put(clearSystemMessage());
      }
    } else {
      yield put(
        loadSystemMessageError(new Error('Could not load system message')),
      );
    }
  } catch (error) {
    yield put(loadSystemMessageError(error));
  }
}

export default function* systemMessageSaga() {
  yield takeEvery(SYSTEM_MESSAGE_LOADING, loadSystemMessage);
}
