import React from 'react';
import get from 'lodash.get';
import { t1 } from 'translate';
import Avatar from 'components/common/avatar/index';
import Comment from 'antd/lib/comment';
import { required, restrictionWords } from 'common/validators';
import { uppercaseVietnamese } from 'common/utils/string/vn';
import apiUrls from 'components/admin/course/endpoints';
import Tooltip from 'antd/lib/tooltip';
import Icon from 'antd/lib/icon';

const schema = (formid, values, step, xpath, props) => {
  return {
    content: {
      type: 'text',
      multiLine: true,
      rows: 2,
      hintText: props.userToReply
        ? `${t1('reply')} ${props.userToReply}`
        : t1('enter_comment'),
      fullWidth: true,
      validate: [restrictionWords(t1('comment_has_restriction_words'))],
    },
    comment_for_course: {
      type: 'checkbox',
      label: t1('comment_for_course'),
      defaultValue: 0,
    },
    course_iid_to_comment: {
      type: 'select',
      floatingLabelText: t1('course'),
      fullWidth: true,
      options: 'async',
      validate: [required()],
      showSearch: true,
      optionFilterProp: 'children',
      filterOption: (input, option) =>
        uppercaseVietnamese(option.props.children.toLowerCase()).indexOf(
          uppercaseVietnamese(input.toLowerCase()),
        ) >= 0,
      populateValue: true,
      paramsasync: {
        __url__: apiUrls.my_active_courses,
        transformData: (data) => {
          if (!Array.isArray(data) || !data.length) {
            return [];
          }

          return data.map((row) => ({
            value: row.iid,
            primaryText: get(row, 'name'),
          }));
        },
      },
    },
  };
};

const ui = (
  step,
  values,
  themeConfig,
  xpath,
  formid,
  { selectCourse, showCheckboxToCommentForCourse },
) => {
  return [
    {
      id: 'default',
      fields: [
        selectCourse && 'course_iid_to_comment',
        'content',
        showCheckboxToCommentForCourse && 'comment_for_course',
      ].filter(Boolean),
    },
  ];
};

const layoutFreestyle = ({ groups, submitButton, formValues, ...props }) => {
  const elementCourse = get(groups, 'default.fieldNames.course_iid_to_comment');
  const elementCommentForCourse = get(
    groups,
    'default.fieldNames.comment_for_course',
  );
  const elementSubmit = (
    <div className="add-comment__button-add">{submitButton}</div>
  );

  const elementCheckboxToCommentForCourse = (
    <div className="d-flex">
      {elementCommentForCourse}
      <span className="icon-question-circle">
        <Tooltip title={t1('explain_commment_for_course')}>
          <Icon type="question-circle" />
        </Tooltip>
      </span>
    </div>
  );

  const { simplifyComment = false, user = {} } = props.layoutOptionsProperties;

  return (
    <Comment
      avatar={
        simplifyComment || elementCourse ? null : (
          <Avatar className="avatar" user={user} />
        )
      }
      content={[
        elementCourse,
        <div
          className={`add-comment ${
            simplifyComment ? 'add-comment--simplify' : ''
          }`}
        >
          <div className="content-wrapper">
            {get(groups, 'default.fieldNames.content')}
          </div>
          {simplifyComment && elementCommentForCourse ? (
            <div className="add-comment__action">
              {elementCheckboxToCommentForCourse}
              {elementSubmit}
            </div>
          ) : (
            elementSubmit
          )}
        </div>,
        !simplifyComment && elementCommentForCourse && (
          <div className="m-t-10">{elementCheckboxToCommentForCourse}</div>
        ),
      ].filter(Boolean)}
      className="add-comment-wrapper"
    />
  );
};

const finalProcessBeforeSubmit = ({
  content,
  course_iid_to_comment,
  comment,
  ...fullData
}) => {
  const newComment = Object.assign({}, comment, { content });

  const extraVale = {
    comment: newComment,
  };

  //TODO: refactor
  if (course_iid_to_comment) {
    extraVale.comment.collaborating_item = {
      iid: course_iid_to_comment,
      id: course_iid_to_comment,
      name: course_iid_to_comment,
    };
    extraVale.comment.course_iid = course_iid_to_comment;
    extraVale.comment.syllabus_iid = course_iid_to_comment;

    extraVale.course_iid = course_iid_to_comment;
    extraVale.syllabus_iid = course_iid_to_comment;
  }

  return Object.assign({}, fullData, extraVale);
};

export default {
  schema,
  ui,
  layout: (step, values, xpath, { user, simplifyComment = false }) => ({
    component: layoutFreestyle,
    freestyle: 1,
    optionsProperties: {
      user,
      simplifyComment,
    },
  }),
  finalProcessBeforeSubmit,
};
