import React from 'react';
import trim from 'lodash.trim';
import { portals } from 'components/common/portal';
import Tooltip from 'antd/lib/tooltip';
import { secondsToTimeString } from 'common/utils/Date';
import { getQuestionCSSClassModifier } from 'common/learn/exercise/question';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { steps } from 'common/learn/exercise';
import Icon from 'components/common/Icon';
import { t1 } from 'translate';
import IconAnt from 'antd/lib/icon';
import actions from 'actions/node/creators';
import DisplayHtml from 'components/common/html';
import { guideTypes } from 'components/admin/conf/schema/questionGuidanceConfig';
import PDF from 'components/common/pdf';
import { replaceStaticToStatic2 } from 'common/utils/url';

import './stylesheet.scss';

const cssClass = 'question-panel';

const getGuideContent = (questionType, questionGuides) => {
  let output = false;
  questionGuides.map((guide) => {
    if (guide.question_type == questionType) {
      output = guide;
    }
  });
  return output;
};

const QuestionHeader = ({
  title,
  onBookMarkAreaClick,
  isTicked,
  shouldShowTitle,
  shouldShowNumber,
  shouldShowHeader,
  number,
  question,
  questionTimeLeftInSeconds,
  children,
  className,
  inlineExercise,
  handleHintChange,
  step,
  dispatch,
  questionGuides = [],
}) => {
  const isTickable = typeof onBookMarkAreaClick === 'function';
  const questionTimeLeft = questionTimeLeftInSeconds
    ? secondsToTimeString(questionTimeLeftInSeconds)
    : null;

  const questionStatusClass = getQuestionCSSClassModifier(question);
  const questionClassModifier = questionStatusClass
    ? `${cssClass}--${questionStatusClass}`
    : '';

  const questionTickedClass = question.isTicked ? `${cssClass}--ticked` : '';

  const iconClass =
    questionStatusClass === 'correct'
      ? 've-check'
      : questionStatusClass === 'incorrect'
      ? 've-close-outline'
      : '';

  const { hints = [] } = question;
  const showHints =
    Array.isArray(hints) && !!hints.length && step !== steps.REVIEW;
  const guideContent = getGuideContent(question.type, questionGuides);
  const showGuideAction = (guideContent) => {
    if (guideContent) {
      let contentDialog = <></>;
      switch (guideContent.guide_type) {
        case guideTypes.plain_html:
          contentDialog = (
            <div className="row">
              <div className="col-md-12">
                <DisplayHtml content={guideContent.guide_content} />
              </div>
            </div>
          );
          break;
        case guideTypes.pdf_link:
          contentDialog = (
            <div className="row">
              <div
                className="col-md-12"
                style={{ minHeight: '60vh', overflow: 'auto' }}
              >
                <PDF
                  file={replaceStaticToStatic2(guideContent.guide_content)}
                  pdfViewerRef={(pdfViewer) => console.log('pdf loaded')}
                  onDocumentLoadSuccess={(e, f, g) => console.log(e, f, g)}
                  rotate={0}
                  scale={1}
                />
              </div>
            </div>
          );
          break;
        case guideTypes.youtube_link:
          var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*)(.*)/;
          try {
            let str = guideContent.guide_content.match(regExp);
            if (str['7']) {
              let outputEmbedLink = 'https://www.youtube.com/embed/' + str[7];
              if (str['8']) {
                outputEmbedLink +=
                  '?' +
                  str['8'].replace('?t=', 'start=').replace('&t=', 'start=');
              }
              contentDialog = (
                <div className="row">
                  <div className="col-md-12 text-center">
                    <iframe
                      width="560"
                      height="315"
                      src={outputEmbedLink}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    />
                  </div>
                </div>
              );
            } else {
              contentDialog = (
                <div className="row">
                  <div className="col-md-12 text-center">
                    <p>{t1('cannot_match_content_to_any_youtube_link')}</p>
                  </div>
                </div>
              );
            }
          } catch (e) {
            contentDialog = (
              <p>
                {t1(
                  'there_is_problem_when_parse_guide_line_for_this_question_type',
                )}
              </p>
            );
          }
          break;
        default:
          contentDialog = (
            <p>{t1('there_is_no_preset_guidance_for_this_question_type')}</p>
          );
      }

      const optionsProperties = {
        handleClose: true,
        title: t1('question_guideline_form'),
        modal: true,
      };
      dispatch(actions.handleOpenDialog({ contentDialog, optionsProperties }));
    }
  };

  return (
    <div
      className={`${cssClass} ${questionClassModifier} ${questionTickedClass} ${className ||
        ''} `}
    >
      {(shouldShowHeader ||
        (shouldShowTitle && title) ||
        (shouldShowNumber && number) ||
        isTickable) && (
        <div
          className={`${cssClass}__header ${
            isTicked ? `${cssClass}__header--ticked` : ''
          } ${isTickable ? `${cssClass}__header--tickable` : ''} ${
            inlineExercise ? `${cssClass}__header--inline` : ''
          }`}
          onClick={() => {
            if (!inlineExercise && isTickable) {
              onBookMarkAreaClick();
            }
          }}
        >
          <div
            className={`${cssClass}__header-text font-size-medium`}
            id={portals.questionHeader(question.id)}
          >
            {guideContent && (
              <IconAnt
                className="m-r-5"
                type="question-circle"
                theme="twoTone"
                style={{ fontSize: '1.3em' }}
                onClick={(e) => showGuideAction(guideContent)}
              />
            )}
            <span className={`question-status-icon ${iconClass}`} />
            {`${shouldShowNumber && number ? `${number}. ` : ''}${
              shouldShowTitle ? trim(title) : ''
            }`}

            {showHints && (
              <Tooltip title={t1('hint_description')}>
                <span
                  onClick={(event) => {
                    event.stopPropagation();
                    handleHintChange();
                  }}
                >
                  <Icon
                    icon="light-bulb"
                    className={`${cssClass}__light-blub`}
                  />
                </span>
              </Tooltip>
            )}
          </div>

          <div
            className={`${cssClass}__header-question-time`}
            id={portals.QUESTION_IN_VIDEO_TIME}
          />
          <div
            className={`${cssClass}__header-question-time-submit m-r-15`}
            id={portals.questionTimeSubmit(question.id)}
            style={{ fontWeight: 'normal' }}
          />
          <div
            className={`${cssClass}__score`}
            id={portals.questionScore(question.id)}
          />
          {questionTimeLeft && (
            <div className={`${cssClass}__header-time`}>{questionTimeLeft}</div>
          )}
          {isTickable && !inlineExercise && (
            <div className={`${cssClass}__header-tick`}>
              <Tooltip
                title={
                  isTicked
                    ? 'Bỏ đánh dấu đang suy nghĩ / đang làm dở'
                    : 'Đánh dấu đang suy nghĩ / đang làm dở'
                }
              >
                <span
                  className={`${cssClass}__tick-icon ${
                    isTicked ? 've-pin' : 've-pin-outline'
                  } ${isTicked ? cssClass + '__tick-icon--ticked' : ''}`}
                />
              </Tooltip>
            </div>
          )}
          <div
            className={`${cssClass}__header-tick`}
            id={portals.questionAction(question.id)}
          />
        </div>
      )}
      <div className={`${cssClass}__content`}>{children}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const learnItemIid = get(state, 'learn.itemIid');
  const step = get(state, `learn.info.${learnItemIid}.step`, '');
  const questionGuides = get(
    state,
    'domainInfo.conf.question_guidance_settings',
    [],
  );
  return {
    step,
    questionGuides,
  };
};

export default connect(mapStateToProps)(QuestionHeader);
