import { required } from 'common/validators';
import { chuong_trinh, listSchoolYear } from 'components/common/elements';
import { CHUONG_TRINH_03 } from 'components/bdtx/configs';

const schema = () => {
  return {
    school_year: listSchoolYear({
      floatingLabelText: 'Năm học (*)',
      validate: [required()],
      multiple: false,
      classWrapper: 'col-md-6',
    }),
    chuong_trinh: chuong_trinh(
      {
        type: 'radio',
        classWrapper: 'col-md-6',
      },
      {
        excludeChuongTrinhs: [CHUONG_TRINH_03],
      },
    ),
  };
};

const ui = () => {
  return [
    {
      id: 'default',
      fields: ['school_year', 'chuong_trinh'],
    },
  ];
};

export default {
  schema,
  ui,
};
