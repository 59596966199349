import React from 'react';
import Core from './core';
import makeReduxFormCompatible from 'components/common/makeReduxFormCompatible';
import get from 'lodash.get';

export default makeReduxFormCompatible({})(Core);

export const ImageAttachmentRender = (props) => (
  <div>
    <img
      src={get(props, 'attachment.link')}
      alt={get(props, 'attachment.name')}
      style={{
        maxHeight: '150px',
        maxWidth: '150px',
        height: 'auto',
        width: 'auto',
      }}
    />
  </div>
);
