import React from 'react';
import get from 'lodash.get';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import TrainingPhaseStep from 'components/bdtx/training-phase/steps';
import NewTrainingPhase from 'components/bdtx/training-phase/mainstage/new';
import endpoints from 'components/bdtx/training-phase/endpoints';

const EditTrainingPhase = ({ node, onTrainingPhaseUpdated }) => {
  if (!node) {
    return <SimpleNoResult text="Không tồn tại" />;
  }

  return <TrainingPhaseStep trainingPhase={node} />;

  const fromEtep = get(node, 'from_etep');
  const status = get(node, 'status');
  const deployed = get(status, 'deployed');

  return (
    <div className="white-background">
      <NewTrainingPhase
        node={node}
        mode="edit"
        alternativeApi={endpoints.bdtx_update_training_phase}
        readOnly={fromEtep}
        deployed={deployed}
        onEditSuccessful={onTrainingPhaseUpdated}
      />
    </div>
  );
};

export default EditTrainingPhase;
