import TableForm from './TableForm';

const field = 'assign_modules';

const schema = (formid, values, localStep, xpath, props) => {
  const {
    readOnly,
    chosenModules,
    organizations,
    node,
    modules,
    previewDeploymentHiddenFields,
    trainingPhaseIid,
    searchFormId,
    isCCModel,
  } = props;
  return {
    [field]: {
      type: TableForm,
      readOnly,
      chosenModules,
      organizations,
      valueSelected: values[field],
      node,
      modules,
      previewDeploymentHiddenFields,
      trainingPhaseIid,
      searchFormId,
      isCCModel,
    },
  };
};

const ui = () => {
  return [
    {
      id: 'default',
      fields: [field],
    },
  ];
};

export default {
  schema,
  ui,
};
