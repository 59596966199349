import { put, takeEvery } from 'redux-saga/effects';
import { CHANGELOG_LOADING } from 'actions/app-info/types';
import { loadChangelogError, loadChangelogSuccess } from 'actions/app-info';
import Requester from 'common/network/http/Request';
import apiUrls from 'api-endpoints/index';

function* loadChangelog() {
  try {
    const changelogResponse = yield Requester.get(apiUrls.changelog);

    if (changelogResponse && changelogResponse.success) {
      yield put(loadChangelogSuccess(changelogResponse.data));
    } else {
      yield put(loadChangelogError(new Error('Could not load changelog')));
    }
  } catch (error) {
    yield put(loadChangelogError(error));
  }
}

export default function* changelogSaga() {
  yield takeEvery(CHANGELOG_LOADING, loadChangelog);
}
