import InputTokenElement from 'schema-form/elements/input-token';

/**
 *
 * @param floatingLabelText
 * @param fullWidth
 * @param classWrapper
 * @param rest
 * @returns {*&{floatingLabelText, fullWidth, classWrapper, type: (*|((function(*=, *=): *) & hoistNonReactStatics.NonReactStatics<*, {}>))}}
 * @constructor
 */
const InputToken = ({
  floatingLabelText,
  fullWidth,
  classWrapper,
  readOnly,
  ...rest
}) => {
  return {
    type: InputTokenElement,
    floatingLabelText,
    fullWidth,
    classWrapper,
    readOnly,
    ...rest,
  };
};

export default InputToken;
