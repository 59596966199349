import React from 'react';
import FlatButton from 'components/common/mui/NewButton';
import Icon from 'components/common/Icon';
import DetailOnDialog from 'components/common/detail-on-dialog';
import NewModuleForm from './Form';
import { t1 } from 'translate';

const ButtonNew = ({ searchFormId, onlyForCap, defaultValues }) => {
  return (
    <DetailOnDialog
      renderPreview={({ showFull }) => {
        return (
          <FlatButton
            type="button"
            icon={<Icon icon="plus" />}
            label={'Tạo module mới'}
            onClick={showFull}
          />
        );
      }}
      renderFull={({ closeDialog }) => (
        <NewModuleForm
          mode="new"
          title={t1('crete_new_module')}
          searchFormId={searchFormId || 'module_search'}
          onlyForCap={onlyForCap}
          defaultValues={defaultValues}
        />
      )}
      dialogOptionsProperties={{
        handleClose: true,
        width: '80%',
      }}
    />
  );
};

export default ButtonNew;
