import { statuses as examStatus } from 'common/learn';
import lGet from 'lodash.get';
import {
  types as questionTypes,
  questionTypeInText,
} from 'components/admin/question/schema/question-types';
import crypto from 'crypto-js';
import getTexts from './texts';

const texts = getTexts();

export const examFinish = (course, learnInfo) =>
  (learnInfo && learnInfo.status === examStatus.FINISHED) ||
  (course && course.examStatus === examStatus.FINISHED);

export const examDoing = (course, learnInfo) =>
  course && course.examStatus === examStatus.DOING && !startedExam(learnInfo);

export const examRetake = (course, learnInfo) =>
  course && course.examStatus === examStatus.RETAKE && !startedExam(learnInfo);

export const examStart = (course, learnInfo) =>
  course && course.examStatus === examStatus.STARTED && !startedExam(learnInfo);

export const examInit = (course, learnInfo) =>
  course && course.examStatus === examStatus.INIT && !startedExam(learnInfo);

export const startedExam = (learnInfo) =>
  learnInfo &&
  [examStatus.STARTED, examStatus.DOING].includes(learnInfo.status);

// this convert from backend, giving in exam Template and a questionBank, create a paper with fake iid, id and other informations
// in order to create a playable paper like in contest -> dont have to write anything else again
// just need to using redux as a store for practice exam progress
export const generatePaper = (examTemplate, examSubject, questionBank) => {
  var paper = {};
  let exerciseTemplates = examTemplate['exercise_template'];
  let exerciseList = createExerciseListFromExerciseTemplates(
    exerciseTemplates,
    examTemplate,
    questionBank,
  );
  let children = [];
  exerciseList.map((exercisePart, key) => {
    children.push({
      iid: exercisePart['iid'],
      id: exercisePart['id'],
      ntype: 'exercise',
      children: exercisePart['children'],
      type: exercisePart['type'],
      name: exercisePart['name'],
      weighted: exerciseTemplates[key]['score']
        ? exerciseTemplates[key]['score']
        : 0,
      mode: exercisePart.mode,
    });
  });

  paper = {
    id: crypto.MD5(Math.floor(Math.random() * 99999999)).toString(),
    iid: Math.floor(Math.random() * 99999999),
    ntype: 'sco',
    name: examTemplate['name'],
    duration: examSubject['duration'],
    children: children,
    count: children.length,
    options: examSubject.options,
    tpl_type: 'exam',
    exam_template_iid: examTemplate['iid'],
  };

  return paper;
};

const createExerciseListFromExerciseTemplates = (
  exerciseTemplates,
  examTemplate,
  questionBank,
) => {
  var ListExercise = [];
  var includedQuestionIds = [];
  exerciseTemplates.map((exerciseTemplate, key) => {
    var exercise = createExerciseFromExerciseTemplate(
      exerciseTemplate,
      includedQuestionIds,
      examTemplate,
      key,
      questionBank,
    );
    /*exercise['children'].map((question) => {
      includedQuestionIds.push(question['id']);
    });*/
    ListExercise.push(exercise);
  });

  return ListExercise;
};

const createExerciseFromExerciseTemplate = (
  exerciseTemplate,
  excludeQuestionIds,
  examTemplate,
  exerciseIndex,
  questionBank,
) => {
  var exercise = {
    name: exerciseTemplate['name'],
    type: 'exam',
    mode: lGet(exerciseTemplate, 'mode', false),
  };
  var excludeQuestionIdsTmp = excludeQuestionIds;
  var children = [];

  if (exerciseTemplate['groups']) {
    var groups = exerciseTemplate['groups'];
    var groupNumber = 1;
    groups.map((group, key) => {
      var displayByGroup = group['display_by_group'];
      if (displayByGroup) {
        groupNumber = key + 1;
      }
      if (group['question_type'] === questionTypes.TYPE_INTRODUCTION) {
        if (group['question_iid']) {
          //continue
        }
      } else {
        var questionsResult = getRandomQuestionsFromGroupTemplate(
          group,
          excludeQuestionIds,
          questionBank,
        );
        if (questionsResult.length > 0) {
          var questions = questionsResult;
          var totalQuestion = lGet(group, 'nr_of_questions', 1);
          var totalScore = lGet(group, 'score', null);
          if (totalQuestion && questions.length !== totalQuestion) {
            console.log('group_not_have_enough_question');
          }

          questions.map((question) => {
            var question_c = Object.assign(question, {
              group: groupNumber,
              ntype: 'question',
              weighted: totalScore != null ? totalScore / totalQuestion : 0,
            });

            children.push(question_c);

            if (!displayByGroup) {
              groupNumber += 1;
            }

            excludeQuestionIdsTmp.push(question['id']);
          });
        } else {
          console.log('group_not_have_enough_question_2');
        }
      }
    });
  }

  if (exerciseTemplate['total_nr_of_weighted_questions']) {
    // validate against the number of weighted questions
    var totalWeightedQuestions = 0;

    children.map((question) => {
      if (question['weighted']) {
        totalWeightedQuestions += parseInt(question['weighted']);
      }
    });

    if (
      totalWeightedQuestions !=
      exerciseTemplate['total_nr_of_weighted_questions']
    ) {
      console.log('not_enough_question_for_part');
    }
  }

  exercise = Object.assign(exercise, {
    children: children,
    //metadata: children,
    id: crypto.MD5(Math.floor(Math.random() * 99999999)).toString(),
    iid: Math.floor(Math.random() * 99999999),
    help_mode: 'practice',
    ntype: 'exercise',
    ts: Math.floor(Date.now() / 1000),
  });
  return exercise;
};

const getRandomQuestionsFromGroupTemplate = (
  groupTemplate,
  excludeQuestionIds,
  questionBank,
) => {
  var template = {
    categories: lGet(groupTemplate, 'categories', []),
    difficulty: lGet(groupTemplate, 'difficulty', 'all'),
    display_by_group: lGet(groupTemplate, 'display_by_group', 1),
    nr_of_questions: lGet(groupTemplate, 'nr_of_questions', 0),
    question_type: lGet(groupTemplate, 'question_type', []),
    level: lGet(groupTemplate, 'level', 0),
    score: lGet(groupTemplate, 'score', 0),
  };
  var excludeQuestionIdsTmp = excludeQuestionIds;
  var questionAvailable = [];
  var output = [];
  questionBank.map((question) => {
    if (excludeQuestionIdsTmp.includes(question.id)) {
      return false;
    }

    if (
      (template.question_type.length > 0) &
      !template.question_type.includes(question.type)
    ) {
      return false;
    }

    if (
      template.difficulty != 'all' &&
      !template.difficulty.includes(question.difficulty)
    ) {
      return false;
    }

    if (
      template.categories.length > 0 &&
      !template.categories.includes(texts.no_value_tags)
    ) {
      if (question.tags == undefined) {
        return false;
      } else {
        if (
          !question.tags.some((e) => {
            return template.categories.includes(e);
          })
        ) {
          return false;
        }
      }
    }

    if (
      template.categories.length > 0 &&
      template.categories.includes(texts.no_value_tags)
    ) {
      if (question.tags == undefined) {
      } else {
        if (
          !question.tags.some((e) => {
            return template.categories.includes(e);
          })
        ) {
          return false;
        }
      }
    }

    if (template.level != 0 && template.level != question.level) {
      return false;
    }
    var questionSuccess = question;
    questionSuccess['uniqueId'] = question.id;
    questionAvailable.push(questionSuccess);
  });
  for (var i = 0; i < template.nr_of_questions; i++) {
    var index = Math.floor(Math.random() * questionAvailable.length);
    output.push(
      Object.assign(questionAvailable[index], {
        iid: questionAvailable[index]['id'],
        number: excludeQuestionIdsTmp.length + i + 1,
      }),
    );
    questionAvailable.splice(index, 1);
  }

  return output;
};

export const markTakeWithPaper = (take, paper) => {
  var takeOutput = take;
  var answers = take['answers'];

  return takeOutput;
};
/*
private function _markTake($take, $examRound = null) {
  if (empty($examRound)) {
      $examRound = $this->examRoundRepository->getByIid($take['exam_round_iid']);
  }

  $answers = $take['answers'];
  $examParts = $take['paper']['tree_view']['children'];
//        dd($examParts);
  if (!empty($take['exam']) && !empty($take['exam']['exam_type']) && $take['exam']['exam_type'] === 'exercise') {
      $exerciseInfo = $take['paper']['tree_view'];
      $examParts = [
          [
              'name' => $exerciseInfo['name'],
              'weighted' => $exerciseInfo['weighted'],
              'children' => $examParts,
          ]
      ];
  }

  $takeWeightedScore = 0;
  $parts = $this->getExamParts($examParts);
  $totalWeight = 0;
  foreach ($examParts as $part) {
      $questions = $part['children'];
      foreach ($questions as $question) {
          $totalWeight += $question['weighted'];
      }
  }
//        $userIid = $take['u']['iid'];
//        $courseIid = !empty($take['course']) ? $take['course'] : null;
//        dd($examParts);

  $examRoundInvalidQuestions = [];
  if ($examRound['invalid_questions']) {
      foreach ($examRound['invalid_questions'] as $invalid) {
          $examRoundInvalidQuestions[] = $invalid['iid'];
      }
  }

  foreach ($answers as $qid => &$answer) {
      // TODO: check if question is invalid first

      if (in_array($qid, $examRoundInvalidQuestions)) {
//                foreach ($examRound['invalid_questions'] as $invalid) {
//                    if ($invalid['iid'] == $qid && $invalid['action'] == 'plus') {
//                        // this question is by default correct for all the takes
//
//                    }
//
//                }
//
          $questionScoreData = Question_Service_Mark::getInstance()->markInvalidQuestion($qid, $examParts);
//                continue;
      } else {
          $questionScoreData = Question_Service_Mark::getInstance()->markExamQuestion($qid, $answer, $examParts);
      }

      if (!empty($questionScoreData)) {
          $questionWeightedScore = $questionScoreData['weightedScore'];
          $questionWeight = $questionScoreData['weighted'];
          $questionPartId = $questionScoreData['partIid'];

          $answer['is_correct'] = $questionWeightedScore == $questionWeight;
          $answer['score'] = $questionWeightedScore;
          $answer['sw'] = $answer['weighted'] = $questionWeight;

          if ($questionScoreData['is_invalid_question']) {
              $answer['bonus'] = 1;
          }

          $takeWeightedScore += $questionWeightedScore;

          // this 'weighted' attribute should really be renamed to 'weightedScore'
          // but since the schema is already 'weighted' in the db, we don't wanna migrate
          // as of Aug 2019
          $parts[$questionPartId]['weighted'] = $parts[$questionPartId]['weighted'] + $questionWeightedScore;
          $parts[$questionPartId]['iid'] = $questionPartId;

      } else {
          $answer['is_correct'] = false; // false
      }
  }

  $toeicService = Take_Service_ToeicScore::getInstance();
  if ($toeicService->isTOEICExam($examRound['exam_format'], count($parts))) {
      list($parts, $takeWeightedScore) = $toeicService->convertScoreToTOEICScale($parts, $examRound['exam_format']);
  }

  $scoreDetail = array_values($parts);

  $take['score'] = $takeWeightedScore;
  $take['score_detail'] = $scoreDetail;
  $take['total_score'] = $totalWeight;
  $take['exam_result_point_parts_status'] = $this->_getExamResultPointPartsStatus($take, $examRound);

  $take['exam_format'] = $examRound['exam_format'];

  // get raw score and scaled score
  $rawScore = [
      'score' => $take['score'],
      'score_detail' => $take['score_detail'],
      'total_score' => $take['total_score'],
  ];

  if ($examRound['scaled_total_score'] && $examRound['scaled_total_score'] != $take['total_score']) {
      $scaledScore = $this->_scaleFinalScores($examRound, $rawScore);
  } else
      $scaledScore = $rawScore;

  // pass status
  if (
      $examRound['advancing_criterion'] &&
      $examRound['advancing_criterion'] == 'score') {
      if ($take['exam_result_point_parts_status'] == 'passed' && $examRound['min_score'] <= $scaledScore['score']) {
          $take['exam_result_status'] = 'passed';
      } else {
          $take['exam_result_status'] = 'failed';
      }
  }

  $update = [
      '$set' => [
          'raw_score' => $rawScore,
          'score' => round($scaledScore['score'], 2),
          'score_detail' => $scaledScore['score_detail'],
          'total_score' => round($scaledScore['total_score'], 2),
          'answers' => json_decode(json_encode($answers),true),
          'mstatus' => Take_Entity_Take::MARKING_MARKED,
          'exam_result_point_parts_status' => $take['exam_result_point_parts_status'],
          'exam_result_status' => $take['exam_result_status'],
          'exam_format' => $take['exam_format']
      ]
  ];

//        dd($take);
//        $where = [];
  $res = $this->takeRepository->update(['id' => $take['id']], $update);
  if (!$res['success']) {
      return $res;
  }

  return [
      'success' => true,
      'score' => $takeWeightedScore,
      'total_score' => $totalWeight,
      'score_detail' => $take['score_detail'],
  ];
}
*/

export const getTotalScoreFromAnswer = (take) => {
  let output = 0;
  Object.values(take.answer).forEach((ans) => {
    output += ans.score * ans.percent * ans.weighted;
  });
  return output;
};

export const getTotalScoreFromPaper = (paper) => {
  let output = 0;
  lGet(paper, 'children', []).map((exercise) => {
    output += exercise.weighted;
  });
  return output;
};

export const joinAnswerFromTakeToQuestionGroup = (
  questions,
  take,
  isShowAll = false,
) => {
  var output = questions;
  output.map((question, key) => {
    output[key]['answer'] = lGet(
      take,
      'answer[' + question.id + '].answer',
      [],
    );
    if (isShowAll) {
      output[key]['isCorrect'] = lGet(
        take,
        'answer[' + question.id + '].isCorrect',
        false,
      );
      output[key]['shouldShowAnswer'] = true;
      output[key]['shouldDisplayCheckedResult'] = true;
    } else {
      if (lGet(take, 'questionLock', []).includes(question.iid)) {
        output[key]['isCorrect'] = lGet(
          take,
          'answer[' + question.id + '].isCorrect',
          false,
        );
        output[key]['shouldShowAnswer'] = true;
        output[key]['shouldDisplayCheckedResult'] = true;
      }
    }
  });
  return output;
};

export const getListOptionsFromBank = (field, questionBank) => {
  var output = [];
  var outputArray = [];
  questionBank.map((question) => {
    if (field == 'tags' && question[field] == undefined) {
      if (!output.includes(texts.no_value_tags)) {
        output.push(texts.no_value_tags);
        outputArray.push({
          value: texts.no_value_tags,
          label: texts.no_value_tags_category,
        });
      }
    }
    if (question[field] != undefined && question[field] != null) {
      if (Array.isArray(question[field])) {
        question[field].map((value) => {
          if (!output.includes(value)) {
            output.push(value);
            outputArray.push({
              value: value,
              label: value,
            });
          }
        });
      } else {
        if (!output.includes(question[field])) {
          if (field != 'type') {
            output.push(question[field]);
            outputArray.push({
              value: question[field],
              label: question[field],
            });
          } else {
            output.push(question[field]);
            outputArray.push({
              value: question[field],
              label: questionTypeInText(question[field]),
            });
          }
        }
      }
    }
  });
  return outputArray;
};

export const getQuestionResultText = (questionIid, questionBankResult) => {
  return questionBankResult.map((result) => {
    if (questionIid == result.iid) {
      return texts.question_result_review_text(
        result.touched,
        result.correct,
        result.incorrect,
        result.last,
      );
    }
  });
};

export const getNumberOfResultWithStatus = (status, qResultList) => {
  let output = 0;
  qResultList.map((result) => {
    if (result.last == status) {
      output += 1;
    }
  });
  return output;
};

export const getListofQuestionSuitable = (
  options,
  questionBank,
  questionResult = [],
) => {
  var output = {
    totalQuestion: 0,
    qResult: [],
  };
  var totalQuestion = 0;
  var qResultList = [];
  questionBank.map((question) => {
    if (options.type != undefined && question.type != options.type) {
      return false;
    }
    if (options.tags != undefined && options.tags != texts.no_value_tags) {
      if (question.tags == undefined || !question.tags.includes(options.tags)) {
        return false;
      }
    }

    if (
      options.difficulty != undefined &&
      question.difficulty != options.difficulty
    ) {
      return false;
    }
    if (options.level != undefined && question.level != options.level) {
      return false;
    }

    if (options.tags == texts.no_value_tags && question.tags != undefined) {
      return false;
    }
    for (var i = 0; i < questionResult.length; i++) {
      if (questionResult[i].iid == question.iid) {
        qResultList.push(questionResult[i]);
        break;
      }
    }
    totalQuestion += 1;
  });
  output.totalQuestion = totalQuestion;
  output.qResult = qResultList;
  return output;
};

export const getNumberofQuestionSuitable = (options, questionBank) => {
  var output = 0;
  questionBank.map((question) => {
    if (options.type != undefined && question.type != options.type) {
      return false;
    }
    if (options.tags != undefined && options.tags != texts.no_value_tags) {
      if (question.tags == undefined || !question.tags.includes(options.tags)) {
        return false;
      }
    }

    if (
      options.difficulty != undefined &&
      question.difficulty != options.difficulty
    ) {
      return false;
    }
    if (options.level != undefined && question.level != options.level) {
      return false;
    }

    if (options.tags == texts.no_value_tags && question.tags != undefined) {
      return false;
    }
    output += 1;
  });
  return output;
};

export const findGroupthatQuestionBelongTo = (groups, currentQuestionId) => {
  if (groups != undefined && currentQuestionId != undefined) {
    let output = lGet(groups, '[0]', {});
    groups.map((group) => {
      if (Array.isArray(group.children) && group.children.length > 0) {
        var index = group.children.findIndex(
          (question) => question.id == currentQuestionId,
        );
        if (index > -1) {
          output = group;
        }
      }
    });
    return output;
  } else {
    return true;
  }
};

export const scrollToElementID = (idElement) => {
  try {
    if (document.getElementById(idElement)) {
      document.getElementById(idElement).scrollIntoView({ behavior: 'smooth' });
    }
  } catch (err) {}
};

//This will return array that have list of question Id which sorted and also show out to naviagte next and previous
export const getShortenNavigateQuestionIdList = (paper) => {
  let output = [];
  lGet(paper, 'children', []).map((group) => {
    lGet(group, 'children', []).map((question) => {
      output[lGet(question, 'number', 1) - 1] = question.id;
    });
  });
  return output;
};

export const createQuestionResultFromAnswer = (answers) => {
  let output = [];
  Object.values(answers).map((answer) => {
    output.push({
      iid: answer.iid,
      touched: 1, //0 time touched
      correct: answer.isCorrect ? 1 : 0, //0 time correct
      incorrect: answer.isCorrect ? 0 : 1, //0 time incorrect
      last: answer.isCorrect ? 'correct' : 'incorrect', //"null" -> havent touch, correct, incorrect
    });
  });
  return output;
};

export const mergeQuestionResultFromTakeResult = (
  questionResult,
  takeResult,
) => {
  let output = questionResult;
  takeResult.map((tQresult) => {
    var inQResult = false;
    for (var i = 0; i < questionResult.length; i++) {
      if (questionResult[i].iid == tQresult.iid) {
        output[i] = combineResult(questionResult[i], tQresult);
        inQResult = true;
        break;
      }
    }
    if (!inQResult) {
      output.push(tQresult);
    }
  });
  return output;
};

export const linkToAction = (match, action) => {
  if (lGet(match, 'params.siid') > 0) {
    return `/bank-review/${match.params.siid}/${match.params.eiid}/${action}`;
  } else if (lGet(match, 'params.template_iid') > 0) {
    return `/template-review/${match.params.template_iid}/${action}`;
  } else {
    return `/exam/practice/${match.params.contest_code}/${
      match.params.contestant_iid
    }/${action}`;
  }
};

const combineResult = (oldResult, newResult) => ({
  iid: oldResult.iid,
  touched: oldResult.touched + newResult.touched,
  correct: oldResult.correct + newResult.correct,
  incorrect: oldResult.incorrect + newResult.incorrect,
  last: newResult.last,
});

// don't know why exam_round_info is a key in a course, but it is anyway
// we're trying to get no_fullscreen key
export const shouldNotGoFullscreen = (course) =>
  lGet(course, 'exam_round_info.no_fullscreen');
