import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t1 } from 'translate';
import Alert from 'antd/lib/alert';

const isFunction = (any) => ({}.toString.call(any) === '[object Function]');

class QuestionMissing extends Component {
  onRedoButtonClick = () => {
    const { onRedoButtonClick } = this.props;
    const redoWhenQuestionsMissing = true;

    isFunction(onRedoButtonClick) &&
      onRedoButtonClick(redoWhenQuestionsMissing);
  };

  render() {
    return (
      <div className="m-20">
        <Alert
          showIcon
          type="error"
          message={t1(
            'this_exercise_has_been_changed_please_click_the_redo_button_to_redo_this_exercise',
          )}
        />

        <div className="m-30 center">
          <button className="btn btn-primary" onClick={this.onRedoButtonClick}>
            {t1('redo')}
          </button>
        </div>
      </div>
    );
  }
}

QuestionMissing.propTypes = {
  onRedoButtonClick: PropTypes.func,
};

export default QuestionMissing;
