import fetchData from 'components/common/fetchData';
import confApiEndpoints from 'components/admin/conf/endpoints';

const withConf = (key, attr, fetchDataProps = {}) => {
  return fetchData((props) => {
    return {
      baseUrl: confApiEndpoints.get_conf,
      params: {
        key,
      },
      propKey: attr,
      fetchCondition: true,
      // shouldRenderLoading: true,
      ...fetchDataProps,
    };
  });
};

export default withConf;
