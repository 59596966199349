/**
 * Created by hungvo on 19/12/17.
 */

import React from 'react';
import { connect } from 'react-redux';
import Request from 'common/network/http/Request';
import actions from 'actions/node/creators';
import {
  RIEInput,
  RIENumber,
  RIESelect,
  RIETags,
  RIETextArea,
  RIEToggle,
} from 'riek';

const InlineEdittable = ({
  url,
  dispatch,
  params,
  handleOnChange: handleOnChangeFromProps,
  type,

  propName,
  value,
  validate,
  shouldBlockWhileLoading,
  shouldRemainWhileInvalid,
  defaultProps,
  classLoading,
  classEditing,
  classInvalid,
  className,
  editProps,
  beforeStart,
  afterStart,
  beforeFinish,
  afterFinish,

  //RIENumber
  format,

  //RIETextArea
  rows,
  cols,

  //RIESelect
  options,
}) => {
  const handleSubmit = (value, submitUrl) => {
    const requestParams = Object.assign(params || {}, value);
    Request.post(submitUrl, requestParams).then((res) => {
      if (res) {
        if (res.success) {
          dispatch(actions.snackbar(true, res.message));
        } else {
          dispatch(actions.snackbar('error', res.message));
        }
      }
    });
  };

  const handleOnChange = (value) => {
    if (url) {
      handleSubmit(value, url);
      return;
    }
    if (handleOnChangeFromProps) {
      handleOnChangeFromProps(value);
    }
  };

  const rieProps = {
    propName: propName || 'content',
    value: ![null, undefined].includes(value) ? value : '...',
    editProps: editProps || {
      style: {
        borderBottom: '2px solid #00BCD4',
      },
    },
    defaultProps: defaultProps || {
      style: {
        borderBottom: '2px solid #00BCD4',
      },
    },
    change: handleOnChange,
    validate,
    shouldBlockWhileLoading,
    shouldRemainWhileInvalid,
    classLoading,
    classEditing,
    classInvalid,
    className,
    beforeStart,
    afterStart,
    beforeFinish,
    afterFinish,
    //RIENumber
    format,
    //RIETextArea
    rows,
    cols,
    //RIESelect
    options,
  };

  switch (type) {
    case 'number': {
      return <RIENumber {...rieProps} />;
    }
    case 'toggle': {
      return <RIEToggle {...rieProps} />;
    }
    case 'textArea': {
      return <RIETextArea {...rieProps} />;
    }
    case 'tags': {
      return <RIETags {...rieProps} />;
    }
    case 'select': {
      return <RIESelect {...rieProps} />;
    }
    default:
      return <RIEInput {...rieProps} />;
  }
};

export default connect()(InlineEdittable);
