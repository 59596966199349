import React from 'react';
import { connect } from 'react-redux';

import MemberSearchLayout from './member/search';
import MemberAddLayout from './member/add/search-user/Layout';
import LearningItemSearchLayout from './learning-item/search';
import LearningItemAddLayout from './learning-item/add';
import Info from './info';
import Filtersets from './filterset/index';
import DashBoard from './dashboard';
import NewRoles from './roles/new';
import Staff from './staff/Layout';
import Attendance from './attendance/Layout';
import SurveySearch from './survey/Layout';
import FeedbackStudentsInBatch from 'components/admin/survey/feedback-students-in-batch';
import AddEp from './enrolment-plan/new';
// import ImportUsers from 'components/admin/import-users';
import GroupImportMembers from 'components/admin/group/edit/import/import-members';

const routes = (prefix, node, action, subAction, type) => {
  return [
    {
      path: `/${prefix}/group/:iid/:action(members)/:subAction(new)`,
      // relativePath: '/members/new',
      component: () => (
        <MemberAddLayout group={node} action={action} subAction={subAction} />
      ),
    },
    {
      path: `/${prefix}/group/:iid/members`,
      component: () => {
        return <MemberSearchLayout group={node} action={action} />;
      },
      exact: true,
    },
    {
      path: `/${prefix}/group/:iid/:action(members)/import(/)?:importId?`,
      component: () => {
        return <GroupImportMembers node={node} />;
      },
      exact: true,
    },
    {
      path: `/${prefix}/group/:iid/learning-items`,
      component: () => {
        return <LearningItemSearchLayout group={node} />;
      },
      exact: true,
    },
    {
      path: `/${prefix}/group/:iid/:action(learning-items)/:subAction(new)`,
      component: () => {
        return <LearningItemAddLayout group={node} />;
      },
      exact: true,
    },
    {
      path: `/${prefix}/group/:iid/:action(info)`,
      component: () => {
        return <Info group={node} />;
      },
      exact: true,
    },
    {
      path: `/${prefix}/group/:iid/:action(filtersets)`,
      component: () => {
        return <Filtersets group={node} />;
      },
      exact: true,
    },
    {
      path: `/${prefix}/group/:iid/:action(dashboard)`,
      component: () => <DashBoard group={node} type={type} />,
      exact: true,
    },
    {
      path: `/${prefix}/group/:iid/:action(roles)/:subAction(new)`,
      component: () => <NewRoles group={node} />,
      exact: true,
    },
    {
      path: `/${prefix}/group/:iid/staff`,
      component: () => <Staff group={node} />,
      exact: true,
    },
    {
      path: `/${prefix}/group/:iid/attendance`,
      component: () => <Attendance group={node} />,
      exact: true,
    },
    {
      path: `/${prefix}/group/:iid/add-ep`,
      component: () => <AddEp group={node} />,
      exact: true,
    },
    {
      path: `/${prefix}/group/:iid/surveys/batch`,
      component: () => <FeedbackStudentsInBatch group={node} />,
      exact: true,
    },
    {
      path: `/${prefix}/group/:iid/surveys`,
      component: () => <SurveySearch group={node} />,
      exact: true,
    },
    // {
    //   path: `/${prefix}/group/:iid/year-report`,
    //   component: () => <SchoolYearReport group={node} />,
    //   exact: true,
    // },
    // {
    //   path: `/${prefix}/group/:iid/setup`,
    //   component: () => <K12Setup group={node} />,
    //   exact: true,
    // },
  ];
};

export default routes;

const actions =
  'members|info|surveys|add-ep|staff|roles|attendance|dashboard|import-members|filterset|learning-items';
export const editGroupPath = (prefix) =>
  `/${prefix}/group/:iid/:action(${actions})?(/)?:subAction?`;
