import React from 'react';
import connect from 'react-redux/lib/connect/connect';
import lodashGet from 'lodash.get';
import Pdf from 'components/learn/items/lecture/pdf';
import { saveLearningProgress } from 'common/learn';
import './stylesheet.scss';
import Heading from '../../common/Heading';

const BlockPdf = ({ item, course, dispatch, isPreview, isReview }) => {
  const savePdfLearningProgress = (progress) => {
    saveLearningProgress(
      progress,
      lodashGet(course, 'iid'),
      dispatch,
      isPreview,
      isReview,
    );
  };

  return (
    <>
      <Heading item={item} />

      <Pdf
        className="block-pdf"
        learnItemIid={item.iid}
        node={item}
        saveLearningProgress={savePdfLearningProgress}
        notShowFullscreenButton
      />
    </>
  );
};

export default connect()(BlockPdf);
