import React from 'react';
import Tree from 'antd/lib/tree';
import lGet from 'lodash.get';
import getTexts from './texts';
import flattenDeep from 'lodash.flattendeep';

const texts = getTexts();

const { TreeNode } = Tree;

const ExamTemplateLayout = (examTemplate, startExamAction) => {
  return (
    <>
      {examTemplate ? (
        <div className="row">
          <div className="col-md-12">
            <button
              type="button"
              className="m-b-20 m-t-10 ant-btn ant-btn-primary"
              style={{ margin: 'auto', display: 'block' }}
              onClick={(e) => startExamAction(e)}
            >
              <span>{texts.start_practice_exam}</span>
            </button>
          </div>
          <div className="col-md-12">{TreeTemplate(examTemplate)}</div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export const TreeTemplate = (examTemplate) => {
  var schema = TreeTemplateSchemaCreator(examTemplate);
  var allKey = getAllKeys(schema);
  return <Tree defaultExpandedKeys={allKey} treeData={schema} />;
};

const getAllKeys = (schema) => {
  const nestedKeys = schema.map((node) => {
    let childKeys = [];
    if (node.children) {
      childKeys = getAllKeys(node.children);
    }
    return [childKeys, node.key];
  });
  return flattenDeep(nestedKeys);
};

const TreeTemplateChildrenRender = ({ schema }) => {
  return (
    <>
      {schema.map((template) => {
        return (
          <TreeNode title={template.title} key={template.key}>
            {template.children ? (
              <TreeTemplateChildrenRender schema={template.children} />
            ) : (
              <></>
            )}
          </TreeNode>
        );
      })}
    </>
  );
};

const TreeTemplateSchemaCreator = (examTemplate) => {
  let output = [
    {
      title: (
        <h2 className="m-b-3">
          {texts.exam_template_full_title(
            examTemplate.name,
            examTemplate.nr_of_exam_questions,
            examTemplate.total_exam_score,
          )}
        </h2>
      ),
      key: examTemplate.iid,
    },
  ];
  lGet(examTemplate, 'exercise_template', []).map((template) => {
    var templateSchema = {
      title: (
        <p className="m-b-0">
          <b>
            {texts.exam_practice_group_full_text(template.name, template.score)}
          </b>
        </p>
      ),
      key: template.iid,
      children: TreeTemplateGroupsSchemaCreator(template),
    };
    output.push(templateSchema);
  });
  return output;
};

const TreeTemplateGroupsSchemaCreator = (examTemplate) => {
  let output = [];
  if (Array.isArray(examTemplate.groups)) {
    examTemplate.groups.map((group, key) => {
      var groupSchema = {
        title: (
          <>
            {groupElementRender(group)}
            <hr className="m-t-10 m-b-5" />
          </>
        ),
        key: 'group-' + key + '-' + examTemplate.iid,
      };
      output.push(groupSchema);
    });
  }
  return output;
};

const groupElementRender = (group) => {
  return (
    <>
      {group.categories ? (
        <div>{texts.text_group_categories(group.categories)}</div>
      ) : (
        <></>
      )}
      {group.difficulty ? (
        <div>{texts.text_group_difficulty(group.difficulty)}</div>
      ) : (
        <></>
      )}
      <div>{texts.text_group_nr_of_questions(group.nr_of_questions)}</div>
      {group.score ? <div>{texts.text_group_score(group.score)}</div> : <></>}
    </>
  );
};

export default ExamTemplateLayout;
