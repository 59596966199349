export const SAVE_SURVEY_TAKE_REQUEST = 'SAVE_SURVEY_TAKE_REQUEST';
export const INIT_SURVEY = 'INIT_SURVEY';
export const SUBMIT_SURVEY = 'SUBMIT_SURVEY';

export const initSurvey = (itemIid, info) => ({
  type: INIT_SURVEY,
  itemIid,
  info,
});

export const submitSurvey = (itemIid) => ({
  type: SUBMIT_SURVEY,
  itemIid,
});

export const saveSurveyTakeRequest = (itemIid, params) => ({
  type: SAVE_SURVEY_TAKE_REQUEST,
  itemIid,
  params,
});
