import React from 'react';
import lodashGet from 'lodash.get';

import NodeNew from 'components/admin/node/new';
import endpoints from 'components/bdtx/review-program/endpoints';

import schema from './schema';

const ReviewEvidenceForm = ({
  searchFormId,
  node,
  readOnly,
  onUpdateReviewEvidence,
  hiddenFields,
  apiUrl,
  step,
}) => {
  const nodeId = lodashGet(node, 'id');
  const reviewEvidence = lodashGet(node, 'review_evidence');

  const formId = `review-evidence-form-id-${nodeId}`;

  const requestSuccessful = () => {
    if (typeof onUpdateReviewEvidence === 'function') {
      onUpdateReviewEvidence();
    }
  };

  return (
    <NodeNew
      mode="edit"
      step={step || ''}
      node={{
        ...(reviewEvidence || {}),
        id: nodeId,
        iid: lodashGet(node, 'iid'),
        ntype: lodashGet(node, 'ntype'),
        type: lodashGet(node, 'type'),
      }}
      schema={schema}
      hiddenFields={{
        ...(hiddenFields || {}),
        review_status: 'approved', // khi VGD upload MC thì có nghĩa là duyệt Program
        id: nodeId,
        iid: lodashGet(node, 'iid'),
        ntype: lodashGet(node, 'ntype'),
        type: lodashGet(node, 'type'),
      }}
      closeModal
      alternativeApi={apiUrl || endpoints.update_review_evidence}
      formid={formId}
      searchFormId={searchFormId}
      requestSuccessful={requestSuccessful}
      readOnly={readOnly}
    />
  );
};

export default ReviewEvidenceForm;
