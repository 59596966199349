import React from 'react';
import { t1 } from 'translate';
import Modal from 'antd/lib/modal';
import store from 'store';
import { submit } from 'redux-form';

const AskToParent = ({
  formId,
  children,
  messageTitle = '',
  messageContent = '',
}) => {
  const submitForm = () => {
    store.dispatch(submit(formId));
  };

  const showConfirmPopup = () => {
    Modal.confirm({
      centered: true,
      title: messageTitle,
      content: <span>{messageContent}</span>,
      onOk() {
        submitForm(formId);
      },
      cancelText: t1('cancel'),
      okText: t1('send'),
    });
  };

  return (
    <button
      type="button"
      style={{ background: 'none', border: 'none', outline: 'none' }}
      onClick={showConfirmPopup}
    >
      {children}
    </button>
  );
};

export default AskToParent;
