import get from 'lodash.get';
import uniqWith from 'lodash.uniqwith';
import isEqual from 'lodash.isequal';
import dataSource, { dataKey } from './data-source';

export const KEY_MAPPING = {
  number: '{NUMBER}',
  date: '{DATE}',
  area: '{AREA}',
};

export const PARSE_KEY = `${KEY_MAPPING.number}|${KEY_MAPPING.date}|${
  KEY_MAPPING.area
}`;

export const parsePlanToElement = (
  item,
  fieldKey,
  renderField = () => {},
  renderText = () => {},
  viewOnly,
) => {
  const fieldData = get(item, fieldKey, '');

  const regex = new RegExp(`(${PARSE_KEY}|\n)`, 'g');
  const regexSymbol = new RegExp(`(${PARSE_KEY})`, 'g');
  const regexNewLine = /\n/g;
  const questionAfterSplitByRegex = fieldData.split(regex);
  let rawPlan = [];
  let parse_key_count = 1;

  questionAfterSplitByRegex.forEach((fieldType) => {
    if (fieldType && fieldType.match(regexSymbol)) {
      rawPlan = [
        ...rawPlan,
        renderField(item, fieldKey, fieldType, parse_key_count, viewOnly),
      ];
      parse_key_count += 1;
    } else if (renderText && fieldType && fieldType.match(regexNewLine)) {
      rawPlan = [...rawPlan, renderText(fieldType)];
    } else {
      rawPlan = [...rawPlan, fieldType];
    }
  });

  return rawPlan;
};

export const itemTemplate = (isResultPlan) => {
  let template = {
    [dataKey.action]: KEY_MAPPING.area,
    [dataKey.result]: KEY_MAPPING.area,
    [dataKey.finalResult]: KEY_MAPPING.area,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: KEY_MAPPING.area,
  };

  if (isResultPlan) {
    template = {
      ...template,
      [dataKey.validation]: {
        [dataKey.result]: {
          1: {
            required: false,
          },
        },
        [dataKey.finalResult]: {
          1: {
            required: false,
          },
        },
        [dataKey.dateTime]: {
          1: {
            required: false,
          },
          2: {
            required: false,
          },
        },
        [dataKey.corporate]: {
          1: {
            required: false,
          },
        },
      },
    };
  }

  return template;
};

export const formatDataSource = (
  defaultSource,
  currentValues,
  isResultPlan,
) => {
  const extendedSource = Object.values(currentValues || {});

  let enricSource = [];

  defaultSource.forEach((item) => {
    let children = extendedSource.filter(
      (extendItem) => get(extendItem, 'parentId') == item.id,
    );

    enricSource.push({
      ...item,
      rowSpan: children.length + 1,
    });

    if (!children.length) {
      return;
    }

    // add item template to children
    children = children.map((child) => ({
      ...child,
      ...itemTemplate(isResultPlan),
    }));

    enricSource = enricSource.concat(children);
  });

  return enricSource;
};

export const getTTFromKey = (key) => {
  if (!key) {
    return '';
  }
  // key formatted: id-name-order
  const keys = key.split('-');
  const id = keys[0];

  const item = dataSource().find((source) => source.id == id);

  return get(item, 'tt');
};

export const mappingDigitalSupportPlanErrorByKey = (error, isResultPlan) => {
  const errorKeys = Object.keys(error || {});
  const orders = errorKeys.map((key) => getTTFromKey(key)).filter(Boolean);
  const ordersUniq = uniqWith(orders, isEqual);

  if (!ordersUniq || !ordersUniq.length) {
    return '';
  }

  return `Mục: ${ordersUniq.join(
    ', ',
  )} không được để trống, hoặc thông tin điền không hợp lệ. Chi tiết lỗi hiển thị trong nội dung ${
    isResultPlan ? 'Báo cáo kết quả HTĐN' : 'KHHTĐN'
  }`;
};
