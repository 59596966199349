import ApiRequestBtnWithConfirmDialog from 'components/common/action-button/ApiRequestBtnWithConfirmDialog';
import RaisedButton from 'components/common/mui/RaisedButton';
import bdtxEpApiUrls from './endpoints';
import React from 'react';
import lodashGet from 'lodash.get';
import { t1 } from 'translate';
import Icon from 'components/common/Icon';

const AddCotCanToEpTeachers = ({
  enrolmentPlan,
  trainingPhaseIid,

  renderComponent,
}) => {
  const enrolmentPlanIid = lodashGet(enrolmentPlan, 'iid');

  return (
    <ApiRequestBtnWithConfirmDialog
      renderComponent={({ onClick }) => {
        if (typeof renderComponent === 'function') {
          return renderComponent({ onClick });
        }

        return (
          <RaisedButton
            primary
            label={t1('add_cot_can_to_enrolment_plan_teachers')}
            className="m-l-10 m-r-10"
            icon={<Icon icon="sync" />}
            onClick={onClick}
          />
        );
      }}
      url={bdtxEpApiUrls.add_cot_can_to_dai_tra_enrolment_plan_teachers}
      params={{
        enrolment_plan_iid: enrolmentPlanIid,
        training_phase_iid: trainingPhaseIid,
      }}
    />
  );
};

export default AddCotCanToEpTeachers;
