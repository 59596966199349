//cancelled | saved | sent | approved | rejected
export const ASSESSMENT_STATUS = {
  APPROVED: 'approved',
  QUEUED: 'queued',
  REJECTED: 'rejected',
};

export const TCNN_TYPE = {
  HT: 'tcnn_ht',
  GV: 'tcnn_gv',
};

export const SCHOOL_TYPE = {
  MN: 'mamnon',
  GVPT: 'gvpt',
};

export const ACCOUNT_STATUS = {
  ACTIVATED: 'activated',
  INACTIVE: 'inactive',
};

export const BECOME_MASTER_STATUS = {
  CANCELLED: 'cancelled',
  SAVED: 'saved',
  SENT: 'sent',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

export const REVIEW_STATUS = {
  [BECOME_MASTER_STATUS.SENT]: {
    label: 'Chưa duyệt',
    iconClass: 've-chuadengio',
    key: BECOME_MASTER_STATUS.SENT,
  },
  [BECOME_MASTER_STATUS.APPROVED]: {
    label: 'Đã duyệt',
    iconClass: 've-check-circle',
    key: BECOME_MASTER_STATUS.APPROVED,
  },
  [BECOME_MASTER_STATUS.REJECTED]: {
    label: 'Từ chối',
    key: BECOME_MASTER_STATUS.REJECTED,
    iconClass: 've-check-circle',
  },
};

export const ASSESS_ASSESSMENT_ROUTER = '/temis/assessment/assess-assessment';
export const ASSESS_ASSESSMENT_GROUP_ROUTER = {
  assign: `${ASSESS_ASSESSMENT_ROUTER}/assign`,
  peers: `${ASSESS_ASSESSMENT_ROUTER}/peers`,
  coworker: '/temis/assessment/coworker-assessment',
};

export const ASSESSMENTS_IN_ORGANIZATION_ROUTER =
  '/temis/assessment/assessments-in-organization';
export const ASSESSMENTS_IN_ORGANIZATION_GROUP_ROUTER = {
  my_assessment: `${ASSESSMENTS_IN_ORGANIZATION_ROUTER}/my-assessment`,
  assessment_my_peer: `${ASSESSMENTS_IN_ORGANIZATION_ROUTER}/assessment-my-peer`,
  all_assessment: `${ASSESSMENTS_IN_ORGANIZATION_ROUTER}/all-assessment`,
};

export const MANAGER_ASSESSMENT_ROUTER = '/temis/assessment/manager-assessment';
export const MANAGER_ASSESSMENT_GROUP_ROUTER = {
  school_in_organization: `${MANAGER_ASSESSMENT_ROUTER}/school-in-organization`,
  principal_assessment: `${MANAGER_ASSESSMENT_ROUTER}/principal-assessment`,
  approve_teacher: `${MANAGER_ASSESSMENT_ROUTER}/approve-teacher`,
  choose_manager: `${MANAGER_ASSESSMENT_ROUTER}/choose-manager`,
};

export const SUMMARY_ASSESSMENT_RESULT_ROUTER =
  '/temis/assessment/assessment-result';
export const SUMMARY_ASSESSMENT_RESULT_ROUTER_GROUP_ROUTER = {
  choose_teacher: `${SUMMARY_ASSESSMENT_RESULT_ROUTER}/choose-teacher`,
  school: `${SUMMARY_ASSESSMENT_RESULT_ROUTER}/school`,
};

export const REPORT_ROUTER = '/temis/report';
export const REPORT_GROUP_ROUTER = {
  cbql: `${REPORT_ROUTER}/cbql`,
  cbql_gvmn: `${REPORT_ROUTER}/cbql-gvmn`,
  cbql_cbmn: `${REPORT_ROUTER}/cbql-cbmn`,
  cbql_gvpt: `${REPORT_ROUTER}/cbql-gvpt`,
  cbql_cbpt: `${REPORT_ROUTER}/cbql-cbql`,
  cbql_gvmn_by_rubric: `${REPORT_ROUTER}/cbql-gvmn-by-rubric`,
  cbql_cbmn_by_rubric: `${REPORT_ROUTER}/cbql-cbmn-by-rubric`,
  cbql_gvpt_by_rubric: `${REPORT_ROUTER}/cbql-gvpt-by-rubric`,
  cbql_cbpt_by_rubric: `${REPORT_ROUTER}/cbql-cbpt-by-rubric`,
  cbql_orgainzation: `${REPORT_ROUTER}/cbql-organization`,
  cbql_tcnn_orgainzation: `${REPORT_ROUTER}/cbql-tcnn-organization`,
  cv: `${REPORT_ROUTER}/cv`,
  synthesis: `${REPORT_ROUTER}/synthesis-report`,
  detail_synthesis: `${REPORT_ROUTER}/detail-synthesis`,
  survey: `${REPORT_ROUTER}/survey`,
  reflect: `${REPORT_ROUTER}/reflect`,
  generate: `${REPORT_ROUTER}/general-report`,
  list_user: `${REPORT_ROUTER}/list-user`,
  ctbdtx_gvpt: `${REPORT_ROUTER}/ctbdtx-gvpt`,
  ctbdtx_cbql: `${REPORT_ROUTER}/ctbdtx-cbpt`,
  ncbdtx_gvpt: `${REPORT_ROUTER}/ncbdtx-gvpt`,
  ncbdtx_cbql: `${REPORT_ROUTER}/ncbdtx-cbql`,
  ncbdtx_gvmn: `${REPORT_ROUTER}/ncbdtx-gvmn`,
  ncbdtx_cbmn: `${REPORT_ROUTER}/ncbdtx-cbmn`,
  ncbdtx_plan_develop_gvmn: `${REPORT_ROUTER}/ncbdtx-plan-develop-gvmn`,
  ncbdtx_plan_develop_cbmn: `${REPORT_ROUTER}/ncbdtx-plan-develop-cbmn`,
  ncbdtx_plan_develop_gvpt: `${REPORT_ROUTER}/ncbdtx-plan-develop-gvpt`,
  ncbdtx_plan_develop_cbpt: `${REPORT_ROUTER}/ncbdtx-plan-develop-cbpt`,
  ncbdtx_by_module: `${REPORT_ROUTER}/ncbdtx-by-module`,
  result_bdtx: `${REPORT_ROUTER}/result-bdtx`,
  bdtx_result: `${REPORT_ROUTER}/ncbdtx-by-module`,
  detail: `${REPORT_ROUTER}/detail`,
  report_1a: `${REPORT_ROUTER}/report-1a`,
  report_1b: `${REPORT_ROUTER}/report-1b`,
  report_course: `${REPORT_ROUTER}/report-course`,
};

export const PROFILE_ROUTER = '/temis/profile';
export const PROFILE_GROUP_ROUTER = {
  update_info: `${PROFILE_ROUTER}/update-info`,
  update_avatar: `${PROFILE_ROUTER}/update-avatar`,
  change_password: `${PROFILE_ROUTER}/change-password`,
  user_settings: `${PROFILE_ROUTER}/user-settings`,
};

export const SURVEY_MANAGEMENT_ROUTER = '/temis/management-survey';
export const ACCOUNT_ROUTER = '/temis/account';
export const COTCAN_ACCOUNT_ROUTER = '/temis/cotcan';
export const IMPORT_IDENTIFICATION_CODE_ROUTER =
  '/temis/account/import-identification-code';
export const CATEGORY_ROUTER = '/temis/category';

export const MENUS_HAS_TAB = [
  ASSESS_ASSESSMENT_ROUTER,
  ASSESSMENTS_IN_ORGANIZATION_ROUTER,
  MANAGER_ASSESSMENT_ROUTER,
  SUMMARY_ASSESSMENT_RESULT_ROUTER,
  REPORT_ROUTER,
  PROFILE_ROUTER,
  ACCOUNT_ROUTER,
  COTCAN_ACCOUNT_ROUTER,
  CATEGORY_ROUTER,
];

export const EXPORT_FILENAME = {
  teacher: {
    BM1_PL2: 'Bieu_mau_01_Phieu_tu_danh_gia_cua_giao_vien_Co_So_giao_duc',
    BM3_PL2:
      'Bieu_mau_03_Bang_tong_hop_ket_qua_danh_gia_cua_dong_nghiep_trong_to_chuyen_mon',
  },
  principal: {
    BM1_PL2: 'Bieu_mau_01_Phieu_CBQL_tu_danh_gia',
    BM4_PL2_4529: 'Bieu_mau_04_Phieu_cap_tren_danh_gia_CBQL',
    BM4_PL2:
      'Bieu_mau_04_Bang_tong_hop_ket_qua_danh_gia_giao_vien_cua_co_so_giao_duc',
    GV:
      'Bieu_mau_04_Bang_tong_hop_ket_qua_danh_gia_giao_vien_cua_co_so_giao_duc',
    HT:
      'Bieu_mau_04_Bang_tong_hop_ket_qua_danh_gia_hieu_truong_cua_co_so_giao_duc',
    HP:
      'Bieu_mau_04_Bang_tong_hop_ket_qua_danh_gia_hieu_pho_cua_co_so_giao_duc',
    BM1_PL3: 'Bieu_mau_01_Bao_cao_tong_hop_ket_qua_danh_gia_giao_vien_TCNN',
  },
  admin: {
    BM2_PL3: 'Bieu_mau_02_Bao_cao_tong_hop_ket_qua_danh_gia_giao_vien',
    BM3_PL3: 'Bieu_mau_03_Bao_cao_tong_hop_ket_qua_danh_gia_giao_vien',
    BM1_PL3_4529:
      'Bieu_mau_01_Tong_hop_ket_qua_danh_gia_Hieu_truong_Pho_hieu_truong_theo_chuan_Hieu_truong_truong_pho_thong',
  },
  overallResultAssessmentByTCNNGV:
    'Tong_hop_ket_qua_danh_gia_va_xep_loai_theo_tieu_chuan_nghe_nghiep_giao_vien',
  overallResultAssessmentByTCNNHT:
    'Tong_hop_ket_qua_danh_gia_va_xep_loai_theo_tieu_chuan_nghe_nghiep_hieu_truong',
  overallResultAssessmentByTCNNGVByOrganization:
    'Tong_hop_ket_qua_danh_gia_va_xep_loai_theo_tieu_chuan_nghe_nghiep_giao_vien_theo_don_vi',
  overallResultAssessmentByTCNNHTByOrganization:
    'Tong_hop_ket_qua_danh_gia_va_xep_loai_theo_tieu_chuan_nghe_nghiep_hieu_truong_theo_don_vi',
  resultAssessmentGV: 'Tong_hop_ket_qua_tu_danh_gia_cua_giao_vien',
  resultAssessmentCSGDPT: 'Tong_hop_ket_qua_tu_danh_gia_cua_CSGD',
  resultOverall: 'Tong_hop_bao_cao',
  surveysOnline: {
    gvpt: 'Ket_qua_tra_loi_phieu_khao_sat_truc_tuyen_cua_giao_vien_pho_thong',
    cbql: 'Ket_qua_tra_loi_phieu_khao_sat_truc_tuyen_cua_CBQL_CSGD',
  },
  trainingNeedsByRubric: {
    generalTrainingNeedsOfGVPT:
      'Bao_cao_nhu_cau_boi_duong_dua_tren_danh_gia_giao_vien_theo_chuan_nghe_nghiep',
    generalTrainingNeedsOfCBQL:
      'Bao_cao_nhu_cau_boi_duong_dua_tren_danh_gia_CBQL_CSGD_theo_chuan_hieu_truong',
    planDevelopmentOfGVPT:
      'Ke_hoach_học_tap_boi_duong_phat_trien_nang_luc_nghe_nghiep_trong_nam_hoc_tiep_theo_cua_giao_vien',
    planDevelopmentOfCBQL:
      'Ke_hoach_học_tap_boi_duong_phat_trien_nang_luc_nghe_nghiep_trong_nam_hoc_tiep_theo_cua_CBQL_CSGD',
  },
};

export const VIEWER_FILE_EXTENSIONS = ['mp4', 'jpg', 'png', 'pdf'];

export const NOTIFICATION_ROUTE = '/temis/notifications';
export const SENT_NOTIFICATION_ROUTE = '/temis/sent-notifications';

export const SURVEY_ROUTE = '/temis/survey';
export const SURVEY_LIST_ROUTE = '/temis/survey/list';
export const SURVEY_NCBDTX_ROUTE = '/temis/survey/ncbdtx';
export const SURVEY_TAKE_ROUTE = '/temis/survey/take/:surveyIid';

export const TEMIS_LEARN = '/temis/learn';
export const TEMIS_BDTX_MODULE_LEARNING_RESULTS =
  '/temis/learn/bdtx-module-learning-results';
export const TEMIS_LEARN_IN_PROGRESS = '/temis/learn/in-progress-courses';
export const TEMIS_LEARN_COMPLETED_COURSES = '/temis/learn/completed-courses';
export const TEMIS_LEARN_FAILED_COURSES = '/temis/learn/failed-courses';
export const TEMIS_LEARN_PUBLIC_COURSE = '/temis/learn/public-courses';

export const TEMIS_LEARN_MATERIALS = '/temis/learn/materials';
