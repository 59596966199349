import React from 'react';
import { t1 } from 'translate';
import { organizations } from 'components/admin/organization/schema/elements';
import { required } from 'common/validators';
import get from 'lodash.get';
import taphuanSubTypes from 'configs/constants/org-sub-types';
import { isBoOrAdminSP, isSo } from 'components/admin/user/utils';
import { evidenceElement } from 'components/temis/common/schema-elements';
import { isIVAUser } from 'components/temis/utils';
import { academicCategories } from 'components/admin/academic-category/schema/elements';

const orgTypesReached = [taphuanSubTypes.TAPHUAN_SUBTYPE_SO_GD];

const schema = (formid, values, step, xpath, props) => ({
  organizations: organizations({
    formid,
    multiple: false,
    floatingLabelText: t1('display_template'),
    defaultValue: props.orgIids,
    validate: required(t1('organization_can_not_empty')),
    ...(isBoOrAdminSP(props.userInfo)
      ? { orgTypesReached: orgTypesReached }
      : {}),
    ...(isSo(props.userInfo)
      ? {
          subTypesDiscarded: [
            taphuanSubTypes.TAPHUAN_SUBTYPE_TIEUHOC,
            taphuanSubTypes.TAPHUAN_SUBTYPE_THCS,
            taphuanSubTypes.TAPHUAN_SUBTYPE_LIENCAP,
            taphuanSubTypes.TAPHUAN_SUBTYPE_LTTU,
            taphuanSubTypes.TAPHUAN_SUBTYPE_MAM_NON,
            taphuanSubTypes.TAPHUAN_SUBTYPE_GIAODUC_THUONGXUYEN,
            taphuanSubTypes.TAPHUAN_SUBTYPE_TUTHUC_LIENCAP,
          ],
        }
      : {}),
  }),
  evidence: evidenceElement(),
  academic_categories: academicCategories(formid, {
    label: t1('temis_academic_categories'),
    multiple: true,
    apiParams: {
      view: 'tree',
      type: ['academic'],
      items_per_page: -1,
    },
    alternativeApi: '/category/search',
    keyState: `user_academic_categories_iids`,
  }),
  survey_completion_statuses: {
    fullWidth: true,
    type: 'multiCheckbox',
    inline: true,
    floatingLabelText: t1('survey_completion_statuses'),
    hintText: t1('survey_completion_statuses'),
    options: [
      {
        value: 'has_survey_take',
        label: t1('completed_survey_status'),
        primaryText: t1('completed_survey_status'),
      },
      {
        value: 'not_yet_survey_take',
        label: t1('not_yet_survey_take_status'),
        primaryText: t1('not_yet_survey_take_status'),
      },
    ],
  },
  user_learning_types: {
    fullWidth: true,
    type: 'multiCheckbox',
    inline: true,
    floatingLabelText: t1('user_learning_types'),
    hintText: t1('user_learning_types'),
    options: [
      {
        value: 'cotcan',
        label: t1('user_learning_cotcan'),
        primaryText: t1('user_learning_cotcan'),
      },
      {
        value: 'daitra',
        label: t1('user_learning_daitra'),
        primaryText: t1('user_learning_daitra'),
      },
    ],
  },
});

const ui = (step, values, themeConfig, xpath, formid, props) => {
  const { isReportOfTruong, customFilter } = props;

  return [
    {
      id: 'default',
      fields: [
        !isReportOfTruong && 'organizations',
        customFilter && 'academic_categories',
        customFilter && 'survey_completion_statuses',
        customFilter && 'user_learning_types',
        !isIVAUser() && 'evidence',
      ],
    },
  ];
};

const LayoutFreeStyle = ({ groups, submitButton }) => {
  const organizationFieldName = get(groups, 'default.fieldNames.organizations');
  const academicCategories = get(
    groups,
    'default.fieldNames.academic_categories',
  );
  const surveyCompletionStatus = get(
    groups,
    'default.fieldNames.survey_completion_statuses',
  );
  const userLearningType = get(
    groups,
    'default.fieldNames.user_learning_types',
  );

  const evidenceFieldName = get(groups, 'default.fieldNames.evidence');

  return (
    <>
      <div className="row">
        {!!organizationFieldName && (
          <div className="col-md-6">{organizationFieldName}</div>
        )}
        {!!evidenceFieldName && (
          <div className="col-md-6">{evidenceFieldName}</div>
        )}
      </div>

      <div className="row">
        {!!surveyCompletionStatus && (
          <div className="col-md-6">{surveyCompletionStatus}</div>
        )}

        {!!userLearningType && (
          <div className="col-md-6">{userLearningType}</div>
        )}

        {!!academicCategories && (
          <div className="col-md-12">{academicCategories}</div>
        )}
      </div>

      {!!submitButton && <div className="text-center">{submitButton}</div>}
    </>
  );
};

const layout = () => ({
  component: LayoutFreeStyle,
  freestyle: 1,
});

export default {
  schema,
  ui,
  layout,
};
