import { isBDTX } from 'utils/Util';
import { t1 } from 'translate';

export const USER_GROUP_ALL = 'all_users';
export const USER_GROUP_DTTS = 'dtts';
export const USER_GROUP_FEMALE = 'female';
export const USER_GROUP_FEMALE_DTTS = 'female_dtts';
export const USER_GROUP_DIA_BAN_KHOKHAN = 'dia_ban_khokhan';
export const USER_GROUP_KHOI_1 = 'khoi_1';
export const USER_GROUP_KHOI_2 = 'khoi_2';
export const USER_GROUP_KHOI_3 = 'khoi_3';
export const USER_GROUP_KHOI_4 = 'khoi_4';
export const USER_GROUP_KHOI_5 = 'khoi_5';
export const USER_GROUP_KHOI_6 = 'khoi_6';
export const USER_GROUP_KHOI_7 = 'khoi_7';
export const USER_GROUP_KHOI_8 = 'khoi_8';
export const USER_GROUP_KHOI_9 = 'khoi_9';
export const USER_GROUP_KHOI_10 = 'khoi_10';
export const USER_GROUP_KHOI_11 = 'khoi_11';
export const USER_GROUP_KHOI_12 = 'khoi_12';

export default () => {
  if (window.isETEP || isBDTX())
    return [
      USER_GROUP_ALL,
      USER_GROUP_FEMALE,
      USER_GROUP_FEMALE_DTTS,
      USER_GROUP_DTTS,
      USER_GROUP_DIA_BAN_KHOKHAN,
    ];
  else return [USER_GROUP_ALL];
};

export const tableTitles = {
  [USER_GROUP_ALL]: 'Tổng hợp',
  [USER_GROUP_DTTS]: t1('ethnic_minority'),
  [USER_GROUP_FEMALE]: 'Nữ',
  [USER_GROUP_FEMALE_DTTS]: 'Nữ DTTS',
  [USER_GROUP_DIA_BAN_KHOKHAN]: t1('difficult_area'),
};

export const demographicSurveyReportCheckboxes = () =>
  window.isETEP
    ? [
        {
          label: 'Tổng hợp',
          value: USER_GROUP_ALL,
        },
        {
          label: 'Xem dữ liệu giáo viên Nữ',
          value: USER_GROUP_FEMALE,
        },
        {
          label: 'Xem dữ liệu giáo viên DTTS',
          value: USER_GROUP_DTTS,
        },
        {
          label: 'Xem dữ liệu giáo viên Nữ DTTS',
          value: USER_GROUP_FEMALE_DTTS,
        },
        {
          label: 'Xem dữ liệu địa bàn khó khăn',
          value: USER_GROUP_DIA_BAN_KHOKHAN,
        },
      ]
    : [];

export const demographicCheckboxes = () =>
  window.isETEP
    ? [
        {
          label: tableTitles[USER_GROUP_ALL],
          value: USER_GROUP_ALL,
        },
        {
          label: 'Nữ',
          value: USER_GROUP_FEMALE,
        },
        {
          label: t1('ethnic_minority_short_text'),
          value: USER_GROUP_DTTS,
        },
        {
          label: 'Nữ DTTS',
          value: USER_GROUP_FEMALE_DTTS,
        },
        {
          label: t1('difficult_area'),
          value: USER_GROUP_DIA_BAN_KHOKHAN,
        },
      ]
    : [];
