export const SHOW_SUPPORT_USER_INFO_NOTIFICATION =
  'SHOW_SUPPORT_USER_INFO_NOTIFICATION';
export const HIDE_SUPPORT_USER_INFO_NOTIFICATION =
  'HIDE_SUPPORT_USER_INFO_NOTIFICATION';

export const RESET_SUPPORT_USER_INFO_NOTIFICATION_OPEN_STATE =
  'RESET_SUPPORT_USER_INFO_NOTIFICATION_OPEN_STATE';

export const showSupportUserInfoNotification = () => ({
  type: SHOW_SUPPORT_USER_INFO_NOTIFICATION,
});

export const hideSupportUserInfoNotification = (turnOff, skipped) => ({
  type: HIDE_SUPPORT_USER_INFO_NOTIFICATION,
  turnOff,
  skipped,
});

export const resetShowSupportUserInfoNotificationOpenState = () => ({
  type: RESET_SUPPORT_USER_INFO_NOTIFICATION_OPEN_STATE,
});
