export const VIEW_MODE = {
  WATCH: 'watch',
  READ: 'read',
  INFO_GRAPHIC: 'info_graphic',
};

export const ITEM_TYPE = {
  VIDEO: 'video',
  SCORM: 'scorm',
  LINK: 'link',
  H5P: 'h5p',
};

export const isReadMode = (lecture) =>
  lecture.sco_iid && lecture.watch_or_learn === VIEW_MODE.READ;

export const isInfoGraphicMode = (lecture) =>
  lecture.infographic_sco_iid &&
  lecture.watch_or_learn === VIEW_MODE.INFO_GRAPHIC;

export const isWatchMode = (lecture) =>
  !isReadMode(lecture) && !isInfoGraphicMode(lecture);
