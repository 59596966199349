import React from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import { t1 } from 'translate';
import schema from './schema';
import UserResults, {
  columnIds,
} from 'components/admin/user/account/common/AntdSearchResult';
import lodashGet from 'lodash.get';
import AntSelectField from 'schema-form/elements/select/AntdSelectField';
import { userGradeOptions, userGradeToShortText } from 'configs/constants/user';
import fetchData from 'components/common/fetchData';
import bdtxCotCanTpApiUrls from 'components/bdtx/training-phase/mainstage/training-model-cot-can/endpoints';
import Button from 'components/common/primary-button';
import Icon from 'components/common/Icon';
import ApiRequestBtnWithConfirmDialog from 'components/common/action-button/ApiRequestBtnWithConfirmDialog';
import { required } from 'common/validators';
import Warning from 'components/common/Warning';

const Results = ({
  academicCategoriesByGrade,
  items,
  searchFormId,
  trainingPlan,
}) => {
  items = items || [];
  const userById = items.reduce((res, user) => {
    return {
      ...res,
      [lodashGet(user, 'id')]: user,
    };
  }, {});

  const getDefaultLearningData = React.useCallback(
    (items) => {
      return items.reduce((res, row) => {
        const userId = lodashGet(row, 'id');
        const possibleGrades = lodashGet(row, '__expand.possible_caps') || [];

        const defaultGrade =
          possibleGrades.length === 1 ? possibleGrades[0] : null;

        let defaultAcademicCategoryIid = null;
        if (
          defaultGrade &&
          lodashGet(academicCategoriesByGrade, [defaultGrade, 'length']) === 1
        ) {
          defaultAcademicCategoryIid = lodashGet(academicCategoriesByGrade, [
            defaultGrade,
            0,
            'iid',
          ]);
        }

        return {
          ...res,
          [userId]: {
            ...(defaultGrade ? { grade: defaultGrade } : {}),
            ...(defaultAcademicCategoryIid
              ? { academic_category_iid: defaultAcademicCategoryIid }
              : {}),
          },
        };
      }, {});
    },
    [academicCategoriesByGrade],
  );

  const [learningData, setLearningData] = React.useState(
    getDefaultLearningData(items),
  );
  const [selectedUserIds, setSelectedUserIds] = React.useState([]);

  React.useEffect(
    () => {
      setLearningData(getDefaultLearningData(items));
    },
    [items, getDefaultLearningData],
  );

  const getFieldValue = (userId, field) => {
    return lodashGet(learningData, [userId, field]);
  };

  const setFieldValue = (userId, field, newValue) => {
    setLearningData(
      Object.assign({}, learningData, {
        [userId]: {
          ...(lodashGet(learningData, [userId]) || {}),
          [field]: newValue,
        },
      }),
    );
  };

  const getSelectedGrade = (userId) => {
    return getFieldValue(userId, 'grade');
  };

  const onGradeSelect = (userId, newGrade) => {
    setFieldValue(userId, 'grade', newGrade);
  };

  const getSelectedAcademicCategoryIid = (userId) => {
    return getFieldValue(userId, 'academic_category_iid');
  };

  const onAcademicCategorySelect = (userId, newCategoryIid) => {
    setFieldValue(userId, 'academic_category_iid', newCategoryIid);
  };

  const getSelectedExtraGradesAcademicCategories = (userId) => {
    return getFieldValue(userId, 'extra_grades_academic_categories');
  };

  const getSelectedAcademicCategoryIidOfExtraGrade = (userId, grade) => {
    return lodashGet(getSelectedExtraGradesAcademicCategories(userId), grade);
  };

  const onExtraGradeAcademicCategorySelect = (
    userId,
    grade,
    newCategoryIid,
  ) => {
    const oldValue = getSelectedExtraGradesAcademicCategories(userId);

    setFieldValue(
      userId,
      'extra_grades_academic_categories',
      Object.assign({}, oldValue, {
        [grade]: newCategoryIid,
      }),
    );
  };

  const getGradeError = (userId) => {
    if (!selectedUserIds.includes(userId)) {
      return undefined;
    }
    const selectedGrade = getSelectedGrade(userId);
    return required()(selectedGrade);
  };

  const getAcademicCategoryError = (userId) => {
    if (!selectedUserIds.includes(userId)) {
      return undefined;
    }
    const cateIid = getSelectedAcademicCategoryIid(userId);
    return required()(cateIid);
  };

  const anyError = selectedUserIds.some((userId) => {
    return getGradeError(userId) || getAcademicCategoryError(userId);
  });

  const getAcademicCategoriesOptions = (grade, includeNone) => {
    let options = (lodashGet(academicCategoriesByGrade, grade) || []).map(
      (cate) => {
        return {
          value: lodashGet(cate, 'iid'),
          label: lodashGet(cate, 'name'),
        };
      },
    );

    if (includeNone) {
      options = [
        {
          value: null,
          label: t1('no_academic_category'),
        },
      ].concat(options);
    }

    return options;
  };

  return (
    <UserResults
      onSelectedUsersChange={(userIds) => {
        setSelectedUserIds(userIds);
      }}
      items={items}
      addExtraColumns={(columns) => {
        return columns.concat([
          {
            title: 'Cấp đào tạo cốt cán',
            key: 'grade',
            render: (row) => {
              const userId = lodashGet(row, 'id');

              return (
                <AntSelectField
                  errorText={getGradeError(userId)}
                  options={userGradeOptions().filter((opt) =>
                    (lodashGet(row, '__expand.possible_caps') || []).includes(
                      lodashGet(opt, 'value'),
                    ),
                  )}
                  value={getSelectedGrade(userId)}
                  onChange={(newGrade) => onGradeSelect(userId, newGrade)}
                />
              );
            },
          },
          {
            title: 'Môn đào tạo cốt cán',
            key: 'academic_category',
            render: (row) => {
              const userId = lodashGet(row, 'id');
              const grade = getSelectedGrade(userId);

              return (
                <AntSelectField
                  errorText={getAcademicCategoryError(userId)}
                  options={getAcademicCategoriesOptions(grade)}
                  value={getSelectedAcademicCategoryIid(userId)}
                  onChange={(newCategoryIid) =>
                    onAcademicCategorySelect(userId, newCategoryIid)
                  }
                />
              );
            },
          },
          {
            title: 'Thêm môn đào tạo cho các cấp khác',
            key: 'other_grade_academic_categories',
            render: (row) => {
              const userId = lodashGet(row, 'id');
              const selectedGrade = getSelectedGrade(userId);
              if (!selectedGrade) {
                return null;
              }

              return userGradeOptions().map((opt) => {
                const grade = lodashGet(opt, 'value');
                if (grade === selectedGrade) {
                  return null;
                }

                return (
                  <div key={grade}>
                    {userGradeToShortText(grade)}:
                    <AntSelectField
                      options={getAcademicCategoriesOptions(grade, true)}
                      value={getSelectedAcademicCategoryIidOfExtraGrade(
                        userId,
                        grade,
                      )}
                      onChange={(newCategoryIid) =>
                        onExtraGradeAcademicCategorySelect(
                          userId,
                          grade,
                          newCategoryIid,
                        )
                      }
                    />
                  </div>
                );
              });
            },
          },
        ]);
      }}
      renderAfter={({ selectedRowKeys: userIds }) => {
        const numberOfSelectedUsers = (userIds || []).length;
        const usersData = userIds.reduce((res, uId) => {
          return res.concat({
            user_iid: lodashGet(userById[uId], 'iid'),
            grade: getSelectedGrade(uId),
            academic_category_iid: getSelectedAcademicCategoryIid(uId),
            extra_grades_academic_categories: getSelectedExtraGradesAcademicCategories(
              uId,
            ),
          });
        }, []);

        return (
          <ApiRequestBtnWithConfirmDialog
            formidToSubmitOnSuccess={searchFormId}
            renderComponent={({ onClick }) => {
              return (
                <>
                  <Button
                    disabled={!numberOfSelectedUsers || anyError}
                    label={`Thêm ${numberOfSelectedUsers} học viên`}
                    onClick={onClick}
                    icon={<Icon icon="add" />}
                    className="m-r-10 m-t-10 btn-primary"
                  />
                  {anyError ? (
                    <Warning>{t1('please_check_form_errors')}</Warning>
                  ) : null}
                </>
              );
            }}
            url={bdtxCotCanTpApiUrls.quick_add_planned_users}
            params={{
              training_plan_iid: lodashGet(trainingPlan, 'iid'),
              users_data: usersData,
            }}
            textConfirm={`Thêm ${numberOfSelectedUsers} học viên`}
          />
        );
      }}
      resetSelectionWhenItemIdsChange
      columnsToShow={[
        columnIds.CODE,
        columnIds.NAME,
        columnIds.MAIL,
        columnIds.ORGANIZATION,
      ]}
    />
  );
};

const renderResults = (items, props, objects, searchValues, resultId) => {
  const { academicCategoriesByGrade, formid, trainingPlan } = props;

  return (
    <Results
      items={items}
      academicCategoriesByGrade={academicCategoriesByGrade}
      searchFormId={formid}
      trainingPlan={trainingPlan}
    />
  );
};

const AdditionalPlannedUsers = ({
  trainingPlan,
  academicCategoriesByGrade,
}) => {
  const searchFormId = `quick_add_planned_users_${trainingPlan.iid}`;
  if (!academicCategoriesByGrade) {
    return null;
  }

  return (
    <SearchWrapper
      resultsRerenderTrigger={[trainingPlan]}
      trainingPlan={trainingPlan}
      formid={searchFormId}
      academicCategoriesByGrade={academicCategoriesByGrade}
      renderResultsComponent={renderResults}
      alternativeApi={
        bdtxCotCanTpApiUrls.search_users_to_quick_add_planned_users
      }
      noResultText={t1('no_users')}
      method={'post'}
      schema={schema}
      hiddenFields={{
        training_plan_iid: lodashGet(trainingPlan, 'iid'),
      }}
      hidePagination
    />
  );
};

export default fetchData((props) => {
  const { trainingPlan } = props;
  const trainingPlanIid = lodashGet(trainingPlan, 'iid');

  return {
    baseUrl:
      bdtxCotCanTpApiUrls.get_academic_categories_by_grade_to_add_planned_users,
    params: {
      training_plan_iid: trainingPlanIid,
    },
    propKey: 'academicCategoriesByGrade',
    fetchCondition: trainingPlanIid,
    shouldRenderLoading: true,
  };
})(AdditionalPlannedUsers);
