import { convertBooleanValueToInt } from 'common/normalizers';
import t1 from 'translate';

const shouldSkipQuota = (props = {}) => ({
  ...props,
  type: 'checkbox',
  label: t1('should_skip_quota'),
  normalize: convertBooleanValueToInt,
});

export default shouldSkipQuota;
