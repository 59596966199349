import React from 'react';
import './stylesheet.scss';

const TotalPoint = ({
  point,
  showTotalPoint = true,
  size = 'normal',
  type = 'normal',
  highlight = true,
}) => {
  const roundingPoint = Math.round(point);

  return (
    <span
      className={`total-point total-point--${size} total-point--type-${type} ${
        highlight ? 'highlight' : ''
      }`}
    >
      <span className="total-point__value">{roundingPoint || 0}</span>
      {showTotalPoint && <span>/100</span>}
    </span>
  );
};

export default TotalPoint;
