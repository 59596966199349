import fetchData from 'components/common/fetchData';
import get from 'lodash.get';
import endpoints from './endpoints';

export const fetchExamTemplate = fetchData((props) => {
  const examIidFromParams = get(props, 'match.params.iid');
  const examIid = get(props, 'examIid');

  return {
    baseUrl: endpoints.bdtx_get_exam_template,
    params: {
      iid: examIid || examIidFromParams,
    },
    method: 'get',
    propKey: 'node',
    fetchCondition: true,
    fetchFunctionPropKey: 'refetchExamTemplate',
  };
});
