import React, { Component } from 'react';
import { connect } from 'react-redux';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import apiUrls from 'api-endpoints';
import Results from './Results';
import EtepResult from './Results-ETEP';
import schemaAdvance from './schema/schema-form-advance';
import { handleOpenNewCourse } from '../new/ButtonNew';
import Permission from 'components/common/permission/Permission';
import { CourseActions } from 'configs/constants/permission';
import commonSagaActions from 'actions/saga-creators';
import withSchoolConfigs from 'common/hoc/withSchoolConfigs';
import withFeatureFlags from 'feature-flag/withFeatureFlags';
import lodashGet from 'lodash.get';

const formid = 'course_search';

class SearchForm extends Component {
  constructor(props) {
    super(props);
  }

  getParamsForCheckPermission = () => {
    return {
      actions: CourseActions,
      type: 'course',
    };
  };

  componentDidMount() {
    const { action, dispatch, history } = this.props;
    if (action === 'new') {
      handleOpenNewCourse({ dispatch, searchFormId: formid, history });
    }
  }

  renderResultComponent = (items, props) => {
    const {
      renderActionCell,
      resultReadOnly,
      permissions,
      hasPermission,
    } = this.props;

    if (window.isETEP) {
      return (
        <EtepResult
          items={items}
          {...props}
          readOnly={resultReadOnly}
          renderActionCell={renderActionCell}
          permissions={permissions}
          hasPermission={hasPermission}
        />
      );
    }

    return (
      <Results
        items={items}
        {...props}
        readOnly={resultReadOnly}
        renderActionCell={renderActionCell}
        permissions={permissions}
        hasPermission={hasPermission}
      />
    );
  };

  handleExport = () => {
    const { dispatch } = this.props;
    dispatch(
      commonSagaActions.exportDataRequest({
        url: apiUrls.export_course,
        params: {},
      }),
    );
  };

  render() {
    const formid = this.props.formid || 'course_search';
    const { alternativeApi, defaultStatuses } = this.props;

    let { hiddenFields } = this.props;
    hiddenFields = Object.assign(
      {
        requireOrganization: 1,
        _sand_expand: ['number_of_students'],
      },
      hiddenFields,
    );

    return (
      <SearchWrapper
        schema={schemaAdvance}
        showSearchButton={false}
        autoSearchWhenStart
        {...this.props}
        formid={formid}
        renderResultsComponent={
          this.props.renderResultsComponent || this.renderResultComponent
        }
        showQueryField
        ntype="course"
        hiddenFields={hiddenFields}
        alternativeApi={alternativeApi || apiUrls.course_search}
        classPaginationWrapper="pagination-default"
        defaultStatuses={defaultStatuses}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  let includeSubOrg = lodashGet(
    state,
    `valueFieldsToPopulateDefault.${props.formid ||
      'course_search'}.include_sub_organizations`,
  );

  const defaultSearchSubOrgFromConfig = lodashGet(
    state,
    'domainInfo.conf.default_search_sub_organizations.course',
  );

  if (typeof includeSubOrg === 'undefined') {
    includeSubOrg = defaultSearchSubOrgFromConfig;
  }

  return {
    action: props.match && props.match.params && props.match.params.action,
    includeSubOrg,
  };
};

export default connect(mapStateToProps)(
  withSchoolConfigs(withFeatureFlags()(Permission(SearchForm))),
);
