import React from 'react';
import lodashGet from 'lodash.get';
import { getNodeSelector } from 'components/admin/node/utils';
import { connect } from 'react-redux';
import WatchOrReadWrapper from 'components/learn/watch-or-read';
import Heading from 'components/learn/items/sco-spl/common/Heading';
import { isWatchMode, ITEM_TYPE } from 'components/learn/watch-or-read/utils';
import H5PPlayer from 'components/common/media-player/h5p';

const BlockH5P = ({ lecture, course, showInScoSpl, hiddenHeading = false }) => {
  if (!lecture) {
    return null;
  }

  const watchMode = isWatchMode(lecture);

  return (
    <WatchOrReadWrapper
      item={lecture}
      course={course}
      showInScoSpl={showInScoSpl}
      itemType={ITEM_TYPE.H5P}
    >
      {!hiddenHeading && watchMode && (
        <div className={'video-heading block-item-heading'}>
          <i className="ve-play" />
          <Heading item={lecture} />
        </div>
      )}

      <div className="video-wrapper video-wrapper-ratio">
        <div className="video">
          <H5PPlayer h5pJsonPath={lecture.h5pJsonPath} item={lecture} />
        </div>
      </div>
    </WatchOrReadWrapper>
  );
};

const mapStateToProps = (state, props) => {
  const lecture = props.item;
  if (!lecture) {
    return;
  }

  const iid = lodashGet(lecture, 'iid');
  const pid = lodashGet(lecture, 'pid');

  return {
    lecture: getNodeSelector(state)(iid, pid) || {},
  };
};

export default connect(mapStateToProps)(BlockH5P);
