import get from 'lodash.get';
import { caphoc, targetUser } from 'components/common/elements';
import subTypes from 'configs/constants/org-sub-types';
import { targetUserOptions } from 'configs/constants/user';
import { taphuanCategoryElement } from 'components/admin/training-plan/schema/element/taphuan-category';
import trainingPlanSelectBoxElement from 'components/admin/training-plan/schema/element/training-plan-select-box';
import { simpleOrganization } from 'components/admin/organization/schema/elements';

const schema = (formid, values) => {
  return {
    organization_iid: simpleOrganization(
      {
        floatingLabelText: 'SGD',
        classWrapper: 'col-md-12',
      },
      {
        value: {
          sub_type: [subTypes.TAPHUAN_SUBTYPE_SO_GD],
        },
      },
      false,
      {
        value: '',
        label: 'Tất cả đơn vị',
      },
    ),
    tp_category: taphuanCategoryElement({
      forSearch: false,
      saveValueAsString: true,
      daitra: true,
      classWrapper: 'col-md-6',
      floatingLabelText: 'Mô đun ETEP đại trà',
    }),
    training_plan_iids: trainingPlanSelectBoxElement({
      formid,
      category: get(values, 'tp_category'),
      classWrapper: 'col-md-6',
      organizations: [get(values, 'organization_iid')],
    }),
    caps: caphoc({
      classWrapper: 'col-md-8',
    }),
    target_users: targetUser({
      defaultValue: targetUserOptions().map((target) => target.value),
      classWrapper: 'col-md-4',
    }),
  };
};

const ui = () => {
  return [
    {
      id: 'default',
      fields: [
        'organization_iid',
        'tp_category',
        'training_plan_iids',
        'target_users',
        'caps',
      ],
    },
  ];
};

export default {
  schema,
  ui,
};
