import React from 'react';
import DisplayHtml from 'components/common/html';
import styled from 'styled-components';
import bg from './quote-bg.png';
import get from 'lodash.get';
import './stylesheet.scss';

const Quote = styled.div`
  background-image: url(${bg});
  background-size: cover;
  * {
    color: #fff !important;
  }
`;

const QuotationMark = styled.div`
  font-family: Georgia, serif;
  display: flex;
  align-items: center;
  
  &:before {
    content: "\\201C";
    font-size: 6em;
    height: 0.5em;
    line-height: 1;
`;

const Content = styled.p`
  font-weight: 700;
  text-align: justify;
`;

const Indicator = styled.div`
  margin-top: 10px;
  width: 50px;
  border-top: 5px solid #fff;
`;

const Author = styled.div`
  margin-top: 7px;
  font-weight: 500;
`;

const ClassyQuote = ({ item = {} }) => {
  return (
    <Quote className="quote-content">
      <QuotationMark />
      <Content>
        <DisplayHtml content={item.content || ''} />
      </Content>
      <Indicator />
      {!get(item, 'block_settings.hide_heading') ? (
        <Author>{get(item, 'block_quote.sage') || item.name}</Author>
      ) : null}
    </Quote>
  );
};

export default ClassyQuote;
