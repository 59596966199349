import React, { Fragment } from 'react';
import './style.scss';

const PageTitle = ({
  title = '',
  type = 'default',
  className = '',
  children,
  style,
}) => {
  if (!title) {
    return null;
  }
  return (
    <h1
      className={`page-title page-title--${type} ${className}`}
      {...(style ? { style } : {})}
    >
      {title}
      {children && <Fragment>{children}</Fragment>}
    </h1>
  );
};

export default PageTitle;
