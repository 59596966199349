/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import t1 from 'translate';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { compose } from 'redux';
import AttendanceRegisteringToVirtualDialog from '../search/AttendanceRegisteringToVirtualDialog';
import sagaActions from 'actions/saga-creators';
import Icon from 'components/common/Icon';
import 'layouts/stylesheet.scss';
import './LHAStyle.scss';

const LHAWithAttendaceRegistering = (props) => {
  const [isShowDialog, setShowDialog] = useState(false);
  const [isShowButtonAttendance, setShowButtonAttendance] = useState(true);

  const { location = {}, dispatch } = props;
  const queryParams = queryString.parse(location.search) || {};
  const { url = '', sessionIid = '', remainingTime = 0 } = queryParams;

  const showDialogAttendanceRegister = () => {
    setShowDialog(true);
  };
  const hideDialogAttendanceRegister = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    dispatch(sagaActions.getTranslations('vn'));
    // count down to hide button attendance when LHA is finished
    let timer = parseInt(remainingTime);
    const interval = setInterval(() => {
      timer -= 1;
      if (timer <= 0) {
        setShowButtonAttendance(false);
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div style={{ width: '100%', height: '100%' }} className={'theme-vt-light'}>
      <iframe
        id="appFrame"
        src={url}
        frameBorder="0"
        loading="lazy"
        height="100%"
        width="100%"
        allow="camera ;microphone ;fullscreen"
        allowfullscreen="true"
        allowusermedia="true"
      />
      {isShowButtonAttendance && (
        <div style={{ position: 'absolute', top: 10 }}>
          <button
            className="btn btn-secondary lha-attendance-button m-b-10 m-l-10"
            onClick={showDialogAttendanceRegister}
          >
            <Icon icon={'check'} /> {t1('attendance_register')}
          </button>
        </div>
      )}
      <Modal
        modalKey={Date.parse(new Date()) / 1000}
        key={Date.parse(new Date()) / 1000}
        title={t1('attendance_register')}
        width="80%"
        visible={isShowDialog}
        className={'modal-attendence'}
        onOk={hideDialogAttendanceRegister}
        onCancel={hideDialogAttendanceRegister}
        destroyOnClose={true}
        footer={null}
      >
        <AttendanceRegisteringToVirtualDialog
          foreHideSystemAttendance={true}
          automaticScaleVirtualClassEnabled={true}
          sessionIid={sessionIid}
        />
      </Modal>
    </div>
  );
};

export default compose(
  withRouter,
  connect(),
)(LHAWithAttendaceRegistering);
