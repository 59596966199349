import { getCurrentUnixTimestamp } from 'common/utils/Date';
import lodashGet from 'lodash.get';
import { ILT_BBB } from './common/constants';
import CryptoJS from 'crypto-js';
import { Modal } from 'antd';
import { t1 } from 'translate';
import React from 'react';
import DisplayHtml from 'components/common/html';
import { newlineToBr } from 'common/utils/string';

export const joinStatuses = {
  JOIN_STATUS_TOO_LATE: 'late',
  JOIN_STATUS_OK: 'ok',
  JOIN_STATUS_TOO_EARLY: 'early',
  JOIN_STATUS_NOT_YET_SCHEDULED: 'not_yet_scheduled',
};

/**
 * return
 *  - 1 if ok to join
 *  - 0 if not ok
 *  - -1 if too late
 * @param session
 * @param beforeCanJoinToLearn
 * @param serverTimestamp
 * @return {*}
 */
export const canJoinToLearn = (
  session,
  beforeCanJoinToLearn = 15 /* minutes*/,
  serverTimestamp,
  automaticScaleVirtualClassEnabled,
) => {
  const currentUnixTs = serverTimestamp || getCurrentUnixTimestamp();

  const preClassTime =
    lodashGet(session, 'pre_class_time') || beforeCanJoinToLearn;
  if (isSessionScheduled(session, automaticScaleVirtualClassEnabled)) {
    const fromTime =
      session.scheduled.date_time +
      session.scheduled.start_time * 60 -
      preClassTime * 60;
    const toTime =
      session.scheduled.date_time + session.scheduled.end_time * 60;

    if (currentUnixTs <= toTime && currentUnixTs >= fromTime) {
      return joinStatuses.JOIN_STATUS_OK;
    } else if (currentUnixTs > toTime) {
      return joinStatuses.JOIN_STATUS_TOO_LATE;
    } else {
      return joinStatuses.JOIN_STATUS_TOO_EARLY;
    }
  }

  return joinStatuses.JOIN_STATUS_NOT_YET_SCHEDULED;
};

export const isSessionScheduled = (session) => {
  return (
    typeof lodashGet(session, 'scheduled.start_time') === 'number' &&
    typeof lodashGet(session, 'scheduled.end_time') === 'number' &&
    lodashGet(session, 'scheduled.date_time') &&
    lodashGet(session, 'scheduled.teacher_iids')
  );
};

export const isMeeting = (type) => type === 'meeting';

export const getCountdownTime = (session, beforeCanJoinToLearn) => {
  const startTime = lodashGet(session, 'scheduled.start_time_ts', 0);
  const preClassTime =
    lodashGet(session, 'pre_class_time', beforeCanJoinToLearn) || 0;

  return startTime - Number(preClassTime) * 60;
};

export const isBBB = (session) => {
  return isSessionScheduled(session) && session.location == ILT_BBB;
};

export const encryptObjLHAInfo = (obj) => {
  return encrypt(JSON.stringify(obj), 'vieteducation');
};

// Code goes here
var keySize = 256;
var ivSize = 128;
var iterations = 100;

function encrypt(msg, pass) {
  var salt = CryptoJS.lib.WordArray.random(128 / 8);

  var key = CryptoJS.PBKDF2(pass, salt, {
    keySize: keySize / 32,
    iterations: iterations,
  });

  var iv = CryptoJS.lib.WordArray.random(128 / 8);

  var encrypted = CryptoJS.AES.encrypt(msg, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  // salt, iv will be hex 32 in length
  // append them to the ciphertext for use  in decryption
  var transitmessage = salt.toString() + iv.toString() + encrypted.toString();
  return transitmessage;
}

function decrypt(transitmessage, pass) {
  var salt = CryptoJS.enc.Hex.parse(transitmessage.substr(0, 32));
  var iv = CryptoJS.enc.Hex.parse(transitmessage.substr(32, 32));
  var encrypted = transitmessage.substring(64);

  var key = CryptoJS.PBKDF2(pass, salt, {
    keySize: keySize / 32,
    iterations: iterations,
  });

  var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });
  return decrypted;
}

export const isAutomaticScaleVirtualClassEnabled = (
  automaticScaleVirtualClassEnabled,
  session,
) => {
  return (
    automaticScaleVirtualClassEnabled ||
    lodashGet(session, 'automaticScaleVirtualClassEnabled')
  );
};

export const showTestingSessionWarning = (event, isTesting) => {
  // in case is_testing checkbox is changed programmatically then event is null
  // we only show this warning if this is user action, not system action
  if (!event || !isTesting) {
    return;
  }

  Modal.warning({
    title: t1('testing_session_warning_title'),
    content: (
      <DisplayHtml
        content={newlineToBr(t1('testing_session_warning_content'))}
      />
    ),
  });
};

export const getCourseIidFromParentNode = (parentNode) => {
  return lodashGet(parentNode, 'ntype') === 'course'
    ? lodashGet(parentNode, 'iid')
    : null;
};

export const getEpIidFromParentNode = (parentNode) => {
  return lodashGet(parentNode, 'ntype') === 'enrolment_plan'
    ? lodashGet(parentNode, 'iid')
    : null;
};

export const getTpIidFromParentNode = (parentNode) => {
  return lodashGet(parentNode, 'ntype') === 'training_plan'
    ? lodashGet(parentNode, 'iid')
    : null;
};

/**
 *
 * @param session
 * @param parentNode this can be a course/ep/tp, the context where user is viewing the session
 * @returns {boolean}
 */
export const canManageSessionOfParentNode = (session, parentNode) => {
  const sessionParentNodeIid =
    lodashGet(session, 'enrolment_plan_iid') ||
    lodashGet(session, 'training_plan_iid');
  if (sessionParentNodeIid) {
    // session cannot be managed if it is viewed from the context other than the context creates it
    if (sessionParentNodeIid != lodashGet(parentNode, 'iid')) {
      return false;
    }
  }

  return true;
};

export const isOwnerSession = (userInfo, session) => {
  const teacherIids = lodashGet(session, 'scheduled.teacher_iids') || [];
  const currentTeacherIid = lodashGet(userInfo, 'iid');

  return teacherIids.includes(currentTeacherIid);
};
