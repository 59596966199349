import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import { t1 } from 'translate';
import { Icon } from 'antd';

const ContentGuide = () => {
  return (
    <div>
      <div>- Điểm được chấm theo thang điểm 100.</div>
      <div>
        - Hệ thống sẽ tự qui về thang điểm theo “Tiêu chí chấm” được qui định
        bởi các trường ĐHSP.
      </div>{' '}
      <br />
      <div>Thầy/cô có thể tham khảo theo ví dụ sau :</div>
      <table className="table-guide-marking m-t-5">
        <thead>
          <th>Điểm Giảng viên chấm trên hệ thống (theo thang 100)</th>
          <th>Điểm theo tiêu chí chấm quy định bởi các trường ĐHSP</th>
          <th>Điểm hệ thống đã qui đổi</th>
        </thead>
        <tbody>
          <tr>
            <td>90</td>
            <td>70</td>
            <td>(90 x 70) : 100 = 63 điểm</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const MarkingGuideDetail = () => {
  return (
    <DetailOnDialog
      renderPreview={({ showFull }) => {
        return (
          <div className="marking-guide text-center">
            <span className="cursor-pointer" onClick={showFull}>
              {t1('marking_guide_score')} <Icon type="question-circle" />
            </span>
          </div>
        );
      }}
      renderFull={() => {
        return <ContentGuide />;
      }}
      dialogKey="marking-guide"
      dialogOptionsProperties={{
        handleClose: true,
        title: t1('marking_guide_score'),
      }}
    />
  );
};

export default MarkingGuideDetail;
