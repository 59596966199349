import React from 'react';
import lodashGet from 'lodash.get';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import endpoints from 'components/temis/endpoints';
import schema from './schema';
import Results from './Results';

const DetailResultBdtx = ({ hiddenFields, defaultValues }) => {
  const FORM_ID = 'get-detail-result-bdtx';

  const renderResultComponent = (items, props, objects, searchValues) => {
    return (
      <Results
        items={items}
        searchValues={searchValues}
        totalResult={lodashGet(objects, 'total')}
        searchFormId={FORM_ID}
      />
    );
  };

  const renderNoResultComponent = () => {
    return <SimpleNoResult />;
  };

  return (
    <>
      <SearchWrapper
        alternativeApi={endpoints.detail_result_bdtx}
        schema={schema}
        formid={FORM_ID}
        hiddenFields={hiddenFields || {}}
        defaultValues={defaultValues}
        renderResultsComponent={renderResultComponent}
        renderNoResultComponent={renderNoResultComponent}
        autoSearchWhenValuesChange={false}
        autoSearchWhenStart={true}
        showSearchButton={true}
      />
    </>
  );
};

export default DetailResultBdtx;
