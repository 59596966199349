import './stylesheet.scss';

import React from 'react';
import get from 'lodash.get';
import { withRouter } from 'react-router';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import endpoints from 'components/bdtx/training-phase/endpoints';
import schema from './search/schema';
import AssignModule from './new';
import { Collapse } from 'antd';
import { userGradeToText } from 'configs/constants/user';
import { TRAINING_MODEL } from 'components/taphuan/configs';
import AddCotCanToEpTeachers from 'components/bdtx/enrolment-plan/AddCotCanToEpTeachers';
import Icon from 'components/common/Icon';
import { t1 } from 'translate';

const { Panel } = Collapse;

const SEARCH_FORM_ID = 'search_assign_module';

const SearchAssignModules = ({ match, node }) => {
  const trainingPhaseIid = get(match, 'params.iid');
  const fromEtep = get(node, 'from_etep');
  const trainingModel = get(node, 'training_model');
  const isCCModel = trainingModel === TRAINING_MODEL.COT_CAN;

  const formId = `${SEARCH_FORM_ID}${trainingPhaseIid}`;

  const renderResultComponent = (items, searchValues) => {
    return (
      <div className="assign-module">
        <div className="m-b-15 d-flex d-flex-column">
          <div className="m-b-5 d-flex align-items-center">
            <div
              className={`assign-form__status-icon assign-form__status-icon--large primary m-r-5`}
            />
            <span>
              Mô đun đã được triển khai cho đơn vị{' '}
              <i>(Có thể nhấn vào icon để xem chi tiết)</i>
            </span>
          </div>
        </div>

        <Collapse
          defaultActiveKey={['panel-0', 'panel-1', 'panel-2', 'panel-3']}
        >
          {items.map((item, index) => {
            const cap = get(item, 'cap');
            const results = get(item, 'result');

            return (
              <Panel
                key={`panel-${index}`}
                header={
                  <span className={'text-secondary'}>
                    Triển khai cho cấp: {userGradeToText(cap)}
                  </span>
                }
              >
                <AssignModule
                  formid={`assign-module-for-cap-${cap}`}
                  organizations={results}
                  searchFormId={formId}
                  searchValues={searchValues}
                  cap={cap}
                  hiddenFields={{
                    cap: cap,
                  }}
                  trainingPhaseIid={trainingPhaseIid}
                  readOnly={fromEtep}
                  isCCModel={isCCModel}
                />
              </Panel>
            );
          })}
        </Collapse>
      </div>
    );
  };

  const hiddenFields = {
    flat_by_cap: 1,
    training_phase_iid: trainingPhaseIid,
  };

  return (
    <div>
      {trainingModel === TRAINING_MODEL.DAI_TRA &&
      get(node, 'use_cot_can_as_teacher') ? (
        <div>
          <div>
            <b className="text-secondary m-r-5">
              {t1(
                'this_training_phase_use_cot_can_as_teachers_for_enrolment_plans',
              )}
            </b>
          </div>
          <AddCotCanToEpTeachers
            trainingPhaseIid={trainingPhaseIid}
            renderComponent={({ onClick }) => {
              return (
                <button
                  className={'btn btn-primary btn-small m-t-10'}
                  onClick={onClick}
                >
                  <Icon icon="sync" />{' '}
                  {t1('add_cot_can_to_enrolment_plan_teachers')}
                </button>
              );
            }}
          />
          <hr />
        </div>
      ) : null}

      <SearchWrapper
        schema={isCCModel ? {} : schema}
        formid={formId}
        alternativeApi={endpoints.bdtx_get_assign_module}
        renderResultsComponent={(items, props, objects, searchValues) =>
          renderResultComponent(items, searchValues)
        }
        renderNoResultComponent={(props, searchValues) =>
          renderResultComponent([], searchValues)
        }
        hiddenFields={hiddenFields}
        hidePagination={true}
        showSearchButton={false}
        autoSearchWhenValuesChange
      />
    </div>
  );
};

export default withRouter(SearchAssignModules);
