import Store from 'store';
import { change } from 'redux-form';

export const addPropsToEverySchemaElements = (schema, newProps) => {
  if (!schema) {
    return schema;
  }

  const result = {};
  Object.keys(schema).forEach((fieldName) => {
    if (!schema[fieldName] || typeof schema[fieldName] !== 'object') {
      result[fieldName] = schema[fieldName];
    } else {
      result[fieldName] = Object.assign({}, schema[fieldName], newProps);
    }
  });
  return result;
};

export const addRquiredTick = (string, cond) =>
  `${string}${cond ? ' (*)' : ''}`;

export const getPopupContainerOfElement = (node) => {
  if (node) {
    return node.parentNode;
  }

  return document.body;
};

export const makeCheckboxesCannotBeCheckedAtTheSameTime = (
  schema,
  fieldsCannotGoTogether,
  formid,
) => {
  if (!schema) {
    return schema;
  }

  (fieldsCannotGoTogether || []).forEach((fieldName) => {
    const oldDefinition = schema[fieldName];

    schema = {
      ...schema,
      [fieldName]: {
        ...oldDefinition,
        onChange: (event, value) => {
          if (typeof oldDefinition.onChange === 'function') {
            oldDefinition.onChange(event, value);
          }

          if (value) {
            fieldsCannotGoTogether.forEach((otherFieldName) => {
              if (otherFieldName !== fieldName) {
                Store.dispatch(change(formid, otherFieldName, null));
              }
            });
          }
        },
      },
    };
  });

  return schema;
};
