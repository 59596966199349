import React from 'react';
import Card from 'antd/lib/card';
import Icon from 'components/common/Icon';
import './stylesheet.scss';

function Item({ item, index, onClick, isActive }) {
  return (
    <Card
      onClickCapture={onClick}
      key={index}
      className={`item cursor-pointer ${item.colorByStatus || ''} ${
        isActive ? 'active' : ''
      }`}
      size="small"
    >
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center flex-grow-1">
          <Icon icon={item.icon} antIcon />{' '}
          <span className="m-l-5">{item.title}</span>
        </div>
        <span className="value">{item.value}</span>
      </div>
    </Card>
  );
}

function StatisticsCard({ items = [], onClick, isSmall, itemActive = [] }) {
  if (!(Array.isArray(items) && items.length)) {
    return null;
  }

  return (
    <div
      className={`d-flex d-flex-wrap ${
        isSmall ? 'small' : ''
      } statistics-card-container`}
    >
      {items.map((item, index) => (
        <Item
          item={item}
          index={index}
          onClick={() => onClick(item.id, item.paramFilter || item.paramFilter)}
          isActive={itemActive === item.id}
        />
      ))}
    </div>
  );
}

export default StatisticsCard;
