import React from 'react';
import lodashGet from 'lodash.get';
import OrganizationNotInDbInfo from './OrganizationNotInDbInfo';

/**
 */
export const shortenPgdSgdName = (name, type) => {
  // if (type == 'pgd') {
  //   // return name.replace(/Phòng Giáo dục và Đào tạo/ig, 'PGD');
  //   return name.replace('Phòng Giáo dục và Đào tạo', 'PGD');
  // }

  return (
    name &&
    name
      .replace('Sở Giáo dục và Đào tạo', 'SGD')
      .replace('Phòng Giáo dục và Đào tạo', 'PGD')
  );
  // return name.replace(/Sở Giáo dục và Đào tạo/ig, 'SGD');
  // return name;
};

const UserPGDOrSGD = ({ item, type, silent }) => {
  const attr = 'user_organizations';
  const iids = lodashGet(item, attr, []);
  const objects = lodashGet(item, '__expand.' + attr, []);

  const expand = lodashGet(item, '__expand');
  if (type == 'pgd') {
    if (lodashGet(expand, 'pgd.name')) {
      return (
        <div>
          {lodashGet(expand, 'pgd.name')}
          {lodashGet(expand, 'pgd.not_in_db') ? (
            <OrganizationNotInDbInfo silent={silent} />
          ) : null}
        </div>
      );
    }
  }

  if (type == 'sgd') {
    if (lodashGet(expand, 'sgd.name')) {
      return (
        <div>
          {lodashGet(expand, 'sgd.name')}
          {lodashGet(expand, 'sgd.not_in_db') ? (
            <OrganizationNotInDbInfo silent={silent} />
          ) : null}
        </div>
      );
    }
  }

  return <div>-</div>;
};

export default UserPGDOrSGD;
