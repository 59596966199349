import React from 'react';
import { t1 } from 'translate';
import DetailOnDialog from 'components/common/detail-on-dialog';
import SearchModule from './search-module';

const ChooseModuleOnDialog = ({
  cap,
  targetUser,
  schoolYear,
  selectedModules,
  onSelectModules,
}) => {
  return (
    <DetailOnDialog
      renderPreview={({ showFull }) => {
        return (
          <span
            className="link"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              showFull();
            }}
          >
            {t1(`choose_module_for_cap_${cap}`)}
          </span>
        );
      }}
      renderFull={({ closeDialog }) => {
        const hiddenFields = {
          school_year: schoolYear,
          target_user: targetUser,
          caps: [cap],
          nin_module_iids: (selectedModules || []).map((module) => module.iid),
        };

        return (
          <SearchModule
            hiddenFields={hiddenFields}
            onSelectModules={onSelectModules}
            targetUser={targetUser}
            cap={cap}
            closeDialog={closeDialog}
          />
        );
      }}
      dialogKey="choose-module-on-dialog-for-training-phase"
      dialogOptionsProperties={{
        title: t1('choose_module_for_cap_%s', [cap]),
        modal: true,
      }}
    />
  );
};

export default ChooseModuleOnDialog;
