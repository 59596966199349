import { taphuanUserTypeOptions } from 'configs/constants/temis';
import { t1 } from 'translate';

export const taphuanTargetUserMulti = (formid, props = {}) => ({
  inline: true,
  type: 'multiCheckbox',
  floatingLabelText: t1('taphuan_target_user'),
  ...props,
  options: taphuanUserTypeOptions(),
});

export const taphuanTargetUserOne = (formid, props = {}) => ({
  floatingLabelText: t1('taphuan_target_user'),
  ...props,
  type: 'select',
  options: taphuanUserTypeOptions(),
});
