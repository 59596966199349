import React from 'react';
import { createSelector } from 'reselect';
import ScoItSelf from './ScoItSelf';
import ScoSpl from '../sco-spl';

class ScoIndex extends React.Component {
  render() {
    const {
      learnItem,
      navId,
      courseIid,
      nodes,
      course,
      syllabus,
      isPreview,
      navRootNodeIid,
    } = this.props;

    let content = null;
    if (learnItem.is_spl) {
      content = (
        <div style={{ background: 'white' }}>
          <ScoSpl
            key={`${navId}-${learnItem.iid}-sco`}
            id={`${navId}-${learnItem.iid}-sco`}
            courseIid={courseIid}
            scoIid={learnItem.iid}
            nodes={nodes}
            course={course}
            syllabus={syllabus}
            isPreview={isPreview}
            navRootNodeIid={navRootNodeIid}
          />
        </div>
      );

      if (learnItem.sco_content_position != 'hidden') {
        const scoItself = <ScoItSelf {...this.props} />;

        if (learnItem.sco_content_position == 'before') {
          content = (
            <>
              {scoItself}
              {content}
            </>
          );
        } else if (learnItem.sco_content_position == 'after') {
          content = (
            <>
              {content}
              {scoItself}
            </>
          );
        }
      }

      return content;
    }

    return <ScoItSelf {...this.props} />;
  }
}

export default ScoIndex;

class DisplayScoItSelf extends React.Component {}
