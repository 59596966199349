import { call, put } from 'redux-saga/effects';
import Requester from 'common/network/http/Request';
import apiUrls from 'api-endpoints';
import { takeLatest } from 'redux-saga';
import {
  COURSES_FIND_REQUEST,
  findCoursesError,
  findCoursesSuccess,
} from 'actions/course';

function* findCourse(action) {
  const { userIid } = action;
  if (!userIid) {
    return;
  }

  try {
    const response = yield call(Requester.get, apiUrls.find_course_for_user, {
      uiid: userIid,
    });

    yield put(findCoursesSuccess(response.result));
  } catch (ex) {
    yield put(findCoursesError(ex));
  }
}

export default function* findCourseSaga() {
  yield takeLatest(COURSES_FIND_REQUEST, findCourse);
}
