import { masterCourseUrl, masterUrl } from '../root-url';
import { stringify } from 'query-string';
import { teacherMenuItems } from 'routes/teacher/util';

export const getMasterMenuItem = (id) => {
  return `${masterUrl}/${id}`;
};

export const getCourseMasterMenuItem = (course, routerId) => {
  return getMasterCourseDashboardLinkById(course.iid, routerId);
};

export const getMasterCourseDashboardLinkById = (iid, routerId) => {
  return `${masterCourseUrl}/${iid}/${routerId}`;
};

export const getMasterApproveSupportPlanUrl = ({ module } = {}) => {
  const queryStr = stringify({
    module,
  });

  return `${getMasterMenuItem(teacherMenuItems.APPROVE_SUPPORT_PLAN)}${
    queryStr ? `?${queryStr}` : ''
  }`;
};

export const getMasterApproveSupportPlanResultUrl = ({ school_year } = {}) => {
  const queryStr = stringify({
    school_year,
  });

  return `${getMasterMenuItem(teacherMenuItems.APPROVE_RESULT_PLAN)}${
    queryStr ? `?${queryStr}` : ''
  }`;
};

export const getMasterAnswerQAUrl = ({ module } = {}) => {
  const queryStr = stringify({
    module,
  });

  return `${getMasterMenuItem(teacherMenuItems.QA)}${
    queryStr ? `?${queryStr}` : ''
  }`;
};

export const getMasterListGvccUrl = ({ module } = {}) => {
  const queryStr = stringify({
    module,
  });

  return `${getMasterMenuItem(teacherMenuItems.LIST_GVCC)}${
    queryStr ? `?${queryStr}` : ''
  }`;
};
