import React from 'react';

const ButtonSecondary = ({ children, className, ...props }) => {
  const c = className ? `${className} btn btn-secondary` : 'btn btn-secondary';

  return (
    <button className={c} {...props}>
      {children}
    </button>
  );
};

export default ButtonSecondary;
