/**
 * Created by quandv on 18/08/17.
 */
import Store from 'store';
import CryptoJS from 'crypto-js';
import Decrypt from 'common/security/Decrypt';
import Encrypt from 'common/security/Encrypt';
import lGet from 'lodash.get';

const getTranslationsFromStore = () => Store && Store.getState().translations;

// export const translationsLocalStorage = (field, getStore = false) => {
//   let translations = Store.getState().translations;
//
//   if (field) {
//     return translations['messages'][field];
//   }
//
//   return translations;
// };

export const questionBankResultLocalStorage = (
  contestantIid,
  contestCode,
  isPublic = false,
  syllabusIid,
) => {
  let questionBankResult = false;
  if (isPublic) {
    questionBankResult = localStorage.getItem(
      'questionBankResult:' + contestCode + ':' + syllabusIid,
    );
  } else {
    questionBankResult = localStorage.getItem(
      'questionBankResult:' + contestCode + ':' + contestantIid,
    );
  }
  if (questionBankResult != null && questionBankResult.length > 0) {
    questionBankResult = JSON.parse(questionBankResult);
  } else {
    questionBankResult = false;
  }
  return questionBankResult;
};

export const saveQuestionBankResultToLocalStorage = (
  questionBankResult,
  contestantIid,
  contestCode,
  isPublic = false,
  syllabusIid,
) => {
  if (isPublic) {
    localStorage.setItem(
      'questionBankResult:' + contestCode + ':' + syllabusIid,
      JSON.stringify(questionBankResult),
    );
  } else {
    localStorage.setItem(
      'questionBankResult:' + contestCode + ':' + contestantIid,
      JSON.stringify(questionBankResult),
    );
  }
};

export const examTemplateLocalStorage = (
  contestantIid,
  contestCode,
  isPublic = false,
  syllabusIid,
) => {
  let examTemplateOutput = false;
  let token = '';
  if (isPublic) {
    examTemplateOutput = localStorage.getItem(
      'examTemplate:' + contestCode + ':' + syllabusIid,
    );
    token = CryptoJS.MD5(syllabusIid * 2).toString();
  } else {
    token = CryptoJS.MD5(contestantIid * 2).toString();
    examTemplateOutput = localStorage.getItem(
      'examTemplate:' + contestCode + ':' + contestantIid,
    );
  }
  if (examTemplateOutput != null && examTemplateOutput.length > 0) {
    examTemplateOutput = JSON.parse(Decrypt(token, examTemplateOutput));
  } else {
    examTemplateOutput = false;
  }
  return examTemplateOutput;
};

export const saveExamTemplateToLocalStorage = (
  examTemplate,
  contestantIid,
  contestCode,
  isPublic = false,
  syllabusIid,
) => {
  let token = '';
  let examTemplateString = JSON.stringify(examTemplate);
  if (isPublic) {
    token = CryptoJS.MD5(syllabusIid * 2).toString();
    localStorage.setItem(
      'examTemplate:' + contestCode + ':' + syllabusIid,
      Encrypt(token, examTemplateString),
    );
  } else {
    token = CryptoJS.MD5(contestantIid * 2).toString();
    localStorage.setItem(
      'examTemplate:' + contestCode + ':' + contestantIid,
      Encrypt(token, examTemplateString),
    );
  }
};

export const questionBankLocalStorage = (
  contestantIid,
  contestCode,
  isPublic = false,
  syllabusIid,
) => {
  var output = {
    bank: false,
    version: 0,
  };
  let token = '';
  let bank = {};
  let data = '';
  let version = 0;
  if (isPublic) {
    data = localStorage.getItem(
      'questionBank:' + contestCode + ':' + syllabusIid,
    );
    version = localStorage.getItem(
      'bankVersion:' + contestCode + ':' + syllabusIid,
    );
    token = CryptoJS.MD5(syllabusIid * 2).toString();
  } else {
    token = CryptoJS.MD5(contestantIid * 2).toString();
    data = localStorage.getItem(
      'questionBank:' + contestCode + ':' + contestantIid,
    );
    version = localStorage.getItem(
      'bankVersion:' + contestCode + ':' + contestantIid,
    );
  }
  if (data != null && data.length > 0) {
    bank = JSON.parse(Decrypt(token, data));
    output.bank = bank;
    output.version = version ? version : 0;
  } else {
    output.bank = false;
    output.version = version ? version : 0;
  }
  return output;
};

export const saveQuestionBankToLocalStorage = (
  questionBank,
  contestantIid,
  contestCode,
  isPublic = false,
  syllabusIid,
  isNewVer,
  bankVersion,
) => {
  let token = '';
  let bankString = JSON.stringify(questionBank);
  if (isPublic) {
    token = CryptoJS.MD5(syllabusIid * 2).toString();
    localStorage.setItem(
      'questionBank:' + contestCode + ':' + syllabusIid,
      Encrypt(token, bankString),
    );
    if (isNewVer) {
      localStorage.setItem(
        'bankVersion:' + contestCode + ':' + syllabusIid,
        bankVersion,
      );
    }
  } else {
    token = CryptoJS.MD5(contestantIid * 2).toString();
    localStorage.setItem(
      'questionBank:' + contestCode + ':' + contestantIid,
      Encrypt(token, bankString),
    );
    if (isNewVer) {
      localStorage.setItem(
        'bankVersion:' + contestCode + ':' + contestantIid,
        bankVersion,
      );
    }
  }
};

export const saveTakeAndPaperToLocalStorage = (
  take,
  paper,
  contestantIid,
  contestCode,
  isPublic = false,
  syllabusIid,
  token = CryptoJS.MD5(123456789)
    .toString()
    .substring(0, 15),
) => {
  let answerFlatten = Object.values(take.answer);
  let takeSave = Object.assign({}, take, { answerFlatten: answerFlatten });
  let data;
  if (isPublic) {
    data = localStorage.getItem(
      'totalPracticeTime:' + contestCode + ':' + syllabusIid,
    );
  } else {
    data = localStorage.getItem(
      'totalPracticeTime:' + contestCode + ':' + contestantIid,
    );
  }
  var currentTurn = data && parseInt(data) > 0 ? parseInt(data) + 1 : 1;
  if (isPublic) {
    localStorage.setItem(
      'paperPractice:' + contestCode + ':' + syllabusIid + ':' + currentTurn,
      JSON.stringify(paper),
    );
    localStorage.setItem(
      'takePractice:' + contestCode + ':' + syllabusIid + ':' + currentTurn,
      JSON.stringify(takeSave),
    );
    localStorage.setItem(
      'totalPracticeTime:' + contestCode + ':' + syllabusIid,
      currentTurn,
    );
  } else {
    localStorage.setItem(
      'paperPractice:' + contestCode + ':' + contestantIid + ':' + currentTurn,
      JSON.stringify(paper),
    );
    localStorage.setItem(
      'takePractice:' + contestCode + ':' + contestantIid + ':' + currentTurn,
      JSON.stringify(takeSave),
    );
    localStorage.setItem(
      'totalPracticeTime:' + contestCode + ':' + contestantIid,
      currentTurn,
    );
  }
};

export const getTotalPracticeTimeFromLocalStorage = (
  contestantIid,
  contestCode,
  isPublic = false,
  syllabusIid,
) => {
  let data;
  if (isPublic) {
    data = localStorage.getItem(
      'totalPracticeTime:' + contestCode + ':' + syllabusIid,
    );
  } else {
    data = localStorage.getItem(
      'totalPracticeTime:' + contestCode + ':' + contestantIid,
    );
  }
  if (data && parseInt(data) > 0) {
    return parseInt(data);
  } else {
    return 0;
  }
};

export const getTakeAndPaperFromLocalStorage = (
  contestantIid,
  contestCode,
  isPublic = false,
  syllabusIid,
  currentTurn,
  token = CryptoJS.MD5(123456789)
    .toString()
    .substring(0, 15),
) => {
  if (isPublic) {
    var paper = JSON.parse(
      localStorage.getItem(
        'paperPractice:' + contestCode + ':' + syllabusIid + ':' + currentTurn,
      ),
    );
    var take = JSON.parse(
      localStorage.getItem(
        'takePractice:' + contestCode + ':' + syllabusIid + ':' + currentTurn,
      ),
    );
  } else {
    var paper = JSON.parse(
      localStorage.getItem(
        'paperPractice:' +
          contestCode +
          ':' +
          contestantIid +
          ':' +
          currentTurn,
      ),
    );
    var take = JSON.parse(
      localStorage.getItem(
        'takePractice:' + contestCode + ':' + contestantIid + ':' + currentTurn,
      ),
    );
  }
  let answerTake = {};
  lGet(take, 'answerFlatten', []).map((answer) => {
    answerTake[answer.iid] = answer;
  });
  take = Object.assign({}, take, { answer: answerTake });
  return {
    paper: paper,
    take: take,
  };
};

export const getAllTakeAndPaperFromLocalStorage = (
  contestantIid,
  contestCode,
  isPublic = false,
  syllabusIid,
  token = CryptoJS.MD5(123456789)
    .toString()
    .substring(0, 15),
) => {
  var output = [];
  var total = getTotalPracticeTimeFromLocalStorage(
    contestantIid,
    contestCode,
    isPublic,
    syllabusIid,
  );
  for (var i = 0; i < total; i++) {
    output.push(
      getTakeAndPaperFromLocalStorage(
        contestantIid,
        contestCode,
        (isPublic = false),
        syllabusIid,
        i + 1,
        token,
      ),
    );
  }
  return output;
};

export const translationsLocalStorage = (field, getStore = false) => {
  let data;
  if (getStore) {
    data = getTranslationsFromStore();
  }
  if (!data && !localStorage.getItem('reduxPersist:translations')) {
    return null;
  }

  if (!data || (field && !data[field]) || (!field && data.messages)) {
    data = JSON.parse(localStorage.getItem('reduxPersist:translations')) || {
      messages: {},
    };
  }
  if (field) {
    return data[field];
  }

  return data;
};

export const formatDataDrawTableRender = (item, index = 0) => {
  let result = [];
  if (!item) {
    return result;
  }
  if (Array.isArray(item)) {
    item.forEach((row) => {
      result = result.concat(formatDataDrawTableRender(row));
    });
    return result;
  }
  if (item.node && item.dataNode && item.dataNode.length) {
    item.dataNode.forEach((node) => {
      const tmp = formatDataDrawTableRender(node, index + 1);
      result = result.concat(tmp);
    });
    if (result.length) {
      const newNode = result[0];
      const rowSpan = newNode.rowSpan || {};
      rowSpan[index] = result.length;
      const colData = newNode.colData || {};
      colData[index] = item.node;
      result[0] = { ...result[0], rowSpan, colData };
    }
  } else {
    result.push(item);
  }
  return result;
};
