import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import NodeNew from 'components/admin/node/new';
import schema from './schema';
import endpoints from '../endpoints';
import { submit } from 'redux-form';

import './stylesheet.scss';

const FORM_ID = 'choose_module';

const Index = ({
  formid,
  modules,
  hiddenFields,
  searchFormId,
  readOnly,
  dispatch,
}) => {
  const generateNode = () => {
    return (modules || [])
      .map((module) => module.chosen && module.iid)
      .filter(Boolean);
  };

  const onChangeFormValue = (formValues = {}) => {
    const modulesChosen = get(formValues, 'modules', []);
    const defaultModulesChosen = generateNode();

    if (modulesChosen.join('-') !== defaultModulesChosen.join('-')) {
      dispatch(submit(formid || FORM_ID));
    }
  };

  return (
    <NodeNew
      modules={modules}
      schema={schema(modules, readOnly, hiddenFields)}
      hiddenFields={hiddenFields}
      formid={formid || FORM_ID}
      alternativeApi={endpoints.bdtx_update_choose_module}
      searchFormId={searchFormId}
      resetForm={false}
      onChange={onChangeFormValue}
      node={{
        modules: generateNode(),
      }}
      readOnly={readOnly}
      hideSubmitButton
    />
  );
};

export default connect()(Index);
