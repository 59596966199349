/* eslint-disable react/prop-types,jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import routes from 'routes';
import NodeNew from 'components/admin/node/new';
import { t1 } from 'translate';
import path from 'components/admin/path/schema/form';
import { withRouter } from 'react-router';
import withSchoolConfig from 'common/hoc/withSchoolConfigs';
import withUserInfo from 'common/hoc/withUserInfo';

class NewPathForm extends Component {
  onHandleNewSuccess = (data) => {
    if (!data || !data.result) return;
    const { type, history } = this.props;
    const mod = type || 'path';
    if (mod === 'classgroup') {
      history.push(`/admin/classgroup/${data.result.iid}/children`);
    } else {
      const url = routes.url('node_edit', { ...data.result, ntype: 'path' });
      history.push(url);
    }
  };

  render() {
    const {
      mode,
      node,
      type,
      requestSuccessful,
      dialogKey,
      schema,
      submitLabel,
      userInfo,
    } = this.props;
    const title = this.props.title || t1('new_path');
    const formid = this.props.formid || 'new_path';
    const finalType = type || (node && node.type) || 'path';
    let hiddenFields = this.props.hiddenFields || { type: finalType };

    return (
      <div className={'white-box'}>
        <NodeNew
          title={title}
          ntype={'path'}
          schema={schema || path}
          mode={mode}
          step={finalType === 'path' ? '' : finalType}
          node={node}
          formid={formid}
          hiddenFields={hiddenFields}
          requestSuccessful={requestSuccessful || this.onHandleNewSuccess}
          dialogKey={dialogKey}
          closeModal={!!dialogKey}
          submitLabel={submitLabel}
          userInfo={userInfo}
        />
      </div>
    );
  }
}

export default withRouter(withSchoolConfig(withUserInfo(NewPathForm)));
