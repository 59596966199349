import MD5 from 'crypto-js/md5';
import Base64 from 'crypto-js/enc-base64';
import sha256 from 'crypto-js/sha256';
import CryptoJS from 'crypto-js';
import { detect } from 'detect-browser';

const SALT = 'lms';
const ORIGIN_TAPHUAN_VIDEO = 'taphuan-video.csdl.edu.vn';
const HLSExtenstion = 'm3u8';
const DRM = 'drm';

export const generateHashByKey = (key) => {
  const hashMD5 = MD5(key, true);
  const hashBase64 = Base64.stringify(hashMD5);
  const hashWithoutPlus = hashBase64.replace(/\+/gm, '-');
  const hashWithoutSplash = hashWithoutPlus.replace(/\//gm, '_');

  return hashWithoutSplash.replace(/=/gm, '');
};

export const getPathNameOfVideoUrl = (videoUrl) => {
  const url = new URL(videoUrl);

  return url.pathname;
};

export const shouldUseCDN = (videoUrl) => {
  const url = new URL(videoUrl);
  const { hostname } = url;

  return videoUrl.endsWith(HLSExtenstion) || hostname === ORIGIN_TAPHUAN_VIDEO;
};

export const generateCMCCDNUrl = (config, bucket) => {
  const tokenKey = sha256(config.tokenKey + SALT).toString();
  const domainKey = config.domainKey;

  const formatUriVOD = (bucket || '').startsWith('/') ? bucket : `/${bucket}`;

  let cdn = `https://${domainKey}.cmccloud.com.vn${formatUriVOD}`;

  if (config.disabledToken) {
    return cdn;
  }

  const expireTime =
    Math.floor(new Date().getTime() / 1000) + (config.tokenExpireTime || 300);
  const hashsign = generateHashByKey(`${tokenKey}${formatUriVOD}${expireTime}`);

  return `${cdn}?hashsign=${hashsign}&e=${expireTime}`;
};

export const generateCMCVODCDNUrl = (config, bucket) => {
  const tokenKey = sha256(config.tokenKey + SALT).toString();
  const domainKey = config.domainKey;

  const formatUriVOD = (bucket || '').startsWith('/') ? bucket : `/${bucket}`;
  const uri = `/vod/hls${formatUriVOD}`;

  let cdn = `https://${domainKey}.cmccloud.com.vn${uri}/master.m3u8`;

  if (config.disabledToken) {
    return cdn;
  }

  const expireTime =
    Math.floor(new Date().getTime() / 1000) + (config.tokenExpireTime || 300);
  const hashsign = generateHashByKey(`${tokenKey}${uri}${expireTime}`);

  return `${cdn}?hashsign=${hashsign}&e=${expireTime}`;
};

export const drmRequestConfig = (url) => {
  const detectedBrowser = detect();
  const os = detectedBrowser.os.toLocaleLowerCase(); //IOS, Mac OS, Android OS

  let forceHLS = false;

  // exclude ios because forceHLS settings not working on ios browser
  if (os !== 'ios') {
    forceHLS = (url || '').includes('.m3u8'); // if m3u8 file -> must be HLS, we need this config because for some reason react-player cannot conclude that by itself in small (mobile) screen
  }

  return {
    file: {
      forceHLS,
      hlsOptions: {
        xhrSetup: (xhr, url = '') => {
          if (url.match(/\/key\/(.*)/))
            xhr.setRequestHeader(
              'token',
              CryptoJS.AES.encrypt(
                JSON.stringify({ expire: Math.round(+new Date() / 1000) }),
                DRM,
              ),
            );
        },
      },
    },
  };
};
