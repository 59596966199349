import './stylesheet.scss';

import React from 'react';
import get from 'lodash.get';
import { t1 } from 'translate';
import AnswerByStructure from 'components/common/forms/questions/open-ended/AnswerByStructure';
import ViewAnswer from 'components/common/forms/questions/open-ended/submission-area/viewAnswer';
import {
  getArchivedAnswerAttachmentsRestorationInfo,
  isOEAdvancedLayoutType,
  isStructureAnswerType,
} from 'components/common/forms/questions/open-ended/util';
import Warning from 'components/common/Warning';
import { timestampToDateTimeString } from 'common/utils/Date';

const ViewOpenEndedAnswer = ({
  question,
  takeDetail,
  setRubricToShowMarking,
}) => {
  const structureAnswer = get(question, 'structure_answer');

  if (isOEAdvancedLayoutType(question) && isStructureAnswerType(question)) {
    return (
      <AnswerByStructure
        value={get(takeDetail, 'answer.content')}
        structureAnswer={structureAnswer}
        setRubricToShowMarking={setRubricToShowMarking}
        question={question}
        readOnly
        viewAnswerInMarkingMode
      />
    );
  }

  const answer = get(takeDetail, 'answer');
  const isAnswerAttachmentsArchived = get(
    takeDetail,
    'is_answer_attachments_archived',
  );

  const expectedTimeToAccessRestoredArchiveFile = get(
    takeDetail,
    'expected_time_to_access_restored_archive_file',
  );

  const {
    archiveRestorationFromTs,
    archiveRestorationToTs,
    isArchiveRestorationInitiated,
    isArchiveRestored,
  } = getArchivedAnswerAttachmentsRestorationInfo(
    expectedTimeToAccessRestoredArchiveFile,
  );

  if (isAnswerAttachmentsArchived && !isArchiveRestored) {
    if (isArchiveRestorationInitiated) {
      return (
        <Warning>
          {t1(
            'this_take_archived_answer_is_being_restored,_it_will_available_from_%s_to_%s',
            [
              timestampToDateTimeString(archiveRestorationFromTs),
              timestampToDateTimeString(archiveRestorationToTs),
            ],
          )}
        </Warning>
      );
    }

    return (
      <Warning>
        {t1(
          'this_take_answer_has_been_archived,_please_contact_support_if_you_need_to_get_it',
        )}
      </Warning>
    );
  }

  if (answer) {
    return <ViewAnswer answer={answer} />;
  }

  return <div>{t1('cannot_view_answer')}</div>;
};

export default ViewOpenEndedAnswer;
