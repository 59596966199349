import React from 'react';
import SearchExamStore from 'components/bdtx/review-exam-store/search';
import SubTopMenuContext from 'common/context/menu/SubMenuTop';

const ReviewExamStore = () => {
  return (
    <>
      <SubTopMenuContext lastBreadcrumbName={'module'} description={'module'} />
      <SearchExamStore />;
    </>
  );
};

export default ReviewExamStore;
