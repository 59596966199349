import React from 'react';
import lodashGet from 'lodash.get';
import styled from 'styled-components';

import Row from 'antd/lib/grid/row';
import Col from 'antd/lib/grid/col';
import Card from 'antd/lib/card';
import { t1 } from 'translate';

const Container = styled(Row)`
  label,
  .ant-form-item-no-colon {
    color: rgba(0, 0, 0, 0.85) !important;
  }
  label.ant-radio-wrapper {
    color: rgba(0, 0, 0, 0.7) !important;
  }
`;

const LayoutFreestyle = (props) => {
  const {
    name,
    mail,
    phone,
    birthday,
    reflection_name,
    reflection_description,
    reflection_send_to_organization_type,
    reflection_attachments,
  } = lodashGet(props, 'groups.default.fieldNames') || {};
  const submitButton = lodashGet(props, 'submitButton');

  return (
    <Container>
      <Row type="flex" justify="space-between" gutter={{ md: 10 }}>
        <Col md={24} span={24} className="m-t-20">
          <Card title={<strong>Thông tin người gửi</strong>}>
            <Row gutter={24} className="m-b-10">
              <Col md={12} span={24}>
                <div>{name}</div>
              </Col>
              <Col md={12} span={24}>
                <div>{birthday}</div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col md={12} span={24}>
                <div>{mail}</div>
              </Col>
              <Col md={12} span={24}>
                <div>{phone}</div>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col md={24} span={24} className="m-t-20">
          <Card title={<strong>{t1('content_comment_and_reflection')}</strong>}>
            <Row gutter={24} className="m-b-10">
              <Col md={24} span={24}>
                {reflection_send_to_organization_type}
              </Col>
              <Col md={24} span={24}>
                {reflection_name}
              </Col>
              <Col md={24} span={24}>
                {reflection_description}
              </Col>
              <Col md={24} span={24}>
                {reflection_attachments}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <div className="text-center m-t-30">{submitButton}</div>
    </Container>
  );
};

export default LayoutFreestyle;
