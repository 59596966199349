import React from 'react';
import ImportUsers from 'components/admin/import-users';
import { getUrlParam } from 'routes/links/common';
import { withRouter } from 'react-router';

const GroupImportMembers = ({ node, ...props }) => {
  const importId = getUrlParam(props, 'importId');

  return <ImportUsers node={node} importId={importId} />;
};

export default withRouter(GroupImportMembers);
