import React from 'react';
import AuthCode from 'react-auth-code-input';
import fetchData from 'components/common/fetchData';
import './stylesheet.scss';
import { t1 } from 'translate';
import withUserInfo from 'common/hoc/withUserInfo';
import lodashGet from 'lodash.get';
import QRCode from './QRCode';

const AuthCodeInput = ({
  handleVerifyAuthCodeInput,
  sessionOf2FA,
  onVerificationSuccessful,
  params = {},
  userInfo,
  codeVerificationType,
}) => {
  const [validInputCode, setValidInputCode] = React.useState(false);
  React.useEffect(
    () => {
      if (
        typeof sessionOf2FA !== 'undefined' &&
        typeof onVerificationSuccessful === 'function'
      ) {
        onVerificationSuccessful(validInputCode && sessionOf2FA);
      }
    },
    [onVerificationSuccessful, sessionOf2FA, validInputCode],
  );

  const gaEnabled = [1, '1'].includes(lodashGet(userInfo, 'ga_enabled'));
  const elementAuthCodeInput = (
    <div className="text-center">
      <p>
        {t1(
          codeVerificationType === 'verify_token'
            ? 'a_message_with_a_verification_code_has_been_sent_to_your_devices'
            : 'get_code_from_the_app_on_your_mobile_device_or_computer',
        )}
        .
        <br />
        {t1('enter_the_code_to_continue')}.
      </p>
      <AuthCode
        containerClassName="wapper-auth-code"
        inputClassName={`input ${
          typeof sessionOf2FA !== 'undefined' && !sessionOf2FA && validInputCode
            ? 'errors'
            : ''
        }`}
        allowedCharacters="numeric"
        onChange={(code) => {
          if (!code || code.length !== 6) {
            setValidInputCode(false);
            return;
          }
          setValidInputCode(true);
          handleVerifyAuthCodeInput(
            Object.assign({}, params, { userInputCode: code }),
          );
        }}
      />
    </div>
  );

  if (gaEnabled || codeVerificationType) {
    return elementAuthCodeInput;
  }

  return (
    <div>
      <ol>
        <li>
          <p>
            <b>
              {t1('install_a_compatible_app_on_your_mobile_device_or_computer')}
            </b>
          </p>

          <p>
            {t1(
              'description_install_a_compatible_app_on_your_mobile_device_or_computer',
            )}
          </p>
          <hr />
        </li>
        <li>
          <p>
            <b>
              {t1('use_your_app_and_your_device_camera_to_scan_the_qr_code')}
            </b>
          </p>
          <div className="text-center">
            <QRCode />
          </div>
          <hr />
        </li>
        <li>
          <p>
            <b>{t1('type_two_factor_authentication_code_below')}</b>
          </p>
          {elementAuthCodeInput}
          <hr />
        </li>
      </ol>
    </div>
  );
};

export default withUserInfo(
  fetchData(() => ({
    baseUrl: '/site/two-factor-authentication/verify',
    propKey: 'sessionOf2FA',
    fetchCondition: false,
    fetchFunctionPropKey: 'handleVerifyAuthCodeInput',
    refetchCondition: () => false,
  }))(AuthCodeInput),
);
