import React from 'react';
import Icon from '../Icon';
import { t1 } from 'translate';
import './stylesheet.scss';

const DetailButton = ({ onClick }) => {
  return (
    <span onClick={onClick} className="detail-button">
      <Icon icon="angle-right" className={'detail-button__icon'} />{' '}
      {t1('click_to_see_detail')}
    </span>
  );
};

export default DetailButton;
