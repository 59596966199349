import React from 'react';
import get from 'lodash.get';
import CommonAntdTable from 'components/common/antd/table';
import { userGradeOptions, userGradeToText } from 'configs/constants/user';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import { Progress } from 'antd';

const CT_NAME = {
  ct1: 'CT 01',
  ct2: 'CT 02',
  ct12: 'CT 01 + 02',
  ct3: 'CT 03',
};

const TableResult = ({ items }) => {
  const renderTableColumns = () => {
    return [
      {
        title: 'Đơn vị',
        dataIndex: 'organization',
        render: (organization) => {
          const name = get(organization, 'name');

          return <span>{name}</span>;
        },
      },
      ...userGradeOptions().map((capOption) => {
        const cap = capOption.value;

        return {
          title: userGradeToText(cap),
          type: TYPE_OF_TABLE_DATA.TITLE,
          children: ['ct1', 'ct2', 'ct12', 'ct3'].map((ct) => {
            return {
              title: CT_NAME[ct],
              type: TYPE_OF_TABLE_DATA.TITLE,
              render: (item) => {
                const stats = get(item, 'stats');

                if (!Array.isArray(stats)) return <span>.</span>;

                const statsByCap = stats.find((el) => el.cap == cap);

                if (!statsByCap) {
                  return <span className="text-danger">---</span>;
                }

                const finished = get(statsByCap, ct, 0);
                const total = get(statsByCap, 'totalStudentsInOrg');
                if (total == 0) return '0/0';

                return (
                  <div>
                    <div>
                      {finished} / {total}
                    </div>
                    <Progress
                      percent={Math.floor((100 * finished) / total)}
                      size="small"
                    />
                  </div>
                );
              },
            };
          }),
        };
      }),
    ];
  };

  return (
    <CommonAntdTable
      dataSource={items}
      columns={renderTableColumns()}
      pagination={false}
      className="learn-default"
      scroll={{
        x: true,
      }}
    />
  );
};

export default TableResult;
