import React from 'react';
import { connect } from 'react-redux';
import { Tag } from 'antd';
import get from 'lodash.get';
import CommonAntdTable from 'components/common/antd/table';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import { getTargetUser, userGradeToText } from 'configs/constants/user';
import { parseTSToDateTimeString } from 'common/utils/Date';
import ActionBtnWithConfirmDialog from 'components/common/action-button/ActionBtnWithConfirmDialog';
import sagaActions from 'actions/node/saga-creators';
import endpoints from '../endpoints';
import ChuongTrinhTag from 'components/bdtx/common/ChuongTrinhTag';
import DetailOnDialog from 'components/common/detail-on-dialog';
import Migrate from './migrate';

const TableResult = ({ items, searchFormId, dispatch }) => {
  const itemMigrated = (migration) => get(migration, 'chuong_trinh');

  const resetMigration = (item) => {
    const migration = get(item, 'migration');

    dispatch(
      sagaActions.submitFormRequest('', {
        params: {
          iid: item.iid,
          ...migration,
        },
        url: endpoints.reset_migrate,
        formidToSubmitOnSuccess: searchFormId,
      }),
    );
  };

  const formatDataSource = () => {
    let dataSource = [];

    items.forEach((item) => {
      const migrations = get(item, 'migrations', []);

      if (!migrations.length) {
        dataSource.push({
          ...item,
          rowSpan: 1,
        });
      }

      dataSource = dataSource.concat(
        migrations.map((migration, index) => ({
          ...item,
          migration,
          rowSpan: !index ? migrations.length : 0,
        })),
      );
    });

    return dataSource;
  };

  const renderTableColumns = () => {
    return [
      {
        title: 'Tên đợt tập huân',
        dataIndex: 'name',
        render: (name, item) => ({
          children: name,
          props: {
            rowSpan: item.rowSpan,
          },
        }),
      },
      {
        title: 'Cấp',
        type: TYPE_OF_TABLE_DATA.TITLE,
        dataIndex: 'migration',
        render: (migration) => {
          const cap = get(migration, 'cap');

          return {
            children: <Tag className="break-word">{userGradeToText(cap)}</Tag>,
            props: {
              rowSpan: 1,
            },
          };
        },
      },
      {
        title: 'Đối tượng',
        type: TYPE_OF_TABLE_DATA.TITLE,
        dataIndex: 'migration',
        render: (migration) => {
          const targetUser = get(migration, 'target_user');

          return {
            children: (
              <Tag className="break-word">{getTargetUser(targetUser)}</Tag>
            ),
            props: {
              rowSpan: 1,
            },
          };
        },
      },
      {
        title: 'Chương trình',
        type: TYPE_OF_TABLE_DATA.TITLE,
        dataIndex: 'migration',
        render: (migration) => {
          if (!itemMigrated(migration)) {
            return null;
          }

          const chuongTrinh = get(migration, 'chuong_trinh');

          return {
            children: <ChuongTrinhTag chuongTrinh={chuongTrinh} shortName />,
            props: {
              rowSpan: 1,
            },
          };
        },
      },
      {
        title: 'Năm học',
        type: TYPE_OF_TABLE_DATA.TITLE,
        dataIndex: 'migration',
        render: (migration) => {
          if (!itemMigrated(migration)) {
            return null;
          }

          const schoolYear = get(migration, 'school_year');

          return {
            children: schoolYear,
            props: {
              rowSpan: 1,
            },
          };
        },
      },

      {
        title: 'Thời gian kết chuyển',
        type: TYPE_OF_TABLE_DATA.TITLE,
        dataIndex: 'migration',
        render: (migration) => {
          if (!itemMigrated(migration)) {
            return <span className="text-danger">Chưa kết chuyển dữ liệu</span>;
          }

          const ts = get(migration, 'ts');

          return {
            children: parseTSToDateTimeString(ts),
            props: {
              rowSpan: 1,
            },
          };
        },
      },
      {
        title: 'Thao tác',
        type: TYPE_OF_TABLE_DATA.TITLE,
        render: (item) => {
          const migration = get(item, 'migration');

          return (
            <>
              <DetailOnDialog
                renderPreview={({ showFull }) => {
                  return (
                    <button
                      className="btn btn-primary btn-small"
                      onClick={showFull}
                    >
                      Kết chuyển dữ liệu
                    </button>
                  );
                }}
                renderFull={({ closeDialog }) => {
                  return (
                    <Migrate
                      migrationData={item}
                      searchFormId={searchFormId}
                      closeDialog={closeDialog}
                    />
                  );
                }}
                dialogOptionsProperties={{
                  title: 'Kết chuyển dữ liệu',
                  modal: true,
                }}
              />

              {itemMigrated(migration) && (
                <div className="m-t-5">
                  <ActionBtnWithConfirmDialog
                    label="Hủy kết chuyển"
                    title="Hủy kết chuyển dữ liệu"
                    textConfirm="Việc làm này sẽ khôi phục các kết chuyển dữ liệu của ETEP sang bên BDTX. Bạn có chắc chắn muốn khôi phục kết chuyển dữ liệu?"
                    formid={searchFormId}
                    icon={'reset'}
                    alternativeApi={endpoints.bdtx_delete_training_phase}
                    actionHandler={() => resetMigration(item)}
                    className="btn-small"
                  />
                </div>
              )}
            </>
          );
        },
      },
    ];
  };

  return (
    <CommonAntdTable
      dataSource={formatDataSource(items)}
      columns={renderTableColumns()}
      pagination={false}
      className="learn-default"
    />
  );
};

export default connect()(TableResult);
