import React from 'react';
import CommonAntdTable from 'components/common/antd/table';
import makeReduxFormCompatible from 'components/common/makeReduxFormCompatible';
import {
  CHUONG_TRINH_03,
  CHUONG_TRINHS,
  getChuongTrinhName,
} from 'components/bdtx/configs';
import get from 'lodash.get';
import Checkbox from 'antd/lib/checkbox';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import { getTotalCredit, isEnoughCredit } from 'components/bdtx/utils';
import Tooltip from 'antd/lib/tooltip';
import TooltipWithIcon from 'common/tooltip';

import './stylesheet.scss';
import DeploymentPreview from 'components/bdtx/training-phase/mainstage/deployment-preview';
import DetailOnDialog from 'components/common/detail-on-dialog';

const TableForm = ({
  chosenModules,
  organizations,
  onChange,
  readOnly,
  valueSelected,
  modules,
  trainingPhaseIid,
  searchFormId,
  previewDeploymentHiddenFields,
  isCCModel,
}) => {
  const formatDataSource = () => {
    if (!organizations || !organizations.length) {
      return [];
    }

    if (organizations.length === 1 || isCCModel) {
      return organizations;
    }

    // TODO: Check with subtype
    // For now, API always return parent is first of array organizations
    const orgParent = organizations[0];
    const orgChildren = organizations.filter(
      (org) => org.organization.iid != orgParent.organization.iid,
    );

    return [
      {
        ...orgParent,
        children: orgChildren,
      },
    ];
  };

  const getValueFormFromOrganization = (organization) => {
    if (!valueSelected || !valueSelected.length || !organization) {
      return;
    }

    return valueSelected.find(
      (value) => value.organization == organization.iid,
    );
  };

  const getSelectedDataByOrganizationOfChuongTrinh = (
    organization,
    chuongTrinh,
  ) => {
    const valueForm = getValueFormFromOrganization(organization);
    const chuongTrinhs = get(valueForm, 'chuong_trinhs', []);
    return chuongTrinhs.find((ct) => ct.chuong_trinh == chuongTrinh);
  };

  const getModulesDeployedFromOrganization = (organization, chuongTrinh) => {
    const chuongTrinhModules = getSelectedDataByOrganizationOfChuongTrinh(
      organization,
      chuongTrinh,
    );

    return get(chuongTrinhModules, 'modules_deployed', []);
  };

  const getModulesFormFromOrganization = (organization, chuongTrinh) => {
    const chuongTrinhModules = getSelectedDataByOrganizationOfChuongTrinh(
      organization,
      chuongTrinh,
    );

    return get(chuongTrinhModules, 'modules', []);
  };

  const updateFormValue = (formValue) => {
    if (typeof onChange !== 'function') {
      return;
    }

    onChange(formValue);
  };

  const handleChangeAssignModule = ({
    checked,
    module,
    organization,
    chuongTrinh,
  }) => {
    const valueFormOfOrganization = getValueFormFromOrganization(organization);
    if (!valueFormOfOrganization) {
      const formValue = [
        ...valueSelected,
        {
          organization,
          chuong_trinhs: [
            {
              chuong_trinh: chuongTrinh,
              modules: [module.iid],
            },
          ],
        },
      ];

      updateFormValue(formValue);

      return;
    }

    const newValue = valueSelected.map((value) => {
      if (value.organization != valueFormOfOrganization.organization) {
        return value;
      }

      const chuongTrinhs = get(value, 'chuong_trinhs', []);
      const editingChuongTrinh = chuongTrinhs.find(
        (ct) => ct.chuong_trinh == chuongTrinh,
      );

      if (!editingChuongTrinh) {
        return {
          organization: organization.iid,
          chuong_trinhs: [
            ...chuongTrinhs,
            {
              chuong_trinh: chuongTrinh,
              modules: [module.iid],
            },
          ],
        };
      }

      const modules = get(editingChuongTrinh, 'modules', []);
      const newModules = checked
        ? [...modules, module.iid]
        : modules.filter((iid) => iid != module.iid);

      return {
        organization: organization.iid,
        chuong_trinhs: chuongTrinhs.map((ct) => ({
          ...ct,
          modules:
            ct.chuong_trinh === editingChuongTrinh.chuong_trinh
              ? newModules
              : ct.modules,
        })),
      };
    });

    updateFormValue(newValue);
  };

  const handleChangeAllAssignModule = ({ checked, module, chuongTrinh }) => {
    const valueForm = (organizations || []).map((org) => {
      const { organization } = org;
      const organizationValue = (valueSelected || []).find(
        (value) => value.organization == organization.iid,
      );

      if (!organizationValue) {
        return {
          organization: organization.iid,
          chuong_trinhs: [
            {
              chuong_trinh: chuongTrinh,
              modules: [module.iid],
            },
          ],
        };
      }

      let chuongTrinhs = get(organizationValue, 'chuong_trinhs', []);
      let editingChuongTrinh = chuongTrinhs.find(
        (ct) => ct.chuong_trinh == chuongTrinh,
      );
      if (!editingChuongTrinh) {
        chuongTrinhs = [
          ...chuongTrinhs,
          {
            chuong_trinh: chuongTrinh,
            modules: [module.iid],
          },
        ];
      } else {
        chuongTrinhs = chuongTrinhs.map((ct) => ({
          ...ct,
          modules:
            ct.chuong_trinh === editingChuongTrinh.chuong_trinh
              ? checked
                ? [...ct.modules, module.iid]
                : ct.modules.filter((md) => md != module.iid)
              : ct.modules,
        }));
      }

      return {
        organization: organization.iid,
        chuong_trinhs: chuongTrinhs,
      };
    });

    updateFormValue(valueForm);
  };

  const getSelectedDataByChuongTrinh = (chuongTrinh) => {
    if (!valueSelected || !valueSelected.length) {
      return;
    }

    return valueSelected
      .map((value) => {
        const chuongTrinhs = get(value, 'chuong_trinhs', []);

        return chuongTrinhs.find((ct) => ct.chuong_trinh == chuongTrinh);
      })
      .filter(Boolean);
  };

  const isSelectedAllModules = (module, chuongTrinh) => {
    if (!valueSelected || !valueSelected.length) {
      return;
    }

    const selectedDataByChuongTrinh = getSelectedDataByChuongTrinh(chuongTrinh);
    if (!selectedDataByChuongTrinh || !selectedDataByChuongTrinh.length) {
      return;
    }

    const modulesByChuongTrinh = selectedDataByChuongTrinh
      .map((dataByChuongTrinh) => {
        return get(dataByChuongTrinh, 'modules');
      })
      .filter(Boolean);

    const modulesSelected = modulesByChuongTrinh.filter((modules) =>
      modules.includes(module.iid),
    );

    return modulesSelected.length === organizations.length;
  };

  const hasOrganizationDeployed = (module, chuongTrinh) => {
    const selectedDataByChuongTrinh = getSelectedDataByChuongTrinh(chuongTrinh);
    if (!selectedDataByChuongTrinh || !selectedDataByChuongTrinh.length) {
      return;
    }

    const dataByChuongTrinh = selectedDataByChuongTrinh
      .map((dataByChuongTrinh) => {
        const modules = get(dataByChuongTrinh, 'modules_deployed', []);

        return modules.includes(module.iid);
      })
      .filter(Boolean);

    return !!dataByChuongTrinh.length;
  };

  const getTotalCreditByOrganization = (
    organization,
    modules,
    chuongTrinhs,
  ) => {
    if (!chuongTrinhs || !chuongTrinhs.length) {
      return;
    }

    let modulesForm = [];
    chuongTrinhs.forEach((ct) => {
      const modulesFormByChuongTrinh = getModulesFormFromOrganization(
        organization,
        ct,
      );

      modulesForm = modulesForm.concat(modulesFormByChuongTrinh);
    });

    const modulesObject = modulesForm
      .map((module) => {
        return (modules || []).find((md) => md.iid == module);
      })
      .filter(Boolean);

    return getTotalCredit(modulesObject);
  };

  const moduleHasMaterial = (module) => {
    return !!module.chosen_program_iid;
  };

  const renderTooltipIconStatus = (
    organization,
    module,
    chuongTrinh,
    deployed,
  ) => {
    if (!deployed) {
      // TODO: implement logic for this status
      return null;
    }

    return (
      <DetailOnDialog
        renderPreview={({ showFull }) => {
          return (
            <Tooltip title="Đơn vị đã được triển khai, vui lòng nhấn vào đây để xem chi tiết.">
              <div
                onClick={showFull}
                className="font-size-small assign-form__status-icon assign-form__status-icon--on-top-left primary"
              />
            </Tooltip>
          );
        }}
        renderFull={() => {
          const hiddenFields = {
            ...previewDeploymentHiddenFields,
            module_iid: get(module, 'iid'),
            ep_organization_iid: get(organization, 'iid'),
            chuong_trinh: chuongTrinh,
          };

          return (
            <DeploymentPreview
              hiddenFields={hiddenFields}
              trainingPhaseIid={trainingPhaseIid}
              searchFormId={searchFormId}
              noSearchForm
              isCCModel={isCCModel}
              showAction={!readOnly}
            />
          );
        }}
        dialogOptionsProperties={{
          title: <span>Chi tiết triển khai</span>,
          handleClose: true,
          modal: true,
          width: '90%',
        }}
      />
    );
  };

  const renderModuleColumn = (modules, chuongTrinh) => {
    return modules.map((module) => {
      const selectedAllModules = isSelectedAllModules(module, chuongTrinh);
      const enoughMaterial = moduleHasMaterial(module);
      const disabled = hasOrganizationDeployed(module, chuongTrinh);

      return {
        title: (
          <>
            <div className="m-b-10">
              <Tooltip title={get(module, 'name')}>
                {get(module, 'code')} ({get(module, 'credit')}t)
              </Tooltip>
              {!enoughMaterial && (
                <a
                  href={'/admin/bdtx/overview-module-materials/choose-material'}
                  target="_blank"
                >
                  <div
                    className={`${
                      enoughMaterial ? 'text-primary' : 'text-danger'
                    } font-size-small`}
                  >
                    {`${
                      enoughMaterial ? 'Đã gán học liệu' : 'Chưa gán học liệu'
                    }`}
                  </div>
                </a>
              )}
            </div>
            {enoughMaterial && !readOnly && (
              <div className="text-center m-t-5 assign-form__check-all">
                <Tooltip
                  title={`Chọn mô đun ${get(
                    module,
                    'name',
                  )} cho tất cả các đơn vị`}
                >
                  <Checkbox
                    className="checkbox-module"
                    checked={selectedAllModules}
                    onChange={(event) => {
                      const checked = get(event, 'target.checked');
                      handleChangeAllAssignModule({
                        checked,
                        module,
                        chuongTrinh,
                      });
                    }}
                    disabled={disabled}
                  />
                </Tooltip>
              </div>
            )}
          </>
        ),
        type: TYPE_OF_TABLE_DATA.TITLE,
        width: 100,
        className: 'position-relative',
        render: ({ organization }) => {
          const modulesForm = getModulesFormFromOrganization(
            organization,
            chuongTrinh,
          );
          const modulesDeployedForm = getModulesDeployedFromOrganization(
            organization,
            chuongTrinh,
          );

          const hasValue = !!modulesForm.find((md) => md == module.iid);
          const deployed = !!modulesDeployedForm.find((md) => md == module.iid);

          return {
            children: (
              <>
                {readOnly ? (
                  hasValue && <span className="ve-check text-primary" />
                ) : (
                  <Checkbox
                    className="checkbox-module"
                    checked={hasValue}
                    onChange={(event) => {
                      const checked = get(event, 'target.checked');
                      handleChangeAssignModule({
                        checked,
                        module,
                        organization,
                        chuongTrinh,
                      });
                    }}
                    disabled={!enoughMaterial || deployed}
                  />
                )}

                {renderTooltipIconStatus(
                  organization,
                  module,
                  chuongTrinh,
                  deployed,
                )}
              </>
            ),
            props: {
              className: 'text-center position-relative',
            },
          };
        },
      };
    });
  };

  const renderTotalCreditColumn = (modules, chuongTrinhs = CHUONG_TRINHS) => ({
    title: (
      <>
        Tổng số tiết đã chọn
        <TooltipWithIcon
          content={`Tổng số tiết đã chọn của các mô đun cho từng đơn vị cho chương trình: ${String(
            chuongTrinhs,
          )}`}
        />
      </>
    ),
    type: TYPE_OF_TABLE_DATA.TITLE,
    width: 100,
    render: ({ organization }) => {
      const totalCredit = getTotalCreditByOrganization(
        organization,
        modules,
        chuongTrinhs,
      );

      return {
        children: (
          <span
            className={`text-bold ${
              isEnoughCredit(totalCredit, chuongTrinhs.length)
                ? 'text-primary'
                : 'text-danger'
            }`}
          >
            {totalCredit}
          </span>
        ),
        props: {
          className: 'text-center position-relative',
        },
      };
    },
  });

  const renderTableColumns = () => {
    return [
      {
        title: 'Đơn vị con',
        render: ({ organization }) => {
          return {
            children: get(organization, 'name'),
          };
        },
      },
      ...CHUONG_TRINHS.map((ct) => {
        const title = getChuongTrinhName(ct);
        const shouldIncludeCreditColumn = ct == CHUONG_TRINH_03;

        const chuongTrinhModules = get(chosenModules, `${ct}.modules`, []);

        const creditColumn = shouldIncludeCreditColumn
          ? [renderTotalCreditColumn(chuongTrinhModules, [CHUONG_TRINH_03])]
          : [];

        return {
          title: title,
          type: TYPE_OF_TABLE_DATA.TITLE,
          children: [
            ...renderModuleColumn(chuongTrinhModules, ct),
            ...creditColumn,
          ],
        };
      }),
      renderTotalCreditColumn(modules),
    ];
  };

  return (
    <>
      <CommonAntdTable
        dataSource={formatDataSource()}
        columns={renderTableColumns()}
        pagination={false}
        className="learn-default assign-form"
      />
    </>
  );
};

export default makeReduxFormCompatible({})(TableForm);
