import {
  CHANGELOG_LOADING,
  CHANGELOG_LOADING_ERROR,
  CHANGELOG_LOADING_SUCCESS,
  SAVE_APP_VERSION,
  SAVE_SESSION_ENDED,
  SYSTEM_MESSAGE_CLEAR,
  SYSTEM_MESSAGE_LOADING,
  SYSTEM_MESSAGE_LOADING_ERROR,
  SYSTEM_MESSAGE_LOADING_SUCCESS,
} from './types';

export function saveAppVersion(appInfo) {
  return {
    type: SAVE_APP_VERSION,
    payload: {
      version: appInfo.version,
    },
  };
}

export function saveSessionEnded(appInfo) {
  return {
    type: SAVE_SESSION_ENDED,
    payload: {
      sessionEnded: appInfo.sessionEnded,
    },
  };
}

export function loadSystemMessage() {
  return {
    type: SYSTEM_MESSAGE_LOADING,
  };
}

export function loadSystemMessageSuccess(systemMessage) {
  return {
    type: SYSTEM_MESSAGE_LOADING_SUCCESS,
    payload: { systemMessage },
  };
}

export function loadSystemMessageError(error) {
  return {
    type: SYSTEM_MESSAGE_LOADING_ERROR,
    payload: { error },
  };
}

export function clearSystemMessage() {
  return {
    type: SYSTEM_MESSAGE_CLEAR,
  };
}

export function loadChangelog() {
  return {
    type: CHANGELOG_LOADING,
  };
}

export function loadChangelogSuccess(changelog) {
  return {
    type: CHANGELOG_LOADING_SUCCESS,
    payload: { changelog },
  };
}

export function loadChangelogError(error) {
  return {
    type: CHANGELOG_LOADING_ERROR,
    payload: { error },
  };
}
