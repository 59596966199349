import React from 'react';
import DisplayHtml from 'components/common/html';
import Heading from '../../common/Heading';

import './styles.scss';

const Statement = ({ item }) => {
  // const style = {
  //   // backgroundImage: 'url("http://vlms.local/ufiles/2020/e/10/5142da1a9c513f4a09000018/5f8803d50acc7b4f61545ae8.png")'
  //   backgroundImage: 'url("https://www.w3schools.com/cssref/paper.gif")',
  //   backgroundRepeat: 'repeat',
  // } ;

  return (
    <div>
      <Heading item={item} />
      <div className="content block-statement text-primary">
        <DisplayHtml content={item.content} />
      </div>
    </div>
  );
};

export default Statement;
