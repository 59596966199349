import React from 'react';
import store from 'store/index';
import get from 'lodash.get';
import { t1 } from 'translate/index';

import { equivalentPositions } from 'components/admin/equivalent-job-position/schema/elements/index';
import { evnEquivalentPositions } from 'components/admin/top-equivalent-position/schema/elements';
import { positions } from 'components/admin/job-position/schema/elements';
import { UiLibs } from 'configs/constants';
import skill from './filter/skill/skill';
import { text, textOp } from './filter/query/text';
import sex from './filter/sex/sex';
import age from './filter/age/age';
import experience from './filter/experience/experience';
import code from './filter/schema/code';
import codes from './filter/schema/codes';
import mails from './filter/schema/mails';
import dtts from './filter/schema/dtts';
import dia_ban_khokhan from './filter/schema/dia_ban_khokhan';
import name from './filter/schema/name';
import lname from './filter/schema/lname';
import external from './filter/schema/external';
import creditSyllabuses from './filter/schema/creditSyllabuses';
import iid from './filter/schema/iid';
import mail from './filter/schema/mail';
import statuses from './filter/schema/statuses';
import subject from './filter/schema/subject';
import contractBox from './filter/schema/contractBox';
import totalCreditBox from './filter/schema/totalCreditBox';
import averageScoreBox from './filter/schema/averageScoreBox';
import availableFilters from './filter/schema/availableFilters';
import getAvailableFilters, { editGroupFiltersFormId } from './utils';
import SearchFormLayoutFreestyle from './form-layouts/SearchFormLayoutFreestyle';
import { gradeElement, groupElement } from 'common/utils/form';
import userOrganizationsWithSubOrganizationCheckbox from 'components/admin/organization/schema/elements/organization-with-include-sub-organization/index';
import DatePicker from 'schema-form/elements/date-picker';
import { userGradeOptions } from 'configs/constants/user';
import { academicCategories } from 'components/admin/academic-category/schema/elements';
import trainingGrades from 'components/admin/group/schema/elements/filterset/filter/schema/trainingGrades';

import { taphuanTargetUserMulti } from 'components/admin/group/schema/elements/filterset/filter/schema/taphuanTargetUser';
import cotCanDataByCategory from 'components/admin/group/schema/elements/filterset/filter/schema/cotCanDataByCategory';

const getDefaultEnableFiltersKey = (isStaff) => {
  let defaultEnableFiltersKey = isStaff
    ? 'domainInfo.conf.default_enabled_staff_filters'
    : 'domainInfo.conf.default_enabled_target_group_filters';

  return defaultEnableFiltersKey;
};

const getAllowedEnableFiltersKey = (isStaff) => {
  let allowedEnableFiltersKey = isStaff
    ? 'domainInfo.conf.allowed_staff_filters'
    : 'domainInfo.conf.allowed_target_group_filters';

  return allowedEnableFiltersKey;
};

export function getDefaultByAllowedFilters(
  defaultEnabledFilters,
  allowedEnableFilters,
) {
  let index;
  const newDefaultEnabledFilters = [];
  for (let i = 0; i < defaultEnabledFilters.length; i++) {
    index = allowedEnableFilters.indexOf(defaultEnabledFilters[i]);
    if (index > -1) {
      newDefaultEnabledFilters.push(defaultEnabledFilters[i]);
    }
  }

  return newDefaultEnabledFilters;
}

const schema = (formid, values, localStep, xpath, props, domainInfo) => {
  const defaultEnableFiltersKey = getDefaultEnableFiltersKey(
    values && values.is_staff,
  );
  const allowedEnableFiltersKey = getAllowedEnableFiltersKey(
    values && values.is_staff,
  );
  let defaultEnabledFilters = get(
    store.getState(),
    defaultEnableFiltersKey,
  ) || ['text'];
  let allowedEnabledFilters = get(
    store.getState(),
    allowedEnableFiltersKey,
  ) || ['text'];

  const userOrganizations = get(
    values,
    xpath ? `${xpath}.user_organizations` : 'user_organizations',
  );

  defaultEnabledFilters = getDefaultByAllowedFilters(
    defaultEnabledFilters,
    allowedEnabledFilters,
  );

  return {
    availableFilters: availableFilters(
      defaultEnabledFilters,
      formid === editGroupFiltersFormId,
      values && values.is_staff,
      values && values.add_member_to_group_form,
    ),
    positions: positions(formid, {}, userOrganizations),
    equivalent_positions: equivalentPositions(formid, userOrganizations),
    evn_equivalent_positions: evnEquivalentPositions(formid, userOrganizations),
    user_organizations: userOrganizationsWithSubOrganizationCheckbox(
      formid,
      values,
      localStep,
      xpath,
      props,
      domainInfo,
    ),
    skill,
    text: text(),
    textOp: textOp(),
    sex: sex(),
    age: age(),
    experience: experience(),
    code: code(),
    codes: codes(),
    nin_codes: codes(true),
    mails: mails(),
    nin_mails: mails(true),
    dtts: dtts(),
    dia_ban_khokhan: dia_ban_khokhan(),
    lname: lname(),
    external: external(),
    name: name(),
    iid: iid(),
    mail: mail(),
    contract_box: contractBox,
    statuses: statuses(),
    credit_syllabuses: creditSyllabuses(formid),
    total_credit_box: totalCreditBox,
    average_score_box: averageScoreBox,
    subject,
    school__grade: gradeElement(domainInfo, true, t1('grade')), // for search
    school__group: groupElement(values, 'school__grade'),
    number_of_warnings: {
      type: 'number',
      min: 0,
      step: 1,
      floatingLabelText: t1('number_of_warnings'),
      classWrapper: 'col-md-6 m-t-10',
      fullWidth: true,
    },
    time_to_start: {
      type: DatePicker,
      uiLib: UiLibs.ANT,
      classWrapper: 'col-md-6',
      floatingLabelText: t1('date_user_join_learning'),
      fullWidth: true,
      getEndDate: true,
      maxDate: new Date(),
      autoOk: true,
    },
    grade: {
      type: 'multiCheckbox',
      floatingLabelText: t1('user_grade'),
      options: userGradeOptions(),
      inline: true,
    },
    training_grades: trainingGrades(formid, values.grade),
    academic_categories: academicCategories(formid, {}),
    cot_can_data_by_category: cotCanDataByCategory({}),
    target_user: taphuanTargetUserMulti(formid),
  };
};

const ui = (step, values, themeConfig, xpath, formid) => {
  const availFilters = getAvailableFilters(values, xpath);

  let fields = availFilters
    ? ['text', 'textOp', ...availFilters]
    : ['text', 'textOp'];
  // let fields;

  return [
    // TODO: should we switch if it's ums or enterprise here
    // or get the list from configurations, of which fields are searchable by each school
    {
      id: 'filters', // you still have to have this id even for freestyle
      fields,
    },
    {
      id: 'others',
      fields: ['availableFilters'],
    },
  ];
};

export default {
  schema,
  ui,
  layout: { component: SearchFormLayoutFreestyle, freestyle: 1 },
};
