import Links from 'routes/links';
import {
  isBranchedSCO,
  shouldNodeBeExpanded,
  isSplSCO,
  isNavigationSCO,
  shouldSkipNavigation,
  isSequentialSCO,
  isSCO,
  isExercise,
} from 'common/learn';
import { ntype } from 'configs/constants';
import { learningItemTypeViewers } from 'components/learn/viewer/constants';
import {
  courseShouldDisplayOverview,
  getDefaultCourseLink,
} from 'components/front-end/course/utils';
import { isIidEqual } from '../utils';
import get from 'lodash.get';
import { isScormSco } from 'components/admin/scorm/scorm';

export const createNavId = (parentNode, node, index = 1, useIndex = true) => {
  const indexSuffix = useIndex ? `-${index}` : '';

  return parentNode && parentNode.iid
    ? `${parentNode.iid}-${node.iid}${indexSuffix}`
    : `${node.iid}${indexSuffix}`;
};

export const createLinkForItem = (course, nodes, node, options = {}) => {
  const parentItems = nodes[node.pid];

  let {
    navRootNodeIid = null,
    pathIid = null,
    learnMode = learningItemTypeViewers.LEARN,
    isPreview = false,
    index = 1,
    useIndex = true,
    navId = null,
  } = options;

  navId = navId || createNavId(parentItems, node, index, useIndex);

  if (
    (node.ntype === ntype.COURSE || node.ntype === ntype.SYLLABUS) &&
    courseShouldDisplayOverview(node)
  ) {
    return getDefaultCourseLink(course, navId);
  }

  return Links.LearnCourseByPath(course, navId, {
    pathIid,
    isPreview,
    learnMode,
    navRootNodeIid,
  });
};

let firstItemOfSyllabus = null;

export const generateSyllabusNavs = (
  syllabus,
  course,
  nodes = [],
  isPreview = false,
) => {
  const branchedNode = syllabus;
  const currentNodeLevel = 0;
  const includeSplSCO = false;
  const flattenSyllabusTree = flattenNodeTree(syllabus, nodes, includeSplSCO);

  return generateNavItemForNode(
    syllabus,
    branchedNode,
    currentNodeLevel,
    course,
    nodes,
    flattenSyllabusTree,
    isPreview,
  );
};

// main function to generate nav info for all syllabus tree nodes
const generateNavItemForNode = (
  node,
  branchedNode,
  currentNodeLevel,
  course,
  nodes = [],
  flattenSyllabusTree = [],
  isPreview,
) => {
  if (node.ntype === ntype.QUESTION) {
    return null;
  }

  // get all available info for node
  const nodeWithNavInfo = getNavInfoForNode(
    node,
    branchedNode,
    currentNodeLevel,
    course,
    nodes,
    flattenSyllabusTree,
    isPreview,
  );

  if (
    !isSplSCO(node) &&
    !isNavigationSCO(node) &&
    Array.isArray(node.children)
  ) {
    const children = [];

    node.children.forEach((childIid) => {
      const childNode = nodes[childIid];

      if (childNode) {
        const childWithNavInfo = generateNavItemForNode(
          childNode,
          nodeWithNavInfo.branchedNode,
          currentNodeLevel + 1,
          course,
          nodes,
          flattenSyllabusTree,
          isPreview,
        );

        if (childWithNavInfo) {
          children.push(childWithNavInfo);
        }
      }
    });

    if (children.length) {
      nodeWithNavInfo.children = children;
    }
  }

  return nodeWithNavInfo;
};

const getNavInfoForNode = (
  node,
  branchedNode,
  currentNodeLevel,
  course,
  nodes = [],
  flattenSyllabusTree = [],
  isPreview,
) => {
  if (!node) {
    return null;
  }

  const newBranchedNode = isBranchedSCO(node) ? node : branchedNode; // gặp 1 thằng branched node mới thì set branchedNode cho current node
  const parentNode = nodes[node.pid];

  const nextNode = getNextNodeFromFlattenSyllabusTree(
    node,
    nodes,
    flattenSyllabusTree,
  );
  const prevNode = getPrevNodeFromFlattenSyllabusTree(
    node,
    nodes,
    flattenSyllabusTree,
  );

  if (!firstItemOfSyllabus && node.ntype === ntype.SYLLABUS && nextNode) {
    firstItemOfSyllabus = nextNode;
  }

  let isFirstItemOfSyllabus =
    firstItemOfSyllabus && isIidEqual(node.iid, firstItemOfSyllabus.iid);

  return {
    iid: node.iid,
    name: node.name,
    ntype: node.ntype,
    tpl_type: node.tpl_type,
    type: node.type,
    tags: node.tags,
    prevNode,
    nextNode,
    link:
      shouldSkipNavigation(node) && nextNode
        ? createLinkForItem(course, nodes, nextNode, {
            navRootNodeIid: newBranchedNode.iid,
            isPreview,
          })
        : createLinkForItem(course, nodes, node, {
            navRootNodeIid: newBranchedNode.iid,
            isPreview,
          }),
    navId: createNavId(parentNode, node),
    isFirstItemOfSCO: isFirstItemOfSCO(node, nodes, flattenSyllabusTree),
    isLastItemOfSCO: isLastItemOfSCO(node, nodes, flattenSyllabusTree),
    isFirstItemOfSyllabus,
    isLastItemOfSyllabus: isIidEqual(
      node.iid,
      flattenSyllabusTree[flattenSyllabusTree.length - 1],
    ),
    branchedNode: newBranchedNode,
    isHeader: ['sco', 'survey'].includes(node.ntype),
    isExpand: shouldNodeBeExpanded(node),
    is_spl: isSplSCO(node),
    shouldSkipNavigation: shouldSkipNavigation(node),
    treeDepth: currentNodeLevel,
    isNavigationSCO: isNavigationSCO(node),
  };
};

export const flattenNodeTree = (node, nodes, includeSplSCO = true) => {
  const ret = [node.iid];

  if (Array.isArray(node.children)) {
    if ((!isSplSCO(node) || includeSplSCO) && !isNavigationSCO(node)) {
      node.children.forEach((childIid) => {
        const child = nodes[childIid];

        if (child && child.ntype !== ntype.QUESTION) {
          ret.push(...flattenNodeTree(child, nodes, includeSplSCO));
        }
      });
    }
  }

  return ret;
};

export const flattenAllChildrenInSCOSPL = (
  node,
  nodes,
  includeSequentialSCO = false,
) => {
  const children = get(node, 'children', []);
  if (!children || !children.length) {
    return [];
  }

  const ret = [];
  if (
    isNavigationSCO(node) ||
    (!includeSequentialSCO && isSequentialSCO(node))
  ) {
    return ret;
  }

  children.forEach((childIid) => {
    const child = nodes[childIid];
    if (!child || child.iid == node.iid) {
      return;
    }

    const childrenOfSco = get(child, 'children', []);
    const isSCOHasChildren = isSCO(child) && childrenOfSco.length;

    if (isSCOHasChildren && !isExercise(child) && !isScormSco(child)) {
      ret.push(
        ...flattenAllChildrenInSCOSPL(child, nodes, includeSequentialSCO),
      );
    } else {
      ret.push(child);
    }
  });

  return ret;
};

const getNextNodeFromFlattenSyllabusTree = (
  node,
  nodes,
  flattenSyllabusTree,
) => {
  const nodeIndex = flattenSyllabusTree.findIndex((nodeIid) =>
    isIidEqual(nodeIid, node.iid),
  );

  if (nodeIndex >= 0 && nodeIndex < flattenSyllabusTree.length - 1) {
    for (
      let nextIndex = nodeIndex + 1;
      nextIndex < flattenSyllabusTree.length;
      nextIndex++
    ) {
      const nextIid = flattenSyllabusTree[nextIndex];

      if (!shouldSkipNavigation(nodes[nextIid])) {
        return nodes[nextIid];
      }
    }
  }

  return null;
};

const getPrevNodeFromFlattenSyllabusTree = (
  node,
  nodes,
  flattenSyllabusTree,
) => {
  const nodeIndex = flattenSyllabusTree.findIndex((nodeIid) =>
    isIidEqual(nodeIid, node.iid),
  );

  if (nodeIndex > 0) {
    for (let prevIndex = nodeIndex - 1; prevIndex > 0; prevIndex--) {
      const prevIid = flattenSyllabusTree[prevIndex];

      if (!shouldSkipNavigation(nodes[prevIid])) {
        return nodes[prevIid];
      }
    }
  }

  return null;
};

const isFirstItemOfSCO = (node, nodes, flattenSyllabusTree) => {
  const nodeIndex = flattenSyllabusTree.findIndex((nodeIid) =>
    isIidEqual(nodeIid, node.iid),
  );

  if (nodeIndex > 0) {
    for (let prevIndex = nodeIndex - 1; prevIndex > 0; prevIndex--) {
      const prevIid = flattenSyllabusTree[prevIndex];
      const prevNode = nodes[prevIid];

      if (!shouldSkipNavigation(prevNode)) {
        return false;
      }

      if (isBranchedSCO(prevNode) || isNavigationSCO(prevNode)) {
        return true;
      }
    }
  }

  return false;
};

const isLastItemOfSCO = (node, nodes, flattenSyllabusTree) => {
  const nodeIndex = flattenSyllabusTree.findIndex((nodeIid) =>
    isIidEqual(nodeIid, node.iid),
  );

  if (nodeIndex === flattenSyllabusTree.length - 1) {
    return true;
  }

  if (nodeIndex >= 0) {
    for (
      let nextIndex = nodeIndex + 1;
      nextIndex < flattenSyllabusTree.length;
      nextIndex++
    ) {
      const nextIid = flattenSyllabusTree[nextIndex];
      const nextNode = nodes[nextIid];

      if (!shouldSkipNavigation(nextNode)) {
        return false;
      }

      if (isBranchedSCO(nextNode) || isNavigationSCO(nextNode)) {
        return true;
      }
    }
  }

  return false;
};
