import { getCurrentUnixTimestamp } from 'common/utils/Date';
import lodashGet from 'lodash.get';
import get from 'lodash.get';
import { arrayIntersect } from 'common/utils/Array';
import { t1 } from 'translate';

export const isCourseEnded = (course, serverTs = null) => {
  const endDate = lodashGet(course, 'end_date');
  const currentTs = serverTs || getCurrentUnixTimestamp();

  return endDate && endDate < currentTs;
};

export const isCourseSameAcademicCategories = (course1, course2) => {
  const course1AcademicCategories = get(course1, 'academic_categories', []);
  const course2AcademicCategories = get(course2, `academic_categories`, []);

  return (
    arrayIntersect(course1AcademicCategories, course2AcademicCategories)
      .length === course1AcademicCategories.length
  );
};

export const courseDeadlineStatus = {
  all: 0,
  active: 1,
  ended: -1,
};

export const courseDeadlineOptions = () => [
  {
    label: t1('all'),
    value: courseDeadlineStatus.all,
  },
  {
    label: t1('course_active'),
    value: courseDeadlineStatus.active,
  },
  {
    label: t1('course_ended'),
    value: courseDeadlineStatus.ended,
  },
];
