import getUser from 'common/auth';
import get from 'lodash.get';

export const saveValueToLocalStorage = (value, key) => {
  const userInfo = getUser();
  const userId = get(userInfo, 'info.iid', '');
  const currentData = getDataOfAllUserFromLocalStorage(key) || {};
  const newData = {
    ...currentData,
    [userId]: value,
  };

  localStorage.setItem(key, JSON.stringify(newData));
};

export const getDataOfAllUserFromLocalStorage = (key) => {
  const dataString = localStorage.getItem(key);
  if (dataString) {
    return JSON.parse(dataString);
  }

  return null;
};

export const getDataOfCurrentUser = (key) => {
  const userInfo = getUser();
  const userId = get(userInfo, 'info.iid', '');
  const dataFromLocalStorage = getDataOfAllUserFromLocalStorage(key) || {};

  return get(dataFromLocalStorage, userId);
};
