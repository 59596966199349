import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import PreviewUser from './PreviewUser';
import lodashGet from 'lodash.get';
import Avatar from 'components/common/avatar';
import fetchData from 'components/common/fetchData';
import userApiEndpoints from 'components/admin/user/endpoints';
import Loading from 'components/common/loading';
import { t1 } from 'translate';

const UserDetailInDialog = ({
  user,
  userFromServer,
  closeDialog,
  openDetailInNewTab,
  showFullDetailButton,
  hiddenItem,
  showItem,
  loadingStatus,
}) => {
  let userInfo = userFromServer || user;
  return (
    <>
      <Avatar
        user={userInfo}
        size={200}
        style={{
          display: 'block',
          margin: '0 auto',
        }}
      />

      <hr />

      {loadingStatus !== 'finished' ? (
        <div className="text-center">
          <Loading circularLoadingIcon />
          <div>{t1('getting_user_info')}</div>
        </div>
      ) : (
        <PreviewUser
          user={userInfo}
          closeDialog={closeDialog}
          openDetailInNewTab={openDetailInNewTab}
          showFullDetailButton={showFullDetailButton}
          hiddenItem={hiddenItem}
          showItem={showItem}
        />
      )}
    </>
  );
};

const fetchUser = fetchData(({ user }) => {
  const userIid = lodashGet(user, 'iid');

  return {
    baseUrl: userApiEndpoints.get_user_basic_info,
    params: {
      iid: userIid,
      ntype: 'user',
    },
    propKey: 'userFromServer',
    fetchCondition: userIid,
  };
});

const UserDetailInDialogWithFetchUser = fetchUser(UserDetailInDialog);

class PreviewUserInDialog extends Component {
  renderFull = (
    closeDialog,
    user,
    showFullDetailButton,
    hiddenItem,
    showItem,
    openDetailInNewTab,
    fetchUserFromServer,
  ) => {
    return (
      <UserDetailInDialogWithFetchUser
        user={user}
        closeDialog={closeDialog}
        showFullDetailButton={showFullDetailButton}
        hiddenItem={hiddenItem}
        showItem={showItem}
        openDetailInNewTab={openDetailInNewTab}
        fetchUserFromServer={fetchUserFromServer}
      />
    );
  };

  render() {
    const {
      user,
      children,
      showFullDetailButton,
      hiddenItem,
      showItem,
      openDetailInNewTab,
      field,
      fallbackToUsername,
    } = this.props;
    const userName = lodashGet(user, 'name');
    const userId = lodashGet(user, 'id');
    const userIid = lodashGet(user, 'iid');

    return (
      <DetailOnDialog
        renderPreview={({ showFull }) => (
          <span onClick={showFull} className="cursor-pointer">
            {field
              ? lodashGet(user, field) ||
                (fallbackToUsername ? userName : children)
              : children}
            {field === 'code' && ` (#${userIid})`}
          </span>
        )}
        renderFull={({ closeDialog }) =>
          this.renderFull(
            closeDialog,
            user,
            showFullDetailButton,
            hiddenItem,
            showItem,
            openDetailInNewTab,
          )
        }
        dialogKey={`user-info-${userId}`}
        dialogOptionsProperties={{
          title: `${userName} ${
            !(hiddenItem && hiddenItem.includes('iid')) ? `(#${userIid})` : ''
          }`,
          width: '40%',
        }}
      />
    );
  }
}

PreviewUserInDialog.propTypes = {
  children: PropTypes.any,
  field: PropTypes.any,
  hiddenItem: PropTypes.array,
  showItem: PropTypes.array,
  showFullDetailButton: PropTypes.bool,
  user: PropTypes.any,
};

PreviewUserInDialog.defaultProps = {
  fallbackToUsername: true,
};

export default PreviewUserInDialog;
