import React from 'react';
import ClassyQuote from './classy';
import './styles.scss';

const BlockQuote = ({ item }) => {
  return (
    <div className="content quote" id={item.id}>
      <ClassyQuote item={item} />
    </div>
  );
};

export default BlockQuote;
