import React from 'react';
import { getUrlParam } from 'routes/links/common';
import GroupEditContainer from './EditContainerV2';
import { withRouter } from 'react-router';
import lodashGet from 'lodash.get';

/**
 *
 * @param props
 *  mode could either be 'admin' if we're editing group inside admin.
 *    possible screens: 'meet'
 * @returns {*}
 * @constructor
 */
const GroupEdit = (props) => {
  /// get stuff from url
  const iid = getUrlParam(props, 'iid');
  const action = getUrlParam(props, 'action');
  const subAction = getUrlParam(props, 'subAction');
  return (
    <GroupEditContainer
      iid={iid}
      action={action}
      subAction={subAction}
      mode={props.mode || 'admin'}
      {...props}
    />
  );
};

export default withRouter(GroupEdit);
