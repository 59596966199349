import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import { t1 } from 'translate';
import Icon from 'antd/lib/icon';
import { Tooltip } from 'antd';

const RejectedMessage = ({ className = '', comment = '' }) => {
  return (
    <div
      className={`rejected-message ${className}`}
      style={{ marginTop: '-2px' }}
    >
      <DetailOnDialog
        dialogKey="view_detail_rejected-comment"
        renderPreview={({ showFull }) => (
          <Tooltip title="Bài làm bị GVSP từ chối." placement="bottom">
            <div onClick={showFull} className="cursor-pointer">
              <span className="text-danger m-r-4 m-l-4">
                <Icon type="close-circle" />
              </span>
            </div>
          </Tooltip>
        )}
        renderFull={() => <div>{comment}</div>}
        dialogOptionsProperties={{
          title: t1('reason'),
          handleClose: true,
          width: '60%',
        }}
      />
    </div>
  );
};

export default RejectedMessage;
