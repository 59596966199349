import React, { useCallback, useEffect, useMemo, useState } from 'react';
import withUserInfo from 'common/hoc/withUserInfo';
import DisplayHtml from './html';
import { Modal } from 'antd';
import { t1 } from 'translate';

export const ALERT_STORAGE_KEY = {
  SYSTEM: 'system_alert_closed',
  COURSE: 'course_alert_closed',
};

export const generateStorageKey = (userIid, messageKey) =>
  `${userIid}_${messageKey}`;

const getUserAlertClosedObject = (storageKey) => {
  const alertClosed = localStorage.getItem(storageKey) || '';

  return alertClosed ? JSON.parse(alertClosed) : null;
};

const isUserClosedAlert = (storageKey, messageKey) => {
  const alertClosedObject = getUserAlertClosedObject(storageKey);

  if (!alertClosedObject) {
    return false;
  }

  return !!alertClosedObject[messageKey];
};

const setUserCloseAlert = (storageKey, messageKey) => {
  let alertClosedObject = getUserAlertClosedObject(storageKey);

  if (alertClosedObject) {
    alertClosedObject[messageKey] = true;
  } else {
    alertClosedObject = {
      [messageKey]: true,
    };
  }

  localStorage.setItem(storageKey, JSON.stringify(alertClosedObject));
};

const SystemAlert = ({
  userInfo,
  storageKey,
  messageKey,
  message,
  type = 'error',
  title,
}) => {
  const messageId = generateStorageKey(userInfo.iid, messageKey);
  const alertClosed = isUserClosedAlert(storageKey, messageId);
  const [isMessageVisible, setMessageVisible] = useState(false);

  useEffect(
    () => {
      if (message && !alertClosed) {
        if (!isMessageVisible) {
          setMessageVisible(true);
        }
      } else if (isMessageVisible) {
        setMessageVisible(false);
      }
    },
    [message, alertClosed, isMessageVisible],
  );

  const handleCloseMessageUpdateCourse = useCallback(
    () => {
      setUserCloseAlert(storageKey, messageId);

      setMessageVisible(false);
    },
    [setMessageVisible, messageId, storageKey],
  );

  const ModalType = useMemo(
    () => {
      return Modal[type];
    },
    [type],
  );

  useEffect(
    () => {
      if (!isMessageVisible || !message) {
        return;
      }

      ModalType({
        centered: true,
        title: title || t1('notify'),
        content: <DisplayHtml content={message} />,
        onOk: () => {
          handleCloseMessageUpdateCourse();
        },
      });
    },
    [
      isMessageVisible,
      title,
      message,
      ModalType,
      handleCloseMessageUpdateCourse,
    ],
  );

  return null;
};

export default withUserInfo(SystemAlert);
