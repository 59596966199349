import React from 'react';
import get from 'lodash.get';
import { t1 } from 'translate';
import AntdTable from 'antd/lib/table';
import AntInput from 'antd/lib/input';
import Attachments from 'schema-form/elements/attachments';

const getColumns = ({ value, readOnly, onChange, handleRowOnChange }) => {
  return [
    {
      width: '20%',
      render: ({ isParent, name }) => {
        return {
          children: name,
          props: {
            colSpan: isParent ? 2 : 1,
            style: isParent
              ? {
                  fontWeight: 'bold',
                }
              : {},
          },
        };
      },
    },
    {
      title: t1('answer'),
      render: ({ isParent, id, input_submit, ...row }) => {
        let currentValue = (Array.isArray(value) &&
          value.find((val) => id === val.id)) || { id };

        return {
          children: [
            Array.isArray(input_submit) && input_submit.includes('text') && (
              <div>
                <AntInput.TextArea
                  autoSize={{
                    minRows: 2,
                  }}
                  value={currentValue.content || ''}
                  onChange={(event) => {
                    currentValue = {
                      ...currentValue,
                      content: get(event, 'target.value'),
                    };
                    handleRowOnChange(onChange, value, currentValue);
                  }}
                />
              </div>
            ),
            Array.isArray(input_submit) && input_submit.includes('youtube') && (
              <div className="m-t-15">
                <div className="m-b-5">{t1('youtube')}</div>
                <AntInput
                  readOnly={readOnly}
                  value={get(currentValue, 'youtube')}
                  onChange={(event) => {
                    currentValue = {
                      ...currentValue,
                      youtube: get(event, 'target.value'),
                    };
                    handleRowOnChange(onChange, value, currentValue);
                  }}
                  key={`input_${id}`}
                />
              </div>
            ),
            Array.isArray(input_submit) && input_submit.includes('attachment') && (
              <div className="m-t-15">
                <Attachments
                  justShowFileName
                  allowDownload
                  viewerFileExtensions="all"
                  readOnly={readOnly}
                  value={get(currentValue, 'attachments') || []}
                  onChange={(attachments) => {
                    currentValue = {
                      ...currentValue,
                      attachments,
                    };
                    handleRowOnChange(onChange, value, currentValue);
                  }}
                  key={`input_${id}`}
                />
              </div>
            ),
          ],
          props: {
            colSpan: isParent ? 0 : 1,
            style: {
              maxWidth: '300px',
            },
          },
        };
      },
    },
  ];
};

const TableAnswerByStructure = ({
  value,
  dataSource,
  readOnly,
  onChange,
  handleRowOnChange,
}) => {
  return (
    <AntdTable
      className="white-background table-open-ended-question"
      dataSource={dataSource}
      columns={getColumns({
        value,
        readOnly,
        onChange,
        handleRowOnChange,
      })}
      defaultExpandAllRows
      expandIcon={() => <span />}
      onExpand={() => {}}
      pagination={false}
      bordered
      size="middle"
    />
  );
};

export default TableAnswerByStructure;
