import React from 'react';
import lodashGet from 'lodash.get';
import { BLOCK_SIZE_MIDDLE } from 'components/admin/video/schema/block-settings/font-size';
import { BLOCK_IMAGE_SIZE_FULL } from 'components/admin/video/schema/block-settings/image-size';
import {
  BLOCK_PADDING_LARGE,
  BLOCK_PADDING_MIDDLE,
} from 'components/admin/video/schema/block-settings/padding';

export const getBlockItemSize = (item) =>
  lodashGet(item, 'block_settings.size', BLOCK_SIZE_MIDDLE);

export const getBlockItemImageSize = (item) =>
  lodashGet(item, 'block_settings.image_size', BLOCK_IMAGE_SIZE_FULL);

export const getBlockItemImageStyle = (item) => {
  const size = getBlockItemImageSize(item);

  if (size === BLOCK_IMAGE_SIZE_FULL) {
    return {
      width: '100%',
    };
  }

  return {};
};

export const getBlockAnimation = (inOut, item) => {
  const v = lodashGet(item, `block_settings.animation.${inOut}`);
  if (inOut == 'in' && v == 'fade') return 'fadeIn';
  else if (inOut == 'out' && v == 'fade') return 'fadeOut';
  else if (inOut == 'in' && v == 'linear') return 'linearInUp';
  else if (inOut == 'out' && v == 'linear') return 'linearOutUp';

  return '';
};

export const getBorderSettings = (item) =>
  lodashGet(item, 'block_settings.border');

export const getPaddingSettings = (item) =>
  lodashGet(item, 'block_settings.padding');

export const getTextAlignSettings = (item) =>
  lodashGet(item, 'block_settings.text_align');

export const getHeadingColorSettings = (item) =>
  lodashGet(item, 'block_settings.heading_color');

export const getWrapperClass = (item) => {
  let className;

  const padding = getPaddingSettings(item);
  if (padding == BLOCK_PADDING_LARGE) {
    className = 'p-20';
  } else if (padding == BLOCK_PADDING_MIDDLE) {
    className = 'p-10';
  } else {
    className = 'p-5';
  }

  const textAlign = getTextAlignSettings(item);
  if (textAlign == 'center') className = `${className} text-center`;

  if (getBorderSettings(item)) {
    const border = getBorderSettings(item);

    if (border.includes('bottom')) {
      className = `${className} border-bottom`;
    }

    if (border.includes('top')) {
      className = `${className} border-top`;
    }

    if (border.includes('left')) {
      className = `${className} border-left`;
    }

    if (border.includes('right')) {
      className = `${className} border-right`;
    }

    if (border.includes('all')) {
      className = `${className} border border-round`;
    }
  }

  if (getBlockItemSize(item)) {
    const size = getBlockItemSize(item);
    className = `${className} ${size}`;
  }

  return className;
};

export const getInlineStyle = (item) => {
  const style = {};

  if (lodashGet(item, 'block_settings.background_color')) {
    style.backgroundColor = lodashGet(
      item,
      'block_settings.background_color',
      'white',
    );
  }
  if (lodashGet(item, 'block_settings.text_color')) {
    style.color = lodashGet(item, 'block_settings.text_color');
  }

  return style;
};
