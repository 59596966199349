import { t1 } from 'translate';
import get from 'lodash.get';

export const courseTeachers = ({
  label,
  guide = '',
  courseIids = [],
  courseIidsOrCodes = '', // nếu tìm teacher theo courseIidsOrCodes thì phải truyền cả tpIid
  tpIid,
  selectedTeacherIid,
}) => {
  const key =
    'teacher_list_' + (courseIids || []).join('_') + '_' + courseIidsOrCodes;
  const apiUrl = '/course/staff/list';

  return {
    type: 'select',
    multiple: true,
    floatingLabelText: label || t1('teachers'),
    options: 'async',
    populateValue: true,
    defaultValue: selectedTeacherIid || null,
    paramsasync: {
      key,
      __url__: apiUrl,
      transformData: (teachers) => {
        return (teachers || []).map((teacher) => {
          const iid = get(teacher, 'iid');
          const name = get(teacher, 'name');

          return {
            value: iid,
            label: `${name} (#${iid})`,
          };
        });
      },
      value: {
        course_iids: courseIids,
        course_iids_or_codes: courseIidsOrCodes,
        training_plan_iid: tpIid,
      },
    },
    guide: guide,
  };
};
