export const BEGIN_UPLOAD_FILE = 'BEGIN_UPLOAD_FILE';
export const FINISH_UPLOAD_FILE = 'FINISH_UPLOAD_FILE';

export const DEFAULT_SUBMISSION_KEY = 'DEFAULT_SUBMISSION_KEY';

export const beginUploadFile = () => ({
  type: BEGIN_UPLOAD_FILE,
});

export const finishUploadFile = () => ({
  type: FINISH_UPLOAD_FILE,
});
