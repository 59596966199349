import React from 'react';
import VideoPlayer from 'components/common/media-player/video';
import HtmlContent from 'components/common/html';
import AttachmentViewer from '../attachment-viewer';
import { Card } from 'antd';
import { t1 } from 'translate';
import get from 'lodash.get';
import Store from 'store';
import DigitalSupportPlan from 'components/common/forms/questions/open-ended/digital-plan';
import { isOldTrainingPlanCategory } from 'components/teacher-dashboard/my-support-plan/utils';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';

const ViewAnswer = ({ answer, showHeader = true }) => {
  const module = get(answer, 'content.module');
  const domainInfo = get(Store.getState(), 'domainInfo');
  const isDigitalKHHT =
    module && !isOldTrainingPlanCategory(module, domainInfo);

  const hasAnswers =
    answer.youtube ||
    answer.content ||
    (answer.attachments && answer.attachments.length);

  if (!hasAnswers) {
    return <SimpleNoResult text={t1('have_no_answers')} />;
  }

  return (
    <div className={hasAnswers ? 'm-t-20' : ''}>
      {answer.youtube && (
        <Card
          type="inner"
          hoverable
          title={t1('question_open_ended_your_video')}
          style={{ marginBottom: 30 }}
        >
          <VideoPlayer
            controls
            youTubeId={answer.youtube}
            autoPlay="true"
            width="100%"
          />
        </Card>
      )}

      {answer.content ? (
        <Card
          type="inner"
          hoverable
          title={
            isDigitalKHHT
              ? t1('question_open_ended_your_support_plan')
              : t1('question_open_ended_your_text')
          }
          style={{ marginBottom: 30 }}
        >
          {isDigitalKHHT ? (
            <DigitalSupportPlan
              value={answer.content}
              module={module}
              disabled
              showScroll
            />
          ) : (
            <HtmlContent content={answer.content} showLessMore />
          )}
        </Card>
      ) : null}

      {answer.attachments && answer.attachments.length > 0 && (
        <Card
          type="inner"
          hoverable
          title={t1('question_open_ended_your_files')}
          style={{ marginBottom: 30 }}
        >
          <AttachmentViewer
            attachments={answer.attachments}
            showHeader={showHeader}
          />
        </Card>
      )}
    </div>
  );
};

export default ViewAnswer;
