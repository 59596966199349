import React from 'react';
import PropTypes from 'prop-types';
import './pagination.scss';
import Pagination from 'antd/lib/pagination';

class Control extends React.Component {
  spanStyle = { paddingRight: '1em' };

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
    };
  }

  onChange = (page) => {
    const { onPageChange } = this.props;
    if (typeof onPageChange === 'function') {
      onPageChange(parseInt(page, 10));
    }
  };

  render() {
    const { className, pages, page } = this.props;

    return (
      <Pagination
        simple
        current={page}
        total={pages}
        onChange={this.onChange}
        defaultPageSize={1}
        className={className ? className : 'lecture-pdf-pagination'}
      />
    );
  }
}

Control.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onPageChange: PropTypes.func,
  onPrevious: PropTypes.func,
  page: PropTypes.number,
  pages: PropTypes.number,
};

Control.defaultProps = {
  className: '',
  onNext: null,
  onPageChange: null,
  onPrevious: null,
  page: 0,
  pages: 0,
};

export default Control;
