import 'common/grid-bootstrap/grid-bootstrap.scss';
import 'antd/dist/antd.css';
import './stylesheet.scss';
import './fonts.scss';

import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Link, Redirect } from 'react-router-dom';
import { matchRoutes, renderRoutes } from 'react-router-config';
import Helmet from 'react-helmet';
import get from 'lodash.get';
import userLinks from 'routes/links/user';
import { t1 } from 'translate';
import sagaActions from 'actions/saga-creators';
import actions from 'actions/creators';
import { layoutSelector } from 'utils/selector';
import { layouts, loadingStatuses } from 'configs/constants';
import { getFacebookAppIdFromConf } from 'common/conf';
import Loading from 'components/common/loading';
import Perm from 'common/utils/Perm';
import menuActions from 'actions/admin/menu/actions';
import {
  hideTawk,
  initTawk,
  isTawkInitialized,
  shouldShowTawk,
  showTawk,
} from 'common/3rd-party/tawk-to';
import {
  hideOmi,
  initOmi,
  isOmiInitialized,
  shouldShowOmi,
  showOmi,
} from 'common/3rd-party/omi-crm';
import { getFontByLanguage, saveRefererLink } from 'utils/Util';
import LayoutRegister from 'layouts/register';
import initFont from './font-loader-async';
import layoutContextAction from '../actions/layout-context';
import SupportedBrowsers from 'components/common/supported-browsers';
import TetHolidaySupport from 'components/common/tet-holiday-support';
import {
  doesUserMissingData,
  enterMissingData,
} from 'components/temis/common/routes.js';
import fetchData from 'components/common/fetchData';
import { adminMenuItems } from 'configs/constants/menu';

import ModalDialogs from 'components/common/modal/Dialogs';
import SnackbarSimple from 'components/common/snackbar/Snackbar';
import LoginAsAlert from 'components/common/LoginAsAlert';
import GoogleAnalytics from 'components/common/google-analytics';
import SupportUserInfoNotification from 'components/common/system/support-user-info-notification';
import LoginDialog from 'components/user/auth/login/LoginDialog';
import { parse } from 'query-string';
import LoginWithViettelSSO from 'components/user/auth/social-auth/ViettelSSO';
import checkStaff from 'common/hoc/checkStaff';
import { adminRootUrl, authUrl, systemRootUrl } from 'routes/root-url';
import apiUrls from 'api-endpoints';
import { Modal } from 'antd';
import { doesUserNeedToFillProfileNow } from 'components/profile/utils';
import {
  doesUserNeedToFillTemisNow,
  isEnableTemis,
  temisSystemText,
} from 'components/temis/utils';
import Store from 'store';
import { logout } from 'actions/auth';
import SystemAlert, { ALERT_STORAGE_KEY } from 'components/common/Alert';
import { PROFILE_GROUP_ROUTER } from 'components/temis/constant';
import ValidateLicense from './ValidateLicense';
// import ShowModalWarningToFinishMission from 'components/temis/components/modal-message/ShowModalWarningToFinishMission';
import SwitchProject from 'components/common/switch-project';
import { shouldShowSwitchProject } from 'components/common/switch-project/utils';
import ThemeProvider from 'layouts/theme';
import WarningUpdateProfile from 'components/temis/profile/WarningUpdateProfile';
import {
  mustEnableTwoFactorAuthentication,
  mustUserChangePassBeforeUsingSystem,
} from 'components/admin/user/utils';

const checkAdminRoute = (props) => {
  const pathname = get(props, 'location.pathname') || '';
  const isAdminRoute = pathname.indexOf(adminRootUrl) === 0;
  const isSystemRoute = pathname.indexOf(systemRootUrl) === 0;
  return {
    isAdminRoute,
    isSystemRoute,
  };
};

class Layouts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      breadcrumb: null,
    };
  }

  componentWillMount() {
    const { location, dispatch } = this.props;
    window.NProgress &&
      window.NProgress.configure({
        template:
          '<div class="bar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>',
      });

    dispatch(sagaActions.getInformationByDomain(location.hostname));

    if (Perm.isGuest()) {
      dispatch(menuActions.changeSelectedRole(null));
    }
  }

  loadFacebookSDKIfNeeded = (props) => {
    const { themeConfig, conf } = props;
    if (
      getFacebookAppIdFromConf(themeConfig, conf) &&
      !this.isFacebookSDKLoaded
    ) {
      this.loadFacebookSDK();
    }
  };

  componentDidMount() {
    this.handleOnChangeLayout();
    this.loadFacebookSDKIfNeeded(this.props);
  }

  componentDidUpdate() {
    this.loadFacebookSDKIfNeeded(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch } = this.props;
    if (
      nextProps &&
      nextProps.domain &&
      ((nextProps.newLanguage &&
        (nextProps.newLanguage !== nextProps.language ||
          this.props.newLanguage !== nextProps.newLanguage)) ||
        (nextProps.newIsTranslating &&
          nextProps.newIsTranslating !== nextProps.isTranslating) ||
        (nextProps.newVersionTranslations &&
          nextProps.newVersionTranslations !==
            nextProps.versionTranslations)) &&
      (this.props.newLanguage !== nextProps.newLanguage ||
        this.props.newVersionTranslations !==
          nextProps.newVersionTranslations ||
        this.props.newIsTranslating !== nextProps.newIsTranslating)
    ) {
      dispatch(
        sagaActions.getTranslations(
          nextProps.newLanguage,
          nextProps.newVersionTranslations,
          nextProps.newIsTranslating,
        ),
      );
    } else if (
      nextProps &&
      nextProps.isUpdating &&
      nextProps.isUpdating !== this.props.isUpdating
    ) {
      dispatch(actions.saveTranslations({ update: false }));
    }

    this.handleOnChangeLayout(nextProps);

    const { appInfo } = this.props;
    const pathname = get(nextProps, 'location.pathname');

    if (
      pathname !== '/' &&
      pathname !== userLinks.login &&
      appInfo.sessionEnded === null &&
      appInfo.sessionEnded !== nextProps.appInfo.sessionEnded
    ) {
      this.showSessionEndedPopup();
      return;
    }

    if (
      appInfo.version !== null &&
      appInfo.version !== nextProps.appInfo.version
    ) {
      this.showNewAppVersionPopup(nextProps.appInfo.version);
    }
  }

  showNewAppVersionPopup = () => {
    const version = this.props.appInfo.version;

    Modal.warning({
      title: 'Hệ thống có phiên bản mới',
      content: (
        <div>
          <p>
            Phiên bản mới {version} đã có. Vui lòng tải lại trang để tiếp tục!
          </p>

          <p>
            <BrowserRouter>
              <Link to="changelog" target={'_blank'}>
                Xem thay đổi
              </Link>
            </BrowserRouter>
          </p>
        </div>
      ),
    });
  };

  goBackToLogin = () => {
    Store.dispatch(logout());
  };

  showSessionEndedPopup = () => {
    Modal.warning({
      title: 'Phiên làm việc hết hạn',
      content: (
        <div>
          Phiên làm việc của bạn đã hết hạn, vui lòng đăng nhập lại để tiếp tục!
        </div>
      ),
      onOk: this.goBackToLogin,
    });
  };

  loadFacebookSDK = () => {
    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = '//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.10';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');

    this.isFacebookSDKLoaded = true;
  };

  handleOnChangeLayout = (nextProps) => {
    const { dispatch, location, route, themeConfig, conf, language } =
      nextProps || this.props;

    if (!location) {
      return;
    }
    const branch = matchRoutes(route.routes, location.pathname);
    if (!branch || branch.length === 0) {
      return;
    }

    this.setBreadcrumb(branch);

    // loading font if server defines one
    if (themeConfig.font) {
      const font = getFontByLanguage(themeConfig.font, language);

      if (font !== 'default') {
        initFont(font);
      }
    }

    const layoutId =
      (branch[0].route && branch[0].route.layout) ||
      (themeConfig && themeConfig.layout);
    if (layoutId) {
      if (!this.state.layoutId || layoutId !== this.state.layoutId) {
        const params = branch[0].route.params || {};
        this.setState({ layoutId, params });
      }
    }

    const roles = branch[0].route.roles;
    if (roles && roles.length > 0) {
      this.checkPermission(branch[0].route);
    }

    const { pathname } = location;
    if (conf && conf.omi_id) {
      if (shouldShowOmi(pathname, conf.show_omi)) {
        if (isOmiInitialized()) {
          showOmi();
        } else {
          initOmi(conf.omi_id);
        }
      } else {
        hideOmi();
      }
    }
    if (conf && conf.tawkto_id) {
      const { show_tawkto } = conf;
      if (shouldShowTawk(pathname, show_tawkto)) {
        if (isTawkInitialized()) {
          showTawk();
        } else {
          initTawk(conf.tawkto_id);
        }
      } else {
        hideTawk();
      }
    }
  };

  setBreadcrumb = (branch) => {
    const routes = branch || [];
    const { dispatch } = this.props;
    const { breadcrumb } = this.state;

    const schema = [];
    routes.map((route) => {
      const url = route.match && route.match.url;
      const title =
        route.route &&
        (route.route.title || route.route.name || t1(route.route.componentId));
      schema.push({
        url: url,
        title: title,
      });
    });
    if (schema.length === 0) {
      return;
    }

    const newBreadcrumb = JSON.stringify(schema);
    if (newBreadcrumb !== breadcrumb) {
      this.setState({ breadcrumb: newBreadcrumb });
      dispatch(
        layoutContextAction.setSubMenuTop({
          breadcrumbSchema: schema,
          fromLayout: true,
        }),
      );
    }
  };

  noPermissionAccordingToAdminMenuItems = () => {
    const {
      adminMenuItemsConfig,
      adminMenuItemsConfigLoadingStatus,
      location,
    } = this.props;

    if (adminMenuItemsConfigLoadingStatus !== loadingStatuses.FINISHED) {
      return false;
    }

    const menuItems = Object.keys(
      get(adminMenuItemsConfig, 'menuAvailable') || {},
    );

    const pathName = get(location, 'pathname');

    switch (pathName) {
      case '/admin/training-plan': {
        if (!menuItems.includes(adminMenuItems.TRAINING_PLAN)) {
          return true;
        }
        break;
      }
      case '/admin/enrolment-plan': {
        if (!menuItems.includes(adminMenuItems.ENROLMENT_PLAN)) {
          return true;
        }
        break;
      }
      case '/admin/school/accounts': {
        if (!menuItems.includes(adminMenuItems.ACCOUNT)) {
          return true;
        }
        break;
      }
      case '/admin/school/users': {
        if (!menuItems.includes(adminMenuItems.USER_MANAGE)) {
          return true;
        }
        break;
      }
      case '/admin/school/teachers': {
        if (!menuItems.includes(adminMenuItems.TEACHER)) {
          return true;
        }
        break;
      }
      case '/admin/credit': {
        if (!menuItems.includes(adminMenuItems.CREDIT)) {
          return true;
        }
        break;
      }
      case '/admin/course': {
        if (!menuItems.includes(adminMenuItems.COURSE)) {
          return true;
        }
        break;
      }
      case '/admin/tp-quota': {
        if (!menuItems.includes(adminMenuItems.TRAINING_PLAN_QUOTA)) {
          return true;
        }
        break;
      }
      case '/admin/license': {
        if (!menuItems.includes(adminMenuItems.LICENSE_LEARNING)) {
          return true;
        }
        break;
      }
      case '/admin/licensing': {
        if (!menuItems.includes(adminMenuItems.LICENSING_LEARNING)) {
          return true;
        }
        break;
      }
    }

    return false;
  };

  setPreviousPageBeforeLogin = () => {
    const pathname = get(this.props, 'location.pathname');
    const search = get(this.props, 'location.search');

    saveRefererLink(`${pathname}${search}`);
  };

  renderSystemMessage = (systemMessage) => {
    return (
      <SystemAlert
        customClass="system-message"
        message={get(systemMessage, 'content')}
        storageKey={ALERT_STORAGE_KEY.SYSTEM}
        messageKey={get(systemMessage, 'id')}
        type={get(systemMessage, 'type')}
        title={get(systemMessage, 'title')}
      />
    );
  };

  isPublicUrl = (pathName = '') => {
    const publicRoutes = [
      userLinks.login,
      userLinks.forgot_password,
      userLinks.register,
      userLinks.reset_password_forgotten,
      authUrl,
      ...(window.isETEP ? ['/faq'] : []),
      '/privacy-policy',
    ];

    return !!publicRoutes.find((route) => pathName.startsWith(route));
  };

  isForkToLogin = () => {
    const pathname = get(this.props, 'location.pathname', '');
    const { requiredLogin } = this.props;
    const isGuest = Perm.isGuest();
    return requiredLogin && isGuest && !this.isPublicUrl(pathname);
  };

  isForkToAdminProfile = () => {
    const { userInfo, location } = this.props;
    const pathname = get(location, 'pathname', '');
    const canSkipProfile = get(userInfo, 'can_skip_profile');
    return (
      canSkipProfile &&
      (pathname === userLinks.update_profile_info ||
        pathname === userLinks.profile)
    );
  };

  render() {
    const {
      route,
      themeConfig,
      newLanguage,
      siteTitle,
      configLoaded,
      userInfo,
      location,
      conf,
      isStaff,
      appInfo,
      versionTranslations,
    } = this.props;

    if (this.noPermissionAccordingToAdminMenuItems()) {
      return <Redirect to={'/admin'} />;
    }

    const searchParam = parse(get(location, 'search'));
    if (
      window.isETEP &&
      Perm.isGuest() &&
      ['/', ''].includes(get(location, 'pathname')) &&
      get(searchParam, 'code')
    ) {
      return (
        <LoginWithViettelSSO
          {...this.props}
          authCode={get(searchParam, 'code')}
        />
      );
    }

    let layoutId = this.state.layoutId;
    const params = this.state.params || {};

    if (!configLoaded || !layoutId) {
      return <Loading />;
    }

    if (
      [
        // layouts.SEABANK,
        // layouts.MSI,
        layouts.VT,
        // layouts.HPU2,
        // layouts.BLUE,
        // layouts.GJ,
      ].includes(layoutId)
    ) {
      layoutId = layouts.VT;
    }

    const { isAdminRoute, isSystemRoute } = checkAdminRoute(this.props);
    const changingPassword = [
      PROFILE_GROUP_ROUTER.change_password,
      userLinks.update_password,
    ].includes(location.pathname);
    const enableTwoFactorAuthentication = [
      userLinks.update_settings,
      PROFILE_GROUP_ROUTER.user_settings,
    ].includes(location.pathname);

    if (!isStaff && (isAdminRoute || isSystemRoute)) {
      return <Redirect to={'/'} />;
    }

    if (this.isForkToLogin()) {
      this.setPreviousPageBeforeLogin();
      return <Redirect to={userLinks.login} />;
    } else if (
      !changingPassword &&
      mustUserChangePassBeforeUsingSystem(userInfo)
    ) {
      return (
        <Redirect
          to={
            isEnableTemis()
              ? PROFILE_GROUP_ROUTER.change_password
              : userLinks.update_password
          }
        />
      );
    } else if (
      !changingPassword &&
      !enableTwoFactorAuthentication &&
      mustEnableTwoFactorAuthentication(userInfo)
    ) {
      return (
        <Redirect
          to={
            isEnableTemis()
              ? PROFILE_GROUP_ROUTER.user_settings
              : userLinks.update_settings
          }
        />
      );
    }

    if (
      this.isForkToAdminProfile() &&
      !changingPassword &&
      !enableTwoFactorAuthentication
    ) {
      const adminProfileLink = userLinks.adminProfile(userInfo.iid);
      return <Redirect to={adminProfileLink} />;
    }

    // hack DHSP1 prezi
    if (
      !changingPassword &&
      !enableTwoFactorAuthentication &&
      window.enableMindmap &&
      (window.location.pathname === '/dashboard' ||
        window.location.pathname === '/')
    ) {
      return <Redirect to={'/dashboard/my-enrolment-plans'} />;
    }

    if (isEnableTemis()) {
      const needTemisProfileInfo = doesUserNeedToFillTemisNow(conf, userInfo);
      if (
        needTemisProfileInfo &&
        location.pathname !== PROFILE_GROUP_ROUTER.update_info &&
        location.pathname !== '/faq' &&
        !changingPassword &&
        !enableTwoFactorAuthentication
      ) {
        return <Redirect to={PROFILE_GROUP_ROUTER.update_info} />;
      }
    } else {
      const needProfileInfo = doesUserNeedToFillProfileNow(conf, userInfo);
      if (
        needProfileInfo &&
        location.pathname !== userLinks.update_profile_info &&
        location.pathname !== '/faq' &&
        !changingPassword &&
        !enableTwoFactorAuthentication
      ) {
        return <Redirect to={userLinks.update_profile_info} />;
      }
    }

    const missingData = doesUserMissingData(userInfo);

    if (
      !changingPassword &&
      !enableTwoFactorAuthentication &&
      !isEnableTemis() &&
      missingData &&
      location.pathname !== enterMissingData() &&
      location.pathname !== userLinks.update_profile_info &&
      location.pathname !== '/faq'
    ) {
      return <Redirect to={enterMissingData()} />;
    }

    const CurrentLayoutConfig =
      LayoutRegister[layoutId] || LayoutRegister.lotus;
    if (!CurrentLayoutConfig) {
      return <Loading />;
    }
    let className = `${
      themeConfig.layout
    }-theme-wrapper ${layoutId}-layout-wrapper`;

    className = `${className} ${
      themeConfig.layout
    }-layout-wrapper ${newLanguage}-language-font`;

    let title = '';
    if (isEnableTemis()) {
      title = temisSystemText;
    } else {
      title = siteTitle
        ? `${siteTitle} - ${themeConfig.website_title}`
        : themeConfig.website_title;
    }

    // const font = themeConfig.font
    //   ? getFontByLanguage(themeConfig.font, newLanguage)
    //   : 'default';

    // temporary set the font
    const font = 'Google Sans';

    if (!versionTranslations) {
      return null;
    }

    return (
      <ThemeProvider>
        <div className={className} style={{ height: 'inherit' }}>
          <Helmet
            title={title}
            meta={[
              {
                name: 'description',
                content: themeConfig.website_description,
              },
            ]}
            link={[
              {
                type: 'image/x-icon',
                href: themeConfig.favicon,
                rel: 'icon',
              },
            ]}
          />
          <CurrentLayoutConfig.component {...this.props} {...params}>
            {appInfo && this.renderSystemMessage(appInfo.systemMessage)}
            <WarningUpdateProfile timeClose={10000} userInfo={userInfo} />
            <ValidateLicense>{renderRoutes(route.routes)}</ValidateLicense>
          </CurrentLayoutConfig.component>
          <style
            type={'text/css'}
            dangerouslySetInnerHTML={{
              __html: `
            .${themeConfig.layout}-layout-wrapper {font-family: ${font};}
            .${themeConfig.layout}-layout-wrapper h1,
            h1, .${themeConfig.layout}-layout-wrapper h2, .${
                themeConfig.layout
              }-layout-wrapper h3,
             .${themeConfig.layout}-layout-wrapper h4, .${
                themeConfig.layout
              }-layout-wrapper h5,
             .${themeConfig.layout}-layout-wrapper h6, .${
                themeConfig.layout
              }-layout-wrapper .h1,
             .${themeConfig.layout}-layout-wrapper .h2, .${
                themeConfig.layout
              }-layout-wrapper .h3,
             .${themeConfig.layout}-layout-wrapper .h4, .${
                themeConfig.layout
              }-layout-wrapper .h5,
             .${themeConfig.layout}-layout-wrapper .h6 {
              font-family: ${font};
              margin-bottom: 24px;
              margin-top: 0;
              padding: 0;
              letter-spacing: 0;
              line-height: initial
          }`,
            }}
          />

          <SupportedBrowsers />
          <LoginDialog />
          <ModalDialogs />
          <SnackbarSimple />
          <LoginAsAlert />
          <SupportUserInfoNotification />
          {/*<ShowModalWarningToFinishMission />*/}
          {/*<MediaPopup />*/}
          {shouldShowSwitchProject() && (
            <SwitchProject showConfigOneTime modal />
          )}
          <TetHolidaySupport />
          {process.env.NODE_ENV !== 'development' && <GoogleAnalytics />}
        </div>
      </ThemeProvider>
    );
  }
}

const fetchRolesMenus = fetchData((props) => {
  const url = apiUrls.menu_configs('admin');
  const { isAdminRoute, isSystemRoute } = checkAdminRoute(props);

  return {
    baseUrl: url,
    propKey: 'adminMenuItemsConfig',
    loadingStatusPropKey: 'adminMenuItemsConfigLoadingStatus',
    fetchCondition: isAdminRoute || isSystemRoute,
    refetchCondition: (prevProps) => {
      const {
        isAdminRoute: isAdminRoutePrev,
        isSystemRoute: isSystemRoutePrev,
      } = checkAdminRoute(prevProps);

      return (
        isAdminRoutePrev !== isAdminRoute || isSystemRoutePrev !== isSystemRoute
      );
    },
  };
});

export default connect(layoutSelector)(checkStaff()(fetchRolesMenus(Layouts)));
