import React from 'react';
import lodashGet from 'lodash.get';
import { findInTree, mapTree } from 'common/utils/object';
import { isLeafOrg } from 'configs/constants/org-sub-types';
import { arraysConsistOfTheSameValues, groupByKey } from 'common/utils/Array';

export const BGD_ORG_IID = 100587;
export const HVQL_IID = 100597;
export const VGD_IID = 100587;
export const SGD_HCM_ORG_IID = 176958;

export const dhspIds = [
  '5cb6d5eb3b77503c9f0f03b9', // HN
  '5cb6d6163b77503c9f0f03bc', // HN2
  '5cb6d5df3b77503c9f0f03b8', // Thai Nguyen
  '5cb6ce4e3b77503c9f0f03b2', // Vinh
  '5d43a1113145fa545961ba27', // Hue
  '5d402b0a9dc6d6282d22e342', // Da Nang
  '5cb6d5f73b77503c9f0f03ba', // HCM
];

export const dhspIids = [
  100591, // HN
  100594, // HN2
  100590, // Thai Nguyen
  100585, // Vinh
  146401, // Hue
  144879, // Da nang
  100592, // HCM
];

export const isBGD = (orgIid) => orgIid == BGD_ORG_IID;

export const isVGD = (orgIid) => orgIid == BGD_ORG_IID || orgIid == VGD_IID;

export const isHVQLGD = (orgIid) => orgIid == HVQL_IID;

export const getTreeSelectNodeValueFromOrganization = (org) =>
  lodashGet(org, 'iid');
export const getTreeSelectNodeTitleFromOrganization = (org) =>
  lodashGet(org, 'name');

const reachToLevelDeptLevel = (deptLevel, treeData = []) => {
  if (typeof deptLevel !== 'number') {
    return false;
  }

  const availableMaxLevel = Math.max.apply(
    Math,
    treeData.map(function(data) {
      return data.level;
    }),
  );

  return availableMaxLevel >= deptLevel;
};

export const shouldDisabledOrgOption = (
  org,
  disabledBySubType,
  disabledOptions,
) => {
  const orgIid = lodashGet(org, 'iid');
  const subType = lodashGet(org, 'sub_type');

  return (
    (disabledBySubType || []).includes(subType) ||
    (disabledOptions || []).includes(orgIid)
  );
};

export const addValueToOrganizationTreeDataForTreeSelect = (
  organizationTreeData,
  disabledBySubType = [],
  disabledOptions = [],
  depthOfOrganizationTreeToShow,
  orgTypesReached = [],
) => {
  if (
    !Array.isArray(organizationTreeData) ||
    organizationTreeData.length === 0
  ) {
    return organizationTreeData;
  }

  const isReachToDeptLevel = reachToLevelDeptLevel(
    depthOfOrganizationTreeToShow,
    organizationTreeData,
  );

  return organizationTreeData.map((tree) =>
    mapTree(tree, (org) => ({
      ...org,
      key: getTreeSelectNodeValueFromOrganization(org),
      id: getTreeSelectNodeValueFromOrganization(org),
      title: getTreeSelectNodeTitleFromOrganization(org),
      value: getTreeSelectNodeValueFromOrganization(org),
      isLeaf: isLeafOrg(org.sub_type, orgTypesReached) || isReachToDeptLevel,
      disabled: shouldDisabledOrgOption(
        org,
        disabledBySubType,
        disabledOptions,
      ),
    })),
  );
};

/**
 * if we have 2 organization types OT1 and OT2
 * and the original tree data look like this:
 *  - A type OT1
 *    |- B type OT1
 *       | - C type OT2
 *    |- D type OT2
 *    |- E type OT1
 *
 *  it will be hard for user to know which is element is OT1 and which is OT2
 *
 *  We will add some fake nodes in the tree so user can navigate easier (please look at the ui if this ascii art is too hard to understand)
 *  - OT1
 *    | -- A
 *          | - OT1
 *          |   | -- B
 *          |   |    | - OT1 (no data)
 *          |   |    |
 *          |   |    | - OT2
 *          |   |        | -- C
 *          |   |
 *          |   | -- E
 *          |
 *          | - OT2
 *              | -- D
 *
 *  - OT2 (no data)
 *
 * @param organizationTreeData
 * @param orgTypes
 * @param parentNodeOfOrganizationTreeData
 *    because this is recursive function,
 *    when working with the lower part of the tree, we need to know that part belong to what node
 * @return {*}
 */
export const groupOrganizationTreeDataByOrgType = (
  organizationTreeData,
  orgTypes,
  parentNodeOfOrganizationTreeData = null,
  modeRender,
) => {
  orgTypes = (orgTypes || []).filter(Boolean); // just to make sure there are not abnormal elements in orgTypes array

  if (
    // if there are not tree data, we have nothing to filter
    !Array.isArray(organizationTreeData) ||
    organizationTreeData.length === 0 ||
    // if there are only one org type, we don't have to group tree data together
    orgTypes.length <= 1 ||
    modeRender === 'cascader'
  ) {
    return organizationTreeData;
  }

  const treeDataBySubType = groupByKey(organizationTreeData, 'sub_type');

  const getTreeDataPartMatchingOrgType = (orgType) => {
    const { value: orgTypeValue } = orgType || {};
    return lodashGet(treeDataBySubType, orgTypeValue);
  };

  const orgTypesThatHasTreeData = orgTypes.filter((type) => {
    return getTreeDataPartMatchingOrgType(type);
  });

  // for each org type there will be a new node in the tree that represent that org type
  // their children will be organizations which have the org type
  let newTreeDataWithNodeToGroupByOrgType = orgTypesThatHasTreeData.map(
    (type) => {
      const treeDataPartMatchingOrgType = getTreeDataPartMatchingOrgType(type);

      const { name: orgTypeName, value: orgTypeValue } = type;
      const groupValue = `${lodashGet(
        parentNodeOfOrganizationTreeData,
        'value',
        '',
      )}_${orgTypeValue}`;

      return {
        title: <span style={{ fontSize: 12 }}>{orgTypeName}</span>,
        children: treeDataPartMatchingOrgType,
        disabled: true,
        value: groupValue, // we just need some unique value because this node is disabled anyway
        id: groupValue,
        pId: lodashGet(parentNodeOfOrganizationTreeData, 'id', 0),
      };
    },
  );

  // if at the top level, there is only 1 org type, remove the node present that type because it is unnecessary
  if (newTreeDataWithNodeToGroupByOrgType.length === 1) {
    const children = lodashGet(newTreeDataWithNodeToGroupByOrgType, [
      0,
      'children',
    ]);

    return children.map((item) => ({
      ...item,
      pId: lodashGet(parentNodeOfOrganizationTreeData, 'id', 0),
    }));
  }

  let flattenTreeData = [];
  newTreeDataWithNodeToGroupByOrgType.forEach((item) => {
    const children = lodashGet(item, 'children', []);

    delete item.children;

    const childrenWithParent = children.map((data) => ({
      ...data,
      pId: lodashGet(item, 'id'),
    }));

    if (modeRender === 'tree') {
      flattenTreeData.push({ ...item, children: childrenWithParent });
    } else {
      flattenTreeData = flattenTreeData.concat([item], childrenWithParent);
    }
  });

  return flattenTreeData;
};
