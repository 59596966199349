import React, { useCallback, useEffect, useState } from 'react';
import lodashGet from 'lodash.get';
import { compose } from 'redux';

import withServerTs from 'common/hoc/withServerTs';
import withUserInfo from 'common/hoc/withUserInfo';
import FullScreen from 'components/common/full-screen';
import {
  getScormInteractionsMaxScore,
  getScormInteractionsQuestions,
  getScormInteractionsScore,
  getScormProgress,
  getScormSpentTime,
  getTimeFinishExercise,
  isScormInteractionsPassed,
  isDisableScormTrackingProgress,
} from './utils';
import useScormPlayerLinks from './useScormPlayerLink';
import ChooseScormPlayerLink from './ChooseScormPlayerLink';

const NUMBER_CAN_FINISH_PROGRESS = 0.9; // unit %

const IFRAME_SCORM_PLAYER = 'scormPlayer';

const ScormPlayer = ({
  courseIid,
  learnItemIid,
  scormDirectoryUrl,
  scormEntryFile,
  learningProgress,
  saveLearningProgress,
  isPreview,
  isReview,
  className,
  width,
  height,
  userInfo,
  serverTs,
}) => {
  const [cmi, setCmi] = useState({});
  const [scormPlayerLink, setScormPlayerLink] = useState();

  const canFinishProgress = useCallback(
    (progress) => progress >= NUMBER_CAN_FINISH_PROGRESS * 100,
    [],
  );

  const isFinishLearning = useCallback(
    () => {
      return Number(learningProgress) >= NUMBER_CAN_FINISH_PROGRESS * 100;
    },
    [learningProgress],
  );

  const getMaxProgress = useCallback(
    (progress) => {
      return Math.max(progress, learningProgress);
    },
    [learningProgress],
  );

  const saveScormProgress = useCallback(
    (progress, spentTime = 0, interaction = null) => {
      if (typeof saveLearningProgress !== 'function' || isReview || isPreview) {
        return;
      }

      let data = null;
      if (!isFinishLearning()) {
        data = [
          {
            tco_iid: learnItemIid,
            p: progress,
            time_spent: spentTime,
            cp: progress,
            pf: canFinishProgress(progress) ? 1 : 0,
            extra_data: {
              type: 'scorm',
              interaction,
            },
          },
        ];
      } else if (interaction && !lodashGet(interaction, 'pf')) {
        data = {
          tco_iid: learnItemIid,
          extra_data: {
            type: 'scorm',
            interaction,
          },
        };
      }

      if (data) {
        saveLearningProgress(data);
      }
    },
    [
      isReview,
      isPreview,
      isFinishLearning,
      learnItemIid,
      saveLearningProgress,
      canFinishProgress,
    ],
  );

  const getIframeScormPlayerId = useCallback(
    () => {
      const id = `${IFRAME_SCORM_PLAYER}-${learnItemIid}`;
      const userIid = lodashGet(userInfo, 'iid') || '';

      if (userIid) {
        return `${id}-${userIid}`;
      }

      return id;
    },
    [learnItemIid, userInfo],
  );

  const calculateScormProgress = useCallback(
    (cmi) => {
      let progress = getScormProgress(cmi);

      if (progress >= NUMBER_CAN_FINISH_PROGRESS) {
        progress = 1; // is 100%
      }

      const rounding = Math.round(progress * 100);

      return getMaxProgress(rounding);
    },
    [getMaxProgress],
  );

  const getInteractionsData = useCallback(
    (cmi) => {
      const interactions = getScormInteractionsQuestions(cmi, serverTs);

      if (!interactions || !interactions.length) {
        return;
      }

      return {
        max_score: getScormInteractionsMaxScore(cmi),
        score: getScormInteractionsScore(cmi),
        pf: isScormInteractionsPassed(cmi),
        questions: getScormInteractionsQuestions(cmi, serverTs),
        exercise_finished_ts: getTimeFinishExercise(cmi, serverTs),
      };
    },
    [serverTs],
  );

  const handleSaveTrackingProgress = useCallback(
    (cmi) => {
      const progress = calculateScormProgress(cmi);
      const spentTime = getScormSpentTime(cmi, serverTs);
      const interactions = getInteractionsData(cmi);
      if (!progress && !spentTime && !interactions) {
        return;
      }

      saveScormProgress(progress, spentTime, interactions);
    },
    [getInteractionsData, saveScormProgress, calculateScormProgress, serverTs],
  );

  const getEventSetDataKey = useCallback(
    () => {
      const scormIframeId = getIframeScormPlayerId();

      return `scorm:${scormIframeId}`;
    },
    [getIframeScormPlayerId],
  );

  const messageEventHandler = useCallback(
    (e) => {
      const eventValue = getEventSetDataKey();
      const eventKey = lodashGet(e, 'data.eventKey');
      if (eventKey === eventValue) {
        const cmi = lodashGet(e, 'data.cmi') || {};

        setCmi(cmi);

        if (isDisableScormTrackingProgress(courseIid, learnItemIid)) {
          return;
        }

        handleSaveTrackingProgress(cmi);
      }
    },
    [handleSaveTrackingProgress, getEventSetDataKey, learnItemIid, courseIid],
  );

  useEffect(
    () => {
      window.addEventListener('message', messageEventHandler, false);

      return () => {
        window.removeEventListener('message', messageEventHandler, false);
      };
    },
    [messageEventHandler],
  );

  useEffect(
    () => {
      if (isFinishLearning()) {
        return;
      }

      if (isDisableScormTrackingProgress(courseIid, learnItemIid)) {
        saveScormProgress(50, 0);
      }

      return () => {
        if (isFinishLearning()) {
          return;
        }

        if (isDisableScormTrackingProgress(courseIid, learnItemIid)) {
          saveScormProgress(100, 0);
        }
      };
    },
    [saveScormProgress, learnItemIid, courseIid, isFinishLearning],
  );

  useEffect(
    () => {
      const iframeId = getIframeScormPlayerId();
      const iframe = document.getElementById(iframeId);

      return () => {
        try {
          const sessionStorageClearFunc = lodashGet(
            iframe,
            'contentWindow.sessionStorage.clear',
          );

          if (typeof sessionStorageClearFunc === 'function') {
            console.log('cleared scorm session storage');
            sessionStorageClearFunc();
          }
        } catch (exception) {
          console.log('cannot clear scorm session storage');
        }
      };
    },
    [getIframeScormPlayerId],
  );

  const scormId = getIframeScormPlayerId();

  const scormPlayerLinks = useScormPlayerLinks(
    scormDirectoryUrl,
    scormEntryFile,
    getEventSetDataKey(),
    getIframeScormPlayerId(),
  );

  const actionElement = useCallback(
    () => {
      return (
        <ChooseScormPlayerLink
          setScormPlayerLink={setScormPlayerLink}
          scormPlayerLinks={scormPlayerLinks}
        />
      );
    },
    [scormPlayerLinks, setScormPlayerLink],
  );

  const renderContent = useCallback(
    () => {
      return (
        <>
          {actionElement()}
          {!!scormPlayerLink && (
            <iframe
              id={scormId}
              className={`${className || ''} border`}
              style={{
                background: 'white',
                lineHeight: 1,
              }}
              src={scormPlayerLink}
              frameBorder="0"
              width="100%"
              height="100%"
            />
          )}
        </>
      );
    },
    [className, scormId, scormPlayerLink, actionElement],
  );

  return (
    <FullScreen
      style={{
        width: width || '100%',
        height: height || 708,
      }}
      renderContent={renderContent}
    />
  );
};

export default compose(
  withUserInfo,
  withServerTs,
)(ScormPlayer);
