import PropTypes from 'prop-types';
import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';

import './stylesheet.scss';

const Image = ({ src, alt = '', size, style = {} }) => {
  if (!src) {
    return <span>no image</span>;
  }

  const renderPreview = ({ showFull }) => {
    return (
      <div className="text-center">
        <div
          onClick={showFull}
          style={{
            maxWidth: '600px',
            margin: '0 auto',
            ...(style || {}),
          }}
        >
          <img
            src={src}
            alt={alt}
            style={{
              borderRadius: '3%',
              ...(style || {}),
            }}
          />
        </div>
      </div>
    );
  };
  const renderFull = ({ closeDialog }) => {
    return (
      <div
        className="component-image__container"
        onClick={() => {
          closeDialog();
        }}
      >
        <img src={src} alt={alt} />
      </div>
    );
  };

  return (
    <DetailOnDialog
      renderPreview={renderPreview}
      renderFull={renderFull}
      dialogOptionsProperties={{
        modal: true,
        width: '100%',
        handleClose: true,
        className: 'modal-full-height component-image',
      }}
    />
  );
};

export default Image;

Image.propTypes = {
  alt: PropTypes.string,
  size: PropTypes.any,
  src: PropTypes.any.isRequired,
  style: PropTypes.object,
};

Image.defaultProps = {
  alt: '',
  style: {},
};
