import { call, put, select, takeEvery, take, race } from 'redux-saga/effects';
import {
  RETRY_SAVE_ANSWERS,
  RETRY_SAVE_ANSWERS_STOP,
  saveItemInfoToStore,
} from 'actions/learn';
import { getLearnItemInfoSelector } from 'common/learn';
import { saveProgressAndTake } from './exerciseFlow';
import { delay } from 'redux-saga';
import { steps } from 'common/learn/exercise';

const maxRetryCount = 100;
const initialDelay = 3000;
const maxDelay = 6000;

function* waitRetrySuccess(itemIid) {
  return yield take(
    (action) =>
      String(action.itemIid) === String(itemIid) &&
      action.type === 'SAVE_TAKE_REQUEST_SUCCESS',
  );
}

function* waitRetryFailed(itemIid) {
  return yield take(
    (action) =>
      String(action.itemIid) === String(itemIid) &&
      action.type === 'SAVE_TAKE_REQUEST_FAIL',
  );
}

function* retrySavingAnswers(action) {
  const { itemIid, isImmediate } = action;
  let selectInfo = yield select(getLearnItemInfoSelector);
  let info = yield call(selectInfo, itemIid);
  const { retryCount = 0, retryDelay = initialDelay } = info;

  // update to store to start retrying UI
  yield put(
    saveItemInfoToStore(itemIid, {
      retrying: true,
      retryDelay: isImmediate ? 0 : retryDelay,
      retryCount: isImmediate ? 0 : retryCount,
      retrySuccess: false,
    }),
  );

  // if user clicks Retry Now button, then no waiting
  if (!isImmediate) {
    yield delay(retryDelay);
  }

  // during the delay, if user clicks Retry Now then stop previous retry
  selectInfo = yield select(getLearnItemInfoSelector);
  info = yield call(selectInfo, itemIid);
  if (!info.retrying || info.step !== steps.MAIN) {
    yield call(stopRetry, {
      itemIid,
    });
    return;
  }

  // start saving answers
  const isFinished = false;
  yield call(saveProgressAndTake, action.itemIid, isFinished, false, true, []);

  // wait until saving success or failed
  const { retrySuccess } = yield race({
    retrySuccess: call(waitRetrySuccess, itemIid),
    retryFailed: call(waitRetryFailed, itemIid),
  });

  // stop retrying if retryCount reach maximum or retrying successful or user clicks Retry Now
  if (retryCount >= maxRetryCount - 1 || retrySuccess || isImmediate) {
    yield call(stopRetry, {
      itemIid,
    });
    return;
  }

  // if user clicks Retry Now button, stop the flow despite of successful or failed retry
  if (action.isImmediate) {
    return;
  }

  // if not successful, increase the retry delay and start a new loop
  yield put(
    saveItemInfoToStore(itemIid, {
      retryDelay: Math.min(retryDelay + 1000, maxDelay),
      retryCount: retryCount + 1,
      retrying: true,
    }),
  );

  yield call(retrySavingAnswers, action);
}

function* stopRetry(action) {
  const { itemIid } = action;

  yield put(
    saveItemInfoToStore(itemIid, {
      retrying: false,
      retryDelay: undefined,
      retryCount: 0,
    }),
  );
}

export default function* retryFlowSaga() {
  yield takeEvery(RETRY_SAVE_ANSWERS, retrySavingAnswers);
  yield takeEvery(RETRY_SAVE_ANSWERS_STOP, stopRetry);
}
