/**
 * Created by hungvo on 19/04/2017.
 */
import React from 'react';
import { connect } from 'react-redux';
import AntdModal from 'antd/lib/modal';
import actions from 'actions/node/creators';
import CloseButton from './CloseButton';
import './stylesheet.scss';
import usePrevious from 'components/common/hook/usePrevious';
import withFeatureFlags from 'feature-flag/withFeatureFlags';
import { getThemeConfig } from 'utils/selectors';
import { withRouter } from 'react-router';

const ModalDialog = ({
  dispatch,
  dialogKey,
  handleCloseDialog,
  optionsProperties,
  openDialog,
  children,
  contentDialog,
  zIndex,
  modal: isModalAccordingToProps,
  themeConfig,
  backUrl,
  history,
}) => {
  let {
    clearHashOnClose,
    callbacks,
    actions: dialogActions,
    handleClose: showCloseButton,
    title,
    modal: isModalAccordingToOptionsProperties,
    className,
    closeButtonType,
    width,
    style,
    bodyStyle,
    keyboard,
  } = optionsProperties || {};

  if (typeof showCloseButton === 'undefined') {
    showCloseButton = true;
  }

  let { onCloseDialog: onCloseDialogCallback } = callbacks || {};
  const isModal =
    isModalAccordingToProps || isModalAccordingToOptionsProperties;

  const previousOnCloseDialogCallback = usePrevious(onCloseDialogCallback);

  contentDialog = children || contentDialog;

  const previousOpenDialog = usePrevious(openDialog);

  const triggerOpenDialog = React.useCallback(
    () => {
      dispatch(actions.handleOpenDialog({ openDialog: true }, dialogKey));
    },
    [dispatch, dialogKey],
  );

  const handleRequestClose = React.useCallback(
    () => {
      if (handleCloseDialog) {
        return handleCloseDialog();
      }
      dispatch(actions.handleOpenDialog({ openDialog: false }, dialogKey));
      if (backUrl) {
        history.replace(backUrl);
      }
      if (clearHashOnClose) {
        // always remove the # part from dialog
        const url = window.location.pathname;
        window.history.pushState(null, null, url);
      }
    },
    [
      handleCloseDialog,
      dispatch,
      dialogKey,
      backUrl,
      clearHashOnClose,
      history,
    ],
  );

  React.useEffect(
    () => {
      if (typeof openDialog !== 'boolean' && contentDialog) {
        triggerOpenDialog();
      }
    },
    [openDialog, contentDialog, triggerOpenDialog],
  );

  React.useEffect(
    () => {
      if (!previousOpenDialog && contentDialog) {
        triggerOpenDialog();
      } else if (
        // you need to use the previous version
        // because when dialog is closed, optionsProperties object will be cleaned
        typeof previousOnCloseDialogCallback === 'function' &&
        previousOpenDialog &&
        !openDialog
      ) {
        previousOnCloseDialogCallback();
      }
    },
    [
      previousOpenDialog,
      openDialog,
      contentDialog,
      triggerOpenDialog,
      previousOnCloseDialogCallback,
    ],
  );

  if (!contentDialog) {
    openDialog = false;
  }

  if (!openDialog) {
    return null;
  }

  const footer = typeof dialogActions !== 'undefined' ? dialogActions : null;
  const { layout = '' } = themeConfig;
  const { color_palette = '' } = themeConfig;
  const themeClass = `theme-${layout}-${color_palette}`;

  return (
    <AntdModal
      style={style}
      bodyStyle={bodyStyle}
      visible={!!openDialog}
      title={title}
      onCancel={handleRequestClose}
      closable={false} // we already have CloseButton component
      footer={footer}
      width={width}
      className={`
          modal_dialog_ant
          ${
            typeof width === 'undefined'
              ? 'modal_dialog_ant--default-width'
              : ''
          }
          light-background-dialog no-header-dialog
          ${className || ''} ${themeClass}
        `}
      maskClosable={!isModal}
      keyboard={keyboard}
      //================================
      // autoDetectWindowHeight={autoDetectWindowHeight}
      // repositionOnUpdate={repositionOnUpdate}
      // autoScrollBodyContent={autoScrollBodyContent}
      // bodyClassName={bodyClassName}
      // bodyStyle={bodyStyle}
      // contentStyle={contentStyle}
      // overlayStyle={overlayStyle}
      // paperProps={paperProps}
      // style={style}
    >
      {showCloseButton ? (
        <CloseButton
          closeButtonType={closeButtonType}
          onClick={handleRequestClose}
        />
      ) : null}
      {contentDialog}
    </AntdModal>
  );
};

const mapStateToProps = (state) => {
  return {
    themeConfig: getThemeConfig(state),
  };
};

export default withFeatureFlags()(
  connect(mapStateToProps)(withRouter(ModalDialog)),
);
