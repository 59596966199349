import {
  INIT_EXAM_PRACTICE,
  START_EXAM_PRACTICE,
  FINISH_EXAM_PRACTICE,
  SAVE_ANSWER_EXAM_PRACTICE,
  LOAD_EXAM_PRACTICE_TAKES_FROM_LOCAL_STORAGE,
  CHANGE_QUESTION_EXAM_PRACTICE,
  UPDATE_EXAM_PRACTICE_TICK,
  SHOW_ANSWER_AND_LOCK_QUESTION,
  REMOVE_TAKE_EXAM_PRACTICE,
  REVIEW_TAKE_QUESTION,
  REVIEW_ALL_TAKE,
  STOP_REVIEW_TAKE,
  VIEW_HISTORY,
} from 'actions/learn/exercise/exam-practice/saga-creators';
import { markTakeWithPaper } from 'components/contest/practice/utils';
import lGet from 'lodash.get';
import {
  saveTakeAndPaperToLocalStorage,
  getAllTakeAndPaperFromLocalStorage,
} from 'common/utils/Data';

const initialState = {
  practiceContest: {
    isLoading: false,
    error: null,
    result: {},
  },
};

const practiceContest = (state = initialState, action) => {
  switch (action.type) {
    case INIT_EXAM_PRACTICE: {
      return {
        ...state,
        paper: action.paper,
        rules: action.examRule,
      };
    }
    case START_EXAM_PRACTICE: {
      return {
        ...state,
        take: action.takeObject,
      };
    }
    case FINISH_EXAM_PRACTICE: {
      saveTakeAndPaperToLocalStorage(
        Object.assign({}, state.take, { status: 'FINISHED' }),
        state.paper,
        action.contestantIid,
        action.contestCode,
        action.isPublic,
        action.syllabusIid,
      );
      return {
        ...state,
        take: Object.assign({}, state.take, { status: 'FINISHED' }),
      };
    }
    case SAVE_ANSWER_EXAM_PRACTICE: {
      let answersObject = state.take.answer;
      let answerLogArray = state.take.answerLog;
      let currentQuestionId = 0;
      answersObject[action.answerObject.iid] = action.answerObject;
      answerLogArray.push(action.answerObject);
      currentQuestionId =
        lGet(action, 'answerObject.answer', []).length > 0 ||
        state.take.currentQuestionId == 0
          ? action.answerObject.iid
          : state.take.currentQuestionId;
      return {
        ...state,
        take: Object.assign(
          {},
          state.take,
          { answer: answersObject },
          { answerLog: answerLogArray },
          { currentQuestionId: currentQuestionId },
        ),
      };
    }
    case CHANGE_QUESTION_EXAM_PRACTICE: {
      return {
        ...state,
        take: Object.assign({}, state.take, {
          currentQuestionId: action.questionId,
        }),
      };
    }
    case UPDATE_EXAM_PRACTICE_TICK: {
      if (state.take != undefined) {
        var currentTick = lGet(state, 'take.tickPassed', 0) + action.tickAdded;
        return {
          ...state,
          take: Object.assign({}, state.take, { tickPassed: currentTick }),
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case SHOW_ANSWER_AND_LOCK_QUESTION: {
      var currentQuestionLock = state.take.questionLock;
      currentQuestionLock.push(action.questionId);
      return {
        ...state,
        take: Object.assign({}, state.take, {
          questionLock: currentQuestionLock,
        }),
      };
    }
    case REMOVE_TAKE_EXAM_PRACTICE: {
      return {
        ...state,
        take: undefined,
      };
    }
    case LOAD_EXAM_PRACTICE_TAKES_FROM_LOCAL_STORAGE: {
      var historyFromCache = getAllTakeAndPaperFromLocalStorage(
        action.contestantIid,
        action.contestCode,
        action.isPublic,
        action.syllabusIid,
      );
      return {
        ...state,
        history: historyFromCache,
      };
    }
    case REVIEW_TAKE_QUESTION: {
      return {
        ...state,
        take: Object.assign({}, state.take, {
          questionReview: action.questionId,
        }),
      };
    }
    case REVIEW_ALL_TAKE: {
      return {
        ...state,
        take: Object.assign({}, state.take, {
          mode: 'review',
        }),
      };
    }
    case STOP_REVIEW_TAKE: {
      return {
        ...state,
        take: Object.assign({}, state.take, {
          mode: undefined,
        }),
      };
    }
    case VIEW_HISTORY: {
      return {
        ...state,
        paper: lGet(state, 'history.' + action.historyKey + '.paper'),
        take: lGet(state, 'history.' + action.historyKey + '.take'),
      };
    }
    default: {
      return state;
    }
  }
};

export default practiceContest;
