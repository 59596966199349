import React from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import ImportResult from './Results';
import schema from './schema';

const SearchImport = ({
  type,
  importId,
  searchImportApiUrl,
  importApiUrl,
  searchImportColumns,
  otherImportParams,
  onImportSuccessful,
  searchImportedItemsFormId,
}) => {
  const searchFormId = `import-search-${type}`;

  const renderResultsComponent = (items, importId) => (
    <ImportResult
      items={items}
      columns={searchImportColumns}
      importId={importId}
      searchFormId={searchFormId}
      otherImportParams={otherImportParams}
      importApiUrl={importApiUrl}
      onImportSuccessful={onImportSuccessful}
      searchImportedItemsFormId={searchImportedItemsFormId}
    />
  );

  return (
    <SearchWrapper
      formid={searchFormId}
      schema={schema}
      hiddenFields={{ importId }}
      renderResultsComponent={(items) =>
        renderResultsComponent(items, importId)
      }
      renderNoResultComponent={() => <SimpleNoResult />}
      alternativeApi={searchImportApiUrl}
    />
  );
};

export default SearchImport;
