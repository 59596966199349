import React from 'react';
import Seach from './Layout';

const LEARNING = ({ action }) => {
  const reportTitle = 'Tổng hợp kết quả học tập theo chương trình BDTX';

  return (
    <>
      <h1>{reportTitle}</h1>
      <Seach />
    </>
  );
};

export default LEARNING;
