import React from 'react';
import { connect } from 'react-redux';
import ScoSpl from 'components/learn/items/sco-spl';
import actions from 'actions/node/creators';
import Loading from 'components/common/loading';

class AttachedSco extends React.Component {
  componentDidMount() {
    const { lecture, course, dispatch } = this.props;
    const scoIid = lecture.sco_iid;

    dispatch(
      actions.fetchNode({
        iid: scoIid,
        ntype: 'sco',
        depth: -1,
        syllabus: course.syllabus,
        course: course.iid,
      }),
    );
  }

  render() {
    const { lecture, course, nodes, trackerProgress, dispatch } = this.props;
    const scoIid = lecture.sco_iid;
    const sco = nodes[scoIid];

    if (sco) {
      return (
        <ScoSpl
          scoIid={scoIid}
          nodes={nodes}
          course={course}
          trackerProgress={trackerProgress}
          dispatch={dispatch}
        />
      );
    }

    return <Loading circularLoadingIcon />;
  }
}

const mapStateToProps = (state) => {
  return {
    nodes: state.tree,
    trackerProgress: state.trackerProgress,
  };
};

export default connect(mapStateToProps)(AttachedSco);
