import React from 'react';
import Tooltip from 'antd/lib/tooltip';

const ScoreNotice = ({ title }) => {
  return (
    <Tooltip title={title} placement="bottom">
      <div className="m-r-4 m-l-4 ti ti-alert score-notice text-secondary" />
    </Tooltip>
  );
};

export default ScoreNotice;
