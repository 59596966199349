import React, { useState } from 'react';
import { t1 } from 'translate';
import CommonAntdTable from 'components/common/antd/table';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import NewModuleButton from 'components/bdtx/module/new/ButtonNew';

const Results = ({
  items,
  onSelectModules,
  targetUser,
  cap,
  closeDialog,
  searchFormId,
}) => {
  const [choosenModules, setChoosenModules] = useState([]);

  const chooseModule = () => {
    if (typeof onSelectModules === 'function') {
      onSelectModules(cap, choosenModules);
    }

    if (typeof closeDialog === 'function') {
      closeDialog();
    }
  };

  const columns = [
    {
      title: 'Mã mô đun',
      key: 'code',
      dataIndex: 'code',
      render: (code, item) => {
        return code;
      },
    },
    {
      title: 'Tên mô đun',
      key: 'name',
      dataIndex: 'name',
      render: (name, item) => {
        return name;
      },
    },
    {
      title: 'Số tiết',
      key: 'credit',
      dataIndex: 'credit',
      type: TYPE_OF_TABLE_DATA.TITLE,
      render: (credit, item) => {
        return credit;
      },
      width: 60,
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between m-b-15">
        <button
          className="btn btn-primary m-t-5 m-b-5"
          disabled={!choosenModules.length}
          onClick={chooseModule}
        >
          {choosenModules.length
            ? t1('select_%s_modules', [choosenModules.length])
            : t1('please_select_modules')}
        </button>

        <NewModuleButton
          onlyForCap={cap}
          defaultValues={{ target_users: [targetUser] }}
          searchFormId={searchFormId}
        />
      </div>

      <CommonAntdTable
        dataSource={items}
        columns={columns}
        pagination={false}
        childrenColumnName={null}
        className="learn-default"
        rowKey="iid"
        rowSelection={{
          selectedRowKeys: choosenModules,
          onChange: setChoosenModules,
          hideDefaultSelections: true,
        }}
      />
    </>
  );
};

export default Results;
