import get from 'lodash.get';
import throttle from 'lodash.throttle';
import React, { useCallback, useState } from 'react';
import { useDrag } from 'react-dnd';

const OFFSET = 60;
const PX_DIFF = 5;

let scrollIncrement = 0;
let isScrolling = false;
let sidebarElement = null;

/**
 * Scroll up in the sidebar.
 */
const goUp = () => {
  scrollIncrement -= PX_DIFF;
  sidebarElement.scrollTop = scrollIncrement;

  if (isScrolling && scrollIncrement >= 0) {
    window.requestAnimationFrame(goUp);
  }
};

/**
 * Scroll down in the sidebar.
 */
const goDown = () => {
  scrollIncrement += PX_DIFF;
  sidebarElement.scrollTop = scrollIncrement;

  if (isScrolling && scrollIncrement <= sidebarElement.scrollHeight) {
    window.requestAnimationFrame(goDown);
  }
};

const onDragOver = (event) => {
  const clientRect = sidebarElement.getBoundingClientRect();
  const isMouseOnTop =
    scrollIncrement >= 0 &&
    event.clientY > clientRect.top &&
    event.clientY < clientRect.top + OFFSET;
  const isMouseOnBottom =
    scrollIncrement <= sidebarElement.scrollHeight &&
    event.clientY > window.innerHeight - OFFSET &&
    event.clientY <= window.innerHeight;

  if (!isScrolling && (isMouseOnTop || isMouseOnBottom)) {
    isScrolling = true;
    scrollIncrement = sidebarElement.scrollTop;

    if (isMouseOnTop) {
      window.requestAnimationFrame(goUp);
    } else {
      window.requestAnimationFrame(goDown);
    }
  } else if (!isMouseOnTop && !isMouseOnBottom) {
    isScrolling = false;
  }
};

const isElement = function(any) {
  try {
    return (
      !!get(any, 'constructor.__proto__.prototype.constructor.name') &&
      !['HTML', '#document'].includes(get(any, 'nodeName'))
    );
  } catch (e) {
    return false;
  }
};

const isScrollable = function(node) {
  const scrollable = { vertical: false, horizontal: false };
  if (!isElement(node)) {
    return scrollable;
  }

  const nodeStyle = window.getComputedStyle(node);
  if (!nodeStyle) {
    return scrollable;
  }

  const overflowY = nodeStyle['overflow-y'];
  const overflowX = nodeStyle['overflow-x'];

  scrollable.vertical =
    (overflowY === 'scroll' || overflowY === 'auto') &&
    node.scrollHeight > node.clientHeight;

  scrollable.horizontal =
    (overflowX === 'scroll' || overflowX === 'auto') &&
    node.scrollWidth > node.clientWidth;

  return scrollable;
};

export const getTargetHasScrollBar = function(node) {
  if (node) {
    const scrollable = isScrollable(node);
    if (scrollable.vertical || scrollable.horizontal) return node;
    if (node.parentNode) {
      return getTargetHasScrollBar(node.parentNode);
    }
  }
  return document.body;
};

/**
 * The "throttle" method prevents executing the same function SO MANY times.
 */
const throttleOnDragOver = throttle(onDragOver, 300);

// IMPORTANT: CALL THIS METHOD IN: beginDrag!!!
export const addEventListenerForSidebar = (ref) => {
  sidebarElement = getTargetHasScrollBar(ref);
  window.addEventListener('dragover', throttleOnDragOver);
};

// IMPORTANT: CALL THIS METHOD IN: endDrag!!!
export const removeEventListenerForSidebar = () => {
  window.removeEventListener('dragover', throttleOnDragOver);
  isScrolling = false;
};

export default function useDragWithScrolling(dragOptions) {
  const [innerRef, setInnerRef] = useState();

  const [collect, connector, previewConnector] = useDrag({
    ...dragOptions,
    begin: (monitor) => {
      dragOptions.begin && dragOptions.begin(monitor);
      addEventListenerForSidebar(innerRef);
    },
    end: (item, monitor) => {
      dragOptions.end && dragOptions.end(item, monitor);
      removeEventListenerForSidebar();
    },
  });

  const refConnector = useCallback(
    (ref) => {
      setInnerRef(ref);
      return connector(ref);
    },
    [connector, setInnerRef],
  );

  return [collect, refConnector, previewConnector];
}
