import React from 'react';
import ReCAPTCHALib from 'react-google-recaptcha';
import withConf from 'common/hoc/withConf';

const ReCAPTCHA = ({ onChange, captchaSiteKey = '' }) => {
  if (!captchaSiteKey) {
    return null;
  }

  return (
    <>
      <div className="captcha" />
      <ReCAPTCHALib sitekey={captchaSiteKey} onChange={onChange} hl={'vi'} />
    </>
  );
};

export default withConf('recaptcha:publickey', 'captchaSiteKey')(ReCAPTCHA);
