import React from 'react';
import Icon from 'components/common/Icon';
import AntInput from 'schema-form/elements/ant-input/Input';

class InputFieldEditable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: props.defaultEditing || false,
    };

    this.inputRef = React.createRef();
  }

  updateShowHideFieldToEdit = () => {
    const { editing } = this.state;

    this.setState(
      {
        editing: !editing,
      },
      () => {
        if (!editing && this.inputRef) {
          try {
            this.inputRef.focus();
          } catch (e) {
            console.error(e);
          }
        }
      },
    );
  };

  onBlur = () => {
    const { onBlur, value, blurToCloseEdit } = this.props;

    if (typeof onBlur === 'function') {
      onBlur(value);
    }

    if (blurToCloseEdit) {
      this.updateShowHideFieldToEdit();
    }
  };

  setRef = (ref) => {
    this.inputRef = ref;
  };

  render() {
    const { className, guide, value, inputType, ...remainProps } = this.props;
    const { editing } = this.state;

    return (
      <div className={className}>
        <div className={editing ? '' : 'd-none'}>
          <div className="d-flex align-items-center">
            <div className={`m-r-5 ${className}__input`}>
              <AntInput
                {...remainProps}
                type={inputType}
                value={value}
                onBlur={this.onBlur}
                setRef={this.setRef}
              />
            </div>
            <Icon
              icon="check"
              className="text-primary font-size-large cursor-pointer"
              onClick={this.updateShowHideFieldToEdit}
            />
          </div>
        </div>
        <div className={editing ? 'd-none' : ''}>
          <div className="d-flex align-items-center">
            <span className="m-r-5">{value || guide}</span>
            <Icon
              icon="edit"
              className="cursor-pointer"
              onClick={this.updateShowHideFieldToEdit}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default InputFieldEditable;
