import React from 'react';
import { peerTakeFor as peerTakeForList, peerTakeType } from './constants';
import {
  getAdminPeerTakeFilterRouter,
  getAdminPeerTakeRootRouter,
  getCourseDashboardPeerTakeFilterRouter,
  getCourseDashboardPeerTakeRootRouter,
  getTeacherPeerTakeFilterRouter,
  getTeacherPeerTakeRootRouter,
  teacherMenuItems,
} from 'routes/teacher/util';
import { getMasterCourseDashboardLinkById } from 'routes/master/utils';
import {
  OEType,
  openEndedPeerActivity,
} from 'components/admin/question/schema/question-types';
import get from 'lodash.get';
import lodashGet from 'lodash.get';
import { t1 } from 'translate';
import { inRange } from 'common/validators';
import { mappingDigitalSupportPlanErrorByKey } from 'components/common/forms/questions/open-ended/digital-plan/utils';
import { hasUserAnswer } from 'common/learn/Question';
import { getCurrentUnixTimestamp } from '../../../../../common/utils/Date';

export const alertMarkingDialog = () => {
  return (
    <div className="alert-message">
      Hiện nay các Sở GD&ĐT, Phòng GD&ĐT đang phân công công tác chấm điểm và hỗ
      trợ đồng nghiệp theo cụm trường nhằm mục đích sinh hoạt chuyên môn tốt
      hơn. Vì vậy, các hoạt động chấm điểm bài tập sẽ tạm thời hoãn lại đến hết
      ngày 20.06.2020.
      <p className="m-t-10">
        Kính mong quý thầy cô quay lại tham gia vào hoạt động chấm bài hỗ trợ
        Giáo viên phổ thông sau khi đã được phân công cụm trường.
      </p>
    </div>
  );
};

export const generatePeerTakeUrl = (peerTakeFor, type, courseIid, isGVSP) => {
  switch (peerTakeFor) {
    case peerTakeForList.COURSE_DASHBOARD:
      if (isGVSP) {
        return type === peerTakeType.OVERVIEW
          ? getMasterCourseDashboardLinkById(
              courseIid,
              teacherMenuItems.MARKING_OPEN_ENDED_QUESTIONS,
            )
          : getMasterCourseDashboardLinkById(
              courseIid,
              teacherMenuItems.MARKING_OPEN_ENDED_QUESTIONS_FILTER,
            );
      }
      return type === peerTakeType.OVERVIEW
        ? getCourseDashboardPeerTakeRootRouter(courseIid)
        : getCourseDashboardPeerTakeFilterRouter(courseIid);

    case peerTakeForList.TEACHER_DASHBOARD:
      return type === peerTakeType.OVERVIEW
        ? getTeacherPeerTakeRootRouter()
        : getTeacherPeerTakeFilterRouter();

    case peerTakeForList.ADMIN:
      return type === peerTakeType.OVERVIEW
        ? getAdminPeerTakeRootRouter(courseIid)
        : getAdminPeerTakeFilterRouter(courseIid);

    default:
      return type === peerTakeType.OVERVIEW
        ? getAdminPeerTakeRootRouter(courseIid)
        : getAdminPeerTakeFilterRouter(courseIid);
  }
};

export const defaultActivityMenuItem = {
  id: openEndedPeerActivity.YOUR_TAKE,
  name: 'Bài làm của HV',
  icon: 'pencil',
};

export const peerActivityMenu = {
  [openEndedPeerActivity.YOUR_TAKE]: defaultActivityMenuItem,
  [openEndedPeerActivity.VIEW_DESIGN_OF_OTHER]: {
    id: openEndedPeerActivity.VIEW_DESIGN_OF_OTHER,
    name: 'Xem bài làm của HV khác',
    icon: 'list',
  },
  [openEndedPeerActivity.GENERAL_ANALYSIS]: {
    id: openEndedPeerActivity.GENERAL_ANALYSIS,
    name: 'Phân tích chung',
    icon: 'bar-chart',
  },
  [openEndedPeerActivity.DETAIL_MARKING]: {
    id: openEndedPeerActivity.DETAIL_MARKING,
    name: 'Thông tin chấm bài',
    icon: 'pencil-alt',
  },
  [openEndedPeerActivity.CAN_COMMENT]: {
    id: openEndedPeerActivity.CAN_COMMENT,
    name: 'Thảo luận',
    icon: 'comments',
  },
  [openEndedPeerActivity.TEACHER_CAN_COMMENT]: {
    id: openEndedPeerActivity.TEACHER_CAN_COMMENT,
    name: 'Thảo luận với GV',
    icon: 'comments',
  },
};

export const canCommentPeerTake = (question) => {
  const peerActivities = get(question, 'peer_activities', []);

  return (
    peerActivities && peerActivities.includes(openEndedPeerActivity.CAN_COMMENT)
  );
};

export const teacherCanCommentPeerTake = (question) => {
  const peerActivities = get(question, 'peer_activities', []);

  return (
    peerActivities &&
    peerActivities.includes(openEndedPeerActivity.TEACHER_CAN_COMMENT)
  );
};

export const getErrorTextWithWordMinMax = (wordMin, wordMax) => {
  if (wordMin && wordMax) {
    return t1(`the_answer_length_must_between_%s_and_%s_characters`, [
      wordMin,
      wordMax,
    ]);
  }

  if (wordMin) {
    return t1(`the_answer_length_must_be_longer_than_%s_characters`, [wordMin]);
  }

  if (wordMax) {
    return t1(`the_answer_length_must_be_shorter_than_%s_characters`, [
      wordMax,
    ]);
  }
};

export const getValidateForRTE = (wordMin, wordMax) => {
  return inRange(
    wordMin,
    wordMax,
    getErrorTextWithWordMinMax(wordMin, wordMax),
  );
};

export const manualValidateWordMinMax = (data = {}) => {
  const { formValues, params = {} } = data;

  const { wordmin, wordmax } = params;
  let contents = get(formValues, 'content', []);

  let errorText = '';
  if (Array.isArray(contents)) {
    contents.forEach((data) => {
      errorText = getValidateForRTE(wordmin, wordmax)(data.content);
      if (errorText) {
        return errorText;
      }
    });
  } else if (typeof contents === 'string') {
    errorText = getValidateForRTE(wordmin, wordmax)(contents);
  }

  return errorText;
};

export const shouldShowDetailMarking = (isAdvancedLayoutType, userAnswers) => {
  return (
    isAdvancedLayoutType &&
    !!lodashGet(userAnswers, 'score_by_rubric.rubric_iid')
  );
};

export const canPeerMarking = (takeInfo, isExamMode, noMarking, question) => {
  const takeId = lodashGet(takeInfo, 'takeId');
  const peerActivities = lodashGet(question, 'peer_activities', []);
  const peerMarking = peerActivities.includes(
    openEndedPeerActivity.PEER_MARKING,
  );

  return !isExamMode && takeId && !noMarking && peerMarking;
};

export const showMarkingInformation = (
  takeInfo,
  userAnswers,
  isExamMode,
  noMarking,
) => {
  const takeId = lodashGet(takeInfo, 'takeId');

  return !isExamMode && takeId && hasUserAnswer(userAnswers) && !noMarking;
};

export const isOEAdvancedLayoutType = (question) =>
  get(question, 'sub_type') === OEType.advanced;

export const isOESupportPlanLayoutType = (question) =>
  get(question, 'sub_type') === OEType.support_plan;

export const isOEDigitalSupportPlanLayoutType = (question) =>
  get(question, 'sub_type') === OEType.support_plan &&
  !get(question, 'allow_upload_support_plan_file');

export const isOEAdvancedTableLayout = (question) =>
  isOEAdvancedLayoutType(question) && !get(question, 'sp1_layout');

export const isStructureAnswerType = (question) => {
  const structureAnswer = get(question, 'structure_answer');

  return Array.isArray(structureAnswer);
};

export const getQuestionAnswerTexts = (question) => {
  return lodashGet(question, 'structure_answer', [])
    .map((answer) => {
      const inputSubmit = get(answer, 'input_submit', []);
      const name = get(answer, 'name');

      if (!inputSubmit.some((value) => value !== 'text')) {
        return name;
      }

      return false;
    })
    .filter(Boolean);
};

export const getRightMenuItemByPeerActivity = (
  peerActivities = [],
  question,
  userAnswers,
) => {
  const isAdvancedLayoutType = isOEAdvancedLayoutType(question);
  const isAdvancedTableLayout = isOEAdvancedTableLayout(question);
  const showDetailMarking = shouldShowDetailMarking(
    isAdvancedLayoutType,
    userAnswers,
  );

  let activities = peerActivities.length
    ? [openEndedPeerActivity.YOUR_TAKE, ...peerActivities]
    : [];

  if (isAdvancedLayoutType && !isAdvancedTableLayout) {
    activities = peerActivities;
  }

  // with advanced layout type, we always show detail marking with question has take
  if (showDetailMarking) {
    activities = [...activities, openEndedPeerActivity.DETAIL_MARKING];
  }

  return activities
    .map((activity) => peerActivityMenu[activity])
    .filter(Boolean);
};

export const generateOEFormId = (courseIid, questionIid) => {
  return `oe-form-${courseIid}-${questionIid}`;
};

export const oeErrorMappingKey = (question, error) => {
  if (isOEDigitalSupportPlanLayoutType(question)) {
    return mappingDigitalSupportPlanErrorByKey(error);
  }

  // TODO: implement key error of OE
  return '';
};

export function getArchivedAnswerAttachmentsRestorationInfo(
  expectedTimeToAccessRestoredArchiveFile,
) {
  const currentTime = getCurrentUnixTimestamp();

  const { from_ts: archiveRestorationFromTs, to_ts: archiveRestorationToTs } =
    expectedTimeToAccessRestoredArchiveFile || {};

  // if restoreArchiveToTs < now then this object has already been archived again
  const isArchiveRestorationInitiated =
    archiveRestorationFromTs &&
    archiveRestorationToTs &&
    archiveRestorationToTs >= currentTime;

  const isArchiveRestored =
    isArchiveRestorationInitiated &&
    archiveRestorationFromTs <= currentTime &&
    archiveRestorationToTs >= currentTime;

  return {
    archiveRestorationFromTs,
    archiveRestorationToTs,
    isArchiveRestorationInitiated,
    isArchiveRestored,
  };
}
