import React from 'react';
import schema from './schema';
import NodeNew from 'components/admin/node/new';
import { submit } from 'redux-form';
import endpoints from 'components/bdtx/overview-module-materials/endpoints';
import { connect } from 'react-redux';
import get from 'lodash.get';

import './style.scss';

const FORM_ID = 'choose_material';

const generateFormId = (objectData = {}) => {
  const { target_user, cap, chuong_trinh, module_iid } = objectData;

  return `${FORM_ID}_${target_user}_${cap}_${chuong_trinh}_${module_iid}`;
};

const ChooseMaterial = ({ hiddenField, materials, dispatch }) => {
  const formId = generateFormId(hiddenField);
  const formatMaterials = (materials || []).map((material) => {
    const seller = get(material, '__expand.organizations.0', {});

    return {
      ...material,
      value: material.iid,
      label: `${material.name} (${seller.name})`,
    };
  });

  const onChangeFormValue = (formValues = {}) => {
    const defaultNode = generateNode();

    if (
      !(formValues || {}).program_iid ||
      formValues.program_iid == defaultNode.program_iid
    ) {
      return;
    }

    dispatch(submit(formId));
  };

  const generateNode = () => {
    const selectedMaterial = (materials || []).find(
      (material) => material.chosen,
    );

    return {
      program_iid: (selectedMaterial || {}).iid,
    };
  };

  return (
    <div className="chosen-material">
      <NodeNew
        schema={schema(formatMaterials)}
        hiddenFields={hiddenField}
        formid={formId}
        alternativeApi={endpoints.bdtx_assign_material}
        mode="edit"
        onChange={onChangeFormValue}
        node={generateNode()}
        hideSubmitButton
      />
    </div>
  );
};

export default connect()(ChooseMaterial);
