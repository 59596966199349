const Cascade = ({ component, readOnly, ...rest }) => {
  return {
    type: 'cascade',
    component,
    readOnly,
    ...rest,
  };
};

export default Cascade;
