import React, { createRef, useEffect, useState } from 'react';
import get from 'lodash.get';
import styled from 'styled-components';
import DisplayHtml from 'components/common/html';
import List from 'antd/lib/list';

const Indicator = styled.hr`
  width: 50%;
  background-color: #0a0a0a;
`;

const PlayerWrapper = styled.div`
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  audio {
    background-color: #fff;
    outline: none;
    border-radius: 5px;

    &::-webkit-media-controls-panel {
      background-color: #fff;
      border: none;
    }
    &::-webkit-media-controls-play-button {
      height: 32px;
      border-radius: 50%;
      filter: invert(100%);
      background-color: #ff6e73; //hack => background primary
    }
  }
`;

const AudioItem = styled.div`
  user-select: none;
  cursor: pointer;
  ${({ active }) => (active ? 'color: #00918c' : '')}
`;

function Audio({ item }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const audios = get(item, 'attachments', []);

  const audioRef = createRef();

  useEffect(
    () => {
      if (
        isUpdate &&
        audioRef &&
        audioRef.current.play &&
        typeof audioRef.current.play === 'function'
      ) {
        audioRef.current.play();
      }
    },
    [audioRef, currentIndex, isUpdate],
  );

  const onEnded = () => {
    if (currentIndex >= audios.length - 1) {
      setCurrentIndex(0);
      return;
    }
    setCurrentIndex(currentIndex + 1);
  };

  const onAudioItemClick = (index) => {
    if (index !== currentIndex) {
      setCurrentIndex(index);
      setIsUpdate(true);
    }
  };

  return (
    <div id={item.id}>
      <Indicator />
      <DisplayHtml content={item.content} />
      <PlayerWrapper>
        <audio
          ref={audioRef}
          src={get(audios[currentIndex], 'link')}
          onEnded={onEnded}
          controls
          className="w-100"
        />
        {audios.length > 1 && (
          <div className="p-10">
            <List
              size="small"
              bordered
              dataSource={audios}
              renderItem={(item, index) => (
                <List.Item>
                  <AudioItem
                    onClick={() => onAudioItemClick(index)}
                    active={currentIndex === index}
                  >
                    {currentIndex === index ? '\u2023 ' : `${index + 1}. `}
                    {item.name}
                  </AudioItem>
                </List.Item>
              )}
            />
          </div>
        )}
      </PlayerWrapper>

      <Indicator />
    </div>
  );
}

export default Audio;
