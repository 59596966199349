import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import ChooseModeFreeze from './ChooseFreezeMode';
import { t1 } from 'translate';
import lodashGet from 'lodash.get';
import { freezeMode } from 'configs/constants';

export const freezeLabel = (freeze, ntype) => {
  let label;

  if (ntype == 'course') {
    switch (freeze) {
      case freezeMode.FREEZE_MODE_COMPLETELY:
        return t1('freeze_learning_and_marking');
        break;

      case freezeMode.FREEZE_MODE_LEARN:
        return t1('freeze_learning');
        break;

      case freezeMode.FREEZE_MODE_NORMAL:
      default:
        return t1('course_is_open');
        break;
    }
  } else {
    label = '';
  }

  return label;
};

const Freeze = ({ node, ntype, vertical }) => {
  const freeze = lodashGet(node, 'freeze', '');

  let typeFreeze = freezeLabel(freeze, ntype);

  return (
    <div className={vertical ? '' : 'd-flex justify-content-between'}>
      <div>{t1('freeze_mode')}</div>
      <div>
        <DetailOnDialog
          renderPreview={({ showFull }) => (
            <div onClick={showFull} className={'cursor-pointer'}>
              {typeFreeze} <span className="m-l-5 ve ve-write" />
            </div>
          )}
          renderFull={() => <ChooseModeFreeze node={node} ntype={ntype} />}
          dialogOptionsProperties={{
            title: t1('choose_mode_freeze'),
            handleClose: true,
            width: '40%',
          }}
          dialogKey={`update-node-${ntype}`}
        />
      </div>
    </div>
  );
};

export default Freeze;
