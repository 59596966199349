import './stylesheet.scss';

import React from 'react';
import { getNodeSelector } from 'components/admin/node/utils';
import AttachedSco from 'components/learn/items/lecture/AttachedSco';
import lodashGet from 'lodash.get';
import { connect } from 'react-redux';
import InfoGraphicSco from 'components/learn/items/lecture/InfoGraphicSco';
import Action from 'components/learn/watch-or-read/Action';
import {
  isInfoGraphicMode,
  isReadMode,
  isWatchMode,
} from 'components/learn/watch-or-read/utils';

const WatchOrReadWrapper = ({
  lecture,
  course,
  listActiveVideoWindow = [],
  showInScoSpl,
  className,
  itemType,
  children,
}) => {
  if (!lecture) {
    return null;
  }

  const readMode = isReadMode(lecture);
  const infoGraphicMode = isInfoGraphicMode(lecture);
  const watchMode = isWatchMode(lecture);

  const renderReadMode = () => {
    return (
      <>
        <div className="learn-lecture-wrapper learn-content-border">
          <div className="full-width">
            <AttachedSco lecture={lecture} course={course} />
          </div>
        </div>
      </>
    );
  };

  const renderInfoGraphicMode = () => {
    return (
      <div className="learn-lecture-wrapper learn-content-border">
        <div className="full-width">
          <InfoGraphicSco lecture={lecture} course={course} />
        </div>
      </div>
    );
  };

  const getWatchOrReadClassName = () => {
    const isLectureActivated = listActiveVideoWindow.find(
      (iid) => iid == lecture.iid,
    );

    const isHideVideoPicture = lecture.hide_video_picture;

    if (isLectureActivated && !isHideVideoPicture) {
      return 'as-picture';
    } else {
      return 'd-none';
    }
  };

  const renderWatchMode = () => {
    if (watchMode) {
      return children;
    }

    return (
      <>
        <div className={getWatchOrReadClassName()}>
          <Action lecture={lecture} playAsPicture={true} />

          {children}
        </div>
      </>
    );
  };

  const shouldShowWatchOrRead = lecture.sco_iid || lecture.infographic_sco_iid;

  return (
    <div className={`watch-or-read ${className || ''}`}>
      {shouldShowWatchOrRead && showInScoSpl ? (
        <div className="text-right m-b-10">
          <Action lecture={lecture} itemType={itemType} />
        </div>
      ) : null}

      {readMode && renderReadMode()}

      {infoGraphicMode && renderInfoGraphicMode()}

      {renderWatchMode()}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const lecture = props.item;
  if (!lecture) {
    return;
  }

  const iid = lodashGet(lecture, 'iid');
  const pid = lodashGet(lecture, 'pid');

  return {
    lecture: getNodeSelector(state)(iid, pid) || {},
    listActiveVideoWindow: lodashGet(state, 'learn.listActiveVideoWindow', []),
  };
};

export default connect(mapStateToProps)(WatchOrReadWrapper);
