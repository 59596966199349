import React from 'react';
import errorCodes from 'common/errorCodes';
import Store from 'store';
import actions from 'actions/node/creators';
import { logout } from 'actions/auth';
import AuthCodeInput from './AuthCodeInput';
import { updateUserInfo } from 'actions/auth';
import { t1 } from 'translate';

const checkGoogleAuthenticatorError = (ret) => {
  const errorCode = ret.err_code;

  switch (errorCode) {
    case errorCodes.ERR_2FA_REQUIRED:
    case errorCodes.ERR_2FA_SETUP_REQUIRED:
      const dialogKey = 'AuthCodeInput';
      Store.dispatch(
        actions.handleOpenDialog(
          {
            contentDialog: (
              <div>
                <AuthCodeInput
                  onVerificationSuccessful={(sessionOf2FA) => {
                    if (sessionOf2FA) {
                      Store.dispatch(
                        actions.handleOpenDialog(
                          { openDialog: false },
                          dialogKey,
                        ),
                      );
                      Store.dispatch(
                        updateUserInfo(
                          { session_for_2fa: sessionOf2FA },
                          false,
                        ),
                      );
                    }
                  }}
                />
              </div>
            ),
            optionsProperties: {
              handleClose: true,
              title: t1('two_factor_authentication_code'),
              modal: true,
            },
          },
          'AuthCodeInput',
        ),
      );

      break;
    case errorCodes.ERR_AUTHENTICATE_FAILED:
      Store.dispatch(logout());

      break;
  }
};

export default checkGoogleAuthenticatorError;
