import lodashGet from 'lodash.get';
import { t1 } from 'translate';
import { organizations } from 'components/admin/organization/schema/elements';
import subTypes from 'configs/constants/org-sub-types';
import { required } from 'common/validators';
import { userGradeToShortText } from 'configs/constants/user';

const schema = (formid, values, step, xpath, props) => {
  const caps = lodashGet(props, 'caps') || [];
  const capOptions = caps.map((cap) => {
    return {
      label: userGradeToShortText(cap),
      value: cap,
    };
  });

  const rootIids = lodashGet(props, 'orgIids') || [];
  let excludeOrgIids = lodashGet(props, 'excludeOrganizationIids') || [];

  excludeOrgIids = excludeOrgIids.filter((exOrgIid) => {
    return !rootIids.includes(exOrgIid);
  });

  return {
    caps: {
      type: 'multiCheckbox',
      inline: true,
      options: capOptions,
      floatingLabelText: 'Cấp học *',
      validate: [required()],
    },
    apply_organizations: organizations({
      formid,
      includeRoot: 0,
      populateDefaultValue: [],
      subTypes: [
        subTypes.TAPHUAN_SUBTYPE_SO_GD,
        subTypes.TAPHUAN_SUBTYPE_PHONG_GD,
      ],
      excludeOrganizationIids: excludeOrgIids,
      multiple: true,
      label: `${t1('apply_for_organizations')} (*)`,
      defaultValidate: [required(t1('organizations_can_not_empty'))],
      validate: [required(t1('organizations_can_not_empty'))],
    }),
  };
};

const ui = () => {
  return [
    {
      id: 'default',
      fields: ['apply_organizations'],
    },
  ];
};

export default {
  schema,
  ui,
};
