import React, { Component } from 'react';
import { t1 } from 'translate';
import OrganizationsOrPhongBan from 'components/admin/group/common/OrganizationsOrPhongBanInResultTable';
import Positions from 'components/admin/group/edit/member/search-results/Positions';
import AcademicCategories from 'components/admin/group/common/AcademicCategories';
import { connect } from 'react-redux';
import Perm from 'common/utils/Perm';
import lGet from 'lodash.get';

class BasicUserInfo extends Component {
  canShowField = (field, isExpandField = false) => {
    const { user, hiddenItem, showItem } = this.props;
    const hasData = !isExpandField
      ? !!lGet(user, field)
      : !!lGet(user, `__expand.${field}`);

    const hasHiddenItem = hiddenItem && hiddenItem.length;
    const hasShowItem = showItem && showItem.length;

    let canShow = hasData;

    if (hasHiddenItem) {
      canShow = canShow && !hiddenItem.includes(field);
    }

    if (hasShowItem) {
      canShow = canShow && showItem.includes(field);
    }

    return canShow;
  };

  render() {
    const { user, domain, isRoot } = this.props;

    const phongbans = lGet(user, '__expand.phongbans', []).filter(Boolean);
    const orgs = lGet(user, '__expand.user_organizations', []).filter(Boolean);
    const positions = lGet(user, '__expand.positions', []).filter(Boolean);
    const academicCategories = lGet(
      user,
      '__expand.academic_categories',
      [],
    ).filter(Boolean);

    return (
      <div className="break-word">
        {this.canShowField('code') && (
          <div>
            {t1('code')}: <b>{user.code}</b>
          </div>
        )}

        {this.canShowField('name') && (
          <div>
            {t1('name')}: <b>{user.name}</b>
          </div>
        )}

        {this.canShowField('lname') && (
          <div>
            {t1('login_name')}:{' '}
            <b>{user.original_lname ? user.original_lname : user.lname}</b>
          </div>
        )}

        {this.canShowField('mail') && (
          <div>
            {t1('email')}: <b>{user.mail}</b>
          </div>
        )}
        {this.canShowField('phone') && (
          <div>
            {t1('phone')}: <b>{user.phone}</b>
          </div>
        )}
        {this.canShowField('nationality') && (
          <div>
            {t1('nationality')}: <b>{user.nationality}</b>
          </div>
        )}
        {this.canShowField('ethnicity') && (
          <div>
            {t1('ethnicity')}: <b>{user.ethnicity}</b>
          </div>
        )}
        {this.canShowField('address') ? (
          <div>
            {t1('address')}: <b>{user.address}</b>
          </div>
        ) : null}

        {user.__expand && [
          <div className="d-flex">
            {this.canShowField('user_organizations', true) &&
              orgs &&
              !!orgs.length && [
                <div>
                  {t1('organizations')}
                  :&nbsp;
                </div>,
                <b>
                  <OrganizationsOrPhongBan
                    item={user}
                    attr={'user_organizations'}
                  />
                </b>,
              ]}
          </div>,
          <div className="d-flex">
            {this.canShowField('phongbans', true) &&
              phongbans &&
              !!phongbans.length && [
                <div>{t1('temis_category')}:&nbsp;</div>,
                <div>
                  <b>
                    <OrganizationsOrPhongBan item={user} attr={'phongbans'} />
                  </b>
                </div>,
              ]}
          </div>,
          <div className="d-flex">
            {this.canShowField('positions', true) &&
              positions &&
              !!positions.length && [
                <div>
                  {t1('positions')}
                  :&nbsp;
                </div>,
                <b>
                  <Positions item={user} showEquivalentJobPositionSystem />
                </b>,
              ]}
          </div>,
          <div className="d-flex">
            {this.canShowField('academic_categories', true) &&
              academicCategories &&
              !!academicCategories.length && [
                <div>
                  {t1('academic_categories')}
                  :&nbsp;
                </div>,
                <b>
                  <AcademicCategories item={user} noLink />
                </b>,
              ]}
          </div>,
        ]}

        {this.canShowField('roles') && (
          <div>
            {t1('is_staff')}:{' '}
            <b>{t1(Perm.hasPerm('staff', domain, user) ? 'yes' : 'no')}</b>
          </div>
        )}

        <div>
          {isRoot && (
            <>
              {t1('user_account_status')}:{' '}
              <b>
                {t1(user.status === 'activated' ? 'activated' : 'unactivated')}
              </b>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const domainInfo = state.domainInfo;
  return {
    domain: domainInfo && domainInfo.domain,
    isRoot: lGet(state, 'domainInfo.isRoot'),
  };
};

export default connect(mapStateToProps)(BasicUserInfo);
