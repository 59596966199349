import React from 'react';
import Icon from '../Icon';
import RaisedButton from '../mui/RaisedButton';
import NewButton from '../primary-button';
import FlatButton from '../mui/FlatButton';

const ButtonAction = (props) => {
  const {
    raisedButton,
    renderComponent,
    title,
    disabled,
    primary,
    secondary,
    className,
    buttonLabelStyle,
    newButton,
    antIcon,
    type,
    onClick,
    loading,
  } = props;

  const iconStyle =
    props.icon === 'delete'
      ? {
          theme: 'twoTone',
          twoToneColor: 'red',
        }
      : {};

  const icon = (
    <Icon
      title={title}
      icon={props.icon}
      className="action"
      style={props.style}
      antIcon={antIcon || props.icon === 'delete'}
      {...iconStyle}
    />
  );

  const propsButton = {
    icon,
    label: props.label || '',
    style: props.buttonStyle,
    onClick,
    disabled,
    primary,
    secondary,
    className,
    labelStyle: buttonLabelStyle,
    type,
    loading,
  };
  const btn = props.iconButton ? (
    <span onClick={onClick}>
      {typeof props.iconButton === 'boolean' ? icon : props.iconButton}
    </span>
  ) : raisedButton ? (
    <RaisedButton {...propsButton} />
  ) : newButton ? (
    <NewButton {...propsButton} buttonType={props.buttonType} />
  ) : (
    <FlatButton {...propsButton} />
  );

  return renderComponent ? renderComponent({ ...props }) : btn;
};

export default ButtonAction;
