import React, { useState } from 'react';
import lodashGet from 'lodash.get';
import { t1 } from 'translate';
import CommonAntdTable from 'components/common/antd/table';
import ShowMoreLess from 'components/common/show-more-less';
import PreviewUserInDialog from 'components/admin/user/account/common/PreviewInDialog';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import OrganizationsOrPhongBanInResultTable from 'components/admin/group/common/OrganizationsOrPhongBanInResultTable';
import OrganizationsParent from 'components/admin/group/common/OrganizationParent';
import subTypes from 'configs/constants/org-sub-types';
import PassOrFail from 'components/common/pass-or-fail';
import NotifyWarningButton from 'components/admin/user/account/warning/NotifyWarningButton';
import endpoints from 'components/temis/endpoints';
import ExportBtnWithConfirmDialog from 'components/common/action-button/ExportBtnWithConfirmDialog';

const Results = ({ items, searchValues, totalResult, searchFormId }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = [
    {
      title: t1('user_name'),
      render: (item) => {
        return <PreviewUserInDialog user={item} field="name" />;
      },
    },
    {
      title: t1('user_email'),
      dataIndex: 'mail',
      render: (mail) => mail,
    },
    {
      title: t1('user_organizations'),
      render: (item) => {
        return (
          <OrganizationsOrPhongBanInResultTable
            item={item}
            attr={'user_organizations'}
          />
        );
      },
    },
    {
      title: t1('belong_to_organizations'),
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (item) => (
        <OrganizationsParent
          item={item}
          attr={'user_organizations'}
          parentSubTypesToShow={[
            subTypes.TAPHUAN_SUBTYPE_PHONG_GD,
            subTypes.TAPHUAN_SUBTYPE_SO_GD,
          ]}
        />
      ),
    },
    {
      title: t1('total_credit_must_to_learn'),
      type: TYPE_OF_TABLE_DATA.TITLE,
      width: 100,
      render: (user) => {
        return (
          lodashGet(user, 'learning_result_final.total_credit_must_to_learn') ||
          0
        );
      },
    },
    {
      title: t1('total_credit_learned'),
      type: TYPE_OF_TABLE_DATA.TITLE,
      width: 100,
      render: (user) => {
        return (
          lodashGet(user, 'learning_result_final.total_credit_learned') || 0
        );
      },
    },
    {
      title: t1('total_credit_passed'),
      type: TYPE_OF_TABLE_DATA.TITLE,
      width: 100,
      render: (user) => {
        return (
          lodashGet(user, 'learning_result_final.total_credit_passed') || 0
        );
      },
    },
    {
      title: t1('learn_passed'),
      type: TYPE_OF_TABLE_DATA.TITLE,
      width: 100,
      render: (user) => {
        const passed =
          !!lodashGet(user, 'learning_result_final.passed') &&
          lodashGet(user, 'learning_result_final_status') !== 'rejected';
        const reason_rejected = lodashGet(
          user,
          'learning_result_final_reason_rejected',
        );

        return (
          <>
            <div className="d-flex justify-content-center">
              <PassOrFail status={passed} />
            </div>
            {!passed && reason_rejected && (
              <>
                <br />
                <p style={{ maxWidth: 250 }} className="m-t-10">
                  {t1('reason_rejected')}:&nbsp;{' '}
                  <ShowMoreLess
                    content={reason_rejected}
                    length={50}
                    showIcon={false}
                  />
                </p>
              </>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <ExportBtnWithConfirmDialog
        url={endpoints.export_detail_result_bdtx}
        params={searchValues}
        formid={searchFormId}
        label={t1('export_detail_result_bdtx')}
        disabled={!items || !items.length}
        fetchingCacheOnly
      />

      <CommonAntdTable
        columns={columns}
        dataSource={items}
        pagination={false}
        rowSelection={{
          selectedRowKeys,
          onChange: setSelectedRowKeys,
        }}
        bordered=""
      />

      <NotifyWarningButton
        totalResult={totalResult}
        userIds={selectedRowKeys}
        conditionToSearch={searchValues}
        disabled={!selectedRowKeys || !selectedRowKeys.length}
      />
    </>
  );
};

export default Results;
