import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash.get';
import CustomScrollbars from 'components/common/custom-scrollbars';
import CollaboratingItemComment from 'components/common/comment/collaborating-item-comment';

const Discussion = ({
  isPreview = false,
  syllabus,
  course,
  collaboratingItemId = '',
  collaboratingItem,
}) => {
  return (
    <CustomScrollbars autoHide autoHeight autoHeightMax={300}>
      <CollaboratingItemComment
        syllabusIid={syllabus.iid}
        courseIid={course.iid}
        collaboratingItemId={collaboratingItemId}
        isLearn={!isPreview}
        collaboratingItem={collaboratingItem}
        askTeacherToAnswer={false}
      />
    </CustomScrollbars>
  );
};

const mapStateToProps = (state) => {
  const { learn = {} } = state;
  const {
    isPreview = false,
    syllabusIid = '',
    courseIid = '',
    itemIid = '',
  } = learn;
  const syllabus = get(state, `tree.${syllabusIid}`, {});
  const course = get(state, `dataApiResults.${courseIid}`, {});
  return {
    isPreview,
    syllabus,
    course,
    collaboratingItemId: itemIid,
    collaboratingItem: {
      id: itemIid,
      iid: itemIid,
    },
  };
};

export default compose(connect(mapStateToProps))(Discussion);
