export const LOGIN_OAUTH_SUCCESS_REQUEST = 'LOGIN_OAUTH_SUCCESS_REQUEST';
export const LOGIN_AUTH_WITH_TOKEN = 'LOGIN_AUTH_WITH_TOKEN';

export const loginOAuthSuccessRequest = (loginType, token, params) => ({
  type: LOGIN_OAUTH_SUCCESS_REQUEST,
  loginType,
  token,
  params,
});

export const authWithToken = (authUrl, data, handler) => ({
  type: LOGIN_AUTH_WITH_TOKEN,
  authUrl,
  data,
  handler,
});
