import React from 'react';
import connect from 'react-redux/es/connect/connect';
import Tooltip from 'antd/lib/tooltip';

import { isSmallScreen } from 'common';
import { t1 } from 'translate';
import { getCurrentPercent } from './utils';
import './styles.scss';

const StatusInCircle = ({ learnItem, trackerProgress }) => {
  const currentPercent = getCurrentPercent({ trackerProgress, learnItem });
  const isCompleted = currentPercent && currentPercent.toString() === '100';

  return currentPercent !== undefined ? (
    <Tooltip
      title={t1('completed_percent')}
      placement={isSmallScreen ? 'leftTop' : 'top'}
    >
      <span
        className={`current-percent ${isSmallScreen ? 'm-r-15' : 'm-l-15'} ${
          isCompleted ? 'completed' : ''
        }`}
      >
        {currentPercent}
      </span>
    </Tooltip>
  ) : null;
};

// const map = (state, props) => ({
//   trackerProgress: state.trackerProgress
// });

// export default connect(map)(StatusInCircle);
export default StatusInCircle;
