import get from 'lodash.get';
import { KEY_MAPPING } from 'components/common/forms/questions/open-ended/digital-plan/utils';
import { trainingPlanModules } from 'components/admin/training-plan/common';

export const dataKey = {
  id: 'id',
  tt: 'tt',
  validation: 'validation',
  formatData: 'formatData',
  disabled: 'disabled',
  result: 'result',
  dateTime: 'dateTime',
  corporate: 'corporate',
  action: 'action',
  finalResult: 'finalResult',
  defaultValue: 'defaultValue',
};

/***
 * Tạo field name từ data source
 * @param rowId is id of block (row item)
 * @param fieldKey is result | finalResult | dateTime | corporate
 * @param fieldIndex start from 1. Là field index của mỗi field key tương ứng trong data source
 */
export const createFieldName = (rowId, fieldKey, fieldIndex) => {
  return `${rowId}-${fieldKey}-${fieldIndex}`;
};

const getDivisionString = (val, total) => {
  if (!total || Number(total) <= 0 || Number.isNaN(Number(total))) {
    return '0%';
  } else {
    return `${Math.floor(
      Number((val ? Number(val) : 0) * 100) / Number(total),
    )}%`;
  }
};

const getBaseResultFieldNameByModule = (module) => {
  let key;
  if (module == trainingPlanModules.MODULE_3_COTCAN) {
    key = createFieldName(1, dataKey.result, 1);
  } else if (module == trainingPlanModules.MODULE_4_COTCAN) {
    key = createFieldName(2, dataKey.result, 1);
  } else if (module == trainingPlanModules.MODULE_5_COTCAN) {
    key = createFieldName(3, dataKey.result, 1);
  } else if (module == trainingPlanModules.MODULE_9_COTCAN) {
    key = createFieldName(4, dataKey.result, 1);
  }

  return key;
};

// NOTE: key format: Id-Key-FieldIndex
const formatDataByKey = (module, defaultValue = 0) => {
  const key = getBaseResultFieldNameByModule(module);

  return (value, formData) =>
    getDivisionString(value, get(formData, key, defaultValue));
};

const formatDataDevelopmentLearning = (module) => {
  return (value, formData) => {
    const reference = getDataOfModule(module)(formData);

    return getDivisionString(value, reference);
  };
};

const getDataOfModule = (module) => {
  return (formData) => {
    const key = getBaseResultFieldNameByModule(module);

    return get(formData, key, 0);
  };
};

const getDataByKey = (key) => {
  return (formData) => {
    return get(formData, key, 0);
  };
};

const geMinValueOfModules = (
  formData,
  passedTrainingPlanCategories = [
    trainingPlanModules.MODULE_3_COTCAN,
    trainingPlanModules.MODULE_4_COTCAN,
    trainingPlanModules.MODULE_5_COTCAN,
    trainingPlanModules.MODULE_9_COTCAN,
  ],
) => {
  const values = [
    passedTrainingPlanCategories.includes(
      trainingPlanModules.MODULE_3_COTCAN,
    ) &&
      Number(
        get(
          formData,
          getBaseResultFieldNameByModule(trainingPlanModules.MODULE_3_COTCAN),
          0,
        ) || 0,
      ),
    passedTrainingPlanCategories.includes(
      trainingPlanModules.MODULE_4_COTCAN,
    ) &&
      Number(
        get(
          formData,
          getBaseResultFieldNameByModule(trainingPlanModules.MODULE_4_COTCAN),
          0,
        ) || 0,
      ),
    passedTrainingPlanCategories.includes(
      trainingPlanModules.MODULE_5_COTCAN,
    ) &&
      Number(
        get(
          formData,
          getBaseResultFieldNameByModule(trainingPlanModules.MODULE_5_COTCAN),
          0,
        ) || 0,
      ),
    passedTrainingPlanCategories.includes(
      trainingPlanModules.MODULE_9_COTCAN,
    ) &&
      Number(
        get(
          formData,
          getBaseResultFieldNameByModule(trainingPlanModules.MODULE_9_COTCAN),
          0,
        ) || 0,
      ),
  ].filter(Boolean);

  if (!values.length) {
    return 0;
  }

  return Math.min(...values);
};

const shouldDisabledByTrainingPlanModule = (
  module,
  activeModule,
  isResultPlan,
  passedTrainingPlanCategories,
) => {
  if (isResultPlan) {
    return (
      !Array.isArray(passedTrainingPlanCategories) ||
      !passedTrainingPlanCategories.includes(module)
    );
  }
  return activeModule < module;
};

const shouldDisabled = (
  module,
  activeModule,
  isResultPlan,
  passedTrainingPlanCategories,
  withMinOfAllModules = false,
) => {
  if (
    passedTrainingPlanCategories !== '' &&
    shouldDisabledByTrainingPlanModule(
      module,
      activeModule,
      isResultPlan,
      passedTrainingPlanCategories,
    )
  ) {
    return true;
  }

  return (formData) => {
    let value = getDataOfModule(module)(formData);
    if (withMinOfAllModules) {
      value = geMinValueOfModules(formData, passedTrainingPlanCategories);
    }

    return !Number(value);
  };
};

const preLearn = (module, isResultPlan, passedTrainingPlanCategories = []) => [
  {
    [dataKey.id]: '0',
    [dataKey.tt]: '1',
    [dataKey.action]: 'Chuẩn bị học tập',
    colSpan: 4,
  },
  {
    [dataKey.id]: '1',
    [dataKey.tt]: '1.1',
    [dataKey.action]:
      'Tiếp nhận danh sách GVPT/ CBQLCSGDPT được phân công phụ trách mô đun 3',
    [dataKey.result]: `${
      KEY_MAPPING.number
    } GV/CBQLCSGDPT đại trà (điền số lượng do sở GDĐT phân công)`,
    [dataKey.finalResult]: `${
      KEY_MAPPING.number
    } GV/CBQLCSGDPT đại trà được SGD/PGD phân công.`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: () => {
          return shouldDisabledByTrainingPlanModule(
            trainingPlanModules.MODULE_3_COTCAN,
            module,
            isResultPlan,
            passedTrainingPlanCategories,
          );
        },
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_3_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    addAble: isResultPlan,
    addAbleTooltip: isResultPlan
      ? 'Với các sở chưa triển khai trên LMS thì điền số "0" và ghi "Chưa triển khai"'
      : null,
    guideForAddable: isResultPlan
      ? 'Với các sở chưa triển khai trên LMS thì điền số "0" và nhấn vào dấu "+" rồi ghi "Chưa triển khai"'
      : null,
  },
  {
    [dataKey.id]: '2',
    [dataKey.tt]: '1.2',
    [dataKey.action]:
      'Tiếp nhận danh sách GVPT/ CBQLCSGDPT  được phân công phụ trách mô đun  4',
    [dataKey.result]: `${
      KEY_MAPPING.number
    } GV/CBQLCSGDPT đại trà (điền số lượng do sở GDĐT phân công)`,
    [dataKey.finalResult]: `${
      KEY_MAPPING.number
    } GV/CBQLCSGDP đại trà được SGD/PGD phân công.`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: () => {
          return shouldDisabledByTrainingPlanModule(
            trainingPlanModules.MODULE_4_COTCAN,
            module,
            isResultPlan,
            passedTrainingPlanCategories,
          );
        },
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_4_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    addAble: isResultPlan,
    addAbleTooltip: isResultPlan
      ? 'Với các sở chưa triển khai trên LMS thì điền số "0" và ghi "Chưa triển khai"'
      : null,
    guideForAddable: isResultPlan
      ? 'Với các sở chưa triển khai trên LMS thì điền số "0" và nhấn vào dấu "+" rồi ghi "Chưa triển khai"'
      : null,
  },
  {
    [dataKey.id]: '3',
    [dataKey.tt]: '1.3',
    [dataKey.action]:
      'Tiếp nhận danh sách GVPT/ CBQLCSGDPT  được phân công phụ trách mô đun 5 (nếu có)',
    [dataKey.result]: `${
      KEY_MAPPING.number
    } GV/CBQLCSGDPT đại trà (điền số lượng do sở GDĐT phân công)`,
    [dataKey.finalResult]: `${
      KEY_MAPPING.number
    } GV/CBQLCSGDP đại trà được SGD/PGD phân công.`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: () => {
          return shouldDisabledByTrainingPlanModule(
            trainingPlanModules.MODULE_5_COTCAN,
            module,
            isResultPlan,
            passedTrainingPlanCategories,
          );
        },
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_5_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    addAble: isResultPlan,
    addAbleTooltip: isResultPlan
      ? 'Với các sở chưa triển khai trên LMS thì điền số "0" và ghi "Chưa triển khai". Kết quả hỗ trợ mô đun khác theo kế hoạch của sở năm 2021.'
      : null,
    guideForAddable: isResultPlan
      ? 'Với các sở chưa triển khai trên LMS thì điền số "0" và nhấn vào dấu "+"  rồi ghi "Chưa triển khai" \n\n' +
        'Kết quả hỗ trợ mô đun khác theo kế hoạch của sở năm 2021. (Giáo viên thêm bằng cách nhấn vào dấu "+" phía dưới)'
      : null,
  },
  !isResultPlan && {
    [dataKey.id]: '4',
    [dataKey.tt]: '1.4',
    [dataKey.action]:
      'Tiếp nhận danh sách GVPT/ CBQLCSGDPT  được phân công phụ trách mô đun 9 (nếu có)',
    [dataKey.result]: `${
      KEY_MAPPING.number
    } GV/CBQLCSGDPT đại trà (điền số lượng do sở GDĐT phân công)`,
    [dataKey.finalResult]: `${
      KEY_MAPPING.number
    } GV/CBQLCSGDP đại trà được SGD/PGD phân công.`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: () => {
          return shouldDisabledByTrainingPlanModule(
            trainingPlanModules.MODULE_9_COTCAN,
            module,
            isResultPlan,
            passedTrainingPlanCategories,
          );
        },
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_9_COTCAN}-total_members_learned`,
          );
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          required: false,
        },
      },
    },
  },
  {
    [dataKey.id]: '5',
    [dataKey.tt]: '1.5',
    [dataKey.action]:
      'Hỗ trợ đồng nghiệp hoàn thiện thông tin đăng ký tự học mô đun 3 trên hệ thống LMS',
    [dataKey.result]: `${
      KEY_MAPPING.number
    } GVPT/CBQLCSGDPT đại trà được cấp quyền tham gia học tập trên LMS của Viettel hoàn thành thông tin đăng ký tự học trên Hệ thống LMS, truy cập học liệu mô đun 3 thành công hoặc/và nhận được tài liệu bản in (đối với GVPT/CBQLCSGDPT ở vùng khó tiếp cận CNTT); (điền số lượng. Lưu ý: số lượng GVPT/CBQLCSGDPT được cấp quyền tham gia học tập có thể nhỏ hơn số lượng GVPT/CBQLCSGDPT được phân công, hoặc chưa có, tùy theo việc Sở ký kết thỏa thuận với nhà cung ứng LMS)`,
    [dataKey.finalResult]: `(${KEY_MAPPING.number}/${
      KEY_MAPPING.number
    }) GVPT/CBQLCSGDPT đại trà được cấp quyền tham gia học tập trên LMS của Viettel hoàn thành thông tin đăng ký tự học trên Hệ thống LMS, truy cập học liệu mô đun 3 thành công hoặc/và nhận được tài liệu bản in (đối với GVPT/CBQLCSGDPT ở vùng khó tiếp cận CNTT);`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataByKey(trainingPlanModules.MODULE_3_COTCAN),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `5-finalResult-1`),
            get(formData, `5-finalResult-2`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_3_COTCAN}-total_members_registered`,
          );
        },
        2: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_3_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(trainingPlanModules.MODULE_3_COTCAN),
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('5-finalResult-2'),
        },
      },
    },
  },
  {
    [dataKey.id]: '6',
    [dataKey.tt]: '1.6',
    [dataKey.action]:
      'Hỗ trợ đồng nghiệp hoàn thiện thông tin đăng ký tự học mô đun 4 (tùy theo lựa chọn) trên hệ thống LMS',
    [dataKey.result]: `${
      KEY_MAPPING.number
    } GVPT/CBQLCSGDPT đại trà được cấp quyền tham gia học tập trên LMS của Viettel đăng ký tự học, truy cập học liệu mô đun 4 thành công hoặc/ và nhận được tài liệu bản in (đối với GVPT/CBQLCSGDPT ở vùng khó tiếp cận CNTT); (điền số lượng).`,
    [dataKey.finalResult]: `(${KEY_MAPPING.number}/${
      KEY_MAPPING.number
    }) GVPT/CBQLCSGDPT đại trà được cấp quyền tham gia học tập trên LMS của Viettel đăng ký tự học, truy cập học liệu mô đun 4 thành công hoặc/ và nhận được tài liệu bản in (đối với GVPT/CBQLCSGDPT ở vùng khó tiếp cận CNTT);`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataByKey(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `6-finalResult-1`),
            get(formData, `6-finalResult-2`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_4_COTCAN}-total_members_registered`,
          );
        },
        2: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_4_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(trainingPlanModules.MODULE_4_COTCAN),
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('6-finalResult-2'),
        },
      },
    },
  },
  {
    [dataKey.id]: '7',
    [dataKey.tt]: '1.7',
    [dataKey.action]:
      'Hỗ trợ đồng nghiệp hoàn thiện thông tin đăng ký tự học mô đun 5 (nếu có) trên hệ thống LMS',
    [dataKey.result]: `${
      KEY_MAPPING.number
    } GVPT/CBQLCSGDPT đại trà được cấp quyền tham gia học tập trên LMS của Viettel đăng ký tự học, truy cập học liệu mô đun 5 thành công hoặc/ và nhận được tài liệu bản in (đối với GVPT/CBQLCSGDPT ở vùng khó tiếp cận CNTT); (điền số lượng).`,
    [dataKey.finalResult]: `(${KEY_MAPPING.number}/${
      KEY_MAPPING.number
    }) GVPT/CBQLCSGDPT đại trà được cấp quyền tham gia học tập trên LMS của Viettel đăng ký tự học, truy cập học liệu mô đun 5 thành công hoặc/ và nhận được tài liệu bản in (đối với GVPT/CBQLCSGDPT ở vùng khó tiếp cận CNTT); `,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataByKey(trainingPlanModules.MODULE_5_COTCAN),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `7-finalResult-1`),
            get(formData, `7-finalResult-2`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_5_COTCAN}-total_members_registered`,
          );
        },
        2: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_5_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(trainingPlanModules.MODULE_5_COTCAN),
        },
        [dataKey.finalResult]: {
          1: {
            max: getDataByKey('7-finalResult-2'),
          },
        },
      },
    },
    addAble: isResultPlan,
    addAbleTooltip: isResultPlan
      ? 'Kết quả hỗ trợ mô đun khác theo kế hoạch của sở năm 2021.'
      : null,
    guideForAddable: isResultPlan
      ? 'Kết quả hỗ trợ mô đun khác theo kế hoạch của sở năm 2021. (Giáo viên thêm bằng cách nhấn vào dấu "+" phía dưới)'
      : null,
  },
  !isResultPlan && {
    [dataKey.id]: '8',
    [dataKey.tt]: '1.8',
    [dataKey.action]:
      'Hỗ trợ đồng nghiệp hoàn thiện thông tin đăng ký tự học mô đun 9 (nếu có) trên hệ thống LMS',
    [dataKey.result]: `${
      KEY_MAPPING.number
    } GVPT/CBQLCSGDPT đại trà được cấp quyền tham gia học tập trên LMS của Viettel đăng ký tự học, truy cập học liệu mô đun 9 thành công hoặc/ và nhận được tài liệu bản in (đối với GVPT/CBQLCSGDPT ở vùng khó tiếp cận CNTT); (điền số lượng).`,
    [dataKey.finalResult]: `(${KEY_MAPPING.number}/${
      KEY_MAPPING.number
    }) GVPT/CBQLCSGDPT đại trà được cấp quyền tham gia học tập trên LMS của Viettel đăng ký tự học, truy cập học liệu mô đun 9 thành công hoặc/ và nhận được tài liệu bản in (đối với GVPT/CBQLCSGDPT ở vùng khó tiếp cận CNTT);`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataByKey(trainingPlanModules.MODULE_9_COTCAN),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `8-finalResult-1`),
            get(formData, `8-finalResult-2`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_9_COTCAN}-total_members_registered`,
          );
        },
        2: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_9_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(trainingPlanModules.MODULE_9_COTCAN),
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('8-finalResult-2'),
        },
      },
    },
  },
];

const developmentLearning = (
  module,
  isResultPlan,
  passedTrainingPlanCategories,
) => [
  {
    [dataKey.id]: '9',
    [dataKey.tt]: '2',
    [dataKey.action]:
      'Triển khai học tập: Hỗ trợ đồng nghiệp tự học mô đun 3, mô đun 4, mô đun 5 (nếu có) và mô đun 9 (nếu có)',
    colSpan: 4,
  },
  {
    [dataKey.id]: '10',
    [dataKey.tt]: '2.1',
    [dataKey.action]:
      'Hỗ trợ trên hệ thống LMS của Viettel: Thảo luận, góp ý, bài tập, nhắc hoàn thành BT quá trình, cuối khóa,  khảo sát, trao đổi với giảng viên sư phạm, các trao đổi, hỗ trợ khác ngoài việc hoàn thành mô đun trên hệ thống học tập\n' +
      '(Ghi rõ tên hoạt động hỗ trợ trực tuyến, cần chèn thêm các dòng phụ)',
    [dataKey.result]:
      `${
        KEY_MAPPING.number
      } GVPT/CBQLCSGDPT được tham gia các lớp học ảo, tham gia thảo luận trực tuyến trên hệ thống LMS của Viettel với sự hỗ trợ của đội ngũ cốt cán; (điền số lượng được cấp quyền tham gia học tập trên hệ thống LMS)\n` +
      ` ${
        KEY_MAPPING.number
      }% các thắc mắc của GVPT/ CBQLCSGDPT được GVPTCC/CBQLCSGDPTCC giải đáp trong tuần với chất lượng chuyên môn cao.\n` +
      `${KEY_MAPPING.number}% thắc mắc (${
        KEY_MAPPING.number
      } thắc mắc ước tính) được GVSPCC/ GVQLGDCC giải đáp trong tuần (Đội ngũ cốt cán, trong trường hợp không thể giải đáp thắc mắc của GVPT/ CBQLCSGDPT, cần chuyển để nhận được sự hỗ trợ từ giảng viên sư phạm để đảm bảo 100% các thắc mắc của GVPT/CBQLCSGDPT được phân công được giải đáp trong tuần).
`,
    [dataKey.finalResult]:
      `(${KEY_MAPPING.number}/${
        KEY_MAPPING.number
      }) GVPT/CBQLCSGDPT được tham gia các lớp học ảo, tham gia thảo luận trực tuyến trên hệ thống LMS của Viettel với sự hỗ trợ của đội ngũ cốt cán; (điền số lượng được cấp quyền tham gia học tập trên hệ thống LMS)\n` +
      `${
        KEY_MAPPING.number
      }% các thắc mắc của GVPT/ CBQLCSGDPT được GVPTCC/CBQLCSGDPTCC giải đáp trong tuần với chất lượng chuyên môn cao.\n` +
      `(${KEY_MAPPING.number}/${
        KEY_MAPPING.number
      }) thắc mắc được GVSPCC/ GVQLGDCC giải đáp trong tuần (Đội ngũ cốt cán, trong trường hợp không thể giải đáp thắc mắc của GVPT/ CBQLCSGDPT, cần chuyển để nhận được sự hỗ trợ từ giảng viên sư phạm để đảm bảo 100% các thắc mắc của GVPT/CBQLCSGDPT được phân công được giải đáp trong tuần).`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    addAble: 1,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        3: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        4: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        3: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        4: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        5: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataDevelopmentLearning(module),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `10-finalResult-1`),
            get(formData, `10-finalResult-2`),
          ),
        4: (value, formData) =>
          getDivisionString(
            get(formData, `10-finalResult-4`),
            get(formData, `10-finalResult-5`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(formData, 'total_members_learned');
        },
        2: (formData) => {
          return get(formData, 'total_members_assigned');
        },
        3: (formData) => {
          return get(formData, 'total_question_replied_rate');
        },
        4: (formData) => {
          return get(formData, 'total_question_master_replied');
        },
        5: (formData) => {
          return get(formData, 'total_question_asked_master');
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(module),
        },
        2: {
          max: 100,
        },
        3: {
          max: 100,
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('10-finalResult-2'),
        },
        3: {
          max: 100,
        },
        4: {
          max: getDataByKey('10-finalResult-5'),
        },
      },
    },
  },
  {
    [dataKey.id]: '11',
    [dataKey.tt]: '2.2',
    [dataKey.action]:
      'Các hoạt động hỗ trợ trực truyến khác, giải đáp các thắc mắc về chuyên môn trong các diễn đàn trực tuyến, các nhóm group chat, zalo, trao đổi qua email, các lớp học ảo…, với sự hỗ trợ của đội ngũ cốt cán.   \n' +
      '(Ghi rõ tên hoạt động hỗ trợ trực tuyến khác, không trên hệ thống LMS của Viettel, cần chèn thêm các dòng phụ)\n',
    [dataKey.result]:
      `${
        KEY_MAPPING.number
      } GVPT/CBQLCSGDPT được tham gia các hoạt động trực truyến khác, được giải đáp các thắc mắc về chuyên môn trong các diễn đàn trực tuyến, các nhóm group chat, zalo, trao đổi qua email, các lớp học ảo…, với sự hỗ trợ của đội ngũ cốt cán. (Điền số lượng GVPT/CBQLCSGDPT được phân công hỗ trợ).\n` +
      `${KEY_MAPPING.number}% thắc mắc (${
        KEY_MAPPING.number
      } thắc mắc ước tính) của GVPT/ CBQLCSGDPT được GVPTCC/CB QLCSGDPTCC giải đáp trong tuần với chất lượng chuyên môn cao.` +
      `${KEY_MAPPING.number}% thắc mắc (${
        KEY_MAPPING.number
      }điền số lượng thắc mắc ước tính) được GVSPCC/ GVQLGDCC giải đáp trong tuần (Đội ngũ cốt cán, trong trường hợp không thể giải đáp thắc mắc của GVPT/ CBQLCSGDPT, cần chuyển để nhận được sự hỗ trợ từ giảng viên sư phạm để đảm bảo 100% các thắc mắc của GVPT/ CBQLCSGDPT được phân công được giải đáp trong tuần).
`,
    [dataKey.finalResult]:
      `${
        KEY_MAPPING.number
      }% GVPT/CBQLCSGDPT được tham gia các hoạt động trực truyến khác, được giải đáp các thắc mắc về chuyên môn trong các diễn đàn trực tuyến, các nhóm group chat, zalo, trao đổi qua email, các lớp học ảo…, với sự hỗ trợ của đội ngũ cốt cán; (Điền số lượng GVPT/CBQLCS GDPT được phân công hỗ trợ).\n` +
      `(${KEY_MAPPING.number}/${
        KEY_MAPPING.number
      }) các thắc mắc  của GVPT/ CBQLCSGDPT được GVPTCC/CB QLCSGDPTCC giải đáp trong tuần với chất lượng chuyên môn cao.\n` +
      `(${KEY_MAPPING.number}/${
        KEY_MAPPING.number
      }) thắc mắc được GVSPCC/ GVQLGDCC giải đáp trong tuần (Đội ngũ cốt cán, trong trường hợp không thể giải đáp thắc mắc của GVPT/ CBQLCSGDPT, cần chuyển để nhận được sự hỗ trợ từ giảng viên sư phạm để đảm bảo 100% các thắc mắc của GVPT/ CBQLCSGDPT được phân công được giải đáp trong tuần).`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    addAble: 1,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        3: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        4: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        5: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        3: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        4: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        5: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataDevelopmentLearning(module),
      },
      [dataKey.finalResult]: {
        2: (value, formData) =>
          getDivisionString(
            get(formData, `11-finalResult-2`),
            get(formData, `11-finalResult-3`),
          ),
        4: (value, formData) =>
          getDivisionString(
            get(formData, `11-finalResult-4`),
            get(formData, `11-finalResult-5`),
          ),
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(module),
        },
        2: {
          max: 100,
        },
        4: {
          max: 100,
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: 100,
        },
        2: {
          max: getDataByKey('11-finalResult-3'),
        },
        4: {
          max: getDataByKey('11-finalResult-5'),
        },
      },
    },
  },
  {
    [dataKey.id]: '12',
    [dataKey.tt]: '2.3',
    [dataKey.action]:
      'Hỗ trợ trực tiếp: sinh hoạt chuyên môn/cụm trường (bao gồm cả các hỗ trợ liên quan đến quá trình học tập mô đun và các hỗ trợ phát triển nghề nghiệp tại chỗ, liên tục khác trong năm)\n' +
      '(Ghi rõ tên hoạt động, có thể chèn thêm các dòng phụ)\n',
    [dataKey.result]:
      `${
        KEY_MAPPING.number
      } GVPT/CBQLCSGDPT được tham gia các hoạt động chuyên môn trực trực tiếp: sinh hoạt chuyên môn, giải đáp thắc mắc trực tiếp, dự giờ - sinh hoạt chuyên môn, trao đổi chuyên môn khác, với sự hỗ trợ của đội ngũ cốt cán. (Điền số lượng GVPT/CBQLCSGDPT được phân công hỗ trợ).\n` +
      `${KEY_MAPPING.number}% thắc mắc (${
        KEY_MAPPING.number
      } thắc mắc ước tính) của GVPT/ CBQLCSGDPT được GVPTCC/CB QLCSGDPTCC giải đáp trong tuần với chất lượng chuyên môn cao.\n` +
      `${KEY_MAPPING.number}% thắc mắc (${
        KEY_MAPPING.number
      }điền số lượng thắc mắc ước tính) được GVSPCC/ GVQLGDCC giải đáp trong tuần (Đội ngũ cốt cán, trong trường hợp không thể giải đáp thắc mắc của GVPT/ CBQLCSGDPT, cần chuyển để nhận được sự hỗ trợ từ giảng viên sư phạm để đảm bảo 100% các thắc mắc của GVPT/ CBQLCSGDPT được phân công được giải đáp trong tuần).`,
    [dataKey.finalResult]:
      `(${KEY_MAPPING.number}/${
        KEY_MAPPING.number
      }) GVPT/CBQLCSGDPT được tham gia các hoạt động chuyên môn trực trực tiếp: sinh hoạt chuyên môn, giải đáp thắc mắc trực tiếp, dự giờ - sinh hoạt chuyên môn, trao đổi chuyên môn khác, với sự hỗ trợ của đội ngũ cốt cán; (Điền số lượng GVPT/CBQLCSGDPT được phân công hỗ trợ).\n` +
      `(${KEY_MAPPING.number}/${
        KEY_MAPPING.number
      }) các thắc mắc của GVPT/ CBQLCSGDPT được GVPTCC/CB QLCSGDPTCC giải đáp trong tuần với chất lượng chuyên môn cao.\n` +
      `(${KEY_MAPPING.number}/${
        KEY_MAPPING.number
      }) thắc mắc được GVSPCC/ GVQLGDCC giải đáp trong tuần (Đội ngũ cốt cán, trong trường hợp không thể giải đáp thắc mắc của GVPT/ CBQLCSGDPT, cần chuyển để nhận được sự hỗ trợ từ giảng viên sư phạm để đảm bảo 100% các thắc mắc của GVPT/ CBQLCSGDPT được phân công được giải đáp trong tuần).`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    addAble: 1,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        3: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        4: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        5: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        3: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        4: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        5: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        6: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataDevelopmentLearning(module),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `12-finalResult-1`),
            get(formData, `12-finalResult-2`),
          ),
        3: (value, formData) =>
          getDivisionString(
            get(formData, `12-finalResult-3`),
            get(formData, `12-finalResult-4`),
          ),
        5: (value, formData) =>
          getDivisionString(
            get(formData, `12-finalResult-5`),
            get(formData, `12-finalResult-6`),
          ),
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(module),
        },
        2: {
          max: 100,
        },
        4: {
          max: 100,
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('12-finalResult-2'),
        },
        3: {
          max: getDataByKey('12-finalResult-4'),
        },
        5: {
          max: getDataByKey('12-finalResult-6'),
        },
      },
    },
  },
];

const assessmentResult = (
  module,
  isResultPlan,
  passedTrainingPlanCategories,
) => [
  {
    [dataKey.id]: '13',
    [dataKey.tt]: '3',
    [dataKey.action]: 'Đánh giá kết quả học tập mô đun bồi dưỡng',
    colSpan: 4,
  },
  {
    [dataKey.id]: '14',
    [dataKey.tt]: '3.1',
    [dataKey.action]:
      'Đôn đốc, hỗ trợ  GVPT/CBQLCSGDPT hoàn thành bài kiểm tra trắc nghiệm mô đun 3',
    [dataKey.result]: `${
      KEY_MAPPING.number
    } GVPT/CBQLCSGDPT (điền số lượng tham gia học tập trên hệ thống LMS của Viettel) hoàn thành bài kiểm tra trắc nghiệm mô đun. `,
    [dataKey.finalResult]: `(${KEY_MAPPING.number}/${
      KEY_MAPPING.number
    }) GVPT/CBQLCSGDPT  hoàn thành bài kiểm tra trắc nghiệm mô đun;`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataByKey(trainingPlanModules.MODULE_3_COTCAN),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `14-finalResult-1`),
            get(formData, `14-finalResult-2`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_3_COTCAN}-total_members_passed_mc`,
          );
        },
        2: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_3_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(trainingPlanModules.MODULE_3_COTCAN),
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('14-finalResult-2'),
        },
      },
    },
  },
  {
    [dataKey.id]: '15',
    [dataKey.tt]: '3.2',
    [dataKey.action]: 'Chấm bài tập hoàn thành mô đun 3',
    [dataKey.result]:
      `${
        KEY_MAPPING.number
      } bài tập hoàn thành mô đun được chấm (điền số lượng bằng với số lượng GVPT/CBQLCSGDPT tham gia học tập mô đun).\n` +
      `GVSPCC/GVQLGDCC nhận xét cách chấm 3 bài hoàn thành mô đun của mỗi GVPTCC/CBQLCSGDPTCC chịu trách nhiệm hướng dẫn.\n` +
      `*Chú ý: GVSPCC/GVQLGDCC Không làm thay đổi kết quả chấm bài của GVPTCC/CB QLCSGDPTCC, không phê duyệt kết quả hoàn thành mô đun bồi dưỡng của GVPT/CBQLCSGDPT`,
    [dataKey.finalResult]:
      `(${KEY_MAPPING.number}/${
        KEY_MAPPING.number
      }) bài tập hoàn thành mô đun được chấm.\n` +
      `(${KEY_MAPPING.number}/${
        KEY_MAPPING.number
      }) GVSPCC/GVQLGDCC nhận xét cách chấm 3 bài hoàn thành mô đun của mỗi GVPTCC/CBQLCSGDPTCC chịu trách nhiệm hướng dẫn. `,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,

    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        3: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        4: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataByKey(trainingPlanModules.MODULE_3_COTCAN),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `15-finalResult-1`),
            get(formData, `15-finalResult-2`),
          ),
        3: (value, formData) =>
          getDivisionString(
            get(formData, `15-finalResult-3`),
            get(formData, `15-finalResult-4`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_3_COTCAN}-total_members_marked`,
          );
        },
        2: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_3_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(trainingPlanModules.MODULE_3_COTCAN),
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('15-finalResult-2'),
        },
        3: {
          max: getDataByKey('15-finalResult-4'),
        },
      },
    },
  },
  {
    [dataKey.id]: '16',
    [dataKey.tt]: '3.3',
    [dataKey.action]:
      'Đôn đốc, hỗ trợ  GVPT/ CBQLCSGDPT hoàn thành bài kiểm tra trắc nghiệm mô đun 4',
    [dataKey.result]: `${
      KEY_MAPPING.number
    } GVPT/CBQLCSGDPT (điền số lượng tham gia học tập trên hệ thống LMS của Viettel) hoàn thành bài kiểm tra trắc nghiệm .`,
    [dataKey.finalResult]: `(${KEY_MAPPING.number}/${
      KEY_MAPPING.number
    }) GVPT/CBQLCSGDPT hoàn thành bài kiểm tra trắc nghiệm mô đun;`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataByKey(trainingPlanModules.MODULE_4_COTCAN),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `16-finalResult-1`),
            get(formData, `16-finalResult-2`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_4_COTCAN}-total_members_passed_mc`,
          );
        },
        2: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_4_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(trainingPlanModules.MODULE_4_COTCAN),
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('16-finalResult-2'),
        },
      },
    },
  },
  {
    [dataKey.id]: '17',
    [dataKey.tt]: '3.4',
    [dataKey.action]: 'Chấm bài tập hoàn thành mô đun 4',
    [dataKey.result]:
      `${
        KEY_MAPPING.number
      } bài tập hoàn thành mô đun được chấm (điền số lượng bằng với số lượng GVPT/CBQLCSGDPT tham gia học tập mô đun).\n` +
      `GVSPCC/GVQLGDCC nhận xét cách chấm 3 bài hoàn thành mô đun của mỗi GVPTCC/CBQLCSGDPTCC chịu trách nhiệm hướng dẫn.\n ` +
      `*Chú ý: GVSPCC/GVQLGDCC không làm thay đổi kết quả chấm bài của GVPTCC/CB QLCSGDPTCC, không phê duyệt kết quả hoàn thành mô đun bồi dưỡng của GVPT/CBQLCSGDPT.`,
    [dataKey.finalResult]:
      `(${KEY_MAPPING.number}/${
        KEY_MAPPING.number
      }) bài tập hoàn thành mô đun được chấm.\n` +
      `(${KEY_MAPPING.number}/${
        KEY_MAPPING.number
      }) GVSPCC/GVQLGDCC nhận xét cách chấm 3 bài hoàn thành mô đun của mỗi GVPTCC/CBQLCSGDPTCC chịu trách nhiệm hướng dẫn. `,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        3: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        4: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataByKey(trainingPlanModules.MODULE_4_COTCAN),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `17-finalResult-1`),
            get(formData, `17-finalResult-2`),
          ),
        3: (value, formData) =>
          getDivisionString(
            get(formData, `17-finalResult-3`),
            get(formData, `17-finalResult-4`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_4_COTCAN}-total_members_marked`,
          );
        },
        2: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_4_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(trainingPlanModules.MODULE_4_COTCAN),
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('17-finalResult-2'),
        },
        3: {
          max: getDataByKey('17-finalResult-4'),
        },
      },
    },
  },
  {
    [dataKey.id]: '18',
    [dataKey.tt]: '3.5',
    [dataKey.action]:
      'Đôn đốc, hỗ trợ  GVPT/ CBQLCSGDPT hoàn thành bài kiểm tra trắc nghiệm mô đun 5 (nếu có)',
    [dataKey.result]: `${
      KEY_MAPPING.number
    } GVPT/CBQLCSGDPT (điền số lượng tham gia học tập trên hệ thống LMS của Viettel) hoàn thành bài kiểm tra trắc nghiệm mô đun`,
    [dataKey.finalResult]: `(${KEY_MAPPING.number}/${
      KEY_MAPPING.number
    }) GVPT/CBQLCSGDPT  hoàn thành bài kiểm tra trắc nghiệm mô đun;`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataByKey(trainingPlanModules.MODULE_5_COTCAN),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `18-finalResult-1`),
            get(formData, `18-finalResult-2`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_5_COTCAN}-total_members_passed_mc`,
          );
        },
        2: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_5_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(trainingPlanModules.MODULE_5_COTCAN),
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('18-finalResult-2'),
        },
      },
    },
  },
  {
    [dataKey.id]: '19',
    [dataKey.tt]: '3.6',
    [dataKey.action]: 'Chấm bài tập hoàn thành mô đun 5 (nếu có)',
    [dataKey.result]:
      `${
        KEY_MAPPING.number
      } bài tập hoàn thành mô đun được chấm (điền số lượng bằng với số lượng GVPT/CBQLCSGDPT tham gia học tập mô đun).\n` +
      `GVSPCC/GVQLGDCC nhận xét cách chấm 3 bài hoàn thành mô đun của mỗi GVPTCC/CBQLCSGDPTCC chịu trách nhiệm hướng dẫn.\n ` +
      `*Chú ý: GVSPCC/GVQLGDCC không làm thay đổi kết quả chấm bài của GVPTCC/CB QLCSGDPTCC, không phê duyệt kết quả hoàn thành mô đun bồi dưỡng của GVPT/CBQLCSGDPT.`,
    [dataKey.finalResult]:
      `(${KEY_MAPPING.number}/${
        KEY_MAPPING.number
      }) bài tập hoàn thành mô đun được chấm.\n` +
      `(${KEY_MAPPING.number}/${
        KEY_MAPPING.number
      }) GVSPCC/GVQLGDCC nhận xét cách chấm 3 bài hoàn thành mô đun của mỗi GVPTCC/CBQLCSGDPTCC chịu trách nhiệm hướng dẫn. `,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        3: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        4: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataByKey(trainingPlanModules.MODULE_5_COTCAN),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `19-finalResult-1`),
            get(formData, `19-finalResult-2`),
          ),
        3: (value, formData) =>
          getDivisionString(
            get(formData, `19-finalResult-3`),
            get(formData, `19-finalResult-4`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_5_COTCAN}-total_members_marked`,
          );
        },
        2: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_5_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(trainingPlanModules.MODULE_5_COTCAN),
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('19-finalResult-2'),
        },
        3: {
          max: getDataByKey('19-finalResult-4'),
        },
      },
    },
    addAble: isResultPlan,
    addAbleTooltip: isResultPlan
      ? 'Kết quả hỗ trợ mô đun khác theo kế hoạch của sở năm 2021.'
      : null,
    guideForAddable: isResultPlan
      ? 'Kết quả hỗ trợ mô đun khác theo kế hoạch của sở năm 2021. (Giáo viên thêm bằng cách nhấn vào dấu "+" phía dưới)'
      : null,
  },
  !isResultPlan && {
    [dataKey.id]: '20',
    [dataKey.tt]: '3.7',
    [dataKey.action]:
      'Đôn đốc, hỗ trợ  GVPT/ CBQLCSGDPT hoàn thành bài kiểm tra trắc nghiệm mô đun 9 (nếu có)',
    [dataKey.result]: `${
      KEY_MAPPING.number
    } GVPT/CBQLCSGDPT (điền số lượng tham gia học tập trên hệ thống LMS) hoàn thành bài kiểm tra trắc nghiệm mô đun. `,
    [dataKey.finalResult]: `(${KEY_MAPPING.number}/${
      KEY_MAPPING.number
    }) GVPT/CBQLCSGDPT  hoàn thành bài kiểm tra trắc nghiệm mô đun;`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataByKey(trainingPlanModules.MODULE_9_COTCAN),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `20-finalResult-1`),
            get(formData, `20-finalResult-2`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_9_COTCAN}-total_members_passed_mc`,
          );
        },
        2: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_9_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(trainingPlanModules.MODULE_9_COTCAN),
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('20-finalResult-2'),
        },
      },
    },
  },
  !isResultPlan && {
    [dataKey.id]: '21',
    [dataKey.tt]: '3.8',
    [dataKey.action]: 'Chấm bài tập hoàn thành mô đun 9 (nếu có)',
    [dataKey.result]:
      `${
        KEY_MAPPING.number
      } bài tập hoàn thành mô đun được chấm (điền số lượng bằng với số lượng GVPT/CBQLCSGDPT tham gia học tập mô đun).\n` +
      `GVSPCC/GVQLGDCC nhận xét cách chấm 3 bài hoàn thành mô đun của mỗi GVPTCC/CBQLCSGDPTCC chịu trách nhiệm hướng dẫn.\n ` +
      `*Chú ý: GVSPCC/GVQLGDCC không làm thay đổi kết quả chấm bài của GVPTCC/CB QLCSGDPTCC, không phê duyệt kết quả hoàn thành mô đun bồi dưỡng của GVPT/CBQLCSGDPT.`,
    [dataKey.finalResult]:
      `(${KEY_MAPPING.number}/${
        KEY_MAPPING.number
      }) bài tập hoàn thành mô đun được chấm.\n` +
      `(${KEY_MAPPING.number}/${
        KEY_MAPPING.number
      }) GVSPCC/GVQLGDCC nhận xét cách chấm 3 bài hoàn thành mô đun của mỗi GVPTCC/CBQLCSGDPTCC chịu trách nhiệm hướng dẫn.`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        3: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        4: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataByKey(trainingPlanModules.MODULE_9_COTCAN),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `21-finalResult-1`),
            get(formData, `21-finalResult-2`),
          ),
        3: (value, formData) =>
          getDivisionString(
            get(formData, `21-finalResult-3`),
            get(formData, `21-finalResult-4`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_9_COTCAN}-total_members_marked`,
          );
        },
        2: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_9_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(trainingPlanModules.MODULE_9_COTCAN),
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('21-finalResult-2'),
        },
        3: {
          max: getDataByKey('21-finalResult-4'),
        },
      },
    },
  },
];

const askToDoSurvey = (module, isResultPlan, passedTrainingPlanCategories) => [
  {
    [dataKey.id]: '22',
    [dataKey.tt]: '4',
    [dataKey.action]:
      'Đôn đốc GVPT/ CBQLCSGDPT trả lời phiếu khảo sát về mô đun bồi dưỡng',
    colSpan: 4,
  },
  {
    [dataKey.id]: '23',
    [dataKey.tt]: '4.1',
    [dataKey.action]:
      'Đôn đốc, hỗ trợ GVPT/CBQLCSGDPT hoàn thành phiếu khảo sát cuối mô đun 3',
    [dataKey.result]: `${
      KEY_MAPPING.number
    } (điền số lượng hoàn thành mô đun bồi dưỡng) GVPT/ CBQLCSGDPT hoàn thành khảo sát cuối mô đun 3.`,
    [dataKey.finalResult]: `(${KEY_MAPPING.number}/${
      KEY_MAPPING.number
    }) GVPT/ CBQLCSGDPT hoàn thành khảo sát cuối mô đun 3;`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataByKey(trainingPlanModules.MODULE_3_COTCAN),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `23-finalResult-1`),
            get(formData, `23-finalResult-2`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${
              trainingPlanModules.MODULE_3_COTCAN
            }-total_members_finished_survey`,
          );
        },
        2: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_3_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(trainingPlanModules.MODULE_3_COTCAN),
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('23-finalResult-2'),
        },
      },
    },
  },
  {
    [dataKey.id]: '24',
    [dataKey.tt]: '4.2',
    [dataKey.action]:
      'Đôn đốc, hỗ trợ GVPT/CBQLCSGDPT hoàn thành phiếu khảo sát cuối mô đun 4',
    [dataKey.result]: `${
      KEY_MAPPING.number
    } (điền số lượng hoàn thành mô đun bồi dưỡng) GVPT/ CBQLCSGDPT hoàn thành khảo sát cuối mô đun 4.`,
    [dataKey.finalResult]: `(${KEY_MAPPING.number}/${
      KEY_MAPPING.number
    }) GVPT/ CBQLCSGDPT hoàn thành khảo sát cuối mô đun 4;`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataByKey(trainingPlanModules.MODULE_4_COTCAN),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `24-finalResult-1`),
            get(formData, `24-finalResult-2`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${
              trainingPlanModules.MODULE_4_COTCAN
            }-total_members_finished_survey`,
          );
        },
        2: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_4_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(trainingPlanModules.MODULE_4_COTCAN),
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('24-finalResult-2'),
        },
      },
    },
  },
  {
    [dataKey.id]: '25',
    [dataKey.tt]: '4.3',
    [dataKey.action]:
      'Đôn đốc, hỗ trợ GVPT/CBQLCSGDPT hoàn thành phiếu khảo sát cuối mô đun 5 (nếu có)',
    [dataKey.result]: `${
      KEY_MAPPING.number
    } (điền số lượng hoàn thành mô đun bồi dưỡng) GVPT/ CBQLCSGDPT hoàn thành khảo sát cuối mô đun 5.`,
    [dataKey.finalResult]: `(${KEY_MAPPING.number}/${
      KEY_MAPPING.number
    }) GVPT/ CBQLCSGDPT hoàn thành khảo sát cuối mô đun 5;`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataByKey(trainingPlanModules.MODULE_5_COTCAN),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `25-finalResult-1`),
            get(formData, `25-finalResult-2`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${
              trainingPlanModules.MODULE_5_COTCAN
            }-total_members_finished_survey`,
          );
        },
        2: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_5_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(trainingPlanModules.MODULE_5_COTCAN),
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('25-finalResult-2'),
        },
      },
    },
    addAble: isResultPlan,
    addAbleTooltip: isResultPlan
      ? 'Kết quả hỗ trợ mô đun khác theo kế hoạch của sở năm 2021.'
      : null,
    guideForAddable: isResultPlan
      ? 'Kết quả hỗ trợ mô đun khác theo kế hoạch của sở năm 2021. (Giáo viên thêm bằng cách nhấn vào dấu "+" phía dưới)'
      : null,
  },
  !isResultPlan && {
    [dataKey.id]: '26',
    [dataKey.tt]: '4.4',
    [dataKey.action]:
      'Đôn đốc, hỗ trợ GVPT/CBQLCSGDPT hoàn thành phiếu khảo sát cuối mô đun 9 (nếu có)',
    [dataKey.result]: `${
      KEY_MAPPING.number
    } (điền số lượng hoàn thành mô đun bồi dưỡng) GVPT/ CBQLCSGDPT hoàn thành khảo sát cuối mô đun 9.`,
    [dataKey.finalResult]: `(${KEY_MAPPING.number}/${
      KEY_MAPPING.number
    }) GVPT/ CBQLCSGDPT hoàn thành khảo sát cuối mô đun 9;`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataByKey(trainingPlanModules.MODULE_9_COTCAN),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `26-finalResult-1`),
            get(formData, `26-finalResult-2`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${
              trainingPlanModules.MODULE_9_COTCAN
            }-total_members_finished_survey`,
          );
        },
        2: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_9_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(trainingPlanModules.MODULE_9_COTCAN),
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('26-finalResult-2'),
        },
      },
    },
  },
  {
    [dataKey.id]: '27',
    [dataKey.tt]: '4.5',
    [dataKey.action]:
      'Đôn đốc, hỗ trợ GVPT/CBQLCSGDPT hoàn thành phiếu khảo sát về chương trình bồi dưỡng năm 2021',
    [dataKey.result]: `${
      KEY_MAPPING.number
    } (điền số lượng) GVPT/CBQLCSGDPT hoàn thành 03 mô đun BDTX năm 2021 hoàn thành Khảo sát về chương trình BDTX năm 2021`,
    [dataKey.finalResult]: `(${KEY_MAPPING.number}/${
      KEY_MAPPING.number
    }) (điền số lượng) GVPT/CBQLCS GDPT hoàn thành 03 mô đun BDTX năm 2021 hoàn thành Khảo sát về chương trình BDTX năm 2021`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
          true,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
          true,
        ),
        2: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
          true,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
          true,
        ),
        2: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
          true,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
          true,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: (value, formData) =>
          getDivisionString(value, geMinValueOfModules(formData)),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `27-finalResult-1`),
            get(formData, `27-finalResult-2`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(formData, 'total_members_finished_survey');
        },
        2: (formData) => {
          return get(formData, 'total_members_assigned');
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: (formData) => geMinValueOfModules(formData),
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('27-finalResult-2'),
        },
      },
    },
  },
];

const peerCheckingFinishMDBD = (
  module,
  isResultPlan,
  passedTrainingPlanCategories,
) => [
  {
    [dataKey.id]: '28',
    [dataKey.tt]: '5',
    [dataKey.action]:
      'Xác nhận đồng nghiệp hoàn thành mô đun bồi dưỡng trên hệ thống LMS',
    colSpan: 4,
  },
  {
    [dataKey.id]: '29',
    [dataKey.tt]: '5.1',
    [dataKey.action]:
      'Xác nhận đồng nghiệp hoàn thành mô đun 3 trên hệ thống LMS',
    [dataKey.result]: `${
      KEY_MAPPING.number
    } (điền số lượng) GVPT/CBQLCSGDPT tham gia bồi dưỡng trên hệ thống LMS của Viettel) hoàn thành mô đun (Đạt)`,
    [dataKey.finalResult]: `(${KEY_MAPPING.number}/${
      KEY_MAPPING.number
    }) GVPT/CBQLCSGDPT tham gia bồi dưỡng trên hệ thống LMS của Viettel) hoàn thành mô đun (Đạt)`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_3_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataByKey(trainingPlanModules.MODULE_3_COTCAN),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `29-finalResult-1`),
            get(formData, `29-finalResult-2`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${
              trainingPlanModules.MODULE_3_COTCAN
            }-total_members_passed_module`,
          );
        },
        2: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_3_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(trainingPlanModules.MODULE_3_COTCAN),
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('29-finalResult-2'),
        },
      },
    },
  },
  {
    [dataKey.id]: '30',
    [dataKey.tt]: '5.2',
    [dataKey.action]:
      'Xác nhận đồng nghiệp hoàn thành mô đun 4 trên hệ thống LMS',
    [dataKey.result]: `${
      KEY_MAPPING.number
    } (điền số lượng) GVPT/CBQLCSGDPT tham gia bồi dưỡng trên hệ thống LMS của Viettel) hoàn thành mô đun (Đạt)`,
    [dataKey.finalResult]: `(${KEY_MAPPING.number}/${
      KEY_MAPPING.number
    }) GVPT/ CBQLCSGDPT hoàn thành mô đun (Đạt)`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_4_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataByKey(trainingPlanModules.MODULE_4_COTCAN),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `30-finalResult-1`),
            get(formData, `30-finalResult-2`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${
              trainingPlanModules.MODULE_4_COTCAN
            }-total_members_passed_module`,
          );
        },
        2: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_4_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(trainingPlanModules.MODULE_4_COTCAN),
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('30-finalResult-2'),
        },
      },
    },
  },
  {
    [dataKey.id]: '31',
    [dataKey.tt]: '5.3',
    [dataKey.action]:
      'Xác nhận đồng nghiệp hoàn thành mô đun 5 (nếu có) trên hệ thống LMS',
    [dataKey.result]: `${
      KEY_MAPPING.number
    } (điền số lượng) GVPT/CBQLCSGDPT tham gia bồi dưỡng trên hệ thống LMS của Viettel) hoàn thành mô đun (Đạt)`,
    [dataKey.finalResult]: `(${KEY_MAPPING.number}/${
      KEY_MAPPING.number
    }) GVPT/CBQLCSGDPT tham gia bồi dưỡng trên hệ thống LMS của Viettel hoàn thành mô đun (Đạt)`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_5_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataByKey(trainingPlanModules.MODULE_5_COTCAN),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `31-finalResult-1`),
            get(formData, `31-finalResult-2`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${
              trainingPlanModules.MODULE_5_COTCAN
            }-total_members_passed_module`,
          );
        },
        2: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_5_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(trainingPlanModules.MODULE_5_COTCAN),
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('31-finalResult-2'),
        },
      },
    },
    addAble: isResultPlan,
    addAbleTooltip: isResultPlan
      ? 'Kết quả hỗ trợ mô đun khác theo kế hoạch của sở năm 2021.'
      : null,
    guideForAddable: isResultPlan
      ? 'Kết quả hỗ trợ mô đun khác theo kế hoạch của sở năm 2021. (Giáo viên thêm bằng cách nhấn vào dấu "+" phía dưới)'
      : null,
  },
  !isResultPlan && {
    [dataKey.id]: '32',
    [dataKey.tt]: '5.4',
    [dataKey.action]:
      'Xác nhận đồng nghiệp hoàn thành mô đun 9 (nếu có) trên hệ thống LMS',
    [dataKey.result]: `${
      KEY_MAPPING.number
    } (điền số lượng) GVPT/CBQLCSGDPT tham gia bồi dưỡng trên hệ thống LMS của Viettel) hoàn thành mô đun (Đạt)`,
    [dataKey.finalResult]: `(${KEY_MAPPING.number}/${
      KEY_MAPPING.number
    }) GVPT/CBQLCSGDPT tham gia bồi dưỡng trên hệ thống LMS của Viettel hoàn thành mô đun (Đạt)`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
        2: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          trainingPlanModules.MODULE_9_COTCAN,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: formatDataByKey(trainingPlanModules.MODULE_9_COTCAN),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `32-finalResult-1`),
            get(formData, `32-finalResult-2`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(
            formData,
            `${
              trainingPlanModules.MODULE_9_COTCAN
            }-total_members_passed_module`,
          );
        },
        2: (formData) => {
          return get(
            formData,
            `${trainingPlanModules.MODULE_9_COTCAN}-total_members_assigned`,
          );
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: getDataOfModule(trainingPlanModules.MODULE_9_COTCAN),
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('32-finalResult-2'),
        },
      },
    },
  },
  {
    [dataKey.id]: '33',
    [dataKey.tt]: '5.5',
    [dataKey.action]: 'Xác nhận hoàn thành 03 mô đun bồi dưỡng năm 2021',
    [dataKey.result]: `${
      KEY_MAPPING.number
    } (điền số lượng) GVPT/CBQLCSGDPT tham gia bồi dưỡng trên hệ thống LMS của Viettel hoàn thành mô đun (Đạt)`,
    [dataKey.finalResult]: `(${KEY_MAPPING.number}/${
      KEY_MAPPING.number
    }) GVPT/CBQLCSGDPT tham gia bồi dưỡng trên hệ thống LMS của Viettel hoàn thành mô đun (Đạt)`,
    [dataKey.dateTime]: `Từ ngày ${KEY_MAPPING.date} đến ngày ${
      KEY_MAPPING.date
    }`,
    [dataKey.corporate]: `${KEY_MAPPING.area}`,
    [dataKey.disabled]: {
      [dataKey.result]: {
        1: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
          true,
        ),
      },
      [dataKey.finalResult]: {
        1: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
          true,
        ),
        2: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
          true,
        ),
      },
      [dataKey.dateTime]: {
        1: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
          true,
        ),
        2: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
          true,
        ),
      },
      [dataKey.corporate]: {
        1: shouldDisabled(
          module,
          module,
          isResultPlan,
          passedTrainingPlanCategories,
          true,
        ),
      },
    },
    [dataKey.formatData]: {
      [dataKey.result]: {
        1: (value, formData) =>
          getDivisionString(value, geMinValueOfModules(formData)),
      },
      [dataKey.finalResult]: {
        1: (value, formData) =>
          getDivisionString(
            get(formData, `33-finalResult-1`),
            get(formData, `33-finalResult-2`),
          ),
      },
    },
    [dataKey.defaultValue]: {
      [dataKey.finalResult]: {
        1: (formData) => {
          return get(formData, 'total_members_passed_module');
        },
        2: (formData) => {
          return get(formData, 'total_members_assigned');
        },
      },
    },
    [dataKey.validation]: {
      [dataKey.result]: {
        1: {
          max: (formData) => geMinValueOfModules(formData),
        },
      },
      [dataKey.finalResult]: {
        1: {
          max: getDataByKey('33-finalResult-2'),
        },
      },
    },
  },
];

const dataSource = (module, isResultPlan, passedTrainingPlanCategories) =>
  [
    ...preLearn(module, isResultPlan, passedTrainingPlanCategories),
    ...developmentLearning(module, isResultPlan, passedTrainingPlanCategories),
    ...assessmentResult(module, isResultPlan, passedTrainingPlanCategories),
    ...askToDoSurvey(module, isResultPlan, passedTrainingPlanCategories),
    ...peerCheckingFinishMDBD(
      module,
      isResultPlan,
      passedTrainingPlanCategories,
    ),
  ].filter(Boolean);

export default dataSource;
