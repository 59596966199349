import './stylesheet.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Element from '../Element';
import Title from './Title';
import withFeatureFlags from 'feature-flag/withFeatureFlags';

class FieldSet extends React.PureComponent {
  hasElementHaveColClass = (schema) => {
    return Object.keys(schema).some((fieldKey) => {
      const field = schema[fieldKey] || {};
      const { classWrapper } = field;

      return (classWrapper || '').indexOf('col-') > -1;
    });
  };

  getClassWrapper = () => {
    const { xpath, wrapperClass, schema } = this.props;

    const hasElementHaveColClass = this.hasElementHaveColClass(schema);

    if (!xpath && !wrapperClass && !hasElementHaveColClass) {
      return;
    }

    let className = `${xpath ? xpath : ''}`;

    if (wrapperClass) {
      className = `${wrapperClass} ${className}`;
    }

    if (hasElementHaveColClass) {
      className = `row ${className}`;
    }

    return className.trim();
  };

  getElementClassWrapper = (schema, element) => {
    const hasElementHaveColClass = this.hasElementHaveColClass(schema);
    const hasClassWrapper = !!element.classWrapper;

    let classWrapper = '';
    if (hasElementHaveColClass) {
      classWrapper = `${classWrapper} element-default`;

      if (!hasClassWrapper) {
        classWrapper = `${classWrapper} col-md-12`;
      } else {
        classWrapper = `${classWrapper} ${element.classWrapper}`;
      }
    }

    return classWrapper;
  };

  render() {
    const {
      title,
      subTitle,
      isBlock,
      schema,
      node,
      params,
      readOnly,
      formid,
      formType,
      leftcol,
      rightcol,
      xpath,
    } = this.props;

    const klass = formType === 'horizontal' ? 'horizontal-wrapper' : '';

    if (Object.keys(schema).length === 0) {
      return null;
    }

    const hasElementHaveColClass = this.hasElementHaveColClass(schema);

    const elements = Object.keys(schema).map((fieldName) => {
      const fieldSchema = schema[fieldName];
      const elemKey = xpath ? `${xpath}-${fieldName}` : fieldName;
      const classWrapper = this.getElementClassWrapper(schema, fieldSchema);

      return (
        <Element
          key={elemKey}
          fieldSchema={{
            ...fieldSchema,
            classWrapper,
          }}
          fieldName={fieldName}
          node={node}
          params={params}
          readOnly={readOnly}
          formType={formType}
          wrapperClass={klass}
          leftcol={leftcol}
          rightcol={rightcol}
          formValues={this.props.formValues}
          formid={formid}
          xpath={xpath}
        />
      );
    });

    const className = this.getClassWrapper();

    return (
      <>
        {!isBlock && <Title title={title} subTitle={subTitle} />}

        {className ? (
          <div className={this.getClassWrapper()}>{elements}</div>
        ) : (
          <div className="row">
            <div className="col-md-12">{elements}</div>
          </div>
        )}
      </>
    );
  }
}

FieldSet.propTypes = {
  title: PropTypes.string,
  schema: PropTypes.object,
  // stepping: PropTypes.bool, // if this should be divided into steps
  // maybe stepping should be string representing which layout (horizontal or vertical...)
  // to use
  // model of the whole form
  node: PropTypes.object,
};
export default withFeatureFlags()(FieldSet);
