import React from 'react';
import { t1 } from 'translate';
import RestoreButton from '../take-histories/RestoreButton';
import { initExercise as initExerciseAction } from 'actions/learn/exercise/normal/saga-creators';
import connect from 'react-redux/es/connect/connect';

const RestoreHighestScoreTake = ({
  currentScore,
  highestScore,
  takeId,
  historyId,
  restoreSuccessful,
  searchFormId,
  initExercise,
}) => {
  const onRestoreSuccessful = () => {
    // dispatch init exercise so that new take is applied
    initExercise();

    // execute callback so parent component can close this dialog
    if (typeof restoreSuccessful === 'function') {
      restoreSuccessful();
    }
  };

  return (
    <div className={'text-center'}>
      <h3>{t1('restore_take_score_dialog_title')}</h3>
      <div>
        {t1('restore_take_score_dialog_current_score')}{' '}
        <span className={'text-danger'}>{currentScore}</span>
      </div>
      <div>
        {t1('restore_take_score_dialog__highest_score')}{' '}
        <span className={'text-success'}>{highestScore}</span>
      </div>

      <p className={'m-t-15'}>{t1('restore_take_score_dialog_description')}</p>

      <div className={'d-flex align-items-center justify-content-center'}>
        <RestoreButton
          takeId={takeId}
          historyId={historyId}
          searchFormId={searchFormId}
          restoreSuccessful={onRestoreSuccessful}
          showIcon={false}
          label={t1('ok_i_understand_restore_my_take')}
          confirmBeforeDoing={false}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch, { itemIid, learnInfo }) => ({
  initExercise() {
    dispatch(initExerciseAction(itemIid, learnInfo, true));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(RestoreHighestScoreTake);
