/* eslint-disable no-undef */
import React, { Component } from 'react';
import get from 'lodash.get';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ImportByTemplate from 'components/admin/form-import';

class ImportForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      import_id: null,
    };
  }

  render() {
    if (this.state.import_id) {
      return (
        <Redirect to={`${window.location.pathname}/${this.state.import_id}`} />
      );
    }

    const hiddenFields = get(this.props, 'node.iid')
      ? {
          import_for_item: {
            id: get(this.props, 'node.id'),
            iid: get(this.props, 'node.iid'),
            ntype: get(this.props, 'node.ntype'),
          },
        }
      : {};

    return (
      <ImportByTemplate
        hiddenFields={hiddenFields}
        formid="import-users"
        alternativeApi={'user/data/import-users-temp-to-process'}
        requestSuccessful={({ result }) => {
          this.setState({ import_id: result && result.import_id });
        }}
        downloadTemplateParams={{
          type: 'student',
          template: get(this.props, 'node.iid')
            ? 'import-users-in-node'
            : 'import-users-in-the-system',
        }}
      />
    );
  }
}

export default connect()(ImportForm);
