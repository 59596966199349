import React, { Component } from 'react';
import { t4 } from 'translate';
import lodashGet from 'lodash.get';

const StudentsCounter = ({ course }) => (
  <div title={`${t4('passed')}/${t4('failed')}`}>
    <span className="text-success">
      {lodashGet(course, 'stats.passed') || '0'}
    </span>{' '}
    /{lodashGet(course, '__expand.number_of_students', 0)}
  </div>
);

export default StudentsCounter;
