import get from 'lodash.get';
import {
  isEmptyAnswerFormValue,
  isIntro,
  isOEQuestion,
  isQuestionMarked,
} from 'common/learn/Question';
import { defaultAcceptType } from 'components/admin/question/schema/question-types';
import { stripHTML } from 'common/utils/string';

export const checkQuestionSubmitted = (question) => {
  const answer = get(question, 'answer.answer', {});
  return !isEmptyAnswerFormValue(answer);
};

export const checkQuestionMC = (question) => !isOEQuestion(question.type);

export const checkQuestionMarked = (question) => {
  const answer = get(question, 'answer.answer', []);
  const { content, wordmax, wordmin } = answer;
  let check = true;

  if (typeof content === 'string') {
    const { accept_inputs = defaultAcceptType } = question;
    accept_inputs.forEach((val, index) => {
      const tmp = get(
        answer,
        `${val == 'text' ? 'content' : val == 'file' ? 'attachments' : val}`,
      );
      if (
        val == 'text' &&
        tmp &&
        ((wordmin && stripHTML(tmp).trim().length < wordmin) ||
          (wordmax && stripHTML(tmp).trim().length > wordmax) ||
          !stripHTML(tmp).trim())
      ) {
        check = false;
      } else if ((Array.isArray(tmp) && tmp.length === 0) || !tmp) {
        check = false;
      }
    });
    return check;
  }
  if (Array.isArray(content)) {
    const { structure_answer = [] } = question;
    structure_answer.forEach((value, index) => {
      const { input_submit = [] } = value;
      input_submit.forEach((val, idx) => {
        const tmp = (content.find((v) => v.id === `[${index}]`) || {})[
          `${
            val == 'text'
              ? 'content'
              : val == 'attachment'
              ? 'attachments'
              : val
          }`
        ];
        if (
          val == 'text' &&
          tmp &&
          ((wordmin && stripHTML(tmp).trim().length < wordmin) ||
            (wordmax && stripHTML(tmp).trim().length > wordmax) ||
            !stripHTML(tmp).trim())
        ) {
          check = false;
        } else if ((Array.isArray(tmp) && tmp.length === 0) || !tmp) {
          check = false;
        }
      });
    });
    return check;
  }
  return false;
};

export const checkQuestionAutoMarkingSubmitted = (question = {}) => {
  const answer = get(question, 'answer.answer', []);
  if (typeof answer === 'object') {
    const keys = Object.keys(answer);

    return !!keys.length;
  }

  return !!answer.length;
};

export const addIndexToQuestionFromTrackingLine = (
  questionsFromTree,
  trackingLine,
  questionsWithAnswer,
) => {
  const questionResult = [];
  const allQuestionIdsInTrackingLine = (trackingLine || []).reduce(
    (accumulate, trackingLineItem) => {
      return accumulate.concat(trackingLineItem.allItems);
    },
    [],
  );

  // Hot fix for case REDO exercise with type is not Trắc nghiệm
  // TODO: Need to clear for condition check exercise is Trắc nghiệm
  const keys = Object.keys(questionsFromTree || {});
  if (!keys.length) {
    return [];
  }

  keys.forEach((key) => {
    const questionIndex = allQuestionIdsInTrackingLine.findIndex(
      (questionIdInTrackingLine) => questionIdInTrackingLine == key,
    );

    if (questionIndex >= 0) {
      questionResult.push({
        ...questionsFromTree[key],
        newIndex: questionIndex + 1,
        answer: get(questionsWithAnswer, `${key}.answer`, {}),
      });
    }
  });

  return questionResult;
};

export const getQuestionStatusInfoOfItem = (learn, tree, itemIid) => {
  const questionsFromTree = tree || {};
  const itemIidToGetInfo = itemIid || get(learn, 'itemIid');
  const questionsWithAnswer = get(
    learn,
    `info.${itemIidToGetInfo}.questions`,
    {},
  );
  const trackingLine = get(learn, `info.${itemIidToGetInfo}.trackingLine`, []);
  const questionWithIndexes = addIndexToQuestionFromTrackingLine(
    questionsFromTree,
    trackingLine,
    questionsWithAnswer,
  );

  let questionsAutoMarking = [];
  let questionsTeacherMarking = [];

  questionWithIndexes.forEach((question) => {
    if (isIntro(question)) {
      return;
    }

    if (checkQuestionMC(question)) {
      questionsAutoMarking.push(question);
    } else {
      questionsTeacherMarking.push(question);
    }
  });

  const questionsAutoMarkingSubmitted = questionsAutoMarking.filter(
    (question) => checkQuestionAutoMarkingSubmitted(question),
  );

  const autoMarking = {
    submitted: questionsAutoMarkingSubmitted.length,
    total: questionsAutoMarking.length,
  };

  const questionsTeacherMarkingSubmitted = questionsTeacherMarking.filter(
    (question) => checkQuestionSubmitted(question),
  );
  const questionsTeacherMarkingMarked = questionsTeacherMarking.filter(
    (question) => {
      return checkQuestionMarked(question);
    },
  );

  const teacherMarking = {
    submitted: questionsTeacherMarkingSubmitted.length,
    marked: questionsTeacherMarkingMarked.length,
    total: questionsTeacherMarking.length,
  };

  return {
    trackingLine,
    autoMarking,
    teacherMarking,
  };
};
