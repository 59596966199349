import React from 'react';
import Heading from '../../common/Heading';
import Content from '../../common/Content';
import { Table } from 'antd';
import './stylesheet.scss';
import DisplayHtml from 'components/common/html';

const ListDefinitionInTable = ({ item }) => {
  if (item.block_list && Array.isArray(item.block_list)) {
    const columns = [
      {
        title: '-',
        dataIndex: 'name',
        key: 'name',
        render: (name) => <strong className="term">{name}</strong>,
      },
      {
        title: '',
        dataIndex: 'content',
        key: 'content',
        render: (content) => <DisplayHtml content={content} />,
      },
    ];

    return (
      <div className="block-list-container">
        <Heading item={item} />
        <Content item={item} />
        <div className="content definition-table">
          <Table
            columns={columns}
            dataSource={item.block_list}
            pagination={false}
            showHeader={false}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default ListDefinitionInTable;
