import {
  CHANGELOG_LOADING,
  CHANGELOG_LOADING_ERROR,
  CHANGELOG_LOADING_SUCCESS,
  SAVE_APP_VERSION,
  SAVE_SESSION_ENDED,
  SYSTEM_MESSAGE_CLEAR,
  SYSTEM_MESSAGE_LOADING_ERROR,
  SYSTEM_MESSAGE_LOADING_SUCCESS,
} from 'actions/app-info/types';

const initialState = {
  version: null,
  sessionEnded: null,
  systemMessage: null,
  loading: false,
  changelog: '',
  error: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SAVE_APP_VERSION: {
      return {
        ...state,
        version: action.payload.version,
      };
    }
    case SAVE_SESSION_ENDED: {
      return {
        ...state,
        sessionEnded: action.payload.sessionEnded,
      };
    }
    case SYSTEM_MESSAGE_LOADING_SUCCESS: {
      return {
        ...state,
        systemMessage: action.payload.systemMessage,
      };
    }
    case SYSTEM_MESSAGE_LOADING_ERROR: {
      return {
        ...state,
        systemMessage: null,
      };
    }
    case SYSTEM_MESSAGE_CLEAR: {
      return {
        ...state,
        systemMessage: null,
      };
    }
    case CHANGELOG_LOADING: {
      return {
        ...state,
        loading: true,
        changelog: '',
        error: null,
      };
    }
    case CHANGELOG_LOADING_SUCCESS: {
      return {
        ...state,
        loading: false,
        changelog: action.payload.changelog,
        error: null,
      };
    }
    case CHANGELOG_LOADING_ERROR: {
      return {
        ...state,
        loading: false,
        changelog: '',
        error: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
