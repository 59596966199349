import React from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import endpoints from 'components/bdtx/training-phase/endpoints';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import TableResult from 'components/bdtx/training-phase/search/TableResult';
import schema from 'components/bdtx/training-phase/search/schema';
import { Alert } from 'antd';

const SEARCH_FORM_ID = 'search-training-phase';

const TrainingPhase = () => {
  const renderResultComponent = (items) => {
    return <TableResult trainingPhases={items} searchFormId={SEARCH_FORM_ID} />;
  };

  const renderNoResultComponent = () => {
    return (
      <SimpleNoResult text="Chưa có đợt đào tạo trong khoảng thời gian trên" />
    );
  };

  return (
    <>
      <Alert
        message="Chú ý"
        description={
          <ul>
            <li>Trong năm học, có thể tạo được nhiều đợt đào tạo.</li>
            <li>
              Mỗi đợt đào tạo, sẽ có thời gian bắt đầu và kết thúc, dành cho đối
              tượng GV/CBQL, cấp và mô hình triển khai Cốt cán hoặc Đại trà.
            </li>
            <li>
              Khi bạn thay đổi một đợt đào tạo, có thể sẽ bị ảnh hưởng đến Kế
              hoạch đào tạo của đợt đó. Bạn cần phải thay đổi lại Kế hoạch đào
              tạo tương ứng cho phù hợp với đợt đào tạo vừa sửa.
            </li>
          </ul>
        }
        type="info"
        showIcon
        className="m-b-15"
      />

      <SearchWrapper
        schema={schema}
        formid={SEARCH_FORM_ID}
        alternativeApi={endpoints.bdtx_get_list_training_phase}
        renderResultsComponent={renderResultComponent}
        renderNoResultComponent={renderNoResultComponent}
        showSearchButton={false}
        autoSearchWhenStart
        autoSearchWhenValuesChange
      />
    </>
  );
};

export default TrainingPhase;
