import trainingPlanSelectBoxElement from 'components/admin/training-plan/schema/element/training-plan-select-box';
import endpoints from 'components/bdtx/training-phase/endpoints';
import get from 'lodash.get';

const schema = (formid, values, trainingPhaseIid) => {
  return {
    training_plan: trainingPlanSelectBoxElement({
      formid,
      trainingPhaseIid,
      category: get(values, 'category'),
      classWrapper: 'col-md-12',
      multiple: false,
      isRequired: false,
      populateValue: false,
      havePlannedUsers: true,
      customValueOfApi: {
        training_phase_iid: trainingPhaseIid,
        include_items_from_ancestor_organizations: 1,
      },
      apiUrl: endpoints.bdtx_get_training_plan_by_school_year,
    }),
  };
};

const ui = () => {
  return [
    {
      id: 'default',
      fields: ['training_plan'],
    },
  ];
};

export default (trainingPhaseIid) => ({
  schema: (formid, values) => schema(formid, values, trainingPhaseIid),
  ui,
});
