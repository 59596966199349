import React from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import endpoints from 'components/bdtx/overview-module-materials/endpoints';
import schema from './schema';
import { userGrades, userGradeToText } from 'configs/constants/user';
import { formatDataByGrades } from 'components/bdtx/overview-module-materials/utils';
import Alert from 'antd/lib/alert';
import CommonAntdTable from 'components/common/antd/table';
import { CHUONG_TRINH_01, getChuongTrinhName } from 'components/bdtx/configs';
import get from 'lodash.get';
import { isOrganizationBelongToBGD } from 'components/admin/user/utils';
import ChooseMaterial from 'components/bdtx/overview-module-materials/new';
import Warning from 'components/common/Warning';
import { Collapse } from 'antd';
import Tooltip from 'common/tooltip';
import Link from 'components/common/router/Link';

import './stylesheet.scss';

const SEARCH_FORM_ID = 'search_choose_material';
const { Panel } = Collapse;

const SearchChooseModule = ({ chuongTrinh }) => {
  const renderTableColumns = (schoolYear, targetUser, grade, organization) => {
    return [
      {
        title: 'Mô đun',
        render: (rowData) => {
          const { name, rowSpan } = rowData;
          return {
            children: name,
            props: {
              rowSpan,
            },
          };
        },
      },
      {
        title: 'Học liệu đã mua',
        render: (rowData) => {
          const { bought_materials, chuong_trinh, iid } = rowData;

          const hiddenField = {
            target_user: targetUser,
            cap: grade,
            school_year: schoolYear,
            chuong_trinh: chuong_trinh,
            organization_iid: get(organization, '[0]'),
            module_iid: iid,
          };

          return {
            children:
              Array.isArray(bought_materials) && bought_materials.length ? (
                <ChooseMaterial
                  materials={bought_materials}
                  hiddenField={hiddenField}
                />
              ) : (
                <div className="text-center">
                  <Warning>Chưa mua học liệu</Warning>
                  <Link
                    className="link m-t-10"
                    to={'/admin/bdtx/overview-module-materials/purchased'}
                  >
                    Xem học liệu đã mua
                  </Link>
                </div>
              ),
          };
        },
      },
      {
        title: (
          <div>
            Học liệu khác
            <Tooltip content="Học liệu của đơn vị khác trên hệ thống" />
          </div>
        ),
        width: 250,
        render: (rowData) => {
          const { available_materials } = rowData;

          return {
            children:
              Array.isArray(available_materials) &&
              available_materials.length ? (
                <div>
                  {available_materials.map((material) => (
                    <div id={material.id}>
                      {material.name} - (orgIid:{' '}
                      {get(material, 'organizations.0')})
                    </div>
                  ))}
                </div>
              ) : (
                <div>Chưa có học liệu khác</div>
              ),
          };
        },
      },
    ];
  };

  const renderChooseForm = (
    data = {},
    schoolYear,
    targetUser,
    organization,
  ) => {
    const grades = Object.keys(userGrades).map((key) => userGrades[key]);

    const gradesWithNoModules = [];
    const gradesWithModulesComponent = [];

    grades.forEach((grade) => {
      const modules = data[grade];
      const hasModules = data[grade] && data[grade].length;

      if (!hasModules) {
        gradesWithNoModules.push(grade);
      } else {
        gradesWithModulesComponent.push(
          <div className="m-b-15">
            <div className="text-bold">{`Cấp: ${userGradeToText(grade)}`}</div>
            <CommonAntdTable
              dataSource={modules}
              columns={renderTableColumns(
                schoolYear,
                targetUser,
                grade,
                organization,
              )}
              pagination={false}
              className="learn-default table-choose-material"
            />
          </div>,
        );
      }
    });

    const gradesWithNoModulesComponent = (
      <div className="p-b-10">
        Chưa có module:{' '}
        {gradesWithNoModules.map((grade) => (
          <span>{userGradeToText(grade)}, </span>
        ))}
      </div>
    );

    return [gradesWithNoModulesComponent, gradesWithModulesComponent];
  };

  const renderResultComponent = (items = [], props, objects, searchValues) => {
    const organization = get(searchValues, 'organization');
    const schoolYear = get(searchValues, 'school_year');
    const targetUser = get(searchValues, 'target_user');

    let dataSource = items;
    if (isOrganizationBelongToBGD(organization)) {
      dataSource = dataSource.filter(
        (data) => data.chuong_trinh == CHUONG_TRINH_01,
      );
    }

    if (!dataSource || !dataSource.length) {
      return (
        <>
          <SimpleNoResult text="Không có chương trình cho đơn vị" />
        </>
      );
    }

    return (
      <>
        <div className="m-b-25">
          <Alert
            message={'Tổng quan học liệu cho năm học'}
            type="info"
            showIcon
          />
        </div>

        <Collapse defaultActiveKey={[1]}>
          {dataSource.map((item, index) => {
            const { chuong_trinh, caps } = item;
            const data = formatDataByGrades(caps);
            return (
              <Panel
                header={
                  <span className="text-secondary">
                    {getChuongTrinhName(chuong_trinh)}
                  </span>
                }
                key={chuong_trinh}
              >
                {renderChooseForm(data, schoolYear, targetUser, organization)}
                {index + 1 < dataSource.length && <hr />}
              </Panel>
            );
          })}
        </Collapse>
      </>
    );
  };

  const renderNoResultComponent = () => {
    return (
      <SimpleNoResult text={`Chương trình ${chuongTrinh} không có mô đun`} />
    );
  };

  return (
    <SearchWrapper
      schema={schema}
      formid={SEARCH_FORM_ID}
      alternativeApi={endpoints.bdtx_get_overview_module}
      renderResultsComponent={renderResultComponent}
      renderNoResultComponent={renderNoResultComponent}
      hiddenFields={{
        chuong_trinh: chuongTrinh,
      }}
      autoSearchWhenStart
      showSearchButton={false}
      autoSearchWhenValuesChange
      hidePagination={true}
    />
  );
};

export default SearchChooseModule;
