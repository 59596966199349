import React from 'react';
import BoxContainer from './BoxContainer';
import get from 'lodash.get';
import AnswerTable from './AnswerTable';
import './box.scss';

const colors = [
  '#e42b2b',
  '#3b7bbe',
  '#FDD835',
  '#f032e6',
  '#fe7a3d',
  '#000075',
  '#3cb44b',
  '#dcbeff',
  '#808000',
  '#00918c',
  '#ffd8b1',
  '#aaffc3',
  '#4363d8',
  '#fabed4',
];

const highlightColor = '#5ac743';

class MatchingPairBox extends React.Component {
  getAnswerTableData = () => {
    const { question = {} } = this.props;
    const questions = get(question, 'answer_as_hint.l_pair', []);
    const answers = get(question, 'answer_as_hint.r_pair', []);

    if (
      !Array.isArray(questions) ||
      !questions.length ||
      !Array.isArray(answers) ||
      !answers.length
    ) {
      return [];
    }

    return questions.map((q) => {
      const answer = answers.find((a) => a.id === q.id);
      return {
        ...q,
        answer: get(answer, 'content', ''),
      };
    });
  };

  render() {
    const {
      userAnswers,
      question,
      shouldShowKey,
      setUserAnswers,
      disabled,
      practice,
    } = this.props;

    const cssClass = 'matching-pair-question';
    return (
      <div>
        <BoxContainer
          userAnswers={userAnswers}
          question={question}
          setUserAnswers={setUserAnswers}
          disabled={disabled}
          className={cssClass}
          colors={colors}
          highlightColor={highlightColor}
          practice={practice}
        />
        {shouldShowKey && (
          <AnswerTable dataSource={this.getAnswerTableData()} />
        )}
      </div>
    );
  }
}

export default MatchingPairBox;
