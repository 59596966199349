import React from 'react';

class AcademicCategoryLayout extends React.PureComponent {
  render() {
    const { groups, readOnly } = this.props;
    let { submitButton } = this.props;

    if (readOnly) {
      submitButton = null;
    }

    return (
      <div className="container-fluid elementGroup">
        <div className="row">
          <div className="col-md-6">{groups.default.fieldNames.name}</div>
          <div className="col-md-6">{groups.default.fieldNames.code}</div>
          <div className="col-md-9">{groups.default.fieldNames.caps}</div>
          <div className="col-md-3">
            {groups.default.fieldNames.target_users}
          </div>

          <div className="col-md-12 text-center">{submitButton}</div>
        </div>
      </div>
    );
  }
}

export default AcademicCategoryLayout;
