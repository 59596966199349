export const CURRENT_LEARN_ELEMENT = 'CURRENT_LEARN_ELEMENT';
export const CURRENT_LEARN_NAV_ELEMENT = 'CURRENT_LEARN_NAV_ELEMENT';
export const INIT_LEARN_VIEW_LIST = 'INIT_LEARN_VIEW_LIST';
export const ACTIVE_ITEMS_IN_VIEW = 'ACTIVE_ITEMS_IN_VIEW';
export const DISPLAY_QUESTIONS_CHECKED_RESULT =
  'DISPLAY_QUESTIONS_CHECKED_RESULT';
export const STOP_DISPLAY_QUESTIONS_CHECKED_RESULT =
  'STOP_DISPLAY_QUESTIONS_CHECKED_RESULT';
export const SAVE_ANSWER = 'SAVE_ANSWER';
export const RETRY_SAVE_ANSWERS = 'RETRY_SAVE_ANSWERS';
export const RETRY_SAVE_ANSWERS_STOP = 'RETRY_SAVE_ANSWERS_STOP';
export const SET_USER_ANSWER = 'SET_USER_ANSWER';
export const CLEAR_ANSWERS = 'CLEAR_ANSWERS';
export const SAVE_TCOS_PRICE_TO_STORE = 'SAVE_TCOS_PRICE_TO_STORE';
export const ADD_PHONETIC_DIFF = 'ADD_PHONETIC_DIFF';
export const CLEAN_PHONETIC_DIFF = 'CLEAN_PHONETIC_DIFF';
export const SAVE_ITEM_INFO_TO_STORE = 'SAVE_ITEM_INFO_TO_STORE';
export const SAVE_ITEM_COUNT_DOWN_TIME = 'SAVE_ITEM_COUNT_DOWN_TIME';
export const SAVE_ITEM_QUESTION_INFO = 'SAVE_ITEM_QUESTION_INFO';
export const SAVE_ITEM_QUESTION_AUDIO_STATUS =
  'SAVE_ITEM_QUESTION_AUDIO_STATUS';
export const SET_PAPER_ID = 'SET_PAPER_ID';
export const SET_POSITION_OF_NOW_PLAYING_ITEM_ON_NAV =
  'SET_POSITION_OF_NOW_PLAYING_ITEM_ON_NAV';
export const SET_ANSWERS_LOG = 'SET_ANSWERS_LOG';
export const SAVE_TAKE_REQUEST_BEGIN = 'SAVE_TAKE_REQUEST_BEGIN';
export const SAVE_TAKE_REQUEST_SUCCESS = 'SAVE_TAKE_REQUEST_SUCCESS';
export const SAVE_TAKE_REQUEST_FAIL = 'SAVE_TAKE_REQUEST_FAIL';
export const RESET_LEARN_INFO = 'RESET_LEARN_INFO';
export const SET_EXERCISE_CONFIRMATION_FLOW = 'SET_EXERCISE_CONFIRMATION_FLOW';
export const RESET_TICKED_QUESTIONS = 'RESET_TICKED_QUESTIONS';
export const SET_EXTRA_INFO_FOR_CURRENT_ITEM =
  'SET_EXTRA_INFO_FOR_CURRENT_ITEM';
// export const SHOULD_SHOW_TAKE_NOTE = 'SHOULD_SHOW_TAKE_NOTE';
export const COLLAPSE_LEARN_MENU_NAV = 'COLLAPSE_LEARN_MENU_NAV';
export const SHOW_VIDEO_WINDOW = 'SHOW_VIDEO_WINDOW';
export const SAVE_CURRENT_USER_TAKING_CONTEST =
  'SAVE_CURRENT_USER_TAKING_CONTEST';

export const setPositionOfNowPlayingItemOnNav = (data) => ({
  type: SET_POSITION_OF_NOW_PLAYING_ITEM_ON_NAV,
  data,
});

export const initLearnViewList = (iid, items) => ({
  type: INIT_LEARN_VIEW_LIST,
  iid,
  items,
});

export const activeItemsInView = (iid, activeItems) => ({
  type: ACTIVE_ITEMS_IN_VIEW,
  iid,
  activeItems,
});

export const displayQuestionsCheckedResult = (itemIid, questionIndexes) => ({
  type: DISPLAY_QUESTIONS_CHECKED_RESULT,
  itemIid,
  questionIndexes,
});

export const setAnswersLogAndUserAnswers = (itemIid, answersLog) => ({
  type: SET_ANSWERS_LOG,
  itemIid,
  answersLog,
});

export const stopDisplayQuestionsCheckedResult = (
  itemIid,
  questionIndexes,
) => ({
  type: STOP_DISPLAY_QUESTIONS_CHECKED_RESULT,
  itemIid,
  questionIndexes,
});

export const setCurrentLearningElement = (data) => ({
  type: CURRENT_LEARN_ELEMENT,
  data,
});

export const saveAnswer = (itemIid, questionIndex, answer) => ({
  type: SAVE_ANSWER,
  itemIid,
  questionIndex,
  answer,
});

export const retrySavingAnswers = (itemIid, isImmediate = false) => ({
  type: RETRY_SAVE_ANSWERS,
  itemIid,
  isImmediate, // whether or not to start retry immediately
});

export const stopRetrySavingAnswers = (itemIid) => ({
  type: RETRY_SAVE_ANSWERS_STOP,
  itemIid,
});

export const setUserAnswers = (itemIid, userAnswers) => ({
  type: SET_USER_ANSWER,
  itemIid,
  userAnswers,
});

export const clearAnswers = (itemIid) => ({ type: CLEAR_ANSWERS, itemIid });

export const saveTcosPriceToStore = (data) => ({
  type: SAVE_TCOS_PRICE_TO_STORE,
  data,
});

export const addPhoneticDiff = (vid, position, phoneticDiff) => ({
  type: ADD_PHONETIC_DIFF,
  vid,
  position,
  phoneticDiff,
});

export const cleanPhoneticDiff = (vid) => ({ type: CLEAN_PHONETIC_DIFF, vid });

export const saveItemInfoToStore = (itemIid, info, shouldUpdate = true) => ({
  type: SAVE_ITEM_INFO_TO_STORE,
  itemIid,
  info,
  shouldUpdate,
});

export const saveTakeRequestBegin = (itemIid, affectedQuestions) => ({
  type: SAVE_TAKE_REQUEST_BEGIN,
  itemIid,
  affectedQuestions,
});

export const saveTakeRequestSuccess = (itemIid, info) => ({
  type: SAVE_TAKE_REQUEST_SUCCESS,
  itemIid,
  info,
});

export const saveTakeRequestFail = (itemIid, info) => ({
  type: SAVE_TAKE_REQUEST_FAIL,
  itemIid,
  info,
});

export const saveItemCountDownTimeToStore = (itemIid, timeLeftInSeconds) => ({
  type: SAVE_ITEM_COUNT_DOWN_TIME,
  itemIid,
  timeLeftInSeconds,
});

export const setPaperId = (paperId) => ({ type: SET_PAPER_ID, paperId });

export const saveItemQuestionInfo = (
  itemIid,
  questionUniqueId,
  info,
  shouldUpdate = true,
) => ({
  type: SAVE_ITEM_QUESTION_INFO,
  itemIid,
  questionUniqueId,
  info,
  shouldUpdate,
});

export const saveItemQuestionAudioStatus = (
  itemIid,
  questionUniqueId,
  audioIndex,
  status,
) => ({
  type: SAVE_ITEM_QUESTION_AUDIO_STATUS,
  itemIid,
  questionUniqueId,
  audioIndex,
  status,
});

export const setCurrentLearnNavElement = (element) => ({
  type: CURRENT_LEARN_NAV_ELEMENT,
  element,
});

export const resetLearnInfo = () => ({
  type: RESET_LEARN_INFO,
});

export const setExerciseConfirmationFlow = (exerciseConfirmationFlow) => ({
  type: SET_EXERCISE_CONFIRMATION_FLOW,
  exerciseConfirmationFlow,
});

export const resetTickedQuestions = (itemIid, questionIndexes) => ({
  type: RESET_TICKED_QUESTIONS,
  itemIid,
  questionIndexes,
});

export const setExtraInfoForCurrentItem = (itemType, extraInfo) => ({
  type: SET_EXTRA_INFO_FOR_CURRENT_ITEM,
  itemType,
  extraInfo,
});

// export const shouldShowTakeNote = (showTakeNote) => ({
//   type: SHOULD_SHOW_TAKE_NOTE,
//   showTakeNote,
// });

export const collapseLearnMenuNav = (showNav) => ({
  type: COLLAPSE_LEARN_MENU_NAV,
  showNav,
});
export const showVideoWindow = (listActiveVideo) => ({
  type: SHOW_VIDEO_WINDOW,
  listActiveVideo,
});

export const saveCurrentUserTakingContest = (contest) => ({
  type: SAVE_CURRENT_USER_TAKING_CONTEST,
  contest,
});
