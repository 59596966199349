import React from 'react';
const MicroSoftEmbedLink = (props) => {
  const { link, ...rest } = props;
  return (
    <iframe
      src={`https://view.officeapps.live.com/op/embed.aspx?src=${link}`}
      width="100%"
      height="800px"
      frameborder="0"
      {...rest}
    />
  );
};

export default MicroSoftEmbedLink;
