import './stylesheet.scss';

import React, { useEffect } from 'react';
import get from 'lodash.get';
import { linkTypes } from 'components/admin/video/schema/blocks/link';
import { t1 } from 'translate';
import { isWatchMode, ITEM_TYPE } from 'components/learn/watch-or-read/utils';
import WatchOrReadWrapper from 'components/learn/watch-or-read';
import Heading from 'components/learn/items/sco-spl/common/Heading';
import { saveLearningProgress } from 'common/learn';
import { connect } from 'react-redux';
import DisplayHtml from 'components/common/html';
import queryString from 'query-string';
import lodashIsEmpty from 'lodash.isempty';
import lodashGet from 'lodash.get';

const Link = ({
  item,
  course,
  isPreview,
  isReview,
  showInScoSpl,
  hiddenHeading,
  learningProgress,
  dispatch,
}) => {
  const watchMode = isWatchMode(item);
  const link = get(item, 'block_link.link');
  const linkType = get(item, 'block_link.link_type');
  const description = get(item, 'block_link.description');

  const isOpenLink = link && linkType === linkTypes.TYPE_OPEN_LINK;
  const itemIid = get(item, 'iid');
  const courseIid = get(course, 'iid');

  useEffect(
    () => {
      if (learningProgress == 100) {
        return;
      }

      saveLearningProgress(
        [
          {
            tco_iid: itemIid,
            p: 100,
            cp: 100,
          },
        ],
        courseIid,
        dispatch,
        isPreview,
        isReview,
      );
    },
    [dispatch, isPreview, isReview, learningProgress, courseIid, itemIid],
  );

  const formatLinkWithQueryString = (link) => {
    let newLink;
    const queryStrings = queryString.parseUrl(link);
    if (lodashIsEmpty(lodashGet(queryStrings, 'query'))) {
      newLink = link.concat(`?courseIid=${courseIid}`);
    } else {
      newLink = link.concat(`&courseIid=${courseIid}`);
    }
    return newLink;
  };

  const getVideoClassName = () => {
    if (!showInScoSpl) {
      return 'video-wrapper';
    }

    return 'video-wrapper video-wrapper-ratio';
  };

  if (!item) {
    return null;
  }

  return (
    <WatchOrReadWrapper
      item={item}
      course={course}
      showInScoSpl={showInScoSpl}
      itemType={ITEM_TYPE.LINK}
    >
      {!hiddenHeading && watchMode && (
        <div className={'video-heading block-item-heading'}>
          <Heading item={item} />
        </div>
      )}

      <div
        className={`lecture-link ${
          isOpenLink ? 'lecture-link--open container text-center' : ''
        }`}
      >
        {isOpenLink ? (
          <>
            <b className="m-b-10">
              {t1('link')}: {formatLinkWithQueryString(link)}
            </b>

            {!!description && (
              <div className="lecture-link__description">
                <DisplayHtml content={description} />
              </div>
            )}

            <a
              href={formatLinkWithQueryString(link)}
              target={'_blank'}
              className="m-t-10"
            >
              <button type="button" className="btn btn-secondary btn-outline">
                {t1('go_to_link')}
              </button>
            </a>
          </>
        ) : (
          <div className={getVideoClassName()}>
            <div className="video">
              <iframe
                src={formatLinkWithQueryString(link)}
                frameBorder="0"
                width="100%"
                height="100%"
                allow="microphone; camera; vr; speaker;"
              />
            </div>
          </div>
        )}
      </div>
    </WatchOrReadWrapper>
  );
};

const mapStateToProps = (state, props) => {
  const learnItemIid = get(props, 'item.iid');
  const trackerProgress = state.trackerProgress[learnItemIid];
  const learningProgress = (trackerProgress && trackerProgress.p) || 0;

  return {
    learningProgress,
  };
};

export default connect(mapStateToProps)(Link);
