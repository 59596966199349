import React from 'react';
import get from 'lodash.get';
import InlineNormalExercise from './InlineNormalExercise';

import './stylesheet.scss';

const BlockExercise = ({
  nodes,
  course,
  syllabus,
  iid,
  piid,
  isPreviewInSyllabusEditor,
}) => {
  const courseIid = get(course, 'iid');
  const item = nodes[iid];
  return (
    <>
      <hr className="m-t-10 m-b-10" />
      <div className="inline-exercise">
        <InlineNormalExercise
          title={item.name}
          exerciseIid={iid}
          parentIid={piid}
          courseIid={courseIid}
          course={course}
          syllabus={syllabus}
          isPreviewInSyllabusEditor={isPreviewInSyllabusEditor}
        />
      </div>
    </>
  );
};

export default BlockExercise;
