import {
  SCHOLASTIC_CONFIG_LOADING,
  SCHOLASTIC_CONFIG_LOADING_SUCCESS,
  SCHOLASTIC_CONFIG_LOADING_ERROR,
  SCHOLASTIC_CONFIG_ALL_SCHOOL_YEARS,
} from 'actions/scholastic-config/types';

const initialState = {
  config: null,
  loading: false,
  error: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SCHOLASTIC_CONFIG_LOADING_SUCCESS: {
      return {
        ...state,
        config: action.payload.config,
      };
    }
    case SCHOLASTIC_CONFIG_LOADING: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case SCHOLASTIC_CONFIG_LOADING_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    }
    case SCHOLASTIC_CONFIG_ALL_SCHOOL_YEARS: {
      return {
        ...state,
        schoolYears: action.payload.schoolYears,
      };
    }
    default: {
      return state;
    }
  }
}
