import RTE from 'schema-form/elements/richtext';
import { required, restrictionWords } from 'common/validators';
import { t1 } from 'translate';
import React from 'react';

export default {
  schema: () => {
    return {
      comment: {
        type: RTE,
        validate: [
          required(),
          restrictionWords(t1('comment_has_restriction_words')),
        ],
      },
    };
  },
  ui: () => {
    return [
      {
        id: 'default',
        fields: ['comment'],
      },
    ];
  },
  layout: {
    component: ({ groups, readOnly }) => {
      return (
        <div>
          <div>{groups.default.fieldNames.comment}</div>
          <div className="text-center m-t-15">
            <button disabled={readOnly} className="btn btn-secondary">
              {t1('send')}
            </button>
          </div>
        </div>
      );
    },
    freestyle: 1,
  },
};
