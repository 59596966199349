import { SCREEN_WIDTH } from 'configs/constants';

export function scrollToLearnContainer(width) {
  if (width > 0 && width <= SCREEN_WIDTH.MAX_TABLET) {
    const learnContainer = document.getElementById('learn-container');
    if (learnContainer) {
      learnContainer.scrollIntoView();
    }
  }
}
