import React from 'react';
import Helmet from 'components/common/helmet';
import AboutETEP from '../../../../etep/ETEP';

function About() {
  if (window.isETEP) return AboutETEP();
  else return <div>About us</div>;
}

export default About;
