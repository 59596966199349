import { MDDM_PARSE_KEY } from 'components/admin/question/constants';

export const spanId = (index, i) => `${index}-mddm-span-${i}`;

export const getMapByQuestionData = (text = '') => {
  const regex = new RegExp(`(${MDDM_PARSE_KEY})`, 'g');
  const matches = text.match(regex);
  if (!matches) {
    return [];
  }
  let rawQuestion = [];
  const questionAfterSplitByRegex = text.split(regex);
  let parse_key_count = 0;
  questionAfterSplitByRegex.forEach((word) => {
    if (word && word.match(regex)) {
      rawQuestion.push(parse_key_count);
      parse_key_count += 1;
    }
  });
  return rawQuestion;
};

export const parseMDDMQuestionRawText = (content = '', index = 0) => {
  const regex = new RegExp(`(${MDDM_PARSE_KEY})`, 'g');
  const matches = content.match(regex);
  if (!matches) {
    return {
      content,
      rawQuestion: content,
      spaceAfterSplitByRegex: [],
    };
  }
  let rawQuestion = '';
  const questionAfterSplitByRegex = content.split(regex);
  let parse_key_count = 0;
  questionAfterSplitByRegex.forEach((word) => {
    if (word && word.match(regex)) {
      rawQuestion += `<span id='${spanId(index, parse_key_count)}'></span>`;
      parse_key_count += 1;
    } else {
      rawQuestion += word;
    }
  });

  const spaceAfterSplitByRegex = questionAfterSplitByRegex.filter(
    (word) => word && word.match(regex),
  );

  return {
    content,
    rawQuestion,
    spaceAfterSplitByRegex,
  };
};

export const parseMDDMQuestionToElement = (text = '', raw, content) => {
  const regex = new RegExp(`(${MDDM_PARSE_KEY}|\n)`, 'g');
  const regexSymbol = new RegExp(`(${MDDM_PARSE_KEY})`, 'g');
  const regexNewLine = /\n/g;
  let rawQuestion = [];
  const questionAfterSplitByRegex = text.split(regex);
  let parse_key_count = 0;
  const map = getMapByQuestionData(text);
  questionAfterSplitByRegex.forEach((word) => {
    if (word && word.match(regexSymbol)) {
      rawQuestion = [...rawQuestion, content(parse_key_count, map)];
      parse_key_count += 1;
    } else if (word && word.match(regexNewLine)) {
      rawQuestion = [...rawQuestion, raw(word)];
    } else {
      rawQuestion = [...rawQuestion, word];
    }
  });
  return rawQuestion;
};
