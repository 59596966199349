import { lectureType, ntype } from 'configs/constants';
import { isSmallScreen } from 'common';
import get from 'lodash.get';
import { steps } from 'common/learn/exercise';

export const getLectureContent = (learnItem, type = lectureType.VIDEO) => {
  if (learnItem && learnItem.ntype === ntype.VIDEO && learnItem.type === type) {
    return learnItem.content;
  }
  return '';
};

export const viewingExerciseOnMobile = (learnItemInfo) => {
  return (
    isSmallScreen &&
    learnItemInfo.step !== steps.RESULT &&
    learnItemInfo.step !== steps.NOT_STARTED &&
    learnItemInfo.type === 'exercise'
  );
};

export const hideScoreOnCourseEndedScreen = (course) => {
  const courseIid = get(course, 'iid');
  const coursesWillHideScore = window.hide_score_on_course_ended_screen || [];

  return (
    Array.isArray(coursesWillHideScore) &&
    coursesWillHideScore.find((iid) => iid == courseIid)
  );
};

export const reloadPageAfterFinishExerciseOfCourse = (course) =>
  hideScoreOnCourseEndedScreen(course);

export const generateEndingScreenFromCurrentPathname = () => {
  const pathnames = window.location.pathname.split('/');

  // TODO: Refactor this.
  const itemIndex = 4;
  pathnames[itemIndex] = 'last-screen';

  return pathnames.join('/');
};
