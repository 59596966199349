import React, { useEffect, useState } from 'react';
import get from 'lodash.get';
import DetailOnDialog from 'components/common/detail-on-dialog';
import CommentForm from './CommentForm';
import { t1 } from 'translate';
import Icon from 'components/common/Icon';
import { Tooltip } from 'antd';

const MasterComment = ({
  formidToSubmitOnSuccess,
  course = {},
  justShowStatus = false,
  readOnly = false,
}) => {
  const courseIid = get(course, 'iid');
  const marking = get(course, 'marking_status', {});
  const masterComment = get(marking, 'comment');

  const [masterCommented, setMasterCommented] = useState(!!masterComment);

  useEffect(
    () => {
      if (!!masterComment) {
        setMasterCommented(true);
      }
    },
    [masterComment],
  );

  return (
    <DetailOnDialog
      renderPreview={({ showFull }) => {
        if (justShowStatus) {
          if (masterCommented) {
            return (
              <span className="cursor-pointer" onClick={showFull}>
                {t1(
                  'core_teacher_educators_core_principal_advisor_educators_have_commented',
                )}
                <i className="d-block">({t1('click_to_view')})</i>
              </span>
            );
          }

          return (
            <span>
              {t1(
                'core_teacher_educators_core_principal_advisor_educators_have_not_commented_yet',
              )}
            </span>
          );
        }

        if (!masterCommented) {
          return (
            <button onClick={showFull} className="btn btn-secondary">
              {t1(
                'core_teacher_educators_core_principal_advisor_educators_comment',
              )}
            </button>
          );
        }

        return (
          <Tooltip
            title={t1(
              'core_teacher_educators_core_principal_advisor_educators_have_commented._click_to_view_and_update',
            )}
          >
            <span onClick={showFull} className="cursor-pointer">
              <span>
                {t1(
                  'core_teacher_educators_core_principal_advisor_educators_have_commented',
                )}
              </span>
              <Icon icon="edit" className="text-primary m-l-5" />
              <i className="d-block">({t1('click_to_view_and_update')})</i>
            </span>
          </Tooltip>
        );
      }}
      renderFull={({ closeDialog }) => {
        return (
          <CommentForm
            justShowStatus={justShowStatus}
            courseIid={courseIid}
            formidToSubmitOnSuccess={formidToSubmitOnSuccess}
            closeDialog={closeDialog}
            readOnly={readOnly}
            executeOnSuccess={() => setMasterCommented(true)}
          />
        );
      }}
      dialogOptionsProperties={{
        title: t1('master_comment_course'),
      }}
    />
  );
};

export default MasterComment;
