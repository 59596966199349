import { t1 } from 'translate';

export const taphuanUserTypes = {
  CAN_BO_QUAN_LY: 'cbql',
  GIAO_VIEN: 'gv',
};

export const taphuanUserTypeToText = (key) => {
  const map = {
    [taphuanUserTypes.CAN_BO_QUAN_LY]: t1('taphuan_user_type_cbql'),
    [taphuanUserTypes.GIAO_VIEN]: t1('taphuan_user_type_gv'),
  };
  return map[key];
};

export const taphuanUserTypeOptions = () =>
  [taphuanUserTypes.GIAO_VIEN, taphuanUserTypes.CAN_BO_QUAN_LY].map(
    (value) => ({
      value,
      primaryText: taphuanUserTypeToText(value),
      label: taphuanUserTypeToText(value),
    }),
  );
