import './stylesheet.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, getFormSyncErrors, submit } from 'redux-form';
import { compose } from 'redux';

import {
  displayQuestionsCheckedResult,
  retrySavingAnswers,
  saveAnswer,
  saveItemInfoToStore as saveItemInfoToStoreAction,
  saveItemQuestionInfo as saveItemQuestionInfoAction,
  setExerciseConfirmationFlow as setExerciseConfirmationFlowAction,
} from 'actions/learn';
import {
  finishExercise,
  finishReviewExercise,
  redoExercise as redoExerciseAction,
  redoExerciseWhenQuestionsMissing as redoExerciseActionWhenQuestionsMissing,
  resumeExercise as resumeExerciseAction,
  reviewExercise as reviewExerciseAction,
  setCurrentQuestionInExercise as setCurrentQuestionInExerciseAction,
  startExercise as startExerciseAction,
  loadPreviousTake,
} from 'actions/learn/exercise/normal/saga-creators';
import { withRouter, Prompt } from 'react-router-dom';
import {
  getFullExercisesWithQuestionInfoSelector,
  getNavigateInfoOfExerciseSelector,
  getQuestionsWithFullInfoFromUniqueIdsInExercises,
  modes,
  statuses,
  steps,
} from 'common/learn/exercise';
import {
  displayAsMarkingPassFailMode,
  isIntroSticky,
  shouldHideScore,
  waitForMarking,
} from 'common/learn/Question';
import Question from 'components/common/forms/questions';
import { getLearnItemInfoSelector } from 'common/learn';
import { findDOMNode } from 'react-dom';
import NormalExerciseDisplay from './display';
import NormalExerciseControl from './control';
import NormalExerciseNotStarted from './not-started';
import NormalExerciseResult from './result';
import QuestionMissing from './question-missing';
import Portal, { portals } from 'components/common/portal';
import { t1 } from 'translate';
import Affix from 'antd/lib/affix';
import Modal from 'antd/lib/modal';
import TotalPoint from 'components/learn/common/total-point/TotalPoint';
import { isSmallScreen } from 'common';
import Loading from 'components/common/loading';
import screenfull from 'screenfull';
import DetailOnDialog from 'components/common/detail-on-dialog';
import { loadingStatuses } from 'configs/constants';
import { isOEQuestion, isNotOEExercise } from 'common/learn/Question';
import ConfirmToLeave from './confirm-to-leave';
import get from 'lodash.get';
import actions from 'actions/node/creators';
import CustomScrollbars from 'components/common/custom-scrollbars';
import ExerciseStatusOfQuestions from 'components/learn/common/exercise-status-of-questions';
import { types as questionTypes } from 'components/admin/question/schema/question-types';
import {
  shouldShowExerciseQuestionsStatus,
  getQuestionAnswersWrongScore,
} from './common/utils';
import Alert from 'antd/lib/alert';
import { isQuestionEmpty, isQuestionMissing } from '../../../utils';
import PassOrFail from 'components/common/pass-or-fail';
import { saveMultipleTakeRequest } from 'actions/learn/saga-creators';
import Warning from 'components/common/Warning';
import { getQuestionStatusInfoOfItem } from 'components/learn/common/exercise-status-of-questions/utils';
import { reloadPageAfterFinishExerciseOfCourse } from '../../../viewer/utils';
import withUserInfo from 'common/hoc/withUserInfo';
import {
  generateOEFormId,
  isOESupportPlanLayoutType,
  oeErrorMappingKey,
} from 'components/common/forms/questions/open-ended/util';

const pendingSavingQuestionsDialogKey = 'pending-saving-questions-dialog';
const confirmToLeaveDialogKey = 'confirm-to-leave-dialog-key';

export class NormalExercise extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      elementHeight: 0,
      retryingSaveAnswers: false,
    };

    this.confirmDialogOptionsProperties = {
      width: '60%',
      handleClose: true,
      modal: true,
      title: t1('notice'),
    };
  }

  componentDidMount() {
    const { onComponentDidMount, course } = this.props;

    this.updateHeightOfElementsToState();
    if (typeof onComponentDidMount === 'function') {
      onComponentDidMount();
    }

    if (!reloadPageAfterFinishExerciseOfCourse(course)) {
      window.addEventListener('beforeunload', this.onUnload);
    }
  }

  onUnload = (e) => {
    const message = t1('are_you_sure_you_want_to_refresh');
    e.returnValue = message;
    return message;
  };

  componentDidUpdate(prevProps) {
    const { loadPreviousTake, learnItemIid } = this.props;
    const info = this.props.info || {};
    const previousInfo = get(prevProps, 'info') || {};

    if (this.shouldShowPreviousTakeLoadingError(info, previousInfo)) {
      Modal.error({
        title: t1('an_error_occurred'),
        content: t1(
          'an_error_occurred_when_downloading_your_exercise_please_click_on_ok_button_to_download_again',
        ),
        onOk() {
          loadPreviousTake(learnItemIid);
        },
      });
    }

    this.autoShowFinishExerciseConfirmDialog(previousInfo, info);

    this.autoCloseConfirmDialog(previousInfo, info);

    this.updateHeightOfElementsToState();

    this.checkPointQuestionAnswerToResetScore();
  }

  componentWillUnmount() {
    const { onComponentWillUnmount, setExerciseConfirmationFlow } = this.props;
    if (typeof onComponentWillUnmount === 'function') {
      onComponentWillUnmount();
    }

    setExerciseConfirmationFlow({
      allowExitTheExercise: false,
    });

    window.removeEventListener('beforeunload', this.onUnload);
  }

  showPopupToResetPoint = (onOK) => {
    const { result } = this.props;
    Modal.confirm({
      key: result,
      centered: true,
      title: <span>Thông Báo</span>,
      content: (
        <div>
          <p>
            Bài tập của bạn có sự thay đổi về nội dung và câu trả lời của câu
            hỏi, nên đang có sự sai lệch về điểm. Điểm hiện tại của bạn
            là:&nbsp;
            <span className="text-danger">&nbsp;{result}</span>, nếu bạn đồng ý,
            điểm của bạn&nbsp;
            <span className="text-danger">sẽ được tính lại</span>&nbsp; (có thể
            cao hoặc thấp hơn so với điểm hiện tại)
          </p>
          <Alert
            type="error"
            message={
              <Warning>Bạn có thể xem lại bài làm trước khi thay đổi</Warning>
            }
          />
        </div>
      ),
      okText: <span>Tính lại cho tôi</span>,
      onOk() {
        onOK();
      },
      cancelText: <span>Giữ nguyên điểm cũ </span>,
    });
  };

  checkPointQuestionAnswerToResetScore = (show = true) => {
    const { step, info, nodes, result, dispatch } = this.props;
    if (step !== steps.RESULT || this.thePointOfAnswersHaveBeenUpdated) {
      return;
    }

    const questions = get(info, 'questions', {});
    const listAnswer = getQuestionAnswersWrongScore(questions, nodes);

    if (listAnswer.length > 0 && result) {
      if (show) {
        this.showPopupToResetPoint(() =>
          dispatch(saveMultipleTakeRequest(info, listAnswer)),
        );
      } else {
        dispatch(saveMultipleTakeRequest(info, listAnswer));
      }
      this.thePointOfAnswersHaveBeenUpdated = true;
    }
  };

  autoShowFinishExerciseConfirmDialog = (previousInfo, nextInfo) => {
    if (
      get(nextInfo, 'finishExerciseSuccess') === false &&
      get(previousInfo, 'finishExerciseSuccess') !==
        get(nextInfo, 'finishExerciseSuccess')
    ) {
      this.showFinishExerciseConfirmationDialog();
    }
  };

  autoCloseConfirmDialog = (previousInfo, nextInfo) => {
    // if user finish exercise successfully, then we close confirm to leave dialog to show ending screen
    let shouldCloseConfirmToLeaveDialog =
      get(nextInfo, 'finishExerciseSuccess') &&
      get(previousInfo, 'finishExerciseSuccess') !==
        get(nextInfo, 'finishExerciseSuccess');

    // if user retry save take successfully then we close confirm to leave dialog too
    if (!shouldCloseConfirmToLeaveDialog) {
      shouldCloseConfirmToLeaveDialog =
        get(nextInfo, 'saveTakeSuccess') &&
        get(previousInfo, 'saveTakeSuccess') !==
          get(nextInfo, 'saveTakeSuccess');
    }

    // if system finishes saving all pending question, then close pending question dialog
    if (
      previousInfo &&
      previousInfo.pendingQuestions &&
      previousInfo.pendingQuestions.length &&
      (!nextInfo.pendingQuestions || !nextInfo.pendingQuestions.length)
    ) {
      this.closePendingQuestionsDialog();
    }

    if (shouldCloseConfirmToLeaveDialog) {
      this.closeConfirmToLeaveDialog();
    }
  };

  shouldShowPreviousTakeLoadingError = (info, previousInfo) => {
    const lastPreviousTakeLoadingError =
      previousInfo && previousInfo.previousTakeLoadingError;

    return (
      info &&
      info.previousTakeLoadingError &&
      lastPreviousTakeLoadingError !== info.previousTakeLoadingError
    );
  };

  shouldShowScoreNotice = (exercise, info) => {
    return (
      info &&
      !info.syllabus_has_updated_for_kgg &&
      Number(get(exercise, 'max_number_of_questions_per_try', 0)) > 0 &&
      get(exercise, 'exam_template', []).length == 0
    );
  };

  renderScoreExplanation = (exercise) => {
    return (
      <div>
        <h1>Cách tính điểm </h1>
        <p>
          Mỗi lần bạn làm bài, hệ thống sẽ dùng trí tuệ nhân tạo (AI) để đưa ra{' '}
          {exercise.max_number_of_questions_per_try} câu hỏi khác nhau, mỗi câu
          có trọng số điểm như nhau.
          <br />
          Nếu bạn chưa hài lòng với kết quả, bạn có thể làm lại để lấy điểm cao
          hơn. Hệ thống sẽ lưu điểm lần cuối bạn làm bài.{' '}
        </p>
      </div>
    );
  };

  updateHeightOfElementsToState = () => {
    const elementNode = findDOMNode(this);
    const { elementHeight } = this.state;

    if (elementNode) {
      const newElementHeight = elementNode.getBoundingClientRect().height;
      if (elementHeight !== newElementHeight) {
        this.setState({
          elementHeight: newElementHeight,
        });
      }
    }
  };

  showFinishExerciseConfirmationDialog = (gotoPathName) => {
    const { learnItemIid, showConfirmationDialog } = this.props;

    const messageKey = gotoPathName
      ? 'your_exercise_is_not_finished_if_you_want_to_go_to_another_page_your_answers_may_be_loss'
      : 'there_is_an_error_when_finish_exercise_please_try_again';

    const contentDialog = (
      <ConfirmToLeave
        learnItemIid={learnItemIid}
        onSubmit={this.onFinishButtonClick}
        onGoBack={this.closeConfirmToLeaveDialog}
        message={t1(messageKey)}
        submitLabel={t1('submit_exercise')}
      />
    );

    setTimeout(() => {
      showConfirmationDialog(
        contentDialog,
        this.confirmDialogOptionsProperties,
      );
    });
  };

  showFailedToSaveQuestionsDialog = (gotoPathName) => {
    const { learnItemIid, showConfirmationDialog } = this.props;

    const contentDialog = (
      <ConfirmToLeave
        learnItemIid={learnItemIid}
        onSubmit={this.submitExercise}
        onGoBack={this.closeConfirmToLeaveDialog}
        onCancel={() => this.cancelExercise(gotoPathName)}
        message={t1(
          'system_is_not_saved_all_question_answers_if_you_want_to_go_to_another_page_your_answers_may_be_lose',
        )}
      />
    );

    setTimeout(() => {
      showConfirmationDialog(contentDialog);
    });
  };

  showPendingToSaveQuestionsDialog = () => {
    const { learnItemIid, showPendingQuestionDialog } = this.props;

    const contentDialog = (
      <ConfirmToLeave
        learnItemIid={learnItemIid}
        showPendingToSaveQuestions
        message={t1(
          'system_is_trying_to_save_all_pending_question_answers_if_you_want_to_go_to_another_page_your_answers_may_be_lose_please_wait',
        )}
      />
    );

    setTimeout(() => {
      showPendingQuestionDialog(
        contentDialog,
        this.confirmDialogOptionsProperties,
      );
    });
  };

  showSavingAnswersConfirmation = (nextLocation) => {
    const { step, exercise, info } = this.props;

    const inlineExercise = get(info, 'inlineExercise');
    const userHasNotFinishedMCExercise =
      !inlineExercise && step === steps.MAIN && isNotOEExercise(exercise);

    if (nextLocation.pathname !== window.location.pathname) {
      if (this.thereArePendingQuestions()) {
        this.showPendingToSaveQuestionsDialog();
      } else if (this.thereAreFailedToSaveQuestions()) {
        this.showFailedToSaveQuestionsDialog(nextLocation.pathname);
      } else if (userHasNotFinishedMCExercise) {
        this.showFinishExerciseConfirmationDialog(nextLocation.pathname);
      }

      return false;
    }

    // allow user to navigate between questions (search param is changed)
    return nextLocation.search !== window.location.search;
  };

  thereArePendingQuestions = () => {
    const { info } = this.props;

    return (get(info, 'pendingQuestions') || []).length;
  };

  thereAreFailedToSaveQuestions = () => {
    const { info } = this.props;

    return (get(info, 'failToSaveTakeQuestions') || []).length;
  };

  closeConfirmToLeaveDialog = () => {
    const { dispatch } = this.props;

    dispatch(actions.closeAllDialogs());
  };

  closePendingQuestionsDialog = () => {
    const { dispatch } = this.props;

    dispatch(
      actions.handleOpenDialog(
        { openDialog: false },
        pendingSavingQuestionsDialogKey,
      ),
    );
  };

  renderErrorsFormPopup = (errors) => {
    const { questionsToDisplay } = this.props;

    if (!errors || !errors.length) {
      return;
    }

    Modal.warning({
      title: 'Có lỗi xảy ra. Vui lòng kiểm tra lại nội dung nhập liệu.',
      content: (
        <div>
          {errors.map((error, index) => {
            const question = get(error, 'question');
            const questionTitle = get(error, 'question.title');
            const questionId = get(error, 'question.id');
            const questionError = get(error, 'error', {});

            let title;
            if (errors.length > 1) {
              const questionOrder =
                questionsToDisplay.findIndex(
                  (question) => question.id == questionId,
                ) + 1;

              title = `Câu hỏi ${questionOrder}: ${
                questionTitle ? questionTitle : ''
              } có lỗi`;
            }

            return (
              <>
                {!!title && (
                  <div>
                    <b>{title}</b>
                  </div>
                )}
                <span>{oeErrorMappingKey(question, questionError)}</span>

                {index + 1 < errors.length ? <hr /> : null}
              </>
            );
          })}
        </div>
      ),
    });
  };

  // Với câu hỏi OE mà type là Digital support plan, ở một số module không cần user làm bài nhưng có sẵn default value, nên user sẽ không thao tác trên form mà click submit exercise luôn.
  // Thì khi submit bài tập, nếu lần đầu tiên sẽ đi save câu hỏi OE lại.
  submitOEQuestionBeforeFinishExercise = () => {
    const { course, questionsToDisplay, dispatch } = this.props;
    const courseIid = get(course, 'iid');

    const oeQuestion = (questionsToDisplay || []).filter((question) =>
      isOEQuestion(question),
    );

    if (!oeQuestion || !oeQuestion.length) {
      return false;
    }

    let shouldWait = false;

    questionsToDisplay.forEach((question) => {
      const formId = generateOEFormId(courseIid, get(question, 'iid'));
      const isOESupportPlan = isOESupportPlanLayoutType(question);

      if (isOESupportPlan) {
        shouldWait = true;

        dispatch(submit(formId));
      }
    });

    return shouldWait;
  };

  validateOEQuestionInExercise = () => {
    const { course, questionsToDisplay, getFormErrors, dispatch } = this.props;
    const courseIid = get(course, 'iid');

    const oeQuestion = (questionsToDisplay || []).filter((question) =>
      isOEQuestion(question),
    );

    if (!oeQuestion || !oeQuestion.length) {
      return true;
    }

    let errors = [];
    questionsToDisplay.forEach((question) => {
      const formId = generateOEFormId(courseIid, get(question, 'iid'));

      const error = getFormErrors(formId);
      if (error) {
        errors.push({
          question,
          error,
        });

        dispatch(submit(formId));
      }
    });

    if (errors.length) {
      this.renderErrorsFormPopup(errors);

      return false;
    }

    return true;
  };

  onFinishButtonClick = () => {
    const { requestFinish, learnItemIid, exercise } = this.props;

    if (this.thereArePendingQuestions()) {
      this.showPendingToSaveQuestionsDialog();

      return;
    }

    const shouldValidate = get(exercise, 'options.validate_question');
    if (!shouldValidate) {
      requestFinish(learnItemIid);

      return;
    }

    const questionFormValid = this.validateOEQuestionInExercise();
    if (!questionFormValid) {
      return;
    }

    const shouldWaitForSubmittedOEForm = this.submitOEQuestionBeforeFinishExercise();

    if (shouldWaitForSubmittedOEForm) {
      // TODO: refactor this code.
      setTimeout(() => {
        requestFinish(learnItemIid);
      });
    } else {
      requestFinish(learnItemIid);
    }
  };

  submitExercise = () => {
    const { retryNow } = this.props;

    retryNow();
  };

  cancelExercise = (gotoPathName) => {
    const { history, setExerciseConfirmationFlow } = this.props;

    if (gotoPathName) {
      setExerciseConfirmationFlow({ allowExitTheExercise: true });

      setTimeout(() => {
        history.push(gotoPathName);
      });
    }

    this.closeConfirmToLeaveDialog();
  };

  getTimeLeftDangerClass = (timeLeft) =>
    timeLeft && timeLeft.toString() <= '00:59'
      ? `${this.cssClass}-duration--danger`
      : '';

  cssClass = 'normal-exercise';

  onControlQuestionClick = (question) => {
    this.navigateToQuestion(question.uniqueId);
  };

  onNextButtonClick = () => {
    const { questionUniqueIdWhenClickNext } = this.props;

    this.navigateToQuestion(questionUniqueIdWhenClickNext);
  };

  onBackButtonClick = () => {
    const { questionUniqueIdWhenClickBack } = this.props;

    this.navigateToQuestion(questionUniqueIdWhenClickBack);
  };

  navigateToQuestion = (questionId) => {
    const {
      currentQuestionUniqueId,
      learnItemIid,
      info,
      saveItemInfoToStore,
      setCurrentQuestion,
    } = this.props;

    const currentQuestionType = get(
      info,
      `questions.${currentQuestionUniqueId}.answer.type`,
    );
    if (this.shouldAutoSaveAnswer(currentQuestionType)) {
      // mark current question as changing question so that child component know that it need to save its content
      saveItemInfoToStore(learnItemIid, {
        changingQuestionIid: currentQuestionUniqueId,
        nextQuestionIid: questionId,
      });
    } else {
      setCurrentQuestion(learnItemIid, questionId);
    }
  };

  onQuestionMouseEnter = (question) => {
    const { learnItemIid, setCurrentQuestion } = this.props;

    setCurrentQuestion(learnItemIid, question.uniqueId, false);
  };

  onFinishHandleChangingQuestion = (question, handleSuccess = false) => {
    const {
      learnItemIid,
      saveItemInfoToStore,
      info,
      setCurrentQuestion,
    } = this.props;

    if (handleSuccess && info.nextQuestionIid) {
      setCurrentQuestion(learnItemIid, info.nextQuestionIid, true);
    }

    saveItemInfoToStore(learnItemIid, {
      changingQuestionIid: null,
      nextQuestionIid: null,
    });
  };

  shouldAutoSaveAnswer = (questionType) => {
    return isOEQuestion(questionType);
  };

  shouldShowConfirmation = () => {
    const { step, exercise, info, exerciseConfirmationFlow = {} } = this.props;
    const inlineExercise = get(info, 'inlineExercise');

    const userHasNotFinishedMCExercise =
      !inlineExercise && step === steps.MAIN && isNotOEExercise(exercise);

    const { allowExitTheExercise } = exerciseConfirmationFlow;

    return (
      !allowExitTheExercise &&
      (userHasNotFinishedMCExercise ||
        this.thereAreFailedToSaveQuestions() ||
        this.thereArePendingQuestions())
    );
  };

  renderExerciseControl = () => {
    const {
      currentQuestionUniqueId,
      exercise,
      info,
      isControlBackButtonEnabled,
      isControlNextButtonEnabled,
      isControlQuestionClickable,
      onCheckAnswerButtonClick,
      shouldShowControlBackButton,
      shouldShowControlCheckButton,
      shouldShowControlFinishButton,
      shouldShowControlNextButton,
      shouldShowControlQuestions,
      onFinishReviewButtonClick,
      shouldShowControlFinishReviewButton,
      inlineExercise,
      isFinishButtonDisabled,
      userInfo,
      course,
      syllabus,
    } = this.props;

    const shouldShowControl =
      !get(exercise, 'options.hide_controls') &&
      [
        shouldShowControlNextButton,
        shouldShowControlFinishButton,
        shouldShowControlCheckButton,
        shouldShowControlBackButton,
        shouldShowControlQuestions,
        shouldShowControlFinishReviewButton,
      ].some((value) => value);

    const normalExerciseControl = exercise && (
      <NormalExerciseControl
        exercise={exercise}
        failToSaveTakeQuestions={info && info.failToSaveTakeQuestions}
        currentQuestionUniqueId={currentQuestionUniqueId}
        onFinishButtonOnClick={this.onFinishButtonClick}
        onCheckAnswerButtonClick={onCheckAnswerButtonClick}
        onBackButtonClick={this.onBackButtonClick}
        onNextButtonClick={this.onNextButtonClick}
        showNextButton={shouldShowControlNextButton}
        showCheckButton={shouldShowControlCheckButton}
        showBackButton={shouldShowControlBackButton}
        showControlQuestions={shouldShowControlQuestions}
        isBackButtonDisabled={!isControlBackButtonEnabled}
        isNextButtonDisabled={!isControlNextButtonEnabled}
        isQuestionClickable={isControlQuestionClickable}
        onQuestionClick={this.onControlQuestionClick}
        showFinishReviewButton={shouldShowControlFinishReviewButton}
        showQuestionSavingStatus
        onFinishReviewButtonOnClick={onFinishReviewButtonClick}
        inlineExercise={inlineExercise}
        showFinishButton={shouldShowControlFinishButton}
        isFinishButtonDisabled={
          isFinishButtonDisabled || !shouldShowControlFinishButton
        }
        userInfo={userInfo}
        course={course}
        syllabus={syllabus}
      />
    );
    if (!shouldShowControl) {
      return null;
    }

    if (isSmallScreen || inlineExercise) {
      return normalExerciseControl;
    }

    return (
      <Affix offsetBottom={0} className={`${this.cssClass}__control`}>
        {normalExerciseControl}
      </Affix>
    );
  };

  renderScoreView = () => {
    const { exercise, info, result } = this.props;
    if (shouldHideScore(exercise)) {
      return null;
    }

    return (
      <Portal id={portals.EXERCISE_POINT} insideCard title={t1('point')}>
        <div className={`${this.cssClass}-point text-center`}>
          {displayAsMarkingPassFailMode(exercise) ? (
            waitForMarking(result) ? (
              <span>{t1('wait_for_marking')}</span>
            ) : (
              <PassOrFail status={result && parseInt(result) > 0} />
            )
          ) : (
            <TotalPoint point={result} size="medium" />
          )}
          {this.shouldShowScoreNotice(exercise, info) && (
            <div>
              <DetailOnDialog
                renderPreview={({ showFull }) => (
                  <a
                    className={`${this.cssClass}__score-note text-muted`}
                    onClick={showFull}
                  >
                    Xem cách tính điểm
                  </a>
                )}
                renderFull={() => this.renderScoreExplanation(exercise)}
              />
            </div>
          )}
        </div>
      </Portal>
    );
  };

  getQuestionShuffleKey = () => {
    const { info, userInfo } = this.props;
    const takeId = get(info, 'takeId');
    const userIid = get(userInfo, 'iid');

    if (takeId && userIid) {
      return `${userIid}_${takeId}`;
    }

    return null;
  };

  render() {
    const {
      courseIid,
      currentQuestionUniqueId,
      displayMaxHeight,
      exercise,
      info,
      introSticky,
      isIntroStickyAudiosPlaying,
      onFinish,
      onQuestionBookMarkAreaClick,
      onQuestionDone,
      onRedoButtonClick,
      onReviewButtonClick,
      onStartButtonClick,
      onResumeButtonClick,
      onUserAnswer,
      options,
      questionsToDisplay,
      result,
      shouldDisplayCurrentQuestionAtTop,
      shouldShowQuestionHelpText,
      shouldShowQuestionLearningSuggestionWhenShowAnswer,
      shouldShowResultDetail,
      step,
      resultDetailActionProps,
      hasResultAction,
      isExerciseLoading,
      course,
      syllabus,
      inlineExercise,
      noCustomScroll,
      saveTakeSuccess,
      learnItemIid,
    } = this.props;
    const previousTakeLoadingError = info && info.previousTakeLoadingError;

    if (!step) {
      return (
        <div className={this.cssClass}>
          {!previousTakeLoadingError && <Loading circularLoadingIcon />}
        </div>
      );
    }

    if ([steps.NOT_STARTED, steps.NOT_CONTINUED].includes(step)) {
      const bankReviewLink = (sIid, exerciseIid) =>
        '/bank-review/' + sIid + '/' + exerciseIid;

      return (
        <div className={this.cssClass}>
          {get(this.props, 'mode') == 'preview' &&
          get(this.props, 'info.exam_template') ? (
            <a
              href={bankReviewLink(
                get(this.props, 'match.params.siid', 0),
                get(this.props, 'info.iid', 0),
              )}
              className="ant-btn ant-btn-primary"
              target="_blank"
              style={{ margin: 'auto', maxWidth: '400px' }}
            >
              <span>{t1('review_question_bank_and_exam_template')}</span>
            </a>
          ) : (
            <>
              <NormalExerciseNotStarted
                onRedoButtonClick={onRedoButtonClick}
                onResumeButtonClick={onResumeButtonClick}
                onStartButtonClick={onStartButtonClick}
                step={step}
                isExerciseLoading={isExerciseLoading}
              />
            </>
          )}
        </div>
      );
    }

    if (step === steps.RESULT) {
      return (
        <div className={this.cssClass}>
          <NormalExerciseResult
            hasAction={hasResultAction}
            shouldShowDetail={shouldShowResultDetail}
            onReviewButtonClick={onReviewButtonClick}
            onResumeButtonClick={onResumeButtonClick}
            onRedoButtonClick={onRedoButtonClick}
            onNextButtonClick={onFinish}
            options={options}
            result={result}
            learnInfo={info}
            course={course}
            syllabus={syllabus}
            exercise={exercise}
            detailActionProps={resultDetailActionProps}
            isExerciseLoading={isExerciseLoading}
          />
        </div>
      );
    }

    const introStickyPosition = introSticky
      ? introSticky.intro_sticky_position || 'top'
      : null;

    let introStickyScrollbarsProps = {};
    if (introStickyPosition === 'top') {
      introStickyScrollbarsProps = {
        autoHeight: true,
        autoHeightMax: (this.state.elementHeight * 30) / 100,
      };
    }

    const displayTemplate = get(
      this.props,
      'exercise.question_display_template',
    );

    const isMissingQuestion = isQuestionMissing(exercise);
    const isEmptyQuestion = isQuestionEmpty(exercise);

    const shouldBeShowConfirmation = this.shouldShowConfirmation();
    const shouldShowStatus =
      !inlineExercise &&
      !isMissingQuestion &&
      (step === steps.MAIN || step === steps.REVIEW) &&
      shouldShowExerciseQuestionsStatus(exercise);

    const mainIntroClassName = introStickyPosition
      ? `${this.cssClass}__main--intro-sticky-${introStickyPosition}`
      : '';

    const introClassName = introStickyPosition
      ? `${this.cssClass}__intro-sticky--${introStickyPosition}`
      : '';

    const exerciseDisplay = (
      <NormalExerciseDisplay
        onFinishHandleChangingQuestion={this.onFinishHandleChangingQuestion}
        className={`${this.cssClass}__display`}
        courseIid={courseIid}
        currentQuestionUniqueId={currentQuestionUniqueId}
        handleUserAnswer={onUserAnswer}
        maxHeight={displayMaxHeight}
        onQuestionBookMarkAreaClick={onQuestionBookMarkAreaClick}
        onQuestionDone={onQuestionDone}
        onQuestionMouseEnter={this.onQuestionMouseEnter}
        options={options}
        questions={questionsToDisplay}
        displayTemplate={displayTemplate}
        shouldDisplayCurrentQuestionAtTop={shouldDisplayCurrentQuestionAtTop}
        shouldShowQuestionHelpText={shouldShowQuestionHelpText}
        shouldShowQuestionLearningSuggestionWhenShowAnswer={
          shouldShowQuestionLearningSuggestionWhenShowAnswer
        }
        exerciseStep={step}
        course={course}
        syllabus={syllabus}
        practice={get(exercise, 'practice')}
        noMarking={get(exercise, 'no_marking')}
        inlineExercise={inlineExercise}
        saveTakeSuccess={saveTakeSuccess}
        learnItemIid={learnItemIid}
        questionShuffleKey={this.getQuestionShuffleKey()}
      />
    );

    return (
      <div className={`${this.cssClass}`}>
        <Prompt
          when={!!shouldBeShowConfirmation}
          message={this.showSavingAnswersConfirmation}
        />

        {info && info.duration && !inlineExercise && (
          <Portal id={portals.EXERCISE_TIMER} title={t1('exercise_time')}>
            <div
              className={`text-center ${
                this.cssClass
              }-duration ${this.getTimeLeftDangerClass(info.timeLeft)}`}
            >
              <span className={`${this.cssClass}-duration__icon ve-time`} />
              <span
                className={`${this.cssClass}-duration__text ${
                  !info.duration
                    ? `${this.cssClass}-duration__text--unlimited`
                    : ''
                }
                  `}
              >
                {typeof info.timeLeft !== 'undefined'
                  ? info.timeLeft
                  : info.duration
                  ? info.duration
                  : t1('unlimited_time')}
              </span>
            </div>
          </Portal>
        )}
        <div
          className={`${
            this.cssClass
          }__main learn-content ${mainIntroClassName}`}
        >
          {introSticky && (
            <div
              className={`${this.cssClass}__intro-sticky
                  ${introClassName}`}
            >
              <CustomScrollbars
                {...introStickyScrollbarsProps}
                className={`${this.cssClass}__intro-sticky-scroll`}
                autoHide
              >
                <Question
                  isContentAudiosPlaying={isIntroStickyAudiosPlaying}
                  shouldShowQuestionHelpText={false}
                  question={introSticky}
                  name="intro_sticky"
                  displayTemplate={displayTemplate}
                />
              </CustomScrollbars>
            </div>
          )}
          {isMissingQuestion ? (
            <QuestionMissing onRedoButtonClick={onRedoButtonClick} />
          ) : isEmptyQuestion ? (
            <div className="m-20">
              <Alert
                showIcon
                type="error"
                message={t1('this_section_has_no_question')}
              />
            </div>
          ) : noCustomScroll ? (
            exerciseDisplay
          ) : (
            <CustomScrollbars autoHide>{exerciseDisplay}</CustomScrollbars>
          )}
        </div>

        {!isEmptyQuestion && !isMissingQuestion && (
          <>
            {this.renderExerciseControl()}
            {shouldShowStatus && (
              <Portal id={portals.EXERCISE_STATUS_OF_QUESTIONS} insideCard>
                <ExerciseStatusOfQuestions exercise={exercise} />
              </Portal>
            )}
          </>
        )}

        {step === steps.REVIEW && this.renderScoreView()}
      </div>
    );
  }
}

NormalExercise.propTypes = {
  courseIid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentQuestionUniqueId: PropTypes.string,
  displayMaxHeight: PropTypes.number,
  exercise: PropTypes.shape(),
  info: PropTypes.shape(),
  introSticky: PropTypes.shape(),
  isControlBackButtonEnabled: PropTypes.bool,
  isControlNextButtonEnabled: PropTypes.bool,
  isControlQuestionClickable: PropTypes.func,
  isIntroStickyAudiosPlaying: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
  onCheckAnswerButtonClick: PropTypes.func,
  onComponentDidMount: PropTypes.func,
  onComponentWillUnmount: PropTypes.func,
  onFinish: PropTypes.func,
  onNextButtonClick: PropTypes.func,
  onQuestionBookMarkAreaClick: PropTypes.func,
  onQuestionDone: PropTypes.func,
  onRedoButtonClick: PropTypes.func,
  onReviewButtonClick: PropTypes.func,
  onStartButtonClick: PropTypes.func,
  onResumeButtonClick: PropTypes.func,
  onUserAnswer: PropTypes.func,
  options: PropTypes.shape(),
  questionsToDisplay: PropTypes.arrayOf(PropTypes.shape()),
  result: PropTypes.number,
  resultDetailActionProps: PropTypes.shape(),
  shouldDisplayCurrentQuestionAtTop: PropTypes.bool,
  shouldFeedbackInstantly: PropTypes.bool,
  shouldShowControlBackButton: PropTypes.bool,
  shouldShowControlCheckButton: PropTypes.bool,
  shouldDisableControlFinishButton: PropTypes.bool,
  shouldShowControlNextButton: PropTypes.bool,
  shouldShowControlQuestions: PropTypes.bool,
  shouldShowQuestionHelpText: PropTypes.bool,
  shouldShowQuestionLearningSuggestionWhenShowAnswer: PropTypes.bool,
  shouldShowResultDetail: PropTypes.bool,
  step: PropTypes.string,
};

NormalExercise.defaultProps = {
  courseIid: null,
  currentQuestionUniqueId: null,
  displayMaxHeight: 0,
  exercise: null,
  info: null,
  introSticky: null,
  isControlBackButtonEnabled: false,
  isControlNextButtonEnabled: false,
  isControlQuestionClickable: () => true,
  isIntroStickyAudiosPlaying: false,
  onBackButtonClick: null,
  onCheckAnswerButtonClick: null,
  onComponentDidMount: null,
  onComponentWillUnmount: null,
  onFinish: () => {},
  onNextButtonClick: null,
  onQuestionBookMarkAreaClick: null,
  onQuestionDone: null,
  onRedoButtonClick: () => {},
  onReviewButtonClick: null,
  onStartButtonClick: null,
  onResumeButtonClick: null,
  onUserAnswer: null,
  options: {},
  questionsToDisplay: [],
  result: null,
  resultDetailActionProps: null,
  shouldDisplayCurrentQuestionAtTop: true,
  shouldFeedbackInstantly: false,
  shouldShowControlBackButton: false,
  shouldShowControlCheckButton: true,
  shouldDisableControlFinishButton: false,
  shouldShowControlNextButton: true,
  shouldShowControlQuestions: true,
  shouldShowQuestionHelpText: true,
  shouldShowQuestionLearningSuggestionWhenShowAnswer: false,
  shouldShowResultDetail: false,
  step: null,
};

const mapStateToProps = (state, props) => {
  const isExerciseLoading =
    state.loading['exercise_fetching'] === loadingStatuses.LOADING;
  const learnItemIid = props.learnItemIid || state.learn.itemIid;
  const progress = state.trackerProgress && state.trackerProgress[learnItemIid];

  const exercisesFromInfo = getFullExercisesWithQuestionInfoSelector(state)(
    learnItemIid,
  );
  const fullExercise = exercisesFromInfo && exercisesFromInfo[0];

  const navigationInfo =
    getNavigateInfoOfExerciseSelector(state)(learnItemIid) || {};
  const {
    currentQuestionUniqueId,
    previousQuestionUniqueId: questionUniqueIdWhenClickBack,
    nextQuestionUniqueId: questionUniqueIdWhenClickNext,
    introStickyUniqueId,
    uniqueIdsInQuestionGroup,
  } = navigationInfo;

  const [introSticky] = getQuestionsWithFullInfoFromUniqueIdsInExercises(
    exercisesFromInfo,
    [introStickyUniqueId],
  );

  const info = getLearnItemInfoSelector(state)(learnItemIid);
  const step = info && info.step;
  const saveTakeSuccess = get(info, 'saveTakeSuccess');

  const isIntroStickyAudiosPlaying = info && info.isIntroStickyAudiosPlaying;

  let questionsToDisplay = [];

  if (step === steps.REVIEW) {
    questionsToDisplay = getQuestionsWithFullInfoFromUniqueIdsInExercises(
      exercisesFromInfo,
      'all',
      (question) => !isIntroSticky(question),
    );
  } else {
    questionsToDisplay = getQuestionsWithFullInfoFromUniqueIdsInExercises(
      exercisesFromInfo,
      uniqueIdsInQuestionGroup,
    );
  }

  const result = progress && progress.p;

  const learn = get(state, 'learn');
  const tree = get(state, 'tree', {});
  const questionStatusInfo = getQuestionStatusInfoOfItem(
    learn,
    tree,
    learnItemIid,
  );
  const autoMarking = get(questionStatusInfo, 'autoMarking');
  const teacherMarking = get(questionStatusInfo, 'teacherMarking');

  const isAllQuestionAnswered =
    autoMarking.total === autoMarking.submitted &&
    teacherMarking.total === teacherMarking.submitted;

  const shouldShowResultDetail =
    get(info, 'options.can_fix_wrong_questions') && info.shouldShowResultDetail;

  return {
    nodes: state.tree,
    currentQuestionUniqueId,
    exercise: fullExercise,
    form: String(learnItemIid),
    info,
    introSticky,
    isIntroStickyAudiosPlaying,
    learnItemIid,
    mode: info && info.mode,
    options: info && info.options,
    questionUniqueIdWhenClickBack,
    questionUniqueIdWhenClickNext,
    questionsToDisplay,
    result,
    shouldDisplayCurrentQuestionAtTop:
      info && info.shouldDisplayCurrentQuestionAtTop,
    step,
    isAllQuestionAnswered,
    shouldShowResultDetail,
    isExerciseLoading,
    exerciseConfirmationFlow: state.learn.exerciseConfirmationFlow,
    saveTakeSuccess,
    getFormErrors: (formId) => getFormSyncErrors(formId)(state),
    learningProgress: progress,
  };
};

const mapDispatchToProps = (dispatch, { learnItemIid, inlineExercise }) => ({
  checkAnswers: (itemIid, questionUniqueIds) => {
    dispatch(displayQuestionsCheckedResult(itemIid, questionUniqueIds));
  },
  setCurrentQuestion: (
    itemIid,
    questionUniqueId,
    shouldDisplayCurrentQuestionAtTop,
  ) => {
    dispatch(
      setCurrentQuestionInExerciseAction(
        itemIid,
        questionUniqueId,
        shouldDisplayCurrentQuestionAtTop,
        inlineExercise,
      ),
    );
  },
  saveUserAnswer: (itemIid, questionUniqueId, take) => {
    dispatch(saveAnswer(itemIid, questionUniqueId, take));
  },
  saveItemInfoToStore: (itemIid, info, shouldUpdate) => {
    dispatch(saveItemInfoToStoreAction(itemIid, info, shouldUpdate));
  },
  saveQuestionInfo: (itemIid, questionUniqueId, info) => {
    dispatch(saveItemQuestionInfoAction(itemIid, questionUniqueId, info));
  },
  startExercise: (itemIid) => {
    dispatch(startExerciseAction(itemIid));
  },
  resumeExercise: (itemIid) => {
    dispatch(resumeExerciseAction(itemIid));
  },
  redoExercise: (itemIid, info, questionUniqueId, wrongQuestionOnly) => {
    dispatch(
      redoExerciseAction(itemIid, info, questionUniqueId, wrongQuestionOnly),
    );
  },
  redoExerciseWhenQuestionsMissing: (itemIid) => {
    dispatch(redoExerciseActionWhenQuestionsMissing(itemIid));
  },
  reviewExercise: (itemIid) => {
    dispatch(reviewExerciseAction(itemIid));
  },
  requestFinish: (itemIid) => {
    dispatch(finishExercise(itemIid));
  },
  requestFinishReview: (itemIid) => {
    dispatch(finishReviewExercise(itemIid));
  },
  loadPreviousTake: (itemIid) => {
    dispatch(loadPreviousTake(itemIid));
  },
  retryNow: () => {
    const isImmediate = true;
    dispatch(retrySavingAnswers(learnItemIid, isImmediate));
  },
  setExerciseConfirmationFlow: (exerciseConfirmationFlow) => {
    dispatch(setExerciseConfirmationFlowAction(exerciseConfirmationFlow));
  },
  showConfirmationDialog: (contentDialog, options) => {
    dispatch(
      actions.handleOpenDialog(
        { contentDialog, optionsProperties: options },
        confirmToLeaveDialogKey,
      ),
    );
  },
  showPendingQuestionDialog: (contentDialog, options) => {
    dispatch(
      actions.handleOpenDialog(
        { contentDialog, optionsProperties: options },
        pendingSavingQuestionsDialogKey,
      ),
    );
  },
});

const mergeProps = (stateProps, dispatchProps, props) => {
  const {
    info,
    learnItemIid,
    mode,
    options,
    questionUniqueIdWhenClickBack,
    questionUniqueIdWhenClickNext,
    questionsToDisplay,
    step,
    isAllQuestionAnswered,
  } = stateProps;
  const {
    checkAnswers,
    redoExercise,
    redoExerciseWhenQuestionsMissing,
    requestFinish,
    reviewExercise,
    saveItemInfoToStore,
    saveQuestionInfo,
    saveUserAnswer,
    setCurrentQuestion,
    startExercise,
    resumeExercise,
    requestFinishReview,
  } = dispatchProps;
  const { onFinish, shouldFeedbackInstantly, isReview } = props;
  const isControlNextButtonEnabled = Boolean(questionUniqueIdWhenClickNext);
  const isControlBackButtonEnabled = Boolean(questionUniqueIdWhenClickBack);

  const shouldShowControlNextButton =
    isControlNextButtonEnabled || isControlBackButtonEnabled;
  const shouldShowControlFinishButton = true;
  const shouldShowControlBackButton =
    isControlNextButtonEnabled || isControlBackButtonEnabled;

  return {
    ...props,
    ...stateProps,
    ...dispatchProps,
    onCheckAnswerButtonClick: () => {
      const questionUniqueIdsToCheck =
        questionsToDisplay &&
        questionsToDisplay.map((question) => question.uniqueId);
      checkAnswers(learnItemIid, questionUniqueIdsToCheck);
    },
    onUserAnswer: (question, take) => {
      saveUserAnswer(learnItemIid, question.uniqueId, take);
    },
    onComponentDidMount: () => {
      saveItemInfoToStore(learnItemIid, {
        status: statuses.DOING,
      });
    },
    onQuestionBookMarkAreaClick: (question) => {
      saveQuestionInfo(learnItemIid, question.uniqueId, {
        isTicked: !question.isTicked,
      });
    },
    onFinish: () => {
      if (typeof onFinish === 'function') {
        onFinish();
      }
    },
    onStartButtonClick: () => {
      startExercise(learnItemIid);
    },
    onResumeButtonClick: () => {
      resumeExercise(learnItemIid);
    },
    onFinishReviewButtonClick: () => {
      requestFinishReview(learnItemIid);
    },
    onRedoButtonClick: (redoWhenQuestionsMissing = false) => {
      if (screenfull && screenfull.enabled) {
        screenfull.exit();
      }
      Modal.confirm({
        centered: true,
        title: `${t1('are_you_sure_to_redo_this_exercise')}?`,
        content: (
          <span className="text-danger">
            {t1('if_you_redo_exercise_progress_will_be_reset')}!
          </span>
        ),
        onOk() {
          if (redoWhenQuestionsMissing) {
            redoExerciseWhenQuestionsMissing(learnItemIid);
          } else {
            redoExercise(learnItemIid);
          }
        },
        cancelText: t1('cancel'),
        okText: t1('redo'),
      });
    },
    onReviewButtonClick: () => {
      reviewExercise(learnItemIid);
    },
    isControlBackButtonEnabled,
    isControlNextButtonEnabled,
    shouldShowControlNextButton,
    shouldShowControlFinishButton,
    // don't show if all questions are open ended
    shouldShowControlCheckButton: Boolean(
      options &&
        options.can_review &&
        questionsToDisplay &&
        questionsToDisplay.some(
          (question) => question.type != questionTypes.TYPE_OPEN_ENDED,
        ),
    ),
    shouldShowControlBackButton,
    ...(shouldFeedbackInstantly
      ? {
          onQuestionDone: (question) => {
            checkAnswers(learnItemIid, [question.uniqueId]);
          },
          shouldShowControlFinishButton: false,
          shouldShowControlCheckButton: false,
        }
      : {}),
    ...(options && options.question_sequence
      ? {
          isControlQuestionClickable: (question) =>
            Array.isArray(questionsToDisplay) &&
            questionsToDisplay.findIndex(
              (displayedQuestion) =>
                displayedQuestion &&
                question &&
                displayedQuestion.uniqueId === question.uniqueId,
            ) !== -1,
          onQuestionBookMarkAreaClick: null,
          shouldShowControlNextButton: Boolean(
            questionUniqueIdWhenClickNext &&
              questionsToDisplay &&
              questionsToDisplay.every(
                (question) => question.shouldDisplayCheckedResult,
              ),
          ),
          shouldShowControlCheckButton: Boolean(
            questionsToDisplay &&
              questionsToDisplay.some(
                (question) =>
                  !question.shouldDisplayCheckedResult &&
                  question.type != questionTypes.TYPE_OPEN_ENDED,
              ),
          ),
          shouldShowControlFinishButton: Boolean(
            !questionUniqueIdWhenClickNext &&
              questionsToDisplay &&
              questionsToDisplay.every(
                (question) => question.shouldDisplayCheckedResult,
              ),
          ),
        }
      : {}),
    ...(options && options.only_show_finish_button_when_reach_last_question
      ? {
          onQuestionBookMarkAreaClick: null,
          isControlBackButtonEnabled: Boolean(questionUniqueIdWhenClickBack),
          shouldShowControlCheckButton: false,
          shouldShowControlFinishButton: Boolean(isAllQuestionAnswered),
        }
      : {}),
    ...(options && options.can_fix_wrong_questions
      ? {
          resultDetailActionProps: {
            onFixButtonClick: (question) => {
              if (question && question.uniqueId) {
                redoExercise(learnItemIid, null, question.uniqueId, true);
              }
            },
          },
        }
      : {}),
    ...(step === steps.REVIEW
      ? {
          isControlQuestionClickable: () => true,
          onQuestionBookMarkAreaClick: null,
          shouldShowControlNextButton: false,
          shouldShowControlBackButton: false,
          shouldShowControlCheckButton: false,
          shouldShowControlFinishButton: false,
          shouldShowControlFinishReviewButton: !isReview,
        }
      : {}),
    ...(mode === modes.PREVIEW
      ? {
          onQuestionBookMarkAreaClick: null,
        }
      : {}),
    hasResultAction: mode !== modes.REVIEW,
    shouldShowQuestionLearningSuggestionWhenShowAnswer:
      options && options.show_suggestion_after_questions,
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
  reduxForm({}),
  withUserInfo,
)(NormalExercise);
