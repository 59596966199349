import React, { Component } from 'react';
import withUserInfo from 'common/hoc/withUserInfo';
import { isLTTUStaff } from 'components/admin/user/utils';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import schema from './schema';
import { t1 } from 'translate';

import TakeResults from './Results';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import { isGVSPRoute } from 'utils/router';
import fetchData from 'components/common/fetchData';
import { getTeacherCourseAPIObject } from 'components/admin/common/apiHelper';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { isFreezeMarking } from 'utils/Util';
import get from 'lodash.get';
import FreezeAlert from 'components/admin/course/common/FreezeAlert';
import { compose } from 'redux';
import { submit } from 'redux-form';

export const getSearchTakesFormId = (courseIid) =>
  `${courseIid || 'opened-question'}-takes`;

const getSearchFormId = (formId) => {
  return formId || 'teacher-opened-question-search';
};

class SearchTakes extends Component {
  getSelectedCourse = () => {
    const {
      formValues = {},
      courseList = [],
      courseDefault,
      courseIid: courseIidDefault,
    } = this.props;

    const { courseIid = '' } = formValues;

    const iid = courseIid || courseIidDefault;

    const selectedCourse =
      iid && courseList.length
        ? courseList.find((course) => course.iid.toString() === courseIid)
        : null;
    return {
      iid: iid,
      courseIid: iid,
      ...(selectedCourse || courseDefault || {}),
    };
  };

  refreshFormSubmit = () => {
    const { dispatch, formId } = this.props;
    if (typeof dispatch === 'function') {
      dispatch(submit(getSearchFormId(formId)));
    }
  };

  renderResultComponent = (items, props, itemsNeedSlice) => {
    const course = this.getSelectedCourse();
    const {
      userInfo = '',
      noMarking,
      courseIid,
      peerMarking,
      canPeerMarkingQuestion,
    } = this.props;
    const viewOnly = isFreezeMarking(course, userInfo);

    let itemsToRender = items;

    if (itemsNeedSlice && items) {
      itemsToRender = items.slice(0, 10);
    }

    return (
      <TakeResults
        {...props}
        items={itemsToRender}
        courseIid={courseIid}
        peerMarking={peerMarking}
        viewOnly={viewOnly}
        refreshFormSubmit={this.refreshFormSubmit}
        noMarking={noMarking}
        canPeerMarkingQuestion={canPeerMarkingQuestion}
      />
    );
  };

  renderNoResultComponent = () => {
    const { questionIid } = this.props;
    const text = questionIid
      ? t1('this_question_is_not_yet_done_by_any_one_in_the_course')
      : t1('no_open_ended_questions_to_be_marked');

    return (
      <SimpleNoResult
        text={text}
        imagePath={!questionIid && '/media/images/empty/essay-empty.png'}
      />
    );
  };

  generateHiddenFields = (defaultCourseIid) => {
    const {
      noSelectCourse,
      questionIid,
      peerMarking,
      location,
      userInfo,
      takeLimit,
    } = this.props;

    const isHideRejectCheckbox =
      !isGVSPRoute(location) && isLTTUStaff(userInfo);
    const hiddenFields = {
      questionIid,
      peerMarking: peerMarking ? 1 : 0,
      ...(isHideRejectCheckbox ? { rejected: 0 } : {}),
      takeLimit,
    };

    return noSelectCourse
      ? {
          ...hiddenFields,
          courseIid: defaultCourseIid,
        }
      : hiddenFields;
  };

  render() {
    const {
      courseIid = '',
      noSelectCourse,
      noSearchForm,
      itemsNeedSlice,
      hidePagination,
      isNotYetMarkedShown = true,
      isTakeUpdatedShown = true,
      formId,
      isGVSP,
    } = this.props;

    const paginationProps = {
      itemPerPage: [10, 20, 50, 100],
    };

    const courseSelected = this.getSelectedCourse();

    return (
      <>
        <FreezeAlert course={courseSelected} insideAlert />

        <SearchWrapper
          hidePagination={hidePagination}
          hiddenFields={this.generateHiddenFields(courseIid)}
          formid={getSearchFormId(formId)}
          schema={noSearchForm ? undefined : schema(courseSelected, isGVSP)}
          renderResultsComponent={(items, props) =>
            this.renderResultComponent(items, props, itemsNeedSlice)
          }
          renderNoResultComponent={this.renderNoResultComponent}
          alternativeApi={
            this.props.alternativeApi || '/course/marking/get-open-ended-takes'
          }
          paginationProps={paginationProps}
          isNotYetMarkedShown={isNotYetMarkedShown}
          isTakeUpdatedShown={isTakeUpdatedShown}
          classPaginationWrapper="pagination-default"
          node={courseSelected}
          noSelectCourse={noSelectCourse}
          showSearchButton={false}
          autoSearchWhenValuesChange
        />
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { formId } = props;

  return {
    formValues: getFormValues(getSearchFormId(formId))(state),
  };
};

const fetchCourseList = fetchData((props) => {
  const { courseIid = '' } = props;
  const organizations = get(props, 'orgIids', []);
  const params = {
    organizations,
  };

  if (courseIid) {
    params['iid'] = courseIid;
    params['is_active'] = null;
  }

  const apiData = getTeacherCourseAPIObject(params);

  return {
    ...apiData,
    propKey: 'courseList',
  };
});

export default compose(
  connect(mapStateToProps),
  fetchCourseList,
  withUserInfo,
)(SearchTakes);
