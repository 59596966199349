import { t1 } from 'translate';

const names = (notIn, props = {}) => ({
  type: 'text',
  multiLine: true,
  hintText: `Nguyễn Văn A, Phạm Văn B, ... ${t1(
    'separated_by_comma_or_new_line',
  )}`,
  floatingLabelText: notIn ? t1('user_names_not_in') : t1('user_names'),
  fullWidth: true,
  ...props,
});

export default names;
