import React from 'react';
import Timeline from 'antd/lib/timeline';
import { t1 } from 'translate';
import get from 'lodash.get';
import { timestampToDateTimeString } from 'common/utils/Date';

import 'components/bdtx/review-program/common/history/styles.scss';
import DetailOnDialog from 'components/common/detail-on-dialog';
import ReviewEvidenceForm from 'components/bdtx/review-program/common/review-evidence/ReviewEvidenceForm';
import lodashGet from 'lodash.get';
import Icon from 'components/common/Icon';
import Tooltip from 'antd/lib/tooltip';

const { Item } = Timeline;

const History = ({ node, onReviewSuccessful, allowUpdateEvidence }) => {
  const reviewStatus = get(node, 'review_status');
  const reviewHistories = get(node, 'review_histories', []);

  const histories = reviewHistories.sort((a, b) => {
    if (a.ts < b.ts) {
      return 1;
    }
    if (a.ts > b.ts) {
      return -1;
    }
    return 0;
  });

  const renderCurrentReviewInfo = (history) => {
    const { status, content, evidence } = history || {};
    return (
      <>
        {status === 'rejected' ? (
          <div className="d-flex d-flex-wrap">
            {t1('current_reason')}:&nbsp;
            {content ? content : t1('vgd_have_no_reason')}
          </div>
        ) : null}

        {status === 'approved' ? (
          <DetailOnDialog
            renderPreview={({ showFull }) => {
              let content;
              if (evidence) {
                content = (
                  <span
                    onClick={showFull}
                    className="text-primary cursor-pointer"
                  >
                    {allowUpdateEvidence
                      ? t1('has_evidence.click_to_update')
                      : t1('has_evidence.click_to_view_detail')}
                  </span>
                );
              } else {
                content = (
                  <span
                    onClick={allowUpdateEvidence ? showFull : () => {}}
                    className="text-danger cursor-pointer"
                  >
                    {allowUpdateEvidence
                      ? t1('vgd_have_no_evidence.click_to_update')
                      : t1('vgd_have_no_evidence')}
                  </span>
                );
              }

              return (
                <div className="d-flex align-items-center">
                  <span>{t1('current_evidence')}:&nbsp;</span>
                  <Tooltip title={t1('click_to_update_evidence')}>
                    <span className="d-flex align-items-center">
                      {content}
                      <Icon icon="edit" />
                    </span>
                  </Tooltip>
                </div>
              );
            }}
            renderFull={({ closeDialog }) => {
              return (
                <ReviewEvidenceForm
                  node={node}
                  onUpdateReviewEvidence={onReviewSuccessful}
                  readOnly={!allowUpdateEvidence}
                />
              );
            }}
            dialogOptionsProperties={{
              title: t1('view_review_evidence'),
              modal: true,
            }}
          />
        ) : null}
      </>
    );
  };

  const renderReviewInfo = (history) => {
    const { status, content, evidence } = history || {};
    return (
      <>
        {status === 'rejected' && content ? (
          <div className="d-flex d-flex-wrap">
            {t1('reason')}:&nbsp;{content}
          </div>
        ) : null}

        {status === 'approved' && evidence ? (
          <DetailOnDialog
            renderPreview={({ showFull }) => {
              return (
                <>
                  <span
                    onClick={showFull}
                    className="text-primary cursor-pointer"
                  >
                    {t1('vgd_has_update_evidence.click_to_view_detail')}
                  </span>
                </>
              );
            }}
            renderFull={({ closeDialog }) => {
              return (
                <ReviewEvidenceForm
                  node={{
                    review_evidence: evidence,
                  }}
                  readOnly
                />
              );
            }}
            dialogOptionsProperties={{
              title: t1('view_review_evidence'),
              modal: true,
            }}
          />
        ) : (
          <span className="text-danger cursor-pointer">
            {t1('vgd_have_no_evidence')}
          </span>
        )}
      </>
    );
  };

  const hasReview = typeof reviewStatus !== 'undefined';
  const reviewClassName = hasReview
    ? reviewStatus === 'approved'
      ? 'text-success'
      : 'text-danger'
    : '';

  const firstHistory = lodashGet(histories, '0');

  return (
    <div className="history-timeline-container white-background">
      <div className="m-t-10">
        <span className={'m-r-10'}>{t1('current_status')}:</span>
        <span>
          {hasReview ? (
            <>
              <span className={`text-bold ${reviewClassName}`}>
                {t1(`vgd_${reviewStatus}`)}
              </span>

              <div className="m-t-5">
                {renderCurrentReviewInfo(firstHistory)}
              </div>
            </>
          ) : (
            <span>{t1('wait_for_vgd_review')}</span>
          )}
        </span>
      </div>

      <hr className={'m-b-15 m-t-15'} />

      <h3>{t1('approval_history')}</h3>

      <Timeline>
        {histories.map((history) => {
          const { status, u, ts } = history;

          return (
            <Item
              className="timeline-item"
              color={status === 'rejected' ? 'red' : 'approved'}
            >
              <div className={'d-flex d-flex-wrap'}>
                <span
                  className={`${
                    status === 'rejected' ? 'text-danger' : 'text-success'
                  }`}
                >
                  {t1(`vgd_${status}`)}
                </span>
                &nbsp;{t1('by')}&nbsp;
                {u.name}&nbsp;(
                {timestampToDateTimeString(ts, { type: 'short_date' })})
              </div>

              {renderReviewInfo(history)}
            </Item>
          );
        })}
      </Timeline>
    </div>
  );
};

export default History;
