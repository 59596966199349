import get from 'lodash.get';

export const correctLevel = {
  correct: 2,
  correct_not_enough: 1,
  incorrect: 0,
};

export const isCorrectItemLikert = (
  radioValue,
  questionIndex,
  correctAnswers,
  newData,
) => {
  const correctAnswer = get(correctAnswers, `[${questionIndex}]`, []);
  const answer = get(newData, `[${questionIndex}]`, []);
  const isCorrect = correctAnswer && correctAnswer.includes(String(radioValue));
  if (isCorrect && correctAnswer.length === answer.length) {
    return correctLevel.correct;
  }

  if (isCorrect) {
    return correctLevel.correct_not_enough;
  }

  return correctLevel.incorrect;
};
