import { t1 } from 'translate';
import { isEmailString, getRestrictionWords } from 'common/utils/string';
import { stripHTML } from '../utils/string';

/* eslint-disable import/prefer-default-export */
export const matchRegex = (regex, errorText) => {
  let localErrorText = errorText;
  localErrorText =
    localErrorText || t1('this_field_does_not_match_regex_%s', [regex]);
  return (value) => {
    if (!value || !regex) {
      return '';
    }
    return regex.test(value) ? '' : localErrorText;
  };
};

export const isEmail = (errorText) => {
  const localErrorText = errorText || t1('this_is_not_a_valid_email');
  return (value) => {
    if (!value) {
      return '';
    }
    return isEmailString(value.trim()) ? '' : localErrorText;
  };
};

export const isPhoneNumber = (errorText) => {
  const localErrorText = errorText || t1('this_is_not_a_valid_phone_number');
  return matchRegex(/(\+84|84|0)+([0-9]{9})\b/, localErrorText);
};

export const required = (errorText) => {
  const localErrorText = errorText || t1('this_field_is_required');
  return (value) => {
    let valueFormatted = value;
    if (typeof valueFormatted === 'string') {
      valueFormatted = valueFormatted.trim();
    }

    return ['', null, undefined].includes(valueFormatted) ||
      (Array.isArray(valueFormatted) && valueFormatted.length === 0)
      ? localErrorText
      : undefined;
  };
};

export const requiredRTE = (errorText) => {
  const blackList = ['<p><br></p>', '<p></p>'];
  const localErrorText = errorText || t1('this_field_is_required');

  return (value) => {
    const valueTrim = stripHTML(value);
    return ['', null, undefined].includes(valueTrim) ||
      blackList.includes(valueTrim)
      ? localErrorText
      : undefined;
  };
};

export const requiredQuestion = (errorText) => {
  let localErrorText = errorText;
  localErrorText = localErrorText || t1('this_field_is_required');
  return (value) =>
    value && value.filter((v) => v.is_answer).length === 0
      ? localErrorText
      : undefined;
};

export const dateGreaterThan = (comparedTime, errorText) => (value) => {
  if (!value || !comparedTime) {
    return null;
  }
  if (typeof value !== 'number') {
    value = Date.parse(value) / 1000;
  }
  if (typeof comparedTime !== 'number') {
    comparedTime = Date.parse(comparedTime) / 1000;
  }
  if (
    typeof value === 'number' &&
    typeof comparedTime === 'number' &&
    parseInt(value, 10) >= parseInt(comparedTime, 10)
  ) {
    return null;
  }
  return errorText || t1('%s_is_not_greater_than_%s', [value, comparedTime]);
};

export const dateLessThan = (comparedTime, errorText) => (value) => {
  if (!value || !comparedTime) {
    return null;
  }
  if (typeof value !== 'number') {
    value = Date.parse(value) / 1000;
  }
  if (typeof comparedTime !== 'number') {
    comparedTime = Date.parse(comparedTime) / 1000;
  }

  if (parseInt(value, 10) <= parseInt(comparedTime, 10)) {
    return null;
  }
  return errorText || t1('%s_is_not_less_than_%s', [value, comparedTime]);
};

export const ifJSON = (errorText) => (value) => {
  try {
    return JSON.parse(value);
  } catch (ex) {
    return errorText;
  }
};

export const inRange = (min, max, errorText) => (value) => {
  const valueToCheck =
    typeof value === 'string' ? stripHTML(value).length : value;

  if (typeof valueToCheck === 'undefined') {
    return undefined;
  }
  if (
    typeof min !== 'undefined' &&
    typeof max !== 'undefined' &&
    min !== null &&
    max !== null
  ) {
    if (min > valueToCheck || valueToCheck > max) {
      return errorText || t1('value_must_be_between_%s_and_%s', [min, max]);
    }
    return undefined;
  }
  if (typeof min !== 'undefined' && min !== null) {
    if (min > valueToCheck) {
      return errorText || t1('value_must_be_greater_than_%s', [min]);
    }
    return undefined;
  }
  if (typeof max !== 'undefined' && max !== null) {
    if (valueToCheck > max) {
      return errorText || t1('value_must_be_smaller_than_%s', [max]);
    }
    return undefined;
  }
  return undefined;
};

export const isNumber = (errorText) => {
  let localErrorText = errorText;
  localErrorText = localErrorText || t1('this_field_is_not_a_number');
  return (value) => {
    if (['', null, undefined].includes(value)) return undefined;
    return isNaN(value) ? localErrorText : undefined;
  };
};

export const isInteger = (errorText) => {
  let localErrorText = errorText;
  localErrorText = localErrorText || t1('this_field_is_not_an_integer');
  return (value) => {
    if (['', null, undefined].includes(value)) return undefined;
    if (isNumber(localErrorText)(value)) {
      return localErrorText;
    }
    return String(parseInt(value, 10)) !== String(value)
      ? localErrorText
      : undefined;
  };
};

export const validationWithCondition = (validate, condition) => (value) =>
  condition ? validate(value) : undefined;

export const usersMappedRoles = (errorText) => (value) => {
  if (!value) {
    return errorText;
  }

  let wasMappedRoles = true;
  value.forEach((user) => {
    if (!user.roles || user.roles.length < 1) {
      wasMappedRoles = false;
    }
  });

  return wasMappedRoles ? undefined : errorText;
};

export const isCmnd = (errorText = t1('this_is_not_valid_id_number')) => {
  //Regex CMND: https://github.com/phpviet/validation/blob/master/src/Rules/IdVN.php
  // const id = /^((((0[0-8]|1[0-9]|2[0-9]|3[0-8])\d{7})|((09[015]|23[01]|245|28[015])\d{6}))|(((0[0-8]|1[0-9]|2[0-9]|3[0-8])\d{10})|((09[015]|23[01]|245|28[015])\d{9}))|((0[012468]|1[0124579]|2[024-7]|3[013-8]|4[0245689]|5[12468]|6[024678]|7[024579]|8[0234679]|9[1-6])\d{10}))$/;

  const id = /^([0-9]{9}|[0-9]{12})+$/;

  return (value) => {
    if (!value) {
      return '';
    }

    return id.test(value.trim()) ? '' : errorText;
  };
};

export const isCCCD = (errorText = t1('this_is_not_valid_id_number')) => {
  //Regex CMND: https://github.com/phpviet/validation/blob/master/src/Rules/IdVN.php
  // const id = /^((((0[0-8]|1[0-9]|2[0-9]|3[0-8])\d{7})|((09[015]|23[01]|245|28[015])\d{6}))|(((0[0-8]|1[0-9]|2[0-9]|3[0-8])\d{10})|((09[015]|23[01]|245|28[015])\d{9}))|((0[012468]|1[0124579]|2[024-7]|3[013-8]|4[0245689]|5[12468]|6[024678]|7[024579]|8[0234679]|9[1-6])\d{10}))$/;

  const id = /^([0-9]{12})+$/;

  return (value) => {
    if (!value) {
      return '';
    }

    return id.test(value.trim()) ? '' : errorText;
  };
};

export const isFullName = (errorText = t1('this_is_not_valid_name')) => {
  if (window.isETEP) {
    return (value) => {
      if (!value) {
        return '';
      }

      return /\d/.test(value) ? errorText : '';
    };
  }

  const regexPattern = /^[a-zA-ZAÁÀẢÃẠÂẤẦẨẪẬĂẮẰẲẴẶEÉÈẺẼẸÊẾỀỂỄỆIÍÌỈĨỊOÓÒỎÕỌÔỐỒỔỖỘƠỚỜỞỠỢUÚÙỦŨỤƯỨỪỬỮỰYÝỲỶỸỴĐaáàảãạâấầẩẫậăắằẳẵặeéèẻẽẹêếềểễệiíìỉĩịoóòỏõọôốồổỗộơớờởỡợuúùủũụưứừửữựyýỳỷỹỵđ ,.'-]+$/u;

  return (value) => {
    if (!value) {
      return '';
    }

    return regexPattern.test(value) ? '' : errorText;
  };
};

export const requiredImagePlaceHolder = (errorText) => {
  let localErrorText = errorText;
  localErrorText = localErrorText || t1('this_field_is_required');

  return (value) => {
    if (!value || !value.image || !value.image.length) {
      return localErrorText;
    }
    return undefined;
  };
};

export const requiredTableLikert = (errorText = '') => {
  return (value) => {
    if (value && value.length) {
      const itemFail = value.some((item) => {
        return !item || !item.length;
      });

      if (itemFail) {
        return errorText;
      }
      return undefined;
    }
    return errorText;
  };
};

export const requiredCertificateDuration = (errorText) => {
  let localErrorText = errorText;
  localErrorText = localErrorText || t1('this_field_is_required');

  return (value) => {
    if (value && (value.year || value.month || value.day)) {
      return undefined;
    }
    return localErrorText;
  };
};

export const requiredColor = (errorText) => {
  let localErrorText = errorText;
  localErrorText = localErrorText || t1('this_field_is_required');

  return (value) => {
    if (value && value.hex) {
      return undefined;
    }
    return localErrorText;
  };
};

export const restrictionWords = (errorText, restrictionWords = []) => {
  const localErrorText = errorText || t1('has_restriction_words');

  return (value) => {
    if (!value) {
      return undefined;
    }

    if (value.length <= 10) {
      return localErrorText;
    }

    const restrictionWordsExisted = getRestrictionWords(
      value,
      restrictionWords,
    );

    if (restrictionWordsExisted.length) {
      return localErrorText;
    }
  };
};

export const requiredPhongBan = (errorText = '') => {
  return (value) => {
    return !value ? errorText : undefined;
  };
};

export const requiredRTEContent = (errorText) => {
  let localErrorText = errorText;
  localErrorText = localErrorText || t1('this_field_is_required');
  const defaultContent = '<p><br></p>';

  return (value) => {
    if (!value || value === defaultContent) {
      return localErrorText;
    }

    return undefined;
  };
};

export const requiredMultipleChoice = (errorText) => {
  let localErrorText = errorText;
  localErrorText = localErrorText || t1('this_field_is_required');
  return (value) => {
    const isCorrect = (value || []).find((v) => !!v.is_answer);
    if (isCorrect) {
      return undefined;
    }

    return localErrorText;
  };
};

export const requiredDuration = (errorText = '') => {
  const localErrorText = errorText || t1('this_field_is_required');
  return (value) => {
    if (value) {
      return undefined;
    }

    return localErrorText;
  };
};
