import { t1 } from 'translate/index';

const mail = (props = {}) => ({
  type: 'text',
  floatingLabelText: t1('email'),
  hintText: t1('email'),
  fullWidth: true,
  validate: props.validate,
});

export default mail;
