import React, { useCallback } from 'react';
import lodashGet from 'lodash.get';
import { connect } from 'react-redux';
import { t1 } from 'translate';
import { chuongTrinhOptions } from 'components/bdtx/configs';
import CommonAntdTable from 'components/common/antd/table';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import sagaActions from 'actions/node/saga-creators';
import endpoints from 'components/bdtx/training-phase/endpoints';
import DeleteItem from 'components/common/action-button/DeleteBtnWithConfirmDialog';

const AssignModulesByCap = ({
  trainingPhase,
  cap,
  targetUser,
  assignModules,
  refetchAssignModulesByCap,
  dispatch,
}) => {
  const onSuccess = useCallback(
    () => {
      if (typeof refetchAssignModulesByCap === 'function') {
        refetchAssignModulesByCap();
      }
    },
    [refetchAssignModulesByCap],
  );

  const onChangeChuongTrinh = useCallback(
    (moduleIid, chuongTrinh) => {
      dispatch(
        sagaActions.submitFormRequest(
          '',
          {
            url: endpoints.bdtx_step_save_assign_module,
            extraParams: {
              cap: cap,
              training_phase_iid: lodashGet(trainingPhase, 'iid'),
              module_iids: [moduleIid],
              chuong_trinh: chuongTrinh,
            },
            executeOnSuccess: onSuccess,
          },
          'POST',
          false,
        ),
      );
    },
    [cap, dispatch, onSuccess, trainingPhase],
  );

  const columns = useCallback(
    () => {
      return [
        {
          title: t1('assigned_module'),
          render: (module) => {
            const name = lodashGet(module, 'name');
            const ct = lodashGet(module, 'chuong_trinh');

            return (
              <>
                <span>{name}</span>
                {!ct && (
                  <div className="text-danger font-size-small">
                    {t1('missing_chuong_trinh')}
                  </div>
                )}
              </>
            );
          },
        },
        ...chuongTrinhOptions({}).map((ct) => {
          return {
            title: lodashGet(ct, 'label'),
            type: TYPE_OF_TABLE_DATA.TITLE,
            width: 150,
            render: (module) => {
              const radioName = `${lodashGet(
                module,
                'iid',
              )}-${cap}-${targetUser}`;
              const deployed = lodashGet(module, 'deployed');

              return (
                <div className="d-flex align-items-center justify-content-center">
                  <input
                    type="radio"
                    name={radioName}
                    defaultChecked={
                      lodashGet(ct, 'value') ==
                      lodashGet(module, 'chuong_trinh')
                    }
                    disabled={deployed}
                    onChange={() =>
                      onChangeChuongTrinh(
                        lodashGet(module, 'iid'),
                        lodashGet(ct, 'value'),
                      )
                    }
                  />
                </div>
              );
            },
          };
        }),
        {
          title: t1('actions'),
          width: 100,
          type: TYPE_OF_TABLE_DATA.ACTION,
          render: (module) => {
            const deployed = lodashGet(module, 'deployed');

            if (deployed) {
              return (
                <span className="text-primary">
                  {t1('module_has_deployed')}
                </span>
              );
            }

            return (
              <div className="d-flex justify-content-center">
                <DeleteItem
                  iconButton
                  title={t1('remove_module')}
                  className="btn-small"
                  alternativeApi={endpoints.bdtx_step_remove_assign_module}
                  textConfirm={t1('are_you_sure_you_want_to_delete_%s?', [
                    module.name,
                  ])}
                  params={{
                    cap: cap,
                    training_phase_iid: lodashGet(trainingPhase, 'iid'),
                    module_iids: [lodashGet(module, 'iid')],
                  }}
                  onRequestSuccessful={onSuccess}
                />
              </div>
            );
          },
        },
      ].filter(Boolean);
    },
    [cap, onChangeChuongTrinh, onSuccess, targetUser, trainingPhase],
  );

  if (!assignModules || !assignModules.length) {
    return <span>{t1('no_assign_modules')}</span>;
  }

  return (
    <div>
      <CommonAntdTable
        rowKey="iid"
        dataSource={assignModules}
        columns={columns()}
        pagination={false}
        className="learn-default"
      />
    </div>
  );
};

export default connect()(AssignModulesByCap);
