import React from 'react';

import useDragWithScrolling from './scrollable.js';

const MovableItem = ({
  className,
  avatar,
  content,
  type,
  isBeingDragged,
  answered,
}) => {
  const cssClass = 'ddm-question-movable-item';

  const componentClassName = `${className || ''} ${cssClass} ${
    isBeingDragged ? `${cssClass}--dragging` : ''
  }`;

  const [property, drag] = useDragWithScrolling({
    item: { content, type, avatar },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });

  return (
    <div ref={drag} className={componentClassName}>
      <div className="main" style={{ opacity: answered ? 0.3 : 1 }}>
        <div className="content p-10">
          {content && (
            <div className="content-text">
              <div title={content} className="content-text__movable-item">
                {content}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MovableItem;
