import { targetUser } from 'components/common/elements';

export const KPI_REPORT_TYPE_LEARNING_COMPLETION = 'learning_completion';
export const KPI_REPORT_TYPE_PLANNING = 'planning';

const schema = () => {
  return {
    target_user: targetUser({
      type: 'radio',
      classWrapper: 'col-md-3',
    }),
    report_type: {
      type: 'radio',
      floatingLabelText: 'Loại báo cáo',
      options: [
        {
          value: KPI_REPORT_TYPE_LEARNING_COMPLETION,
          label: 'Tiến độ học của học viên',
        },
        {
          value: KPI_REPORT_TYPE_PLANNING,
          label: 'Tiến độ lên kế hoạch',
        },
      ],
      defaultValue: KPI_REPORT_TYPE_PLANNING,
      classWrapper: 'col-md-6',
      inline: true,
    },
  };
};

const ui = () => {
  return [
    {
      id: 'default',
      fields: ['target_user', 'report_type'],
    },
  ];
};

export default {
  schema,
  ui,
};
