import { t1 } from 'translate';

export const BLOCK_PADDING_LARGE = 'large';
export const BLOCK_PADDING_MIDDLE = 'middle';
export const BLOCK_PADDING_SMALL = 'small';

const blockPadding = [
  {
    value: '',
    label: t1('no_padding'),
  },
  {
    value: BLOCK_PADDING_MIDDLE,
    label: t1('padding_middle'),
  },
  {
    value: BLOCK_PADDING_LARGE,
    label: t1('padding_large'),
  },
  {
    value: BLOCK_PADDING_SMALL,
    label: t1('padding_small'),
  },
];

export default () => ({
  type: 'select',
  options: blockPadding,
  floatingLabelText: t1('block_padding'),
  floatingLabelFixed: true,
  fullWidth: true,
  defaultValue: '',
});
