import React from 'react';
import { Modal } from 'antd';
import get from 'lodash.get';
import { mappingDigitalSupportPlanErrorByKey } from 'components/common/forms/questions/open-ended/digital-plan/utils';

export const isOldTrainingPlanCategory = (
  training_plan_category,
  domainInfo,
) => {
  const oldTps = get(
    domainInfo,
    'conf.training_plan_categories_using_old_form_to_submit_support_plan',
    [],
  );

  return (
    !training_plan_category || oldTps.includes(String(training_plan_category))
  );
};

export const usingOldFormToSubmitSupportPlanResult = (
  schoolyear,
  domainInfo,
) => {
  const oldSchoolYears = get(
    domainInfo,
    'conf.school_year_using_old_form_to_submit_support_plan_result',
    [],
  );

  return (
    !schoolyear ||
    oldSchoolYears.includes(String(schoolyear)) ||
    oldSchoolYears.includes(Number.parseInt(schoolyear))
  );
};

export const stringPlanCategory = {
  1: 1,
  3: 2,
  5: 3,
  7: 4,
};

export const modulesInYear = {
  2020: [1, 2],
  2021: [3, 4, 5],
};

export const getStringPlanCategory = (training_plan_category) => {
  return get(stringPlanCategory, training_plan_category, '');
};

export const isApprovedFinalOnline = (supportPlan) => {
  const attachments = get(supportPlan, 'attachments');
  const attachmentsFinal = get(supportPlan, 'attachments_final');
  const khht = get(supportPlan, 'khht');

  if (attachments && attachmentsFinal) {
    return attachments.every((attachment) => {
      return !!attachmentsFinal.find(
        (attachmentFinal) => attachmentFinal.id == attachment.id,
      );
    });
  }

  if (!attachments && khht) {
    return !attachmentsFinal;
  }

  return false;
};

export const supportPlanErrorMapping = (error, isResultPlan) => {
  const errors = [mappingDigitalSupportPlanErrorByKey(error)];

  if (isResultPlan) {
    if (error['attachments']) {
      errors.push('Mục: Nộp tệp báo cáo kết quả HTĐN có lỗi');
    }

    if (error['attachments_final']) {
      errors.push(
        'Mục: Nộp tệp báo cáo kết quả HTĐN Hiệu Trưởng đã duyệt có lỗi',
      );
    }
  } else {
    if (error['attachments']) {
      errors.push('Mục: Nộp tệp kế hoạch hỗ trợ đồng nghiệp có lỗi');
    }

    if (error['attachments_final']) {
      errors.push('Mục: Nộp tệp kế hoạch Hiệu Trưởng đã duyệt có lỗi');
    }
  }

  return errors.filter(Boolean);
};

export const renderErrorsFormPopup = (error) => {
  if (!error || !Object.keys(error || {}).length) {
    return;
  }

  const errorsMapping = supportPlanErrorMapping(error);

  Modal.warning({
    title: 'Có lỗi xảy ra. Vui lòng kiểm tra lại nội dung nhập liệu.',
    content: (
      <div>
        {errorsMapping.map((error, index) => {
          return (
            <>
              <span>{error}</span>
              {index + 1 < errorsMapping.length ? <hr /> : null}
            </>
          );
        })}
      </div>
    ),
  });
};
