import React from 'react';
import get from 'lodash.get';
import DetailOnDialog from 'components/common/detail-on-dialog';
import OverviewChosenModule from '../cancel-chosen-module';

const ModuleLabel = ({ module, hiddenFields = {} }) => {
  const assigned = get(module, 'assigned');
  const fromEtep = get(module, 'from_etep');

  const renderPreview = ({ showFull }) => {
    let label = '(Đã lên kế hoạch)';
    if (fromEtep) {
      label = '(Được kết chuyển từ ETEP)';
    }

    return (
      <span className="text-secondary cursor-pointer" onClick={showFull}>
        {label}
      </span>
    );
  };

  const renderFull = () => {
    return (
      <OverviewChosenModule
        hiddenFields={{
          ...hiddenFields,
          module_iid: module.iid,
        }}
        showAction={!fromEtep}
        showSearchForm={false}
      />
    );
  };

  return (
    <>
      <span>
        {module.code} ({module.credit} tiết)&nbsp;
        {assigned || fromEtep ? (
          <DetailOnDialog
            dialogKey={'video-suggestion'}
            renderPreview={renderPreview}
            renderFull={renderFull}
            dialogOptionsProperties={{
              title: `Các đơn vị đã chọn mô đun ${module.name}`,
              handleClose: true,
              modal: true,
              width: '85%',
            }}
          />
        ) : null}
      </span>
    </>
  );
};

export default ModuleLabel;
