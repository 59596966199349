import React from 'react';
import NodeNew from 'components/admin/node/new';
import InputFile from 'schema-form/elements/input-file';
import { t1 } from 'translate';
import ExportBtn from 'components/common/action-button/ExportBtn';
import RaisedButton from 'components/common/mui/RaisedButton';
import Icon from 'components/common/Icon';
import apiUrls from 'api-endpoints';
import { required } from 'common/validators';
import { isSubmitting } from 'redux-form';
import connect from 'react-redux/lib/connect/connect';

const FormImport = ({
  showDownloadTemplate = true,
  downloadTemplateParams,
  downloadTemplateAlternativeApi,
  alternativeApi,
  requestSuccessful,
  formid,
  submitting,
  ...props
}) => {
  return (
    <NodeNew
      className="white-box border-round"
      {...props}
      ntype={'import'}
      schema={{
        schema: () => ({
          import_file: {
            name: 'import_file',
            type: InputFile,
            accept: ['.xlsx', '.xls', '.xlsm'],
            hintText: t1('import_file'),
            fullWidth: true,
            validate: [required(t1('import_file_cannot_be_empty'))],
          },
        }),
        ui: () => [
          {
            id: 'default',
            fields: ['import_file'],
          },
        ],
      }}
      step={'quick_assign_roles'}
      alternativeApi={alternativeApi}
      formid={formid || 'import_by_template'}
      submitButton={(formValues) => (
        <>
          <RaisedButton
            primary
            label={submitting ? t1('working...') : t1('submit')}
            type="submit"
            className="m-10"
            icon="save"
            disabled={!formValues || !formValues.import_file || submitting}
          />

          {showDownloadTemplate ? (
            <ExportBtn
              label={t1('download_form_import')}
              icon={<Icon icon="cloud-download" />}
              className="m-10"
              url={
                downloadTemplateAlternativeApi ||
                apiUrls.template_request_to_import
              }
              params={downloadTemplateParams}
            />
          ) : null}
        </>
      )}
      requestSuccessful={requestSuccessful}
    />
  );
};

const mapStateToProps = (state, props) => ({
  submitting: isSubmitting(props.formid)(state),
});

export default connect(mapStateToProps)(FormImport);
