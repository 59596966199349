import { t1 } from 'translate';
import { input, avatar, rte } from 'components/common/forms/schema-fields';

export const textVariants = {
  TEXT_STANDARD: 'text_standard',
  TEXT_IMAGE_TOP: 'text_image_top',
  TEXT_IMAGE_TOP_FULL_WIDTH: 'text_image_top_full_width',
  TEXT_CHAPO_AVATAR: 'text_chapo_avatar',
  TEXT_RIGHT_AVATAR_LEFT: 'text_right_avatar_left',
  TEXT_LEFT_AVATAR_RIGHT: 'text_left_avatar_right',
};

export const textVariantOptions = () => [
  {
    value: textVariants.TEXT_STANDARD,
    label: t1('standard_text'),
  },
  {
    value: textVariants.TEXT_IMAGE_TOP,
    label: t1('text_with_image_top'),
  },
  {
    value: textVariants.TEXT_IMAGE_TOP_FULL_WIDTH,
    label: t1('text_with_image_top_full_width'),
  },
  {
    value: textVariants.TEXT_CHAPO_AVATAR,
    label: t1('text_chapo_avatar'),
  },
  {
    value: textVariants.TEXT_RIGHT_AVATAR_LEFT,
    label: t1('text_right_avatar_left'),
  },
  {
    value: textVariants.TEXT_LEFT_AVATAR_RIGHT,
    label: t1('text_left_avatar_right'),
  },
];

export const textElementSchema = (type, variant) => {
  return {
    type: 'cascade',
    schema: {
      schema: {
        heading: input(t1('text_heading')),
        avatar: avatar(),
        text: rte(),
      },
      ui: [
        {
          fields: ['heading', 'avatar', 'text'],
          id: 'text-xxx',
        },
      ],
    },
  };
};
