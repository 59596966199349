import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import Icon from 'components/common/Icon';
import { t1 } from 'translate';
import ListAttachmentsToDownloadAsTable from 'components/common/attachment/ListAttachmentsToDownloadAsTable';

const SessionDownloadMaterial = ({ attachments }) => {
  if (!attachments || !attachments.length) {
    return null;
  }

  const renderAttachmentTable = () => {
    return (
      <>
        <h2>{t1('session_materials')}</h2>

        <ListAttachmentsToDownloadAsTable attachments={attachments} />
      </>
    );
  };

  return (
    <DetailOnDialog
      renderPreview={({ showFull }) => (
        <div
          className={'cursor-pointer text-secondary m-t-10'}
          onClick={showFull}
        >
          <Icon icon={'attachment'} />
          &nbsp;{t1('download_materials')}
        </div>
      )}
      renderFull={() => renderAttachmentTable()}
    />
  );
};

export default SessionDownloadMaterial;
