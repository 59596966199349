import React from 'react';
import nodeSagaActions from 'actions/node/saga-creators';
import apiUrls from 'api-endpoints';
import { types } from 'components/admin/question/schema/question-types';
import isEqual from 'lodash.isequal';

export const questionTakeState = (courseIid, questionId) =>
  `course-${courseIid}-question-${questionId}-take`;

export const correctFullAnswerQuestions = (userAnswer, answer) => {
  return isEqual(userAnswer, answer);
};

const fetchAnswerAndFeedback = (props) => {
  const { isTesting, exam, userIid } = props;
  const question = props.question || {};
  const { dispatch, courseIid } = props;
  const url = apiUrls.getTakeDetail;

  let params = {
    course: courseIid,
    question_id: question.id,
  };

  if (userIid) {
    params = {
      ...params,
      userIid,
    };
  }

  if (isTesting) {
    params.exam = exam;
  }

  const keyState = questionTakeState(courseIid, question.id);

  dispatch(
    nodeSagaActions.getDataRequest(
      {
        url,
        keyState,
      },
      params,
    ),
  );
};

export const generateHelpTextKey = (type) => {
  let helpTextKey;

  switch (type) {
    case types.TYPE_API:
      helpTextKey = 'question_help_for_api';
      break;
    case types.TYPE_CATEGORIZED:
      helpTextKey = 'question_help_for_categorized';
      break;
    case types.TYPE_DICTATION:
      helpTextKey = 'question_help_for_dictation';
      break;
    case types.TYPE_END_INTRODUCTION:
      helpTextKey = 'question_help_for_end_introduction';
      break;
    case types.TYPE_INLINE:
      helpTextKey = 'question_help_for_inline';
      break;
    case types.TYPE_INTRODUCTION:
      helpTextKey = 'question_help_for_introduction';
      break;
    case types.TYPE_MATCHING_PAIRS:
      helpTextKey = 'question_help_for_matching_pairs';
      break;
    case types.TYPE_MC:
      helpTextKey = 'question_help_for_mc';
      break;
    case types.TYPE_MC_OPEN_ENDED:
      helpTextKey = 'question_help_for_mc_open_ended';
      break;
    case types.TYPE_NUMBER:
      helpTextKey = 'question_help_for_number';
      break;
    case types.TYPE_OPEN_ENDED:
      helpTextKey = 'question_help_for_open_ended';
      break;
    case types.TYPE_REORDER:
      helpTextKey = 'question_help_for_reorder';
      break;
    case types.TYPE_REPEAT_AFTER_ME:
      helpTextKey = 'question_help_for_repeat_after_me';
      break;
    case types.TYPE_ROLEPLAY:
      helpTextKey = 'question_help_for_role_play';
      break;
    case types.TYPE_SPEAKING:
      helpTextKey = 'question_help_for_speaking';
      break;
    case types.TYPE_WRITING:
      helpTextKey = 'question_help_for_writing';
      break;
    case types.TYPE_TRUE_FALSE:
      helpTextKey = 'question_help_for_true_false';
      break;
    case types.TYPE_MMC:
      helpTextKey = 'question_help_for_mmc';
      break;
    case types.TYPE_LIKERT:
      helpTextKey = 'question_help_for_likert';
      break;
    case types.TYPE_YES_NO:
      helpTextKey = 'question_help_for_yes_no';
      break;
    case types.TYPE_DDM:
      helpTextKey = 'question_help_for_ddm';
      break;
    case types.TYPE_MDDM:
      helpTextKey = 'question_help_for_mddm';
      break;
    default:
      helpTextKey = 'question_help';
  }

  return helpTextKey;
};

export default fetchAnswerAndFeedback;
