import { t1 } from 'translate';

export const INSERT_MODE = {
  NORMAL: 'normal',
  SIMPLE: 'simple',
};

export const isSimpleInsertMode = (mode) => mode === INSERT_MODE.SIMPLE;

export default (props = {}) => ({
  type: 'radio',
  floatingLabelText: t1('insert_mode'),
  defaultValue: INSERT_MODE.NORMAL,
  options: [
    {
      value: INSERT_MODE.NORMAL,
      label: t1('option_normal'),
    },
    {
      value: INSERT_MODE.SIMPLE,
      label: t1('option_simple'),
    },
  ],
  inline: true,
  ...props,
});
