import React from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import schema from 'components/bdtx/kpi/detail-by-organization/search/schema';
import TableResult from 'components/bdtx/kpi/detail-by-organization/search/TableResult';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import withUserInfo from 'common/hoc/withUserInfo';
import endpoints from 'components/bdtx/kpi/endpoints';

const SEARCH_FORM_ID = 'search-detail-by-organization';

const OverviewDeploymentByOrganization = ({ userInfo }) => {
  const renderResultComponent = (items, props, objects, searchValues) => {
    return <TableResult items={items} searchValues={searchValues} />;
  };

  const renderNoResultComponent = () => {
    return <SimpleNoResult text="Chưa triển khai cho đơn vị" />;
  };

  return (
    <SearchWrapper
      schema={schema}
      formid={SEARCH_FORM_ID}
      alternativeApi={endpoints.bdtx_get_detail_deployment}
      renderResultsComponent={renderResultComponent}
      renderNoResultComponent={renderNoResultComponent}
      showSearchButton={false}
      autoSearchWhenValuesChange={(formValue) => {
        return formValue && formValue.organization;
      }}
      autoSearchWhenStart={false}
      userInfo={userInfo}
      hidePagination
    />
  );
};

export default withUserInfo(OverviewDeploymentByOrganization);
