export default {
  get_organizations: '/temis/profile/get-organizations-options',
  get_organization_info: '/organization/api/get', //an effort to get away from /syllabus/api/get
  get_multiple_organizations_info: '/organization/api/get-multiple',
  organization_search: '/organization/api/search?type=organization',
  get_organizations_for_select_options:
    '/organization/schema-form/get-organizations-for-select-options',
  get_organization_sub_types_for_select_options:
    '/organization/schema-form/get-organization-sub-types-for-select-options',
  get_organization_children_sub_types_for_select_options:
    '/organization/schema-form/get-organization-children-sub-types-for-select-options',
};
