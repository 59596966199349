/**
 * NOTE mặc dù đã có folder components/common/admin/import rồi, nhưng do lúc làm ko biết đã có folder common ấy (và code ấy cũ quá rồi)
 * nên đã tạo thêm phần common import này, giờ sẽ dùng phần này làm common import
 */
import React from 'react';
import ImportByTemplate from 'components/admin/form-import';
import SearchImport from './search';
import lodashGet from 'lodash.get';

const ImportForm = ({
  type, // import what: course, user...
  otherImportParams,
  downloadTemplateParams,
  importTempApiUrl,
  searchImportApiUrl,
  importApiUrl,
  searchImportColumns,
  onImportSuccessful,
  downloadTemplateAlternativeApi,
  searchImportedItemsFormId,
}) => {
  const [importId, setImportId] = React.useState(null);

  if (importId) {
    return (
      <SearchImport
        type={type}
        importId={importId}
        searchImportApiUrl={searchImportApiUrl}
        importApiUrl={importApiUrl}
        searchImportColumns={searchImportColumns}
        otherImportParams={otherImportParams}
        onImportSuccessful={onImportSuccessful}
        searchImportedItemsFormId={searchImportedItemsFormId}
      />
    );
  }

  return (
    <ImportByTemplate
      formid={`import-form-${type}`}
      hiddenFields={otherImportParams}
      alternativeApi={importTempApiUrl}
      requestSuccessful={({ result }) => {
        setImportId(lodashGet(result, 'import_id'));
      }}
      downloadTemplateAlternativeApi={downloadTemplateAlternativeApi}
      downloadTemplateParams={downloadTemplateParams}
    />
  );
};

export default ImportForm;
