import { CHUONG_TRINHS } from 'components/bdtx/configs';
import fetchData from 'components/common/fetchData';
import endpoints from 'components/bdtx/training-phase/endpoints';
import get from 'lodash.get';
import isEqual from 'lodash.isequal';

export const materialMessage = {
  true: 'Mô đun đủ học liêu, bạn có thể chọn cho đơn vị tương ứng',
  false: 'Mô đun chưa gán học liêu, bạn không thể chọn cho đơn vị tương ứng',
};

export const fetchChosenModules = fetchData((props) => {
  const { searchValues = {}, hiddenFields = {} } = props;

  return {
    baseUrl: endpoints.bdtx_get_chosen_module,
    params: {
      ...searchValues,
      ...hiddenFields,
    },
    method: 'get',
    propKey: 'chosenModules',
    fetchCondition: true,
    formatDataResult: (data) => {
      const modules = data || [];

      return {
        chosenModules: formatModuleToChuongTrinh(modules),
        modules,
      };
    },
  };
});

export const fetchTrainingPhase = fetchData((props) => {
  const trainingPhaseIidFromParams = get(props, 'match.params.iid');
  const trainingPhaseIid = get(props, 'trainingPhaseIid');

  return {
    baseUrl: endpoints.bdtx_get_training_phase,
    params: {
      iid: trainingPhaseIid || trainingPhaseIidFromParams,
    },
    method: 'get',
    propKey: 'node',
    fetchCondition: true,
    fetchFunctionPropKey: 'refetchTrainingPhase',
  };
});

export const fetchCapsByTrainingPhase = fetchData((props) => {
  const trainingPhaseIidFromParams = get(props, 'match.params.iid');
  const trainingPhaseIid = get(props, 'trainingPhaseIid');

  return {
    baseUrl: endpoints.bdtx_get_caps_of_training_phase,
    params: {
      training_phase: trainingPhaseIid || trainingPhaseIidFromParams,
    },
    method: 'get',
    propKey: 'node',
    fetchCondition: true,
  };
});

export const formatModuleToChuongTrinh = (modules) => {
  if (!modules || !modules.length) {
    return;
  }

  const modulesByChuongTrinh = {};

  CHUONG_TRINHS.forEach((chuongTrinh) => {
    const data = modules.filter((module) => module.chuong_trinh == chuongTrinh);
    if (modulesByChuongTrinh[chuongTrinh]) {
      return;
    }

    modulesByChuongTrinh[chuongTrinh] = {
      chuong_trinh: chuongTrinh,
      modules: data,
    };
  });

  return modulesByChuongTrinh;
};
