import './stylesheet.scss';
import React from 'react';
import get from 'lodash.get';
import ErrorMessageQuestion from 'components/common/forms/questions/ErrorMessageQuestion';
import DragAndDrop from 'components/common/forms/questions/DnDProvider';
import { useCoreLogicTableData } from 'components/common/forms/questions/mddm/core-logic';
import FixedItem from 'components/common/forms/questions/mddm/FixedItem';
import MovableItem from 'components/common/forms/questions/mddm/MovableItem';
import { t1 } from 'translate';
import BlockTable from 'components/common/table-editor';
import {
  options_show_table_mddm,
  question_types_mddm,
} from 'components/admin/question/utils';
import { parseMDDMQuestionToElement } from 'components/admin/question/schema/mddm/utils';
import { splitNewLine } from 'common/utils/string';

const MDDMTableQuestion = (props) => {
  const {
    shouldShowKey = false,
    disabled = false,
    practice = false,
    userAnswers,
    setUserAnswers,
    setShowError,
    showError,
    question,
    questionShuffleKey,
  } = props;

  const cssClass = 'mddm-question-type-box';
  const {
    mddm_list_answer,
    mddm_question_table_data,
    mddm_question_table_columns,
    mddm_question_table_rows,
    mddm_question_type,
    mddm_question_table_widths,
    mddm_question_table_options = [],
  } = question;

  const [
    questionsData,
    answersData,
    handleDrop,
    handleAnsweredItemClick,
    checkAnswerIsCorrect,
  ] = useCoreLogicTableData({
    userAnswers: userAnswers || [],
    question,
    setUserAnswers,
    disabled,
    practice,
    setShowError,
    questionShuffleKey,
  });

  const renderFixedItem = (resultMode) => (
    <BlockTable
      defaultValue={mddm_question_table_data}
      nameOfColumns={splitNewLine(mddm_question_table_columns)}
      nameOfRows={splitNewLine(mddm_question_table_rows)}
      columnWidths={splitNewLine(mddm_question_table_widths)}
      hideHeader={mddm_question_table_options.includes(
        options_show_table_mddm.hideHeader,
      )}
      hideFirstColumn={mddm_question_table_options.includes(
        options_show_table_mddm.hideFirstColumn,
      )}
      readOnlyHeader={true}
      alwaysShowScroll={mddm_question_type !== question_types_mddm.INLINE}
      onlyShowContent={mddm_question_type === question_types_mddm.INLINE}
      renderItem={(currentValue, row, col, onChange) => {
        return (
          <div className={`${cssClass}__${mddm_question_type}`}>
            {parseMDDMQuestionToElement(
              currentValue,
              (word) => (
                <p style={{ width: '100%' }}>{word}</p>
              ),
              (index) => {
                const questionBox = get(
                  questionsData,
                  `[${row}][${col}][${index}]`,
                  {},
                );
                const isAnswerCorrect =
                  checkAnswerIsCorrect(questionBox) || resultMode;
                const answers = resultMode
                  ? mddm_list_answer
                      .filter((v) =>
                        questionBox.correctAnswers.includes(String(v.id)),
                      )
                      .map((v) => ({ ...v, type: String(v.id) }))
                  : questionBox.answers;

                const fixedItemClass = answers.length
                  ? ''
                  : `${cssClass}__item__fixed`;

                return (
                  <FixedItem
                    key={`item-${col}-${row}-${index}`}
                    className={fixedItemClass}
                    answers={answers}
                    onDrop={(item) => handleDrop(item, row, col, index)}
                    handleItemChildrenClick={(item) =>
                      handleAnsweredItemClick(item, row, col, index)
                    }
                    disabled={disabled || resultMode}
                    isAnswerCorrect={isAnswerCorrect}
                    accept={questionBox.accepts}
                  />
                );
              },
            )}
          </div>
        );
      }}
    />
  );

  return (
    <>
      <ErrorMessageQuestion question={question} error={showError} />

      <DragAndDrop>
        <div>
          <h3>{t1('mddm_list_answer')}</h3>
          <div
            className={`${cssClass}__moveable_box m-b-20 ${cssClass}__box_answer`}
          >
            {answersData
              .map(
                (item, index) =>
                  !item.answered && (
                    <MovableItem
                      className={`${cssClass}__item`}
                      item={item}
                      key={index}
                    />
                  ),
              )
              .filter(Boolean)}
          </div>
          <h3>{t1('table_question')}</h3>
          <div className={`${cssClass}__fixed_box ${cssClass}__row `}>
            {renderFixedItem()}
          </div>
          {shouldShowKey && (
            <>
              <h3 className="m-t-30">{t1('correct_answer')}</h3>
              <div className={`${cssClass}__fixed_box m-t-20`}>
                {renderFixedItem(true)}
              </div>
            </>
          )}
        </div>
      </DragAndDrop>
    </>
  );
};
export default MDDMTableQuestion;
