export const TRUE_FALSE_QUESTION = 'TF';
export const TRUE_FALSE_SUB_TYPE = {
  TF: 'TF',
  YN: 'YN',
};

export const LINKERT_INSERT_MODE = {
  NORMAL: 'normal',
  SIMPLE: 'simple',
};
export const DDM_INSERT_MODE = {
  NORMAL: 'normal',
  SIMPLE: 'simple',
};

export const MDDM_PARSE_KEY = '___';
