import React, { useState } from 'react';
import { steps } from 'common/learn/exercise';
import lodashGet from 'lodash.get';
import Tooltip from 'antd/lib/tooltip';
import Icon from 'components/common/Icon';
import Portal, { portals } from 'components/common/portal';
import { getRightMenuItemByPeerActivity } from './util';
import { Menu } from 'antd';
import get from 'lodash.get';

const OEMenu = ({
  question,
  isSurvey,
  inlineExercise,
  exerciseStep,
  onActiveMenu,
  userAnswers,
  defaultActivatedMenuItem,
  viewAnswerInMarkingMode,
  dataSource,
  setRubricToShowMarking,
  setHideSubmitButton,
  noPortal,
}) => {
  const [activatedMenuItem, setActivatedMenuItem] = useState(
    defaultActivatedMenuItem,
  );

  const portalId = portals.SP1_QUESTION_NAVIGATIONS_RIGHT_BAR(
    lodashGet(question, 'id') || '',
  );

  const peerActivities = lodashGet(question, 'peer_activities', []);
  const activityMenu = getRightMenuItemByPeerActivity(
    peerActivities,
    question,
    userAnswers,
  );

  if (isSurvey) {
    return null;
  }

  // if is not config show peer activity and not have extra menu items, will return null
  if (!activityMenu.length && !dataSource) {
    return null;
  }

  const isReviewStep = exerciseStep === steps.REVIEW;
  const menuInsideQuestionMode =
    viewAnswerInMarkingMode || inlineExercise || isReviewStep;

  const getMenuItemByDataSource = () => {
    if (!dataSource || !dataSource.length) {
      return null;
    }

    return dataSource.map((item, index) => {
      const { id, name } = item;

      return (
        <Menu.Item
          onClick={() => {
            onActiveMenu(item);

            const rubricIidMarking = get(item, 'rubric_iid_marking') || null;
            if (typeof setRubricToShowMarking === 'function') {
              setRubricToShowMarking(rubricIidMarking);
            }

            if (typeof setHideSubmitButton === 'function') {
              setHideSubmitButton(true);
            }
          }}
          key={id}
        >
          <Icon
            themify
            icon={
              item.input_submit.includes('youtube') ? 'control-play' : 'pencil'
            }
          />
          <span className="m-l-5">{name}</span>
        </Menu.Item>
      );
    });
  };

  const getMenuItemsByActivities = () => {
    return activityMenu.map((item) => (
      <Menu.Item
        onClick={() => {
          setActivatedMenuItem(item);

          if (typeof onActiveMenu === 'function') {
            onActiveMenu(item);
          }
        }}
        key={item.id}
      >
        <Tooltip placement="topLeft" title={item.name}>
          <Icon icon={item.icon} themify /> {item.name}
        </Tooltip>
      </Menu.Item>
    ));
  };

  const menu = (
    <Menu
      mode={menuInsideQuestionMode ? 'horizontal' : 'inline'}
      defaultSelectedKeys={[activatedMenuItem.id]}
    >
      {getMenuItemByDataSource()}

      {viewAnswerInMarkingMode ? null : getMenuItemsByActivities()}
    </Menu>
  );

  if (viewAnswerInMarkingMode || noPortal) {
    return menu;
  }

  return <Portal id={portalId}>{menu}</Portal>;
};

export default OEMenu;
