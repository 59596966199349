import React from 'react';
import Tag from 'antd/lib/tag';
import {
  CHUONG_TRINH_02,
  CHUONG_TRINH_03,
  getChuongTrinhName,
} from 'components/bdtx/configs';

const ChuongTrinhTag = ({ chuongTrinh, shortName }) => {
  let color = 'orange';
  if (chuongTrinh == CHUONG_TRINH_02) {
    color = 'blue';
  } else if (chuongTrinh == CHUONG_TRINH_03) {
    color = 'green';
  }

  return (
    <Tag color={color} className={'m-5 break-word'}>
      {getChuongTrinhName(chuongTrinh, shortName)}
    </Tag>
  );
};

export default ChuongTrinhTag;
