import { t1 } from 'translate';

export const BLOCK_SIZE_LARGE = 'large';
export const BLOCK_SIZE_MIDDLE = 'middle';
export const BLOCK_SIZE_SMALL = 'small';

export default () => ({
  type: 'select',
  options: [
    {
      value: BLOCK_SIZE_MIDDLE,
      label: t1('size_middle'),
    },
    {
      value: BLOCK_SIZE_LARGE,
      label: t1('size_large'),
    },
    {
      value: BLOCK_SIZE_SMALL,
      label: t1('size_small'),
    },
  ],
  floatingLabelText: t1('font_size'),
  floatingLabelFixed: true,
  fullWidth: true,
  defaultValue: BLOCK_SIZE_LARGE,
});
