import { types as questionTypes } from 'components/admin/question/schema/question-types';
import { deepAssign } from 'common/utils/object';
import { scoreFormulaValue } from 'components/admin/question/utils';
import { t, t1 } from 'translate';
import { isEmptyAnswerFormValue } from '../learn/Question';
import get from 'lodash.get';
import isEqual from 'lodash.isequal';
import lodashPickBy from 'lodash.pickby';
import { getMapByQuestionData } from 'components/admin/question/schema/mddm/utils';

export const isQuestionDone = (type, userAnswers) => {
  switch (type) {
    case questionTypes.TYPE_API: {
      return userAnswers && userAnswers.status === 'have_ordered';
    }
    case questionTypes.TYPE_OPEN_ENDED: {
      const { content } = userAnswers || {};

      if (!Array.isArray(content)) {
        return !isEmptyAnswerFormValue(userAnswers || {});
      }
      const isEmpty = content.every((data) => isEmptyAnswerFormValue(data));

      return !isEmpty;
    }
    default: {
      return Array.isArray(userAnswers) && userAnswers.length > 0;
    }
  }
};

const init = (question, userAnswers, highlightsClass) => {
  const highlights = [];
  const { match, notMatch } = highlightsClass || {};
  const totalCorrectAnswer = 0;
  const take = {
    iid: question.iid,
    type: question.type,
    answer: userAnswers,
    sw: question.sw,
    score: question.score,
  };
  const matchClass = match || 'default-match';
  const notMatchClass = notMatch || 'default-not-match';
  return {
    take,
    highlights,
    matchClass,
    notMatchClass,
    totalCorrectAnswer,
  };
};

export const getIntroAnswers = (question, userAnswers, highlightsClass) => {
  const { take, highlights, totalCorrectAnswer } = init(
    question,
    userAnswers,
    highlightsClass,
  );

  const { sw } = question;

  take.isCorrect = true;
  take.score = sw;
  take.percent = 100;

  return {
    take,
    totalCorrectAnswer,
    highlights,
  };
};

export const getMcAnswers = (
  question,
  userAnswers,
  highlightsClass,
  shouldShowKey,
) => {
  let {
    take,
    highlights,
    matchClass,
    notMatchClass,
    totalCorrectAnswer,
  } = init(question, userAnswers, highlightsClass);
  const { answers, mc_answers } = question;

  if (!answers || !Array.isArray(answers) || !Array.isArray(userAnswers)) {
    return {
      take,
      highlights,
    };
  }

  let totalWrongAnswer = 0;

  const answersHaveBeenNormalized = answers.map((answer) => String(answer));
  const userAnswersHaveBeenNormalized = userAnswers.map((answer) =>
    String(answer),
  );

  mc_answers.forEach((item, index) => {
    let className = '';
    const selected = userAnswersHaveBeenNormalized.indexOf(String(index)) >= 0;

    if (answersHaveBeenNormalized.indexOf(String(index)) >= 0) {
      className = matchClass;
      if (selected) {
        totalCorrectAnswer += 1;
      }
    } else if (selected) {
      className = notMatchClass;
      totalWrongAnswer += 1;
    }

    highlights.push({
      value: index,
      selected,
      className,
    });
  });

  let correctPercent = 0;
  if (totalWrongAnswer > 0) {
    correctPercent = 0;
  } else {
    correctPercent = totalCorrectAnswer == answers.length ? 1 : 0;
  }

  const isCorrect =
    userAnswers &&
    totalCorrectAnswer === userAnswers.length &&
    totalCorrectAnswer === answers.length;

  if (!isCorrect && !shouldShowKey) {
    highlights = highlights
      .filter((hightlight) => hightlight && hightlight.selected)
      .map((highlight) =>
        Object.assign({}, highlight, { className: notMatchClass }),
      );
  }

  take.isCorrect = isCorrect;
  take.score = correctPercent;

  take.percent = correctPercent;

  return {
    take,
    totalCorrectAnswer,
    highlights,
  };
};

export const getMcOpenEndedAnswers = (question, userAnswers) => {
  let { take, highlights } = init(question, userAnswers);

  // right now, this is only used for survey,
  // if in the future, there are actual logic where you need to mark mc open ended question, refactor this

  return {
    take,
    highlights,
  };
};

export const getInlineAnswers = (
  question,
  userAnswers,
  highlightsClass,
  shouldShowKey,
) => {
  let {
    take,
    highlights,
    matchClass,
    notMatchClass,
    totalCorrectAnswer,
  } = init(question, userAnswers, highlightsClass);
  const { answers, acs } = question;
  if (!answers) {
    return {
      take,
      highlights,
    };
  }

  if (answers.length > 0) {
    for (let i = 0; i < answers.length; i += 1) {
      let isAnswerCorrect = false;
      const userAnswer = get(userAnswers, i) || '';
      if (
        answers[i] &&
        (acs
          ? answers[i].findIndex(
              (word) =>
                word.trim().normalize('NFC') ===
                userAnswer.trim().normalize('NFC'),
            ) !== -1
          : answers[i].findIndex(
              (word) =>
                word.toLowerCase().normalize('NFC') ===
                userAnswer
                  .trim()
                  .toLowerCase()
                  .normalize('NFC'),
            ) !== -1)
      ) {
        isAnswerCorrect = true;
        totalCorrectAnswer += 1;
      }

      highlights.push({
        value: i,
        className: isAnswerCorrect ? matchClass : notMatchClass,
        hasAnswer: Boolean(userAnswer),
      });
    }
  }

  const isCorrect = totalCorrectAnswer === answers.length;

  if (!isCorrect && !shouldShowKey) {
    highlights = highlights.map((highlight) =>
      Object.assign({}, highlight, { className: notMatchClass }),
    );
  }

  take.isCorrect = isCorrect;
  const percent = isCorrect ? 1 : 0;
  take.score = percent;
  take.percent = percent;

  return {
    take,
    totalCorrectAnswer,
    highlights,
  };
};

export const getReorderAnswers = (
  question,
  userAnswers,
  highlightsClass,
  shouldShowKey,
) => {
  let {
    take,
    highlights,
    matchClass,
    notMatchClass,
    totalCorrectAnswer,
  } = init(question, userAnswers, highlightsClass);

  const {
    answers,
    score_formula = scoreFormulaValue.BY_ENTIRE_QUESTION,
  } = question;

  if (!answers) {
    return {
      take,
      highlights,
    };
  }

  let fullUserAnswers = [];
  if (Array.isArray(userAnswers)) {
    fullUserAnswers = userAnswers;
  } else if (typeof userAnswers === 'object') {
    Object.keys(userAnswers).forEach((key) => {
      const data = userAnswers[key];
      if (Array.isArray(data)) {
        fullUserAnswers = fullUserAnswers.concat(data);
      } else {
        fullUserAnswers.push(data);
      }
    });
  }

  const tmp = answers.length - fullUserAnswers.length;
  fullUserAnswers = fullUserAnswers.concat(Array(tmp > 0 ? tmp : 0).fill([]));

  if (answers.length) {
    answers.forEach((answer, index) => {
      let isAnswerCorrect = false;

      if (answer[0] === fullUserAnswers[index][0]) {
        isAnswerCorrect = true;
        totalCorrectAnswer += 1;
      }

      highlights = highlights.concat([
        {
          value: answer[0],
          className: isAnswerCorrect ? matchClass : notMatchClass,
        },
      ]);
    });
  }

  const isCorrect = totalCorrectAnswer === answers.length;
  let percent = isCorrect ? 1 : 0;

  // if (!isCorrect && !shouldShowKey) {
  //   highlights = highlights.map((highlight) =>
  //     Object.assign({}, highlight, { className: notMatchClass }),
  //   );
  // }

  take.isCorrect = isCorrect;
  if (score_formula === scoreFormulaValue.BY_PART_QUESTION) {
    percent = totalCorrectAnswer / answers.length;
  }
  take.percent = percent;
  take.score = percent.toFixed(2);

  return {
    take,
    totalCorrectAnswer,
    highlights,
  };
};

export const getMatchingPairAnswers = (
  question,
  userAnswers,
  highlightsClass,
  shouldShowKey,
) => {
  let {
    take,
    highlights,
    matchClass,
    notMatchClass,
    totalCorrectAnswer,
  } = init(question, userAnswers, highlightsClass);

  const {
    answers,
    score_formula = scoreFormulaValue.BY_ENTIRE_QUESTION,
  } = question;
  if (!answers) {
    return {
      take,
      highlights,
    };
  }

  let fullUserAnswers = userAnswers || [];

  if (answers.length) {
    answers.forEach((answer, index) => {
      let isAnswerCorrect = false;

      if (fullUserAnswers[index] && answer[0] === fullUserAnswers[index][0]) {
        isAnswerCorrect = true;
        totalCorrectAnswer += 1;
      }

      highlights = highlights.concat([
        {
          value: answer[0],
          className: isAnswerCorrect ? matchClass : notMatchClass,
        },
      ]);
    });
  }

  const isCorrect = totalCorrectAnswer === answers.length;

  if (!isCorrect && !shouldShowKey) {
    highlights = highlights.map((highlight) =>
      Object.assign({}, highlight, { className: notMatchClass }),
    );
  }

  take.isCorrect = isCorrect;

  let percent = isCorrect ? 1 : 0;
  if (score_formula === scoreFormulaValue.BY_PART_QUESTION) {
    percent = totalCorrectAnswer / answers.length;
  }

  take.percent = percent;
  take.score = percent.toFixed(2);

  return {
    take,
    totalCorrectAnswer,
    highlights,
  };
};

export const getOpenEndedAnswers = (question, userAnswers) =>
  deepAssign(init(question, userAnswers), ['take'], {
    weighted: question.weighted,
    ts: Math.floor(new Date().getTime() / 1000),
  });

export const getQuestionAPIAnswer = (question, userAnswers) =>
  init(question, userAnswers);

export const getLikertAnswers = (
  question,
  userAnswers,
  highlightsClass,
  shouldShowKey,
) => {
  let {
    take,
    highlights,
    matchClass,
    notMatchClass,
    totalCorrectAnswer,
  } = init(question, userAnswers, highlightsClass);

  const {
    likert_correct_answers,
    score_formula = scoreFormulaValue.QUESTION,
  } = question;
  if (!likert_correct_answers) {
    return {
      take,
      highlights,
    };
  }

  let fullUserAnswers = userAnswers || [];

  if (likert_correct_answers.length) {
    likert_correct_answers.forEach((answer, index) => {
      let isAnswerCorrect = false;
      if (!fullUserAnswers[index]) {
        return;
      }

      const answerSorted = answer.map((i) => String(i)).sort();
      const fullUserAnswersSorted = fullUserAnswers[index]
        .map((i) => String(i))
        .sort();
      if (String(answerSorted) === String(fullUserAnswersSorted)) {
        isAnswerCorrect = true;
        totalCorrectAnswer += 1;
      }

      highlights = highlights.concat([
        {
          value: answer[0],
          className: isAnswerCorrect ? matchClass : notMatchClass,
        },
      ]);
    });
  }

  const isCorrect = totalCorrectAnswer === likert_correct_answers.length;
  take.isCorrect = isCorrect;

  let percent = isCorrect ? 1 : 0;
  if (score_formula === scoreFormulaValue.BY_PART_QUESTION) {
    percent = totalCorrectAnswer / likert_correct_answers.length;
  }

  take.percent = percent;
  take.score = percent.toFixed(2);

  return {
    take,
    totalCorrectAnswer,
    highlights,
  };
};

export const getDdmAnswers = (question, userAnswers, highlightsClass) => {
  let { take, highlights, totalCorrectAnswer } = init(
    question,
    userAnswers,
    highlightsClass,
  );

  const {
    ddm_correct_answers,
    score_formula = scoreFormulaValue.BY_PART_QUESTION,
  } = question;
  if (!ddm_correct_answers) {
    return {
      take,
      highlights,
    };
  }

  let fullUserAnswers = userAnswers || [];

  if (ddm_correct_answers.length) {
    ddm_correct_answers.forEach((answer, index) => {
      const currentUserAnswer = get(fullUserAnswers, `[${index}]`, []);
      if (
        currentUserAnswer &&
        currentUserAnswer.length &&
        currentUserAnswer.length === answer.length
      ) {
        const correctAnswerToInteger = answer
          .map((item) => parseInt(item))
          .sort();

        const currentUserAnswerToInteger = currentUserAnswer
          .map((item) => parseInt(item))
          .sort();

        if (isEqual(correctAnswerToInteger, currentUserAnswerToInteger)) {
          totalCorrectAnswer += 1;
        }
      }
    });
  }

  const isCorrect = totalCorrectAnswer === ddm_correct_answers.length;
  take.isCorrect = isCorrect;

  let percent = isCorrect ? 1 : 0;
  if (score_formula === scoreFormulaValue.BY_PART_QUESTION) {
    percent = totalCorrectAnswer / ddm_correct_answers.length;
  }

  take.percent = percent;
  take.score = percent.toFixed(2);

  return {
    take,
    totalCorrectAnswer,
    highlights,
  };
};

export const getMddmAnswers = (question, userAnswers) => {
  const {
    mddm_mapping_table_data = [],
    mddm_question_table_data,
    score_formula = scoreFormulaValue.BY_PART_QUESTION,
  } = question;

  let { take, highlights, totalCorrectAnswer } = init(question, userAnswers);

  if (!mddm_mapping_table_data || !userAnswers) {
    return {
      take,
      totalCorrectAnswer,
      highlights,
    };
  }
  let answer_length = get(mddm_question_table_data, 'dataSource', []).reduce(
    (value, currentValue, i) => {
      return (
        value +
        Object.values(
          lodashPickBy(currentValue, (value, key) => key != 'iid'),
        ).reduce((v, c) => {
          return v + getMapByQuestionData(c).length;
        }, 0)
      );
    },
    0,
  );

  (userAnswers || []).map((question, questionIndex) => {
    const answer = get(question, 'value', [])
      .map((v) => String(v))
      .sort();
    const correct_answer = get(
      mddm_mapping_table_data,
      `dataSource[${question.row}][${question.col}][${question.index}]`,
      [],
    )
      .map((v) => String(v))
      .sort();
    if (isEqual(answer, correct_answer)) {
      totalCorrectAnswer += 1;
    }
  });

  const isCorrect = totalCorrectAnswer === answer_length;
  take.isCorrect = isCorrect;

  let percent = isCorrect ? 1 : 0;
  if (score_formula === scoreFormulaValue.BY_PART_QUESTION && answer_length) {
    percent = totalCorrectAnswer / answer_length;
  }

  take.percent = percent;
  take.score = percent.toFixed(2);

  return {
    take,
    totalCorrectAnswer,
    highlights,
  };
};

const getQuestionAnswerStatusMessage = (
  trackingLine,
  affectedQuestions,
  lessMessageKey,
  muchMoreMessageKey,
) => {
  if (!(affectedQuestions || []).length) {
    return null;
  }
  if (affectedQuestions.length <= 3) {
    const questionIndexes = [];
    const allQuestionIdsInTrackingLine = trackingLine.reduce(
      (accumulate, trackingLineItem) => {
        return accumulate.concat(trackingLineItem.allItems);
      },
      [],
    );
    affectedQuestions.forEach((questionId) => {
      const questionIndex = allQuestionIdsInTrackingLine.findIndex(
        (questionIdInTrackingLine) => questionIdInTrackingLine == questionId,
      );

      if (questionIndex >= 0) {
        questionIndexes.push(t('question_%s', questionIndex + 1));
      }
    });
    return t1(lessMessageKey, questionIndexes.join(', '));
  } else {
    return t1(muchMoreMessageKey, affectedQuestions.length);
  }
};

export const getQuestionAnswerSavingMessage = (
  trackingLine,
  pendingQuestions,
) => {
  const lessMessageKey = 'saving_question_answers_of_%s';
  const muchMoreMessageKey = 'saving_question_answers_of_total_%s';

  return getQuestionAnswerStatusMessage(
    trackingLine,
    pendingQuestions,
    lessMessageKey,
    muchMoreMessageKey,
  );
};

export const getFailedToSaveQuestionAnswerMessage = (
  trackingLine,
  failToSaveTakeQuestions,
) => {
  const lessMessageKey = 'failed_to_save_question_answers_of_%s';
  const muchMoreMessageKey = 'failed_to_save_question_answers_of_total_%s';

  return getQuestionAnswerStatusMessage(
    trackingLine,
    failToSaveTakeQuestions,
    lessMessageKey,
    muchMoreMessageKey,
  );
};

export const getQuestionAnswersInfo = (
  question,
  userAnswers,
  highlightsClass,
  shouldShowKeyWhenShowAnswer,
) => {
  let result;
  if (question.type === questionTypes.TYPE_INLINE) {
    result = getInlineAnswers(
      question,
      userAnswers,
      highlightsClass,
      shouldShowKeyWhenShowAnswer,
    );
  } else if (question.type === questionTypes.TYPE_REORDER) {
    result = getReorderAnswers(
      question,
      userAnswers,
      highlightsClass,
      shouldShowKeyWhenShowAnswer,
    );
  } else if (question.type === questionTypes.TYPE_MATCHING_PAIRS) {
    result = getMatchingPairAnswers(
      question,
      userAnswers,
      highlightsClass,
      shouldShowKeyWhenShowAnswer,
    );
  } else if (question.type === questionTypes.TYPE_LIKERT) {
    result = getLikertAnswers(
      question,
      userAnswers,
      highlightsClass,
      shouldShowKeyWhenShowAnswer,
    );
  } else if (question.type === questionTypes.TYPE_OPEN_ENDED) {
    result = getOpenEndedAnswers(question, userAnswers);
  } else if (question.type === questionTypes.TYPE_API) {
    result = getQuestionAPIAnswer(question, userAnswers);
  } else if (question.type === questionTypes.TYPE_INTRODUCTION) {
    result = getIntroAnswers(question, userAnswers);
  } else if (question.type === questionTypes.TYPE_MC_OPEN_ENDED) {
    result = getMcOpenEndedAnswers(question, userAnswers);
  } else if (question.type === questionTypes.TYPE_DDM) {
    result = getDdmAnswers(question, userAnswers, highlightsClass);
  } else if (question.type === questionTypes.TYPE_MDDM) {
    result = getMddmAnswers(question, userAnswers);
  } else {
    result = getMcAnswers(
      question,
      userAnswers,
      highlightsClass,
      shouldShowKeyWhenShowAnswer,
    );
  }
  return result;
};
