import { DefinedUrlParams } from 'routes/links/common';
import Loadable from 'react-loadable';
import Loading from 'components/common/loading';
import userLinks from 'routes/links/user';

const ForgotPassword = Loadable({
  loader: () => import('components/user/profile/forgot-password/Layout'),
  loading: Loading,
});

const Login = Loadable({
  loader: () => import('components/user/auth/login'),
  loading: Loading,
});
const Register = Loadable({
  loader: () => import('components/user/auth/register'),
  loading: Loading,
});

const UserProfileContainer = Loadable({
  loader: () => import('components/user/profile/Container'),
  loading: Loading,
});

const ReserPasswordForgotten = Loadable({
  loader: () =>
    import('components/user/profile/reset-password-forgotten/Layout'),
  loading: Loading,
});

export default () => [
  {
    componentId: 'UserProfileContainer',
    path: userLinks.update,
    component: UserProfileContainer,
    exact: true,
  },
  {
    componentId: 'UserProfileContainerWithStep',
    path: `${userLinks.update}/:${DefinedUrlParams.STEP}`,
    component: UserProfileContainer,
    exact: true,
  },
  {
    componentId: 'login',
    path: userLinks.login,
    component: Login,
    exact: true,
  },
  {
    componentId: 'register',
    path: userLinks.register,
    component: Register,
    exact: true,
  },
  {
    componentId: 'ForgotPassword',
    path: userLinks.forgot_password,
    component: ForgotPassword,
    exact: true,
  },
  {
    componentId: 'ResetPasswordForgotten',
    path: `${userLinks.reset_password_forgotten}/:${
      DefinedUrlParams.HASH_TOKEN
    }`,
    component: ReserPasswordForgotten,
    exact: true,
  },
];
