export const CONFIG_3B_STEP = {
  NO_ASSESSMENT: 0,
  SELF_ASSESSMENT: 1,
  FULL_ASSESSMENT: 2,
};

export const getConfig3BStepOption = () => [
  {
    label: 'Không đánh giá',
    value: CONFIG_3B_STEP.NO_ASSESSMENT,
  },
  {
    label: 'Tự đánh giá',
    value: CONFIG_3B_STEP.SELF_ASSESSMENT,
  },
  {
    label: 'Đánh giá 3 bước',
    value: CONFIG_3B_STEP.FULL_ASSESSMENT,
  },
];
