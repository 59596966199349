import { SET_LOGIN_CAPTCHA_ENABLED } from 'actions/auth';

const initialState = {
  loginCaptcha: {
    enabled: false,
    captchaId: undefined, // when need to re-render the captcha, change this id
  },
};

const login = (state = initialState, action) => {
  let newState = {};

  switch (action.type) {
    case SET_LOGIN_CAPTCHA_ENABLED: {
      const { enabled, captchaId } = action;
      const loginCaptcha = { ...state.loginCaptcha, enabled, captchaId };
      newState = { ...state, loginCaptcha };
      break;
    }

    default:
      return state;
  }
  return newState;
};

export default login;
