import React, { useMemo, useState } from 'react';
import { Tag } from 'antd';
import Icon from 'components/common/Icon';
import Tooltip from 'antd/lib/tooltip';

const ShowMoreLess = ({
  items,
  numberItemsShow = 3,
  showMore = false,
  asTag = true,
  breakWord = true,
}) => {
  const [showMoreItem, setShowMoreItem] = useState(showMore);

  const [itemsShowAsDefault, itemsWillBehind] = useMemo(
    () => {
      const cloneItems = [...(items || [])];
      if (cloneItems.length <= numberItemsShow) {
        return [cloneItems || [], []];
      }

      return [cloneItems.splice(0, numberItemsShow), cloneItems || []];
    },
    [items, numberItemsShow],
  );

  const renderItem = (item, shown) => {
    let className = shown ? 'd-inline-block m-5' : 'd-none';
    if (breakWord) {
      className = `${className} break-word`;
    }

    if (asTag) {
      return <Tag className={className}>{item.name}</Tag>;
    }

    return <span className={className}>{item.name}</span>;
  };

  if (!items || !items.length || !itemsShowAsDefault.length) {
    return null;
  }

  return (
    <>
      {itemsShowAsDefault.map((item) => renderItem(item, true))}

      {!!itemsWillBehind.length && (
        <>
          {itemsWillBehind.map((item) => renderItem(item, showMoreItem))}
          <span
            className="link font-size-small"
            onClick={() => setShowMoreItem(!showMoreItem)}
          >
            <Tooltip
              title={showMoreItem ? 'Hiển thị ít hơn' : 'Hiển thị nhiều hơn'}
            >
              <Icon icon={showMoreItem ? 'minus' : 'plus'} />
            </Tooltip>
          </span>
        </>
      )}
    </>
  );
};

export default ShowMoreLess;
