import React from 'react';
import NodeNew from 'components/admin/node/new';
import { connect } from 'react-redux';
import apiUrls from './endpoints';
import schema from './schema/form';
import withUserInfo from 'common/hoc/withUserInfo';

const FORM_ID = 'new_module';

const NewDeal = ({
  mode,
  step,
  node,
  searchFormId,
  title,
  userInfo,
  alternativeApi = apiUrls.new_deal,
  formId,
}) => {
  return (
    <>
      <NodeNew
        title={title}
        alternativeApi={alternativeApi}
        schema={schema}
        mode={mode || 'new'}
        step={step}
        node={node}
        closeModal
        searchFormId={searchFormId}
        formid={formId || FORM_ID}
        userInfo={userInfo}
      />
    </>
  );
};

export default connect()(withUserInfo(NewDeal));
